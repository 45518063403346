import { connect } from 'react-redux'


import NeverReceivedConfirmationCode from './components'

import createAction from '../../../../../../redux/actions/createAction'
import {
  FETCH_STATUSES_PUBLIC_FORMS_CLEAR,
} from '../../../../../../redux/actions/actionTypes'

import {
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL,
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW,
} from '../../../../../../constants'

const mapDispatchToProps = dispatch => ({
  handleReturnToPreviousScreen: () => {
    dispatch(createAction(
      FETCH_STATUSES_PUBLIC_FORMS_CLEAR,
      { target: [REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL] },
    ))
    // Why do we also need to clear the reset password fetch status? Imagine
    // this scenario: a user types in their email address, then mistypes their
    // confirmation code, resulting in them seeing an "Invalid Confirmation
    // Code" error message on the "Reset Password" screen. Only after that do
    // they realize that the original email address they typed in was incorrect
    // (let's say this user has access to several different MicroStar email
    // logins--a CEO of a large brewery or something), so they click the "Return
    // to enter email screen" button and re-enter their correct email address.
    // When they get back to the "Reset Password" screen, they'll still see the
    // "Invalid Confirmation Code" error message unless we clear the reset
    // password fetch status.
    dispatch(createAction(
      FETCH_STATUSES_PUBLIC_FORMS_CLEAR,
      { target: [REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW] },
    ))
  },
})

export default connect(
  undefined,
  mapDispatchToProps,
)(NeverReceivedConfirmationCode)
