import { combineReducers } from 'redux'

import {
  REDUCER_NAMES_FORMS_ADDITIONAL_INFO_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES,
} from '../../../constants'

import mostRecentSuccessfullySubmittedHistoryFormValuesReducer from './mostRecentSuccessfullySubmittedHistoryFormValues'

const formsAdditionalInfoReducers = {
  [REDUCER_NAMES_FORMS_ADDITIONAL_INFO_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES]:
    mostRecentSuccessfullySubmittedHistoryFormValuesReducer,
}

export default combineReducers(formsAdditionalInfoReducers)
