import { put, call, select, takeEvery } from 'redux-saga/effects'

import { publicFetch } from '../../fetch'
import {
  FETCH_FORGOT_PASSWORD_RESET_PW,
  FETCH_FETCH_CONFIRM_ACCOUNT,

  FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
  FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
  FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
} from '../../../actions/actionTypes'

import {
  API_URL_PATH_FORGOT_PASSWORD,
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW,
  REDUCER_NAMES_PUBLIC_FORMS_CONFIRM_ACCOUNT,
} from '../../../../constants'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'

import {
  getProp,
} from '../../../selectors/publicForms/forgotPassword'


export function* fetchResetPassword(action) {
  const {
    // email is only passed in if this is being used to confirm an account
    // rather than to reset a password of an already-confirmed account
    email,
    confirmationCode,
    password,
  } = action.payload

  const targetFetchStatusesReducerName = email
    ? REDUCER_NAMES_PUBLIC_FORMS_CONFIRM_ACCOUNT
    : REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW

  const apiRequestBody = yield call(createApiRequestBody, { email, confirmationCode, password })
  yield put(createAction(
    FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
    { target: [targetFetchStatusesReducerName] },
  ))
  try {
    yield call(publicFetch, { path: API_URL_PATH_FORGOT_PASSWORD, data: apiRequestBody, method: 'PUT' })
  } catch (error) {
    yield call(doFailure, error, targetFetchStatusesReducerName)
    return
  }
  yield call(doSuccess, targetFetchStatusesReducerName)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_FORGOT_PASSWORD_RESET_PW, fetchResetPassword],
  [takeEvery, FETCH_FETCH_CONFIRM_ACCOUNT, fetchResetPassword],
]

// just takes two lines down to one: extreme DRY!
function* doSuccess(targetFetchStatusesReducerName) {
  yield put(createAction(
    FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
    { target: [targetFetchStatusesReducerName] },
  )) // CODE_COMMENTS_20
}

function* doFailure(error, targetFetchStatusesReducerName) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
        target: [targetFetchStatusesReducerName],
      }),
    },
  )
}


function* createApiRequestBody({
  email, // passed in only if this saga is to confirm an account rather than to reset a password
  password,
  confirmationCode,
}) {
  let confirmingAccountRatherThanResettingPassword = true
  if (!email) {
    // eslint-disable-next-line no-param-reassign
    email = yield select(getProp, 'email')
    confirmingAccountRatherThanResettingPassword = false
  }
  return assembleApiRequestBodyObj({
    confirmingAccountRatherThanResettingPassword,
    email,
    password,
    confirmationCode,
  })
}


function assembleApiRequestBodyObj({
  confirmingAccountRatherThanResettingPassword,
  email,
  password,
  confirmationCode,
}) {
  return ({
    forgotConfirmation: !confirmingAccountRatherThanResettingPassword,
    username: email,
    password,
    confirmationCode,
  })
}
