import {
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_USER_PERMISSIONS,
} from '../../../../constants'
import getFetchStatusSelectorsByUserId from '../HOF/fetchStatusSelectors/byUserId'

// eslint-disable-next-line import/prefer-default-export
export default getFetchStatusSelectorsByUserId(
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_USER_PERMISSIONS,
)
