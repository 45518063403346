/* eslint-disable no-continue */

import { call, put, select, takeEvery } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import get_ from 'lodash/get'

import {
  getAreWeCurrentlyInMaintenanceMode,
} from '../../selectors/maintenanceMode'

import { publicFetch } from '../fetch'

import {
  START_LATEST_WEB_APP_VERSION_FETCHER_THREAD,
  SAVE_LATEST_WEB_APP_VERSION_STRING,
} from '../../actions/actionTypes'
import {
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  URL_PARAM_PROPERTY,
  URL_PARAM_PROPERTY_VALUE_WEB_APP_JENKINS_BUILD_NUMBER,
} from '../../../constants/formAndApiUrlConfig/systemProperties'

import {
  API_URL_PATH_SYSTEM_PROPERTIES_WEB_APP_VERSION,
  API_MAINTENANCE_HTTP_ERROR_CODES,
} from '../../../constants'

import {
  extractSystemPropertyFromHttpResponse,
} from '../util/systemProperties'

import {
  POLL_BACKEND_EVERY_X_MINUTES_FOR_LATEST_WEB_APP_VERSION,
} from '../../../config'


// Wait, shouldn't this be a cancelable saga which gets canceled on logout or
// authentication failure? Yes, but instead of using the cancelableForkedSaga()
// function here, we let our main rootSaga() wrap this with a
// transformSagasIntoCancellableSagas() function.
function* fetchLatestWebAppVersionNumberThread() {
  while (true) {
    // Pause this thread while we're in maintenance mode (CODE_COMMENTS_238)
    const areWeCurrentlyInMaintenanceMode = yield select(getAreWeCurrentlyInMaintenanceMode)
    if (areWeCurrentlyInMaintenanceMode) {
      yield call(delay, 20*1000) // wait several seconds
      continue
    }
    yield call(fetchLatestWebAppVersionNumber)
    yield call(delay, POLL_BACKEND_EVERY_X_MINUTES_FOR_LATEST_WEB_APP_VERSION*1000*60)
  }
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    START_LATEST_WEB_APP_VERSION_FETCHER_THREAD,
    fetchLatestWebAppVersionNumberThread,
  ],
]


// Helper functions

function* fetchLatestWebAppVersionNumber() {
  let response
  try {
    response = yield call(
      publicFetch,
      {
        path: API_URL_PATH_SYSTEM_PROPERTIES_WEB_APP_VERSION,
        params: {
          [URL_PARAM_PROPERTY]: URL_PARAM_PROPERTY_VALUE_WEB_APP_JENKINS_BUILD_NUMBER,
        },
        setOperateAsCustomerUserHeaderIfEmployeeIsLoggedIn: false, // CODE_COMMENTS_221
      },
    )
  } catch (error) {
    const statusCode = get_(error, ['response', 'status'])
    if (API_MAINTENANCE_HTTP_ERROR_CODES.includes(statusCode)) {
      // If the user is logged out and the back-end is in maintenance mode, the
      // getAreWeCurrentlyInMaintenanceMode selector will return false because
      // the front-end only sets the areWeInMaintenanceMode redux slice to true
      // if the user is logged in. Detecting a 503 (which all endpoints will
      // return during maintenance mode) is the alternative way to determine
      // whether we're in maintenance mode. If we are, don't attempt to set the
      // latest web app version.
      return
    }
    yield call(
      generalDoFailure,
      {
        error,
        preventsDashboardFromLoading: false,
      },
    )
    return
  }

  const latestWebAppVersion = extractSystemPropertyFromHttpResponse(
    response,
    'buildTag',
  )
  if (latestWebAppVersion) {
    yield put(createAction(SAVE_LATEST_WEB_APP_VERSION_STRING, latestWebAppVersion))
  }
}
