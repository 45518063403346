/**
 * CODE_COMMENTS_31
 */

import moment from 'moment'
import {
  CONCATENATED_STRING_SEPARATOR,
  ORDER_KEGS_ORDER_TYPE_BLANKET,
  ORDER_KEGS_ORDER_TYPE_LOCAL,
  ORDER_KEGS_ORDER_TYPE_OPEN,
  ORDER_KEGS_ORDER_TYPE_PICKUP,
  ORDER_KEGS_ORDER_TYPE_STANDARD,
} from '../index'

/*
 * *****************************************************************************
 * Field names
 * *****************************************************************************
*/

export const ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED = 'date-needed'
export const ORDER_KEGS_FORM_FIELD_NAME_DATE_TIME_NEEDED = 'dateTimeNeeded'
export const ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS = 'shipping-address'
export const ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS = 'pickup-address'
export const ORDER_KEGS_FORM_FIELD_NAME_CUSTOMER_PO_NUM = 'customer-po-number'
export const ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS = 'order-comments'
// export const ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE = 'order-type'
export const ORDER_KEGS_FORM_FIELD_LABEL_DATE_NEEDED = 'Date Needed'
export const ORDER_KEGS_FORM_FIELD_LABEL_SHIPPING_ADDRESS = 'Shipping Address'
export const ORDER_KEGS_FORM_FIELD_LABEL_CUSTOMER_PO_NUM = 'Internal Name / PO #'
export const ORDER_KEGS_FORM_FIELD_LABEL_ORDER_COMMENTS = 'Order Comments'
export const ORDER_KEGS_FORM_FIELD_LABEL_PALLET_TYPE = 'Pallet Type'
export const ORDER_KEGS_FORM_FIELD_LABEL_PICKUP_ADDRESS = 'Pick Up'
export const ORDER_KEGS_FORM_FIELD_LABEL_ORDER_TYPE = 'Order Type'

// CODE_COMMENTS_40
export const ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER = 'local'
export const ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER = 'nonlocal'
export const ORDER_KEGS_FORM_FIELD_NAME_PALLET_TYPE = 'palletType'
export const ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE = 'orderType'


/*
 * *****************************************************************************
 * Misc. Constants
 * *****************************************************************************
*/

export const MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER = 0


/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

/**
 * Returns a string to be used as a form field name for quantity fields in the
 * order kegs form
 */
export const createOrderKegsFormFieldNameOfQuantity = (itemSkuId, localOrNonlocal) => (
  `${itemSkuId}${CONCATENATED_STRING_SEPARATOR}${localOrNonlocal}`
)

/**
 * parses a form field name of a quantity field in the
 * order kegs form. Returns an array [itemSkuId, localOrNonlocal]
 */
export const parseOrderKegsFormFieldNameOfQuantity = fieldName => (
  fieldName.split(CONCATENATED_STRING_SEPARATOR)
)


/*
 * Quantities Forms field names
 * ****************************
*/

export const getNonlocalQuantitiesFormFieldsNames = itemSkuIds => (
  itemSkuIds.map(itemSkuId => (
    createOrderKegsFormFieldNameOfQuantity(
      itemSkuId,
      ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER,
    )
  ))
)

export const getLocalQuantitiesFormFieldsNames = itemSkuIds => (
  itemSkuIds.map(itemSkuId => (
    createOrderKegsFormFieldNameOfQuantity(
      itemSkuId,
      ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER,
    )
  ))
)

/**
 * Determine which quantities fields exist in the form depending on whether the
 * customer has local distributors.
 */
export const getQuantitiesFormFieldsNames = (itemSkuIds, hasLocalDistributors) => {
  const nonlocalQuantitiesFormFieldsNames = getNonlocalQuantitiesFormFieldsNames(itemSkuIds)
  const localQuantitiesFormFieldsNames = getLocalQuantitiesFormFieldsNames(itemSkuIds)

  if (!hasLocalDistributors) { return nonlocalQuantitiesFormFieldsNames }

  return nonlocalQuantitiesFormFieldsNames.reduce(
    (acc, nonlocalFieldName, index) => ([
      ...acc,
      nonlocalFieldName,
      localQuantitiesFormFieldsNames[index],
    ]),
    [],
  )
}

export const DEFAULT_ORDER_TYPES_FOR_ORDERING_KEGS = [
  {
    key: ORDER_KEGS_ORDER_TYPE_LOCAL,
    text: 'Local',
    value: ORDER_KEGS_ORDER_TYPE_LOCAL,
    showOnlyIfTrue: ({ hasLocalDistributors, contractEndDate }) => {
      // const mome = moment(contractEndDate)
      if (contractEndDate) {
        return moment(contractEndDate)?.isSameOrAfter(Date.now(), 'day') && Boolean(hasLocalDistributors)
      }
      return Boolean(hasLocalDistributors)
    },
  },
  {
    key: ORDER_KEGS_ORDER_TYPE_OPEN,
    text: 'Delivered',
    value: ORDER_KEGS_ORDER_TYPE_OPEN,
    showOnlyIfTrue: ({ deliveredFulfillment = true }) => Boolean(deliveredFulfillment),
  },
  {
    key: ORDER_KEGS_ORDER_TYPE_PICKUP,
    text: 'Customer pickup',
    value: ORDER_KEGS_ORDER_TYPE_PICKUP,
    showOnlyIfTrue: ({ pickupFulfillment = true }) => Boolean(pickupFulfillment),
  },
  {
    key: ORDER_KEGS_ORDER_TYPE_BLANKET,
    text: 'Order on a Schedule',
    value: ORDER_KEGS_ORDER_TYPE_BLANKET,
    showOnlyIfTrue: ({ canUseDeliverySchedule = true }) => Boolean(canUseDeliverySchedule),
  },
]
export const ORDER_TYPES_LABEL = {
  LOCAL: 'Local',
  OPEN: 'Delivered',
  PICKUP: 'Customer Pickup',
  [ORDER_KEGS_ORDER_TYPE_BLANKET]: 'Order on a Schedule',
  [ORDER_KEGS_ORDER_TYPE_STANDARD]: 'Standard',
}
