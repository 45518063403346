export const PAY_FOR_KEGS_AFTER_SUBMISSION = 'After submitting this form you must pay for your kegs'
export const CUSTOMER_PICKUP = 'Customer pickup'
export const DATE_NEEDED = 'Date Needed'
export const DELIVERED = 'Delivered'
export const INTERNAL_NAME_PO = 'Internal Name / PO #'
export const ORDER_COMMENTS = 'Order Comments'
export const ORDER_TYPE = 'Order Type'
export const PALLET_TYPE = 'Pallet Type'
export const PICK_UP = 'Pick Up'
export const REMINDER_PREPAY_FOR_YOUR_KEGS = 'Reminder: Prepay for Your Kegs'
export const SHIP_TO = 'Ship To'
export const PAY_KEGS_BEFORE_THEY_DELIVER = 'You MUST PAY for your kegs before they will be delivered.Contact Accounts Receivable to make a payment'
export const KEGS_ORDERED = 'Your kegs have been ordered'
export const KEGS_WILL_NOT_BE_PROCESSED = 'Your order will not be processed until payment is made.'
export const CONTACT_ACC_RECEIVABLE_FOR_PAYMENT = 'Contact Accounts Receivable to make a payment.'
export const SUBMIT = 'Submit'
export const ORDER_KEGS_FORM_TENTATIVE_MSG ='will make every attempt to provide kegs by your requested delivery date but dates should be considered tentative until you receive a formal acceptance from'
export const ACK_INBOUND_SHIPMENTS_UNFILLED_WARNING = 'Are there some shipments here you can\'t acknowledge yet? No problem, unfilled rows won\'t be submitted'
export const WARNING_TEXT1 = 'Until this is resolved, you cannot'
export const WARNING_TEXT2 = 'but you can perform all other activities'
export const WARNING_TEXT3 = 'so you cannot'
export const WARNING_TEXT4 = 'has been placed on a credit hold. Please contact your personalized representative to resolve.'
export const WARNING_TEXT5 = 'Our records indicate you have not reported outbound shipments within the last 90 days. Please report outbound shipments to order kegs.'
export const WARNING_TEXT6 = 'has been placed on hold. Please contact your account manager to resolve.'
export const WARNING_TEXT7 = 'If you feel your account has been de-activated by mistake, contact your personalized representative to resolve.'
export const WARNING_TEXT8 = 'account is inactive'
export const WARNING_TEXT9 = 'You account is currently on acknowledgment hold. This will prevent you from ordering more kegs. Please acknowledge your shipments to remove the hold on your account.'
export const WARNING_TEXT10 = 'You have not acknowledged some shipments for over 5 days. Please note your ability to order kegs will be suspended soon until these shipments are acknowledged.'
export const WARNING_TEXT11 = 'Our records indicate you have not reported enough outbound shipments for the volume of inbound shipments on your account'
export const WARNING_TEXT12 = 'within the last'
export const WARNING_TEXT13 = 'days. If your outbounds are not reported in the next 5 days, a reporting hold will be placed on your account and will restrict your ability to order kegs until reported. If you have any questions, feel free to reach out to our billing team at billing@microstarkegs.com'
export const REQUEST_NEW_DIST_TEXT1 = 'Your relationship to'
export const REQUEST_NEW_DIST_TEXT2 = 'has been created! It\'s still pending approval from the'
export const REQUEST_NEW_DIST_TEXT3 = 'team, but you can start reporting shipments to this distributor right now.'
export const REQUEST_NEW_DIST_TEXT4 = 'is already a distributor for the selected source.'
export const REQUEST_NEW_DIST_TEXT5 = 'Confirm Info'
export const PAY_BALANCE_TEXT1 = 'Balance'
export const PAY_BALANCE_TEXT2 = 'As of'
export const PAY_BALANCE_TEXT3 = 'Your balance won\'t be immediately updated on this page after making a payment. It will update after the next billing period.'
