export const supportedLanguages = {
  en: {
    id: 'en',
    value: 'en',
    text: 'English',
    active: true,
  },
  es: {
    id: 'es',
    value: 'es',
    text: 'Spanish',
    active: false,
  },
}

//
// fr: {
//   id: 'fr-MF',
//     value: 'fr-MF',
//     text: 'French',
//     active: false,
// },
