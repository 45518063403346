import { call, put, select, takeEvery } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import {
  getLatestWebAppVersionString,
} from '../../selectors/latestWebAppVersion'

import {
  HARD_REFRESH_TO_DOWNLOAD_LATEST_WEB_APP_VERSION,
  INCREASE_BY_ONE_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH,
} from '../../actions/actionTypes'
import createAction from '../../actions/createAction'


export function* hardRefreshToDownloadLatestWebAppVersion() {
  const latestWebAppVersion = yield select(getLatestWebAppVersionString)
  yield put(createAction(
    INCREASE_BY_ONE_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH,
    { webAppVersion: latestWebAppVersion },
  ))
  // Wait a second before hard refreshing the app so that the event loop has
  // time to save the
  // INCREASE_BY_ONE_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH
  // action to disk via redux-persist
  yield call(delay, 1*1000)
  window.location.reload() // hard refresh the app
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    HARD_REFRESH_TO_DOWNLOAD_LATEST_WEB_APP_VERSION,
    hardRefreshToDownloadLatestWebAppVersion,
  ],
]
