import historyReducerCreator from './historyReducerCreator'

import {
  SAVE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
  SAVE_SINGLE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM,
} from '../../actions/actionTypes'

export default historyReducerCreator({
  saveHistoryOfSingleCustomerActionType: SAVE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
  saveSingleHistoryItemActionType: SAVE_SINGLE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM,
})
