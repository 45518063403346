import React from 'react'
import { Modal } from 'semantic-ui-react'


import ModalWithCatastrophicFailureErrorBoundary from '../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import TextAsPseudoLink from '../../../common-components/TextAsPseudoLink'

import {
  createDisplayedDateCounted,
} from '../../History/individualTabs/util/inventoryHistory/displayedInventoryReportData'

import {
  INVENTORY_REPORT_OBJS,
} from '../util/constants'

import {
  getMostRepresentativeInventoryReportObj,
} from '../util'


export default ({
  dontActuallyApplyTheFullDetailsModalHoc,
  fullDetailsModalHeaderText,
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  children,
}) => {
  if (dontActuallyApplyTheFullDetailsModalHoc) {
    return children
  }
  const mostRepresentativeInventoryReportObj = getMostRepresentativeInventoryReportObj({
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  })
  return (
    <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      closeIcon
      size="large"
      trigger={<TextAsPseudoLink>{createDisplayedDateCounted(mostRepresentativeInventoryReportObj)}</TextAsPseudoLink>}
    >
      <Modal.Header>{fullDetailsModalHeaderText}</Modal.Header>
      <Modal.Content>
        {children}
      </Modal.Content>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}
