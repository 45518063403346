import React from 'react'


import HasPermissions from '../../../../../../common-components/HasPermissions'

import {
  getHasAnyInvoices,
} from '../../../../../../redux/selectors/customerBalances'
import customerBalancesFetchStatusSelectors from '../../../../../../redux/selectors/fetchStatuses/customers/customerBalances'

import {
  OrderKegCollarsOption,
  PayBalanceOption,
  ManageUsersOption,
  ManageContactsAndSubscriptionsOption,
  ManageAddressesOption,
} from '../../shared/IndividualOptions'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
} from '../../../../../../constants/permissions'

import {
  createDisabledIfAnyOfTheseConditionsAreTrueFunc,
} from '../../util'

import {
  TEMPORARILY_DISABLE_PAY_BALANCES_FEATURE,
  TEMPORARILY_DISABLE_CONTACTS_FEATURE,
} from '../../../../../../config'


// CODE_COMMENTS_39
export default ({
  customerId,
  shouldOrderKegCollarsFormBeRendered,
}) => ([
  ...(
    shouldOrderKegCollarsFormBeRendered
      ? [
        <OrderKegCollarsOption
          key="OrderKegCollarsOption"
          disabledIf={
            createDisabledIfAnyOfTheseConditionsAreTrueFunc({
              customerId,
              customerIsInactive: true,
              customerIsOnCreditHold: true,
              customerIsOnReportingHold: true,
              customerIsOnAcknowledgementHold: true,
            })
          }
        />,
      ]
      : []
  ),
  ...(!TEMPORARILY_DISABLE_PAY_BALANCES_FEATURE
    ? [
      <HasPermissions
        key="PayBalanceOption"
        requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES}
        renderIfHasPermissions={
          () => (
            <PayBalanceOption
              customerId={customerId}
              disabledIf={state => {
                const { getFetchStatuses } = customerBalancesFetchStatusSelectors
                const { didFetchSucceed: didFetchingBalancesSucceed } = getFetchStatuses(state, customerId)
                const hasNoInvoices = getHasAnyInvoices(state, customerId) === false
                // If fetching balances fails, we want the Pay Balances card to be
                // enabled, even if the user ends up having no invoices, because the
                // user will be able to re-try that fetch on the Pay Balances page
                // (if the fetch then succeeds, they user will be presented with a
                // message saying something like "You have no invoices on file").
                return (didFetchingBalancesSucceed && hasNoInvoices)
              }}
            />
          )
        }
      />,
    ]
    : []
  ),
  <HasPermissions
    key="ManageUsersOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
    renderIfHasPermissions={
      () => (
        <ManageUsersOption />
      )
    }
  />,
  ...(!TEMPORARILY_DISABLE_CONTACTS_FEATURE
    ? [
      <HasPermissions
        key="ManageContactsAndSubscriptionsOption"
        requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
        renderIfHasPermissions={
          () => (
            <ManageContactsAndSubscriptionsOption />
          )
        }
      />,
    ]
    : []
  ),
  <HasPermissions
    key="ManageAddressesOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES}
    renderIfHasPermissions={
      () => (
        <ManageAddressesOption />
      )
    }
  />,
])
