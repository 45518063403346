import React, { useState } from 'react'
import { Button, Icon, Loader, Popup } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import createAction from '../../../../../../../redux/actions/createAction'
import { FETCH_BOL_PDF_DOWNLOAD } from '../../../../../../../redux/actions/actionTypes'

const PrintBOL = props => {
  const { dispatch, shipmentId } = props
  const { t: translate } = useTranslation('common')
  const [response, setResponse] = useState(null)
  const [loadingFailed, setLoadingFailed] = useState(false)
  const [pdfLoaded, setPdfLoaded] = useState(false)
  const [loading, setLoading] = useState(false)

  const download = responseData => {
    if (responseData?.contents) {
      const dataURItoBlob = dataURI => {
        const byteString = window.atob(dataURI)
        const arrayBuffer = new ArrayBuffer(byteString.length)
        const int8Array = new Uint8Array(arrayBuffer)
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i)
        }
        const blob = new Blob([int8Array], { type: 'application/pdf' })
        return blob
      }

      try {
        const blob = dataURItoBlob(responseData.contents)
        const url = URL.createObjectURL(blob)
        // window.open(url, '_blank')
        const anchor = document.createElement('a')
        anchor.download = responseData.fileName
        anchor.href = url
        anchor.click()
        if (!response) {
          setResponse(responseData)
        }
        setPdfLoaded(true)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        setLoadingFailed(true)
      }
    }
  }
  const handleClick = () => {
    setLoading(true)
    setLoadingFailed(false)
    setPdfLoaded(false)
    dispatch(createAction(
      FETCH_BOL_PDF_DOWNLOAD,
      {
        shipmentId,
        onFailure: () => {
          setLoadingFailed(true)
          setLoading(false)
          setResponse(null)
        },
        onSuccess: responseData => {
          setLoadingFailed(false)
          setLoading(false)
          download(responseData)
        },
      },
    ))
  }

  return (
    <React.Fragment>
      <Popup
        pinned={loadingFailed}
        content={loadingFailed ? translate('BOL download failed. Try again.') : pdfLoaded ? translate('PDF downloaded') : translate('Click to Download')}
        trigger={
          <Button
            color="green"
            type="button"
            disabled={loading}
            onClick={() => (response ? download(response) : handleClick())}
          >
            <Button.Content visible>
              <span>{translate('BOL')}</span>
              {!loading && <Icon name="download" style={{ margin: '0 0 0 0.5rem' }} />}
              {loading &&
                <Loader
                  inline
                  active={loading}
                  size="mini"
                  style={{ margin: '0 0 0 0.5rem' }}
                />}
            </Button.Content>
          </Button>
        }
      />
    </React.Fragment>
  )
}

export default PrintBOL
