/* eslint-disable jsx-a11y/label-has-for */

// This component allows you to format part of a form as a labeled grid:

// +------------+-----------+-----------+
// |            | topLabel1 | topLabel2 |
// +------------+-----------+-----------+
// | leftLabel1 | [field]   | [field]   |
// | leftLabel2 | [field]   | [field]   |
// +------------+-----------+-----------+

// The user cannot add or subtract rows to the grid, and there's no facility for
// rows being individually submitted to the backend. If you need this
// functionality, use the FormSectionAsTable component.

// The only required props are leftLabels and topLabels. The simplest usage is:

// <Form>
//  <HorizontalFormSectionAsLabeledGrid
//    leftLabels={[{label: 'leftLabel1'}, {label: 'leftLabel2'}]}
//    topLabels={[{label: 'topLabel1'}, {label: 'topLabel2'}]}
//  />
// </Form>

// Doing this, all form fields will automatically be semantic-ui-react <Input>
// components, and their "name" prop will be e.g. "leftlabel1-toplabel1".

// If you pass in your own 'rows' prop, it should be an array of arrays, each
// item being a React element (_not_ a React component).

// If you don't want a form field to be rendered in certain cells, simply pass
// null or undefined in place of the React element in your 'rows' prop:

// +------------+-----------+-----------+
// |            | topLabel1 | topLabel2 |
// +------------+-----------+-----------+
// | leftLabel1 |           | [field]   |
// | leftLabel2 | [field]   |           |
// +------------+-----------+-----------+

// As shown above, the leftLabels and topLabels props are arrays of objects.
// Each object must contain a 'label' field, but can contain these other fields:

// {
//   label: 'leftLabel1',
//   required: true, // puts a red asterisk next to the label
//   arbitraryContentAfterLabel: () => <InformationalPopup content="blah" />,
// }

// CODE_COMMENTS_272: This component is mobile-friendly just like the
// HorizontalFormSection component is; the left labels have the same default
// widths as HorizontalFormSection, so the two components can be used in the
// same form and things line up perfectly. You can customize the width of the
// label column with the customLabelWidths prop. As for the input field columns,
// the remaining space is divided equally among the columns. It's probably best
// to avoid more than 3 field columns, because space gets really tight in the
// mobile view.

import React from 'react'
import { Grid, Form } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'


import {
  createFormFieldName,
  getLabelAndFieldWidthsForHorizontalFormSection,
} from '../../utils'


import './horizontalFormSectionAsLabeledGrid.css'


export default function HorizontalFormSectionAsLabeledGrid({
  leftLabels,
  topLabels,
  rows,
  colonAtEndsOfLeftLabels=false,
  compact=true, // Decreases the vertical space between rows
  customLabelWidths={}, // CODE_COMMENTS_272
  ...gridProps
}) {
  const [labelWidths] = getLabelAndFieldWidthsForHorizontalFormSection(customLabelWidths)
  const { t: translate } = useTranslation('common')

  if (!rows) {
    // eslint-disable-next-line no-param-reassign
    rows = leftLabels.map(leftLabel => (
      topLabels.map(topLabel => (
        <Form.Input name={createFormFieldName(leftLabel.label, topLabel.label)} />
      ))
    ))
  }

  const Gridrows = []

  rows.forEach((row, rowIndex) => {
    if (rowIndex === 0) {
      // top labels
      Gridrows.push(
        <Grid.Row key="top labels" style={compact ? { paddingBottom: '0' } : null}>
          <Grid.Column
            key="upper left blank cell"
            {...labelWidths}
          />
          {topLabels.map(topLabelDef => {
            const ArbitraryContentAfterLabel = (
              topLabelDef.arbitraryContentAfterLabel
                ? topLabelDef.arbitraryContentAfterLabel
                : () => null
            )
            return (
              <Grid.Column
                key={topLabelDef.label}
                textAlign="center"
                style={{ fontWeight: 'bold' }}
              >
                <Form.Field required={topLabelDef.required}>
                  {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                  <label>
                    {translate(topLabelDef.label)}
                    <ArbitraryContentAfterLabel />
                  </label>
                </Form.Field>
              </Grid.Column>
            )
          })}
        </Grid.Row>,
      )
    }

    // Rows
    const ArbitraryContentAfterLabel = leftLabels[rowIndex].arbitraryContentAfterLabel || (() => null)
    Gridrows.push(
      <Grid.Row key={leftLabels[rowIndex].label} style={compact ? { paddingBottom: '0' } : null}>

        {/* Left Label */}
        <Grid.Column
          key={leftLabels[rowIndex].label}
          style={{ fontWeight: 'bold' }}
          {...labelWidths}
        >
          <Form.Field required={leftLabels[rowIndex].required}>
            {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
            <label>
              {`${translate(leftLabels[rowIndex].label)}${colonAtEndsOfLeftLabels ? ':' : ''}`}
              <ArbitraryContentAfterLabel />
            </label>
          </Form.Field>
        </Grid.Column>

        {row.map((cellContent, cellIndex) => {
          const name = createFormFieldName(leftLabels[rowIndex].label, topLabels[cellIndex].label)
          return (
            <Grid.Column
              key={name}
              textAlign="center"
            >
              {cellContent}
            </Grid.Column>
          )
        })}
      </Grid.Row>,
    )
  })

  return (
    <Grid // CODE_COMMENTS_22
      className="horizontal-form-section-as-labeled-grid"
      // Adding a margin of 1em on the bottom matches the spacing of semantic
      // ui's built-in form fields (though you'll only see a perfectly-spaced
      // form when the 'compact' prop is set)

      // eslint-disable-next-line react/jsx-props-no-multi-spaces
      style={{ marginBottom: '1em' }}
      columns='equal'
      verticalAlign="middle"
      {...gridProps}
    >
      {Gridrows}
    </Grid>
  )
}
