/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file, see CODE COMMENTS 50

import { put, all } from 'redux-saga/effects'


import {
  FETCH_PRELIM_CUSTOMER_INFO_REQUEST,
  FETCH_PRELIM_CUSTOMER_INFO_SUCCESS,
  FETCH_PRELIM_CUSTOMER_INFO_FAILURE,
} from '../../../actions/actionTypes'

import createAction from '../../../actions/createAction'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../utils'

import {
  PrelimInfoFetchNeededForRenderingDashboardFailedError,
  ApiReturnedUnexpectedDataError,
} from '../../../../customErrors'


/**
 * A higher-order saga that sets the Prelim Info fetch statuses
 * (FETCH_PRELIM_CUSTOMER_INFO_REQUEST, FETCH_PRELIM_CUSTOMER_INFO_SUCCESS,
 * etc.) of the target customer. Wrap the
 * fetch-prelim-info-of-individual-customer sagas in this.
 */
export const withPrelimCustomerInfoFetchStartedAndFinishedFlags = wrappedSaga => (
  function* replacementSaga(
    customerId,
    // The following arg will only be passed in if the wrappedSaga is for a
    // contractee brewer
    operatingContractBrewerCustomerId,
  ) {
    yield put(createAction(FETCH_PRELIM_CUSTOMER_INFO_REQUEST, {
      target: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
    }))
    try {
      yield wrappedSaga(
        customerId,
        // The wrappedSaga takes two arguments if it's for a contractee brewer,
        // and only one argument if it's for any other type of customer. If this
        // is not for a contractee, the saga will simply ignore this argument
        operatingContractBrewerCustomerId,
      )
    } catch (e) {
      if (
        e instanceof PrelimInfoFetchNeededForRenderingDashboardFailedError ||
        e instanceof ApiReturnedUnexpectedDataError // CODE_COMMENTS_180
      ) {
        yield put(createAction(FETCH_PRELIM_CUSTOMER_INFO_FAILURE, {
          target: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
        }))
        return
      }
      throw e
    }
    yield put(createAction(FETCH_PRELIM_CUSTOMER_INFO_SUCCESS, {
      target: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
    }))
  }
)


export function* callMultipleFetchesAtTheSameTime({
  // An array of generators. These should not be blocking functions. Be careful
  // with these: the "Preliminary Info Has Been Fetched" flag can be set before
  // these finish running, which means that the Dashboard can be rendered before
  // these finish running, so only include sagas here that aren't required to
  // finish before the user can start using the app.
  generatorsOfNonBlockingSagasWhoseFailureWontResultInTheFailureOfTheWrappingSaga=[],
  // An array of generators. These should be blocking functions. Put anything
  // here that needs to finish before the Dashboard is rendered. Although these
  // are blocking sagas, they're run in parallel, not one after the other
  // (they're 'blocking' in that this function will return only after the last
  // saga in this array finishes); therefore, the order of them in this array
  // doesn't matter.
  generatorsOfBlockingSagasWhoseFailureWillResultInTheFailureOfTheWrappingSaga=[],
}) {
  // Here you can invoke any sagas whose failure should NOT result in the
  // failure of the wrapping Preliminary Info saga. See CODE_COMMENTS_89.
  for (const generator of generatorsOfNonBlockingSagasWhoseFailureWontResultInTheFailureOfTheWrappingSaga) {
    yield generator
  }

  // Invoke any sagas here whose failure should result in the failure of the
  // wrapping Preliminary Info saga
  yield ( // CODE_COMMENTS_21
    function* actualSaga() {
      yield all(generatorsOfBlockingSagasWhoseFailureWillResultInTheFailureOfTheWrappingSaga)
    }()
  )
}
