import { connect } from 'react-redux'

import Routes from './components/EmployeeRoutes'


const mapStateToProps = () => ({})

export default connect(
  mapStateToProps,
)(Routes)
