/**
 * CODE_COMMENTS_4
 */
import React from 'react'
import { withRouter } from 'react-router-dom'

import NoMatch from './NoMatch'

export default withRouter(({ children, location }) => (
  location && location.state && location.state.notFoundError
    ? <NoMatch />
    : children
))
