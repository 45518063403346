import React from 'react'
import { Loader } from 'semantic-ui-react'

import has_ from 'lodash/has'
import isNumber_ from 'lodash/isNumber'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import valuesFp_ from 'lodash/fp/values'
import flattenFp_ from 'lodash/fp/flatten'
import filterFp_ from 'lodash/fp/filter'
import maxFp_ from 'lodash/fp/max'
import maxByFp_ from 'lodash/fp/maxBy'
import minByFp_ from 'lodash/fp/minBy'


import {
  formatDataForUseInChartAndDownloadFile,
} from '../../../../../../ReportedInventoryVsCalculatedInventory/util'

import {
  sortArrayByTemplateArray,
  getContainerTypesDefaultSortOrder,
} from '../../../../../../../utils'

import './styles.css'


export default function ReportedVsCalculatedInventoryCardCustomContent({
  didFetchSucceed,
  didFetchFail,
  reportedVsCalculatedInventoryDataForCustomer,
}) {
  if (didFetchSucceed) {
    const differences = getReportedVsCalculatedDifferencesForMostRecentInventoryReport({
      reportedVsCalculatedInventoryDataForCustomer,
    })
    return (
      <div
        style={{ lineHeight: '60%' }}
      >
        {differences.map(({ containerType, difference, differencePercentageOfMaxReportedOrCalculated }) => (
          <p
            key={containerType}
            style={{
              fontSize: '1.6em',
              // Decrease the vertical space between <p> tags if the user has 3
              // or more container types
              marginBottom: differences.length > 2 ? '0' : null,
              color: 'black',
            }}
          >
            {containerType}:
            <span style={{ fontSize: '0.6em' }}>
              {'\u00A0'} {/* non-breaking space; {' '} doesn't work */}
              off by
              {'\u00A0'} {/* non-breaking space; {' '} doesn't work */}
            </span>
            <span
              style={{
                color: getDifferenceTextColorByPercentage(differencePercentageOfMaxReportedOrCalculated),
                fontWeight: 'bold',
              }}
            >
              {difference}
            </span>
          </p>
        ))
        }
      </div>
    )
  } else if (didFetchFail) {
    // There's nothing we can do here if the fetch fails--we can't render a
    // re-fetch button because the entire card is a link, so there can be no
    // clickable button within the card. Simply show nothing.
    return null
  }
  return <Loader size="large" active inline='centered' />
}


function getReportedVsCalculatedDifferencesForMostRecentInventoryReport({
  reportedVsCalculatedInventoryDataForCustomer,
}) {
  const [dataForChart] = formatDataForUseInChartAndDownloadFile({
    reportedVsCalculatedInventoryDataForCustomer,
  })
  const containerTypes = sortArrayByTemplateArray(
    Object.keys(dataForChart),
    getContainerTypesDefaultSortOrder(),
  )
  return containerTypes.map(containerType => {
    let difference = getReportedVsCalculatedDifferenceForLatestInventoryReport(dataForChart, containerType)
    if (isNumber_(difference)) {
      difference = Math.abs(difference)
    }
    const maxQtyReportedOrCalculated = getMaxQtyReportedOrCalculated(dataForChart, containerType)
    const differencePercentageOfMaxReportedOrCalculated = difference
      ? Math.abs(difference/maxQtyReportedOrCalculated)
      // difference will be null in the unlikely event that there are no reported
      // inventory reports in the entire reportedVsCalculated data for this
      // customer
      : null
    return {
      containerType,
      difference,
      differencePercentageOfMaxReportedOrCalculated,
    }
  })
}


function getReportedVsCalculatedDifferenceForLatestInventoryReport(dataForChart, containerType) {
  const latestReportedInventoryReport = flow_(
    o => o[containerType],
    filterFp_(o => has_(o, 'reportedCountDate')),
    maxByFp_('date'),
  )(dataForChart)

  const latestCalculatedInventoryReport = flow_(
    o => o[containerType],
    filterFp_(o => has_(o, 'calculatedPostedDate')),
    maxByFp_('date'),
  )(dataForChart)

  // If there are no reported and/or calculated inventory reports in the entire
  // reportedVsCalculated data for this customer (which would happen when a
  // customer has just had their invReconcilliationDate field set to something
  // like yesterday)
  if (!latestReportedInventoryReport || !latestCalculatedInventoryReport) {
    return null
  }
  if (has_(latestReportedInventoryReport, 'calculatedPostedDate')) {
    return latestReportedInventoryReport.reportedQuantity - latestReportedInventoryReport.calculatedQuantity
  }
  // What if the latest reported inventory report wasn't on  or near the end of
  // the month, but rather in the middle of the month, meaning the
  // latestReportedInventoryReport object has no corresponding calculated data?
  // First, we need to determine whether the latest inventory report is after
  // the latest calculated report. For example, today is March 20th and the
  // latest report was made on March 15th. If so, compare the March 15th report
  // with the latest calculated report, _even if_ the latest calculated report
  // has a corresponding reported report.
  if (latestCalculatedInventoryReport.date < latestReportedInventoryReport.date) {
    return latestReportedInventoryReport.reportedQuantity - latestCalculatedInventoryReport.calculatedQuantity
  }
  // If the latest reported inventory report falls in between two calculated
  // inventory reports, we need to take a guestimate: wherever the reported
  // inventory report date intersects the slope of the calculated line, that's
  // what we'll assume the calculated quantity to be
  const calculatedReportImmediatelyBeforeLatestReportedReport = flow_(
    o => o[containerType],
    filterFp_(o => has_(o, 'calculatedPostedDate')),
    filterFp_(o => o.date < latestReportedInventoryReport.date),
    maxByFp_('date'),
  )(dataForChart)
  const calculatedReportImmediatelyAfterLatestReportedReport = flow_(
    o => o[containerType],
    filterFp_(o => has_(o, 'calculatedPostedDate')),
    filterFp_(o => o.date > latestReportedInventoryReport.date),
    minByFp_('date'),
  )(dataForChart)
  const calculatedQtyDifferenceBetweenTheTwoMonths = (
    Number(calculatedReportImmediatelyAfterLatestReportedReport?.calculatedQuantity || 0)
    - Number(calculatedReportImmediatelyBeforeLatestReportedReport?.calculatedQuantity || 0)
  )
  const timeDifferenceBetweenTheTwoMonthsInMilliseconds = (
    Number(calculatedReportImmediatelyAfterLatestReportedReport?.date || 0)
    - Number(calculatedReportImmediatelyBeforeLatestReportedReport?.date || 0)
  )
  const timeDiffBetweenCalculatedReportImmediatelyBeforeLatestReportedReportAndLatestReportedReportInMilliseconds = (
    Number(latestReportedInventoryReport?.date || 0)
    - Number(calculatedReportImmediatelyBeforeLatestReportedReport?.date || 0)
  )
  const reportedDateAsPercentageOfEntireMonth = (
    timeDiffBetweenCalculatedReportImmediatelyBeforeLatestReportedReportAndLatestReportedReportInMilliseconds
    / timeDifferenceBetweenTheTwoMonthsInMilliseconds
  ) || 0
  const offsetForEstimatedCalculatedQuantityOnDayOfLatestReport = Math.round(
    calculatedQtyDifferenceBetweenTheTwoMonths*reportedDateAsPercentageOfEntireMonth,
  )
  const estimatedCalculatedQuantityOnDayOfLatestReport = (
    Number(calculatedReportImmediatelyBeforeLatestReportedReport?.calculatedQuantity || 0)
    + Number(offsetForEstimatedCalculatedQuantityOnDayOfLatestReport || 0)
  )

  return (
    Number(latestReportedInventoryReport?.reportedQuantity || 0)
    - estimatedCalculatedQuantityOnDayOfLatestReport
  )
}


// Returns the maximum reported or calculated (whichever is higher) of a certain
// container type.
function getMaxQtyReportedOrCalculated(dataForChart, containerType) {
  return flow_(
    o => o[containerType],
    mapFp_(o => [o.calculatedQuantity, o.reportedQuantity]),
    valuesFp_,
    flattenFp_,
    // filter out nulls
    filterFp_(i => i),
    maxFp_,
  )(dataForChart)
}


function getDifferenceTextColorByPercentage(percentage) {
  const thresholds = [
    { upToThisPercentage: 0.1, shouldBeThisColor: 'green' },
    { upToThisPercentage: 0.3, shouldBeThisColor: 'orange' },
    { upToThisPercentage: 1, shouldBeThisColor: 'red' },
  ]
  const colorNameToSemanticUiColorScheme = {
    green: 'green', // The Semantic UI green, #21ba45, is a little too bright
    orange: '#f2711c',
    red: '#db2828',
  }
  let colorName
  thresholds.reverse().forEach(o => {
    if (percentage <= o.upToThisPercentage) {
      colorName = o.shouldBeThisColor
    }
  })
  return colorNameToSemanticUiColorScheme[colorName]
}
