/**
 * CODE_COMMENTS_62
 * CODE_COMMENTS_134
 */
import React from 'react'


import {
  DisplayedOriginNameForOutboundShipmentsHistoryTable,
} from './displayedOutboundFullKegShipmentDataConnectedComponents'


export const createDisplayedOriginNameForOutboundShipmentsHistoryTable = (
  row,
  {
    customerId,
    operatingContractBrewerCustomerId,
  },
) => (
  <DisplayedOriginNameForOutboundShipmentsHistoryTable
    customerId={customerId}
    operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
    row={row}
  />
)
