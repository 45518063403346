import {
  REDUCER_NAMES_HISTORY,
  REDUCER_NAMES_HISTORY_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDERS as defaultSlice,
} from '../../../constants'

import { drillDownInObject } from '../../../utils'

export const getAllShipmentsOfKegOrder = (state, customerId, orderId) => (
  drillDownInObject(state, REDUCER_NAMES_HISTORY, defaultSlice, 'byCustomerId', customerId, orderId)
)
