import React from 'react'


import { useTranslation } from 'react-i18next'
import ForeignKegExplanation from '../../../common-components/ForeignKegExplanation'
import InformationalPopup from '../../../common-components/InformationalPopup'


export default () => {
  const { t: translate } = useTranslation('common')
  return (
    // CODE_COMMENTS_97
    <div style={{ marginBottom: '1em' }}>
      <InformationalPopup
        includeTriggerText
        triggerText={translate("What's a foreign keg?")}
        content={<ForeignKegExplanation />}
      />
    </div>
  )
}
