import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'
import { supportedLanguages } from './constants'

export default () => {
  const translate = useTranslation()
  const { i18n } = translate
  const defaultLang = localStorage.getItem('lang') || supportedLanguages[navigator.language]?.value || 'en'
  const [lang, setLang] = useState(defaultLang)
  if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang', lang)
    i18n?.changeLanguage(lang)
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <Dropdown
        placeholder='Select Language'
        onChange={(e, { value }) => {
          e.preventDefault()
          setLang(value)
          localStorage.setItem('lang', value)
          i18n?.changeLanguage(value)
        }}
        value={supportedLanguages?.[lang]?.key}
        search
        scrolling
        defaultValue={lang}
        closeOnBlur
        closeOnChange
        closeOnEscape
        name='language'
        options={Object.values(supportedLanguages)}
      />
    </div>
  )
}
