import React from 'react'

import useIsCapsLockOn from '../hooks/useIsCapsLockOn'
import PopupWithCatastrophicFailureErrorBoundary from '../semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'


export default ({
  trigger,
  translate,
  ...rest
}) => {
  const isCapsLockOn = useIsCapsLockOn()

  return (
    <PopupWithCatastrophicFailureErrorBoundary
      trigger={trigger}
      open={isCapsLockOn}
      content={translate('Caps Lock is on')}
      position='left center'
      style={{
        padding: '0.5em',
        border: '1px solid #fbbd08',
      }}
      {...rest}
    />
  )
}
