/* CODE_COMMENTS_241 */

import {
  SAVE_UNIVERSAL_CUSTOMER_REPRESENTATIVE,
} from '../actions/actionTypes'

const initialState = {}

export default (state=initialState, action) => {
  switch (action.type) {
    case SAVE_UNIVERSAL_CUSTOMER_REPRESENTATIVE:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
