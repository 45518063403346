import React from 'react'

import get_ from 'lodash/get'
import orderBy_ from 'lodash/orderBy'


import DownloadTableAsFile from '../../../sharedComponents/DownloadTableAsFile'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
} from '../../../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  createTableAndDownloadFileContentDefinitions,
} from '../../util/tableAndDownloadFileContentDefinitions'

import {
  createFilenameOfDownloadedTableFile,
  constructNonHeadingRowsOfDownloadedTableFile,
} from '../../../util/util'

import {
  isTruthyAndNonEmpty,
} from '../../../../../../utils'


export default props => {
  const {
    mostRecentlySubmittedFormValues,
  } = props
  if (!mostRecentlySubmittedFormValues) {
    return (
      <DownloadTableAsFile
        {...props}
        data={null}
        filename={null}
      />
    )
  }

  const filename = createFilename(mostRecentlySubmittedFormValues)
  const data = createFileData(props)

  return (
    <DownloadTableAsFile
      data={data}
      filename={filename}
    />
  )
}


/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

function createFilename(mostRecentlySubmittedFormValues) {
  const historyFormStartDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_START_DATE]
  const historyFormEndDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_END_DATE]

  return createFilenameOfDownloadedTableFile(
    'inventory',
    historyFormStartDate,
    historyFormEndDate,
  )
}


// array of arrays, with first array being column headings
function createFileData(props) {
  const {
    groupingsOfInventoryReportObjsForHistoryTable,
  } = props
  const definitions = createTableAndDownloadFileContentDefinitions({
    ...props,
    tableOrDownloadFile: 'downloadFile',
  })
  const dataDefinitions = definitions.content

  let sortedGroupingsOfInventoryReportObjsForHistoryTable
  // See CODE_COMMENTS_247 for why definitions.content might not be truthy
  if (isTruthyAndNonEmpty(dataDefinitions)) {
    const defaultColumnDefinitionToSortOn = dataDefinitions.find(o => o.heading === definitions.defaultSortColumn)
    let sortFunction = get_(defaultColumnDefinitionToSortOn, ['customSortInfo', 'sortFunction'])
    if (!sortFunction) {
      sortFunction = rows => orderBy_(rows, [defaultColumnDefinitionToSortOn.heading], ['desc'])
    }
    sortedGroupingsOfInventoryReportObjsForHistoryTable = sortFunction(
      groupingsOfInventoryReportObjsForHistoryTable,
    )
  } else {
    sortedGroupingsOfInventoryReportObjsForHistoryTable = groupingsOfInventoryReportObjsForHistoryTable
  }


  const nonHeadingRows = constructNonHeadingRowsOfDownloadedTableFile(
    sortedGroupingsOfInventoryReportObjsForHistoryTable,
    dataDefinitions,
  )

  return [
    dataDefinitions.map(o => o.heading), // headings row
    ...nonHeadingRows,
  ]
}
