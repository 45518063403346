import moment from 'moment'

import groupBy_ from 'lodash/groupBy'
import values_ from 'lodash/values'
import orderBy_ from 'lodash/orderBy'
import uniq_ from 'lodash/uniq'
import get_ from 'lodash/get'
import has_ from 'lodash/has'
import omit_ from 'lodash/omit'
import maxBy_ from 'lodash/maxBy'
import sortBy_ from 'lodash/sortBy'
import memoize_ from 'lodash/memoize'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import reduceFp_ from 'lodash/fp/reduce'
import groupByFp_ from 'lodash/fp/groupBy'
import valuesFp_ from 'lodash/fp/values'
import flattenFp_ from 'lodash/fp/flatten'
import sortByFp_ from 'lodash/fp/sortBy'
import entriesFp_ from 'lodash/fp/entries'


import {
  DEFAULT_API_DATE_FORMAT,
} from '../../constants/formAndApiUrlConfig/commonConfig'
import {
  API_INVENTORY_TYPE_DEFAULT_ORDER,
  API_INVENTORY_TYPE_TO_FIELD_LABEL_MAP,
} from '../../constants/formAndApiUrlConfig/reportInventory'

import {
  formatDateForApiCall,
  sortArrayByTemplateArray,
  getContainerTypesDefaultSortOrder,
} from '../../utils'


// a list of lists rather than a map because this also specifies the order of
// the columns of the download file.
const downloadFileHeadingNamesMap = [
  ['date', 'Date'],
  ['SizeId', 'Container Type'],
  ['reportedQuantity', 'Reported Qty'],
  ['calculatedQuantity', 'Calculated Qty'],
  ['differenceBetweenReportedAndCalculated', 'Difference'],
  ['TotalIn', 'Total In'],
  ['TotalOut', 'Total Out'],
  ['reportedInventoryReportId', 'Reported Inventory Report ID'],
  ['reportedQtysByInventoryTypeId', 'Reported Qtys by Type'],
]

// Returns an array of two objects [dataForChart, dataForDownloadTable]
export const formatDataForUseInChartAndDownloadFile = memoize_(
  // We memoize because this function does quite a bit and we only want to run
  // it once per customer (especially since this function is used by multiple
  // components, both the ReportedVsCalculatedInventory page and the Customer
  // portal card which shows the differences numbers by container type).
  ({ reportedVsCalculatedInventoryDataForCustomer }) => {
    let reportedInventory = combineInventoryTypesOfReportedInventory(
      reportedVsCalculatedInventoryDataForCustomer.reportedInventory,
    )
    // No need to filter out duplicate inventory reports (i.e. reports with the
    // same CountDate and SizeId), the backend does this for us. (Why does the
    // backend need to deduplicate? Because semi-regularly, customers will
    // report duplicate inventory by accident: two employees at the same Brewer
    // will both report inventory on the same day, not realizing their coworker
    // is reporting. The backend doesn't care which reports get filtered out and
    // which one remains [If the numbers are different, we can't know which
    // report is right], as long as only one remains.)

    // Convert dates, which are strings formatted like '2020-10-31', to unix
    // timestamps at Noon local time
    reportedInventory = reportedInventory.map(o => ({
      ...o,
      date: formatDateForApiCall({
        date: o.CountDate,
        customDateFormat: DEFAULT_API_DATE_FORMAT,
      }),
    }))
    let calculatedInventory = reportedVsCalculatedInventoryDataForCustomer.calculatedInventory.map(o => ({
      ...o,
      date: formatDateForApiCall({
        date: o.PostedDate,
        customDateFormat: DEFAULT_API_DATE_FORMAT,
      }),
    }))
    // Sort by date ascending
    reportedInventory = orderBy_(reportedInventory, ['date'], ['asc'])
    calculatedInventory = orderBy_(calculatedInventory, ['date'], ['asc'])

    // Separate by Container Type, which turns an array of objects into an
    // object whose keys are container types and whose values are arrays of
    // objects.
    reportedInventory = groupBy_(reportedInventory, 'SizeId')
    calculatedInventory = groupBy_(calculatedInventory, 'SizeId')

    // Group reported HB with calculated HB and reported SB with calculated SB
    let containerTypes = uniq_([
      ...Object.keys(reportedInventory),
      ...Object.keys(calculatedInventory),
    ])
    containerTypes = sortArrayByTemplateArray(containerTypes, getContainerTypesDefaultSortOrder())
    const groupedBySizeId = containerTypes.reduce(
      (acc, containerType) => ({
        ...acc,
        [containerType]: {
          // || [] in case there are no reportedInventory/calculatedInventory
          // reports
          reportedInventory: get_(reportedInventory, containerType) || [],
          calculatedInventory: get_(calculatedInventory, containerType) || [],
        },
      }),
      {},
    )

    const calculatedAndReportedCombined = Object.entries(groupedBySizeId).reduce(
      (
        acc,
        [
          containerType,
          {
            reportedInventory: reportedInventory_,
            calculatedInventory: calculatedInventory_,
          },
        ],
      ) => {
        const allDates = uniq_([...reportedInventory_, ...calculatedInventory_].map(o => o.date)).sort()
        const combinedReports = allDates.map(date => {
          const combinedObj = {}
          const reportedInventoryReport = reportedInventory_.find(o => o.date === date)
          const calculatedInventoryReport = calculatedInventory_.find(o => o.date === date)
          combinedObj.date = date
          if (reportedInventoryReport) {
            combinedObj.SizeId = reportedInventoryReport.SizeId
            combinedObj.reportedCountDate = reportedInventoryReport.CountDate
            combinedObj.reportedQuantity = reportedInventoryReport.Quantity
            combinedObj.reportedQtysByInventoryTypeId = reportedInventoryReport.qtysByInventoryTypeId
            combinedObj.reportedInventoryReportId = reportedInventoryReport.id
          }
          if (calculatedInventoryReport) {
            combinedObj.SizeId = calculatedInventoryReport.SizeId
            combinedObj.calculatedPostedDate = calculatedInventoryReport.PostedDate
            combinedObj.calculatedQuantity = calculatedInventoryReport.Quantity
            combinedObj.TotalIn = calculatedInventoryReport.TotalIn
            combinedObj.TotalOut = calculatedInventoryReport.TotalOut
          }
          combinedObj.differenceBetweenReportedAndCalculated = (
            reportedInventoryReport && calculatedInventoryReport
              ? Math.abs(reportedInventoryReport.Quantity - calculatedInventoryReport.Quantity)
              : null
          )
          return combinedObj
        })
        return {
          ...acc,
          [containerType]: combinedReports,
        }
      },
      {},
    )

    return [
      formatDataForUseInChart(calculatedAndReportedCombined),
      formatDataForUseInDownloadFile(calculatedAndReportedCombined),
    ]
  },
  ({
    reportedVsCalculatedInventoryDataForCustomer,
  }) => reportedVsCalculatedInventoryDataForCustomer,
)


// The data.reportedInventory in its original form is split by inventoryTypeId:

// [
//   {
//     "id": "6738883a-0d30-4197-a367-d92c7091c207",
//     "CustomerId": "17b14966-1eef-4867-ae6b-2b4b88e83562",
//     "CountDate": "2020-09-30",
//     "InventoryTypeId": "FUL",
//     "SizeId": "HB",
//     "Quantity": 1295
//   },
//   {
//     "id": "6738883a-0d30-4197-a367-d92c7091c207",
//     "CustomerId": "17b14966-1eef-4867-ae6b-2b4b88e83562",
//     "CountDate": "2020-09-30",
//     "InventoryTypeId": "EMP",
//     "SizeId": "HB",
//     "Quantity": 676
//   },
//   {
//     "id": "6738883a-0d30-4197-a367-d92c7091c207",
//     "CustomerId": "17b14966-1eef-4867-ae6b-2b4b88e83562",
//     "CountDate": "2020-09-30",
//     "InventoryTypeId": "OSW",
//     "SizeId": "HB",
//     "Quantity": 15
//   },
//   ...
// ]

// Combine all InventoryTypeId into one, grouping by both CountDate and SizeId.
// But instead of throwing away data the individual InventoryTypeId numbers, put
// them into their own prop:

// [
//   {
//     "id": "6738883a-0d30-4197-a367-d92c7091c207",
//     "CustomerId": "17b14966-1eef-4867-ae6b-2b4b88e83562",
//     "CountDate": "2020-09-30",
//     "SizeId": "HB",
//     "Quantity": 1986,
//     "qtysByInventoryTypeId": {
//       "FUL": 1295,
//       "EMP": 676,
//       "OSW": 15,
//     }
//   },
//   ...
// ]
function combineInventoryTypesOfReportedInventory(reportedInventory) {
  return flow_(
    groupByFp_('CountDate'),
    valuesFp_,
    mapFp_(arrayOfObjs => groupBy_(arrayOfObjs, 'SizeId')),
    mapFp_(o => values_(o)),
    reduceFp_(
      (acc, arrayOfTwoArrays) => ([
        ...acc,
        ...arrayOfTwoArrays.map(arrOfObjsGroupedByIdAndSize => ({
          id: arrOfObjsGroupedByIdAndSize[0].id,
          CustomerId: arrOfObjsGroupedByIdAndSize[0].CustomerId,
          CountDate: arrOfObjsGroupedByIdAndSize[0].CountDate,
          SizeId: arrOfObjsGroupedByIdAndSize[0].SizeId,
          Quantity: arrOfObjsGroupedByIdAndSize.reduce(
            (sum, o) => sum+o.Quantity,
            0,
          ),
          qtysByInventoryTypeId: arrOfObjsGroupedByIdAndSize.reduce(
            (acc_, o) => ({ ...acc_, [o.InventoryTypeId]: o.Quantity }),
            {},
          ),
        })),
      ]),
      [],
    ),
  )(reportedInventory)
}


// The formatting done by formatDataForUseInChartAndDownloadFile() gets us to a
// valid shape that can be used by recharts. However, there's a practical
// problem with the data: if the CountDate of a reported inventory report isn't
// on the last day of the month but is close to it--on the 1st, 2nd, 29th 28th,
// etc--the dots for that month on the Reported Line vs the Calculated line
// won't line up vertically in the chart (because the Calculated dots _always_
// fall on the last day of every month queried, a constraint enforced by the
// backend). It's important that the dots on the chart line up vertically,
// because only when they line up is a "Difference" value rendered (both in the
// chart's tooltip and in the data table below the chart). So in this function,
// we change the CountDate of inventory reports that are within 4 calendar days
// (either before or after) of the last day of the month, setting them to the
// last day of the month. Also, we collapse multiple Inventory Reports within
// that 9 day range into a single one, ignoring all but the latest one (this
// multiple-report phenomenon happens regularly with brewers who have CONBRWs
// brewing for them--the BRW reports their numbers on the 28th, then one CONBRW
// reports on the 1st then the other CONBRW reports on the 2nd; the backend will
// combine numbers from all three individual locations into the latest report,
// the one on the 2nd [even though it still returns the ones on the 28th and the
// 1st], so the front end follows suit here).
function formatDataForUseInChart(calculatedAndReportedCombined) {
  // 4 calendar days is just a best guess by the web app devs, this can be
  // increased or decreased as requested.
  const numDaysBeforeOrAfterLastDayOfMonthReportsShouldBeCombined = 4
  return flow_(
    entriesFp_,
    reduceFp_(
      (acc, [containerType, arrayOfCombinedReports]) => {
        let itemsToDeleteFromCombinedReports = []
        const itemsToAddToCombinedReports = []
        const allCalculatedReports = arrayOfCombinedReports.filter(
          o => has_(o, 'calculatedPostedDate'),
        )
        allCalculatedReports.forEach(calculatedReport => {
          const dateFenceLow = moment(calculatedReport.date)
          const dateFenceHigh = moment(calculatedReport.date)
          dateFenceLow.subtract(
            numDaysBeforeOrAfterLastDayOfMonthReportsShouldBeCombined,
            'days',
          ).startOf('day')
          dateFenceHigh.add(
            numDaysBeforeOrAfterLastDayOfMonthReportsShouldBeCombined,
            'days',
          ).endOf('day')
          const reportsNearThisDate = arrayOfCombinedReports.filter(combinedReport => {
            if (combinedReport === calculatedReport) {
              return false
            }
            const reportDateMoment = moment(combinedReport.date)
            return (
              reportDateMoment.isSameOrAfter(dateFenceLow)
              && reportDateMoment.isSameOrBefore(dateFenceHigh)
            )
          })
          if (reportsNearThisDate.length < 1) {
            return
          }
          const latestReport = maxBy_(reportsNearThisDate, 'date')
          // The easiest thing to do is just delete every single report near
          // this date, including the calculated report we got the
          // "reportsNearThisDate" date from. We re-create this calculated
          // report in the "replacementReport" below, combining it with the
          // latest reported report. This is the simplest code-wise and it also
          // accounts for multiple latest reported reports that have the same
          // date (the backend is supposed to only return one report per date,
          // but bugs happen and it might stop doing that at some point in the
          // future).
          const reportsToDelete = [...reportsNearThisDate, calculatedReport]
          const replacementReport = {
            ...latestReport,
            // order is important here: calculatedReport must come after
            // latestReport, because its props override latestReport's
            ...calculatedReport,
            reportedCountDate: calculatedReport.calculatedPostedDate,
            differenceBetweenReportedAndCalculated: (
              Math.abs(latestReport.reportedQuantity - calculatedReport.calculatedQuantity)
            ),
          }
          itemsToAddToCombinedReports.push(replacementReport)
          itemsToDeleteFromCombinedReports = [
            ...itemsToDeleteFromCombinedReports,
            ...reportsToDelete,
          ]
        })
        let newArrayOfCombinedReports = arrayOfCombinedReports.filter(o => (
          !itemsToDeleteFromCombinedReports.includes(o)
        ))
        newArrayOfCombinedReports = [
          ...newArrayOfCombinedReports,
          ...itemsToAddToCombinedReports,
        ]
        newArrayOfCombinedReports = sortBy_(newArrayOfCombinedReports, 'date')
        return {
          ...acc,
          [containerType]: newArrayOfCombinedReports,
        }
      },
      {},
    ),
  )(calculatedAndReportedCombined)
}


function formatDataForUseInDownloadFile(calculatedAndReportedCombined) {
  return flow_(
    // turn { HB: [...], SB: [...] } into [[...], [...]]
    valuesFp_,
    // turn [[...], [...]] into [...]
    flattenFp_,
    sortByFp_(o => o.date),
    // change 'date', which is a unix timestamp, to e.g. "2020-10-31" (which is
    // what 'calculatedPostedDate' and/or reportedCountDate are set to)
    mapFp_(o => ({ ...o, date: o.calculatedPostedDate || o.reportedCountDate })),
    // Now that 'date' is as we want it displayed, get rid of the
    // calculatedPostedDate and reportedCountDate props
    mapFp_(o => omit_(o, ['calculatedPostedDate', 'reportedCountDate'])),
    // Change reportedQtysByInventoryTypeId from an object:
    // {OSW: 0, FUL: 1614, EMP: 806, DFF: 146}
    // to a single string:
    // 'Full: 1614, Empty: 806, Defective: 146, Offsite Warehouse: 0'
    mapFp_(o => ({
      ...o,
      reportedQtysByInventoryTypeId: flow_(
        entriesFp_,
        a => sortArrayByTemplateArray(a, API_INVENTORY_TYPE_DEFAULT_ORDER, i => i[0]),
        mapFp_(([k, v]) => `${[API_INVENTORY_TYPE_TO_FIELD_LABEL_MAP[k]]}: ${v}`),
        arrayOfStrings => arrayOfStrings.join(', '),
      )(o.reportedQtysByInventoryTypeId),
    })),
    // Rename the keys (which will be the column headings of the download file)
    mapFp_(o => Object.entries(o).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [downloadFileHeadingNamesMap.find(a => a[0] === k)[1]]: v,
      }),
      {},
    )),
  )(calculatedAndReportedCombined)
}


// Return calculated inventory dates (calculated inventory dates will always
// fall on the last day of the month, which is, incidentally, how we want to lay
// out our x-axis)
export function getXAxisTicksFromDataForChartOld(dataForChart) {
  return Object.entries(dataForChart).reduce(
    (acc, [containerType, arr]) => ({
      ...acc,
      [containerType]: arr.reduce(
        (acc_, o) => {
          if (has_(o, 'calculatedPostedDate')) {
            return [
              ...acc_,
              o.date,
            ]
          }
          return acc_
        },
        [],
      ),
    }),
    {},
  )
}

// Return an object keyed by container types whose values are a list of Unix
// timestamps representing the last day of each month within the date range of
// the calculated inventory entries, e.g.:
//
// {
//   "HB": [
//       1669834800000, // Nov 30, 2022
//       1672513200000, // Dec 31, 2022
//       1675191600000, // Jan 31, 2023
//       1677610800000, // Feb 28, 2023
//       1680285600000, // Mar 31, 2023
//       1682877600000 // Apr 30, 2023
//   ],
//   "SB": [
//     1669834800000, // Nov 30, 2022
//     1672513200000, // Dec 31, 2022
//     1675191600000, // Jan 31, 2023
//     1677610800000, // Feb 28, 2023
//     1680285600000, // Mar 31, 2023
//     1682877600000 // Apr 30, 2023
//   ]
// }
//
// Calculated inventory dates will always include entries for the last day of
// each month within the date range covered, but they might also include
// additional dates such as the middle of the month (Dec 15, 2022); filter out
// such dates.
export function getXAxisTicksFromDataForChart(dataForChart) {
  return Object.entries(dataForChart).reduce(
    (acc, [containerType, arr]) => ({
      ...acc,
      [containerType]: arr.reduce(
        (acc_, o) => {
          if (has_(o, 'calculatedPostedDate')) {
            return [
              ...acc_,
              ...(
                getDoesUnixTimestampFallOnLastDayOfMonth(o.date)
                  ? [o.date]
                  : []
              ),
            ]
          }
          return acc_
        },
        [],
      ),
    }),
    {},
  )
}


export function convertQuantityLabelToDisplayedLabel(s) {
  if (s === 'reportedQuantity') {
    return 'Reported'
  }
  if (s === 'calculatedQuantity') {
    return 'Calculated'
  }
  return s
}


export function formatDateForTruncatedDisplay({
  unixTime,
  doesDayComeBeforeMonthInLocaleDateStringFormat,
  includeYear,
}) {
  return moment(unixTime).format(
    doesDayComeBeforeMonthInLocaleDateStringFormat
      ? `DD/M${includeYear ? '/YY' : ''}`
      : `M/DD${includeYear ? '/YY' : ''}`,
  )
}

function getDoesUnixTimestampFallOnLastDayOfMonth(unixTime) {
  const convertedToDate = moment(unixTime)
  const dayOfMonth = convertedToDate.format('DD')
  const lastDayOfMonth = convertedToDate.endOf('month').format('DD')
  return dayOfMonth === lastDayOfMonth
}
