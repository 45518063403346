import React from 'react'

import { useTranslation } from 'react-i18next'
import InformationalPopup from '../../../../../../../common-components/InformationalPopup'
import RevealContactInfo from '../../../../../../../common-components/RevealContactInfo'
import TextAsPseudoLink from '../../../../../../../common-components/TextAsPseudoLink'


export default ({
  handleReturnToPreviousScreen,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <InformationalPopup
      includeTriggerIcon={false}
      includeTriggerText
      triggerText={translate('I never received a confirmation code')}
      popupProps={{ on: 'click' }}
      content={(
        <div>
          <p>
            <TextAsPseudoLink
              onClick={handleReturnToPreviousScreen}
            >
              {translate('Click here')}
            </TextAsPseudoLink>
            {' '}
            {translate('to return to the previous page. There you can re-enter your email address and another code will be sent to you. If the problem persists, contact us.')}
          </p>
          <RevealContactInfo />
        </div>
      )}
    />
  )
}
