/**
 * There are several components which need the same single piece of information
 * from the store: "is the user authenticated?" Instead of writing multiple
 * nearly-identical container components for each of these, we write one wrapper
 * component here.
 */
import { connect } from 'react-redux'

import { getIsAuthenticated } from '../../../../redux/selectors/authentication'

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: getIsAuthenticated(state),
  ...ownProps,
})

export default component => (
  connect(mapStateToProps)(component)
)
