/**
 * How is this different from InformationalPopup?
 * 1) The popup content should be simple text only;
 * 2) The popup is styled with a black opaque background, as used on the
 *    edit/delete Individual Contact icons on the Manage Contacts page.
 */

import React from 'react'

import PopupWithCatastrophicFailureErrorBoundary from '../semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'

export default ({
  trigger,
  content,
  ...restPpopupProps
}) => {
  const {
    style,
    ...rest
  } = restPpopupProps
  return (
    <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      position="top center"
      hoverable
      on='hover'
      size='mini'
      inverted
      basic
      style={{
        opacity: 0.6,
        padding: '0.3em',
        // By default, there's a little too much vertical space between the
        // mouse pointer and the popup; because the popup is size='mini', the
        // vertical space needs to decrease in step from a normal sized popup.
        marginBottom: 0,
        ...(style || {}),
      }}
      trigger={trigger}
      content={content}
      {...rest}
    />
  )
}
