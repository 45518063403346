import { fork, take, cancel } from 'redux-saga/effects'

// Transforms a non-cancellable saga into a cancelable saga. Saga will be
// cancelled when one of the action types fires in the passed-in actionTypes
// array. See CODE_COMMENTS_11 for a practical example of when this comes in
// handy.
export function transformSagaIntoCancellableSagaOnTheseActionTypes(saga, actionTypes) {
  return function* cancellableSagaWrapper(action) {
    const cancellableSaga = yield fork(saga, action)
    // The use of 'fork` in the line above allows cancelability. See
    yield take(actionTypes)
    yield cancel(cancellableSaga)
  }
}


export const cancelableForkedSaga = actionsThatShouldCancelThisFork => wrappedSaga => (
  function* replacementSaga(action) {
    const cancelableSaga = yield fork(
      wrappedSaga,
      action,
    )
    // The use of 'fork` in the line above allows cancelability: If the user logs
    // out before the app has finished fetching preliminary customer info, cancel
    // the prelim-info fetching process (if we don't cancel it, the fetching
    // process will continue while the user is on the /login page, adding
    // customers to the Redux store while the user is logged out!). NOTE: it may
    // not be possible for an AUTHENTICATION_FAILURE action to be dispatched
    // during prelim info fetching, but we include it just in case (doesn't hurt
    // anything for it to be here).
    yield take([actionsThatShouldCancelThisFork])
    yield cancel(cancelableSaga)
  }
)
