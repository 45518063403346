/**
 * CODE_COMMENTS_131
 */
import { connect } from 'react-redux'


import CatastrophicFailureComponentBoundary from './components/CatastrophicFailureComponentBoundary'

import createAction from '../../../redux/actions/createAction'
import { SET_CATASTROPHIC_FAILURE_HAPPENED } from '../../../redux/actions/actionTypes'


const mapDispatchToProps = dispatch => ({
  setCatastrohpicFailureHappened: (error, info) => {
    dispatch(createAction(
      SET_CATASTROPHIC_FAILURE_HAPPENED,
      {
        error,
        // info is an object which usually only contains one prop,
        // `componentStack`, a string listing every React Component in the
        // Component stack.
        info,
      },
    ))
  },
})

export default connect(
  undefined,
  mapDispatchToProps,
)(CatastrophicFailureComponentBoundary)
