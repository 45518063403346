/**
 * CODE_COMMENTS_93
 */
import { connect } from 'react-redux'
import values_ from 'lodash/values'


import Table from './components/Table'

import {
  getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
  getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
} from '../../util/shipmentHistory/outboundFullKegShipmentHistory'

import {
  getEntireSlice as getAllOutboundFullKegShipmentHistoryObjects,
} from '../../../../../redux/selectors/histories/outboundFullKegShipmentHistory'
import {
  getEntireSlice as getAllNoMovementsHistoryObjects,
} from '../../../../../redux/selectors/histories/noMovementsHistory'
import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../../redux/selectors/permissions'
import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/forms'

import {
  SHIPMENT_STATUS_PENDING,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_FILLS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_FILLS,
} from '../../../../../constants/permissions'

import {
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
} from '../../../../../constants'

import {
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


const mapStateToProps = (state, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    formReducerName,
  } = ownProps
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')

  const formName = createFormNameForRedux({
    reducerName: formReducerName,
    customerId,
  })

  const { getFetchStatuses } = fetchStatusSelectors
  const { isFetching, didFetchFail } = getFetchStatuses(state, formName)

  const areAnyItemsEditable = getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId)

  const allOutboundFullKegShipmentObjects = getAllOutboundFullKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  const allNoMovementsObjects = getAllNoMovementsHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  return {
    entireItemSkusSlice,
    allOutboundFullKegShipmentObjects,
    allNoMovementsObjects,
    configuredItemSkuIds,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    isFormSubmitting: isFetching,
    hasFormSubmitFailed: didFetchFail,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
    ...ownProps,
  }
}

export default connect(
  mapStateToProps,
)(Table)


function getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId) {
  if (
    // CODE_COMMENTS_153
    !getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_FILLS,
    }) ||
    !getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_FILLS,
    })
  ) { return false }

  const allOutboundFullKegShipmentObjects = getAllOutboundFullKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  const allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    values_(getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable(
      allOutboundFullKegShipmentObjects,
    ))
  const allObjsInTable = allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable.filter(
    getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
  )
  return allObjsInTable.some(row => getIsindividualItemEditable(state, row))
}

function getIsindividualItemEditable(state, row) {
  // only pending shipments are allowed to be edited
  return row.status === SHIPMENT_STATUS_PENDING
}
