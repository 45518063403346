import React from 'react'

import ReportInventoryComponent from './containers'

export default props => {
  const {
    // itemObj is an object with 3 properties: countDate, INVENTORY_REPORT_OBJS,
    // and CUSTOMER_TYPE_FOR_REPORT_INVENTORY. These props need to be passed to
    // <ReportInventoryComponent> individually.
    itemObj: row,
    ...rest
  } = props
  return (
    <ReportInventoryComponent
      isEditForm
      {...row}
      {...rest}
    />
  )
}
