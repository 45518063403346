import { call, put, select } from 'redux-saga/effects'
import { compose } from 'recompose'

import get_ from 'lodash/get'


import {
  addAuthHeaderToFetchHeaders,
  addOperateAsCustomerUserHeaderToFetchHeaders,
  getIsThisASafeHttpCall,
  getDoesThisCallUseTimeoutPolling,
  getUniversalErrorReactions,
} from './'

import {
  ENABLE_LOGOUT_BUTTON,
  DISABLE_LOGOUT_BUTTON,
} from '../../../actions/actionTypes'

import {
  getIsEmployeeUserLoggedInRatherThanCustomerUser,
} from '../../../selectors/currentUserEmployee'
import {
  getEntireSlice as getEntireCurrentCustomerUserSlice,
} from '../../../selectors/currentUser'

import {
  isTruthyAndNonEmpty,
} from '../../../../utils'


// Does 2 things:
// 1. adds the authentication header to the fetch's 'headers' prop, turning a
//    public fetch saga into a private fetch saga.
// 2. passes in the 'isPrivateFetch: true' prop to the wrappedSaga.
export const convertPublicFetchIntoPrivateFetch = wrappedSaga => (
  function* replacementSaga(props) {
    // Notice that 'headers' is a direct child key of 'props'. Make sure that
    // the saga you wrap with this has a single arg which is an object and that
    // 'headers' as a key of that object.
    const { headers } = props
    const headersWithAuthHeader = yield call(addAuthHeaderToFetchHeaders, headers)
    const response = yield call(
      wrappedSaga, {
        ...props,
        headers: headersWithAuthHeader,
        isPrivateFetch: true,
      })
    return response
  }
)

// CODE_COMMENTS_221: Adds the Operate-as-customer-user header to the fetch if
// necessary.
export const withOperateAsCustomerUserHeader = wrappedSaga => (
  function* replacementSaga(props) {
    const {
      headers,
      setOperateAsCustomerUserHeaderIfEmployeeIsLoggedIn=true,
    } = props
    const isEmployeeLoggedIn = yield select(getIsEmployeeUserLoggedInRatherThanCustomerUser)
    const entireCurrentCustomerUserSlice = yield select(getEntireCurrentCustomerUserSlice)
    const hasACustomerUserToOperateForBeenChosen = isTruthyAndNonEmpty(entireCurrentCustomerUserSlice)

    const newHeaders = (
      isEmployeeLoggedIn
      && setOperateAsCustomerUserHeaderIfEmployeeIsLoggedIn
      && hasACustomerUserToOperateForBeenChosen
    )
      ? yield call(addOperateAsCustomerUserHeaderToFetchHeaders, headers)
      : headers

    const response = yield call(
      wrappedSaga, {
        ...props,
        headers: newHeaders,
        isPrivateFetch: true,
      })
    return response
  }
)


// adds the baseURL prop to the fetch's config.
export const withBaseUrl = wrappedSaga => (
  function* replacementSaga(props) {
    const { config } = props

    // set the baseURL of the axios call
    const configWithBaseUrl = { ...config, baseURL: process.env.REACT_APP_API_ROOT }

    const response = yield call(wrappedSaga, { ...props, config: configWithBaseUrl })
    return response
  }
)


// adds universal error reactions to the errorReactions prop
export const withUniversalErrorReactions = wrappedSaga => (
  function* replacementSaga(props) {
    const {
      config,
      errorReactions=[],
      isPrivateFetch,
    } = props
    const doesThisCallUseTimeoutPolling = yield call(getDoesThisCallUseTimeoutPolling, config)

    const universalErrorReactions = getUniversalErrorReactions({
      doesThisCallUseTimeoutPolling,
      isPrivateFetch,
    })
    // add universal error reactions
    const universalErrorReactionsThatMustComeBeforeCustomErrorReactions = universalErrorReactions.filter(o => get_(o, ['doesThisReactionNeedToComeBeforeAnyCustomErrorReactions'])) // CODE_COMMENTS_256
    const universalErrorReactionsThatCanComeAfterCustomErrorReactions = universalErrorReactions.filter(o => !get_(o, ['doesThisReactionNeedToComeBeforeAnyCustomErrorReactions']))
    const customErrorReactionsThatShouldComeBeforeUniversalErrorReactions = errorReactions.filter(o => get_(o, ['shouldThisErrorReactionBeCalledBeforeAnyUniversalErrorReactionsSoAsToAvoidUnnecessaryRefetches']))
    const customErrorReactionsThatCanComeAfterUniversalErrorReactions = errorReactions.filter(o => !get_(o, ['shouldThisErrorReactionBeCalledBeforeAnyUniversalErrorReactionsSoAsToAvoidUnnecessaryRefetches']))
    const allErrorReactions = [
      ...universalErrorReactionsThatMustComeBeforeCustomErrorReactions,
      ...customErrorReactionsThatShouldComeBeforeUniversalErrorReactions,
      ...universalErrorReactionsThatCanComeAfterCustomErrorReactions,
      ...customErrorReactionsThatCanComeAfterUniversalErrorReactions,
    ]

    const response = yield call(wrappedSaga, { ...props, errorReactions: allErrorReactions })
    return response
  }
)


// The user is not allowed to log out during the fetch if this fetch changes
// the backend. The thinking here is that the web app gives real-time
// feedback about changes to the backend (the order was created, the
// shipment was reported, etc.) which is very important for the user to see.
// This feedback is so important that we assume the user will never
// intentionally log out during the middle of a call. If the logout button
// is clicked during the fetch, we assume it was an accident and don't
// actually log the user out.
export const withDisableLogoutWrapperIfNecessary = wrappedSaga => (
  function* replacementSaga(props) {
    const { config } = props
    const isThisASafeHttpCall = getIsThisASafeHttpCall(config)

    if (!isThisASafeHttpCall) {
      yield put({ type: DISABLE_LOGOUT_BUTTON })
      let toReturn
      try {
        toReturn = yield call(wrappedSaga, props)
      } catch (error) {
        yield put({ type: ENABLE_LOGOUT_BUTTON })
        throw error
      }
      yield put({ type: ENABLE_LOGOUT_BUTTON })
      return toReturn
    }
    // if this _is_ a safe HTTP call
    const toReturn = yield call(wrappedSaga, props)
    return toReturn
  }
)

// CODE_COMMENTS_119
export const defaultFetchMiddleware = compose(
  // Order isn't important here because none of these higher order sagas depends
  // on the changes made by the others.
  withBaseUrl,
  withUniversalErrorReactions,
  withDisableLogoutWrapperIfNecessary,
)
