// CODE_COMMENTS_244

/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file, see CODE COMMENTS 50

import { put, call, select, takeEvery } from 'redux-saga/effects'


import { privateFetch } from '../fetch'


import {
  FETCH_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
  SAVE_MULTIPLE_SHIPMENTS_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
} from '../../actions/actionTypes'

import {
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../selectors/rewrite/contracts'
import {
  getMultipleRelationshipPropsOfAllRelatedTo,
} from '../../selectors/rewrite/relationships/relatedToInfo'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../selectors/rewrite/relationships/relatedToOrFromInfo'

import {
  URL_PARAM_ORIGIN_CUSTOMER_ID,
  URL_PARAM_DESTINATION_CUSTOMER_ID,
  URL_PARAM_SHIPPED_START_DATE,
  URL_PARAM_SHIPPED_END_DATE,
} from '../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/shipments'

import {
  SHIPMENT_STATUS_CANCELED,
} from '../../../constants/formAndApiUrlConfig/commonConfig'

import {
  API_URL_PATH_SHIPMENTS_HISTORY,
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../constants'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  getDateRangeOfPotentialDuplicates,
} from '../../../utils/reportShipmentsFormsPotentialDuplicates'

import {
  isTruthyAndNonEmpty,
  formatDateRangeForApiCall,
} from '../../../utils'

function* fetchReportShipmentsFormsPotentialDuplicates(action) {
  yield call(fetchShipments, { ...action.payload, inboundRatherThanOutbound: false })
  yield call(fetchShipments, { ...action.payload, inboundRatherThanOutbound: true })
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES, fetchReportShipmentsFormsPotentialDuplicates],
]


function* fetchShipments({
  customerId,
  operatingContractBrewerCustomerId,
  inboundRatherThanOutbound,
}) {
  const fetchConfig = {
    path: API_URL_PATH_SHIPMENTS_HISTORY,
    params: createFetchParams({ customerId, inboundRatherThanOutbound }),
  }

  // CODE_COMMENTS_92
  if (operatingContractBrewerCustomerId) {
    const headers = createHeadersForContracteeBrewerApiCall(customerId)
    fetchConfig.headers = headers
  }

  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) { // no shipments
        return
      }
      yield call(doFailure, customerId, error)
      return
    }
    yield call(doFailure, customerId, error)
    return
  }
  yield call(doSuccess, customerId, inboundRatherThanOutbound, response.data)
}

function* doSuccess(customerId, inboundRatherThanOutbound, arrayOfShipmentObjs) {
  const filteredShipments = arrayOfShipmentObjs.filter(o => o.status !== SHIPMENT_STATUS_CANCELED)
  if (isTruthyAndNonEmpty(filteredShipments)) {
    const arrays = yield call(
      separateArrayOfShipmentObjsByContract,
      customerId,
      inboundRatherThanOutbound,
      filteredShipments,
    )
    // Why for of instead of forEach? CODE_COMMENTS_50
    for (const arrayByConbrw of arrays) {
      const {
        contractBrewerCustomerId,
        separatedArrayOfShipmentObjs,
      } = arrayByConbrw
      yield put(
        createAction(
          SAVE_MULTIPLE_SHIPMENTS_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
          {
            customerId,
            operatingContractBrewerCustomerId: contractBrewerCustomerId,
            arrayOfShipmentObjs: separatedArrayOfShipmentObjs,
          },
        ),
      )
    }
  }
}

function* doFailure(error) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
}


function createFetchParams({
  customerId,
  inboundRatherThanOutbound,
}) {
  const urlParamOriginOrDestinationCustomerId = inboundRatherThanOutbound
    ? URL_PARAM_DESTINATION_CUSTOMER_ID
    : URL_PARAM_ORIGIN_CUSTOMER_ID

  const {
    startDate: startDateMoment,
    endDate: endDateMoment,
  } = getDateRangeOfPotentialDuplicates()
  const { startDate, endDate } = formatDateRangeForApiCall({
    startDate: startDateMoment,
    endDate: endDateMoment,
  })

  return {
    [urlParamOriginOrDestinationCustomerId]: customerId,
    [URL_PARAM_SHIPPED_START_DATE]: startDate,
    [URL_PARAM_SHIPPED_END_DATE]: endDate,
  }
}

// Let's say a BRW logs in who doesn't brew for themselves but has two CONBRWs
// brewing for it. The array of shipments we get back needs to be saved to the
// redux store in different ways depending on which CONBRW the shipment came
// from. That's why this function exists. It returns an array of objects shaped
// like this:

// [
//   {
//     contractBrewerCustomerId: null, // null means default BRW contract
//     separatedArrayOfShipmentObjs: [<shipmentObj>, <shipmentObj>, ...],
//   },
//   {
//     contractBrewerCustomerId: 'm1234'
//     separatedArrayOfShipmentObjs: [<shipmentObj>, <shipmentObj>, ...],
//   },
// ]
function* separateArrayOfShipmentObjsByContract(
  customerId,
  inboundRatherThanOutbound,
  arrayOfShipmentObjs,
) {
  const state = yield select()
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const conbrwsRelatedTo = getMultipleRelationshipPropsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    propNames: ['destinationCustomerId', 'sourcePpfContract'],
    onlyRelationshipsThatShipmentsCanBeReportedOn: false, // CODE_COMMENTS_112
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
  })

  const contractPropNameOnShipmentObj = inboundRatherThanOutbound
    ? 'destinationContractId'
    : 'originContractId'

  return arrayOfShipmentObjs.reduce(
    (acc, shipment) => {
      const targetConbrw = conbrwsRelatedTo.find(o => o.sourcePpfContract === shipment[contractPropNameOnShipmentObj])
      if (targetConbrw) {
        const objInAccToAddThisShipmentTo = acc.find(o => (
          o.contractBrewerCustomerId === targetConbrw.destinationCustomerId
        ))
        if (objInAccToAddThisShipmentTo) {
          objInAccToAddThisShipmentTo.separatedArrayOfShipmentObjs.push(shipment)
        } else {
          acc.push({
            contractBrewerCustomerId: targetConbrw.destinationCustomerId,
            separatedArrayOfShipmentObjs: [shipment],
          })
        }
      } else {
        const objInAccToAddThisShipmentTo = acc.find(o => (
          o.contractBrewerCustomerId === null
        ))
        if (objInAccToAddThisShipmentTo) {
          objInAccToAddThisShipmentTo.separatedArrayOfShipmentObjs.push(shipment)
        } else {
          acc.push({
            contractBrewerCustomerId: null,
            separatedArrayOfShipmentObjs: [shipment],
          })
        }
      }
      return acc
    },
    [],
  )
}
