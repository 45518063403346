import Immutable from 'seamless-immutable'

import {
  SAVE_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER,
  DELETE_SPECIFIC_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER,
} from '../actions/actionTypes'

import {
  withoutInMultiple,
} from './util/seamlessImmutable'

import {
  convertArrayOfObjectsToObjectOfSubObjects,
} from '../../utils'

const initialState = Immutable({ byCustomerId: {} })

export default function inboundUnacknowledgedKegShipmentsReducer(state=initialState, action) {
  switch (action.type) {
    case SAVE_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER: {
      const { customerId, info } = action.payload
      return (
        Immutable.setIn(
          state,
          ['byCustomerId', customerId],
          convertArrayOfObjectsToObjectOfSubObjects(info, 'id'),
        )
      )
    }

    // usually performed after a user acknowledges keg shipments when
    // the user clicks "Fill out form again"
    case DELETE_SPECIFIC_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER: {
      const { customerId, shipmentIds } = action.payload
      const fullPathsToDelete = shipmentIds.map(shipmentId => (
        ['byCustomerId', customerId, shipmentId]
      ))
      return withoutInMultiple(state, fullPathsToDelete)
    }

    default:
      return state
  }
}
