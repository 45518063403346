import { put, call, takeEvery } from 'redux-saga/effects'


import { publicFetch, privateFetch } from '../fetch'

import createAction from '../../actions/createAction'

import {
  FETCH_ITEM_SKUS,
  SAVE_ITEM_SKUS,
  FETCH_ITEM_SKUS_REQUEST,
  FETCH_ITEM_SKUS_SUCCESS,
  FETCH_ITEM_SKUS_FAILURE,
} from '../../actions/actionTypes'

import {
  API_URL_PATH_ITEM_SKUS,
  API_URL_PATH_ITEM_SKUS_DIST_INVENTORY_TOKEN,
} from '../../../constants'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'

import {
  createApiTimedTokenHttpHeader,
} from '../../../utils'


export function* fetchItemSkus(action) {
  const {
    preventsDashboardFromLoadingIfFails,
    isDistReportInventoryNoLoginRequired,
    isDistReportConstellationNoLoginRequired,
    token: tokenForDistReportInventoryNoLoginRequired,
  } = action.payload
  yield put(createAction(FETCH_ITEM_SKUS_REQUEST))
  let response
  const requestHeaders = (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
    ? createApiTimedTokenHttpHeader(tokenForDistReportInventoryNoLoginRequired)
    : {}
  try {
    response = yield call(
      (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) ? publicFetch : privateFetch,
      {
        path: (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
          ? API_URL_PATH_ITEM_SKUS_DIST_INVENTORY_TOKEN
          : API_URL_PATH_ITEM_SKUS,
        headers: {
          ...requestHeaders,
          'accept-language': localStorage.getItem('lang') || 'en-US', // default is en-US
        },
      },
    )
  } catch (error) {
    if (error.response && error.response.status === 404) {
      response = { data: [] }
    } else {
      yield call(
        doFailure,
        {
          error,
          preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
        },
      )
      return
    }
  }
  yield call(
    doSuccess,
    response.data,
  )
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_ITEM_SKUS, fetchItemSkus],
]


function* doSuccess(itemSkus) {
  yield put(createAction(SAVE_ITEM_SKUS, { info: itemSkus }))
  yield put(createAction(FETCH_ITEM_SKUS_SUCCESS)) // CODE_COMMENTS_20
}


function* doFailure({ error, preventsDashboardFromLoading }) {
  yield call(
    generalDoFailure,
    {
      error,
      preventsDashboardFromLoading,
      action: createFetchFailureAction({
        error,
        type: FETCH_ITEM_SKUS_FAILURE,
      }),
    },
  )
}
