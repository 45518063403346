import { connect } from 'react-redux'

import DeleteHistoryItemButton from './components'

import fetchStatusSelectors from '../../../../../../redux/selectors/fetchStatuses/forms'

import createAction from '../../../../../../redux/actions/createAction'

import {
  FETCH_DELETE_KEG_ORDER,
  FETCH_DELETE_COLLAR_ORDER,
  FETCH_DELETE_REPORTED_KEG_SHIPMENT,

  SAVE_SINGLE_KEG_ORDER_HISTORY_ITEM,
  SAVE_SINGLE_COLLAR_ORDER_HISTORY_ITEM,
  SAVE_SINGLE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM,
  SAVE_SINGLE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM,
} from '../../../../../../redux/actions/actionTypes'

import {
  KEG_ORDER_STATUS_CANCELED,
} from '../../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  REDUCER_NAMES_FORMS_DELETE_HISTORY_ITEM,
} from '../../../../../../constants'

import {
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../../utils'


// Helper Functions

const getUiProps = ({ itemType, itemObj }) => {
  let humanReadableItemId
  let deleteHistoryItemAction
  let itemIdIfThisIsForDeletingAnItem
  let setItemAsCanceledRatherThanDeletingFromStore
  let statusKey
  let reduxStoreItemDeleteAction
  let buttonText
  let successMessage

  switch (itemType) {
    case 'keg order': {
      humanReadableItemId = itemObj.orderId
      deleteHistoryItemAction = FETCH_DELETE_KEG_ORDER
      reduxStoreItemDeleteAction = SAVE_SINGLE_KEG_ORDER_HISTORY_ITEM
      setItemAsCanceledRatherThanDeletingFromStore = true
      statusKey = 'status'
      itemIdIfThisIsForDeletingAnItem = itemObj.id
      buttonText = 'Cancel'
      successMessage = 'Your keg order has been canceled.'
      break
    }
    case 'keg collar order': {
      humanReadableItemId = itemObj.collarOrderIdNumber
      deleteHistoryItemAction = FETCH_DELETE_COLLAR_ORDER
      reduxStoreItemDeleteAction = SAVE_SINGLE_COLLAR_ORDER_HISTORY_ITEM
      setItemAsCanceledRatherThanDeletingFromStore = true
      statusKey = 'collarOrderStatus'
      itemIdIfThisIsForDeletingAnItem = itemObj.id
      buttonText = 'Cancel'
      successMessage = 'Your collar order has been canceled.'
      break
    }
    case 'inbound empty keg shipment': {
      humanReadableItemId = itemObj.shipmentId
      deleteHistoryItemAction = FETCH_DELETE_REPORTED_KEG_SHIPMENT
      reduxStoreItemDeleteAction = SAVE_SINGLE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM
      setItemAsCanceledRatherThanDeletingFromStore = true
      statusKey = 'status'
      itemIdIfThisIsForDeletingAnItem = itemObj.id
      buttonText = 'Cancel'
      successMessage = 'Your shipment report has been canceled.'
      break
    }
    case 'keg fill report':
    case 'outbound full keg shipment': {
      humanReadableItemId = itemObj.shipmentId
      deleteHistoryItemAction = FETCH_DELETE_REPORTED_KEG_SHIPMENT
      reduxStoreItemDeleteAction = SAVE_SINGLE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM
      setItemAsCanceledRatherThanDeletingFromStore = true
      statusKey = 'status'
      itemIdIfThisIsForDeletingAnItem = itemObj.id
      buttonText = 'Cancel'
      successMessage = 'Your shipment report has been canceled.'
      break
    }

    default: break
  }

  return {
    buttonText,
    humanReadableItemId,
    deleteHistoryItemAction,

    // needed by the form submission dimmer overlay
    reduxStoreItemDeleteAction,
    setItemAsCanceledRatherThanDeletingFromStore,
    statusKey,
    itemIdIfThisIsForDeletingAnItem,
    successMessage,
  }
}


const mapStateToProps = (state, ownProps) => {
  const {
    itemObj,
    itemType,
    customerId,
    operatingContractBrewerCustomerId,
  } = ownProps

  // CODE_COMMENTS_197
  const formName = createFormNameForRedux({
    reducerName: REDUCER_NAMES_FORMS_DELETE_HISTORY_ITEM,
    customerId,
    operatingContractBrewerCustomerId,
    additionalIdentifier: itemObj.id,
  })

  const uiProps = getUiProps({ itemType, itemObj })

  const { getFetchStatuses } = fetchStatusSelectors

  const {
    // The form submission dimmer overlay needs these precisely-named fetch status
    // variables
    isFetching: isSubmitting,
    didFetchSucceed: hasSubmitSucceeded,
    didFetchFail: hasSubmitFailed,
  } = getFetchStatuses(state, formName)

  return {
    itemType,
    ...uiProps,

    // needed by the form submission dimmer overlay HOC
    form: formName,
    isSubmitting,
    hasSubmitSucceeded,
    hasSubmitFailed,
  }
}

const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    itemObj,
  } = ownProps
  const {
    deleteHistoryItemAction,
    reduxStoreItemDeleteAction,
    setItemAsCanceledRatherThanDeletingFromStore,
    statusKey,
    itemIdIfThisIsForDeletingAnItem,
  } = stateProps
  const { dispatch } = dispatchProps

  // It's important that this function is called 'onSubmit' exactly, because
  // that's what the form submission dimmer overlay HOC expects (it offers the
  // user the chance to call this again if the submission fails)
  const onSubmit = () => dispatch(
    createAction(
      deleteHistoryItemAction,
      {
        customerId,
        operatingContractBrewerCustomerId,
        itemObj,
      },
    ),
  )

  // CODE_COMMENTS_69
  const setItemStatusAsCanceled = () => dispatch(createAction(
    reduxStoreItemDeleteAction,
    {
      savePath: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
      info: {
        ...itemObj,
        // This works because all object types (keg orders, collar orders,
        // shipments, etc.) get set to the same status when the user "deletes"
        // them. Therefore we could have used SHIPMENT_STATUS_CANCELED or
        // COLLAR_ORDER_STATUS_CANCELED instead of KEG_ORDER_STATUS_CANCELED.
        [statusKey]: KEG_ORDER_STATUS_CANCELED,
      },
    }))

  const deleteHistoryItemFromStore = () => dispatch(createAction(
    reduxStoreItemDeleteAction,
    {
      savePath: [customerId],
      itemId: itemIdIfThisIsForDeletingAnItem,
    }))

  const onModalCloseAfterSuccessfulSubmit = setItemAsCanceledRatherThanDeletingFromStore
    ? setItemStatusAsCanceled
    : deleteHistoryItemFromStore

  return {
    ...ownProps,
    ...stateProps,
    onSubmit,
    onModalCloseAfterSuccessfulSubmit,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DeleteHistoryItemButton)
