import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import get_ from 'lodash/get'


import FetchFailureContent from '../../FetchFailureContent'
import RevealContactInfo from '../../RevealContactInfo'
import FetchFailureUnknownErrorContent from './FetchFailureUnknownErrorContent'


import fetchStatusSelectors from '../../../redux/selectors/fetchStatuses/publicForms'

import DismissableMessageClearingFetchStatus from './DismissableMessageClearingFetchStatus'

import {
  DISPLAYED_ERROR_MESSAGES_FETCH_NO_RESPONSE_CODE,
  API_MAINTENANCE_HTTP_ERROR_CODES,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
  extractDateRangeFromString,
} from '../../../utils'


const { getFetchFailureErrorDetails } = fetchStatusSelectors

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps
  let { customFailureRenderInstructions } = ownProps
  // eslint-disable-next-line no-param-reassign
  if (!customFailureRenderInstructions) { customFailureRenderInstructions = [] }

  const instructions = {
    renderWhenTrue: [
      ...customFailureRenderInstructions,
      {
        when: ({ errorCode }) => !errorCode,
        render: () => (
          <DismissableMessageClearingFetchStatus
            formName={formName}
            content={
              <div>
                <p>{DISPLAYED_ERROR_MESSAGES_FETCH_NO_RESPONSE_CODE}</p>
                <RevealContactInfo
                  asPopup
                />
              </div>
            }
          />
        ),
      },
      {
        when: ({ errorCode }) => API_MAINTENANCE_HTTP_ERROR_CODES.includes(errorCode),
        render: ({ errorDetails }) => {
          const maintenanceModeDurationString = get_(errorDetails, 'responseBody.messageDetail')
          const startAndEndTimesFrom503 = maintenanceModeDurationString
            ? extractDateRangeFromString({ str: maintenanceModeDurationString })
            : null

          let endTimeString
          if (isTruthyAndNonEmpty(startAndEndTimesFrom503)) {
            const endMomentFrom503 = moment(startAndEndTimesFrom503.end)
            const endMoment = moment().isBefore(endMomentFrom503) ? endMomentFrom503 : null
            if (endMoment) {
              // https://stackoverflow.com/a/29226671/6995996 - If the user is in
              // California, we want to display e.g. "10/28/2019 6:00pm PST", but if
              // they're in New York we want to display "10/28/2019 9:00pm EST"
              const endMomentLocal = endMoment.tz(moment.tz.guess())
              endTimeString = endMomentLocal.format('ddd. M/D/YYYY h:mma z')
            }
          }
          return (
            <DismissableMessageClearingFetchStatus
              formName={formName}
              content={
                <div>
                  <p>
                    {'TAP3 is undergoing scheduled maintenance '}
                    {
                      endTimeString
                        ? <span style={{ fontWeight: 'bold' }}>until {endTimeString}</span>
                        : 'for the next few hours'
                    }
                    {', so all features, including login, are currently disabled.'}
                  </p>
                  <RevealContactInfo
                    asPopup
                  />
                </div>
              }
            />
          )
        },
      },
    ],
    renderWhenNoneTrue: FetchFailureUnknownErrorContent,
  }

  const errorDetails = getFetchFailureErrorDetails(state, formName)
  return ({
    instructions,
    // the FetchFailureUnknownErrorContent component needs these
    errorDetails,
    formName,
  })
}


export default connect(
  mapStateToProps,
)(FetchFailureContent)
