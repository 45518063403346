import Immutable from 'seamless-immutable'

import {
  convertArrOfObjsOrSingleObjToObjOfSubObjs,
} from '../../../../utils'

// saveActionTypes is an array of action types in this order:
//
// [
//   'SAVE_MULTIPLE_ENTITIES', // pass in either an array of objects or an object of objects as 'info' of payload
//   'SAVE_SINGLE_ENTITY', // pass in a single object as 'info' of payload
//   'DELETE_MULTIPLE_ENTITIES', // pass in an array of ids as 'info' of payload
//   'DELETE_SINGLE_ENTITY', // pass in a single id as 'info' of payload
// ]
//
// If you only need the first and third action type, you can set the second
// element to null.
export default (
  saveActionTypes,
  initialState_=null,
) => {
  const initialState = initialState_
    ? Immutable(initialState_)
    : Immutable({})
  return (state=initialState, action) => {
    if (
      saveActionTypes[0] === action.type // save multiple entities
      || saveActionTypes[1] === action.type // save single entity
    ) {
      // `info` can either be an array of objects or a single object
      const { info } = action.payload
      return (
        Immutable.merge(
          state,
          convertArrOfObjsOrSingleObjToObjOfSubObjs(info),
          // For testing purposes, you can replace the line above with the line
          // below. Not to be used in production

          // alterCustomerObjectsBeforeSavingToStore(convertArrOfObjsOrSingleObjToObjOfSubObjs(info)),
        )
      )
    }
    if (saveActionTypes[2] === action.type) { // delete multiple entities
      const { info: ids } = action.payload
      return ids.reduce(
        (tempState, id_) => Immutable.without(state, id_),
        state,
      )
    }
    if (saveActionTypes[3] === action.type) { // delete single entitiy
      const { info: id_ } = action.payload
      return Immutable.without(state, id_)
    }
    return state
  }
}


// // This function is helpful for testing purposes: it allows you to manipulate
// // objects before they get saved into the Redux store. Not to be used
// // in production.
// function alterCustomerObjectsBeforeSavingToStore(info, action) {
//   if (['SAVE_CUSTOMERS', 'SAVE_CUSTOMER'].includes(action.type)) {
//     return Object.values(info).map(o => ({
//       ...o,
//       // for instance, you can set the customer status of all customers to
//       // 'active'
//       customerStatus: 'ACT',
//     })).reduce(
//       (acc, o) => ({ ...acc, [o.id]: o }),
//       {},
//     )
//   }
//   return info
// }
