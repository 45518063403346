import {
  createCustomersFieldsDropdownOptionsForShipmentFormsBrewer,
  createDefaultBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer,
  createContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer,
} from './shared'

import {
  getContractsThatCanHaveShipmentsReportedOnThem,
} from '../../../../../../../redux/selectors/rewrite/contracts'

import {
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
} from '../../../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
} from '../../../../../../../constants/formAndApiUrlConfig/reportBuybackShipments'

import {
  CUSTOMER_TYPES_BREWER,
  PPF_CONTRACT_TYPES_BRW,
} from '../../../../../../../constants'

import {
  getCustomerTypesABrewerTargetingAContractBrewingContractCanReportBuybackShipmentsTo,
  getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportBuybackShipmentsTo,
} from '../../../../../util'
import {
  getSubsidiaryTapDefectiveKegs,
  showProductOutOfCodeForSubsidiary,
} from '../../../../../../../redux/selectors/rewrite/subsidiaries'


/*
 * *****************************************************************************
 * Create Field Arrays
 * *****************************************************************************
*/

const universalPropsOfAllBuybacksFieldArrays = {
  textToAppendToSourceFieldLabel: ' (where you originally shipped to)',
  textToAppendToDestinationFieldLabel: ' (where you want to return kegs to)',
}


// CODE_COMMENTS_133
const getCustomersFieldsDropdownOptionsForOutboundReverseShipment =
  getCustomersFieldsDropdownOptionsForBuybackShipmentsForm({
    includeConbrwPpfContractsInCustomersFieldThatMustBeChosenFirst: true,
  })

// CODE_COMMENTS_133
const getCustomersFieldsDropdownOptionsForInboundShipment =
  getCustomersFieldsDropdownOptionsForBuybackShipmentsForm({
    // Inbounds are empty, and we can't send empty kegs into a conbrw contract
    // location, because we haven't yet figured out a good way to get them from
    // the conbrw contract location back to the CBMST location, which is where
    // empty kegs at a Contract Brewer really belong.
    includeConbrwPpfContractsInCustomersFieldThatMustBeChosenFirst: false,
  })


/*
 * Report Buyback Shipments
 * ************************
*/

export function createFieldArraysAndCustomersFieldsDropdownOptionsForReportBuybackShipments(props) {
  const {
    operatingContractBrewerCustomerId,
    entireSubsidiariesSlice,
    entireCustomersSlice,
    customerId,
    defectiveItemSkuIds,
  } = props

  const { subsidiaryId } = entireCustomersSlice?.[customerId] || {}

  return [
    {
      // We use the word 'Product' here rather than 'Beer' because MicroStar
      // also has cideries for customers.
      fieldArrayTitle: 'Full Kegs Ready to Be Resold (Nothing Wrong with Kegs or Product)',
      fieldArrayName: REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
      whichCustomersFieldMustBeChosenFirstSourceOrDestination:
        WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
      customersFieldsDropdownOptions: getCustomersFieldsDropdownOptionsForOutboundReverseShipment(props),
      ...universalPropsOfAllBuybacksFieldArrays,
    },
    ...(getSubsidiaryTapDefectiveKegs({
      entireSubsidiariesSlice, subsidiaryId: entireCustomersSlice?.[customerId]?.subsidiaryId,
    }) && defectiveItemSkuIds?.length ?
      [{
        fieldArrayTitle: 'Defective Kegs (Leaking, etc.)',
        fieldArrayName: REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
        whichCustomersFieldMustBeChosenFirstSourceOrDestination:
        WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
        customersFieldsDropdownOptions: getCustomersFieldsDropdownOptionsForOutboundReverseShipment(props),
        ...universalPropsOfAllBuybacksFieldArrays,
        itemSkuIdsForFieldArray: defectiveItemSkuIds,
      }] :
      []),
    ...(
      // Don't include the "Product Out of Code" section if this is a Contract
      // Brewer operating for a contractee; see CODE_COMMENTS_158
      operatingContractBrewerCustomerId
      // Don't include the "Product Out of Code" section if this is a
      // non-contractee Brewer who _only_ has CONBRW contracts (no BRW
      // contracts); see CODE_COMMENTS_264
      || !getContractsThatCanHaveShipmentsReportedOnThem(props).some(o => o.ppfContractType === PPF_CONTRACT_TYPES_BRW)
        || !showProductOutOfCodeForSubsidiary({ entireSubsidiariesSlice, subsidiaryId })
        ? []
        : [
          {
            // We use the word 'Product' here rather than 'Beer' because
            // MicroStar also has cideries for customers.
            fieldArrayTitle: 'Product Out of Code (Expired, etc.)',
            fieldArrayName: REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE,
            whichCustomersFieldMustBeChosenFirstSourceOrDestination:
              WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
            customersFieldsDropdownOptions: getCustomersFieldsDropdownOptionsForInboundShipment(props),
            ...universalPropsOfAllBuybacksFieldArrays,
          },
        ]
    ),
  ]
}


/*
 * Edit Buyback Shipments
 * **********************
*/

export function createFieldArraysAndCustomersFieldsDropdownOptionsForEditBuybackShipments({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  operatingContractBrewerCustomerId,
  isThisAnInboundShipmentRatherThanAReverseShipment,
}) {
  const dropdownOptionsFunc = isThisAnInboundShipmentRatherThanAReverseShipment
    ? getCustomersFieldsDropdownOptionsForInboundShipment
    : getCustomersFieldsDropdownOptionsForOutboundReverseShipment
  const dropdownOptions = dropdownOptionsFunc({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    operatingContractBrewerCustomerId,
  })

  return [{
    fieldArrayName: isThisAnInboundShipmentRatherThanAReverseShipment
      // This can be REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG or
      // REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE, it doesn't
      // matter because the field array name won't show up since only one field
      // array is being rendered in the edit form. The only important thing is
      // that the field array communicates that this is an inbound shipment, not
      // an outbound reverse shipment, and both
      // REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG and
      // REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE communicate
      // this.
      ? REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE
      : REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE, // CODE_COMMENTS_58
    whichCustomersFieldMustBeChosenFirstSourceOrDestination:
      WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
    customersFieldsDropdownOptions: dropdownOptions,
    ...universalPropsOfAllBuybacksFieldArrays,
  }]
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

function getCustomersFieldsDropdownOptionsForBuybackShipmentsForm({
  includeConbrwPpfContractsInCustomersFieldThatMustBeChosenFirst,
}) {
  return ({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    operatingContractBrewerCustomerId,
  }) => {
    if (operatingContractBrewerCustomerId) {
      return createContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        customerId,
        conbrwCustomerId: operatingContractBrewerCustomerId,
        customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond:
          getCustomerTypesABrewerTargetingAContractBrewingContractCanReportBuybackShipmentsTo(),
        setBrewerOrContractBrewerAsName: CUSTOMER_TYPES_BREWER,
      })
    }

    if (includeConbrwPpfContractsInCustomersFieldThatMustBeChosenFirst) {
      return createCustomersFieldsDropdownOptionsForShipmentFormsBrewer({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        customerId,
        customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsDefaultBrwPpf:
          getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportBuybackShipmentsTo(),
        customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsConbrwPpf:
          getCustomerTypesABrewerTargetingAContractBrewingContractCanReportBuybackShipmentsTo(),
      })
    }

    return createDefaultBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond:
        getCustomerTypesABrewerTargetingAContractBrewingContractCanReportBuybackShipmentsTo(),
    })
  }
}
