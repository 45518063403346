/**
 * CODE_COMMENTS_131
 */
import React, { Fragment } from 'react'
import moment from 'moment-timezone'

import get_ from 'lodash/get'


import TwoColumnSplashScreen from '../../../common-components/TwoColumnSplashScreen'

import {
  getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow,
} from '../../../redux/selectors/maintenanceMode'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'


export default ({
  areWeCurrentlyInMaintenanceMode,
  startAndEndTimesFrom503,
  maintenanceSchedule,
  children,
}) => {
  if (areWeCurrentlyInMaintenanceMode) {
    return (
      <TwoColumnSplashScreen
        title="Site under Maintenance"
        titleColor="orange"
        content={() => (
          <UnderMaintenanceMessage
            startAndEndTimesFrom503={startAndEndTimesFrom503}
            maintenanceSchedule={maintenanceSchedule}
          />
        )}
      />
    )
  }
  return (
    <Fragment>
      {children}
    </Fragment>
  )
}


const UnderMaintenanceMessage = props => {
  const displayedEndTime = getDisplayedEndTimeOfMaintenanceMode(props)
  if (displayedEndTime) {
    return (
      <Fragment>
        <p>
          TAP3 is currently under maintenance until <span style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>{displayedEndTime}</span>.
        </p>
        <p>The site will be fully available after then.</p>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <p>
        TAP3 is currently under maintenance.
      </p>
      <p>The site will be fully available within a few hours.</p>
    </Fragment>
  )
}


// Helper functions

// Returns an end time string of the current maintenance mode to display to the
// end user. Returns null if the caller should not display any end time but
// rather a generic "will end soon" message.
function getDisplayedEndTimeOfMaintenanceMode({
  startAndEndTimesFrom503,
  maintenanceSchedule,
}) {
  let endMoment

  const endUnixTimestampFrom503 = get_(startAndEndTimesFrom503, 'end')
  const startAndEndTimesOfSoonestUpcomingMaintenanceWindow = getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow({
    maintenanceSchedule,
    includeMaintenanceWindowsWereCurrentlyIn: true,
  })
  if (endUnixTimestampFrom503) {
    const endMomentFrom503 = moment(endUnixTimestampFrom503)
    endMoment = moment().isBefore(endMomentFrom503) ? endMomentFrom503 : null
  } else if (isTruthyAndNonEmpty(startAndEndTimesOfSoonestUpcomingMaintenanceWindow)) {
    const {
      start,
      end,
    } = startAndEndTimesOfSoonestUpcomingMaintenanceWindow
    const areWeCurrentlyInAMaintenanceWindow = moment().isBetween(start, end)
    if (areWeCurrentlyInAMaintenanceWindow) {
      endMoment = end
    }
  }

  if (endMoment) {
    // https://stackoverflow.com/a/29226671/6995996 - If the user is in
    // California, we want to display e.g. "10/28/2019 6:00pm PST", but if
    // they're in New York we want to display "10/28/2019 9:00pm EST"
    const endMomentLocal = endMoment.tz(moment.tz.guess())
    const displayedEndTime = endMomentLocal.format('ddd. M/D/YYYY h:mma z')
    return displayedEndTime
  }
  // When would we get here? The stars have to align, but it can happen: we
  // can't be in a scheduled maintenance window, and the 503 returned by the
  // backend either has a malformed JSON body or its 'endTimeUTC' is in the
  // past.
  return null
}
