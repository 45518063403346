// A replacement for { CSVLink } from 'react-csv' which properly escapes quotes
// (see https://microstartap3.atlassian.net/browse/TP3-3686 and
// https://github.com/react-csv/react-csv/issues/28 for details). Always use
// this instead of the regular CSVLink.
import React, { memo } from 'react'
import {
  CSVLink as CSVLinkOriginal,
} from 'react-csv'


import {
  recursivelyReplaceSubstringInAllStringsInObject,
} from '../../utils'


export const CSVLink = memo(({
  headers,
  data,
  ...rest
}) => {
  const substring = '"'
  const replacement = '""'
  return (
    <CSVLinkOriginal
      headers={recursivelyReplaceSubstringInAllStringsInObject({
        obj: headers,
        substring,
        replacement,
        checkObjectKeys: true,
      })}
      data={recursivelyReplaceSubstringInAllStringsInObject({
        obj: data,
        substring,
        replacement,
        checkObjectKeys: true,
      })}
      {...rest}
    />
  )
})
