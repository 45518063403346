import LoadingPage from '../../../LoadingPage'
import FailedToLoadCustomer from '../../../FailedToLoadCustomer'
import withFetchingOrFailed from '../../ui/withFetchingOrFailed'
import UiContainer from '../../../UiContainer'

export default withFetchingOrFailed(
  LoadingPage,
  UiContainer({
    headerText: 'Failed to Load Customer',
    containerId: 'failedToLoadCustomerSection',
  })(FailedToLoadCustomer),
)
