import React from 'react'

import Form from './Form'
import Table from './Table'

import {
  HISTORY_FORM_NAME_INBOUND_FULL_KEG_SHIPMENTS,
} from '../../../../constants'

const formReducerName = HISTORY_FORM_NAME_INBOUND_FULL_KEG_SHIPMENTS

export default props => (
  <div>
    <Form
      // CODE_COMMENTS_93
      formReducerName={formReducerName}
      {...props}
    />
    <Table
      formReducerName={formReducerName}
      {...props}
    />
  </div>
)
