import {
  SAVE_CUSTOMER_CONTRACTS,
  SAVE_CUSTOMER_CONTRACT,
} from '../../actions/actionTypes'

import reducerCreator from './higherOrderEntityReducers/basicReducerCreator'

export default reducerCreator([
  SAVE_CUSTOMER_CONTRACTS,
  SAVE_CUSTOMER_CONTRACT,
])
