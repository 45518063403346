/**
 * CODE_COMMENTS_84
 */
import {
  SAVE_CUSTOMER_TO_ADDRESSES_NORMALIZED_LINKS,
} from '../../actions/actionTypes'


import reducerCreator from './higherOrderEntityReducers/basicReducerCreator'

export default reducerCreator([
  SAVE_CUSTOMER_TO_ADDRESSES_NORMALIZED_LINKS,
])
