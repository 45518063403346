import {
  REDUCER_NAMES_HISTORY,
  REDUCER_NAMES_HISTORY_INVOICES as defaultSlice,
} from '../../../constants'

import {
  withPropSimple,
  withPropOfAllSimple,
  getEntireSlice as commonGetEntireSlice,
} from '../higherOrderFunctions'


const defaultSlices = [REDUCER_NAMES_HISTORY, defaultSlice, 'byCustomerId']

// usage: getProp(state, customerId, orderId, 'invoiceDate')
export const getProp = withPropSimple(...defaultSlices)
// usage: getPropOfAfll(state, customerId, 'invoiceDate')
export const getPropOfAll = withPropOfAllSimple(...defaultSlices)

// Fetch the entire slice for all customers with getEntireSlice(state)
// or for a single customer with getEntireSlice(state, customerId)
export const getEntireSlice = commonGetEntireSlice(...defaultSlices)
