import {
  REDUCER_NAMES_ORDER_KEGS as defaultSlice,
} from '../../constants'

import {
  withPropOrUndefinedSimple,
  getEntireSlice as commonGetEntireSlice,
} from './higherOrderFunctions'


const defaultSlices = [defaultSlice, 'byCustomerId']

export const getEntireSlice = commonGetEntireSlice(...defaultSlices)
const getProp = withPropOrUndefinedSimple(...defaultSlices)

export function getKegOrderDays(state, customerId) {
  return getProp(state, customerId, 'kegOrderDays')
}
