import {
  REDUCER_NAMES_CUSTOMER_BALANCES as defaultSlice,
} from '../../constants'

import {
  withPropOrUndefinedSimple,
  getEntireSlice as commonGetEntireSlice,
} from './higherOrderFunctions'

import {
  isTruthyAndNonEmpty,
} from '../../utils'

const defaultSlices = [defaultSlice, 'byCustomerId']

// You can use this to get several levels of slices:
//
// getEntireSlice(state) // all shipment IDs of all customers
// getEntireSlice(state, customerId) // all shipment objects of one customer
export const getEntireSlice = commonGetEntireSlice(...defaultSlices)

// usage: const customerType = getProp(state, customerId, 'previousBalance')
const getProp = withPropOrUndefinedSimple(...defaultSlices)


export function getBalance(state, customerId) {
  return getProp(state, customerId, 'balance')
}

export function getPreviousBalance(state, customerId) {
  return getProp(state, customerId, 'previousBalance')
}

// Only call this after you're sure the fetch has succeeded, otherwise you'll
// get a false negative
export function getHasAnyInvoices(state, customerId) {
  return isTruthyAndNonEmpty(getEntireSlice(state, customerId))
}

export function getInvoiceDate(state, customerId) {
  return getProp(state, customerId, 'invoiceDate')
}
