import { connect } from 'react-redux'

import FormOrNoShipmentsMessage from '../components/FormOrNoShipmentsMessage'

import {
  getUnacknowledgedShipmentsQty,
} from '../../../redux/selectors/rewrite/inboundUnacknowledgedShipments'

import createAction from '../../../redux/actions/createAction'
import {
  SET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER,
} from '../../../redux/actions/actionTypes'


const mapStateToProps = (state, ownProps) => ({
  unacknowledgedShipmentsQty: getUnacknowledgedShipmentsQty(state, ownProps.customerId),
  ...ownProps,
})

const mapDispatchToProps = (dispatch, ownProps) => {
  const { customerId } = ownProps
  const setAckInboundShipmentsReminderPopupHasHadTheChanceToRender = () => (
    dispatch(createAction(
      SET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER,
      { customerId },
    ))
  )

  return {
    setAckInboundShipmentsReminderPopupHasHadTheChanceToRender,
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormOrNoShipmentsMessage)
