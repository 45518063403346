import { connect } from 'react-redux'


import DownloadKegOrderHistoryTableAsFile from './components'

import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getEntireSlice as getAllInvoiceHistoryObjects,
} from '../../../../../redux/selectors/histories/invoiceHistory'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'


import {
  HISTORY_FORM_NAME_INVOICES,
} from '../../../../../constants'

import {
  createFormNameForRedux,
} from '../../../../../utils'


const mapStateToProps = (state, { customerId }) => {
  const customerType = getCustomerProp(state, customerId, 'customerType')
  const historyFormName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_INVOICES,
    customerId,
  })
  const mostRecentlySubmittedFormValues = getMostRecentlySubmittedFormValues(state, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return {} }
  const invoicesObject = getAllInvoiceHistoryObjects(state, customerId)

  return {
    customerType,
    invoicesObject,
    mostRecentlySubmittedFormValues,
  }
}


export default connect(
  mapStateToProps,
)(DownloadKegOrderHistoryTableAsFile)
