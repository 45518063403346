import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'
import CustomerPortal from '../../../../CustomerPortal'
import History from '../../../../History'

import {
  CUSTOMER_TYPES_MAINTENANCE_FACILITY,
} from '../../../../../constants'
import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
} from '../../../../../constants/permissions'
import AcknowledgeInboundShipmentsReminderPopup from '../../../../AcknowledgeInboundShipmentsReminderPopup'
import HasPermissions from '../../../../../common-components/HasPermissions'

export default memo(({ // Why memo? See CODE_COMMENTS_253
 customerId,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <div>
      <CustomerPortal
        customerId={customerId}
        customerType={CUSTOMER_TYPES_MAINTENANCE_FACILITY}
      />
      <History customerId={customerId} translate={translate} />
      <HasPermissions
        requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS}
        renderIfHasPermissions={
            () => <AcknowledgeInboundShipmentsReminderPopup customerId={customerId} />
        }
      />
    </div>
  )
})
