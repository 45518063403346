import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import memoize_ from 'lodash/memoize'


import InformationalPopup from '../../../../../../common-components/InformationalPopup'

import {
  getProp as getCustomerProp,
} from '../../../../../../redux/selectors/customers'

import {
  getOriginCustomerIdForOutboundShipmentsHistoryTableAssumingContracteeFillsMeanConbrwOrigin,
} from '../outboundFullKegShipmentHistory'

import {
  convertApiDateToMoment,
  convertMomentToMonthAndYearString,
} from '../../../../../../utils'

export const createSpecialTableContentDefinitions = memoize_(
  ({
    itemType,
    shouldOriginColumnBeRendered,
  }) => {
    const shipmentNumberHeading = itemType === 'outbound full keg shipment'
      ? 'Shipment #'
      : 'Fill #'
    const shipDateHeading = itemType === 'outbound full keg shipment'
      ? 'Ship Date'
      : 'Fill Date'
    const noShipmentsReportLanguage = itemType === 'outbound full keg shipment'
      ? 'No Shipments Report'
      : 'No Keg Fills Report'
    const informationalPopupContent = itemType === 'outbound full keg shipment'
      ? "You've informed us that you didn't ship any kegs from this origin during this month."
      : "You've informed us that you didn't fill any kegs during this month."
    return {
      [shipmentNumberHeading]: {
        cellContent: row => (
          <Fragment>
            <span>{noShipmentsReportLanguage} for {
              convertMomentToMonthAndYearString({
                momentObj: convertApiDateToMoment(row.reportingPeriodStartDate, true),
              })
            }</span>
            <InformationalPopup
              content={informationalPopupContent}
            />
          </Fragment>
        ),
      },
      ...(shouldOriginColumnBeRendered
        ? {
          Origin: {
            transformationsThatNeedToHappenOnRowInOrderToProperlySortOrRenderCellContent: row => ({
              ...row,
              // necessary for the
              // getOriginCustomerIdForOutboundShipmentsHistoryTableAssumingContracteeFillsMeanConbrwOrigin()
              // call below
              originContractId: row.ppfContractId,
            }),
            cellContent: createDisplayedOriginNameForNoMovementsReportInOutboundShipmentsHistoryTable,
          },
        }
        : {}
      ),
      [shipDateHeading]: {
        cellContent: row => (
          convertMomentToMonthAndYearString({
            momentObj: convertApiDateToMoment(row.reportingPeriodStartDate, true),
          })
        ),
        transformationsThatNeedToHappenOnRowInOrderToProperlySortOrRenderCellContent: row => ({
          ...row,
          dateShipped: row.reportingPeriodStartDate,
        }),
      },
    }
  },
  ({ itemType, shouldOriginColumnBeRendered }) => `${itemType}${Boolean(shouldOriginColumnBeRendered)}`,
)


function createDisplayedOriginNameForNoMovementsReportInOutboundShipmentsHistoryTable(row, { customerId }) {
  return (
    <DisplayedOriginNameForNoMovementsReportOutboundShipmentsHistoryTable
      customerId={customerId}
      row={row}
    />
  )
}

const DisplayedOriginNameForNoMovementsReportOutboundShipmentsHistoryTable = connect(
  (state, { customerId, row }) => {
    const customerIdToDisplayAsOrigin =
      getOriginCustomerIdForOutboundShipmentsHistoryTableAssumingContracteeFillsMeanConbrwOrigin(
        state,
        customerId,
        row,
      )
    let originName
    // This will happen if the shipment didn't originate at the
    // Brewer's default location nor any of its Contract Brewer's
    // locations, so it shouldn't have been included in the response
    // from the fetching of this customer's outbound shipment
    // history, therefore this is a bug. However, we don't want this
    // bug to crash the app, so we display the origin's address1
    // contained in the shipment object instead and throw a bug
    // alert behind the scenes.
    if (!customerIdToDisplayAsOrigin) {
      // TODO rollbar: throw error described in comment above
      originName = row.originAddressObject.address1
    } else {
      originName = getCustomerProp(state, customerIdToDisplayAsOrigin, 'name')
    }
    return {
      originName,
    }
  },
)(({ originName }) => originName)
