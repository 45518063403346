import React from 'react'

import FetchFailedChildCard from '../containers/FetchFailedCard'
import LoadingChildCard from './LoadingCard'

export default ({
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  hasPrelimInfoFetchBeenAttempted,
  isPrelimInfoFetching,
  didPrelimInfoFetchFail,
  populatedCard: PopulatedCard,
}) => {
  if (isPrelimInfoFetching || !hasPrelimInfoFetchBeenAttempted) {
    return (
      <LoadingChildCard />
    )
  }
  if (didPrelimInfoFetchFail) {
    return (
      <FetchFailedChildCard
        customerId={customerId}
        operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      />
    )
  }
  return (
    <PopulatedCard
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
    />
  )
}
