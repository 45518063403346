import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import ModalWithCatastrophicFailureErrorBoundary
from '../../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import createAction from '../../../../../../../redux/actions/createAction'
import {
  FETCH_WH_EMAIL_CONTENT,
} from '../../../../../../../redux/actions/actionTypes'

const WHEmailSection = props => {
  const { dispatch, shipmentId } = props
  const { t: translate } = useTranslation('common')
  const [modalOpen, setModalOpen] = useState(false)
  const [content, setContent] = useState(null)
  const [loadingFailed, setLoadingFailed] = useState(false)
  useEffect(() => {
    setLoadingFailed(true)
    dispatch(createAction(
      FETCH_WH_EMAIL_CONTENT,
      {
        shipmentId,
        onFailure: () => {
          setLoadingFailed(true)
          setContent(null)
        },
        onSuccess: responseData => {
          setContent(responseData)
          setLoadingFailed(false)
        },
      },
    ))
  }, [shipmentId, dispatch])

  useEffect(() => {
    if (content?.text && modalOpen) {
      const doc = document.getElementById('content').contentWindow.document
      if (doc) {
        doc.open()
        doc.write(content.text)
        doc.close()
      }
    }
  }, [content, modalOpen])

  const printFunction = () => {
    if (!content?.text) return
    document.getElementById('content').contentWindow.print()
    // const printWindow =
    // window.open('Microstar Logistics', 'WH EMAIL PRINT POPUP', 'height=650,width=900,top=100,left=150')
    // printWindow.document.write(`<html><head><title>${content.subject}</title></head>`)
    // printWindow.document.write('<body>')
    // printWindow.document.write(content.text)
    // printWindow.document.write(document.getElementById('content').innerHTML)
    // printWindow.document.write('</body></html>')
    // printWindow.document.close()
    // printWindow.focus()
    // printWindow.print()
    // printWindow.close()
  }
  return (
    <React.Fragment>
      <Button
        type="button"
        color="green"
        disabled={loadingFailed}
        onClick={() => setModalOpen(true)}
      >
        {translate('Display WH Email')}
      </Button>
      <ModalWithCatastrophicFailureErrorBoundary
        size="large"
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
      >
        <Modal.Header>{content?.subject ? content?.subject : translate('Email Info')}</Modal.Header>
        <Modal.Content style={{ height: '500px' }}>
          {/* eslint-disable-next-line react/no-danger */}
          {/* <span id='content' dangerouslySetInnerHTML={{ __html: content.text }} /> */}
          <iframe id='content' title='WH email section' />
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            color="green"
            size="small"
            onClick={() => printFunction()}
          >
            {translate('Print')}
          </Button>
          <Button
            type="button"
            color="grey"
            size="small"
            onClick={() => setModalOpen(!modalOpen)}
          >
            {translate('Close')}
          </Button>
        </Modal.Actions>
      </ModalWithCatastrophicFailureErrorBoundary>
    </React.Fragment>
  )
}

export default WHEmailSection
