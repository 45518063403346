import React from 'react'


import HasPermissions from '../../../../../../common-components/HasPermissions'

import {
  ManageAllContacts,
  ManageUsersOption,
  ViewAllInvoices,
  ReportedVsCalculatedInventoryOption,
} from '../../shared/IndividualOptions'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
} from '../../../../../../constants/permissions'


// CODE_COMMENTS_39
export default props => {
  const {
    shouldViewAllInvoicesBeRendered,
    shouldReportedInventoryVsCalculatedInventoryPageBeRendered,
  } = props
  return [
    <HasPermissions
      key="ManageUsersOption"
      requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
      renderIfHasPermissions={
        () => (
          <ManageUsersOption />
        )
      }
    />,
    <HasPermissions
      key="ManageAllContacts"
      requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
      renderIfHasPermissions={
        () => (
          <ManageAllContacts />
        )
      }
    />,
    ...(shouldViewAllInvoicesBeRendered ? [
      <HasPermissions
      key="ViewAllInvoices"
      requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES}
      renderIfHasPermissions={
        () => (
          <ViewAllInvoices />
        )
      }
      />,
    ] : []),
    ...(
      shouldReportedInventoryVsCalculatedInventoryPageBeRendered
        ? [<ReportedVsCalculatedInventoryOption key="ReportedVsCalculatedInventoryOption" {...props} />]
        : []
    ),
  ]
}
