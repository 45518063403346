// See CODE_COMMENTS_238. This thread does one thing and one thing only: it
// checks the Maintenance schedule in the Redux store every 15-30 seconds, and
// if we're in the first ~5 minutes of a maintenance mode, set the
// areWeInMaintenanceMode Redux flag to true. Note that this thread does not
// take the app out of maintenance mode nor does it fetch the maintenance mode
// schedule from the backend.

// Why only in the first 5 minutes? Why not set the areWeInMaintenanceMode Redux
// flag during the entire duration of the maintenance mode? Because what happens
// if the maintenance schedule has a typo in it? For instance, What happens if
// the maintenance window is a year and 3 hours long rather than just 3 hours
// long? We don't want to blindly trust the maintenance schedule; we want to
// limit the timeframe in which we automatically put the web app into
// maintenance mode based on the maintenance schedule. If we don't, then in the
// year-and-three-hours-long example, whenever the user navigates to the web
// app, the Maintenance Mode splash screen will invariably render, making the
// web app completely unuseable for all Microstar's customers. The only fix will
// be that our customers will complain after a couple days, and we'll have to
// track down the mistake on the backend and fix the typo. That's not a robust
// design.

// But what happens if the user navigates to the web app 6 minutes after
// maintenance mode starts? In that case, this thread will be completely
// ineffective in putting the web app into maintenance mode. However, remember
// that by that time, the backend will probably be returning 503 errors on all
// calls; any call whatsoever that returns a 503 will result in our common fetch
// saga, `privateFetch`, putting the web app into maintenance mode. We can get
// away with putting limitations on this thread because it's not the only thing
// that can put the web app into maintenance mode.

import {
  call,
  takeEvery,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'


import {
  START_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE_THREAD,
} from '../../actions/actionTypes'

import {
  putAppIntoMaintenanceModeIfTheCurrentTimeIsWithinXMinutesAfterTheStartOfAMaintenanceWindow,
} from './util'

import {
  CHECK_EVERY_X_SECONDS_WHETHER_TO_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE,
} from '../../../config'


// Wait, shouldn't this be a cancelable saga which gets canceled on logout or
// authentication failure? Yes, but instead of using the cancelableForkedSaga()
// function here, we let our main rootSaga() wrap this with a
// transformSagasIntoCancellableSagas() function.
function* putAppInMaintenanceModeBasedOnMaintenanceSchedule() {
  while (true) {
    yield call(putAppIntoMaintenanceModeIfTheCurrentTimeIsWithinXMinutesAfterTheStartOfAMaintenanceWindow)
    yield call(delay, CHECK_EVERY_X_SECONDS_WHETHER_TO_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE*1000)
  }
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    START_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE_THREAD,
    putAppInMaintenanceModeBasedOnMaintenanceSchedule,
  ],
]
