import React from 'react'
import { Container, Segment, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'


/**
 * The same as UiContainer except that you use this as the parent component of
 * whatever you want in the UI Container, e.g.
 * <UiContainerAsParent
 *   headerText="Some Form"
 * >
 *   <Form>
 *     ...
 *   </Form>
 * </UiContainerAsParent>
 */
export default ({
  headerText,
  headerProps={},
  containerId,
  dontActuallyApplyTheUiContainerHoc,
  containerProps,
  children,
}) => {
  const { t: translate } = useTranslation('common')
  if (dontActuallyApplyTheUiContainerHoc) {
    return children
  }
  return (
    <Container
      id={containerId || null}
      {...containerProps}
    >
      <Segment>
        {headerText
          ? <Header as="h2" color="grey" {...headerProps}>{translate(headerText)}</Header>
          : null
        }
        {children}
      </Segment>
    </Container>
  )
}
