import React, { memo } from 'react'


import { useTranslation } from 'react-i18next'
import CustomerPortal from '../../../../CustomerPortal'
import History from '../../../../History'


export default memo(({ // Why memo? See CODE_COMMENTS_253
  customerId,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <div>
      <CustomerPortal
        customerId={customerId}
        isRentalCustomer
      />
      <History
        customerId={customerId}
        isRentalCustomer
        translate={translate}
      />
    </div>
  )
})
