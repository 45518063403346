/**
 * CODE_COMMENTS_54
 */
import Immutable from 'seamless-immutable'

import {
  SAVE_FORGOT_PASSWORD_INPUT_EMAIL_AND_RESET_CODE_SENT_TO,
} from '../../actions/actionTypes'


const initialState = Immutable({ email: null, apiResponseIndicatingWhereResetCodeSent: null })

export default function currentUserReducer(state=initialState, action) {
  switch (action.type) {
    case SAVE_FORGOT_PASSWORD_INPUT_EMAIL_AND_RESET_CODE_SENT_TO:
      return Immutable.replace(state, action.payload)

    default:
      return state
  }
}
