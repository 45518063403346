import React from 'react'


import FeatureDisabledMessage from './FeatureDisabledMessage'

export default ({
  isCustomerOnCreditHold,
  isCustomerOnReportingHold,
  isCustomerOnSalesHold,
  isCustomerStatusInactive,
  isCustomerOnAcknowledgmentHold,
  wrappedComponent: WrappedComponent,
  ...rest
}) => {
  const { customerId } = rest
  if (isCustomerOnAcknowledgmentHold || isCustomerOnSalesHold ||
    isCustomerOnCreditHold || isCustomerOnReportingHold || isCustomerStatusInactive) {
    return (
      <FeatureDisabledMessage
        isCustomerOnCreditHold={isCustomerOnCreditHold}
        isCustomerOnReportingHold={isCustomerOnReportingHold}
        isCustomerStatusInactive={isCustomerStatusInactive}
        isCustomerOnSalesHold={isCustomerOnSalesHold}
        isCustomerOnAcknowledgmentHold={isCustomerOnAcknowledgmentHold}
        customerId={customerId}
      />
    )
  }
  return <WrappedComponent {...rest} />
}
