import React from 'react'
import moment from 'moment'
import { Form, Dropdown } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import DownloadCollarOrderHistoryTableAsFile from '../DownloadTableAsFile'
import HistoryFormCreator from '../../HOC/HistoryFormCreator'

import { FETCH_COLLAR_ORDER_HISTORY } from '../../../../../redux/actions/actionTypes'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
  COMMON_STATUS_ALL,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  HISTORY_FORM_NAME_COLLAR_ORDERS,
} from '../../../../../constants'

import {
  collarOrderHistoryStatusesConfig,
} from '../../util/collarOrderHistory'

import {
  createHistoryFormDropdownOptionsFromStatusesConfig,
} from '../../util/util'

import {
  createFormNameForRedux,
} from '../../../../../utils'


const additionalIsFormSubmittableChecksBesidesDateRanges = () => (
  // no need to check whether order status has a valid value or not, because
  // it's impossible to choose an invalid value.
  true
)

const initialValues = {
  [HISTORY_FORM_FIELD_NAME_STATUS]: COMMON_STATUS_ALL, // CODE_COMMENTS_61
  [HISTORY_FORM_FIELD_NAME_START_DATE]:
    moment().subtract(1, 'months').startOf('month').format(DEFAULT_DISPLAYED_DATE_FORMAT),
  [HISTORY_FORM_FIELD_NAME_END_DATE]:
    moment().add(2, 'months').format(DEFAULT_DISPLAYED_DATE_FORMAT),
}

const fieldsBeforeDateRange = ({
  formValues,
  dispatchFormValues,
  translate,
}) => (
  <Form.Field inline>
    <label htmlFor={HISTORY_FORM_FIELD_NAME_STATUS}>{`${translate('Order Status')}:`}</label>
    <Dropdown
        selection
        name={HISTORY_FORM_FIELD_NAME_STATUS}
        options={
          createHistoryFormDropdownOptionsFromStatusesConfig(
            collarOrderHistoryStatusesConfig,
          ).map(({
            status,
            humanReadableName,
          }) => (
            { key: status, value: status, text: humanReadableName }
          ))
        }
        value={formValues[HISTORY_FORM_FIELD_NAME_STATUS]}
        onChange={
          (event, { value }) => {
            dispatchFormValues({
              [HISTORY_FORM_FIELD_NAME_STATUS]: value,
            })
          }
        }
    />
  </Form.Field>
  )

function createDateRangeMin() {
  return moment('01/01/1985', 'MM-DD-YYYY')
}

function createDateRangeMax() {
  return moment().add(1, 'years')
}

export default props => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = props
  const { t: translate } = useTranslation('common')
  return (
    <HistoryFormCreator
      {...props}
      translate={translate}
      createDateRangeMin={createDateRangeMin}
      createDateRangeMax={createDateRangeMax}
      dateRangeFieldsLabel="Order Date"
      initialValues={initialValues}
      additionalIsFormSubmittableChecksBesidesDateRanges={additionalIsFormSubmittableChecksBesidesDateRanges}
      submitAction={FETCH_COLLAR_ORDER_HISTORY}
      fieldsBeforeDateRange={fieldsBeforeDateRange}
      downloadFileComponent={DownloadCollarOrderHistoryTableAsFile}
      formName={createFormNameForRedux({
        reducerName: HISTORY_FORM_NAME_COLLAR_ORDERS,
        customerId,
        operatingContractBrewerCustomerId,
      })}
    />
  )
}
