import { connect } from 'react-redux'
import values_ from 'lodash/values'

import Table from './components/Table'

import {
  getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
  getShouldOriginColumnBeRendered,
  getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
  getOriginCustomerIdForOutboundShipmentsHistoryTableAssumingContracteeFillsMeanConbrwOrigin,
} from '../../util/shipmentHistory/outboundFullKegShipmentHistory'

import {
  getEntireSlice as getAllOutboundFullKegShipmentHistoryObjects,
} from '../../../../../redux/selectors/histories/outboundFullKegShipmentHistory'
import {
  getEntireSlice as getAllNoMovementsHistoryObjects,
} from '../../../../../redux/selectors/histories/noMovementsHistory'
import {
  getProp as getCustomerProp,
  getPropOr as getCustomerPropOr,
} from '../../../../../redux/selectors/customers'
import {
  getAllChildCustomerIdsOfCustomer,
} from '../../../../../redux/selectors/children'
import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../../redux/selectors/permissions'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getCustomerIdsOfAllRelatedTo,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getDoesBrewerHaveCustomerLevelPermission,
  getDoesContractBrewerHaveCustomerLevelPermission,
} from '../../../../../redux/selectors/rewrite/permissionsAtTheCustomerLevel'
import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/forms'

import {
  SHIPMENT_STATUS_PENDING,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_SHIPMENTS,

  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS,
} from '../../../../../constants/permissions'

import {
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
  CUSTOMER_TYPES_PUB,
} from '../../../../../constants'

import {
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


const mapStateToProps = (state, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    formReducerName,
  } = ownProps

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const customerType = getCustomerProp(state, customerId, 'customerType')
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')

  const formName = createFormNameForRedux({
    reducerName: formReducerName,
    customerId,
  })


  const { getFetchStatuses } = fetchStatusSelectors
  const { isFetching, didFetchFail } = getFetchStatuses(state, formName)

  // CODE_COMMENTS_130
  const shouldOriginColumnBeRendered = getShouldOriginColumnBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    customerType,
  })

  const areAnyItemsEditable = getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId)

  const allOutboundFullKegShipmentObjects = getAllOutboundFullKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  const allNoMovementsObjects = getAllNoMovementsHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  return {
    // We need to be extra careful that none of these render new value every
    // time some irrelevant part of the state changes, otherwise our form, which
    // can comprise hundreds or even thousands of components, will re-render on
    // each submit.
    entireItemSkusSlice,
    configuredItemSkuIds,
    allOutboundFullKegShipmentObjects,
    allNoMovementsObjects,
    isFormSubmitting: isFetching,
    hasFormSubmitFailed: didFetchFail,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
    shouldOriginColumnBeRendered,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    entireCustomersSlice,
  }
}

export default connect(
  mapStateToProps,
)(Table)


// are items editable

function getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId) {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  if (
    // CODE_COMMENTS_153
    !getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS,
      customerIdIfThisIsAPerCustomerPermissionsCheck: operatingContractBrewerCustomerId && customerId,
    }) ||
    !getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_SHIPMENTS,
      customerIdIfThisIsAPerCustomerPermissionsCheck: operatingContractBrewerCustomerId && customerId,
    })
  ) { return false }

  // CODE_COMMENTS_168
  if (
    // If this is a CB operating for a contractee
    operatingContractBrewerCustomerId &&
    !getDoesContractBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId: customerId,
      conbrwCustomerId: operatingContractBrewerCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS,
    })
  ) { return false }

  const allOutboundFullKegShipmentObjects = getAllOutboundFullKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  const allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    values_(getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable(
      allOutboundFullKegShipmentObjects,
    ))

  const shipmentObjsInTable = allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable.filter(
    getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
  )
  return shipmentObjsInTable.some(row => getIsindividualItemEditable(
    state,
    row,
    { customerId, operatingContractBrewerCustomerId },
  ))
}


function getIsindividualItemEditable(
  state,
  row,
  additionalPropsToPassToTableCells,
) {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)

  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = additionalPropsToPassToTableCells

  // only pending shipments are allowed to be edited
  if (row.status !== SHIPMENT_STATUS_PENDING) { return false }

  const destinationCustomerId = row.destinationCustomerId
  const destinationCustomerType = getCustomerPropOr(state, destinationCustomerId, 'customerType')

  // This is a rare case in which we have a shipment to a customer we've never
  // been related to. See CODE_COMMENTS_108.
  if (!destinationCustomerType) { return false }


  // The following block checks to see whether the origin of the shipment was
  // from the Brewer's own inventory location or one of its Contract Brewing
  // inventory locations. This doesn't apply when a CB is operating for a
  // contractee, because in that case, the fetch for all outbound shipments will
  // return only shipments from the Brewer's Contract Brewing inventory
  // location, not from the Brewer's own inventory location (the logged-in CB
  // doesn't have access to that info).
  if (!operatingContractBrewerCustomerId) {
    const customerIdToConsiderAsOrigin =
      getOriginCustomerIdForOutboundShipmentsHistoryTableAssumingContracteeFillsMeanConbrwOrigin(
        state,
        customerId,
        row,
      )

    // When would the originCustomerId of the shipment ever not be the currently
    // operating customerId? This only happens for brewers (non-contractee
    // brewers, to be exact), and only when the brewer is allowed to report
    // outbound shipments from one of this Contract Brewer's locations to a
    // distributor. See CODE_COMMENTS_128 for details.
    if (customerIdToConsiderAsOrigin !== customerId) {
      // If the getOriginCustomerIdFor...() function returns null, it means
      // there's a backend bug (which the web app will report to our 3rd-party
      // logging service elsewhere). But we don't want this bug to crash the app,
      // so return not editable.
      if (!customerIdToConsiderAsOrigin) { return false }

      return getCanBrewerEditShipmentsFromContractBrewerLocation(
        state,
        customerId,
        customerIdToConsiderAsOrigin,
      )
    }
  }


  // CODE_COMMENTS_123: the shipment is not allowed to be edited If the
  // destination is a Pub customer and either:
  //   1) the currently operating customer is related to the pub but the
  //   relationship is not currently active, or
  //   2) the pub is a child of the currently operating customer but the pub
  //   customer is not currently active the customer's status is inactive
  if (destinationCustomerType !== CUSTOMER_TYPES_PUB) { return true }
  const activeRelatedToPubIds = getCustomerIdsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId: row.originCustomerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_PUB,
  })
  const activeChildPubs = getAllChildCustomerIdsOfCustomer({
    state,
    customerId: row.originCustomerId,
    // CODE_COMMENTS_112, CODE_COMMENTS_122
    onlyCustomersWhoAreNotCurrentlyInactive: true,
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_PUB,
  })
  return (
    activeRelatedToPubIds.includes(destinationCustomerId) ||
    activeChildPubs.includes(destinationCustomerId)
  )
}


// helper functions

// checks that the Brewer has customer-level permissions (CODE_COMMENTS_168) to
// report shipments on behalf of its Contract Brewer.
function getCanBrewerEditShipmentsFromContractBrewerLocation(
  state,
  brwCustomerId,
  conbrwCustomerId,
) {
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  return getDoesBrewerHaveCustomerLevelPermission({
    entireRelationshipsSlice,
    entireCustomersSlice,
    entireContractsSlice,
    brwCustomerId,
    conbrwCustomerId,
    permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS,
  })
}
