import { connect } from 'react-redux'

import HistoryTabs from './HistoryTabs'

import {
  getProp as getCustomerProp,
} from '../../redux/selectors/customers'

import {
  createTabPanesByCustomerType,
} from './util'

const mapStateToProps = (state, props) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    isRentalCustomer,
    translate,
  } = props
  const customerType = getCustomerProp(state, props.customerId, 'customerType')
  const currency = getCustomerProp(state, props.customerId, 'currency')
  const tabPanes = createTabPanesByCustomerType({
    state,
    customerId,
    operatingContractBrewerCustomerId,
    isRentalCustomer,
    customerType,
    translate,
  })
  return {
    tabPanes,
    currency,
  }
}

export default connect(
  mapStateToProps,
)(HistoryTabs)
