/* eslint-disable max-len */

import React from 'react'
import {
  Dropdown,
} from 'semantic-ui-react'

import get_ from 'lodash/get'


import ReactDatePicker from '../../../../../../../common-components/rewrite/ReactDatepicker'


import KegQtyInput from '../../KegQtyInput'
import InputNoAutoComplete from '../../../../../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import ContainerTypesDifferences from '../../../../../../ContainerTypesDifferences'

// import {
//   getContainerTypeFromItemSkuId,
// } from '../../../../../../../redux/selectors/rewrite/itemSkus'

import {
  REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
} from '../../../../../../../constants/formAndApiUrlConfig/reportKegFills'

import {
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
} from '../../../../../../../constants/formAndApiUrlConfig/reportSelfDistAndPubShipments'

import {
  FIELD_NAME_SOURCE_CUSTOMER,
  FIELD_NAME_DESTINATION_CUSTOMER,
  FIELD_NAME_INTERNAL_REFERENCE_NUM,
  FIELD_NAME_DATE_SHIPPED,
  REFERENCE_NUMBER_REQUIRED_EXPLANATION,

  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
} from '../../../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  getDateFieldFencingOfReportShipmentsForm, getItemSkuIdsForReportShipmentsForm,
} from '../../../../../util'

import {
  NUM_KEGS_TO_SHOW_LARGE_SHIPMENT_REPORT_INPUT_WARNING,
} from '../../../../../../../config'
import { getHumanReadableContainerTypeFromItemSku } from '../../../../../../../utils'
import {
  REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../../../../../../constants/formAndApiUrlConfig/reportOutboundShipments'

const ContainerGroupByComponent = props => (
  <div style={{ padding: '0 1.5em' }} id='group-by-rows'>
    <div className="required field toplabel"><label>{props?.groupByLabel}</label></div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>{props?.children}</div>
  </div>
)

export function createFieldArrayDefinitions(props) {
  const {
    customerId,
    entireItemSkusSlice,
    entireCustomersSlice,
    customersFieldsDropdownOptions,
    textToAppendToSourceFieldLabel,
    textToAppendToDestinationFieldLabel,
    itemSkuIds,
    fieldArrayName,
    whichCustomersFieldMustBeChosenFirstSourceOrDestination,
    onChangeFormValue,
    formType,
    translate,
  } = props
  // Container Quantities Fields Definitions
  const containerQtysFieldsDefinitions = itemSkuIds.map(itemSkuId => (
    {
      showLabel: true,
      groupByFields: true,
      groupByLabel: `${translate('reportShipments.Keg Types')}:`,
      groupByComponent: ContainerGroupByComponent,
      topLabel: getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice),
      fieldName: itemSkuId,
      formFieldProps: {
        required: true,
        style: { width: '85px' },
      },
      fieldComponent: KegQtyInput,
      // eslint-disable-next-line no-empty-pattern
      ...(formType === 'selfCollection' && {
        renderOnlyIf: ({
          values,
        }) => {
          const sourceCustomerSkus = getItemSkuIdsForReportShipmentsForm({
            entireItemSkusSlice,
            itemSkuIds: entireCustomersSlice?.[values?.[FIELD_NAME_SOURCE_CUSTOMER]]?.itemSkuIds,
          })
          return sourceCustomerSkus?.length ? sourceCustomerSkus?.includes(itemSkuId) : true
        },
      }),
      fieldProps: ({
        formValues,
        rowIndex,
      }) => {
        const valueAsString = get_(
          formValues,
          [fieldArrayName, rowIndex, itemSkuId],
          '', // CODE_COMMENTS_245
        )
        const valueAsNumber = Number(valueAsString || 0)
        return {
          placeholder: translate('reportShipments.Qty'),
          value: valueAsString,
          // This warning signals to the KegQtyInput component that it should make
          // itself orange as a signal to the user that they've typed in an
          // unusually large value (see CODE_COMMENTS_233).
          shouldHaveWarningClassApplied: valueAsNumber >= NUM_KEGS_TO_SHOW_LARGE_SHIPMENT_REPORT_INPUT_WARNING,
          onChange: (event, { value }) => {
            onChangeFormValue({
              fieldArrayName,
              rowIndex,
              fieldName: itemSkuId,
              value,
              ...props,
            })
          },
        }
      },
      arbitraryComponentAfterLabel: () => (
        <ContainerTypesDifferences
          customerId={customerId}
          itemSkuId={itemSkuId}
        />
      ),
    }
  ))


  // props of Customer field that must be chosen first (applied to the
  // <Dropdown> compoonent)
  const dropdownOptionsOfCustomersFieldThatMustBeChosenFirst = customersFieldsDropdownOptions.map(def => (
    { key: def.id, value: def.id, text: def.name }
  ))
  const sourceMustBeChosenFirst = whichCustomersFieldMustBeChosenFirstSourceOrDestination ===
    WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE
  const fieldNameOfCustomersFieldThatMustBeChosenFirst = sourceMustBeChosenFirst
    ? FIELD_NAME_SOURCE_CUSTOMER
    : FIELD_NAME_DESTINATION_CUSTOMER

  const areThereMultipleOptionsForCustomerFieldThatMustBeChosenFirst = customersFieldsDropdownOptions.length > 1

  let placeholder1
  if (sourceMustBeChosenFirst) {
    placeholder1 = 'Select Source'
  } else if (fieldArrayName === REPORT_KEG_FILLS_FIELD_ARRAY_NAME) {
    placeholder1 = 'Select Brewer'
  } else {
    placeholder1 = 'Select Destination'
  }

  const fieldPropsOfCustomerFieldThatMustBeChosenFirst = ({
    formValues,
    rowIndex,
  }) => ({
    options: dropdownOptionsOfCustomersFieldThatMustBeChosenFirst,
    placeholder: translate(`reportShipments.${placeholder1}`),
    value: get_(
      formValues,
      [fieldArrayName, rowIndex, fieldNameOfCustomersFieldThatMustBeChosenFirst],
      '', // CODE_COMMENTS_245
    ),
    // When the user changes the customerThatMustBeChosenFirst field, clear the
    // customerThatMustBeChosenSecond field
    onChange: (event, { value }) => {
      onChangeFormValue({
        fieldArrayName,
        rowIndex,
        fieldName: fieldNameOfCustomersFieldThatMustBeChosenFirst,
        value,
        sourceMustBeChosenFirst,
      })
    },
    // visual props for the Semantic UI React <Dropdown> component
    search: true,
    selection: true,
  })


  // props of Customer field that must be chosen second (applied to the
  // <Dropdown> compoonent)
  const fieldNameOfCustomersFieldThatMustBeChosenSecond = sourceMustBeChosenFirst
    ? FIELD_NAME_DESTINATION_CUSTOMER
    : FIELD_NAME_SOURCE_CUSTOMER
  const fieldPropsOfCustomerFieldThatMustBeChosenSecond = ({
    formValues,
    rowIndex,
  }) => {
    const valueOfChosenCustomerOfFieldThatMustBeChosenFirst =
      get_(formValues, [fieldArrayName, rowIndex, fieldNameOfCustomersFieldThatMustBeChosenFirst])

    let customersFieldsDropdownOptionsOfFieldThatMustBeChosenSecond
    if (areThereMultipleOptionsForCustomerFieldThatMustBeChosenFirst) {
      customersFieldsDropdownOptionsOfFieldThatMustBeChosenSecond =
        valueOfChosenCustomerOfFieldThatMustBeChosenFirst
          ? customersFieldsDropdownOptions.find(o => (
            o.id === valueOfChosenCustomerOfFieldThatMustBeChosenFirst
          )).choicesForOtherCustomersFieldWhenThisCustomerIsSelected
          // If the user hasn't yet chosen the fieldThatMustBeChosenFirst, display
          // no options for the fieldThatMustBeChosenSecond. This doesn't matter
          // because the secondCustomer dropdown will be disabled, we just set it
          // here so we don't hit an error on the map() function in the next line
          // of code.
          : []
    } else {
      customersFieldsDropdownOptionsOfFieldThatMustBeChosenSecond =
        customersFieldsDropdownOptions[0].choicesForOtherCustomersFieldWhenThisCustomerIsSelected
    }


    const dropdownOptionsOfCustomersFieldThatMustBeChosenSecond =
      customersFieldsDropdownOptionsOfFieldThatMustBeChosenSecond.map(def => (
        { key: def.id, value: def.id, text: def.name }
      ))


    let placeholder2
    if (
      areThereMultipleOptionsForCustomerFieldThatMustBeChosenFirst &&
      !valueOfChosenCustomerOfFieldThatMustBeChosenFirst
    ) {
      placeholder2 = `Choose ${sourceMustBeChosenFirst ? 'source' : 'destination'} first`
    } else {
      placeholder2 = `Select ${sourceMustBeChosenFirst ? (fieldArrayName === REPORT_KEG_FILLS_FIELD_ARRAY_NAME ? 'brewer' : 'destination') : 'source'}`
    }

    return {
      options: dropdownOptionsOfCustomersFieldThatMustBeChosenSecond,
      placeholder: translate(`reportShipments.${placeholder2}`),
      value: get_(
        formValues,
        [fieldArrayName, rowIndex, fieldNameOfCustomersFieldThatMustBeChosenSecond],
        '', // CODE_COMMENTS_245
      ),
      onChange: (event, { value }) => {
        onChangeFormValue({
          fieldArrayName,
          rowIndex,
          fieldName: fieldNameOfCustomersFieldThatMustBeChosenSecond,
          value,
          sourceMustBeChosenFirst,
        })
      },
      rerenderComponentWhenThesePropsChange: { valueOfChosenCustomerOfFieldThatMustBeChosenFirst },
      // visual props for the Semantic UI React <Dropdown> component
      search: true,
      selection: true,
      noResultsMessage: translate('reportShipments.None; click Request New Distributor'),
    }
  }

  const sourceFieldProps = sourceMustBeChosenFirst
    ? fieldPropsOfCustomerFieldThatMustBeChosenFirst
    : fieldPropsOfCustomerFieldThatMustBeChosenSecond

  const destinationFieldProps = sourceMustBeChosenFirst
    ? fieldPropsOfCustomerFieldThatMustBeChosenSecond
    : fieldPropsOfCustomerFieldThatMustBeChosenFirst


  // Source And Destination Customers Fields formFieldProps (these will be
  // applied to the Semantic UI React <Form.Field> compoonents)
  const formFieldPropsThatApplyToBothFields = {
    required: true,
    style: { width: '300px' },
  }

  const formFieldPropsOfCustomerFieldThatMustBeChosenFirst = formFieldPropsThatApplyToBothFields

  // If the customer field that must be chosen first hasn't been chosen yet,
  // disable the customer field that must be chosen second.
  const formFieldPropsOfCustomerFieldThatMustBeChosenSecond = ({ formValues, rowIndex }) => {
    // If there's only one CustomerFieldThatMustBeChosenFirst option, the field
    // will be displayed as text only, so no need to set the second customer
    // field to disabled.
    if (!areThereMultipleOptionsForCustomerFieldThatMustBeChosenFirst) {
      return formFieldPropsThatApplyToBothFields
    }

    const valueOfChosenCustomerOfFieldThatMustBeChosenFirst =
      formValues[fieldArrayName][rowIndex][fieldNameOfCustomersFieldThatMustBeChosenFirst]
    return valueOfChosenCustomerOfFieldThatMustBeChosenFirst
      ? formFieldPropsThatApplyToBothFields
      : {
        ...formFieldPropsThatApplyToBothFields,
        disabled: true,
      }
  }

  const sourceFormFieldProps = sourceMustBeChosenFirst
    ? formFieldPropsOfCustomerFieldThatMustBeChosenFirst
    : formFieldPropsOfCustomerFieldThatMustBeChosenSecond
  const destinationFormFieldProps = sourceMustBeChosenFirst
    ? formFieldPropsOfCustomerFieldThatMustBeChosenSecond
    : formFieldPropsOfCustomerFieldThatMustBeChosenFirst


  // If there's only one option for the customer that must be chosen first,
  // display the customer's name in place of a dropdown
  const sourceNoFieldJustTextPropIfNecessary =
    sourceMustBeChosenFirst && !areThereMultipleOptionsForCustomerFieldThatMustBeChosenFirst
      ? { noFieldJustText: customersFieldsDropdownOptions[0].name }
      : {}
  const destinationNoFieldJustTextPropIfNecessary =
    !sourceMustBeChosenFirst && !areThereMultipleOptionsForCustomerFieldThatMustBeChosenFirst
      ? { noFieldJustText: customersFieldsDropdownOptions[0].name }
      : {}


  // Finally, return the actual field array definitions
  return [
    {
      topLabel: translate(`reportShipments.Source${textToAppendToSourceFieldLabel || ''}`),
      fieldName: FIELD_NAME_SOURCE_CUSTOMER,
      formFieldProps: sourceFormFieldProps,
      fieldComponent: Dropdown,
      fieldProps: sourceFieldProps,
      ...(sourceNoFieldJustTextPropIfNecessary),
      // After user requests new distributor
      rerenderComponentWhenThesePropsChange: { customersFieldsDropdownOptions },
    },

    {
      topLabel: translate(`reportShipments.Destination${textToAppendToDestinationFieldLabel || ''}`),
      fieldName: FIELD_NAME_DESTINATION_CUSTOMER,
      formFieldProps: destinationFormFieldProps,
      fieldComponent: Dropdown,
      fieldProps: destinationFieldProps,
      ...(destinationNoFieldJustTextPropIfNecessary),
      // After user requests new distributor
      rerenderComponentWhenThesePropsChange: { customersFieldsDropdownOptions },
    },


    {
      topLabel: translate('reportShipments.Your Reference Number'),
      fieldName: FIELD_NAME_INTERNAL_REFERENCE_NUM,
      formFieldProps: {
        required: (
          // CODE_COMMENTS_95
          ![
            REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
            REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
            REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
          ].includes(fieldArrayName)
        ),
        style: { width: '150px' },
      },
      fieldComponent: InputNoAutoComplete,
      fieldProps: ({
        formValues,
        rowIndex,
      }) => ({
        value: get_(
          formValues,
          [fieldArrayName, rowIndex, FIELD_NAME_INTERNAL_REFERENCE_NUM],
          '', // CODE_COMMENTS_245
        ),
        onChange: (event, { value }) => {
          onChangeFormValue({
            fieldArrayName,
            rowIndex,
            fieldName: FIELD_NAME_INTERNAL_REFERENCE_NUM,
            value,
            ...props,
          })
        },
      }),
      informationalIconContent: (
        [
          REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
          REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
          REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
        ].includes(fieldArrayName)
          ? translate(`reportShipments.Optional for ${fieldArrayName === REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME ? 'Outbound' : 'Self-Dist/Pub'} shipments (you can fill this out for your own records)`)
          : translate(`reportShipments.${REFERENCE_NUMBER_REQUIRED_EXPLANATION}`)
      ), // CODE_COMMENTS_95
    },


    {
      topLabel: translate(`reportShipments.${createDateShippedFieldLabel(fieldArrayName === REPORT_KEG_FILLS_FIELD_ARRAY_NAME)}`),
      fieldName: FIELD_NAME_DATE_SHIPPED,
      formFieldProps: { required: true },
      fieldComponent: ReactDatePicker,
      fieldProps: ({
        formValues,
        rowIndex,
      }) => ({
        ...getDateFieldFencingOfReportShipmentsForm(),
        compact: true,
        value: get_(
          formValues,
          [fieldArrayName, rowIndex, FIELD_NAME_DATE_SHIPPED],
          '', // CODE_COMMENTS_245
        ),
        onChange: value => {
          onChangeFormValue({
            fieldArrayName,
            rowIndex,
            fieldName: FIELD_NAME_DATE_SHIPPED,
            value,
            ...props,
          })
        },
      }),
    },


    ...containerQtysFieldsDefinitions,
  ]
}

function createDateShippedFieldLabel(
  isThisAKegFillsForm,
) {
  if (isThisAKegFillsForm) {
    return 'Date Filled' // CODE_COMMENTS_93
  }
  return 'Date Shipped'
}
