/**
 * CODE_COMMENTS_31
 */
import {
  FIELD_LABEL_GOOD_PALLETS as ACK_INBOUND_SHIPMENTS_FORM_LABEL_GOOD_PALLETS,
  FIELD_LABEL_BAD_PALLETS as ACK_INBOUND_SHIPMENTS_FORM_LABEL_BAD_PALLETS,
} from '../acknowledgeInboundShipments'

export const INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM = 'Your Reference #'

export const INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_SOURCE = 'Origin'
export const INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_CARRIER = 'Carrier'
export const INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_DELIVERY_DATE = 'Delivery Date'
export const INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ACTUAL_DELIVERY_DATE = 'Actual Delivery Date'
export const INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ESTIMATED_DELIVERY_DATE = 'Estimated Delivery Date'

export const INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_FOREIGN_KEGS = 'Foreign Kegs'

export const INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_GOOD_PALLETS = ACK_INBOUND_SHIPMENTS_FORM_LABEL_GOOD_PALLETS
export const INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_BAD_PALLETS = ACK_INBOUND_SHIPMENTS_FORM_LABEL_BAD_PALLETS
