import {
  SET_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503,
} from '../../actions/actionTypes'


const initialState = {
  start: null,
  end: null,
}

export default function setStartAndEndTimesFrom503(state=initialState, action) {
  switch (action.type) {
    case SET_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503: {
      if (
        state.start === action.payload.start
        && state.end === action.payload.end
      ) {
        return state
      }
      return action.payload
    }

    default:
      return state
  }
}
