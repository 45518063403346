import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'
import CustomerPortal from '../../../../CustomerPortal'
import History from '../../../../History'

import {
  CUSTOMER_TYPES_DISTRIBUTOR,
} from '../../../../../constants'

export default memo(({ // Why memo? See CODE_COMMENTS_253
  customerId,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <div>
      <CustomerPortal
        customerId={customerId}
        customerType={CUSTOMER_TYPES_DISTRIBUTOR}
      />
      <History customerId={customerId} translate={translate} />
    </div>
  )
})
