import React from 'react'

import { useTranslation } from 'react-i18next'
import Form from '../containers/Form'

import PublicFormFailuresContent from '../../../../../../common-components/FormSubmission/PublicFormFailuresContent'
import DismissableMessageClearingFetchStatus from '../../../../../../common-components/FormSubmission/PublicFormFailuresContent/DismissableMessageClearingFetchStatus'

import ContactCard from '../../../../../../common-components/ContactCard'

import {
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL,
  CUSTOMER_REPS_GENERIC,
} from '../../../../../../constants'


export default ({
  isFetching,
  didFetchFail,
  ...rest
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <div>
      {didFetchFail && !isFetching
        ? <PublicFormFailuresContent
          formName={REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL}

          customFailureRenderInstructions={[
            {
              when: ({ errorCode }) => errorCode === 404,
              render: () => (
                <DismissableMessageClearingFetchStatus
                  formName={REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL}
                  content={
                    <div>
                      <p>{translate('No such email address exists in our system. Please contact our support team for further help.')}</p>
                      <ContactCard repType={CUSTOMER_REPS_GENERIC} />
                    </div>
                  }
                />
              ),
            },
          ]}
        />
        : null
      }
      <Form {...rest} />
    </div>
  )
}
