import {
  SAVE_TIME_OF_MOST_RECENT_USER_ACTIVITY,
} from '../actions/actionTypes'

const initialState = null

export default (state=initialState, action) => {
  switch (action.type) {
    case SAVE_TIME_OF_MOST_RECENT_USER_ACTIVITY: {
      // action.payload should be a Unix timestamp with milliseconds
      return action.payload
    }

    default:
      return state
  }
}
