import { connect } from 'react-redux'


import acknowledgeInboundShipmentsForm from '../components/Form'

import {
  getEntireSlice as getEntireInboundUnacknowledgedShipmentsSlice,
} from '../../../redux/selectors/rewrite/inboundUnacknowledgedShipments'
import {
  getEntireSlice as getEntireCustomersSlice,
  getProp as getCustomerProp,
} from '../../../redux/selectors/rewrite/customers'
import {
  getContracts,
  getEntireSlice as getEntireContractsSlice,
} from '../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
} from '../../../redux/selectors/rewrite/subsidiaries'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import { getUnacknowledgedShipmentSkus } from '../util'
import { PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CONBRW } from '../../../constants'


/*
 * *****************************************************************************
 * mapStateToProps
 * *****************************************************************************
*/

const mapStateToProps = (state, { customerId }) => {
  const customers = getEntireCustomersSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const inboundUnacknowledgedShipmentsSliceForThisCustomer = getEntireInboundUnacknowledgedShipmentsSlice(
    state,
    customerId,
  )
  const businessUnitId = getCustomerProp(state, customerId, 'businessUnitId')
  const subsidiaryId = getCustomerProp(state, customerId, 'subsidiaryId')
  const customerType = getCustomerProp(state, customerId, 'customerType')
  const itemSkuIdList = getCustomerProp(state, customerId, 'itemSkuIds')
  const { contractEndDate = null } = getContracts({
    entireContractsSlice,
    customers,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CONBRW],
    notExpiredOnly: false,
    activeStatusOnly: true,
    noFutureContracts: true,
    mostRecentOnly: true,
  }) || {}
  // eslint-disable-next-line max-len
  const itemSkuIds = getUnacknowledgedShipmentSkus(inboundUnacknowledgedShipmentsSliceForThisCustomer, entireItemSkusSlice, itemSkuIdList, subsidiaryId, customerType)

  return {
    customers,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    inboundUnacknowledgedShipmentsSliceForThisCustomer,
    businessUnitId,
    subsidiaryId,
    itemSkuIds,
    contractEndDate,
  }
}


export default connect(
  mapStateToProps,
)(acknowledgeInboundShipmentsForm)
