import React from 'react'
import { Button, Header } from 'semantic-ui-react'


import { Trans, useTranslation } from 'react-i18next'
import ContactCard from '../../../common-components/ContactCard'


import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../constants'


export default Component => props => {
  const {
    customerId,
    hasAnyInvoices,
    resubmitFunc,
  } = props
  if (hasAnyInvoices) {
    return <Component {...props} />
  }
  const { t: translate } = useTranslation('pagelabels')
  return (
    <div>
      <Header as="h3" inverted color="red">
        <Trans ns="pagelabels" i18nKey="payBalance.You have no invoices!">
          You have no invoices!
        </Trans>
      </Header>
      <p>
        <Trans ns="pagelabels" i18nKey="payBalance.You have no invoices on file with us, so you have no balance to pay. Contact us if you feel this is a mistake.">
          {'You have no invoices on file with us, so you have no balance to pay. Contact us if you feel this is a mistake.'}
        </Trans>
      </p>
      <ContactCard customerId={customerId} repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE} />
      <Button
        color="blue"
        content={translate('payBalance.Re-fetch My Invoices')}
        onClick={resubmitFunc}
      />
    </div>
  )
}
