/* eslint-disable max-len */

import {
  createDisplayedShipmentNumber,
  createDisplayedProNumber,
  createDisplayedShipmentType,
  createDisplayedShipmentDate,
  createItemSkuIdFieldsDefinitions,
  createDisplayedOrderNumber, createDisplayedMovementType,
} from '../common/displayedShipmentDataEtc'

import {
  createDisplayedOriginNameForOutboundShipmentsHistoryTable,
} from '../outboundFullKegShipmentHistory/displayedDataEtc/displayedOutboundFullKegShipmentDataEtc'

import {
  getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
} from '../outboundFullKegShipmentHistory'

import {
  INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
  INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
} from '../../../../../../constants/formAndApiUrlConfig/histories/InboundFullKegShipmentHistory'
import { CUSTOMER_TYPES_WAREHOUSE } from '../../../../../../constants'


// Used by both the Full Details modal and the "Download File As CSV" feature
export function getInfoDefinitionsInboundFullKegShipmentFullDetails({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  customerType,
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged = false,
  entireCustomersSlice,
  customerId,
}) {
  return [
    { heading: 'Shipment #', cellContent: createDisplayedShipmentNumber },
    { heading: 'Order #', cellContent: createDisplayedOrderNumber },
    ...(entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ?
      [{ heading: 'Shipment Type', cellContent: createDisplayedShipmentType }]
      : [{ heading: 'Movement Type', cellContent: createDisplayedMovementType }]
    ),
    { heading: INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN, cellContent: createDisplayedOriginNameForOutboundShipmentsHistoryTable },
    { heading: 'Ship Date', cellContent: createDisplayedShipmentDate },
    { heading: 'Status', cellContent: getDisplayedHumanReadableStatusOfOutboundFullkegShipment },
    { heading: INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM, cellContent: createDisplayedProNumber('none') },
    ...createItemSkuIdFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
      customerType,
      useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
    }),
  ]
}
