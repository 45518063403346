import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'semantic-ui-react'


import ModalWithCatastrophicFailureErrorBoundary from '../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'

import RequestNewDistributorForm from './RequestNewDistributorForm'

import {
  getShouldSourceStepBeRendered,
} from '../util'


import './styles.css'
import { URL_PATHS } from '../../../constants'
import { ENABLE_V2_REQUEST_NEW_DISTRIBUTOR } from '../constants'


export default props => {
  const shouldSourceStepBeRendered = getShouldSourceStepBeRendered(props)
  const [modalOpen, setModalOpen] = useState(false)

  if (ENABLE_V2_REQUEST_NEW_DISTRIBUTOR) {
    return (
      <Link to={URL_PATHS.requestNewDistributor.path}>
        <Button
          type="button"
          id="request-new-distributor-button" // for Selenium tests
          size="mini"
          basic
          color="blue"
        >
          Add New Location
        </Button>
      </Link>
    )
  }

  return (
    <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      closeIcon
      closeOnDimmerClick={false} // CODE_COMMENTS_64
      open={modalOpen}
      onClose={() => { setModalOpen(false) }}
      size={shouldSourceStepBeRendered ? 'large' : 'small'}
      id="request-new-distributor-section" // for Selenium tests
      trigger={
        <Button
          // setting the type to "button" is extremely important: without it,
          // the form will think that this is the submit button and disable
          // all inputs the first time it is clicked. (see  CODE_COMMENTS_260)
          type="button"
          // setting the size of mini ensures that this button is exactly the
          // same size as the "Add shipments" button
          id="request-new-distributor-button" // for Selenium tests
          size="mini"
          basic
          color="blue"
          onClick={() => { setModalOpen(true) }}
        >
          Add New Location
        </Button>
      }
      // These lines are needed because otherwise the <Loader> spinners will be
      // invisible when the forms (i.e. multiple forms, one for each step) are
      // fetching. commenting the blah. See CODE_COMMENTS_72
      dimmer="inverted"
      className="test-full-order-details faux-inverted-dimmer"
    >
      <Modal.Header>
        Add New Location
      </Modal.Header>
      <Modal.Content>
        <div
          // for styling all Request New Distributor forms (enter state form,
          // enter city form, etc)
          className="request-new-distributor"
        >
          <RequestNewDistributorForm
            {...props}
            closeModalFunc={() => { setModalOpen(false) }}
            shouldSourceStepBeRendered={shouldSourceStepBeRendered}
          />
        </div>
      </Modal.Content>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}
