import { connect } from 'react-redux'


import DownloadInboundEmptyKegHistoryTableAsFile from './components'

import {
  getEntireSlice as getAllInboundEmptyKegShipmentHistoryObjects,
} from '../../../../../redux/selectors/histories/inboundEmptyKegShipmentHistory'
import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
} from '../../../../../redux/selectors/rewrite/subsidiaries'

import {
  getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
} from '../../util/shipmentHistory/inboundEmptyKegShipmentHistory'
import {
  getAllOriginAndDestinationCustomerObjectsFromShipmentObjects,
} from '../../util/shipmentHistory/common/util'

import {
  HISTORY_FORM_NAME_INBOUND_EMPTY_KEG_SHIPMENTS,
} from '../../../../../constants'

import {
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'
import { getEntireSlice as getEntireCustomersSlice } from '../../../../../redux/selectors/rewrite/customers'


const mapStateToProps = (state, { customerId, operatingContractBrewerCustomerId }) => {
  const historyFormName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_INBOUND_EMPTY_KEG_SHIPMENTS,
    customerId,
  })
  const mostRecentlySubmittedFormValues = getMostRecentlySubmittedFormValues(state, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return {} }

  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')
  const subsidiaryId = getCustomerProp(state, customerId, 'subsidiaryId')
  const allInboundEmptyKegShipmentHistoryObjects = getAllInboundEmptyKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  const allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable(
      allInboundEmptyKegShipmentHistoryObjects,
    )
  // We don't actually need destination customers for the inbound shipments
  // file: The destination is always the currently-operated-for customer, so the
  // file doesn't even have a "Destination" field. The reason we gather this
  // data is because this is what the outbound shipments file needs, and its
  // easier to simply re-use this function than to write a special function for
  // inbounds that returns a little less data but only the data we need.
  const allOriginAndDestinationCustomerObjectsToBeIncludedInDownloadShipmentsHistoryFile =
    getAllOriginAndDestinationCustomerObjectsFromShipmentObjects({
      state,
      arrayOrObjectOfObjects: allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
    })
  const entireCustomersSlice = getEntireCustomersSlice(state)
  return {
    customerId,
    entireCustomersSlice,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    allInboundEmptyKegShipmentHistoryObjects,
    subsidiaryId,
    configuredItemSkuIds,
    allOriginAndDestinationCustomerObjectsToBeIncludedInDownloadShipmentsHistoryFile,
    mostRecentlySubmittedFormValues,
  }
}


export default connect(
  mapStateToProps,
)(DownloadInboundEmptyKegHistoryTableAsFile)
