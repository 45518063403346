import isArray_ from 'lodash/isArray'
import isPlainObject_ from 'lodash/isPlainObject'
import get_ from 'lodash/get'
import values_ from 'lodash/values'

import {
  isNumberValidator,
  isStringOrNumberValidator,
  isFalsyValidator,
  isBooleanValidator,
  isArrayOfStringsValidator,
} from '../simpleValidators'

import {
  validatorCombinerWithOrLogic,
  createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers,
} from '../util'

import {
  individualPropsValidatorsAndTransformersOfCustomerObjectReceivedFromCustomersCall,
} from '../customers'

import {
  convertObjContainingCustomerToRelationsMapIntoArrayOfRelationshipObjects,
} from '../../relatedToOrFrom'

import {
  logErrorMessage,
  logObjectHasProblemsErrorMessage,
  LOG_SEVERITY_FATAL,

  CANT_BE_DISPLAYED,
  CAN_STILL_BE_DISPLAYED,
} from '../../../../../utils/thirdPartyLogging'

import {
  getApiUrlPathFromHttpResponseOrErrorObject,
  getMethodFromHttpResponseOrErrorObject,
  isTruthyAndNonEmpty,
} from '../../../../../utils'


export function validateAndFilterRelationshipObjectsBasedOnBasicProps({
  customerInfoResponse,
  relationshipInfoResponse,
}) {
  const relatedToOrFromResponseBody = customerInfoResponse.data
  const relatedToOrFromMapResponseBody = relationshipInfoResponse.data

  const problemsWithRelatedToOrFromResponseBodyStructure =
    getAreThereAnyProblemsWithTheOverallStructureOfTheRelatedToOrFromResponseBody(
      relatedToOrFromResponseBody,
    )
  const problemsWithRelatedToOrFromMapResponseBodyStructure =
    getAreThereAnyProblemsWithTheOverallStructureOfTheRelatedToOrFromMapResponseBody(
      relatedToOrFromMapResponseBody,
    )

  if (
    problemsWithRelatedToOrFromResponseBodyStructure ||
    problemsWithRelatedToOrFromMapResponseBodyStructure
  ) {
    const infoForLogMessages = []
    if (problemsWithRelatedToOrFromResponseBodyStructure) {
      infoForLogMessages.push({
        apiUrlPath: getApiUrlPathFromHttpResponseOrErrorObject(customerInfoResponse),
        method: getMethodFromHttpResponseOrErrorObject(customerInfoResponse),
        httpResponse: customerInfoResponse,
        problemsWithJsonStructure: problemsWithRelatedToOrFromResponseBodyStructure,
      })
    }
    if (problemsWithRelatedToOrFromMapResponseBodyStructure) {
      infoForLogMessages.push({
        apiUrlPath: getApiUrlPathFromHttpResponseOrErrorObject(relationshipInfoResponse),
        method: getMethodFromHttpResponseOrErrorObject(relationshipInfoResponse),
        httpResponse: relationshipInfoResponse,
        problemsWithJsonStructure: problemsWithRelatedToOrFromMapResponseBodyStructure,
      })
    }
    infoForLogMessages.forEach(info => {
      logErrorMessage({
        message: `Fatal problems with JSON structure of ${info.method} ${info.apiUrlPath}`,
        severity: LOG_SEVERITY_FATAL,
        additionalInfo: {
          details: `The JSON response body of ${info.method} ${info.apiUrlPath} has an unexpected structure, making it unparseable by the web app. This is preventing the user's dashboard from loading.`,
          problemsWithJsonStructure: info.problemsWithJsonStructure,
        },
        httpResponse: info.httpResponse,
      })
    })

    return { canDataBeSavedToStore: false }
  }

  const {
    arrayOfCustomerObjects,
    arrayOfRelationshipObjects,
    haveAnyCustomerOrRelationshipObjectsBeenFiltered,
  } = filterMalformedCustomerObjectsAndRelationshipObjects({
    customerInfoResponse,
    relationshipInfoResponse,
  })

  return {
    arrayOfCustomerObjects,
    arrayOfRelationshipObjects,
    canDataBeSavedToStore: true,
    haveAnyCustomerOrRelationshipObjectsBeenFiltered,
  }
}


/*
 * *****************************************************************************
 * Overall structure of the response bodies
 * *****************************************************************************
*/

// Returns false if there are no problems or a string describing what's wrong or
// an array of strings describing what's wrong.
function getAreThereAnyProblemsWithTheOverallStructureOfTheRelatedToOrFromResponseBody(
  relatedToOrFromResponseBody,
) {
  const isResponseBodyAnArray = isArray_(relatedToOrFromResponseBody)
  const isResponseBodyFalsy = !relatedToOrFromResponseBody

  if (!isResponseBodyAnArray && !isResponseBodyFalsy) {
    return "The response body needs to be either an array or null, but it's neither of these things"
  }
  return false
}


// relatedToOrFromResponseBody should be an object with this shape:
// {
//   activeOnlyRelations: [...],
//   selectedRelations: {
//     customerToRelationsMap: {
//       [customerId]: { BRW2CONBRW: [...], BRW2DIST: [...], ... },
//       [customerId]: { BRW2CONBRW: [...], BRW2DIST: [...], ... },
//       ...
//     }
//   }
// }
//
// Returns false if there are no problems or a string describing what's wrong.
function getAreThereAnyProblemsWithTheOverallStructureOfTheRelatedToOrFromMapResponseBody(
  relatedToOrFromMapResponseBody,
) {
  if (!isPlainObject_(relatedToOrFromMapResponseBody)) {
    return 'The response body is not an object'
  }

  // This happens when we get a 404
  if (values_(relatedToOrFromMapResponseBody).length === 0) { return false }


  const isActiveOnlyRelationsAnArrayOfStrings = validatorCombinerWithOrLogic(
    isFalsyValidator, // activeOnlyRelations is allowed to equal null
    isArrayOfStringsValidator,
  )({ data: relatedToOrFromMapResponseBody.activeOnlyRelations })
  if (isActiveOnlyRelationsAnArrayOfStrings !== true) {
    return 'activeOnlyRelations is not an array of strings'
  }

  const pathToObjWithCustomerIdsAsKeys = ['selectedRelations', 'customerToRelationsMap']
  const objWithCustomerIdsAsKeys = get_(
    relatedToOrFromMapResponseBody,
    pathToObjWithCustomerIdsAsKeys,
  )
  if (!isPlainObject_(objWithCustomerIdsAsKeys)) {
    return `The ${pathToObjWithCustomerIdsAsKeys.join('.')} prop is not an object`
  }

  const problemsWithinObjWithCustomerIdsAsKeys = []

  Object.keys(objWithCustomerIdsAsKeys).forEach(customerIdKey => {
    const objWithCustType2CustTypeKeys = objWithCustomerIdsAsKeys[customerIdKey]
    const pathToObjWithCustType2CustTypeKeys = [...pathToObjWithCustomerIdsAsKeys, customerIdKey]
    // Each 'customerIdKey' value needs to be an object or falsy
    if (!objWithCustType2CustTypeKeys) { return }
    if (!isPlainObject_(objWithCustType2CustTypeKeys)) {
      problemsWithinObjWithCustomerIdsAsKeys.push(`The ${pathToObjWithCustType2CustTypeKeys.join('.')} prop is not an object.`)
      return // i.e. continue with the forEach() loop
    }

    Object.keys(objWithCustType2CustTypeKeys).forEach(custType2CustTypeKey => {
      const arrayOfRelationshipObjects = objWithCustType2CustTypeKeys[custType2CustTypeKey]
      const pathToThisArrayOfRelationshipObjs = [...pathToObjWithCustType2CustTypeKeys, custType2CustTypeKey]
      if (!isArray_(arrayOfRelationshipObjects)) {
        problemsWithinObjWithCustomerIdsAsKeys.push(`The ${pathToThisArrayOfRelationshipObjs.join('.')} prop is not an array`)
      }
      if (!values_(arrayOfRelationshipObjects).every(value => isPlainObject_(value))) {
        problemsWithinObjWithCustomerIdsAsKeys.push(`Every item of the ${pathToThisArrayOfRelationshipObjs.join('.')} array needs to be an object, and one or more of them is not`)
      }
    })
  })

  if (problemsWithinObjWithCustomerIdsAsKeys.length > 0) {
    return problemsWithinObjWithCustomerIdsAsKeys
  }
  return false
}


/*
 * *****************************************************************************
 * Customer and Relationship Objects themselves
 * *****************************************************************************
*/

const validatorsAndTransformersOfCustomerObjectReceivedFromRelatedToOrFromCall =
    individualPropsValidatorsAndTransformersOfCustomerObjectReceivedFromCustomersCall.filter(
      def => (
        [
          'customerType',
          'id',
          'name',
        ].includes(def.propPath)
      ))

const relationshipObjectValidatorsAndTransformers = [
  {
    propPath: 'destinationCustomerId',
    validatorFn: isStringOrNumberValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
  },
  {
    propPath: 'disabled',
    validatorFn: validatorCombinerWithOrLogic(isFalsyValidator, isBooleanValidator),
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
  },
  {
    propPath: 'effectiveDate',
    validatorFn: isNumberValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
  },
  {
    propPath: 'id',
    validatorFn: isStringOrNumberValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
  },
  {
    propPath: 'sourceCustomerId',
    validatorFn: validatorCombinerWithOrLogic(isFalsyValidator, isStringOrNumberValidator),
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
  },
]

function filterMalformedCustomerObjectsAndRelationshipObjects({
  customerInfoResponse,
  relationshipInfoResponse,
}) {
  let haveAnyCustomerOrRelationshipObjectsBeenFiltered = false

  const relatedToOrFromResponseBody = customerInfoResponse.data
  const relatedToOrFromMapResponseBody = relationshipInfoResponse.data

  let arrayOfCustomerObjects = relatedToOrFromResponseBody
  let arrayOfRelationshipObjects =
    convertObjContainingCustomerToRelationsMapIntoArrayOfRelationshipObjects(relatedToOrFromMapResponseBody)

  arrayOfCustomerObjects = arrayOfCustomerObjects.reduce(
    (acc, customerObj) => {
      const {
        validatedData,
        canDataBeSavedToStore,
        arrayOfProblemsWithData,
      } = createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
        data: customerObj,
        individualPropsValidatorsAndTransformers:
          validatorsAndTransformersOfCustomerObjectReceivedFromRelatedToOrFromCall,
      })
      if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
        const apiUrlPath = getApiUrlPathFromHttpResponseOrErrorObject(customerInfoResponse)
        const httpMethod = getMethodFromHttpResponseOrErrorObject(customerInfoResponse)
        const details = canDataBeSavedToStore
          ? `One of the customer objects returned from ${httpMethod} ${apiUrlPath} has problems but can still be saved to the store. Therefore, the web app can still display all relationships that concern this customer (either as the source or destination).`
          : `One of the customer objects returned from ${httpMethod} ${apiUrlPath} has problems and can't be saved to the store. This is preventing all relationships that concern this customer (either as the source or destination) from being displayed in the web app.`
        logObjectHasProblemsErrorMessage({
          level: canDataBeSavedToStore ? CAN_STILL_BE_DISPLAYED : CANT_BE_DISPLAYED,
          objectType: 'customerObject',
          additionalInfo: {
            details,
            arrayOfProblemsWithData,
            offendingCustomerObjId: customerObj.id,
          },
          httpResponse: customerInfoResponse,
        })
      }
      if (!canDataBeSavedToStore) {
        haveAnyCustomerOrRelationshipObjectsBeenFiltered = true
        return acc
      }
      return [...acc, validatedData]
    },
    [],
  )

  arrayOfRelationshipObjects = arrayOfRelationshipObjects.reduce(
    (acc, relationshipObj) => {
      const {
        validatedData,
        canDataBeSavedToStore,
        arrayOfProblemsWithData,
      } = createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
        data: relationshipObj,
        individualPropsValidatorsAndTransformers:
          relationshipObjectValidatorsAndTransformers,
      })
      if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
        const apiUrlPath = getApiUrlPathFromHttpResponseOrErrorObject(relationshipInfoResponse)
        const httpMethod = getMethodFromHttpResponseOrErrorObject(relationshipInfoResponse)
        const details = canDataBeSavedToStore
          ? `One of the relationship objects returned from ${httpMethod} ${apiUrlPath} has problems but can still be saved to the store. Therefore, the web app can still display this relationship.`
          : `One of the relationship objects returned from ${httpMethod} ${apiUrlPath} has problems and can't be saved to the store. This is preventing this relationship from being displayed in the web app.`
        logObjectHasProblemsErrorMessage({
          level: canDataBeSavedToStore ? CAN_STILL_BE_DISPLAYED : CANT_BE_DISPLAYED,
          objectType: 'relationshipObject',
          additionalInfo: {
            details,
            arrayOfProblemsWithData,
            offendingRelationshipObjId: relationshipObj.id,
          },
          httpResponse: relationshipInfoResponse,
        })
      }
      if (!canDataBeSavedToStore) {
        haveAnyCustomerOrRelationshipObjectsBeenFiltered = true
        return acc
      }
      return [...acc, validatedData]
    },
    [],
  )

  return {
    arrayOfCustomerObjects,
    arrayOfRelationshipObjects,
    haveAnyCustomerOrRelationshipObjectsBeenFiltered,
  }
}
