import { takeEvery } from 'redux-saga/effects'

import uniq_ from 'lodash/uniq'


import {
  shipmentHistorySagaCreator,
} from './shipmentHistorySagaCreator'

import {
  FETCH_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
  SAVE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
} from '../../../actions/actionTypes'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getRelationshipObjectsOfAllRelatedTo,
} from '../../../selectors/rewrite/relationships/relatedToInfo'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'

import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../../constants'


const saga = shipmentHistorySagaCreator({
  saveAction: SAVE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
  historyName: 'inboundShipments',
  // If a Brewer has one or more contract brewers, then it has not only an
  // inbound empty keg shipments history tab but also a keg fills history tab,
  // which lists the kegs that CONBRWs have filled for them (see
  // CODE_COMMENTS_232). Therefore, we want to filter out all keg fills in the
  // inbound empty keg shipments history tab, because those shipments will be
  // listed in the Keg Fills history tab.
  transformInfoBeforeSaveFunc: ({ state, customerId, info: arrayOfShipmentObjs }) => {
    const entireCustomersSlice = getEntireCustomersSlice(state)
    const entireContractsSlice = getEntireContractsSlice(state)
    const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
    const conbrwContractIds = uniq_(
      getRelationshipObjectsOfAllRelatedTo({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        customerId,
        customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
      }).map(o => o.sourcePpfContract),
    )
    return arrayOfShipmentObjs.filter(shipment => !conbrwContractIds.includes((shipment.destinationContractId)))
  },
})

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY, saga],
]
