import React, { Fragment, useEffect, useState } from 'react'
import { Button, Dropdown, Grid, Icon, Message, Modal, Table } from 'semantic-ui-react'

import { Trans, useTranslation } from 'react-i18next'
import {
  FIELD_LABEL_CUSTOMER_ADDRESS,
  FIELD_LABEL_CUSTOMER_NAME,
  FIELD_LABEL_CUSTOMER_NUMBER,
  FIELD_NAME_CUSTOMER_ADDRESS,
  FIELD_NAME_CUSTOMER_NAME,
  FIELD_NAME_CUSTOMER_NUMBER,
} from '../../constants'
import {
  isTruthyAndNonEmpty,
  parseCustIdAndOptionalConbrwCustIdReduxIdentifier,
  processAddressObjectForDisplayWithStreetAddressOnOneLine,
} from '../../../../utils'
import ModalWithCatastrophicFailureErrorBoundary from '../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import RevealContactInfo from '../../../../common-components/RevealContactInfo'
import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CONBRW,
} from '../../../../constants'
import { REQUEST_NEW_DIST_TEXT4, REQUEST_NEW_DIST_TEXT5 } from '../../../../constants/labelCaptions'

const DistributorAddress = ({ obj }) => {
  if (!isTruthyAndNonEmpty(obj)) return null
  // eslint-disable-next-line max-len
  const [streetAddress, cityStateZip] = processAddressObjectForDisplayWithStreetAddressOnOneLine(obj)
  return (
    <span>
      {streetAddress}{' '}{cityStateZip}
    </span>
  )
}

const InfoMessage = ({ showContactInfo, msg, operatingContractBrewerCustomerId, customerId }) => (
  <Message>
    <Icon
      name='info'
      color="blue"
    />
    {msg}
    {showContactInfo &&
      <RevealContactInfo
        customerId={operatingContractBrewerCustomerId || customerId}
        repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
        asPopup
        blockDisplay
      />
    }
  </Message>
)

const DistributorModal = props => {
  const { isSelectedDistAlreadyRelated, modalOpen, distributor = {}, sources = [] } = props
  const selectedDistributorName = distributor?.[FIELD_NAME_CUSTOMER_NAME]
  const [selectedSource, setSelectedSource] = useState({})
  const [alreadyRelated, setAlreadyRelated] = useState(false)
  const { t: translate } = useTranslation('common')
  useEffect(() => {
    const { customerId, customerType } = selectedSource
    if (distributor && customerId) {
      setAlreadyRelated(isSelectedDistAlreadyRelated({
        distributor,
        selectedSource: customerId,
        selectedCustomerType: customerType,
      }))
    }
  }, [isSelectedDistAlreadyRelated, distributor, selectedSource])
  const dataToConfirm = [{
    field: FIELD_LABEL_CUSTOMER_NUMBER,
    value: distributor?.[FIELD_NAME_CUSTOMER_NUMBER],
  },
  {
    field: FIELD_LABEL_CUSTOMER_NAME,
    value: distributor?.[FIELD_NAME_CUSTOMER_NAME],
  },
  {
    field: FIELD_LABEL_CUSTOMER_ADDRESS,
    value: <DistributorAddress obj={distributor?.[FIELD_NAME_CUSTOMER_ADDRESS]?.[0]} />,
  },
  ]
  return (
    <ModalWithCatastrophicFailureErrorBoundary
      open={modalOpen}
      onClose={() => props.handleModalChange()}
      size="small"
    >
      <Modal.Header>{alreadyRelated ? <InfoMessage msg={`${selectedDistributorName} ${translate(REQUEST_NEW_DIST_TEXT4)}`}/> : translate(REQUEST_NEW_DIST_TEXT5)}</Modal.Header>
      <Modal.Content>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column width={6} style={{ fontWeight: 'bold' }}>{translate('Source:')}</Grid.Column>
            <Grid.Column width={10}>
              <Dropdown
                // value={selectedSource?.customerId}
                options={(
                  sources?.map(({
                    id,
                    name,
                  }) => {
                    const {
                      customerId, contractBrewerCustomerId,
                    } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(id)
                    return {
                      key: contractBrewerCustomerId || customerId,
                      value: {
                        customerId: contractBrewerCustomerId || customerId,
                        customerType: contractBrewerCustomerId ? PPF_CONTRACT_TYPES_CONBRW : PPF_CONTRACT_TYPES_BRW,
                      },
                      text: name,
                    }
                  })
                  )
                }
                onChange={(e, { value }) => {
                  setSelectedSource(value)
                }}
                search
                selection
                placeholder={translate('Select Source')}
                name='select-source'
              />
            </Grid.Column>
          </Grid.Row>
          {dataToConfirm.map(field => (
            <Grid.Row key={field.label}>
              <Grid.Column width={6} style={{ fontWeight: 'bold' }}>{`${translate(field.field)}:`}</Grid.Column>
              <Grid.Column width={10}>{field.value}</Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="button"
          color="green"
          size="small"
          onClick={() => props.handleSubmit({ selectedSource: selectedSource?.customerId || '' })}
          id="form-modal-confirm-info-submit-button"
          disabled={!selectedSource?.customerId?.length || alreadyRelated}
        >
          {translate('Add New Location')}
        </Button>
        <Button
          type="button"
          color="grey"
          size="small"
          onClick={() => props.handleModalChange()}
        >
          {translate('Cancel')}
        </Button>
      </Modal.Actions>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}


const DistributorTable = ({
  sources= [],
  distributorObjs = [],
  requestNewDistributor,
  setSelectedDistributor,
  selectedDistributor,
  isSelectedDistAlreadyRelated,
}) => {
  const { t: translate } = useTranslation('common')
  const [modalOpen, setModalOpen] = useState(false)
  const handleSubmit = ({ selectedSource }) => {
    if (!selectedSource?.length) { return }
    setModalOpen(!modalOpen)
    requestNewDistributor({ selectedSource })
  }

  const handleModalChange = (distributor={}) => {
    setSelectedDistributor(distributor)
    setModalOpen(!modalOpen)
  }
  const displayedColumns = [
    { fieldLabelProps: { style: { width: '200px' } }, fieldLabel: FIELD_LABEL_CUSTOMER_NUMBER, cellContent: o => o[FIELD_NAME_CUSTOMER_NUMBER] },
    { fieldLabelProps: { style: { width: '400px' } }, fieldLabel: FIELD_LABEL_CUSTOMER_NAME, cellContent: o => o[FIELD_NAME_CUSTOMER_NAME] },
    { fieldLabel: FIELD_LABEL_CUSTOMER_ADDRESS,
      cellContent: o => <DistributorAddress obj={o?.[FIELD_NAME_CUSTOMER_ADDRESS]?.[0]} />,
    },
  ]

  return (
    <Fragment>
      <Table celled compact selectable textAlign="center">
        <Table.Header>
          <Table.Row>
            {displayedColumns.map(displayedColumn => (
              <Table.HeaderCell key={displayedColumn.fieldLabel} {...displayedColumn?.fieldLabelProps}>
                {translate(displayedColumn.fieldLabel)}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {distributorObjs.map(distributor => (
            <Table.Row key={distributor.id} style={{ cursor: 'pointer' }} onClick={() => handleModalChange(distributor)}>
              {displayedColumns.map(displayedColumn => (
                <Table.Cell key={displayedColumn.fieldLabel}>
                  {displayedColumn.cellContent(distributor)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
          {distributorObjs.length === 0 &&
            <Table.Row>
              <Table.Cell colSpan={displayedColumns.length}>
                <Trans ns="common" i18nKey="No distributor records available">
                  No distributor records available
                </Trans>
              </Table.Cell>
            </Table.Row>
          }
        </Table.Body>
      </Table>
      { isTruthyAndNonEmpty(selectedDistributor) &&
        <DistributorModal
          sources={sources}
          distributor={selectedDistributor}
          modalOpen={modalOpen}
          handleModalChange={handleModalChange}
          handleSubmit={handleSubmit}
          isSelectedDistAlreadyRelated={isSelectedDistAlreadyRelated}
        />
      }
    </Fragment>
  )
}

export default DistributorTable
