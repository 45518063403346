import { call, takeEvery } from 'redux-saga/effects'
import { EDIT_OR_CANCEL_ORDER_KEGS } from '../../../actions/actionTypes'
import { privateFetch } from '../../fetch'
import { generalDoFailure } from '../../util/fetchFailure'
// import { doesKegOrderObjectMeetCriteriaOfHistoryForm } from './util'
// import createAction from '../../../actions/createAction'
import { API_URL_PATH_KEG_ORDERS } from '../../../../constants'
import {
  ORDER_CANCEL_FAILED,
  ORDER_CANCELLED_SUCCESSFULLY,
  ORDER_UPDATE_FAILED,
  ORDER_UPDATED_SUCCESSFULLY,
} from '../../../../features/OrderKegs/OrderKegs/constants'

function* editOrCancelKegs(action) {
  const {
    customerId,
    itemSkuIds,
    orderDetails,
    setIsSubmitting,
    setErrorMsg,
    setSuccessMsg,
    id,
  } = action.payload
  setIsSubmitting(true)
  setErrorMsg('')
  setSuccessMsg('')
  const fetchConfig = yield call(createFetchConfig, customerId, itemSkuIds, orderDetails, id)
  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { response, ...action.payload })
}
export default [
  [takeEvery, EDIT_OR_CANCEL_ORDER_KEGS, editOrCancelKegs],
]

function createFetchConfig(customerId, itemSkuIds, orderDetails, id) {
  return {
    path: `${API_URL_PATH_KEG_ORDERS}/${id}`,
    data: orderDetails,
    method: 'PUT',
  }
}

function doSuccess({
  // customerId,
  response,
  setIsSubmitting,
  setDidSubmitFail,
  setDidSubmitSucceed,
  setSuccessMsg,
  setOrderObj,
  action,
}) {
  const orderObj = response.data
  // if (yield call(doesKegOrderObjectMeetCriteriaOfHistoryForm, customerId, orderObj)) {
  //   yield put(createAction(SAVE_SINGLE_KEG_ORDER_HISTORY_ITEM, { savePath: [customerId], info: orderObj }))
  // }
  setIsSubmitting(false)
  setDidSubmitSucceed(true)
  setDidSubmitFail(false)
  setSuccessMsg(action === 'cancel' ? ORDER_CANCELLED_SUCCESSFULLY : ORDER_UPDATED_SUCCESSFULLY)
  setOrderObj(orderObj)
}

function* doFailure({
  error,
  setIsSubmitting,
  setDidSubmitFail,
  setDidSubmitSucceed,
  setErrorMsg,
  action,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  setIsSubmitting(false)
  setDidSubmitSucceed(false)
  setDidSubmitFail(true)
  setErrorMsg(action === 'cancel' ? ORDER_CANCEL_FAILED : ORDER_UPDATE_FAILED)
}
