// See CODE_COMMENTS_238. This thread does one thing and one thing only: it
// checks the Redux store every ~10 minutes to see whether an upcoming
// maintenance window is within ~48 hours of starting. If so, it sets the Redux
// flag to display the "Upcoming Maintenance" banner message. If not, it unsets
// the Redux flag.

/* eslint-disable max-len */

import {
  call,
  takeEvery,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'


import {
  START_SET_MAINTENANCE_MODE_NOTIFICATION_FLAG_THREAD,
} from '../../actions/actionTypes'

import {
  checkIfFlagToDisplayNotificationOfUpcomingMaintenanceShouldBeSetAndSetItIfSo,
} from './util'

import {
  CHECK_EVERY_X_MINUTES_WHETHER_A_NOTIFICATION_OF_UPCOMING_MAINTENANCE_SHOULD_BE_DISPLAYED,
} from '../../../config'


// Wait, shouldn't this be a cancelable saga which gets canceled on logout or
// authentication failure? Yes, but instead of using the cancelableForkedSaga()
// function here, we let our main rootSaga() wrap this with a
// transformSagasIntoCancellableSagas() function.
function* setMaintenanceModeNotificationFlagThread() {
  while (true) {
    yield call(checkIfFlagToDisplayNotificationOfUpcomingMaintenanceShouldBeSetAndSetItIfSo)
    yield call(delay, CHECK_EVERY_X_MINUTES_WHETHER_A_NOTIFICATION_OF_UPCOMING_MAINTENANCE_SHOULD_BE_DISPLAYED*1000*60)
  }
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    START_SET_MAINTENANCE_MODE_NOTIFICATION_FLAG_THREAD,
    setMaintenanceModeNotificationFlagThread,
  ],
]
