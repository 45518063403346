import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import flattenFp_ from 'lodash/fp/flatten'
import uniq_ from 'lodash/fp/uniq'
import filterFp_ from 'lodash/fp/filter'

import {
  REDUCER_NAMES_HISTORY,
  REDUCER_NAMES_HISTORY_COLLAR_ORDERS as defaultSlice,
} from '../../../constants'

import {
  withPropSimple,
  withPropOfAllSimple,
  getEntireSlice as commonGetEntireSlice,
} from '../higherOrderFunctions'


import {
  getAreAnyItemsInACertainStatus as commonGetAreAnyItemsInACertainStatus,
} from './shared'


const defaultSlices = [REDUCER_NAMES_HISTORY, defaultSlice, 'byCustomerId']

// usage: getProp(state, customerId, orderId, 'dateOrdered')
export const getProp = withPropSimple(...defaultSlices)
// usage: getPropOfAfll(state, customerId, 'dateOrdered')
export const getPropOfAll = withPropOfAllSimple(...defaultSlices)


export const getEntireSlice = commonGetEntireSlice(...defaultSlices)

export const getAreAnyItemsInACertainStatus = commonGetAreAnyItemsInACertainStatus(getPropOfAll, 'collarOrderStatus')


export const getAddressIdsFromCollarOrderObjects = arrayOfCollarOrderObjects => (
  arrayOfCollarOrderObjects.map(o => o.shippingAddressId)
)


export const getCollarPlateIdsFromCollarOrderObjects = ({
  arrayOfCollarOrderObjects,
  filterDuplicates=true,
  filterFalsyValues=true,
  sortResult=true,
}) => (
  flow_([
    mapFp_(o => o.collarPlates),
    // now we have arrays of objects that have two props: collarPlateId and
    // quantity. Turn them into an array of just collarPlateIds
    mapFp_(arrayOfObjects => arrayOfObjects.map(o => o.collarPlateId)),
    // now we have an array of arrays of collarPlateIds. Flatten it
    flattenFp_,
    ...(filterDuplicates ? [uniq_] : []),
    ...(filterFalsyValues ? [filterFp_(id => id)] : []),
    ...(sortResult ? [a => a.slice().sort()] : []),
  ])(arrayOfCollarOrderObjects)
)
