/**
 * CODE_COMMENTS_134
 */

import { connect } from 'react-redux'


import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'


export const DisplayedCustomerName = connect(
  (state, { row }) => ({
    displayedText: getCustomerProp(state, row.customerId, 'name'),
  }),
)(({ displayedText }) => displayedText)
