import React from 'react'

import NavBar from '../../employee/NavBarEmployee'
import Breadcrumbs from '../../Breadcrumbs'
import TasksToPerformImmediatelyAfterLogin from '../../../common-components/TasksToPerformImmediatelyAfterLogin'


export default ({ children }) => (
  <main>
    <NavBar />
    <TasksToPerformImmediatelyAfterLogin>
      <Breadcrumbs />
      {children}
    </TasksToPerformImmediatelyAfterLogin>
  </main>
)
