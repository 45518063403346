/* eslint-disable max-len */

import flow_ from 'lodash/fp/flow'
import map_ from 'lodash/fp/map'
import values_ from 'lodash/fp/values'
import flatten_ from 'lodash/fp/flatten'


import {
  REDUCER_NAMES_FETCH_STATUSES,
  REDUCER_NAMES_FETCH_STATUSES_FORMS,
} from '../../../../constants'
import { getFetchStatusesStandaloneF } from '../HOF/fetchStatusSelectors'

import {
  withProp,
  withPropEquals,
} from '../../higherOrderFunctions'

import {
  drillDownInObject,
} from '../../../../utils'

import { NestedPropNotInObjectError } from '../../../../customErrors'


/**
 * Pass in the form name of a form that has multiple rows, each of which gets
 * individually submitted, and this function returns an array of booleans
 * representing:
 *
 * -- has the entire form been submitted?
 * -- are some rows submitting?
 * -- have some row submissions failed?
 * -- have all row submissions succeeded?
 *
 */
export const getAggregateRowFetchStatuses = (state, formName) => {
  const formFetchStatusesSlice = getFetchStatusesSliceOfEntireForm(state, formName)
  if (!formFetchStatusesSlice) { return [false, false, false, false] }

  const fetchStatusesOfAllRows = flow_(
    // convert form slice object to array of fieldArray objects
    values_,
    // convert each fieldArray object into an array of individual row fetch
    // status info objects
    map_(values_),
    // combine all fieldArray arrays, leaving a single array of individual row
    // fetch status info objects
    flatten_,
  )(formFetchStatusesSlice)

  // In order to get the actual statuses of each form row (isFetching,
  // didFetchSucceed, etc.), we're going to use the same code used by
  // /selectors/fetchStatuses/HOF/fetchStatusSelectors/index.js. To do
  // this, we're going to pass in each status object, which looks like
  //
  // { status: "fetchStatusesSuccess", errorDetails: {...} }
  //
  // and pretend it's the entire state.
  const customGetF = withProp()
  const customCompareF = withPropEquals()

  return [
    (
      fetchStatusesOfAllRows.some(status => getFetchStatusesStandaloneF(customGetF, customCompareF, status).isQueued) ||
      fetchStatusesOfAllRows.some(status => getFetchStatusesStandaloneF(customGetF, customCompareF, status).hasFetchBeenAttempted)
    ),
    fetchStatusesOfAllRows.some(status => {
      const status_ = getFetchStatusesStandaloneF(customGetF, customCompareF, status).isFetching
      return status_.isFetching || status_.isQueued
    }),
    fetchStatusesOfAllRows.some(status => getFetchStatusesStandaloneF(customGetF, customCompareF, status).didFetchFail),
    fetchStatusesOfAllRows.every(status => getFetchStatusesStandaloneF(customGetF, customCompareF, status).didFetchSucceed),
  ]
}

// Checks to see whether the shape of a form's "form statuses" slice is like
// that of a form with no field arrays:
//
// {
//   myCoolForm: { status: ..., errorDetails: ...}
// }
//
// Or rather like that of a form with field arrays:
//
// {
//   myCoolForm: {
//     myFieldArrayName1: [
//       { status: ..., errorDetails: ...},
//       { status: ..., errorDetails: ...},
//     ],
//     myFieldArrayName2: [
//       { status: ..., errorDetails: ...},
//       { status: ..., errorDetails: ...},
//     ],
//   }
// }
//
export const isFetchStatusesSliceSeparatedIntoIndividualFieldArrays = (state, formName) => {
  const slice = getFetchStatusesSliceOfEntireForm(state, formName)
  const statusesOrFieldArrayNames = Object.keys(slice)
  if (statusesOrFieldArrayNames.includes('status') || statusesOrFieldArrayNames.includes('errorDetails')) {
    return false
  }
  return true
}


export const getNameOfFirstFieldArrayOfForm = (state, formName) => {
  const slice = getFetchStatusesSliceOfEntireForm(state, formName)
  return Object.keys(slice)[0]
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

function getFetchStatusesSliceOfEntireForm(state, formName) {
  try {
    return drillDownInObject(
      state,
      REDUCER_NAMES_FETCH_STATUSES,
      REDUCER_NAMES_FETCH_STATUSES_FORMS,
      formName)
  } catch (e) {
    if (e instanceof NestedPropNotInObjectError) { return null }
    throw e
  }
}
