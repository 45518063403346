import { call, put, takeEvery } from 'redux-saga/effects'
import moment from 'moment'

import { privateFetch } from '../../fetch'

import {
  FETCH_ORDER_COLLARS,
  SAVE_SINGLE_COLLAR_ORDER_HISTORY_ITEM,
} from '../../../actions/actionTypes'

import {
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'

import {
  COLLAR_ORDER_STATUS_NEW_ORDER,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  API_URL_PATH_COLLAR_ORDERS,
} from '../../../../constants'


import {
  extractFormValues,
  doesCollarOrderObjectMeetCriteriaOfHistoryForm,
} from './util'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  formatDateForApiCall,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../utils'


function* fetchOrderKegs(action) {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    formValues,
    setIsSubmitting,
  } = action.payload
  setIsSubmitting(true)
  const apiRequestBody = createApiRequestBody(customerId, formValues)

  const fetchConfig = {
    path: API_URL_PATH_COLLAR_ORDERS,
    data: apiRequestBody,
    method: 'POST',
  }

  // CODE_COMMENTS_92
  if (operatingContractBrewerCustomerId) {
    const headers = createHeadersForContracteeBrewerApiCall(customerId)
    fetchConfig.headers = headers
  }

  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { response, ...action.payload })
}


function* doSuccess({
  response,
  customerId,
  operatingContractBrewerCustomerId,
  setIsSubmitting,
  setDidSubmitSucceed,
  setDidSubmitFail,
}) {
  const orderObj = response.data
  if (yield call(
    doesCollarOrderObjectMeetCriteriaOfHistoryForm,
    customerId,
    operatingContractBrewerCustomerId,
    orderObj,
  )) {
    const reduxId = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
      customerId,
      operatingContractBrewerCustomerId,
    )
    yield put(createAction(SAVE_SINGLE_COLLAR_ORDER_HISTORY_ITEM, { savePath: [reduxId], info: orderObj }))
  }
  setDidSubmitSucceed(true)
  setDidSubmitFail(false)
  setIsSubmitting(false)
}


function* doFailure({
  error,
  setIsSubmitting,
  setDidSubmitSucceed,
  setDidSubmitFail,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  setDidSubmitSucceed(false)
  setDidSubmitFail(true)
  setIsSubmitting(false)
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_ORDER_COLLARS, fetchOrderKegs],
]


// helper functions

function createApiRequestBody(customerId, formValues) {
  const extractedFormValues = extractFormValues(formValues)


  return assembleApiRequestBodyObj({
    customerId,
    ...extractedFormValues,
  })
}

function assembleApiRequestBodyObj({
  customerId,
  collarPlates,
  shippingAddressId,
  poNumber,
  comments,
  overnightShipFlag,
}) {
  return {
    customerId,
    collarPlates,
    shippingAddressId,
    poNumber,
    comments,
    overnightShipFlag,

    // defaults
    collarOrderStatus: COLLAR_ORDER_STATUS_NEW_ORDER,
    dateOrdered: formatDateForApiCall({ date: moment() }),
    collarOrderIdNumber: null,
    id: null,
    invoiceTotal: null,
    fuelSurcharge: 0,
    shipDate: null,
    shippingCost: 0,
  }
}
