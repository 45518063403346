import React from 'react'

import { Form, Button } from 'semantic-ui-react'

import { useTranslation } from 'react-i18next'
import ToCustomerPortalButton from '../../../../common-components/FormSubmission/AfterSubmissionButtons/ToCustomerPortalButton'
import ResetFormButton from '../../../../common-components/rewrite/FormSubmission/AfterSubmissionButtons/ResetFormButton'


export default props => {
  const {
    children,

    semanitcUiFormProps,
    isFormSubmittable,
    handleSubmit,
    hasEntireFormBeenSubmitted,
    areSomeRowsSubmitting,
    didSomeRowSubmissionsFail,
    didAllRowSubmissionsSucceed,
    onResetFormToInitialValues,
    onClickToCustomerPortalButton,
    // will only appear before the form is submitted
    arbitraryContentNextToSubmitButton: ArbitraryContentNextToSubmitButton,
    arbitraryLinkNextToSubmitButton: ArbitraryLinkNextToSubmitButton,
  } = props
  const { t: translate } = useTranslation('common')

  // Why doesn't areSomeRowsSubmitting suffice? Because areSomeRowsSubmitting
  // doesn't account for the render tick where the form has been submitted but
  // none of the rows have yet been set to queued (which will happen in the next
  // render tick).
  const currentlySubmitting = (
    hasEntireFormBeenSubmitted
    && (
      areSomeRowsSubmitting
      || (!didSomeRowSubmissionsFail && !didAllRowSubmissionsSucceed)
    )
  )
  const buttonText = getSubmitButtonText(props)

  return (
    <Form onSubmit={handleSubmit} {...(semanitcUiFormProps || {})}>
      {children}
      {didSomeRowSubmissionsFail &&
        <p
          style={{ fontStyle: 'italic', color: 'red' }}
        >
          Some row submissions failed. Click on the <span style={{ fontWeight: 'bold' }}>red exclamation point</span> of each unsubmitted row for details.
        </p>
      }

      <Button
        color={!isFormSubmittable || currentlySubmitting || didSomeRowSubmissionsFail ? 'grey' : 'green'}
        disabled={!isFormSubmittable || hasEntireFormBeenSubmitted}
        loading={currentlySubmitting}
        type="submit"
      >
        {translate(buttonText)}
      </Button>

      {!hasEntireFormBeenSubmitted && ArbitraryContentNextToSubmitButton &&
        <ArbitraryContentNextToSubmitButton {...props} />
      }

      {!hasEntireFormBeenSubmitted && ArbitraryLinkNextToSubmitButton &&
        <ArbitraryLinkNextToSubmitButton {...props} />
      }

      {hasEntireFormBeenSubmitted && !currentlySubmitting &&
        <span>
          <ToCustomerPortalButton
            additionalActions={onClickToCustomerPortalButton}
          />
          <ResetFormButton
            onResetForm={onResetFormToInitialValues}
            confirm={didSomeRowSubmissionsFail}
          />
        </span>
      }


    </Form>
  )
}

function getSubmitButtonText({
  didAllRowSubmissionsSucceed,
  didSomeRowSubmissionsFail,
  isEditForm,
  areCurrentValuesSameAsInitialValues,
}) {
  if (didAllRowSubmissionsSucceed) {
    return 'Done!'
  } else if (didSomeRowSubmissionsFail) {
    return 'Done, some errors'
  } else if (isEditForm && areCurrentValuesSameAsInitialValues) {
    return 'No Values Changed'
  }
  return 'Submit'
}
