import React from 'react'
import { Card, Button } from 'semantic-ui-react'
import {
  Link,
  withRouter,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import InformationalPopup from '../../../common-components/InformationalPopup'

import {
  getOperateForCustomerUrl,
  createRelativePath,
} from '../../../utils'


export default withRouter(({
  customerId,
  name,
  region,
  tapcustomerId,
  isRelationshipBetweenContracteeAndCbCurrentlyActive,
  renderOperateForButton,
  // provided by withRouter
  match,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Card centered>
      <Card.Content>
        <Card.Header>{ name }</Card.Header>
        <Card.Meta>Region: { region }</Card.Meta>
        <Card.Meta>Tap ID: { tapcustomerId }</Card.Meta>
        {
          !isRelationshipBetweenContracteeAndCbCurrentlyActive &&
          <span>
            <Card.Meta style={{ color: 'red' }}>
              Inactive
              <InformationalPopup
              iconName="question"
              content="Your relationship to this brewer is no longer active. You can still report Outbound Shipments for this brewer but you won't be able to order keg collars for it."
              />
            </Card.Meta>
          </span>
        }
      </Card.Content>
      {
        renderOperateForButton && (
          <Card.Content extra className="centered-content">
            <Link to={createRelativePath(match, getOperateForCustomerUrl(customerId))}><Button className="testOperateForButton" fluid color="blue">{translate('Operate For')}</Button></Link>
          </Card.Content>
        )
      }
    </Card>
  )
})
