import React, { Fragment, useState, useEffect, useReducer, useMemo } from 'react'
import {
  Segment,
  Header,
} from 'semantic-ui-react'
import { compose } from 'recompose'

import get_ from 'lodash/get'
import cloneDeep_ from 'lodash/cloneDeep'
import flow_ from 'lodash/fp/flow'
import valuesFp_ from 'lodash/fp/values'
import mapFp_ from 'lodash/fp/map'
import flattenFp_ from 'lodash/fp/flatten'


import { useTranslation } from 'react-i18next'
import FormSection from './FormSection'
import YoureNotResponsibleForReportingAllKegFills from './YoureNotResponsibleForReportingAllKegFills'
import ResolvePotentialDuplicatesMessage from './ResolvePotentialDuplicatesMessage'
import ReportNoMovements from './ReportNoMovements'

import UiContainer from '../../../../../common-components/UiContainer'
import NoDropdownOptionsForCustomersFieldThatMustBeChosenFirstMessage from './NoDropdownOptionsForCustomersFieldThatMustBeChosenFirstMessage'
import FormAsTableWithMultipleApiSubmits from '../../../../../common-components/rewrite/formAsTable/FormAsTableWithMultipleApiSubmits'

import fetchStatusesReducer from '../../../../../redux/reducers/fetchStatuses/forms'
import createAction from '../../../../../redux/actions/createAction'
import {
  FETCH_REPORT_SHIPMENTS,
  FETCH_EDIT_OUTBOUND_SHIPMENT_REPORT,
  DELETE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
  FETCH_STATUSES_FORMS_CLEAR,
} from '../../../../../redux/actions/actionTypes'


import {
  getAggregateRowFetchStatuses,
} from '../../../../../redux/selectors/rewrite/fetchStatuses/forms/formWithFieldArrays'
import {
  mapItemSkuIdsForQualityLevel,
} from '../../../../../redux/selectors/rewrite/itemSkus'

import {
  FIELD_NAME_SOURCE_CUSTOMER,
  FIELD_NAME_DESTINATION_CUSTOMER,
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
  FIELD_NAME_INTERNAL_REFERENCE_NUM,
} from '../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  NUM_KEGS_TO_SHOW_LARGE_SHIPMENT_REPORT_INPUT_WARNING,
} from '../../../../../config'

import {
  createFieldArraysAndCustomersFieldsDropdownOptions,
} from './util/fieldArraysAndCustomersFieldsDropdownOptions'

import {
  getInitialValues,
} from './util/initialFormValues'

import {
  createInitialLocalStateForPotentialDuplicates,
  extractPertinentDetailsFromShipmentsFormRowToDecideIfPotentialDuplicate,
  calculatePotentialDuplicatesWhenUserEditsARow,
  calculatePotentialDuplicatesWhenUserDeletesARow,
  getDoAnyFormRowsHaveUnresolvedPotentialDuplicates,
  POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS,
  POTENTIAL_DUPLICATES_OTHER_FORM_ROWS,
} from './util/potentialDuplicates'

import {
  getAreAllFieldRowsFullyFilledOut,
  fieldArraysToApplyPotentialDuplicatesFeatureTo,
  getItemSkuIdsForReportShipmentsForm,
} from '../../../util'

import {
  normalizerCombiner,
  nonZeroPaddedPositiveIntegerOrZero,
  maxLength,
} from '../../../../../utils/formNormalization'

import {
  getAreFormValuesSameAsInitialValues,
} from '../../../../../utils'
import {
  ITEM_SKUS_SKU_DEFECTIVE_QUALITY_LEVEL,
} from '../../../../../constants'
// import { getSubsidiaryTapDefectiveKegs } from '../../../../../redux/selectors/rewrite/subsidiaries'
// import ReportDefectiveKegLink from './ReportDefectiveKegLink'


const Unwrapped = props => {
  const {
    customerId,
    customerType,
    operatingContractBrewerCustomerId,
    formType,
    isEditForm,
    fieldArrayNameIfIsEditForm,
    itemObj,
    closeModalFunc,
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entireItemSkusSlice,
    entireReportShipmentsFormsPotentialDuplicatesSlice,
    itemSkuIds: itemSkuIdsUnsorted,
    entireSubsidiariesSlice,
    dispatch,
    match,
    restrictPubs,
    banner,
    ignoreDisplayOnShipments,
  } = props
  const { t: translate } = useTranslation('pagelabels')
  const itemSkuIds = useMemo(
    () => getItemSkuIdsForReportShipmentsForm({
      entireItemSkusSlice,
      itemSkuIds: itemSkuIdsUnsorted,
      ignoreDisplayOnShipments,
    }),
    [itemSkuIdsUnsorted, entireItemSkusSlice, ignoreDisplayOnShipments],
  )

  const defectiveItemSkuIds = useMemo(() => mapItemSkuIdsForQualityLevel({
    itemSkuIds, entireItemSkusSlice, filterQualityLevel: ITEM_SKUS_SKU_DEFECTIVE_QUALITY_LEVEL,
  }), [itemSkuIds, entireItemSkusSlice])

  const fieldArraysAndCustomersFieldsDropdownOptions = useMemo(
    () => createFieldArraysAndCustomersFieldsDropdownOptions({
      customerId,
      customerType,
      operatingContractBrewerCustomerId,
      formType,
      isEditForm,
      fieldArrayNameIfIsEditForm,
      entireCustomersSlice,
      entireContractsSlice,
      entireParentChildLinksSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      entireUsersSlice,
      entireSubsidiariesSlice,
      itemSkuIds,
      defectiveItemSkuIds,
      match,
      restrictPubs,
      banner,
    }),
    [
      customerId,
      customerType,
      operatingContractBrewerCustomerId,
      formType,
      isEditForm,
      fieldArrayNameIfIsEditForm,
      entireCustomersSlice,
      entireContractsSlice,
      entireParentChildLinksSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      entireSubsidiariesSlice,
      entireUsersSlice,
      itemSkuIds,
      defectiveItemSkuIds,
      match,
      restrictPubs,
      banner,
    ],
  )
  const initialValues = getInitialValues({
    fieldArraysAndCustomersFieldsDropdownOptions,
    itemSkuIds,
    ...props,
  })
  const [formValues, setFormValues] = useState(initialValues)
  const [showHighKegValueWarning, setShowHighKegValueWarning] = useState(getInitialShowHighKegValueWarningState({
    isEditForm,
    formValues,
    itemSkuIds,
  }))
  // CODE_COMMENTS_244
  const [potentialDuplicates, setPotentialDuplicates] = useState(
    createInitialLocalStateForPotentialDuplicates(),
  )
  const [
    formValuesPertinentDetailsManuallyMarkedAsNotDuplicates,
    setFormValuesPertinentDetailsManuallyMarkedAsNotDuplicates,
  ] = useState([])
  const [hasEntireFormBeenSubmitted, setHasEntireFormBeenSubmitted] = useState(false)
  const [areSomeRowsSubmitting, setAreSomeRowsSubmitting] = useState(false)
  const [didSomeRowSubmissionsFail, setDidSomeRowSubmissionsFail] = useState(false)
  const [didAllRowSubmissionsSucceed, setDidAllRowSubmissionsSucceed] = useState(false)
  const [fetchStatuses, dispatchFetchStatuses] = useReducer(fetchStatusesReducer, {})


  useEffect(
    () => {
      const [
        hasEntireFormBeenSubmitted_,
        areSomeRowsSubmitting_,
        didSomeRowSubmissionsFail_,
        didAllRowSubmissionsSucceed_,
      ] = getAggregateRowFetchStatuses(fetchStatuses)
      setHasEntireFormBeenSubmitted(hasEntireFormBeenSubmitted_)
      setAreSomeRowsSubmitting(areSomeRowsSubmitting_)
      setDidSomeRowSubmissionsFail(didSomeRowSubmissionsFail_)
      setDidAllRowSubmissionsSucceed(didAllRowSubmissionsSucceed_)
    },
    [fetchStatuses],
  )

  // When user clicks "Fill out form again" after submitting form
  const onResetFormToInitialValues = () => {
    setPotentialDuplicates(createInitialLocalStateForPotentialDuplicates())
    setFormValues(getInitialValues({
      fieldArraysAndCustomersFieldsDropdownOptions,
      itemSkuIds,
      ...props,
    }))
    setShowHighKegValueWarning(getInitialShowHighKegValueWarningState({
      isEditForm,
      formValues,
      itemSkuIds,
    }))
    dispatchFetchStatuses(createAction(FETCH_STATUSES_FORMS_CLEAR))
    setHasEntireFormBeenSubmitted(false)
    setAreSomeRowsSubmitting(false)
    setDidSomeRowSubmissionsFail(false)
    setDidAllRowSubmissionsSucceed(false)
  }


  const onManuallyMarkPotentialDuplicateAsNotDuplicate = props_ => {
    setPotentialDuplicates(previousPotentialDuplicates => {
      const {
        fieldArrayName,
        rowIndex,
      } = props_
      // Step 1: set each marked row as no longer a duplicate
      const rowIndexesToSetAsNoLongerDuplicate = [
        ...previousPotentialDuplicates[fieldArrayName][rowIndex][POTENTIAL_DUPLICATES_OTHER_FORM_ROWS],
        rowIndex,
      ]
      const newPotentialDuplicatesOfTargetFieldArray = previousPotentialDuplicates[fieldArrayName].map((o, index) => {
        if (rowIndexesToSetAsNoLongerDuplicate.includes(index)) {
          return {
            [POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS]: [],
            [POTENTIAL_DUPLICATES_OTHER_FORM_ROWS]: [],
          }
        }
        return o
      })
      const newPotentialDuplicates = {
        ...previousPotentialDuplicates,
        [fieldArrayName]: newPotentialDuplicatesOfTargetFieldArray,
      }

      // Step 2: Add this row's values to the list of those manually marked as
      // not duplicate
      const rowValues = formValues[fieldArrayName][rowIndex]
      const details = extractPertinentDetailsFromShipmentsFormRowToDecideIfPotentialDuplicate({
        ...props_,
        rowValues,
      })
      setFormValuesPertinentDetailsManuallyMarkedAsNotDuplicates(
        previousFormValuesPertinentDetailsManuallyMarkedAsNotDuplicates => ([
          ...previousFormValuesPertinentDetailsManuallyMarkedAsNotDuplicates,
          details,
        ]))

      // Step 3: remove this row from the list of previously submitted potential
      // duplicates.
      dispatch(createAction(
        DELETE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
        details,
      ))

      return newPotentialDuplicates
    })
  }


  const onChangeFormValue = props_ => {
    setFormValues(
      previousFormValues => {
        const {
          fieldArrayName,
          rowIndex,
          fieldName,
          value,
          sourceMustBeChosenFirst,
        } = props_
        const previousValue = get_(previousFormValues, [fieldArrayName, rowIndex, fieldName])
        // If a user selects an option from a Semantic UI React <Dropdown>, then
        // later clicks that same dropdown again but selects the same option,
        // Semantic UI React registers that as a change, causing this change
        // handler to be called, even though the value hasn't actually changed.
        // Ignore such cases.
        if (previousValue === value) {
          return previousFormValues
        }

        const newFormValues = cloneDeep_(previousFormValues)
        // If the user changes the customer row that must be selected first (source
        // or dest), clear the row that must be selected second.
        const [customerFieldThatMustBeChosenFirst, customerFieldThatMustBeChosenSecond] = sourceMustBeChosenFirst
          ? [FIELD_NAME_SOURCE_CUSTOMER, FIELD_NAME_DESTINATION_CUSTOMER]
          : [FIELD_NAME_DESTINATION_CUSTOMER, FIELD_NAME_SOURCE_CUSTOMER]
        if (fieldName === customerFieldThatMustBeChosenFirst) {
          newFormValues[fieldArrayName][rowIndex][customerFieldThatMustBeChosenSecond] = undefined
        }
        const newValue = normalizeInputValue({
          previousValue,
          value,
          itemSkuIds,
          fieldName,
        })
        newFormValues[fieldArrayName][rowIndex][fieldName] = newValue
        if (fieldArraysToApplyPotentialDuplicatesFeatureTo.includes(fieldArrayName)) {
          setPotentialDuplicates(previousPotentialDuplicates => (
            calculatePotentialDuplicatesWhenUserEditsARow({
              previousPotentialDuplicates,
              newFormValues,
              fieldArrayName,
              rowIndex,
              // There's a bug where
              // formValuesPertinentDetailsManuallyMarkedAsNotDuplicates is
              // stale by a single render tick of onChangeFormValue(). But most
              // users won't hit this bug (in order to encounter the bug, you
              // must mark a row that has the "Potential Duplicates" warning as
              // not a duplicate, then ON THE VERY NEXT KEYSTROKE modify a third
              // line that makes it identical to the row you just marked as not
              // a duplicate, and the Duplciates warning will incorrectly be
              // shown again--if this takes two keystrokes, you won't encounter
              // the error), so it's not a big deal. Reason: I don't know, but
              // it's probably because I'm using the set...() hook functions
              // (such as setPotentialDuplicates() here) inside of each other
              // (i.e. in nested functions), which breaks the rules of hooks
              // (https://reactjs.org/docs/hooks-rules.html states, "Don’t call
              // Hooks inside loops, conditions, or nested functions."). The
              // solution is to refactor the code so that both the formValues
              // and formValuesPertinentDetailsManuallyMarkedAsNotDuplicates are
              // in the same state variable using the useReducer hook; details
              // at
              // https://levelup.gitconnected.com/handling-complex-form-state-using-react-hooks-76ee7bc937
              formValuesPertinentDetailsManuallyMarkedAsNotDuplicates,
              entireReportShipmentsFormsPotentialDuplicatesSlice, // store slice
              entireUsersSlice, // store slice
              entireItemSkusSlice,
              formValues,
              isEditForm,
              itemObj,
            })
          ))
        }
        return newFormValues
      },
    )
  }

  // Every time the user updates any form value, check to see whether any date
  // fields are super high
  useEffect(
    () => {
      setShowHighKegValueWarning(getDoSomeKegQtyFieldsHaveHighValues(formValues, itemSkuIds))
    },
    [formValues, itemSkuIds],
  )


  const onAddRow = ({
    fieldArrayName,
    customersFieldsDropdownOptions,
    whichCustomersFieldMustBeChosenFirstSourceOrDestination,
  }) => {
    setFormValues(previousFormValues => {
      const newFormValues = cloneDeep_(previousFormValues)
      // add the new row to the form values state.
      const newRow = {}
      newFormValues[fieldArrayName].push(newRow)
      // When it comes time to submit the form, the Source/Destination field for
      // each row must have a value. This value can't be set by the UI if the
      // customers field that must be chosen first only has one choice, so we
      // set the value here. (Why must it have a value? For 2 reasons: first,
      // the isFormSubmittable() function checks to make sure it's filled out
      // before allowing the user to click the "Submit" button. Second, because
      // the Submission Saga will depend on this value to properly craft the API
      // request body). We also set the value of the field that must be chosen
      // second if it only has one choice (and only if the customer field that
      // must be chosen first has only one choice) as a UX convenience (which is
      // especially important in Self-Collection Shipments forms because that
      // form will always have only one choice for each customer field).
      if (customersFieldsDropdownOptions.length === 1) {
        const fieldToSet = whichCustomersFieldMustBeChosenFirstSourceOrDestination ===
          WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE
          ? FIELD_NAME_SOURCE_CUSTOMER
          : FIELD_NAME_DESTINATION_CUSTOMER
        newRow[fieldToSet] = customersFieldsDropdownOptions[0].id

        const otherCustomersFieldChoices = customersFieldsDropdownOptions[
          0
        ].choicesForOtherCustomersFieldWhenThisCustomerIsSelected
        if (otherCustomersFieldChoices.length === 1) {
          const secondFieldNameToSet = whichCustomersFieldMustBeChosenFirstSourceOrDestination ===
            WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE
            ? FIELD_NAME_DESTINATION_CUSTOMER
            : FIELD_NAME_SOURCE_CUSTOMER
          newRow[[secondFieldNameToSet]] = otherCustomersFieldChoices[0].id
        }
      }

      // add the new row to the potential duplicates state
      if (fieldArraysToApplyPotentialDuplicatesFeatureTo.includes(fieldArrayName)) {
        setPotentialDuplicates(previousPotentialDuplicates => {
          const newPotentialDuplicates = cloneDeep_(previousPotentialDuplicates)
          newPotentialDuplicates[fieldArrayName].push({
            [POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS]: [],
            [POTENTIAL_DUPLICATES_OTHER_FORM_ROWS]: [],
          })
          return newPotentialDuplicates
        })
      }
      return newFormValues
    })
  }


  const onDeleteRow = props_ => {
    const {
      fieldArrayName,
      rowIndex,
    } = props_
    setFormValues(previousFormValues => {
      // delete the row from the potential duplicates state
      if (fieldArraysToApplyPotentialDuplicatesFeatureTo.includes(fieldArrayName)) {
        setPotentialDuplicates(previousPotentialDuplicates => {
          const newPotentialDuplicates = calculatePotentialDuplicatesWhenUserDeletesARow({
            potentialDuplicates: previousPotentialDuplicates,
            ...props_,
          })
          return newPotentialDuplicates
        })
      }

      // delete the row from the form
      const newFormValues = cloneDeep_(previousFormValues)
      // delete the row from the form values state
      newFormValues[fieldArrayName].splice(rowIndex, 1)

      return newFormValues
    })
  }

  const areAllFieldRowsFullyFilledOut = getAreAllFieldRowsFullyFilledOut({
    formValues,
    fieldArraysAndCustomersFieldsDropdownOptions,
    entireItemSkusSlice,
  })
  const doAnyFormRowsHaveUnresolvedPotentialDuplicates = getDoAnyFormRowsHaveUnresolvedPotentialDuplicates({
    potentialDuplicates,
  })
  const areCurrentValuesSameAsInitialValues = getAreFormValuesSameAsInitialValues(
    formValues,
    initialValues,
    Object.keys(formValues),
  )
  const isFormSubmittable = (
    areAllFieldRowsFullyFilledOut
    && !doAnyFormRowsHaveUnresolvedPotentialDuplicates
    && !areCurrentValuesSameAsInitialValues
  )

  /*
   * ***************************************************************************
   * Form Submission
   * ***************************************************************************
  */

  const handleSubmit = () => {
    setHasEntireFormBeenSubmitted(true)

    dispatch(createAction(
      isEditForm ? FETCH_EDIT_OUTBOUND_SHIPMENT_REPORT : FETCH_REPORT_SHIPMENTS,
      {
        customerId,
        operatingContractBrewerCustomerId,
        entireItemSkusSlice,
        fieldArraysAndCustomersFieldsDropdownOptions,
        itemSkuIds,
        formValues,
        dispatchFetchStatuses,
        fieldArrayNameIfIsEditForm,
        itemObj,
      },
    ))
  }


  const formAsTableProps = {
    semanitcUiFormProps: { className: 'form-top-spacing' },
    isEditForm,
    areCurrentValuesSameAsInitialValues,
    isFormSubmittable,
    handleSubmit,
    hasEntireFormBeenSubmitted,
    areSomeRowsSubmitting,
    didSomeRowSubmissionsFail,
    didAllRowSubmissionsSucceed,
    onResetFormToInitialValues,
    onClickToCustomerPortalButton: closeModalFunc,
    // arbitraryLinkNextToSubmitButton: !getSubsidiaryTapDefectiveKegs({
    //   entireSubsidiariesSlice, subsidiaryId: entireCustomersSlice?.[customerId]?.subsidiaryId,
    // }) ? ReportDefectiveKegLink : null,
    arbitraryContentNextToSubmitButton: (
      doAnyFormRowsHaveUnresolvedPotentialDuplicates
      && ResolvePotentialDuplicatesMessage
    ),
  }


  let formSections
  if (fieldArraysAndCustomersFieldsDropdownOptions.length === 1 && formType !== 'buybacks') {
    // If there's only one form section, don't wrap the form section in its own
    // <Segment>, that's unnecessary. Just Display the form section so that it
    // looks like a regular, section-less form.
    const {
      arbitraryContentBelowFieldArrayTitleAndAboveForm: ArbitraryContentBelowFieldArrayTitleAndAboveForm,
    } = fieldArraysAndCustomersFieldsDropdownOptions[0]
    formSections = (
      <Fragment>
        {formType === 'kegFills' &&
          <YoureNotResponsibleForReportingAllKegFills
            {...props}
          />
        }
        {
          ArbitraryContentBelowFieldArrayTitleAndAboveForm &&
            <ArbitraryContentBelowFieldArrayTitleAndAboveForm {...props} />
        }
        <FormSection
          {...props}
          customerId={customerId}
          {...fieldArraysAndCustomersFieldsDropdownOptions[0]}
          formType={formType}
          itemSkuIds={itemSkuIds}
          dispatch={dispatch}
          potentialDuplicates={potentialDuplicates}
          onManuallyMarkPotentialDuplicateAsNotDuplicate={onManuallyMarkPotentialDuplicateAsNotDuplicate}
          onChangeFormValue={onChangeFormValue}
          onAddRow={onAddRow}
          onDeleteRow={onDeleteRow}
          hasEntireFormBeenSubmitted={hasEntireFormBeenSubmitted}
          formValues={formValues}
          fetchStatuses={fetchStatuses}
        />
        {showHighKegValueWarning && <HighKegValueWarning />}
      </Fragment>
    )
  } else {
    formSections = (
      <Fragment>
        <Segment.Group className="form-sections">
          {
            fieldArraysAndCustomersFieldsDropdownOptions.map(fieldArrayDef => {
              const {
                fieldArrayName,
                fieldArrayTitle,
                arbitraryContentBelowFieldArrayTitleAndAboveForm:
                  ArbitraryContentBelowFieldArrayTitleAndAboveForm,
                itemSkuIdsForFieldArray=itemSkuIds,
              } = fieldArrayDef
              return (
                <Segment key={fieldArrayName}>
                  <Header as="h3">{translate(`reportShipments.${fieldArrayTitle}`)}</Header>
                  {
                    ArbitraryContentBelowFieldArrayTitleAndAboveForm &&
                      <ArbitraryContentBelowFieldArrayTitleAndAboveForm {...props} />
                  }
                  <FormSection
                    {...props}
                    customerId={customerId}
                    {...fieldArrayDef}
                    formType={formType}
                    itemSkuIds={itemSkuIdsForFieldArray}
                    fieldArrayName={fieldArrayName}
                    dispatch={dispatch}
                    potentialDuplicates={potentialDuplicates}
                    onManuallyMarkPotentialDuplicateAsNotDuplicate={onManuallyMarkPotentialDuplicateAsNotDuplicate}
                    onChangeFormValue={onChangeFormValue}
                    onAddRow={onAddRow}
                    onDeleteRow={onDeleteRow}
                    hasEntireFormBeenSubmitted={hasEntireFormBeenSubmitted}
                    formValues={formValues}
                    fetchStatuses={fetchStatuses}
                  />
                </Segment>
              )
            })
          }
        </Segment.Group>
        {showHighKegValueWarning && <HighKegValueWarning />}
      </Fragment>
    )
  }

  return (
    <NoDropdownOptionsForCustomersFieldThatMustBeChosenFirstMessage
      fieldArraysAndCustomersFieldsDropdownOptions={fieldArraysAndCustomersFieldsDropdownOptions}
      {...props}
    >
      <FormAsTableWithMultipleApiSubmits
        {...formAsTableProps}
      >
        {formSections}
      </FormAsTableWithMultipleApiSubmits>
      {['outbounds', 'kegFills'].includes(formType) && !isEditForm &&
        <ReportNoMovements
          fieldArraysAndCustomersFieldsDropdownOptions={fieldArraysAndCustomersFieldsDropdownOptions}
          {...props}
        />
      }
    </NoDropdownOptionsForCustomersFieldThatMustBeChosenFirstMessage>
  )
}


export default props => {
  const { isEditForm } = props
  return isEditForm
    ? <Edit {...props} />
    : <Report {...props} />
}

const Report = compose(
  UiContainer(({ formType }) => ({
    headerText: getPageTitle({ formType }),
    headerProps: { className: 'segment-header-bottom-margin' },
    containerId: `${getPageTitle({ formType }).replace(/ /g, '')}Section`, // strip spaces from page title
  })),
)(Unwrapped)

const Edit = compose()(Unwrapped)

function getPageTitle({ formType }) {
  return {
    outbounds: 'Report Outbound Shipments',
    kegFills: 'Report Keg Fills',
    buybacks: 'Report Buyback Shipments',
    selfDistAndPubs: 'Report Self-Distribution and Own Pubs Shipments',
    selfCollection: 'Report Self-Collection for Reuse',
  }[formType]
}

const HighKegValueWarning = () => (
  <p style={{ color: '#f2711c', fontWeight: 'bold' }}>
    {"You've entered some high keg values. You can still submit, just be sure these values are correct."}
  </p>
)


function normalizeInputValue({
  previousValue,
  value,
  itemSkuIds,
  fieldName,
}) {
  let normalizer
  if (itemSkuIds.includes(fieldName)) {
    normalizer = normalizerCombiner(nonZeroPaddedPositiveIntegerOrZero, maxLength(5))
  } else if (fieldName === FIELD_NAME_INTERNAL_REFERENCE_NUM) {
    normalizer = maxLength(45) // CODE_COMMENTS_192
  } else {
    normalizer = val => val
  }
  return normalizer(
    value,
    previousValue,
  )
}


function getInitialShowHighKegValueWarningState({
  isEditForm,
  formValues,
  itemSkuIds,
}) {
  if (!isEditForm) {
    return false
  }
  return getDoSomeKegQtyFieldsHaveHighValues(formValues, itemSkuIds)
}


function getDoSomeKegQtyFieldsHaveHighValues(formValues, itemSkuIds) {
  if (!formValues) { return false }
  return flow_(
    valuesFp_,
    flattenFp_,
    mapFp_(rowValues => itemSkuIds.map(itemSkuId => Number(get_(rowValues, [itemSkuId], 0)))),
    flattenFp_,
    a => (a.some(value => value >= NUM_KEGS_TO_SHOW_LARGE_SHIPMENT_REPORT_INPUT_WARNING)),
  )(formValues)
}
