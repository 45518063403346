import React from 'react'
import { Modal, Grid } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import ModalWithCatastrophicFailureErrorBoundary from '../../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import TextAsPseudoLink from '../../../../../../../common-components/TextAsPseudoLink'

import {
  getInfoDefinitionsOutboundFullKegShipmentFullDetails,
} from '../../../../util/shipmentHistory/outboundFullKegShipmentHistory'

import HistoryLabelWithContainerTypesDifferencesPopupIfNecessary from '../../../../util/HistoryLabelWithContainerTypesDifferencesPopupIfNecessary'
import PrintBOL from '../../../../ScheduledShipmentsHistory/Table/subFeatures/ShipmentDetails/printBOL'


export default ({
  entireItemSkusSlice,
  customerId,
  info: shipmentDetails,
  itemSkuIdsToIncludeAsFieldsInTable,
  shouldOriginColumnBeRendered, // CODE_COMMENTS_130
  entireCustomersSlice,
  dispatch,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      closeIcon
      size="large"
      trigger={<TextAsPseudoLink>{shipmentDetails.shipmentId}</TextAsPseudoLink>}
      className="test-full-shipment-details"
    >
      <Modal.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{translate('Shipment Details')}</span>
          <PrintBOL dispatch={dispatch} shipmentId={shipmentDetails.id} />
        </div>
      </Modal.Header>
      <Modal.Content>
        <AdditionalShipmentDetails
          entireCustomersSlice={entireCustomersSlice}
          entireItemSkusSlice={entireItemSkusSlice}
          customerId={customerId}
          shipmentDetails={shipmentDetails}
          itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
          shouldOriginColumnBeRendered={shouldOriginColumnBeRendered} // CODE_COMMENTS_130
        />
      </Modal.Content>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}

const AdditionalShipmentDetails = ({
  entireItemSkusSlice,
  customerId,
  shipmentDetails,
  itemSkuIdsToIncludeAsFieldsInTable,
  shouldOriginColumnBeRendered, // CODE_COMMENTS_130
  entireCustomersSlice,
}) => {
  const toDisplay = getInfoDefinitionsOutboundFullKegShipmentFullDetails({
    entireItemSkusSlice,
    itemSkuIdsToIncludeAsFieldsInTable,
    shouldOriginColumnBeRendered,
    entireCustomersSlice,
    customerId,
  })
  return (
    // For alignment purposes, all displayed content within this grid needs to
    // be contained within a <Grid.Column> component; see CODE_COMMENTS_97 for
    // why.
    <Grid columns={2}>
      {toDisplay.map(definition => (
        <Grid.Row key={definition.heading} className="compact-grid-row">
          <Grid.Column width={3} style={{ fontWeight: 'bold' }}>
            <HistoryLabelWithContainerTypesDifferencesPopupIfNecessary
              customerId={customerId}
              labelText={definition.heading}
              colonAfterText
            />
          </Grid.Column>
          <Grid.Column width={13}>{definition.cellContent(shipmentDetails, { customerId })}</Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  )
}
