import { connect } from 'react-redux'

import ContactCardsForOneCustomer from '../components/ContactCardsForOneCustomer'

import {
  getAllNonNullRepTypesOfCustomer,
} from '../../../redux/selectors/customers'


const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps
  const repTypesToCreateACardFor = getAllNonNullRepTypesOfCustomer(state, customerId)

  return {
    repTypesToCreateACardFor,
    ...ownProps,
  }
}

export default connect(
  mapStateToProps,
)(ContactCardsForOneCustomer)
