/**
 * CODE_COMMENTS_11
 */
import { all } from 'redux-saga/effects'


import {
  LOGOUT,
  AUTHENTICATION_FAILURE,
  SET_CATASTROPHIC_FAILURE_HAPPENED,
} from '../actions/actionTypes'


import authSagas from './authentication'
import tasksToPerformImmediatelyAfterLoginSagas from './tasksToPerformImmediatelyAfterLogin'
import keepUserLoggedInSagas from './keepUserLoggedIn'
import maintenanceModeSagas from './maintenanceMode'
import downloadLatestWebAppVersionSagas from './downloadLatestWebAppVersion'
import customersSagas from './customers'
import formsSagas from './forms'
import publicFormsSagas from './publicForms'
import downloadInvoiceSagas from './downloadInvoice'
import universalCustomerRepresentativesSagas from './universalCustomerRepresentatives'

import {
  transformSagaIntoCancellableSagaOnTheseActionTypes,
} from './util/cancellableSagas'
import {
  setCatastrophicFailureFlagIfSagaExperiencesUnhandledError,
} from './util/catastrophicFailureWrapper'


// CODE_COMMENTS_11: If the user logs out, is kicked out from an authentication
// failure, or the app experiences a catastrophic failure while a saga is
// currently running, cancel the saga. This is super important: if we don't
// cancel the saga, it will continue while the user is on the /login page (in
// the instance of logout/auth failure), likely adding to the Redux store while
// the user is logged out!
const transformSagasIntoCancellableSagas = sagasInfo => (
  sagasInfo.map(sagaInfo => {
    const [takeFunc, actionToTake, sagaGenerator] = sagaInfo
    const newSagaGenerator = transformSagaIntoCancellableSagaOnTheseActionTypes(
      sagaGenerator,
      [
        LOGOUT,
        AUTHENTICATION_FAILURE,
        SET_CATASTROPHIC_FAILURE_HAPPENED,
      ],
    )
    return [takeFunc, actionToTake, newSagaGenerator]
  })
)


// CODE_COMMENTS_131
const wrapSagasInCatastrophicFailureHandler = sagasInfo => (
  sagasInfo.map(sagaInfo => {
    const [takeFunc, actionToTake, sagaGenerator] = sagaInfo
    const newSagaGenerator = setCatastrophicFailureFlagIfSagaExperiencesUnhandledError(sagaGenerator)
    return [takeFunc, actionToTake, newSagaGenerator]
  })
)


// CODE_COMMENTS_11
const applyTakeFunctionsToSagasInfo = sagasInfo => (
  sagasInfo.map(sagaInfo => {
    const [takeFunc, actionToTake, sagaGenerator] = sagaInfo
    return takeFunc(actionToTake, sagaGenerator)
  })
)


// CODE_COMMENTS_11
export default function* rootSaga() {
  const allSagasThatShouldBeCanceledOnLogoutOrAuthFailure = transformSagasIntoCancellableSagas([
    ...customersSagas,
    ...formsSagas,
    ...publicFormsSagas,
    ...downloadInvoiceSagas,
    ...tasksToPerformImmediatelyAfterLoginSagas,
    ...keepUserLoggedInSagas,
    ...maintenanceModeSagas,
    ...downloadLatestWebAppVersionSagas,
    ...universalCustomerRepresentativesSagas,
    // Authentication sagas are not included here: we specifically don't want to
    // cancel sagas that have to do with authentication because that would mean,
    // for instance, that the logout action cancels the logout saga: you'd never
    // be able to log out. This does _not_ include keepUserLoggedInSagas, which
    // only deal indirectly with authentication: we want the "keep user logged
    // in for as long as the web app is open" saga to be canceled when the user
    // logs out, obviously.
  ])

  // CODE_COMMENTS_131. All sagas should be included here.
  const allSagasThatShouldSetCatastrophicFailureOnUnhandledErrors = wrapSagasInCatastrophicFailureHandler([
    ...allSagasThatShouldBeCanceledOnLogoutOrAuthFailure,
    ...authSagas,
  ])


  const allSagasWithTakeFunctionsApplied = applyTakeFunctionsToSagasInfo(
    allSagasThatShouldSetCatastrophicFailureOnUnhandledErrors,
  )

  yield all(allSagasWithTakeFunctionsApplied)
}
