import {
  withPropSimple,
} from './higherOrderFunctions'

/**
 * CODE_COMMENTS_14
 */
import { REDUCER_NAMES_AUTH as defaultSlice } from '../../constants'


export const getProp = withPropSimple(defaultSlice)


// eslint-disable-next-line import/prefer-default-export
export function getIsAuthenticated(state) {
  return state[defaultSlice].isAuthenticated
}

/**
 * Will throw an error if this reducer doesn't have a
 * userToken property.
 */
export function getAuthTokenExpiry(state) {
  const decodedUserToken = getProp(state, 'decodedUserToken')
  return decodedUserToken.exp
}
