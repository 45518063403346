import React from 'react'
import { Card } from 'semantic-ui-react'


import MasterOptions from './customerTypeSpecificCustomerPortals/MasterAccountCustomerPortal'
import BrewerOptions from './customerTypeSpecificCustomerPortals/BrewerCustomerPortal'
import ContractBrewerOptions from './customerTypeSpecificCustomerPortals/ContractBrewerCustomerPortal'
import ContracteeBrewerOptions from './customerTypeSpecificCustomerPortals/ContracteeBrewerCustomerPortal'
import DistributorOptions from './customerTypeSpecificCustomerPortals/DistributorCustomerPortal'
import RentalCustomerPortal from './customerTypeSpecificCustomerPortals/RentalCustomerPortal'
import EmployeeCustomerPortal from './customerTypeSpecificCustomerPortals/EmployeeCustomerPortal'
import WarehouseOptions from './customerTypeSpecificCustomerPortals/WarehouseCustomerPortal'
import MaintenanceFacilityOptions from './customerTypeSpecificCustomerPortals/MaintenanceFacilityPortal'

import {
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_WAREHOUSE,
  CUSTOMER_TYPES_MAINTENANCE_FACILITY,
} from '../../../constants'


// eslint-disable-next-line import/prefer-default-export
export const mapCustomerTypeToOptionsGroup = customerType => {
  const customerTypeMapping = {
    [CUSTOMER_TYPES_MASTER]: MasterOptions,
    [CUSTOMER_TYPES_BREWER]: BrewerOptions,
    [CUSTOMER_TYPES_CONTRACT_BREWER]: ContractBrewerOptions,
    [CUSTOMER_TYPES_DISTRIBUTOR]: DistributorOptions,
    [CUSTOMER_TYPES_WAREHOUSE]: WarehouseOptions,
    [CUSTOMER_TYPES_MAINTENANCE_FACILITY]: MaintenanceFacilityOptions,
  }
  return customerTypeMapping[customerType]
}


export default ({
  customerId,
  customerType,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  isRentalCustomer,
  isEmployee,
}) => {
  let CustomerPortalOptionsForThisCustomerType
  if (isRentalCustomer) {
    CustomerPortalOptionsForThisCustomerType = RentalCustomerPortal
  } else if (operatingContractBrewerCustomerId) {
    CustomerPortalOptionsForThisCustomerType = ContracteeBrewerOptions
  } else if (isEmployee) {
    CustomerPortalOptionsForThisCustomerType = EmployeeCustomerPortal
  } else {
    CustomerPortalOptionsForThisCustomerType = mapCustomerTypeToOptionsGroup(customerType, isEmployee)
  }

  return (
    <div>
      <Card.Group itemsPerRow={5}>
        <CustomerPortalOptionsForThisCustomerType
          customerId={customerId}
          operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
        />
      </Card.Group>
    </div>
  )
}
