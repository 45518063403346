import isString_ from 'lodash/isString'
import isNumber_ from 'lodash/isNumber'
import isArray_ from 'lodash/isArray'
import isPlainObject_ from 'lodash/isPlainObject'

import {
  getIsStringAValidEmailAddress,
} from '../../../../utils'


// CODE_COMMENTS_179
export function isStringValidator({ data }) {
  return isString_(data) || 'not a string'
}


// CODE_COMMENTS_179
export function isNumberValidator({ data }) {
  return isNumber_(data) || 'not a number'
}


// CODE_COMMENTS_179
export function isStringOrNumberValidator({ data }) {
  if (
    isString_(data) ||
    isNumber_(data)
  ) { return true }
  return 'Neither a string nor a number'
}


// CODE_COMMENTS_179
export function isValidEmailAddressValidator({ data }) {
  if (isStringValidator({ data }) !== true) { return isStringValidator({ data }) }
  if (!getIsStringAValidEmailAddress(data)) { return 'not a valid email address' }
  return true
}

// CODE_COMMENTS_179
export function isFalsyValidator({ data }) {
  if (!data) { return true }
  return 'not falsy (null, undefined, false, etc)'
}


// CODE_COMMENTS_179
export function isBooleanValidator({ data }) {
  if (data === false || data === true) { return true }
  return 'not a boolean (true or false)'
}


// CODE_COMMENTS_179
export function isArrayOfStringsValidator({ data }) {
  if (!isArray_(data)) {
    return 'not an array'
  }
  if (!data.every(item => isString_(item))) {
    return 'not every item in the array is a string'
  }
  return true
}

// CODE_COMMENTS_179
export function isArrayOfObjectsValidator({ data }) {
  if (!isArray_(data)) {
    return 'not an array'
  }
  if (!data.every(item => isPlainObject_(item))) {
    return 'not every item in the array is an object'
  }
  return true
}


/*
 * *****************************************************************************
 * HoFs
 * *****************************************************************************
*/

// CODE_COMMENTS_179
export function isOneOfTheseStringsValidator(arrayOfStrings) {
  return ({ data }) => {
    if (arrayOfStrings.includes(data)) { return true }
    return `not one of these strings: ${arrayOfStrings.join(', ')}`
  }
}
