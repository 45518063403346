/**
 * Takes an object of reducers (with keys) and returns a new object of reducers
 * in which each reducer returns its 'initialState' if certain action
 * types are fired. For example, you can pass in a group of reducers and
 * have them return the initial state if the user logs out or becomes
 * unauthenticated. This allows us to avoid having to put the following into our
 * reducers (the vast majority of which will need it):
 *
 * case LOGOUT:
 * case AUTHENTICATION_FAILURE:
 *   return initialState
 *
 * This only works, though, if the wrapped reducer sets the 'state' argument to
 * its initialState if 'state' is not defined, so be careful to do that in all
 * reducers you use this with.
 *
 * `reducerFilter` is an optional argument that allows the caller to filter the
 * reducers passed in. The filter function will be passed the object key as the
 * first arg and object value as the second arg.
 */
export default ({
  reducers,
  actionTypesThatTriggerReset,
  // eslint-disable-next-line no-unused-vars
  reducerFilter=(key, value) => true,
}) => (
  Object.keys(reducers).reduce(
    (acc, key) => {
      let possiblyAlteredReducer
      if (reducerFilter(key, reducers[key])) {
        possiblyAlteredReducer = {
          [key]: resetReducerToInitialState(
            reducers[key],
            actionTypesThatTriggerReset,
          ),
        }
      } else {
        possiblyAlteredReducer = { [key]: reducers[key] }
      }
      return {
        ...acc,
        ...possiblyAlteredReducer,
      }
    },
    {},
  )
)


/**
 * This is the actual higher order reducer. See the docstring of the caller for
 * details.
 */
function resetReducerToInitialState(reducer, actionTypesThatTriggerReset) {
  return (state, action) => {
    if (actionTypesThatTriggerReset.includes(action.type)) {
      return reducer(undefined, action)
    }
    return reducer(state, action)
  }
}
