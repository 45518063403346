import { combineReducers } from 'redux'


import {
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_FORMS,
  REDUCER_NAMES_FETCH_STATUSES_PUBLIC_FORMS,
  REDUCER_NAMES_FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPS,
} from '../../../constants'

import fetchStatusesPublicFormsReducer from './publicForms'
import fetchStatusesCustomersReducers from './customers'
import fetchStatusesFormsReducer from './forms'
import fetchStatusesUniversalCustomerRepsReducer from './universalCustomerReps'

const fetchStatusesReducers = {
  [REDUCER_NAMES_FETCH_STATUSES_PUBLIC_FORMS]: fetchStatusesPublicFormsReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO]: fetchStatusesCustomersReducers,
  [REDUCER_NAMES_FETCH_STATUSES_FORMS]: fetchStatusesFormsReducer,
  [REDUCER_NAMES_FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPS]: fetchStatusesUniversalCustomerRepsReducer,
}

export default combineReducers(fetchStatusesReducers)
