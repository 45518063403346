/**
 * CODE_COMMENTS_88
 * CODE_COMMENTS_90
 */
import { call, select } from 'redux-saga/effects'

import maxBy_ from 'lodash/maxBy'


import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
  getSinglePropOfAllRelationshipObjectsBySourceAndDestCustomerIds,
  getMultiplePropsOfAllRelationshipObjectsBySourceAndDestCustomerIds,
} from '../../../selectors/rewrite/relationships/relatedToOrFromInfo'

import {
  isTruthyAndNonEmpty,
} from '../../../../utils'


export function createHeadersForContracteeBrewerApiCall(
  contracteeCustomerId,
) {
  return {
    OperateOnBehalfOf: contracteeCustomerId,
  }
}


export function* getSourcePpfContractIdBetweenContracteeAndOperatingCBForQueryParam(
  contracteeCustomerId,
  operatingContractBrewerCustomerId,
) {
  // This is the one and only place where the web app needs to send a contractId
  // to the backend--the backend is responsible for setting contractIds in every
  // other situation because it's usually impossible for the web app to choose
  // the correct contractId; see CODE_COMMENTS_133 (particularly FOOTNOTE 1) for
  // details. Here, too, it's impossible for the web app to choose the correct
  // contractId: what if a Brewer and Contract Brewer have multiple relationship
  // objects (and therefore multiple contractIds, one for each relationship),
  // which contractId should we choose as the `sourceppfcontractid` query
  // parameter of a /relatedto fetch on a logged-in CB's contractee? There's no
  // right answer here: optimally the web app wants to receive all distributors
  // the contractee is related to over all BRW2CONBRW contracts, not just one.
  // But this isn't feasible for Release 1, so the following rule is the best we
  // can do: Choose the contractId of the currently active relationship between
  // the BRW and CONBRW (there will only ever be at most 1 currently active
  // relationship between the BRW and CONBRW; see CODE_COMMENTS_112 for details
  // on what "currently active" means). If none of the relationships are
  // currently active, choose the contractId from the relationship with the
  // latest `effectiveDate` (see FOOTNOTE 1 of CODE_COMMENTS_169 for details on
  // why it's safe to inspect `effectiveDate` here).
  const state = yield select()

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)

  const activeSourePpfContractIds = yield call(
    getSinglePropOfAllRelationshipObjectsBySourceAndDestCustomerIds,
    {
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      sourceCustomerId: contracteeCustomerId,
      destinationCustomerId: operatingContractBrewerCustomerId,
      propName: 'sourcePpfContract',
      onlyRelationshipsThatShipmentsCanBeReportedOn: true,
    },
  )
  if (isTruthyAndNonEmpty(activeSourePpfContractIds)) {
    return activeSourePpfContractIds[0]
  }

  // No active relationships
  const allSourePpfContractIds = yield call(
    getMultiplePropsOfAllRelationshipObjectsBySourceAndDestCustomerIds,
    {
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      sourceCustomerId: contracteeCustomerId,
      destinationCustomerId: operatingContractBrewerCustomerId,
      propNames: ['sourcePpfContract', 'effectiveDate'],
      onlyRelationshipsThatShipmentsCanBeReportedOn: false,
    },
  )

  const withLatestEffectiveDate = maxBy_(allSourePpfContractIds, 'effectiveDate')
  return withLatestEffectiveDate.sourcePpfContract
}
