import React from 'react'

import orderBy_ from 'lodash/orderBy'


import HistoryTableCreator from '../../../HOC/HistoryTableCreator'
import ShipmentDetails from '../subFeatures/ShipmentDetails'

import {
  CUSTOMER_TYPES_WAREHOUSE,
} from '../../../../../../constants'

import {
  createDisplayedShipmentType,
  createDisplayedShipmentDate,
  createDisplayedProNumber,
  createContainerTypesFieldsDefinitions,
  createValveTypeDefinition,
  createQualityLevelDefinition, createDisplayedMovementType,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'

import {
  createDisplayedOriginNameForOutboundShipmentsHistoryTable,
} from '../../../util/shipmentHistory/outboundFullKegShipmentHistory/displayedDataEtc/displayedOutboundFullKegShipmentDataEtc'

import {
  // Why are we using a bunch of functions from the
  // outboundFullKegShipmentHistory component? Because that component and this
  // component refer to the same shipments: outbound full keg shipments for
  // Brewers are inbound full keg shipments for Distributors. See
  // CODE_COMMENTS_205.
  getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
  getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
  getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
} from '../../../util/shipmentHistory/outboundFullKegShipmentHistory'
import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail,
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'
import KegOrderContainer from '../../../KegOrderHistory/Table/subFeatures/containers/KegOrderContainer'


function createTableContentDefinitions({
  entireItemSkusSlice,
  customerId,
  customerType,
  allInboundFullKegShipmentObjects,
  configuredItemSkuIds,
  entireCustomersSlice,
}) {
  const useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged = true
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allInboundFullKegShipmentObjects,
  })

  return {
    content: [
      {
        heading: 'Shipment #',
        cellContent: row => (
          <ShipmentDetails
            entireCustomersSlice={entireCustomersSlice}
            entireItemSkusSlice={entireItemSkusSlice}
            customerId={customerId}
            customerType={customerType}
            info={row}
            allInboundFullKegShipmentObjects={allInboundFullKegShipmentObjects}
            configuredItemSkuIds={configuredItemSkuIds}
            itemSkuIdsToIncludeAsFieldsInTable={determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail({
              entireItemSkusSlice,
              shipment: row,
              useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
            })}
          />
        ),
        customSortInfo: {
          sortFunction: (apiObjs, ascOrDesc) => (orderBy_(apiObjs, ['shipmentId'], [ascOrDesc])),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testShipmentNumber', // CODE_COMMENTS_57
      },
      {
        heading: 'Order #',
        cellContent: row => (
          <KegOrderContainer
            row={row}
            itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
            includeLocalKegsColumns
            customerId={customerId}
          />
        ),
        className: 'testOrderId', // CODE_COMMENTS_57
      },
      (entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ?{
        heading: 'Shipment Type',
        cellContent: createDisplayedShipmentType,
        className: 'testShipmentType', // CODE_COMMENTS_57
      } : {
        heading: 'Movement Type',
        cellContent: createDisplayedMovementType,
        className: 'testMovementType', // CODE_COMMENTS_57
      }),
      {
        heading: 'Origin',
        cellContent: createDisplayedOriginNameForOutboundShipmentsHistoryTable,
        customSortInfo: {
          columnNotSortable: true, // CODE_COMMENTS_248
        },
        className: 'testOrigin', // CODE_COMMENTS_57
      },
      {
        heading: 'Ship Date',
        cellContent: createDisplayedShipmentDate,
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testShipmentDate', // CODE_COMMENTS_57
      },
      {
        heading: 'Status',
        cellContent: getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
        className: 'inboundStatus', // CODE_COMMENTS_57
      },
      {
        heading: 'Reference #',
        cellContent: createDisplayedProNumber(),
        className: 'testReferenceNumber', // CODE_COMMENTS_57
      },
      ...createContainerTypesFieldsDefinitions({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
        useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
      }),
      ...(
        // Only Warehouses have the "Valve Type" (i.e. "Spear") field rendered
        customerType === CUSTOMER_TYPES_WAREHOUSE
          ? [createValveTypeDefinition({
            entireItemSkusSlice,
            itemSkuIdsToIncludeAsFieldsInTable,
            useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
          })]
          : []
      ),
      ...(
        // Only Warehouses have the "Quality" field rendered
        customerType === CUSTOMER_TYPES_WAREHOUSE
          ? [createQualityLevelDefinition({
            entireItemSkusSlice,
            useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
          })]
          : []
      ),
    ],
    defaultSortColumn: 'Ship Date',
    filterFunc: getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
    rowKey: row => row.id,
  }
}


export default ({
  entireItemSkusSlice,
  customerId,
  customerType,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  allInboundFullKegShipmentObjects,
  configuredItemSkuIds,
  isFormSubmitting,
  hasFormSubmitFailed,
  noResultsMessage,
  areAnyItemsEditable,
  getIsindividualItemEditable,
  entireCustomersSlice,
}) => {
  const allInboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable(
      allInboundFullKegShipmentObjects,
    )

  const definitions = createTableContentDefinitions({
    entireItemSkusSlice,
    customerId,
    customerType,
    allInboundFullKegShipmentObjects,
    configuredItemSkuIds,
    entireCustomersSlice,
  })

  return (
    <HistoryTableCreator
      tableInfoObj={allInboundFullKegShipmentObjectsToBeIncludedInHistoryTable}
      definitions={definitions}
      isFormSubmitting={isFormSubmitting}
      hasFormSubmitFailed={hasFormSubmitFailed}
      noResultsMessage={noResultsMessage}
      additionalPropsToPassToTableCells={{ customerId, operatingContractBrewerCustomerId }}

      // for Edit and Delete buttons column
      areAnyItemsEditable={areAnyItemsEditable}
      itemType="inbound full keg shipment"
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      getIsindividualItemEditable={getIsindividualItemEditable}
    />
  )
}
