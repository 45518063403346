import FetchStatusReducerCreator from '../higherOrderReducers/reducerCreators'

import {
  FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
  FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
  FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
  FETCH_STATUSES_PUBLIC_FORMS_CLEAR,
} from '../../../actions/actionTypes'


export default FetchStatusReducerCreator({})(
  FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
  FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
  FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
  FETCH_STATUSES_PUBLIC_FORMS_CLEAR,
)
