/* eslint-disable no-useless-constructor */

import get_ from 'lodash/get'

/**
 * What's the best way to create custom errors in ES6? There's debate, but this
 * stackoverflow solution https://stackoverflow.com/a/32749533 is popular. The
 * problem is, it requires a custom babel plugin to work properly, which is a
 * non-starter for an app like this, whose source code must be easily compiled
 * and run on any maintainer's computer with minimum configuration (i.e. just
 * node and babel). Fortunately, the 'extendable-error-class' NPM module
 * replicates the babel plugin behavior. PS do we really need custom errors? No,
 * but they're nice to have. The alternative is to just `throw new Error(msg)`
 * and then check the message string in the catch to determine the error type.
 * This is a reasonable solution, but it requires regexes, which add their own
 * complexity. These classes are a little easier to read and maintain than
 * regexes.
 */
import ExtendableError from 'extendable-error-class'


export class PrelimInfoFetchNeededForRenderingDashboardFailedError extends ExtendableError {
  constructor(m) {
    super(m)
  }
}

export class NestedPropNotInObjectError extends ExtendableError {
  constructor(m) {
    super(m)
  }
}

export class CustomTypeError extends ExtendableError {
  constructor(m) {
    super(m)
  }
}

export class ApiReturnedUnexpectedDataError extends ExtendableError {
  constructor(m) {
    super(m)
  }
}

export class FormSubmissionError extends ExtendableError {
  constructor(m) {
    super(m)
  }
}

/**
For when you need to throw an xhr error but the API call itself isn't an error
but rather a 2xx response. See the createFetchFailureDetailsObject function in
the /sagas/util/createFetchFailureAction.js file for what exact props this class
needs, but the call should look something like this:

throw new XhrReplicationError({
  response: {
    status: 500,
    statusText: 'Internal server error',
    data: { message: 'Internal server error' },
    headers: {},
  },
  config: {
    url: '/collarOrders',
    params: {},
    method: 'GET',
  },
})

You can also call `throw new XhrReplicationError()` without any arguments and
it will use cookiecutter error details (a generic 500 error).
 */
export class XhrReplicationError extends ExtendableError {
  constructor(errorInfo) {
    super(get_(errorInfo, ['response', 'status'], 500).toString())
    this.response = get_(
      errorInfo,
      ['response'],
      {
        status: 500,
        statusText: 'fake xhr error',
        data: 'body of fake xhr error',
        headers: { 'x-amzn-requestid': 'fake x-amzn-requestid' },
      },
    )
    this.config = get_(
      errorInfo,
      ['config'],
      {
        url: 'fake url',
        params: {},
        method: 'GET',
      },
    )
  }
}
