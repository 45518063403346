import historyReducerCreator from './historyReducerCreator'

import {
  SAVE_INVENTORY_HISTORY,
  SAVE_INVENTORY_HISTORY_OF_MULTIPLE_CUSTOMERS,
  SAVE_INVENTORY_HISTORY_ITEMS_OF_MULTIPLE_CUSTOMERS_WITHOUT_DELETING_ANY_EXISTING_HISTORY_ITEMS,
  SAVE_SINGLE_INVENTORY_HISTORY_ITEM,
} from '../../actions/actionTypes'


export default historyReducerCreator({
  saveHistoryOfSingleCustomerActionType: SAVE_INVENTORY_HISTORY,
  saveHistoryOfMultipleCustomersActionType: SAVE_INVENTORY_HISTORY_OF_MULTIPLE_CUSTOMERS,
  saveHistoryItemsOfMultipleCustomersWithoutDeletingAnyExistingHistoryItemsActionType: (
    SAVE_INVENTORY_HISTORY_ITEMS_OF_MULTIPLE_CUSTOMERS_WITHOUT_DELETING_ANY_EXISTING_HISTORY_ITEMS
  ),
  saveSingleHistoryItemActionType: SAVE_SINGLE_INVENTORY_HISTORY_ITEM,
})
