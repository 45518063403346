import React from 'react'
import { Button } from 'semantic-ui-react'
import { Trans, useTranslation } from 'react-i18next'


export default ({ refetchInboundUnackedShipments }) => {
  const { t: translate } = useTranslation('pagelabels')
  return (
    <div>
      <p>
        <Trans ns="pagelabels" i18nKey="ackInboundShipments.You have no unacknowledged shipments at the moment.">
          You have no unacknowledged shipments at the moment.
        </Trans>
      </p>
      <Button
        color="blue"
        content={translate('ackInboundShipments.Refresh Shipments List')}
        onClick={refetchInboundUnackedShipments}
      />
    </div>
  )
}
