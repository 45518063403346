import { connect } from 'react-redux'

import InboundEmptyKegShipmentHistoryTable from '../../../../../InboundEmptyKegShipmentHistory/Table/components/Table'

import {
  getProp as getCustomerProp,
} from '../../../../../../../../redux/selectors/customers'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
} from '../../../../../../../../redux/selectors/rewrite/subsidiaries'
import {
  getAllShipmentsOfKegOrder,
} from '../../../../../../../../redux/selectors/histories/inboundShipmentsOfSpecificKegOrders'


const mapStateToProps = (state, { customerId, orderDetails }) => ({
  entireCustomersSlice: getEntireCustomersSlice(state),
  entireItemSkusSlice: getEntireItemSkusSlice(state),
  entireSubsidiariesSlice: getEntireSubsidiariesSlice(state),
  configuredItemSkuIds: getCustomerProp(state, customerId, 'itemSkuIds'),
  allInboundEmptyKegShipmentObjects: getAllShipmentsOfKegOrder(state, customerId, orderDetails.id),
  noResultsMessage: 'There are no shipments for this order yet.',
})

export default connect(
  mapStateToProps,
)(InboundEmptyKegShipmentHistoryTable)
