import React from 'react'
import moment from 'moment'


import DownloadInvoiceHistoryTableAsFile from '../DownloadTableAsFile'
import HistoryFormCreator from '../../HOC/HistoryFormCreator'

import { FETCH_INVOICE_HISTORY } from '../../../../../redux/actions/actionTypes'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
  COMMON_STATUS_ALL,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  HISTORY_FORM_NAME_INVOICES,
} from '../../../../../constants'

import {
  createFormNameForRedux,
} from '../../../../../utils'


const additionalIsFormSubmittableChecksBesidesDateRanges = () => (
  // There are no other fields besides date ranges for Invoice History
  true
)

const initialValues = {
  [HISTORY_FORM_FIELD_NAME_STATUS]: COMMON_STATUS_ALL, // CODE_COMMENTS_61
  [HISTORY_FORM_FIELD_NAME_START_DATE]:
    moment().subtract(1, 'year').startOf('month').format(DEFAULT_DISPLAYED_DATE_FORMAT),
  [HISTORY_FORM_FIELD_NAME_END_DATE]:
    moment().format(DEFAULT_DISPLAYED_DATE_FORMAT),
}

function createDateRangeMin() {
  return moment('01/01/1985', 'MM-DD-YYYY')
}

function createDateRangeMax() {
  return moment()
}

export default props => {
  const {
    customerId,
  } = props
  return (
    <HistoryFormCreator
      {...props}
      createDateRangeMin={createDateRangeMin}
      createDateRangeMax={createDateRangeMax}
      dateRangeFieldsLabel="Invoice Date"
      initialValues={initialValues}
      additionalIsFormSubmittableChecksBesidesDateRanges={additionalIsFormSubmittableChecksBesidesDateRanges}
      submitAction={FETCH_INVOICE_HISTORY}
      downloadFileComponent={DownloadInvoiceHistoryTableAsFile}
      formName={createFormNameForRedux({
        reducerName: HISTORY_FORM_NAME_INVOICES,
        customerId,
      })}
    />
  )
}
