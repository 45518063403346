import { connect } from 'react-redux'

import isFunction_ from 'lodash/isFunction'


import CustomerPortalOption from './components'

const mapStateToProps = (state, {
  disabledIf,
}) => {
  let disabled = false
  if (disabledIf) {
    disabled = isFunction_(disabledIf)
      ? disabledIf(state)
      : disabledIf
  }
  return {
    disabled,
  }
}

export default connect(
  mapStateToProps,
)(CustomerPortalOption)
