import { select } from 'redux-saga/effects'

import {
  getMostRecentlySubmittedFormValues,
} from '../../../selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'


import {
  getQuantitiesFormFieldsNames,
  parseOrderKegsFormFieldNameOfQuantity,

  // ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER,

  ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED,
  ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS,
  ORDER_KEGS_FORM_FIELD_NAME_CUSTOMER_PO_NUM,
  ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS,
  ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE,
  ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS,
  ORDER_KEGS_FORM_FIELD_NAME_DATE_TIME_NEEDED,
} from '../../../../constants/formAndApiUrlConfig/orderKegs'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  HISTORY_FORM_NAME_KEG_ORDERS,
  ORDER_KEGS_ORDER_TYPE_BLANKET,
  ORDER_KEGS_ORDER_TYPE_OPEN,
  ORDER_KEGS_ORDER_TYPE_STANDARD,
} from '../../../../constants'

import {
  getShouldKegOrderHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm,
} from '../../../../features/History/individualTabs/util/kegOrderHistory'

import {
  createFormNameForRedux,
  doesHistoryItemDateFallWithinHistoryFormDateRange,
  formatDateForApiCall,
} from '../../../../utils'
import {
  DELIVERY_SCHEDULE_FORM_VALUE,
  DELIVERY_SCHEDULE_ORDER_STATUS,
  DELIVERY_SCHEDULE_ORDER_STATUS_OPEN,
  standardOrderTypeSubsidaries,
} from '../../../../features/OrderKegs/OrderKegs/constants'

export function getItemSkuUUId(itemSkuId) {
  return `${itemSkuId}-id`
}
export function getItemSkuKegOrderId(itemSkuId) {
  return `${itemSkuId}-kegOrderId`
}
export function parseKegs(itemSkuIds, formValues) {
  // This gives us a list of all the quantities field names in the order kegs
  // form. At least one of these will be included in formValues (the ones the
  // user filled out). The true value in the second parameter indicates that the
  // user has local distributors. But what if the user doesn't have local
  // distributors? In that case, in the next line, when we iterate over these
  // form field names, the loop checks to see if the field exists in the form,
  // and if not, skips over it. (But see CODE_COMMENTS_63)
  const quantitiesFormFieldNames = getQuantitiesFormFieldsNames(itemSkuIds, true)

  const quantitiesFormFieldNamesInFormValues = Object.keys(formValues).filter(
    formValueFieldName => quantitiesFormFieldNames.includes(formValueFieldName),
  )

  return quantitiesFormFieldNamesInFormValues.map(fieldName => {
    const [itemSkuId] = parseOrderKegsFormFieldNameOfQuantity(fieldName)
    const id = formValues?.[getItemSkuUUId(itemSkuId)]
    return {
      itemSkuId,
      // orderType: localOrNonlocal === ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER ?
      // 'REGULAR' : 'LOCAL',
      quantity: Number(formValues[fieldName] || 0),
      id, // applicable only for edit
    }
  })
}


export function parseBlanketKegs({ formValues, itemSkuIds, isEdit, orderKegForm }) {
  const deliveryScheduleObjects = []
  const blanketKegs = []
  formValues.forEach(obj => {
    itemSkuIds.forEach(itemSkuId => {
      const quantity = Number(obj[itemSkuId] || 0)
      const kegOrderId = obj[getItemSkuKegOrderId(itemSkuId)]
      const id = obj[getItemSkuUUId(itemSkuId)]
      if (quantity < 1) { return }
      const kegObj = {
        itemSkuId,
        quantity,
        [DELIVERY_SCHEDULE_ORDER_STATUS]: DELIVERY_SCHEDULE_ORDER_STATUS_OPEN,
        ...{ ...(isEdit && { kegOrderId, id }) },
        [ORDER_KEGS_FORM_FIELD_NAME_DATE_TIME_NEEDED]: formatDateForApiCall({
          date: obj[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED],
        }),
      }
      deliveryScheduleObjects.push(kegObj)
    })
  })
  itemSkuIds.forEach(itemSkuId => {
    let quantity = 0
    const id = orderKegForm?.[getItemSkuUUId(itemSkuId)]
    formValues.forEach(obj => {
      quantity += Number(obj[itemSkuId] || 0)
    })
    if (quantity > 0) {
      blanketKegs.push({
        itemSkuId,
        quantity,
        ...{ ...(isEdit && { id }) },
      })
    }
  })
  return { deliveryScheduleObjects, blanketKegs }
}

// function parseKegsForBlanketOrders({ formValues }) {
//   return formValues?.reduce((acc, curr) => {
//     const obj = cloneDeep_(curr)
//     delete obj[ORDER_KEGS_FORM_FIELD_NAME_DATE_TIME_NEEDED]
//     delete obj[DELIVERY_SCHEDULE_ORDER_STATUS]
//     acc.push(obj)
//     return acc
//   }, [])
// }

function getOrderType({ formValues, customerId, entireCustomersSlice }) {
  // check for selected subsidiaries, if true send order type as standard
  const isStandardOrderType = standardOrderTypeSubsidaries?.includes(entireCustomersSlice?.[customerId]?.subsidiaryId)
  if (formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE] === ORDER_KEGS_ORDER_TYPE_OPEN && isStandardOrderType) {
    return ORDER_KEGS_ORDER_TYPE_STANDARD
  }
  return formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE]
}

export function extractFormValues(itemSkuIds, formValues, customerId, entireCustomersSlice) {
  const orderType = getOrderType({ formValues, customerId, entireCustomersSlice })
  const { deliveryScheduleObjects=[], blanketKegs=[] } = (orderType === ORDER_KEGS_ORDER_TYPE_BLANKET) ?
    parseBlanketKegs({
      formValues: formValues[DELIVERY_SCHEDULE_FORM_VALUE],
      itemSkuIds,
    }) : {}
  const kegs = (orderType === ORDER_KEGS_ORDER_TYPE_BLANKET) ? blanketKegs : parseKegs(itemSkuIds, formValues)

  const dateNeeded = formatDateForApiCall({
    date: formValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED],
  })
  const custPonum = formValues[ORDER_KEGS_FORM_FIELD_NAME_CUSTOMER_PO_NUM] || null
  const orderComments = formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS] || null
  const shipAddressId = formValues[ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS]
  const pickupFacilityId = formValues[ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS]

  return {
    orderLineItemObjects: kegs,
    deliveryScheduleObjects,
    dateNeeded,
    shipAddressId,
    custPonum,
    orderComments,
    orderType,
    pickupFacilityId,
  }
}


export function* doesKegOrderObjectMeetCriteriaOfHistoryForm(customerId, orderObj, forceSave: false) {
  const historyFormName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_KEG_ORDERS,
    customerId,
  })
  const mostRecentlySubmittedFormValues = yield select(getMostRecentlySubmittedFormValues, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return false }

  const historyFormStartDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_START_DATE]
  const historyFormEndDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_END_DATE]
  const historyFormOrderStatus = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_STATUS]

  const dateOk = doesHistoryItemDateFallWithinHistoryFormDateRange(
    orderObj.dateNeeded,
    historyFormStartDate,
    historyFormEndDate,
  )
  const statusOk = getShouldKegOrderHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm(
    historyFormOrderStatus,
    orderObj,
  )

  return (forceSave || (dateOk && statusOk))
}
export function extractDateValidationErrorMessageIfPresent(error) {
 return error?.response?.data?.validationErrors?.errors?.dateNeeded
}
