import React from 'react'
import { Form, Dropdown, Input } from 'semantic-ui-react'

import isFunction_ from 'lodash/isFunction'


import { useTranslation } from 'react-i18next'
import {
  getEntireSlice as getAddressObject,
} from '../../../../redux/selectors/addresses'

import {
  createFakeStateObject,
} from '../../../../redux/reducers/util/fakeState'

import {
  SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_SEARCH,
  SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE,
} from '../../../../constants/formAndApiUrlConfig/histories/shipmentHistoryShared'

import {
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  COMMON_STATUS_ALL,
  STATUSES_HUMAN_READABLE_NAMES_MAP,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  DOWNLOAD_HISTORY_TABLE_FILE_EXTENSION,
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_ADDRESSES,
  SHIPMENT_TYPES,
} from '../../../../constants'

import {
  isTruthyAndNonEmpty,
  formatDateStringForFilenameCompatibility,
  convertArrOfObjsOrSingleObjToObjOfSubObjs,
  parseShipmentType,
  getHumanReadableShipmentType,
} from '../../../../utils'


// The purpose of this is to handle props that might or might not be populated
// in the row object (for instance, optional fields such as "Order Comments").
// If the prop value is falsey, you can choose to do one of two things: 1) show
// alternate text (e.g. "none" or "n/a") styled in grey; 2) render null, which
// would show nothing. Having these two options means you can use this same
// function for both the "full details of individual row item" component (in
// which case you'd want to show alternate text) and the "Download Table as
// File" component (in which case you don't want to show any alternate text).
export const createDisplayedInfoWithAlternateTextOption = (propName, displayFunc=p => p) => alternateText => row => {
  if (row[propName]) { return displayFunc(row[propName]) }
  if (alternateText) {
    return <span style={{ color: 'grey' }}>{alternateText}</span>
  }
  return null
}


// Creates a filename such as 'keg_orders_20180403-20180701_status_all.csv'
export const createFilenameOfDownloadedTableFile = (
  startOfFilename, // e.g. "keg orders" (spaces will be replaced by underscores)
  startDateString,
  endDateString,
  additionalStringPairs, // optional, for things like 'status_all'
) => {
  const startDate = formatDateStringForFilenameCompatibility(startDateString)
  const endDate = formatDateStringForFilenameCompatibility(endDateString)
  const beginningOfFilename = replaceSpacesWithUnderscores(startOfFilename)

  let toReturn = `${beginningOfFilename}_${startDate}-${endDate}`

  if (additionalStringPairs) {
    const stringPairs = convertObjectIntoStringForFilename(additionalStringPairs)
    toReturn = `${toReturn}_${stringPairs}`
  }

  toReturn = `${toReturn}.${DOWNLOAD_HISTORY_TABLE_FILE_EXTENSION}`
  return toReturn.toLowerCase()
}


/*
 * *****************************************************************************
 * Download table file
 * *****************************************************************************
*/

export const createRowDefinitionsOfAddressForDownloadedTableFile = (
  allAddressObjectsToBeIncludedInDownloadHistoryTableFile,
  addressIdPropName,
  // An optional argument which, when included, will prepend each heading name
  // with the text this arg is set to. For example, pass in 'Destination' to
  // change each heading from e.g. 'Address 1' to 'Destination Address 1'.
  prependToHeadingNames,
) => {
  const state = createFakeStateObject([
    {
      path: [REDUCER_NAMES_ENTITIES, REDUCER_NAMES_ENTITIES_ADDRESSES],
      value: convertArrOfObjsOrSingleObjToObjOfSubObjs(
        allAddressObjectsToBeIncludedInDownloadHistoryTableFile,
      ),
    },
  ])
  let toReturn = [
    { heading: 'Address 1', cellContent: row => getAddressProp(state, row[addressIdPropName], 'address1') },
    { heading: 'Address 2', cellContent: row => getAddressProp(state, row[addressIdPropName], 'address2') },
    { heading: 'Address 3', cellContent: row => getAddressProp(state, row[addressIdPropName], 'address3') },
    { heading: 'Address City', cellContent: row => getAddressProp(state, row[addressIdPropName], 'city') },
    { heading: 'Address State or Province', cellContent: row => getAddressProp(state, row[addressIdPropName], 'stateOrProvince') },
    { heading: 'Address Country', cellContent: row => getAddressProp(state, row[addressIdPropName], 'country') },
    { heading: 'Address Postal Code', cellContent: row => getAddressProp(state, row[addressIdPropName], 'postalCode') },
  ]

  if (prependToHeadingNames) {
    toReturn = toReturn.map(def => ({
      // make sure to create a new object rather than editing the existing
      // object, otherwise unexpected results occur on multiple calls of this
      // surrounding function.
      ...def,
      heading: `${prependToHeadingNames} ${def.heading}`,
    }))
  }
  return toReturn
}


export const constructNonHeadingRowsOfDownloadedTableFile = (objects, definitions) => (
  objects.map(rowObject => definitions.map(def => def.cellContent(rowObject)))
)


/*
 * *****************************************************************************
 * statuses config
 * *****************************************************************************
*/

// Returns an array of objects with this shape:
// [
//   {
//     status: 'PEN'
//     humanReadableName: 'Pending',
//   },
//   ...
// ]
//
// And also adds this object to the start of the array:
//
// {
//   status: 'ALL',
//   humanReadableName: 'All',
// }
//
// If the status is configured with an alias in the passed-in statusesConfig
// arg, this alias will appear as the object's `humanReadableName` prop.
export function createHistoryFormDropdownOptionsFromStatusesConfig(statusesConfig) {
  const statusesThatShouldBeIncludedInDropdown = Object.keys(statusesConfig).filter(status => (
    // CODE_COMMENTS_144
    Boolean(statusesConfig[status].includeShipmentObjsInHistoryTable) &&
    Boolean(statusesConfig[status].includeStatusAsDropdownOptionInHistoryForm)
  ))
  const toReturn = statusesThatShouldBeIncludedInDropdown.map(status => ({
    status,
    humanReadableName: (
      statusesConfig[status].statusShouldBeDisplayedAsThisHumanreadableAlias ||
      STATUSES_HUMAN_READABLE_NAMES_MAP[status]
    ),
  }))

  return [
    {
      // CODE_COMMENTS_61
      status: COMMON_STATUS_ALL,
      humanReadableName: STATUSES_HUMAN_READABLE_NAMES_MAP[COMMON_STATUS_ALL],
    },
    ...toReturn,
  ]
}


// CODE_COMMENTS_143
export const isPurposeOfShipmentToFulfillKegOrder = shipmentObj => (
  Boolean(shipmentObj.orderId)
)


export const getShouldHistoryItemObjBeDisplayedInTable = (
  historyItemObj,
  statusConfig,
  statusPropName,
) => {
  // TODO rollbar: log error here: history items should never have a null status
  // prop
  if (!historyItemObj[statusPropName]) { return false }

  // eslint-disable-next-line max-len
  let includeShipmentObjsInHistoryTable = statusConfig[historyItemObj[statusPropName]]?.includeShipmentObjsInHistoryTable

  includeShipmentObjsInHistoryTable = isFunction_(includeShipmentObjsInHistoryTable)
    ? includeShipmentObjsInHistoryTable(historyItemObj)
    : includeShipmentObjsInHistoryTable

  return includeShipmentObjsInHistoryTable
}


export const getDisplayedHumanReadableStatusOfHistoryItem = (
  historyItemObj,
  statusConfig,
  statusPropName,
) => {
  // TODO rollbar: log error here: history items should never have a null status
  // prop
  if (!historyItemObj[statusPropName]) { return false }

  const shouldStatusBeSetToADifferentStatusForDisplay =
    statusConfig[historyItemObj[statusPropName]].renameThisStatusToAnother

  let whatShouldBeTheDisplayedStatusOfThisObject
  if (shouldStatusBeSetToADifferentStatusForDisplay) {
    const shouldStatusBeRenamed = isFunction_(shouldStatusBeSetToADifferentStatusForDisplay)
      ? shouldStatusBeSetToADifferentStatusForDisplay(historyItemObj)
      : true
    whatShouldBeTheDisplayedStatusOfThisObject = shouldStatusBeRenamed
      ? statusConfig[historyItemObj[statusPropName]].statusToRenameTo
      : historyItemObj[statusPropName]
  } else {
    whatShouldBeTheDisplayedStatusOfThisObject = historyItemObj[statusPropName]
  }

  const doesTheStatusHaveAnAlias =
    statusConfig[whatShouldBeTheDisplayedStatusOfThisObject].statusShouldBeDisplayedAsThisHumanreadableAlias

  return (
    doesTheStatusHaveAnAlias ||
    STATUSES_HUMAN_READABLE_NAMES_MAP[whatShouldBeTheDisplayedStatusOfThisObject]
  )
}


// CODE_COMMENTS_145
export const getShouldHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm = (
  currentlySelectedStatusInHistoryForm,
  historyItemObj,
  statusConfig,
  statusPropName,
) => {
  // sanity check
  const shouldHistoryItemObjBeDisplayedInTableAtAll = getShouldHistoryItemObjBeDisplayedInTable(
    historyItemObj,
    statusConfig,
    statusPropName,
  )
  if (!shouldHistoryItemObjBeDisplayedInTableAtAll) { return false }

  // TODO rollbar log error here: history items should always have their status prop filled in
  if (!historyItemObj[statusPropName]) { return false }

  if (currentlySelectedStatusInHistoryForm === COMMON_STATUS_ALL) { return true }

  // this should never happen, but just in case the caller does something
  // boneheaded
  if (
    !statusConfig[currentlySelectedStatusInHistoryForm] ||
    !statusConfig[currentlySelectedStatusInHistoryForm].includeStatusAsDropdownOptionInHistoryForm
  ) { return false }


  const otherStatusesThatHaveBeenRenamedToTheStatusChosenByUserInHistoryForm =
    Object.keys(statusConfig).filter(
      status => statusConfig[status].statusToRenameTo === currentlySelectedStatusInHistoryForm,
    )

  return [
    currentlySelectedStatusInHistoryForm,
    ...otherStatusesThatHaveBeenRenamedToTheStatusChosenByUserInHistoryForm,
  ].includes(statusPropName)
}


// CODE_COMMENTS_156
export const filterHistoryObjectsWithUnrecognizedStatus = (
  objectOfHistoryObjects, statusesConfigObject, statusKey, filterVal,
) => {
  // if the history items have not yet been fetched
  if (!isTruthyAndNonEmpty(objectOfHistoryObjects)) { return objectOfHistoryObjects }

  const allRecognizedStatusesOfThisHistoryType = Object.keys(statusesConfigObject)
  return Object.keys(objectOfHistoryObjects).reduce(
    (acc, historyObjId) => {
      if (allRecognizedStatusesOfThisHistoryType.includes(objectOfHistoryObjects?.[historyObjId]?.[statusKey])) {
        if (filterVal) {
          // eslint-disable-next-line max-len
          if (objectOfHistoryObjects?.[historyObjId]?.shipmentId.toUpperCase().indexOf(filterVal.toUpperCase()) > -1) {
            return {
              ...acc,
              [historyObjId]: objectOfHistoryObjects[historyObjId],
            }
          }
          return acc
        }
        return {
          ...acc,
          [historyObjId]: objectOfHistoryObjects[historyObjId],
        }
      }
      // TODO rollbar: send message explaining that a history item has a 'status'
      // prop value that the web app doesn't recognize. This should never happen:
      // the web app can't properly display a human-readable 'status' name for
      // this history item nor can it determine whether this item should be
      // included in the history table based on the user's "status" selection in
      // the history form. Therefore, the web app isn't showing this item in the
      // history table at all.
      return acc
    },
    {},
  )
}


/*
 * *****************************************************************************
 * Shipment Type
 * *****************************************************************************
*/

export function createHistoryFormShipmentTypeDropdownOptionsFromCustomerType({
  customerType,
  inboundOrOutbound,
}) {
  const toReturn = SHIPMENT_TYPES.filter(shipmentType => {
    const [origin, dest] = parseShipmentType(shipmentType)
    const targetCustomerType = inboundOrOutbound === 'inbound' ? dest : origin
    return customerType === targetCustomerType
  }).map(shipmentType => ({
    shipmentType,
    humanReadableName: getHumanReadableShipmentType(shipmentType),
  }))

  return [
    {
      // CODE_COMMENTS_61
      shipmentType: COMMON_STATUS_ALL,
      humanReadableName: STATUSES_HUMAN_READABLE_NAMES_MAP[COMMON_STATUS_ALL],
    },
    ...toReturn,
  ]
}


/*
 * *****************************************************************************
 * History form fields
 * *****************************************************************************
*/

export const HistoryFormStatusField = ({
  statusesConfig,
  formValues,
  dispatchFormValues,
  label='Status',
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Form.Field inline>
      <label htmlFor={HISTORY_FORM_FIELD_NAME_STATUS}>{translate(label)}:</label>
      <Dropdown
        selection
        name={HISTORY_FORM_FIELD_NAME_STATUS}
        options={
          createHistoryFormDropdownOptionsFromStatusesConfig(
            statusesConfig,
          ).map(({
            status,
            humanReadableName,
          }) => (
            { key: status, value: status, text: humanReadableName }
          ))
        }
        value={formValues[HISTORY_FORM_FIELD_NAME_STATUS]}
        onChange={
          (event, { value }) => {
            dispatchFormValues({
              [HISTORY_FORM_FIELD_NAME_STATUS]: value,
            })
          }
        }
      />
    </Form.Field>
  )
}

export const ShipmentTypeField = ({
  customerType,
  inboundOrOutbound,
  formValues,
  dispatchFormValues,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Form.Field inline>
      <label htmlFor={SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE}>{`${translate('Shipment Type')}:`}</label>
      <Dropdown
        selection
        name={SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE}
        options={
          createHistoryFormShipmentTypeDropdownOptionsFromCustomerType({
            customerType,
            inboundOrOutbound,
          }).map(({
            shipmentType,
            humanReadableName,
          }) => (
            { key: shipmentType, value: shipmentType, text: humanReadableName }
          ))
        }
        value={formValues[SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE]}
        onChange={
          (event, { value }) => {
            dispatchFormValues({
              [SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE]: value,
            })
          }
        }
      />
    </Form.Field>
  )
}

/* send inputRef from container or index */
export const ShipmentSearchField = ({ shipmentSearch, setShipmentSearch }) => {
  const { t: translate } = useTranslation('common')
  return (
    <Form.Field inline>
      <label htmlFor={SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE}>{`${translate('Shipment')}:`}</label>
      <Input
        autoComplete="off"
        name={SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_SEARCH}
        value={shipmentSearch}
        placeholder={translate('Search Shipment')}
        onChange={
          (event, { value }) => {
            setShipmentSearch(value)
          }
        }
      />
    </Form.Field>
  )
}


/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

function replaceSpacesWithUnderscores(str) {
  return str.trim().replace(' ', '_')
}

// { status: 'all', 'two words': 'three more words'} --> `status_all_two_words_three_more_words`
function convertObjectIntoStringForFilename(obj) {
  return Object.keys(obj).reduce(
    (acc, key, index) => {
      const toAdd = `${replaceSpacesWithUnderscores(key)}_${replaceSpacesWithUnderscores(obj[key])}`
      if (index === 0) { return toAdd }
      return `${acc}_${toAdd}`
    },
    '',
  )
}


// The backend is never supposed to send us a keg order going to an address that
// the user doesn't have on file (and therefore that we didn't receive from the
// /addresses GET call), but sometimes bugs happen. This function ensures that
// if the /addresses call didn't return the delivery address of this keg order,
// the web app won't throw a NestedPropNotInObject error.
function getAddressProp(state, addressId, propName) {
  const addressObject = getAddressObject(state, addressId)
  if (!addressObject) {
    return '[No address on file]'
    // TODO: send rollbar error
  }
  return addressObject[propName]
}
