/**
 * CODE_COMMENTS_93
 */
import React from 'react'

import orderBy_ from 'lodash/orderBy'


import HistoryTableCreator from '../../../HOC/HistoryTableCreator'
import FillReportDetails from '../subFeatures/FillReportDetails'

import {
  getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
  getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
  getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
} from '../../../util/shipmentHistory/outboundFullKegShipmentHistory'
import {
  createSpecialTableContentDefinitions,
} from '../../../util/shipmentHistory/noMovements'
import {
  createDisplayedDestinationName,
  createDisplayedShipmentDate,
  createDisplayedProNumber,
  // createItemSkuIdFieldsDefinitions,
  createContainerFieldsDefinitionsForKegFills,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'
import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'


function createTableContentDefinitions({
  customerId,
  entireItemSkusSlice,
  configuredItemSkuIds,
  allOutboundFullKegShipmentObjects,
}) {
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allOutboundFullKegShipmentObjects,
  })

  return {
    content: [
      {
        heading: 'Fill #',
        cellContent: row => (
          <FillReportDetails
            entireItemSkusSlice={entireItemSkusSlice}
            customerId={customerId}
            info={row}
            itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
          />
        ),
        customSortInfo: {
          sortFunction: (apiObjs, ascOrDesc) => (orderBy_(apiObjs, ['shipmentId'], [ascOrDesc])),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testShipmentNumber', // CODE_COMMENTS_57
      },
      {
        heading: 'Filled For',
        cellContent: createDisplayedDestinationName,
        customSortInfo: {
          columnNotSortable: true, // CODE_COMMENTS_248
        },
        className: 'testFilledFor', // CODE_COMMENTS_57
      },
      {
        heading: 'Fill Date',
        cellContent: createDisplayedShipmentDate,
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testFillDate', // CODE_COMMENTS_57
      },
      {
        heading: 'Status',
        cellContent: getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
        className: 'testStatus', // CODE_COMMENTS_57
      },
      {
        heading: 'Reference #',
        cellContent: createDisplayedProNumber(),
        className: 'testReferenceNumber', // CODE_COMMENTS_57
      },
      // ...createItemSkuIdFieldsDefinitions({
      //   entireItemSkusSlice,
      //   itemSkuIdsToIncludeAsFieldsInTable,
      // }),
      ...createContainerFieldsDefinitionsForKegFills({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
      }),
    ],
    defaultSortColumn: 'Fill Date',
    filterFunc: getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
    rowKey: row => row.id,
  }
}

export default ({
  entireItemSkusSlice,
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  allOutboundFullKegShipmentObjects,
  allNoMovementsObjects,
  configuredItemSkuIds,
  isFormSubmitting,
  hasFormSubmitFailed,
  noResultsMessage,
  areAnyItemsEditable,
  getIsindividualItemEditable,
}) => {
  const itemType = 'keg fill report'
  const allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable(
      allOutboundFullKegShipmentObjects,
    )
  const definitions = createTableContentDefinitions({
    customerId,
    entireItemSkusSlice,
    configuredItemSkuIds,
    allOutboundFullKegShipmentObjects,
  })
  const specialTableContentDefinitions = createSpecialTableContentDefinitions({
    itemType,
    shouldOriginColumnBeRendered: false,
  })

  return (
    <HistoryTableCreator
      tableInfoObj={allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable}
      definitions={definitions}
      isFormSubmitting={isFormSubmitting}
      hasFormSubmitFailed={hasFormSubmitFailed}
      additionalPropsToPassToTableCells={{ customerId, operatingContractBrewerCustomerId }}
      noResultsMessage={noResultsMessage}

      // for Edit and Delete buttons column
      areAnyItemsEditable={areAnyItemsEditable}
      itemType={itemType}
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      getIsindividualItemEditable={getIsindividualItemEditable}

      // For NoMovements rows
      specialInfoObj={allNoMovementsObjects}
      specialTableContentDefinitions={specialTableContentDefinitions}
    />
  )
}
