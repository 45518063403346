/**
 * This wrapper component's purpose is to redirect users to the site root if
 * they try to manually navigate to /forgot-password or /confirm-account while
 * currently logged in.
 * https://reacttraining.com/react-router/web/example/auth-workflow
 */
import React from 'react'
import { Redirect } from 'react-router-dom'

import FormDecider from './containers/FormDecider'

import withIsAuthenticated from '../../../common-components/HOC/redux/withIsAuthenticated'

import PublicPageWrapper from '../../../common-components/publicPages/PublicPageWrapper'

import {
  URL_PATHS,
} from '../../../constants'

export default withIsAuthenticated(({
  isAuthenticated,
}) => (
  isAuthenticated
    ? <Redirect to={{ pathname: URL_PATHS.root.path }} />
    : <WrappedFormDecider />
))


const WrappedFormDecider = PublicPageWrapper('Confirm Account')(() => (
  <FormDecider />
))
