import {
  getPropsValidatorsOfSpecificUserType,
  WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE,
} from './util'

import {
  createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers,
} from '../util'

import {
  logObjectHasProblemsErrorMessage,
  CAN_STILL_BE_DISPLAYED,
  CANT_BE_DISPLAYED,
} from '../../../../../utils/thirdPartyLogging'

import {
  isTruthyAndNonEmpty,
} from '../../../../../utils'


// `props` should be an object with at least one property: `data`
export function validateCustomerRepObject(props) {
  const individualPropsValidatorsAndTransformers = getPropsValidatorsOfSpecificUserType({
    userType: WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE,
  })

  const results = createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
    ...props,
    individualPropsValidatorsAndTransformers,
  })

  const {
    arrayOfProblemsWithData,
    canDataBeSavedToStore,
  } = results

  const {
    data,
    httpResponse,
  } = props

  if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
    logObjectHasProblemsErrorMessage({
      objectType: 'customerRepresentativeObject',
      level: canDataBeSavedToStore ? CAN_STILL_BE_DISPLAYED : CANT_BE_DISPLAYED,
      additionalInfo: {
        arrayOfProblemsWithData,
        targetObject: data,
      },
      httpResponse,
    })
  }

  return results
}
