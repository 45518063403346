/* eslint-disable max-len */

import { connect } from 'react-redux'

import isPlainObj_ from 'lodash/isPlainObject'


import CustomerPortal from './components'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../../../../redux/selectors/rewrite/parentChildLinks'
import {
  getEntireSlice as getEntireContractsSlice,
  getHasContractThatCanHaveKegsOrderedOnIt,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../../../redux/selectors/rewrite/users'

import {
  getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getShouldReportOutboundShipmentsFormBeRendered,
  getShouldReportBuybackShipmentsFormBeRendered,
  getShouldReportSelfDistAndPubShipmentsFormBeRendered,
  getShouldReportKegFillsFormBeRendered,
  getShouldReportSelfCollectionShipmentsFormBeRendered,
} from '../../../../../redux/selectors/rewrite/forms/reportShipmentsCommon/shouldReportShipmentsFormBeRendered'

import {
  getShouldReportInventoryFormBeRendered,
} from '../../../../ReportInventory/util'
import {
  getShouldReportedInventoryVsCalculatedInventoryPageBeRendered,
} from '../../../../../redux/selectors/reportedInventoryVsCalculatedInventory'

import {
  getShouldOrderKegCollarsFormBeRendered,
} from '../../../../OrderKegCollars/util'

import { getProp as getCustomerProp } from '../../../../../redux/selectors/customers'
import { getEntireSlice as getEntireSubsidiariesSlice } from '../../../../../redux/selectors/rewrite/subsidiaries'


const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)
  const currency = getCustomerProp(state, customerId, 'currency')


  const isRelatedToLocalDistributors = getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  })

  const hasContractThatCanHaveKegsOrderedOnIt = getHasContractThatCanHaveKegsOrderedOnIt({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  })

  const shouldOrderKegCollarsFormBeRendered = getShouldOrderKegCollarsFormBeRendered({
    entireCustomersSlice,
    entireRelationshipsSlice,
    entireContractsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    entireSubsidiariesSlice,
  })

  const shouldReportOutboundShipmentsFormBeRendered = getShouldReportOutboundShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })

  // CODE_COMMENTS_231
  const {
    shouldOptionBeDisabled: shouldReportOutboundShipmentsFormOptionBeDisabled,
    whyDisabledMessage: whyReportOutboundShipmentsFormDisabledMessage,
  } = isPlainObj_(shouldReportOutboundShipmentsFormBeRendered)
    ? shouldReportOutboundShipmentsFormBeRendered
    : {}

  const shouldReportBuybackShipmentsFormBeRendered = getShouldReportBuybackShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })
  const shouldReportSelfDistAndPubShipmentsFormBeRendered = getShouldReportSelfDistAndPubShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })
  const shouldReportKegFillsFormBeRendered = getShouldReportKegFillsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })
  const shouldReportSelfCollectionShipmentsFormBeRendered = getShouldReportSelfCollectionShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })

  const shouldReportInventoryFormBeRendered = getShouldReportInventoryFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    customerId,
  })
  const shouldReportedInventoryVsCalculatedInventoryPageBeRendered =
    getShouldReportedInventoryVsCalculatedInventoryPageBeRendered({
      state,
      customerId,
    })

  return {
    // Why Booleans? See CODE_COMMENTS_252
    isRelatedToLocalDistributors: Boolean(isRelatedToLocalDistributors),
    hasContractThatCanHaveKegsOrderedOnIt,
    shouldOrderKegCollarsFormBeRendered: Boolean(shouldOrderKegCollarsFormBeRendered),
    shouldReportOutboundShipmentsFormBeRendered: Boolean(shouldReportOutboundShipmentsFormBeRendered),
    shouldReportOutboundShipmentsFormOptionBeDisabled: Boolean(shouldReportOutboundShipmentsFormOptionBeDisabled),
    whyReportOutboundShipmentsFormDisabledMessage,
    shouldReportBuybackShipmentsFormBeRendered: Boolean(shouldReportBuybackShipmentsFormBeRendered),
    shouldReportSelfDistAndPubShipmentsFormBeRendered: Boolean(shouldReportSelfDistAndPubShipmentsFormBeRendered),
    shouldReportInventoryFormBeRendered: Boolean(shouldReportInventoryFormBeRendered),
    shouldReportKegFillsFormBeRendered: Boolean(shouldReportKegFillsFormBeRendered),
    shouldReportSelfCollectionShipmentsFormBeRendered: Boolean(shouldReportSelfCollectionShipmentsFormBeRendered),
    shouldReportedInventoryVsCalculatedInventoryPageBeRendered: Boolean(shouldReportedInventoryVsCalculatedInventoryPageBeRendered),
    currency,
  }
}

export default connect(
  mapStateToProps,
)(CustomerPortal)
