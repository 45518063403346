/* eslint-disable react/no-multi-comp */

import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'


import { Trans } from 'react-i18next'
import ModalWithCatastrophicFailureErrorBoundary from '../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'

import UnackedInboundShipmentsTable from '../subfeatures/UnackedInboundShipmentsTable'

import {
  URL_PATHS,
} from '../../../constants'

import {
  createRelativePath,
} from '../../../utils'


const modalHtmlId = 'modal-acknowledge-inbound-shipments' // CODE_COMMENTS_102

class SubmitButtonWithConfirmFormInput extends Component {
  state = {
    modalOpen: true,
  }

  componentDidMount() {
    if (
      !this.props.hasAnyInboundUnacknowledgedShipments
      ||
      this.props.hasAckInboundShipmentsReminderPopupHadChanceToRender
    ) {
      this.props.setAckInboundShipmentsReminderPopupHasHadTheChanceToRender()
    }
  }

  handleOpen = () => {
    this.setState(
      { modalOpen: true },
      // sets the focus on the 'acknowledge shipments' button. See CODE_COMMENTS_102
      () => {
        const $button = document.querySelector(modalHtmlId)
        if ($button) { $button.focus() }
      },
    )
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
    this.props.setAckInboundShipmentsReminderPopupHasHadTheChanceToRender()
  }

  handleAcknowledgeShipments = () => {
    this.handleClose()
    // where's our code that redicrects to the Ack Inbound Shipments form? It's
    // the 'to' prop on the button below.
  }

  render() {
    if (
      this.props.hasAnyInboundUnacknowledgedShipments
      &&
      !this.props.hasAckInboundShipmentsReminderPopupHadChanceToRender
    ) {
      return (
        <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
          closeOnDimmerClick={false} // CODE_COMMENTS_106
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="small"
          closeIcon
          style={{ textAlign: 'center' }}
        >
          <Modal.Header>
            <Trans ns="common" i18nKey="You have unacknowledged shipments!">
              You have unacknowledged shipments!
            </Trans>
          </Modal.Header>
          <Modal.Content>
            <UnackedInboundShipmentsTable {...this.props} />
          </Modal.Content>
          <Modal.Actions
            style={{ textAlign: 'center' }}
          >
            <Button
              as={Link}
              to={createRelativePath(this.props.match, URL_PATHS.acknowledgeInboundShipments.path)}
              type="button" // CODE_COMMENTS_260
              color="green"
              onClick={this.handleClose}
              id={modalHtmlId}
              style={{ marginBottom: '1em' }}
            >
              <Trans ns="common" i18nKey="Acknowledge One or More of These">
                Acknowledge One or More of These
              </Trans>
            </Button>
            <br />
            <Button
              // This specifically needs to be type="button"; the button with
              // type="submit" is elsewhere
              type="button" // CODE_COMMENTS_260
              color="grey"
              size="small"
              onClick={this.handleClose}
            >
              <Trans ns="common" i18nKey="These shipments haven't arrived yet">
                {"These shipments haven't arrived yet"}
              </Trans>
            </Button>
          </Modal.Actions>
        </ModalWithCatastrophicFailureErrorBoundary>
      )
    }
    // else
    return null
  }
}

export default withRouter(SubmitButtonWithConfirmFormInput)
