/**
 * CODE_COMMENTS_79
 */
import Immutable from 'seamless-immutable'

import {
  SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES,
} from '../../actions/actionTypes'


const initialState = Immutable({})

export default function mostRecentSuccessfullySubmittedHistoryFormValuesReducer(state=initialState, action) {
  switch (action.type) {
    case SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES: {
      const { formName, formValues } = action.payload
      return (
        Immutable.setIn(state, [formName], formValues)
      )
    }

    default:
      return state
  }
}
