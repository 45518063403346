/* eslint-disable no-continue */

import { call, put, select, takeEvery } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import isEqual_ from 'lodash/isEqual'

import {
  hardRefreshToDownloadLatestWebAppVersion,
} from './hardRefreshToDownloadLatestWebAppVersion'

import {
  initialStateNumHardRefreshesByWebAppAtemptingToFetch,
} from '../../reducers/latestWebAppVersion'

import {
  getLatestWebAppVersionString,
  getCurrentWebAppVersionString,
  getIsLatestWebAppVersionNumberGreaterThanCurrentWebAppVersionNumber,
  getNumberOfHardRefreshesByWebAppVersionAttemptingToFetch,
  getEntireNumberOfHardRefreshesByWebAppVersionAttemptingToFetchSlice,
} from '../../selectors/latestWebAppVersion'
import {
  getTimeOfMostRecentUserActivity,
} from '../../selectors/timeOfMostRecentUserActivity'
import {
  getAreWeCurrentlyInMaintenanceMode,
} from '../../selectors/maintenanceMode'

import {
  START_HARD_REFRESH_THREAD_TO_DOWNLOAD_LATEST_WEB_APP_VERSION,
  CLEAR_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH,
} from '../../actions/actionTypes'
import createAction from '../../actions/createAction'

import {
  logErrorMessage,
  LOG_SEVERITY_ERROR,
} from '../../../utils/thirdPartyLogging'

import {
  TRY_TO_DOWNLOAD_NEW_VERSION_OF_WEB_APP_AT_MOST_X_TIMES_IN_A_ROW,
  ONLY_DOWNLOAD_LATEST_VERSION_OF_WEB_APP_WHEN_NO_USER_ACTIVITY_FOR_LAST_X_MINUTES,
} from '../../../config'

// Don't set this to less than 60 seconds because unfocused browser tabs are
// only allowed to update once a minute in Chrome.
const waitXSecondsInBetweenCurrentToLatestWebAppVersionComparisons = 60


// This is the saga that checks whether the latest web app version is greater
// than the current web app version and hard-refreshes the browser if so
// (thereby downloading the newest version of the app), refreshing multiple
// times if necessary.

// Wait, shouldn't this be a cancelable saga which gets canceled on logout or
// authentication failure? Yes, but instead of using the cancelableForkedSaga()
// function here, we let our main rootSaga() wrap this with a
// transformSagasIntoCancellableSagas() function.
function* hardRefreshThreadToDownloadLatestWebAppVersion() {
  while (true) {
    // Pause this thread while we're in maintenance mode (CODE_COMMENTS_238)
    const areWeCurrentlyInMaintenanceMode = yield select(getAreWeCurrentlyInMaintenanceMode)
    if (areWeCurrentlyInMaintenanceMode) {
      yield call(delay, 20*1000) // wait several seconds
      continue
    }
    if (yield select(getIsLatestWebAppVersionNumberGreaterThanCurrentWebAppVersionNumber)) {
      const latestWebAppVersionString = yield select(getLatestWebAppVersionString)
      const howManyTimesHasTheAppTriedToDownloadThisLatestVersion = yield select(
        getNumberOfHardRefreshesByWebAppVersionAttemptingToFetch,
        latestWebAppVersionString,
      )
      if (
        howManyTimesHasTheAppTriedToDownloadThisLatestVersion
        < TRY_TO_DOWNLOAD_NEW_VERSION_OF_WEB_APP_AT_MOST_X_TIMES_IN_A_ROW
      ) {
        yield call(
          delay,
          // here we're using an exponential backoff exponent of 4, which gives
          // us this rather stretched out schedule:

          // * 1st redownload: 1 second after previous download
          // * 2nd: 16 seconds after previous download
          // * 3rd: 1m 21s
          // * 4th: 4m 16s
          // * 5th: 10m 25s
          // * 6th: 21m 36s
          // * 7h: 40m 01s
          // * 8th: 1h 8m 16s

          // Why so long between re-downloads? The front-end devs have noticed
          // that it can sometimes take hours for the AWS Cloudfront cache to
          // start serving the new web app, so we don't want to try to download
          // the new version 8 times within a few minutes. Also, remember that
          // this feature is mostly for end users who leave the web app open
          // overnight, so it's OK for this entire process to take multiple
          // hours. An exponent to the 4th means that the first to the 8th
          // download takes a total of around 2.5 hours. If it turns out that
          // this feature becomes annoying for users, we need to look into our
          // Cloudfront cache settings and start demanding more agressive
          // "update cache when a new version is pushed" behavior from it.
          ((howManyTimesHasTheAppTriedToDownloadThisLatestVersion)**4)*1000,
        )
        yield call(waitUntilThereHasBeenNoRecentUserActivity)
        yield call(hardRefreshToDownloadLatestWebAppVersion)
      } else {
        // If the refresh token hasn't expired but some other error occurred, log the
        // error to our 3rd-party logging service
        logErrorMessage({
          message: `User's Browser Will Not Download New Web App Version, Even After ${TRY_TO_DOWNLOAD_NEW_VERSION_OF_WEB_APP_AT_MOST_X_TIMES_IN_A_ROW} Refreshes`,
          severity: LOG_SEVERITY_ERROR,
          additionalInfo: {
            currentWebAppVersion: getCurrentWebAppVersionString(),
            newWebAppVersion: latestWebAppVersionString,
            downloadAttempts: TRY_TO_DOWNLOAD_NEW_VERSION_OF_WEB_APP_AT_MOST_X_TIMES_IN_A_ROW,
          },
        })
        // wait 1 minute before re-checking to see whether the latest version of
        // the web app has changed (unfocused browser tabs are only updated
        // every minute, so this is the shortest reasonable amount of time we'll
        // want to wait)
        yield call(delay, waitXSecondsInBetweenCurrentToLatestWebAppVersionComparisons*1000)
      }
    } else {
      const slice = yield select(getEntireNumberOfHardRefreshesByWebAppVersionAttemptingToFetchSlice)
      if (!isEqual_(slice, initialStateNumHardRefreshesByWebAppAtemptingToFetch)) {
        yield put(createAction(CLEAR_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH))
      }
      // wait 1 minute (unfocused browser tabs are only updated every minute, so
      // this is the shortest reasonable amount of time we'll want to wait)
      yield call(delay, waitXSecondsInBetweenCurrentToLatestWebAppVersionComparisons*1000)
    }
  }
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    START_HARD_REFRESH_THREAD_TO_DOWNLOAD_LATEST_WEB_APP_VERSION,
    hardRefreshThreadToDownloadLatestWebAppVersion,
  ],
]

// helper functions
function* waitUntilThereHasBeenNoRecentUserActivity() {
  let xMunitesAgo = (
    Date.now()
    - ONLY_DOWNLOAD_LATEST_VERSION_OF_WEB_APP_WHEN_NO_USER_ACTIVITY_FOR_LAST_X_MINUTES*1000*60
  )
  let timeOfMostRecentUserActivity = yield select(getTimeOfMostRecentUserActivity)
  while (timeOfMostRecentUserActivity > xMunitesAgo) {
    yield call(delay, 30*1000)
    xMunitesAgo = Date.now() - ONLY_DOWNLOAD_LATEST_VERSION_OF_WEB_APP_WHEN_NO_USER_ACTIVITY_FOR_LAST_X_MINUTES*1000*60
    timeOfMostRecentUserActivity = yield select(getTimeOfMostRecentUserActivity)
  }
}
