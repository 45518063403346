import React from 'react'
import { Container, Segment, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import isPlainObj_ from 'lodash/isPlainObject'
import isFunc_ from 'lodash/isFunction'


import { isString } from '../../utils'


/**
 * A simple component that puts content into its own Container and Segment. You
 * can either pass in a string as the argument, which will be the header text,
 * or an object:
 *
 * {
 *   headerText: 'My Header',
 *   headerProps: {className: 'segment-header'}
 * }
 *
 * You can also pass in these two props in the wrapped component. To do so, set
 * a prop in the wrapped component called uiContainerHocProps and set it to an
 * object with the same keys as the one defined above.
 */
export default hocProps => Component => ({ uiContainerHocProps, ...rest }) => {
  const { t: translate } = useTranslation(['common'])
  let headerText
  let headerProps = {}
  let containerId
  let dontActuallyApplyTheUiContainerHoc
  let containerProps
  if (hocProps === undefined) {
    if (uiContainerHocProps) {
      ({
        headerText,
        headerProps,
        containerId,
        dontActuallyApplyTheUiContainerHoc,
        ...containerProps
      } = uiContainerHocProps)
    }
  } else if (isString(hocProps)) {
    headerText = hocProps
  } else if (isFunc_(hocProps)) {
    ({
      headerText,
      headerProps,
      containerId,
      dontActuallyApplyTheUiContainerHoc,
      ...containerProps
    } = hocProps(rest))
  } else if (isPlainObj_(hocProps)) {
    ({
      headerText,
      headerProps,
      containerId,
      dontActuallyApplyTheUiContainerHoc,
      ...containerProps
    } = hocProps)
  }
  if (dontActuallyApplyTheUiContainerHoc) {
    return <Component {...rest} />
  }
  return (
    <Container
      id={containerId || null}
      {...containerProps}
    >
      <Segment>
        {headerText
          ? <Header as="h2" color="grey" {...headerProps}>{translate(headerText)}</Header>
          : null
        }
        <Component
          {...rest}
        />
      </Segment>
    </Container>
  )
}
