import React from 'react'
import { Dropdown } from 'semantic-ui-react'


import {
  createMonthAndYearPickerDropdownOptions,
} from './util'


export default ({
  rangeMin,
  rangeMax,
  name,
  sortOptionsInDropdownFromLatestToEarliestRatherThanEarliestToLatest=false,
  placeholder='Select Month',
  ...rest
}) => (
  <Dropdown
    options={createMonthAndYearPickerDropdownOptions({
      rangeMin,
      rangeMax,
      sortOptionsInDropdownFromLatestToEarliestRatherThanEarliestToLatest,
    })}
    name={name}
    placeholder={placeholder}
    selection
    {...rest}
  />
)
