import {
  SAVE_USER,
  SAVE_USERS,
} from '../../actions/actionTypes'

import reducerCreator from './higherOrderEntityReducers/basicReducerCreator'

export default reducerCreator([
  SAVE_USERS,
  SAVE_USER,
])
