import { call, select, takeEvery } from 'redux-saga/effects'
import moment from 'moment'


import { privateFetch } from '../../fetch'

import {
  FETCH_REPORT_INDIVIDUAL_SHIPMENT,
  FETCH_STATUSES_FORMS_REQUEST,
  FETCH_STATUSES_FORMS_SUCCESS,
  FETCH_STATUSES_FORMS_FAILURE,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'
import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'

import {
  getProp as getCurrentUserProp,
} from '../../../selectors/currentUser'

import {
  extractFormValues,
  saveShipmentObjectToPotentialDuplicatesSliceIfMeetsCriteria,
} from './util'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm,
} from '../sharedUtil/ackInboundShipmentsAndReportOutboundShipments'

import {
  SHIPMENT_STATUS_PENDING,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  API_URL_PATH_SHIPMENTS_HISTORY,
  SHIPMENT_TYPES_FREIGHT,
} from '../../../../constants'

import {
  formatDateForApiCall,
} from '../../../../utils'


export function* fetchReportIndividualShipment(action) {
  const {
    fieldArrayName,
    fieldArraysAndCustomersFieldsDropdownOptions,
    rowIndex,
    customerId,
    operatingContractBrewerCustomerId,
    rowValues,
    dispatchFetchStatuses,
    itemSkuIds,
  } = action.payload
  dispatchFetchStatuses(createAction(FETCH_STATUSES_FORMS_REQUEST, { target: [fieldArrayName, rowIndex] }))
  const fetchConfig = yield call(
    createFetchConfig,
    {
      customerId,
      operatingContractBrewerCustomerId,
      fieldArrayName,
      fieldArraysAndCustomersFieldsDropdownOptions,
      rowValues,
      itemSkuIds,
    },
  )

  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    yield call(
      doFailure,
      customerId,
      fieldArrayName,
      rowIndex,
      dispatchFetchStatuses,
      error,
    )
    return
  }
  yield call(
    doSuccess,
    customerId,
    operatingContractBrewerCustomerId,
    fieldArrayName,
    rowIndex,
    dispatchFetchStatuses,
    response.data,
  )
}


function* doSuccess(
  customerId,
  operatingContractBrewerCustomerId,
  fieldArrayName,
  rowIndex,
  dispatchFetchStatuses,
  objToSave,
) {
  yield call(
    saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm,
    {
      customerId,
      operatingContractBrewerCustomerId,
      fieldArrayName,
      shipmentObj: objToSave,
    },
  )
  yield call(
    saveShipmentObjectToPotentialDuplicatesSliceIfMeetsCriteria,
    {
      customerId,
      operatingContractBrewerCustomerId,
      fieldArrayName,
      shipmentObj: objToSave,
    },
  )
  dispatchFetchStatuses(createAction(FETCH_STATUSES_FORMS_SUCCESS, { target: [fieldArrayName, rowIndex] }))
}


function* doFailure(
  customerId,
  fieldArrayName,
  rowIndex,
  dispatchFetchStatuses,
  error,
) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_FORMS_FAILURE,
        target: [fieldArrayName, rowIndex],
      }),
      customDispatch: dispatchFetchStatuses,
    },
  )
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REPORT_INDIVIDUAL_SHIPMENT, fetchReportIndividualShipment],
]


// helper functions

function* createFetchConfig({
  customerId,
  operatingContractBrewerCustomerId,
  fieldArrayName,
  fieldArraysAndCustomersFieldsDropdownOptions,
  rowValues,
  itemSkuIds,
}) {
  const apiRequestBody = yield call(
    createApiRequestBody,
    {
      customerId,
      fieldArrayName,
      fieldArraysAndCustomersFieldsDropdownOptions,
      rowValues,
      itemSkuIds,
    },
  )
  const fetchConfig = {
    path: API_URL_PATH_SHIPMENTS_HISTORY, // CODE_COMMENTS_87
    data: apiRequestBody,
    method: 'POST',
  }

  // CODE_COMMENTS_92
  if (operatingContractBrewerCustomerId) {
    const headers = createHeadersForContracteeBrewerApiCall(customerId)
    fetchConfig.headers = headers
  }

  return fetchConfig
}


function* createApiRequestBody({
  customerId,
  fieldArrayName,
  fieldArraysAndCustomersFieldsDropdownOptions,
  rowValues,
  itemSkuIds,
}) {
  const {
    movementType,
    originCustomerId,
    destinationCustomerId,
    reverseMovement,
    proNumber,
    dateShipped,
    lineItems,
    // eslint-disable-next-line no-underscore-dangle
    _contractMetadataObject, // CODE_COMMENTS_133
    shipmentType,
    loadType,
  } = yield call(
    extractFormValues,
    {
      customerId,
      fieldArrayName,
      fieldArraysAndCustomersFieldsDropdownOptions,
      rowValues,
      itemSkuIds,
    },
  )


  const userId = yield select(getCurrentUserProp, 'userId')
  const dateReported = formatDateForApiCall({ date: moment() })


  const requestBody = assembleApiRequestBodyObj({
    userId,
    movementType,
    originCustomerId,
    destinationCustomerId,
    proNumber,
    dateShipped,
    lineItems,
    reverseMovement,
    dateReported,
    _contractMetadataObject,
    shipmentType,
    loadType,
  })

  return requestBody
}


function assembleApiRequestBodyObj({
  userId,
  movementType,
  destinationCustomerId,
  originCustomerId,
  proNumber,
  dateShipped,
  lineItems,
  reverseMovement,
  dateReported,
  _contractMetadataObject,
  shipmentType = SHIPMENT_TYPES_FREIGHT,
  loadType,
}) {
  return {
    // CODE_COMMENTS_47
    originCustomerId,
    destinationCustomerId,
    createdByUserId: userId,
    movementType,
    proNumber,
    dateShipped,
    reverseMovement,
    lineItems,

    dateReported,

    _contractMetadataObject,

    // defaults
    id: null,
    entryMethod: 'API',
    shipmentType,
    loadType,
    status: SHIPMENT_STATUS_PENDING,
    zeroDistInventory: false,
    goodPalletsOrigin: 0,
    paperworkVerified: false,
    chargeExpediteFee: false,
    hotShipment: false,
    newKegsFlag: 0,
    legCount: 0,
    '@jsonType': 'ShipmentObject',
  }
}
