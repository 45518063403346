import React, { useState, useEffect, useReducer } from 'react'
import { Form, Grid, Button, Dropdown, TextArea } from 'semantic-ui-react'
import moment from 'moment'
import produce from 'immer'

import get_ from 'lodash/get'
import set_ from 'lodash/set'
import flatten_ from 'lodash/flatten'
import range_ from 'lodash/range'
import sortBy_ from 'lodash/sortBy'
import isArray_ from 'lodash/isArray'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import flattenFp_ from 'lodash/fp/flatten'


import { useTranslation } from 'react-i18next'
import NoShippingAddresses from './NoShippingAddresses'

import FormSectionAsTable from '../../../../common-components/rewrite/formAsTable/FormSectionAsTable'
import InputNoAutoComplete from '../../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import SemanticUiCardChoices from '../../../../common-components/rewrite/SemanticUiCardChoices'
import TabsOfSemanticUiCardChoices from '../../../../common-components/rewrite/SemanticUiCardChoices/TabsOfSemanticUiCardChoices'
import InformationalPopup from '../../../../common-components/InformationalPopup'

import FormSubmissionDimmerOverlay from '../../../../common-components/rewrite/FormSubmission/DimmerOverlay'

import {
  createAddressChoicesForSemanticUiCardChoices,
} from '../../../../common-components/rewrite/SemanticUiCardChoices/util'

import {
  FETCH_ORDER_COLLARS,
} from '../../../../redux/actions/actionTypes'
import createAction from '../../../../redux/actions/createAction'
import {
  getSortedShippingAddressesOfCustomer,
} from '../../../../redux/selectors/rewrite/addresses'
import {
  getAllCollarPlateObjectsOfCustomer,
} from '../../../../redux/selectors/rewrite/collarPlates'

import {
  FIELD_NAME_SHIPPING_ADDRESS,
  FIELD_NAME_CUSTOMER_PO_NUM,
  FIELD_NAME_ORDER_COMMENTS,
  FIELD_NAME_SHIPPING_TYPE,

  FIELD_LABEL_SHIPPING_ADDRESS,
  FIELD_LABEL_CUSTOMER_PO_NUM,
  FIELD_LABEL_ORDER_COMMENTS,
  FIELD_LABEL_SHIPPING_TYPE,

  SHIPPING_TYPE_OPTIONS,
  FIELD_VALUE_SHIPPING_TYPE_FEDEX_GROUND,

  FIELD_NAME_KEG_COLLAR_CHOICE,
  FIELD_NAME_KEG_COLLAR_AMOUNT,
  FIELD_LABEL_KEG_COLLAR_CHOICE,
  FIELD_LABEL_KEG_COLLAR_AMOUNT,

  KEG_COLLAR_AMOUNTS_IN_INCREMENTS_OF,
  KEG_COLLAR_AMOUNTS_MAX_OF_SINGLE_ROW,

  LEFT_LABELS_COLUMN_WIDTH,
  FIELD_ARRAY_NAME_KEG_COLLARS,
} from '../../../../constants/formAndApiUrlConfig/orderKegCollars'

import {
  FORM_FIELD_PO_NUMBER_EXPLANATION,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'


import {
  getWhichCustomersCanKegCollarsBeShippedTo,
  getIsFieldRowFullyFilledOut,
  getIsFieldRowPartiallyFilledOut,
} from '../../util'

import {
  maxLength,
} from '../../../../utils/formNormalization'

import {
  isTruthyAndNonEmpty,
} from '../../../../utils'


const CHANGE_FORM_VALUE = 'CHANGE_FORM_VALUE'
const ADD_COLLARS_ROW = 'ADD_COLLARS_ROW'
const REMOVE_COLLARS_ROW = 'REMOVE_COLLARS_ROW'
const RESET_FORM_VALUES_TO_INITIAL_VALUES = 'RESET_FORM_VALUES_TO_INITIAL_VALUES'

/* eslint-disable no-param-reassign, no-useless-return, prefer-const, consistent-return */
const changeFormValue = produce((formValues, action) => {
  switch (action.type) {
    case CHANGE_FORM_VALUE: {
      let [fieldName, value] = action.payload
      const pathToField = isArray_(fieldName) ? fieldName : [fieldName]
      const previousValue = get_(formValues, pathToField)
      value = normalizeInputValue({
        previousValue,
        value,
        fieldName,
      })
      set_(formValues, pathToField, value)
      return
    }
    case ADD_COLLARS_ROW: {
      formValues[FIELD_ARRAY_NAME_KEG_COLLARS].push({})
      return
    }
    case REMOVE_COLLARS_ROW: {
      let [rowIndex] = action.payload
      formValues[FIELD_ARRAY_NAME_KEG_COLLARS].splice(rowIndex, 1)
      return
    }
    case RESET_FORM_VALUES_TO_INITIAL_VALUES: {
      const {
        shippingAddresses,
      } = action.payload
      return getInitialValues({
        shippingAddresses,
      })
    }
    default: { return }
  }
})
/* eslint-enable no-param-reassign, no-useless-return, prefer-const, consistent-return */

const collarAmountsDropdownOptions = range_(
  KEG_COLLAR_AMOUNTS_IN_INCREMENTS_OF, // start
  KEG_COLLAR_AMOUNTS_MAX_OF_SINGLE_ROW+1, // end (exclusive, hence the +1)
  KEG_COLLAR_AMOUNTS_IN_INCREMENTS_OF, // step
)


export default props => {
  const {
    customerId,
    operatingContractBrewerCustomerId,

    entireCollarPlatesSlice,
    entireCustomerCollarPlateLinksSlice,
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,

    dispatch,
  } = props
  const { t: translate } = useTranslation('common')

  const customerIdsKegCollarsCanBeShippedTo = getWhichCustomersCanKegCollarsBeShippedTo({
    customerId,
    operatingContractBrewerCustomerId,
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
  })

  const shippingAddresses = getShippingAddresses({
    entireCustomersSlice,
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    customerIdsKegCollarsCanBeShippedTo,
  })

  const hasAnyShippingAddress = getHasAnyShippingAddresses({ shippingAddresses })
  if (!hasAnyShippingAddress) {
    return (
      <NoShippingAddresses
        shippingAddresses={shippingAddresses}
        {...props}
      />
    )
  }

  const collarPlatesDropdownOptions = sortBy_(
    getAllCollarPlateObjectsOfCustomer({
      customerId,
      entireCollarPlatesSlice,
      entireCustomerCollarPlateLinksSlice,
      currentlyActiveCollarPlatesOnly: true, // CODE_COMMENTS_112
    }),
    'flavor',
  )

  const [formValues, dispatchFormValues] = useReducer(
    changeFormValue,
    getInitialValues({
      shippingAddresses,
    }),
  )

  const [isFormSubmittable, setIsFormSubmittable] = useState(false)
  useEffect(
    () => {
      setIsFormSubmittable(getIsFormSubmittable({
        formValues,
      }))
    },
    [
      formValues,
    ],
  )

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(false)
  const [didSubmitFail, setDidSubmitFail] = useState(false)

  const handleSubmit = () => {
    dispatch(createAction(
      FETCH_ORDER_COLLARS,
      {
        customerId,
        operatingContractBrewerCustomerId,
        formValues,
        setIsSubmitting,
        setDidSubmitSucceed,
        setDidSubmitFail,
      },
    ))
  }

  const onResetForm = () => {
    dispatchFormValues(createAction(
      RESET_FORM_VALUES_TO_INITIAL_VALUES,
      {
        shippingAddresses,
      },
    ))
    setIsSubmitting(false)
    setDidSubmitSucceed(false)
    setDidSubmitFail(false)
  }


  return (
    <FormSubmissionDimmerOverlay
      customerId={customerId}
      isSubmitting={isSubmitting}
      didSubmitSucceed={didSubmitSucceed}
      didSubmitFail={didSubmitFail}
      onResetForm={onResetForm}
      successMessage={<p>{translate('Your collars have been ordered.')}</p>}
    >
      <Form
        className="form-top-spacing"
        onSubmit={handleSubmit}
      >
        <Grid columns={2}> {/* CODE_COMMENTS_22 */}
          <Grid.Row>
            <Grid.Column style={{ flex: `0 0 ${LEFT_LABELS_COLUMN_WIDTH}` }} verticalAlign="middle">
              {/* The only reason we wrap our <FormSectionAsTable> component in a
              <Form.Field> is for alignment purposes: for aesthetic purposes, we want
              the form-section-as-table to have an inline label just like all the other
              fields do (all fields lined up similarly). The <Form.Field> here has no
              corresponding <Field>. */}
              <Form.Field required>
                <label htmlFor="field array">{translate('Keg Collars')}:</label>
              </Form.Field>
            </Grid.Column>

            <Grid.Column style={{ flex: '1' }}>
              <div>
                <FormSectionAsTable
                  fieldDefinitions={[
                    {
                      topLabel: translate(FIELD_LABEL_KEG_COLLAR_CHOICE),
                      fieldName: FIELD_NAME_KEG_COLLAR_CHOICE,
                      formFieldProps: {
                        required: true,
                        style: { width: '350px' },
                      },
                      fieldComponent: Dropdown,
                      fieldProps: ({ rowIndex }) => ({
                        options: collarPlatesDropdownOptions.map(collarPlate => (
                          { key: collarPlate.id, value: collarPlate.id, text: `${collarPlate.flavor} - ${collarPlate.partNumber}` }
                        )),
                        placeholder: translate('Select Collar Plate'),
                        value: get_(
                          formValues,
                          [FIELD_ARRAY_NAME_KEG_COLLARS, rowIndex, FIELD_NAME_KEG_COLLAR_CHOICE],
                          '', // CODE_COMMENTS_245
                        ),
                        onChange: (event, { value }) => {
                          dispatchFormValues(createAction(
                            CHANGE_FORM_VALUE,
                            [[FIELD_ARRAY_NAME_KEG_COLLARS, rowIndex, FIELD_NAME_KEG_COLLAR_CHOICE], value],
                          ))
                        },
                        // visual props for the Semantic UI React <Dropdown> component
                        search: true,
                        selection: true,
                      }),
                    },

                    {
                      topLabel: translate(FIELD_LABEL_KEG_COLLAR_AMOUNT),
                      fieldName: FIELD_NAME_KEG_COLLAR_AMOUNT,
                      formFieldProps: {
                        required: true,
                      },
                      fieldComponent: Dropdown,
                      fieldProps: ({ rowIndex }) => ({
                        options: collarAmountsDropdownOptions.map(amount => (
                          { key: amount, value: amount, text: amount }
                        )),
                        placeholder: translate('Amount'),
                        value: get_(
                          formValues,
                          [FIELD_ARRAY_NAME_KEG_COLLARS, rowIndex, FIELD_NAME_KEG_COLLAR_AMOUNT],
                          '', // CODE_COMMENTS_245
                        ),
                        onChange: (event, { value }) => {
                          dispatchFormValues(createAction(
                            CHANGE_FORM_VALUE,
                            [[FIELD_ARRAY_NAME_KEG_COLLARS, rowIndex, FIELD_NAME_KEG_COLLAR_AMOUNT], value],
                          ))
                        },
                        // visual props for the Semantic UI React <Dropdown> component
                        search: true,
                        selection: true,
                      }),
                    },
                  ]}
                  onAddRow={() => {
                    dispatchFormValues(createAction(ADD_COLLARS_ROW))
                  }}
                  onDeleteRow={({ rowIndex }) => {
                    dispatchFormValues(createAction(REMOVE_COLLARS_ROW, [rowIndex]))
                  }}
                  fieldArrayName={FIELD_ARRAY_NAME_KEG_COLLARS}
                  formValues={formValues}
                  hasEntireFormBeenSubmitted={isSubmitting || didSubmitSucceed || didSubmitFail}
                  areRowsIndividuallySubmitted={false}
                  abilityToAddAndDeleteRows
                  // We always want the value of
                  // formValues[FIELD_ARRAY_NAME_KEG_COLLARS] to be an array,
                  // and if we allow users to delete the first row, I don't know
                  // if there are any facilities to set the value back to an
                  // array when the user then clicks 'Add row'.
                  firstRowCannotBeDeleted
                />
              </div>
            </Grid.Column>
          </Grid.Row>


          <Grid.Row key={FIELD_NAME_SHIPPING_ADDRESS}>
            <Grid.Column style={{ flex: `0 0 ${LEFT_LABELS_COLUMN_WIDTH}` }} verticalAlign="middle">
              <Form.Field required>
                <label htmlFor={FIELD_NAME_SHIPPING_ADDRESS}>{translate(FIELD_LABEL_SHIPPING_ADDRESS)}:</label>
              </Form.Field>
            </Grid.Column>
            <Grid.Column style={{ flex: '1' }}>
              {
                shippingAddresses.length > 1
                  ? (
                    <TabsOfSemanticUiCardChoices
                      name={FIELD_NAME_SHIPPING_ADDRESS}
                      inline
                      tabDefinitions={shippingAddresses.map(({ name, addresses }) => ({
                        tabTitle: `Ship to ${name}`,
                        propsForSemanticUiCardChoices: {
                          choices: createAddressChoicesForSemanticUiCardChoices(addresses),
                          value: formValues[FIELD_NAME_SHIPPING_ADDRESS],
                          onChange: (e, { value }) => {
                            dispatchFormValues(createAction(
                              CHANGE_FORM_VALUE,
                              [FIELD_NAME_SHIPPING_ADDRESS, value],
                            ))
                          },
                        },
                      }))}
                      parentTabComponentProps={{
                        menu: { fluid: true, vertical: true, tabular: true },
                      }}
                    />
                  )
                  : (
                    <SemanticUiCardChoices
                      choices={createAddressChoicesForSemanticUiCardChoices(shippingAddresses[0].addresses)}
                      value={formValues[FIELD_NAME_SHIPPING_ADDRESS]}
                      onChange={(e, { value }) => {
                        dispatchFormValues(createAction(
                          CHANGE_FORM_VALUE,
                          [FIELD_NAME_SHIPPING_ADDRESS, value],
                        ))
                      }}
                    />
                  )
              }
            </Grid.Column>
          </Grid.Row>


          <Grid.Row key={FIELD_NAME_CUSTOMER_PO_NUM}>
            <Grid.Column style={{ flex: `0 0 ${LEFT_LABELS_COLUMN_WIDTH}` }} verticalAlign="middle">
              <Form.Field>
                <label htmlFor={FIELD_NAME_CUSTOMER_PO_NUM}>
                  {translate(FIELD_LABEL_CUSTOMER_PO_NUM)}:
                  <InformationalPopup content={FORM_FIELD_PO_NUMBER_EXPLANATION} />
                </label>
              </Form.Field>
            </Grid.Column>

            <Grid.Column style={{ flex: '1' }}>
              <InputNoAutoComplete
                value={formValues[FIELD_NAME_CUSTOMER_PO_NUM] || ''} // CODE_COMMENTS_245
                onChange={(e, { value }) => {
                  dispatchFormValues(createAction(
                    CHANGE_FORM_VALUE,
                    [FIELD_NAME_CUSTOMER_PO_NUM, value],
                  ))
                }}
              />
            </Grid.Column>
          </Grid.Row>


          <Grid.Row key={FIELD_NAME_ORDER_COMMENTS}>
            <Grid.Column style={{ flex: `0 0 ${LEFT_LABELS_COLUMN_WIDTH}` }} verticalAlign="middle">
              <Form.Field>
                <label htmlFor={FIELD_NAME_ORDER_COMMENTS}>{translate(FIELD_LABEL_ORDER_COMMENTS)}:</label>
              </Form.Field>
            </Grid.Column>

            <Grid.Column style={{ flex: '1' }}>
              <TextArea
                value={formValues[FIELD_NAME_ORDER_COMMENTS] || ''} // CODE_COMMENTS_245
                onChange={(e, { value }) => {
                  dispatchFormValues(createAction(
                    CHANGE_FORM_VALUE,
                    [FIELD_NAME_ORDER_COMMENTS, value],
                  ))
                }}
                className="ui input" // textArea won't work as inline component w/o this
                style={{ width: '300px' }}
              />
            </Grid.Column>
          </Grid.Row>


          <Grid.Row key={FIELD_NAME_SHIPPING_TYPE}>
            <Grid.Column style={{ flex: `0 0 ${LEFT_LABELS_COLUMN_WIDTH}` }} verticalAlign="middle">
              <Form.Field
                // These styles are necessary in order to center the label.
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <label htmlFor={FIELD_NAME_SHIPPING_TYPE}>{translate(FIELD_LABEL_SHIPPING_TYPE)}:</label>
              </Form.Field>
            </Grid.Column>

            <Grid.Column style={{ flex: '1' }}>
              <div className="ui radio" style={{ display: 'inline-block' }}>
                {
                  SHIPPING_TYPE_OPTIONS.map(option => (
                    <div key={option.value}>
                      <label htmlFor={option.value}>
                        <InputNoAutoComplete
                          type="radio"
                          value={option.value}
                          checked={option.value === formValues[FIELD_NAME_SHIPPING_TYPE]}
                          onChange={() => {
                            dispatchFormValues(createAction(
                              CHANGE_FORM_VALUE,
                              [FIELD_NAME_SHIPPING_TYPE, option.value],
                            ))
                          }}
                          className="ui radio"
                        />
                        &nbsp;&nbsp;
                        {`${option.name}: Receive order `}
                        <span style={{ fontWeight: 'bold' }}>{`${option.arrivalDateString(moment(), 'ddd. MMMM D')}`}</span>
                      </label>
                      <br />
                    </div>
                  ))
                }
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>


        <Button
          type="submit"
          color={isFormSubmittable ? 'green' : 'grey'}
          // CODE_COMMENTS_38
          disabled={!isFormSubmittable || isSubmitting || didSubmitSucceed || didSubmitFail}
          // not too close to the bottom form field
          style={{ marginTop: '25px' }}
        >
          {translate('Submit')}
        </Button>
      </Form>
    </FormSubmissionDimmerOverlay>
  )
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

function getInitialValues({
  shippingAddresses,
}) {
  return {
    [FIELD_ARRAY_NAME_KEG_COLLARS]: [{}], // single blank row in keg collars choices
    [FIELD_NAME_SHIPPING_ADDRESS]: shippingAddresses[0].addresses[0].id,
    [FIELD_NAME_SHIPPING_TYPE]: FIELD_VALUE_SHIPPING_TYPE_FEDEX_GROUND,
  }
}


function normalizeInputValue({
  previousValue,
  value,
  fieldName,
}) {
  let normalizer
  if (fieldName === FIELD_NAME_CUSTOMER_PO_NUM) {
    normalizer = maxLength(50)
  } else if (fieldName === FIELD_NAME_ORDER_COMMENTS) {
    normalizer = maxLength(256)
  } else {
    normalizer = val => val
  }
  return normalizer(
    value,
    previousValue,
  )
}


// Returns an object containing the date-fencing props that should be set on the
// ReactDatepicker field.
export function getDateFieldFencingProps() {
  return ({
    minDate: moment().add(1, 'days'),
    maxDate: moment().add(90, 'days'),
  })
}


function getIsFormSubmittable({
  formValues,
}) {
  if (!formValues) { return false } // form hasn't yet been rendered


  // Ensure a delivery address has been chosen (the form is currently crafted
  // so that it's impossible for an address not to be selected, but that could
  // change if, for instance, we decided in the future not to set a default
  // value for the chosen address. When we unset that default value, we don't
  // want to have to remember to include this check.)
  if (!formValues[FIELD_NAME_SHIPPING_ADDRESS]) { return false }


  const collarsValues = formValues[FIELD_ARRAY_NAME_KEG_COLLARS]
  if (!isTruthyAndNonEmpty(collarsValues)) { return false }
  // ensure at least one row has been fully filled out
  if (!collarsValues.some(rowValues => getIsFieldRowFullyFilledOut(rowValues))) {
    return false
  }
  // ensure no rows have been partially filled out (it's OK if the user has
  // created a row but hasn't entered any information into it)
  if (collarsValues.some(rowValues => getIsFieldRowPartiallyFilledOut(rowValues))) {
    return false
  }

  return true
}


// Remember that some users can ship collars to multiple customers
// (CODE_COMMENTS_203). The addresses prop will be an empty array only if a
// customer doesn't have a default shipping address.

// [
//   {
//     customerId: 'b1234',
//     name: 'Cool Brewery, Inc.',
//     addresses: [addressObj1, addressObj2, ...],
//   },
//   {
//     customerId: 'cb5678',
//     name: 'Cool Contract Brewery, Inc.',
//     addresses: [addressObj1, addressObj2, ...],
//   },
//   ...
// ]

// Also, duplicate addresses are not allowed. If two customers share the same
// address (which is actually pretty common), the customer higher up in the
// customerIdsKegCollarsCanBeShippedTo list gets it
function getShippingAddresses({
  entireCustomersSlice,
  entireAddressesSlice,
  entireCustomerAddressLinksSlice,
  customerIdsKegCollarsCanBeShippedTo,
}) {
  return customerIdsKegCollarsCanBeShippedTo.reduce(
    (acc, custId) => {
      let addresses = getSortedShippingAddressesOfCustomer({
        entireAddressesSlice,
        entireCustomerAddressLinksSlice,
        customerId: custId,
      })
      const addressIdsThatAreAlreadyIncluded = flow_(
        mapFp_(o => o.addresses),
        flattenFp_,
        mapFp_(address => address.id),
      )(acc)
      addresses = addresses.filter(address => !addressIdsThatAreAlreadyIncluded.includes(address.id))
      if (addresses.length > 0) {
        return [
          ...acc,
          {
            customerId: custId,
            name: entireCustomersSlice[custId].name,
            addresses,
          },
        ]
      }
      // If, due to the de-duping of addresses, a customer has no shipping
      // addresses, don't include that customer in the returned list.
      return acc
    },
    [],
  )
}


function getHasAnyShippingAddresses({ shippingAddresses }) {
  const arraysOfAddresses = shippingAddresses.map(o => o.addresses)
  const allChooseableAddresses = flatten_(arraysOfAddresses)
  return allChooseableAddresses.length > 0
}
