import { call, takeEvery } from 'redux-saga/effects'
// import { delay } from 'redux-saga'

import {
  FETCH_PAY_CUSTOMER_BALANCE,
} from '../../../actions/actionTypes'

import fetchSignedData from './_fetchSignedData'

import {
  INTERNAL_ERROR_MESSAGES_FAILED_FORM_SUBMISSION,
} from '../../../../constants'


import { FormSubmissionError } from '../../../../customErrors'


function* fetchPayBalance(action) {
  const {
    customerId,
    amount,
    saveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
    useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
    updateSavedBankAccountIfUseNewBankAccount,

    resolve,
    reject,
    remittanceDetail,
  } = action.payload

  const signedData = yield call(fetchSignedData, {
    payload: {
      customerId,
      amount,
      saveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
      useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
      updateSavedBankAccountIfUseNewBankAccount,
      remittanceDetail,
    },
  })
  // yield call(delay, 30*1000)

  if (signedData === INTERNAL_ERROR_MESSAGES_FAILED_FORM_SUBMISSION) {
    yield call(reject, new FormSubmissionError({ _error: INTERNAL_ERROR_MESSAGES_FAILED_FORM_SUBMISSION }))
  } else {
    yield call(resolve, signedData)
  }
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_PAY_CUSTOMER_BALANCE, fetchPayBalance],
]
