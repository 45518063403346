import React from 'react'

import { withRouter } from 'react-router-dom'

import {
  getEnvironmentBasedOnRootApiUrl,
} from '../../../../utils'

function getActualEnvironemntEqualsPassedInEnvironment({
  environments,
  onlyRenderIfWeAreNotInTheseEnvsRatherThanIfWeAre,
}) {
  return onlyRenderIfWeAreNotInTheseEnvsRatherThanIfWeAre
    ? !environments.includes(getEnvironmentBasedOnRootApiUrl())
    : environments.includes(getEnvironmentBasedOnRootApiUrl())
}


// Usage:
//
// const MyCoolComponent = withOnlyRenderInEnvironments({
//   environments: [ENVIRONMENT_PROD],
//   onlyRenderIfWeAreNotInTheseEnvsRatherThanIfWeAre: true,
//   renderInAllEnvsIfThisOverrideFunctionReturnsTrue: () => { return someCondition },
// })(
//   () => <div>My cool component</div>
// )
//
// This will render MyCoolComponent in all environments except Prod.
//
// You can also pass in the 'environments', etc. props within the component
// itself, like this:
//
// const MyCoolComponent = withOnlyRenderInEnvironments()(
//   ({
//     environments: [ENVIRONMENT_PROD],
//     onlyRenderIfWeAreNotInTheseEnvsRatherThanIfWeAre: true,
//     renderInAllEnvsIfThisOverrideFunctionReturnsTrue: () => { return someCondition },
//   }) => <div>My cool component</div>
// )
//
// All props will be passed to the
// renderInAllEnvsIfThisOverrideFunctionReturnsTrue function. So will
// React Router's 'match', 'history', and 'location' props.
//
// If these props are passed into both places, the props passed into the
// component win out.
export default staticProps => Component => withRouter(props => {
  const allPropsIncludingHocProps = {
    ...(staticProps || {}),
    ...(props || {}),
  }
  const {
    environments,
    onlyRenderIfWeAreNotInTheseEnvsRatherThanIfWeAre,
    renderInAllEnvsIfThisOverrideFunctionReturnsTrue,
  } = allPropsIncludingHocProps

  const shouldWeRenderBasedOnEnvsEvaluation = getActualEnvironemntEqualsPassedInEnvironment({
    environments,
    onlyRenderIfWeAreNotInTheseEnvsRatherThanIfWeAre,
  })
  const isOverridden = (
    renderInAllEnvsIfThisOverrideFunctionReturnsTrue &&
    renderInAllEnvsIfThisOverrideFunctionReturnsTrue(allPropsIncludingHocProps)
  )

  if (shouldWeRenderBasedOnEnvsEvaluation || isOverridden) {
    return <Component {...props} />
  }
  return null
})
