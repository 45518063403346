import React from 'react'
import { Form, Button, Icon, Header } from 'semantic-ui-react'

import { Trans, withTranslation } from 'react-i18next'
import {
  getIsStringAValidEmailAddress,
} from '../../../../../../utils'

import {
  LOGIN_EMAIL_MAX_LENGTH,
} from '../../../../../../config'

class ForgotPasswordEnterEmailForm extends React.Component {
  state = {
    emailInput: '',
    emailInputTouched: false, // True when the user first enters a value
    emailErrorText: null, // only show error message after value has been touched
  }

  onEmailInputChange = e => {
    let emailInputToSave = e.target.value
    let emailErrorText

    // remove all whitespace
    emailInputToSave = emailInputToSave && emailInputToSave.replace(/ /g, '')

    if (!getIsStringAValidEmailAddress(emailInputToSave)) {
      emailErrorText = 'Invalid email address'
    } else {
      emailErrorText = null
    }

    if (['', undefined].includes(emailInputToSave)) {
      emailErrorText = 'Email address required'
    }

    // max length rule
    if (emailInputToSave.length > LOGIN_EMAIL_MAX_LENGTH) {
      emailInputToSave = this.state.emailInput
    }

    this.setState({
      emailInput: emailInputToSave,
      emailInputTouched: true,
      emailErrorText,
    })
  }

  onSubmit = e => {
    e.preventDefault()
    const {
      handleSubmit,
      setEmail: parentSetEmail,
    } = this.props
    const {
      emailInput,
    } = this.state
    parentSetEmail(emailInput) // CODE_COMMENTS_251
    handleSubmit(emailInput)
  }

  render() {
    const { t: translate } = this.props
    const {
      emailInput,
      emailErrorText,
      emailInputTouched,
    } = this.state
    return (
      <div>
        <Header as="h4" color="grey">
          <Trans ns='common' i18nKey='Step 1: Enter Your Email Address'>
            Step 1: Enter Your Email Address
          </Trans>
        </Header>
        <Form onSubmit={this.onSubmit}>
          <div className="field-with-error-message">
            <Form.Input label={translate('Email')} type="text" name="email" value={emailInput} onChange={this.onEmailInputChange} />
            {emailErrorText ? (
              <small>
                <Trans ns='common' i18nKey={emailErrorText}>
                  {emailErrorText}
                </Trans>
              </small>
            ) : null}
          </div>
          {this.props.isFetching
            ? <Button type="submit" fluid animated loading disabled><Trans ns='common' i18nKey='Loading'>Loading</Trans></Button>
            : (
              <Button
                type="submit"
                fluid
                animated
                disabled={Boolean(emailErrorText) || !emailInputTouched}
              >
                <Button.Content visible><Trans ns='common' i18nKey='Submit'>Submit</Trans></Button.Content>
                <Button.Content hidden>
                  <Icon name="right arrow" />
                </Button.Content>
              </Button>
            )
          }
        </Form>
      </div>
    )
  }
}

export default withTranslation()(ForgotPasswordEnterEmailForm)
