/**
 * CODE_COMMENTS_16
 */
import React from 'react'

import LoadingPage from '../../../../common-components/LoadingPage'
import AppFailedToLoad from '../../../AppFailedToLoad'

const WaitForCustomerInfo = ({
  hasRootCustomerPrelimInfoFetchBeenAttempted,
  isRootCustomerPrelimInfoFetching,
  isRootCustomerPrelimInfoFetched,
  didFetchRootCustomerPrelimInfoFail,
  fetchPrelimInfoOfAllCustomers,
  children,
// eslint-disable-next-line consistent-return
}) => {
  if (!hasRootCustomerPrelimInfoFetchBeenAttempted) {
    // we should only get here once, when the page first loads
    fetchPrelimInfoOfAllCustomers()
    return <LoadingPage />
  }
  if (isRootCustomerPrelimInfoFetching) {
    return <LoadingPage />
  }
  // Only render the meat of the app after the prelim info of the current user
  // has been fetched
  if (isRootCustomerPrelimInfoFetched) {
    return (
      <div>
        {children}
      </div>
    )
  }
  if (didFetchRootCustomerPrelimInfoFail) {
    return <AppFailedToLoad fetchPrelimInfoOfAllCustomers={fetchPrelimInfoOfAllCustomers} />
  }
}

export default WaitForCustomerInfo
