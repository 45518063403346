import React from 'react'
import withEither from '../../../../common-components/HOC/general/withEither'


import UiContainer from '../../../../common-components/UiContainer'

import FeatureDisabledMessage from '../../../../common-components/FeatureDisabledMessage'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER,
} from '../../../../constants/permissions'
import {
  createYouDontHaveThesePermissionsMessage,
} from '../../../../utils/permissions'


export default withEither(
  ({ shouldOperateAsCustomerUserFormBeDisabled }) => shouldOperateAsCustomerUserFormBeDisabled,
  UiContainer()(() => {
    const permissionsString = createYouDontHaveThesePermissionsMessage({
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER,
    })

    const message = `You can't use this feature because ${permissionsString}. Edit your permissions in the TAP3 Desktop app, then log out of the web app and log back in.`

    return (
      <FeatureDisabledMessage
        headerText="Permissions Needed"
        messageText={message}
        messageSeverity="warning"
        showCustomerRepContactCard={false}
      />
    )
  }),
)
