import React from 'react'


import HistoryTableCreator from '../../../HOC/HistoryTableCreator'

import CollarOrderDetails from '../subFeatures/CollarOrderDetails'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  createDisplayedOrderDate,
  createDisplayedDeliveryDate,
  getDisplayedHumanReadableStatusOfCollarOrder,
  createDisplayedNumberOfDifferentPlates,
  createDisplayedTotalQtyOrdered,
  getShouldCollarOrderHistoryItemBeDisplayedInTable,
  getAllCollarOrderObjectsToBeIncludedInHistoryTable,
} from '../../../util/collarOrderHistory'

import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'

import {
  sortByDateString,
} from '../../../../../../utils'


const createTableContentDefinitions = () => ({
  content: [
    {
      heading: 'Order #',
      cellContent: (row, { customerId }) => (
        <CollarOrderDetails
          info={row}
          customerId={customerId}
        />
      ),
      className: 'testKegCollarOrderNumber', // CODE_COMMENTS_57
    },

    {
      heading: 'Order Date',
      cellContent: createDisplayedOrderDate,
      customSortInfo: {
        sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateOrdered' }),
        sortApiObjectValuesRatherThanCellContents: true,
      },
      className: 'testKegCollarOrderDateOrdered', // CODE_COMMENTS_57
    },
    {
      heading: 'Delivery Date',
      cellContent: createDisplayedDeliveryDate,
      className: 'testKegCollarOrderDateNeeded', // CODE_COMMENTS_57
      customSortInfo: {
        sortFunction: (rowsArray, ascOrDesc='desc') => {
          const rowsArrayWithDeliveryDateSansAppxPrepend = rowsArray.map(o => ({
            ...o,
            'Delivery Date No Appx': (
              o['Order Date'].startsWith('appx ')
                ? o['Order Date'].substring(5)
                : o['Order Date']
            ),
          }))
          return sortByDateString({
            arr: rowsArrayWithDeliveryDateSansAppxPrepend,
            nestedPath: ['Delivery Date No Appx'],
            dateFormat: DEFAULT_DISPLAYED_DATE_FORMAT,
            ascending: ascOrDesc === 'asc',
          })
        },
        sortApiObjectValuesRatherThanCellContents: false,
      },
    },
    {
      heading: 'Status',
      cellContent: getDisplayedHumanReadableStatusOfCollarOrder,
      className: 'testKegCollarOrderStatus', // CODE_COMMENTS_57
    },
    {
      heading: 'Total Qty',
      cellContent: createDisplayedTotalQtyOrdered,
      className: 'testKegCollarOrderTotalQty',
      includeInTotalsRow: true, // CODE_COMMENTS_57
    },
    {
      heading: '# Plates',
      cellContent: createDisplayedNumberOfDifferentPlates,
      className: 'testKegCollarOrderNumberPlates',
      includeInTotalsRow: true, // CODE_COMMENTS_57
    },
  ],
  defaultSortColumn: 'Order Date',
  filterFunc: getShouldCollarOrderHistoryItemBeDisplayedInTable,
  rowKey: row => row.id,
})

export default ({
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  allCollarOrderObjects,
  isFormSubmitting,
  hasFormSubmitFailed,
  noResultsMessage,
  areAnyItemsEditable,
  getIsindividualItemEditable,
}) => {
  const definitions = createTableContentDefinitions()

  const allCollarOrderObjectsToBeIncludedInHistoryTable = getAllCollarOrderObjectsToBeIncludedInHistoryTable(
    allCollarOrderObjects,
  )

  return (
    <HistoryTableCreator
      tableInfoObj={allCollarOrderObjectsToBeIncludedInHistoryTable}
      definitions={definitions}
      isFormSubmitting={isFormSubmitting}
      hasFormSubmitFailed={hasFormSubmitFailed}
      additionalPropsToPassToTableCells={{ customerId, operatingContractBrewerCustomerId }}
      noResultsMessage={noResultsMessage}

      // for Edit and Delete buttons column
      areAnyItemsEditable={areAnyItemsEditable}
      itemType="keg collar order"
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      getIsindividualItemEditable={getIsindividualItemEditable}
    />
  )
}
