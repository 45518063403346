import { call, select, takeEvery } from 'redux-saga/effects'
import saveAs from 'file-saver'
// import axios from 'axios'

import maxBy_ from 'lodash/maxBy'


import {
  getProp as getCustomerProp,
} from '../../selectors/customers'

import { privateFetch } from '../fetch'
import {
  FETCH_DOWNLOAD_INVOICE,
} from '../../actions/actionTypes'

import {
  generalDoFailure,
} from '../util/fetchFailure'

import {
  logErrorMessage,
  LOG_SEVERITY_ERROR,
} from '../../../utils/thirdPartyLogging'

import {
  getDownloadInvoiceApiUrl,
  convertApiDateToMoment,
} from '../../../utils'


function* fetchDownloadInvoice(action) {
  const {
    customerId,
    invoiceMetadata,
    resolve,
    reject,
  } = action.payload

  const fetchConfig = yield call(createFetchConfig, invoiceMetadata)
  let response
  try {
    // // The following `config` variable and `axios` call can be used for testing
    // // as it does the "right thing," namely, the web app will download this
    // // random public PDF file. This allows you to ensure that the web app code
    // // is doing the right thing if you encounter problems downloading PDFs and
    // // you suspect the source of the problem is the TAP3 backend, such as the
    // // PDF file from the backend is corrupted/malformed/etc. If you use this,
    // // uncomment out the convertApiResponseToPdfFile() line in doSuccess()
    // // and pass response.body directly into saveAs(), because this URL sends
    // // the PDF as a Blob already.
    // const config = {
    //   // The following PDF URL is special because the response includes the
    //   // "access-control-allow-origin" header, which is necessary in order to
    //   // download it from a different origin (namely, 'localhost' when we're
    //   // developing and troubleshooting the web app locally). We have no special
    //   // way to track down which downloadable PDFs from the internet have the
    //   // "access-control-allow-origin" header--we just googled for one and tried
    //   // a bunch before stumbling onto this one.
    //   url: 'https://media.readthedocs.org/pdf/flask-cors/latest/flask-cors.pdf',
    //   responseType: 'blob', // important
    //   headers: {
    //     'Content-Type': 'application/pdf',
    //   },
    // }
    // response = yield call(
    //   axios,
    //   config,
    // )

    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    yield call(doFailure, { error, reject })
    return
  }
  yield call(doSuccess, { response, customerId, invoiceMetadata, resolve, reject })
}

function* doSuccess({ response, customerId, invoiceMetadata, resolve, reject }) {
  const fileName = yield call(createInvoiceFileName, customerId, invoiceMetadata)
  let file
  try {
    file = convertApiResponseToPdfFile(response.data)
  } catch (e) {
    logErrorMessage({
      message: 'convertApiResponseToPdfFile() Failed when Downloading Invoice',
      severity: LOG_SEVERITY_ERROR,
      additionalInfo: {
        details: 'The GET request to fetch the Invoice succeeded, but an error occurred when converting the response body to a PDF file. See Sentry for the actual javascript error that was thrown.',
        customerId,
      },
      additionalInfoThatDoesntAffectWhetherSameLogOccurrenceHasAlreadyBeenSent: {
        error: e,
      },
      httpResponse: response,
    })

    yield call(doFailure, { reject })
    return
  }
  try {
    saveAs(file, fileName)
    resolve()
  } catch (e) {
    logErrorMessage({
      message: 'saveAs() Failed when Downloading Invoice',
      severity: LOG_SEVERITY_ERROR,
      additionalInfo: {
        details: 'The GET request to fetch the Invoice succeeded, as did converting the response body to a PDF file, but an error occurred on saveAs(). See Sentry for the actual javascript error that was thrown.',
        customerId,
      },
      additionalInfoThatDoesntAffectWhetherSameLogOccurrenceHasAlreadyBeenSent: {
        error: e,
      },
      httpResponse: response,
    })

    yield call(doFailure, { reject })
    reject()
  }
}


function* doFailure({
  error,
  reject,
}) {
  // If it's not a real API error, don't pass in any error object
  if (error) {
    yield call(
      generalDoFailure,
      {
        error,
      },
    )
  }
  reject(error)
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_DOWNLOAD_INVOICE, fetchDownloadInvoice],
]


// helper functions

function createFetchConfig(invoiceMetadata) {
  const fetchConfig = {
    path: createDownloadInvoiceApiUrlFromInvoiceMetadata(invoiceMetadata),
    responseType: 'blob', // important
    headers: {
      'Content-Type': 'application/pdf', // important
    },
  }

  return fetchConfig
}

function createDownloadInvoiceApiUrlFromInvoiceMetadata(invoiceMetadata) {
  const s3Key = getDownloadS3KeyFromInvoiceMetadata(invoiceMetadata)
  const urlEncodedS3Key = createUrlEncodedS3Key(s3Key)
  return getDownloadInvoiceApiUrl(urlEncodedS3Key)
}


function getDownloadS3KeyFromInvoiceMetadata(invoiceMetadata) {
  // The `fileLinkDataObjectSet` array should only have one item in it, but if
  // it has multiple items, choose the one with the latest
  // `fileObject.uploadedDate` (a decision by Kevin in a slack conversation with
  // Tad on 11/1/2018)
  const targetFileLinkDataObject = maxBy_(
    invoiceMetadata.fileLinkDataObjectSet,
    'fileObject.uploadedDate',
  )
  return targetFileLinkDataObject.fileObject.s3key
}


/**
 * The s3 key looks like:
 *
 * 2018/invoices/b048eedb-b1ce-48f4-8335-c01d21e35c03-finalized_invoice_19191_2018-05-02.pdf
 *
 * We need to URLencode this, but only partially, only after the first forward
 * slash:
 *
 * 2018/invoices%2Fb048eedb-b1ce-48f4-8335-c01d21e35c03-finalized_invoice_19191_2018-05-02.pdf
 */
function createUrlEncodedS3Key(s3Key) {
  const [beforeFirstForwardSlash, afterFirstForwardSlash] = s3Key.split(/\/(.+)/)
  // CODE_COMMENTS_129
  return `${beforeFirstForwardSlash}/${encodeURIComponent(afterFirstForwardSlash)}`
}

// '[tapId]-[invoiceDate].pdf'
function* createInvoiceFileName(customerId, invoiceMetadata) {
  const tapId = (
    yield select(getCustomerProp, customerId, 'tapcustomerId')
    // in the unlikely event that the customer doesn't have a tap ID, simply
    // name the file e.g. "-11192018"
    || ''
  )

  const dateFormat = 'MMDDYYYY' // 'MMDDYYYY'
  let dateString
  const invoiceDate = invoiceMetadata.invoiceDate
  if (!invoiceDate) {
    dateString = 'unknownDate'
  } else {
    dateString = convertApiDateToMoment(invoiceDate).format(dateFormat)
  }

  const fileExtension = 'pdf'
  return `${tapId}-${dateString}.${fileExtension}`
}


// https://stackoverflow.com/a/45872086. The backend sends PDFs as a
// base64-encoded string (sometimes backends send PDFs as base64-encoded
// strings, sometimes as Blobs, there's no standard and no one approved "right"
// way to do it), but the file-saver library can only save Blobs as PDFs, so
// this function converts the base64 string to a Blob.
function convertApiResponseToPdfFile(apiResponseBody) {
  // decode base64 string, remove space for IE compatibility
  const binary = atob(apiResponseBody.replace(/\s/g, ''))
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < len; i+=1) {
    view[i] = binary.charCodeAt(i)
  }

  // create the blob object with content-type "application/pdf"
  const blob = new Blob([view], { type: 'application/pdf' })
  return blob
}
