/**
 * A higher-order component you can use to wrap other components that should be
 * disabled when the customer is on a credit hold or their account is inactive.
 * Instead of displaying the feature (e.g. a form), a red error message will be
 * displayed explaining that their account is inactive/on credit hold.
 */

import React from 'react'

import FeatureDisabledDueToInactiveAccountOrHold from './containers'


export default wrappedComponent => props => (
  <FeatureDisabledDueToInactiveAccountOrHold
    wrappedComponent={wrappedComponent}
    {...props}
  />
)
