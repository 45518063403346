// CODE_COMMENTS_272

import React, { Fragment } from 'react'
import { Form, Grid } from 'semantic-ui-react'


import {
  getLabelAndFieldWidthsForHorizontalFormSection,
} from '../../utils'


export default function HorizontalFormSection({
  // array of objects:
  // {
  //   label: 'Comments',
  //   // A react element (not a React component)
  //   arbitraryContentAfterLabelText: <InformationalPopup content="Some comment" />,
  //   required: false, // puts a red asterisk next to the label
  //   field: <TextArea value={formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS] || ''} />,
  // }
  children,
  // This component has sane defaults, but you can customize them by passing in
  // as few or as many entries as you'd like, e.g.
  // {
  //   widescreen: 4,
  //   largeScreen: 4,
  // }
  labelWidths: customLabelWidths={},
}) {
  const [labelWidths, fieldWidths] = getLabelAndFieldWidthsForHorizontalFormSection(customLabelWidths)

  return (
    <Grid
      columns={2}
      verticalAlign="middle"
    >
      {children.map(({
        label,
        arbitraryContentAfterLabelText,
        field,
        required,
      }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <Grid.Column
            {...labelWidths}
          >
            {/* We wrap the label element in Form.Field solely for styling */}
            <Form.Field required={required}>
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <label>
                {label}
                {arbitraryContentAfterLabelText || null}
              </label>
            </Form.Field>
          </Grid.Column>
          <Grid.Column
            {...fieldWidths}
          >
            {field}
          </Grid.Column>
        </Fragment>
      ))}
    </Grid>
  )
}
