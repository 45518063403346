import { put, call, select } from 'redux-saga/effects'


import { privateFetch } from '../../fetch'
import {
  SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES,
} from '../../../actions/actionTypes'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../utils'


export default sagaProps => {
  const {
    apiUrlPath,
    createFetchParamsFunc,
    stillSaveOnErrorFunc,
    transformInfoBeforeSaveFunc,
    saveAction,
  } = sagaProps
  return (
    function* saga(action) {
      const {
        customerId,
        operatingContractBrewerCustomerId, // CODE_COMMENTS_88
        formName,
        formValues,
        setIsFetching,
        setDidFetchFail,
        setDidFetchSucceed,
      } = action.payload

      setIsFetching(true)

      const fetchConfig = {
        path: apiUrlPath,
        params: createFetchParamsFunc({ ...action.payload, ...sagaProps }),
      }

      // CODE_COMMENTS_92
      if (operatingContractBrewerCustomerId) {
        const headers = createHeadersForContracteeBrewerApiCall(customerId)
        fetchConfig.headers = headers
      }
      let response
      try {
        response = yield call(privateFetch, fetchConfig)
      } catch (error) {
        if (error.response) {
          const info = yield call(stillSaveOnErrorFunc, error)
          if (info) {
            yield call(
              doSuccess,
              {
                saveAction,
                customerId,
                operatingContractBrewerCustomerId,
                formName,
                formValues,
                setIsFetching,
                setDidFetchSucceed,
                setDidFetchFail,
                info,
              },
            )
            return
          }
          yield call(
            doFailure,
            {
              error,
              setIsFetching,
              setDidFetchSucceed,
              setDidFetchFail,
            },
          )
          return
        }
        yield call(
          doFailure,
          {
            error,
            setIsFetching,
            setDidFetchSucceed,
            setDidFetchFail,
          },
        )
        return
      }
      const state = yield select()
      const info = transformInfoBeforeSaveFunc
        ? transformInfoBeforeSaveFunc({ state, info: response.data, values: formValues, ...action.payload })
        : response.data
      yield call(
        doSuccess,
        {
          saveAction,
          customerId,
          operatingContractBrewerCustomerId,
          formName,
          formValues,
          setIsFetching,
          setDidFetchSucceed,
          setDidFetchFail,
          info,
        },
      )
    }
  )
}

// Why are we exporting? Because the shipmentHistorySagaCreator uses this.
export function* doSuccess({
  saveAction,
  customerId,
  operatingContractBrewerCustomerId,
  formName,
  formValues,
  setIsFetching,
  setDidFetchSucceed,
  setDidFetchFail,
  info,
}) {
  yield put(createAction(
    saveAction,
    {
      savePath: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
      info,
    }))
  // CODE_COMMENTS_79
  yield put(createAction(
    SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES,
    {
      formName,
      formValues,
    },
  ))
  setIsFetching(false)
  setDidFetchFail(false)
  setDidFetchSucceed(true)
}

function* doFailure({
  error,
  setIsFetching,
  setDidFetchSucceed,
  setDidFetchFail,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  setIsFetching(false)
  setDidFetchSucceed(false)
  setDidFetchFail(true)
}
