/* eslint-disable dot-notation */

import { connect } from 'react-redux'

import values_ from 'lodash/values'


import Table from './components/Table'

import {
  getProp as getCustomerProp,
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getContracts,
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getAllKegOrderHistoryObjects,
} from '../../../../../redux/selectors/histories/kegOrderHistory'
import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../../redux/selectors/permissions'

import {
  getAllKegOrderObjectsToBeIncludedInHistoryTable,
} from '../../util/kegOrderHistory'

import {
  KEG_ORDER_STATUS_PENDING,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_ORDERS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_ORDERS,
} from '../../../../../constants/permissions'

import {
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CBMST,
  PPF_CONTRACT_TYPES_CONBRW,
} from '../../../../../constants'


const mapStateToProps = (state, ownProps) => {
  const { customerId, operatingContractBrewerCustomerId } = ownProps
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)

  const allKegOrderObjects = getAllKegOrderHistoryObjects(state, customerId)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')
  const areAnyItemsEditable = getAreAnyItemsEditable(state, customerId)
  const customerContracts = getContracts({
    entireContractsSlice,
    entireCustomersSlice,
    entireRelationshipsSlice,
    customerId: operatingContractBrewerCustomerId || customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CONBRW, PPF_CONTRACT_TYPES_CBMST],
    notExpiredOnly: false,
    activeStatusOnly: true,
    noFutureContracts: true,
    mostRecentOnly: true,
  })
  return {
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    allKegOrderObjects,
    configuredItemSkuIds,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
    customerContracts,
  }
}

export default connect(
  mapStateToProps,
)(Table)


function getAreAnyItemsEditable(state, customerId) {
  if (
    // CODE_COMMENTS_153
    !getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_ORDERS,
    }) ||
    !getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_ORDERS,
    })
  ) { return false }
  const allKegOrderObjs = values_(getAllKegOrderObjectsToBeIncludedInHistoryTable(state, customerId))
  return allKegOrderObjs.some(row => getIsindividualItemEditable(state, row))
}

function getIsindividualItemEditable(state, row) {
  // only pending shipments are allowed to be edited
  return row.status === KEG_ORDER_STATUS_PENDING
}
