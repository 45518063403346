/* eslint-disable react/jsx-fragments */

import React, { useMemo } from 'react'
import { Button } from 'semantic-ui-react'
import { LineChart, Line, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
// https://github.com/recharts/recharts/issues/275#issuecomment-386696660
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent'


import { CSVLink } from '../../../common-components/react-csv-fixed'
// import DownloadCsv from '../../../common-components/DownloadCsv'

import DataTable from './dataTable'

import {
  formatDataForUseInChartAndDownloadFile,
  getXAxisTicksFromDataForChart,
  convertQuantityLabelToDisplayedLabel,
  formatDateForTruncatedDisplay,
} from '../util'

import {
  getDoesDayComeBeforeMonthInLocaleDateStringFormat,
  getHumanReadableContainerType,
} from '../../../utils'


import './styles.css'


export default function ReportedInventoryVsCalculatedInventoryPresentationalComponent({
  reportedVsCalculatedInventoryDataForCustomer,
}) {
  const doesDayComeBeforeMonthInLocaleDateStringFormat = useMemo(
    () => getDoesDayComeBeforeMonthInLocaleDateStringFormat(),
    [],
  )
  // no memoization needed here because the
  // formatDataForUseInChartAndDownloadFile function is already memoized
  const [dataForChart, dataForDownloadFile] = formatDataForUseInChartAndDownloadFile({
    reportedVsCalculatedInventoryDataForCustomer,
  })
  const xAxisTicks = useMemo(
    () => getXAxisTicksFromDataForChart(dataForChart),
    [dataForChart],
  )

  return (
    <div className="reported-vs-calc-inv-container">
      {Object.keys(dataForChart).map((containerType, index) => (
        <div key={containerType}>
          {index === 0
            ? (
              <div id="centered-first-child-right-second-child">
                <h3 id="first-child-of-centered-first-child-right-second-child">{getHumanReadableContainerType(containerType)}</h3>
                {/* Alternative, less prominent "Download Data" button */}
                {/* <DownloadCsv
                  data={dataForDownloadFile}
                  headers={downloadFileHeadingNamesMap.map(i => i[1])}
                  filename="reported-vs-calculated-inventory.csv"
                  wrappingDivProps={{ id: 'second-child-of-centered-first-child-right-second-child' }}
                /> */}
                <CSVLink
                  data={dataForDownloadFile}
                  filename="reported-vs-calculated-inventory.csv"
                  id="second-child-of-centered-first-child-right-second-child"
                >
                  <Button
                    compact
                    color="blue"
                  >
                    Download Data
                  </Button>
                </CSVLink>
              </div>
            )
            : (
              <h3 style={{ textAlign: 'center' }}>{getHumanReadableContainerType(containerType)}</h3>
            )}
          <div style={{ width: '100%', height: 280 }}>
            <ResponsiveContainer>
              <LineChart
                data={dataForChart[containerType]}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
                syncId="anyId"
              >
                <CartesianGrid stroke="#f5f5f5" vertical={false} />
                <XAxis
                  dataKey="date"
                  type="number"
                  domain={[xAxisTicks[containerType][0], xAxisTicks[containerType][xAxisTicks[containerType].length-1]]}
                  ticks={xAxisTicks[containerType]}
                  tickFormatter={unixTime => {
                    const isFirstOrLastTick = (
                      unixTime === xAxisTicks[containerType][0]
                      || unixTime === xAxisTicks[containerType][xAxisTicks[containerType].length-1]
                    )
                    return formatDateForTruncatedDisplay({
                      unixTime,
                      doesDayComeBeforeMonthInLocaleDateStringFormat,
                      includeYear: isFirstOrLastTick,
                    })
                  }}
                />
                <YAxis
                  // https://github.com/recharts/recharts/issues/843#issuecomment-347637995:
                  // Without this, there's an unnecessary amount of empty space
                  // to the left of the Y-axis labels.
                  width={40}
                />
                <Tooltip
                  content={<CustomTooltipContent />}
                  // content={<CustomTooltipContentNew />}
                  offset={30}
                  formatter={(value, name) => ([value, convertQuantityLabelToDisplayedLabel(name)])}
                  labelFormatter={value => formatDateForTruncatedDisplay({
                    unixTime: value,
                    doesDayComeBeforeMonthInLocaleDateStringFormat,
                    includeYear: true,
                  })}
                />
                <Line connectNulls type="monotone" dataKey="reportedQuantity" stroke="#82ca9d" animationDuration={750} />
                <Line connectNulls type="monotone" dataKey="calculatedQuantity" stroke="#0058a8" animationDuration={750} />
                <Legend
                  formatter={label => convertQuantityLabelToDisplayedLabel(label)}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <DataTable
            dataForChart={dataForChart}
            containerType={containerType}
            doesDayComeBeforeMonthInLocaleDateStringFormat={doesDayComeBeforeMonthInLocaleDateStringFormat}
          />
        </div>
      ))}
    </div>
  )
}


// https://github.com/recharts/recharts/issues/275#issuecomment-386696660
function CustomTooltipContent(props) {
  const {
    payload = [],
    active,
  } = props
  // payload[0] doesn't exist when tooltip isn't visible
  if (!active) {
    return null
  }
  // payload is an array like this:
  // [
  //   {
  //       "stroke": "#82ca9d",
  //       "strokeWidth": 1,
  //       "fill": "#fff",
  //       "points": [],
  //       "dataKey": "reportedQuantity",
  //       "name": "reportedQuantity",
  //       "color": "#82ca9d",
  //       "value": 2284,
  //       "payload": {
  //           "date": 1609441200000,
  //           "SizeId": "HB",
  //           "reportedCountDate": "2020-12-31",
  //           "reportedQuantity": 2284,
  //           "reportedQtysByInventoryTypeId": {
  //               "OSW": 0,
  //               "DFF": 320,
  //               "EMP": 786,
  //               "FUL": 1178
  //           },
  //           "reportedInventoryReportId": "b02bbd9e-8eb9-4152-a94e-0f11ccea274e",
  //           "calculatedPostedDate": "2020-12-31",
  //           "calculatedQuantity": 1364,
  //           "differenceBetweenReportedAndCalculated": 920,
  //       }
  //   },
  //   {
  //       "stroke": "#0058a8",
  //       "strokeWidth": 1,
  //       "fill": "#fff",
  //       "points": [],
  //       "dataKey": "calculatedQuantity",
  //       "name": "calculatedQuantity",
  //       "color": "#0058a8",
  //       "value": 1364,
  //       "payload": {
  //           "date": 1609441200000,
  //           "SizeId": "HB",
  //           "reportedCountDate": "2020-12-31",
  //           "reportedQuantity": 2284,
  //           "reportedQtysByInventoryTypeId": {
  //               "OSW": 0,
  //               "DFF": 320,
  //               "EMP": 786,
  //               "FUL": 1178
  //           },
  //           "reportedInventoryReportId": "b02bbd9e-8eb9-4152-a94e-0f11ccea274e",
  //           "calculatedPostedDate": "2020-12-31",
  //           "calculatedQuantity": 1364,
  //           "differenceBetweenReportedAndCalculated": 920,
  //       }
  //   }
  // ]
  const reportedQuantityObj = payload?.find(o => o.name === 'reportedQuantity')
  const calculatedQuantityObj = payload?.find(o => o.name === 'calculatedQuantity')
  const includeDifferenceLine = Boolean(reportedQuantityObj && calculatedQuantityObj)

  // mutating props directly is against react's conventions
  // so we create a new payload with the name and value fields set to what we want
  const newPayload = [
    ...(payload || []),
    ...(includeDifferenceLine ? [{
      name: 'Difference',
      // all your data which created the tooltip is located in the .payload property
      value: calculatedQuantityObj.payload.differenceBetweenReportedAndCalculated,
      color: '#f28e1c',
    }] : []),
  ]

  // we render the default, but with our overridden payload
  return <DefaultTooltipContent {...props} payload={newPayload} />
}


// https://github.com/recharts/recharts/issues/275#issuecomment-919556402: If we
// ever want to refactor this component so that the tooltip is a completely
// custom component, this is how.

// eslint-disable-next-line no-unused-vars
function CustomTooltipContentNew(props) {
  const {
    active,
    payload,
  } = props
  if (!active) {
    return null
  }
  return (
    <div
      style={{
        border: '#bbb 1px solid',
      }}
    >
      <p
        style={{
          margin: '0 0',
          padding: '3px 7.5px',
          backgroundColor: 'white',
        }}
      >
        {payload && payload[0] != null && payload[0].payload.Name}
      </p>
      <p
        style={{
          margin: '0 0',
          padding: '3px 7.5px',
          backgroundColor: 'white',
          color: '#007AFF',
        }}
      >
        Name:
        {' '}
        {
          payload && payload[0] != null && payload[0].payload.name
        }
      </p>
    </div>
  )
}
