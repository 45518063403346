/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file, see CODE COMMENTS 50
/* eslint-disable max-len */

/**
 * CODE_COMMENTS_15
*/
import { put, all, call, select, takeEvery } from 'redux-saga/effects'
import { batchActions } from 'redux-batched-actions'
import cloneDeep_ from 'lodash/cloneDeep'

import { privateFetch } from '../fetch'
import {
  getRelatedToCustomerInfoApiUrl,
  getRelatedToRelationshipInfoApiUrl,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../utils'
import {
  FETCH_CUSTOMER_RELATED_TO_INFO,
  SAVE_CUSTOMERS,
  SAVE_RELATIONSHIPS,
  SAVE_RELATIONSHIPS_AND_CUSTOMERS_AT_THE_SAME_TIME,
  FETCH_CUSTOMER_RELATED_TO_INFO_REQUEST,
  FETCH_CUSTOMER_RELATED_TO_INFO_SUCCESS,
  FETCH_CUSTOMER_RELATED_TO_INFO_FAILURE,
} from '../../actions/actionTypes'

import {
  getProp as getCustomerProp,
} from '../../selectors/customers'
import {
  getProp as getCurrentUserProp,
} from '../../selectors/currentUser'
import {
  getAllChildCustomerIdsOfCustomer,
} from '../../selectors/children'

import {
  getDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall,
  filterCustomerObjectsAndRelationshipObjectsThatHaveDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall,
  logErrorExplainingDiscrepancyBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall,
} from '../util/apiResponseDataValidation/relatedToOrFrom/relatedToOrFromMapVsNonMapDiscrepancies'
import {
  validateAndFilterRelationshipObjectsBasedOnBasicProps,
} from '../util/apiResponseDataValidation/relatedToOrFrom'
import {
  createHeadersForContracteeBrewerApiCall,
} from '../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'
import {
  createQueryParametersForRelationshipApiCall,
} from '../util/headersAndQueryParamsOfApiCalls/relationships'
import {
  saveAddressesEmbeddedInCustomerObjs,
} from '../util/relatedToOrFrom'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_PUB,
} from '../../../constants'

import {
  ApiReturnedUnexpectedDataError,
} from '../../../customErrors'


export function* fetchCustomerRelatedToInfo(action) {
  const {
    customerId,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    preventsDashboardFromLoadingIfFails,
  } = action.payload
  yield put(createAction(
    FETCH_CUSTOMER_RELATED_TO_INFO_REQUEST,
    // CODE_COMMENTS_196
    { target: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)] },
  ))


  // CODE_COMMENTS_90 CODE_COMMENTS_88
  const params = yield call(
    createQueryParametersForRelationshipApiCall,
    customerId,
    operatingContractBrewerCustomerId,
  )
  const headers = operatingContractBrewerCustomerId
    ? createHeadersForContracteeBrewerApiCall(customerId)
    : {}

  const fetchConfigForRelatedToCustomerInfoApiCall = {
    path: getRelatedToCustomerInfoApiUrl(customerId),
    params,
    headers,
  }
  const fetchConfigForRelatedToRelationshipsInfoApiCall = {
    path: getRelatedToRelationshipInfoApiUrl(customerId),
    params,
    headers,
  }

  let customerInfoResponse
  let relationshipInfoResponse
  try {
    // CODE_COMMENTS_48
    [customerInfoResponse, relationshipInfoResponse] = yield all([
      call(privateFetch, fetchConfigForRelatedToCustomerInfoApiCall),
      call(privateFetch, fetchConfigForRelatedToRelationshipsInfoApiCall),
    ])
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) { // customer is not related to any other customers
        yield call(
          doSuccess,
          customerId,
          operatingContractBrewerCustomerId,
          { ...error, data: [] },
          { ...error, data: {} },
        )
        return
      }
      yield call(
        doFailure,
        {
          customerId,
          operatingContractBrewerCustomerId,
          error,
          preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
        },
      )
      return
    }
    yield call(
      doFailure,
      {
        customerId,
        operatingContractBrewerCustomerId,
        error,
        preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
      },
    )
    return
  }
  yield call(
    doSuccess,
    customerId,
    operatingContractBrewerCustomerId,
    customerInfoResponse,
    relationshipInfoResponse,
  )
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CUSTOMER_RELATED_TO_INFO, fetchCustomerRelatedToInfo],
]

function* doSuccess(customerId, operatingContractBrewerCustomerId, customerInfoResponse, relationshipInfoResponse) {
  const fResult = validateAndFilterRelationshipObjectsBasedOnBasicProps({
    customerInfoResponse,
    relationshipInfoResponse,
  })
  let {
    arrayOfCustomerObjects,
    arrayOfRelationshipObjects,
  } = fResult
  const {
    canDataBeSavedToStore,
    haveAnyCustomerOrRelationshipObjectsBeenFiltered: haveAnyCustomerOrRelationshipObjectsBeenFilteredBasedOnBasicProps,
  } = fResult

  if (!canDataBeSavedToStore) {
    throw new ApiReturnedUnexpectedDataError() // CODE_COMMENTS_180
  }

  // When we get the related to information of our contractee brewer, the
  // reationship to our operating Contract Brewer will be included in that
  // related to information. This information is already saved in the Redux
  // store, so we don't want to save it again (and in fact, it's harmful if we
  // save it again because the customer object of the Contract Brewer that gets
  // returned from this call contains a restricted set of information. For
  // example, the customer representative props [ceRep, logisticsRep, etc] are
  // all null).
  if (operatingContractBrewerCustomerId) {
    arrayOfCustomerObjects = arrayOfCustomerObjects.filter(
      o => o.id !== operatingContractBrewerCustomerId,
    )
    arrayOfRelationshipObjects = arrayOfRelationshipObjects.filter(
      o => o.destinationCustomerId !== operatingContractBrewerCustomerId,
    )
  }

  const discrepanciesBetweenTheTwoCalls = getDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall(
    'to',
    arrayOfCustomerObjects,
    arrayOfRelationshipObjects,
  )

  if (discrepanciesBetweenTheTwoCalls) {
    ({
      arrayOfCustomerObjects,
      arrayOfRelationshipObjects,
    } = filterCustomerObjectsAndRelationshipObjectsThatHaveDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall(
      'to',
      arrayOfCustomerObjects,
      arrayOfRelationshipObjects,
      discrepanciesBetweenTheTwoCalls,
    ))

    // If any customer or relationship objects have been filtered because their
    // basic props have problems, then the discrepancies between customers and
    // relationships are probably due to that filtering rather than to the
    // original data sent by the backend.
    if (!haveAnyCustomerOrRelationshipObjectsBeenFilteredBasedOnBasicProps) {
      logErrorExplainingDiscrepancyBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall(
        discrepanciesBetweenTheTwoCalls,
        customerInfoResponse,
        relationshipInfoResponse,
      )
    }
  }

  const state = yield select()

  // When we get the related to information of a Brewer, that Brewer might have
  // Child Pubs (a child pub is different than a pub the Brewer is related
  // to--see CODE_COMMENTS_49), and if so, those child pubs will already have
  // their customer information saved in the Redux store from a /children call
  // made before this /relatedTo call. If a pub is a child of a Brewer, it will
  // also be related to a Brewer. We want to save the relationshipObject between
  // the Brewer and the Pub, but we don't want to save the customerObject,
  // because the customerObject returned by a /relatedTo call contains a
  // limited set of information compared to the customerObject returned by a
  // /children call (for instance, the `customerStatus` of a customerObject
  // returned by /relatedTo will always be null, whereas it won't be null from a
  // /children call, and `customerStatus` is super important because it's a
  // factor in determining whether the Brewer can report inventory for that
  // Pub). It's very important that this code run after the
  // getDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall()
  // function, because it creates an intended discrepancy between the list of
  // customer objects and relationship objects.
  const customerTypeofCurrentlyOperatingCustomer = yield select(getCustomerProp, customerId, 'customerType')
  if (customerTypeofCurrentlyOperatingCustomer === CUSTOMER_TYPES_BREWER) {
    const childPubIds = yield call(
      getAllChildCustomerIdsOfCustomer,
      {
        state,
        customerId,
        // CODE_COMMENTS_112, CODE_COMMENTS_122
        onlyCustomersWhoAreNotCurrentlyInactive: false,
        customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_PUB,
      },
    )
    arrayOfCustomerObjects = arrayOfCustomerObjects.filter(
      o => (!childPubIds.includes(o.id)),
    )
  }

  yield call(saveAddressesEmbeddedInCustomerObjs, arrayOfCustomerObjects)

  // A Master logs in who has a child BRW and a child CONBRW. The Child BRW is
  // related to the child CONBRW, so the CONBRW's customerObj will show up in
  // these results. The CONBRW's customerObj is already saved in the Redux
  // store, so we don't want to save it again (and in fact, it's harmful if we
  // save it again, for the same reason as the comment above)
  const rootCustomerId = getCurrentUserProp(state, 'rootCustomerId')
  const rootCustomerType = getCustomerProp(state, rootCustomerId, 'customerType')
  if (rootCustomerType === CUSTOMER_TYPES_MASTER) {
    const childCustomerIds = getAllChildCustomerIdsOfCustomer({
      state,
      customerId: rootCustomerId,
    })
    arrayOfCustomerObjects = arrayOfCustomerObjects.filter(
      o => !childCustomerIds.includes(o.id),
    )
    // Keep in mind that although we filter out the superfluous customerObj, we
    // DON'T want to filter out the relationshipObj--it's not superfluous.
  }

  yield put(
    batchActions( // CODE_COMMENTS_148
      [
        createAction(SAVE_CUSTOMERS, { info: cloneDeep_(arrayOfCustomerObjects) }),
        createAction(SAVE_RELATIONSHIPS, { info: cloneDeep_(arrayOfRelationshipObjects) }),
      ],
      SAVE_RELATIONSHIPS_AND_CUSTOMERS_AT_THE_SAME_TIME,
    ),
  )

  // CODE_COMMENTS_20
  yield put(createAction(
    FETCH_CUSTOMER_RELATED_TO_INFO_SUCCESS,
    // CODE_COMMENTS_196
    { target: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)] },
  ))
}


function* doFailure({
  customerId,
  operatingContractBrewerCustomerId,
  error,
  preventsDashboardFromLoading,
}) {
  yield call(
    generalDoFailure,
    {
      error,
      preventsDashboardFromLoading,
      action: createFetchFailureAction({
        error,
        type: FETCH_CUSTOMER_RELATED_TO_INFO_FAILURE,
        target: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
      }),
    },
  )
}
