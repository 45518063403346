// CODE_COMMENTS_244
import moment from 'moment'

import pick_ from 'lodash/pick'
import sortBy_ from 'lodash/sortBy'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../constants/formAndApiUrlConfig/commonConfig'

import {
  NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS,
} from '../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  formatApiDate,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  convertApiDateToMoment,
} from './'


import {
  CHECK_FOR_DUPLICATE_SHIPMENTS_IN_REPORT_SHIPMENTS_FORMS_FROM_AS_FAR_BACK_AS_X_DAYS_AGO,
} from '../config'


/*
 * *****************************************************************************
 * General
 * *****************************************************************************
*/

export function getDateRangeOfPotentialDuplicates() {
  return {
    startDate: moment().subtract(CHECK_FOR_DUPLICATE_SHIPMENTS_IN_REPORT_SHIPMENTS_FORMS_FROM_AS_FAR_BACK_AS_X_DAYS_AGO, 'days'),
    endDate: moment().add(NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS, 'days'),
  }
}


export function getDoesDateFallWithinDateRangeOfPotentialDuplicates({
  date, // either a moment object, a string or a unix timestamp
  // set to True if date is unix timestamp or DEFAULT_API_DATE_FORMAT
  isApiDate=true,
  // acceptable values include DEFAULT_DISPLAYED_DATE_FORMAT
  explicitDateStringFormatIfNotIsApiDate,
}) {
  if (!date) {
    // Just in case a shipmentObject from the backend doesn't have a dateShipped
    // prop.
    return false
  }
  const dateMoment = moment.isMoment(date)
    ? date
    : isApiDate
      ? convertApiDateToMoment(date)
      : moment(date, explicitDateStringFormatIfNotIsApiDate)

  const { startDate, endDate } = getDateRangeOfPotentialDuplicates()
  // CODE_COMMENTS_80: don't use > or <
  return dateMoment.isBetween(startDate, endDate, null, '[]')
}


/*
 * *****************************************************************************
 * Saving Shipments from the past x days to the Redux Store
 * *****************************************************************************
*/

export function convertShipmentObjsIntoProperShapeForReduxStore({
  customerId,
  operatingContractBrewerCustomerId,
  arrayOfShipmentObjs,
  shipmentObj: shipmentObject, // if arrayOfShipmentObjs is null
}) {
  const actualArrayOfShipmentObjs = arrayOfShipmentObjs || [shipmentObject]

  return actualArrayOfShipmentObjs.reduce(
    (acc, shipmentObj) => {
      const key = createPotentialDuplicateShipmentStringKeyForReduxStoreFromShipmentObj({
        customerId,
        operatingContractBrewerCustomerId,
        shipmentObj,
      })
      const value = createPotentialDuplicateShipmentDetailsObjValueForReduxStoreFromShipmentObj({
        customerId,
        operatingContractBrewerCustomerId,
        shipmentObj,
      })

      // Same origin, destination, keg quantities and isReverse; potentially
      // different dateReported, createdByUserId and proNumber
      const existingShipmentsWithIdenticalDetails = acc[key] || []

      return {
        ...acc,
        [key]: [...existingShipmentsWithIdenticalDetails, value],
      }
    },
    {},
  )
}


export function createPotentialDuplicateShipmentStringKeyForReduxStoreFromShipmentObj({
  customerId,
  operatingContractBrewerCustomerId,
  shipmentObj,
}) {
  const targetCustomerReduxId = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
    customerId,
    operatingContractBrewerCustomerId,
  )
  const origin = shipmentObj.originCustomerId === customerId
    ? targetCustomerReduxId
    : shipmentObj.originCustomerId
  const destination = shipmentObj.destinationCustomerId === customerId
    ? targetCustomerReduxId
    : shipmentObj.destinationCustomerId
  const dateShipped = formatApiDate(shipmentObj.dateShipped, DEFAULT_DISPLAYED_DATE_FORMAT)

  return createPotentialDuplicateShipmentStringKeyForReduxStore({
    origin,
    destination,
    dateShipped,
    reverseMovement: shipmentObj.reverseMovement,
    lineItems: shipmentObj.lineItems,
  })
}


export function createPotentialDuplicateShipmentDetailsObjValueForReduxStoreFromShipmentObj({
  shipmentObj,
}) {
  return pick_(shipmentObj, [
    // These details need to be exposed to
    // the end user in the "This might be a duplicate" message
    'createdByUserId',
    'dateReported',
    'proNumber',

    // If we're editing a shipment, we need to avoid the scenario where the very
    // shipment we're editing triggers a potential duplicate warning; the code
    // that ensures this scenario needs access to shipment IDs.
    'id',
  ])
}


export function createPotentialDuplicateShipmentStringKeyForReduxStore({
  origin,
  destination,
  dateShipped,
  reverseMovement,
  lineItems,
}) {
  // Converts to an array of arrays and filters out any items that have
  // a quantity of 0
  let itemSkusArr = lineItems.filter(o => o.quantity !== 0).map(o => [o.itemSkuId, o.quantity])
  itemSkusArr = sortBy_(itemSkusArr, a => a[0])
  const arr = [
    ['origin', origin],
    ['destination', destination],
    ['dateShipped', dateShipped],
    ['reverseMovement', reverseMovement],
    ...itemSkusArr,
  ]
  const arrOfKeyValueStrings = arr.map(([k, v]) => `${k}: ${v}`)
  return arrOfKeyValueStrings.join(', ')
}
