import React from 'react'

import PopulatedContactCard from './PopulatedContactCard'
import FetchingOrFailed from '../containers/FetchingOrFailed'

import {
  CUSTOMER_REPS_GENERIC,
} from '../../../constants'

import {
  GENERIC_CONTACT_INFO_PHONE,
  GENERIC_CONTACT_INFO_EMAIL,
} from '../../../config'


export default ({
  repType,
  repId,
  ...rest
}) => (
  repType === CUSTOMER_REPS_GENERIC
    ? <PopulatedContactCard
      repType={repType}
      email={GENERIC_CONTACT_INFO_EMAIL}
      phone={GENERIC_CONTACT_INFO_PHONE}
      {...rest}
    />:
      (repType === '' && repId === '')
    ? <div/>: <FetchingOrFailed
      repType={repType}
      repId={repId}
      {...rest}
    />
)
