import React from 'react'
import { Form } from 'semantic-ui-react'

import isArray_ from 'lodash/isArray'
import isFunction_ from 'lodash/isFunction'
import get_ from 'lodash/get'
import omit_ from 'lodash/omit'


import InformationalPopup from '../../../InformationalPopup'

import {
  createFieldClassName,
  isDropdownComponent,
} from './util'

import {
  shallowCompare,
} from '../../../../utils'


const IndividualField = props => {
  const {
    formValues,
    fieldDefinition,
    fieldPropsForThisParticularField,
    shouldThisParticularFieldBeOmitted,
    disabled,
    showLabel,
    initialValue,
    fieldHtmlName,
    fieldArrayName,
    rowIndex,
  } = props
  const fieldPropsForThisField = fieldPropsForThisParticularField || []

  const formFieldProps = gatherFormFieldProps(props)
  const fieldProps = gatherFieldProps(props)

  const isFixedWidthDropdown = Boolean(
    isDropdownComponent(fieldDefinition) &&
    formFieldProps &&
    get_(formFieldProps, ['style', 'width']),
  )

  // The difference between arbitraryComponentAfterLabel and
  // arbitraryComponentBeforeFieldComponent is that arbitraryComponentAfterLabel
  // comes before the label's red asterisk (which is rendered only if the field
  // is a required field), whereas arbitraryComponentBeforeFieldComponent comes
  // after the red asterisk
  const ArbitraryComponentAfterLabel = fieldDefinition.arbitraryComponentAfterLabel
  const ArbitraryComponentBeforeFieldComponent = fieldDefinition.arbitraryComponentBeforeFieldComponent

  const FieldComponentToRender = fieldDefinition.fieldComponent
  const propsOfFieldComponentToRender = {
    name: fieldHtmlName,
    // The fluid and compact props are _both_ necessary in order to
    // make fixed-width dropdowns work properly
    ...(isFixedWidthDropdown ? { fluid: true, compact: true } : {}),
    ...fieldProps,
    ...fieldPropsForThisField,
    className: createFieldClassName(fieldDefinition),
  }

  return (
    <Form.Field
      disabled={disabled}
      {...formFieldProps}
    >
      {showLabel && !shouldThisParticularFieldBeOmitted &&
        <label htmlFor={fieldHtmlName} className="field-label">
          {fieldDefinition.topLabel}
          {fieldDefinition.informationalIconContent &&
            <InformationalPopup content={fieldDefinition.informationalIconContent} />
          }
          {ArbitraryComponentAfterLabel && <ArbitraryComponentAfterLabel />}
        </label>
      }
      {
        ArbitraryComponentBeforeFieldComponent && !shouldThisParticularFieldBeOmitted
          && <ArbitraryComponentBeforeFieldComponent
            {...props}
          />
      }
      {
        shouldThisParticularFieldBeOmitted
          ? null
          : fieldDefinition.noFieldJustText
            ? <JustTextField
              fieldDefinition={fieldDefinition}
              formValues={formValues}
              fieldArrayName={fieldArrayName}
              rowIndex={rowIndex}
              initialValue={initialValue}
            />
            : <FieldComponentToRender
              {...propsOfFieldComponentToRender}
            />
      }
    </Form.Field>
  )
}

const JustTextField = ({
  fieldDefinition,
  formValues,
  fieldArrayName,
  rowIndex,
  initialValue,
}) => {
  if (!fieldDefinition.noFieldJustText) { return null }

  let fieldContent
  if (fieldDefinition.noFieldJustText === true) {
    fieldContent = initialValue
  } else if (isFunction_(fieldDefinition.noFieldJustText)) {
    fieldContent = fieldDefinition.noFieldJustText(formValues, fieldArrayName, rowIndex)
  } else if (isArray_(fieldDefinition.noFieldJustText)) {
    fieldContent = fieldDefinition.noFieldJustText[rowIndex]
  } else { // must be a string
    fieldContent = fieldDefinition.noFieldJustText
  }

  return (
    <div className="text-in-lieu-of-form-field">
      {fieldContent}
    </div>
  )
}


function individualFieldPropsAreEqual(prevProps, nextProps) {
  const {
    disabled: disabledPrev,
    formValues: formValuesPrev,
    fieldArrayName: fieldArrayNamePrev,
    rowIndex: rowIndexPrev,
    fieldDefinition: fieldDefinitionPrev,
  } = prevProps
  const valuePrev = get_(
    formValuesPrev,
    [fieldArrayNamePrev, rowIndexPrev, fieldDefinitionPrev.fieldName],
    '',
  )
  const explicitRerenderPropsPrev = gatherAllRerenderComponentWhenThesePropsChange(prevProps)

  const {
    disabled,
    formValues,
    fieldArrayName,
    rowIndex,
    fieldDefinition,
  } = nextProps
  const value = get_(
    formValues,
    [fieldArrayName, rowIndex, fieldDefinition.fieldName],
    '',
  )
  const explicitRerenderProps = gatherAllRerenderComponentWhenThesePropsChange(nextProps)
  const areExplicitRerenderPropsIdentical = shallowCompare(
    explicitRerenderPropsPrev,
    explicitRerenderProps,
  )

  return (
    disabledPrev === disabled &&
    valuePrev === value
    && areExplicitRerenderPropsIdentical
  )
}

export default React.memo(
  IndividualField,
  individualFieldPropsAreEqual,
)


function gatherAllRerenderComponentWhenThesePropsChange(props) {
  const formFieldProps = gatherFormFieldProps({
    ...props,
    omitRerenderComponentWhenThesePropsChange: false,
  })
  const fieldProps = gatherFieldProps({
    ...props,
    omitRerenderComponentWhenThesePropsChange: false,
  })
  const one = get_(props.fieldDefinition, ['rerenderComponentWhenThesePropsChange']) || {}
  const two = get_(formFieldProps, ['rerenderComponentWhenThesePropsChange']) || {}
  const three = get_(fieldProps, ['rerenderComponentWhenThesePropsChange']) || {}

  return {
    ...one,
    ...two,
    ...three,
  }
}


function gatherFormFieldProps({
  fieldDefinition,
  formValues,
  rowIndex,
  omitRerenderComponentWhenThesePropsChange=true,
}) {
  let formFieldProps = (
    fieldDefinition.formFieldProps &&
    isFunction_(fieldDefinition.formFieldProps)
      ? fieldDefinition.formFieldProps({ formValues, rowIndex })
      : fieldDefinition.formFieldProps
  ) || {}
  if (omitRerenderComponentWhenThesePropsChange) {
    formFieldProps = omit_(formFieldProps, ['rerenderComponentWhenThesePropsChange'])
  }
  return formFieldProps
}


function gatherFieldProps({
  fieldDefinition,
  formValues,
  rowIndex,
  omitRerenderComponentWhenThesePropsChange=true,
}) {
  let fieldProps = (
    fieldDefinition.fieldProps &&
    isFunction_(fieldDefinition.fieldProps)
      ? fieldDefinition.fieldProps({ formValues, rowIndex })
      : fieldDefinition.fieldProps
  ) || {}
  if (omitRerenderComponentWhenThesePropsChange) {
    fieldProps = omit_(fieldProps, ['rerenderComponentWhenThesePropsChange'])
  }
  return fieldProps
}
