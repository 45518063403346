import {
    FETCH_CUSTOMER_MESSAGES_REQUEST,
    FETCH_CUSTOMER_MESSAGES_SUCCESS,
    FETCH_CUSTOMER_MESSAGES_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
    FETCH_CUSTOMER_MESSAGES_REQUEST,
    FETCH_CUSTOMER_MESSAGES_SUCCESS,
    FETCH_CUSTOMER_MESSAGES_FAILURE,
)
