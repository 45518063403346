import React from 'react'
import { connect } from 'react-redux'

import { Segment } from 'semantic-ui-react'


import SaveBankAccountInfo from './components'

import fetchStatusSelectors from '../../../redux/selectors/fetchStatuses/customers/bankAccountInfo'

import {
  FETCH_STORED_BANK_ACCOUNT_INFO,
} from '../../../redux/actions/actionTypes'
import createAction from '../../../redux/actions/createAction'

import {
  getHasStoredBankAccountInfo,
  getBankName,
  getEndingDigitsOfBankAccount,
} from '../../../redux/selectors/bankAccountInfo'

import withEither from '../../../common-components/HOC/general/withEither'

import {
  TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE,
  DISPLAY_SAVE_BANK_ACCOUNT_FEATURE_TEMPORARILY_DISABLED_MESSAGE_RATHER_THAN_NOTHING_AT_ALL,
} from '../../../config'


const mapStateToProps = (state, { customerId }) => {
  const { getFetchStatuses } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(state, customerId)

  const hasStoredBankAccountInfo = getHasStoredBankAccountInfo(state, customerId)
  let bankName
  let endingDigitsOfBankAccount
  if (hasStoredBankAccountInfo) {
    bankName = getBankName(state, customerId)
    endingDigitsOfBankAccount = getEndingDigitsOfBankAccount(state, customerId)
  }


  return {
    hasStoredBankAccountInfo,
    bankName,
    endingDigitsOfBankAccount,

    ...fetchStatuses,
  }
}

const mapDispatchToProps = (dispatch, { customerId }) => ({
  refetchSavedBankAccountInfo: () => {
    dispatch(createAction(
      FETCH_STORED_BANK_ACCOUNT_INFO,
      { customerId },
    ))
  },
})

const SaveBankAccountInfoConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaveBankAccountInfo)

export default withEither(
  () => TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE,
  () => {
    if (DISPLAY_SAVE_BANK_ACCOUNT_FEATURE_TEMPORARILY_DISABLED_MESSAGE_RATHER_THAN_NOTHING_AT_ALL) {
      return (
        <Segment color="orange">
          Saving bank accounts and using saved bank accounts is temporarily disabled.
        </Segment>
      )
    }
    return null
  },
)(SaveBankAccountInfoConnectedComponent)
