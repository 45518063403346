// CODE_COMMENTS_283

/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file, see CODE COMMENTS 50


import { put, call, select, takeEvery } from 'redux-saga/effects'

import uniq_ from 'lodash/uniq'

import { privateFetch } from '../fetch'

import {
  FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS,
  FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_REQUEST,
  FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_SUCCESS,
  FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_FAILURE,
  SAVE_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER,
} from '../../actions/actionTypes'

import {
  getEntireSlice as getEntireInboundUnacknowledgedShipmentsSlice,
} from '../../selectors/inboundUnacknowledgedShipments'
import {
  getEntireSlice as getEntireOrdersOfInboundUnacknowledgedShipmentsSlice,
} from '../../selectors/ordersOfInboundUnacknowledgedShipments'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  API_URL_PATH_KEG_ORDERS,
} from '../../../constants'
import {
  isTruthyAndNonEmpty,
} from '../../../utils'

export function* fetchOrdersOfInboundUnacknowledgedShipments(action) {
  const { customerId } = action.payload
  yield put(createAction(FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_REQUEST, { target: [customerId] }))
  const orderUuids = yield call(getOrderUuidsThatNeedToBeFetched, customerId)
  // Unfortunately, the back-end doesn't expose an endpoint to get multiple
  // orders by id in a single call (this capability doesn't exist even if you
  // pass in other fields like 'orderId' or 'orderIdNumber' field). So we're
  // forced to make calls sequentially, one for each order.
  for (const orderUuid of orderUuids) {
    let response
    try {
      response = yield call(
        privateFetch,
        {
          path: `${API_URL_PATH_KEG_ORDERS}/${orderUuid}`,
        },
      )
    } catch (error) {
      yield call(doFailure, customerId, error)
      return
    }
    const existingOrders = yield select(
      getEntireOrdersOfInboundUnacknowledgedShipmentsSlice,
      customerId,
    )
    // We save each shipment individually so that if the first 3 order fetches
    // succeed succeed but the last order fails (imagine the Ack Inbound
    // Shipments form has 4 shipments, each on a separate order), the end user
    // will still be able to see 3 out of the 4 keg orders, seeing a failure
    // message only for the last order (this is due to the way our component is
    // written: a failure message is only shown if this saga fails _and_ the
    // order doesn't exist in the Redux store; if the saga fails but the order
    // exists in Redux, the order is shown).
    yield put(
      createAction(
        SAVE_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER,
        { customerId, info: [...Object.values(existingOrders || {}), response.data] },
      ),
    )
  }
  yield call(doSuccess, customerId)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS, fetchOrdersOfInboundUnacknowledgedShipments],
]

function* doSuccess(customerId) {
  // CODE_COMMENTS_20
  yield put(createAction(
    FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_SUCCESS, { target: [customerId] },
  ))
}

function* doFailure(customerId, error) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_FAILURE,
        target: [customerId],
      }),
    },
  )
}

function* getOrderUuidsThatNeedToBeFetched(customerId) {
  const inboundUnacknowledgedShipmentsOfCustomer = yield select(
    getEntireInboundUnacknowledgedShipmentsSlice,
    customerId,
  )
  if (!isTruthyAndNonEmpty(inboundUnacknowledgedShipmentsOfCustomer)) {
    return []
  }
  const allOrderIdsOfInboundUnacknowledgedShipmentsOfCustomer = Object.values(
    inboundUnacknowledgedShipmentsOfCustomer,
  ).map(o => o.orderId)
  // Orders will already be fetched if, for example, the user has just
  // successfully acknowledged some but not all inbound unacked shipments and
  // clicks the "Fill out form again" button, in which case unacked shipments
  // will be re-fetched (there probably won't be any new unacked shipments, and
  // those that were already fetched the first time will have their orders
  // already fetched from the first time as well).
  const ordersOfInboundUnacknowledgedShipmentsOfCustomerThatHaveAlreadyBeenFetched = yield select(
    getEntireOrdersOfInboundUnacknowledgedShipmentsSlice,
    customerId,
  )
  if (!isTruthyAndNonEmpty(ordersOfInboundUnacknowledgedShipmentsOfCustomerThatHaveAlreadyBeenFetched)) {
    return allOrderIdsOfInboundUnacknowledgedShipmentsOfCustomer
  }
  const orderIdsOfInboundUnacknowledgedShipmentsOfCustomerThatHaveAlreadyBeenFetched = Object.values(
    ordersOfInboundUnacknowledgedShipmentsOfCustomerThatHaveAlreadyBeenFetched,
  ).map(o => o.id)
  const toReturn = allOrderIdsOfInboundUnacknowledgedShipmentsOfCustomer.filter(id => (
    !orderIdsOfInboundUnacknowledgedShipmentsOfCustomerThatHaveAlreadyBeenFetched.includes(id)
  ))
  return uniq_(toReturn)
}
