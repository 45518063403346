import React from 'react'
import { useTranslation } from 'react-i18next'
import withIsPrelimInfoFetched from '../../HOC/redux/withIsPrelimInfoFetched'

import RevealContactInfo from '../../RevealContactInfo'

import {
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../constants'

export default withIsPrelimInfoFetched(({
  didFetchSucceed: isPrelimInfoFetched,
  customerId,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <div>
      <span>{translate(DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR)}</span>
      {/* Don't show the contact card if the customerId is not passed in */}
      {isPrelimInfoFetched && customerId && (
        <RevealContactInfo
          customerId={customerId}
          repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
          asPopup
          buttonProps={{
            style: { marginLeft: '0.5rem' },
          }}
        />
      )}
    </div>
  )
})
