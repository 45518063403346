import React from 'react'
import { Card, Header } from 'semantic-ui-react'
import { Trans, useTranslation } from 'react-i18next'

import AddressCard from './AddressCard'

import UiContainer from '../../../common-components/UiContainer'
import RevealContactInfo from '../../../common-components/RevealContactInfo'

import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'

import './styles.css'


const InfoMessage = ({ customerId, translate }) => (
  <span style={{ fontStyle: 'italic', color: 'grey' }}>
    {`* ${translate('Contact your personalized representative to edit an address.')} `}
    <RevealContactInfo
      customerId={customerId}
      repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
      style={{ paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0 }}
      asPopup
    />
  </span>
)

const AddressesCardGroup = ({ children }) => (
  <Card.Group
    centered
    className="addresses-card-group"
  >
    {children}
  </Card.Group>
)

export default UiContainer({
  headerText: 'Addresses',
  containerId: 'manageAddressesSection',
})(({
  defaultShippingAddress,
  billingAddress,
  nonDefaultShippingAddresses, // an array of address objects

  customerId,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <div>
      <InfoMessage
        customerId={customerId}
        translate={translate}
      />

      <Header as="h3">
        <Trans ns="common" i18nKey="Required Addresses">
          Required Addresses
        </Trans>
      </Header>

      <AddressesCardGroup>
        <AddressCard
          address={defaultShippingAddress}
          headerText={translate('Default Shipping Address')}
        />
        <AddressCard
          address={billingAddress}
          headerText={translate('Billing Address')}
        />
      </AddressesCardGroup>

      <Header as="h3">{translate('Additional Shipping Addresses')}</Header>

      {!isTruthyAndNonEmpty(nonDefaultShippingAddresses) && translate('You currently have no additional shipping addresses on file.')}

      {nonDefaultShippingAddresses &&
        <AddressesCardGroup>
          {nonDefaultShippingAddresses.map(shippingAddress => (
            <AddressCard
              key={shippingAddress.id}
              address={shippingAddress}
            />
          ))}
        </AddressesCardGroup>
      }
    </div>
  )
})
