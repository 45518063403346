/**
 * CODE_COMMENTS_62
 */

import {
  createDisplayedShipmentNumber,
  createDisplayedProNumber,
  createDisplayedShipmentType,
  createDisplayedMovementType,
  createDisplayedCarrierName,
  createDisplayedSourceName,
  createDisplayedShipmentDate,
  createDisplayedActualDeliveryDateForFullDetailsModal,
  createDisplayedEstimatedDeliveryDateForFullDetailsModal,
  createItemSkuIdFieldsDefinitions,
  createContainerTypesFieldsDefinitions,
  createDisplayedForeignKegs,
  createDisplayedOrderNumber,
} from '../common/displayedShipmentDataEtc'

import {
  getIsSubsidiaryATypeThatShouldTrackForeignKegs,
  getIsSubsidiaryATypeThatShouldTrackWoodenPallets,
} from '../../../../../../redux/selectors/rewrite/subsidiaries'

import {
  isPurposeOfShipmentToFulfillKegOrder,
  getDisplayedHumanReadableStatusOfHistoryItem,
  getShouldHistoryItemObjBeDisplayedInTable,
  getShouldHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm,
  filterHistoryObjectsWithUnrecognizedStatus,
} from '../../util'

import {
  INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_CARRIER,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_SOURCE,
  INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ACTUAL_DELIVERY_DATE,
  INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ESTIMATED_DELIVERY_DATE,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_GOOD_PALLETS,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_BAD_PALLETS,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_FOREIGN_KEGS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/InboundEmptyKegShipmentHistory'


import {
  SHIPMENT_STATUS_PENDING,
  SHIPMENT_STATUS_ALLOCATED,
  SHIPMENT_STATUS_BOOKED,
  SHIPMENT_STATUS_IN_TRANSIT,
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_ACKNOWLEDGED,
  SHIPMENT_STATUS_COMPLETED,
  SHIPMENT_STATUS_CANCELED,
  SHIPMENT_STATUS_ON_HOLD,
  SHIPMENT_STATUS_UNDER_REVIEW,
  SHIPMENT_STATUS_PRE_POST,
  SHIPMENT_STATUS_READY_TO_POST,
  SHIPMENT_STATUS_POSTED,
  SHIPMENT_STATUS_SENT_TO_STAGING,
  SHIPMENT_STATUS_SENT_TO_GP,
  SHIPMENT_STATUS_INVOICED,
  SHIPMENT_STATUS_PARTIAL_POST,
} from '../../../../../../constants/formAndApiUrlConfig/commonConfig'


import {
  CUSTOMER_TYPES_DISTRIBUTOR,
  ITEM_SKU_IDS_GOOD_PALLETS,
  ITEM_SKU_IDS_BAD_PALLETS,
  CUSTOMER_TYPES_WAREHOUSE,
} from '../../../../../../constants'

import {
  parseShipmentType,
} from '../../../../../../utils'


/*
 * *****************************************************************************
 * statuses config
 * *****************************************************************************
*/

// CODE_COMMENTS_60
export const inboundEmptyKegShipmentHistoryStatusesConfig = {
  [SHIPMENT_STATUS_PENDING]: {
    includeShipmentObjsInHistoryTable: shipmentObj => !isPurposeOfShipmentToFulfillKegOrder(shipmentObj),
    renameThisStatusToAnother: false,
    includeStatusAsDropdownOptionInHistoryForm: true,
    displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder: true,
  },
  [SHIPMENT_STATUS_ALLOCATED]: {
    includeShipmentObjsInHistoryTable: false,
    displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder: true,
  },
  [SHIPMENT_STATUS_BOOKED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
    displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder: true,
  },
  [SHIPMENT_STATUS_IN_TRANSIT]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
    displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder: true,
  },
  [SHIPMENT_STATUS_DELIVERED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
    displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder: true,
  },
  [SHIPMENT_STATUS_ACKNOWLEDGED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_COMPLETED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_CANCELED]: {
    includeShipmentObjsInHistoryTable: shipmentObj => !isPurposeOfShipmentToFulfillKegOrder(shipmentObj),
    // In a conversation between Tad and Mac on 7/30/18, Mac was leaning toward
    // not making "Canceled" a dropdown option (thereby making it so that the
    // only time the end user can see canceled shipments is when they choose the
    // 'all' status) but Tad convinced him that this should be a dropdown option
    // because the user can cancel the pending inbound shipments that they
    // reported through the web app (e.g. empty kegs from a Pub back into the
    // brewery), so they should be able to filter for them.
    includeStatusAsDropdownOptionInHistoryForm: true,
  },

  [SHIPMENT_STATUS_ON_HOLD]: {
    // If a shipment that fulfills a keg order is on hold, hide it altogether;
    // if another type of shipment is on hold, show it
    includeShipmentObjsInHistoryTable: shipmentObj => !isPurposeOfShipmentToFulfillKegOrder(shipmentObj),
    renameThisStatusToAnother: shipmentObj => !isPurposeOfShipmentToFulfillKegOrder(shipmentObj),
    // wait, why rename On Hold items to Completed? Because it's pretty unusual
    // that this will happen, and a MicroStar employee will likely be contacting
    // the user about it shortly. Why not just hide the shipment object
    // altogether? Because as a general rule, we don't want to hide shipments
    // that the user has created. It's OK to hide shipments that MicroStar
    // created, but if the user has created the history item, they have a sense
    // of ownership over it that doesn't apply to MicroStar-created history
    // items.
    statusToRenameTo: SHIPMENT_STATUS_COMPLETED,
  },
  [SHIPMENT_STATUS_UNDER_REVIEW]: {
    includeShipmentObjsInHistoryTable: shipmentObj => !isPurposeOfShipmentToFulfillKegOrder(shipmentObj),
    renameThisStatusToAnother: shipmentObj => !isPurposeOfShipmentToFulfillKegOrder(shipmentObj),
    // See the comment on the `statusToRenameTo` prop of COMMON_STATUS_ON_HOLD
    statusToRenameTo: SHIPMENT_STATUS_COMPLETED,
  },
  [SHIPMENT_STATUS_PRE_POST]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_COMPLETED,
  },
  [SHIPMENT_STATUS_READY_TO_POST]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_COMPLETED,
  },
  [SHIPMENT_STATUS_POSTED]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: false,
  },
  [SHIPMENT_STATUS_PARTIAL_POST]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_COMPLETED,
  },
  [SHIPMENT_STATUS_SENT_TO_STAGING]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_COMPLETED,
  },
  [SHIPMENT_STATUS_SENT_TO_GP]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: SHIPMENT_STATUS_COMPLETED,
  },
  [SHIPMENT_STATUS_INVOICED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
}


export const getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable = shipmentObj => (
  getShouldHistoryItemObjBeDisplayedInTable(
    shipmentObj,
    inboundEmptyKegShipmentHistoryStatusesConfig,
    'status',
  )
)

export const getDisplayedHumanReadableStatusOfInboundEmptyKegShipment = shipmentObj => (
  getDisplayedHumanReadableStatusOfHistoryItem(
    shipmentObj,
    inboundEmptyKegShipmentHistoryStatusesConfig,
    'status',
  )
)


// CODE_COMMENTS_145
export const getShouldInboundShipmentHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm = (
  currentlySelectedStatusInHistoryForm,
  shipmentObj,
) => (
  getShouldHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm(
    currentlySelectedStatusInHistoryForm,
    shipmentObj,
    inboundEmptyKegShipmentHistoryStatusesConfig,
    'status',
  )
)


/*
 * *****************************************************************************
 * Full Details Modal
 * *****************************************************************************
*/

// Used by both the Full Details modal and the "Download File As CSV" feature
export const getInfoDefinitionsInboundEmptyKegShipmentFullDetails = ({
  entireItemSkusSlice,
  entireSubsidiariesSlice,
  subsidiaryId,
  itemSkuIdsToIncludeAsFieldsInTable,
  downloadAttachment,
  customerId,
  entireCustomersSlice,
}) => ([
  {
    heading: 'Shipment #',
    cellContent: createDisplayedShipmentNumber,
  },
  {
    heading: 'Order #',
    cellContent: createDisplayedOrderNumber,
  },
  {
    heading: INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
    cellContent: createDisplayedProNumber('none'),
  },
  (entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ? {
    heading: 'Shipment Type',
    cellContent: createDisplayedShipmentType,
  } :
    {
      heading: 'Movement Type',
      cellContent: createDisplayedMovementType,
    }),
  {
    heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_CARRIER,
    cellContent: createDisplayedCarrierName,
  },
  {
    heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_SOURCE,
    cellContent: createDisplayedSourceName,
  },
  {
    heading: 'Ship Date',
    cellContent: createDisplayedShipmentDate,
  },
  {
    heading: INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ACTUAL_DELIVERY_DATE,
    cellContent: createDisplayedActualDeliveryDateForFullDetailsModal,
  },
  {
    heading: INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ESTIMATED_DELIVERY_DATE,
    cellContent: createDisplayedEstimatedDeliveryDateForFullDetailsModal,
  },
  {
    heading: 'Status',
    cellContent: row => getDisplayedHumanReadableStatusOfInboundEmptyKegShipment(row),
  },

  ...(downloadAttachment ?
    createContainerTypesFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
      useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged: true,
    })
    :
    createItemSkuIdFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
      useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged: true,
    })),
  ...(
    getIsSubsidiaryATypeThatShouldTrackForeignKegs({
      entireSubsidiariesSlice,
      subsidiaryId,
    })
      ? [
        {
          heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_FOREIGN_KEGS,
          cellContent: createDisplayedForeignKegs(),
          displayCondition: row => {
            const qty = createDisplayedForeignKegs(row)
            return qty > 0
          },
        },
      ]
      : []
  ),
  ...(
    getIsSubsidiaryATypeThatShouldTrackWoodenPallets({
      entireSubsidiariesSlice,
      subsidiaryId,
    })
      ? [
        {
          heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_GOOD_PALLETS,
          cellContent: row => {
            const goodPalletsLineItem = row.lineItems?.find(
              lineItem => lineItem.itemSkuId === ITEM_SKU_IDS_GOOD_PALLETS,
            )
            return goodPalletsLineItem
              ? goodPalletsLineItem.quantity
              : null
          },
          displayCondition: row => {
            const goodPalletsLineItem = row.lineItems?.find(
              lineItem => lineItem.itemSkuId === ITEM_SKU_IDS_GOOD_PALLETS,
            )
            const qty = goodPalletsLineItem ? goodPalletsLineItem.quantity : null
            return (parseShipmentType(row.movementType)[0] === CUSTOMER_TYPES_DISTRIBUTOR ||
            parseShipmentType(row.movementType)[0] === CUSTOMER_TYPES_WAREHOUSE) && qty
          },
        },
        {
          heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_BAD_PALLETS,
          cellContent: row => {
            const goodPalletsLineItem = row.lineItems?.find(lineItem => lineItem.itemSkuId === ITEM_SKU_IDS_BAD_PALLETS)
            return goodPalletsLineItem
              ? goodPalletsLineItem.quantity
              : null
          },
          displayCondition: row => {
            const goodPalletsLineItem = row.lineItems?.find(lineItem => lineItem.itemSkuId === ITEM_SKU_IDS_BAD_PALLETS)
            const qty = goodPalletsLineItem ? goodPalletsLineItem.quantity : null
            return (parseShipmentType(row.movementType)[0] === CUSTOMER_TYPES_DISTRIBUTOR ||
              parseShipmentType(row.movementType)[0] === CUSTOMER_TYPES_WAREHOUSE) && qty
          },
        },
      ]
      : []
  ),
])


/*
 * *****************************************************************************
 * Misc.
 * *****************************************************************************
*/

export function getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable(
  allInboundEmptyKegShipmentObjects,
) {
  return filterHistoryObjectsWithUnrecognizedStatus( // CODE_COMMENTS_156
    allInboundEmptyKegShipmentObjects,
    inboundEmptyKegShipmentHistoryStatusesConfig,
    'status',
  )
}
