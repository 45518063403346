import React from 'react'

import VerifiedOrErrorMessages from '../../VerifiedOrErrorMessages'
import {
  getIsNotInPendingStatus,
} from '../../Verifiers'


export default props => (
  <VerifiedOrErrorMessages
    verifiers={[
      {
        verifierFunction: getIsNotInPendingStatus,
      },
      // One might think that a distributor must be related from at least one
      // customer, but it turns out that's not the case: Some distributors are
      // so small that they only distribute for one MicroStar brewer, then that
      // brewer shuts down, so they may not have ACTIVE relationships any
      // longer. Therefore there's no need for an 'isRelatedFromAnyone'
      // verifier.
    ]}
    {...props}
  />
)
