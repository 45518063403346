/* eslint-disable max-len */

import React, { useState } from 'react'
import { Form, Button, Icon, Input } from 'semantic-ui-react'


import { Trans, useTranslation } from 'react-i18next'
import CapsLockIsOnWarning from '../../../common-components/CapsLockIsOnWarning'

import {
  getIsStringAValidEmailAddress,
  getInvalidPasswordErrorText,
} from '../../../utils'

import {
  PASSWORD_INPUT_TOO_LONG_ERROR_MSG,
  REDUCER_NAMES_PUBLIC_FORMS_LOGIN,
} from '../../../constants'

import {
  LOGIN_EMAIL_MAX_LENGTH,
} from '../../../config'


const LoginForm = ({
  onLoginSubmit,
  isAuthenticating,
  email: emailFromResetPasswordFormOrConfirmAccountForm, // CODE_COMMENTS_251
  password: passwordFromResetPasswordFormOrConfirmAccountForm, // CODE_COMMENTS_251
}) => {
  const { t: translate } = useTranslation('common')
  const [emailInput, setEmailInput] = useState(emailFromResetPasswordFormOrConfirmAccountForm || '')
  const [emailInputTouched, setEmailInputTouched] = useState(false) // True when the user first enters a value
  const [emailErrorText, setemailErrorText] = useState(null) // only show error message after value has been touched
  const [passwordInput, setPasswordInput] = useState(passwordFromResetPasswordFormOrConfirmAccountForm || '')
  const [passwordInputTouched, setPasswordInputTouched] = useState(false) // True when the user first enters a value
  const [passwordErrorText, setPasswordErrorText] = useState(null) // only show error message after value has been touched

  const onEmailInputChange = e => {
    let emailInputToSave = e.target.value
    let emailErrorText_

    // remove all whitespace
    emailInputToSave = emailInputToSave && emailInputToSave.replace(/ /g, '')

    if (!getIsStringAValidEmailAddress(emailInputToSave)) {
      emailErrorText_ = 'Invalid email address'
    } else {
      emailErrorText_ = null
    }

    if (['', undefined].includes(emailInputToSave)) {
      emailErrorText_ = 'Email address required'
    }

    // max length rule
    if (emailInputToSave.length > LOGIN_EMAIL_MAX_LENGTH) {
      emailInputToSave = emailInput
    }

    setEmailInput(emailInputToSave)
    setEmailInputTouched(true)
    setemailErrorText(emailErrorText_)
  }

  const onPasswordInputChange = e => {
    let pwInput = e.target.value
    let passwordErrorText_ = getInvalidPasswordErrorText(pwInput) // null if pw is valid

    if (['', undefined].includes(pwInput)) {
      passwordErrorText_ = 'Password required'
    }

    // max length rule
    if (passwordErrorText_ === PASSWORD_INPUT_TOO_LONG_ERROR_MSG) {
      pwInput = passwordInput
    }

    setPasswordInput(pwInput)
    setPasswordInputTouched(true)
    setPasswordErrorText(passwordErrorText_)
  }

  const onSubmit = e => {
    e.preventDefault()
    onLoginSubmit(emailInput, passwordInput)
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        // id="login-form"
        id={REDUCER_NAMES_PUBLIC_FORMS_LOGIN}
      >
        <div className="field-with-error-message">
          <Form.Field>
            <Trans ns='common' i18nKey='Email'>
              <label htmlFor="email">Email</label>
            </Trans>
            <Input name="email" type="text" value={emailInput} onChange={onEmailInputChange} />
          </Form.Field>
          {emailErrorText ? <small>{translate(emailErrorText)}</small> : null}
        </div>
        <div className="field-with-error-message">
          <Form.Field>
            <Trans ns='common' i18nKey='Password'>
              <label htmlFor="password">{translate('Password')}</label>
            </Trans>
            <CapsLockIsOnWarning
              translate={translate}
              trigger={<Input name="password" type="password" value={passwordInput} onChange={onPasswordInputChange} />}
            />
          </Form.Field>
          {passwordErrorText ? <small>{translate(passwordErrorText)}</small> : null}
        </div>
        {isAuthenticating
          ? <Button fluid animated loading disabled>{translate('Loading')}</Button>
          : (
            <Button
              fluid
              animated
              disabled={
                Boolean(emailErrorText)
                || Boolean(passwordErrorText)
                || (!emailFromResetPasswordFormOrConfirmAccountForm && !emailInputTouched)
                || (!passwordFromResetPasswordFormOrConfirmAccountForm && !passwordInputTouched)
              }
              type="submit"
            >
              <Button.Content visible>{translate('Submit')}</Button.Content>
              <Button.Content hidden>
                <Icon name="right arrow" />
              </Button.Content>
            </Button>
          )
        }
      </Form>
    </div>
  )
}

export default LoginForm
