/* eslint-disable max-len */
import { put } from 'redux-saga/effects'

import get_ from 'lodash/get'


import historySagaCreator from './historySagaCreator'
import historySagaCreatorMultipleFetchesForOneFormSubmission from './historySagaCreatorMultipleFetchesForOneFormSubmission'

import {
  SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES,
  SAVE_NO_MOVEMENTS_HISTORY,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'

import {
  URL_PARAM_DESTINATION_CUSTOMER_ID,
  URL_PARAM_ORIGIN_CUSTOMER_ID,
  URL_PARAM_SHIPPED_START_DATE,
  URL_PARAM_SHIPPED_END_DATE,
  URL_PARAM_SHIPMENT_STATUS,
  URL_PARAM_SHIPMENT_MOVEMENT_TYPE,
  URL_PARAM_PLANNED_PICKUP_START_DATE,
  URL_PARAM_PLANNED_PICKUP_END_DATE,
  URL_PARAM_END_DATE_PLANNED_OR_ACTUAL,
  URL_PARAM_START_DATE_PLANNED_OR_ACTUAL,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/shipments'

import {
  URL_PARAM_CUSTOMER_ID,
  URL_PARAM_START_DATE,
  URL_PARAM_END_DATE,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/noMovements'

import {
  SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE,
} from '../../../../constants/formAndApiUrlConfig/histories/shipmentHistoryShared'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  COMMON_STATUS_ALL,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  API_URL_PATH_SHIPMENTS_HISTORY,
  API_URL_PATH_GET_NO_MOVEMENTS,
} from '../../../../constants'

import {
  createStatusApiParameterValueForHistoryFetch,
} from './util'


import {
  createHeadersForContracteeBrewerApiCall,
} from '../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  formatDateRangeForApiCall,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  getDateAsMoment,
} from '../../../../utils'


export function shipmentHistorySagaCreator(sagaCreatorProps) {
  const {
    saveAction,
    transformInfoBeforeSaveFunc,
    alsoFetchNoMovements, // for Outbound Full Kegs and Keg Fills history forms
    historyName,
  } = sagaCreatorProps
  if (alsoFetchNoMovements) {
    return historySagaCreatorMultipleFetchesForOneFormSubmission({
      createConfigFunc,
      ...sagaCreatorProps,
    })
  }
  return historySagaCreator({
    apiUrlPath: API_URL_PATH_SHIPMENTS_HISTORY,
    createFetchParamsFunc,
    stillSaveOnErrorFunc,
    transformInfoBeforeSaveFunc,
    saveAction,
    historyName,
  })
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

function createFetchParamsFunc({
  customerId,
  formValues,
  statusesConfig,
  inboundOrOutbound,
  historyName,
}) {
  const { startDate, endDate } = formatDateRangeForApiCall({
    startDate: formValues[HISTORY_FORM_FIELD_NAME_START_DATE],
    endDate: formValues[HISTORY_FORM_FIELD_NAME_END_DATE],
  })

  const urlParamOriginOrDestinationCustomerId = inboundOrOutbound.toLowerCase() === 'inbound'
    ? URL_PARAM_DESTINATION_CUSTOMER_ID
    : URL_PARAM_ORIGIN_CUSTOMER_ID
  return {
    [urlParamOriginOrDestinationCustomerId]: customerId,
    ...(historyName === 'scheduledShipments'
      ? {
        [URL_PARAM_PLANNED_PICKUP_START_DATE]: startDate,
        [URL_PARAM_PLANNED_PICKUP_END_DATE]: endDate,
      }
      : historyName === 'inboundShipments' ?
        {
          [URL_PARAM_START_DATE_PLANNED_OR_ACTUAL]: startDate,
          [URL_PARAM_END_DATE_PLANNED_OR_ACTUAL]: endDate,
        }
        : {
          [URL_PARAM_SHIPPED_START_DATE]: startDate,
          [URL_PARAM_SHIPPED_END_DATE]: endDate,
        }),
    ...(
      // only add a 'status' param if the status chosen is something other than
      // "All". But see CODE_COMMENTS_61
      formValues[HISTORY_FORM_FIELD_NAME_STATUS] === COMMON_STATUS_ALL
        ? {}
        : {
          [URL_PARAM_SHIPMENT_STATUS]: createStatusApiParameterValueForInboundEmptyKegShipmentsHistoryFetch(
            formValues[HISTORY_FORM_FIELD_NAME_STATUS],
            statusesConfig,
          ),
        }
    ),
    ...(
      // only add a 'movementType' param if the shipment type chosen is
      // something other than "All"
      formValues[SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE] === COMMON_STATUS_ALL
        ? {}
        : {
          [URL_PARAM_SHIPMENT_MOVEMENT_TYPE]: formValues[SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE],
        }
    ),
  }
}

// eslint-disable-next-line consistent-return
function stillSaveOnErrorFunc(error) {
  if (error.response.status === 404) { // no orders within this date range
    return []
  }
}


function createStatusApiParameterValueForInboundEmptyKegShipmentsHistoryFetch(
  statusChosenByUserInHistoryForm,
  statusesConfig,
) {
  return createStatusApiParameterValueForHistoryFetch(
    statusChosenByUserInHistoryForm,
    statusesConfig,
  )
}


/*
 * *****************************************************************************
 * For calls that also require a NoMovements call
 * *****************************************************************************
*/

// CODE_COMMENTS_141
function createConfigFunc(props) {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = props

  const fetchDefinitionForShipmentsHistory = {
    fetchConfig: {
      path: API_URL_PATH_SHIPMENTS_HISTORY,
      params: createFetchParamsFunc(props),
      headers: operatingContractBrewerCustomerId
        ? createHeadersForContracteeBrewerApiCall(customerId)
        : undefined,
    },
    // no doSuccess here: instead, the data is saved in doAfterAllCallsHaveFinished
    doFailure: ({ error }) => {
      // no shipments within this date range
      if (get_(error, ['response', 'status']) === 404) {
        return []
      }
      return undefined
    },
  }

  const { startDate, endDate } = getStartAndEndDatesForNoMovementsCall(props)
  const fetchDefinitionsForNoMovementsHistory = {
    fetchConfig: {
      path: API_URL_PATH_GET_NO_MOVEMENTS,
      params: {
        [URL_PARAM_CUSTOMER_ID]: customerId,
        [URL_PARAM_START_DATE]: startDate,
        [URL_PARAM_END_DATE]: endDate,
      },
      headers: operatingContractBrewerCustomerId
        ? createHeadersForContracteeBrewerApiCall(customerId)
        : undefined,
    },
    // no doSuccess here: instead, the data is saved in doAfterAllCallsHaveFinished
    doFailure: ({ error }) => {
      // no NoMovements reports within this date range
      if (get_(error, ['response', 'status']) === 404) {
        return []
      }
      return undefined
    },
    failureShouldNotResultInTheFailureOfTheEntireSaga: true,
  }

  // This is how to get the fetches to run in parallel; see CODE_COMMENTS_141
  const fetchDefinitions = [[
    fetchDefinitionForShipmentsHistory,
    fetchDefinitionsForNoMovementsHistory,
  ]]

  return {
    fetchDefinitions,
    doAfterAllCallsHaveFinished,
  }
}

// When the user searches for shipments, they choose a specific day for both the
// start and end date. However, when searching for NoMovements to show, we want
// to search for entire months. Therefore, we extend the date ranges of the
// search to be: Beginning of the month UTC of the start date to end of the
// month UTC of the end date. UTC is very important here, because dates of
// NoMovements reports are stored in the database as simply "July 1, 2020", yet
// the url search parameters require a Unix timestamp, so we want our timestamps
// to be as unequivocal as possible in what month we're targeting.
function getStartAndEndDatesForNoMovementsCall({
  formValues,
}) {
  const startDateAsMoment = getDateAsMoment({
    date: formValues[HISTORY_FORM_FIELD_NAME_START_DATE],
    useUtcInsteadOfLocalTime: true,
  })
  const endDateAsMoment = getDateAsMoment({
    date: formValues[HISTORY_FORM_FIELD_NAME_END_DATE],
    useUtcInsteadOfLocalTime: true,
  })
  const startOfStartMonth = startDateAsMoment.startOf('month')
  const endOfEndMonth = endDateAsMoment.endOf('month')
  return formatDateRangeForApiCall({
    startDate: startOfStartMonth,
    endDate: endOfEndMonth,
    useUtcInsteadOfLocalTime: true,
  })
}

// Why are we waiting to save all data until both the Shipments and NoMovements
// calls have finished? Imagine the NoMovements call takes several seconds
// longer to finish than the Shipments call: if we save shipments immediately,
// the History table will update with the new info but the Submit button on the
// form will keep showing a loading spinner until NoMovements is finished. This
// is no good: the form should show it's finished fetching at the same moment
// that the table gets updated with new info.
function* doAfterAllCallsHaveFinished({
  // an array of arrays of objects:
  // [
  //   [
  //     { didFetchSucceed: true, response: <response> }, // shipments call
  //     { didFetchSucceed: true, response: <response> }, // NoMovements call
  //   ]
  // ]
  responses,
  customerId,
  operatingContractBrewerCustomerId,
  formName,
  formValues,
  saveAction,
}) {
  const shipmentsResponse = responses[0][0]
  const noMovementsResponse = responses[0][1]

  if (shipmentsResponse.didFetchSucceed) {
    const { response } = shipmentsResponse
    const info = response.data
    yield put(createAction(
      saveAction,
      {
        savePath: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
        info,
      }))
    // CODE_COMMENTS_79
    yield put(createAction(
      SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES,
      {
        formName,
        formValues,
      },
    ))
  }

  if (noMovementsResponse.didFetchSucceed) {
    const { response } = noMovementsResponse
    const info = response.data
    yield put(createAction(
      SAVE_NO_MOVEMENTS_HISTORY,
      {
        savePath: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
        info,
      }))
  }
}
