import React from 'react'
import { Message, Button } from 'semantic-ui-react'

import { Trans } from 'react-i18next'
import RevealContactInfo from '../RevealContactInfo'
import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../constants'

export default ({ customerId, refetch }) => (
  <Message compact negative style={{ width: '400px' }}>
    <p>
      <Trans ns="common" i18nKey="We're sorry, the app failed to fetch some crucial pieces of information needed to show this page. Double check your internet connection, then click the Retry button below to re-fetch the information. If the problem persists, contact your personalized representative.">
        {/* eslint-disable-next-line max-len */}
        {"We're sorry, the app failed to fetch some crucial pieces of information needed to show this page. Double check your internet connection, then click the Retry button below to re-fetch the information. If the problem persists, contact your personalized representative."}
      </Trans>
    </p>
    <Button
      color="blue"
      onClick={refetch}
    >
      <Trans ns="common" i18nKey="Retry">
        Retry
      </Trans>
    </Button>
    <div>
      <RevealContactInfo
        customerId={customerId}
        repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
      />
    </div>
  </Message>
)
