import React from 'react'
import { Dimmer, Loader, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import DefaultFailureAndSuccessButtons from './DefaultFailureAndSuccessButtons'
import RevealContactInfo from '../../../RevealContactInfo'

import {
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../../constants'


import './dimmer-overlay.css'


export default props => {
  const {
    customerId,
    isSubmitting,
    didSubmitSucceed,
    didSubmitFail,
    successMessage,
    onResetForm,
    dontActuallyApplyTheFormSubmissionDimmerOverlay,
    children,
    dontShowAnySuccessButtons,
    dontShowAnyFailureButtons,
    replacementForDefaultSuccessButtons: ReplacementForDefaultSuccessButtons,
    replacementForDefaultFailureButtons: ReplacementForDefaultFailureButtons,
    formSubmissionScrollRef, // CODE_COMMENTS_274
    kegOrderValidationMessage,
  } = props
  const { t: translate } = useTranslation('common')
  if (dontActuallyApplyTheFormSubmissionDimmerOverlay) {
    return children
  }

  const active = isSubmitting || didSubmitSucceed || didSubmitFail
  return (
    <Dimmer.Dimmable dimmed={active} className="form-submission-dimmer">
      <Dimmer
        active={active}
        onClickOutside={null}
        // Increase the darkness of the dimmer from Semantic UI's default (white
        // text on top of dimmed text is too hard to read without darkening this
        // a little): https://github.com/Semantic-Org/Semantic-UI/issues/1897
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.925)' }}
      >
        <div ref={formSubmissionScrollRef}>
          {isSubmitting &&
            <Loader size="big">{translate('Submitting')}</Loader>
          }
          {didSubmitSucceed && !isSubmitting &&
            <div>
              <Header as="h2" inverted color="green">
                {translate('Success!')}
              </Header>
              {successMessage}
              {!dontShowAnySuccessButtons && (
                <div style={{ marginTop: '20px' }}>
                  {ReplacementForDefaultSuccessButtons
                    ? <ReplacementForDefaultSuccessButtons onResetForm={onResetForm} {...props} />
                    : <DefaultFailureAndSuccessButtons onResetForm={onResetForm} {...props} />
                  }
                </div>
              )}
            </div>
          }
          {didSubmitFail && !isSubmitting &&
            <div>
              <Header as="h2" inverted color="red">
                {translate('Submission Failed')}
              </Header>
              <div>
                <p>{kegOrderValidationMessage?
                    translate(kegOrderValidationMessage):
                    translate(DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR)}</p>
                {!dontShowAnyFailureButtons && (
                  <div style={{ marginTop: '20px' }}>
                    {ReplacementForDefaultFailureButtons
                      ? <ReplacementForDefaultFailureButtons onResetForm={onResetForm} {...props} />
                      : <DefaultFailureAndSuccessButtons onResetForm={onResetForm} {...props} />
                    }
                  </div>
                )}
                <div>
                  <RevealContactInfo
                    customerId={customerId}
                    repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
                    asPopup
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </Dimmer>
      {children}
    </Dimmer.Dimmable>
  )
}
