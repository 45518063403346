import React from 'react'


import ContactCard from '../../../common-components/ContactCard'


import {
  UNIVERSAL_CUSTOMER_REPS_ACCOUNTS_RECEIVABLE,
} from '../../../constants'

import {
  TEMPORARILY_DISABLE_PAY_BALANCES_FEATURE,
} from '../../../config'


export default Component => props => {
  if (!TEMPORARILY_DISABLE_PAY_BALANCES_FEATURE) {
    return <Component {...props} />
  }
  return (
    <div>
      <p>{'Online payments are temporarily disabled. To make a payment over the phone, please contact the Accounts Receivable Department:'}</p>
      <ContactCard
        repType={UNIVERSAL_CUSTOMER_REPS_ACCOUNTS_RECEIVABLE}
      />
    </div>
  )
}
