import { connect } from 'react-redux'


import fetchStatusSelectors from '../../../../../../redux/selectors/fetchStatuses/customers/reportedVsCalculatedInventory'
import {
  getReportedVsCalculatedInventoryDataForCustomer,
} from '../../../../../../redux/selectors/reportedInventoryVsCalculatedInventory'


import ReportedVsCalculatedInventoryCardCustomContent from './components'

function mapStateToProps(state, { customerId }) {
  const { getFetchStatuses } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(state, customerId)
  const {
    didFetchSucceed,
  } = fetchStatuses

  const reportedVsCalculatedInventoryDataForCustomer = didFetchSucceed
    ? getReportedVsCalculatedInventoryDataForCustomer(state, customerId)
    : null

  return {
    ...fetchStatuses,
    reportedVsCalculatedInventoryDataForCustomer,
  }
}

export default connect(
  mapStateToProps,
)(ReportedVsCalculatedInventoryCardCustomContent)
