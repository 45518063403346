/**
 * This wrapper component's purpose is to redirect users to their original
 * destination after they log in:
 * https://reacttraining.com/react-router/web/example/auth-workflow
 */
import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'

import get_ from 'lodash/get'


import LoginSection from './containers/LoginSection'
import withIsAuthenticated from '../../common-components/HOC/redux/withIsAuthenticated'
import {
  URL_PATHS,
} from '../../constants'
import LanguageSelection from '../../common-components/Internationalization'

export default withIsAuthenticated(withRouter(
  ({
    isAuthenticated,
    location,
  }) => {
    let from = get_(location, 'state.from.pathname')
    // if the URL the user typed in was mysite.com/login rather than just
    // mysite.com, we want to redirect them to the main page after they log in
    if (from === URL_PATHS.login.path) {
      from = { pathname: URL_PATHS.root.path }
    } else {
      from = from || { pathname: URL_PATHS.root.path }
    }

    return isAuthenticated
      ? <Redirect to={from} />
      : (
        <>
          <LoginSection
            // CODE_COMMENTS_251: If we were redirected to the login page from the
            // forgot-password or confirm-account success pages, then location.state
            // was set to { email, password } in the
            // src/features/ForgotPasswordAndConfirmAccount/shared/SuccessPage.js
            // page, and we want to pass this email and password into the login form
            // so that it can auto-set the input fields.
            {...(get_(location, 'state') || {})}
          />
          <LanguageSelection />
        </>
      )
  },
))
