import React from 'react'

import Form from './Form'
import Table from './Table'

export default props => (
  <div>
    <Form {...props} />
    <Table {...props} />
  </div>
)
