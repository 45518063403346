/* eslint-disable max-len */

// CODE_COMMENTS_282

import { REHYDRATE } from 'redux-persist/lib/constants'


import {
  REDUCER_NAMES_LATEST_WEB_APP_VERSION,
  REDUCER_NAMES_LATEST_WEB_APP_VERSION_STRING,
  REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH,
} from '../../../constants'

// import latestWebAppVersion from './latestWebAppVersion'
// import numHardRefreshesByWebAppVersionAttemptingToFetch from './numHardRefreshesByWebAppVersionAttemptingToFetch'

// const latestWebAppVersionReducers = {
//   [REDUCER_NAMES_LATEST_WEB_APP_VERSION_STRING]: latestWebAppVersion,
//   [REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH]: numHardRefreshesByWebAppVersionAttemptingToFetch,
// }
// export default combineReducers(latestWebAppVersionReducers)

import {
  INCREASE_BY_ONE_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH,
  CLEAR_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH,
  SAVE_LATEST_WEB_APP_VERSION_STRING,
} from '../../actions/actionTypes'

import {
  validatePersistedState,
} from '../util/persistedState'

const initialStateLastestWebAppVersionString = null
// This is needed by
// src/redux/sagas/downloadLatestWebAppVersion/hardRefreshThreadToDownloadLatestWebAppVersion.js
export const initialStateNumHardRefreshesByWebAppAtemptingToFetch = {}

export const initialState = {
  [REDUCER_NAMES_LATEST_WEB_APP_VERSION_STRING]: initialStateLastestWebAppVersionString,
  [REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH]: initialStateNumHardRefreshesByWebAppAtemptingToFetch,
}

// We can't split this up into multiple reducers because we need to persist it
// to local storage with redux-persist, and we can't write a REHYDRATE case if
// this is split up into multiple sub-reducers (see CODE_COMMENTS_3)
export default function latestWebAppVersion(state=initialState, action) {
  switch (action.type) {
    case INCREASE_BY_ONE_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH: {
      const { webAppVersion } = action.payload
      const slice = state[REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH]
      const toReturn = {
        ...state,
        [REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH]: {
          ...slice,
          [webAppVersion]: (slice[webAppVersion] || 0) + 1,
        },
      }
      return toReturn
    }

    case CLEAR_THE_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH: {
      return {
        ...state,
        [REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH]: initialStateNumHardRefreshesByWebAppAtemptingToFetch,
      }
    }

    case SAVE_LATEST_WEB_APP_VERSION_STRING: {
      return {
        ...state,
        [REDUCER_NAMES_LATEST_WEB_APP_VERSION_STRING]: action.payload,
      }
    }

    // CODE_COMMENTS_3
    case REHYDRATE: {
      const persistedState = action.payload
      const isPersistedStateProperlyStructured = validatePersistedState(persistedState)
      if (!isPersistedStateProperlyStructured) {
        return { ...state } // CODE_COMMENTS_8
      }
      if (!persistedState) {
        return { ...state }
      } // CODE_COMMENTS_8
      const persistedLatestWebAppVersionSlice = persistedState[REDUCER_NAMES_LATEST_WEB_APP_VERSION]
      // if this slice of store has never been persisted before (which would
      // happen if, for example, this is the first time a user has ever visited
      // the site), don't run any more code in this REHYDRATE case, because the
      // subsequent code assumes that the persisted slice has stuff in it
      if (!persistedLatestWebAppVersionSlice) {
        return { ...state } // CODE_COMMENTS_8
      }
      return persistedLatestWebAppVersionSlice
    }

    default:
      return state
  }
}
