
/**
 * CODE_COMMENTS_31
 */

import {
  ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET,
  ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
} from '../index'

export const HOW_MANY_YEARS_BACK_CAN_INVENTORY_BE_REPORTED = 3
// Ideally users will count their inventory on the very last day of the month:
// every brewer counts December inventory on December 31st. Of course, that's
// not how it works in practice (after all, no one's working on Dec. 31st, New
// Years Eve). The form needs to be flexible enough to allow users to count
// December inventory on Dec. 28th or on January 1st. However, we can't be so
// flexible that we allow users to count December inventory in July the next
// year. These variables restrict the number of days before and after the end of
// the month users can claim they counted inventory.
export const FIRST_DAY_OF_MONTH_REPORTING_FOR_USER_CAN_SAY_THEY_MADE_THE_COUNT = 1
export const LAST_DAY_OF_MONTH_AFTER_MONTH_REPORTING_FOR_USER_CAN_SAY_THEY_MADE_THE_COUNT = 1


export const API_INVENTORY_TYPE_EMPTY = 'EMP'
export const API_INVENTORY_TYPE_FULL = 'FUL'
export const API_INVENTORY_TYPE_OFFSITE_WAREHOUSE = 'OSW'
export const API_INVENTORY_TYPE_DEFECTIVE = 'DFF'
export const API_INVENTORY_TYPE_TOTAL = 'TTL' // for brewpubs
export const API_INVENTORY_TYPE_TOTAL_SHIPPED = 'TTL_OUT'
export const API_INVENTORY_TYPE_TOTAL_RECEIVED = 'TTL_IN'
export const API_INVENTORY_TYPE_TOTAL_FILLED = 'TTL_FILLED'


export const FIELD_NAME_MONTH_REPORTING_FOR = 'month-reporting-for'
export const FIELD_NAME_REPORTING_CUSTOMER_NAME = 'customer-name'
export const FIELD_NAME_REPORTING_PERSON = 'reporting-person'
export const FIELD_NAME_COUNTED_BY = 'counted-by'
export const FIELD_NAME_DATE_COUNTED = 'date-counted'
export const FIELD_NAME_COMMENTS = 'comments'
export const FIELD_NAME_DATE_REPORTED = 'date-reported'

export const FIELD_NAME_TOTAL_KEGS_FOREIGN = 'foreign'
export const FIELD_NAME_EMPTY_CPP = `empty-${ITEM_SKU_IDS_CBI_PLASTIC_PALLET}`
export const FIELD_NAME_EMPTY_CCP = `empty-${ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET}`

export const FIELD_LABEL_MONTH_REPORTING_FOR = 'Reporting For Month'
export const FIELD_LABEL_REPORTING_CUSTOMER_NAME = 'Customer Name'
export const FIELD_LABEL_REPORTING_PERSON = 'Reporting Person'
export const FIELD_LABEL_COUNTED_BY = 'Counted By'
export const FIELD_LABEL_DATE_COUNTED = 'Date Counted'
export const FIELD_LABEL_COMMENTS = 'Comments'
export const FIELD_LABEL_DATE_REPORTED = 'Date Reported'

export const FIELD_LABEL_TOTAL_KEGS_FOREIGN = 'Foreign Kegs (All Keg Types Combined)'
export const FIELD_LABEL_EMPTY_CPP = 'Constellation Plastic - KEG - Pallets'
export const FIELD_LABEL_EMPTY_CCP = 'Constellation Plastic - CASE - Pallets'

export const FIELD_LABEL_TOTAL_KEGS_NO_PUBS_EMPTY = 'Empty'
export const FIELD_LABEL_TOTAL_KEGS_NO_PUBS_FULL = 'Full'
export const FIELD_LABEL_TOTAL_KEGS_NO_PUBS_OFFSITE_WAREHOUSE = 'Offsite Warehouse'
export const FIELD_LABEL_TOTAL_KEGS_NO_PUBS_DEFECTIVE = 'Defective'
export const FIELD_LABEL_TOTAL_KEGS_NO_PUBS_UNSORTED = 'Unsorted'
export const FIELD_LABEL_TOTAL_KEGS_NO_PUBS_SORTED = 'Sorted'
export const FIELD_LABEL_TOTAL_KEGS_NO_PUBS_CLEAN = 'Clean'


export const API_INVENTORY_TYPE_DEFAULT_ORDER = [
  API_INVENTORY_TYPE_EMPTY,
  API_INVENTORY_TYPE_FULL,
  API_INVENTORY_TYPE_OFFSITE_WAREHOUSE,
  API_INVENTORY_TYPE_DEFECTIVE,
  API_INVENTORY_TYPE_TOTAL,
  API_INVENTORY_TYPE_TOTAL_SHIPPED,
  API_INVENTORY_TYPE_TOTAL_RECEIVED,
  API_INVENTORY_TYPE_TOTAL_FILLED,
]

export const API_INVENTORY_TYPE_TO_FIELD_LABEL_MAP = {
  [API_INVENTORY_TYPE_EMPTY]: FIELD_LABEL_TOTAL_KEGS_NO_PUBS_EMPTY,
  [API_INVENTORY_TYPE_FULL]: FIELD_LABEL_TOTAL_KEGS_NO_PUBS_FULL,
  [API_INVENTORY_TYPE_OFFSITE_WAREHOUSE]: FIELD_LABEL_TOTAL_KEGS_NO_PUBS_OFFSITE_WAREHOUSE,
  [API_INVENTORY_TYPE_DEFECTIVE]: FIELD_LABEL_TOTAL_KEGS_NO_PUBS_DEFECTIVE,
}
export const FIELD_LABEL_TO_API_INVENTORY_TYPE_MAP = Object.entries(API_INVENTORY_TYPE_TO_FIELD_LABEL_MAP).reduce(
  (acc, [typeId, fieldLabel]) => ({
    ...acc,
    [fieldLabel]: typeId,
  }),
  {},
)

export const TABLE_LABEL_DATE_REPORTED = FIELD_LABEL_DATE_REPORTED


/*
 * *****************************************************************************
 * Misc.
 * *****************************************************************************
*/

export const FORM_NOT_SUBMITTABLE_EXPLANATION_NON_DISTRIBUTORS = 'All keg quantity fields must be filled in, even if with a 0'
export const FORM_NOT_SUBMITTABLE_EXPLANATION_DISTRIBUTORS = 'At least one keg quantity field must be filled in, even if with a 0'
