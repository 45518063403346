import { combineReducers } from 'redux'

import {
  REDUCER_NAMES_FETCH_STATUSES_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS,
} from '../../../constants'

import fetchIndividualCustomerUserAndItsPermissionsReducer from './individualCustomerUserAndItsPermissions'

export default combineReducers({
  [REDUCER_NAMES_FETCH_STATUSES_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS]:
    fetchIndividualCustomerUserAndItsPermissionsReducer,
})
