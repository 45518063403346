// Sometimes you want to put text or other arbitrary content to the right of a
// form field. This component allow you to do that.
import React from 'react'

export default ({ children }) => (
  <div style={{
    display: 'inline',
    marginLeft: '10px',
  }}
  >
    <div style={{
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    }}
    >
      {children}
    </div>
  </div>
)
