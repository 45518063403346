/* eslint-disable max-len */

/**
 * The code functions in this file are used by these components:
 * Report Inventory (Brewer and Conbrw), Edit Inventory Report (Brewer and
 * Conbrw), and Display Inventory Report Full Details (the popup within the
 * history table) (Brewer and Conbrw). Some are also used by the Report
 * Inventory saga and Edit Inventory Report saga.
 */

import moment from 'moment'

import get_ from 'lodash/get'
import flatten_ from 'lodash/flatten'

import flow_ from 'lodash/fp/flow'
import valuesFp_ from 'lodash/fp/values'
import mapFp_ from 'lodash/fp/map'
import filterFp_ from 'lodash/fp/filter'
import flattenFp_ from 'lodash/fp/flatten'

import values_ from 'lodash/values'
import uniq_ from 'lodash/uniq'
import sortBy_ from 'lodash/sortBy'


import {
  getCurrentUserFullName,
} from '../../../redux/selectors/rewrite/currentUser'
import {
  getMultipleCustomerPropsOfAllChildrenFilteredByCustomerType,
} from '../../../redux/selectors/rewrite/children'
import {
  getContracts,
} from '../../../redux/selectors/rewrite/contracts'
import {
  getWhichContractBrewersDoesBrwHaveCustomerLvlPermissionsToReportInventoryFor,
  getWhichContracteesDoesConbrwHaveCustomerLvlPermissionsToReportInventoryFor,
} from '../../../redux/selectors/rewrite/permissionsAtTheCustomerLevel'
import {
  getHasReportInventoryPermissions,
} from '../../../redux/selectors/rewrite/forms/reportInventory'
import {
  getItemSkusDefaultSortOrder,
} from '../../../redux/selectors/rewrite/itemSkus'

import {
  INVENTORY_REPORT_OBJS,
  INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ,
  INVENTORY_REPORT_OBJS_CBMST_REPORT_OBJ,
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ,
  INVENTORY_REPORT_OBJS_CONTRACT_BREWERS,
  INVENTORY_REPORT_OBJS_PUBS,

  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DOES_NOT_REPORT_INVENTORY,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE, CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_MAINTENANCE_FACILITY,
} from './constants'

import {
  FIELD_NAME_MONTH_REPORTING_FOR,

  FIRST_DAY_OF_MONTH_REPORTING_FOR_USER_CAN_SAY_THEY_MADE_THE_COUNT,
  LAST_DAY_OF_MONTH_AFTER_MONTH_REPORTING_FOR_USER_CAN_SAY_THEY_MADE_THE_COUNT,
  HOW_MANY_YEARS_BACK_CAN_INVENTORY_BE_REPORTED,
} from '../../../constants/formAndApiUrlConfig/reportInventory'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_PUB,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  PPF_CONTRACT_TYPES_BRW,

  ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG,
  CUSTOMER_TYPES_WAREHOUSE,
  CUSTOMER_TYPES_MAINTENANCE_FACILITY,
} from '../../../constants'

import {
  convertMonthAndYearStringToMoment,
  isTruthyAndNonEmpty,
  getAreFormValuesSameAsInitialValues,
  isStringNonZeroPaddedPositiveOrNegativeInteger,
  removeBusinessUnitPrependedToCustomerName,
  getMostFrequentItemInArray,
  sortArrayByTemplateArray,
} from '../../../utils'


/*
 * *****************************************************************************
 * Dynamic Field Names
 * *****************************************************************************
 */

// We don't want this to be the same as our CONCATENATED_STRING_SEPARATOR used
// in creating redux identifiers, otherwise the parsers will get mixed up.
const UNIQUE_CONCATENATED_STRING_SEPARATOR = '__^^__'

export function createDynamicFormFieldName(reduxIdentifier, apiInventoryType, itemSkuId) {
  return `${reduxIdentifier}${UNIQUE_CONCATENATED_STRING_SEPARATOR}${apiInventoryType}${UNIQUE_CONCATENATED_STRING_SEPARATOR}${itemSkuId}`
}


export function parseDynamicFormFieldName(fieldName) {
  if (!fieldName.includes(UNIQUE_CONCATENATED_STRING_SEPARATOR)) { return undefined }
  const [reduxIdentifier, apiInventoryType, itemSkuId] = fieldName.split(UNIQUE_CONCATENATED_STRING_SEPARATOR)
  return { reduxIdentifier, apiInventoryType, itemSkuId }
}


/*
 * *****************************************************************************
 * Item SKU IDs
 * *****************************************************************************
*/

export function determineItemSkuIdsToIncludeInInventoryReportFullDetailsOrEditForm({
  entireItemSkusSlice,
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
}) {
  const flatArrayOfInventoryReportObjs = extractFlatArrayOfAllIndividualInventoryReportObjs({ [INVENTORY_REPORT_OBJS]: inventoryReportObjs })
  const arrayOfArrays = getAllItemSkuIdsOfAllLineItemObjectsOfAllInventoryReports({
    flatArrayOfInventoryReportObjs,
    entireItemSkusSlice,
  })
  const arrayPotentiallyWithForeign = uniq_(flatten_(arrayOfArrays))
  // We handle foreign kegs seperately in forms/full details renders
  return arrayPotentiallyWithForeign.filter(itemSkuId => itemSkuId !== ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG)
}


// Helper functions for this section

function extractFlatArrayOfAllIndividualInventoryReportObjs({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
}) {
  return flow_(
    valuesFp_, // Convert the object of arrays of inventoryObjects to an array of arrays
    mapFp_(value => value), // remove undefined values
    flattenFp_, // Convert array of arrays to single array
  )(inventoryReportObjs)
}

/*
 * *****************************************************************************
 * Gather all Inventory Report Objects
 * *****************************************************************************
*/

// Returns an array of { id, name } objects, one for each inventory report
// passed in. Returns an empty array if inventoryReportObjs is empty.
export function getNamesAndIdsOfCustomersFromInventoryReportObjects({
  arrayOfInventoryReportObjs, // flat array, different than INVENTORY_REPORT_OBJS
  entireCustomersSlice,
}) {
  return (
    arrayOfInventoryReportObjs.map(inventoryReport => ({
      id: inventoryReport.customerId,
      name: get_(entireCustomersSlice, [inventoryReport.customerId, 'name']),
    }))
  )
}

// returns an array of inventory report objects
export function getInventoryReportObjectOfEachCustomerForThisCountDate({
  entireInventoryHistorySlice,
  // why is this reduxIdentifiers rather than customerIds? Indeed this might be
  // an array of just customerIds, but it might also include customerId +
  // operatingContractBrewerCustomerId strings; see CODE_COMMENTS_196 and
  // CODE_COMMENTS_212 for details.
  reduxIdentifiers,
  countDate, // unix timestamp
}) {
  return flow_(
    // first, get all inventory reports of each customer, not yet filtered by
    // any time period. This will give us an array of inventory report objects
    // in this shape:
    // [
    //   {
    //     inventoryReportId1: {comments: "blah", ...},
    //     inventoryReportId2: {comments: "blah", ...},
    //   },
    //   {
    //     inventoryReportId3: {comments: "blah", ...},
    //     inventoryReportId4: {comments: "blah", ...},
    //   },
    //   ...
    // ]
    mapFp_(reduxIdentifier => get_(entireInventoryHistorySlice, [reduxIdentifier])),
    // get rid of any empty objects
    filterFp_(allInventoryReportsOfOneCustomer => isTruthyAndNonEmpty(allInventoryReportsOfOneCustomer)),
    // pick out only the inventory objects that are for the countDate passed
    // in. This will leave us with an array of inventory objects and undefined:
    // [
    //   {comments: "blah", ...},
    //   undefined,
    //   {comments: "blah", ...},
    //   ...
    // ]
    mapFp_(allInventoryReportsOfOneCustomer => values_(allInventoryReportsOfOneCustomer).find(
      inventoryReportObj => inventoryReportObj.countDate === countDate,
    )),
    // Get rid of all undefined values
    filterFp_(inventoryReportObj => inventoryReportObj),
  )(reduxIdentifiers)
}


// As in, most representative of the Customer most closely associated with the
// inventory report. See CODE_COMMENTS_215
export function getMostRepresentativeInventoryReportObj({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
}) {
  return (
    // A BRW with a default brewing contract (not all BRWs have a default
    // brewing contract; see below)
    inventoryReportObjs[INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ]
    // CONBRW customers always have a CBMST contract
    || inventoryReportObjs[INVENTORY_REPORT_OBJS_CBMST_REPORT_OBJ]
    // Distributors don't have default contracts (they don't have _any_
    // contracts)
    || inventoryReportObjs[INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ]
    // A Brewer with no Default contract who reports for one or more of its
    // Conbrw contracts.
    || (
      inventoryReportObjs[INVENTORY_REPORT_OBJS_CONTRACT_BREWERS]
      && inventoryReportObjs[INVENTORY_REPORT_OBJS_CONTRACT_BREWERS][0]
    )
    // A Brewer with no Default contract who doesn't report for any of its
    // Conbrw contracts and is only responsible for reporting for child pubs
    || (
      inventoryReportObjs[INVENTORY_REPORT_OBJS_PUBS]
      && inventoryReportObjs[INVENTORY_REPORT_OBJS_PUBS][0]
    )
  )
}


/*
 * *****************************************************************************
 * Is Form Submittable
 * *****************************************************************************
*/

export function getAreInventoryReportFormValuesSameAsInitialValues({
  formValues,
  initialValues,
}) {
  if (!formValues) { return false } // form has not yet been rendered

  return getAreFormValuesSameAsInitialValues(
    formValues,
    initialValues,
  )
}


export function getIsKegQtyFormValueFilledOut(value) {
  // if value is undefined, which can happen for several reasons: the user
  // hasn't yet entered any value or enters a value then deletes it
  if (!value && value !== 0) {
    return false
  }
  const stringValue = value.toString()
  // All kegQuantityFields must be either a positive number, negative
  // number or 0; for instance, they can't be '-'
  if (
    !isStringNonZeroPaddedPositiveOrNegativeInteger(stringValue) &&
    !stringValue === '0'
  ) { return false }
  return true
}


/*
 * *****************************************************************************
 * Top section
 * *****************************************************************************
 */

export function createMonthReportingForRangeMin() {
  return moment().subtract(HOW_MANY_YEARS_BACK_CAN_INVENTORY_BE_REPORTED, 'years')
}

export function createMonthReportingForRangeMax() {
  return moment()
}


export function getReportingForMinAndMaxDates({
  formValues,
}) {
  const currentlySelectedMonthReportingFor = currentlySelectedMonthReportingForInMoment({
    formValues,
  })
  let dateCountedMinDate = null
  let dateCountedMaxDate = null
  let dateCountedOpenToDate = null
  if (currentlySelectedMonthReportingFor) {
    dateCountedOpenToDate = currentlySelectedMonthReportingFor.clone().endOf('month')

    // info needed by the "date Inventory Counted" form field
    dateCountedMinDate = currentlySelectedMonthReportingFor.clone().startOf('month').add(
      FIRST_DAY_OF_MONTH_REPORTING_FOR_USER_CAN_SAY_THEY_MADE_THE_COUNT - 1,
      'days',
    )

    dateCountedMaxDate = currentlySelectedMonthReportingFor.clone().endOf('month').add(
      LAST_DAY_OF_MONTH_AFTER_MONTH_REPORTING_FOR_USER_CAN_SAY_THEY_MADE_THE_COUNT,
      'days',
    )
    if (dateCountedMaxDate > moment()) { dateCountedMaxDate = moment() }
  }
  return [dateCountedMinDate, dateCountedMaxDate, dateCountedOpenToDate]
}


function currentlySelectedMonthReportingForInMoment({
  formValues,
}) {
  if (!formValues) { return undefined } // form has never been rendered before
  const currentlySelectedValue = formValues[FIELD_NAME_MONTH_REPORTING_FOR]
  if (!currentlySelectedValue) { return undefined }
  return convertMonthAndYearStringToMoment(currentlySelectedValue)
}


/*
 * *****************************************************************************
 * "Customer Type" for Report Inventory Forms (see CODE_COMMENTS_213)
 * *****************************************************************************
 */

// CODE_COMMENTS_213: This is for reporting only, not editing or displaying full
// details. The corresponding function for editing/full details is in the
// src/features/History/individualTabs/InventoryHistory/util/groupingInventoryReportObjs.js
// file, it's called determineCustomerTypeForEditInventoryReport
export function determineCustomerTypeForReportInventory({
  entireCustomersSlice,
  entireContractsSlice,
  entireParentChildLinksSlice,
  entireRelationshipsSlice,
  customerId,
  customerType,
  isDistReportInventoryNoLoginRequired, // CODE_COMMENTS_276
  isDistReportConstellationNoLoginRequired,
}) {
  if (isDistReportInventoryNoLoginRequired) {
    return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED
  }
  if (isDistReportConstellationNoLoginRequired) {
    return CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED
  }
  if (customerType === CUSTOMER_TYPES_BREWER) {
    const hasActiveBrwPpfContract = isTruthyAndNonEmpty(getContracts({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      ppfContractTypes: PPF_CONTRACT_TYPES_BRW,
      notExpiredOnly: false, // CODE_COMMENTS_268
      activeStatusOnly: true,
      noFutureContracts: true,
    }))
    const canReportOnConbrwContracts = getWhichContractBrewersDoesBrwHaveCustomerLvlPermissionsToReportInventoryFor({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
    }).length > 0
    if (hasActiveBrwPpfContract && canReportOnConbrwContracts) {
      return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS
    }
    if (hasActiveBrwPpfContract && !canReportOnConbrwContracts) {
      return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS
    }
    if (!hasActiveBrwPpfContract && canReportOnConbrwContracts) {
      return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS
    }
    if (!hasActiveBrwPpfContract && !canReportOnConbrwContracts) {
      const pubsToReportInventoryFor = getAllPubsABrwCanReportInventoryFor({
        entireCustomersSlice,
        entireParentChildLinksSlice,
        customerId,
      })
      if (pubsToReportInventoryFor.length > 0) {
        return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY
      }
      return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DOES_NOT_REPORT_INVENTORY
    }
  }

  if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    const canReportOnConbrwContracts = getWhichContracteesDoesConbrwHaveCustomerLvlPermissionsToReportInventoryFor({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      conbrwCustomerId: customerId,
    })
    if (canReportOnConbrwContracts) {
      return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS
    }
    return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS
  }

  if (customerType === CUSTOMER_TYPES_DISTRIBUTOR) {
    return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR
  }
  if (customerType === CUSTOMER_TYPES_WAREHOUSE) {
    return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE
  }
  if (customerType === CUSTOMER_TYPES_MAINTENANCE_FACILITY) {
    return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_MAINTENANCE_FACILITY
  }

  return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DOES_NOT_REPORT_INVENTORY
}


/*
 * *****************************************************************************
 * Should report inventory form be rendered
 * *****************************************************************************
 */

// Helper functions
export function getAllPubsABrwCanReportInventoryFor({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerId,
}) {
  // CODE_COMMENTS_49
  const pubs = getMultipleCustomerPropsOfAllChildrenFilteredByCustomerType({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId,
    propNames: ['name', 'id'],
    customerTypes: [CUSTOMER_TYPES_PUB], // CODE_COMMENTS_120
    // CODE_COMMENTS_123, CODE_COMMENTS_122
    onlyCustomersWhoAreNotCurrentlyInactive: true,
  })
  return sortBy_(pubs, 'name') // sort alphabetically by name
}


export function getShouldReportInventoryFormBeRendered({
  entireCustomersSlice,
  entireContractsSlice,
  entireParentChildLinksSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  customerId,
}) {
  const customerType = entireCustomersSlice[customerId].customerType
  if (!getHasReportInventoryPermissions({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    customerId,
  })) {
    return false
  }

  // Some Brewers are just contractees with no default Brewing contract, no
  // pubs, and all their CONBRWs report the inventory at the
  // contract-brewing-contract locations (see CODE_COMMENTS_213). Therefore,
  // they have no locations they need to report inventory on.
  if (determineCustomerTypeForReportInventory({
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    customerId,
    customerType,
  }) === CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DOES_NOT_REPORT_INVENTORY) {
    return false
  }

  // If none of a BRW or CONBRW's contracts are active, don't render the Report
  // Inventory form. See https://microstartap3.atlassian.net/browse/TP3-3916 for
  // details.
  if ([CUSTOMER_TYPES_BREWER, CUSTOMER_TYPES_CONTRACT_BREWER].includes(customerType)) {
    if (!isTruthyAndNonEmpty(getContracts({
      entireContractsSlice,
      entireCustomersSlice,
      entireRelationshipsSlice,
      customerId,
      notExpiredOnly: false, // CODE_COMMENTS_268: customers are allowed to report inventory on expired contracts
      activeStatusOnly: true,
      noFutureContracts: true,
    }))) {
      return false
    }
  }
  return true
}


/*
 * *****************************************************************************
 * Special Distributor Report Inventory form not requiring login; see
 * CODE_COMMENTS_267
 * *****************************************************************************
 */

export function getDisplayedDistCustomerNameFromArrayOfCustomerObjectsForOnePhysicalLocation(a) {
  const namesStrippedOfPrependedBusinessUnitId = a.map(customerObj => (
    removeBusinessUnitPrependedToCustomerName(customerObj)
  ))
  return getMostFrequentItemInArray(namesStrippedOfPrependedBusinessUnitId)
}


/*
 * *****************************************************************************
 * Misc.
 * *****************************************************************************
 */

export function getAllItemSkuIdsOfAllLineItemObjectsOfAllInventoryReports({
  flatArrayOfInventoryReportObjs,
  entireItemSkusSlice,
}) {
  return flow_(
    // Get an array of inventoryLineItemObjects objects:
    // [
    //   [
    //     {
    //       "itemSkuId": "SB-MS-D-U",
    //       "inventoryType": "TTL_IN",
    //       "quantity": 1
    //     },
    //     {
    //       "itemSkuId": "HB-MS-D-U",
    //       "inventoryType": "OSW",
    //       "quantity": 2
    //     },
    //   ],
    //   [
    //     {
    //       "itemSkuId": "QB-MS-D-U",
    //       "inventoryType": "TTL_IN",
    //       "quantity": 3
    //     },
    //     {
    //       "itemSkuId": "PP-CBI",
    //       "inventoryType": "OSW",
    //       "quantity": 4
    //     },
    //   ],
    // ]
    mapFp_(inventoryReportObj => inventoryReportObj.inventoryLineItemObjects),
    // Get an array of arrays of individual  objects:
    // [
    //   ['SB-MS-D-U', 'HB-MS-D-U'],
    //   ['QB-MS-D-U', 'PP-CBI'],
    // ]
    mapFp_(arr => arr.map(o => o.itemSkuId)),
    // Sort each array:
    // [
    //   ['HB-MS-D-U', 'SB-MS-D-U'],
    //   ['QB-MS-D-U', 'PP-CBI'],
    // ]
    mapFp_(arr => sortArrayByTemplateArray(arr, getItemSkusDefaultSortOrder({ entireItemSkusSlice }))),
  )(flatArrayOfInventoryReportObjs)
}


export function getCustomerName({
  entireCustomersSlice,
  customerId,
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  isEditForm,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
}) {
  if (
    isEditForm ||
    isThisForDisplayingAnAlreadyCreatedInventoryReport
  ) {
    const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
      [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    })
    return entireCustomersSlice[individualInventoryReportObj.customerId].name
  }
  return entireCustomersSlice[customerId].name
}


export function getNameOfReportingPerson({
  entireCurrentUserSlice,
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  isEditForm,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
}) {
  if (
    isEditForm ||
    isThisForDisplayingAnAlreadyCreatedInventoryReport
  ) {
    const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
      [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    })
    // When would a reporting user's name ever be the string 'null null'? It's a
    // backend bug (https://microstartap3.atlassian.net/browse/TP3-1175) that
    // we're making less visible on the web app until the backend team gets this
    // fixed. Instead of displaying 'null null' to user, show a blank string.
    return individualInventoryReportObj.reportingUsersName === 'null null'
      ? ''
      : individualInventoryReportObj.reportingUsersName
  }
  return getCurrentUserFullName(entireCurrentUserSlice)
}
