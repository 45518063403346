import flow_ from 'lodash/fp/flow'
import map_ from 'lodash/fp/map'
import values_ from 'lodash/fp/values'
import filter_ from 'lodash/fp/filter'
import sortBy_ from 'lodash/fp/sortBy'

import {
  withMultiplePropsOfAllNormalized,
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'

import {
  COLLAR_PLATES_STATUS,
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_COLLAR_PLATES as defaultSlice,
  REDUCER_NAMES_ENTITIES_CUSTOMER_COLLAR_PLATE_LINKS,
} from '../../../constants'

/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/

export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)


export function getEntireCustomerCollarPlateLinksSlice(state) {
  return getEntireSliceCommon(
    REDUCER_NAMES_ENTITIES,
    REDUCER_NAMES_ENTITIES_CUSTOMER_COLLAR_PLATE_LINKS,
  )(state)
}

// usage: const idAndFlavorOfAllCollarPlates = getMultiplePropsOfAll(entireCollarPlatesSlice, 'id', 'flavor')
// returns:
// [
//   {id: 1234, flavor: 'white rascal'},
//   {id: 1235, flavor: 'rumpkin'},
//   {id: 1236, flavor: 'pale alesya'},
// ]
export const getMultiplePropsOfAll = withMultiplePropsOfAllNormalized()


// usage: const idAndFlavorOfAllCollarPlates = getMultiplePropsOfAll(state, 'id', 'flavor')
// returns:
// [
//   {id: 1234, flavor: 'white rascal'},
//   {id: 1235, flavor: 'rumpkin'},
//   {id: 1236, flavor: 'pale alesya'},
// ]
export const getMultiplePropsOfAllPassingInTheFullStateObject = withMultiplePropsOfAllNormalized(
  REDUCER_NAMES_ENTITIES,
  defaultSlice,
)


/*
 * *****************************************************************************
 * More specific selectors
 * *****************************************************************************
*/


export function getTotalNumCollarPlatesOnFileForCustomer({
  customerId,
  entireCollarPlatesSlice,
  entireCustomerCollarPlateLinksSlice,
  currentlyActiveCollarPlatesOnly, // CODE_COMMENTS_112
}) {
  return getAllCollarPlateIdsOfCustomer({
    customerId,
    entireCollarPlatesSlice,
    entireCustomerCollarPlateLinksSlice,
    currentlyActiveCollarPlatesOnly, // CODE_COMMENTS_112
  }).length
}


// returns an array of collar plate objects, ordered alphabetically by 'flavor'.
export function getAllCollarPlateObjectsOfCustomer({
  customerId,
  entireCollarPlatesSlice,
  entireCustomerCollarPlateLinksSlice,
  currentlyActiveCollarPlatesOnly, // CODE_COMMENTS_112
}) {
  const allCollarPlateIdsOfCustomer = getAllCollarPlateIdsOfCustomer({
    customerId,
    entireCollarPlatesSlice,
    entireCustomerCollarPlateLinksSlice,
    currentlyActiveCollarPlatesOnly, // CODE_COMMENTS_112
  })
  return flow_(
    map_(id => entireCollarPlatesSlice[id]),
    sortBy_(obj => obj.flavor),
  )(allCollarPlateIdsOfCustomer)
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

export function getAllCollarPlateIdsOfCustomer({
  customerId,
  entireCollarPlatesSlice,
  entireCustomerCollarPlateLinksSlice,
  currentlyActiveCollarPlatesOnly, // CODE_COMMENTS_112
}) {
  const collarPlateIdsOfCustomer = flow_(
    values_, // turn the object of objects into an array of objects
    filter_(linkObj => linkObj.customerId === customerId),
    map_(linkObj => linkObj.collarPlateId),
  )(entireCustomerCollarPlateLinksSlice)

  if (!currentlyActiveCollarPlatesOnly) { return collarPlateIdsOfCustomer }

  const propsPertinantToCurrentlyActive = getMultiplePropsOfAll(entireCollarPlatesSlice, 'id', 'hidden', 'dateApproved', 'status')
  return propsPertinantToCurrentlyActive.filter(props => (
    collarPlateIdsOfCustomer.includes(props.id) &&
    // a collar plate is currently active if its 'hidden' prop is false and its
    // dateApproved prop is not null (the dateApproved prop will never be a date
    // in the future, so we don't have to worry about checking what the date
    // actually is)
    !props.hidden &&
    props.dateApproved &&
    COLLAR_PLATES_STATUS.includes(props.status)
  )).map(props => props.id)
}
