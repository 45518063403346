import FetchStatusReducerCreator from '../higherOrderReducers/reducerCreators'

import {
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_REQUEST,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_SUCCESS,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_FAILURE,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_CLEAR,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_QUEUE,
} from '../../../actions/actionTypes'


export default FetchStatusReducerCreator({})(
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_REQUEST,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_SUCCESS,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_FAILURE,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_CLEAR,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_QUEUE,
)
