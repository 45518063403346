// Needed by both the AddOrEditIndividualContact and DeleteIndividualContact
// components.

import React from 'react'
import {
  Dropdown,
} from 'semantic-ui-react'


export default ({
  contact,
  contacts,
  value: currentValue,
  setValue,
  placeholder='Choose New Default',
  ...rest
}) => {
  const options = contacts.filter(o => o.id !== contact.id).map(o => ({
    key: o.id,
    text: `${o.firstName} ${o.lastName}`,
    value: o.id,
  }))
  return (
    <Dropdown
      options={options}
      value={currentValue}
      onChange={(e, { value }) => setValue(value)}
      search
      selection
      name="contactType"
      placeholder={placeholder}
      {...rest}
    />
  )
}
