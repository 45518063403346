/**
 * CODE_COMMENTS_131
 */
import {
  REDUCER_NAMES_CATASTROPHIC_FAILURE as defaultSlice,
} from '../../constants'

export function getHasCatastrophicFailureHappened(state) {
  return state[defaultSlice].hasCatastrophicFailureHappened
}

export function getCatastrophicFailureError(state) {
  return state[defaultSlice].error
}

export function getCatastrophicFailureInfo(state) {
  return state[defaultSlice].info
}
