import { connect } from 'react-redux'

import FormOrNoCollarPlatesMessage from '../components/FormOrNoCollarPlatesMessage'


import {
  getEntireSlice as getEntireCollarPlatesSlice,
  getEntireCustomerCollarPlateLinksSlice,
} from '../../../../../../redux/selectors/rewrite/collarPlates'


const mapStateToProps = state => {
  const entireCollarPlatesSlice = getEntireCollarPlatesSlice(state)
  const entireCustomerCollarPlateLinksSlice = getEntireCustomerCollarPlateLinksSlice(state)

  return {
    entireCollarPlatesSlice,
    entireCustomerCollarPlateLinksSlice,
  }
}


export default connect(
  mapStateToProps,
)(FormOrNoCollarPlatesMessage)
