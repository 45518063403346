import React from 'react'

import withIsPrelimInfoFetched from '../../HOC/redux/withIsPrelimInfoFetched'

import RevealContactInfo from '../../RevealContactInfo'
import RevealFetchErrorDetails from '../../fetchErrorDetails/RevealFetchErrorDetails'

import DismissableMessageClearingFetchStatus from './DismissableMessageClearingFetchStatus'

import {
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../constants'


export default withIsPrelimInfoFetched(({
  // props from withIsPrelimInfoFetched
  didFetchSucceed: isPrelimInfoFetched,
  // props from caller
  customerId,
  formName,
  errorDetails,
  onDismiss,
}) => (
  <DismissableMessageClearingFetchStatus
    error
    formName={formName}
    onDismiss={onDismiss}
    content={
      <div>
        <p>{DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR}</p>
        <div>
          {/* Don't show the contact card if the customerId is not passed in */}
          {isPrelimInfoFetched &&
            <RevealContactInfo
              customerId={customerId}
              repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
              asPopup
            />
          }
          <RevealFetchErrorDetails
            errorDetails={errorDetails}
            asPopup
          />
        </div>
      </div>
    }
  />
))
