/**
 * CODE_COMMENTS_77
 */

import React from 'react'


import ByCustomerType from './ByCustomerType'

export default props => <ByCustomerType {...props} />
