import React from 'react'

import orderBy_ from 'lodash/orderBy'


import { matchPath } from 'react-router'
import HistoryTableCreator from '../../../HOC/HistoryTableCreator'
import OrderAndShipmentsDetails from '../subFeatures/OrderAndShipmentsDetails'

import {
  getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn,
} from '../../../../../../redux/selectors/rewrite/relationships/relatedToInfo'

import {
  getAllKegOrderObjectsToBeIncludedInHistoryTable,
  getIncludeLocalKegsColumnsInHistoryTable,
  createDisplayedOrderDate,
  createDisplayedDateNeeded,
  createContainerFieldsDefinitions,
  getShouldKegOrderHistoryItemBeDisplayedInTable,
  getDisplayedHumanReadableStatusOfKegOrder,
  determineItemSkuIdsToIncludeAsFieldsInKegOrderHistoryTable,
  createDisplayedPO,
} from '../../../util/kegOrderHistory'

import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'
import { ORDER_TYPES_LABEL } from '../../../../../../constants/formAndApiUrlConfig/orderKegs'
import { EditOrderLink } from '../../../../../OrderKegs/OrderAndEditShared/components/DeliverySchedule'
import { KEG_ORDER_STATUS_PENDING } from '../../../../../../constants/formAndApiUrlConfig/commonConfig'


const createTableContentDefinitions = ({
  entireCustomersSlice,
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  includeLocalKegsColumns,
  entireRelationshipsSlice,
  entireContractsSlice,
  customerContracts,
  match,
}) => ({
  content: [
    {
      heading: 'Order #',
      cellContent: (row, { customerId }) => {
        if (row?.status === KEG_ORDER_STATUS_PENDING) {
          return (
            <EditOrderLink
              match={match}
              entireItemSkusSlice={entireItemSkusSlice}
              entireCustomersSlice={entireCustomersSlice}
              entireContractsSlice={entireContractsSlice}
              entireRelationshipsSlice={entireRelationshipsSlice}
              itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
              customerContracts={customerContracts}
              customerId={customerId}
              info={row}
            />
          )
        }
        return (
          <OrderAndShipmentsDetails
            match={match}
            entireItemSkusSlice={entireItemSkusSlice}
            info={row}
            customerId={customerId}
            itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
            includeLocalKegsColumns={includeLocalKegsColumns}
          />
        )
      },
      customSortInfo: {
        sortFunction: (apiObjs, ascOrDesc) => (orderBy_(apiObjs, ['orderId'], [ascOrDesc])),
        sortApiObjectValuesRatherThanCellContents: true,
      },
      className: 'testOrderNumber', // CODE_COMMENTS_57
    },
    {
      heading: 'Order Date',
      cellContent: createDisplayedOrderDate,
      customSortInfo: {
        sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateOrdered' }),
        sortApiObjectValuesRatherThanCellContents: true,
      },
      className: 'testOrderDate', // CODE_COMMENTS_57
    },
    {
      heading: 'Order Type',
      cellContent: row => ORDER_TYPES_LABEL?.[row?.orderType],
    },
    {
      heading: 'Date Needed',
      cellContent: createDisplayedDateNeeded,
      customSortInfo: {
        sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateNeeded' }),
        sortApiObjectValuesRatherThanCellContents: true,
      },
      className: 'testDateNeeded', // CODE_COMMENTS_57
    },
    {
      heading: 'Status',
      cellContent: getDisplayedHumanReadableStatusOfKegOrder,
      className: 'testStatus', // CODE_COMMENTS_57
    },
    {
      heading: 'PO #',
      cellContent: createDisplayedPO,
      className: 'testStatus',
    },
    ...createContainerFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
      includeLocalKegsColumns,
    }),
  ],
  defaultSortColumn: 'Date Needed',
  filterFunc: getShouldKegOrderHistoryItemBeDisplayedInTable,
  rowKey: row => row.id,
})

export default ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entireItemSkusSlice,
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  configuredItemSkuIds,
  allKegOrderObjects,
  noResultsMessage,
  areAnyItemsEditable,
  getIsindividualItemEditable,
  customerContracts,
}) => {
  const match = matchPath(window.location.pathname.toString(), {
    path: window.location.pathname.toString(),
    exact: true,
    strict: false,
  })
  const allKegOrderObjectsToBeIncludedInHistoryTable = getAllKegOrderObjectsToBeIncludedInHistoryTable(
    allKegOrderObjects,
  )
  const isRelatedToLocalDistributors = getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  })
  const includeLocalKegsColumns = getIncludeLocalKegsColumnsInHistoryTable(
    allKegOrderObjectsToBeIncludedInHistoryTable,
    isRelatedToLocalDistributors,
  )
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInKegOrderHistoryTable({
    entireItemSkusSlice,
    allKegOrderObjectsToBeIncludedInHistoryTable,
    configuredItemSkuIds,
  })

  const definitions = createTableContentDefinitions({
    entireItemSkusSlice,
    itemSkuIdsToIncludeAsFieldsInTable,
    includeLocalKegsColumns,
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerContracts,
    match,
  })

  return (
    <HistoryTableCreator
      tableInfoObj={allKegOrderObjectsToBeIncludedInHistoryTable}
      definitions={definitions}
      additionalPropsToPassToTableCells={{ customerId, operatingContractBrewerCustomerId }}
      noResultsMessage={noResultsMessage}

      // for Edit and Delete buttons column
      areAnyItemsEditable={areAnyItemsEditable}
      itemType="keg order"
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      getIsindividualItemEditable={getIsindividualItemEditable}
    />
  )
}
