// Pass in EITHER:

// -- address (set to the address object from the store)
// -- customerId AND (either addressType or addressId)

// If the second choice is passed in, this component will find the address
// object for you.

// If you pass in addressType, it's assumed you want a
// currentlyActiveAddressOnly addresses (one major reason for this is that
// Default Shipping and Billing addresses will always be active: see the
// ADDRESSES.md document for details) (this may require refactoring in the
// future, but as the app stands right now, you're never going to want to
// display an inactive address by type--if you want to display an inactive
// address, you'll either already have the address object or the address ID [for
// example, in the case of history items, you always have the address id]). If
// you pass in an addressId, it doesn't have to be a currently active address
// (this will often be the case when displaying the addresses of history items)
// (see CODE_COMMENTS_112).

import { connect } from 'react-redux'

import DisplayedAddress from './components/DisplayedAddress'

import {
  getAddressOfCustomer,
  getAddressByIdNoLinkInfoOnlyAddressInfo,
} from '../../redux/selectors/addresses'


const mapStateToProps = (state, {
  customerId,
  addressType,
  addressId,
  address,
  ...rest
}) => {
  let addressObject
  // make sure that this conditional is not if(!address)
  if (customerId && (addressType || addressId)) {
    if (addressType) {
      addressObject = getAddressOfCustomer({
        state,
        customerId,
        currentlyActiveAddressesOnly: true, // CODE_COMMENTS_112
        addressType,
      })
    } else { // addressId must have been passed in
      addressObject = getAddressByIdNoLinkInfoOnlyAddressInfo({
        state,
        addressId,
      })
    }
  }
  if (addressId && !customerId) {
    addressObject = getAddressByIdNoLinkInfoOnlyAddressInfo({
      state,
      addressId,
    })
  }
  return {
    addressObject: (
      addressObject
      // The address passed in must be an addressObject itself
      || address
    ),
    ...rest,
  }
}

export default connect(
  mapStateToProps,
)(DisplayedAddress)
