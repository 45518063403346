import { connect } from 'react-redux'


import DownloadKegOrderHistoryTableAsFile from './components'

import {
  getProp as getCustomerProp,
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getAllKegOrderHistoryObjects,
} from '../../../../../redux/selectors/histories/kegOrderHistory'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'

import {
  getAllKegOrderObjectsToBeIncludedInHistoryTable,
  getAllAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile,
} from '../../util/kegOrderHistory'

const mapStateToProps = (
  state,
  {
    customerId,
    formName: historyFormName,
  },
) => {
  const mostRecentlySubmittedFormValues = getMostRecentlySubmittedFormValues(state, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return {} }

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const allKegOrderObjects = getAllKegOrderHistoryObjects(state, customerId)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')
  const allKegOrderObjectsToBeIncludedInHistoryTable = getAllKegOrderObjectsToBeIncludedInHistoryTable(
    allKegOrderObjects,
  )
  const allAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile =
    getAllAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile({
      state,
      arrayOrObjectOfObjects: allKegOrderObjectsToBeIncludedInHistoryTable,
    })

  return {
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    allKegOrderObjects,
    configuredItemSkuIds,
    allAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile,
    mostRecentlySubmittedFormValues,
    // We specifically don't pass allKegOrderObjectsToBeIncludedInHistoryTable
    // because it's derived state (and it's not memoized like
    // allAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile is), i.e. it
    // returns a new array every time it's called so it would cause the
    // presentational component to be re-rendered every single time any piece of
    // the store changes, even pieces that have nothing to do with the history
    // table.
  }
}


export default connect(
  mapStateToProps,
)(DownloadKegOrderHistoryTableAsFile)
