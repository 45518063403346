import React from 'react'

// import findIndex_ from 'lodash/findIndex'
import values_ from 'lodash/values'
import cloneDeep_ from 'lodash/cloneDeep'


import capitalize_ from 'lodash/capitalize'
import DownloadTableAsFile from '../../../sharedComponents/DownloadTableAsFile'

// import {
//   getOriginNameForOutboundShipmentsHistoryTable,
// } from '../../../util/shipmentHistory/outboundFullKegShipmentHistory/displayedDataEtc/util'
// import {
//   getOriginNameForKegFillsContracteeHistoryTable,
// } from '../../../util/shipmentHistory/kegFillsContracteeHistory/displayedDataEtc/util'
// import {
//   // getInfoDefinitionsScheduledShipmentFullDetails,
//   // getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
// } from '../../../util/shipmentHistory/outboundFullKegShipmentHistory'
// import {
//   createDisplayedProNumber,
// } from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'
import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'
import {
  createFilenameOfDownloadedTableFile,
  constructNonHeadingRowsOfDownloadedTableFile,
  filterHistoryObjectsWithUnrecognizedStatus,
  getDisplayedHumanReadableStatusOfHistoryItem,
} from '../../../util/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'

// import {
//   OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
//   // OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
//   // OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION,
// } from '../../../../../../constants/formAndApiUrlConfig/histories/OutboundFullKegShipmentHistory'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/historyShared'
import { scheduledShipmentsHistoryStatusesConfig } from '../../Form'
import {
  createDisplayedDeliveryDate,
  createDisplayedDestinationName,
  createDisplayedMovementType, createDisplayedPlannedDeliveryDate,
  createDisplayedProNumber, createDisplayedShipmentDate,
  createDisplayedShipmentNumber, createDisplayedShipmentType, createScheduledShipmentAddress,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'
import {
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION,
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
} from '../../../../../../constants/formAndApiUrlConfig/histories/OutboundFullKegShipmentHistory'
import { CUSTOMER_TYPES_WAREHOUSE } from '../../../../../../constants'
import {
  createDisplayedOriginNameForOutboundShipmentsHistoryTable,
} from '../../../util/shipmentHistory/outboundFullKegShipmentHistory/displayedDataEtc/displayedOutboundFullKegShipmentDataEtc'
import { getHumanReadableContainerTypeFromItemSku } from '../../../../../../utils'
// import {
//   getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
// } from '../../../util/shipmentHistory/outboundFullKegShipmentHistory'

export const getDisplayedHumanReadableStatusOfScheduledShipment = shipmentObj => (
  getDisplayedHumanReadableStatusOfHistoryItem(
    shipmentObj,
    scheduledShipmentsHistoryStatusesConfig,
    'status',
  )
)

export const getInfoDefinitionsScheduledShipmentFullDetails = ({
  entireItemSkusSlice,
  shouldOriginColumnBeRendered,
  entireCustomersSlice,
  customerId,
}) => ([
  { heading: 'Shipment #', cellContent: createDisplayedShipmentNumber },
  { heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM, cellContent: createDisplayedProNumber('none') },
  ...(entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ?
    [{ heading: 'Shipment Type', cellContent: createDisplayedShipmentType }]
    : [{ heading: 'Movement Type', cellContent: createDisplayedMovementType }]
  ),
  ...(shouldOriginColumnBeRendered
    ? [{
      heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
      cellContent: createDisplayedOriginNameForOutboundShipmentsHistoryTable,
    }]
    : []
  ),
  { heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION, cellContent: createDisplayedDestinationName },
  { heading: 'Destination Address', cellContent: createScheduledShipmentAddress },
  { heading: 'Planned Delivery Date', cellContent: createDisplayedPlannedDeliveryDate },
  { heading: 'Delivery Date', cellContent: createDisplayedDeliveryDate },
  { heading: 'Ship Date', cellContent: createDisplayedShipmentDate },
  { heading: 'Status', cellContent: getDisplayedHumanReadableStatusOfScheduledShipment },
  { heading: 'SKU', cellContent: row => getHumanReadableContainerTypeFromItemSku(row?.lineItems?.[0]?.itemSkuId, entireItemSkusSlice) },
  { heading: 'Quantity', cellContent: row => row?.lineItems?.[0]?.quantity },
  { heading: 'Quality', cellContent: row => capitalize_(entireItemSkusSlice?.[row?.lineItems?.[0]?.itemSkuId]?.qualityLevel) },
  { heading: 'Delivery Notes', cellContent: row => row?.destinationDeliveryNotes },
  { heading: 'Order Comments', cellContent: row => row?.orderComments },
])

export default ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entireItemSkusSlice,
  customerId,
  operatingContractBrewerCustomerId,

  allHistoryObjs,
  configuredItemSkuIds,
  shouldOriginColumnBeRendered,
  mostRecentlySubmittedFormValues,
  kegFillsHistoryFormRatherThanOutboundShipmentsHistoryForm,
  kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm,
}) => {
  if (!mostRecentlySubmittedFormValues) {
    return <DownloadTableAsFile data={null} filename={null} />
  }

  const allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    filterHistoryObjectsWithUnrecognizedStatus(
      allHistoryObjs,
      scheduledShipmentsHistoryStatusesConfig,
      'status',
    )

  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allHistoryObjs,
  })

  const filename = createFilename({
    mostRecentlySubmittedFormValues,
    kegFillsHistoryFormRatherThanOutboundShipmentsHistoryForm,
    kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm,
  })

  const data = createFileData({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    customerId,
    operatingContractBrewerCustomerId,
    allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
    itemSkuIdsToIncludeAsFieldsInTable,
    shouldOriginColumnBeRendered,
    kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm,
  })

  return (
    <DownloadTableAsFile
      data={data}
      filename={filename}
    />
  )
}

/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

function createFilename({
  mostRecentlySubmittedFormValues,
  kegFillsHistoryFormRatherThanOutboundShipmentsHistoryForm,
  kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm,
}) {
  const historyFormStatus = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_STATUS
  ]
  const historyFormStartDate = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_START_DATE
  ]
  const historyFormEndDate = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_END_DATE
  ]

  const fileType = (
    kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm
    || kegFillsHistoryFormRatherThanOutboundShipmentsHistoryForm
  )
    ? 'keg fills'
    : 'scheduled shipments'
  return createFilenameOfDownloadedTableFile(
    fileType,
    historyFormStartDate,
    historyFormEndDate,
    { status: historyFormStatus },
  )
}


// array of arrays, with first array being column headings
function createFileData({
  entireCustomersSlice,
  // entireContractsSlice,
  // entireRelationshipsSlice,
  entireItemSkusSlice,
  customerId,
  // operatingContractBrewerCustomerId,
  allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
  itemSkuIdsToIncludeAsFieldsInTable,
  shouldOriginColumnBeRendered,
  // kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm,
}) {
  const originalDataDefinitions = getInfoDefinitionsScheduledShipmentFullDetails({
    entireItemSkusSlice,
    itemSkuIdsToIncludeAsFieldsInTable,
    shouldOriginColumnBeRendered,
    downloadAttachment: true,
    entireCustomersSlice,
    customerId,
  })

  const dataDefinitions = cloneDeep_(originalDataDefinitions)
  // dataDefinitions = swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions)
  // dataDefinitions = swapCustomerNamesDefinition({
  //   entireCustomersSlice,
  //   entireContractsSlice,
  //   entireRelationshipsSlice,
  //   customerId,
  //   operatingContractBrewerCustomerId,
  //   dataDefinitions,
  //   shouldOriginColumnBeRendered,
  //   kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm,
  // })

  let arrayOfShipmentObjects = values_(allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable)
  arrayOfShipmentObjects = createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' })(arrayOfShipmentObjects)
  // arrayOfShipmentObjects=arrayOfShipmentObjects.filter(getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable)

  const nonHeadingRows = constructNonHeadingRowsOfDownloadedTableFile(arrayOfShipmentObjects, dataDefinitions)

  return [
    dataDefinitions.map(o => o.heading), // headings row
    ...nonHeadingRows,
  ]
}


// CODE_COMMENTS_111
// function swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions) {
//   dataDefinitions.splice(
//     findIndex_(dataDefinitions, o => o.heading === OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM),
//     1,
//   { heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM, cellContent: createDisplayedProNumber() },
//   )
//
//   // CODE_COMMENTS_109
//   return dataDefinitions
// }


// CODE_COMMENTS_132
// function swapCustomerNamesDefinition({
//   entireCustomersSlice,
//   entireContractsSlice,
//   entireRelationshipsSlice,
//   customerId,
//   operatingContractBrewerCustomerId,
//   dataDefinitions,
//   shouldOriginColumnBeRendered,
//   kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm,
// }) {
//   // Destination field
//   dataDefinitions.splice(
//     findIndex_(dataDefinitions, o => o.heading === OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION),
//     1,
//     {
//       heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION,
//       cellContent: row => row.destinationCustomerName,
//     },
//   )
//
//   if (shouldOriginColumnBeRendered) {
//     // Origin field, see CODE_COMMENTS_130
//     dataDefinitions.splice(
//       findIndex_(dataDefinitions, o => o.heading === OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN),
//       1,
//       {
//         heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
//         cellContent: (
//           kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm
//             ? row => getOriginNameForKegFillsContracteeHistoryTable({ row })
//             : row => getOriginNameForOutboundShipmentsHistoryTable({
//               entireCustomersSlice,
//               entireContractsSlice,
//               entireRelationshipsSlice,
//               customerId,
//               operatingContractBrewerCustomerId,
//               row,
//             })
//         ),
//       },
//     )
//   }
//
//   // CODE_COMMENTS_109
//   return dataDefinitions
// }
