import React from 'react'

import WaitForOperateePrelimInfo from './WaitForOperateePrelimInfo'
import RouteNotFound from '../../RouteNotFound'

import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../constants'

export default ({
  isOperatorAtAllowedToOperateForOperatee,
  operateeCustomerId,
  operatorCustomerType,
  operatorCustomerId,
}) => (
  isOperatorAtAllowedToOperateForOperatee
    ? (
      <WaitForOperateePrelimInfo
        customerId={operateeCustomerId}
        operatingContractBrewerCustomerId={
          operatorCustomerType === CUSTOMER_TYPES_CONTRACT_BREWER
            ? operatorCustomerId
            : null
        }
      />
    )
    : <RouteNotFound />
)
