// The purpose of this UniversalLayout component is to have a sticky footer with
// the privacy policy on all pages, both public and private. Solution
// for sticky footer at
// https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/

import React, { memo } from 'react'


import Footer from '../../Footer'

import './styles.css'

export default memo(({ children, ...rest }) => (
  <div className="universal-layout">
    <div className="universal-layout-content">
      {children}
    </div>
    <div className="universal-layout-footer">
      <Footer {...rest} />
    </div>
  </div>
))
