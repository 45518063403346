import isPlainObject_ from 'lodash/isPlainObject'
import has_ from 'lodash/has'


import {
  validateCurrentUserObject,
} from '../../sagas/util/apiResponseDataValidation/usersAndPermissions/currentUser'

import {
  REDUCER_NAMES_AUTH,
  REDUCER_NAMES_CURRENT_USER,
  REDUCER_NAMES_CURRENT_USER_EMPLOYEE,
  REDUCER_NAMES_LATEST_WEB_APP_VERSION,
  REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'

// CODE_COMMENTS_181
export function validatePersistedState(persistedState) {
  // persistedState will be null/undefined if this is the first time the user
  // has ever used the site. Otherwise it must be an object
  if (!persistedState) { return true }
  if (!isPlainObject_(persistedState)) { return false }

  const isAuthPersistedStateProperlyStructured = validateAuthPersistedState({ persistedState })
  const isCurrentUserPersistedStateProperlyStructured = validateCurrentUserPersistedState({ persistedState })
  const isEmployeeCurrentUserPersistedStateProperlyStructured = validateEmployeeCurrentUserPersistedState({
    persistedState,
  })
  const isLatestWebAppVersionPersistedStateProperlyStructured = validateLatestWebAppVersionPersistedState({
    persistedState,
  })

  return (
    isAuthPersistedStateProperlyStructured
    && isCurrentUserPersistedStateProperlyStructured
    && isEmployeeCurrentUserPersistedStateProperlyStructured
    && isLatestWebAppVersionPersistedStateProperlyStructured
  )
}


// helper functions

function validateAuthPersistedState({ persistedState }) {
  const persistedAuthSlice = persistedState[REDUCER_NAMES_AUTH]
  // the persisted state object is allowed to not have the auth prop
  if (!persistedAuthSlice) { return true }

  // If the persisted state object exists, it must be a plain object with an
  // 'isAuthenticated' item
  if (!isPlainObject_(persistedAuthSlice)) { return false }
  if (!has_(persistedAuthSlice, 'isAuthenticated')) { return false }

  // See CODE_COMMENTS_223 for why we're not checking for the presence of
  // `userToken`, `decodedUserToken` or `refreshToken` props.
  return true
}


function validateCurrentUserPersistedState({ persistedState }) {
  return validateCurrentUserPersistedStateOrEmployeeCurrentUserPersistedState({
    persistedState,
    targetEmployeeCurrentUserRatherThanCurrentUser: false,
  })
}


function validateEmployeeCurrentUserPersistedState({ persistedState }) {
  return validateCurrentUserPersistedStateOrEmployeeCurrentUserPersistedState({
    persistedState,
    targetEmployeeCurrentUserRatherThanCurrentUser: true,
  })
}


// There are 2 different current user slices: currentUser and a
// currentUserEmployee. See CODE_COMMENTS_221.
function validateCurrentUserPersistedStateOrEmployeeCurrentUserPersistedState({
  persistedState,
  targetEmployeeCurrentUserRatherThanCurrentUser,
}) {
  const targetSliceName = targetEmployeeCurrentUserRatherThanCurrentUser
    ? REDUCER_NAMES_CURRENT_USER_EMPLOYEE
    : REDUCER_NAMES_CURRENT_USER
  const persistedTargetSlice = persistedState[targetSliceName]
  // The currentUser/currentUserEmployee prop does not need to exist. This would
  // happen, for example, if this is the first time the user has ever visited
  // the site.
  if (!persistedTargetSlice) { return true }
  // It's also OK if the currentUser/currentUserEmployee prop is set to an empty
  // object. This would happen if the currentUser is empty but
  // currentUserEmployee has props in it, or vice versa.
  if (!isTruthyAndNonEmpty(persistedTargetSlice)) { return true }

  // If the persisted state object does have the currentUser prop, it must be
  // shaped like a currentUserObject
  const { canDataBeSavedToStore } = validateCurrentUserObject({
    data: persistedTargetSlice,
    isEmployee: targetEmployeeCurrentUserRatherThanCurrentUser,
    ifIsCurrentUserTypeShouldPropsRelatedToAuthenticationBeChecked: false, // CODE_COMMENTS_222
  })
  return canDataBeSavedToStore
}


function validateLatestWebAppVersionPersistedState({ persistedState }) {
  const persistedSlice = persistedState[REDUCER_NAMES_LATEST_WEB_APP_VERSION]
  // the persisted state object is allowed to not have the latestWebAppVersion
  // prop
  if (!persistedSlice) { return true }

  // If the persisted state object does have the latestWebAppVersion prop, it
  // must be an object shaped like
  // { numHardRefreshesByWebAppVersionAttemptingToFetch: {...} }
  if (!isPlainObject_(persistedSlice)) { return false }
  if (!has_(
    persistedSlice,
    REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH,
  )) { return false }
  if (
    !isPlainObject_(persistedSlice[REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH])
  ) { return false }

  // See CODE_COMMENTS_223 for why we're not checking for the presence of
  // `userToken`, `decodedUserToken` or `refreshToken` props.
  return true
}
