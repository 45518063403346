import {
  FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_REQUEST,
  FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_SUCCESS,
  FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_FAILURE,
} from '../../actions/actionTypes'

import fetchStatusReducerCreator from './higherOrderReducers/reducerCreators'


export default fetchStatusReducerCreator({})(
  FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_REQUEST,
  FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_SUCCESS,
  FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_FAILURE,
)
