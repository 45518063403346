import i18n from 'i18next'
// import Backend from 'i18next-http-backend'
import Backend from 'i18next-locize-backend'
// import SyncBackend from 'i18next-sync-fs-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import LastUsed from 'locize-lastused'
import { initReactI18next } from 'react-i18next'

const isProduction = process.env.NODE_ENV === 'production'
const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
}

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  .use(LastUsed)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // .use(SyncBackend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['common', 'pagelabels'],
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    fallbackLng: 'en',
    saveMissing: !isProduction,
    debug: !isProduction,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
  })
  // eslint-disable-next-line
  .then(() => console.log('Lng initialization successful'))
  // eslint-disable-next-line
  .catch(err => console.error('Lng initialization failed - ', err))

export default i18n
