import React from 'react'
import { Divider, Container, Segment } from 'semantic-ui-react'

import './styles/publicpagewrapper.css'
import { useTranslation } from 'react-i18next'


/**
 * A simple component that puts the meat of a public page into its own
 * Container, with a page title above it. Note that this isn't the component
 * that renders the big MicroStar logo: this renders everything below the logo
 * and above the copyright line.
 */
export default pageTitle => Component => componentProps => {
  const { t: translate } = useTranslation('common')
  return (
    <div>
      <Divider horizontal>{translate(pageTitle)}</Divider>
      <Container className="public-page-container">
        <Segment basic>
          <Component {...componentProps} />
        </Segment>
      </Container>
    </div>
  )
}
