import React from 'react'
import { Container, Segment } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import BreadcrumbLinks from './BreadcrumbLinks'

import {
  URL_PATHS,
} from '../../constants'


export default withRouter(({
  location,
}) => {
  if ([URL_PATHS.root.path, URL_PATHS.employeeRoot.path].includes(location.pathname)) { return null }
  return (
    <Container className="no-background-container">
      <Segment basic style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
        <BreadcrumbLinks
          currentPath={location.pathname}
        />
      </Segment>
    </Container>
  )
})
