/**
 * CODE_COMMENTS_14
 */
import jwtDecode from 'jwt-decode'
import { REHYDRATE } from 'redux-persist/lib/constants'

import intersection_ from 'lodash/intersection'
import pick_ from 'lodash/pick'
import keys_ from 'lodash/keys'


import {
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE,
  UPDATE_USER_TOKEN,
  LOGOUT,
} from '../actions/actionTypes'

import {
  validatePersistedState,
} from './util/persistedState'

import {
  REDUCER_NAMES_CURRENT_USER,
  REDUCER_NAMES_CURRENT_USER_EMPLOYEE,
  REDUCER_NAMES_AUTH,
} from '../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../utils'


const initialState = {
  isAuthenticated: false,
  userToken: null,
  decodedUserToken: null,
  refreshToken: null,
}

export default function authenticationReducer(state=initialState, action) {
  switch (action.type) {
    case AUTHENTICATION_SUCCESS: {
      const {
        userToken,
        refreshToken,
      } = action.payload
      const decodedUserToken = jwtDecode(userToken)
      return {
        isAuthenticated: true,
        userToken,
        decodedUserToken,
        refreshToken,
      }
    }

    case LOGOUT:
    case AUTHENTICATION_FAILURE:
      return initialState

    case UPDATE_USER_TOKEN: {
      const {
        userToken,
        refreshToken,
      } = action.payload
      const decodedUserToken = jwtDecode(userToken)
      return {
        ...state,
        userToken,
        refreshToken,
        decodedUserToken,
      }
    }

    // CODE_COMMENTS_3
    case REHYDRATE: {
      const persistedState = action.payload
      const isPersistedStateProperlyStructured = validatePersistedState(persistedState)
      if (!isPersistedStateProperlyStructured) {
        return { ...state } // CODE_COMMENTS_8
      }
      if (!persistedState) { return { ...state } } // CODE_COMMENTS_8
      const persistedAuthenticationSlice = persistedState[REDUCER_NAMES_AUTH]
      // if this slice of store has never been persisted before (which would
      // happen if, for example, this is the first time a user has ever visited
      // the site), don't run any more code in this REHYDRATE case, because the
      // subsequent code assumes that the authentication slice of the persisted
      // store has stuff in it.
      if (!persistedAuthenticationSlice) {
        return { ...state } // CODE_COMMENTS_8
      }

      if (!persistedAuthenticationSlice.isAuthenticated) {
        return { ...state }
      }

      // CODE_COMMENTS_6
      const persistedCurrentUserSlice = persistedState[REDUCER_NAMES_CURRENT_USER]
      const persistedCurrentUserEmployeeSlice = persistedState[REDUCER_NAMES_CURRENT_USER_EMPLOYEE]
      // It shouldn't be possible to have a persisted state which includes the
      // authentication slice and neither the currentUser nor the
      // currentUserEmployee slice, but just in case something funky ever
      // happens, we simply return the initialState (which logs the user out)
      if (
        !persistedCurrentUserSlice
        && !persistedCurrentUserEmployeeSlice
      ) {
        return { ...state } // CODE_COMMENTS_8
      }

      // CODE_COMMENTS_13
      if (
        !isTruthyAndNonEmpty(persistedCurrentUserSlice)
        && !isTruthyAndNonEmpty(persistedCurrentUserEmployeeSlice)
      ) {
        return { ...state } // CODE_COMMENTS_8
      }
      // CODE_COMMENTS_223
      return movePropsRelatedToAuthenticationFromCurrentUserSliceIfNecessary(
        state,
        persistedAuthenticationSlice,
        persistedCurrentUserSlice,
        persistedCurrentUserEmployeeSlice,
      )
    }

    default:
      return state
  }
}


// If necessary, transfer 'userToken', 'decodedUserToken' and 'refreshToken'
// from the currentUser slice (or currentUserEmployee slice) to the
// authentication slice; see CODE_COMMENTS_223
export function movePropsRelatedToAuthenticationFromCurrentUserSliceIfNecessary(
  state,
  persistedAuthenticationSlice,
  persistedCurrentUserSlice,
  persistedCurrentUserEmployeeSlice,
) {
  const propsRelatedToAuthentication = ['userToken', 'decodedUserToken', 'refreshToken']
  const doesAuthSliceHaveAllPropsRelatedToAuthentication = intersection_(
    keys_(persistedAuthenticationSlice),
    propsRelatedToAuthentication,
  ).length === 3
  if (doesAuthSliceHaveAllPropsRelatedToAuthentication) {
    // Wait, don't we want to check whether the userToken is expired before
    // rehydrating from localStorage? No, see CODE_COMMENTS_224
    return persistedAuthenticationSlice
  }
  const propsInCurrentUserSliceThatShouldGoIntoTheAuthenticationSlice = pick_(
    persistedCurrentUserSlice,
    propsRelatedToAuthentication,
  )
  const doesCurrentUserSliceHaveAllPropsRelatedToAuthentication = (
    keys_(propsInCurrentUserSliceThatShouldGoIntoTheAuthenticationSlice).length === 3
  )
  const propsInCurrentUserEmployeeSliceThatShouldGoIntoTheAuthenticationSlice = pick_(
    persistedCurrentUserEmployeeSlice,
    propsRelatedToAuthentication,
  )
  const doesCurrentUserEmployeeSliceHaveAllPropsRelatedToAuthentication = (
    keys_(propsInCurrentUserEmployeeSliceThatShouldGoIntoTheAuthenticationSlice).length === 3
  )
  // It shouldn't be possible that `isAuthenticated: true` and _none_ of
  // these 3 store slices--neither authentication, currentUser, nor
  // currentUserEmployee--has propsRelatedToAuthentication in it, but just
  // in case something funky ever happens, we simply return the initialState
  // (which logs the user out)
  if (
    !doesCurrentUserSliceHaveAllPropsRelatedToAuthentication &&
    !doesCurrentUserEmployeeSliceHaveAllPropsRelatedToAuthentication
  ) {
    return { ...state }
  }
  if (doesCurrentUserEmployeeSliceHaveAllPropsRelatedToAuthentication) {
    return {
      // Wait, don't we want to check whether the userToken is expired
      // before rehydrating from localStorage? No, see CODE_COMMENTS_224
      isAuthenticated: true,
      ...propsInCurrentUserEmployeeSliceThatShouldGoIntoTheAuthenticationSlice,
    }
  }
  return {
    // Wait, don't we want to check whether the userToken is expired before
    // rehydrating from localStorage? No, see CODE_COMMENTS_224
    isAuthenticated: true,
    ...propsInCurrentUserSliceThatShouldGoIntoTheAuthenticationSlice,
  }
}
