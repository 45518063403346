import { connect } from 'react-redux'

import ManageUsersPage from './components'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../../redux/selectors/rewrite/parentChildLinks'

const mapStateToProps = state => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)

  return {
    entireCustomersSlice,
    entireParentChildLinksSlice,
  }
}


export default connect(
  mapStateToProps,
)(ManageUsersPage)
