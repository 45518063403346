/* eslint-disable max-len */

import { select } from 'redux-saga/effects'

import merge_ from 'lodash/merge'
import uniq_ from 'lodash/uniq'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import flattenFp_ from 'lodash/fp/flatten'
import uniqFp_ from 'lodash/fp/uniq'

import {
  getPerCustomerPermissions,
  getHasPermissionsToPerformManageUsersPermissionsChoice,
} from '../../../../selectors/permissions'

import {
  getProp as getCustomerProp,
} from '../../../../selectors/customers'
import {
  getCustomerIdsOfAllRelatedFrom,
} from '../../../../selectors/rewrite/relationships/relatedFromInfo'
import {
  getAllChildCustomerIdsOfCustomer,
} from '../../../../selectors/children'

import {
  createUpdatedPerCustomerPermissionsMapByGrantingPermissionsForAnManageUsersPermissionsChoiceToASingleCustomer,
  createUpdatedPerCustomerPermissionsMapByRevokingPermissionsForAnManageUsersPermissionsChoiceFromASingleCustomer,
  parseEditUserPermissionsFormFieldName,
} from '../../../../../features/ManageUsers/util'

import {
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_MASTER,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER,
  PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
  PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
} from '../../../../../constants/permissions'

import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_MASTER,
} from '../../../../../constants'

import {
  getAllPermissionsNeededForManageUsersPermissionsChoice,
  convertPermissionsMapBackToPermissionsArray,
  convertEntirePerCustomerPermissionsMapBackToPerCustomerPermissions,
  mergePermissionsObjects,
  getUniversalPermissionsObjectByRootCustomerType,
} from '../../../../../utils/permissions'

import {
  isTruthyAndNonEmpty,
} from '../../../../../utils'


export function* createApiRequestBody({
  customerId,
  rootCustomerType,
  userObject,
  formValues,
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entireParentChildLinksSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
}) {
  const state = yield select()

  const {
    ownPermissionsFormValues,
    perCustomerPermissionsFormValues,
  } = separateRootCustomerPermissionsFormValuesAndPerCustomerPermissionsFormValues({
    rootCustomerIdOfUser: customerId,
    formValues,
  })

  const updatedRootCustomerPermissionsMap = createUpdatedPermissionsMapForIndividualCustomerBasedOnFormValues({
    formValuesForThisCustomer: ownPermissionsFormValues,
    isThisForAPerCustomerPermissionsCustomer: false,
    rootCustomerType,
  })
  const updatedRootCustomerPermissions = convertPermissionsMapBackToPermissionsArray(updatedRootCustomerPermissionsMap)


  let updatedPerCustomerPermissions
  // perCustomerPermissionsFormValues will be empty if this is a customer type
  // that doesn't operate for other customers (e.g. Brewers)
  if (!isTruthyAndNonEmpty(perCustomerPermissionsFormValues)) {
    updatedPerCustomerPermissions = getPerCustomerPermissions({
      state,
      userIdIfNotCurrentUser: userObject.id,
    })
  } else {
    const updatedPerCustomerPermissionsMap = Object.keys(perCustomerPermissionsFormValues).reduce(
      (acc, operateForCustomerId) => ({
        ...acc,
        [operateForCustomerId]: mergePermissionsObjects([
          acc[operateForCustomerId],
          createUpdatedPermissionsMapForIndividualCustomerBasedOnFormValues({
            formValuesForThisCustomer: perCustomerPermissionsFormValues[operateForCustomerId],
            isThisForAPerCustomerPermissionsCustomer: true,
            rootCustomerType,
          }),
        ]),
      }),
      {},
    )

    // CODE_COMMENTS_173
    const updatedPerCustomerPermissionsMapWithInventoryValuesPotentiallyModified =
      grantOrRevokePerCustomerInventoryPermissionsBasedOnRootCustomerInventoryPermissions({
        state,
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        entireParentChildLinksSlice,
        entireCurrentUserSlice,
        entireUsersSlice,
        entirePermissionsSlice,
        userObject,
        rootCustomerPermissionsMap: updatedRootCustomerPermissionsMap,
        perCustomerPermissionsMap: updatedPerCustomerPermissionsMap,
      })

    updatedPerCustomerPermissions = convertEntirePerCustomerPermissionsMapBackToPerCustomerPermissions(
      updatedPerCustomerPermissionsMapWithInventoryValuesPotentiallyModified,
    )
  }

  return {
    permissions: updatedRootCustomerPermissions,
    perCustomerPermissions: updatedPerCustomerPermissions,
  }
}


/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

// Returns the following object:
// {
//   ownPermissionsFormValues: {
//     cb1234--_--MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS: true,
//     cb1234--_--MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS: true,
//     ...
//   },
//   perCustomerPermissionsFormValues: {
//     b1234: {
//       b1234--_--MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS: true
//       b1234--_--MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS: true
//       ...
//     },
//     b5678: {
//       b5678--_--MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS: true
//       b5678--_--MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS: true
//       ...
//     },
//     ...
//   },
// }
function separateRootCustomerPermissionsFormValuesAndPerCustomerPermissionsFormValues({
  rootCustomerIdOfUser,
  formValues,
}) {
  const ownPermissionsFormValues = {}
  let perCustomerPermissionsFormValues = {}
  Object.keys(formValues).forEach(fieldName => {
    const {
      rootCustomerIdOrOperateForCustomerId,
    } = parseEditUserPermissionsFormFieldName(fieldName)
    if (rootCustomerIdOrOperateForCustomerId === rootCustomerIdOfUser) {
      ownPermissionsFormValues[fieldName] = formValues[fieldName]
    } else {
      perCustomerPermissionsFormValues = merge_(
        perCustomerPermissionsFormValues,
        {
          [rootCustomerIdOrOperateForCustomerId]: {
            [fieldName]: formValues[fieldName],
          },
        },
      )
    }
  })

  return {
    ownPermissionsFormValues,
    perCustomerPermissionsFormValues,
  }
}


// can be used for either root customers or individual perCustomerPermissions
// customers. If used for perCustomerPermissions customers, the returned map
// will not automatically include any UNIVERSAL_PERMISSIONS.
export function createUpdatedPermissionsMapForIndividualCustomerBasedOnFormValues({
  formValuesForThisCustomer,
  isThisForAPerCustomerPermissionsCustomer,
  rootCustomerType,
}) {
  return Object.keys(formValuesForThisCustomer).reduce(
    (acc, fieldName) => {
      const {
        manageUsersPermissionsChoice,
      } = parseEditUserPermissionsFormFieldName(fieldName)
      const hasBeenGrantedPermissionInForm = formValuesForThisCustomer[fieldName]
      if (hasBeenGrantedPermissionInForm) {
        return mergePermissionsObjects([
          acc,
          getAllPermissionsNeededForManageUsersPermissionsChoice(
            manageUsersPermissionsChoice,
          ),
        ])
      }
      return acc
    },
    isThisForAPerCustomerPermissionsCustomer
      ? {}
      : getUniversalPermissionsObjectByRootCustomerType(rootCustomerType),
  )
}


/*
 * *****************************************************************************
 * Granting/revoking inventory per customer permissions to operate-on-behalf-of
 * customers--CODE_COMMENTS_173
 * *****************************************************************************
*/


// CODE_COMMENTS_173
function grantOrRevokePerCustomerInventoryPermissionsBasedOnRootCustomerInventoryPermissions({
  state,
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entireParentChildLinksSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  userObject,
  rootCustomerPermissionsMap,
  perCustomerPermissionsMap,
}) {
  const fullMap = {
    [PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]: rootCustomerPermissionsMap,
    [PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]: perCustomerPermissionsMap,
  }
  const customerType = getCustomerProp(state, userObject.rootCustomerId, 'customerType')
  const createInventoryPermissionsChoice = {
    [CUSTOMER_TYPES_CONTRACT_BREWER]: MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,
    [CUSTOMER_TYPES_MASTER]: MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER,
  }[customerType]
  const viewInventoryPermissionsChoice = {
    [CUSTOMER_TYPES_CONTRACT_BREWER]: MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER,
    [CUSTOMER_TYPES_MASTER]: MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_MASTER,
  }[customerType]

  const inEditFormWasUserGivenRootCustomerPermissionsToCreateEditInventoryReports =
    getHasPermissionsToPerformManageUsersPermissionsChoice({
      state,
      customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore: fullMap,
      permissionsChoice: createInventoryPermissionsChoice,
    })

  const inEditFormWasUserGivenRootCustomerPermissionsToViewInventoryReportHistory =
    getHasPermissionsToPerformManageUsersPermissionsChoice({
      state,
      customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore: fullMap,
      permissionsChoice: viewInventoryPermissionsChoice,
    })
  const createEditInventoryReportsChangeFn = inEditFormWasUserGivenRootCustomerPermissionsToCreateEditInventoryReports
    ? grantPerCustomerPermissionsChoiceToEveryContractee
    : revokePerCustomerPermissionsChoiceFromEveryContractee

  const viewInventoryReportHistoryChangeFn = inEditFormWasUserGivenRootCustomerPermissionsToViewInventoryReportHistory
    ? grantPerCustomerPermissionsChoiceToEveryContractee
    : revokePerCustomerPermissionsChoiceFromEveryContractee

  const altered1 = createEditInventoryReportsChangeFn({
    state,
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireParentChildLinksSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entirePermissionsSlice,
    perCustomerPermissionsMap,
    permissionsChoice: createInventoryPermissionsChoice,
    userObject,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true,
  })
  const altered2 = viewInventoryReportHistoryChangeFn({
    state,
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireParentChildLinksSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entirePermissionsSlice,
    perCustomerPermissionsMap: altered1,
    permissionsChoice: viewInventoryPermissionsChoice,
    userObject,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true,
  })

  return altered2
}


// helper functions for this section

function grantPerCustomerPermissionsChoiceToEveryContractee({
  state,
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  perCustomerPermissionsMap,
  permissionsChoice,
  userObject,
  onlyRelationshipsThatShipmentsCanBeReportedOn,
}) {
  const contracteeIds = getAllContracteeIdsForGrantingOrRevokingPerCustomerPermissionsChoiceToEveryContractee({
    state,
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    userObject,
    onlyRelationshipsThatShipmentsCanBeReportedOn,
  })

  return contracteeIds.reduce(
    (acc, contracteeId) => (
      createUpdatedPerCustomerPermissionsMapByGrantingPermissionsForAnManageUsersPermissionsChoiceToASingleCustomer({
        entirePermissionsSlice,
        entireCurrentUserSlice,
        userIdIfNotCurrentUser: userObject.id,
        customPerCustomerPermissionsMapToUseInsteadOfMapInReduxStore: acc,
        manageUsersPermissionsChoice: permissionsChoice,
        customerIdOfCustToOperateOnBehalfOf: contracteeId,
      })
    ),
    perCustomerPermissionsMap,
  )
}


function revokePerCustomerPermissionsChoiceFromEveryContractee({
  state,
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entireParentChildLinksSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  perCustomerPermissionsMap,
  permissionsChoice,
  userObject,
  onlyRelationshipsThatShipmentsCanBeReportedOn,
}) {
  const contracteeIds = getAllContracteeIdsForGrantingOrRevokingPerCustomerPermissionsChoiceToEveryContractee({
    state,
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    userObject,
    onlyRelationshipsThatShipmentsCanBeReportedOn,
  })

  return contracteeIds.reduce(
    (acc, contracteeId) => (
      createUpdatedPerCustomerPermissionsMapByRevokingPermissionsForAnManageUsersPermissionsChoiceFromASingleCustomer({
        entireCustomersSlice,
        entireParentChildLinksSlice,
        entireCurrentUserSlice,
        entireUsersSlice,
        entirePermissionsSlice,
        userIdIfNotCurrentUser: userObject.id,
        customPerCustomerPermissionsMapToUseInsteadOfMapInReduxStore: acc,
        manageUsersPermissionsChoice: permissionsChoice,
        customerIdOfCustToOperateOnBehalfOf: contracteeId,
      })
    ),
    perCustomerPermissionsMap,
  )
}


function getAllContracteeIdsForGrantingOrRevokingPerCustomerPermissionsChoiceToEveryContractee({
  state,
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  userObject,
  onlyRelationshipsThatShipmentsCanBeReportedOn,
}) {
  let conbrwIdsWhoseContracteesMustBeIncluded
  const customerType = getCustomerProp(state, userObject.rootCustomerId, 'customerType')
  if (customerType === CUSTOMER_TYPES_MASTER) {
    conbrwIdsWhoseContracteesMustBeIncluded = getAllChildCustomerIdsOfCustomer({
      state,
      customerId: userObject.rootCustomerId,
      onlyCustomersWhoAreNotCurrentlyInactive: true,
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
    })
  } else {
    // Must be a contract brewer
    conbrwIdsWhoseContracteesMustBeIncluded = [userObject.rootCustomerId]
  }

  return flow_(
    mapFp_(conbrwCustomerId => (
      uniq_( // CODE_COMMENTS_216
        getCustomerIdsOfAllRelatedFrom({
          entireCustomersSlice,
          entireContractsSlice,
          entireRelationshipsSlice,
          customerId: conbrwCustomerId,
          customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
          onlyRelationshipsThatShipmentsCanBeReportedOn,
        }),
      )
    )),
    flattenFp_,
    uniqFp_,
  )(conbrwIdsWhoseContracteesMustBeIncluded)
}
