import get_ from 'lodash/get'
import isArray_ from 'lodash/isArray'

import setFp_ from 'lodash/fp/set'


// The idea here is to validate and transform a single object from the backend.
// So if you make a call for an array of shipment objects, you'll run this
// function on every single shipment object in the array.

// Returns an object with three props:
// * validatedData: a transformed version of the passed-in `data` having been
//   run through the `transformerFn` functions passed in (`data` is only run
//   through the `transformerFn` functions if the `validatorFn` does not pass at
//   first).
// * canDataBeSavedToStore: true or false
// * arrayOfProblemsWithData: an array of strings describing what's wrong with
//   the data. Will be an empty array if there's nothing wrong with the data.
export function createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
  data,
  individualPropsValidatorsAndTransformers,
  ...rest
}) {
  if (!individualPropsValidatorsAndTransformers) { return data }

  let validatedData = data
  let canDataBeSavedToStore = true
  let arrayOfProblemsWithData = []

  // run validator functions next
  individualPropsValidatorsAndTransformers.forEach(({
    propPath,
    validatorFn,
    transformerFn,
    doNotSaveApiResponseDataToStoreIfValidationFails,
  // eslint-disable-next-line consistent-return
  }) => {
    if (validatorFn) {
      let isPropValidOrProblemsWithProp = validatorFn({
        data: get_(validatedData, propPath),
        originalObj: data,
        ...rest,
      })

      if (isPropValidOrProblemsWithProp !== true && transformerFn) {
        validatedData = setFp_(
          propPath,
          transformerFn({
            data: get_(data, propPath),
            ...rest,
          }),
          data,
        )
        // redo the validation
        isPropValidOrProblemsWithProp = validatorFn({
          data: get_(validatedData, propPath),
          originalObj: data,
          ...rest,
        })
      }
      if (isPropValidOrProblemsWithProp !== true) {
        if (isArray_(isPropValidOrProblemsWithProp)) {
          arrayOfProblemsWithData = [
            ...arrayOfProblemsWithData,
            ...isPropValidOrProblemsWithProp.map(str => `${propPath}: ${str}`),
          ]
        } else { // must be string
          arrayOfProblemsWithData.push(`${propPath}: ${isPropValidOrProblemsWithProp}`)
        }
        if (doNotSaveApiResponseDataToStoreIfValidationFails) {
          canDataBeSavedToStore = false
        }
      }
    }
  })

  return {
    validatedData,
    canDataBeSavedToStore,
    arrayOfProblemsWithData,
  }
}


// Rather than 'And' logic: Runs each of the validator functions and if one of
// them is valid (i.e. returns true), returns true. If none are valid, returns
// an array of strings explaining the value's problems.
export function validatorCombinerWithOrLogic(...validatorFunctions) {
  return props => {
    let problems = []
    // eslint-disable-next-line no-restricted-syntax
    for (const validatorFn of validatorFunctions) {
      const result = validatorFn(props)
      if (result === true) { return true }
      if (isArray_(result)) {
        problems = [...problems, ...result]
      } else {
        // must be an string
        problems.push(result)
      }
    }
    return problems
  }
}
