import React, { useState } from 'react'
import {
  Grid,
  Embed,
  List,
  Segment,
} from 'semantic-ui-react'

import './video-playlist-styles.css'


function VideoPlaylist({
  videos,
}) {
  const [activeId, setActiveId] = useState(videos[0].youtubeId)
  return (
    <Grid className="video-playlist-grid" columns={2}>
      <Grid.Row>
        <Grid.Column
          computer={11}
          wideScreen={11}
          largeScreen={11}
          tablet={16}
          mobile={16}
        >
          <Embed
            id={activeId}
            // placeholder='/images/image-16by9.png'
            source="youtube"
            hd
            iframe={{
              allowFullScreen: true,
            }}
          />
        </Grid.Column>
        <Grid.Column
          computer={5}
          wideScreen={5}
          largeScreen={5}
          tablet={16}
          mobile={16}
        >
          <Segment basic className="playlist-section">
            <List relaxed>
              {videos.map(({ name, youtubeId, length }) => (
                <List.Item
                  as='a'
                  className={`video-playlist-item${youtubeId === activeId ? ' video-playlist-item-selected' : ''}`}
                  onClick={() => { setActiveId(youtubeId) }}
                >
                  <List.Icon name='youtube' size='large' color="red" verticalAlign='middle' />
                  <List.Content>
                    <List.Header>{name}</List.Header>
                    {length && <List.Description>{length}</List.Description>}
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default VideoPlaylist
