/* eslint-disable max-len */

import { connect } from 'react-redux'


import CustomerPortal from './components'

import { getEntireSlice as getEntireCustomersSlice } from '../../../../../redux/selectors/rewrite/customers'
import { getShouldViewAllInvoicesBeRendered } from '../util'


import {
  getShouldReportedInventoryVsCalculatedInventoryPageBeRendered,
} from '../../../../../redux/selectors/reportedInventoryVsCalculatedInventory'


const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps

  const entireCustomersSlice = getEntireCustomersSlice(state)

  const shouldViewAllInvoicesBeRendered = getShouldViewAllInvoicesBeRendered({
    entireCustomersSlice,
    customerId,
  })

  const shouldReportedInventoryVsCalculatedInventoryPageBeRendered =
    getShouldReportedInventoryVsCalculatedInventoryPageBeRendered({
      state,
      customerId,
    })

  return {
    // Why Booleans? See CODE_COMMENTS_252
    shouldViewAllInvoicesBeRendered: Boolean(shouldViewAllInvoicesBeRendered),
    shouldReportedInventoryVsCalculatedInventoryPageBeRendered: Boolean(shouldReportedInventoryVsCalculatedInventoryPageBeRendered),
  }
}

export default connect(
  mapStateToProps,
)(CustomerPortal)
