// The purpose of this file is to make sure that, if the user is a MASTER user,
// the contractees of each of a Master's CONBRW children have been successfully
// fetched, that way we have a complete list of contractees to render in the
// perCustomerPermissions accordion. See CODE_COMMENTS_159 for details.

import { connect } from 'react-redux'


import UserPermissions from './components'

import {
  getAllChildCustomerIdsOfCustomer,
} from '../../../../../../redux/selectors/rewrite/children'

import prelimInfoFetchStatusesSelectors from '../../../../../../redux/selectors/fetchStatuses/customers/preliminaryInfo'

import {
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../../../../constants'

import {
  determineRootCustomerIdOfUser,
} from '../../../../../../utils/permissions'
import {
  isTruthyAndNonEmpty,
  createAggregateFetchStatuses,
} from '../../../../../../utils'


const { getFetchStatuses: getPrelimInfoFetchStatuses } = prelimInfoFetchStatusesSelectors


const mapStateToProps = (
  state,
  {
    userObject,
    rootCustomerType,
    entireCustomersSlice,
    entireParentChildLinksSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
  },
) => {
  if (rootCustomerType !== CUSTOMER_TYPES_MASTER) {
    return {}
  }

  const rootCustomerId = determineRootCustomerIdOfUser({
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser: userObject.id,
  })

  const conbrwChildIds = getAllChildCustomerIdsOfCustomer({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId: rootCustomerId,
    onlyCustomersWhoAreNotCurrentlyInactive: true,
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
  })

  // Master user has no CONBRW children
  if (!isTruthyAndNonEmpty(conbrwChildIds)) {
    return {
      hasConbrwChildIds: false,
    }
  }

  const {
    hasFetchBeenAttempted: hasFetchBeenAttemptedAggregateConbrwChildren,
    isFetching: isFetchingAggregateConbrwChildren,
    didFetchSucceed: didFetchSucceedAggregateConbrwChildren,
    didFetchFail: didFetchFailAggregateConbrwChildren,
  } = getAggregateFetchStatusesOfContracteesOfAllChildConbrwCustomersOfMaster({ state, conbrwChildIds })

  const toReturn = {
    hasConbrwChildIds: true,
    hasFetchBeenAttemptedAggregateConbrwChildren,
    isFetchingAggregateConbrwChildren,
    didFetchSucceedAggregateConbrwChildren,
    didFetchFailAggregateConbrwChildren,
  }

  return toReturn
}

export default connect(
  mapStateToProps,
)(UserPermissions)


// Returns this object:
// {
//   hasFetchBeenAttempted: true,
//   isFetching: true,
//   didFetchSucceed: false,
//   didFetchFail: false,
// }
function getAggregateFetchStatusesOfContracteesOfAllChildConbrwCustomersOfMaster({
  state,
  conbrwChildIds,
}) {
  const fetchStatusObjects = conbrwChildIds.reduce(
    (acc, conbrwCustomerId) => ([
      ...acc,
      // You may be tempted to call getRelatedFromInfoFetchStatuses() here
      // rather than getPrelimInfoFetchStatuses(), because technically all we're
      // concerned about is the relatedFrom info. Resist that temptation,
      // because what if the preliminary info starts fetching for the child
      // CONBRW user but fails before even getting to the /relatedFrom call
      // (e.g. fails on the :childConbrwCustomerId/customers call)? If you only
      // call getRelatedFromInfoFetchStatuses(), the UI will be stuck in an
      // infinite "fetching permissions..." state because the relatedFrom call
      // hasn't even had the chance to start and fail.
      getPrelimInfoFetchStatuses(state, conbrwCustomerId),
    ]),
    [],
  )
  return createAggregateFetchStatuses({
    fetchStatusObjects,
  })
}
