import React, { Suspense } from 'react'
import { I18nextProvider } from 'react-i18next'

import i18nConfig from './common-components/Internationalization/i18n'
import CatastrophicFailurePage from './features/CatastrophicFailurePage'
import CatastrophicFailureComponentBoundary from './common-components/catastrophicFailure/CatastrophicFailureComponentBoundary'
import StartDownloadLatestWebAppVersionThread from './common-components/StartDownloadLatestWebAppVersionThread'
import RecordTimeOfMostRecentUserActivity from './features/RecordTimeOfMostRecentUserActivity'
import LayoutDecider from './features/layouts/LayoutDecider'
import CaptureRouteNotFound from './common-components/routes/CaptureRouteNotFound'
import ReplaceMultipleSlashesInPathWithSingle from './common-components/routes/ReplaceMultipleSlashesInPathWithSingle'
import RootRoutes from './common-components/routes/RootRoutes'

// loading component for suspense fallback
// const Loader = () => (
//   <div style={{ textAlign: 'center' }}>
//     <div>loading...</div>
//   </div>
// )


const App = () => (
  <Suspense fallback={null}>
    <I18nextProvider i18n={i18nConfig}>
      <CatastrophicFailurePage>
        <CatastrophicFailureComponentBoundary>
          <RecordTimeOfMostRecentUserActivity>
            <StartDownloadLatestWebAppVersionThread />
            <ReplaceMultipleSlashesInPathWithSingle> {/* CODE_COMMENTS_161 */}
              <LayoutDecider>
                <CaptureRouteNotFound> {/* CODE_COMMENTS_4 */}
                  <RootRoutes />
                </CaptureRouteNotFound>
              </LayoutDecider>
            </ReplaceMultipleSlashesInPathWithSingle>
          </RecordTimeOfMostRecentUserActivity>
        </CatastrophicFailureComponentBoundary>
      </CatastrophicFailurePage>
    </I18nextProvider>
  </Suspense>
)

export default App
