import React from 'react'

import MasterAccountDashboard from '../customerTypeSpecificDashboards/MasterAccountDashboard'
import BrewerDashboard from '../customerTypeSpecificDashboards/BrewerDashboard'
import ContractBrewerDashboard from '../customerTypeSpecificDashboards/ContractBrewerDashboard'
import ContracteeBrewerDashboard from '../customerTypeSpecificDashboards/ContracteeBrewerDashboard'
import DistributorDashboard from '../customerTypeSpecificDashboards/DistributorDashboard'
import RentalCustomerDashboard from '../customerTypeSpecificDashboards/RentalCustomerDashboard'
import WarehouseDashboard from '../customerTypeSpecificDashboards/WarehouseDashboard'
import MaintenanceFacilityDashboard from '../customerTypeSpecificDashboards/MaintenenceFacilityDashboard'

import {
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_WAREHOUSE,
  CUSTOMER_TYPES_MAINTENANCE_FACILITY,
} from '../../../constants'


const dashboardsOfCustomerTypes = {
  [CUSTOMER_TYPES_MASTER]: MasterAccountDashboard,
  [CUSTOMER_TYPES_BREWER]: BrewerDashboard,
  [CUSTOMER_TYPES_CONTRACT_BREWER]: ContractBrewerDashboard,
  [CUSTOMER_TYPES_DISTRIBUTOR]: DistributorDashboard,
  [CUSTOMER_TYPES_WAREHOUSE]: WarehouseDashboard,
  [CUSTOMER_TYPES_MAINTENANCE_FACILITY]: MaintenanceFacilityDashboard,
}

export default ({
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  customerType,
  isRentalCustomer,
}) => {
  let DashboardComponent
  if (isRentalCustomer) {
    DashboardComponent = RentalCustomerDashboard
  } else if (operatingContractBrewerCustomerId) {
    DashboardComponent = ContracteeBrewerDashboard
  } else {
    DashboardComponent = dashboardsOfCustomerTypes[customerType]
  }

  return (
    <DashboardComponent
      customerId={customerId}
      customerType={customerType}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId} // CODE_COMMENTS_88
    />
  )
}
