import { call, put, select } from 'redux-saga/effects'

import {
  getProp as getAuthenticationProp,
} from '../../../selectors/authentication'

import {
  LOGOUT,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'

import {
  fetchNewUserToken,
  FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED,
} from './fetchNewUserToken'

import {
  getIsUserTokenExpired,
} from '../../../../utils'


// This doesn't need to be a cancelableForkedSaga() because it gets called
// directly as a blocking call by the tasksToPerformImmediatelyAfterLogin saga
export default function* fetchNewUserTokenIfCurrentUserTokenExpired() {
  const decodedUserToken = yield select(getAuthenticationProp, 'decodedUserToken')
  const isUserTokenExpired = getIsUserTokenExpired(decodedUserToken.exp)

  if (isUserTokenExpired) {
    // if the user token is expired, try to refresh it (we can do this? Yep,
    // see CODE_COMMENTS_209)
    const result = yield call(fetchNewUserToken)
    if (result !== FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED) {
      yield put(createAction(LOGOUT))
    }
  }
}
