// CODE_COMMENTS_117. This is retries, not total tries. 2 seems to not be enough,
// but 3 almost always is.
export const API_FETCH_TIMEOUT_NUM_OF_RETRY_ATTEMPTS = 3
// Originally we put this to 5 seconds, but Tad observes that the chance of a
// retry working with a 5 second delay is very small. Perhaps we need a 15-20
// second delay, but perhaps it needs to be even longer. The closer the delay
// gets to 30 seconds, the more meaningless this delay becomes. For this reason,
// Tad has changed this to 0 seconds, with the expectation that for most 504s,
// the first retry will also encounter a 504; only the second retry will work.
export const API_FETCH_TIMEOUT_RETRY_DELAY = 0 // in seconds

// CODE_COMMENTS_76
export const API_FETCH_FALSE_4XX_AND_5XX_ERRORS_NUM_OF_RETRY_ATTEMPTS = API_FETCH_TIMEOUT_NUM_OF_RETRY_ATTEMPTS
// a timeout of a couple seconds really is necessary on false 501s, otherwise
// you'll get multiple false 501s in a row
export const API_FETCH_NOT_IMPLEMENTED_RETRY_DELAY = 2 // in seconds
export const API_FETCH_NETWORK_CONNECTION_ISSUE_NUM_OF_RETRY_ATTEMPTS = API_FETCH_TIMEOUT_NUM_OF_RETRY_ATTEMPTS
export const API_FETCH_NETWORK_CONNECTION_ISSUE_RETRY_DELAY = API_FETCH_NOT_IMPLEMENTED_RETRY_DELAY

// CODE_COMMENTS_117
export const API_FETCH_TIMEOUT_POLLING_POLL_RETRY_DELAY = 2 // in seconds
export const API_FETCH_TIMEOUT_POLLING_STOP_POLLING_AFTER_X_MINUTES = 5

// LOGIN. Both the login form and the Forgot Password form will use these.
export const LOGIN_EMAIL_MAX_LENGTH = 100 // CODE_COMMENTS_192
export const LOGIN_PASSWORD_MIN_LENGTH = 8
export const LOGIN_PASSWORD_MAX_LENGTH = LOGIN_EMAIL_MAX_LENGTH

export const FORGOT_PASSWORD_CONFIRMATION_CODE_MAX_LENGTH = 75

// shows up when we want to display contact info to a user but we don't have any
// info on specific contact reps for that particular user (which would happen if
// the fetching of the specific contact reps info failed or if the user is
// logged out)
export const GENERIC_CONTACT_INFO_NAME = 'Support'
export const GENERIC_CONTACT_INFO_PHONE = '(800) 245-2200'
export const GENERIC_CONTACT_INFO_EMAIL = 'tap3support@microstarkegs.com'


// It's possible MicroStar's leadership might want to temporarily hide the Pay
// Balances feature (for instance, if something goes wrong on the side of the
// vendor that processes payments, which is currently CyberSource). That can be
// done easily with this simple flag. (Note: if a user has no permissions to pay
// their balances, they still won't see the feature even if this flag is
// set to false; this flag only ignores permissions when disabling the feature,
// not enabling it.)
export const TEMPORARILY_DISABLE_PAY_BALANCES_FEATURE = false
// For the Pay Balance feature
export const CYBER_SOURCE_PAYMENTS_URL =
  // 'https://testsecureacceptance.cybersource.com/pay' // eval and test accounts
  'https://secureacceptance.cybersource.com/pay' // live/prod account
// It's possible we might want to keep the Pay Balances feature enabled but
// disable the Save-Bank-Account/Use-Saved-Bank-Account feature
export const TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE = true
// If the Save-Bank-Account/Use-Saved-Bank-Account feature is disabled, should
// we render nothing at all in place of the feature or a "Using saved bank
// accounts is temporarily disabled" messsage? (This only has an effect if
// TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE is true)
export const DISPLAY_SAVE_BANK_ACCOUNT_FEATURE_TEMPORARILY_DISABLED_MESSAGE_RATHER_THAN_NOTHING_AT_ALL = false

// CODE_COMMENTS_227
export const AFTER_CATASTROPHIC_FAILURE_SHOULD_RELOAD_APP_BUTTON_HARD_REFRESH_BROWSER_PAGE = true

// CODE_COMMENTS_233
export const NUM_KEGS_TO_SHOW_LARGE_SHIPMENT_REPORT_INPUT_WARNING = 800

export const TEMPORARILY_DISABLE_CONTACTS_FEATURE = false

// CODE_COMMENTS_238
export const POLL_BACKEND_EVERY_X_MINUTES_WHILE_IN_MAINTENANCE_MODE = 1
export const DONT_POLL_BACKEND_FOR_THE_FIRST_X_MINUTES_OF_A_SCHEDULED_MAINTENANCE_WINDOW = 15
export const POLL_BACKEND_EVERY_X_MINUTES_FOR_MAINTENANCE_SCHEDULE = 60
export const CHECK_EVERY_X_MINUTES_WHETHER_A_NOTIFICATION_OF_UPCOMING_MAINTENANCE_SHOULD_BE_DISPLAYED = 10
export const DISPLAY_NOTIFICATION_OF_UPCOMING_MAINTENANCE_X_HOURS_BEFORE_IT_STARTS = 48
export const CHECK_EVERY_X_SECONDS_WHETHER_TO_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE = 15

// CODE_COMMENTS_244: This should be at least 1 month and a week--long enough to
// account for Brewers frantically entering all their shipments on the 3rd of
// the month to make Month End.
export const CHECK_FOR_DUPLICATE_SHIPMENTS_IN_REPORT_SHIPMENTS_FORMS_FROM_AS_FAR_BACK_AS_X_DAYS_AGO = 45

export const REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_MAX_NUM_PAST_MONTHS_TO_DISPLAY = 12

export const POLL_BACKEND_EVERY_X_MINUTES_FOR_LATEST_WEB_APP_VERSION = 10
// When a new version of the web app is released, the browser doesn't always
// download it upon hard refresh; sometimes you need to refresh several times;
// and in fact, sometimes several refreshes doesn't even do it, you need to just
// wait several minutes (sometimes several hours!). (This is almost certainly a
// caching issue on the part of Amazon CloudFront and is a headache the dev team
// has dealt with since the release of TAP3.) When the web app detects that
// there's a new version of itself to download, it will hard-refresh itself at
// most this many times before giving up, logging a Sentry error, and allowing
// the end user to continue using the old version of the web app.
export const TRY_TO_DOWNLOAD_NEW_VERSION_OF_WEB_APP_AT_MOST_X_TIMES_IN_A_ROW = 8
export const ONLY_DOWNLOAD_LATEST_VERSION_OF_WEB_APP_WHEN_NO_USER_ACTIVITY_FOR_LAST_X_MINUTES = 10
