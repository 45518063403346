// CODE_COMMENTS_205
import React from 'react'

import Form from './Form'
import Table from './Table'


import {
  HISTORY_FORM_NAME_OUTBOUND_EMPTY_KEG_SHIPMENTS,
} from '../../../../constants'

const formReducerName = HISTORY_FORM_NAME_OUTBOUND_EMPTY_KEG_SHIPMENTS

export default props => (
  <div>
    <Form
      formReducerName={formReducerName}
      {...props}
    />
    <Table
      {...props}
      formReducerName={formReducerName}
    />
  </div>
)
