import React from 'react'

import NavBar from '../../NavBar'
import Breadcrumbs from '../../Breadcrumbs'
import WaitForPrelimCustomerInfo from './containers/WaitForPrelimCustomerInfo'
import TasksToPerformImmediatelyAfterLogin from '../../../common-components/TasksToPerformImmediatelyAfterLogin'
import GetBackToEmployeeSectionWhenOperatingAsCustomerUser from '../../employee/GetBackToEmployeeSectionWhenOperatingAsCustomerUser'
import MaintenanceModeSplashScreen from '../../MaintenanceModeSplashScreen'

export default ({ children }) => (
  <MaintenanceModeSplashScreen>
    <GetBackToEmployeeSectionWhenOperatingAsCustomerUser>
      <main>
        <NavBar />
        <TasksToPerformImmediatelyAfterLogin>
          <WaitForPrelimCustomerInfo>
            <Breadcrumbs />
            {children}
          </WaitForPrelimCustomerInfo>
        </TasksToPerformImmediatelyAfterLogin>
      </main>
    </GetBackToEmployeeSectionWhenOperatingAsCustomerUser>
  </MaintenanceModeSplashScreen>
)
