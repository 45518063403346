import sortBy_ from 'lodash/sortBy'
import uniqBy_ from 'lodash/uniqBy'
import get_ from 'lodash/get'
import cloneDeep_ from 'lodash/cloneDeep'

import {
  getContracts,
} from '../../../../../../../redux/selectors/rewrite/contracts'
import {
  getMultipleCustomerPropsOfAllRelatedTo,
} from '../../../../../../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getMultipleCustomerPropsOfAllChildren,
} from '../../../../../../../redux/selectors/rewrite/children'
import {
  getAllConbrwCustomerObjsBrewerHasCustomerLevelPermissionsToReportShipmentsFor,
} from '../../../../../../../redux/selectors/rewrite/permissionsAtTheCustomerLevel'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CONBRW,
} from '../../../../../../../constants'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  isTruthyAndNonEmpty,
} from '../../../../../../../utils'


const propNamesNeededByDropdownOptionDefinition = ['id', 'name', 'customerType']


// Returns an array of objects used to define each of the field arrays in a
// non-contractee Brewer's Report Outbound Shipments form. See the docstring of
// the function that calls this one for details on the structure of the returned
// objects.
export function createCustomersFieldsDropdownOptionsForShipmentFormsBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsDefaultBrwPpf,
  customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsConbrwPpf,
}) {
  const brewerPpfContractDropdownOptions =
    createDefaultBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond:
        customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsDefaultBrwPpf,
    })

  const allContractBrewerPpfContractDropdownOptions =
    getAllContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond:
        customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsConbrwPpf,
    })

  const doesBrewerHaveEnabledBrwPpfContractId = isTruthyAndNonEmpty(getContracts({
    entireContractsSlice,
    customerId,
    ppfContractTypes: PPF_CONTRACT_TYPES_BRW,
    notExpiredOnly: false, // CODE_COMMENTS_263: customers are allowed to report shipments on expired contracts
    activeStatusOnly: true,
  }))
  return [
    ...(doesBrewerHaveEnabledBrwPpfContractId ? brewerPpfContractDropdownOptions : []),
    ...allContractBrewerPpfContractDropdownOptions,
  ]
}


// CODE_COMMENTS_188: Returns a one-item array with an object to be included in
// the customersFieldsDropdownOptions:
// [
//   {
//     id: '1111b',
//     name: 'Brewer 1',
//     customerType: 'BRW',
//     choicesForOtherCustomersFieldWhenThisCustomerIsSelected: [
//       {id: '1111d', name: 'Distributor 1', customerType: 'DIST'},
//       {id: '2222d', name: 'Distributor 2', customerType: 'DIST'},
//     ],
//   }
// ]
export function createDefaultBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond,
}) {
  let dropdownOptionsOfCustomersFieldThatMustBeChosenSecond = getMultipleCustomerPropsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    propNames: propNamesNeededByDropdownOptionDefinition,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112, CODE_COMMENTS_159
    filterByPpfContract: { ppfContractType: PPF_CONTRACT_TYPES_BRW },
    customerObjsCustomFilterFunc: o => (
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond.includes(o.customerType)
    ),
  })

  // CODE_COMMENTS_230
  dropdownOptionsOfCustomersFieldThatMustBeChosenSecond =
    uniqBy_(dropdownOptionsOfCustomersFieldThatMustBeChosenSecond, 'id')
  dropdownOptionsOfCustomersFieldThatMustBeChosenSecond =
    sortBy_(dropdownOptionsOfCustomersFieldThatMustBeChosenSecond, 'name')

  return [
    {
      id: createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        customerId,
      ),
      name: get_(entireCustomersSlice, [customerId, 'name']),
      customerType: CUSTOMER_TYPES_BREWER,
      choicesForOtherCustomersFieldWhenThisCustomerIsSelected:
      cloneDeep_(dropdownOptionsOfCustomersFieldThatMustBeChosenSecond),
    },
  ]
}


// CODE_COMMENTS_188: Returns a one-item array with an object to be included in
// the customersFieldsDropdownOptions:
// [
//   {
//     id: '1111b--__--2222conbrwCustomerId',
//     name: 'Brewer 1',
//     customerType: 'BRW',
//     choicesForOtherCustomersFieldWhenThisCustomerIsSelected: [
//       {id: '1111d', name: 'Distributor 1', customerType: 'DIST'},
//       {id: '2222d', name: 'Distributor 2', customerType: 'DIST'},
//     ],
//   }
// ]
export function createContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  conbrwCustomerId,
  customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond,
  setBrewerOrContractBrewerAsName, // BRW or CONBRW
}) {
  let options = getMultipleCustomerPropsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    propNames: propNamesNeededByDropdownOptionDefinition,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263, CODE_COMMENTS_159
    filterByPpfContract: {
      ppfContractType: PPF_CONTRACT_TYPES_CONBRW,
      conbrwCustomerId,
    },
    customerObjsCustomFilterFunc: o => (
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond.includes(o.customerType)
    ),
  })

  options = uniqBy_(options, 'id') // CODE_COMMENTS_230
  options = sortBy_(options, 'name')

  return [
    {
      id: createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        customerId,
        conbrwCustomerId,
      ),
      name: get_(
        entireCustomersSlice,
        [
          setBrewerOrContractBrewerAsName === CUSTOMER_TYPES_BREWER
            ? customerId
            : conbrwCustomerId,
          'name',
        ],
      ),
      customerType: CUSTOMER_TYPES_BREWER,
      choicesForOtherCustomersFieldWhenThisCustomerIsSelected: options,
    },
  ]
}


// CODE_COMMENTS_188, CODE_COMMENTS_279: Returns a one-item array with an
// object to be included in the customersFieldsDropdownOptions:
// [
//   {
//     id: '2222conbrwCustomerId',
//     name: 'Contract Brewer 1',
//     customerType: 'CONBRW',
//     choicesForOtherCustomersFieldWhenThisCustomerIsSelected: [
//       {id: '1111s', name: 'SelfDist 1', customerType: 'SELFDIST'},
//       {id: '2222s', name: 'SelfDist 2', customerType: 'SELFDIST'},
//     ],
//   }
// ]
export function createSpecialSelfDist2CbmstFieldArrayDropdownOptionsForShipmentFormsBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  conbrwCustomerId,
}) {
  const customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond = [CUSTOMER_TYPES_SELF_DISTRIBUTION]
  const setBrewerOrContractBrewerAsName = CUSTOMER_TYPES_CONTRACT_BREWER

  let options = getMultipleCustomerPropsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    propNames: propNamesNeededByDropdownOptionDefinition,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263, CODE_COMMENTS_159
    filterByPpfContract: {
      ppfContractType: PPF_CONTRACT_TYPES_CONBRW,
      conbrwCustomerId,
    },
    customerObjsCustomFilterFunc: o => (
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond.includes(o.customerType)
    ),
  })

  options = uniqBy_(options, 'id') // CODE_COMMENTS_230
  options = sortBy_(options, 'name')

  return [
    {
      id: createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        conbrwCustomerId,
      ),
      name: get_(
        entireCustomersSlice,
        [
          setBrewerOrContractBrewerAsName === CUSTOMER_TYPES_BREWER
            ? customerId
            : conbrwCustomerId,
          'name',
        ],
      ),
      customerType: CUSTOMER_TYPES_CONTRACT_BREWER,
      choicesForOtherCustomersFieldWhenThisCustomerIsSelected: options,
    },
  ]
}


// CODE_COMMENTS_188: Returns a one-item array with an object to be included in
// the customersFieldsDropdownOptions:
// [
//   {
//     id: '1111b',
//     name: 'Brewer 1',
//     customerType: 'BRW',
//     choicesForOtherCustomersFieldWhenThisCustomerIsSelected: [
//       {id: '1111d', name: 'Self-Collection', customerType: 'COL'},
//     ],
//   }
// ]
export function createCustomersFieldsDropdownOptionsForSelfCollectionShipmentFormsBrwAndConbrw({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerId,
  customerType,
  customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond,
}) {
  let dropdownOptionsOfCustomersFieldThatMustBeChosenSecond = getMultipleCustomerPropsOfAllChildren({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId,
    propNames: propNamesNeededByDropdownOptionDefinition,
    onlyCustomersWhoAreNotCurrentlyInactive: true, // CODE_COMMENTS_112, CODE_COMMENTS_159
    customerObjsCustomFilterFunc: o => (
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond.includes(o.customerType)
    ),
  })

  // CODE_COMMENTS_230
  dropdownOptionsOfCustomersFieldThatMustBeChosenSecond =
    uniqBy_(dropdownOptionsOfCustomersFieldThatMustBeChosenSecond, 'id')
  dropdownOptionsOfCustomersFieldThatMustBeChosenSecond =
    sortBy_(dropdownOptionsOfCustomersFieldThatMustBeChosenSecond, 'name')

  return [
    {
      id: createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        customerId,
      ),
      name: get_(entireCustomersSlice, [customerId, 'name']),
      customerType,
      choicesForOtherCustomersFieldWhenThisCustomerIsSelected:
        dropdownOptionsOfCustomersFieldThatMustBeChosenSecond,
    },
  ]
}


// Helper functions


// CODE_COMMENTS_188
function getAllContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond,
}) {
  const cbObjsBrewerHasCustomerLevelPermissionsToReportShipmentsFor =
    getAllConbrwCustomerObjsBrewerHasCustomerLevelPermissionsToReportShipmentsFor({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
    })

  const cbIdsBrewerHasCustomerLevelPermissionsToReportShipmentsFor =
    cbObjsBrewerHasCustomerLevelPermissionsToReportShipmentsFor.map(o => o.id)

  return cbIdsBrewerHasCustomerLevelPermissionsToReportShipmentsFor.map(conbrwCustomerId => (
    createContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      conbrwCustomerId,
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond,
      setBrewerOrContractBrewerAsName: CUSTOMER_TYPES_CONTRACT_BREWER,
    })[0]
  ))
}
