/* eslint-disable no-restricted-syntax */ // CODE_COMMENTS_50

import { all, call } from 'redux-saga/effects'


import { privateFetch } from '../../fetch'

import {
  generalDoFailure,
} from '../../util/fetchFailure'


export default ({
  createConfigFunc, // CODE_COMMENTS_141
  ...otherSagaCreatorProps
}) => (
  function* saga(action) {
    const props = action.payload

    const {
      setIsFetching,
      setDidFetchFail,
      setDidFetchSucceed,
    } = props

    setIsFetching(true)
    const {
      fetchDefinitions,
      doAfterAllCallsHaveFinished,
    } = yield call(
      createConfigFunc,
      { ...otherSagaCreatorProps, ...props },
    )

    const responses = []

    for (const arrayOfParallelFetchDefs of fetchDefinitions) {
      const results = yield all(arrayOfParallelFetchDefs.map(fetchDefinition => (
        call(individualSubmissionSaga, { ...props, ...fetchDefinition })
      )))


      // If any fetches failed and they don't have a
      // `failureShouldNotResultInTheFailureOfTheEntireSaga` prop set, end the
      // entire saga.
      const didAnyFetchesInThisIterationFail = results.reduce(
        (trueOrFalseSoFar, result, index) => {
          // If a failure has been encountered, no need to check the rest
          if (trueOrFalseSoFar) { return true }

          const { didFetchSucceed } = result
          if (!didFetchSucceed) {
            const fetchDefinition = arrayOfParallelFetchDefs[index]
            const { failureShouldNotResultInTheFailureOfTheEntireSaga } = fetchDefinition
            return !failureShouldNotResultInTheFailureOfTheEntireSaga
          }
          return false
        },
        false,
      )
      if (didAnyFetchesInThisIterationFail) {
        setDidFetchFail(true)
        setDidFetchSucceed(false)
        setIsFetching(false)
        return
      }


      responses.push(results)
    }

    if (doAfterAllCallsHaveFinished) {
      yield call(doAfterAllCallsHaveFinished, { ...props, ...otherSagaCreatorProps, responses })
    }
    setDidFetchFail(false)
    setDidFetchSucceed(true)
    setIsFetching(false)
  }
)


function* individualSubmissionSaga(props) {
  const {
    fetchConfig,
    doFailure,
    doSuccess,
  } = props
  let response
  try {
    response = yield call(privateFetch, fetchConfig)
  } catch (error) {
    if (doFailure) {
      const potentialReplacementResponseBody = yield call(doFailure, { ...props, error })
      if (potentialReplacementResponseBody) {
        return {
          didFetchSucceed: true,
          response: { data: potentialReplacementResponseBody },
        }
      }
    }
    yield call(generalDoFailure, { error })
    return {
      didFetchSucceed: false,
      error,
    }
  }
  if (doSuccess) {
    yield call(doSuccess, { ...props, response })
  }
  return {
    didFetchSucceed: true,
    response,
  }
}
