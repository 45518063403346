import { connect } from 'react-redux'

import ContactRepPage from './components/ContactRepPage'

import {
  getProp,
  getIdsOfNonInactiveAndNonSelfDistAndPubChildren,
} from '../../redux/selectors/customers'

import {
  getProp as getCurrentUserProp,
} from '../../redux/selectors/currentUser'


const mapStateToProps = state => {
  const currentUserCustomerId = getCurrentUserProp(state, 'rootCustomerId')
  const childrenCustomerIds = getIdsOfNonInactiveAndNonSelfDistAndPubChildren(state, currentUserCustomerId)
  const idsOfAllNonSelfDistAndPubCustomersWhoseCustomerInfoHasBeenFetched = [
    // order is important here: make sure the Master customer is first
    currentUserCustomerId,
    ...childrenCustomerIds,
  ]

  const namesAndCustomerIds = (
    idsOfAllNonSelfDistAndPubCustomersWhoseCustomerInfoHasBeenFetched.map(id => ({
      name: getProp(state, id, 'name'),
      id,
    }))
  )

  return ({
    namesAndCustomerIds,
  })
}

export default connect(
  mapStateToProps,
)(ContactRepPage)
