import React, { memo } from 'react'
import { Card, Icon } from 'semantic-ui-react'

/**
 * the choices prop should be an array of objects, each with 'value',
 * 'selected' and 'cardContent' props
 */
export default memo(({
  choices,
  value,
  onChange,
  // the rest of these are if user wants to add additional custom props
  cardGroupProps,
  cardProps,
  cardContentProps,
  cardDescriptionProps,
}) => (
  <Card.Group
    {...cardGroupProps}
    // in other reuseable components, we use {...input} here to pass all the
    // props of 'input' to our custom component. We don't do this here because
    // the props of input include things like 'onChange', 'onBlur',
    // 'onDragStart' and 'onDrop' which mean nothing to a Semantic UI Card
    // component.
  >
    {choices.map(choice => (
      <Card
        link
        value={choice.value}
        onClick={onChange}
        color={choice.value === value ? 'green' : null}
        {...cardProps}
        key={choice.value}
      >
        <Card.Content {...cardContentProps}>
          <Card.Description {...cardDescriptionProps}>
            {choice.value === value &&
              <Icon
                style={{ float: 'right' }}
                name="check circle"
                size="large"
                color="green"
              />
            }
            {choice.cardContent}
          </Card.Description>
        </Card.Content>
      </Card>
    ))}
  </Card.Group>
))
