import { connect } from 'react-redux'


import CollarsTable from './components'

import {
  getEntireSlice as getAllCollarPlates,
} from '../../../../../../../redux/selectors/collarPlates'


const mapStateToProps = state => ({
  collarPlates: getAllCollarPlates(state),
})


export default connect(
  mapStateToProps,
)(CollarsTable)
