/* Makes a modal as easy to use as possible. Feel free to use
* ModalWithCatastrophicFailureErrorBoundary directly if this template doesn't
* suit your needs.
*/

/* eslint-disable prefer-const */

import React, { useState } from 'react'
import { Header, Modal } from 'semantic-ui-react'

import isFunction_ from 'lodash/isFunction'

import ModalWithCatastrophicFailureErrorBoundary from './ModalWithCatastrophicFailureErrorBoundary'

export default props => {
  let {
    // (Required) Renderable component (_component_, not element, i.e.
    // trigger={() => <Button>}, not trigger={<Button>}. The trigger gets passed
    // all the stateful props of this component such as isModalOpen, openModal
    // and closeModal.
    trigger,
    // (Optional) If modalTitle is a string, we simply render a <Header> with
    // sane default styling. If it's a function, we assume it's a component and
    // render it, passing in all the stateful props of this component
    // (isModalOpen, setIsModalOpen) and all the `props` passed to this modal.
    modalTitle,
    // (Required) Renderable component that gets passed all the stateful props
    // of this component such as isModalOpen and openModal
    modalContent,
    // (Optional) The buttons at the bottom. This is renderable component that
    // gets passed all the stateful props of this component such as isModalOpen
    // and setIsModalOpen. From an aesthetic standpoint, I don't like using this
    // for action buttons--the buttons become too distant from the modal
    // content. See the Semantic UI React documentation for details.
    modalActions,
    // (Optional) Sometimes such state may need to be lifted so that other
    // components can open and close the modal. You probably don't need this
    // feature, for 2 reasons:
    //   1. The trigger component gets this component's openModal and closeModal
    //      functions passed to it even when this prop is set to false.
    //   2. If you need to do additional things when the modal closes, you can
    //      pass in your own onClose method and this component will run it
    //      whenever the modal closes; you don't need to set this prop to true
    //      in order to pass in your own onClose method.
    controlThisModalsOpenAndClosedStateFromParentComponent=false,
    // only pass these in if doesThisModalControlItsOwnOpenAndClosedState is false
    isModalOpen,
    closeModal,
    openModal,
    ...modalProps // (Optional) Props on the <Modal> component itself
  } = props

  let setIsModalOpen
  if (!controlThisModalsOpenAndClosedStateFromParentComponent) {
    [isModalOpen, setIsModalOpen] = useState(false)
    openModal = () => setIsModalOpen(true)
    closeModal = () => setIsModalOpen(false)
  }
  let propsToPass = {
    ...props,
    controlThisModalsOpenAndClosedStateFromParentComponent,
    isModalOpen,
    openModal,
    closeModal,
  }

  return (
    <ModalWithCatastrophicFailureErrorBoundary
      open={isModalOpen}
      trigger={trigger(propsToPass)}
      closeIcon
      {...modalProps}
      onClose={() => {
        closeModal()
        if (modalProps.onClose) {
          modalProps.onClose(propsToPass)
        }
      }}
    >
      {modalTitle && (
        isFunction_(modalTitle)
          ? modalTitle(propsToPass)
          : <Header as="h4" content={modalTitle} />
      )}
      <Modal.Content>
        {modalContent(propsToPass)}
      </Modal.Content>
      <Modal.Actions>
        {/* <Button color='green' onClick={this.handleClose} inverted>
          <Icon name='checkmark' /> Got it
        </Button> */}
        {modalActions && modalActions(propsToPass)}
      </Modal.Actions>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}
