/**
 * CODE_COMMENTS_93
 */
import React from 'react'

import orderBy_ from 'lodash/orderBy'


import HistoryTableCreator from '../../../HOC/HistoryTableCreator'
import FillReportDetails from '../subFeatures/FillReportDetails'

import {
  createDisplayedOriginNameForKegFillsContracteeHistoryTable,
} from '../../../util/shipmentHistory/kegFillsContracteeHistory/displayedDataEtc/displayedKegFillsContracteeDataEtc'
import {
  getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
  getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
  getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
} from '../../../util/shipmentHistory/outboundFullKegShipmentHistory'
import {
  createDisplayedShipmentDate,
  createDisplayedProNumber,
  createItemSkuIdFieldsDefinitions,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'
import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'


function createTableContentDefinitions({
  entireItemSkusSlice,
  customerId,
  allKegFillsContracteeHistoryObjects,
  configuredItemSkuIds,
  additionalPropsToPassToTableCells,
}) {
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allKegFillsContracteeHistoryObjects,
  })

  return {
    content: [
      {
        heading: 'Fill #',
        cellContent: row => (
          <FillReportDetails
            entireItemSkusSlice={entireItemSkusSlice}
            customerId={customerId}
            info={row}
            allKegFillsContracteeHistoryObjects={allKegFillsContracteeHistoryObjects}
            configuredItemSkuIds={configuredItemSkuIds}
            additionalPropsToPassToTableCells={additionalPropsToPassToTableCells}
          />
        ),
        customSortInfo: {
          sortFunction: (apiObjs, ascOrDesc) => (orderBy_(apiObjs, ['shipmentId'], [ascOrDesc])),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testShipmentNumber', // CODE_COMMENTS_57
      },
      {
        heading: 'Filled By',
        cellContent: createDisplayedOriginNameForKegFillsContracteeHistoryTable,
        customSortInfo: {
          columnNotSortable: true, // CODE_COMMENTS_248
        },
        className: 'testFilledBy', // CODE_COMMENTS_57
      },
      {
        heading: 'Fill Date',
        cellContent: createDisplayedShipmentDate,
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testFillDate', // CODE_COMMENTS_57
      },
      {
        heading: 'Status',
        cellContent: getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
        className: 'testStatus', // CODE_COMMENTS_57
      },
      {
        heading: 'Reference #',
        cellContent: createDisplayedProNumber(),
        className: 'testReferenceNumber', // CODE_COMMENTS_57
      },
      ...createItemSkuIdFieldsDefinitions({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
      }),
    ],
    defaultSortColumn: 'Fill Date',
    filterFunc: getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
    rowKey: row => row.id,
  }
}

export default ({
  entireItemSkusSlice,
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  allKegFillsContracteeHistoryObjects,
  configuredItemSkuIds,
  isFormSubmitting,
  hasFormSubmitFailed,
  noResultsMessage,
  areAnyItemsEditable,
  getIsindividualItemEditable,
}) => {
  const allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable(
      allKegFillsContracteeHistoryObjects,
    )
  const additionalPropsToPassToTableCells = { customerId, operatingContractBrewerCustomerId }

  const definitions = createTableContentDefinitions({
    entireItemSkusSlice,
    customerId,
    allKegFillsContracteeHistoryObjects,
    configuredItemSkuIds,
    additionalPropsToPassToTableCells,
  })

  return (
    <HistoryTableCreator
      tableInfoObj={allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable}
      definitions={definitions}
      isFormSubmitting={isFormSubmitting}
      hasFormSubmitFailed={hasFormSubmitFailed}
      additionalPropsToPassToTableCells={additionalPropsToPassToTableCells}
      noResultsMessage={noResultsMessage}

      // for Edit and Delete buttons column
      areAnyItemsEditable={areAnyItemsEditable}
      itemType="keg fill report"
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      getIsindividualItemEditable={getIsindividualItemEditable}
    />
  )
}
