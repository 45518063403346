import {
  FETCH_CUSTOMER_CHILDREN_REQUEST,
  FETCH_CUSTOMER_CHILDREN_SUCCESS,
  FETCH_CUSTOMER_CHILDREN_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_CUSTOMER_CHILDREN_REQUEST,
  FETCH_CUSTOMER_CHILDREN_SUCCESS,
  FETCH_CUSTOMER_CHILDREN_FAILURE,
)
