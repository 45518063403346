import React, { Fragment } from 'react'
import moment from 'moment'


import HistoryFormCreator from '../../HOC/HistoryFormCreator'
import withCustomerType from '../../../../../common-components/HOC/redux/withCustomerType'

import {
  SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE,
} from '../../../../../constants/formAndApiUrlConfig/histories/shipmentHistoryShared'

import {
  NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS,
} from '../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
  COMMON_STATUS_ALL,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  ShipmentTypeField,
  HistoryFormStatusField,
} from '../../util/util'

import {
  createFormNameForRedux,
} from '../../../../../utils'


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

const fieldsBeforeDateRange = ({
  statusesConfig,
  customerType,
  inboundOrOutbound,
  formValues,
  dispatchFormValues,
  hideShipmentTypeField,
}) => (
  <Fragment>
    {!hideShipmentTypeField && <ShipmentTypeField
      customerType={customerType}
      inboundOrOutbound={inboundOrOutbound}
      formValues={formValues}
      dispatchFormValues={dispatchFormValues}
    />}
    <HistoryFormStatusField
      statusesConfig={statusesConfig}
      formValues={formValues}
      dispatchFormValues={dispatchFormValues}
    />
  </Fragment>
)


const additionalIsFormSubmittableChecksBesidesDateRanges = () => (
  // no need to check whether shipment status and/or type have a valid value or
  // not, because it's impossible to choose an invalid value.
  true
)


const initialValues = {
  [HISTORY_FORM_FIELD_NAME_START_DATE]:
    moment().subtract(1, 'months').startOf('month').format(DEFAULT_DISPLAYED_DATE_FORMAT),
  [HISTORY_FORM_FIELD_NAME_END_DATE]:
    moment().add(NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS, 'days').format(DEFAULT_DISPLAYED_DATE_FORMAT),
  [HISTORY_FORM_FIELD_NAME_STATUS]: COMMON_STATUS_ALL, // CODE_COMMENTS_61
  [SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE]: COMMON_STATUS_ALL, // CODE_COMMENTS_61
}


function createDateRangeMin() {
  return moment('01/01/1985', 'MM-DD-YYYY')
}

function createDateRangeMax() {
  // CODE_COMMENTS_176
  return moment().clone().add(NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS, 'days')
}

/*
 * *****************************************************************************
 * The exported items
 * *****************************************************************************
*/

export default withCustomerType(props => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    formReducerName,
    submitAction,
    downloadFileComponent,
    dateRangeFieldsLabel='Ship Date',
    formInitialValues=null,
  } = props
  return (
    <HistoryFormCreator
      {...props}
      createDateRangeMin={createDateRangeMin}
      createDateRangeMax={createDateRangeMax}
      dateRangeFieldsLabel={dateRangeFieldsLabel}
      initialValues={formInitialValues || initialValues}
      additionalIsFormSubmittableChecksBesidesDateRanges={additionalIsFormSubmittableChecksBesidesDateRanges}
      submitAction={submitAction}
      fieldsBeforeDateRange={fieldsBeforeDateRange}
      downloadFileComponent={downloadFileComponent}
      formName={createFormNameForRedux({
        reducerName: formReducerName,
        customerId,
        operatingContractBrewerCustomerId,
      })}
    />
  )
})
