/**
 * The purpose of this file is to only conditionally show the table column that
 * contains the "Edit" and "Delete" buttons; only only show the column if one or
 * more items are allowed to be edited/deleted.
 */
import React from 'react'


import MaybeEditAndDeleteButtonsInTables from './MaybeEditAndDeleteButtonsInTables'


export function addEditAndDeleteButtonsColumnToDefinition({
  definitions,
  itemType,
  customerId,
  operatingContractBrewerCustomerId,
  getIsindividualItemEditable,
  additionalPropsToPassToTableCells,
  dontIncludeDeleteButton,
}) {
  const editAndDeleteButtonsColumnInHistoryTable = createEditAndDeleteButtonsColumnInHistoryTable({
    itemType,
    customerId,
    operatingContractBrewerCustomerId,
    getIsindividualItemEditable,
    additionalPropsToPassToTableCells,
    dontIncludeDeleteButton,
  })

  return {
    ...definitions,
    content: [
      // Order is important here: we want our edit/delete buttons column to be
      // the first column in the table.
      ...editAndDeleteButtonsColumnInHistoryTable,
      ...definitions.content,
    ],
  }
}


function createEditAndDeleteButtonsColumnInHistoryTable({
  itemType,
  customerId,
  operatingContractBrewerCustomerId,
  getIsindividualItemEditable,
  additionalPropsToPassToTableCells,
  dontIncludeDeleteButton,
}) {
  return [{
    heading: '', // we don't want the edit/delete table column to have a heading
    cellContent: row => (
      <MaybeEditAndDeleteButtonsInTables
        itemObj={row}
        itemType={itemType}
        customerId={customerId}
        operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
        getIsindividualItemEditable={getIsindividualItemEditable}
        additionalPropsToPassToTableCells={additionalPropsToPassToTableCells}
        dontIncludeDeleteButton={dontIncludeDeleteButton}
      />
    ),
    customSortInfo: { columnNotSortable: true },
    className: 'testEditOrDeleteHistoryItem', // CODE_COMMENTS_57
    additionalSuiTableCellProps: { singleLine: true, collapsing: true },
  }]
}
