import { put, call, takeEvery } from 'redux-saga/effects'


import { privateFetch } from '../fetch'
import {
  getAddressesApiUrl,
} from '../../../utils'
import {
  FETCH_CUSTOMER_ADDRESSES,
  SAVE_ADDRESSES,
  SAVE_CUSTOMER_TO_ADDRESSES_NORMALIZED_LINKS,
  FETCH_CUSTOMER_ADDRESSES_REQUEST,
  FETCH_CUSTOMER_ADDRESSES_SUCCESS,
  FETCH_CUSTOMER_ADDRESSES_FAILURE,
} from '../../actions/actionTypes'


import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'


export function* fetchCustomerAddresses(action) {
  const {
    customerId,
    preventsDashboardFromLoadingIfFails,
  } = action.payload
  yield put(createAction(FETCH_CUSTOMER_ADDRESSES_REQUEST, { target: [customerId] }))
  let addresses
  try {
    addresses = yield call(fetchAddresses, customerId)
  } catch (error) {
    yield call(
      doFailure,
      {
        customerId,
        error,
        preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
      },
    )
    return
  }
  yield call(
    doSuccess,
    customerId,
    addresses,
  )
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CUSTOMER_ADDRESSES, fetchCustomerAddresses],
]


function* fetchAddresses(customerId) {
  let response
  try {
    response = yield call(privateFetch, { path: getAddressesApiUrl(), params: { cid: customerId } })
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return []
    }
    throw error
  }
  return response.data
}


function* doSuccess(customerId, addresses) {
  const addressObjects = addresses.map(o => o.addressObject)
  const calObjects = addresses.map(o => ({
    id: o.id,
    customerId: o.customerId,
    addressId: o.addressObject.id,
    ...o.customerAddressTypeInfoObject,
  }))
  yield put(createAction(SAVE_ADDRESSES, { info: addressObjects }))
  yield put(createAction(SAVE_CUSTOMER_TO_ADDRESSES_NORMALIZED_LINKS, { info: calObjects }))
  yield put(createAction(FETCH_CUSTOMER_ADDRESSES_SUCCESS, { target: [customerId] })) // CODE_COMMENTS_20
}


function* doFailure({ customerId, error, preventsDashboardFromLoading }) {
  yield call(
    generalDoFailure,
    {
      error,
      preventsDashboardFromLoading,
      action: createFetchFailureAction({
        error,
        type: FETCH_CUSTOMER_ADDRESSES_FAILURE,
        target: [customerId],
      }),
    },
  )
}
