import moment from 'moment'

import minBy_ from 'lodash/minBy'


import {
  REDUCER_NAMES_MAINTENANCE_MODE as defaultSlice,
  REDUCER_NAMES_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE,
  REDUCER_NAMES_MAINTENANCE_SCHEDULE,
  REDUCER_NAMES_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503,
  REDUCER_NAMES_MAINTENANCE_MODE_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED,
} from '../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../utils'


export function getAreWeCurrentlyInMaintenanceMode(state) {
  return state[defaultSlice][REDUCER_NAMES_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE]
}

export function getMaintenanceSchedule(state) {
  return state[defaultSlice][REDUCER_NAMES_MAINTENANCE_SCHEDULE]
}

export function getStartAndEndTimesFrom503(state) {
  return state[defaultSlice][REDUCER_NAMES_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503]
}

export function getShouldNotificationOfUpcomingMaintenanceBeDisplayed(state) {
  return state[defaultSlice][REDUCER_NAMES_MAINTENANCE_MODE_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED]
}


// Selectors that don't use state (use these after calling the selectors above)

// returns a 2-item object:
// {
//   start: <moment object>,
//   end: <moment object>,
// }
// If there are no upcoming maintenance windows, return an empy object.
export function getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow({
  maintenanceSchedule,
  includeMaintenanceWindowsWereCurrentlyIn=false,
}) {
  if (!isTruthyAndNonEmpty(maintenanceSchedule)) {
    return {}
  }
  const upcomingMaintenanceWindows = maintenanceSchedule.filter(o => (
    moment().isSameOrBefore(moment(o.startTimeUTC))
    || (
      includeMaintenanceWindowsWereCurrentlyIn
        ? moment().isBetween(moment(o.startTimeUTC), moment(o.endTimeUTC))
        : false
    )
  ))
  if (!isTruthyAndNonEmpty(upcomingMaintenanceWindows)) {
    return {}
  }

  const soonestUpcomingMaintenanceWindow = minBy_(upcomingMaintenanceWindows, 'startTimeUTC')
  return {
    start: moment(soonestUpcomingMaintenanceWindow.startTimeUTC),
    end: moment(soonestUpcomingMaintenanceWindow.endTimeUTC),
  }
}
