import {
  FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_REQUEST,
  FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_SUCCESS,
  FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_REQUEST,
  FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_SUCCESS,
  FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_FAILURE,
)
