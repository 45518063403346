/**
 * CODE_COMMENTS_131
 */
import React from 'react'


export default class CatastrophicFailureBoundary extends React.Component {
  componentDidCatch(error, info) {
    this.props.setCatastrohpicFailureHappened(error, info)
  }

  render() {
    return this.props.children
  }
}
