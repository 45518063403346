import get_ from 'lodash/get'


import {
  getConbrwCustomerIdOfCONBRWContract,
} from '../../../../../../../redux/selectors/rewrite/contracts'


import {
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CONBRW,
} from '../../../../../../../constants'

// CODE_COMMENTS_134
export function getOriginNameForOutboundShipmentsHistoryTable({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  operatingContractBrewerCustomerId,
  row,
}) {
  const originContractObj = get_(entireContractsSlice, [row.originContractId])
  const originContractType = get_(originContractObj, ['ppfContractType'])
  if (
    operatingContractBrewerCustomerId
    || originContractType === PPF_CONTRACT_TYPES_BRW
    || !originContractType
  ) {
    return row.originCustomerName
  }
  if (originContractType === PPF_CONTRACT_TYPES_CONBRW) {
    const conbrwCustomerId = getConbrwCustomerIdOfCONBRWContract({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      contract: originContractObj,
    })
    const conbrwCustomerName = get_(entireCustomersSlice, [conbrwCustomerId, 'name'])
    return conbrwCustomerName || row.originCustomerName
  }
  // A keg fill perhaps
  return row.originCustomerName
}
