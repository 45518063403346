import historySagas from './histories'
import orderKegsSagas from './orderKegs'
import orderKegCollarsSagas from './orderKegCollars'
import reportInventorySagas from './reportInventory'
import reportShipmentsSagas from './reportShipments'
import reportNoMovementsSagas from './reportNoMovements'
import payBalanceSagas from './payBalance'
import acknowledgeInboundShipmentsSagas from './acknowledgeInboundShipments'
import manageUsersSagas from './manageUsers'
import manageContactsAndSubscriptionsSagas from './contacts'
import requestNewDistributorSagas from './requestNewDistributor'

// CODE_COMMENTS_11
export default [
  ...orderKegsSagas,
  ...orderKegCollarsSagas,
  ...reportInventorySagas,
  ...reportShipmentsSagas,
  ...reportNoMovementsSagas,
  ...payBalanceSagas,
  ...acknowledgeInboundShipmentsSagas,
  ...manageUsersSagas,
  ...manageContactsAndSubscriptionsSagas,
  ...requestNewDistributorSagas,
  ...historySagas,
]
