/**
 * CODE_COMMENTS_62
 */
import React from 'react'

import values_ from 'lodash/values'
import memoize_ from 'lodash/memoize'
import isPlainObject_ from 'lodash/isPlainObject'
import isString_ from 'lodash/isString'

import flow_ from 'lodash/fp/flow'
import uniqFp_ from 'lodash/fp/uniq'
import filterFp_ from 'lodash/fp/filter'

import DisplayedAddress from '../../../../common-components/DisplayedAddress'

import {
  getAddressIdsFromKegOrderObjects,
} from '../../../../redux/selectors/histories/kegOrderHistory'
import {
  getMultipleAddressObjectsByIdNoLinkInfoOnlyAddressInfo,
} from '../../../../redux/selectors/addresses'
import {
  getAllUniqueItemSkuIdsInLineItemsPropOfObjects,
  getContainerTypeFromItemSkuId,
} from '../../../../redux/selectors/rewrite/itemSkus'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,

  KEG_ORDER_STATUS_PENDING,
  KEG_ORDER_STATUS_ACCEPTED,
  KEG_ORDER_STATUS_COMPLETED,
  KEG_ORDER_STATUS_CANCELED,
  KEG_ORDER_STATUS_ON_HOLD,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  ORDER_KEGS_FORM_FIELD_LABEL_CUSTOMER_PO_NUM,
  ORDER_TYPES_LABEL,
} from '../../../../constants/formAndApiUrlConfig/orderKegs'

import {
  KEG_ORDER_FULL_DETAILS_LABEL_DELIVERY_ADDRESS,
  KEG_ORDER_FULL_DETAILS_LABEL_COMMENTS,
} from '../../../../constants/formAndApiUrlConfig/histories/kegOrderHistory'

import {
  ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
  ITEM_SKUS_SKU_TYPE_COMPOSITE,
  ITEM_SKUS_SKU_TYPE_KEG,
} from '../../../../constants'

import {
  createDisplayedInfoWithAlternateTextOption,
  getDisplayedHumanReadableStatusOfHistoryItem,
  getShouldHistoryItemObjBeDisplayedInTable,
  getShouldHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm,
  filterHistoryObjectsWithUnrecognizedStatus,
} from './util'

import {
  determineItemSkuIdsToIncludeAsFieldsInTable,
} from './displayedDataCommon'

import {
  formatApiDate, getHumanReadableContainerTypeFromItemSku,
  isTruthyAndNonEmpty,
} from '../../../../utils'


/*
 * *****************************************************************************
 * statuses config
 * *****************************************************************************
*/

// CODE_COMMENTS_60
export const kegOrderHistoryStatusesConfig = {
  [KEG_ORDER_STATUS_PENDING]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [KEG_ORDER_STATUS_ACCEPTED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [KEG_ORDER_STATUS_COMPLETED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [KEG_ORDER_STATUS_CANCELED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [KEG_ORDER_STATUS_ON_HOLD]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
}


export const getShouldKegOrderHistoryItemBeDisplayedInTable = orderObj => (
  getShouldHistoryItemObjBeDisplayedInTable(
    orderObj,
    kegOrderHistoryStatusesConfig,
    'status',
  )
)

export const getDisplayedHumanReadableStatusOfKegOrder = orderObj => (
  getDisplayedHumanReadableStatusOfHistoryItem(
    orderObj,
    kegOrderHistoryStatusesConfig,
    'status',
  )
)


// CODE_COMMENTS_145
export const getShouldKegOrderHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm = (
  currentlySelectedStatusInHistoryForm,
  orderObj,
) => (
  getShouldHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm(
    currentlySelectedStatusInHistoryForm,
    orderObj,
    kegOrderHistoryStatusesConfig,
    'status',
  )
)


/*
 * *****************************************************************************
 * Displayed Data
 * *****************************************************************************
*/

export const createDisplayedOrderNumber = row => row.orderId
export const createDisplayedPO = row => row?.custPonum
export const createDisplayedOrderDate = row => formatApiDate(row.dateOrdered, DEFAULT_DISPLAYED_DATE_FORMAT)
export const createDisplayedDateNeeded = row => formatApiDate(row.dateNeeded, DEFAULT_DISPLAYED_DATE_FORMAT)
export const createDisplayedCustomerPoNumber = createDisplayedInfoWithAlternateTextOption('custPonum')
export const createDisplayedOrderComments = createDisplayedInfoWithAlternateTextOption('orderComments')
export const createDisplayedDeliveryAddress = (row, { customerId }) => (
  <DisplayedAddress customerId={customerId} addressId={row.shipAddressId} />
)

export const createItemSkuIdFieldsDefinitions = ({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  // includeLocalKegsColumns, // CODE_COMMENTS_101
}) => (
  itemSkuIdsToIncludeAsFieldsInTable.reduce((defs, itemSkuId, index) => {
    const containerType = getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice)
    const newDefs = defs.slice() // keep it functional
    // const orderedLabel = includeLocalKegsColumns
    //   ? `Ordered ${containerType}`
    //   : containerType
    // const localLabel = `Local ${containerType}`
    newDefs[index] = {
      heading: containerType,
      cellContent: row => {
        const lineItemObj = row.orderLineItemObjects.find(lineItem => lineItem.itemSkuId === itemSkuId)
        return lineItemObj
          ? lineItemObj.quantity
          : 0
      },
      includeInTotalsRow: true,
    }
    // if (includeLocalKegsColumns) {
    //   newDefs[index+itemSkuIdsToIncludeAsFieldsInTable.length] = {
    //     heading: localLabel,
    //     cellContent: row => {
    // eslint-disable-next-line max-len
    //       const lineItemObj = row.orderLineItemObjects.find(lineItem => lineItem.itemSkuId === itemSkuId && lineItem.orderType === 'LOCAL')
    //       return lineItemObj
    //         ? lineItemObj.quantity
    //         : 0
    //     },
    //     includeInTotalsRow: true,
    //   }
    // }
    return newDefs
  }, [])
)

export const createContainerFieldsDefinitions = ({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  // includeLocalKegsColumns, // CODE_COMMENTS_101
}) => {
  const uniqueContainerTypes = Array.from(itemSkuIdsToIncludeAsFieldsInTable.reduce((containerTypes, itemSkuId) => {
    const containerType = getContainerTypeFromItemSkuId({
      entireItemSkusSlice,
      itemSkuId,
    })
    if (!containerType) return containerTypes
    return containerTypes.add(containerType)
  }, new Set()))
  return uniqueContainerTypes.reduce((defs, containerType, index) => {
    // const containerType = getContainerTypeFromItemSkuId({
    //   entireItemSkusSlice,
    //   itemSkuId,
    // })
    const newDefs = defs.slice() // keep it functional
    // const orderedLabel = includeLocalKegsColumns
    //   ? `Ordered ${containerType}`
    //   : containerType
    // const localLabel = `Local ${containerType}`
    newDefs[index] = {
      heading: containerType,
      cellContent: row => {
        const sumOfQuantity = row.orderLineItemObjects.map(
          lineItem => {
            if (entireItemSkusSlice?.[lineItem?.itemSkuId]?.containerType === containerType &&
              entireItemSkusSlice?.[lineItem?.itemSkuId]?.skuType === ITEM_SKUS_SKU_TYPE_COMPOSITE) {
              const { childItemSkuObjects=[] } = entireItemSkusSlice?.[lineItem?.itemSkuId] || {}
              // eslint-disable-next-line max-len
              const childSkuQuantity = childItemSkuObjects?.filter(({ id }) => entireItemSkusSlice?.[id]?.skuType === ITEM_SKUS_SKU_TYPE_KEG).reduce(
                (previousValue, { quantity = 0 }) => previousValue + quantity,
                0,
              )
              return {
                quantity: childSkuQuantity * (lineItem?.quantity || 0),
              }
            } else if (entireItemSkusSlice?.[lineItem?.itemSkuId]?.containerType === containerType) {
              return { quantity: lineItem?.quantity || 0 }
            } else {
              return { quantity: 0 }
            }
          },
        )?.reduce(
          (previousValue, { quantity = 0 }) => previousValue + quantity,
          0,
        ) // sum all the same container types
        return sumOfQuantity || 0
      },
      includeInTotalsRow: true,
    }
    // if (includeLocalKegsColumns) {
    //   newDefs[index+uniqueContainerTypes.length] = {
    //     heading: localLabel,
    //     cellContent: row => {
    //       const sumOfQuantity = row.orderLineItemObjects.filter(
    // eslint-disable-next-line max-len
    //          const lineItemObj = row.orderLineItemObjects.find(lineItem => lineItem.itemSkuId === itemSkuId && lineItem.orderType === 'LOCAL')
    //       )
    //         ?.reduce(
    //           (previousValue, { quantity = 0 }) => previousValue + quantity,
    //           0,
    //         ) // sum all the same container types
    //       return sumOfQuantity || 0
    //     },
    //     includeInTotalsRow: true,
    //   }
    // }
    return newDefs
  }, [])
}


/*
 * *****************************************************************************
 * field definitions
 * *****************************************************************************
*/

export const getInfoDefinitionsForKegOrderFullDetails = ({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  includeLocalKegsColumns,
  downloadAttachment,
}) => ([
  { heading: 'Order #', cellContent: createDisplayedOrderNumber },
  { heading: 'Order Date', cellContent: createDisplayedOrderDate },
  { heading: 'Order Type', cellContent: row => ORDER_TYPES_LABEL[row?.orderType] || '' },
  { heading: 'Date Needed', cellContent: createDisplayedDateNeeded },
  { heading: 'Status', cellContent: getDisplayedHumanReadableStatusOfKegOrder },
  { heading: ORDER_KEGS_FORM_FIELD_LABEL_CUSTOMER_PO_NUM, cellContent: createDisplayedCustomerPoNumber('none') },
  { heading: KEG_ORDER_FULL_DETAILS_LABEL_COMMENTS, cellContent: createDisplayedOrderComments('none') },
  { heading: KEG_ORDER_FULL_DETAILS_LABEL_DELIVERY_ADDRESS, cellContent: createDisplayedDeliveryAddress },
  ...(downloadAttachment ?
    createContainerFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
      includeLocalKegsColumns,
    })
    :
    createItemSkuIdFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
      includeLocalKegsColumns,
    })),
])


/*
 * *****************************************************************************
 * Miscellaneous
 * *****************************************************************************
*/

export const getDoesKegOrderObjectHaveLocalKegs = orderObj => (
  isTruthyAndNonEmpty(orderObj.orderLineItemObjects) && orderObj.orderType === 'LOCAL'
)


// orderObjects should be an object whose values are individual order objects
export const getDoAnyKegOrderObjectsHaveLocalKegs = orderObjects => {
  // if app is just starting, orderObjects will be null. If there are no order
  // objects resulting from the form API call, orderObjects will be an empty
  // object.
  if (!isTruthyAndNonEmpty(orderObjects)) {
    return false
  }
  return values_(orderObjects).some(orderObj => getDoesKegOrderObjectHaveLocalKegs(orderObj))
}

export function getIncludeLocalKegsColumnsInHistoryTable(
  allKegOrderObjectsToBeIncludedInHistoryTable,
  isRelatedToLocalDistributors,
) {
  return (
    isRelatedToLocalDistributors ||
    getDoAnyKegOrderObjectsHaveLocalKegs(allKegOrderObjectsToBeIncludedInHistoryTable)
  )
}

export function getAllKegOrderObjectsToBeIncludedInHistoryTable(allKegOrderObjects) {
  return filterHistoryObjectsWithUnrecognizedStatus( // CODE_COMMENTS_156
    allKegOrderObjects,
    kegOrderHistoryStatusesConfig,
    'status',
  )
}


export function determineItemSkuIdsToIncludeAsFieldsInKegOrderHistoryTable({
  entireItemSkusSlice,
  allKegOrderObjectsToBeIncludedInHistoryTable,
  configuredItemSkuIds,
}) {
  const itemSkuIdsInHistory = getAllUniqueItemSkuIdsInLineItemsPropOfObjects({
    entireItemSkusSlice,
    objs: allKegOrderObjectsToBeIncludedInHistoryTable,
    propName: 'orderLineItemObjects',
  }) || []
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    itemSkuIdsInHistory,
  })
  return itemSkuIdsToIncludeAsFieldsInTable.filter(itemSkuId => itemSkuId !== ITEM_SKU_IDS_CBI_PLASTIC_PALLET)
}


/*
 * *****************************************************************************
 * Address objects for Download Table as File
 * *****************************************************************************
*/

// Why do we memoize this? Because We want make sure the history tables don't
// re-render every time any unrelated part of the state updates.
export const getAllAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile = memoize_(
  ({ state, arrayOrObjectOfObjects }) => (
    getUniqueAddressObjectsFromKegOrderObjects({ state, arrayOrObjectOfObjects })
  ),
  ({ state, arrayOrObjectOfObjects }) => (
    getUniqueAddressIdsFromKegOrderObjects({ state, arrayOrObjectOfObjects }).join()
  ),
)


// Helper functions for this section

function getUniqueAddressIdsFromKegOrderObjects({ arrayOrObjectOfObjects }) {
  const arrayOfObjects = isPlainObject_(arrayOrObjectOfObjects)
    ? Object.values(arrayOrObjectOfObjects)
    : arrayOrObjectOfObjects

  return flow_(
    getAddressIdsFromKegOrderObjects,
    uniqFp_,
    filterFp_(addressId => isString_(addressId)), // filter out nulls
    a => a.slice().sort(), // Sorting is important because we memoize the returned value
  )(arrayOfObjects)
}

function getUniqueAddressObjectsFromKegOrderObjects({ state, arrayOrObjectOfObjects }) {
  const addressIds = getUniqueAddressIdsFromKegOrderObjects({ state, arrayOrObjectOfObjects })
  return getMultipleAddressObjectsByIdNoLinkInfoOnlyAddressInfo({ state, addressIds })
}
