import { connect } from 'react-redux'


import ActualIndividualUser from './components'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../../../redux/selectors/rewrite/parentChildLinks'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../../redux/selectors/rewrite/users'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../redux/selectors/rewrite/permissions'

import {
  getProp as getCustomerProp,
  getIsRentalCustomer,
} from '../../../../redux/selectors/customers'
import {
  getProp as getCurrentUserProp,
} from '../../../../redux/selectors/currentUser'


const mapStateToProps = (state, { userObject }) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)

  const rootCustomerType = getCustomerProp(state, userObject.rootCustomerId, 'customerType')
  const isRentalCustomer = getIsRentalCustomer(state, userObject.rootCustomerId)
  const currentUserId = getCurrentUserProp(state, 'userId')

  return {
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entirePermissionsSlice,
    rootCustomerType,
    isRentalCustomer,
    currentUserId,
  }
}

export default connect(
  mapStateToProps,
)(ActualIndividualUser)
