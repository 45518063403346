import {
  withPropSimple,
  withPropOrUndefinedSimple,
  getEntireSlice as commonGetEntireSlice,
} from './higherOrderFunctions'

import { REDUCER_NAMES_CURRENT_USER_EMPLOYEE as defaultSlice } from '../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../utils'


export const getProp = withPropSimple(defaultSlice)
export const getPropOrUndefined = withPropOrUndefinedSimple(defaultSlice)
export const getEntireSlice = commonGetEntireSlice(defaultSlice)


export function getIsEmployeeUserLoggedInRatherThanCustomerUser(state) {
  return isTruthyAndNonEmpty(getEntireSlice(state))
}
