/**
 * Oftentimes we'll have text that we want to display as a link but we don't
 * actually want the click to route us anywhere, we just want it to render a
 * modal or a popup. We don't want to use an <a> tag because that has semantic
 * meaning as a link that routes us somewhere; it's against a11y standards to
 * use an <a> tag that doesn't route anywhere. This component does what we want
 * without breaking a11y rules, mimicing the way Semantic UI React styles an
 * <a> tag.
 */

import React from 'react'


export default ({
  children,
  tabIndexOf0RatherThanNegative1=false,
  ...rest
}) => {
  const { style, ...restSansStyle } = rest
  return (
    <span
      // Follows the jsx-a11y rule that "Static HTML elements with event handlers
      // require a role", which would apply if, for instance, the caller passes in
      // an onClick prop:
      // https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-static-element-interactions.md
      role="button"
      // Follows the the jsx-a11y rule that "Elements with the 'Button'
      // interactive role must be focusable":
      // https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/interactive-supports-focus.md#case-i-got-the-error-elements-with-the-role-interactive-role-must-be-focusable-how-can-i-fix-this
      // However, we don't want the focusability of this text to disrupt the
      // normal progression of the tab key when a user is using keyboard
      // navigation rather than the mouse (for instance, when filling out a form),
      // so we set the tabIndex to -1 rather than 0.
      tabIndex={tabIndexOf0RatherThanNegative1 ? 0 : -1}
      className={`link-text${rest.className ? ` ${rest.className}` : ''}`}
      // When navigating with the keyboard, allows the enter press to act as
      // onClick. Note that this solution actually makes it so that pressing _any_
      // key acts as onClick, not just the enter key. But unfortunately, due to
      // browser deprecations, we can't narrow this to just the enter key; see
      // https://stackoverflow.com/a/905233 for details.
      onKeyPress={e => {
        if (rest.onClick) {
          rest.onClick(e)
        }
      }}
      // These styles are also in the .link-text class in our css file, but
      // for whatever reason sometimes they don't get applied. explicitly listing
      // them here as inline styles fixes the issue
      style={{
        color: '#1e70bf',
        cursor: 'pointer',
        ...(style || {}),
      }}
      {...restSansStyle}
    >
      {children}
    </span>
  )
}
