import React from 'react'
import { Container } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import { WEBAPP_VERSION } from '../../version'


import './styles.css'


export default () => {
  const { t: translate } = useTranslation('common')
  return (
    // We wrap the footer in a <Container> to give it left and right margins that
    // line up with the content above it (which is also wrapped in a <Container>);
    // this is important for mobile screens.
    <Container className="container-no-background container-no-margin">
      <div className="footer">
        <span>
          {`© ${new Date().getFullYear()} MicroStar Logistics. `}
          <a href="https://microstarlogistics.com/privacy-policy/" target="_blank" rel="noopener noreferrer">{translate('Privacy Policy')}</a>
          {`. Version: ${WEBAPP_VERSION}`}
        </span>
      </div>
    </Container>
  )
}
