import React from 'react'
import { connect } from 'react-redux'


import AddOrEditIndividualMapping from './components/AddOrEditIndividualContact'
import { CUSTOMER_TYPES_MASTER } from '../../../constants'
import AddOrEditMasterContacts from './components/AddOrEditMasterContacts'

const mapEditBasedOnCustomerType = props => {
  const { customerType } = props
  if (customerType === CUSTOMER_TYPES_MASTER) {
    return <AddOrEditMasterContacts {...props} />
  }
  return <AddOrEditIndividualMapping {...props} />
}
export default connect()(
  mapEditBasedOnCustomerType,
)
