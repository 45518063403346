import isEqual_ from 'lodash/isEqual'

import {
  SAVE_MAINTENANCE_SCHEDULE,
} from '../../actions/actionTypes'


// const initialState = [
//   {
//     startTimeUTC: 1893456000000,
//     endTimeUTC: 1893542400000,
//     internalReason: null,
//     customerFacingReason: null,
//   },
//   {
//     startTimeUTC: 1514764800000,
//     endTimeUTC: 1514851200000,
//     internalReason: 'Database updates 2018-01-01 to 2018-01-02',
//     customerFacingReason: 'The TAP3 portal will be down for scheduled maintenance.',
//   },
// ]
const initialState = []

export default function maintenanceSchedule(state=initialState, action) {
  switch (action.type) {
    case SAVE_MAINTENANCE_SCHEDULE: {
      // If none of maintenance mode schedules have changed, don't update state.
      return isEqual_(state, action.payload)
        ? state
        : action.payload
    }

    default:
      return state
  }
}
