import { connect } from 'react-redux'

import Form from '../components/Form'

import createAction from '../../../../../../redux/actions/createAction'

import {
  FETCH_FORGOT_PASSWORD_ENTER_EMAIL,
} from '../../../../../../redux/actions/actionTypes'


import {
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL,
} from '../../../../../../constants'

import fetchStatusSelectors from '../../../../../../redux/selectors/fetchStatuses/publicForms'


const {
  getFetchStatuses,
} = fetchStatusSelectors


const mapStateToProps = state => {
  const fetchStatuses = getFetchStatuses(state, REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL)
  const isFetching = fetchStatuses.isFetching

  return {
    isFetching,
  }
}

const mapDispatchToProps = dispatch => ({
  // CODE_COMMENTS_54
  handleSubmit: email => dispatch(createAction(FETCH_FORGOT_PASSWORD_ENTER_EMAIL, { email })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form)
