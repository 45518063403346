import Immutable from 'seamless-immutable'

import {
  SET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER,
  UNSET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER,
} from '../actions/actionTypes'


const initialState = Immutable({ hasHadChanceToRender: {} })

export default function ackInboundShipmentsReminderPopup(state=initialState, action) {
  switch (action.type) {
    case SET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER: {
      const { customerId } = action.payload
      return (
        Immutable.setIn(
          state,
          ['hasHadChanceToRender', customerId],
          true,
        )
      )
    }

    case UNSET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER: {
      const { customerId } = action.payload
      return (
        Immutable.setIn(
          state,
          ['hasHadChanceToRender', customerId],
          false,
        )
      )
    }

    default:
      return state
  }
}
