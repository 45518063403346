import {
  CUSTOMER_TYPES_WEB_APP_ONLY_ALL,
} from '.'
/* eslint-disable max-len */

import {
  reverseKeysAndValuesOfObjectOfFlatArrays,
} from '../utils'

/*
 * *****************************************************************************
 * Constants. Why not just use the string literals? See CODE_COMMENTS_33
 * *****************************************************************************
*/

// Permission values. Why do we name the variable RELATIONSHIPS rather than
// PERMISSION_RELATIONSHIPS as a naming convention? Because it makes the
// Permissions-to-functionality map below more readable. It lets us avoid this:
//
//
// export const ORDER_KEGS_ACTIVITY = {
//   [PERMISSION_ORDERS]: [ACCESS_CREATE],
//   [PERMISSION_ADDRESSES]: [ACCESS_READ],
// }
//
// and write this:
//
// export const ORDER_KEGS_ACTIVITY = {
//   [ORDERS]: [CREATE],
//   [ADDRESSES]: [READ],
// }
export const CUSTOMER_PARENTS = 'CUSTOMER_PARENTS' // needed to call /children
export const ADDRESSES = 'ADDRESSES'
export const COLLARORDERS = 'COLLARORDERS'
export const COLLARS = 'COLLARS'
export const CONTACTS = 'CONTACTS'
export const FILES = 'FILES'
export const FILLS = 'FILLS'
export const INVENTORY = 'INVENTORY'
export const INVOICES = 'INVOICES'
export const ORDERS = 'ORDERS'
export const PROCESS_ACKSHIPMENT = 'PROCESS_ACKSHIPMENT'
export const PROJECTIONS = 'PROJECTIONS'
export const CUSTOMER_SEARCH = 'CUSTOMER_SEARCH' // For request new distributor
export const RELATIONSHIP_REQUEST = 'RELATIONSHIP_REQUEST' // For request new distributor
export const RELATIONSHIPS = 'RELATIONSHIPS'
export const SHIPMENTS = 'SHIPMENTS'
export const USER_PERMISSIONS = 'USER_PERMISSIONS'
export const USERS = 'USERS'
export const CUSTOMERS = 'CUSTOMERS'
export const CONTRACTS = 'CONTRACTS'
export const PROPERTIES = 'PROPERTIES'

// Employee-only permissions
export const OPERATE_AS_USER = 'OPERATE_AS_USER'

// access values
export const READ = 'READ'
export const WEBAPP_READ = 'WEBAPP_READ'
export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const EXECUTE = 'EXECUTE'

/*
 * *****************************************************************************
 * By-functionality permissions
 * *****************************************************************************
*/

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_ORDER_HISTORY_TABLE = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_ORDER_HISTORY_TABLE'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_ORDERS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_ORDERS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_ORDERS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_ORDERS'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_COLLARS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_COLLARS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_COLLAR_ORDERS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_COLLAR_ORDERS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_COLLAR_ORDERS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_COLLAR_ORDERS'

export const FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_ORDER_COLLARS = 'FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_ORDER_COLLARS'
export const FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE = 'FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE'
export const FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_COLLAR_ORDERS = 'FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_COLLAR_ORDERS'
export const FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_DELETE_COLLAR_ORDERS = 'FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_DELETE_COLLAR_ORDERS'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_SHIPMENTS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_SHIPMENTS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_SHIPMENTS_HISTORY_TABLE = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_SHIPMENTS_HISTORY_TABLE'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_SHIPMENTS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_SHIPMENTS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REQUEST_NEW_DISTRIBUTOR = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REQUEST_NEW_DISTRIBUTOR'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REQUEST_LOCAL_RELEASE_AUTHORIZATION = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REQUEST_LOCAL_RELEASE_AUTHORIZATION'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_KEG_FILLS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_KEG_FILLS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_FILLS_HISTORY_TABLE = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_FILLS_HISTORY_TABLE'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_FILLS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_FILLS'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_FILLS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_FILLS'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_BREWER = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_BREWER'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_BREWER = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_BREWER'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_BREWER = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_BREWER'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORTED_VS_CALCULATED_INVENTORY = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORTED_VS_CALCULATED_INVENTORY'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_CONTRACT_BREWER = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_CONTRACT_BREWER'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_CONTRACT_BREWER = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_CONTRACT_BREWER'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_CONTRACT_BREWER = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_CONTRACT_BREWER'

export const FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_REPORT_INVENTORY = 'FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_REPORT_INVENTORY'
export const FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE = 'FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE'
export const FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_INVENTORY = 'FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_INVENTORY'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_DISTRIBUTOR = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_DISTRIBUTOR'
export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_DISTRIBUTOR = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_DISTRIBUTOR'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_PROJECTIONS = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_PROJECTIONS'

export const FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER = 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER'


/*
 * *****************************************************************************
 * Permissions-to-functionality map
 * *****************************************************************************
*/

// CODE_COMMENTS_163
export const UNIVERSAL_PERMISSIONS = [
  { permission: CUSTOMERS, access: READ, customerTypes: [CUSTOMER_TYPES_WEB_APP_ONLY_ALL] },
  { permission: CONTRACTS, access: WEBAPP_READ, customerTypes: [CUSTOMER_TYPES_WEB_APP_ONLY_ALL] },
  { permission: USERS, access: READ, customerTypes: [CUSTOMER_TYPES_WEB_APP_ONLY_ALL] },
  { permission: RELATIONSHIPS, access: READ, customerTypes: [CUSTOMER_TYPES_WEB_APP_ONLY_ALL] },
  { permission: CUSTOMER_SEARCH, access: READ, customerTypes: [CUSTOMER_TYPES_WEB_APP_ONLY_ALL] },
  { permission: PROPERTIES, access: READ, customerTypes: [CUSTOMER_TYPES_WEB_APP_ONLY_ALL] },
  { permission: CUSTOMER_PARENTS, access: READ, customerTypes: [CUSTOMER_TYPES_WEB_APP_ONLY_ALL] },
]


export const FUNCTIONALITY_TO_PERMISSIONS_MAP = {
  // Kegs
  // ***************************************************************************

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS]: {
    [ORDERS]: [CREATE],
    [ADDRESSES]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_ORDER_HISTORY_TABLE]: {
    [ORDERS]: [READ],
    [ADDRESSES]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_ORDERS]: {
    [ORDERS]: [UPDATE],
    [ADDRESSES]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_ORDERS]: {
    [ORDERS]: [DELETE],
    [ADDRESSES]: [READ],
  },


  // Collars
  // ***************************************************************************

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_COLLARS]: {
    [COLLARS]: [READ],
    [COLLARORDERS]: [CREATE],
    [ADDRESSES]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE]: {
    [COLLARS]: [READ],
    [COLLARORDERS]: [READ],
    [ADDRESSES]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_COLLAR_ORDERS]: {
    [COLLARS]: [READ],
    [COLLARORDERS]: [UPDATE],
    [ADDRESSES]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_COLLAR_ORDERS]: {
    [COLLARS]: [READ],
    [COLLARORDERS]: [DELETE],
    [ADDRESSES]: [READ],
  },

  // CODE_COMMENTS_155
  [FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_ORDER_COLLARS]: {
    [COLLARS]: [READ],
    [COLLARORDERS]: [CREATE],
  },

  // CODE_COMMENTS_155
  [FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE]: {
    [COLLARS]: [READ],
    [COLLARORDERS]: [READ],
  },

  // CODE_COMMENTS_155
  [FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_COLLAR_ORDERS]: {
    [COLLARS]: [READ],
    [COLLARORDERS]: [UPDATE],
  },

  // CODE_COMMENTS_155
  [FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_DELETE_COLLAR_ORDERS]: {
    [COLLARS]: [READ],
    [COLLARORDERS]: [DELETE],
  },


  // Shipments
  // ***************************************************************************

  // includes outbound, buybacks, self-dist/pub and requesting a local release
  // auth. DOESN'T include keg fills.
  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_SHIPMENTS]: {
    [SHIPMENTS]: [CREATE],
    [RELATIONSHIPS]: [READ],
  },

  // Includes all shipments tabs (inbound & outbound) but not the keg fills tab
  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_SHIPMENTS_HISTORY_TABLE]: {
    [SHIPMENTS]: [READ],
    [RELATIONSHIPS]: [READ],
  },

  // This doesn't include acknowledging an inbound shipments, which has its own permissions
  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS]: {
    [SHIPMENTS]: [UPDATE],
    [RELATIONSHIPS]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS]: {
    [SHIPMENTS]: [READ],
    [PROCESS_ACKSHIPMENT]: [EXECUTE],
    [RELATIONSHIPS]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_SHIPMENTS]: {
    [SHIPMENTS]: [DELETE],
    [RELATIONSHIPS]: [READ],
  },


  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REQUEST_NEW_DISTRIBUTOR]: {
    // CODE_COMMENTS_163: Technically the Request New Distributor feature also
    // requires the CUSTOMER_SEARCH: READ permission, but this is a permission
    // that's given to all customer users no matter what.
    [RELATIONSHIP_REQUEST]: [CREATE],
  },


  // Keg Fills
  // ***************************************************************************

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_KEG_FILLS]: {
    [FILLS]: [CREATE],
    [RELATIONSHIPS]: [READ],
  },

  // Includes all shipments tabs (inbound & outbound) but not the keg fills tab
  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_FILLS_HISTORY_TABLE]: {
    [FILLS]: [READ],
    [RELATIONSHIPS]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_FILLS]: {
    [FILLS]: [UPDATE],
    [RELATIONSHIPS]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_FILLS]: {
    [FILLS]: [DELETE],
    [RELATIONSHIPS]: [READ],
  },


  // Inventory
  // ***************************************************************************

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_BREWER]: {
    [INVENTORY]: [CREATE],
    // Brewers can have child pubs so the app needs to call /children; see
    // CODE_COMMENTS_49
    [CUSTOMER_PARENTS]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_BREWER]: {
    [INVENTORY]: [READ],
    // Brewers can have child pubs so the app needs to call /children; see
    // CODE_COMMENTS_49
    [CUSTOMER_PARENTS]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_BREWER]: {
    [INVENTORY]: [UPDATE],
    // Brewers can have child pubs so the app needs to call /children; see
    // CODE_COMMENTS_49
    [CUSTOMER_PARENTS]: [READ],
  },

  // Customers aren't allowed to delete inventory reports


  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORTED_VS_CALCULATED_INVENTORY]: {
    [INVENTORY]: [READ],
  },


  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_CONTRACT_BREWER]: {
    [INVENTORY]: [CREATE],
    [RELATIONSHIPS]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_CONTRACT_BREWER]: {
    [INVENTORY]: [READ],
    [RELATIONSHIPS]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_CONTRACT_BREWER]: {
    [INVENTORY]: [UPDATE],
    [RELATIONSHIPS]: [READ],
  },


  [FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_REPORT_INVENTORY]: {
    [INVENTORY]: [CREATE],
  },

  [FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE]: {
    [INVENTORY]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_INVENTORY]: {
    [INVENTORY]: [UPDATE],
  },

  // Customers aren't allowed to delete inventory reports

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR]: {
    [INVENTORY]: [CREATE],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_DISTRIBUTOR]: {
    [INVENTORY]: [READ],
  },

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_DISTRIBUTOR]: {
    [INVENTORY]: [UPDATE],
  },

  // Customers aren't allowed to delete inventory reports


  // Invoices
  // ***************************************************************************

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES]: {
    // Technically, viewing invoices requires both these permissions but paying
    // a balance only requires INVOICES: READ. But for the sake of simplicity,
    // we group both these activities into one.
    [FILES]: [READ],
    [INVOICES]: [READ],
  },

  // Customers aren't allowed to edit or delete invoices


  // Addresses
  // ***************************************************************************

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES]: {
    [ADDRESSES]: [READ],
  },

  // Manage Users
  // ***************************************************************************

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS]: {
    // We don't include USERS: READ (even though it's techincally required)
    // because we don't want the code to ever remove USERS: READ from a user's
    // permissions; see CODE_COMMENTS_163
    [USERS]: [CREATE, UPDATE, DELETE],
    [USER_PERMISSIONS]: [READ, CREATE, UPDATE, DELETE],
    [CONTACTS]: [READ, CREATE, UPDATE, DELETE],
  },


  // Manage Projections
  // ***************************************************************************

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_PROJECTIONS]: {
    [PROJECTIONS]: [READ, CREATE, UPDATE, DELETE],
  },


  // Employees
  // ***************************************************************************

  [FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER]: {
    [OPERATE_AS_USER]: [EXECUTE],
  },
}


/*
 * *****************************************************************************
 * Permissions Choices of the Manage Users form
 * *****************************************************************************
*/

export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_ORDERS = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_ORDERS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_ORDERS = 'MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_ORDERS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS = 'MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS'
// CODE_COMMENTS_155
export const MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS = 'MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS'
export const MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS = 'MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS = 'MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS = 'MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_REQUEST_NEW_DISTRIBUTOR = 'MANAGE_USERS_PERMISSIONS_CHOICE_REQUEST_NEW_DISTRIBUTOR'
export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_FILLS = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_FILLS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS = 'MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS'

export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_BREWER = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_BREWER'
export const MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER = 'MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER'
export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER'
export const MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER = 'MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER'
export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_DISTRIBUTOR = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_DISTRIBUTOR'
export const MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR = 'MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR'
export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_MASTER = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_MASTER'
export const MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER = 'MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER'
export const MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS = 'MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS'
export const MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS = 'MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS'

export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_AND_PAY_INVOICES = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_AND_PAY_INVOICES'
export const MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS = 'MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_PROJECTIONS = 'MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_PROJECTIONS'
export const MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES = 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES'


// Some MASTER permissions choices get added to this variable later on in this
// file, so the props listed here are not the only ones that are actually
// contained in this object.
// eslint-disable-next-line import/no-mutable-exports
export let MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP = {
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_ORDERS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_ORDER_HISTORY_TABLE,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_ORDERS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_ORDERS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_ORDERS,
  ],


  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_COLLARS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_COLLAR_ORDERS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_COLLAR_ORDERS,
  ],

  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS]: [
    FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE,
  ],
  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS]: [
    FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_ORDER_COLLARS,
    FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_COLLAR_ORDERS,
    FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_DELETE_COLLAR_ORDERS,
  ],


  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_SHIPMENTS_HISTORY_TABLE,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_SHIPMENTS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_SHIPMENTS,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_REQUEST_NEW_DISTRIBUTOR]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REQUEST_NEW_DISTRIBUTOR,
  ],


  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_FILLS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_KEG_FILLS_HISTORY_TABLE,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_KEG_FILLS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_KEG_FILLS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_KEG_FILLS,
  ],

  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_BREWER]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_BREWER,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORTED_VS_CALCULATED_INVENTORY,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_BREWER,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_BREWER,
  ],

  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_CONTRACT_BREWER,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_CONTRACT_BREWER,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_CONTRACT_BREWER,
  ],

  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_DISTRIBUTOR]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE_DISTRIBUTOR,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_DISTRIBUTOR,
  ],

  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS]: [
    FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_INVENTORY_HISTORY_TABLE,
  ],
  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS]: [
    FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_REPORT_INVENTORY,
    FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_INVENTORY,
  ],


  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_AND_PAY_INVOICES]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  ],


  [MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  ],


  [MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_PROJECTIONS]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_PROJECTIONS,
  ],

  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES]: [
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
  ],
}

export const WAREHOUSE_MANAGE_USERS_PERMISSIONS_CHOICES = [
  MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES,
  MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS,
]
export const MAINTENANCE_FACILITY_MANAGE_USERS_PERMISSIONS_CHOICES = [
  MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES,
  MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS,
]
export const BREWER_MANAGE_USERS_PERMISSIONS_CHOICES = [
  MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_ORDERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_ORDERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS,
  MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  MANAGE_USERS_PERMISSIONS_CHOICE_REQUEST_NEW_DISTRIBUTOR,

  // This is for Brewers who have Contract Brewers brewing for them and who are
  // responsible for reporting the kegs their CONBRW fills for them. Harley
  // decided to expose this permission to all Brewers and include a popup
  // explaining that this only affects Brewers who have Contract Brewers brewing
  // for them.
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_FILLS,

  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_BREWER,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_AND_PAY_INVOICES,
  MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_PROJECTIONS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES,
]


export const CONTRACT_BREWER_MANAGE_USERS_PERMISSIONS_CHOICES = [
  MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_ORDERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_ORDERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS, // viewing inbound shipments
  MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_FILLS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_AND_PAY_INVOICES,
  MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_PROJECTIONS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES,
]


export const CONTRACTEE_BREWER_MANAGE_USERS_PERMISSIONS_CHOICES = [
  MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS,
  MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS,
  MANAGE_USERS_PERMISSIONS_CHOICE_REQUEST_NEW_DISTRIBUTOR,
  // Where are the inventory permissions? CODE_COMMENTS_173
]

export const DISTRIBUTOR_MANAGE_USERS_PERMISSIONS_CHOICES = [
  MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_DISTRIBUTOR,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_AND_PAY_INVOICES,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES,
]

// CODE_COMMENTS_234: This is the only variable for MASTERs you need to edit,
// all other permissions variables for MASTERs are created dynamically from the
// Brewer, Conbrw and Dist permissions.
const specialMasterGroupingsOfManageUsersPermissionsChoices = {
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER]: [
    MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER,
    MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,
    MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR,
  ],
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_MASTER]: [
    MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_BREWER,
    MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER,
    MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_DISTRIBUTOR,
  ],
}

// (see CODE_COMMENTS_234) Inverts
// specialMasterGroupingsOfManageUsersPermissionsChoices so that keys are
// values and values are keys, e.g. transforms

// {
//   [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER]: [
//     MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER,
//     MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,
//     MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR,
//   ],
//   ...
// }

// to

// {
//   [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER]: [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER],
//   [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER]: [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER],
//   [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR]: [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER],
//   ],
//   ...
// }
export const SPECIAL_CHILD_MANAGE_USERS_PERMISSIONS_CHOICES_MAPPED_TO_MASTER_CHOICES =
  reverseKeysAndValuesOfObjectOfFlatArrays(
    specialMasterGroupingsOfManageUsersPermissionsChoices,
  )

// (See CODE_COMMENTS_234) Changes
// specialMasterGroupingsOfManageUsersPermissionsChoices from e.g.

// {
//   [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER]: [
//     MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER,
//     MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,
//     MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR,
//   ],
//   ...
// }

// into

// {
//   [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER]: [
//     FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_BREWER,
//     FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_BREWER,
//     FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_CONTRACT_BREWER,
//     FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_CONTRACT_BREWER,
//     FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR,
//     FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_DISTRIBUTOR,
//   ],
//   ...
// }
const masterManageUsersPermissionsChoices = Object.entries(
  specialMasterGroupingsOfManageUsersPermissionsChoices,
).reduce(
  (acc, [choiceName, arrayOfPermissionsChoices]) => {
    const arrayOfFunctionalities = arrayOfPermissionsChoices.reduce(
      (subacc, permissionsChoice) => ([
        ...subacc,
        ...MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP[permissionsChoice],
      ]),
      [],
    )
    return {
      ...acc,
      [choiceName]: arrayOfFunctionalities,
    }
  },
  {},
)

MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP = {
  ...MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP,
  ...masterManageUsersPermissionsChoices,
}

export const RENTAL_CUSTOMER_MANAGE_USERS_PERMISSIONS_CHOICES = [
  MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_AND_PAY_INVOICES,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES,
]

// assign label string literals to variables if they're used multiple times in
// the map below
const viewInventoryReportsCommonLabel = 'View Inventory Report History'
const createEditInventoryReportsCommonLabel = 'Create & Edit Inventory Reports'
const viewCollarOrderHistoryCommonLabel = 'View Collar Order History'

export const MANAGE_USERS_PERMISSIONS_CHOICE_TO_HUMAN_READABLE_LABEL_MAP = {
  [MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS]: 'Manage Users and Contacts',
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_ORDERS]: 'View Keg Order History',
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_ORDERS]: 'Create, Edit & Cancel Keg Orders',
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS]: viewCollarOrderHistoryCommonLabel,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS]: 'Create, Edit & Cancel Collar Orders',
  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS]: viewCollarOrderHistoryCommonLabel,
  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS]: 'Create, Edit & Cancel Collar Orders',
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS]: 'View Shipment History',
  [MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS]: 'Acknowledge Inbound Shipments',
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS]: 'Create, Edit & Cancel Outbound Shipments',
  [MANAGE_USERS_PERMISSIONS_CHOICE_REQUEST_NEW_DISTRIBUTOR]: 'Request New Distributor',
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_FILLS]: 'View Keg Fill History',
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS]: 'Create, Edit & Cancel Keg Fills',
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_BREWER]: viewInventoryReportsCommonLabel,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER]: createEditInventoryReportsCommonLabel,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER]: viewInventoryReportsCommonLabel,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER]: createEditInventoryReportsCommonLabel,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_MASTER]: viewInventoryReportsCommonLabel,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER]: createEditInventoryReportsCommonLabel,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_DISTRIBUTOR]: 'View Empty Keg Report History', // CODE_COMMENTS_204
  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS]: viewInventoryReportsCommonLabel,
  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS]: createEditInventoryReportsCommonLabel,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR]: 'Create & Edit Empty Keg Reports', // CODE_COMMENTS_204
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_AND_PAY_INVOICES]: 'View Invoices & Pay Balance',
  [MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_PROJECTIONS]: 'Manage Projections',
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES]: 'View Addresses',
}


// Create categories of permissions so like permissions can be grouped together
// for display

export const MANAGE_USERS_PERMISSIONS_CATEGORY_ADMINISTRATOR = 'Administrator'
export const MANAGE_USERS_PERMISSIONS_CATEGORY_KEG_ORDERS = 'Keg Orders'
export const MANAGE_USERS_PERMISSIONS_CATEGORY_COLLARS = 'Collars'
export const MANAGE_USERS_PERMISSIONS_CATEGORY_SHIPMENTS = 'Shipments'
export const MANAGE_USERS_PERMISSIONS_CATEGORY_KEG_FILLS = 'Keg Fills'
export const MANAGE_USERS_PERMISSIONS_CATEGORY_INVENTORY = 'Inventory'
export const MANAGE_USERS_PERMISSIONS_CATEGORY_EMPTY_KEG_REPORTS = 'Empty Keg Reports' // CODE_COMMENTS_204
export const MANAGE_USERS_PERMISSIONS_CATEGORY_INVOICES = 'Invoices'
export const MANAGE_USERS_PERMISSIONS_CATEGORY_PROJECTIONS = 'Projections'
export const MANAGE_USERS_PERMISSIONS_CATEGORY_ADDRESSES = 'Addresses'


export const MANAGE_USERS_PERMISSIONS_CHOICE_TO_CATEGORY_MAP = {
  [MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_USERS]: MANAGE_USERS_PERMISSIONS_CATEGORY_ADMINISTRATOR,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_ORDERS]: MANAGE_USERS_PERMISSIONS_CATEGORY_ADMINISTRATOR,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_ORDERS]: MANAGE_USERS_PERMISSIONS_CATEGORY_KEG_ORDERS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS]: MANAGE_USERS_PERMISSIONS_CATEGORY_COLLARS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS]: MANAGE_USERS_PERMISSIONS_CATEGORY_COLLARS,
  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS]: MANAGE_USERS_PERMISSIONS_CATEGORY_COLLARS,
  [MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_COLLAR_ORDERS]: MANAGE_USERS_PERMISSIONS_CATEGORY_COLLARS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS]: MANAGE_USERS_PERMISSIONS_CATEGORY_SHIPMENTS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_ACKNOWLEDGE_INBOUND_SHIPMENTS]: MANAGE_USERS_PERMISSIONS_CATEGORY_SHIPMENTS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS]: MANAGE_USERS_PERMISSIONS_CATEGORY_SHIPMENTS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_REQUEST_NEW_DISTRIBUTOR]: MANAGE_USERS_PERMISSIONS_CATEGORY_SHIPMENTS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_FILLS]: MANAGE_USERS_PERMISSIONS_CATEGORY_SHIPMENTS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS]: MANAGE_USERS_PERMISSIONS_CATEGORY_KEG_FILLS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_BREWER]: MANAGE_USERS_PERMISSIONS_CATEGORY_INVENTORY,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_BREWER]: MANAGE_USERS_PERMISSIONS_CATEGORY_INVENTORY,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER]: MANAGE_USERS_PERMISSIONS_CATEGORY_INVENTORY,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER]: MANAGE_USERS_PERMISSIONS_CATEGORY_INVENTORY,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_MASTER]: MANAGE_USERS_PERMISSIONS_CATEGORY_INVENTORY,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_MASTER]: MANAGE_USERS_PERMISSIONS_CATEGORY_INVENTORY,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_DISTRIBUTOR]: MANAGE_USERS_PERMISSIONS_CATEGORY_INVENTORY,
  [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_DISTRIBUTOR]: MANAGE_USERS_PERMISSIONS_CATEGORY_INVENTORY,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_AND_PAY_INVOICES]: MANAGE_USERS_PERMISSIONS_CATEGORY_INVOICES,
  [MANAGE_USERS_PERMISSIONS_CHOICE_MANAGE_PROJECTIONS]: MANAGE_USERS_PERMISSIONS_CATEGORY_PROJECTIONS,
  [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_ADDRESSES]: MANAGE_USERS_PERMISSIONS_CATEGORY_ADDRESSES,
}

/*
 * *****************************************************************************
 * Misc
 * *****************************************************************************
*/

export const PERMISSIONS_MAP_REDUX_STORE_PROP_NAME = 'permissionsMap'
export const PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME = 'perCustomerPermissionsMap'


/*
 * *****************************************************************************
 * Customer-level permissions, see CODE_COMMENTS_168
 * *****************************************************************************
*/


export const CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS = 'ORDER_COLLARS'
export const CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS = 'REPORT_OUTBOUNDS'
export const CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_KEG_FILLS = 'REPORT_FILLS'
export const CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_INVENTORY = 'REPORT_INVENTORY'

export const CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BREWER = 'BREWER'
export const CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_CONTRACT_BREWER = 'CONTRACT_BREWER'
export const CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_NEITHER = 'NEITHER'
export const CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BOTH = 'BOTH'


/*
 * *****************************************************************************
 * Permissions Maps
 * *****************************************************************************
*/


// We make these variables so that we don't accidentally misspell them
// somewhere, because they need to be used across multiple files.
export const DISPLAYED_PERMISSIONS_DEFS_KEY_ROOT_CUSTOMER_OF_USER = 'rootCustomerOfUser'
export const DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF = 'customersToOperateOnBehalfOf'
