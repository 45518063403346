import React, { Fragment } from 'react'
import {
  Header,
  Divider,
} from 'semantic-ui-react'


import UsersOfIndividualCustomer from '../../UsersOfIndividualCustomer'

import {
  getAllChildCustomerObjectsOfCustomer,
} from '../../../../redux/selectors/rewrite/children'

import {
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_STATUS_PENDING,
  CUSTOMER_STATUS_INACTIVE,
} from '../../../../constants'


import './styles.css'


export default props => {
  const {
    customerId,
    entireCustomersSlice,
    entireParentChildLinksSlice,
  } = props
  const customerType = entireCustomersSlice[customerId].customerType
  if (customerType === CUSTOMER_TYPES_MASTER) {
    const childCustomersOfMaster = getAllChildCustomerObjectsOfCustomer({
      customerId,
      entireCustomersSlice,
      entireParentChildLinksSlice,
      onlyCustomersWhoAreNotCurrentlyInactive: true,
      customerObjsCustomFilterFunc: o => {
        const isRightType = [
          CUSTOMER_TYPES_BREWER,
          CUSTOMER_TYPES_CONTRACT_BREWER,
          CUSTOMER_TYPES_DISTRIBUTOR,
        ].includes(o.customerType)
        const isRightStatus = ![
          CUSTOMER_STATUS_PENDING,
          CUSTOMER_STATUS_INACTIVE,
        ].includes(o.customerStatus)
        return isRightType && isRightStatus
      },
    })
    return (
      <Fragment>
        <Divider />
        <Header as="h2" color="grey">Users at the Master Level</Header>
        <UsersOfIndividualCustomer
          customerId={customerId}
          customerType={customerType}
        />
        {
          childCustomersOfMaster.map(o => (
            <div key={o.name} className="users-of-master-children">
              <Divider style={{ marginTop: '2em' }} />
              <Header as="h2" color="grey">{`Users of ${o.name}`}</Header>
              <UsersOfIndividualCustomer
                customerId={o.id}
                customerType={customerType}
                isChildOfLoggedInMaster
              />
            </div>
          ))
        }
      </Fragment>
    )
  }
  return (
    <UsersOfIndividualCustomer
      customerId={customerId}
      customerType={customerType}
    />
  )
}
