import { put, call } from 'redux-saga/effects'

import createAction from '../../actions/createAction'

import {
  logApiCallError,
} from '../../../utils/thirdPartyLogging'

import {
  extractMostImportantDetailsFromApiErrorObject,
} from '../../../utils'

/*
 * *****************************************************************************
 * Fetch Failure Redux Action
 * *****************************************************************************
*/

export function createFetchFailureAction({
  error,
  type,
  target,
}) {
  const errorDetails = extractMostImportantDetailsFromApiErrorObject({ error })
  return createAction(type, { errorDetails, target })
}


/*
 * *****************************************************************************
 * Universal doFailure()
 * *****************************************************************************
*/

// call this when an API call fails
export function* generalDoFailure(props) {
  const {
    error={},
    preventsDashboardFromLoading,
    // to use instead of Redux's global dispatch--useful when using local state
    // via the useReducer hook rather than Redux.
    customDispatch,
    action,
    reject,
    onlyLogErrorIf=() => true,
  } = props


  const mostImportantDetails = extractMostImportantDetailsFromApiErrorObject({ error })
  if (onlyLogErrorIf({ ...props, ...mostImportantDetails })) {
    yield call(logApiCallError, { error, preventsDashboardFromLoading })
  }

  if (action) {
    if (customDispatch) {
      customDispatch(action)
    } else {
      yield put(action)
    }
  }

  if (reject) {
    yield call(reject, new Error())
  }
}
