/**
 * CODE_COMMENTS_133
 */
import {
  DEFAULT_OR_ORIGIN_CONTRACT_METADATA_OBJECT_PROP_NAME,
  DEST_CONTRACT_METADATA_BRW_2_BRW_ONLY_PROP_NAME,

  CONTRACT_METADATA_PROP_NAME_PPF_CONTRACT_TYPE,
  CONTRACT_METADATA_PROP_NAME_CONTRACT_BREWER_CID,
  CONTRACT_METADATA_PROP_NAME_CONTRACT_TYPE,
} from '../../../constants/formAndApiUrlConfig/commonConfig'

// Returns an object with two props:

// {
//   defaultOrOriginContractMetaDataObject: ...,
//   destContractMetaDataBrw2BrwOnly: ...,
// }

// The values of those props are determined by the function arguments. Both
// arguments can be set to one of 3 possible things:

// - null
// - the string 'BRW'
// - a customerId string

// If null, the corresponding prop value will be set to null. If 'BRW', the
// corresponding prop value will be set to the object:

// {
//   ppfContractType: 'BRW',
//   contractBrewerCid: null,
//   contractType: 'PPF',
// }

// If a customerId string, the corresponding prop value will be set to the
// object:

// {
//   ppfContractType: 'CONBRW',
//   contractBrewerCid: [the customerId passed in],
//   contractType: 'PPF',
// }
export function createContractMetadataObject(
  defaultOrOrigin,
  destBrw2BrwOnly,
) {
  let valueOfDefaultOrOriginProp
  let valueOfDestBrw2BrwOnlyProp

  if (!defaultOrOrigin) {
    valueOfDefaultOrOriginProp = null
  } else if (defaultOrOrigin === 'BRW' || defaultOrOrigin === 'CONBRW') {
    valueOfDefaultOrOriginProp = createNestedContractMetadataObjectValue()
  } else {
    valueOfDefaultOrOriginProp = createNestedContractMetadataObjectValue(defaultOrOrigin)
  }

  if (!destBrw2BrwOnly) {
    valueOfDestBrw2BrwOnlyProp = null
  } else if (defaultOrOrigin === 'BRW') {
    valueOfDestBrw2BrwOnlyProp = createNestedContractMetadataObjectValue()
  } else {
    valueOfDestBrw2BrwOnlyProp = createNestedContractMetadataObjectValue(destBrw2BrwOnly)
  }

  return {
    [DEFAULT_OR_ORIGIN_CONTRACT_METADATA_OBJECT_PROP_NAME]: valueOfDefaultOrOriginProp,
    [DEST_CONTRACT_METADATA_BRW_2_BRW_ONLY_PROP_NAME]: valueOfDestBrw2BrwOnlyProp,
  }
}

// helper functions

function createNestedContractMetadataObjectValue(contractBrewerCustomerId) {
  return contractBrewerCustomerId
    ? createContractMetadataNestedObjectValueTargetedToAContractBrewer(contractBrewerCustomerId)
    : createContractMetadataNestedObjectValueTargetedToABrewer()
}


function createContractMetadataNestedObjectValueTargetedToABrewer() {
  return {
    [CONTRACT_METADATA_PROP_NAME_PPF_CONTRACT_TYPE]: 'BRW',
    [CONTRACT_METADATA_PROP_NAME_CONTRACT_BREWER_CID]: null,
    [CONTRACT_METADATA_PROP_NAME_CONTRACT_TYPE]: 'PPF',
  }
}


function createContractMetadataNestedObjectValueTargetedToAContractBrewer(contractBrewerCustomerId) {
  return {
    [CONTRACT_METADATA_PROP_NAME_PPF_CONTRACT_TYPE]: 'CONBRW',
    [CONTRACT_METADATA_PROP_NAME_CONTRACT_BREWER_CID]: contractBrewerCustomerId,
    [CONTRACT_METADATA_PROP_NAME_CONTRACT_TYPE]: 'PPF',
  }
}
