/* eslint-disable import/first, no-underscore-dangle */ // For mapFpWithIndex_

import isEqual_ from 'lodash/isEqual'
import cloneDeep_ from 'lodash/cloneDeep'
import pullAt_ from 'lodash/pullAt'


import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import flattenFp_ from 'lodash/fp/flatten'
import valuesFp_ from 'lodash/fp/values'
import cloneFp_ from 'lodash/fp/clone'
import pickFp_ from 'lodash/fp/pick'
import entriesFp_ from 'lodash/fp/entries'
// By default mapFp_ doesn't include the second, `index`, iteratee argument.
// This fixes that--see https://github.com/lodash/lodash/wiki/FP-Guide#convert
// eslint-disable-next-line no-unused-vars
const mapFpWithIndex_ = mapFp_.convert({ cap: false })

import {
  FIELD_NAME_SOURCE_CUSTOMER,
  FIELD_NAME_DESTINATION_CUSTOMER,
  FIELD_NAME_DATE_SHIPPED,
} from '../../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
} from '../../../../../../constants/formAndApiUrlConfig/reportBuybackShipments'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  getIsFieldRowFullyFilledOut,
  fieldArraysToApplyPotentialDuplicatesFeatureTo,
} from '../../../../util'

import {
  createPotentialDuplicateShipmentStringKeyForReduxStore,
} from '../../../../../../utils/reportShipmentsFormsPotentialDuplicates'

import {
  isTruthyAndNonEmpty,
  formatApiDate,
} from '../../../../../../utils'


// Helper constants

export const POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS = 'POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS'
export const POTENTIAL_DUPLICATES_OTHER_FORM_ROWS = 'POTENTIAL_DUPLICATES_OTHER_FORM_ROWS'


// Returns an array like this (one element for each row in the field array)
//
// [
//   {
//     // value will be empty if no duplicates
//     [POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS]: [
//       { dateReported: '12/17/2019', createdBy: 'John Cleese', proNumber: 'MP1234' },
//       { dateReported: '12/19/2019', createdBy: null, proNumber: 'MP5678' },  // null if unknown user
//     ],
//     // value will be empty if no duplicates
//     [POTENTIAL_DUPLICATES_OTHER_FORM_ROWS]: [1, 5, 6]
//   },
//   ...
// ]
export function calculatePotentialDuplicatesWhenUserEditsARow({
  previousPotentialDuplicates,
  newFormValues,
  fieldArrayName,
  rowIndex,
  formValuesPertinentDetailsManuallyMarkedAsNotDuplicates,
  entireReportShipmentsFormsPotentialDuplicatesSlice, // store slice
  entireUsersSlice, // store slice
  entireItemSkusSlice,
  isEditForm,
  itemObj, // only if isEditForm
}) {
  const rowsDetailsOfFieldArray =
    extractPertinentDetailsFromAllRowsOfShipmentsFormFieldArrayToDecideIfPotentialDuplicate({
      formValues: newFormValues,
      fieldArrayName,
      entireItemSkusSlice,
    })
  // We only need to calculate this for the single row that was changed
  const potentialDuplicateOfPreviouslySubmittedShipmentsOfRowIndex =
    getPotentialDuplicateOfPreviouslySubmittedShipments({
      entireReportShipmentsFormsPotentialDuplicatesSlice, // store slice
      entireUsersSlice, // store slice
      rowsDetailsOfFieldArray,
      formValues: newFormValues,
      targetRowIndex: rowIndex,
      isEditForm,
      itemObj,
    })

  // Compare the changed row to every other row in the field array. For each
  // duplicate row, do two things:
  //   1a. Add the compared row's index to the target row's new array of dupes.
  //   1b. Add the target row's index to the compared row's array of dupes.
  // Note that these index values might already be in these arrays, i.e. these
  // rows might have been duplicates before the change and are still duplicates
  // after the change. But how--if the row was changed, aren't its former
  // duplicates no longer duplicates by necessity? No, for one reason: a user
  // can change a KetQty value from a blank string to 0 or from 0 to a blank
  // string. This function fires but the row still equates to the same values.
  // For each non-duplicate row, do two things:
  //   2a. Remove the compared row's index from the target row's new array of
  //      dupes.
  //   2b. Remove the target row's index from the compared row's array of dupes.
  const indexesOfRowsThatAreIdenticalAfterChange = [] // essentially 2a

  const newPotentialDuplicatesOfFieldArray = previousPotentialDuplicates[fieldArrayName].map(
    (dupeDefs, index) => {
      const toReturn = cloneDeep_(dupeDefs)
      if (index === rowIndex) {
        toReturn[POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS] =
          potentialDuplicateOfPreviouslySubmittedShipmentsOfRowIndex
        toReturn[POTENTIAL_DUPLICATES_OTHER_FORM_ROWS] = indexesOfRowsThatAreIdenticalAfterChange
      } else { // #2
        const thisRowsDetails = rowsDetailsOfFieldArray[index].details
        const changedRowDetails = rowsDetailsOfFieldArray[rowIndex].details
        if (
          rowsDetailsOfFieldArray[index].isFieldRowFullyFilledOut
          && rowsDetailsOfFieldArray[rowIndex].isFieldRowFullyFilledOut
          && isEqual_(thisRowsDetails, changedRowDetails)
          && !formValuesPertinentDetailsManuallyMarkedAsNotDuplicates.some(markedRowDetails => (
            isEqual_(markedRowDetails, changedRowDetails)),
          )
        ) {
          indexesOfRowsThatAreIdenticalAfterChange.push(index) // #1a
          if (!toReturn[POTENTIAL_DUPLICATES_OTHER_FORM_ROWS].includes(rowIndex)) {
            toReturn[POTENTIAL_DUPLICATES_OTHER_FORM_ROWS].push(rowIndex) // #1b
          }
        } else {
          // 2b
          toReturn[POTENTIAL_DUPLICATES_OTHER_FORM_ROWS] = toReturn[POTENTIAL_DUPLICATES_OTHER_FORM_ROWS].filter(
            value => value !== rowIndex,
          )
        }
      }
      return toReturn
    },
  )

  return {
    ...previousPotentialDuplicates,
    [fieldArrayName]: newPotentialDuplicatesOfFieldArray,
  }
}


// Returns an array in the same shape as
// calculatePotentialDuplicatesWhenUserEditsARow().
export function calculatePotentialDuplicatesWhenUserDeletesARow(props) {
  const {
    potentialDuplicates,
    fieldArrayName,
    rowIndex,
  } = props

  // Just in case a caller passes in a Self-Dist & Pubs field name
  if (!fieldArraysToApplyPotentialDuplicatesFeatureTo.includes(fieldArrayName)) {
    return potentialDuplicates
  }

  // If the user deletes the only existing row
  if (potentialDuplicates[fieldArrayName].length === 1) {
    return createStateForPotentialDuplicatesIfUserDeletesAllRows(props)
  }

  const newPotentialDuplicatesOfTargetFieldArray = flow_(
    cloneFp_,
    // remove deleted row
    a => {
      pullAt_(a, [rowIndex]) // pullAt_ mutates the array in place
      return a
    },
    // delete all integers in POTENTIAL_DUPLICATES_OTHER_FORM_ROWS arrays
    // that equal rowIndex
    mapFp_(o => ({
      ...o,
      [POTENTIAL_DUPLICATES_OTHER_FORM_ROWS]: o[POTENTIAL_DUPLICATES_OTHER_FORM_ROWS].filter(
        i => (i !== rowIndex),
      ),
    })),
    // decrease by 1 all integers in POTENTIAL_DUPLICATES_OTHER_FORM_ROWS
    // arrays that are greater than rowIndex
    mapFp_(o => ({
      ...o,
      [POTENTIAL_DUPLICATES_OTHER_FORM_ROWS]: o[POTENTIAL_DUPLICATES_OTHER_FORM_ROWS].map(
        i => (i > rowIndex ? i-1 : i),
      ),
    })),
  )(potentialDuplicates[fieldArrayName])


  return {
    ...potentialDuplicates,
    [fieldArrayName]: newPotentialDuplicatesOfTargetFieldArray,
  }
}


export function extractPertinentDetailsFromShipmentsFormRowToDecideIfPotentialDuplicate({
  fieldArrayName,
  rowValues,
  entireItemSkusSlice,
}) {
  let origin = rowValues[FIELD_NAME_SOURCE_CUSTOMER]
  let destination = rowValues[FIELD_NAME_DESTINATION_CUSTOMER]
  const dateShipped = rowValues[FIELD_NAME_DATE_SHIPPED]
  const lineItems = flow_(
    pickFp_(Object.keys(entireItemSkusSlice)),
    entriesFp_,
    mapFp_(([itemSkuId, quantity]) => ({ itemSkuId, quantity })),
  )(rowValues)
  let reverseMovement = false
  if ([
    REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
    REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
  ].includes(fieldArrayName)) {
    [destination, origin] = [origin, destination]
    reverseMovement = true
  }

  return {
    origin,
    destination,
    dateShipped,
    reverseMovement,
    lineItems,
  }
}


export function getDoAnyFormRowsHaveUnresolvedPotentialDuplicates({
  potentialDuplicates, // The local state object
}) {
  if (!potentialDuplicates) { return false } // When form first renders
  return flow_(
    valuesFp_,
    flattenFp_,
    a => a.some(o => getDoesFormRowHaveUnresolvedPotentialDuplicates(o)),
  )(potentialDuplicates)
}


export function getDoesFormRowHaveUnresolvedPotentialDuplicates(potentialDuplicatesOfRow) {
  return (
    isTruthyAndNonEmpty(potentialDuplicatesOfRow[POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS])
    || isTruthyAndNonEmpty(potentialDuplicatesOfRow[POTENTIAL_DUPLICATES_OTHER_FORM_ROWS])
  )
}


export function createInitialLocalStateForPotentialDuplicates() {
  // This will result in an object with four field array values, but no form has
  // 4 field arrays--the Outbound Shipments form has 1 and Buybacks has 3. But
  // it doesn't hurt anything for there to be unused field array values in this
  // state. Too many is fine, too few results in a crash.
  return fieldArraysToApplyPotentialDuplicatesFeatureTo.reduce(
    (acc, fieldArrayName) => ({
      ...acc,
      [fieldArrayName]: [
        // All field arrays start out with one row
        {
          [POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS]: [],
          [POTENTIAL_DUPLICATES_OTHER_FORM_ROWS]: [],
        },
      ],
    }),
    {},
  )
}


function createStateForPotentialDuplicatesIfUserDeletesAllRows({
  potentialDuplicates,
  fieldArrayName,
}) {
  return {
    ...potentialDuplicates,
    [fieldArrayName]: [], // Empty array
  }
}


/*
 * *****************************************************************************
 * Helper Functions for Determining whether a row is a duplicate
 * *****************************************************************************
*/

// Returns an array:
// [
//   { key: 'origin: m1234, ...', dateReported: '12/17/2019', createdBy: 'John Cleese', proNumber: 'MP1234' },
//   { key: 'origin: m1234, ...', dateReported: '12/19/2019', createdBy: null, proNumber: 'MP5678' },
// ]
function getPotentialDuplicateOfPreviouslySubmittedShipments({
  entireReportShipmentsFormsPotentialDuplicatesSlice, // store slice
  entireUsersSlice, // store slice
  targetRowIndex,
  rowsDetailsOfFieldArray,
  isEditForm,
  itemObj, // only if isEditForm
}) {
  const pertinentDetails = rowsDetailsOfFieldArray[targetRowIndex].details
  if (!pertinentDetails) { // Row not fully filled out
    return []
  }
  const key = createPotentialDuplicateShipmentStringKeyForReduxStore(pertinentDetails)
  const potentialDupes = entireReportShipmentsFormsPotentialDuplicatesSlice[key]
  if (isTruthyAndNonEmpty(potentialDupes)) {
    // If this is an edit form, we don't want a potential duplicates warning to
    // be raised by the very item we're editing, that wouldn't make any sense.
    // So remove it from the list of potential duplicates.
    if (isEditForm) {
      if (potentialDupes.some(o => o.id === itemObj.id)) {
        return []
      }
    }
    return potentialDupes.map(o => {
      const dateReported = formatApiDate(o.dateReported, DEFAULT_DISPLAYED_DATE_FORMAT)
      const createdByUser = entireUsersSlice[o.createdByUserId]
      const createdBy = createdByUser
        ? `${createdByUser.firstName} ${createdByUser.lastName}`
        : null
      return {
        key,
        dateReported,
        createdBy,
        proNumber: o.proNumber,
      }
    })
  }
  return []
}


// Returns an array like this:
// [
//   {
//     isFieldRowFullyFilledOut: true,
//     details: { origin: 'm1234', destination: 'm5678', ...}
//   },
//   {
//     isFieldRowFullyFilledOut: false,
//     details: null, // details won't be calculated if the row isn't filled out.
//   },
//   ...
// ]
function extractPertinentDetailsFromAllRowsOfShipmentsFormFieldArrayToDecideIfPotentialDuplicate({
  formValues,
  fieldArrayName,
  entireItemSkusSlice,
}) {
  return formValues[fieldArrayName].map((rowValues, index) => {
    const isFieldRowFullyFilledOut = getIsFieldRowFullyFilledOut({
      rowValues,
      fieldArrayName,
      entireItemSkusSlice,
      ignoreReferenceNumber: true,
    })
    const details = isFieldRowFullyFilledOut
      ? extractPertinentDetailsFromShipmentsFormRowToDecideIfPotentialDuplicate({
        fieldArrayName,
        rowValues,
        entireItemSkusSlice,
      })
      : null
    return {
      rowIndex: index,
      isFieldRowFullyFilledOut,
      details,
    }
  })
}
