/**
 * disable autocomplete on input fields
 */

import React from 'react'
import { Input } from 'semantic-ui-react'


export default props => (
  <Input
    // This can't be "off" because Chrome ignores "off" for "historical
    // purposes"; use some other random string instead, such as "nope":
    // https://stackoverflow.com/a/30976223/6995996
    autoComplete="nope"
    // Overriding the 'compact' prop (which may be part of the 'props' arg) by
    // setting it to null does nothing but prevent the console warning "Received
    // `false` for a non-boolean attribute `compact`"
    compact={null}
    {...props}
  />
)
