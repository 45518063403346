import React, { Fragment, useState, useEffect, useMemo } from 'react'
import {
  Table,
  Icon,
} from 'semantic-ui-react'
import { compose } from 'recompose'

import sortBy_ from 'lodash/sortBy'
import get_ from 'lodash/get'


import { useTranslation } from 'react-i18next'
import AddOrEditIndividualContact from '../AddOrEditIndividualContact'
import DeleteIndividualContact from '../DeleteIndividualContact'

import WaitForApiFetch from '../../../common-components/WaitForApiFetch'
import UiContainer from '../../../common-components/UiContainer'
import InformationalPopup from '../../../common-components/InformationalPopup'
import StyledPopup from '../../../common-components/StyledPopup'
import RevealContactInfo from '../../../common-components/RevealContactInfo'
import ContactCard from '../../../common-components/ContactCard'
import withEither from '../../../common-components/HOC/general/withEither'

import {
  getAllContactObjectsOfCustomer,
  getAllContactsOfCustomer,
} from '../../../redux/selectors/contacts'

import {
  DISPLAYED_INFORMATIONAL_MESSAGE_CONTACT_TYPE,
  DISPLAYED_INFORMATIONAL_MESSAGE_DEFAULT_SHIPPING_CONTACT,
} from '../constants'


import {
  getHumanReadableContactTypeFromContactMashupObj,
  getApplicableEmailSubscriptionPropsBasedOnCustomerType,
} from '../utils'

import {
  CONTACT_EMAIL_DELIVERY_STATUS_UNDELIVERABLE,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE, CUSTOMER_TYPES_MASTER,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
  formatPhoneNumberForDisplay,
  formatPhoneNumberAndPotentialExtensionForDisplay,
} from '../../../utils'

import {
  TEMPORARILY_DISABLE_CONTACTS_FEATURE,
} from '../../../config'


import './styles.css'

const UlContent = ({ content }) => (
  <ul style={{ margin: 0, padding: 0, marginBlock: 0, listStyle: 'none' }}>
    <li style={{ height: '38px', padding: '8px', borderTop: '1px solid #e8e8e9' }}>{content}</li>
  </ul>
)

const ManageContactsAndSubscriptions = ({
  customerId,
  customerType,
  entireContactsSlice,
  entireCustomerContactLinksSlice,
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerName,
  tapCustomerId,
  userFirstName,
  userLastName,
  ceRepFirstName,
  ceRepLastName,
}) => {
  const [column, setColumn] = useState('lastName')
  const [data, setData] = useState([])
  const [direction, setDirection] = useState('ascending')
  const { t: translate } = useTranslation('common')

  const handleSort = (clickedColumn, optDirection) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn)
      setData(sortBy_(data, [clickedColumn]))
      setDirection(optDirection || 'ascending')
      return
    }
    setData(data.reverse())
    setDirection(optDirection || (direction === 'ascending' ? 'descending' : 'ascending'))
  }

  useEffect(
    () => {
      let thisCustomersContacts = getAllContactsOfCustomer({
        customerType,
        customerId,
        entireContactsSlice,
        entireCustomerContactLinksSlice,
      })
      thisCustomersContacts = sortBy_(
        thisCustomersContacts,
        // We don't want to change the user's selected sort order when they
        // update/delete users. Using the 'column' and 'direction' props ensures
        // that we re-sort the altered list by the user's currently-chosen sort
        // order.
        obj => obj[column],
        [direction === 'ascending' ? 'asc' : 'desc'],
      )
      setData(thisCustomersContacts)
      setDirection('ascending')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customerType,
      customerId,
      entireContactsSlice,
      entireCustomerContactLinksSlice,
    ],
  )

  const emailSubscriptionProps = useMemo(
    () => getApplicableEmailSubscriptionPropsBasedOnCustomerType({
      customerId,
      customerType,
      entireCustomersSlice,
      entireParentChildLinksSlice,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customerId,
      // Why aren't we including customerType, entireCustomersSlice and
      // entireParentChildLinksSlice? i.e. why are we disabling
      // react-hooks/exhaustive-deps? Because it messes with the edit form.
      // Details: emailSubscriptionProps gets passed in to the
      // AddOrEditIndividualContact component. Whenever it changes, the
      // resetFormValuesToInitialValues() function gets called. This is a
      // problem: a user can be filling out the Edit Contact form and the form
      // values will automatically change back to initial values right before
      // their eyes. This is especially a problem for CONBRW users, because
      // entireCustomersSlice will be changing for 30 seconds, up to over a
      // minute, after logging in depending on the number of contractees the
      // CONBRW has. Note that all the information needed to calculate
      // emailSubscriptionProps exists in the Redux store on this component's
      // first render, so omiting customerType, etc from the deps here isn't an
      // issue.
    ],
  )

  return (
    <div style={{ overflowX: 'auto' }}>
      {isTruthyAndNonEmpty(data) &&
        <Table celled sortable compact structured className="contacts-table" style={{ minWidth: '100%', width: 'max-content' }}>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell
                style={{ width: '80px' }}
                rowSpan='2'
              /> {/* Edit/Delete buttons */}
              <Table.HeaderCell
                sorted={column === 'lastName' ? direction : null}
                onClick={handleSort('lastName')}
                rowSpan='2'
              >
                {translate('Name')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'emailAddress' ? direction : null}
                onClick={handleSort('emailAddress')}
                rowSpan='2'
              >
                {translate('Email')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'contactType' ? direction : null}
                onClick={handleSort('contactType')}
                rowSpan='2'
              >
                {translate('Type')}
                <InformationalPopup
                  content={translate(DISPLAYED_INFORMATIONAL_MESSAGE_CONTACT_TYPE)}
                />
              </Table.HeaderCell>
              {customerType === CUSTOMER_TYPES_MASTER && <Table.HeaderCell rowSpan='2'>{translate('Location')}</Table.HeaderCell>}
              {customerType !== CUSTOMER_TYPES_MASTER && <Table.HeaderCell rowSpan='2'>{translate('Phone')}</Table.HeaderCell>}
              <Table.HeaderCell
                colSpan='3'
              >
                {translate('Email Subscriptions')}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row textAlign='center'>
              {emailSubscriptionProps.map(prop => (
                <Table.HeaderCell key={prop.name}>
                  {translate(prop.displayedName)}
                  <InformationalPopup
                    content={translate(prop.explanation)}
                  />
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.map(o => {
              const locations = []
              let subscriptions = []
              let customersWithSameContact
              if (customerType === CUSTOMER_TYPES_MASTER) {
                customersWithSameContact = Object.values(entireCustomerContactLinksSlice)?.filter(
                  obj => obj.contactId === o?.contactId,
                )
                // Vertically aligned content
                customersWithSameContact?.forEach(customer => {
                  locations.push(<UlContent content={entireCustomersSlice?.[customer?.customerId]?.name} />)
                })
                subscriptions = emailSubscriptionProps?.map(prop => {
                  const arr = []
                  customersWithSameContact?.forEach(customer => {
                    const customerProps = getAllContactObjectsOfCustomer({
                      customerType,
                      customerId: customer.customerId,
                      entireContactsSlice,
                      entireCustomerContactLinksSlice,
                    })?.find(obj => obj.contactId === customer.contactId)
                    arr.push(<UlContent content={get_(customerProps, prop.name) && <Icon color='green' name='checkmark' size='large'/>} />)
                  })
                  return <Table.Cell style={{ padding: 0 }}>{arr}</Table.Cell>
                })
              }
              return (
                <Table.Row key={o.id} textAlign='center' verticalAlign='top'>
                  <Table.Cell>
                    <AddOrEditIndividualContact
                      entireContactsSlice={entireContactsSlice}
                      entireCustomerContactLinksSlice={entireCustomerContactLinksSlice}
                      customerId={customerId}
                      isEditForm
                      contact={o}
                      contacts={data}
                      customerType={customerType}
                      customersWithSameContact={customersWithSameContact}
                      entireCustomersSlice={entireCustomersSlice}
                      emailSubscriptionProps={emailSubscriptionProps}
                      modalTrigger={({ openModal }) => (
                        <StyledPopup
                          trigger={<Icon link name='edit' color='blue' />}
                          content={translate('Edit')}
                          onClick={openModal}
                        />
                      )}
                    />
                    <DeleteIndividualContact
                      customerId={customerId}
                      contact={o}
                      contacts={data}
                      modalTrigger={({ openModal }) => (
                        <StyledPopup
                          trigger={<Icon link name='delete' color='red' />}
                          content={translate('Delete')}
                          onClick={openModal}
                        />
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell>{`${o.firstName} ${o.lastName}`}</Table.Cell>
                  <Table.Cell>
                    <span
                    style={
                      o.emailDeliveryStatus === CONTACT_EMAIL_DELIVERY_STATUS_UNDELIVERABLE
                        ? {
                          color: 'red',
                        }
                        : null
                    }
                    >
                      {o.emailAddress}
                    </span>
                    {o.emailDeliveryStatus === CONTACT_EMAIL_DELIVERY_STATUS_UNDELIVERABLE &&
                      <InformationalPopup
                        iconProps={{ color: 'red' }}
                        popupProps={{ className: 'semantic-ui-error-border', wide: true }}
                        content={
                          <Fragment>
                            {/* eslint-disable-next-line max-len */}
                            <span>{translate('Our system is unable to deliver to this email address, please update it. Contact your CE rep if you believe this email address is correct.')}</span>
                            <RevealContactInfo
                              customerId={customerId}
                              repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
                              blockDisplay
                            />
                          </Fragment>
                        }
                      />
                    }
                  </Table.Cell>
                  <Table.Cell>
                    {getHumanReadableContactTypeFromContactMashupObj(o)}
                    {o.defaultShippingContact &&
                      <div>
                        <span style={{ fontWeight: 'bold', color: 'green' }}>{translate('Default Shipping')}</span>
                        <InformationalPopup // CODE_COMMENTS_131
                          content={translate(DISPLAYED_INFORMATIONAL_MESSAGE_DEFAULT_SHIPPING_CONTACT)}
                        />
                      </div>
                    }
                  </Table.Cell>
                  {customerType === CUSTOMER_TYPES_MASTER && (
                    <Table.Cell style={{ padding: 0 }}>
                      {locations}
                    </Table.Cell>
                  )}
                  {customerType !== CUSTOMER_TYPES_MASTER && (
                    <Table.Cell
                    // We want the icons to line up vertically, even when a desk
                    // phone number has an extension and is therefore longer than
                    // a cell/fax number.
                    textAlign='left'
                    >
                      {o.deskPhone && <p style={{ marginBottom: '2px' }}><StyledPopup style={{ opacity: 0.9 }} trigger={<Icon style={{ color: '#525252' }} name="phone" />} content="Desk" />{formatPhoneNumberAndPotentialExtensionForDisplay(o.deskPhone, o.deskPhoneExt)}</p>}
                      {o.cellPhone && <p style={{ marginBottom: '2px' }}><StyledPopup style={{ opacity: 0.9 }} trigger={<Icon style={{ color: '#525252' }} name="mobile alternate" />} content="Cell" />{formatPhoneNumberForDisplay(o.cellPhone)}</p>}
                      {o.faxNumber && <p style={{ marginBottom: '2px' }}><StyledPopup style={{ opacity: 0.9 }} trigger={<Icon style={{ color: '#525252' }} name="print" />} content="Fax" />{formatPhoneNumberForDisplay(o.faxNumber)}</p>}
                    </Table.Cell>
                  )}
                  {customerType !== CUSTOMER_TYPES_MASTER && emailSubscriptionProps.map(prop => (
                    <Table.Cell>
                      {get_(o, prop.name) && <Icon color='green' name='checkmark' size='large' />}
                    </Table.Cell>
                  ))}
                  {customerType === CUSTOMER_TYPES_MASTER && subscriptions}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      }
      {!isTruthyAndNonEmpty(data) &&
        <p>{translate("You don't yet have any contacts.")}</p>
      }
      <InformationalPopup
        includeTriggerIcon={false}
        includeTriggerText
        triggerText={translate('Add New Contact')}
        open
        popupProps={{
          position: 'top right',
        }}
        content={(
          <Fragment>
            <p>
              {`${translate("Email us with your new Contact's information and we'll get it added right away")}:`}
            </p>
            <ContactCard
              customerId={customerId}
              repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
              customSubjectText={translate('Add New Contact')}
              customBodyText={
                getNewContactBodyText({
                  customerName,
                  tapCustomerId,
                  userFirstName,
                  userLastName,
                  ceRepFirstName,
                  ceRepLastName,
                })
              }
            />
          </Fragment>
        )}
      />
    </div>
  )
}

// const MasterAccountContacts = props => {
//   const [activeCustomer, setActiveCustomer] = useState({})
//   const { customerId, entireParentChildLinksSlice = [], entireCustomersSlice } = props || {}
//   const childCustomers = Object.values(entireParentChildLinksSlice)?.filter(
//     slice => slice.parentCustomerId === customerId,
//   )
//   const handleClick = (e, titleProps) => {
//     const { accCustomerId } = titleProps
//     setActiveCustomer({ ...activeCustomer, [accCustomerId]: !activeCustomer?.[accCustomerId] })
//   }
//   return (
//     <Fragment>
//       {childCustomers && childCustomers?.map(({ childCustomerId }) => (
//         <Accordion>
//           <Accordion.Title
//             active={activeCustomer[childCustomerId]}
//             accCustomerId={childCustomerId}
//             onClick={handleClick}
//           >
//             <label style={{ display: 'inline-block', width: 'fit-content' }}>
//               <Header as='h2' size='small' style={{ display: 'inline-block' }}>
//                {entireCustomersSlice?.[childCustomerId]?.name}
//               </Header>
//               <Icon name='dropdown' />
//             </label>
//           </Accordion.Title>
//           <Accordion.Content active={activeCustomer[childCustomerId]}>
//             <ManageContactsAndSubscriptions {...props} customerId={childCustomerId} />
//           </Accordion.Content>
//         </Accordion>
//       ))}
//     </Fragment>
//   )
// }

// Manage contacts & subscription screen. Route- /contacts
export default compose(
  UiContainer({
    headerText: 'Manage Contacts and Email Subscriptions',
    containerId: 'manageSubscriptionsSection',
  }),
  withEither(
    () => TEMPORARILY_DISABLE_CONTACTS_FEATURE,
    () => <p>This feature is temporarily disabled</p>,
  ),
  WaitForApiFetch,
)(
  ManageContactsAndSubscriptions,
)

// Manage contacts for master account. Route- /manage-all-contacts
export const ManageAllContacts = compose(
  UiContainer({
    headerText: 'Manage Contacts',
    containerId: 'manageSubscriptionsSection',
  }),
  withEither(
    () => TEMPORARILY_DISABLE_CONTACTS_FEATURE,
    () => <p>This feature is temporarily disabled</p>,
  ),
)(ManageContactsAndSubscriptions)


function getNewContactBodyText(data) {
  const template = `Hi ${data.ceRepFirstName} ${data.ceRepLastName},

Please add a new Contact for me.

Customer: ${data.customerName} (${data.tapCustomerId})
First Name *:
Last Name *:
Email *:
Type (Billing, Shipping, Both) *:
Default Shipping Contact (Y/N):
Desk Phone:
Cell Phone:
Fax Number:
Subscribe to Order Updates (Y/N):
Subscribe to Invoices:
Subscribe to Shipments:

Thanks!

${data.userFirstName || ''} ${data.userLastName || ''}`

  return template
}
