import {
  SAVE_CUSTOMER_BALANCES,
} from '../actions/actionTypes'

const initialState = {
  byCustomerId: {},
}

export default (state=initialState, action) => {
  switch (action.type) {
    case SAVE_CUSTOMER_BALANCES:
      return {
        ...state,
        byCustomerId: {
          ...state.byCustomerId,
          [action.payload.customerId]: action.payload.info,
        },
      }

    default:
      return state
  }
}
