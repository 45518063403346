import React, { Fragment } from 'react'

import {
  Segment,
  Header,
  Loader,
  Button,
} from 'semantic-ui-react'
import {
  Link,
} from 'react-router-dom'

import orderBy_ from 'lodash/orderBy'


import { useTranslation } from 'react-i18next'
import IndividualUser from '../../IndividualUser'
import LoadingSection from '../../../../common-components/LoadingSection'

import createAction from '../../../../redux/actions/createAction'

import {
  getAllUsersOfRootCustomerId,
} from '../../../../redux/selectors/rewrite/users'

import {
  CUSTOMER_TYPES_MASTER,
  URL_PATHS,
} from '../../../../constants'

import {
  isTruthyAndNonEmpty,
  getOperateForCustomerUrl,
} from '../../../../utils'


export default props => {
  const {
    customerId,
    customerType,
    isChildOfLoggedInMaster,
    entireUsersSlice,
    currentUserId,
    hasFetchBeenAttempted: hasFetchCustomerUsersBeenAttempted,
    isFetching: isCustomerUsersFetching,
    didFetchFail: didFetchCustomerUsersFail,
    refetchActionIfFetchFailure,
    dispatch,
  } = props
  const { t: translate } = useTranslation('common')
  const refetchCustomerUsers = () => (
    dispatch(createAction(
      refetchActionIfFetchFailure,
      props,
    ))
  )

  if (!hasFetchCustomerUsersBeenAttempted || isCustomerUsersFetching) {
    if (customerType === CUSTOMER_TYPES_MASTER || isChildOfLoggedInMaster) {
      return (
        <Fragment>
          <Loader active inline size="tiny" style={{ marginRight: '0.5rem' }} />
          <span>{`${translate('Fetching Users')}...`}</span>
        </Fragment>
      )
    }
    return (
      <LoadingSection
        message={translate('Fetching Users')}
      />
    )
  }

  if (didFetchCustomerUsersFail) {
    return (
      <Fragment>
        <span style={{ color: '#9f3a38' }}>{translate('Failed to fetch users!')}</span>
        <Button size="tiny" style={{ marginLeft: '1rem' }} color="blue" onClick={refetchCustomerUsers}>{translate('Retry')}</Button>
      </Fragment>
    )
  }

  const users = getUserObjectsForManageUsersForm({
    customerId,
    entireUsersSlice,
    currentUserId,
  })
  if (!isTruthyAndNonEmpty(users)) {
    return (
      <Fragment>
        <span>{translate('No users exist for this customer.')}</span>
        <Button size="tiny" style={{ marginLeft: '1rem' }} color="blue" onClick={refetchCustomerUsers}>{translate('Refresh List of Users')}</Button>
      </Fragment>
    )
  }

  return (
    <div>
      {users.map(userObject => {
        if (isChildOfLoggedInMaster) {
          const userFullName = `${userObject.firstName} ${userObject.lastName}`
          return (
            <Header as="h3" key={userObject.id}>{userFullName}</Header>
          )
        }
        return (
          <div key={userObject.id}>
            {/* Wrapping the displayed user info in a <Segment> with no box */}
            {/* shadow puts a a 1px grey bounding box around it */}
            <Segment
              style={{
                boxShadow: 'none',
                marginBottom: '10px',
              }}
            >
              <IndividualUser
                key={userObject.id}
                userObject={userObject}
                currentUserId={currentUserId}
                {...props}
              />
            </Segment>
          </div>
        )
      })}
      {isChildOfLoggedInMaster && (
        <Fragment>
          <span>{`${translate('To manage these users')},`}</span>
          <Link
            to={`${getOperateForCustomerUrl(customerId)}${URL_PATHS.users.path}`}
          >
            {translate("go to the child customer's Manage Users page.")}
          </Link>
        </Fragment>
      )}
    </div>
  )
}


// helper functions

function getUserObjectsForManageUsersForm({
  customerId,
  entireUsersSlice,
  currentUserId,
}) {
  let userObjs = getAllUsersOfRootCustomerId(entireUsersSlice, customerId)
  // Filter out inactive users: https://microstartap3.atlassian.net/browse/TP3-3980
  userObjs = userObjs.filter(o => o.active)
  return sortUsersByCurrentUserFirstThenByLastNameAlphabetically(userObjs, currentUserId)
}


function sortUsersByCurrentUserFirstThenByLastNameAlphabetically(users, currentUserId) {
  return orderBy_(
    users,
    [
      user => (user.id === currentUserId),
      'lastName',
    ],
    [
      'desc', // makes it so the current user appears first
      'asc',
    ],
  )
}
