import { connect } from 'react-redux'


import CustomerPortal from './components'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../../redux/selectors/permissions'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER,
} from '../../../../../constants/permissions'

const mapStateToProps = state => {
  const shouldOperateAsCustomerUserFormBeDisabled = !getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER,
    isThisAPermissionsCheckOfALoggedInEmployee: true,
  })

  return {
    // Why Booleans? See CODE_COMMENTS_252
    shouldOperateAsCustomerUserFormBeDisabled: Boolean(shouldOperateAsCustomerUserFormBeDisabled),
  }
}

export default connect(
  mapStateToProps,
)(CustomerPortal)
