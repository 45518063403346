/**
 * CODE_COMMENTS_131
 */
import React, { Component, Fragment } from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


import RevealContactInfo from '../../../../../common-components/RevealContactInfo'
import TwoColumnSplashScreen from '../../../../../common-components/TwoColumnSplashScreen'

import {
  UNIVERSAL_CUSTOMER_REPS_TECH_SUPPORT,
} from '../../../../../constants'


export default class CatastrophicFailurePage extends Component {
  componentDidMount() {
    this.props.logCatastrophicFailureException()
  }

  render() {
    const {
      isAuthenticated,
      urlPathToredirectToOnReloadApp,
      additionalActionsOnReloadApp,
      logout,
    } = this.props
    return (
      <TwoColumnSplashScreen
        title="Something Went Wrong"
        titleColor="red"
        content={() => (
          <Fragment>
            <p>{"We're sorry, this feature isn't working properly. Please reach out to your personalized rep for updates."}</p>
            <p>{isAuthenticated
              ? 'In the meantime, you can reload the app or try logging out, restarting the browser and logging back in. If all else fails, contact us:'
              : 'In the meantime, you can reload the app or contact us:'
            }</p>
            <Button
              as={Link}
              to={urlPathToredirectToOnReloadApp}
              type="button" // CODE_COMMENTS_260
              color="blue"
              onClick={additionalActionsOnReloadApp}
              style={{ marginRight: '1em' }}
            >
              Reload App
            </Button>
            {isAuthenticated &&
              <Button
                type="button" // CODE_COMMENTS_260
                color="blue"
                onClick={logout}
                style={{ marginRight: '1em' }}
              >
                Log out
              </Button>
            }
            <RevealContactInfo
              asPopup
              fullSizeButton
              buttonColor="green"
              repType={UNIVERSAL_CUSTOMER_REPS_TECH_SUPPORT}
              showGenericContactInfoIfFetchUniversalRepNotFinished
            />
          </Fragment>
        )}
      />
    )
  }
}
