// Functions used by multiple files within this directory

import memoize_ from 'lodash/memoize'
import isString_ from 'lodash/isString'
import isPlainObject_ from 'lodash/isPlainObject'

import flow_ from 'lodash/fp/flow'
import uniqFp_ from 'lodash/fp/uniq'
import filterFp_ from 'lodash/fp/filter'

import {
  getMultipleCustomerObjectsById,
} from '../../../../../../redux/selectors/customers'
import {
  getAllUniqueItemSkuIdsInLineItemsPropOfObjects,
  getItemSkusDefaultSortOrder,
} from '../../../../../../redux/selectors/rewrite/itemSkus'

import {
  determineItemSkuIdsToIncludeAsFieldsInTable,
} from '../../displayedDataCommon'
import { isPurposeOfShipmentToFulfillKegOrder } from '../../util'
import { inboundEmptyKegShipmentHistoryStatusesConfig } from '../inboundEmptyKegShipmentHistory'
import { isTruthyAndNonEmpty, sortArrayByTemplateArray } from '../../../../../../utils'

export function determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail({
  entireItemSkusSlice,
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged=false,
  shipment,
}) {
  let linkType
  const { lineItems } = shipment
  if (!isTruthyAndNonEmpty(lineItems)) return []
  const ackLineItemObj = lineItems?.filter(lineItem => (
    lineItem.linkType === 'ACKED'
  ))
  if (
    useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged
    && isPurposeOfShipmentToFulfillKegOrder(shipment)
    && ackLineItemObj.length > 0
    && !inboundEmptyKegShipmentHistoryStatusesConfig[
      shipment.status].displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder
  ) {
    linkType = 'ACKED'
  } else {
    linkType = 'SHIPPED'
  }
  // eslint-disable-next-line max-len
  const itemSkuIdsToInclude = Array.from(new Set(lineItems?.filter(curr => curr?.itemSkuId && curr.linkType === linkType)?.map(e => e.itemSkuId)))
  const sortedItemSkuIdsToInclude = sortArrayByTemplateArray(
    itemSkuIdsToInclude,
    getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
  )
  return sortedItemSkuIdsToInclude
}

export function determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
  entireItemSkusSlice,
  configuredItemSkuIds,
  shipments,
}) {
  const itemSkuIdsInHistory = getAllUniqueItemSkuIdsInLineItemsPropOfObjects({
    entireItemSkusSlice,
    objs: shipments,
    propName: 'lineItems',
  }) || []
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    itemSkuIdsInHistory,
  })
  return itemSkuIdsToIncludeAsFieldsInTable
}

/*
 * *****************************************************************************
 * Customer objects for Download Table as File
 * *****************************************************************************
*/

// Why do we memoize this? Because We want make sure the history tables don't
// re-render every time any unrelated part of the state updates.
export const getAllOriginAndDestinationCustomerObjectsFromShipmentObjects = memoize_(
  ({ state, arrayOrObjectOfObjects }) => (
    getUniqueOriginAndDestinationCustomerObjectsFromShipmentObjects({ state, arrayOrObjectOfObjects })
  ),
  ({ arrayOrObjectOfObjects }) => (
    getUniqueOriginAndDestinationCustomerIdsFromShipmentObjects({ arrayOrObjectOfObjects }).join()
  ),
)

// Helper functions for this section

// Returns an array of customerIds
function getUniqueOriginAndDestinationCustomerIdsFromShipmentObjects({ arrayOrObjectOfObjects }) {
  const arrayOfObjects = isPlainObject_(arrayOrObjectOfObjects)
    ? Object.values(arrayOrObjectOfObjects)
    : arrayOrObjectOfObjects

  return flow_(
    getOriginAndDestinationCustomerIdsFromShipmentObjects,
    uniqFp_,
    filterFp_(addressId => isString_(addressId)), // filter out nulls
    a => a.slice().sort(), // Sorting is important because we memoize the returned value
  )(arrayOfObjects)
}


// Returns an array of customerObjects
function getUniqueOriginAndDestinationCustomerObjectsFromShipmentObjects({ state, arrayOrObjectOfObjects }) {
  const customerIds = getUniqueOriginAndDestinationCustomerIdsFromShipmentObjects({ state, arrayOrObjectOfObjects })
  return getMultipleCustomerObjectsById(state, customerIds)
}


function getOriginAndDestinationCustomerIdsFromShipmentObjects(arrayOfShipmentObjects) {
  return arrayOfShipmentObjects.reduce(
    (acc, shipmentObj) => ([
      ...acc,
      shipmentObj.originCustomerId,
      shipmentObj.destinationCustomerId,
    ]),
    [],
  )
}
