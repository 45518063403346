/**
 * CODE_COMMENTS_213
 */

/* eslint-disable max-len */

import React from 'react'
import { TextArea } from 'semantic-ui-react'
import moment from 'moment'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import uniqFp_ from 'lodash/fp/uniq'
import flattenFp_ from 'lodash/fp/flatten'


import filterFp_ from 'lodash/fp/filter'
import DisplayInventoryReportFullDetails from '../DisplayInventoryReportFullDetails'
import WhatsAForeignKeg from '../sharedComponents/WhatsAForeignKeg'
import WhyIsBrewerReportingConbrwInventory from '../sharedComponents/WhyIsBrewerReportingConbrwInventory'
import MonthlyShippingTotalsHeaderTextBrewer from '../sharedComponents/MonthlyShippingTotalsHeaderTextBrewer'
import KegQtyField from '../components/KegQtyField'
import ContainerTypesDifferences from '../../ContainerTypesDifferences'
import DifferenceBetweenZeroAndBlankKegQtyFieldMessage from '../components/DifferenceBetweenZeroAndBlankKegQtyFieldMessage'

import MonthAndYearPicker from '../../../common-components/rewrite/MonthAndYearPicker'
import ReactDatePicker from '../../../common-components/rewrite/ReactDatepicker'
import InputNoAutoComplete from '../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'


import {
  getItemSkusDefaultSortOrder,
  getItemSkuIdQtyFromLineItem,
} from '../../../redux/selectors/rewrite/itemSkus'
import {
  getIsSubsidiaryATypeThatShouldTrackForeignKegs,
} from '../../../redux/selectors/rewrite/subsidiaries'


import {
  INVENTORY_REPORT_OBJS,
  INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ,
  INVENTORY_REPORT_OBJS_PUBS,
  INVENTORY_REPORT_OBJS_CONTRACT_BREWERS,
  INVENTORY_REPORT_OBJS_CONTRACTEES,
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS,
  INFO_JUST_FOR_THE_WEB_APP,
  INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME,
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS,

  CUSTOMER_TYPE_FOR_REPORT_INVENTORY,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_MAINTENANCE_FACILITY,
  CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS,
  CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS,
  CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED,
} from './constants'

import {
  HOW_MANY_YEARS_BACK_CAN_INVENTORY_BE_REPORTED,

  FIELD_NAME_MONTH_REPORTING_FOR,
  FIELD_NAME_REPORTING_CUSTOMER_NAME,
  FIELD_NAME_REPORTING_PERSON,
  FIELD_NAME_COUNTED_BY,
  FIELD_NAME_DATE_COUNTED,
  FIELD_NAME_COMMENTS,
  FIELD_NAME_DATE_REPORTED,
  FIELD_NAME_TOTAL_KEGS_FOREIGN,
  FIELD_NAME_EMPTY_CPP,

  FIELD_LABEL_MONTH_REPORTING_FOR,
  FIELD_LABEL_REPORTING_CUSTOMER_NAME,
  FIELD_LABEL_REPORTING_PERSON,
  FIELD_LABEL_COUNTED_BY,
  FIELD_LABEL_DATE_COUNTED,
  FIELD_LABEL_COMMENTS,
  FIELD_LABEL_DATE_REPORTED,
  FIELD_LABEL_TOTAL_KEGS_FOREIGN,
  FIELD_LABEL_EMPTY_CPP,
  FIELD_NAME_EMPTY_CCP,
  FIELD_LABEL_EMPTY_CCP,
} from '../../../constants/formAndApiUrlConfig/reportInventory'

import {
  ITEM_SKU_IDS_MKM_HB_DEFAULT,
  ITEM_SKU_IDS_MKM_SB_DEFAULT,
  ITEM_SKU_IDS_MKM_QB_DEFAULT,
  ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG,

  ITEM_SKU_IDS_CBI_PLASTIC_PALLET,

  ITEM_SKUS_SKU_TYPE_PALLET,

  BUSINESS_UNIT_ID_TYPE_MICROSTAR,
  BUSINESS_UNIT_ID_TYPE_KEGCRAFT,
  BUSINESS_UNIT_ID_TYPE_KEGSTAR,
  BUSINESS_UNIT_ID_TYPE_CONSTELLATION,
  BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT,
  ITEM_SKU_IDS_KS_L20,
  ITEM_SKU_IDS_KS_G9,
  ITEM_SKU_IDS_KS_L50,
  ITEM_SKU_IDS_KS_L30,
  ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET,
  ITEM_SKU_IDS_GOOD_PALLETS,
  BUSINESS_UNIT_ID_TYPE_BREWDOG,
  BUSINESS_UNIT_ID_TYPE_BELLS_BRAND,
  ITEM_SKU_IDS_BEL_QB_DEFAULT,
  ITEM_SKU_IDS_BEL_HB_DEFAULT,
} from '../../../constants'

import {
  createLabelToApiInventoryTypeMapBrwEmptysFullsEtcNoPubs,
  createLabelToApiInventoryTypeMapContractBrewersOfBrw,
  createLabelToApiInventoryTypeMapPubs,
  createLabelToApiInventoryTypeMapBrwTotalsShippedAndReceived,

  createLabelToApiInventoryMapConbrwEmptysDefectivesEtc,
  createLabelToApiInventoryMapContracteesOfConbrw,
  createLabelToApiInventoryMapConbrwTotalsFilledAndReceived,

  createLabelToApiInventoryMapDistEmptys,
  creatorOfCreateLabelToApiInventoryMapDistNoLoginRequired,
  creatorOfCreateLabelToApiConstellationMapDistNoLoginRequired,
} from './apiToInventoryTypeMaps'

import {
  getMostRepresentativeInventoryReportObj,
  getIsKegQtyFormValueFilledOut,
} from './'

import {
  createDisplayedCountedBy,
  createDisplayedDateCounted,
  createDisplayedOrderComments,
  createDisplayedDateReported,
} from '../../History/individualTabs/util/inventoryHistory/displayedInventoryReportData'

import {
  maxLength,
} from '../../../utils/formNormalization'

import {
  isTruthyAndNonEmpty,
  sortArrayByTemplateArray,
} from '../../../utils'

/*
 * *****************************************************************************
 * Helper constants, mostly for object keys and values. Why not just use
 * string literals? See CODE_COMMENTS_33
 * *****************************************************************************
*/

export const TITLE_OF_REPORT_FORM = 'TITLE_OF_REPORT_FORM'
export const TITLE_OF_EDIT_FORM = 'TITLE_OF_EDIT_FORM'
export const TITLE_OF_FULL_DETAILS_VIEW = 'TITLE_OF_FULL_DETAILS_VIEW'
export const CONTAINER_CLASS_OF_FULL_DETAILS_VIEW = 'CONTAINER_CLASS_OF_FULL_DETAILS_VIEW'

const normalInventoryReportHeadingsAndBrandingLanguage = {
  [TITLE_OF_REPORT_FORM]: 'Report Inventory',
  [TITLE_OF_EDIT_FORM]: 'Edit Inventory Report',
  [TITLE_OF_FULL_DETAILS_VIEW]: 'Inventory Report Details',
  [CONTAINER_CLASS_OF_FULL_DETAILS_VIEW]: 'test-inventory-report-full-details',
}

const emptyKegReportHeadingsAndBrandingLanguage = {
  [TITLE_OF_REPORT_FORM]: 'Report Empty Kegs and Constellation Pallets',
  [TITLE_OF_EDIT_FORM]: 'Edit Empty Kegs Report',
  [TITLE_OF_FULL_DETAILS_VIEW]: 'Empty Kegs Report Details',
  [CONTAINER_CLASS_OF_FULL_DETAILS_VIEW]: 'test-inventory-report-full-details',
}

export const HEADINGS_AND_BRANDING_LANGUAGE = 'HEADINGS_AND_BRANDING_LANGUAGE'
export const ARBITRARY_CONTENT_BEFORE_FORM = 'ARBITRARY_CONTENT_BEFORE_FORM'
export const SECTION_DEFINITIONS = 'SECTION_DEFINITIONS'


export const SECTION_KEY = 'SECTION_KEY'
export const SECTION_TITLE = 'SECTION_TITLE'
export const ARBITRARY_CONTENT_AFTER_SECTION_TITLE_AND_BEFORE_SECTION_FORM_FIELDS = 'ARBITRARY_CONTENT_AFTER_SECTION_TITLE_AND_BEFORE_SECTION_FORM_FIELDS'
export const SECTION_FIELDS = 'SECTION_FIELDS'

export const FIELD_NAME = 'FIELD_NAME'
export const FIELD_LABEL = 'FIELD_LABEL'
export const TRUNCATED_FIELD_LABEL_FOR_HISTORY_TABLE = 'TRUNCATED_FIELD_LABEL_FOR_HISTORY_TABLE'
export const ARBITRARY_CONTENT_AFTER_LABEL_TEXT = 'ARBITRARY_CONTENT_AFTER_LABEL_TEXT'
// The default 'kind' is an individual field. But sometimes we want one section
// form field (defined in SECTION_FIELDS) to define multiple fields, for
// instance, a labeled grid of keg quantity fields.
export const FORM_FIELD_KIND = 'FORM_FIELD_KIND'
export const FORM_FIELD_KIND_INDIVIDUAL_FIELD = 'FORM_FIELD_KIND_INDIVIDUAL_FIELD'
export const FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP = 'FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP'
// A quick way to override the container types (i.e. columns) rendered at the
// granularity of a single section (i.e. form-section-as-labeled-grid, i.e.
// label-to-API-inventory-type-map).
export const CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP = 'CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP'
// Use this if you want to prevent a Keg QTY form field from being rendered in
// certain cells of a form-section-as-labeled-grid. For example, if you don't
// want to render the field in the 2nd column of the 2nd row of a 3x3 grid (but
// you do want to render in all other cells), set this variable to:
// [
//   [true, true, true],
//   [true, false, true],
//   [true, true, true],
// ]
export const CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP = 'CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP'

export const REPORT_FORM_FIELD_COMPONENT = 'REPORT_FORM_FIELD_COMPONENT'
export const EDIT_FORM_FIELD_COMPONENT = 'EDIT_FORM_FIELD_COMPONENT'
export const FULL_DETAILS_COMPONENT = 'FULL_DETAILS_COMPONENT'
export const HISTORY_TABLE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT = 'HISTORY_TABLE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT'
export const DOWNLOAD_FILE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT = 'DOWNLOAD_FILE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT'

export const SAME_AS_REPORT_FORM_FIELD_COMPONENT = 'SAME_AS_REPORT_FORM_FIELD_COMPONENT'
export const SAME_AS_EDIT_FORM_FIELD_COMPONENT = 'SAME_AS_EDIT_FORM_FIELD_COMPONENT'
export const SAME_AS_FULL_DETAILS_COMPONENT = 'SAME_AS_FULL_DETAILS_COMPONENT'

export const IS_FORM_FIELD_SUBMITTABLE = 'IS_FORM_FIELD_SUBMITTABLE'

export const ONLY_RENDER_SECTION_IF = 'ONLY_RENDER_SECTION_IF'
export const ONLY_RENDER_FIELD_IF = 'ONLY_RENDER_FIELD_IF'

export const LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR = 'LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR'
export const INCLUDE_TOTALS = 'INCLUDE_TOTALS'
// The history table can only contain so many columns, otherwise we run out of
// horizontal space, so for things like Pubs, we only include the totals of all
// pubs rather than columns for each individual pub.
export const LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE = 'LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE'
export const HEADING_IF_LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE = 'HEADING_IF_LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE'

export const INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS = 'INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS'

// By default, the left edge of all form fields in the Report/Edit/FullDetails
// representations line up vertically, giving a clean aesthetic look. Sometimes
// you might not want a field to line up vertically, you might want to slightly
// offset it to the right to make it stand out. NOTE: only use this with
// FORM_FIELD_KIND_INDIVIDUAL_FIELD.
export const SLIGHTLY_OFFSET_FIELD_TO_RIGHT = 'SLIGHTLY_OFFSET_FIELD_TO_RIGHT'

export const IS_FORM_FIELD_REQUIRED = 'IS_FORM_FIELD_REQUIRED'

export const NORMAL_TOP_SECTION_KEY = 'NORMAL_TOP_SECTION_KEY'
export const TOP_SECTION_NO_MONTH_OR_COUNTED_BY_KEY = 'TOP_SECTION_NO_MONTH_OR_COUNTED_BY_KEY'
export const ALL_TOP_SECTIONS = [
  NORMAL_TOP_SECTION_KEY,
  TOP_SECTION_NO_MONTH_OR_COUNTED_BY_KEY,
]

/*
 * *****************************************************************************
 * Top section config objects
 * *****************************************************************************
*/

const normalTopSectionObject = {
  [SECTION_KEY]: NORMAL_TOP_SECTION_KEY, // arbitrary--simply for the React render loop
  [SECTION_TITLE]: null, // Top section doesn't have a title
  [SECTION_FIELDS]: [
    // Month Reporting For
    {
      [FIELD_NAME]: FIELD_NAME_MONTH_REPORTING_FOR,
      [FIELD_LABEL]: FIELD_LABEL_MONTH_REPORTING_FOR,
      [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
      [REPORT_FORM_FIELD_COMPONENT]: ({
        formValues,
        changeFormValue,
        monthReportingForRangeMin,
        monthReportingForRangeMax,
      }) => (
        <MonthAndYearPicker
          rangeMin={monthReportingForRangeMin}
          rangeMax={monthReportingForRangeMax}
          sortOptionsInDropdownFromLatestToEarliestRatherThanEarliestToLatest
          name={FIELD_NAME_MONTH_REPORTING_FOR}
          value={formValues[FIELD_NAME_MONTH_REPORTING_FOR]}
          onChange={(e, { value }) => {
            changeFormValue({
              fieldName: FIELD_NAME_MONTH_REPORTING_FOR,
              value,
            })
          }}
        />
      ),
      [EDIT_FORM_FIELD_COMPONENT]: null, // Not rendered in edit form
      [FULL_DETAILS_COMPONENT]: () => null, // Not rendered in Full Details
      [IS_FORM_FIELD_SUBMITTABLE]: ({ value }) => value, // can't be blank
      [IS_FORM_FIELD_REQUIRED]: true,
      [ONLY_RENDER_FIELD_IF]: ({
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
      }) => (
        // Only render this field if it's for creating a new inventory report
        !isEditForm
        && !isThisForDisplayingAnAlreadyCreatedInventoryReport
      ),
    },

    // Date Counted
    {
      [FIELD_NAME]: FIELD_NAME_DATE_COUNTED,
      [FIELD_LABEL]: FIELD_LABEL_DATE_COUNTED,
      [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
      [IS_FORM_FIELD_REQUIRED]: true,
      [REPORT_FORM_FIELD_COMPONENT]: ({
        formValues,
        changeFormValue,
        isEditForm,
        dateCountedMinDate,
        dateCountedMaxDate,
        dateCountedOpenToDate,
        [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
      }) => {
        let isFieldDisabled
        let placeholderText
        let minDate = dateCountedMinDate
        let maxDate = dateCountedMaxDate
        if (customerTypeForReportInventory === CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR || customerTypeForReportInventory === CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE || customerTypeForReportInventory === CUSTOMER_TYPE_FOR_REPORT_INVENTORY_MAINTENANCE_FACILITY) {
          isFieldDisabled = false
          placeholderText = 'Select Date'
          minDate = moment().subtract(HOW_MANY_YEARS_BACK_CAN_INVENTORY_BE_REPORTED, 'years')
          maxDate = moment()
        } else if (
          // If the dateCountedMinDate is null, it means the "Month Reporting
          // For" field hasn't been selected yet
          dateCountedMinDate === null ||
          isEditForm
        ) {
          isFieldDisabled = true
          placeholderText = 'Choose month first'
        } else {
          isFieldDisabled = false
          placeholderText = 'Select Date'
        }
        return (
          /* CODE_COMMENTS_32 */
          <div
            className="ui input no-lighter-on-disabled"
          >
            <ReactDatePicker
              minDate={minDate}
              maxDate={maxDate}
              disabled={isFieldDisabled}
              openToDate={dateCountedOpenToDate}
              placeholderText={placeholderText}
              name={FIELD_NAME_DATE_COUNTED}
              value={formValues[FIELD_NAME_DATE_COUNTED]} // CODE_COMMENTS_245
              onChange={value => {
                changeFormValue({
                  fieldName: FIELD_NAME_DATE_COUNTED,
                  value,
                })
              }}
            />
          </div>
        )
      },
      [EDIT_FORM_FIELD_COMPONENT]: SAME_AS_FULL_DETAILS_COMPONENT,
      [IS_FORM_FIELD_SUBMITTABLE]: ({ value }) => value, // field can't be blank.
      [FULL_DETAILS_COMPONENT]: ({
        [INVENTORY_REPORT_OBJS]: invRepObjs,
      }) => {
        const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
          [INVENTORY_REPORT_OBJS]: invRepObjs,
        })
        return createDisplayedDateCounted(individualInventoryReportObj)
      },
      [INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
        const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
          [INVENTORY_REPORT_OBJS]: invRepObjs,
        })
        return individualInventoryReportObj.countDate
      },
      [HISTORY_TABLE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT]: (
        row, // groupingOfInventoryReportObjsForHistoryTable object (CODE_COMMENTS_229)
        { customerId },
      ) => (
        <DisplayInventoryReportFullDetails
          {...row}
          customerId={customerId}
        />
      ),
    },

    // Customer Name
    {
      [FIELD_NAME]: FIELD_NAME_REPORTING_CUSTOMER_NAME,
      [FIELD_LABEL]: FIELD_LABEL_REPORTING_CUSTOMER_NAME,
      [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
      [ONLY_RENDER_FIELD_IF]: ({
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        !isThisForDisplayingAsARowInTheHistoryTable
      ),
      // Read-only field
      [REPORT_FORM_FIELD_COMPONENT]: SAME_AS_FULL_DETAILS_COMPONENT,
      [EDIT_FORM_FIELD_COMPONENT]: SAME_AS_FULL_DETAILS_COMPONENT,
      [FULL_DETAILS_COMPONENT]: ({ customerName }) => customerName,
    },

    // Reporting Person
    {
      [FIELD_NAME]: FIELD_NAME_REPORTING_PERSON,
      [FIELD_LABEL]: FIELD_LABEL_REPORTING_PERSON,
      [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
      [ONLY_RENDER_FIELD_IF]: ({
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        !isThisForDisplayingAsARowInTheHistoryTable
      ),
      // Read-only field
      [REPORT_FORM_FIELD_COMPONENT]: SAME_AS_FULL_DETAILS_COMPONENT,
      [EDIT_FORM_FIELD_COMPONENT]: SAME_AS_FULL_DETAILS_COMPONENT,
      [FULL_DETAILS_COMPONENT]: ({ nameOfReportingPerson }) => nameOfReportingPerson,
    },

    // Counted By
    {
      [FIELD_NAME]: FIELD_NAME_COUNTED_BY,
      [FIELD_LABEL]: FIELD_LABEL_COUNTED_BY,
      [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
      [ONLY_RENDER_FIELD_IF]: ({
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        !isThisForDisplayingAsARowInTheHistoryTable
      ),
      [REPORT_FORM_FIELD_COMPONENT]: ({
        formValues,
        changeFormValue,
      }) => (
        <InputNoAutoComplete
          value={formValues[FIELD_NAME_COUNTED_BY] || ''} // CODE_COMMENTS_245
          onChange={(e, { value }) => {
            changeFormValue({
              fieldName: FIELD_NAME_COUNTED_BY,
              value,
              normalizer: maxLength(50), // CODE_COMMENTS_192
            })
          }}
          placeholder="Person Who Counted"
        />
      ),
      [EDIT_FORM_FIELD_COMPONENT]: SAME_AS_REPORT_FORM_FIELD_COMPONENT,
      [IS_FORM_FIELD_SUBMITTABLE]: ({ value }) => value, // can't be blank
      [IS_FORM_FIELD_REQUIRED]: true,
      [FULL_DETAILS_COMPONENT]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
        const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
          [INVENTORY_REPORT_OBJS]: invRepObjs,
        })
        return createDisplayedCountedBy(individualInventoryReportObj)
      },
      [INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
        const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
          [INVENTORY_REPORT_OBJS]: invRepObjs,
        })
        return individualInventoryReportObj.countedBy
      },
    },

    // Comments
    {
      [FIELD_NAME]: FIELD_NAME_COMMENTS,
      [FIELD_LABEL]: FIELD_LABEL_COMMENTS,
      [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
      [ONLY_RENDER_FIELD_IF]: ({
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        !isThisForDisplayingAsARowInTheHistoryTable
      ),
      [REPORT_FORM_FIELD_COMPONENT]: ({
        formValues,
        changeFormValue,
      }) => (
        <TextArea
          value={formValues[FIELD_NAME_COMMENTS] || ''} // CODE_COMMENTS_245
          onChange={(e, { value }) => {
            changeFormValue({
              fieldName: FIELD_NAME_COMMENTS,
              value,
              normalizer: maxLength(255), // CODE_COMMENTS_192
            })
          }}
        />
      ),
      [EDIT_FORM_FIELD_COMPONENT]: SAME_AS_REPORT_FORM_FIELD_COMPONENT,
      [IS_FORM_FIELD_SUBMITTABLE]: () => true, // optional field
      [IS_FORM_FIELD_REQUIRED]: false,
      [FULL_DETAILS_COMPONENT]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
        const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
          [INVENTORY_REPORT_OBJS]: invRepObjs,
        })
        return createDisplayedOrderComments('none')(individualInventoryReportObj)
      },
      // The FULL_DETAILS_COMPONENT renders a grey 'none' React element if no
      // comment; we want the download file content to be a blank string rather
      // than a React element (which would display as '[Object Object]' if we
      // didn't override it here)
      [DOWNLOAD_FILE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
        const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
          [INVENTORY_REPORT_OBJS]: invRepObjs,
        })
        return individualInventoryReportObj.comments
      },
      [INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
        const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
          [INVENTORY_REPORT_OBJS]: invRepObjs,
        })
        return individualInventoryReportObj.comments
      },
    },

    // Date Reported
    {
      [FIELD_NAME]: FIELD_NAME_DATE_REPORTED,
      [FIELD_LABEL]: FIELD_LABEL_DATE_REPORTED,
      [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
      [ONLY_RENDER_FIELD_IF]: ({
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        isThisForDisplayingAnAlreadyCreatedInventoryReport
        && !isThisForDisplayingAsARowInTheHistoryTable
      ),
      [FULL_DETAILS_COMPONENT]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
        const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
          [INVENTORY_REPORT_OBJS]: invRepObjs,
        })
        return createDisplayedDateReported(individualInventoryReportObj)
      },
    },
  ],
}

// for DIST customers
const topSectionObjectWithoutReportingForMonthOrCountedByFields = {
  ...normalTopSectionObject,
  [SECTION_KEY]: TOP_SECTION_NO_MONTH_OR_COUNTED_BY_KEY, // arbitrary--simply for the React render loop
  // CODE_COMMENTS_270: don't render a top section for distributors
  [ONLY_RENDER_SECTION_IF]: ({
    isThisForDisplayingAsARowInTheHistoryTable,
    isThisForDisplayingAnAlreadyCreatedInventoryReport,
    isThisForDisplayingAsARowInTheDownloadFile,
  }) => (
    isThisForDisplayingAsARowInTheHistoryTable
    || isThisForDisplayingAnAlreadyCreatedInventoryReport
    || isThisForDisplayingAsARowInTheDownloadFile
  ),
  [SECTION_FIELDS]: normalTopSectionObject[SECTION_FIELDS].filter(sectionFieldDef => (
    ![
      FIELD_NAME_MONTH_REPORTING_FOR,
      FIELD_NAME_COUNTED_BY,
    ].includes(sectionFieldDef[FIELD_NAME])
  )),
}


/**
* *****************************************************************************
 * parts shared by multiple config objects
 * *****************************************************************************
*/

const foreignKegsFieldConfigObjPart = {
  [FIELD_NAME]: FIELD_NAME_TOTAL_KEGS_FOREIGN,
  [FIELD_LABEL]: FIELD_LABEL_TOTAL_KEGS_FOREIGN,
  [TRUNCATED_FIELD_LABEL_FOR_HISTORY_TABLE]: 'Foreign',
  [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
  [REPORT_FORM_FIELD_COMPONENT]: props => (
    <KegQtyField
      {...props}
      name={FIELD_NAME_TOTAL_KEGS_FOREIGN}
      placeholder="Foreign Kegs Qty"
    />
  ),
  [EDIT_FORM_FIELD_COMPONENT]: SAME_AS_REPORT_FORM_FIELD_COMPONENT,
  [IS_FORM_FIELD_REQUIRED]: true,
  [IS_FORM_FIELD_SUBMITTABLE]: ({ value }) => getIsKegQtyFormValueFilledOut(value),
  [FULL_DETAILS_COMPONENT]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
    const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
      [INVENTORY_REPORT_OBJS]: invRepObjs,
    })
    return getItemSkuIdQtyFromLineItem({
      lineItems: individualInventoryReportObj.inventoryLineItemObjects,
      itemSkuId: ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG,
    }) || 0
  },
  [INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
    const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
      [INVENTORY_REPORT_OBJS]: invRepObjs,
    })
    return getItemSkuIdQtyFromLineItem({
      lineItems: individualInventoryReportObj.inventoryLineItemObjects,
      itemSkuId: ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG,
    }) || 0
  },
  [ONLY_RENDER_FIELD_IF]: ({
    entireSubsidiariesSlice,
    subsidiaryId,
  }) => (
    getIsSubsidiaryATypeThatShouldTrackForeignKegs({
      entireSubsidiariesSlice,
      subsidiaryId,
    })
  ),
}

const emptyCppFieldConfigObjPart = {
  [FIELD_NAME]: FIELD_NAME_EMPTY_CPP,
  [FIELD_LABEL]: FIELD_LABEL_EMPTY_CPP,
  [TRUNCATED_FIELD_LABEL_FOR_HISTORY_TABLE]: 'CPP',
  [ARBITRARY_CONTENT_AFTER_LABEL_TEXT]: () => (
    <ContainerTypesDifferences
      itemSkuId={ITEM_SKU_IDS_CBI_PLASTIC_PALLET}
    />
  ),
  [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
  [ONLY_RENDER_FIELD_IF]: ({ itemSkuIds, isThisForDisplayingAsARowInTheHistoryTable }) => !isThisForDisplayingAsARowInTheHistoryTable && itemSkuIds.includes(ITEM_SKU_IDS_CBI_PLASTIC_PALLET),
  [REPORT_FORM_FIELD_COMPONENT]: props => (
    <KegQtyField
      {...props}
      name={FIELD_NAME_EMPTY_CPP}
      placeholder="CPP Qty"
    />
  ),
  [EDIT_FORM_FIELD_COMPONENT]: SAME_AS_REPORT_FORM_FIELD_COMPONENT,
  [IS_FORM_FIELD_REQUIRED]: true,
  [IS_FORM_FIELD_SUBMITTABLE]: ({ value }) => getIsKegQtyFormValueFilledOut(value),
  [FULL_DETAILS_COMPONENT]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
    const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
      [INVENTORY_REPORT_OBJS]: invRepObjs,
    })
    return getItemSkuIdQtyFromLineItem({
      lineItems: individualInventoryReportObj.inventoryLineItemObjects,
      itemSkuId: ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
    }) || 0
  },
  [INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
    const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
      [INVENTORY_REPORT_OBJS]: invRepObjs,
    })
    return getItemSkuIdQtyFromLineItem({
      lineItems: individualInventoryReportObj.inventoryLineItemObjects,
      itemSkuId: ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
    }) || 0
  },
}
const emptyCcpFieldConfigObjPart = {
  [FIELD_NAME]: FIELD_NAME_EMPTY_CCP,
  [FIELD_LABEL]: FIELD_LABEL_EMPTY_CCP,
  [TRUNCATED_FIELD_LABEL_FOR_HISTORY_TABLE]: 'CCP',
  [ARBITRARY_CONTENT_AFTER_LABEL_TEXT]: () => (
    <ContainerTypesDifferences
      itemSkuId={ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET}
    />
  ),
  [FORM_FIELD_KIND]: FORM_FIELD_KIND_INDIVIDUAL_FIELD,
  [ONLY_RENDER_FIELD_IF]: ({ itemSkuIds, isThisForDisplayingAsARowInTheHistoryTable }) => !isThisForDisplayingAsARowInTheHistoryTable && itemSkuIds.includes(ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET),
  [REPORT_FORM_FIELD_COMPONENT]: props => (
    <KegQtyField
      {...props}
      name={FIELD_NAME_EMPTY_CCP}
      placeholder="CCP Qty"
    />
  ),
  [EDIT_FORM_FIELD_COMPONENT]: SAME_AS_REPORT_FORM_FIELD_COMPONENT,
  [IS_FORM_FIELD_REQUIRED]: true,
  [IS_FORM_FIELD_SUBMITTABLE]: ({ value }) => getIsKegQtyFormValueFilledOut(value),
  [FULL_DETAILS_COMPONENT]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
    const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
      [INVENTORY_REPORT_OBJS]: invRepObjs,
    })
    return getItemSkuIdQtyFromLineItem({
      lineItems: individualInventoryReportObj.inventoryLineItemObjects,
      itemSkuId: ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET,
    }) || 0
  },
  [INITIAL_VALUE_OF_EDIT_FORM_AND_FULL_DETAILS]: ({ [INVENTORY_REPORT_OBJS]: invRepObjs }) => {
    const individualInventoryReportObj = getMostRepresentativeInventoryReportObj({
      [INVENTORY_REPORT_OBJS]: invRepObjs,
    })
    return getItemSkuIdQtyFromLineItem({
      lineItems: individualInventoryReportObj.inventoryLineItemObjects,
      itemSkuId: ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET,
    }) || 0
  },
}

/**
* *****************************************************************************
 * Config object of all BRW types
 * *****************************************************************************
*/

const reportInventoryConfigBrw = {
  [HEADINGS_AND_BRANDING_LANGUAGE]: normalInventoryReportHeadingsAndBrandingLanguage,
  [SECTION_DEFINITIONS]: [
    normalTopSectionObject,
    {
      [SECTION_KEY]: 'Own brewer totals', // arbitrary--simply for the React render loop
      [SECTION_TITLE]: ({
        businessUnitName,
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
      }) => {
        const includePubsSection = isTruthyAndNonEmpty(inventoryReportObjs[INVENTORY_REPORT_OBJS_PUBS])
        if (isTruthyAndNonEmpty(inventoryReportObjs[INVENTORY_REPORT_OBJS_CONTRACT_BREWERS])) {
          return `Kegs in Inventory Delivered to Your Own Brewery (Not Your Contract Breweries)${includePubsSection ? ', Excluding Pubs' : ''}`
        }
        return `Total Number of ${businessUnitName}-Delivered Kegs in Inventory${includePubsSection ? ', Excluding Pubs' : ''}`
      },
      [ONLY_RENDER_SECTION_IF]: ({ [INVENTORY_REPORT_OBJS]: inventoryReportObjs }) => (
        isTruthyAndNonEmpty(inventoryReportObjs[INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ])
      ),
      [ARBITRARY_CONTENT_AFTER_SECTION_TITLE_AND_BEFORE_SECTION_FORM_FIELDS]: props => {
        const {
          entireSubsidiariesSlice,
          subsidiaryId,
        } = props
        if (getIsSubsidiaryATypeThatShouldTrackForeignKegs({
          entireSubsidiariesSlice,
          subsidiaryId,
        })) {
          return <WhatsAForeignKeg {...props} />
        }
        return null
      },
      [SECTION_FIELDS]: [
        {
          [FIELD_NAME]: 'Own brewer totals labeled grid fields', // arbitrary--simply for the React render loop
          [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: createLabelToApiInventoryTypeMapBrwEmptysFullsEtcNoPubs,
          // It doesn't make sense to have Pallets in the
          // label-to-API-inventory-map, because there's no such thing as an
          // empty pallet, full pallet, etc. Pallets need to be in the Report
          // Inventory form, but they should be individual fields with a total
          // count, like total foreign kegs. Therefore, we filter them out of
          // the label-to-API-inventory-map here.
          [CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]: ({ entireItemSkusSlice, itemSkuIds }) => (
            itemSkuIds.filter(id => entireItemSkusSlice[id].skuType !== ITEM_SKUS_SKU_TYPE_PALLET)
          ),
          [INCLUDE_TOTALS]: true,
        },
        foreignKegsFieldConfigObjPart,
        emptyCppFieldConfigObjPart,
        emptyCcpFieldConfigObjPart,
      ],
    },
    {
      [SECTION_KEY]: 'Contract Brewers totals', // arbitrary--simply for the React render loop
      [SECTION_TITLE]: () => 'Full Kegs Currently At Your Contract Breweries',
      [ONLY_RENDER_SECTION_IF]: ({
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        isTruthyAndNonEmpty(inventoryReportObjs[INVENTORY_REPORT_OBJS_CONTRACT_BREWERS])
        && !isThisForDisplayingAsARowInTheHistoryTable
      ),
      [ARBITRARY_CONTENT_AFTER_SECTION_TITLE_AND_BEFORE_SECTION_FORM_FIELDS]: ({
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
      }) => {
        if (isThisForDisplayingAnAlreadyCreatedInventoryReport) {
          return null
        }
        return <WhyIsBrewerReportingConbrwInventory />
      },
      [SECTION_FIELDS]: [
        {
          [FIELD_NAME]: 'Contract brewers totals labeled grid fields', // arbitrary--simply for the React render loop
          [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: createLabelToApiInventoryTypeMapContractBrewersOfBrw,
          [INCLUDE_TOTALS]: true,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE]: true,
          [HEADING_IF_LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE]: 'All Conbrw',
        },
      ],
    },
    {
      [SECTION_KEY]: 'Pubs totals', // arbitrary--simply for the React render loop
      [SECTION_TITLE]: ({ businessUnitName }) => `Total Number of ${businessUnitName} Kegs in Brewery-Owned Pubs and Tasting Rooms (Both Empty and Full)`,
      [ONLY_RENDER_SECTION_IF]: ({
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        isTruthyAndNonEmpty(inventoryReportObjs[INVENTORY_REPORT_OBJS_PUBS])
        && !isThisForDisplayingAsARowInTheHistoryTable
      ),
      [SECTION_FIELDS]: [
        {
          [FIELD_NAME]: 'Pubs totals labeled grid fields', // arbitrary--simply for the React render loop
          [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: createLabelToApiInventoryTypeMapPubs,
          [INCLUDE_TOTALS]: true,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE]: true,
          [HEADING_IF_LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE]: 'All Pubs',
        },
      ],
    },
    {
      [SECTION_KEY]: 'Shipping totals BRW', // arbitrary--simply for the React render loop
      [SECTION_TITLE]: MonthlyShippingTotalsHeaderTextBrewer,
      [ONLY_RENDER_SECTION_IF]: ({
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        isTruthyAndNonEmpty(inventoryReportObjs[INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ])
        && !isThisForDisplayingAsARowInTheHistoryTable
      ),
      [SECTION_FIELDS]: [
        {
          [FIELD_NAME]: 'Shipping totals BRW labeled grid fields', // arbitrary--simply for the React render loop
          [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: createLabelToApiInventoryTypeMapBrwTotalsShippedAndReceived,
          [INCLUDE_TOTALS]: false,
        },
      ],
    },
  ],
}


/**
* *****************************************************************************
 * Config object of all CONBRW types
 * *****************************************************************************
*/

const reportInventoryConfigConbrw = {
  [HEADINGS_AND_BRANDING_LANGUAGE]: normalInventoryReportHeadingsAndBrandingLanguage,
  [SECTION_DEFINITIONS]: [
    normalTopSectionObject,
    {
      [SECTION_KEY]: 'CBMST totals', // arbitrary--simply for the React render loop
      [SECTION_TITLE]: ({
        businessUnitName,
      }) => `Total Number of ${businessUnitName}-Delivered Kegs in Inventory, Excluding Full Kegs`,
      [ARBITRARY_CONTENT_AFTER_SECTION_TITLE_AND_BEFORE_SECTION_FORM_FIELDS]: WhatsAForeignKeg,
      [SECTION_FIELDS]: [
        {
          [FIELD_NAME]: 'CBMST totals labeled grid fields', // arbitrary--simply for the React render loop
          [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: createLabelToApiInventoryMapConbrwEmptysDefectivesEtc,
          // It doesn't make sense to have Pallets in the
          // label-to-API-inventory-map, because there's no such thing as an
          // empty pallet, full pallet, etc. Pallets need to be in the Report
          // Inventory form, but they should be individual fields with a total
          // count, like total foreign kegs. Therefore, we filter them out of
          // the label-to-API-inventory-map here.
          [CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]: ({ entireItemSkusSlice, itemSkuIds }) => (
            itemSkuIds.filter(id => entireItemSkusSlice[id].skuType !== ITEM_SKUS_SKU_TYPE_PALLET)
          ),
          [INCLUDE_TOTALS]: true,
        },
        foreignKegsFieldConfigObjPart,
        emptyCppFieldConfigObjPart,
        emptyCcpFieldConfigObjPart,
      ],
    },
    {
      [SECTION_KEY]: 'Contractees Fulls', // arbitrary--simply for the React render loop
      [SECTION_TITLE]: () => "Full Kegs On-Hand of Breweries' Product Filled by You",
      [ONLY_RENDER_SECTION_IF]: ({
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        isTruthyAndNonEmpty(inventoryReportObjs[INVENTORY_REPORT_OBJS_CONTRACTEES])
        && !isThisForDisplayingAsARowInTheHistoryTable
      ),
      [SECTION_FIELDS]: [
        {
          [FIELD_NAME]: 'Contractees Fulls labeled grid fields', // arbitrary--simply for the React render loop
          [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: createLabelToApiInventoryMapContracteesOfConbrw,
          [INCLUDE_TOTALS]: true,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE]: true,
          [HEADING_IF_LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE]: 'All Conbrw',
        },
      ],
    },
    {
      [SECTION_KEY]: 'Received and Filled Totals CONBRW', // arbitrary--simply for the React render loop
      [SECTION_TITLE]: 'Monthly Totals Received and Filled',
      [SECTION_FIELDS]: [
        {
          [FIELD_NAME]: 'Received and Filled Totals CONBRW labeled grid fields', // arbitrary--simply for the React render loop
          [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: createLabelToApiInventoryMapConbrwTotalsFilledAndReceived,
          [INCLUDE_TOTALS]: false,
        },
      ],
      [ONLY_RENDER_SECTION_IF]: ({
        isThisForDisplayingAsARowInTheHistoryTable,
      }) => (
        !isThisForDisplayingAsARowInTheHistoryTable
      ),
    },
  ],
}


/**
* *****************************************************************************
 * Config object of DIST type
 * *****************************************************************************
*/

const reportInventoryConfigDist = {
  [HEADINGS_AND_BRANDING_LANGUAGE]: emptyKegReportHeadingsAndBrandingLanguage,
  [ARBITRARY_CONTENT_BEFORE_FORM]: DifferenceBetweenZeroAndBlankKegQtyFieldMessage,
  [SECTION_DEFINITIONS]: [
    topSectionObjectWithoutReportingForMonthOrCountedByFields,
    {
      [SECTION_KEY]: 'DIST Empties', // arbitrary--simply for the React render loop
      [SECTION_TITLE]: ({
        businessUnitName,
      }) => `Total Number of Empty ${businessUnitName} Kegs in Inventory`,
      [SECTION_FIELDS]: [
        {
          [FIELD_NAME]: 'DIST Empties labeled grid fields', // arbitrary--simply for the React render loop
          [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
          [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: createLabelToApiInventoryMapDistEmptys,
          [CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]: ({ entireItemSkusSlice, itemSkuIds }) => (
              itemSkuIds.filter(id => entireItemSkusSlice[id].branding !== BUSINESS_UNIT_ID_TYPE_BREWDOG)),
          [INCLUDE_TOTALS]: true,
        },
      ],
    },
  ],
}


/**
* *****************************************************************************
 * Config object of DIST type that doesn't require login: CODE_COMMENTS_267
 * *****************************************************************************
*/

const reportInventoryConfigDistNoLoginRequired = {
  [HEADINGS_AND_BRANDING_LANGUAGE]: emptyKegReportHeadingsAndBrandingLanguage,
  [ARBITRARY_CONTENT_BEFORE_FORM]: DifferenceBetweenZeroAndBlankKegQtyFieldMessage,
  [SECTION_DEFINITIONS]: ({
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  }) => (
    inventoryReportObjs[INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS].map(
      (
        {
          [INFO_JUST_FOR_THE_WEB_APP]: inforJustForTheWebApp,
          [INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]: objs,
        },
        index,
      ) => ({
        [SECTION_KEY]: inforJustForTheWebApp[INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME], // arbitrary--simply for the React render loop
        [SECTION_TITLE]: inforJustForTheWebApp[INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME],
        [SECTION_FIELDS]: [
          {
            [FIELD_NAME]: 'DIST NO LOGIN REQUIRED Empties labeled grid fields', // arbitrary--simply for the React render loop
            [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
            [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: creatorOfCreateLabelToApiInventoryMapDistNoLoginRequired({
              [INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]: objs,
            }),
            [CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]: getWhichItemSkuIdsToIncludeInDistNoLoginLabelToApiInventoryTypeMap(index),
            [CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]: props => {
              const {
                configObjReturnedFromDistInvNoLoginConfigCall,
              } = props
              const itemSkuIdsOfThisSection = getWhichItemSkuIdsToIncludeInDistNoLoginLabelToApiInventoryTypeMap(index)(props)
              const itemSkuIdsToShowPerRow = flow_(
                // Get the customers of this section
                configObjReturnedFromDistInvNoLoginConfigCall_ => configObjReturnedFromDistInvNoLoginConfigCall_.customers[index],
                // Get all business units
                mapFp_(customer => (
                  customer.businessUnitId
                  // If somehow the customer's businessUnitId prop is null, we don't want
                  // the app to crash. Instead, assume MKM.
                  || BUSINESS_UNIT_ID_TYPE_MICROSTAR
                )),
                // get the array of container types that should be rendered for each
                // business unit
                mapFp_(businessUnitId => getDistNoLoginInventoryItemSkuIdsToShow(businessUnitId)),
              )(configObjReturnedFromDistInvNoLoginConfigCall)
              // return a grid-array of booleans representing which fields
              // should get rendered, e.g.
              // [
              //   [true, true, true],
              //   [true, false, true],
              //   [true, true, true],
              // ]
              const toReturn = itemSkuIdsToShowPerRow.map(itemSkuIdsForThisRow => (
                itemSkuIdsOfThisSection.map(itemSkuIdOfColumn => (
                  itemSkuIdsForThisRow.includes(itemSkuIdOfColumn)
                ))
              ))
              return toReturn
            },
            [INCLUDE_TOTALS]: true,
          },
        ],
      }),
    )
  ),
}
const reportConstellationConfigDistNoLoginRequired = {
  [HEADINGS_AND_BRANDING_LANGUAGE]: {
    [TITLE_OF_REPORT_FORM]: `Report Constellation Case Pallet Inventory As Of ${moment().endOf('month').format('MMMM Do YYYY')}`,
    [TITLE_OF_EDIT_FORM]: 'Edit Constellation Pallet Reconciliation',
    [TITLE_OF_FULL_DETAILS_VIEW]: 'Report Constellation Case Pallet Inventory Details',
    [CONTAINER_CLASS_OF_FULL_DETAILS_VIEW]: 'test-inventory-report-full-details',
  },
  [ARBITRARY_CONTENT_BEFORE_FORM]: DifferenceBetweenZeroAndBlankKegQtyFieldMessage,
  [SECTION_DEFINITIONS]: ({
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  }) => (
    inventoryReportObjs[CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS].map(
      (
        {
          [INFO_JUST_FOR_THE_WEB_APP]: inforJustForTheWebApp,
          [CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]: objs,
        },
        index,
      ) => ({
        [SECTION_KEY]: inforJustForTheWebApp[INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME], // arbitrary--simply for the React render loop
        [SECTION_TITLE]: inforJustForTheWebApp[INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME],
        [SECTION_FIELDS]: [
          {
            [FIELD_NAME]: 'DIST NO LOGIN REQUIRED Empties labeled grid fields', // arbitrary--simply for the React render loop
            [FORM_FIELD_KIND]: FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
            [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: creatorOfCreateLabelToApiConstellationMapDistNoLoginRequired({
              [CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]: objs,
            }),
            [CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]: getWhichItemSkuIdsToIncludeInDistNoLoginLabelToApiConstellationTypeMap(index),
            [CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]: props => {
              const {
                configObjReturnedFromDistInvNoLoginConfigCall,
              } = props
              // const itemSkuIdsOfThisSection = getWhichItemSkuIdsToIncludeInDistNoLoginLabelToApiConstellationTypeMap(index)(props)
              const itemSkuIdsToShowPerRow = flow_(
                // Get the customers of this section
                configObjReturnedFromDistInvNoLoginConfigCall_ => configObjReturnedFromDistInvNoLoginConfigCall_.customers?.[0],
                // Get all business units
                mapFp_(customer => (
                  customer.businessUnitId
                  // If somehow the customer's businessUnitId prop is null, we don't want
                  // the app to crash. Instead, assume MKM.
                  || BUSINESS_UNIT_ID_TYPE_MICROSTAR
                )),
                // get the array of container types that should be rendered for each
                // business unit
                mapFp_(businessUnitId => getDistNoLoginConstellationItemSkuIdsToShow(businessUnitId)),
              )(configObjReturnedFromDistInvNoLoginConfigCall)
              // return a grid-array of booleans representing which fields
              // should get rendered, e.g.
              // [
              //   [true, true, true],
              //   [true, false, true],
              //   [true, true, true],
              // ]
              // const toReturn = itemSkuIdsToShowPerRow.map(itemSkuIdsForThisRow => (
              //   itemSkuIdsOfThisSection.map(itemSkuIdOfColumn => (
              //     itemSkuIdsForThisRow.includes(itemSkuIdOfColumn)
              //   ))
              // ))

              // apologize for this kind of code,i've no other option
              const toReturn = itemSkuIdsToShowPerRow.map((itemSkuIdsForThisRow, idx) => {
                if (itemSkuIdsForThisRow.indexOf(ITEM_SKU_IDS_GOOD_PALLETS) === 1 && idx % 2 !== 0) {
                  return [false, true]
                }
                return [true, true]
              })
              if (itemSkuIdsToShowPerRow.length === 1) {
                // SHI* fix
                toReturn.push([false, true])
                return toReturn
              }
              return toReturn
            },
            [INCLUDE_TOTALS]: true,
          },
        ],
      }),
    )
  ),
}

function getWhichItemSkuIdsToIncludeInDistNoLoginLabelToApiConstellationTypeMap(
  // one section representing one physical location, i.e. one
  // label-to-api-inventory-type-map
  formSectionIndex,
) {
  return ({
    configObjReturnedFromDistInvNoLoginConfigCall,
    entireItemSkusSlice,
  }) => flow_(
    // Get the customers of this section
    configObjReturnedFromDistInvNoLoginConfigCall_ => [configObjReturnedFromDistInvNoLoginConfigCall_.customers?.[0]?.filter(e => e.businessUnitId === BUSINESS_UNIT_ID_TYPE_MICROSTAR)?.[formSectionIndex]],
    // Get all business units
    mapFp_(customer => (
      customer.businessUnitId
        // If somehow the customer's businessUnitId prop is null, we don't want
        // the app to crash. Instead, assume MKM.
        || BUSINESS_UNIT_ID_TYPE_MICROSTAR
    )),
    filterFp_(businessUnitId => businessUnitId === BUSINESS_UNIT_ID_TYPE_MICROSTAR),
    // get the array of container types that should be rendered for each
    // business unit
    mapFp_(businessUnitId => getDistNoLoginConstellationItemSkuIdsToShow(businessUnitId)),
    flattenFp_,
    uniqFp_,
    arr => sortArrayByTemplateArray(arr, getItemSkusDefaultSortOrder({ entireItemSkusSlice })),
  )(configObjReturnedFromDistInvNoLoginConfigCall)
}

function getWhichItemSkuIdsToIncludeInDistNoLoginLabelToApiInventoryTypeMap(
  // one section representing one physical location, i.e. one
  // label-to-api-inventory-type-map
  formSectionIndex,
) {
  return ({
    configObjReturnedFromDistInvNoLoginConfigCall,
    entireItemSkusSlice,
  }) => (
    flow_(
      // Get the customers of this section
      configObjReturnedFromDistInvNoLoginConfigCall_ => configObjReturnedFromDistInvNoLoginConfigCall_.customers[formSectionIndex],
      // Get all business units
      mapFp_(customer => (
        customer.businessUnitId
        // If somehow the customer's businessUnitId prop is null, we don't want
        // the app to crash. Instead, assume MKM.
        || BUSINESS_UNIT_ID_TYPE_MICROSTAR
      )),
      // get the array of container types that should be rendered for each
      // business unit
      mapFp_(businessUnitId => getDistNoLoginInventoryItemSkuIdsToShow(businessUnitId)),
      flattenFp_,
      uniqFp_,
      arr => sortArrayByTemplateArray(arr, getItemSkusDefaultSortOrder({ entireItemSkusSlice })),
    )(configObjReturnedFromDistInvNoLoginConfigCall)
  )
}


// CODE_COMMENTS_267
function getDistNoLoginInventoryItemSkuIdsToShow(busUnitId) {
  const map = {
    [BUSINESS_UNIT_ID_TYPE_MICROSTAR]: [
      ITEM_SKU_IDS_MKM_HB_DEFAULT,
      ITEM_SKU_IDS_MKM_SB_DEFAULT,
      ITEM_SKU_IDS_MKM_QB_DEFAULT,
      ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
      ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET,
    ],
    [BUSINESS_UNIT_ID_TYPE_KEGCRAFT]: [
      // Wait, shouldn't this be ITEM_SKU_IDS_KC_HB_DEFAULT and
      // ITEM_SKU_IDS_KC_SB_DEFAULT? No, see CODE_COMMENTS_280
      ITEM_SKU_IDS_MKM_HB_DEFAULT,
      ITEM_SKU_IDS_MKM_SB_DEFAULT,
    ],
    [BUSINESS_UNIT_ID_TYPE_KEGSTAR]: [
      // Wait, shouldn't this be ITEM_SKU_IDS_KSK_HB_DEFAULT and
      // ITEM_SKU_IDS_KSK_SB_DEFAULT? No, see CODE_COMMENTS_280
      ITEM_SKU_IDS_MKM_HB_DEFAULT,
      ITEM_SKU_IDS_MKM_SB_DEFAULT,
    ],
    // On April 4, 2022, Kevin asked Brett and Derek in Logistics whether we
    // need item SKU IDs specifically for CBI or whether we can get away with
    // just using MKM IDs; they decided MKM IDs are fine.
    [BUSINESS_UNIT_ID_TYPE_CONSTELLATION]: [
      ITEM_SKU_IDS_MKM_HB_DEFAULT,
      ITEM_SKU_IDS_MKM_QB_DEFAULT,
    ],
    [BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT]: [
      ITEM_SKU_IDS_KS_L20,
      ITEM_SKU_IDS_KS_L30,
      ITEM_SKU_IDS_KS_L50,
      ITEM_SKU_IDS_KS_G9,
    ],
    [BUSINESS_UNIT_ID_TYPE_BELLS_BRAND]: [
      ITEM_SKU_IDS_BEL_HB_DEFAULT,
      ITEM_SKU_IDS_BEL_QB_DEFAULT,
    ],
  }
  const toReturn = map[busUnitId]
  return (
    toReturn
    // In case the busUnitId isn't represented in map (which should never
    // happen), don't crash
    || map[BUSINESS_UNIT_ID_TYPE_MICROSTAR]
  )
}
function getDistNoLoginConstellationItemSkuIdsToShow() {
  return [
    ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET,
    ITEM_SKU_IDS_GOOD_PALLETS,
  ]
}
/**
* *****************************************************************************
 * The function that gives you access to the config objects
 * *****************************************************************************
*/

const customerTypeToConfigObjMap = {
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS]: reportInventoryConfigBrw,
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS]: reportInventoryConfigBrw,
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS]: reportInventoryConfigBrw,
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY]: reportInventoryConfigBrw,
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS]: reportInventoryConfigConbrw,
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS]: reportInventoryConfigConbrw,
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR]: reportInventoryConfigDist,
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE]: reportInventoryConfigDist, // CODE_COMMENTS 287
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_MAINTENANCE_FACILITY]: reportInventoryConfigDist,
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED]: reportInventoryConfigDistNoLoginRequired,
  [CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED]: reportConstellationConfigDistNoLoginRequired,
}
export function getConfigObjectBasedOnCustomerTypeForReportInventory({
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
}) {
  return customerTypeToConfigObjMap[customerTypeForReportInventory]
}
