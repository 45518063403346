import pollToGetOutOfMaintenanceModeOnceWereInMaintenanceModeSagas from './pollToGetOutOfMaintenanceModeOnceWereInMaintenanceMode'
import fetchMaintenanceScheduleThreadSagas from './fetchMaintenanceScheduleThread'
import setMaintenanceModeNotificationFlagThreadSagas from './setMaintenanceModeNotificationFlagThread'
import putAppInMaintenanceModeBasedOnMaintenanceScheduleSagas from './putAppInMaintenanceModeBasedOnMaintenanceSchedule'


// CODE_COMMENTS_11
export default [
  ...pollToGetOutOfMaintenanceModeOnceWereInMaintenanceModeSagas,
  ...fetchMaintenanceScheduleThreadSagas,
  ...setMaintenanceModeNotificationFlagThreadSagas,
  ...putAppInMaintenanceModeBasedOnMaintenanceScheduleSagas,
]
