/**
 * CODE_COMMENTS_31
 */
export const SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE = 'shipment-type'
export const SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_SEARCH = 'shipment-search'

export const INBOUND_SHIMPENT_STATUS_CLASSNAME = 'inboundStatus'
export const OUTBOUND_SHIPMENT_STATUS_CLASSNAME = 'outboundStatus'

export const INBOUND_STATUS_UPDATE_MESSAGE = 'Completed - Shipment has been completed, Billing in process\n' +
'Posted - Shipment has been finalized'

export const OUTBOUND_STATUS_UPDATE_MESSAGE = 'Pending - Shipment can be revised \n' +
    'Processing - No revisions can be made, billing in progress \n' +
    'Posted - Shipment has been finalized'
