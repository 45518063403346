import React from 'react'

import { Card, Button } from 'semantic-ui-react'


const FetchFailedCardHeader = ({
  customerName,
  operatingContractBrewerCustomerId,
}) => {
  let displayedCustomerName
  if (customerName) {
    displayedCustomerName = customerName
  } else {
    displayedCustomerName = operatingContractBrewerCustomerId
      ? 'Brewer'
      : 'Child Account'
  }

  return (
    <Card.Header className="semantic-ui-error-text-color">
      {`Failed to Load ${displayedCustomerName}`}
    </Card.Header>
  )
}

const FetchFailedCardDescription = ({
  customerName,
  operatingContractBrewerCustomerId,
}) => {
  let displayedCustomerName

  if (customerName) {
    displayedCustomerName = customerName
  } else {
    displayedCustomerName = operatingContractBrewerCustomerId
      ? 'this brewer'
      : 'this child account'
  }

  const text = `There was an error loading the dashboard for ${displayedCustomerName}. Please reach out to your personalized rep for updates. In the meantime, you can try to reload this customer.`

  return (
    <Card.Description>
      {text}
    </Card.Description>
  )
}


const FetchFailedCardMainContent = ({
  customerName,
  operatingContractBrewerCustomerId,
}) => (
  <Card.Content>
    <FetchFailedCardHeader
      customerName={customerName}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
    />
    <FetchFailedCardDescription
      customerName={customerName}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
    />
  </Card.Content>
)


export default ({
  customerName,
  operatingContractBrewerCustomerId,
  retryFetchCustomerPrelimInfo,
}) => (
  <Card centered color="red" className="centered-content">
    <FetchFailedCardMainContent
      customerName={customerName}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
    />
    <Card.Content extra>
      <Button onClick={retryFetchCustomerPrelimInfo}>
        <Button.Content visible>Reload</Button.Content>
      </Button>
    </Card.Content>
  </Card>
)


// helper functions
