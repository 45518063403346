import {
  getIsPermissionsObjectValid,
  getIsPerCustomerPermissionsObjectValid,
} from './util'

import {
  isStringOrNumberValidator,
} from '../simpleValidators'

import {
  createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers,
} from '../util'

import {
  logObjectHasProblemsErrorMessage,
  CAN_STILL_BE_DISPLAYED,
  CANT_BE_DISPLAYED,
} from '../../../../../utils/thirdPartyLogging'

import {
  isTruthyAndNonEmpty,
} from '../../../../../utils'


// `props` should be an object with at least one property: `data`
export function validateCustomerUserPermissionsObject(props) {
  const individualPropsValidatorsAndTransformers = [
    {
      propPath: 'permissions',
      validatorFn: getIsPermissionsObjectValid,
      doNotSaveApiResponseDataToStoreIfValidationFails: true,
    },
    {
      propPath: 'perCustomerPermissions',
      validatorFn: getIsPerCustomerPermissionsObjectValid,
      doNotSaveApiResponseDataToStoreIfValidationFails: true,
    },
    {
      propPath: 'id',
      validatorFn: isStringOrNumberValidator,
      doNotSaveApiResponseDataToStoreIfValidationFails: true,
    },
  ]

  const results = createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
    ...props,
    individualPropsValidatorsAndTransformers,
  })

  const {
    arrayOfProblemsWithData,
    canDataBeSavedToStore,
  } = results

  const {
    httpResponse,
  } = props

  if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
    if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
      logObjectHasProblemsErrorMessage({
        objectType: 'customerUserPermissionsObject',
        level: canDataBeSavedToStore ? CAN_STILL_BE_DISPLAYED : CANT_BE_DISPLAYED,
        additionalInfo: {
          arrayOfProblemsWithData,
        },
        httpResponse,
      })
    }
  }

  return results
}
