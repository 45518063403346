import historyReducerCreator from './historyReducerCreator'

import {
  SAVE_COLLAR_ORDER_HISTORY,
  SAVE_SINGLE_COLLAR_ORDER_HISTORY_ITEM,
  DELETE_COLLAR_ORDER_HISTORY_ITEM,
} from '../../actions/actionTypes'


export default historyReducerCreator({
  saveHistoryOfSingleCustomerActionType: SAVE_COLLAR_ORDER_HISTORY,
  saveSingleHistoryItemActionType: SAVE_SINGLE_COLLAR_ORDER_HISTORY_ITEM,
  deleteSingleHistoryItemActionType: DELETE_COLLAR_ORDER_HISTORY_ITEM,
})
