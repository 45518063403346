import { connect } from 'react-redux'

import memoize_ from 'lodash/memoize'


import ChildrenAndContracteeBrewersOverviewSections from '../../common-components/ChildrenAndContracteeBrewersOverviewSections'
import PopulatedChildCard from './containers/PopulatedChildCard'

import {
  getProp as getCurrentUserProp,
} from '../../redux/selectors/currentUser'
import {
  getIdsOfNonInactiveAndNonSelfDistAndPubChildren,
} from '../../redux/selectors/customers'


const uiContainerHocProps = {
  headerText: 'Accounts',
  containerId: 'childrenOverviewSection',
}

const getCustomerIds = memoize_(
  ({ state, currentUserRootCustomerId }) => (
    getIdsOfNonInactiveAndNonSelfDistAndPubChildren(state, currentUserRootCustomerId)
  ),
  ({ currentUserRootCustomerId }) => currentUserRootCustomerId,
)

const mapStateToProps = state => {
  const currentUserRootCustomerId = getCurrentUserProp(state, 'rootCustomerId')
  const customerIds = getCustomerIds({
    state,
    currentUserRootCustomerId,
  })
  return {
    uiContainerHocProps,
    customerIds,
    populatedCard: PopulatedChildCard,
  }
}

export default connect(
  mapStateToProps,
)(ChildrenAndContracteeBrewersOverviewSections)
