// For when an employee logs in and operates for a customer user

/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file, see CODE COMMENTS 50
import { select, call, put, takeEvery } from 'redux-saga/effects'


import {
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_REQUEST,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_SUCCESS,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_FAILURE,
  SAVE_CURRENT_USER,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'
import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'

import { fetchIndividualCustomerUser } from './individualCustomerUserForEmployeeToOperateAs'
import { fetchCustomerUserPermissions } from './customerUserPermissions'

import customerUserPermissionsFetchStatusSelectors from '../../../selectors/fetchStatuses/customers/customerUserPermissions'
import {
  getPermissions,
  getPerCustomerPermissions,
} from '../../../selectors/permissions'

export function* fetchCustomerUsersAndTheirPermissions(action) {
  const { email } = action.payload
  yield put(createAction(FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_REQUEST))

  // Fetch the user object
  const response = yield call(fetchIndividualCustomerUser, { payload: { email } })

  if (!response.success) {
    yield call(doFailure, response.error)
    return
  }
  const { userObject } = response

  // Fetch the user's permissions
  yield call(fetchCustomerUserPermissions, {
    payload: {
      userId: userObject.id,
      setOperateAsCustomerUserHeaderIfEmployeeIsLoggedIn: false, // CODE_COMMENTS_221
    },
  })
  const { getFetchStatuses, getFetchFailureErrorDetails } = customerUserPermissionsFetchStatusSelectors
  const { didFetchFail } = yield select(getFetchStatuses, userObject.id)
  if (didFetchFail) {
    const errorDetails = yield select(getFetchFailureErrorDetails, userObject.id)
    const { errorObject } = errorDetails
    yield call(doFailure, errorObject)
    return
  }
  const state = yield select()
  const permissions = yield call(
    getPermissions,
    { state, userIdIfNotCurrentUser: userObject.id },
  )
  const perCustomerPermissions = yield call(
    getPerCustomerPermissions,
    { state, userIdIfNotCurrentUser: userObject.id },
  )

  yield call(
    doSuccess,
    {
      userObject,
      permissions,
      perCustomerPermissions,
    },
  )
}

// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS,
    fetchCustomerUsersAndTheirPermissions,
  ],
]


function* doSuccess({
  userObject,
  permissions,
  perCustomerPermissions,
}) {
  const customerCurrentUserToSave = {
    ...userObject,
    permissions,
    perCustomerPermissions,
    // When a user logs in, the userObject that gets sent back is actually a
    // special kind of userObject; for example, it has `permissions` and
    // `perCustomerPermissions` props. But it doesn't have an `id` prop, if
    // you can believe it. Instead, it has a `userId` prop. We need to mimic
    // that here.
    userId: userObject.id,
  }
  yield put(createAction(SAVE_CURRENT_USER, customerCurrentUserToSave))
  // CODE_COMMENTS_20
  yield put(createAction(
    FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_SUCCESS,
  ))
}

function* doFailure(error) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_FAILURE,
        target: null,
      }),
      onlyLogErrorIf: ({ errorMessage, url, errorCode }) => {
        // If the user is an employee user, not a customer user
        if (errorMessage.includes('is an employee user')) { return false }
        // If the user wasn't found in the database
        if (
          errorCode === 404
          // We need to make sure this is the /users call and not the
          // permissions call, because we should never get a 404 on the
          // permissions call. If we do, we definitely want to log that error
          // (it would mean that a customer user has no permissions entry in the
          // database--that's bad).
          && !url.includes('/permissions')
        ) { return false }
        return true
      },
    },
  )
}
