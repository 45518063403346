import sortBy_ from 'lodash/sortBy'
import values_ from 'lodash/values'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import valuesFp_ from 'lodash/fp/values'
import filterFp_ from 'lodash/fp/filter'


import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_ADDRESSES as defaultSlice,

  ADDRESS_TYPES_DEFAULT_SHIPPING,
  ADDRESS_TYPES_BILLING,
  ADDRESS_TYPES_SHIPPING,
} from '../../../constants'

import {
  withPropNormalized,
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/


// usage: const addressCity = getProp(state, addressId, 'city')
export const getProp = withPropNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: you can use this to get the slice for all addresses or for a single
// address:
// const entireSliceForAllAddresses = getEntireSlice(state)
// const entireSliceForSingleAddress = getEntireSlice(state, addressId)
export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)


/*
 * *****************************************************************************
 * More specific selectors
 * *****************************************************************************
*/

// Returns an array of mashup objects of addressObject and
// customerAddressLinkObject from the backend; i.e. they're addressObjects with
// their customerAddressLinkObject props added ('dshp', 'shp', 'bil', 'active',
// 'comments', and 'deliveryNotes')
export function getAllAddressesOfCustomer({
  entireAddressesSlice,
  entireCustomerAddressLinksSlice,
  customerId,
  currentlyActiveAddressesOnly, // CODE_COMMENTS_112
}) {
  const allMashupObjsOfCustomer = flow_(
    valuesFp_, // turn the object of objects into an array of objects
    filterFp_(customerAddressLinkObj => customerAddressLinkObj.customerId === customerId),
    mapFp_(customerAddressLinkObj => createMashupObjFromCustomerAddressLinkObj({
      entireAddressesSlice,
      customerAddressLinkObj,
    })),
  )(entireCustomerAddressLinksSlice)

  return currentlyActiveAddressesOnly
    ? allMashupObjsOfCustomer.filter(o => o.active)
    : allMashupObjsOfCustomer
}


// Returns the entire address/link mashup object or undefined if no address of
// the desired type is found. If a customer has multiple addresses of the
// passed-in type, a random address object of that type is returned.
export function getAddressOfCustomer({
  entireAddressesSlice,
  entireCustomerAddressLinksSlice,
  customerId,
  currentlyActiveAddressesOnly, // CODE_COMMENTS_112
  addressType,
}) {
  const mashupObjs = getAllAddressesOfCustomer({
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    customerId,
    currentlyActiveAddressesOnly,
  })
  return mashupObjs.find(address => address[addressType])
}


// Returns the address/link mashup object. Returns undefined if the customer
// does not have a dshp address
export function getDefaultShippingAddressOfCustomer({
  entireAddressesSlice,
  entireCustomerAddressLinksSlice,
  customerId,
}) {
  return getAddressOfCustomer({
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    customerId,
    currentlyActiveAddressesOnly: true, // CODE_COMMENTS_112, CODE_COMMENTS_116
    addressType: ADDRESS_TYPES_DEFAULT_SHIPPING,
  })
}


// Returns the address/link mashup object. Returns undefined if the customer
// does not have a bil address
export function getBillingAddressOfCustomer({
  entireAddressesSlice,
  entireCustomerAddressLinksSlice,
  customerId,
}) {
  return getAddressOfCustomer({
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    customerId,
    currentlyActiveAddressesOnly: true, // CODE_COMMENTS_112, CODE_COMMENTS_116
    addressType: ADDRESS_TYPES_BILLING,
  })
}


// Returns an array of multiple address/link mashup objects of a single
// customer. Don't pass in both the include and exclude args, only one. If both
// the include and exclude list args are omitted, returns all the customer's
// addresses. Technically this can retrieve just one address if include contains
// only one address type, in which case a 1-item array will be returned. Returns
// an empty array if no addresses of the desired types are found.
export function getAddressesOfCustomer({
  entireAddressesSlice,
  entireCustomerAddressLinksSlice,
  customerId,
  currentlyActiveAddressesOnly, // CODE_COMMENTS_112
  include,
  exclude,
}) {
  const addresses = getAllAddressesOfCustomer({
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    customerId,
    currentlyActiveAddressesOnly,
  })
  if (!include && !exclude) { return addresses }
  if (include) {
    return addresses.filter(address => include.some(addrType => address[addrType]))
  }
  return addresses.filter(address => !exclude.some(addrType => address[addrType]))
}


// Returns an array. This is for things like the Order Kegs form and Order Keg
// Collars form. Returns an empty array if the customer passed in has no
// shipping addresses on file.
export function getSortedShippingAddressesOfCustomer({
  entireAddressesSlice,
  entireCustomerAddressLinksSlice,
  customerId,
}) {
  const addresses = getAddressesOfCustomer({
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    customerId,
    currentlyActiveAddressesOnly: true,
    include: [ADDRESS_TYPES_DEFAULT_SHIPPING, ADDRESS_TYPES_SHIPPING],
  })

  const dshpAddress = addresses.find(o => o[ADDRESS_TYPES_DEFAULT_SHIPPING])
  const nonDshpShippingAddresses = addresses.filter(o => !o[ADDRESS_TYPES_DEFAULT_SHIPPING])

  const nonDshpShippingAddressesSortedByAddress1 = sortBy_(
    nonDshpShippingAddresses,
    ['Addreess1'],
  )

  return [
    ...(dshpAddress ? [dshpAddress] : []),
    ...nonDshpShippingAddressesSortedByAddress1,
  ]
}


// Returns an array of all ADDRESS_TYPES_SHIPPING, no
// ADDRESS_TYPES_DEFAULT_SHIPPING
export function getShippingAddressesOfCustomerExcludingDefaultShippingAddress({
  entireAddressesSlice,
  entireCustomerAddressLinksSlice,
  customerId,
  currentlyActiveAddressesOnly, // CODE_COMMENTS_112
}) {
  const addresses = getAddressesOfCustomer({
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    customerId,
    currentlyActiveAddressesOnly,
    include: [ADDRESS_TYPES_SHIPPING],
  })
  const nonDshpShippingAddresses = addresses.filter(o => !o[ADDRESS_TYPES_DEFAULT_SHIPPING])
  return sortBy_(
    nonDshpShippingAddresses,
    ['Addreess1'],
  )
}


// Returns an empty array if no such link exists for this address
export function getCustomerIdsAddressBelongsTo({
  entireCustomerAddressLinksSlice,
  addressId,
}) {
  const targetLinkObjects = values_(entireCustomerAddressLinksSlice).filter(o => o.addressId === addressId)
  return targetLinkObjects.map(o => o.customerId)
}


/*
 * *****************************************************************************
 * Functions that don't return mashup objects of address+link, just
 * addressObjects (i.e. the object contains 'Address1', 'Address2', etc but not
 * 'dshp', 'shp', 'bil', 'active', or 'deliveryNotes')
 * *****************************************************************************
*/

// Returns the address/link mashup object. Returns undefined if no address with
// that ID is found.
export function getAddressByIdNoLinkInfoOnlyAddressInfo({
  entireAddressesSlice,
  addressId,
}) {
  return entireAddressesSlice[addressId]
}

export function getMultipleAddressObjectsByIdNoLinkInfoOnlyAddressInfo({
  entireAddressesSlice,
  addressIds, // array
}) {
  return values_(entireAddressesSlice).filter(o => addressIds.includes(o.id))
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

function createMashupObjFromCustomerAddressLinkObj({
  entireAddressesSlice,
  customerAddressLinkObj,
}) {
  const addressObj = entireAddressesSlice[customerAddressLinkObj.addressId]
  return {
    // order is important because both objects contain an 'id' prop, and we want
    // 'id' to map to the address's ID
    ...customerAddressLinkObj,
    ...addressObj,
    customerAddressLinkId: customerAddressLinkObj.id,
    // what about addressId? That's a prop of the linkObj
  }
}
