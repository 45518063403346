import React from 'react'

import LoadingPage from '../../../common-components/LoadingPage'


export default ({
  tasksToPerformImmediatelyAfterLogin,
  haveTasksToPerformImmediatelyAfterLoginBeenAttempted,
  haveTasksToPerformImmediatelyAfterLoginSucceeded,
  children,
// eslint-disable-next-line consistent-return
}) => {
  if (!haveTasksToPerformImmediatelyAfterLoginBeenAttempted) {
    // we should only get here once, when the page first loads
    tasksToPerformImmediatelyAfterLogin()
    return <LoadingPage />
  }
  if (!haveTasksToPerformImmediatelyAfterLoginSucceeded) {
    return <LoadingPage />
  }

  return (
    <div>
      {children}
    </div>
  )
}
