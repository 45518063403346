import { put, takeEvery } from 'redux-saga/effects'

import {
  delteHistoryItemSagaCreator,
} from '../../deleteHistoryItem'

import createAction from '../../../actions/createAction'

import {
  getIndividualShipmentApiUrl,
} from '../../../../utils'


import {
  FETCH_DELETE_REPORTED_KEG_SHIPMENT,
  DELETE_SINGLE_SHIPMENT_BY_ID_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
} from '../../../actions/actionTypes'


function* doOnSuccess(action) {
  yield put(createAction(
    DELETE_SINGLE_SHIPMENT_BY_ID_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
    action.payload,
  ))
}

const deleteShipmentReport = delteHistoryItemSagaCreator(
  action => action.payload.itemObj.id,
  action => getIndividualShipmentApiUrl(action.payload.itemObj.id),
  doOnSuccess,
)


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_DELETE_REPORTED_KEG_SHIPMENT, deleteShipmentReport],
]
