import { connect } from 'react-redux'

import memoize_ from 'lodash/memoize'

import ChildrenAndContracteeBrewersOverviewSections from '../../common-components/ChildrenAndContracteeBrewersOverviewSections'
import NoContracteesMessage from './components/NoContracteesMessage'
import PopulatedContracteeBrewerCard from './containers/PopulatedContracteeBrewerCard'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getSortedListOfContracteeCustomerIdsThatShouldBeInTheBrewingForSectionOfTheConbrwDashboard,
} from '../../redux/selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'


import {
  isTruthyAndNonEmpty,
} from '../../utils'

const uiContainerHocProps = {
  headerText: 'Brewing For',
  containerId: 'contracteeBrewersOverviewSection',
}

const getCustomerIds = memoize_(
  ({ state, customerId }) => {
    const entireCustomersSlice = getEntireCustomersSlice(state)
    const entireContractsSlice = getEntireContractsSlice(state)
    const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
    return getSortedListOfContracteeCustomerIdsThatShouldBeInTheBrewingForSectionOfTheConbrwDashboard({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      conbrwCustomerId: customerId,
    })
  },
  ({ customerId }) => customerId,
)

const renderAlternateComponentIfFunc = ({ customerIds }) => (
  // if the Contract Brewer has no contractees, render a message saying so
  !isTruthyAndNonEmpty(customerIds)
)

const mapStateToProps = (state, { customerId }) => ({
  uiContainerHocProps,
  customerIds: getCustomerIds({ state, customerId }),
  populatedCard: PopulatedContracteeBrewerCard,
  // The operating contract brewer's customer ID is needed because if the prelim
  // info fetch of the contractee fails, the "reload" button that re-fectches
  // preliminary info needs to pass the operating contract brewer's customer ID
  // into the fetching saga. See CODE_COMMENTS_88
  operatingContractBrewerCustomerId: customerId,
  renderAlternateComponentIfFunc,
  alternateComponentToRender: NoContracteesMessage,
})

export default connect(mapStateToProps)(
  ChildrenAndContracteeBrewersOverviewSections,
)
