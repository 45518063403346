import { select } from 'redux-saga/effects'


import {
  getIsFieldRowFullyFilledOut,
} from '../../../../features/OrderKegCollars/util'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'

import {
  FIELD_ARRAY_NAME_KEG_COLLARS,

  FIELD_NAME_SHIPPING_ADDRESS,
  FIELD_NAME_CUSTOMER_PO_NUM,
  FIELD_NAME_ORDER_COMMENTS,
  FIELD_NAME_SHIPPING_TYPE,
  FIELD_NAME_KEG_COLLAR_CHOICE,
  FIELD_NAME_KEG_COLLAR_AMOUNT,
  FIELD_VALUE_SHIPPING_TYPE_FEDEX_OVERNIGHT,
} from '../../../../constants/formAndApiUrlConfig/orderKegCollars'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  HISTORY_FORM_NAME_COLLAR_ORDERS,
} from '../../../../constants'

import {
  getShouldCollarOrderHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm,
} from '../../../../features/History/individualTabs/util/collarOrderHistory'

import {
  createFormNameForRedux,
  doesHistoryItemDateFallWithinHistoryFormDateRange,
} from '../../../../utils'


// Returns an array of objects in this shape:
//
// [
//   {id: null, collarPlateId : '...', quantity : 12345},
//   {id: null, collarPlateId : '...', quantity : 12345},
// ]
export function parseCollarQuantities(formValues) {
  return formValues[FIELD_ARRAY_NAME_KEG_COLLARS].filter(rowValues => (
    getIsFieldRowFullyFilledOut(rowValues)
  )).map(rowValues => ({
    id: null,
    collarPlateId: rowValues[FIELD_NAME_KEG_COLLAR_CHOICE],
    quantity: rowValues[FIELD_NAME_KEG_COLLAR_AMOUNT],
  }))
}


export function extractFormValues(formValues) {
  const collarPlates = parseCollarQuantities(formValues)
  const shippingAddressId = formValues[FIELD_NAME_SHIPPING_ADDRESS]
  const poNumber = formValues[FIELD_NAME_CUSTOMER_PO_NUM]
  const comments = formValues[FIELD_NAME_ORDER_COMMENTS]
  const overnightShipFlag = formValues[FIELD_NAME_SHIPPING_TYPE] === FIELD_VALUE_SHIPPING_TYPE_FEDEX_OVERNIGHT

  return {
    shippingAddressId,
    poNumber,
    comments,
    collarPlates,
    overnightShipFlag,
  }
}


export function* doesCollarOrderObjectMeetCriteriaOfHistoryForm(
  customerId,
  operatingContractBrewerCustomerId,
  orderObj,
) {
  const historyFormName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_COLLAR_ORDERS,
    customerId,
    operatingContractBrewerCustomerId,
  })
  const mostRecentlySubmittedFormValues = yield select(getMostRecentlySubmittedFormValues, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return false }

  const historyFormStartDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_START_DATE]
  const historyFormEndDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_END_DATE]
  const historyFormOrderStatus = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_STATUS]

  const dateOk = doesHistoryItemDateFallWithinHistoryFormDateRange(
    orderObj.dateOrdered,
    historyFormStartDate,
    historyFormEndDate,
  )
  const statusOk = getShouldCollarOrderHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm(
    historyFormOrderStatus,
    orderObj,
  )

  return (dateOk && statusOk)
}
