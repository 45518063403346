import {
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects'

// import differenceWith_ from 'lodash/differenceWith'
// import isEqual_ from 'lodash/isEqual'

import { privateFetch } from '../../../fetch'
import {
  FETCH_EDIT_PERMISSIONS_OF_ONE_USER,
  SAVE_CUSTOMER_USER_PERMISSIONS,
  UPDATE_PERMISSIONS_OF_CURRENT_USER,
} from '../../../../actions/actionTypes'

// import {
//   getPermissions,
//   getPerCustomerPermissions,
// } from '../../../../selectors/permissions'

import {
  getProp as getCurrentUserProp,
} from '../../../../selectors/currentUser'

// import {
//   getProp as getCustomerProp,
// } from '../../../../selectors/customers'


// import {
//   INTERNAL_ERROR_MESSAGES_FAILED_FORM_SUBMISSION,
// } from '../../../../../constants'

import {
  createApiRequestBody,
} from './util'

import {
  generalDoFailure,
} from '../../../util/fetchFailure'
import createAction from '../../../../actions/createAction'

import {
  getUserPermissionsApiUrl,
} from '../../../../../utils'

// import {
//   NestedPropNotInObjectError,
// } from '../../../../../customErrors'


function* fetchEditPermissionsOfOneUser(action) {
  const {
    userObject,
    setIsEditUserPermissionsSubmitting,
    setDidEditUserPermissionsSubmissionSucceed,
    setDidEditUserPermissionsSubmissionFail,
  } = action.payload
  const apiRequestBody = yield call(
    createApiRequestBody,
    {
      ...action.payload,
    },
  )

  // // The following commented-out code is for testing purposes only: it allows
  // // you to make sure the createApiRequestBody() function is doing the right
  // // thing.
  // console.log("999999999999999999999999999999999999999999999999999999999999999999999999999")
  // console.log("OWN CUSTOMER PERMISSIONS:")
  // yield call(
  //   ensureCreateApiRequestBodyIsProperlyConstructingNewPermissions,
  //   {
  //     apiRequestBody,
  //     userIdIfNotCurrentUser: userObject.id,
  //   },
  // )
  // console.log("-----------------------------------------------------------------------------")
  // console.log("PER CUSTOMER PERMISSIONS:")
  // yield call(
  //   ensureCreateApiRequestBodyIsProperlyConstructingNewPerCustomerPermissions,
  //   {
  //     apiRequestBody,
  //     userIdIfNotCurrentUser: userObject.id,
  //   },
  // )
  // console.log("88888888888888888888888888888888888888888888888888888888888888888888888888888")

  let response
  try {
    response = yield call(
      privateFetch,
      { path: getUserPermissionsApiUrl(userObject.id), data: apiRequestBody, method: 'PUT' },
    )
  } catch (error) {
    yield call(
      doFailure,
      {
        error,
        setIsEditUserPermissionsSubmitting,
        setDidEditUserPermissionsSubmissionSucceed,
        setDidEditUserPermissionsSubmissionFail,
      },
    )
    return
  }
  yield call(
    doSuccess,
    {
      userObject,
      objWithPermissionsAndPerCustomerPermissions: response.data,
      setIsEditUserPermissionsSubmitting,
      setDidEditUserPermissionsSubmissionSucceed,
      setDidEditUserPermissionsSubmissionFail,
    },
  )
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_EDIT_PERMISSIONS_OF_ONE_USER, fetchEditPermissionsOfOneUser],
]


function* doSuccess({
  userObject,
  objWithPermissionsAndPerCustomerPermissions,
  setIsEditUserPermissionsSubmitting,
  setDidEditUserPermissionsSubmissionSucceed,
  setDidEditUserPermissionsSubmissionFail,
}) {
  yield put(createAction(
    SAVE_CUSTOMER_USER_PERMISSIONS,
    { userId: userObject.id, info: objWithPermissionsAndPerCustomerPermissions },
  ))
  const currentUserId = yield select(getCurrentUserProp, 'userId')
  if (currentUserId === userObject.id) {
    yield put(createAction(
      UPDATE_PERMISSIONS_OF_CURRENT_USER,
      objWithPermissionsAndPerCustomerPermissions,
    ))
  }
  setIsEditUserPermissionsSubmitting(false)
  setDidEditUserPermissionsSubmissionSucceed(true)
  setDidEditUserPermissionsSubmissionFail(false)
  if (currentUserId === userObject.id) {
    window.location.reload() // hard refresh the app
  }
}


function* doFailure({
  error,
  setIsEditUserPermissionsSubmitting,
  setDidEditUserPermissionsSubmissionSucceed,
  setDidEditUserPermissionsSubmissionFail,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  setIsEditUserPermissionsSubmitting(false)
  setDidEditUserPermissionsSubmissionSucceed(false)
  setDidEditUserPermissionsSubmissionFail(true)
}


// // The following commented-out code is for testing purposes only: it allows
// // you to make sure the createApiRequestBody() function is doing the right
// // thing.
// function* ensureCreateApiRequestBodyIsProperlyConstructingNewPermissions({
//   apiRequestBody,
//   userIdIfNotCurrentUser,
// }) {
//   const state = yield select()
//   const originalPermissions = getPermissions({ state, userIdIfNotCurrentUser })
//   const newPermissions = apiRequestBody.permissions
//   const inOriginalNotInNew = differenceWith_(originalPermissions, newPermissions, isEqual_)
//   const inNewNotInOriginal = differenceWith_(newPermissions, originalPermissions, isEqual_)
//   console.log("Original")
//   console.log(originalPermissions)
//   console.log("New")
//   console.log(newPermissions)
//   console.log("inOriginalNotInNew:")
//   console.log(inOriginalNotInNew)
//   console.log("inNewNotInOriginal:")
//   console.log(inNewNotInOriginal)
// }
//
//
// // The following commented-out code is for testing purposes only: it allows
// // you to make sure the createApiRequestBody() function is doing the right
// // thing.
// function* ensureCreateApiRequestBodyIsProperlyConstructingNewPerCustomerPermissions({
//   apiRequestBody,
//   userIdIfNotCurrentUser,
// }) {
//   const state = yield select()
//   const originalPermissions = getPerCustomerPermissions({ state, userIdIfNotCurrentUser })
//   const newPermissions = apiRequestBody.perCustomerPermissions
//   console.log("Original")
//   console.log(originalPermissions)
//   console.log("New")
//   console.log(newPermissions)
//
//   console.log("***********************")
//   console.log("All customers that have differences in their perCustomerPermissions:")
//   Object.keys(originalPermissions).forEach(customerId => {
//     const inOriginalNotInNew = differenceWith_(originalPermissions[customerId], newPermissions[customerId], isEqual_)
//     const inNewNotInOriginal = differenceWith_(newPermissions[customerId], originalPermissions[customerId], isEqual_)
//     if (inOriginalNotInNew.length > 0 || inNewNotInOriginal.length > 0) {
//       let customerName
//       try {
//         customerName = getCustomerProp(state, customerId, 'name')
//       } catch (e) {
//         if (e instanceof NestedPropNotInObjectError) {
//           customerName = "this customer not in Redux Store, therefore can't get name"
//         } else { throw e }
//       }
//       console.log(`* ${customerId}: ${customerName}:`)
//       console.log("inOriginalNotInNew:")
//       console.log(inOriginalNotInNew)
//       console.log("inNewNotInOriginal:")
//       console.log(inNewNotInOriginal)
//       console.log("\n")
//     }
//   })
// }
