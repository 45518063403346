import uniq_ from 'lodash/uniq'

import {
  getItemSkusDefaultSortOrder,
} from '../../../../redux/selectors/rewrite/itemSkus'

import {
  sortArrayByTemplateArray,
} from '../../../../utils'


export function determineItemSkuIdsToIncludeAsFieldsInTable({
  entireItemSkusSlice,
  configuredItemSkuIds,
  itemSkuIdsInHistory,
}) {
  // CODE_COMMENTS_44
  const itemSkuIds = uniq_([
    ...configuredItemSkuIds,
    ...itemSkuIdsInHistory,
  ])
  // CODE_COMMENTS_45
  return sortArrayByTemplateArray(
    itemSkuIds,
    getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
  )
}
