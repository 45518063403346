import {
  FETCH_CUSTOMER_USERS_REQUEST,
  FETCH_CUSTOMER_USERS_SUCCESS,
  FETCH_CUSTOMER_USERS_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_CUSTOMER_USERS_REQUEST,
  FETCH_CUSTOMER_USERS_SUCCESS,
  FETCH_CUSTOMER_USERS_FAILURE,
)
