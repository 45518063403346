/**
 * Often when you show the details of a HTTP fetch error (HTTP error code,
 * message body, etc.), you want to allow the user to show and hide the error
 * details with a button. This component does that.
 */

import React from 'react'
import { Segment } from 'semantic-ui-react'

import RevealContentButton from '../../RevealContentButton'

import withOnlyRenderInEnvironments from '../../HOC/ui/withOnlyRenderInEnvironments'

import {
  ENVIRONMENT_PROD,
} from '../../../constants'


export default withOnlyRenderInEnvironments({
  environments: [ENVIRONMENT_PROD],
  onlyRenderIfWeAreNotInTheseEnvsRatherThanIfWeAre: true,
  // If an employee is logged in and they're not navigated to a customer route
  // (i.e. not currently operating as a customer user), display the error
  // details even in Prod
  renderInAllEnvsIfThisOverrideFunctionReturnsTrue: ({ location }) => location.pathname.toLowerCase().startsWith('/employee'),
})(({
  errorDetails,
  buttonProps,
  blockDisplay,
  asPopup,
  buttonTextWhenVisible='Hide error details',
  buttonTextWhenHidden='Show error details',
}) => {
  const { url, method, errorCode, errorMessage, responseBody, amazonRequestId } = errorDetails
  return (
    <RevealContentButton
      buttonTextWhenVisible={buttonTextWhenVisible}
      buttonTextWhenHidden={buttonTextWhenHidden}
      buttonProps={buttonProps}
      blockDisplay={blockDisplay}
      asPopup={asPopup}
      popupProps={{ wide: 'very' }}
    >
      {/* Wrapping in a Segment provides visual padding and bounding for the
        rather free-flowing text of the error details; it's especially helpful
        when the response body is long. */}
      <Segment>
        <p><span style={{ fontWeight: 'bold' }}>URL: </span>{url}</p>
        <p><span style={{ fontWeight: 'bold' }}>Amazon Request ID: </span>{amazonRequestId}</p>
        <p><span style={{ fontWeight: 'bold' }}>HTTP method: </span>{method ? method.toString().toUpperCase() : method}</p>
        <p><span style={{ fontWeight: 'bold' }}>HTTP error code: </span>{errorCode}</p>
        <p><span style={{ fontWeight: 'bold' }}>HTTP error message: </span>{errorMessage}</p>
        <p><span style={{ fontWeight: 'bold' }}>HTTP response body: </span></p>
        <pre>{JSON.stringify(responseBody, null, 2)}</pre>
      </Segment>
    </RevealContentButton>
  )
})
