/* eslint-disable max-len */
import {
  createCustomersFieldsDropdownOptionsForSelfCollectionShipmentFormsBrwAndConbrw,
} from './shared'


import {
  REPORT_SELF_COLLECTION_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../../../../../../constants/formAndApiUrlConfig/reportSelfCollectionShipments'

import {
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
} from '../../../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  getCustomerTypesABrwOrConbrwCanReportSelfCollectionShipmentsFrom,
} from '../../../../../util'


// Returns an array of objects used to define each of the field arrays in a
// non-contractee Brewer's Report Outbound Shipments form. See the docstring of
// the function that calls this one for details on the structure of the returned
// objects.
export function createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditSelfCollectionShipments({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerId,
  customerType,
}) {
  const allDropdownOptions = createCustomersFieldsDropdownOptionsForSelfCollectionShipmentFormsBrwAndConbrw({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId,
    customerType,
    customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond: getCustomerTypesABrwOrConbrwCanReportSelfCollectionShipmentsFrom(),
  })

  return [
    {
      fieldArrayTitle: null, // no title needed if only one field array in form
      fieldArrayName: REPORT_SELF_COLLECTION_SHIPMENTS_FIELD_ARRAY_NAME,
      whichCustomersFieldMustBeChosenFirstSourceOrDestination:
        WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
      customersFieldsDropdownOptions: allDropdownOptions,
    },
  ]
}
