/* eslint-disable max-len */

import uniq_ from 'lodash/uniq'
import sortBy_ from 'lodash/sortBy'
import uniqBy_ from 'lodash/uniqBy'


import {
  getCustomerObjectsOfAllRelatedTo,
  getCustomerIdsOfAllRelatedTo,
} from './relationships/relatedToInfo'
import {
  getCustomerIdsOfAllRelatedFrom,
  getCustomerObjectsOfAllRelatedFrom,
  getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedFrom,
} from './relationships/relatedFromInfo'
import {
  getCanShipmentsBeReportedOnThisRelationship,
} from './relationships/relatedToOrFromInfo'
import {
  getContracts,
} from './contracts'
import {
  getDoesBrewerHaveCustomerLevelPermission,
  getDoesContractBrewerHaveCustomerLevelPermission,
} from './permissionsAtTheCustomerLevel'
import {
  getDoesContractBrewerUserHavePermissionsToDoAtLeastOneThingOnBehalfOfContractee,
  getHasPermissionsToPerformManageUsersPermissionsChoice,
} from './permissions'

import {
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,
  MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER,

  MANAGE_USERS_PERMISSIONS_CHOICE_TO_HUMAN_READABLE_LABEL_MAP,

  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_KEG_FILLS,
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_INVENTORY,
} from '../../../constants/permissions'

import {
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
} from '../../../constants'


/*
 * *****************************************************************************
 * Basic misc.
 * *****************************************************************************
*/

// Returns an array of contractee customerIds, sorted Actives alphabetially by
// name. This is _NOT_ a list of contractees the CB can operate
// for--remember that just because a contractee appears in the "Brewing For"
// section does not mean that the "Operate For" button should be rendered; see
// the whichContracteesCanConbrwOperateFor() selector in this file for that.
export function getSortedListOfContracteeCustomerIdsThatShouldBeInTheBrewingForSectionOfTheConbrwDashboard({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
}) {
  const allContractees = getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedFrom({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId: conbrwCustomerId,
    propDefs: [
      { prop: 'id', alias: 'relationshipId', residesIn: 'relationshipObj' },
      { prop: 'id', alias: 'customerId', residesIn: 'customerObj' },
      { prop: 'name', residesIn: 'customerObj' },
    ],
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112, CODE_COMMENTS_159
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
  })

  const allActiveContractees = allContractees.filter(o => getCanShipmentsBeReportedOnThisRelationship({
    entireCustomersSlice,
    entireContractsSlice,
    relationshipObj: entireRelationshipsSlice[o.relationshipId],
  }))
  // CODE_COMMENTS_230
  const allActiveContracteesNoDuplicates = uniqBy_(allActiveContractees, 'customerId')
  const allActiveContracteesSorted = sortBy_(allActiveContracteesNoDuplicates, ['name'], ['desc'])
  return allActiveContracteesSorted.map(o => o.customerId)
}


// returns an array of contractee customerIds (or empty array if none)
export function getWhichContracteesCanConbrwOperateFor({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
}) {
  const allContracteesInBrewingForSection =
    getSortedListOfContracteeCustomerIdsThatShouldBeInTheBrewingForSectionOfTheConbrwDashboard({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      conbrwCustomerId,
    })

  return allContracteesInBrewingForSection.filter(contracteeCustomerId => (
    // In deciding whether to render the "Operate For" button, we check user
    // permissions but not customer-level permissions (CODE_COMMENTS_168). This
    // is because customer-level permissions only determine whether to render
    // the "Order Keg Collars" form and the "Report Shipments" forms, but they
    // can't tell us anything about whether the CB operating for the contractee
    // is allowed to view the history tables. Imagine all customer level
    // permissions could be set such that the Contract Brewer operating for the
    // contractee isn't allowed to do anything; it's still possible that the CB
    // should be allowed to view the shipments history tables when operating for
    // the contractee, and this can only be determined by inspecting user-level
    // permissions.
    getDoesContractBrewerUserHavePermissionsToDoAtLeastOneThingOnBehalfOfContractee({
      entirePermissionsSlice,
      entireCurrentUserSlice,
      contracteeCustomerId,
    })
  ))
}


// Returns an array of contractee customerIds whose relationshipObjects with the
// contract brewer can have shipments reported on them. Returns an empty array
// if none.
export function getWhichContracteesOfAConbrwCanHaveShipmentsReportedOnThem({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
  getCustomerObjectsRatherThanCustomerIds=false,
}) {
  const getFunc = getCustomerObjectsRatherThanCustomerIds
    ? getCustomerObjectsOfAllRelatedFrom
    : getCustomerIdsOfAllRelatedFrom
  return uniq_( // CODE_COMMENTS_216
    getFunc({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId: conbrwCustomerId,
      onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
    }),
  )
}


// Returns an array of conbrw customerIds whose relationshipObjects with the
// brewer can have shipments reported on them. Returns an empty array if none.
export function getWhichConbrwsOfALoggedInBrwCanHaveShipmentsReportedOnThem({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  brwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
  getCustomerObjectsRatherThanCustomerIds=false,
}) {
  const getFunc = getCustomerObjectsRatherThanCustomerIds
    ? getCustomerObjectsOfAllRelatedTo
    : getCustomerIdsOfAllRelatedTo
  return uniq_( // CODE_COMMENTS_216
    getFunc({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId: brwCustomerId,
      onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
    }),
  )
}


/*
 * *****************************************************************************
 * Keg Fills
 * *****************************************************************************
*/

export function getWhichContracteesOfALoggedInConbrwIsTheConbrwResponsibleForReportingKegFillsFor({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  conbrwCustomerId,
  getCustomerObjectsRatherThanCustomerIds,
}) {
  const hasReportKegFillsPermissions = getHasPermissionsToPerformManageUsersPermissionsChoice({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    permissionsChoice: MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS,
  })
  if (!hasReportKegFillsPermissions) {
    return []
  }
  const activeContractees = getWhichContracteesOfAConbrwCanHaveShipmentsReportedOnThem({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    conbrwCustomerId,
    getCustomerObjectsRatherThanCustomerIds,
  })

  return activeContractees.filter(objOrId => (
    getDoesContractBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId: getCustomerObjectsRatherThanCustomerIds ? objOrId.id : objOrId,
      conbrwCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_KEG_FILLS,
    })
  ))
}


export function getWhichContracteesOfALoggedInConbrwAreResponsibleForReportingTheConbrwsKegFills({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  conbrwCustomerId,
  getCustomerObjectsRatherThanCustomerIds=false,
}) {
  const activeContractees = getWhichContracteesOfAConbrwCanHaveShipmentsReportedOnThem({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    conbrwCustomerId,
    getCustomerObjectsRatherThanCustomerIds,
  })

  return activeContractees.filter(objOrId => (
    getDoesBrewerHaveCustomerLevelPermission({
      entireRelationshipsSlice,
      entireCustomersSlice,
      entireContractsSlice,
      brwCustomerId: getCustomerObjectsRatherThanCustomerIds ? objOrId.id : objOrId,
      conbrwCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_KEG_FILLS,
    })
  ))
}


// Note that this function is NOT for a conbrw operating as a contractee, but
// rather for a logged-in BRW who happens to have CONBRWs brewing for it
// (remember that a Brewer can be responsible for reporting a CONBRWs keg fills
// if the CONBRW).
export function getWhichConbrwsOfALoggedInBrwAreResponsibleForReportingTheirKegFills({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  brwCustomerId,
  getCustomerObjectsRatherThanCustomerIds=false,
}) {
  const activeConbrws = getWhichConbrwsOfALoggedInBrwCanHaveShipmentsReportedOnThem({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    brwCustomerId,
    getCustomerObjectsRatherThanCustomerIds,
  })

  return activeConbrws.filter(objOrId => (
    getDoesContractBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId,
      conbrwCustomerId: getCustomerObjectsRatherThanCustomerIds ? objOrId.id : objOrId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_KEG_FILLS,
    })
  ))
}


// Note that this function is NOT for a conbrw operating as a contractee, but
// rather for a logged-in BRW who happens to have CONBRWs brewing for it
// (remember that a Brewer can be responsible for reporting a CONBRWs keg fills).
export function getWhichConbrwsOfALoggedInBrwIsTheBrwResponsibleForReportingKegFillsFor({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  brwCustomerId,
  getCustomerObjectsRatherThanCustomerIds=false,
}) {
  const activeConbrws = getWhichConbrwsOfALoggedInBrwCanHaveShipmentsReportedOnThem({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    brwCustomerId,
    getCustomerObjectsRatherThanCustomerIds,
  })

  return activeConbrws.filter(objOrId => (
    getDoesBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId,
      conbrwCustomerId: getCustomerObjectsRatherThanCustomerIds ? objOrId.id : objOrId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_KEG_FILLS,
    })
  ))
}


/*
 * *****************************************************************************
 * Inventory
 * *****************************************************************************
*/

// Returns an array of contractee customerIds the Conbrw can report and edit
// inventory for (or an empty array if none). This function inspects several
// things, including permissions, perCustomerPermissions, and customer-level
// permissions.
export function getWhichContracteesCanConbrwReportInventoryFor({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
}) {
  const hasReportInventoryPermissions = getHasPermissionsToPerformManageUsersPermissionsChoice({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    permissionsChoice: MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,
  })
  if (!hasReportInventoryPermissions) {
    return []
  }

  const allContracteeIds = uniq_(getCustomerIdsOfAllRelatedFrom({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId: conbrwCustomerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // filter out disabled relationships
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
  }))
  const contracteesWithNonExpiredConbrwContracts = allContracteeIds.filter(contracteeId => (
    // Because we set mostRecentOnly to true, getContracts will return undefined
    // if no non-expired conbrw contract exists between the contractee and the
    // CONBRW
    getContracts({
      entireContractsSlice,
      entireCustomersSlice,
      entireRelationshipsSlice,
      customerId: contracteeId,
      filterConbrwContractsToOnlyThisConbrwCustomerId: conbrwCustomerId,
      notExpiredOnly: true,
      activeStatusOnly: true,
      noFutureContracts: true,
      mostRecentOnly: true,
    })
  ))

  const contracteesWithPerCustomerPermissionsGranted = contracteesWithNonExpiredConbrwContracts.filter(
    contracteeCustomerId => (getHasPermissionsToPerformManageUsersPermissionsChoice({
      entirePermissionsSlice,
      entireCurrentUserSlice,
      permissionsChoice: MANAGE_USERS_PER_CUSTOMER_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS,
      customerIdIfThisIsAPerCustomerPermissionsCheck: contracteeCustomerId,
    })),
  )

  return contracteesWithPerCustomerPermissionsGranted.filter(contracteeCustomerId => (
    getDoesContractBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId: contracteeCustomerId,
      conbrwCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_INVENTORY,
    })
  ))
}

// Returns an array of contractee customerIds the Conbrw can fetch inventory
// history for (or an empty array if none). This is made up of all contractees
// (currently active and not currently active, disabled and not disabled) who
// the current user has perCustomerPermissions to fetch inventory history for.
export function getWhichContracteesCanConbrwFetchInventoryReportHistoryFor({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
}) {
  const allContractees = uniq_( // CODE_COMMENTS_216
    getCustomerIdsOfAllRelatedFrom({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId: conbrwCustomerId,
      // expired contracts are OK, but contractees with Inactive status
      // contracts should disappear from a CONBRW's inventory history entirely,
      // as should those whose relationship to the CONBRW is disabled. This flag
      // does exactly that.
      onlyRelationshipsThatShipmentsCanBeReportedOn: true,
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
    }),
  )

  return allContractees.filter(contracteeCustomerId => (
    getHasPermissionsToPerformManageUsersPermissionsChoice({
      entirePermissionsSlice,
      entireCurrentUserSlice,
      permissionsChoice: MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER,
      customerIdIfThisIsAPerCustomerPermissionsCheck: contracteeCustomerId,
    })
  ))
}


/*
 * *****************************************************************************
 * Special permissions that concern Contract Brewers and their contractees
 * *****************************************************************************
*/


// CODE_COMMENTS_173: In order for a Contract Brewer to report inventoy, it
// needs not only "report inventory" permissions for itself but also "report
// inventory" perCustomerPermissions for all its active contractees. This
// function checks to make sure the CB user has such permissions and
// perCustomerPermissions.

// One other responsibility of this function is to send an error message to our
// 3rd-party error-logging service if the Contract Brewer user has permission to
// report inventory but it doesn't have perCustomerPermissions for all its
// active contractees to, or if it has perCustomerPermissions for one or more of
// its active contractees to report inventory but it itself has no such
// permission.
export function getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToReportInventory({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  userIdIfNotCurrentUser,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
  // When would we ever pass in a custom permissions map? When submitting the
  // "Edit User Permissions" form (where we craft a new permissions map based on
  // the form values set by the user)
  customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
}) {
  const rootCustomerIdOfUserPassedIn = userIdIfNotCurrentUser
    ? entireUsersSlice[userIdIfNotCurrentUser].rootCustomerId
    : entireCurrentUserSlice.rootCustomerId
  const customerTypeOfRootCustomer = entireCustomersSlice[rootCustomerIdOfUserPassedIn].customerType

  if (
    [
      CUSTOMER_TYPES_BREWER,
      CUSTOMER_TYPES_DISTRIBUTOR,
    ].includes(customerTypeOfRootCustomer)
  ) { return true }

  // must be a Master or Conbrw customer
  return withErrorLoggingGetDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToReportInventory({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
    conbrwCustomerId,
    customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
  })
}


// CODE_COMMENTS_173: In order for a Contract Brewer to view inventory report
// history, it needs not only "View Inventory Report History" permissions for
// itself but also "View Inventory Report History" perCustomerPermissions for
// all its contractees. This function checks to make sure the CB user has such
// permissions and perCustomerPermissions.

// One other responsibility of this function is to send an error message to our
// 3rd-party error-logging service if the Contract Brewer user has permission to
// view inventory history but it doesn't have perCustomerPermissions for all its
// active contractees to, or if it has perCustomerPermissions for one or more of
// its active contractees to view inventory report history but it itself has no
// such permission.
export function getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToViewInventoryReportHistory({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  userIdIfNotCurrentUser,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
  // When would we ever pass in a custom permissions map? When submitting the
  // "Edit User Permissions" form (where we craft a new permissions map based on
  // the form values set by the user)
  customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
}) {
  const rootCustomerIdOfUserPassedIn = userIdIfNotCurrentUser
    ? entireUsersSlice[userIdIfNotCurrentUser].rootCustomerId
    : entireCurrentUserSlice.rootCustomerId
  const customerTypeOfRootCustomer = entireCustomersSlice[rootCustomerIdOfUserPassedIn].customerType

  if (
    [
      CUSTOMER_TYPES_BREWER,
      CUSTOMER_TYPES_DISTRIBUTOR,
    ].includes(customerTypeOfRootCustomer)
  ) { return true }

  // must be a Master or Conbrw customer
  return withErrorLoggingGetDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToViewInventoryHistory({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
    conbrwCustomerId,
    customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
  })
}


// Helper functions for this section


function withErrorLoggingGetDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToReportInventory({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  userIdIfNotCurrentUser,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
  customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
}) {
  const activeContracteeIds = getWhichContracteesOfAConbrwCanHaveShipmentsReportedOnThem({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    conbrwCustomerId,
  })

  return withErrorLoggingGetDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToPerformPermissionsChoice({
    entireCustomersSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
    conbrwCustomerId,
    permissionsChoice: MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,
    contracteeIdsToCheckPerCustomerPermissionsFor: activeContracteeIds,
    customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
  })
}


function withErrorLoggingGetDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToViewInventoryHistory({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  userIdIfNotCurrentUser,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
  customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
}) {
  const activeContracteeIds = getWhichContracteesOfAConbrwCanHaveShipmentsReportedOnThem({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    conbrwCustomerId,
  })

  return withErrorLoggingGetDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToPerformPermissionsChoice({
    entireCustomersSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
    conbrwCustomerId,
    permissionsChoice: MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER,
    contracteeIdsToCheckPerCustomerPermissionsFor: activeContracteeIds,
    customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
  })
}

// returns true or false and also logs error to our 3rd-party error logging
// service if there's a mismatch between the contract brewer's own permissions
// and its perCustomerPermissions of its contractees
function withErrorLoggingGetDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToPerformPermissionsChoice({
  entireCustomersSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  userIdIfNotCurrentUser,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
  permissionsChoice,
  contracteeIdsToCheckPerCustomerPermissionsFor,
  customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
}) {
  const doesConbrwHaveOwnPermissions = getHasPermissionsToPerformManageUsersPermissionsChoice({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    userIdIfNotCurrentUser,
    customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
    permissionsChoice,
  })

  const whichContracteesDoesConbrwHavePerCustomerPermissionsFor = contracteeIdsToCheckPerCustomerPermissionsFor.map(
    contracteeId => {
      const hasPerCustomerPermissionsForThisContractee = getHasPermissionsToPerformManageUsersPermissionsChoice({
        entirePermissionsSlice,
        entireCurrentUserSlice,
        userIdIfNotCurrentUser,
        customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
        permissionsChoice,
        customerIdIfThisIsAPerCustomerPermissionsCheck: contracteeId,
      })
      return {
        contracteeCustomerId: contracteeId,
        hasPerCustomerPermissions: hasPerCustomerPermissionsForThisContractee,
      }
    },
  )

  logErrorIfNecessary({
    entireCustomersSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    doesConbrwHaveOwnPermissions,
    whichContracteesDoesConbrwHavePerCustomerPermissionsFor,
    conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
    permissionsChoice,
  })

  const doesConbrwUserHavePerCustomerPermissionsForAllItsContractees =
    whichContracteesDoesConbrwHavePerCustomerPermissionsFor.every(o => o.hasPerCustomerPermissions)


  return (
    doesConbrwHaveOwnPermissions &&
    doesConbrwUserHavePerCustomerPermissionsForAllItsContractees
  )
}


function logErrorIfNecessary({
  entireCustomersSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  doesConbrwHaveOwnPermissions,
  whichContracteesDoesConbrwHavePerCustomerPermissionsFor,
  userIdIfNotCurrentUser,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
  permissionsChoice,
}) {
  const doesConbrwUserHavePerCustomerPermissionsForAllItsContractees =
    whichContracteesDoesConbrwHavePerCustomerPermissionsFor.every(o => o.hasPerCustomerPermissions)
  const doesConbrwHavePerCustomerPermissionsForAnyOfItsContractees =
    whichContracteesDoesConbrwHavePerCustomerPermissionsFor.some(o => o.hasPerCustomerPermissions)


  // get all variables needed for the error message
  const humanReadablePermission = MANAGE_USERS_PERMISSIONS_CHOICE_TO_HUMAN_READABLE_LABEL_MAP[permissionsChoice]
  const userEmailAddress = userIdIfNotCurrentUser
    ? entireUsersSlice[userIdIfNotCurrentUser].emailAddress
    : entireCurrentUserSlice.emailAddress
  const rootCustomerIdOfUser = userIdIfNotCurrentUser
    ? entireUsersSlice[userIdIfNotCurrentUser].rootCustomerId
    : entireCurrentUserSlice.rootCustomerId
  const rootCustomerTypeOfUser = entireCustomersSlice[rootCustomerIdOfUser].customerType
  const rootCustomerNameOfUser = entireCustomersSlice[rootCustomerIdOfUser].name
  const rootCustomerTapIdOfUser = entireCustomersSlice[rootCustomerIdOfUser].tapcustomerId
  const isRootCustomerTypeOfUserAMasterRatherThanAContractBrewer = rootCustomerTypeOfUser === CUSTOMER_TYPES_MASTER
  const conbrwName = entireCustomersSlice[conbrwCustomerId].name
  const conbrwTapcustomerId = entireCustomersSlice[conbrwCustomerId].tapcustomerId
  const startOfMessage = isRootCustomerTypeOfUserAMasterRatherThanAContractBrewer
    ? `This error message concerns the user ${userEmailAddress}, whose root customer is the Master customer ${rootCustomerNameOfUser} (TAP customer ID: ${rootCustomerTapIdOfUser}). One of this Master's children is the Contract Brewer ${conbrwName} (TAP customer ID: ${conbrwTapcustomerId})`
    : `This error message concerns the user ${userEmailAddress}, whose root customer is the Contract Brewer ${conbrwName} (TAP customer ID: ${conbrwTapcustomerId})`


  if (
    doesConbrwHaveOwnPermissions &&
    !doesConbrwUserHavePerCustomerPermissionsForAllItsContractees
  ) {
    const contracteeCustomerIdsUserDoesntHavePermissionsFor = whichContracteesDoesConbrwHavePerCustomerPermissionsFor.filter(
      o => !o.hasPerCustomerPermissions,
    ).map(
      o => o.contracteeCustomerId,
    )
    const contracteeNamesUserDoesntHavePermissionsFor = contracteeCustomerIdsUserDoesntHavePermissionsFor.map(
      contracteeCustId => entireCustomersSlice[contracteeCustId].name,
    )
    const printableNamesOfContracteesUserDoesntHavePermissionsFor =
      contracteeNamesUserDoesntHavePermissionsFor.join(', \n')

    // eslint-disable-next-line no-unused-vars
    const errorMessage = `${startOfMessage}. This user has the '${humanReadablePermission}' permission for itself but it does not have the same perCustomerPermission for all the contractee brewers it needs to. This puts the Contract Brewer's permissions in an inconsistent state with the result being that when ${userEmailAddress} logs in to the web app, it is currently being prevented from performing this function. Fix this in the desktop app by granting the '${humanReadablePermission}' perCustomerPermission to the following contractees of ${userEmailAddress}:\n${printableNamesOfContracteesUserDoesntHavePermissionsFor}`

    // console.log(errorMessage)
    // TODO rollbar: log errorMessage to our 3rd-party error logging service
  }


  if (
    !doesConbrwHaveOwnPermissions &&
    doesConbrwHavePerCustomerPermissionsForAnyOfItsContractees
  ) {
    const contracteeCustomerIdsUserHasPerCustomerPermissionsFor = whichContracteesDoesConbrwHavePerCustomerPermissionsFor.filter(
      o => o.hasPerCustomerPermissions,
    ).map(
      o => o.contracteeCustomerId,
    )
    const contracteeNamesUserHasPerCustomerPermissionsFor = contracteeCustomerIdsUserHasPerCustomerPermissionsFor.map(
      contracteeCustId => entireCustomersSlice[contracteeCustId].name,
    )
    const printableNamesOfContracteesUserHasPerCustomerPermissionsFor =
      contracteeNamesUserHasPerCustomerPermissionsFor.join(', \n')

    // eslint-disable-next-line no-unused-vars
    const errorMessage = `${startOfMessage}. This user does not have the '${humanReadablePermission}' permission for itself (and is therefore being prevented from performing this function) but it does have the '${humanReadablePermission}' perCustomerPermission for one or more of its contractee brewers. This puts the Contract Brewer's permissions in an inconsistent state and is a user configuration error. Either the user needs to have this permission and ALL its contractees needs to have this perCustomerPermission, or no one should to have this permission, neither the Contract Brewer nor any of its contractees. Fix this in the desktop app. The contractee brewers who have this perCustomerPermission are:\n${printableNamesOfContracteesUserHasPerCustomerPermissionsFor}`

    // console.log(errorMessage)
    // TODO rollbar: log errorMessage to our 3rd-party error logging service
  }
}
