import React from 'react'


import HasPermissions from '../../../../../../common-components/HasPermissions'

import {
  OrderKegsOption,
  AcknowledgeInboundShipmentsOption,
  ReportKegFillsOption,
  ReportSelfCollectionShipmentsOption,
  PayBalanceOption,
  ReportInventoryOption,
  RequestLocalReleaseAuthorizationOption,
  ManageUsersOption,
  ManageContactsAndSubscriptionsOption,
  ManageAddressesOption,
} from '../../shared/IndividualOptions'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
} from '../../../../../../constants/permissions'

import {
  createDisabledIfAnyOfTheseConditionsAreTrueFunc,
} from '../../util'

import {
  TEMPORARILY_DISABLE_PAY_BALANCES_FEATURE,
  TEMPORARILY_DISABLE_CONTACTS_FEATURE,
} from '../../../../../../config'


// CODE_COMMENTS_75
// CODE_COMMENTS_39
export default ({
  shouldReportKegFillsFormBeRendered,
  shouldReportSelfCollectionShipmentsFormBeRendered,
  isRelatedToLocalDistributors,
  shouldReportInventoryFormBeRendered,
  customerId,
  currency,
}) => ([
  <HasPermissions
    key="OrderKegsOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS}
    renderIfHasPermissions={
      () => (
        <OrderKegsOption />
      )
    }
  />,
  <HasPermissions
    key="AcknowledgeInboundShipmentsOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS}
    renderIfHasPermissions={
      () => (
        <AcknowledgeInboundShipmentsOption />
      )
    }
  />,
  ...(
    shouldReportKegFillsFormBeRendered
      ? [<ReportKegFillsOption key="ReportKegFillsOption" />]
      : []
  ),
  ...(
    shouldReportSelfCollectionShipmentsFormBeRendered
      ? [<ReportSelfCollectionShipmentsOption key="ReportSelfCollectionShipmentsOption" />]
      : []
  ),
  // don't show the "Request Local Release Authorization" card if the customer
  // isn't related to any local distributors
  ...(isRelatedToLocalDistributors
    ? [
      <HasPermissions
        key="RequestLocalReleaseAuthorizationOption"
        requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS}
        renderIfHasPermissions={
          () => (
            <RequestLocalReleaseAuthorizationOption customerId={customerId} />
          )
        }
      />,
    ]
    : []
  ),
  ...(!TEMPORARILY_DISABLE_PAY_BALANCES_FEATURE && currency === 'USD'
    ? [
      <HasPermissions
        key="PayBalanceOption"
        requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES}
        renderIfHasPermissions={
          () => (
            <PayBalanceOption customerId={customerId} />
          )
        }
        disabledIf={
          createDisabledIfAnyOfTheseConditionsAreTrueFunc({
            customerId,
            customerHasNoInvoices: true,
          })
        }
      />,
    ]
    : []
  ),
  ...(
    shouldReportInventoryFormBeRendered
      ? [<ReportInventoryOption key="ReportInventoryOption" />]
      : []
  ),
  <HasPermissions
    key="ManageUsersOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
    renderIfHasPermissions={
      () => (
        <ManageUsersOption />
      )
    }
  />,
  ...(!TEMPORARILY_DISABLE_CONTACTS_FEATURE
    ? [
      <HasPermissions
        key="ManageContactsAndSubscriptionsOption"
        requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
        renderIfHasPermissions={
          () => (
            <ManageContactsAndSubscriptionsOption />
          )
        }
      />,
    ]
    : []
  ),
  <HasPermissions
    key="ManageAddressesOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES}
    renderIfHasPermissions={
      () => (
        <ManageAddressesOption />
      )
    }
  />,
])
