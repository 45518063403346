/**
 * The purpose of this component is to simply call the
 * tasksToPerformImmediatelyAfterLogin saga after the user successfully
 * logs in (or after a user who was previously logged in navigates to the web
 * app and finds he's still logged in thanks to the state persisted in
 * localStorage) and wait for that saga to finish before rendering children. If
 * the saga fails, it will emit its own logout action, so this component doesn't
 * need to worry about handling the saga-failed case.
 */
import { connect } from 'react-redux'

import TasksToPerformImmediatelyAfterLogin from './components'

import {
  getHaveTasksToPerformImmediatelyAfterLoginBeenAttempted,
  getHaveTasksToPerformImmediatelyAfterLoginSucceeded,
} from '../../redux/selectors/tasksToPerformImmediatelyAfterLogin'

import { PERFORM_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN } from '../../redux/actions/actionTypes'
import createAction from '../../redux/actions/createAction'

const mapStateToProps = state => ({
  haveTasksToPerformImmediatelyAfterLoginBeenAttempted:
    getHaveTasksToPerformImmediatelyAfterLoginBeenAttempted(state),
  haveTasksToPerformImmediatelyAfterLoginSucceeded:
    getHaveTasksToPerformImmediatelyAfterLoginSucceeded(state),
})

const mapDispatchToProps = dispatch => ({
  tasksToPerformImmediatelyAfterLogin:
    () => dispatch(createAction(PERFORM_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TasksToPerformImmediatelyAfterLogin)
