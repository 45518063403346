import React from 'react'
import { Header, Message } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import ContactCard from '../ContactCard'

import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../constants'

export default ({
  customerId,
  headerText,
  messageText,
  messageSeverity, // 'negative' or 'warning'
  // set to false for employee features where a customerId is not passed in
  showCustomerRepContactCard=true,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Message
      compact
      warning={messageSeverity==='warning'}
      negative={messageSeverity==='negative'}
      style={{ width: '500px' }}
    >
      <Header as="h2">{translate(headerText)}</Header>
      <p>{translate(messageText)}</p>
      {showCustomerRepContactCard &&
        <ContactCard customerId={customerId} repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE} />
      }
    </Message>
  )
}
