import {
  createFieldArraysAndCustomersFieldsDropdownOptions,
} from '../ReportShipments/common/ReportAndEditShipments/components/util/fieldArraysAndCustomersFieldsDropdownOptions'

import {
  parseCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../utils'


/*
 * *****************************************************************************
 * "Source" step
 * *****************************************************************************
*/

// Returns an array of two-item objects: id and name. The id value will be
// either the customerId of the currently-operated-for customer (i.e. same as
// the customerId passed in, which signifies that the source is the customer's
// Default PPF Contract), or the customerId of a Contract Brewer who brews for
// this customer.
export function getSourceChoices({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId,
}) {
  const sourceFieldsOfReportOutboundShipmentsForm =
    createFieldArraysAndCustomersFieldsDropdownOptions({
      formType: 'outbounds',
      customerId,
      operatingContractBrewerCustomerId,
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
    })[
      // A Report outbound Shipments form will always only have one field array.
      // See CODE_COMMENTS_188 for the structure of the array returned by this
      // function call.
      0
    ].customersFieldsDropdownOptions

  return sourceFieldsOfReportOutboundShipmentsForm.map(o => {
    const {
      customerId: custId,
      contractBrewerCustomerId,
    } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(o.id)
    return {
      id: contractBrewerCustomerId || custId,
      name: o.name,
    }
  })
}


export function getShouldSourceStepBeRendered(props) {
  return getSourceChoices(props).length > 1
}
