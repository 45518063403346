/* eslint-disable max-len */

import reduce_ from 'lodash/reduce'


import pickBy_ from 'lodash/pickBy'
import keys_ from 'lodash/keys'

import flow_ from 'lodash/fp/flow'
import pickFp_ from 'lodash/fp/pick'
import mapFp_ from 'lodash/fp/map'
import reduceFp_ from 'lodash/fp/reduce'
import filterFp_ from 'lodash/fp/filter'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../../../../redux/selectors/rewrite/parentChildLinks'
import {
  getEntireSlice as getEntireAddressesSlice,
} from '../../../../../redux/selectors/rewrite/addresses'
import {
  getEntireSlice as getEntireCustomerAddressLinksSlice,
} from '../../../../../redux/selectors/rewrite/customerAddressLinks'
import {
  getEntireSlice as getEntireRelationshipsSlice,
  getAllBrw2ConbrwRelationshipsOfBrewer,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../../../redux/selectors/rewrite/users'

import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getChildPubIds,
} from '../../../../../redux/selectors/children'
import {
  getWhichContracteesCanConbrwFetchInventoryReportHistoryFor,
} from '../../../../../redux/selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'
import {
  getEntireSlice as getEntireInventoryReportHistorySlice,
} from '../../../../../redux/selectors/histories/inventoryHistory'
import { getAllFormFetchStatuses } from '../../../../../redux/selectors/fetchStatuses/forms'

import {
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY,
  INVENTORY_REPORT_OBJS,

  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR,
} from '../../../../ReportInventory/util/constants'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  HISTORY_FORM_NAME_INVENTORY,
} from '../../../../../constants'

import {
  returnFuncToGatherInventoryReportObjsByCustomerType,
} from '../../../../ReportInventory/util/inventoryReportObjs/interface'

import {
  getCustomerName,
  getNameOfReportingPerson,
} from '../../../../ReportInventory/util'


import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  parseCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


/*
 * *****************************************************************************
 * Exported functions
 * *****************************************************************************
*/

// Returns an array of objects. Each object represents one row in the
// inventory reports history table (see CODE_COMMENTS_229 for details):
// [
//   {
//   countDate: 1567277112000,
//   customerName: 'Avery Brewing Company',
//   nameOfReportingPerson: 'Jane Doe',
//   [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]:
//     CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
//   [INVENTORY_REPORT_OBJS]: the data structure containing inventory
//       report objects as created by the
//       returnFuncToGatherInventoryReportObjsByCustomerType() return func
//       from /features/reportInventory/util/inventoryReportObjs/interface.js
//   },
//   {
//   countDate: 1564598712000,
//   customerName: 'Avery Brewing Company',
//   nameOfReportingPerson: 'Jane Doe',
//   [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]:
//     CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
//   [INVENTORY_REPORT_OBJS]: the data structure containing inventory
//       report objects as created by the
//       returnFuncToGatherInventoryReportObjsByCustomerType() return func
//       from /features/reportInventory/util/inventoryReportObjs/interface.js
//   },
// ]
export function createGroupingsOfInventoryReportObjsForHistoryTable({
  state,
  customerId,
  isEditForm,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
}) {
  const entireInventoryReportHistorySlice = getEntireInventoryReportHistorySlice(state)
  const customerType = getCustomerProp(state, customerId, 'customerType')

  const allPossibleInventoryReportObjectReduxIdentifiers = getAllPossibleInventoryReportReduxIdentifiersForThisCustomer(
    state,
    customerId,
  )

  return flow_(
    // filter the entire Redux slice by only those identifiers that concern this
    // customer. Note: one cool part about lodash's pick/pickFp is that if there
    // are values in the allPossibleInventoryReportObjectReduxIdentifiers array
    // that aren't keys in inventoryReportObjs, they don't get included in the
    // resulting object, so we don't have to take the extra step to filter out
    // items with null/undefined values. The result is, e.g.:
    //
    // {
    //   [brwCustId]: {
    //     [inventoryObjId]: <inventoryReportObj>,
    //     [inventoryObjId]: <inventoryReportObjWithDiffCountDate>,
    //   },
    //   [childPubCustId]: {
    //     [inventoryObjId]: <inventoryReportObj>,
    //     [inventoryObjId]: <inventoryReportObjWithDiffCountDate>,
    //   },
    //   [brwCustId__--__conbrwCustId]: {
    //     [inventoryObjId]: <inventoryReportObj>,
    //     [inventoryObjId]: <inventoryReportObjWithDiffCountDate>,
    //   },
    // }
    pickFp_(allPossibleInventoryReportObjectReduxIdentifiers),
    // change to:
    // [
    //   { reduxIdentifier: brwCustId, inventoryReportObj: <inventoryReportObj> },
    //   { reduxIdentifier: brwCustId, inventoryReportObj: <inventoryReportObjWithDiffCountDate> },
    //   { reduxIdentifier: childPubCustId, inventoryReportObj: <inventoryReportObj> },
    //   { reduxIdentifier: childPubCustId, inventoryReportObj: <inventoryReportObjWithDiffCountDate> },
    //   { reduxIdentifier: brwCustId__--__conbrwCustId, inventoryReportObj: <inventoryReportObj> },
    //   { reduxIdentifier: brwCustId__--__conbrwCustId, inventoryReportObj: <inventoryReportObjWithDiffCountDate> },
    // ]
    result => reduce_(
      result,
      (acc, objOfInventoryReportObjsKeyedById, reduxIdentifier) => ([
        ...acc,
        ...reduce_(
          objOfInventoryReportObjsKeyedById,
          (acc2, inventoryReportObj) => ([...acc2, { reduxIdentifier, inventoryReportObj }]),
          [],
        ),
      ]),
      [],
    ),
    //
    //
    // group by countDate, e.g.:
    // [
    //   {
    //     countDate: 1567277112000,
    //     inventoryReportObjsAndReduxIdentifiers: [
    //       { reduxIdentifier: brwCustId, inventoryReportObj: <inventoryReportObj> },
    //       { reduxIdentifier: childPubCustId, inventoryReportObj: <inventoryReportObj> },
    //       { reduxIdentifier: brwCustId__--__conbrwCustId, inventoryReportObj: <inventoryReportObj> },
    //     ]
    //   },
    //   {
    //     countDate: 1564598712000,
    //     inventoryReportObjsAndReduxIdentifiers: [
    //       { reduxIdentifier: brwCustId, inventoryReportObj: <inventoryReportObjWithDiffCountDate> },
    //       { reduxIdentifier: childPubCustId, inventoryReportObj: <inventoryReportObjWithDiffCountDate> },
    //       { reduxIdentifier: brwCustId__--__conbrwCustId, inventoryReportObj: <inventoryReportObjWithDiffCountDate> },
    //     ]
    //   },
    // ]
    reduceFp_(
      (acc, inventoryReportObjAndReduxIdentifier) => {
        const { inventoryReportObj } = inventoryReportObjAndReduxIdentifier
        const countDate = inventoryReportObj.countDate
        const objWithInventoryReportObjsThatHaveSameCoundDate = acc.find(o => o.countDate === countDate)
        if (!objWithInventoryReportObjsThatHaveSameCoundDate) {
          return [
            ...acc,
            { countDate, inventoryReportObjsAndReduxIdentifiers: [inventoryReportObjAndReduxIdentifier] },
          ]
        }
        objWithInventoryReportObjsThatHaveSameCoundDate.inventoryReportObjsAndReduxIdentifiers.push(
          inventoryReportObjAndReduxIdentifier,
        )
        return acc
      },
      [],
    ),
    // 10/30/2019: For now, when a CONBRW is logged in (or a MASTER operating as
    // a CONBRW child), hide any Inventory Reports that include report objects
    // at a contractee location but not the CBMST location. TODO: Create a new
    // type of displayed inventoryReportObj that includes only a CONBRW's
    // contractees, no CBMST contract. PS: When would this ever happen? See
    // https://microstartap3.atlassian.net/browse/TP3-2909
    filterFp_(grouping => {
      if (customerType !== CUSTOMER_TYPES_CONTRACT_BREWER) {
        return true
      }
      const allReduxIds = grouping.inventoryReportObjsAndReduxIdentifiers.map(o => o.reduxIdentifier)
      const reduxIdWithConbrwOnlyNoContractee = createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId)
      return allReduxIds.includes(reduxIdWithConbrwOnlyNoContractee)
    }),
    // Figure out what kind of customerTypeForReportInventory this customer was
    // when this inventory report was created (remember, it can change over
    // time--one day a Brewer might not have any CONBRWs it needs to report
    // inventory for, so it's a
    // CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
    // and the next day, BAM, it gets itself a Contract Brewer and changes to a
    // CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS).
    // The result is e.g.:
    // [
    //   {
    //   countDate: 1567277112000,
    //   [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]:
    //     CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
    //   [INVENTORY_REPORT_OBJS]: the data structure containing inventory
    //       report objects as created by the
    //       returnFuncToGatherInventoryReportObjsByCustomerType() return func
    //       from /features//reportInventory/util/inventoryReportObjs/interface.js
    //   },
    //   {
    //   countDate: 1564598712000,
    //   [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]:
    //     CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
    //   [INVENTORY_REPORT_OBJS]: the data structure containing inventory
    //       report objects as created by the
    //       returnFuncToGatherInventoryReportObjsByCustomerType() return func
    //       from /features//reportInventory/util/inventoryReportObjs/interface.js
    //   },
    // ]
    mapFp_(o => determineCustomerTypeAndCreateInventoryReportObjs(state, o, customerId)),
    // add the customerName to every object
    mapFp_(o => ({
      ...o,
      customerName: getCustomerName({
        entireCustomersSlice: getEntireCustomersSlice(state),
        customerId,
        ...o,
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
      }),
    })),
    // add the nameOfReportingPerson to every object
    mapFp_(o => ({
      ...o,
      nameOfReportingPerson: getNameOfReportingPerson({
        entireCurrentUserSlice: getEntireCurrentUserSlice(state),
        customerId,
        ...o,
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
      }),
    })),
  )(entireInventoryReportHistorySlice)
}

// The options object used by the connect() function of both the Table component
// and the DownloadTableAsFile component. We use the react-redux `options`
// feature here to tell React to only run this mapStateToProps function and only
// try to re-render the component if the inventory history parts of the Redux
// store change. We use this `options` feature only sparingly throughout the app
// because it goes against the default Redux practice of always running every
// mapStateToProps function throughout the entire app when any part of the store
// changes. When we keep to this default Redux practice, we ensure that the
// rendered output always reflects what's in the Redux store. However, in this
// case we need to break from this default practice because inventory reports
// are just too complex--we need the ability to create new objects in
// mapStateToProps (namely, groupingsOfInventoryReportObjsForHistoryTable) every
// time it runs. Consequently, every time this mapStateToProps function runs,
// the inventory history table re-renders. We don't want this re-rendering to
// happen every time some unrelated part of the Redux store changes (history
// tables comprise a lot of components and are therefore particularly expensive
// to re-render), so this `options` feature is necessary.
export const optionsObjForConnectFunction = {
  areStatesEqual: (state, oldState) => (
    getAreInventoryHistoryStatesEqual(state, oldState)
    // Why do we need this check? Because when a query has 0 results, we display
    // a 'No results' message in place of the results table. The next time the
    // user makes a query, this 'No results' message disappears, because if the
    // query takes a long time, we don't want to give the user the impression,
    // while the query is still fetching, that this new query has 0 results. If
    // we didn't include this check, that functionality of the disappearing 'No
    // results' message would break.
    && getAreInventoryReportHistoryFormFetchStatusStatesEqual(state, oldState)
  ),
}

/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

// Returns an array of customerIds and, potentially,
// brwCustomerId-conbrwCustomerId combinations as created by the
// createCustIdAndOptionalConbrwCustIdReduxIdentifier function.

// The Redux store for inventory report objects is an object of arrays, with
// each object key being either 1) a single customerId or 2) a
// brwCustomerId-conbrwCustomerId combination created by the
// createCustIdAndOptionalConbrwCustIdReduxIdentifier function. All inventory
// reports of the following types are saved using a single customerId as a key:

// * inventory reports of a BRW's default contract location (key the BRW's
//   customerId)
// * inventory reports of a CONBRW's CBMST location (key is the CONBRW's
//   customerId)
// * Inventory reports of a BRW's child pub (key is the pub's customerId)
// * inventory reports of a DIST (key is the dist's customerId)

// The only type of inventory report that gets saved as a
// brwCustomerId-conbrwCustomerId combination are those of a BRW's contract
// brewing location.

// This function does not know, nor does it try to figure out, which keys
// _actually_ exist in the Redux inventory-report-objects slice that pertain to
// the customerId passed in. For example, if a BRW user is logged in and
// searches for inventory reports from 3 years ago, before the BRW had any child
// pubs, the Redux store will not contain any inventory report objects of the
// BRW's child PUBs, meaning the Redux inventory-report-objects slice will not
// have any child pub customerIds as keys. However, this function will still
// include child pub customerIds in the array it returns. It returns all
// _possible_ keys, not all actual keys.
function getAllPossibleInventoryReportReduxIdentifiersForThisCustomer(state, customerId) {
  const customerType = getCustomerProp(state, customerId, 'customerType')
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)

  if (customerType === CUSTOMER_TYPES_BREWER) {
    const childPubIds = getChildPubIds(state, customerId)
    const conbrwRelationships = getAllBrw2ConbrwRelationshipsOfBrewer({
      entireCustomersSlice,
      entireRelationshipsSlice,
      customerId,
    })
    const conbrwCustomerIds = conbrwRelationships.map(o => o.destinationCustomerId)
    const brwConbrwReduxIdentifiers = conbrwCustomerIds.map(conbrwCustomerId => (
      createCustIdAndOptionalConbrwCustIdReduxIdentifier( // CODE_COMMENTS_212
        customerId,
        conbrwCustomerId,
      )
    ))
    return [
      customerId,
      ...childPubIds,
      ...brwConbrwReduxIdentifiers,
    ]
  } else if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    const contracteeBrewerCustomerIds = getWhichContracteesCanConbrwFetchInventoryReportHistoryFor({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      conbrwCustomerId: customerId,
    })
    const brwConbrwReduxIdentifiers = contracteeBrewerCustomerIds.map(brwCustomerId => (
      createCustIdAndOptionalConbrwCustIdReduxIdentifier( // CODE_COMMENTS_212
        brwCustomerId,
        customerId,
      )
    ))
    return [
      customerId,
      ...brwConbrwReduxIdentifiers,
    ]
  }

  // must be a distributor
  return [customerId]
}


// See the function that calls this one for details on what this does
function determineCustomerTypeAndCreateInventoryReportObjs(
  state,
  countDateAndInventoryReportObjsWithReduxIdentifiers,
  customerId,
) {
  const customerType = getCustomerProp(state, customerId, 'customerType')

  const entireInventoryHistorySlice = getEntireInventoryReportHistorySlice(state)
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireAddressesSlice = getEntireAddressesSlice(state)
  const entireCustomerAddressLinksSlice = getEntireCustomerAddressLinksSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)

  const customerTypeForReportInventory = determineCustomerTypeForEditInventoryReport(
    countDateAndInventoryReportObjsWithReduxIdentifiers,
    customerId,
    customerType,
  )

  const { countDate } = countDateAndInventoryReportObjsWithReduxIdentifiers
  const inventoryReportObjs = returnFuncToGatherInventoryReportObjsByCustomerType({
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
    isThisForDisplayingAnAlreadyCreatedInventoryReport: true,
  })({
    customerId,
    countDateAndInventoryReportObjsWithReduxIdentifiers,
    entireInventoryHistorySlice,
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
  })

  return {
    countDate,
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  }
}

// CODE_COMMENTS_213: used for things like editing existing inventory reports
// and displaying inventory reports full details.
function determineCustomerTypeForEditInventoryReport(
  countDateAndInventoryReportObjsWithReduxIdentifiers,
  customerId,
  customerType,
) {
  const { inventoryReportObjsAndReduxIdentifiers } = countDateAndInventoryReportObjsWithReduxIdentifiers
  const allReduxIdentifiersForThisCountDate = inventoryReportObjsAndReduxIdentifiers.map(o => o.reduxIdentifier)

  if (customerType === CUSTOMER_TYPES_BREWER) {
    // Brewers
    const reportedOnDefaultContract = allReduxIdentifiersForThisCountDate.includes(customerId)
    const reportedOnOneOrMoreContractBrewers = allReduxIdentifiersForThisCountDate.some(identifier => {
      const { contractBrewerCustomerId } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(identifier)
      return contractBrewerCustomerId
    })

    if (reportedOnDefaultContract && reportedOnOneOrMoreContractBrewers) {
      return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS
    }
    if (reportedOnDefaultContract && !reportedOnOneOrMoreContractBrewers) {
      return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS
    }
    if (!reportedOnDefaultContract && reportedOnOneOrMoreContractBrewers) {
      return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS
    }
    return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY
  } else if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    // Contract Brewers
    const reportedOnOneOrMoreContractees = allReduxIdentifiersForThisCountDate.some(identifier => {
      const { contractBrewerCustomerId } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(identifier)
      return contractBrewerCustomerId
    })

    if (reportedOnOneOrMoreContractees) {
      return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS
    }
    return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS
  }

  // Distributors
  return CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR
}


function getAreInventoryReportHistoryFormFetchStatusStatesEqual(state, oldState) {
  const fetchStatuses = getAllFormFetchStatuses(state)
  const oldFetchStatuses = getAllFormFetchStatuses(oldState)
  const inventoryHistoryFetchStatuses = pickBy_(fetchStatuses, (value, key) => key.startsWith(HISTORY_FORM_NAME_INVENTORY))
  const oldInventoryHistoryFetchStatuses = pickBy_(oldFetchStatuses, (value, key) => key.startsWith(HISTORY_FORM_NAME_INVENTORY))
  return keys_(inventoryHistoryFetchStatuses).every(key => (
    // yes, triple equals is what we want because we're comparing the identity
    // of the items in both objects. We don't care whether the items have the
    // same things in them, we care whether items with the same keys are
    // strictly equal. We can do this because our Redux store is immutable.
    inventoryHistoryFetchStatuses[key] === oldInventoryHistoryFetchStatuses[key]
  ))
}

function getAreInventoryHistoryStatesEqual(state, oldState) {
  const inventoryHistory = getEntireInventoryReportHistorySlice(state)
  const oldInventoryHistory = getEntireInventoryReportHistorySlice(oldState)
  return inventoryHistory === oldInventoryHistory
}
