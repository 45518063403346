import {
  isStringValidator,
  isNumberValidator,
  isBooleanValidator,
  isStringOrNumberValidator,
  isFalsyValidator,
  isArrayOfObjectsValidator,
} from './simpleValidators'

import {
  createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers,
  validatorCombinerWithOrLogic,
} from './util'

import {
  logObjectHasProblemsErrorMessage,
  CAN_STILL_BE_DISPLAYED,
  CANT_BE_DISPLAYED,
} from '../../../../utils/thirdPartyLogging'

import {
  isTruthyAndNonEmpty,
} from '../../../../utils'


export function validateAndFilterArrayOfCollarPlateObjects(httpResponse) {
  const {
    data,
  } = httpResponse
  if (isArrayOfObjectsValidator({ data }) !== true) {
    logObjectHasProblemsErrorMessage({
      objectType: 'array of collarPlateObjects',
      level: CANT_BE_DISPLAYED,
      additionalInfo: {
        details: "The response body of the call is not an array of objects, which it should always be. Therefore, this user won't be able to order collars or see any collar plate details in the collar order history",
        arrayOfProblemsWithData: ['response body is not an array of objects'],
        targetObject: data,
      },
      httpResponse,
    })
    return []
  }

  return data.reduce(
    (acc, collarPlateObj) => {
      const {
        validatedData,
        canDataBeSavedToStore,
      } = validateCollarPlateObject({
        data: collarPlateObj,
        httpResponse,
      })
      return canDataBeSavedToStore
        ? [...acc, validatedData]
        : acc
    },
    [],
  )
}

// `props` should be an object with at least one property: `data`
function validateCollarPlateObject(props) {
  const individualPropsValidatorsAndTransformers = [
    {
      propPath: 'dateApproved',
      validatorFn: validatorCombinerWithOrLogic(isFalsyValidator, isNumberValidator),
      doNotSaveApiResponseDataToStoreIfValidationFails: false,
    },
    {
      propPath: 'flavor',
      validatorFn: isStringValidator,
      doNotSaveApiResponseDataToStoreIfValidationFails: false,
    },
    {
      propPath: 'hidden',
      validatorFn: validatorCombinerWithOrLogic(isFalsyValidator, isBooleanValidator),
      doNotSaveApiResponseDataToStoreIfValidationFails: false,
    },
    {
      propPath: 'id',
      validatorFn: isStringOrNumberValidator,
      doNotSaveApiResponseDataToStoreIfValidationFails: true,
    },
    {
      propPath: 'numberOfColors',
      validatorFn: isNumberValidator,
      doNotSaveApiResponseDataToStoreIfValidationFails: false,
    },
    {
      propPath: 'partNumber',
      validatorFn: isStringOrNumberValidator,
      doNotSaveApiResponseDataToStoreIfValidationFails: false,
    },
  ]

  const results = createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
    ...props,
    individualPropsValidatorsAndTransformers,
  })

  const {
    arrayOfProblemsWithData,
    canDataBeSavedToStore,
  } = results


  const {
    data,
    httpResponse,
  } = props

  if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
    logObjectHasProblemsErrorMessage({
      objectType: 'collarPlateObject',
      level: canDataBeSavedToStore ? CAN_STILL_BE_DISPLAYED : CANT_BE_DISPLAYED,
      additionalInfo: {
        arrayOfProblemsWithData,
        details: canDataBeSavedToStore
          ? "Consequences to User: The visual display of this plate's info might be a little wonky (for example, if the 'numberOfColors' prop is the string 'blah' rather than a number, then 'blah' will be displayed)"
          : "Consequences to User: 1) the user won't be able to order this type of keg collar; 2) any history items the user views in the web app with this type of keg collar won't show any information on the collar plate (# colors, proof image, etc).",
        targetObject: data,
      },
      httpResponse,
    })
  }

  return results
}
