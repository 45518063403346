import moment from 'moment'

import isString_ from 'lodash/isString'
import uniq_ from 'lodash/uniq'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import uniqFp_ from 'lodash/fp/uniq'
import filterFp_ from 'lodash/fp/filter'
import valuesFp_ from 'lodash/fp/values'
import orderByFp_ from 'lodash/fp/orderBy'
import maxByFp_ from 'lodash/fp/maxBy'


import {
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'

import {
  getAllRelationshipObjectsBySourceAndDestCustomerIds,
} from './relationships/relatedToOrFromInfo'
import {
  getRelationshipObjectsOfAllRelatedTo,
} from './relationships/relatedToInfo'

import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_CONTRACTS as defaultSlice,

  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CONBRW,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  PPF_CONTRACT_TYPES_CBMST,
  PPF_CONTRACT_STATUS_ACTIVE,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
  convertApiDateToMoment,
} from '../../../utils'


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/

export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)


// Returns an array of contract objects sorted newest start date to oldest (or a
// single object if mostRecentOnly is true, but if mostRecentOnly is true, a
// future contract will never be returned). Returns an empty array if none found
// (or undefined if mostRecentOnly is true).
export function getContracts({
  entireContractsSlice,
  // these slices are only necessary if
  // filterConbrwContractsToOnlyThisConbrwCustomerId is passed in
  entireCustomersSlice,
  entireRelationshipsSlice,
  // if you want CONBRW contracts, pass in the contractee here, not the CONBRW.
  // If you pass in a CONBRW, you can only get the CBMST contract.
  customerId,
  // either an array or a string
  ppfContractTypes=[],
  filterConbrwContractsToOnlyThisConbrwCustomerId,
  // i.e. active (this also only returns active status contracts)
  notExpiredOnly=false, // CODE_COMMENTS_263: customers are allowed to report shipments on expired contracts
  activeStatusOnly=true,
  // if true, filters contracts whose start date is in the future
  noFutureContracts=true,
  // if true, returns only one contract object rather than an array. Does not
  // return future contracts.
  mostRecentOnly=false,
}) {
  // CODE_COMMENTS_262
  let conbrwContractIds = []
  if (filterConbrwContractsToOnlyThisConbrwCustomerId) {
    const brwConbrwRelationships = getAllRelationshipObjectsBySourceAndDestCustomerIds({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      sourceCustomerId: customerId,
      destinationCustomerId: filterConbrwContractsToOnlyThisConbrwCustomerId,
    })
    conbrwContractIds = flow_(
      mapFp_('sourcePpfContract'),
      uniqFp_,
    )(brwConbrwRelationships)
  }

  // eslint-disable-next-line no-param-reassign
  if (isString_(ppfContractTypes)) { ppfContractTypes = [ppfContractTypes] }
  return flow_([
    valuesFp_,
    filterFp_(o => o.customerId === customerId),
    ...(isTruthyAndNonEmpty(ppfContractTypes) ? [filterFp_(o => ppfContractTypes.includes(o.ppfContractType))] : []),
    ...(filterConbrwContractsToOnlyThisConbrwCustomerId
      ? [filterFp_(o => o.ppfContractType !== PPF_CONTRACT_TYPES_CONBRW || conbrwContractIds.includes(o.id))]
      : []
    ),
    ...(notExpiredOnly
      ? [filterFp_(o => !getIsContractExpired({ contract: o }) && o.contractStatusId === PPF_CONTRACT_STATUS_ACTIVE)]
      : []
    ),
    ...(activeStatusOnly ? [filterFp_(o => o.contractStatusId === PPF_CONTRACT_STATUS_ACTIVE)] : []),
    ...((noFutureContracts || mostRecentOnly) ? [filterFp_(o => getHasContractStarted({ contract: o }))] : []),
    orderByFp_('contractStartDate', 'desc'),
    ...(mostRecentOnly ? [maxByFp_('contractStartDate')] : []),
  ])(entireContractsSlice)
}

/*
 * *****************************************************************************
 * More Specific selectors
 * *****************************************************************************
*/

// The question really is, do pub to brw shipments get auto-invoiced _on the
// most recent contract_ (whose start date isn't in the future); we don't care
// about past contracts.
export function getDoPubToBrwShipmentsGetAutoInvoiced({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  brewerCustomerId,
  operatingContractBrewerCustomerId,
}) {
  const contract = getContracts({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId: brewerCustomerId,
    ppfContractTypes: operatingContractBrewerCustomerId ? PPF_CONTRACT_TYPES_CONBRW : PPF_CONTRACT_TYPES_BRW,
    filterConbrwContractsToOnlyThisConbrwCustomerId: operatingContractBrewerCustomerId,
    notExpiredOnly: false, // CODE_COMMENTS_263: customers are allowed to report shipments on expired contracts
    noFutureContracts: true,
    mostRecentOnly: true,
  })
  if (!contract) { return false }
  return contract.autoPubFeeOnReturn
}


// CODE_COMMENTS_262. This assumes that each CONBRW contract only has one
// Contract Brewer brewing for it, which happens to be true as of October, 2020,
// but may not always be true in the future, so use this function with caution.
export function getConbrwCustomerIdOfCONBRWContract({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  contract,
}) {
  const brewerCustomerId = contract.customerId
  const conbrwRelationshipObjs = getRelationshipObjectsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId: brewerCustomerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: false, // CODE_COMMENTS_263
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
    relationshipObjsCustomFilterFunc: o => o.sourcePpfContract === contract.id,
  })

  const conbrwCustomerIds = uniq_(conbrwRelationshipObjs.map(o => o.destinationCustomerId))
  return conbrwCustomerIds[0]
}


export function getHasContractThatCanHaveKegsOrderedOnIt({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId, // can be either BRW or CONBRW customerId
}) {
  return isTruthyAndNonEmpty(getContracts({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CBMST],
    notExpiredOnly: true, // Not allowed to order kegs on expired contracts
    noFutureContracts: true,
  }))
}


export function getContractsThatCanHaveShipmentsReportedOnThem({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
}) {
  return getContracts({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    notExpiredOnly: false,
    activeStatusOnly: true,
    noFutureContracts: false,
  })
}


export function getHasContractThatCanHaveShipmentsReportedOnIt({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId, // if CONBRW customerId, result means keg fills to a Brewer
}) {
  return isTruthyAndNonEmpty(getContractsThatCanHaveShipmentsReportedOnThem({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  }))
}


export function getIsContractExpired({
  contract,
}) {
  return convertApiDateToMoment(contract.contractEndDate).isBefore(moment())
}


export function getHasContractStarted({
  contract,
}) {
  return convertApiDateToMoment(contract.contractStartDate).isSameOrBefore(moment())
}
