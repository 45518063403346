import React from 'react'
import {
  Route,
  withRouter,
} from 'react-router-dom'


const PublicRoute = ({
  render,
  component: Component,
  ...rest
}) => {
  if (Component) {
    return (
      <Route
        {...rest}
        render={routeProps => (<Component {...routeProps} />)}
      />
    )
  }
  return (
    <Route
      {...rest}
      render={routeProps => (render(routeProps))}
    />
  )
}

export default withRouter(PublicRoute)
