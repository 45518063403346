/**
 * CODE_COMMENTS_131
 */
import { call, put } from 'redux-saga/effects'


import createAction from '../../actions/createAction'

import {
  SET_CATASTROPHIC_FAILURE_HAPPENED,
} from '../../actions/actionTypes'

export function setCatastrophicFailureFlagIfSagaExperiencesUnhandledError(saga) {
  return function* replacementSagaToHandleCatastrophicFailureError(action) {
    let returnValue
    try {
      returnValue = yield call(saga, action)
    } catch (error) {
      yield put(createAction(SET_CATASTROPHIC_FAILURE_HAPPENED, { error }))
    }
    return returnValue
  }
}
