// CODE_COMMENTS_189

import intersection_ from 'lodash/intersection'

import {
  getContracts,
} from '../../contracts'
import {
  getIsRelatedToAnyone,
  getCustomerObjectsOfAllRelatedTo,
} from '../../relationships/relatedToInfo'
import {
  getAllChildCustomerObjectsOfCustomer,
} from '../../children'
import {
  getHasPermissionsToPerformFunctionality,
} from '../../permissions'
import {
  getDoesBrewerHaveCustomerLevelPermission,
  getDoesContractBrewerHaveCustomerLevelPermission,
} from '../../permissionsAtTheCustomerLevel'
import {
  getWhichContracteesOfALoggedInConbrwIsTheConbrwResponsibleForReportingKegFillsFor,
  getWhichConbrwsOfALoggedInBrwIsTheBrwResponsibleForReportingKegFillsFor,
} from '../../contractBrewersAndContracteesSpecialSelectors'


import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_SHIPMENTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_KEG_FILLS,
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS,
} from '../../../../../constants/permissions'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_PUB,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CONBRW,
} from '../../../../../constants'

import {
  getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportOutboundShipmentsTo,
  getCustomerTypesABrewerTargetingAContractBrewingContractCanReportOutboundShipmentsTo,

  getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportBuybackShipmentsTo,
  getCustomerTypesABrewerTargetingAContractBrewingContractCanReportBuybackShipmentsTo,

  getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsTo,
  getCustomerTypesABrewerTargetingAContractBrewingContractCanReportSelfDistAndPubShipmentsTo,

  getCustomerTypesABrwOrConbrwCanReportSelfCollectionShipmentsFrom,
} from '../../../../../features/ReportShipments/util'

import {
  isTruthyAndNonEmpty,
} from '../../../../../utils'

/*
 * *****************************************************************************
 * The exported functions
 * *****************************************************************************
*/

// CODE_COMMENTS_189
export function getShouldReportOutboundShipmentsFormBeRendered({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId, // only pass in if CB operating for a contractee
}) {
  return getShouldReportShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    operatingContractBrewerCustomerId,
    defaultBrwContractCustomerTypesToShipTo:
      getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportOutboundShipmentsTo(),
    conbrwContractCustomerTypesToShipTo:
      getCustomerTypesABrewerTargetingAContractBrewingContractCanReportOutboundShipmentsTo(),
    isOutboundShipmentForm: true,
  })
}


export function getShouldReportBuybackShipmentsFormBeRendered({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId, // only pass in if CB operating for a contractee
}) {
  return getShouldReportShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    operatingContractBrewerCustomerId,
    defaultBrwContractCustomerTypesToShipTo:
      getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportBuybackShipmentsTo(),
    conbrwContractCustomerTypesToShipTo:
      getCustomerTypesABrewerTargetingAContractBrewingContractCanReportBuybackShipmentsTo(),
  })
}


export function getShouldReportSelfDistAndPubShipmentsFormBeRendered({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId, // only pass in if CB operating for a contractee
}) {
  const { taproomFixedFee = null } = getContracts({
    entireContractsSlice,
    entireCustomersSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CONBRW],
    notExpiredOnly: false,
    activeStatusOnly: true,
    noFutureContracts: true,
    mostRecentOnly: true,
  }) || {}

  const activePubs = getCustomerObjectsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_PUB,
  })
  const activeSelfDist = getCustomerObjectsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_SELF_DISTRIBUTION,
  })

  //  no SD or PUB in relationships (OR)
  if ((!activePubs?.length && !activeSelfDist?.length) ||
    // fixed fee with a pub relationship but no SD  in relationships
    ((taproomFixedFee && activePubs?.length) && !activeSelfDist?.length)) {
    return false
  }
  return getShouldReportShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    operatingContractBrewerCustomerId,
    defaultBrwContractCustomerTypesToShipTo:
      getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsTo(),
    conbrwContractCustomerTypesToShipTo:
      getCustomerTypesABrewerTargetingAContractBrewingContractCanReportSelfDistAndPubShipmentsTo(),
  })
}


export function getShouldReportKegFillsFormBeRendered({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
}) {
  const hasReportKegFillsPermissions = getHasPermissionsToPerformFunctionality({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_KEG_FILLS,
  })

  if (!hasReportKegFillsPermissions) {
    return false
  }

  const customerType = entireCustomersSlice[customerId].customerType

  let customersThisCustIsResponsibleForReportingKegFillsFor
  if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    customersThisCustIsResponsibleForReportingKegFillsFor =
      getWhichContracteesOfALoggedInConbrwIsTheConbrwResponsibleForReportingKegFillsFor({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        entirePermissionsSlice,
        entireCurrentUserSlice,
        conbrwCustomerId: customerId,
      })
  } else {
    customersThisCustIsResponsibleForReportingKegFillsFor =
      getWhichConbrwsOfALoggedInBrwIsTheBrwResponsibleForReportingKegFillsFor({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        brwCustomerId: customerId,
      })
  }

  return isTruthyAndNonEmpty(customersThisCustIsResponsibleForReportingKegFillsFor)
}


export function getShouldReportSelfCollectionShipmentsFormBeRendered({
  entireCustomersSlice,
  entireContractsSlice,
  entireParentChildLinksSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
}) {
  const customerType = entireCustomersSlice[customerId].customerType
  if (![
    CUSTOMER_TYPES_BREWER,
    CUSTOMER_TYPES_CONTRACT_BREWER,
  ].includes(customerType)) {
    return false
  }

  const children = getAllChildCustomerObjectsOfCustomer({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId,
    onlyCustomersWhoAreNotCurrentlyInactive: true, // CODE_COMMENTS_112, CODE_COMMENTS_159
  })
  if (children.length === 0) {
    return false
  }
  if (
    intersection_(
      children.map(o => o.customerType),
      getCustomerTypesABrwOrConbrwCanReportSelfCollectionShipmentsFrom(),
    ).length === 0
  ) {
    return false
  }
  const doesBrewerHavePermissionsToReportSelfCollectionShipments = getHasPermissionsToPerformFunctionality({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_SHIPMENTS,
  })
  const doesContractBrewerHavePermissionsToReportSelfCollectionShipments = getHasPermissionsToPerformFunctionality({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_KEG_FILLS,
  })
  if (
    customerType === CUSTOMER_TYPES_BREWER
    && !doesBrewerHavePermissionsToReportSelfCollectionShipments
  ) {
    return false
  }
  if (
    customerType === CUSTOMER_TYPES_CONTRACT_BREWER
    && !doesContractBrewerHavePermissionsToReportSelfCollectionShipments
  ) {
    return false
  }

  const hasAnyBrwContractThatCanHaveShipmentsReportedOnIt = isTruthyAndNonEmpty(getContracts({
    entireContractsSlice,
    entireCustomersSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW],
    notExpiredOnly: false, // CODE_COMMENTS_263: customers are allowed to report shipments on expired contracts
    activeStatusOnly: true,
    noFutureContracts: true,
  }))
  const { canDoCollectorReuse=null } = getContracts({
    entireContractsSlice,
    entireCustomersSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CONBRW],
    notExpiredOnly: false,
    activeStatusOnly: true,
    noFutureContracts: true,
    mostRecentOnly: true,
  })

  if (!canDoCollectorReuse) {
    return false
  }

  return (
    (customerType === CUSTOMER_TYPES_BREWER && hasAnyBrwContractThatCanHaveShipmentsReportedOnIt)
    ||
    // Contract Brewer customers have no contracts, only Brewers do (yes, it's
    // Brewers who have PPF_CONTRACT_TYPES_CONBRW contracts [as well as
    // PPF_CONTRACT_TYPES_BRW contracts], not Contract Brewers). However,
    // Contract Brewers can still report self-collection shipments even if they
    // have no contract on which to report it (due to a backend exception).
    (customerType === CUSTOMER_TYPES_CONTRACT_BREWER)
  )
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

/*
 * The main workhorse functions
 * ****************************
*/

// CODE_COMMENTS_189
function getShouldReportShipmentsFormBeRendered({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId, // only pass in if CB operating for a contractee
  defaultBrwContractCustomerTypesToShipTo,
  conbrwContractCustomerTypesToShipTo,
  isOutboundShipmentForm,
}) {
  if (operatingContractBrewerCustomerId) {
    return getShouldReportShipmentsFormBeRenderedForCbOperatingAsContractee({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      customerId,
      operatingContractBrewerCustomerId,
      conbrwContractCustomerTypesToShipTo,
      isOutboundShipmentForm,
    })
  }

  return getShouldReportShipmentsFormBeRenderedForNonContracteeBrewer({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    defaultBrwContractCustomerTypesToShipTo,
    conbrwContractCustomerTypesToShipTo,
    isOutboundShipmentForm,
  })
}


function getShouldReportShipmentsFormBeRenderedForNonContracteeBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  defaultBrwContractCustomerTypesToShipTo,
  conbrwContractCustomerTypesToShipTo,
  isOutboundShipmentForm,
}) {
  if (!getHasPermissionsToPerformFunctionality({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_SHIPMENTS,
  })) { return false }

  const hasAnyBrwContractThatCanHaveShipmentsReportedOnIt = isTruthyAndNonEmpty(getContracts({
    entireContractsSlice,
    entireCustomersSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW],
    notExpiredOnly: false, // CODE_COMMENTS_263: customers are allowed to report shipments on expired contracts
    activeStatusOnly: true,
    noFutureContracts: true,
  }))
  if (hasAnyBrwContractThatCanHaveShipmentsReportedOnIt) {
    return true // CODE_COMMENTS_28
  }

  const isDefaultBrewingPpfRelatedToAnyoneToShipTo = getIsRelatedToAnyone({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_159
    filterByPpfContract: { ppfContractType: PPF_CONTRACT_TYPES_BRW },
    customerObjsCustomFilterFunc: o => (
      defaultBrwContractCustomerTypesToShipTo.includes(
        o.customerType,
      )
    ),
  })

  const conbrwsRelatedTo = getCustomerObjectsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_159
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
  })

  const conbrwIdsRelatedTo = conbrwsRelatedTo.map(o => o.id)

  const canShipFromAnyConbrwLocations = conbrwIdsRelatedTo.some(conbrwCustomerId => {
    const doesBrewerHaveCustomerLevelPermissionsToReportShipmentsOnThisConbrwPpfContract =
      getDoesBrewerHaveCustomerLevelPermission({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        brwCustomerId: customerId,
        conbrwCustomerId,
        permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS,
      })
    const isContracteeRelatedToAnyoneThatItCanReportShipmentsToOnItsConbrwPpfContract =
      getIsContracteeRelatedToAnyoneThatItCanReportShipmentsToOnItsConbrwPpfContract({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        brwCustomerId: customerId,
        conbrwCustomerId,
        customerTypesToShipTo: conbrwContractCustomerTypesToShipTo,
      })
    if (isOutboundShipmentForm) { // CODE_COMMENTS_28
      return doesBrewerHaveCustomerLevelPermissionsToReportShipmentsOnThisConbrwPpfContract
    }
    return (
      doesBrewerHaveCustomerLevelPermissionsToReportShipmentsOnThisConbrwPpfContract
      && isContracteeRelatedToAnyoneThatItCanReportShipmentsToOnItsConbrwPpfContract
    )
  })


  if (
    isDefaultBrewingPpfRelatedToAnyoneToShipTo
    || canShipFromAnyConbrwLocations
  ) {
    return true
  }

  // CODE_COMMENTS_231
  if (
    isOutboundShipmentForm
  ) {
    if (
      isTruthyAndNonEmpty(conbrwsRelatedTo)
    ) {
      const conbrwNamesRelatedTo = conbrwsRelatedTo.map(o => o.name)
      const whyDisabledMessage = conbrwNamesRelatedTo.length > 1
        ? 'Your contract brewers are responsible for reporting outbound shipments.'
        : `${conbrwNamesRelatedTo[0]} is responsible for reporting outbound shipments.`
      return {
        shouldOptionBeDisabled: true,
        whyDisabledMessage,
      }
    }
    // If a BRW only has default Brewing contracts, none of which are Active,
    // don't render the Report Outbound Shipments form. See
    // https://microstartap3.atlassian.net/browse/TP3-3916 for details.
    return false
  }
  return false
}


function getShouldReportShipmentsFormBeRenderedForCbOperatingAsContractee({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId,
  conbrwContractCustomerTypesToShipTo,
  isOutboundShipmentForm,
}) {
  if (!getHasPermissionsToPerformFunctionality({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_SHIPMENTS,
    customerIdIfThisIsAPerCustomerPermissionsCheck: customerId,
  })) { return false }


  const doesConbrwHaveCustomerLevelPermissionsToReportShipmentsOnThisConbrwPpfContract =
    getDoesContractBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId: customerId,
      conbrwCustomerId: operatingContractBrewerCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS,
    })
  const isContracteeRelatedToAnyoneThatItCanReportShipmentsToOnItsConbrwPpfContract =
    getIsContracteeRelatedToAnyoneThatItCanReportShipmentsToOnItsConbrwPpfContract({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId: customerId,
      conbrwCustomerId: operatingContractBrewerCustomerId,
      customerTypesToShipTo: conbrwContractCustomerTypesToShipTo,
    })

  if (!doesConbrwHaveCustomerLevelPermissionsToReportShipmentsOnThisConbrwPpfContract) {
    if (isOutboundShipmentForm) {
      // CODE_COMMENTS_231
      const contracteeName = entireCustomersSlice[customerId].name
      return {
        shouldOptionBeDisabled: true,
        whyDisabledMessage: `${contracteeName} is responsible for reporting outbound shipments.`,
      }
    }
    return false
  }

  // CODE_COMMENTS_28
  if (isOutboundShipmentForm) {
    return true
  }
  return isContracteeRelatedToAnyoneThatItCanReportShipmentsToOnItsConbrwPpfContract
}


/*
 * Helpers for the workhorse functions
 * ***********************************
*/

function getIsContracteeRelatedToAnyoneThatItCanReportShipmentsToOnItsConbrwPpfContract({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  brwCustomerId,
  conbrwCustomerId,
  customerTypesToShipTo,
}) {
  return getIsRelatedToAnyone({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId: brwCustomerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_159
    filterByPpfContract: {
      ppfContractType: PPF_CONTRACT_TYPES_CONBRW,
      conbrwCustomerId,
    },
    customerObjsCustomFilterFunc: o => customerTypesToShipTo.includes(o.customerType),
  })
}
