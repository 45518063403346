import {
  SET_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED,
} from '../../actions/actionTypes'


const initialState = false

export default function shouldNotificationOfUpcomingMaintenanceBeDisplayed(state=initialState, action) {
  switch (action.type) {
    case SET_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED: {
      return action.payload
    }

    default:
      return state
  }
}
