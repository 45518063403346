import React from 'react'
import { connect } from 'react-redux'

import DismissableMessage from '../../../common-components/DismissableMessage'

import {
  FETCH_STATUSES_PUBLIC_FORMS_CLEAR,
} from '../../../redux/actions/actionTypes'

import createAction from '../../../redux/actions/createAction'

export default connect()(
  ({
    dispatch, // we get access to this because the component is wrapped in connect()
    formName,
    ...rest
  }) => (
    <DismissableMessage
      style={{ marginBottom: '15px' }}
      error
      onDismiss={() => {
        dispatch(createAction(FETCH_STATUSES_PUBLIC_FORMS_CLEAR, { target: [formName] }))
      }}
      {...rest}
    />
  ),
)
