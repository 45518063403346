import {
  SAVE_CUSTOMERS,
  SAVE_CUSTOMER,
} from '../../actions/actionTypes'

import reducerCreator from './higherOrderEntityReducers/basicReducerCreator'

export default reducerCreator([
  SAVE_CUSTOMERS,
  SAVE_CUSTOMER,
])
