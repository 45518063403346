import {
  SAVE_CONTACTS,
  SAVE_CONTACT,
} from '../../actions/actionTypes'

import reducerCreator from './higherOrderEntityReducers/basicReducerCreator'

export default reducerCreator([
  SAVE_CONTACTS,
  SAVE_CONTACT,
])
