import React from 'react'
import { withRouter, Route, Redirect } from 'react-router-dom'

import withIsAuthenticated from '../../HOC/redux/withIsAuthenticated'
import {
  URL_PATHS,
} from '../../../constants'

// CODE_COMMENTS_19
export default withIsAuthenticated(withRouter(
  ({ component: Component, render, isAuthenticated, ...rest }) => {
    if (Component) {
      return (
        <Route
          {...rest}
          render={routeProps => {
            if (!isAuthenticated) { return (<RedirectToLoginPage from={routeProps.location} />) }
            return (<Component {...routeProps} />)
          }}
        />
      )
    }
    // if render
    return (
      <Route
        {...rest}
        render={routeProps => {
          if (!isAuthenticated) { return (<RedirectToLoginPage from={routeProps.location} />) }
          return render(routeProps)
        }}
      />
    )
  },
))

const RedirectToLoginPage = ({ from }) => (
  <Redirect to={{
    pathname: URL_PATHS.login.path,
    state: { from },
  }}
  />
)
