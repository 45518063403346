import { connect } from 'react-redux'

import IndividualCard from '../components/IndividualCard'
import fetchStatusSelectors from '../../../redux/selectors/fetchStatuses/customers/preliminaryInfo'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../utils'


const { getFetchStatuses } = fetchStatusSelectors

const mapStateToProps = (state, props) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = props
  const {
    hasFetchBeenAttempted, isFetching, didFetchFail,
  } = getFetchStatuses(
    state,
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  return {
    hasPrelimInfoFetchBeenAttempted: hasFetchBeenAttempted,
    isPrelimInfoFetching: isFetching,
    didPrelimInfoFetchFail: didFetchFail,
    ...props,
  }
}

export default connect(
  mapStateToProps,
)(IndividualCard)
