import React from 'react'
import { Card } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import DisplayedAddress from '../../../common-components/DisplayedAddress'

import InformationalPopup from '../../../common-components/InformationalPopup'


export default ({
  address,
  headerText,
  informationalPopupText,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Card>
      <Card.Content className="centered-content">
        {headerText &&
          <Card.Meta>
            {headerText}
            {informationalPopupText &&
              <InformationalPopup
                iconName="question"
                content={informationalPopupText}
              />
            }
          </Card.Meta>
        }
        <Card.Description>
          {
            address
              ? <DisplayedAddress
                address={address}
                slightlyLargerText
              />
              : `[${translate('no address on file')}]`
          }
        </Card.Description>
      </Card.Content>
    </Card>
  )
}
