/* eslint-disable no-restricted-syntax */ // CODE_COMMENTS_50

import { takeEvery, call, put, select } from 'redux-saga/effects'


import {
  fetchPrelimInfoOfAnyCustomerTypeBesidesContracteeBrewer,
} from './fetchPrelimInfoByCustomerType/anyCustomerTypeBesidesContracteeBrewer'

import {
  fetchPrelimInfoOfContracteeBrewer,
  fetchPrelimInfoOfAllContracteeBrewersIfCustomerPassedInIsAContractBrewer,
} from './fetchPrelimInfoByCustomerType/contracteeBrewer'

import {
  cancelableForkedSaga,
} from '../../util/cancellableSagas'

import {
  getProp as getCurrentUserProp,
} from '../../../selectors/currentUser'
import {
  getIdsOfNonInactiveAndNonSelfDistAndPubChildren,
  getIsCustomerOfATypeThatCanHavePrelimInfoFetchedOnIt,
} from '../../../selectors/customers'

import prelimInfoFetchStatusesSelectors from '../../../selectors/fetchStatuses/customers/preliminaryInfo'

import {
  FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS,
  FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER,
  START_FETCH_CUSTOMER_REPRESENTATIVE_QUEUE_THREAD,
  FETCH_ITEM_SKUS,
  FETCH_SUBSIDIARIES,
  LOGOUT,
  AUTHENTICATION_FAILURE,
} from '../../../actions/actionTypes'

import createAction from '../../../actions/createAction'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../utils'


const { getFetchStatuses: getPrelimInfoFetchStatuses } = prelimInfoFetchStatusesSelectors

/*
 * *****************************************************************************
 * Exported Functions
 * *****************************************************************************
*/


// This is the function that should be called immediately after either:

// * a customer user logs in
// * an employee user chooses which customer user they want to operate as

// It's the exact same thing as fetchPrelimInfoOfCustomerAndOfAllChildren except
// for 2 things: 1) it knows to target the current user customer (and its
// children) rather than needing a customerId passed in; 2) it performs any
// setup that needs to happen immediately after login (basically just setting
// some flags letting the UI know that fetches are starting).
export function* fetchPrelimInfoOfAllCustomers() {
  yield put(createAction(START_FETCH_CUSTOMER_REPRESENTATIVE_QUEUE_THREAD, {}))
  yield put(createAction(FETCH_ITEM_SKUS, { preventsDashboardFromLoadingIfFails: true }))
  yield put(createAction(FETCH_SUBSIDIARIES, { preventsDashboardFromLoadingIfFails: true }))

  const currentUserCustomerId = yield select(getCurrentUserProp, 'rootCustomerId')
  yield call(
    // eslint-disable-next-line no-use-before-define
    fetchPrelimInfoOfCustomerAndOfAllChildren,
    { payload: { customerId: currentUserCustomerId } },
  )
}

/**
 * Fetches the preliminary info (customer, children, addresses, etc.) of a
 * customer and all the customer's children. You can either pass in the
 * customerId of the "current user" to kick things off in the logged-in portion
 * of the app, or pass in the customerId of a different customer to fetch prelim
 * info for that single customer (and its children).
 */
export const fetchPrelimInfoOfCustomerAndOfAllChildren = cancelableForkedSaga([LOGOUT, AUTHENTICATION_FAILURE])(
  function* fetchPrelimInfoOfCustomerAndOfAllChildrenWrappedSaga(action) {
    const {
      customerId,
      operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    } = action.payload
    if (operatingContractBrewerCustomerId) {
      yield call(
        fetchPrelimInfoOfContracteeBrewer,
        customerId,
        operatingContractBrewerCustomerId,
      )
      return
    }
    yield call(fetchPrelimInfoOfAnyCustomerTypeBesidesContracteeBrewer, customerId)
    // find out if the saga we just called succeeded or failed. If it failed,
    // don't try to fetch the customer's children
    const prelimInfoStatus = yield select(
      getPrelimInfoFetchStatuses,
      // CODE_COMMENTS_196
      createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        customerId,
        operatingContractBrewerCustomerId,
      ),
    )
    if (prelimInfoStatus.didFetchFail) { return }

    // CODE_COMMENTS_91
    yield call(fetchPrelimInfoOfAllContracteeBrewersIfCustomerPassedInIsAContractBrewer, customerId)

    const children = yield select(getIdsOfNonInactiveAndNonSelfDistAndPubChildren, customerId)
    // Fetch Children Sequentially
    for (const childCustomerId of children) { // CODE_COMMENTS_50
      // The following conditional is necessary because some children are pubs,
      // which do not need to have any prelim info fetched on them. But see
      // CODE_COMMENTS_49. It's also necessary in case any of a customer's
      // children are inactive (INA), in which case we don't want to fetch
      // prelim info for that child.
      if (yield select(getIsCustomerOfATypeThatCanHavePrelimInfoFetchedOnIt, customerId)) {
        yield call(fetchPrelimInfoOfAnyCustomerTypeBesidesContracteeBrewer, childCustomerId)
      }
    }
    for (const childCustomerId of children) { // CODE_COMMENTS_50, CODE_COMMENTS_91
      yield call(fetchPrelimInfoOfAllContracteeBrewersIfCustomerPassedInIsAContractBrewer, childCustomerId)
    }
  },
)


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS, fetchPrelimInfoOfAllCustomers],
  [takeEvery, FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER, fetchPrelimInfoOfCustomerAndOfAllChildren],
]
