/* eslint-disable max-len */

import React from 'react'
import { Container, Header, Message } from 'semantic-ui-react'


export default ({
  businessName,
  messageToDisplayInBanner,
}) => (
  <React.Fragment>
    { messageToDisplayInBanner.length >= 1 && messageToDisplayInBanner.map(message => (
      <Container>
        <Message warning>
          <Header as="h2">Message from {businessName}</Header>
          <p>{message}</p>
        </Message>
      </Container>
    ))}
  </React.Fragment>
  )
