import { compose } from 'recompose'

import withIsPrelimInfoFetched from '../../redux/withIsPrelimInfoFetched'
import withCustomerPrelimInfoFetchingOrFailed from '../../ui/withCustomerPrelimInfoFetchingOrFailed'

export default compose(
  // Order is very important here. We want to make sure that the Redux HOC comes
  // before the UI HOC because the UI HOC will be expecting props
  // ("isPrelimInfoFetching", "didPrelimInfoFetchFail") that the Redux HOC
  // provides.
  withIsPrelimInfoFetched,
  withCustomerPrelimInfoFetchingOrFailed,
)
