import React from 'react'
import { Modal, Grid, Loader } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import ModalWithCatastrophicFailureErrorBoundary from '../../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import TextAsPseudoLink from '../../../../../../../common-components/TextAsPseudoLink'

import ShipmentsSection from './containers/ShipmentsSection'

import {
  getInfoDefinitionsForKegOrderFullDetails,
} from '../../../../util/kegOrderHistory'

import HistoryLabelWithContainerTypesDifferencesPopupIfNecessary from '../../../../util/HistoryLabelWithContainerTypesDifferencesPopupIfNecessary'
import OrderAgain from '../../../../../../OrderKegs/OrderAndEditShared/components/orderAgain'


export default ({
  match,
  entireItemSkusSlice,
  info: orderDetails,
  customerId,
  itemSkuIdsToIncludeAsFieldsInTable,
  includeLocalKegsColumns,
  // In Dec 2022, we needed to extend this component in order to implement
  // https://microstartap3.atlassian.net/browse/TP3-5516 (see CODE_COMMENTS_283)
  // by showing a loading spinner in the popped-up modal while the order is
  // still being fetched.
  humanReadableOrderIdIfOrderNotFetchedYet,
  didFetchFail,
  componentToRenderIfFetchFailed: ComponentToRenderIfFetchFailed,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      closeIcon
      size="large"
      trigger={
        <TextAsPseudoLink>
          {orderDetails ? orderDetails.orderId : humanReadableOrderIdIfOrderNotFetchedYet}
        </TextAsPseudoLink>
      }
      // This line needed because when a user edits a keg order but the keg
      // order fetch has failed, they can click a button to re-try the keg
      // order fetch, at which point a <Loading> spinner is rendered. See
      // CODE_COMMENTS_72
      dimmer="inverted"
      className="test-full-order-details faux-inverted-dimmer"
    >
      <Modal.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{translate('Keg Order Details')}</span>
          <OrderAgain
            match={match}
            orderDetails={orderDetails}
          />
        </div>
      </Modal.Header>

      {orderDetails
        ? (
          <>
            <Modal.Content className="test-order-details-section">
              <AdditionalOrderDetails
                entireItemSkusSlice={entireItemSkusSlice}
                orderDetails={orderDetails}
                customerId={customerId}
                itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
                includeLocalKegsColumns={includeLocalKegsColumns}
              />
            </Modal.Content>

            <Modal.Header>{translate('Shipments')}</Modal.Header>
            <Modal.Content className="test-shipments-of-order-section">
              <ShipmentsSection
                customerId={customerId}
                orderDetails={orderDetails}
              />
            </Modal.Content>
          </>
        )
        : (
          <div
            style={{
              height: '20rem',
              // align items both vertically and horizontally
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {
              didFetchFail
                ? (
                  ComponentToRenderIfFetchFailed
                    ? <ComponentToRenderIfFetchFailed />
                    : <span style={{ color: '#9f3a38' }}>{translate('Failed to fetch order')}</span>
                )
                : <Loader size="big">{translate('Loading')}</Loader>
            }
          </div>
        )
      }
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}

const AdditionalOrderDetails = ({
  entireItemSkusSlice,
  orderDetails,
  customerId,
  itemSkuIdsToIncludeAsFieldsInTable,
  includeLocalKegsColumns,
}) => {
  const infoDefinitionsForKegOrderFullDetails = getInfoDefinitionsForKegOrderFullDetails({
    entireItemSkusSlice,
    itemSkuIdsToIncludeAsFieldsInTable,
    includeLocalKegsColumns,
  })

  return (
    // For alignment purposes, all displayed content within this grid needs to
    // be contained within a <Grid.Column> component; see CODE_COMMENTS_97 for
    // why.
    <Grid columns={2}>
      {infoDefinitionsForKegOrderFullDetails.map(definition => (
        <Grid.Row key={definition.heading} className="compact-grid-row">
          <Grid.Column width={3} style={{ fontWeight: 'bold' }}>
            <HistoryLabelWithContainerTypesDifferencesPopupIfNecessary
              customerId={customerId}
              labelText={definition.heading}
              colonAfterText
            />
          </Grid.Column>
          <Grid.Column width={13}>{definition.cellContent(orderDetails, { customerId })}</Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  )
}
