import { CONCATENATED_STRING_SEPARATOR } from '../../constants'

export const DISPLAYED_CONTACT_TYPE_SHIPPING = 'Shipping'
export const DISPLAYED_CONTACT_TYPE_BILLING = 'Billing'
export const DISPLAYED_CONTACT_TYPE_SHIPPING_AND_BILLING = 'Shipping & Billing'

export const DISPLAYED_CONTACT_TYPES = [
  DISPLAYED_CONTACT_TYPE_SHIPPING,
  DISPLAYED_CONTACT_TYPE_BILLING,
  DISPLAYED_CONTACT_TYPE_SHIPPING_AND_BILLING,
]


export const DISPLAYED_INFORMATIONAL_MESSAGE_CONTACT_TYPE = "We'll reach out to Shipping contacts if we have any inquiries concerning shipping, and Billing contacts for billing inquiries."
export const DISPLAYED_INFORMATIONAL_MESSAGE_DEFAULT_SHIPPING_CONTACT = "The Default Shipping Contact's name appears on BOLs, bad pallets paperwork and other shipping paperwork."
export const DISPLAYED_INFORMATIONAL_MESSAGE_RECEIVE_ORDER_UPDATES_EMAILS = 'Receive email updates regarding shipments related to an order.'
export const DISPLAYED_INFORMATIONAL_MESSAGE_RECEIVE_INVOICES_EMAILS = 'Receive invoices via email 5 business days after billing period ends.'
export const DISPLAYED_INFORMATIONAL_MESSAGE_RECEIVE_SHIPMENT_EMAILS = 'Receive emails of BOLs, bad pallets paperwork and other shipping paperwork.'
export const DISPLAYED_INFORMATIONAL_MESSAGE_RECEIVE_DIST_INV_REPORT_FORM = 'Regulary receive emails containing a link to report your empty keg quantities, no login required.'


export const EMAIL_SUBSCRIPTION_PROPS = [
  // Order is important here, it determines the order shown in the Contact table
  // and the Edit Contact form
  {
    name: 'receiveOrderUpdates',
    displayedName: 'Order Updates',
    explanation: DISPLAYED_INFORMATIONAL_MESSAGE_RECEIVE_ORDER_UPDATES_EMAILS,
  },
  {
    name: 'receiveInvoice',
    displayedName: 'Invoices',
    explanation: DISPLAYED_INFORMATIONAL_MESSAGE_RECEIVE_INVOICES_EMAILS,
  },
  {
    name: 'receiveShipments',
    displayedName: 'Shipments',
    explanation: DISPLAYED_INFORMATIONAL_MESSAGE_RECEIVE_SHIPMENT_EMAILS,
  },
  {
    name: 'receiveDistInvReportForm',
    displayedName: 'No-Login Empty Keg Report Form',
    explanation: DISPLAYED_INFORMATIONAL_MESSAGE_RECEIVE_DIST_INV_REPORT_FORM,
  },
]

export const createCustomerAndEmailSubscriptionIdentifier = (customerId, emailProp) => `${customerId}${CONCATENATED_STRING_SEPARATOR}${emailProp}`
