import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import ConfirmAccountForm from '../containers/ConfirmAccountForm'
import SuccessPage from '../../shared/SuccessPage'


export default ({
  hasConfirmAccountFormFetchSucceeded,
}) => {
  const { t: translate } = useTranslation('common')
  const [email, setEmail] = useState(null) // CODE_COMMENTS_251
  const [password, setPassword] = useState(null) // CODE_COMMENTS_251
  if (!hasConfirmAccountFormFetchSucceeded) {
    return (
      <ConfirmAccountForm
        setEmail={setEmail}
        setPassword={setPassword}
      />
    )
  }
  return (
    <SuccessPage
      message={translate('Your account has been confirmed.')}
      email={email}
      password={password}
    />
  )
}
