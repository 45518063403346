/**
 * CODE_COMMENTS_93
 */
import React from 'react'
import { Modal, Grid } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import ModalWithCatastrophicFailureErrorBoundary from '../../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import TextAsPseudoLink from '../../../../../../../common-components/TextAsPseudoLink'

import {
  getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
} from '../../../../util/shipmentHistory/outboundFullKegShipmentHistory'

import {
  createDisplayedShipmentNumber,
  createDisplayedDestinationName,
  createDisplayedShipmentDate,
  createDisplayedProNumber,
  createItemSkuIdFieldsDefinitions,
} from '../../../../util/shipmentHistory/common/displayedShipmentDataEtc'

import HistoryLabelWithContainerTypesDifferencesPopupIfNecessary from '../../../../util/HistoryLabelWithContainerTypesDifferencesPopupIfNecessary'


export default ({
  entireItemSkusSlice,
  customerId,
  info: shipmentDetails,
  itemSkuIdsToIncludeAsFieldsInTable,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      closeIcon
      size="large"
      trigger={<TextAsPseudoLink>{shipmentDetails.shipmentId}</TextAsPseudoLink>}
      className="test-full-keg-fill-report-details"
    >
      <Modal.Header>{translate('Keg Fill Report Details')}</Modal.Header>
      <Modal.Content>
        <AdditionalShipmentDetails
          entireItemSkusSlice={entireItemSkusSlice}
          customerId={customerId}
          shipmentDetails={shipmentDetails}
          itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
        />
      </Modal.Content>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}

const AdditionalShipmentDetails = ({
  entireItemSkusSlice,
  customerId,
  shipmentDetails,
  itemSkuIdsToIncludeAsFieldsInTable,
}) => {
  const toDisplay = [
    { heading: 'Fill #', cellContent: createDisplayedShipmentNumber },
    { heading: 'Your Reference #', cellContent: createDisplayedProNumber('none') },
    { heading: 'Filled For', cellContent: createDisplayedDestinationName },
    { heading: 'Fill Date', cellContent: createDisplayedShipmentDate },
    { heading: 'Status', cellContent: getDisplayedHumanReadableStatusOfOutboundFullkegShipment },
    ...createItemSkuIdFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
    }),
  ]
  return (
    // For alignment purposes, all displayed content within this grid needs to
    // be contained within a <Grid.Column> component; see CODE_COMMENTS_97 for
    // why.
    <Grid columns={2}>
      {toDisplay.map(definition => (
        <Grid.Row key={definition.heading} className="compact-grid-row">
          <Grid.Column width={3} style={{ fontWeight: 'bold' }}>
            <HistoryLabelWithContainerTypesDifferencesPopupIfNecessary
              customerId={customerId}
              labelText={definition.heading}
              colonAfterText
            />
          </Grid.Column>
          <Grid.Column width={13}>{definition.cellContent(shipmentDetails)}</Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  )
}
