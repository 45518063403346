/**
 * CODE_COMMENTS_160
 */
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import BreadcrumbLinks from './components/BreadcrumbLinks'

import {
  getNamesOfOperateeCustomersInUrlPath,
  createBreadcrumbsDefinitions,
  createBreadcrumbsDefinitionsEmployee,
} from './util'

import {
  getIsEmployeePath,
} from '../../../utils'


const mapStateToProps = (state, { currentPath, t: translate = a => a }) => {
  let breadcrumbDefinitions

  if (getIsEmployeePath(currentPath)) {
    breadcrumbDefinitions = createBreadcrumbsDefinitionsEmployee(
      currentPath,
    )
  } else {
    const namesofOperateeCustomers = getNamesOfOperateeCustomersInUrlPath(state, currentPath)
    breadcrumbDefinitions = createBreadcrumbsDefinitions(
      currentPath,
      namesofOperateeCustomers,
      translate,
    )
  }
  return {
    breadcrumbDefinitions,
  }
}

export default withTranslation()(connect(
  mapStateToProps,
)(BreadcrumbLinks))
