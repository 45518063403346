import React from 'react'

import { Card, Button } from 'semantic-ui-react'
import RevealFetchErrorDetails from '../../fetchErrorDetails/RevealFetchErrorDetails'

import RevealContactInfo from '../../RevealContactInfo'

import {
  CUSTOMER_REPS_HUMAN_READABLE_NAMES,
} from '../../../constants'


export default (repType, displayedErrorMsg, retryFetchCustomerRepresentative) => ({ errorDetails }) => (
  <Card color="red">
    <Card.Content>
      <Card.Header>
        <span className="microstar-blue">{CUSTOMER_REPS_HUMAN_READABLE_NAMES[repType]}</span>
      </Card.Header>
      <Card.Description>{displayedErrorMsg}</Card.Description>
    </Card.Content>
    <Card.Content extra>
      <Button color="blue" fluid onClick={retryFetchCustomerRepresentative}>
        <Button.Content visible>Refetch</Button.Content>
      </Button>
      <div style={{ textAlign: 'center' }}>
        <RevealContactInfo
          buttonTextWhenVisible="General Support Line"
          buttonTextWhenHidden="General Support Line"
          asPopup
        />
        <RevealFetchErrorDetails
          errorDetails={errorDetails}
          buttonTextWhenVisible="Error details"
          buttonTextWhenHidden="Error details"
          asPopup
        />
      </div>
    </Card.Content>
  </Card>
)
