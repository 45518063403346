import uniqBy_ from 'lodash/uniqBy'
import sortBy_ from 'lodash/sortBy'


import NoPubToBreweryNecessary from '../../NoPubToBreweryNecessary'

import {
  createCustomersFieldsDropdownOptionsForShipmentFormsBrewer,
  createDefaultBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer,
  createContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer,
  createSpecialSelfDist2CbmstFieldArrayDropdownOptionsForShipmentFormsBrewer,
} from './shared'

import {
  getMultipleCustomerPropsOfAllRelatedTo,
  getCustomerIdsOfAllRelatedTo,
} from '../../../../../../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getDoPubToBrwShipmentsGetAutoInvoiced,
} from '../../../../../../../redux/selectors/rewrite/contracts'

import {
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
} from '../../../../../../../constants/formAndApiUrlConfig/reportSelfDistAndPubShipments'

import {
  SOURCE_OR_DEST_CUSTOMER_FIELD_LABEL_CHOICE_DESTINATION,
  SOURCE_OR_DEST_CUSTOMER_FIELD_LABEL_CHOICE_SOURCE,

  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
} from '../../../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  CUSTOMER_TYPES_BREWER,
  PPF_CONTRACT_TYPES_BRW,
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../../../../../constants'

import {
  getCustomerTypesABrewerTargetingAContractBrewingContractCanReportSelfDistAndPubShipmentsTo,
  getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsTo,
  getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsFrom,
} from '../../../../../util'
import BannerMessage from '../../BannerMessage'


/*
 * *****************************************************************************
 * Report Self-dist/Pub Shipments
 * *****************************************************************************
*/

export function createFieldArraysAndCustomersFieldsDropdownOptionsForReportSelfDistAndPubShipments(props) {
  const { operatingContractBrewerCustomerId } = props
  if (operatingContractBrewerCustomerId) {
    return createFieldArraysDefinitionsForReportSelfDistAndPubShipmentsFormCbOperatingAsContracteeBrewer(props)
  }
  // regular old brewer.
  return createFieldArraysDefinitionsForReportSelfDistAndPubShipmentsFormNonContracteeBrewer(props)
}


function createFieldArraysDefinitionsForReportSelfDistAndPubShipmentsFormNonContracteeBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  operatingContractBrewerCustomerId,
  restrictPubs,
  banner,
}) {
  const dropdownOptionsForFullKegsTo = createCustomersFieldsDropdownOptionsForShipmentFormsBrewer({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsDefaultBrwPpf:
      getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsTo(restrictPubs),
    customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsConbrwPpf:
      getCustomerTypesABrewerTargetingAContractBrewingContractCanReportSelfDistAndPubShipmentsTo(restrictPubs),
  })

  // The Empty Kegs From section should not include any contract brewing
  // contract locations (because we haven't yet figured out a good way to ship
  // empty kegs to a conbrw-ppf brewer then to the CBMST-ppf conbrw [because
  // empty kegs can't stay at the conbrw-ppf brewer, they need to somehow move
  // back to the CBMST conbrw], but, per CODE_COMMENTS_279, there is one
  // exception: SELFDIST on a conbrw ppf contract back to a CONBRW's CBMST
  // location. Such shipments are a single shipment from SELFDIST to CBMST
  // Conbrw, they don't go through the conbrw-ppf brewer in between, which is
  // the only reason they're allowed.
  const dropdownOptionsForEmptyKegsFromDefaultBrwPpfContract =
    createDefaultBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond:
        getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsFrom({
          entireCustomersSlice,
          entireContractsSlice,
          entireRelationshipsSlice,
          customerId,
          operatingContractBrewerCustomerId,
        }),
    })
  const conbrwsRelatedTo = getCustomerIdsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true,
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
  })
  const dropdownOptionsForEmptyKegsFromSpecialSelfDist2Cbmst = conbrwsRelatedTo.reduce(
    (acc, conbrwCustomerId) => {
      const dropdownOptionsForThisBrwConbrwCombo =
        createSpecialSelfDist2CbmstFieldArrayDropdownOptionsForShipmentFormsBrewer({
          entireCustomersSlice,
          entireContractsSlice,
          entireRelationshipsSlice,
          customerId,
          conbrwCustomerId,
        })
      return [...acc, ...dropdownOptionsForThisBrwConbrwCombo]
    },
    [],
  )
  const dropdownOptionsForEmptyKegsFrom = [
    ...dropdownOptionsForEmptyKegsFromDefaultBrwPpfContract,
    ...dropdownOptionsForEmptyKegsFromSpecialSelfDist2Cbmst,
  ]


  const doPubToBrwShipmentsGetAutoInvoiced = getDoPubToBrwShipmentsGetAutoInvoiced({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    brewerCustomerId: customerId,
    operatingContractBrewerCustomerId,
  })

  return [
    {
      fieldArrayTitle: 'Full Keg Shipments To Self-Distribution and Own Pubs',
      fieldArrayName: REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
      customersFieldsDropdownOptions: dropdownOptionsForFullKegsTo,
      whichCustomersFieldMustBeChosenFirstSourceOrDestination:
        WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
      arbitraryContentBelowFieldArrayTitleAndAboveForm: Object.keys(banner || {})?.length > 0 && BannerMessage,
    },
    {
      fieldArrayTitle: `Empty Keg Shipments from Self-Distribution${doPubToBrwShipmentsGetAutoInvoiced ? '' : ' and Own Pubs'}`,
      fieldArrayName: REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
      customersFieldsDropdownOptions: dropdownOptionsForEmptyKegsFrom,
      whichCustomersFieldMustBeChosenFirstSourceOrDestination:
        WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
      arbitraryContentBelowFieldArrayTitleAndAboveForm: doPubToBrwShipmentsGetAutoInvoiced && NoPubToBreweryNecessary,
    },
  ]
}


function createFieldArraysDefinitionsForReportSelfDistAndPubShipmentsFormCbOperatingAsContracteeBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  operatingContractBrewerCustomerId,
  restrictPubs,
  banner,
}) {
  const dropdownOptionsForFullKegsTo =
    createContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      conbrwCustomerId: operatingContractBrewerCustomerId,
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond:
        getCustomerTypesABrewerTargetingAContractBrewingContractCanReportSelfDistAndPubShipmentsTo(restrictPubs),
      setBrewerOrContractBrewerAsName: CUSTOMER_TYPES_BREWER,
    })
  const dropdownOptionsForEmptyKegsFrom =
    createSpecialSelfDist2CbmstFieldArrayDropdownOptionsForShipmentFormsBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      conbrwCustomerId: operatingContractBrewerCustomerId,
    })

  return [
    {
      fieldArrayTitle: 'Full Keg Shipments To Self-Distribution and Own Pubs',
      fieldArrayName: REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
      customersFieldsDropdownOptions: dropdownOptionsForFullKegsTo,
      whichCustomersFieldMustBeChosenFirstSourceOrDestination:
        WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
      arbitraryContentBelowFieldArrayTitleAndAboveForm: Object.keys(banner || {})?.length > 0 && BannerMessage,
    },
    // CODE_COMMENTS_279: the Empty Kegs From section for conbrws operating on
    // behalf of contractees should not include any contract brewing contract
    // locations (for now, at least). However, it should include the CBMST
    // location for one specific type of shipment: the contractee's SELFDIST
    // (on a CONBRW ppf contract) directly back to the CBMST location.
    {
      fieldArrayTitle: 'Empty Keg Shipments from Self-Distribution',
      fieldArrayName: REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
      customersFieldsDropdownOptions: dropdownOptionsForEmptyKegsFrom,
      whichCustomersFieldMustBeChosenFirstSourceOrDestination:
        WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
    },
  ]
}


/*
 * *****************************************************************************
 * Edit Self-dist/Pub Shipments
 * *****************************************************************************
*/

export function createFieldArraysAndCustomersFieldsDropdownOptionsForEditSelfDistAndPubShipments({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  fieldArrayName,
}) {
  const dropdownOptions = getSourceOrDestOptionsForSelfDistAndPubShipmentsForm({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  })

  let customersFieldLabelChoice
  if (fieldArrayName === REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO) {
    customersFieldLabelChoice = SOURCE_OR_DEST_CUSTOMER_FIELD_LABEL_CHOICE_DESTINATION
  } else {
    customersFieldLabelChoice = SOURCE_OR_DEST_CUSTOMER_FIELD_LABEL_CHOICE_SOURCE
  }

  return [{
    fieldArrayName, // CODE_COMMENTS_58
    customersFieldsDropdownOptions: dropdownOptions,
    customersFieldLabelChoice,
  }]
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

// This should only be called for brewers who are not contractees: In pursuit of
// simplicity, the Dev team and Exec team have decided not to expose the "Report
// Self-Dist/Pub Shipments" form within the contractee Dashboard.
function getSourceOrDestOptionsForSelfDistAndPubShipmentsForm({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
}) {
  const options = getMultipleCustomerPropsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112, CODE_COMMENTS_159
    propNames: ['id', 'name', 'customerType'],
    filterByPpfContract: { ppfContractType: PPF_CONTRACT_TYPES_BRW },
    customerObjsCustomFilterFunc: o => (
      getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsTo().includes(
        o.customerType,
      )
    ),
  })

  // CODE_COMMENTS_159
  const optionsNoCustomerDuplicates = uniqBy_(options, 'id')
  const sortedOptions = sortBy_(optionsNoCustomerDuplicates, 'name')

  return sortedOptions
}
