import { capitalize } from 'lodash/string'
import {
  getProp as getCustomerProp,
} from '../../../redux/selectors/customers'

import {
  URL_PATHS,
} from '../../../constants'

import {
  removeTrailingSlashesFromUrlPath,
  getIsEmployeePath,
} from '../../../utils'

import {
  NestedPropNotInObjectError,
} from '../../../customErrors'

/*
 * *****************************************************************************
 * Constants for this file
 * *****************************************************************************
*/

// an object with the path strings as keys and labels as values. We set this to
// a constant because it makes the code a little faster rather than having to
// re-create this every time within the needed functions.
const validNonEmployeePaths = Object.keys(URL_PATHS).reduce(
  (acc, key) => {
    if (getIsEmployeePath(URL_PATHS[key].path)) { return acc }
    return {
      ...acc,
      [URL_PATHS[key].path]: URL_PATHS[key].breadcrumbLabel,
    }
  },
  {},
)

// an object with the path strings as keys and labels as values. We set this to
// a constant because it makes the code a little faster rather than having to
// re-create this every time within the needed functions.
const validEmployeePaths = Object.keys(URL_PATHS).reduce(
  (acc, key) => {
    if (!getIsEmployeePath(URL_PATHS[key].path)) { return acc }
    return {
      ...acc,
      [URL_PATHS[key].path]: URL_PATHS[key].breadcrumbLabel,
    }
  },
  {},
)


export const OPERATING_FOR_PREPEND_TEXT = 'Operating for'


/*
 * *****************************************************************************
 * Exported functions
 * *****************************************************************************
*/

// returns an object with customerIds as keys and customer names as values. Only
// includes customerIds whose customerInfo has been fetched and is in the store.
export function getNamesOfOperateeCustomersInUrlPath(state, path) {
  const allOperateeCustomerIdsFromUrlPath = getAllOperateeCustomerIdsFromUrlPath(path)
  return allOperateeCustomerIdsFromUrlPath.reduce(
    (acc, customerId) => {
      let name
      try {
        name = getCustomerProp(state, customerId, 'name')
      } catch (e) {
        if (e instanceof NestedPropNotInObjectError) {
          return acc
        }
        throw e
      }
      return {
        ...acc,
        [customerId]: name,
      }
    },
    {},
  )
}


function tryFiguringOut({
  pathPart,
}) {
  let label = '404'
  try {
    label = pathPart?.replaceAll('/', '')?.replaceAll('-', ' ')?.split(' ')?.map(e => capitalize(e))
      ?.join(' ') || '404'
  } catch (e) {
    // console.error('error -', e)
  }
  return label
}

// returns an array of objects, each with 'link' and 'label' props
export function createBreadcrumbsDefinitions(
  currentPath,
  namesofOperateForCustomers,
  translate,
) {
  // removing trailing slashes will prevent a bug where the trailing slashes
  // result in one additional "Home" breadcrum each, e.g. the path `/order-kegs/`
  // will result in the breadcrumbs `Home > Order Kegs > Home`
  const pathWithoutTrailingSlashes = removeTrailingSlashesFromUrlPath(currentPath)

  const pathParts = splitUrlPathIntoIndividualParts(pathWithoutTrailingSlashes)

  // e.g. user manually types in an invalid path in the URL bar
  let haveWeEncounteredAnInvalidPathPart = false

  return pathParts.reduce(
    (acc, pathPart, index) => {
      if (
        haveWeEncounteredAnInvalidPathPart ||
        // we'll add the breadcrumb on the next iteration when we get to the
        // customerId
        pathPart === URL_PATHS.operateFor.path
      ) { return acc }

      const wasPreviousPathPartOperateFor = (
        index !== 0 &&
        pathParts[index-1] === URL_PATHS.operateFor.path
      )

      let label
      if (wasPreviousPathPartOperateFor) {
        const operateeCustomerId = pathPart.substring(1) // remove leading '/'
        // Here, we either display "Operating for Twelve Percent LLC" or
        // "Operating for customer" depending on whether the operatee's
        // customerInfo has been successfully fetched and its `name` is in the
        // Redux store. We don't want to display "Operating for [the customerId
        // in the URL bar]" because 1) a long UUID is meaningless to the end
        // user; 2) an immature user could manually type /operate-for/ass into
        // the URL bar and it would be parroted in our breadcrumbs section.
        label = `${translate(OPERATING_FOR_PREPEND_TEXT)} ${namesofOperateForCustomers[operateeCustomerId] || 'customer'}`
      } else {
        const isPathPartValid = getIsPathValid(pathPart, validNonEmployeePaths)
        if (isPathPartValid) {
          label = validNonEmployeePaths[pathPart]
        } else {
          haveWeEncounteredAnInvalidPathPart = true
          label = tryFiguringOut({ pathPart })
          return [
            ...acc,
            {
              // CODE_COMMENTS_225
              link: assembleUrlPathPartsIntoPath(pathParts.slice(0, index+1)),
              label,
            },
          ]
        }
      }

      return [
        ...acc,
        {
          link: assembleUrlPathPartsIntoPath(pathParts.slice(0, index+1)),
          label,
        },
      ]
    },
    [],
  )
}


// returns an array of objects, each with 'link' and 'label' props. The
// algorithm for creating breadcrumbs for an /employee/... path is different
// than for other paths, hence this function.
export function createBreadcrumbsDefinitionsEmployee(
  currentPath,
) {
  // removing trailing slashes will prevent a bug where the trailing slashes
  // result in one additional "Home" breadcrum each, e.g. the path `/order-kegs/`
  // will result in the breadcrumbs `Home > Order Kegs > Home`
  const pathWithoutTrailingSlashes = removeTrailingSlashesFromUrlPath(currentPath)

  const pathParts = splitUrlPathIntoIndividualParts(pathWithoutTrailingSlashes).slice(1)

  // e.g. user manually types in an invalid path in the URL bar
  let haveWeEncounteredAnInvalidPathPart = false

  let runningReconstructedPath = ''
  const toReturn = []
  pathParts.forEach(
    pathPart => {
      if (haveWeEncounteredAnInvalidPathPart) { return }
      runningReconstructedPath+=pathPart
      const isPathValid = getIsPathValid(runningReconstructedPath, validEmployeePaths)
      if (isPathValid) {
        toReturn.push({
          link: runningReconstructedPath,
          label: validEmployeePaths[runningReconstructedPath],
        })
      } else {
        haveWeEncounteredAnInvalidPathPart = true
        toReturn.push({
          link: runningReconstructedPath, // CODE_COMMENTS_225
          label: '404',
        })
      }
    },
    {},
  )
  return toReturn
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

// splits a path into its individual parts and prepends each with a '/'. The
// first element is alway '/', e.g.:
//
// path='/operate-for/m1234/operate-for/m5678/report-keg-fills' -->
// [
//   '/',
//   '/operate-for',
//   '/m1234',
//   '/operate-for',
//   '/m5678',
//   '/report-keg-fills',
// ]
function splitUrlPathIntoIndividualParts(path) {
  const individualParts = path.split('/')
  return individualParts.map(part => `/${part}`)
}


function assembleUrlPathPartsIntoPath(pathParts) {
  // The only time this will happen is when pathParts = ['/']
  if (pathParts.length === 1) {
    return pathParts[0]
  }
  const pathPartsWithoutRoot = pathParts.slice(1)
  return pathPartsWithoutRoot.join('')
}


function getIsPathValid(path, validPaths) {
  return Boolean(validPaths[path])
}


function getAllOperateeCustomerIdsFromUrlPath(path) {
  const pathParts = splitUrlPathIntoIndividualParts(path)
  if (pathParts.length < 2) { return [] }
  return pathParts.reduce(
    (acc, pathPart, index) => {
      const wasPreviousPathPartOperateFor = (
        index !== 0 &&
        pathParts[index-1] === URL_PATHS.operateFor.path
      )
      if (!wasPreviousPathPartOperateFor) { return acc }
      // `pathPart[0] === '/'` should always be the case, but in the spirit of
      // defensive programming, we check anyway
      const operateeeCustomerId = pathPart[0] === '/'
        ? pathPart.substring(1) // remove leading '/'
        : pathPart
      return [...acc, operateeeCustomerId]
    },
    [],
  )
}
