import { connect } from 'react-redux'


import CatastrophicFailurePage from './components/CatastrophicFailurePage'

import { getIsAuthenticated } from '../../../../redux/selectors/authentication'

import {
  getIsEmployeeUserLoggedInRatherThanCustomerUser,
} from '../../../../redux/selectors/currentUserEmployee'
import {
  getProp as getCurrentUserProp,
  getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,
} from '../../../../redux/selectors/currentUser'


import {
  getCatastrophicFailureError,
  getCatastrophicFailureInfo,
} from '../../../../redux/selectors/catastrophicFailure'

import createAction from '../../../../redux/actions/createAction'
import {
  UNSET_CATASTROPHIC_FAILURE_HAPPENED,
  FETCH_PRELIM_CUSTOMER_INFO_CLEAR,
  UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED,
  UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED,
  LOGOUT,
} from '../../../../redux/actions/actionTypes'

import {
  URL_PATHS,
} from '../../../../constants'

import {
  logCatastrophicFailureError,
} from '../../../../utils/thirdPartyLogging'

import {
  AFTER_CATASTROPHIC_FAILURE_SHOULD_RELOAD_APP_BUTTON_HARD_REFRESH_BROWSER_PAGE,
} from '../../../../config'


const mapStateToProps = state => {
  const isAuthenticated = getIsAuthenticated(state)
  const isEmployeeUserLoggedInRatherThanCustomerUser = getIsEmployeeUserLoggedInRatherThanCustomerUser(state)
  const ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor =
    getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor(state)
  const catastrophicFailureError = getCatastrophicFailureError(state)
  const catastrophicFailureInfo = getCatastrophicFailureInfo(state)
  let currentUserCustomerId
  if (
    isAuthenticated && (
      !isEmployeeUserLoggedInRatherThanCustomerUser
      || (
        isEmployeeUserLoggedInRatherThanCustomerUser
        && ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor
      )
    )
  ) {
    currentUserCustomerId = getCurrentUserProp(state, 'rootCustomerId')
  }

  return {
    isAuthenticated,
    isEmployeeUserLoggedInRatherThanCustomerUser,
    ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,
    catastrophicFailureError,
    catastrophicFailureInfo,
    currentUserCustomerId,
  }
}

const mergeProps = (stateProps, dispatchProps) => {
  const {
    isAuthenticated,
    isEmployeeUserLoggedInRatherThanCustomerUser,
    ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,
    catastrophicFailureError, // the actual error object
    currentUserCustomerId,

    // catastrophicFailureInfo is an object which usually only contains only one
    // prop, `componentStack`, a string listing every React Component in the
    // Component stack. This is relatively unhelpful since most of our
    // components are unnamed (all our stateless functional components are
    // unnamed, and most of our presentational components are stateless
    // functional components; most of our Container components are also
    // unnamed). Furthermore, the files in which most of our components reside
    // are named 'index.js', so not even the filenames of these components are
    // helpful. Therefore, we don't need to save catastrophicFailureInfo to our
    // 3rd-party error logging service. Fortunately, the
    // catastrophicFailureError contains the stack trace, which has much more
    // helpful information, and combined with our 3rd-party error logging
    // service's Source Maps feature
    // (https://docs.sentry.io/clients/javascript/sourcemaps/), the stack trace
    // of catastrophicFailureError is the only stack trace we need.

    // catastrophicFailureInfo,
  } = stateProps
  const {
    dispatch,
  } = dispatchProps

  const urlPathToredirectToOnReloadApp = isAuthenticated
    ? URL_PATHS.root.path
    : URL_PATHS.login.path


  // CODE_COMMENTS_227
  let additionalActionsOnReloadApp
  if (AFTER_CATASTROPHIC_FAILURE_SHOULD_RELOAD_APP_BUTTON_HARD_REFRESH_BROWSER_PAGE) {
    additionalActionsOnReloadApp = () => { window.location.reload() }
  } else {
    additionalActionsOnReloadApp = isAuthenticated
      ? () => {
        // Unsetting these values will ensure that the saga that keeps the
        // user logged in will be restarted
        dispatch(createAction(UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED))
        dispatch(createAction(UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED))

        if (
          !isEmployeeUserLoggedInRatherThanCustomerUser
          || (
            isEmployeeUserLoggedInRatherThanCustomerUser
            && ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor
          )
        ) {
          // Unsetting this will ensure that customer info gets freshly re-fetched
          dispatch(createAction(FETCH_PRELIM_CUSTOMER_INFO_CLEAR, { target: currentUserCustomerId }))
        }

        dispatch(createAction(UNSET_CATASTROPHIC_FAILURE_HAPPENED))
      }
      : () => {
        dispatch(createAction(UNSET_CATASTROPHIC_FAILURE_HAPPENED))
      }
  }

  const logCatastrophicFailureException = () => {
    logCatastrophicFailureError({
      error: catastrophicFailureError,
    })
  }

  const logout = () => {
    dispatch(createAction(LOGOUT))
    additionalActionsOnReloadApp()
  }

  return {
    isAuthenticated,
    urlPathToredirectToOnReloadApp,
    additionalActionsOnReloadApp,
    logCatastrophicFailureException,
    logout,
  }
}

export default connect(
  mapStateToProps,
  undefined,
  mergeProps,
)(CatastrophicFailurePage)
