import {
  put,
  select,
} from 'redux-saga/effects'
import moment from 'moment'


import {
  getMaintenanceSchedule,
  getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow,
} from '../../selectors/maintenanceMode'

import {
  SET_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED,
  SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE,
} from '../../actions/actionTypes'
import createAction from '../../actions/createAction'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'

import {
  DISPLAY_NOTIFICATION_OF_UPCOMING_MAINTENANCE_X_HOURS_BEFORE_IT_STARTS,
  CHECK_EVERY_X_SECONDS_WHETHER_TO_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE,
  DONT_POLL_BACKEND_FOR_THE_FIRST_X_MINUTES_OF_A_SCHEDULED_MAINTENANCE_WINDOW,
} from '../../../config'


export function* putAppIntoMaintenanceModeIfTheCurrentTimeIsWithinXMinutesAfterTheStartOfAMaintenanceWindow() {
  const maintenanceSchedule = yield select(getMaintenanceSchedule)
  const startAndEndTimesOfSoonestUpcomingMaintenanceWindow = getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow({
    maintenanceSchedule,
    includeMaintenanceWindowsWereCurrentlyIn: true,
  })
  if (isTruthyAndNonEmpty(startAndEndTimesOfSoonestUpcomingMaintenanceWindow)) {
    const { start } = startAndEndTimesOfSoonestUpcomingMaintenanceWindow
    if (moment().isBetween(start, start.clone().add(DONT_POLL_BACKEND_FOR_THE_FIRST_X_MINUTES_OF_A_SCHEDULED_MAINTENANCE_WINDOW, 'minutes'))) {
      yield put(createAction(SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE, true))
    }
  }
}


export function* checkIfFlagToDisplayNotificationOfUpcomingMaintenanceShouldBeSetAndSetItIfSo() {
  const maintenanceSchedule = yield select(getMaintenanceSchedule)
  const startAndEndTimesOfSoonestUpcomingMaintenanceWindow = getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow({
    maintenanceSchedule,
    includeMaintenanceWindowsWereCurrentlyIn: true,
  })
  if (isTruthyAndNonEmpty(startAndEndTimesOfSoonestUpcomingMaintenanceWindow)) {
    const { start } = startAndEndTimesOfSoonestUpcomingMaintenanceWindow
    const shouldNotificationBeDisplayed = moment().isBetween(
      start.clone().subtract(DISPLAY_NOTIFICATION_OF_UPCOMING_MAINTENANCE_X_HOURS_BEFORE_IT_STARTS, 'hours'),
      // Why not just `start` here? Because the banner message would disappear
      // several seconds before the app gets put into maintenance mode (due to
      // the fact that the thread that puts the app into maintenance mode is
      // on a check-every-x-seconds loop, so it doesn't set the app into
      // maintenance mode _the instant_ the scheduled maintenance window
      // starts), which would be janky UI.
      start.clone().add(CHECK_EVERY_X_SECONDS_WHETHER_TO_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE, 'seconds'),
    )
    yield put(createAction(SET_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED, shouldNotificationBeDisplayed))
  } else {
    yield put(createAction(SET_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED, false))
  }
}
