import { connect } from 'react-redux'

import BannerMessageFromEmployee from './components'
import { getBusinessUnitIdOfRootCustomer } from '../../redux/selectors/customers'
import { getBusinessUnitNameFromBusinessUnitId } from '../../utils'
import { getEntireSlice as entireCustomerMessagesSlice } from '../../redux/selectors/customerMessages'
import { getCustomerMessageInfo } from './util'

const mapStateToProps = state => {
  const businessUnitIdOfCurrentUserRootCustomer = getBusinessUnitIdOfRootCustomer({
    state,
    doThisRatherThanThrowErrorIfRootCustomerHasNotYetBeenFetched: () => null,
  })
  const businessName = getBusinessUnitNameFromBusinessUnitId(businessUnitIdOfCurrentUserRootCustomer)
  const customerMessages = entireCustomerMessagesSlice(state)
  const currentDate = Date.now()
  const messageToDisplayInBanner = getCustomerMessageInfo({
    customerMessages,
    currentDate,
  })
  return {
    messageToDisplayInBanner,
    businessName,
  }
}

export default connect(
  mapStateToProps,
)(BannerMessageFromEmployee)
