import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_CUSTOMER_ADDRESS_LINKS as defaultSlice,
} from '../../../constants'

import {
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'


export function getEntireSlice(state) {
  return getEntireSliceCommon(
    REDUCER_NAMES_ENTITIES,
    defaultSlice,
  )(state)
}
