/* eslint-disable max-len */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'


import {
  REDUCER_NAMES_AUTH,
  REDUCER_NAMES_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN,
  REDUCER_NAMES_CATASTROPHIC_FAILURE,
  REDUCER_NAMES_CURRENT_USER,
  REDUCER_NAMES_CURRENT_USER_EMPLOYEE,

  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_HISTORY,
  REDUCER_NAMES_FETCH_STATUSES,
  REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE,

  REDUCER_NAMES_LOGOUT_BUTTON_ENABLED_TOGGLE,
  REDUCER_NAMES_PUBLIC_FORMS,
  REDUCER_NAMES_FORMS_ADDITIONAL_INFO,
  REDUCER_NAMES_INBOUND_UNACKNOWLEDGED_SHIPMENTS,
  REDUCER_NAMES_ORDERS_OF_INBOUND_UNACKNOWLEDGED_SHIPMENTS,
  REDUCER_NAMES_CUSTOMER_USER_PERMISSIONS, // users admin form
  REDUCER_NAMES_CUSTOMER_BALANCES,
  REDUCER_NAMES_BANK_ACCOUNT_INFO,
  REDUCER_NAMES_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP,
  REDUCER_NAMES_MAINTENANCE_MODE,
  REDUCER_NAMES_LATEST_WEB_APP_VERSION,
  REDUCER_NAMES_TIME_OF_MOST_RECENT_USER_ACTIVITY,
  REDUCER_NAMES_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
  REDUCER_NAMES_UNIVERSAL_CUSTOMER_REPRESENTATIVES,
  REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY,
  REDUCER_NAMES_ORDER_KEGS,
} from '../../constants'

import {
  LOGOUT,
  AUTHENTICATION_FAILURE,
  UNSET_CATASTROPHIC_FAILURE_HAPPENED,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_SUCCESS,
} from '../actions/actionTypes'

import authenticationReducer from './authentication'
import tasksToPerformImmediatelyAfterLoginReducer from './tasksToPerformImmediatelyAfterLogin'
import logoutButtonEnabledToggleReducer from './logoutButtonEnabledToggle'
import catastrophicFailureReducer from './catastrophicFailure'
import resetReducersToInitialStateOnCertainActionTypes from './higherOrderReducers/resetReducersToInitialStateOnCertainActionTypes'

import currentUserReducer from './currentUser/currentUser'
import currentUserEmployeeReducer from './currentUser/currentUserEmployee'

import entitiesReducers from './entities'

import historyReducers from './histories'
import fetchStatusesReducers from './fetchStatuses'
import fetchStatusesEmployeeReducers from './fetchStatusesEmployee'
import publicFormsReducers from './publicForms'
import formsAdditionalInfoReducers from './formsAdditionalInfo'
import inboundUnacknowledgedShipmentsReducer from './inboundUnacknowledgedShipments'
import ordersOfInboundUnacknowledgedShipmentsReducer from './ordersOfInboundUnacknowledgedShipments'
import customerUserPermissionsReducer from './customerUserPermissions'
import ackInboundShipmentsReminderPopupReducers from './ackInboundShipmentsReminderPopup'
import maintenanceModeReducers from './maintenanceMode'
import latestWebAppVersionReducers from './latestWebAppVersion'
import timeOfMostRecentUserActivityReducer from './timeOfMostRecentUserActivity'
import reportShipmentsFormsPotentialDuplicatesReducers from './reportShipmentsFormsPotentialDuplicates'
import customerBalancesReducer from './customerBalances'
import bankAccountInfoReducer from './bankAccountInfo'
import universalCustomerRepsReducer from './universalCustomerReps'
import reportedInventoryVsCalculatedInventoryReducer from './reportedInventoryVsCalculatedInventory'
import orderKegsReducer from './orderKegs'


let reducers = {
  [REDUCER_NAMES_AUTH]: authenticationReducer,
  [REDUCER_NAMES_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN]: tasksToPerformImmediatelyAfterLoginReducer,
  [REDUCER_NAMES_CATASTROPHIC_FAILURE]: catastrophicFailureReducer,
  [REDUCER_NAMES_CURRENT_USER]: currentUserReducer,
  [REDUCER_NAMES_CURRENT_USER_EMPLOYEE]: currentUserEmployeeReducer,
  [REDUCER_NAMES_HISTORY]: historyReducers,
  [REDUCER_NAMES_FETCH_STATUSES]: fetchStatusesReducers,
  [REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE]: fetchStatusesEmployeeReducers, // CODE_COMMENTS_226
  [REDUCER_NAMES_ENTITIES]: entitiesReducers,
  [REDUCER_NAMES_PUBLIC_FORMS]: publicFormsReducers,
  [REDUCER_NAMES_LOGOUT_BUTTON_ENABLED_TOGGLE]: logoutButtonEnabledToggleReducer,
  [REDUCER_NAMES_FORMS_ADDITIONAL_INFO]: formsAdditionalInfoReducers,
  [REDUCER_NAMES_INBOUND_UNACKNOWLEDGED_SHIPMENTS]: inboundUnacknowledgedShipmentsReducer,
  [REDUCER_NAMES_ORDERS_OF_INBOUND_UNACKNOWLEDGED_SHIPMENTS]: ordersOfInboundUnacknowledgedShipmentsReducer,
  [REDUCER_NAMES_CUSTOMER_USER_PERMISSIONS]: customerUserPermissionsReducer,
  [REDUCER_NAMES_CUSTOMER_BALANCES]: customerBalancesReducer,
  [REDUCER_NAMES_BANK_ACCOUNT_INFO]: bankAccountInfoReducer,
  [REDUCER_NAMES_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP]: ackInboundShipmentsReminderPopupReducers,
  [REDUCER_NAMES_MAINTENANCE_MODE]: maintenanceModeReducers,
  [REDUCER_NAMES_LATEST_WEB_APP_VERSION]: latestWebAppVersionReducers,
  [REDUCER_NAMES_TIME_OF_MOST_RECENT_USER_ACTIVITY]: timeOfMostRecentUserActivityReducer,
  [REDUCER_NAMES_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES]: reportShipmentsFormsPotentialDuplicatesReducers,
  [REDUCER_NAMES_UNIVERSAL_CUSTOMER_REPRESENTATIVES]: universalCustomerRepsReducer,
  [REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY]: reportedInventoryVsCalculatedInventoryReducer,
  [REDUCER_NAMES_ORDER_KEGS]: orderKegsReducer,
}

// see docstring of logoutReset
reducers = resetReducersToInitialStateOnCertainActionTypes({
  reducers,
  actionTypesThatTriggerReset: [LOGOUT, AUTHENTICATION_FAILURE],
})

// After the app experiences a catastrophic failure and the user reloads the
// app, we want to clear the Redux store in order to get a fresh start. But we
// don't actually want to clear the entire store: we'll leave the auth and
// currentUser slices intact so that the if the user was logged in when the app
// crashed, they don't have to re-log in.
reducers = resetReducersToInitialStateOnCertainActionTypes({
  reducers,
  actionTypesThatTriggerReset: [UNSET_CATASTROPHIC_FAILURE_HAPPENED],
  reducerFilter: key => (
    ![
      REDUCER_NAMES_AUTH,
      REDUCER_NAMES_CURRENT_USER,
      REDUCER_NAMES_CURRENT_USER_EMPLOYEE,
    ].includes(key)
  ),
})

// When an employee user changes the customer user they want to operate for, we
// want to reset most of the Redux store. See CODE_COMMENTS_226 for details.
reducers = resetReducersToInitialStateOnCertainActionTypes({
  reducers,
  actionTypesThatTriggerReset: [FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_SUCCESS],
  reducerFilter: key => (
    ![
      REDUCER_NAMES_AUTH,
      REDUCER_NAMES_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN,
      // The saga that fetches the new customer user first saves the user to
      // REDUCER_NAMES_CURRENT_USER then calls the success action that triggers
      // this reset, so we don't want to delete what that saga just saved.
      REDUCER_NAMES_CURRENT_USER,
      REDUCER_NAMES_CURRENT_USER_EMPLOYEE,
      REDUCER_NAMES_LOGOUT_BUTTON_ENABLED_TOGGLE,
      REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE, // CODE_COMMENTS_226
    ].includes(key)
  ),
})

// Instead of exporting the root reducer here, connected-react-router >=5.0.0
// needs us to export a function that takes `history` as an argument and returns
// a root reducer. See
// https://github.com/supasate/connected-react-router/releases/tag/v5.0.0 for
// details.
const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  ...reducers,
})
export default createRootReducer
