/* eslint-disable max-len */

import get_ from 'lodash/get'
import has_ from 'lodash/has'

import {
  logObjectHasProblemsErrorMessage,
  CANT_BE_DISPLAYED,
} from '../../../utils/thirdPartyLogging'


// Extracts a system property from the HTTP Response of a /properties call.
// Returns null if for whatever reason the system property can't be extracted.

// Details: The return object of a /properties call looks like:

// [
//   {
//     "tap3Property": "MAINTENANCE_MODE_SCHEDULE",
//     "value": "{\"maintainanceModeActive\":false,\"maintenanceSchedule\":[{\"startTimeUTC\":1893456000000,\"endTimeUTC\":1893542400000,\"internalReason\":null,\"customerFacingReason\":null}]}",
//     "userRole": "ADM",
//     "userType": "CUS",
//     "description": "A list of times for maintenance windows, and whether maintenance mode is active."
//   }
// ]

// As you can see, 'value' is what we're interested in; it's a JSON string that
// must be parsed. Also, sometimes you only care about a single property in the
// parsed object (in the example above, we only care about
// 'maintenanceSchedule'); you can return only that prop by passing its name in
// as the optionalTargetProp arg.
export function extractSystemPropertyFromHttpResponse(
  httpResponse,
  optionalTargetProp, // e.g. 'maintenanceSchedule'
) {
  let obj
  const jsonString = get_(httpResponse, 'data[0].value')
  if (!jsonString) {
    logError(httpResponse, 'No httpResponse.data[0].value prop')
    return null
  }
  try {
    obj = JSON.parse(jsonString)
  } catch (error) {
    logError(httpResponse, 'Error trying to JSON.parse() httpResponse.data[0].value')
    return null
  }
  if (!optionalTargetProp) {
    return obj
  }
  if (has_(obj, optionalTargetProp)) {
    return obj[optionalTargetProp]
  }
  logError(httpResponse, `httpResponse.data[0].value was able to be JSON.parse()ed but it has no '${optionalTargetProp}' prop`)
  return null
}


// Helper functions

function logError(httpResponse, errorDetails) {
  logObjectHasProblemsErrorMessage({
    objectType: 'systemProperty',
    level: CANT_BE_DISPLAYED,
    additionalInfo: {
      errorDetails,
    },
    httpResponse,
  })
}
