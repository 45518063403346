import { put, call, takeEvery } from 'redux-saga/effects'


import { publicFetch, privateFetch } from '../fetch'

import createAction from '../../actions/createAction'

import {
  FETCH_SUBSIDIARIES,
  SAVE_SUBSIDIARIES,
  FETCH_SUBSIDIARIES_REQUEST,
  FETCH_SUBSIDIARIES_SUCCESS,
  FETCH_SUBSIDIARIES_FAILURE,
} from '../../actions/actionTypes'

import {
  API_URL_PATH_SUBSIDIARIES,
  API_URL_PATH_SUBSIDIARIES_DIST_INVENTORY_TOKEN,
} from '../../../constants'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'

import {
  createApiTimedTokenHttpHeader,
} from '../../../utils'


export function* fetchSubsidiaries(action) {
  const {
    preventsDashboardFromLoadingIfFails,
    isDistReportInventoryNoLoginRequired,
    isDistReportConstellationNoLoginRequired,
    token: tokenForDistReportInventoryNoLoginRequired,
  } = action.payload
  yield put(createAction(FETCH_SUBSIDIARIES_REQUEST))
  let response
  try {
    response = yield call(
      (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) ? publicFetch : privateFetch,
      {
        path: (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
          ? API_URL_PATH_SUBSIDIARIES_DIST_INVENTORY_TOKEN
          : API_URL_PATH_SUBSIDIARIES,
        headers: (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
          ? createApiTimedTokenHttpHeader(tokenForDistReportInventoryNoLoginRequired)
          : {},
      },
    )
  } catch (error) {
    if (error.response && error.response.status === 404) {
      response = { data: [] }
    } else {
      yield call(
        doFailure,
        {
          error,
          preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
        },
      )
      return
    }
  }
  yield call(
    doSuccess,
    response.data,
  )
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_SUBSIDIARIES, fetchSubsidiaries],
]


function* doSuccess(subsidiaries) {
  yield put(createAction(SAVE_SUBSIDIARIES, { info: subsidiaries }))
  yield put(createAction(FETCH_SUBSIDIARIES_SUCCESS)) // CODE_COMMENTS_20
}


function* doFailure({ error, preventsDashboardFromLoading }) {
  yield call(
    generalDoFailure,
    {
      error,
      preventsDashboardFromLoading,
      action: createFetchFailureAction({
        error,
        type: FETCH_SUBSIDIARIES_FAILURE,
      }),
    },
  )
}
