import React from 'react'

import remove_ from 'lodash/remove'
import findIndex_ from 'lodash/findIndex'
import values_ from 'lodash/values'
import cloneDeep_ from 'lodash/cloneDeep'


import DownloadTableAsFile from '../../../sharedComponents/DownloadTableAsFile'

import {
  getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn,
} from '../../../../../../redux/selectors/rewrite/relationships/relatedToInfo'

import {
  KEG_ORDER_FULL_DETAILS_LABEL_DELIVERY_ADDRESS,
  KEG_ORDER_FULL_DETAILS_LABEL_COMMENTS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/kegOrderHistory'

import {
  ORDER_KEGS_FORM_FIELD_LABEL_CUSTOMER_PO_NUM,
} from '../../../../../../constants/formAndApiUrlConfig/orderKegs'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  getInfoDefinitionsForKegOrderFullDetails,
  createDisplayedCustomerPoNumber,
  createDisplayedOrderComments,
  getAllKegOrderObjectsToBeIncludedInHistoryTable,
  getIncludeLocalKegsColumnsInHistoryTable,
  determineItemSkuIdsToIncludeAsFieldsInKegOrderHistoryTable,
} from '../../../util/kegOrderHistory'
import {
  createFilenameOfDownloadedTableFile,
  createRowDefinitionsOfAddressForDownloadedTableFile,
  constructNonHeadingRowsOfDownloadedTableFile,
} from '../../../util/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'


export default ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entireItemSkusSlice,
  customerId,
  allKegOrderObjects,
  configuredItemSkuIds,
  allAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile,
  mostRecentlySubmittedFormValues,
}) => {
  if (!mostRecentlySubmittedFormValues) {
    return <DownloadTableAsFile data={null} filename={null} />
  }

  const allKegOrderObjectsToBeIncludedInHistoryTable = getAllKegOrderObjectsToBeIncludedInHistoryTable(
    allKegOrderObjects,
  )
  const isRelatedToLocalDistributors = getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  })
  const includeLocalKegsColumns = getIncludeLocalKegsColumnsInHistoryTable(
    allKegOrderObjectsToBeIncludedInHistoryTable,
    isRelatedToLocalDistributors,
  )
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInKegOrderHistoryTable({
    entireItemSkusSlice,
    allKegOrderObjectsToBeIncludedInHistoryTable,
    configuredItemSkuIds,
  })
  const filename = createFilename(mostRecentlySubmittedFormValues)
  const data = createFileData({
    entireItemSkusSlice,
    allKegOrderObjectsToBeIncludedInHistoryTable,
    allAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile,
    itemSkuIdsToIncludeAsFieldsInTable,
    includeLocalKegsColumns,
  })

  return (
    <DownloadTableAsFile
      data={data}
      filename={filename}
    />
  )
}


/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

function createFilename(mostRecentlySubmittedFormValues) {
  const historyFormStartDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_START_DATE]
  const historyFormEndDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_END_DATE]
  const historyFormOrderStatus = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_STATUS]

  return createFilenameOfDownloadedTableFile(
    'keg orders',
    historyFormStartDate,
    historyFormEndDate,
    { status: historyFormOrderStatus },
  )
}


// array of arrays, with first array being column headings
function createFileData({
  entireItemSkusSlice,
  allKegOrderObjectsToBeIncludedInHistoryTable,
  allAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile,
  itemSkuIdsToIncludeAsFieldsInTable,
  includeLocalKegsColumns,
}) {
  const originalDataDefinitions = getInfoDefinitionsForKegOrderFullDetails({
    entireItemSkusSlice,
    itemSkuIdsToIncludeAsFieldsInTable,
    includeLocalKegsColumns,
    downloadAttachment: true,
  })

  let dataDefinitions = cloneDeep_(originalDataDefinitions)

  // the 'PO #" and 'comments' displayed to the user in the web app show 'none'
  // in grey text if the user didn't add either of these to the keg order. In
  // the downloaded file, we want to leave the cell blank instead
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === ORDER_KEGS_FORM_FIELD_LABEL_CUSTOMER_PO_NUM),
    1,
    { heading: ORDER_KEGS_FORM_FIELD_LABEL_CUSTOMER_PO_NUM, cellContent: createDisplayedCustomerPoNumber() },
  )
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === KEG_ORDER_FULL_DETAILS_LABEL_COMMENTS),
    1,
    { heading: KEG_ORDER_FULL_DETAILS_LABEL_COMMENTS, cellContent: createDisplayedOrderComments() },
  )

  // The address definition needs to be swapped out with several fields that
  // break out the address into its individual parts
  remove_(dataDefinitions, { heading: KEG_ORDER_FULL_DETAILS_LABEL_DELIVERY_ADDRESS })

  const addressDefsForDownloadedFile = createRowDefinitionsOfAddressForDownloadedTableFile(
    allAddressObjectsToBeIncludedInDownloadKegOrderHistoryFile,
    'shipAddressId',
    'Delivery',
  )

  dataDefinitions = [
    ...dataDefinitions,
    // This puts all the address info at the end of the file, which is fine
    ...addressDefsForDownloadedFile,
  ]

  let arrayOfOrderObjects = values_(allKegOrderObjectsToBeIncludedInHistoryTable)
  arrayOfOrderObjects = createApiDateSortFunctionForHistoryTable({ datePropName: 'dateNeeded' })(arrayOfOrderObjects)

  const nonHeadingRows = constructNonHeadingRowsOfDownloadedTableFile(arrayOfOrderObjects, dataDefinitions)

  return [
    dataDefinitions.map(o => o.heading), // headings row
    ...nonHeadingRows,
  ]
}
