import React from 'react'

import OutboundFullKegShipmentHistoryForm from '../OutboundFullKegShipmentHistory/Form'
import Table from './Table'

import {
  HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE,
} from '../../../../constants'


const formReducerName = HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE
export default props => (
  <div>
    <OutboundFullKegShipmentHistoryForm // CODE_COMMENTS_93
      formReducerName={formReducerName}
      kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm
      {...props}
    />
    <Table
      formReducerName={formReducerName}
      {...props}
    />
  </div>
)
