import {
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_REQUEST,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_SUCCESS,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_FAILURE,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_CLEAR,
} from '../../actions/actionTypes'

import fetchStatusReducerCreator from '../fetchStatuses/higherOrderReducers/reducerCreators'


export default fetchStatusReducerCreator({})(
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_REQUEST,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_SUCCESS,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_FAILURE,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_CLEAR,
)
