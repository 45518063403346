import { connect } from 'react-redux'

import NavBar from './components/NavBar'

import createAction from '../../redux/actions/createAction'
import {
  LOGOUT,
} from '../../redux/actions/actionTypes'


import fetchStatusSelectors from '../../redux/selectors/fetchStatuses/customers/customers'
import {
  getProp as getCurrentUserProp,
} from '../../redux/selectors/currentUser'
import {
  getProp as getCustomerProp,
  getBusinessUnitIdOfRootCustomer,
} from '../../redux/selectors/customers'
import { getIsLogoutButtonEnabled } from '../../redux/selectors/logoutButtonEnabledToggle'

const { getFetchStatuses } = fetchStatusSelectors

const mapStateToProps = (state, { fauxPrivateLayout }) => {
  // CODE_COMMENTS_267
  if (fauxPrivateLayout) {
    return {}
  }
  const businessUnitIdOfCurrentUserRootCustomer = getBusinessUnitIdOfRootCustomer({
    state,
    doThisRatherThanThrowErrorIfRootCustomerHasNotYetBeenFetched: () => null,
  })
  const currentUserFirstName = getCurrentUserProp(state, 'firstName')

  const currentUserRootCustomerId = getCurrentUserProp(state, 'rootCustomerId')
  const currentUserRootCustomerFetchStatus = getFetchStatuses(state, currentUserRootCustomerId)
  const currentUserRootCustomerName = currentUserRootCustomerFetchStatus.didFetchSucceed
    ? getCustomerProp(state, currentUserRootCustomerId, 'name')
    : null

  return {
    businessUnitIdOfCurrentUserRootCustomer,
    isLogoutButtonEnabled: getIsLogoutButtonEnabled(state), // CODE_COMMENTS_41
    currentUserFirstName,
    currentUserRootCustomerName,
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(createAction(LOGOUT)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar)
