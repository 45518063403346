import React from 'react'


import {
  EmployeeOperateAsCustomerUserOption,
} from '../../shared/IndividualOptions'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER,
} from '../../../../../../constants/permissions'
import {
  createYouDontHaveThesePermissionsMessage,
} from '../../../../../../utils/permissions'


// CODE_COMMENTS_39
export default ({
  shouldOperateAsCustomerUserFormBeDisabled,
}) => ([
  <EmployeeOperateAsCustomerUserOption
    key="EmployeeOperateAsCustomerUserOption"
    disabledIf={shouldOperateAsCustomerUserFormBeDisabled}
    whyIsThisOptionDisabledMessage={shouldOperateAsCustomerUserFormBeDisabled &&
      createYouDontHaveThesePermissionsMessage({
        functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER,
      })}
  />,
])
