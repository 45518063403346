/**
 * Return a string explaining the problem if the verifier is not true, otherwise
 * return true. Functions that several different customer types use and whose
 * error messages need to be different for each customer type are higher-order
 * functions with the ability to pass in the error message.
 */
import {
  getDoesCustomerHaveAnyChildren,
  getProp as getCustomerProp,
} from '../../../redux/selectors/customers'

import {
  getDefaultShippingAddressOfCustomer,
  getBillingAddressOfCustomer,
} from '../../../redux/selectors/addresses'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../redux/selectors/rewrite/contracts'
import {
  getIsRelatedToAnyone,
} from '../../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getIsRelatedFromAnyone,
} from '../../../redux/selectors/rewrite/relationships/relatedFromInfo'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'

import {
  getWhichContracteesOfAConbrwCanHaveShipmentsReportedOnThem,
} from '../../../redux/selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  CUSTOMER_TYPES_PUB,
  CUSTOMER_STATUS_PENDING,
  DISPLAYED_ERROR_MESSAGES_CUSTOMER_IN_PENDING_STATUS,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'


export const getIsRelatedToAnyActiveDistributorsOrSelfDistOrPubs = (state, customerId) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)

  const isRelatedToAnyDistributorsOrSelfDistOrPubs = getIsRelatedToAnyone({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112
    customerObjsCustomFilterFunc: o => (
      [
        CUSTOMER_TYPES_DISTRIBUTOR,
        CUSTOMER_TYPES_SELF_DISTRIBUTION,
        CUSTOMER_TYPES_PUB,
      ].includes(o.customerType)
    ),
  })
  if (isRelatedToAnyDistributorsOrSelfDistOrPubs) { return true }
  return "You don't have any active distributors on file, nor are you configured for self-distribution or own pub shipments"
}


export const getHasAnyChildren = (state, customerId) => {
  if (getDoesCustomerHaveAnyChildren(state, customerId)) { return true }
  return 'You have no child accounts on file'
}


export const getHasDefaultShippingAddress = (state, customerId) => {
  if (getDefaultShippingAddressOfCustomer({ state, customerId })) { return true }
  return 'You have no default shipping address on file'
}

export const getHasBillingAddress = (state, customerId) => {
  if (getBillingAddressOfCustomer({ state, customerId })) { return true }
  return 'You have no billing address on file'
}

export const getIsNotInPendingStatus = (state, customerId) => {
  const customerStatus = getCustomerProp(state, customerId, 'customerStatus')

  if (customerStatus !== CUSTOMER_STATUS_PENDING) { return true }
  return DISPLAYED_ERROR_MESSAGES_CUSTOMER_IN_PENDING_STATUS
}


export const getIsRelatedFromAnyActiveBrewers = errorMessage => (state, customerId) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  if (getIsRelatedFromAnyone({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
  })) { return true }
  return errorMessage
}


export const getIsConfiguredWithAtLeastOneContainerType = (state, customerId) => {
  const itemSkus = getCustomerProp(state, customerId, 'itemSkuIds')
  if (isTruthyAndNonEmpty(itemSkus)) { return true }
  return "You're not configured with any keg types"
}


export const getIsEachContracteeBrewerTheCbCanReportInventoryForConfiguredWithAtLeastOneContainerType = (
  state,
  conbrwCustomerId,
) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const contracteeCustomerIds = getWhichContracteesOfAConbrwCanHaveShipmentsReportedOnThem(
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    conbrwCustomerId,
  )
  if (contracteeCustomerIds.every(contracteeCustomerId => (
    isTruthyAndNonEmpty(getCustomerProp(state, contracteeCustomerId, 'itemSkuIds'))
  ))) { return true }
  return 'One or more of your active brewers is not configured with any keg types (i.e. half barrel, sixth barrel, etc.)'
}
