import React from 'react'


import moment from 'moment'
import ShipmentHistoryFormCreator from '../../HOC/ShipmentHistoryFormCreator'

import DownloadOutboundFullKegShipmentHistoryTableAsFile from '../DownloadTableAsFile'

import {
  FETCH_SCHEDULED_SHIPMENT_HISTORY,
} from '../../../../../redux/actions/actionTypes'
import {
  COMMON_STATUS_ALL,
  DEFAULT_DISPLAYED_DATE_FORMAT,
  SHIPMENT_STATUS_ACKNOWLEDGED,
  SHIPMENT_STATUS_ALLOCATED,
  SHIPMENT_STATUS_BOOKED,
  SHIPMENT_STATUS_CANCELED,
  // SHIPMENT_STATUS_COMPLETED,
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_IN_TRANSIT,
  SHIPMENT_STATUS_PENDING,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'
import {
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../constants/formAndApiUrlConfig/histories/historyShared'
import {
  NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS,
} from '../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'
import {
  SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE,
} from '../../../../../constants/formAndApiUrlConfig/histories/shipmentHistoryShared'

export const scheduledShipmentsHistoryStatusesConfig = {
  // [SHIPMENT_STATUS_COMPLETED]: {
  //   includeShipmentObjsInHistoryTable: true,
  //   includeStatusAsDropdownOptionInHistoryForm: true,
  //   statusShouldBeDisplayedAsThisHumanreadableAlias: 'Finalized',
  // },
  [SHIPMENT_STATUS_PENDING]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_ALLOCATED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
    // statusShouldBeDisplayedAsThisHumanreadableAlias: 'Finalized',
  },
  [SHIPMENT_STATUS_CANCELED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_BOOKED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_DELIVERED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: false,
  },
  [SHIPMENT_STATUS_ACKNOWLEDGED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [SHIPMENT_STATUS_IN_TRANSIT]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
}

const initialValues = {
  [HISTORY_FORM_FIELD_NAME_START_DATE]:
    moment().subtract(1, 'months').startOf('month').format(DEFAULT_DISPLAYED_DATE_FORMAT),
  [HISTORY_FORM_FIELD_NAME_END_DATE]:
    moment().add(NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS, 'days').format(DEFAULT_DISPLAYED_DATE_FORMAT),
  [HISTORY_FORM_FIELD_NAME_STATUS]: SHIPMENT_STATUS_BOOKED, // CODE_COMMENTS_61
  [SHIPMENT_HISTORY_FORM_FIELD_NAME_SHIPMENT_TYPE]: COMMON_STATUS_ALL, // CODE_COMMENTS_61
}

export default props => (
  <ShipmentHistoryFormCreator
      {...props}
      downloadFileComponent={DownloadOutboundFullKegShipmentHistoryTableAsFile}
      statusesConfig={scheduledShipmentsHistoryStatusesConfig}
      submitAction={FETCH_SCHEDULED_SHIPMENT_HISTORY}
      dateRangeFieldsLabel={'Planned Delivery Date'}
      inboundOrOutbound={'outbound'}
      hideShipmentTypeField
      formInitialValues={initialValues}
  />
)
