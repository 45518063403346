import React from 'react'

import findIndex_ from 'lodash/findIndex'
import values_ from 'lodash/values'
import cloneDeep_ from 'lodash/cloneDeep'


import DownloadTableAsFile from '../../../sharedComponents/DownloadTableAsFile'

import {
  // Why are we using a bunch of functions from the
  // inboundEmptyKegShipmentHistory component? Because that component and this
  // component refer to the same shipments: outbound full keg shipments for
  // Brewers are inbound full keg shipments for Distributors. See
  // CODE_COMMENTS_205.
  getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable,
  getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
} from '../../../util/shipmentHistory/inboundEmptyKegShipmentHistory'
import {
  getInfoDefinitionsOutboundEmptyKegShipmentFullDetails,
} from '../../../util/shipmentHistory/outboundEmptyKegShipmentHistory'
import {
  createDisplayedProNumber,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'

import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'
import {
  createFilenameOfDownloadedTableFile,
  constructNonHeadingRowsOfDownloadedTableFile,
} from '../../../util/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'

import {
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION,
} from '../../../../../../constants/formAndApiUrlConfig/histories/OutboundFullKegShipmentHistory'

import {
  OUTBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
} from '../../../../../../constants/formAndApiUrlConfig/histories/OutboundEmptyKegShipmentHistory'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/historyShared'


export default ({
  entireItemSkusSlice,
  allOutboundEmptyKegShipmentHistoryObjects,
  configuredItemSkuIds,
  mostRecentlySubmittedFormValues,
  entireCustomersSlice,
  customerId,
}) => {
  if (!mostRecentlySubmittedFormValues) {
    return <DownloadTableAsFile data={null} filename={null} />
  }

  const allOutboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable(
      allOutboundEmptyKegShipmentHistoryObjects,
    )

  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allOutboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
  })

  const filename = createFilename(mostRecentlySubmittedFormValues)

  const data = createFileData({
    entireItemSkusSlice,
    allOutboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
    itemSkuIdsToIncludeAsFieldsInTable,
    entireCustomersSlice,
    customerId,
  })

  return (
    <DownloadTableAsFile
      data={data}
      filename={filename}
    />
  )
}


/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

function createFilename(mostRecentlySubmittedFormValues) {
  const historyFormStatus = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_STATUS
  ]
  const historyFormStartDate = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_START_DATE
  ]
  const historyFormEndDate = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_END_DATE
  ]

  return createFilenameOfDownloadedTableFile(
    'outbound shipments',
    historyFormStartDate,
    historyFormEndDate,
    { status: historyFormStatus },
  )
}


// array of arrays, with first array being column headings
function createFileData({
  entireItemSkusSlice,
  allOutboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
  itemSkuIdsToIncludeAsFieldsInTable,
  entireCustomersSlice,
  customerId,
}) {
  const originalDataDefinitions = getInfoDefinitionsOutboundEmptyKegShipmentFullDetails({
    entireItemSkusSlice,
    itemSkuIdsToIncludeAsFieldsInTable,
    entireCustomersSlice,
    customerId,
  })

  let dataDefinitions = cloneDeep_(originalDataDefinitions)
  dataDefinitions = swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions)
  dataDefinitions = swapCustomerNamesDefinition(dataDefinitions)

  let arrayOfShipmentObjects = values_(allOutboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable)
  arrayOfShipmentObjects = createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' })(arrayOfShipmentObjects)
  arrayOfShipmentObjects = arrayOfShipmentObjects.filter(getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable)

  const nonHeadingRows = constructNonHeadingRowsOfDownloadedTableFile(arrayOfShipmentObjects, dataDefinitions)

  return [
    dataDefinitions.map(o => o.heading), // headings row
    ...nonHeadingRows,
  ]
}

// CODE_COMMENTS_111
function swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions) {
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === OUTBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM),
    1,
    { heading: OUTBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM, cellContent: createDisplayedProNumber() },
  )

  // CODE_COMMENTS_109
  return dataDefinitions
}


// CODE_COMMENTS_132
function swapCustomerNamesDefinition(dataDefinitions) {
  // Destination field
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION),
    1,
    {
      heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION,
      cellContent: row => row.destinationCustomerName,
    },
  )

  // CODE_COMMENTS_109
  return dataDefinitions
}
