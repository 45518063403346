/* eslint-disable max-len */


import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Routes from './components/MasterAccountRoutes'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../redux/selectors/permissions'

import {
  getShouldReportedInventoryVsCalculatedInventoryPageBeRendered,
} from '../../../../redux/selectors/reportedInventoryVsCalculatedInventory'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
} from '../../../../constants/permissions'


const mapStateToProps = (state, { customerId }) => {
  const hasManageUsersAndContactsPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  })
  const hasViewInvoicesPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  })

  const shouldReportedInventoryVsCalculatedInventoryPageBeRendered = getShouldReportedInventoryVsCalculatedInventoryPageBeRendered({
    state,
    customerId,
  })

  return {
    // Why Booleans? See CODE_COMMENTS_252
    hasManageUsersAndContactsPermissions: Boolean(hasManageUsersAndContactsPermissions),
    hasViewInvoicesPermissions: Boolean(hasViewInvoicesPermissions),
    shouldReportedInventoryVsCalculatedInventoryPageBeRendered: Boolean(shouldReportedInventoryVsCalculatedInventoryPageBeRendered),
  }
}

export default withRouter( // CODE_COMMENTS_25
  connect(
    mapStateToProps,
  )(Routes),
)
