import React from 'react'
import { Message } from 'semantic-ui-react'

import get_ from 'lodash/get'


import {
  getWhichContracteesOfALoggedInConbrwAreResponsibleForReportingTheConbrwsKegFills,
  getWhichConbrwsOfALoggedInBrwAreResponsibleForReportingTheirKegFills,
} from '../../../../../redux/selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../../../constants'

import {
  isTruthyAndNonEmpty,
  formatArrayOfStringsAsNaturalLanguagePhrase,
} from '../../../../../utils'

export default ({
  customerId,

  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
}) => {
  const customerType = get_(entireCustomersSlice, [customerId, 'customerType'])


  // Only set this to a truthy value if this is a Report Keg Fills form AND the
  // currently-operated-for customer is a CONBRW
  const contracteesOfALoggedInConbrwResponsibleForReportingTheConbrwsKegFills =
    customerType === CUSTOMER_TYPES_CONTRACT_BREWER
      ? getWhichContracteesOfALoggedInConbrwAreResponsibleForReportingTheConbrwsKegFills({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        conbrwCustomerId: customerId,
        getCustomerObjectsRatherThanCustomerIds: true,
      })
      : null

  // Only set this to a truthy value if this is a Report Keg Fills form AND the
  // currently-operated-for customer is a BRW (and specifically NOT a conbrw
  // operating for a contractee, but just a logged-in BRW)
  const conbrwsOfALoggedInBrwResponsibleForReportingTheConbrwsKegFills =
    customerType === CUSTOMER_TYPES_BREWER
      ? getWhichConbrwsOfALoggedInBrwAreResponsibleForReportingTheirKegFills({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        brwCustomerId: customerId,
        getCustomerObjectsRatherThanCustomerIds: true,
      })
      : null


  if (
    !isTruthyAndNonEmpty(contracteesOfALoggedInConbrwResponsibleForReportingTheConbrwsKegFills)
    && !isTruthyAndNonEmpty(conbrwsOfALoggedInBrwResponsibleForReportingTheConbrwsKegFills)
  ) {
    return null
  }
  const arrayOfCustomerNames = [
    ...(contracteesOfALoggedInConbrwResponsibleForReportingTheConbrwsKegFills || []),
    ...(conbrwsOfALoggedInBrwResponsibleForReportingTheConbrwsKegFills || []),
  ].map(o => o.name)

  const singular = arrayOfCustomerNames.length === 1

  const restOfMessage = isTruthyAndNonEmpty(contracteesOfALoggedInConbrwResponsibleForReportingTheConbrwsKegFills)
    ? `${singular ? 'is' : 'are'} responsible for reporting the kegs you fill for them, so they're not selectable in this form.`
    : `${singular ? 'is' : 'are'} responsible for reporting the kegs they fill for you, so they're not selectable in this form.`

  return (
    <Message
      info
      compact
      style={{
        margin: 0,
        padding: '0.5em 1em',
      }}
    >
      {`${formatArrayOfStringsAsNaturalLanguagePhrase(arrayOfCustomerNames)} ${restOfMessage}`}
    </Message>
  )
}
