import React from 'react'

import orderBy_ from 'lodash/orderBy'


import HistoryTableCreator from '../../../HOC/HistoryTableCreator'
import ShipmentDetails from '../subFeatures/ShipmentDetails'

import {
  createDisplayedOriginNameForOutboundShipmentsHistoryTable,
} from '../../../util/shipmentHistory/outboundFullKegShipmentHistory/displayedDataEtc/displayedOutboundFullKegShipmentDataEtc'
import {
  getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
  getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
  getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
} from '../../../util/shipmentHistory/outboundFullKegShipmentHistory'
import {
  createSpecialTableContentDefinitions,
} from '../../../util/shipmentHistory/noMovements'
import {
  createDisplayedShipmentType,
  createDisplayedMovementType,
  createDisplayedDestinationName,
  createDisplayedDestinationState,
  createDisplayedShipmentDate,
  createDisplayedProNumber,
  createContainerTypesFieldsDefinitions,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'
import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail,
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'
import { CUSTOMER_TYPES_WAREHOUSE } from '../../../../../../constants'


function createTableContentDefinitions({
  entireItemSkusSlice,
  customerId,
  configuredItemSkuIds,
  allOutboundFullKegShipmentObjects,
  shouldOriginColumnBeRendered,
  entireCustomersSlice,
  dispatch,
}) {
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allOutboundFullKegShipmentObjects,
  })

  return {
    content: [
      {
        heading: 'Shipment #',
        cellContent: row => (
          <ShipmentDetails
            dispatch={dispatch}
            entireCustomersSlice={entireCustomersSlice}
            entireItemSkusSlice={entireItemSkusSlice}
            customerId={customerId}
            info={row}
            itemSkuIdsToIncludeAsFieldsInTable={determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail({
              entireItemSkusSlice,
              shipment: row,
            })}
            shouldOriginColumnBeRendered={shouldOriginColumnBeRendered}
          />
        ),
        customSortInfo: {
          sortFunction: (apiObjs, ascOrDesc) => (orderBy_(apiObjs, ['shipmentId'], [ascOrDesc])),
          sortApiObjectValuesRatherThanCellContents: true,
        },
      },
      (entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ? {
        heading: 'Shipment Type',
        cellContent: createDisplayedShipmentType,
      }: {
        heading: 'Movement Type',
        cellContent: createDisplayedMovementType,
      }),
      ...(shouldOriginColumnBeRendered
        ? [{
          heading: 'Origin',
          cellContent: createDisplayedOriginNameForOutboundShipmentsHistoryTable,
          customSortInfo: {
            columnNotSortable: true, // CODE_COMMENTS_248
          },
        }]
        : []
      ),
      {
        heading: 'Destination',
        cellContent: createDisplayedDestinationName,
        customSortInfo: {
          columnNotSortable: true, // CODE_COMMENTS_248
        },
      },
      {
        heading: 'Destination State',
        cellContent: createDisplayedDestinationState,
      },
      {
        heading: 'Ship Date',
        cellContent: createDisplayedShipmentDate,
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
      },
      {
        heading: 'Status',
        cellContent: getDisplayedHumanReadableStatusOfOutboundFullkegShipment,
        className: 'outboundStatus',
      },
      {
        heading: 'Reference #',
        cellContent: createDisplayedProNumber(),
      },
      ...createContainerTypesFieldsDefinitions({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
      }),
    ],
    defaultSortColumn: 'Ship Date',
    filterFunc: getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
    rowKey: row => row.id,
  }
}

export default ({
  entireItemSkusSlice,
  configuredItemSkuIds,
  allOutboundFullKegShipmentObjects,
  allNoMovementsObjects,
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  isFormSubmitting,
  hasFormSubmitFailed,
  noResultsMessage,
  shouldOriginColumnBeRendered,
  areAnyItemsEditable,
  getIsindividualItemEditable,
  entireCustomersSlice,
  dispatch,
}) => {
  const itemType = 'outbound full keg shipment'
  const allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable(
      allOutboundFullKegShipmentObjects,
    )
  const definitions = createTableContentDefinitions({
    customerId,
    entireItemSkusSlice,
    configuredItemSkuIds,
    allOutboundFullKegShipmentObjects,
    shouldOriginColumnBeRendered,
    entireCustomersSlice,
    dispatch,
  })
  const specialTableContentDefinitions = createSpecialTableContentDefinitions({
    itemType,
    shouldOriginColumnBeRendered,
  })

  return (
    <HistoryTableCreator
      tableInfoObj={allOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable}
      definitions={definitions}
      isFormSubmitting={isFormSubmitting}
      hasFormSubmitFailed={hasFormSubmitFailed}
      noResultsMessage={noResultsMessage}
      additionalPropsToPassToTableCells={{ customerId, operatingContractBrewerCustomerId }}

      // for Edit and Delete buttons column
      areAnyItemsEditable={areAnyItemsEditable}
      itemType={itemType}
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      getIsindividualItemEditable={getIsindividualItemEditable}

      // For NoMovements rows
      specialInfoObj={allNoMovementsObjects}
      specialTableContentDefinitions={specialTableContentDefinitions}
    />
  )
}
