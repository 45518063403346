/**
 * The purpose of this component is to fetch the preliminary info (see
 * CODE_COMMENTS_16) of all customers when the user first logs in and show a
 * loading indicator on all private routes until the preliminary info of the
 * current user customer has been fetched. No private routes throughout the
 * entire app will be shown until this info at minimum is fetched.
 */
import { connect } from 'react-redux'

import WaitForPrelimCustomerInfoComponent from '../components/WaitForPrelimCustomerInfo'
import fetchStatusSelectors from '../../../../redux/selectors/fetchStatuses/customers/preliminaryInfo'
import {
  getProp as getCurrentUserProp,
} from '../../../../redux/selectors/currentUser'

import createAction from '../../../../redux/actions/createAction'
import { FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS } from '../../../../redux/actions/actionTypes'

const { getFetchStatuses: getPrelimInfoFetchStatuses } = fetchStatusSelectors

const mapStateToProps = (state, ownProps) => {
  const {
    hasFetchBeenAttempted,
    isFetching,
    didFetchSucceed,
    didFetchFail,
  } = getPrelimInfoFetchStatuses(
    state,
    getCurrentUserProp(state, 'rootCustomerId'),
  )

  return {
    hasRootCustomerPrelimInfoFetchBeenAttempted: hasFetchBeenAttempted,
    isRootCustomerPrelimInfoFetching: isFetching,
    isRootCustomerPrelimInfoFetched: didFetchSucceed,
    didFetchRootCustomerPrelimInfoFail: didFetchFail,
    ...ownProps,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPrelimInfoOfAllCustomers: () => dispatch(createAction(FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaitForPrelimCustomerInfoComponent)
