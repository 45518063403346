/**
 * CODE_COMMENTS_85
 */
import {
  CUSTOMER_TYPES_DISTRIBUTOR,
} from '../../../../constants'

import {
  getCustomerObjectsOfAllRelatedToOrFrom,
  getCustomerIdsOfAllRelatedToOrFrom,
  getRelationshipObjectsOfAllRelatedToOrFrom,
  getRelationshipIdsOfAllRelatedToOrFrom,

  getMultipleCustomerPropsOfAllRelatedToOrFrom,
  getMultipleRelationshipPropsOfAllRelatedToOrFrom,
  getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom,

  getIsRelatedToOrFromAnyone,
} from './relatedToOrFromInfo'


const toOrFrom = 'to'

export const getCustomerObjectsOfAllRelatedTo = getCustomerObjectsOfAllRelatedToOrFrom(toOrFrom)
export const getCustomerIdsOfAllRelatedTo = getCustomerIdsOfAllRelatedToOrFrom(toOrFrom)
export const getRelationshipObjectsOfAllRelatedTo = getRelationshipObjectsOfAllRelatedToOrFrom(toOrFrom)
export const getRelationshipIdsOfAllRelatedTo = getRelationshipIdsOfAllRelatedToOrFrom(toOrFrom)

export const getMultipleCustomerPropsOfAllRelatedTo = getMultipleCustomerPropsOfAllRelatedToOrFrom(toOrFrom)
export const getMultipleRelationshipPropsOfAllRelatedTo = getMultipleRelationshipPropsOfAllRelatedToOrFrom(toOrFrom)
export const getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedTo =
  getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom(toOrFrom)

export const getIsRelatedToAnyone = getIsRelatedToOrFromAnyone(toOrFrom)


export const getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn = ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
}) => (
  getCustomerIdsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112,
    relationshipObjsCustomFilterFunc: o => o.localsOnlyRelationship,
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_DISTRIBUTOR,
  }).length > 0
)
