import React from 'react'
import get_ from 'lodash/get'
import { Icon, Popup } from 'semantic-ui-react'
import InputNoAutoComplete from '../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import { getHumanReadableContainerTypeFromItemSku } from '../../../utils'
import createAction from '../../../redux/actions/createAction'
import ContainerTypesDifferences from '../../ContainerTypesDifferences'
import {
  ORDER_KEGS_FORM_FIELD_LABEL_DATE_NEEDED,
  ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED, ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE,
} from '../../../constants/formAndApiUrlConfig/orderKegs'
import ReactDatePicker from '../../../common-components/rewrite/ReactDatepicker'
import {
  FIELD_LABEL_TOTAL_NUMBER_KEGS, FIELD_LABEL_TOTAL_NUMBER_PALLETS,
  FIELD_NAME_TOTAL_NUMBER_KEGS, FIELD_NAME_TOTAL_NUMBER_PALLETS,
} from '../../../constants/formAndApiUrlConfig/acknowledgeInboundShipments'
import { getDateFieldFencingProps } from '../OrderAndEditShared/components'
import { KEG_ORDERS_MIN_DAYS, ORDER_KEGS_ORDER_TYPE_LOCAL } from '../../../constants'
import XDaysFromNow from '../OrderAndEditShared/components/XDaysFromNow'

// import { createApiDateSortFunctionForHistoryTable } from '../../History/util'
// import { DEFAULT_DISPLAYED_DATE_FORMAT } from '../../../constants/formAndApiUrlConfig/commonConfig'

export const DELIVERY_SCHEDULE_FORM_VALUE = 'DELIVERY_SCHEDULE_FORM_VALUE'
export const DELIVERY_SCHEDULE_ORDER_TOTAL = 'DELIVERY_SCHEDULE_ORDER_TOTAL'
export const DELIVERY_SCHEDULE_ORDER_STATUS = 'status'
export const DELIVERY_SCHEDULE_ORDER_STATUS_OPEN = 'OPEN'
export const DELIVERY_SCHEDULE_FORM_ADD_DELETE_ROW = 'DELIVERY_SCHEDULE_FORM_ADD_DELETE_ROW'
export const ORDER_UPDATED_SUCCESSFULLY = 'Order updated successfully.'
export const ORDER_UPDATE_FAILED = 'Order update failed.'
export const ORDER_CANCELLED_SUCCESSFULLY = 'Order cancelled successfully.'
export const ORDER_CANCEL_FAILED = 'Order cancellation failed.'
export const ORDER_KEGS_NO_SUPPORT_FOR_ETA_MESSAGE = 'Unfortunately due to the number of requests to our phone line we will no longer be providing specific estimated time of arrival (ETA’s) for our deliveries. If your delivery is late, please email orders@kegstar.com and we will re-arrange another delivery ASAP. Thank you.'

const ContainerGroupByComponent = props => {
  const { formValues, customerContracts, entireCustomersSlice, customerId, index } = props
  const dateNeeded = formValues?.[DELIVERY_SCHEDULE_FORM_VALUE]?.[index]?.[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]
  return (
    <span style={{ display: 'flex', flexDirection: 'row' }}>
      {props.children}
      {dateNeeded && (
        <Popup trigger={<Icon color="red" name="info circle" style={{ position: 'relative', right: '10px', bottom: '20px', marginTop: 'auto' }} size="small" />}>
          <Popup.Content>
            <XDaysFromNow
              minDays={formValues?.[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE] !== ORDER_KEGS_ORDER_TYPE_LOCAL ?
                customerContracts?.minOrderLeadDays :
                KEG_ORDERS_MIN_DAYS?.[entireCustomersSlice?.[customerId]?.businessUnitId]
              }
              dateNeededInputValue={dateNeeded}
              orderType={formValues?.[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE]}
              minOrderLeadDays={customerContracts?.minOrderLeadDays}
              minOrderExpediteDays={customerContracts?.minOrderExpediteDays}
              maxOrderExpediteDays={customerContracts?.maxOrderExpediteDays}
              orderTimeOfDayCutOff={customerContracts?.orderTimeOfDayCutOff}
            />
          </Popup.Content>
        </Popup>
      )}
    </span>
  )
}
export function createFieldArrayDefinitions({
  customerId,
  itemSkuIds=[],
  entireItemSkusSlice,
  dispatchFormValues,
  entireCustomersSlice,
  customerContracts,
  translate,
}) {
  const itemSkuQtyFieldsDefinitions = itemSkuIds.map(itemSkuId => (
    {
      topLabel: getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice),
      fieldName: itemSkuId,
      formFieldProps: {
        required: true,
        style: { width: '120px', marginRight: '15px' },
      },
      fieldComponent: InputNoAutoComplete,
      fieldProps: ({
        formValues,
        rowIndex,
      }) => {
        const value_ = get_(
          formValues,
          [DELIVERY_SCHEDULE_FORM_VALUE, rowIndex, itemSkuId],
          '', // CODE_COMMENTS_245
        )
        return {
          placeholder: 'Qty',
          value: value_,
          onChange: (event, { value }) => {
            dispatchFormValues(createAction(
              DELIVERY_SCHEDULE_FORM_VALUE,
              { rowIndex, fieldName: itemSkuId, value: Number(value), entireItemSkusSlice },
            ))
          },
        }
      },
      arbitraryComponentAfterLabel: () => (
        <ContainerTypesDifferences
          customerId={customerId}
          itemSkuId={itemSkuId}
        />
      ),
    }
  ))

  return [
    {
      topLabel: translate(`orderKegs.${ORDER_KEGS_FORM_FIELD_LABEL_DATE_NEEDED}`),
      fieldName: ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED,
      formFieldProps: { required: true, style: { minWidth: '100px', marginRight: '15px' } },
      groupByFields: true,
      groupByLabel: '',
      groupByComponent: ContainerGroupByComponent,
      fieldComponent: ReactDatePicker,
      fieldProps: ({
        formValues,
        rowIndex,
      }) => ({
        ...getDateFieldFencingProps({
          entireCustomersSlice,
          customerId,
          customerContracts,
          formValues,
        }),
        compact: true,
        value: get_(
          formValues,
          [DELIVERY_SCHEDULE_FORM_VALUE, rowIndex, ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED],
          '', // CODE_COMMENTS_245
        ),
        onChange: value => {
          dispatchFormValues(createAction(
            DELIVERY_SCHEDULE_FORM_VALUE,
            { rowIndex, fieldName: ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED, value, entireItemSkusSlice },
          ))
        },
      }),
    },
    ...itemSkuQtyFieldsDefinitions,
    {
      topLabel: translate(`orderKegs.${FIELD_LABEL_TOTAL_NUMBER_KEGS}`),
      fieldName: FIELD_NAME_TOTAL_NUMBER_KEGS,
      formFieldProps: { style: { minWidth: '80px' } },
      noFieldJustText: true,
    },
    {
      topLabel: translate(`orderKegs.${FIELD_LABEL_TOTAL_NUMBER_PALLETS}`),
      fieldName: FIELD_NAME_TOTAL_NUMBER_PALLETS,
      formFieldProps: { style: { minWidth: '80px' } },
      noFieldJustText: true,
    },
  ]
}

export const standardOrderTypeSubsidaries = [21, 22, 24, 25, 26]
export const subsidiaries = {
  21: {
    name: 'KegStar Australia',
    days: 6,
    weekendDelivery: false,
  },
  22: {
    name: 'KegStar New Zealand',
    days: 6,
    weekendDelivery: false,
  },
  24: {
    name: 'KegStar UK',
    days: 6,
    weekendDelivery: false,
  },
  25: {
    name: 'KegStar Ireland',
    days: 6,
    weekendDelivery: false,
  },
}

// export const createDisplayedDateNeeded = row => formatApiDate(row.dateTimeNeeded, DEFAULT_DISPLAYED_DATE_FORMAT)
//
// export const createContainerFieldsDefinitions = ({
//   entireItemSkusSlice,
//   itemSkuIdsToIncludeAsFieldsInTable,
// }) => itemSkuIdsToIncludeAsFieldsInTable.map(itemSku => ({
//   heading: getHumanReadableContainerTypeFromItemSku(itemSku, entireItemSkusSlice),
//   cellContent: row => row?.itemSkuId === itemSku,
// }))
//
// export const createTableContentDefinitions = ({
//   entireItemSkusSlice,
//   itemSkuIdsToIncludeAsFieldsInTable,
//   includeLocalKegsColumns,
// }) => ({
//   content: [
//     {
//       heading: 'Date Needed',
//       cellContent: createDisplayedDateNeeded,
//       customSortInfo: {
//         sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateNeeded' }),
//         sortApiObjectValuesRatherThanCellContents: true,
//       },
//       className: 'testDateNeeded', // CODE_COMMENTS_57
//     },
//     ...createContainerFieldsDefinitions({
//       entireItemSkusSlice,
//       itemSkuIdsToIncludeAsFieldsInTable,
//       includeLocalKegsColumns,
//     }),
//   ],
// })
