import React from 'react'


import { useTranslation } from 'react-i18next'
import {
  getDoAnyFormRowsHaveUnresolvedPotentialDuplicates,
} from '../util/potentialDuplicates'

import {
  createFieldArrayDefinitions,
} from './util/fieldArrayDefinitions'

import {
  fieldArraysToApplyPotentialDuplicatesFeatureTo,
} from '../../../../util'

import NoDropdownOptionsForCustomersFieldThatMustBeChosenFirstMessage from '../NoDropdownOptionsForCustomersFieldThatMustBeChosenFirstMessage'
import PotentialDuplicatePopup from '../PotentialDuplicatePopup'

import FormSectionAsTable from '../../../../../../common-components/rewrite/formAsTable/FormSectionAsTable'


export default props => {
  const {
    customerId,
    isEditForm,

    // from the fieldArraysAndCustomersFieldsDropdownOptions object
    fieldArrayName,
    customersFieldsDropdownOptions,
    whichCustomersFieldMustBeChosenFirstSourceOrDestination,
    arbitraryContentAfterAddRowButton,
    // CODE_COMMENTS_244
    potentialDuplicates,
  } = props
  const { t: translate } = useTranslation('pagelabels')
  const shouldRenderSubmissionColumnAlternatePurpose = (
    fieldArraysToApplyPotentialDuplicatesFeatureTo.includes(fieldArrayName)
    && getDoAnyFormRowsHaveUnresolvedPotentialDuplicates({ potentialDuplicates })
  )

  if (customersFieldsDropdownOptions.length === 0) {
    return (
      <NoDropdownOptionsForCustomersFieldThatMustBeChosenFirstMessage
        whichCustomersFieldMustBeChosenFirstSourceOrDestination={
          whichCustomersFieldMustBeChosenFirstSourceOrDestination
        }
        customerId={customerId}
      />
    )
  }
  return (
    <FormSectionAsTable
      fieldDefinitions={createFieldArrayDefinitions({
        translate,
        customerId,
        ...props,
      })}
      fieldArrayName={fieldArrayName}
      abilityToAddAndDeleteRows={!isEditForm}
      arbitraryContentAfterAddRowButton={arbitraryContentAfterAddRowButton}
      // CODE_COMMENTS_244
      shouldRenderSubmissionColumnAlternatePurpose={shouldRenderSubmissionColumnAlternatePurpose}
      submissionColumnAlternatePurposeRowRenderer={PotentialDuplicatePopup}
      submissionColumnAlternatePurposeColumnTitle="Duplicates?"
      {...props}
    />
  )
}
