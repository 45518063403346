/* eslint-disable no-restricted-syntax */ // CODE_COMMENTS_50

import { call, all, select } from 'redux-saga/effects'

import {
  withPrelimCustomerInfoFetchStartedAndFinishedFlags,
  callMultipleFetchesAtTheSameTime,
} from '../util'

import {
  fetchRelatedToInfoOfCustomer,
  fetchCollarPlatesOfCustomerNoCustomerTypeCheck,
  fetchPotentialDuplicatesForReportShipmentsForms,
} from '../fetchIndividualEntityTypes'

import {
  getProp,
} from '../../../../selectors/customers'


import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../selectors/rewrite/contracts'
import {
  getCustomerIdsOfAllContracteesOfContractBrewer,
} from '../../../../selectors/rewrite/relationships/relatedFromInfo'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../selectors/rewrite/relationships/relatedToOrFromInfo'

import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../../../constants'


export function* fetchPrelimInfoOfAllContracteeBrewersIfCustomerPassedInIsAContractBrewer(customerId) {
  const customerType = yield select(getProp, customerId, 'customerType')
  if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    // failures here will NOT result in the failure of the
    // wrapping Preliminary Info saga
    yield call(fetchPrelimInfoOfAllContracteeBrewers, customerId)
  }
}

// Only Contract Brewers call this
export function* fetchPrelimInfoOfAllContracteeBrewers(operatingContractBrewerCustomerId) {
  const state = yield select()
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const currentlyActiveContracteeBrewerIds = getCustomerIdsOfAllContracteesOfContractBrewer({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId: operatingContractBrewerCustomerId,
  })
  // CODE_COMMENTS_50
  for (const contracteeBrewerId of currentlyActiveContracteeBrewerIds) {
    // We use 'call' here so that we fetch the prelim info of each contractee
    // brewer in sequence rather than in parallel. This is important because AWS
    // lambda api calls need to be made one at a time for performance reasons.
    yield call(
      // eslint-disable-next-line no-use-before-define
      fetchPrelimInfoOfContracteeBrewer,
      contracteeBrewerId,
      operatingContractBrewerCustomerId,
    )
  }
}

/**
 * See the docstring of fetchPrelimInfoOfAnyCustomerTypeBesidesContracteeBrewer. This saga
 * fetches less information than the
 * fetchPrelimInfoOfAnyCustomerTypeBesidesContracteeBrewer saga and should only be invoked
 * on Brewers that a logged-in Contract Brewer will be operating on behalf of.
 * The reason this is a higher-order function that returns a generator is
 * because we need to pass the contractBrewerCustomerId argument to the
 * generator
 */
export const fetchPrelimInfoOfContracteeBrewer = withPrelimCustomerInfoFetchStartedAndFinishedFlags(
  function* fetchPrelimInfoOfContracteeBrewerWrappedSaga(customerId, operatingContractBrewerCustomerId) {
    // Here you can invoke any sagas whose failure should NOT result in the
    // failure of the wrapping Preliminary Info saga. See CODE_COMMENTS_89.
    yield call(
      fetchCollarPlatesOfCustomerNoCustomerTypeCheck,
      customerId,
      operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    )

    // invoke any sagas here whose failure should result in the failure of the
    // wrapping Preliminary Info saga.
    yield (
      // CODE_COMMENTS_21
      function* makeFetchesThatShouldResultInFailureOfWrappingSaga(custId, operatingCbCustId) {
        // Put all sagas here that can happen in parallel
        yield all([
          call(
            fetchRelatedToInfoOfCustomer,
            {
              customerId: custId,
              operatingContractBrewerCustomerId: operatingCbCustId,
              preventsDashboardFromLoadingIfFails: true,
            },
          ),
        ])
      }(customerId, operatingContractBrewerCustomerId)
    )
    yield call(
      makeFetchesThatMustHappenAfterAllPrelimInfoHasBeenFetched,
      customerId,
      operatingContractBrewerCustomerId,
    )
  },
)


// Put all fetches here that require that prelim info be fetched (e.g. children,
// addresses, relationships) before they can run.
function* makeFetchesThatMustHappenAfterAllPrelimInfoHasBeenFetched(
  customerId,
  operatingContractBrewerCustomerId,
) {
  yield callMultipleFetchesAtTheSameTime({
    // Put all fetches in here that can run _after_ the dashboard has loaded, as
    // the user starts using the app.
    generatorsOfNonBlockingSagasWhoseFailureWontResultInTheFailureOfTheWrappingSaga: [
      // Why are we using call() here? Isn't call() a blocking function? We want
      // these to be run at the same time as the functions within the `yield
      // all(...)` below, so shouldn't we be using put() here instead of call()?
      // No, because the functions called by these `call()`s are themselves
      // non-blocking (i.e. they use put()).
      call( // CODE_COMMENTS_244
        fetchPotentialDuplicatesForReportShipmentsForms,
        customerId,
        operatingContractBrewerCustomerId,
      ),
    ],
    // Intentionally an empty array, which ensures that the wrapping saga is
    // non-blocking.
    generatorsOfBlockingSagasWhoseFailureWillResultInTheFailureOfTheWrappingSaga: [],
  })
}
