import React from 'react'
import { Container, Menu, Image, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { Trans, useTranslation } from 'react-i18next'
import microStarLogo from '../../../../public/imagesAndIcons/logos/logo-blue.svg'
import {
  URL_PATHS,
} from '../../../../constants'

export default ({
  isLogoutButtonEnabled, // CODE_COMMENTS_41
  logout,
  currentUserFirstName,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Container id="navbar">
      <Menu
      attached
      >
        <Logo />
        <Menu.Item as={Link} to={URL_PATHS.employeeRoot.path} id="navbarHomeLink">
          <span className="undisplayed-in-phone-portrait-view">
            <Trans ns="common" i18nKey="Home">Home</Trans>
          </span>
          <Icon name="home" className="displayed-only-in-phone-portrait-view" />
        </Menu.Item>
        <Menu.Item as={Link} to={URL_PATHS.employeeOperateAsCustomerUser.path} id="navbarContactRepLink">
          <Trans ns="common" i18nKey="Operate as Customer User">Operate as Customer User</Trans>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item className="borderless undisplayed-in-phone-portrait-view" style={{ fontStyle: 'italic' }} id="customizedGreeting">
            <span>{`${translate('Hi')},`}</span>
            {/* non-breaking space; {' '} doesn't work */}
            {'\u00A0'}
            <span style={{ color: 'green', fontWeight: 'bold' }}>{`${currentUserFirstName} (Employee)`}</span>
          </Menu.Item>
          <Menu.Item onClick={isLogoutButtonEnabled ? logout : null} id="navbarLogoutLink">
            <Trans ns="common" i18nKey="Logout">Logout</Trans>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </Container>
  )
}


const Logo = () => (
  <Link to={URL_PATHS.employeeRoot.path} id="navbarHomeLinkLogo">
    <Menu.Item>
      <Image src={microStarLogo} alt="logo" />
    </Menu.Item>
  </Link>
)
