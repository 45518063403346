import React from 'react'


import HasPermissions from '../../../../../../common-components/HasPermissions'

import {
  ManageUsersOption,
  ManageContactsAndSubscriptionsOption,
  ManageAddressesOption,
  AcknowledgeInboundShipmentsOption,
  ReportInventoryOption,
} from '../../shared/IndividualOptions'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
} from '../../../../../../constants/permissions'

import {
  TEMPORARILY_DISABLE_CONTACTS_FEATURE,
} from '../../../../../../config'

// CODE_COMMENTS_39
export default () => ([
  <HasPermissions
    key="AcknowledgeInboundShipmentsOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS}
    renderIfHasPermissions={
      () => (
        <AcknowledgeInboundShipmentsOption />
      )
    }
  />,
  <HasPermissions
    key="ReportInventoryOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR}
    renderIfHasPermissions={
        () => (
          <ReportInventoryOption isDistributor isMaintenanceFacility />
        )
    }
  />,
  <HasPermissions
    key="ManageUsersOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
    renderIfHasPermissions={
        () => (
          <ManageUsersOption />
        )
    }
  />,
    ...(!TEMPORARILY_DISABLE_CONTACTS_FEATURE
      ? [
        <HasPermissions
          key="ManageContactsAndSubscriptionsOption"
          requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS}
          renderIfHasPermissions={
            () => (
              <ManageContactsAndSubscriptionsOption />
            )
          }
        />,
      ]
    : []
    ),
  <HasPermissions
    key="ManageAddressesOption"
    requiredPermissions={FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES}
    renderIfHasPermissions={
        () => (
          <ManageAddressesOption />
        )
    }
  />,
])
