/* eslint-disable max-len */

import React from 'react'


import PublicFormFailuresContent from '../../../common-components/FormSubmission/PublicFormFailuresContent'
import DismissableMessageClearingFetchStatus from '../../../common-components/FormSubmission/PublicFormFailuresContent/DismissableMessageClearingFetchStatus'
import RevealContactInfo from '../../../common-components/RevealContactInfo'

import {
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW,
} from '../../../constants'

import {
  doesAnyObjectContainString,
} from '../../../utils'


export default Component => props => {
  const {
    didFetchFail,
    isFetching,
    formName,
  } = props

  const customFailureRenderInstructions = formName === REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW
    ? customFailureRenderInstructionsForResetPwForm(formName)
    : customFailureRenderInstructionsForConfirmAccountForm(formName)

  if (didFetchFail && !isFetching) {
    return ([
      <PublicFormFailuresContent
        key="failure message"
        formName={formName}
        customFailureRenderInstructions={customFailureRenderInstructions}
      />,
      <Component key="form" {...props} />,
    ])
  }
  return <Component {...props} />
}

/*
 * *****************************************************************************
 * custom failure render instructions
 * *****************************************************************************
*/

function customFailureRenderInstructionsForResetPwForm(formName) {
  return [
    // CODE_COMMENTS_115
    invalidConfirmationCodeRenderInstructions(
      formName,
      'Incorrect confirmation code, please try again.',
    ),
    accountExpiredRenderInstructions(formName),
  ]
}


function customFailureRenderInstructionsForConfirmAccountForm(formName) {
  return [
    // CODE_COMMENTS_115
    invalidConfirmationCodeRenderInstructions(
      formName,
      'Incorrect email address or confirmation code, please try again (you must use the email address the confirmation code was sent to).',
    ),
    accountExpiredRenderInstructions(formName),
  ]
}


// Render instructions of individual specific features

function invalidConfirmationCodeRenderInstructions(formName, errorMessageDisplayedToUser) {
  return {
    when: ({ errorCode, errorMessage, responseBody }) => {
      if (errorCode !== 400) { return false }
      return (
        doesAnyObjectContainString({ obj: errorMessage, str: 'Invalid Verification Code' }) ||
        doesAnyObjectContainString({ obj: responseBody, str: 'Invalid Verification Code' })
      )
    },
    render: () => (
      <DismissableMessageClearingFetchStatus
        formName={formName}
        content={
          <p>{errorMessageDisplayedToUser}</p>
        }
      />
    ),
  }
}


function accountExpiredRenderInstructions(formName) {
  return {
    when: ({ errorCode, errorMessage, responseBody }) => {
      if (errorCode !== 400) { return false }
      return (
        doesAnyObjectContainString({ obj: errorMessage, str: 'expired' }) ||
        doesAnyObjectContainString({ obj: responseBody, str: 'expired' })
      )
    },
    render: () => (
      <DismissableMessageClearingFetchStatus
        formName={formName}
        content={
          <div>
            <p>Your confirmation code has expired, contact us to request a new code.</p>
            <RevealContactInfo asPopup />
          </div>
        }
      />
    ),
  }
}
