import { connect } from 'react-redux'


import CustomerPortal from './components'


import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../../../../redux/selectors/rewrite/parentChildLinks'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../../../redux/selectors/rewrite/users'

import {
  getAreAnyContracteeBrewersRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn,
} from '../../../../../redux/selectors/rewrite/multipleStoreSlicesSelectors'

import {
  getShouldReportKegFillsFormBeRendered,
  getShouldReportSelfCollectionShipmentsFormBeRendered,
} from '../../../../../redux/selectors/rewrite/forms/reportShipmentsCommon/shouldReportShipmentsFormBeRendered'

import {
  getShouldReportInventoryFormBeRendered,
} from '../../../../ReportInventory/util'

import { getProp as getCustomerProp } from '../../../../../redux/selectors/customers'


const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)
  const currency = getCustomerProp(state, customerId, 'currency')


  const shouldReportKegFillsFormBeRendered = getShouldReportKegFillsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })
  const shouldReportSelfCollectionShipmentsFormBeRendered = getShouldReportSelfCollectionShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })


  const isRelatedToLocalDistributors =
    getAreAnyContracteeBrewersRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      onlyRelationshipsThatShipmentsCanBeReportedOn: true,
    })

  const shouldReportInventoryFormBeRendered = getShouldReportInventoryFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    customerId,
  })

  return {
    // Why Booleans? See CODE_COMMENTS_252
    shouldReportKegFillsFormBeRendered: Boolean(shouldReportKegFillsFormBeRendered),
    shouldReportSelfCollectionShipmentsFormBeRendered: Boolean(shouldReportSelfCollectionShipmentsFormBeRendered),
    isRelatedToLocalDistributors: Boolean(isRelatedToLocalDistributors),
    shouldReportInventoryFormBeRendered: Boolean(shouldReportInventoryFormBeRendered),
    currency,
  }
}

export default connect(
  mapStateToProps,
)(CustomerPortal)
