/* eslint-disable */

import { put, call, takeEvery } from 'redux-saga/effects'
import { delay } from 'redux-saga'


import { privateFetch } from '../../fetch'
import {
  FETCH_DELETE_STORED_BANK_ACCOUNT_INFO,
  DELETE_STORED_BANK_ACCOUNT_INFO_FROM_REDUX_STORE,
  FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_REQUEST,
  FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_SUCCESS,
  FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_FAILURE,
} from '../../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'

import {
  URL_PARAM_CUSTOMER_ID,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/bankAccountInfo'

import {
  API_URL_PATH_STORED_BANK_ACCOUNT_INFO,
} from '../../../../constants'

import {
  TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE,
} from '../../../../config'


export function* fetchDeleteStoredBankAccountInfo(action) {
  if (TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE) {
    return
  }
  const {
    customerId,
  } = action.payload

  yield put(createAction(
    FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_REQUEST,
    {
      target: [customerId],
    },
  ))

  const fetchConfig = {
    path: API_URL_PATH_STORED_BANK_ACCOUNT_INFO,
    method: 'DELETE',
    params: {
      [URL_PARAM_CUSTOMER_ID]: customerId,
    },
  }

  try {
    yield call(delay, 1*1000)
    // yield call(
    //   privateFetch,
    //   fetchConfig,
    // )
  } catch (error) {
    yield call(doFailure, { customerId, error })
    return
  }
  yield call(doSuccess, customerId)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_DELETE_STORED_BANK_ACCOUNT_INFO, fetchDeleteStoredBankAccountInfo],
]


function* doSuccess(customerId) {
  yield put(createAction(DELETE_STORED_BANK_ACCOUNT_INFO_FROM_REDUX_STORE, { customerId }))
  yield put(createAction(
    FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_SUCCESS,
    {
      target: [customerId],
    },
  ))
}

function* doFailure({ customerId, error }) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_DELETE_STORED_BANK_ACCOUNT_INFO_FAILURE,
        target: [customerId],
      }),
    },
  )
}
