/**
 * CODE_COMMENTS_62
 * CODE_COMMENTS_134
 */
import React from 'react'


import {
  DisplayedOriginNameForKegFillsContracteeHistoryTable,
} from './displayedKegFillsContracteeDataConnectedComponents'


export const createDisplayedOriginNameForKegFillsContracteeHistoryTable = (row, { customerId }) => (
  <DisplayedOriginNameForKegFillsContracteeHistoryTable
    customerId={customerId}
    row={row}
  />
)
