import { connect } from 'react-redux'


import OrderAndEditShared from '../components'

import {
  getEntireSlice as getEntireCollarPlatesSlice,
  getEntireCustomerCollarPlateLinksSlice,
} from '../../../../redux/selectors/rewrite/collarPlates'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireAddressesSlice,
} from '../../../../redux/selectors/rewrite/addresses'
import {
  getEntireSlice as getEntireCustomerAddressLinksSlice,
} from '../../../../redux/selectors/rewrite/customerAddressLinks'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../redux/selectors/rewrite/currentUser'


const mapStateToProps = state => {
  const entireCollarPlatesSlice = getEntireCollarPlatesSlice(state)
  const entireCustomerCollarPlateLinksSlice = getEntireCustomerCollarPlateLinksSlice(state)
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)

  const entireAddressesSlice = getEntireAddressesSlice(state)
  const entireCustomerAddressLinksSlice = getEntireCustomerAddressLinksSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)

  return {
    entireCollarPlatesSlice,
    entireCustomerCollarPlateLinksSlice,
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
  }
}


export default connect(
  mapStateToProps,
)(OrderAndEditShared)
