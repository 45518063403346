/**
 * CODE_COMMENTS_15
*/
import { select, put, call, takeEvery } from 'redux-saga/effects'


import {
  validateCustomerObjectReceivedFromCustomersCall,
} from '../util/apiResponseDataValidation/customers'

import {
  getIsCustomerInfoSavedForACustomer,
} from '../../selectors/customers'

import { privateFetch } from '../fetch'
import { getCustomerApiUrl } from '../../../utils'
import {
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  SAVE_CUSTOMER,
} from '../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  ApiReturnedUnexpectedDataError,
} from '../../../customErrors'


export function* fetchCustomerInfo(action) {
  const {
    customerId,
    preventsDashboardFromLoadingIfFails,
  } = action.payload
  // If the user already has customer info saved for it, don't re-fetch it.
  // When would this happen, that the customer we're trying to fetch customer
  // info for already has its customer info saved? The only time this happens
  // is when the customer is a child of a master customer we just received
  // preliminary info for. When we called /children/objects on the master
  // customer, we got the customer info of all its children.
  if (yield select(getIsCustomerInfoSavedForACustomer, customerId)) { return }

  yield put(createAction(FETCH_CUSTOMER_REQUEST, { target: [customerId] }))
  let response
  try {
    response = yield call(privateFetch, { path: getCustomerApiUrl(customerId) })
  } catch (error) {
    yield call(
      doFailure,
      {
        customerId,
        error,
        preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
      },
    )
    return
  }
  yield call(doSuccess, customerId, response, response.data)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CUSTOMER, fetchCustomerInfo],
]

// Why do we export this? Because the customers/children saga needs it
export function* doSuccess(customerId, response, customerObj) {
  const {
    validatedData: info,
    canDataBeSavedToStore,
  } = yield call(
    validateCustomerObjectReceivedFromCustomersCall,
    {
      httpResponse: response,
      data: customerObj,
    },
  )
  if (!canDataBeSavedToStore) {
    throw new ApiReturnedUnexpectedDataError() // CODE_COMMENTS_180
  }

  yield put(createAction(SAVE_CUSTOMER, { info }))
  yield put(createAction(FETCH_CUSTOMER_SUCCESS, { target: [customerId] })) // CODE_COMMENTS_20
}

function* doFailure({ customerId, error, preventsDashboardFromLoading }) {
  yield call(
    generalDoFailure,
    {
      error,
      preventsDashboardFromLoading,
      action: createFetchFailureAction({
        error,
        type: FETCH_CUSTOMER_FAILURE,
        target: [customerId],
      }),
    },
  )
}
