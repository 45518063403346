/**
 * CODE_COMMENTS_134
 */

import { connect } from 'react-redux'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'

import {
  getOriginNameForOutboundShipmentsHistoryTable,
} from './util'


const mapStateToProps = (
  state,
  {
    customerId,
    operatingContractBrewerCustomerId,
    row,
  },
) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const displayedText = getOriginNameForOutboundShipmentsHistoryTable({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    operatingContractBrewerCustomerId,
    row,
  })
  return {
    displayedText,
  }
}


export const DisplayedOriginNameForOutboundShipmentsHistoryTable = connect(
  mapStateToProps,
)(({ displayedText }) => displayedText)
