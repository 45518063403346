import React, { Fragment, useEffect } from 'react'
import { Segment, Form, Button, Loader, Checkbox } from 'semantic-ui-react'


import InformationalPopup from '../../../../common-components/InformationalPopup'
import DeleteBankAccount from '../DeleteBankAccount'

const SaveBankAccountInfoUIWrapper = Component => props => {
  const {
    hasStoredBankAccountInfo,
    saveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
    useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
    updateSavedBankAccountIfUseNewBankAccount,
    isFetching,
    didFetchFail,
  } = props
  let segmentColor
  if (didFetchFail) {
    segmentColor = 'red'
  } else if (
    isFetching
    || (
      !hasStoredBankAccountInfo
      && !saveNewBankAccountInfoIfNoBankAccountInfoYetSaved
    )
    || (
      hasStoredBankAccountInfo
      && useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved === 'new'
      && !updateSavedBankAccountIfUseNewBankAccount
    )
  ) {
    segmentColor = null
  } else {
    segmentColor = 'green'
  }

  return (
    <Segment color={segmentColor}>
      <Component {...props} />
    </Segment>
  )
}


export default SaveBankAccountInfoUIWrapper(({
  customerId,

  saveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
  onToggleSaveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
  useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
  onSetUseSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
  updateSavedBankAccountIfUseNewBankAccount,
  onToggleUpdateSavedBankAccountIfUseNewBankAccount,

  hasStoredBankAccountInfo,
  bankName,
  endingDigitsOfBankAccount,

  didFetchFail: didFetchingSavedBankAccountInfoFail,
  isFetching: isFetchingSavedBankAccountInfo,
  refetchSavedBankAccountInfo,
}) => {
  // If the fetching of the user's saved bank account fails, we want to set
  // saveNewBankAccountInfoIfNoBankAccountInfoYetSaved to false (let's say the
  // user has a saved bank account but the fetching of bank accounts isn't
  // currently working--we don't want to accidentally override their saved bank
  // account with whatever bank account they happen to use this time.)
  useEffect(
    () => {
      onToggleSaveNewBankAccountInfoIfNoBankAccountInfoYetSaved(!didFetchingSavedBankAccountInfoFail)
      // always set to save whether or not the fetch failed
      onSetUseSavedOrNewBankAccountIfBankAccountInfoAlreadySaved('meaninglessVariable', { value: 'saved' })
      // This one isn't strictly necessary because if the fetch fails, we'll
      // set saveNewBankAccountInfoIfNoBankAccountInfoYetSaved to false, which
      // renders the updateSavedBankAccountIfUseNewBankAccount prop meaningless,
      // we simply do it here just to be extra safe.
      onToggleUpdateSavedBankAccountIfUseNewBankAccount(!didFetchingSavedBankAccountInfoFail)
    },
    [
      didFetchingSavedBankAccountInfoFail,
      onToggleSaveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
      onSetUseSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
      onToggleUpdateSavedBankAccountIfUseNewBankAccount,
    ],
  )

  if (isFetchingSavedBankAccountInfo) {
    return (
      <div>
        <Loader active inline size="tiny" style={{ marginRight: '0.5rem' }} />
        <span>Fetching saved bank account...</span>
      </div>
    )
  }

  if (didFetchingSavedBankAccountInfoFail) {
    return (
      <div>
        <span
          className="semantic-ui-error-text-color"
          style={{ paddingRight: '1rem' }}
        >Failed to Fetch Saved Bank Account</span>
        <Button
          color="blue"
          onClick={refetchSavedBankAccountInfo}
          className="reduced-padding-button"
          size="small"
        >
          Retry
        </Button>
      </div>
    )
  }

  if (!hasStoredBankAccountInfo) {
    return (
      <Fragment>
        <Checkbox
          label="Save My Bank Account for Future Use"
          checked={saveNewBankAccountInfoIfNoBankAccountInfoYetSaved}
          onChange={onToggleSaveNewBankAccountInfoIfNoBankAccountInfoYetSaved}
        />
        <InformationalPopup
          content="When you click Pay, the bank account details you enter on the next page will be securely stored for your convenience when making future payments. You can update or delete your bank account details at any time."
        />
      </Fragment>
    )
  }

  // The user has saved bank account info
  return (
    <Fragment>
      <Form.Field>
        <div
          // So we can float the "Delete Bank Account" icon right:
          // https://stackoverflow.com/a/36606694
          style={{ display: 'flex' }}
        >
          <div>
            <Checkbox
              radio
              label={`Use ${bankName.toUpperCase()} ...${endingDigitsOfBankAccount}`}
              name='checkboxRadioGroup'
              value='saved'
              checked={useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved === 'saved'}
              onChange={onSetUseSavedOrNewBankAccountIfBankAccountInfoAlreadySaved}
            />
            <InformationalPopup
              content="When you click Pay, the form fields on the next page will be pre-populated with this bank account."
            />
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <DeleteBankAccount
              customerId={customerId}
            />
          </div>
        </div>
      </Form.Field>
      <Form.Field>
        <Checkbox
          radio
          label='Enter New Bank Account'
          name='checkboxRadioGroup'
          value='new'
          checked={useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved === 'new'}
          onChange={onSetUseSavedOrNewBankAccountIfBankAccountInfoAlreadySaved}
        />
        <InformationalPopup
          content="When you click Pay, the form fields on the next page will be blank, allowing you to use a different bank account than the one currently saved."
        />
      </Form.Field>
      {useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved === 'new' &&
        <Form.Field
          style={{
            paddingLeft: '1.85714em',
          }}
        >
          <Checkbox
            label="Update Saved Bank Account"
            checked={updateSavedBankAccountIfUseNewBankAccount}
            onChange={onToggleUpdateSavedBankAccountIfUseNewBankAccount}
            disabled={useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved !== 'new'}
          />
          <InformationalPopup
            content="We allow only one bank account at a time to be saved, so check this box to update your saved bank account with the new bank account you'll be using. By unchecking, your saved bank account will remain unchanged."
          />
        </Form.Field>
      }
    </Fragment>
  )
})
