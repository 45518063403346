// See CODE_COMMENTS_238. This thread does one thing and one thing only: if
// we're currently in maintenance mode, poll the backend every minute or so, and
// when we get something other than a 503, take the app out of maintenance mode.
// Why not, instead, examine the maintenance mode schedule from the Redux Store,
// wait until the Maintenance Mode is over, then take the app out of maintenance
// mode? There are two problems with this:

// 1. What happens if the maintenance schedule has a typo in it? For instance,
// What happens if the maintenance window is a year and 3 hours long rather than
// just 3 hours long? If we don't regularly poll the backend, the web app will
// stay in maintenance mode virtually indefinitely.

// 2. What happens if the backend goes into Maintenance Mode without any
// warning, without any scheduled maintenance window associated with it? This
// probably won't happen often, but it will happen in emergencies, and when it
// does, there will be no information to tell us when we'll be out of
// maintenance mode, so polling is our only option.

// When in maintenance mode, The only time this thread won't poll the backend is
// when we're in the first few minutes of a scheduled maintenance window; this
// gives the backend a little breathing room, some time to be able to set itself
// into maintenance mode without being hounded immediately by the frontend
// asking "are we out of maintenance mode yet?!"

/* eslint-disable no-continue */

import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'
import moment from 'moment'


import {
  getAreWeCurrentlyInMaintenanceMode,
  getMaintenanceSchedule,
  getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow,
} from '../../selectors/maintenanceMode'

import {
  START_POLL_TO_GET_OUT_OF_MAINTENANCE_MODE_THREAD,
  SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE,
  LOGOUT,
} from '../../actions/actionTypes'
import createAction from '../../actions/createAction'

import {
  fetchNewUserToken,
  FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED,
  FETCH_NEW_USER_TOKEN_RESULT_SITE_UNDER_MAINTENANCE,
} from '../keepUserLoggedIn/util/fetchNewUserToken'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'

import {
  POLL_BACKEND_EVERY_X_MINUTES_WHILE_IN_MAINTENANCE_MODE,
  DONT_POLL_BACKEND_FOR_THE_FIRST_X_MINUTES_OF_A_SCHEDULED_MAINTENANCE_WINDOW,
  CHECK_EVERY_X_SECONDS_WHETHER_TO_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE,
} from '../../../config'


// Wait, shouldn't this be a cancelable saga which gets canceled on logout or
// authentication failure? Yes, but instead of using the cancelableForkedSaga()
// function here, we let our main rootSaga() wrap this with a
// transformSagasIntoCancellableSagas() function.
function* pollToGetOutOfMaintenanceModeOnceWereInMaintenanceMode() {
  while (true) {
    const areWeCurrentlyInMaintenanceMode = yield select(getAreWeCurrentlyInMaintenanceMode)
    if (areWeCurrentlyInMaintenanceMode) {
      // If we're at the very start of a scheduled maintenance window, don't bug
      // the backend asking if we're out of maintenance mode yet; maybe we
      // haven't even gotten into maintenance mode in the first place. Give the
      // backend a few minutes before you start polling it.
      const maintenanceSchedule = yield select(getMaintenanceSchedule)
      const startAndEndTimesOfSoonestUpcomingMaintenanceWindow = getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow({
        maintenanceSchedule,
        includeMaintenanceWindowsWereCurrentlyIn: true,
      })
      if (isTruthyAndNonEmpty(startAndEndTimesOfSoonestUpcomingMaintenanceWindow)) {
        const { start } = startAndEndTimesOfSoonestUpcomingMaintenanceWindow
        if (moment().isBetween(
          start,
          start.clone().add(DONT_POLL_BACKEND_FOR_THE_FIRST_X_MINUTES_OF_A_SCHEDULED_MAINTENANCE_WINDOW, 'minutes'),
        )) {
          yield call(
            delay,
            CHECK_EVERY_X_SECONDS_WHETHER_TO_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE*1000,
          )
          continue
        }
      }

      // The next line of code kills two birds with one stone: first, it makes a
      // call to the backend to see if we're still getting a 503 "currently in
      // maintenance mode" error. Any call will suffice for this task, because
      // all endpoints will return a 503 when we're in maintenance mode. But
      // we've picked fetchNewUserToken because it serves a second purpose: it
      // refreshes the user token of a logged-in user once we finally do get out
      // of maintenance mode. This is important because maintenance mode periods
      // usually last longer than 1 hour, so most of the time, a logged-in
      // user's current userToken will be expired by the time maintenance mode
      // is finished.
      const result = yield call(fetchNewUserToken)
      if (result === FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED) {
        yield put(createAction(SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE, false))
        // hard-reload the app; we do this in case a new version of the web app
        // has been deployed during maintenance mode, which is a strong
        // likelyhood.
        window.location.reload()
        return
      } else if (result === FETCH_NEW_USER_TOKEN_RESULT_SITE_UNDER_MAINTENANCE) {
        yield call(delay, POLL_BACKEND_EVERY_X_MINUTES_WHILE_IN_MAINTENANCE_MODE*1000*60)
      } else {
        // Fetching a new user token has failed for a reason other than
        // maintenance mode
        yield put(createAction(LOGOUT))
        return
      }
    } else {
      yield call(delay, POLL_BACKEND_EVERY_X_MINUTES_WHILE_IN_MAINTENANCE_MODE*1000*60)
    }
  }
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    START_POLL_TO_GET_OUT_OF_MAINTENANCE_MODE_THREAD,
    pollToGetOutOfMaintenanceModeOnceWereInMaintenanceMode,
  ],
]
