import React from 'react'

import orderBy_ from 'lodash/orderBy'


import HistoryTableCreator from '../../../HOC/HistoryTableCreator'
import ShipmentDetails from '../subFeatures/ShipmentDetails'

import {
  getIsSubsidiaryATypeThatShouldTrackForeignKegs,
} from '../../../../../../redux/selectors/rewrite/subsidiaries'

import {
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_SOURCE,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_CARRIER,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_FOREIGN_KEGS,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_DELIVERY_DATE,
} from '../../../../../../constants/formAndApiUrlConfig/histories/InboundEmptyKegShipmentHistory'

import {
  createDisplayedShipmentType,
  createDisplayedCarrierName,
  createDisplayedSourceName,
  createDisplayedShipmentDate,
  createDisplayedDeliveryDateForTable,
  createDisplayedProNumber,
  createContainerTypesFieldsDefinitions,
  createDisplayedForeignKegs, createDisplayedMovementType,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'

import {
  getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
  getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable,
  getDisplayedHumanReadableStatusOfInboundEmptyKegShipment,
} from '../../../util/shipmentHistory/inboundEmptyKegShipmentHistory'

import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail,
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'

import {
  sortByApiDate,
  sortByDateString,
} from '../../../../../../utils'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../../../constants/formAndApiUrlConfig/commonConfig'
import KegOrderContainer from '../../../KegOrderHistory/Table/subFeatures/containers/KegOrderContainer'
import { CUSTOMER_TYPES_WAREHOUSE } from '../../../../../../constants'
// This function is already defined in src/features/History/util.js, so why are
// we re-defining it here? See CODE_COMMENTS_284.
function createApiDateSortFunctionForHistoryTable({
  datePropName,
}) {
  return (rowsArray, ascOrDesc='desc') => (
    sortByApiDate({
      arr: rowsArray,
      nestedPath: [datePropName],
      descending: ascOrDesc === 'desc',
    })
  )
}


// This function is already defined in src/features/History/util.js, so why are
// we re-defining it here? See CODE_COMMENTS_284.
function createDateStringSortFunctionForHistoryTable({
  datePropName,
  dateFormat=DEFAULT_DISPLAYED_DATE_FORMAT,
  sortNonDateStringsAToZInsteadOfThrowingError=true,
}) {
  return (rowsArray, ascOrDesc='desc') => (
    sortByDateString({
      arr: rowsArray,
      nestedPath: [datePropName],
      dateFormat,
      ascending: ascOrDesc === 'asc',
      sortNonDateStringsAToZInsteadOfThrowingError,
    })
  )
}

function createTableContentDefinitions({
  entireCustomersSlice,
  entireItemSkusSlice,
  entireSubsidiariesSlice,
  configuredItemSkuIds,
  customerId,
  allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
  isShipmentSection,
}) {
  // Do not add null checks to the next line of code: the entireCustomersSlice
  // should _always_ have a [customerId] field, and a customer should _always_
  // have a subsidiaryId field. If it doesn't, we want the app to fail fast and
  // fail loudly, otherwise we'll never know there's a problem.
  const subsidiaryId = entireCustomersSlice[customerId].subsidiaryId
  const useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged = true
  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
  })

  return {
    content: [
      {
        heading: 'Shipment #',
        cellContent: row => (
          <ShipmentDetails
            entireCustomersSlice={entireCustomersSlice}
            entireItemSkusSlice={entireItemSkusSlice}
            entireSubsidiariesSlice={entireSubsidiariesSlice}
            customerId={customerId}
            subsidiaryId={subsidiaryId}
            info={row}
            itemSkuIdsToIncludeAsFieldsInTable={determineItemSkuIdsToIncludeAsFieldsInShipmentsDetail({
              entireItemSkusSlice,
              shipment: row,
              useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
            })}
          />
        ),
        customSortInfo: {
          sortFunction: (apiObjs, ascOrDesc) => (orderBy_(apiObjs, ['shipmentId'], [ascOrDesc])),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testShipmentNumber', // CODE_COMMENTS_57
      },
      ...[
        ...(!isShipmentSection ? [{
          heading: 'Order #',
          cellContent: row => (
            <KegOrderContainer
              row={row}
              itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
              includeLocalKegsColumns
              customerId={customerId}
            />
          ),
          className: 'testOrderId', // CODE_COMMENTS_57
        }] : []),
      ],
      (entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ? {
        heading: 'Shipment Type',
        cellContent: createDisplayedShipmentType,
        className: 'testShipmentType', // CODE_COMMENTS_57
      } :
        {
          heading: 'Movement Type',
          cellContent: createDisplayedMovementType,
          className: 'testMovementType', // CODE_COMMENTS_57
        }),
      {
        heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_CARRIER,
        cellContent: createDisplayedCarrierName,
        className: 'testCarrier', // CODE_COMMENTS_57
      },
      {
        heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_SOURCE,
        cellContent: createDisplayedSourceName,
        customSortInfo: {
          columnNotSortable: true, // CODE_COMMENTS_248
        },
        className: 'testSource', // CODE_COMMENTS_57
      },
      {
        heading: 'Ship Date',
        cellContent: createDisplayedShipmentDate,
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        className: 'testShipmentDate', // CODE_COMMENTS_57
      },
      {
        heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_DELIVERY_DATE,
        cellContent: createDisplayedDeliveryDateForTable,
        customSortInfo: {
          sortFunction: createDateStringSortFunctionForHistoryTable({
            datePropName: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_DELIVERY_DATE,
          }),
          sortApiObjectValuesRatherThanCellContents: false,
        },
        className: 'testDeliveryDate', // CODE_COMMENTS_57
      },
      {
        heading: 'Status',
        cellContent: getDisplayedHumanReadableStatusOfInboundEmptyKegShipment,
        className: 'inboundStatus', // CODE_COMMENTS_57
      },
      {
        heading: 'Reference #',
        cellContent: createDisplayedProNumber(),
        className: 'testReferenceNumber', // CODE_COMMENTS_57
      },
      ...createContainerTypesFieldsDefinitions({
        entireItemSkusSlice,
        itemSkuIdsToIncludeAsFieldsInTable,
        useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
      }),
      ...(
        getIsSubsidiaryATypeThatShouldTrackForeignKegs({
          entireSubsidiariesSlice,
          subsidiaryId,
        })
          ? [
            {
              heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_FOREIGN_KEGS,
              cellContent: createDisplayedForeignKegs(),
              className: 'testForeignKegs', // CODE_COMMENTS_57
            },
          ]
          : []
      ),
    ],
    defaultSortColumn: 'Ship Date',
    filterFunc: getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable,
    rowKey: row => row.id,
  }
}

export default ({
  entireCustomersSlice,
  entireItemSkusSlice,
  entireSubsidiariesSlice,
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  configuredItemSkuIds,
  allInboundEmptyKegShipmentObjects,
  isFormSubmitting,
  hasFormSubmitFailed,
  noResultsMessage,
  areAnyItemsEditable,
  getIsindividualItemEditable,
  isShipmentSection,
}) => {
  const allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable(
      allInboundEmptyKegShipmentObjects,
    )
  const definitions = createTableContentDefinitions({
    entireCustomersSlice,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    configuredItemSkuIds,
    customerId,
    allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
    isShipmentSection,
  })

  return (
    <HistoryTableCreator
      tableInfoObj={allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable}
      definitions={definitions}
      isFormSubmitting={isFormSubmitting}
      hasFormSubmitFailed={hasFormSubmitFailed}
      additionalPropsToPassToTableCells={{
        customerId,
        operatingContractBrewerCustomerId,
      }}
      noResultsMessage={noResultsMessage}

      // for Edit and Delete buttons column
      areAnyItemsEditable={areAnyItemsEditable}
      itemType="inbound empty keg shipment"
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      getIsindividualItemEditable={getIsindividualItemEditable}
    />
  )
}
