/* eslint-disable no-restricted-syntax */

import { call, takeEvery } from 'redux-saga/effects'

import {
  fetchReportIndividualShipment,
} from './reportIndividualShipment'

import {
  FETCH_REPORT_SHIPMENTS,
  FETCH_STATUSES_FORMS_QUEUE,
} from '../../../actions/actionTypes'

import createAction from '../../../actions/createAction'

import {
  getIsFieldRowFullyFilledOut,
} from '../../../../features/ReportShipments/util'

import {
  getAllFieldArrayNamesInReportShipmentsForm,
} from './util'


function* fetchReportShipments(action) {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    entireItemSkusSlice,
    fieldArraysAndCustomersFieldsDropdownOptions,
    formValues,
    dispatchFetchStatuses,
    itemSkuIds,
  } = action.payload

  // Set all rows that are going to be fetched to 'queued'

  // CODE_COMMENTS_50
  for (const fieldArrayName of getAllFieldArrayNamesInReportShipmentsForm(formValues)) {
    for (const [rowIndex, rowValues] of formValues[fieldArrayName].entries()) {
      // the user might have created array rows and left some empty
      if (getIsFieldRowFullyFilledOut({
        rowValues,
        fieldArrayName,
        entireItemSkusSlice,
      })) {
        dispatchFetchStatuses(createAction(
          FETCH_STATUSES_FORMS_QUEUE,
          { target: [fieldArrayName, rowIndex] }),
        )
      }
    }
  }

  // Now submit each row in sequential order (no parallel requests)
  for (const fieldArrayName of getAllFieldArrayNamesInReportShipmentsForm(formValues)) {
    for (const [rowIndex, rowValues] of formValues[fieldArrayName].entries()) {
      // the user might have created array rows and left some empty
      if (getIsFieldRowFullyFilledOut({
        rowValues,
        fieldArrayName,
        entireItemSkusSlice,
      })) {
        yield call(fetchReportIndividualShipment, { payload: {
          fieldArrayName,
          fieldArraysAndCustomersFieldsDropdownOptions,
          rowIndex,
          customerId,
          operatingContractBrewerCustomerId, // CODE_COMMENTS_88
          rowValues,
          dispatchFetchStatuses,
          itemSkuIds,
        } })
      }
    }
  }
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REPORT_SHIPMENTS, fetchReportShipments],
]
