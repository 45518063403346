import React from 'react'
import { Form } from 'semantic-ui-react'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import ReactDatepicker from '../ReactDatepicker'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../constants/formAndApiUrlConfig/commonConfig'

import {
  validateAndNormalizeDate,
} from '../../../utils'


export default ({
  createDateRangeMin,
  createDateRangeMax,

  currentLowValue,
  currentHighValue,

  onChangeLowValue,
  onChangeHighValue,

  onSetIsLowValuevalid,
  onSetIsHighValuevalid,

  dateRangeLowFieldName,
  dateRangeHighFieldName,

  dateRangeFieldsLabel='Date Range',
}) => {
  const { t: translate } = useTranslation('common')
  const lowMax = validateAndNormalizeDate(currentHighValue)
    ? moment(validateAndNormalizeDate(currentHighValue), DEFAULT_DISPLAYED_DATE_FORMAT)
    : createDateRangeMax()

  const highMin = validateAndNormalizeDate(currentLowValue)
    ? moment(validateAndNormalizeDate(currentLowValue), DEFAULT_DISPLAYED_DATE_FORMAT)
    : createDateRangeMin()

  return ([
    <Form.Field
      key="low"
      inline
    >
      <label htmlFor={dateRangeLowFieldName}>{`${translate(dateRangeFieldsLabel)}:`}</label>
      {/* CODE_COMMENTS_32 */}
      <div className="ui input">
        <ReactDatepicker
          value={currentLowValue}
          onChange={onChangeLowValue}
          onSetIsValueValid={onSetIsLowValuevalid}
          minDate={createDateRangeMin()}
          maxDate={lowMax}
          name={dateRangeLowFieldName}
          compact
          showMonthDropdown
          showYearDropdown
        />
      </div>
    </Form.Field>,

    <Form.Field
      key="high"
      inline
    >
      <label htmlFor={dateRangeHighFieldName}>-</label>
      {/* CODE_COMMENTS_32 */}
      <div className="ui input">
        <ReactDatepicker
          value={currentHighValue}
          onChange={onChangeHighValue}
          onSetIsValueValid={onSetIsHighValuevalid}
          minDate={highMin}
          maxDate={createDateRangeMax()}
          name={dateRangeHighFieldName}
          compact
          showMonthDropdown
          showYearDropdown
        />
      </div>
    </Form.Field>,
  ])
}
