import keys_ from 'lodash/keys'
import get_ from 'lodash/get'

import {
  drillDownInObject,
} from '../../../utils'
import { NestedPropNotInObjectError } from '../../../customErrors'


export const getEntireSlice = (...slices) => (state, ...entities) => {
  try {
    return drillDownInObject(state, ...slices, ...entities)
  } catch (e) {
    if (e instanceof NestedPropNotInObjectError) { return undefined }
    throw e
  }
}


/**
 * A higher-order function that acts as a surrogate for a getter function
 * that might look like:
 *
 * const getCustomerType = (state, customerId) => (
 *   state[defaultSlice].byCustomerId[customerId].customerType
 * )
 *
 * With this higher-order function, all you have to do is create the getter
 * once in the file:
 *
 * const withCustomer = withProp(defaultSlice, 'byCustomerId')
 *
 * Then, all your getters can be shorter and DRYer:
 *
 * getCustomerType = withCustomer('customerType')
 */
export const withProp = (...slices) => property => (state, ...entities) => (
  drillDownInObject(state, ...slices, ...entities, property)
)


// A higher-order function that helps create getters within a nested slice. For
// example, let's say your entire history slice looks like this:
//
// entireHistorySlice = {
//   historyKegOrders: {
//     byCustomerId: {
//       'customerId1': {
//         'kegOrderObject1': { orderIdNumber: 60052, dateOrdered: 1574276400000, ...},
//         'kegOrderObject2': { orderIdNumber: 60053, dateOrdered: 1574276500000, ...},
//       },
//       'customerId2': {
//         'kegOrderObject3': { orderIdNumber: 60127, dateOrdered: 1574277700000, ...},
//         'kegOrderObject4': { orderIdNumber: 60127, dateOrdered: 1574277800000, ...},
//       },
//     }
//   }
//   historyCollarOrders: {
//     ...
//   }
//   ...
// }
//
// If you want to get the date ordered of a specific keg order object, you could
// simply drill into the slice like this:
//
// entireHistorySlice.historyKegOrders.byCustomerId.customerId2.kegOrderObject4.dateOrdered
//
// The problem with this is that you as the caller must know the slice shape;
// you need to write out the 'byCustomerId' part. Instead, you can use
// withPropSimple to abstract away those details:
//
// const getKegOrderHistoryProp = withPropSimple('historyKegOrders', 'byCustomerId')
//
// const dateOrdered = getKegOrderHistoryProp(entireHistorySlice, 'customerId', 'kegOrderId' 'dateOrdered')
export const withPropSimple = (...slices) => (state, ...entitiesAndProp) => (
  drillDownInObject(state, ...slices, ...entitiesAndProp)
)


// Like withPropSimple except it won't throw an error if the nested prop isn't
// in the object; rather it will return undefined
export const withPropOrUndefinedSimple = (...slices) => (state, ...entitiesAndProp) => {
  try {
    return drillDownInObject(state, ...slices, ...entitiesAndProp)
  } catch (e) {
    if (e instanceof NestedPropNotInObjectError) {
      return undefined
    }
    throw e
  }
}


/**
 * Similar to the withProp() HOF above except that instead of returning a value from the
 * store, it compares a store value with another value
 */
export const withPropEquals = (...slices) => (property, compareValue) => (state, ...entities) => (
  drillDownInObject(state, ...slices, ...entities, property) === compareValue
)


/*
 * *****************************************************************************
 * HOFs for normalized slices
 * *****************************************************************************
*/


// like withProp except for normalized Redux slices, therefore we don't need as
// much nesting. You can use this to get the prop of a specific entiry with
//
// withPropNormalized(entireCustomersSlice, customerId, 'somePropName')
//
// Or you can get the entire customerObject with:
//
// withPropNormalized(entireCustomersSlice, customerId)
export const withPropNormalized = (...slices) => (state, ...paths) => (
  drillDownInObject(state, ...slices, ...paths)
)

// Like withPropNormalized except if the nested prop doesn't exist, return a
// default value rather than throwing an error.
export const withPropOrNormalized = (...slices) => (state, id, prop, defaultValueIfPathNotFound=undefined) => (
  get_(state, [...slices, id, prop], defaultValueIfPathNotFound)
)

// withPropOfAll for normalized Redux slices
export const withPropOfAllNormalized = (...slices) => (state, prop) => {
  const targetSlice = drillDownInObject(state, ...slices)
  return keys_(targetSlice).map(key => targetSlice[key][prop])
}

// withMultiplePropsOfAll for normalized Redux slices
export const withMultiplePropsOfAllNormalized = (...slices) => (state, ...props) => {
  const targetSlice = drillDownInObject(state, ...slices)
  return keys_(targetSlice).map(key => (
    props.reduce((acc, prop) => ({
      [prop]: targetSlice[key][prop],
      ...acc,
    }), {})
  ))
}
