// CODE_COMMENTS_241

/* eslint-disable no-restricted-syntax */ // CODE_COMMENTS_50

import { call, takeEvery } from 'redux-saga/effects'


import { fetchUniversalCustomerRepresentative } from './fetchUniversalCustomerRepresentative'

import {
  FETCH_ALL_UNIVERSAL_CUSTOMER_REPRESENTATIVES,
} from '../../actions/actionTypes'

import {
  ALL_UNIVERSAL_CUSTOMER_REPS,
} from '../../../constants'


function* fetchAllUniversalCustomerRepresentatives() {
  // CODE_COMMENTS_50
  for (const repType of ALL_UNIVERSAL_CUSTOMER_REPS) {
    // fetch them one at a time
    yield call(fetchUniversalCustomerRepresentative, { payload: { repType } })
  }
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    FETCH_ALL_UNIVERSAL_CUSTOMER_REPRESENTATIVES,
    fetchAllUniversalCustomerRepresentatives,
  ],
]
