import React from 'react'

/**
 * renders the EitherComponent if the conditionalRenderingFn returns true,
 * otherwise returns the Component. Taken from
 * https://www.robinwieruch.de/gentle-introduction-higher-order-components/
 */
export default (conditionalRenderingFn, EitherComponent) => Component => props => (
  conditionalRenderingFn(props)
    ? <EitherComponent {...props} />
    : <Component {...props} />
)
