// CODE_COMMENTS_274

import { useRef } from 'react'


export default function useScrollProgrammatically() {
  const scrollToThisElementRef = useRef(null)
  const scrollFunc = () => {
    // setTimeout is necessary because often the element you want to scroll to
    // doesn't exist at the moment scrollFunc is called (for example, when the
    // user clicks the Submit button on a form, only after the click does a
    // dimmer overlay with submission status gets rendered, containing the text
    // you want to scroll to).
    setTimeout(
      () => {
        scrollToThisElementRef?.current?.scrollIntoView({
          // Scroll so that the content is in the center of the screen
          block: 'center',
        })
      },
      // Experience has told us that we don't actually need to wait any amount
      // of time--that putting the callback into the event loop queue is
      // enough--but this could change depending on how this hook gets used in
      // the future, so we set this to 10 (1/100th of a second) rather than 0
      // just in case.
      10,
    )
  }

  return [scrollToThisElementRef, scrollFunc]
}
