/* eslint-disable max-len */

import React from 'react'
import { Container, Header, Message } from 'semantic-ui-react'
import moment from 'moment-timezone'


import {
  getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow,
} from '../../../redux/selectors/maintenanceMode'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'


export default ({
  shouldNotificationOfUpcomingMaintenanceBeDisplayed,
  maintenanceSchedule,
}) => {
  if (!shouldNotificationOfUpcomingMaintenanceBeDisplayed) {
    return null
  }
  const startAndEndTimesOfSoonestUpcomingMaintenanceWindow = getStartAndEndTimesOfSoonestUpcomingMaintenanceWindow({
    maintenanceSchedule,
    includeMaintenanceWindowsWereCurrentlyIn: true,
  })
  // This should never happen, but just in case
  if (!isTruthyAndNonEmpty(startAndEndTimesOfSoonestUpcomingMaintenanceWindow)) {
    return null
  }

  return (
    <Container>
      <Message warning>
        <Header as="h2">Upcoming Maintenance</Header>
        <p>TAP3 will be undergoing scheduled maintenance on <span style={{ fontWeight: 'bold' }}>{getDisplayedMaintenanceWindowRangeString(startAndEndTimesOfSoonestUpcomingMaintenanceWindow)}</span>, during which time all features will be disabled.</p>
      </Message>
    </Container>
  )
}


// Helper functions

// If same day: 10/28/2019 6:00pm - 9:00pm PST
// If spans a day: 10/28/2019 6:00pm - 10/29/2019 9:00pm PST
function getDisplayedMaintenanceWindowRangeString({
  start,
  end,
}) {
  // https://stackoverflow.com/a/29226671/6995996 - If the user is in
  // California, we want to display e.g. "10/28/2019 6:00pm - 8:00pm PST", but if
  // they're in New York we want to display "10/28/2019 9:00pm - 11:00pm EST"
  const startLocal = start.tz(moment.tz.guess())
  const endLocal = end.tz(moment.tz.guess())
  const isSameDay = startLocal.format('M/D/YYYY') === endLocal.format('M/D/YYYY')
  return isSameDay
    ? `${startLocal.format('M/D/YYYY h:mma')} - ${endLocal.format('h:mma')} ${endLocal.format('z')}`
    : `${startLocal.format('M/D/YYYY h:mma')} - ${endLocal.format('M/D/YYYY h:mma')} ${endLocal.format('z')}`
}
