import { connect } from 'react-redux'

import Help from './components'

import {
  getProp as getCurrentUserProp,
} from '../../redux/selectors/currentUser'


const mapStateToProps = state => {
  const customerId = getCurrentUserProp(state, 'rootCustomerId')
  return ({
    customerId,
  })
}

export default connect(
  mapStateToProps,
)(Help)
