import {
  getIsRentalCustomer,
} from '../../redux/selectors/rewrite/customers'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../redux/selectors/rewrite/permissions'

import {
  getDoesBrewerHaveCustomerLevelPermission,
  getDoesContractBrewerHaveCustomerLevelPermission,
} from '../../redux/selectors/rewrite/permissionsAtTheCustomerLevel'

import {
  getCustomerIdsOfAllRelatedTo,
} from '../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getHasContractThatCanHaveKegsOrderedOnIt,
} from '../../redux/selectors/rewrite/contracts'

import {
  getCanAnyRelationshipBetweenTwoCustomersHaveShipmentsReportedOnIt,
} from '../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'

import {
  FIELD_NAME_KEG_COLLAR_CHOICE,
  FIELD_NAME_KEG_COLLAR_AMOUNT,
  KEG_COLLAR_AMOUNTS_IN_INCREMENTS_OF,
} from '../../constants/formAndApiUrlConfig/orderKegCollars'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_COLLARS,
  FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_ORDER_COLLARS,
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS,
} from '../../constants/permissions'

import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../constants'

import {
  isTruthyAndNonEmpty,
  doSelectObjPropsAllExistAndContainTruthyValues,
  doesAtLeastOneObjPropExistAndContainATruthyValue,
} from '../../utils'
import { getSubsidiaryKegCollarFeature } from '../../redux/selectors/rewrite/subsidiaries'

/*
 * *****************************************************************************
 * Are keg collars values filled out?
 * *****************************************************************************
*/

export function getIsFieldRowFullyFilledOut(rowValues) {
  if (!isTruthyAndNonEmpty(rowValues)) { return false }

  // make sure all required fields are filled out
  if (!doSelectObjPropsAllExistAndContainTruthyValues(
    rowValues,
    [
      FIELD_NAME_KEG_COLLAR_CHOICE,
      FIELD_NAME_KEG_COLLAR_AMOUNT,
    ],
  )) { return false }

  // make sure the keg collars amount field is a multiple of the required amounts increment
  if (rowValues[FIELD_NAME_KEG_COLLAR_AMOUNT]%KEG_COLLAR_AMOUNTS_IN_INCREMENTS_OF) {
    return false
  }

  return true
}


export function getIsFieldRowPartiallyFilledOut(rowValues) {
  if (!isAtLeastOneFieldFilledOut(rowValues)) { return false }
  return !getIsFieldRowFullyFilledOut(rowValues)
}


function isAtLeastOneFieldFilledOut(rowValues) {
  if (!isTruthyAndNonEmpty(rowValues)) { return false }
  return doesAtLeastOneObjPropExistAndContainATruthyValue(rowValues, [
    FIELD_NAME_KEG_COLLAR_CHOICE,
    FIELD_NAME_KEG_COLLAR_AMOUNT,
  ])
}


/*
 * *****************************************************************************
 * Should Order Keg Collars Form be rendered
 * *****************************************************************************
*/

// Returns an array, empty if can't ship to any customers.

// There are several reasons why this function will return an empty array. The
// obvious ones are:

// * A Brewer has no permissions to order keg collars;

// * A Contract Brewer operating for a contractee has no perCustomerPermissions
// to order kegs for that contractee.

// However, there are other, less obvious reasons:

// * A Brewer doesn't brew for themselves (i.e. they have no Default Brewing
// contract) _and_ they have no customer-level permissions (see
// CODE_COMMENTS_168) to order keg collars and ship them to any of their
// Contract Brewers (see CODE_COMMENTS_203);

// Also, Customers who are on a Credit Hold aren't allowed to order keg collars.
// So why aren't we checking whether the contractee is on a credit hold and
// disabling "Order Keg Collars" if so? See CODE_COMMENTS_125.
export function getWhichCustomersCanKegCollarsBeShippedTo({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId,
}) {
  if (!getHasPermissionsToPerformFunctionality({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    functionalityStringOrPermissionsMap: operatingContractBrewerCustomerId
      ? FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_ORDER_COLLARS
      : FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_COLLARS,
    customerIdIfThisIsAPerCustomerPermissionsCheck: operatingContractBrewerCustomerId && customerId,
  })) {
    return []
  }

  // Rental customers can only ship to themselves
  if (getIsRentalCustomer(entireCustomersSlice, customerId)) {
    return [customerId]
  }

  if (operatingContractBrewerCustomerId) {
    const hasCustomerLevelPermissions = getDoesContractBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId: customerId,
      conbrwCustomerId: operatingContractBrewerCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS,
    })

    const isRelationshipBetweenContracteeAndOperatingCbCurrentlyActiveAndNotDisabled =
    getCanAnyRelationshipBetweenTwoCustomersHaveShipmentsReportedOnIt({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      sourceCustomerId: customerId,
      destinationCustomerId: operatingContractBrewerCustomerId,
    })
    return (
      isRelationshipBetweenContracteeAndOperatingCbCurrentlyActiveAndNotDisabled &&
      hasCustomerLevelPermissions
    )
      ? [
        // CONBRWs operating on behalf of a contractee are allowed to ship
        // collars both to themselves and to their contractee Brewer. But the
        // vast majority of the time, a CONBRW will want to ship kegs to
        // themselves, where the kegs are (kegs often never go to a contractee's
        // address), so we want to make sure that the default chosen address is
        // the CONBRW address. We achieve that by the ordering in this returned
        // list: operatingContractBrewerCustomerId is first, the contractee is
        // second.
        operatingContractBrewerCustomerId,
        customerId,
      ]
      : []
  }

  const canOrderCollarsForOwnBrewery = getHasContractThatCanHaveKegsOrderedOnIt({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  })

  const relatedToContractBrewerIds = getCustomerIdsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    // Brewers are allowed to report outbound shipments from an inactive CB's
    // location, but they're not allowed to ship keg collars to an inactive CB
    onlyRelationshipsThatShipmentsCanBeReportedOn: true,
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
  })


  const cbIdsBrewerHasPermissionToShipCollarsTo = relatedToContractBrewerIds.filter(conbrwCustomerId => (
    getDoesBrewerHaveCustomerLevelPermission({
      entireRelationshipsSlice,
      entireCustomersSlice,
      entireContractsSlice,
      brwCustomerId: customerId,
      conbrwCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS,
    })
  ))

  // BRWs who don't brew for themselves _can_ ship collars to their own location
  // rather than to their CONBRW locations, but this is extremely rare, so make
  // sure that in such scenarios, the BRW's address is not the default chosen.
  // The way we do this is by being very careful about the sorted order of the
  // returned list.
  const shouldBrwAddressesBePutFirstRatherThanLast = canOrderCollarsForOwnBrewery

  return [
    ...(shouldBrwAddressesBePutFirstRatherThanLast ? [customerId] : []),
    ...cbIdsBrewerHasPermissionToShipCollarsTo,
    ...(!shouldBrwAddressesBePutFirstRatherThanLast ? [customerId] : []),
  ]
}


// function isExcludedBasedOnBusinessUnit({ entireCustomersSlice, customerId, excludedBusinessUnits }) {
//   return excludedBusinessUnits?.includes(entireCustomersSlice?.[customerId]?.businessUnitId)
// }

// For use with things like the Customer Portal
export function getShouldOrderKegCollarsFormBeRendered({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId,
  entireSubsidiariesSlice,
}) {
  // if (isExcludedBasedOnBusinessUnit({ entireCustomersSlice, customerId, excludedBusinessUnits: ['KS'] })) {
  //   return false
  // }
  if (!getSubsidiaryKegCollarFeature({
    entireSubsidiariesSlice,
    subsidiaryId: entireCustomersSlice?.[customerId]?.subsidiaryId,
  })) {
    return false
  }
  return isTruthyAndNonEmpty(getWhichCustomersCanKegCollarsBeShippedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    operatingContractBrewerCustomerId,
  }))
}
