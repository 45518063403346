import values_ from 'lodash/values'
import orderBy_ from 'lodash/orderBy'
import get_ from 'lodash/get'
import has_ from 'lodash/has'

import flow_ from 'lodash/fp/flow'
import valuesFp_ from 'lodash/fp/values'
import mapFp_ from 'lodash/fp/map'
import filterFp_ from 'lodash/fp/filter'


import {
  addEditAndDeleteButtonsColumnToDefinition,
} from './createEditAndDeleteButtonsColumnInHistoryTable'

import {
  isTruthyAndNonEmpty,
} from '../../../../../../utils'


// This constant is already defined in src/features/History/util.js, so why are
// we re-defining it here? See CODE_COMMENTS_284.
const IS_SPECIAL_TABLE_ROW_HISTORY_OBJECT = 'IS_SPECIAL_TABLE_ROW_HISTORY_OBJECT'


export function createTableContent({
  tableInfoObj,
  definitions,
  additionalPropsToPassToTableCells,
  itemType,
  customerId,
  operatingContractBrewerCustomerId,
  areAnyItemsEditable,
  getIsindividualItemEditable,
  dontIncludeDeleteButton,
  // For things like the NoMovements objects within the Shipments history tables
  specialInfoObj,
  specialTableContentDefinitions,
  // local state props
  sortColumn,
  sortDirection,
}) {
  const definitions_ = areAnyItemsEditable
    ? addEditAndDeleteButtonsColumnToDefinition({
      definitions,
      itemType,
      customerId,
      operatingContractBrewerCustomerId,
      getIsindividualItemEditable,
      additionalPropsToPassToTableCells,
      dontIncludeDeleteButton,
    })
    : definitions

  let rows = createRows({
    tableInfoObj,
    specialInfoObj,
    specialTableContentDefinitions,
  })

  // Sort the table now if it's supposed to be sorted by API Objects rather than
  // cell contents. See CODE_COMMENTS_247 for why definitions.content
  // might not be truthy.
  if (isTruthyAndNonEmpty(definitions_.content)) {
    const defOfSortedRow = definitions_.content.find(def => def.heading === sortColumn)
    if (defOfSortedRow.customSortInfo) {
      const { sortFunction, sortApiObjectValuesRatherThanCellContents } = defOfSortedRow.customSortInfo
      if (sortApiObjectValuesRatherThanCellContents) {
        rows = sortFunction(rows, sortDirection)
      }
    }
  }

  if (definitions_.filterFunc) {
    rows = rows.filter(row => {
      if (row[IS_SPECIAL_TABLE_ROW_HISTORY_OBJECT]) { return true }
      return definitions_.filterFunc(row)
    })
  }

  const tableHeadings = definitions_.content.map(r => ({
    heading: r.heading,
    className: r.className,
    additionalSuiTableCellProps: r.additionalSuiTableCellProps,
  }))

  let rowsContent = []
  const rowsKeys = []
  let totalsRow = definitions_.content.reduce(
    (acc, def) => ({
      ...acc,
      [def.heading]: undefined,
    }),
    {},
  )

  rows.forEach(row => {
    const rowContent = definitions_.content.reduce(
      (acc, def) => {
        let cellContent
        if (row[IS_SPECIAL_TABLE_ROW_HISTORY_OBJECT]) {
          const customCellContentFunction = get_(specialTableContentDefinitions, [def.heading, 'cellContent'])
          if (customCellContentFunction) {
            cellContent = customCellContentFunction(row, additionalPropsToPassToTableCells)
          } else {
            try {
              // It's too difficult to chase all the different exceptions that
              // might arise from a special row not rendering properly because
              // we're calling a normal row's render function on it. The
              // simplest thing to do is simply catch all errors and render an
              // empty value.
              cellContent = def.cellContent(row, additionalPropsToPassToTableCells)
            } catch (e) {
              cellContent = null
            }
          }
        } else {
          cellContent = def.cellContent(row, additionalPropsToPassToTableCells)
        }
        return {
          ...acc,
          [def.heading]: cellContent,
        }
      },
      {},
    )

    const additionalRowContent = definitions_.content?.filter(e => typeof e.additionalCellContent === 'function').reduce(
      (acc, def) => {
        let additionalCellContent
        if (typeof def.additionalCellContent === 'function') {
          additionalCellContent = def.additionalCellContent(row, additionalPropsToPassToTableCells)
        }
        return {
          ...acc,
          [def.heading]: additionalCellContent,
        }
      },
      {},
    )

    totalsRow = Object.entries(rowContent).reduce(
      (acc, [heading_, cellContent]) => {
        if (definitions_.content.some(o => o.heading === heading_ && o.includeInTotalsRow)) {
          const currentTotal = totalsRow[heading_] || 0 // if undefined, set to 0
          // If the cell content is not a number, count it as 0
          const cellContentAsNumber = isNaN(cellContent) ? 0 : Number(cellContent)
          const newTotal = currentTotal + cellContentAsNumber
          return { ...acc, [heading_]: newTotal }
        }
        return { ...acc, [heading_]: undefined }
      },
      {},
    )

    if (Object.keys(additionalRowContent || {})?.length) {
      Object.entries(additionalRowContent)?.forEach(([key, value]) => {
        if (rowContent?.[key] && definitions_.content.every(e => e.heading === key && !['hidden'].includes(e.className))) {
          rowContent[key] = [rowContent[key], value]
        } else {
          rowContent[key] = value
        }
      })
    }
    rowsContent.push(rowContent)
    rowsKeys.push(definitions_.rowKey(row))
  })


  // Sort the table now if it's supposed to be sorted by cell contents rather
  // than API Objects. See CODE_COMMENTS_247 for why definitions.content might
  // not be truthy
  if (isTruthyAndNonEmpty(definitions_.content)) {
    const defOfSortedRow = definitions_.content.find(def => def.heading === sortColumn)
    if (defOfSortedRow.customSortInfo) {
      const { sortFunction, sortApiObjectValuesRatherThanCellContents } = defOfSortedRow.customSortInfo
      if (!sortApiObjectValuesRatherThanCellContents) {
        rowsContent = sortFunction(rowsContent, sortDirection)
      }
    } else {
      rowsContent = orderBy_(rowsContent, [sortColumn], [sortDirection])
    }
  }


  // right now rowsContent is an array of arrays of 2-item objects:
  // [
  //   { 'Order #': 'MKM-O-11111', 'Order Date': '11/20/2019', ... },
  //   { 'Order #': 'MKM-O-11112', 'Order Date': '11/21/2019', ... },
  //   ...
  // ]
  // We've needed to keep it this way in order to properly sort it above. Now
  // that it's sorted, convert it to an array of arrays, keeping only values:
  // [
  //  ['MKM-O-1111', '11/20/2019', ...],
  //  ['MKM-O-1112', '11/21/2019', ...],
  //  ...
  // ]
  const orderedColumnNames = definitions_.content.map(o => o.heading)
  const mainTableRows = rowsContent.map((rowContentAsObj, rowIndex) => {
    const rowContentAsArray = orderedColumnNames.map(columnName => rowContentAsObj[columnName])
    return {
      rowKey: rowsKeys[rowIndex],
      rowContent: rowContentAsArray,
    }
  })

  // Right now totalsRow is an array of objects, convert to single array
  totalsRow = orderedColumnNames.map(columnName => totalsRow[columnName])

  // If not a single column should be part of the "totals" row
  if (totalsRow.every(item => item === undefined)) {
    totalsRow = undefined
  }

  return {
    tableHeadings,
    mainTableRows,
    totalsRow,
  }
}


function createRows({
  tableInfoObj,
  specialInfoObj={},
  specialTableContentDefinitions={},
}) {
  const ordinaryRows = values_(tableInfoObj) // convert obj with subobjects to array of objects

  const transformationFunctionsOfSpecialRows = flow_(
    valuesFp_,
    filterFp_(o => has_(o, 'transformationsThatNeedToHappenOnRowInOrderToProperlySortOrRenderCellContent')),
    mapFp_(o => o.transformationsThatNeedToHappenOnRowInOrderToProperlySortOrRenderCellContent),
  )(specialTableContentDefinitions)
  const specialRows = flow_(
    valuesFp_,
    mapFp_(o => transformationFunctionsOfSpecialRows.reduce(
      (acc, transformationFunc) => transformationFunc(acc),
      o,
    )),
    mapFp_(o => ({ ...o, [IS_SPECIAL_TABLE_ROW_HISTORY_OBJECT]: true })),
  )(specialInfoObj)

  return [
    ...ordinaryRows,
    ...specialRows,
  ]
}
