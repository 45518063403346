import {
  GENERIC_CONTACT_INFO_NAME,
} from '../config'

/*
 * *****************************************************************************
 * URL Paths
 * *****************************************************************************
*/

// Dynamic path segments
// -------------------------------------

// CODE_COMMENTS_46
export const URL_PATH_DPS_TEXT_CUSTOMER_ID = 'customerId'
export const URL_PATH_DPS_CUSTOMER_ID = `:${URL_PATH_DPS_TEXT_CUSTOMER_ID}`

// CODE_COMMENTS_46
export const URL_PATH_DPS_TEXT_ADDRESS_ID = 'addressId'
export const URL_PATH_DPS_ADDRESS_ID = `:${URL_PATH_DPS_TEXT_ADDRESS_ID}`

export const URL_PATH_DPS_TEXT_CONTACT_ID = 'contactId'
export const URL_PATH_DPS_CONTACT_ID = `:${URL_PATH_DPS_TEXT_CONTACT_ID}`
export const URL_PATH_DPS_TEXT_CUSTOMER_CONTACT_LINK_ID = 'customerContactLinkId'
export const URL_PATH_DPS_CUSTOMER_CONTACT_LINK_ID = `:${URL_PATH_DPS_TEXT_CUSTOMER_CONTACT_LINK_ID}`

export const URL_PATH_OPERATE_FOR_STRING = 'operate-for' // CODE_COMMENTS_37

export const URL_PATH_SPS_REPORT_EMPTY_KEGS = 'report-empty-kegs'
export const URL_PATH_SPS_REPORT_CASE_PALLET = 'constellationrecon'

// App URL paths
// -------------------------------------

// We put URL paths into a single object so that if a user manually navigates to
// an invalid, totally random URL, the breadcrumbs section knows which parts of
// the URL are potentially valid and which ones definitely aren't (breadcrumbs
// rely mainly on the current URL to determine what to render, but we don't want
// the breadcrumbs blindly parroting whatever the user puts in the URL, e.g.
// profanity). Also, we do it so that when the web app is extended to include
// more URLs in the future, simply adding the URLs here will automatically make
// the Breadcrumbs section work properly--no need to update any other variables
// or any part of the Breadcrumbs component.
export const URL_PATHS = {
  root: {
    path: '/',
    breadcrumbLabel: 'Home',
  },
  login: {
    path: '/login',
    breadcrumbLabel: 'Login',
  },
  forgotPassword: {
    path: '/forgot-password',
    breadcrumbLabel: 'Forgot Password',
  },
  confirmAccount: {
    path: '/confirm-account',
    breadcrumbLabel: 'Confirm Account',
  },
  contactRep: {
    path: '/contact-rep',
    breadcrumbLabel: 'Contact Rep',
  },
  help: {
    path: '/help',
    breadcrumbLabel: 'Help',
  },
  users: {
    path: '/users',
    breadcrumbLabel: 'Manage Users',
  },
  contacts: {
    path: '/contacts',
    breadcrumbLabel: 'Manage Contacts and Email Subscriptions',
  },
  addresses: {
    path: '/addresses',
    breadcrumbLabel: 'Addresses',
  },
  projections: {
    path: '/projections',
    breadcrumbLabel: 'Manage Projections',
  },
  orderKegs: {
    path: '/order-kegs',
    breadcrumbLabel: 'Order Kegs',
  },
  orderKegCollars: {
    path: '/order-keg-collars',
    breadcrumbLabel: 'Order Keg Collars',
  },
  reportOutboundShipments: {
    path: '/report-outbound-shipments',
    breadcrumbLabel: 'Report Outbound Shipments',
  },
  reportBuybackShipments: {
    path: '/report-buyback-movements',
    breadcrumbLabel: 'Report Buyback Shipments',
  },
  reportSelfDistAndPubShipments: {
    path: '/report-self-dist-and-pub-movements',
    breadcrumbLabel: 'Report Self-Distribution & Pub Shipments',
  },
  reportKegFills: {
    path: '/report-keg-fills',
    breadcrumbLabel: 'Report Keg Fills',
  },
  reportSelfCollectionShipments: {
    path: '/report-self-collection-shipments',
    breadcrumbLabel: 'Report Self-Collection for Reuse',
  },
  acknowledgeInboundShipments: {
    path: '/acknowledge-inbound-shipments',
    breadcrumbLabel: 'Acknowledge Inbound Shipments',
  },
  payBalance: {
    path: '/pay-balance',
    breadcrumbLabel: 'Pay Balance',
  },
  reportInventory: {
    path: '/report-inventory',
    breadcrumbLabel: 'Report Inventory',
  },
  reportedInventoryVsCalculatedInventory: {
    path: '/reported-vs-calculated-inventory',
    breadcrumbLabel: 'Reported Vs Calculated Inventory',
  },
  reportEmptyKegs: {
    path: `/${URL_PATH_SPS_REPORT_EMPTY_KEGS}`,
    breadcrumbLabel: 'Report Empty Kegs and Constellation Pallets',
  },

  // special paths
  operateFor: {
    path: `/${URL_PATH_OPERATE_FOR_STRING}`,
    breadcrumbLabel: null,
  },
  operateForCustomer: {
    path: `/${URL_PATH_OPERATE_FOR_STRING}/${URL_PATH_DPS_CUSTOMER_ID}`,
    breadcrumbLabel: null,
  },

  // employee paths
  employeeRoot: {
    path: '/employee',
    breadcrumbLabel: 'Home',
  },
  employeeOperateAsCustomerUser: {
    path: '/employee/operate-as-customer-user',
    breadcrumbLabel: 'Operate as Customer User',
  },

  // faux private paths; CODE_COMMENTS_267
  isUnsubscriptionNoLoginRequired: {
    path: `/contacts/${URL_PATH_DPS_CONTACT_ID}/unsubscribe`,
    breadcrumbLabel: null,
  },
  isDistReportInventoryNoLoginRequired: {
    path: `/${URL_PATH_SPS_REPORT_EMPTY_KEGS}/no-login`,
    breadcrumbLabel: null,
  },
  isDistReportConstellationNoLoginRequired: {
    path: `/${URL_PATH_SPS_REPORT_CASE_PALLET}/no-login`,
    breadcrumbLabel: null,
  },
  viewAllInvoices: {
    path: '/view-all-invoices',
    breadcrumbLabel: 'View All Invoices',
  },
  requestNewDistributor: {
    path: '/report-outbound-shipments/request-new-distributor',
    breadcrumbLabel: 'Add New Location',
  },
  requestNewDistributorBreadCrumb: {
    path: '/request-new-distributor',
    breadcrumbLabel: 'Add New Location',
  },
  manageAllContacts: {
    path: '/manage-contacts',
    breadcrumbLabel: 'Manage Contacts',
  },
}

// these variables are for dynamic links such as "take me up to the customer
// portal". The text they're assigned to is an arbitrary description; what
// matters is that the text is constant.
export const URL_PATH_DYNAMIC_LINKS_CUSTOMER_PORTAL = 'customer portal'


// API URL paths
// -------------------------------------

// CODE_COMMENTS_242; SPS stands for 'Static Path Segment': a part of a URL that
// never changes and never gets replaced with anything else by our code
const API_URL_SPS_API_GATEWAY_SHIPMENTS = '/shipping/v3'
const API_URL_SPS_API_GATEWAY_ADMIN = '/admin/v3'
const API_URL_SPS_API_GATEWAY_RELATIONSHIPS = '/relationships/v3'
const API_URL_SPS_API_GATEWAY_CONTRACTS = '/contracts/v3'
const API_URL_SPS_API_GATEWAY_COLLARS = '/collars/v3'
const API_URL_SPS_API_GATEWAY_INVENTORY = '/inventory/v3'
const API_URL_SPS_API_GATEWAY_ORDERS = '/orders/v3'
const API_URL_SPS_API_GATEWAY_CUSTOMERS = '/customers/v3'
const API_URL_SPS_API_GATEWAY_ADDRESSES = '/addresses/v3'
const API_URL_SPS_API_GATEWAY_FILES = '/files/v3'
const API_URL_SPS_API_GATEWAY_BILLING = '/billing/v3'
const API_URL_SPS_API_GATEWAY_USERS = '/users/v3'
const API_URL_SPS_API_GATEWAY_PUBLIC = '/public/v3'
const API_URL_SPS_API_GATEWAY_JUST_V3 = '/v3'
const API_URL_SPS_API_GATEWAY_EMAIL_V3 = '/emails/v3'


// CODE_COMMENTS_46
export const API_URL_PATH_DPS_TEXT_KEG_ORDER_UUID = 'kegOrderUuid'
export const API_URL_PATH_DPS_KEG_ORDER_UUID = `:${API_URL_PATH_DPS_TEXT_KEG_ORDER_UUID}`

// CODE_COMMENTS_46
export const API_URL_PATH_DPS_TEXT_COLLAR_ORDER_UUID = 'collarOrderUuid'
export const API_URL_PATH_DPS_COLLAR_ORDER_UUID = `:${API_URL_PATH_DPS_TEXT_KEG_ORDER_UUID}`

// CODE_COMMENTS_46
export const API_URL_PATH_DPS_TEXT_SHIPMENT_UUID = 'shipmentUuid'
export const API_URL_PATH_DPS_SHIPMENT_UUID = `:${API_URL_PATH_DPS_TEXT_SHIPMENT_UUID}`

export const API_URL_SPS_CUSTOMERS = 'customers'
export const API_URL_SPS_ADDRESSES = 'addresses'
export const API_URL_SPS_CONTRACTS = 'contracts'

export const API_URL_PATH_CUSTOMERS = `${API_URL_SPS_API_GATEWAY_CUSTOMERS}/${API_URL_SPS_CUSTOMERS}`
export const API_URL_PATH_CUSTOMER = `${API_URL_PATH_CUSTOMERS}/${URL_PATH_DPS_CUSTOMER_ID}`
export const API_URL_PATH_CHILDREN = `${API_URL_PATH_CUSTOMER}/children/objects`

export const API_URL_PATH_ADDRESSES = `${API_URL_SPS_API_GATEWAY_ADDRESSES}/${API_URL_SPS_ADDRESSES}`
export const API_URL_PATH_INDIVIDUAL_ADDRESS = `${API_URL_SPS_API_GATEWAY_ADDRESSES}/${API_URL_SPS_ADDRESSES}/${URL_PATH_DPS_ADDRESS_ID}`

// CODE_COMMENTS_133
export const API_URL_PATH_PART_CONTRACTS = `${API_URL_SPS_API_GATEWAY_CONTRACTS}/${API_URL_SPS_CUSTOMERS}/${URL_PATH_DPS_CUSTOMER_ID}`
export const API_URL_PATH_CONTRACTS = `${API_URL_PATH_PART_CONTRACTS}/contracts/webapp`

export const API_URL_PATH_RELATIONSHIPS = `${API_URL_SPS_API_GATEWAY_RELATIONSHIPS}/${API_URL_SPS_CUSTOMERS}/${URL_PATH_DPS_CUSTOMER_ID}`
export const API_URL_PATH_RELATED_TO_CUSTOMER_INFO = `${API_URL_PATH_RELATIONSHIPS}/relatedTo`
export const API_URL_PATH_RELATED_FROM_CUSTOMER_INFO = `${API_URL_PATH_RELATIONSHIPS}/relatedFrom`
export const API_URL_PATH_RELATED_TO_RELATIONSHIP_INFO = `${API_URL_PATH_RELATIONSHIPS}/relatedTo/knowwhatswhatmap`
export const API_URL_PATH_RELATED_FROM_RELATIONSHIP_INFO = `${API_URL_PATH_RELATIONSHIPS}/relatedFrom/knowwhatswhatmap`

export const API_URL_SPS_CUSTOMER_CONTACT_LINKS = 'customerContact'
export const API_URL_SPS_CREATE_OR_UPDATE_CONTACT_AND_LINKS = 'createOrUpdateContactAndLinks'
export const API_URL_PATH_CONTACTS = `${API_URL_SPS_API_GATEWAY_CUSTOMERS}/contacts`
export const API_URL_PATH_INDIVIDUAL_CONTACT = `${API_URL_PATH_CONTACTS}/${URL_PATH_DPS_CONTACT_ID}`
export const API_URL_PATH_INDIVIDUAL_CUSTOMER_CONTACT_LINK = `${API_URL_PATH_CONTACTS}/${API_URL_SPS_CUSTOMER_CONTACT_LINKS}/${URL_PATH_DPS_CUSTOMER_CONTACT_LINK_ID}`
export const API_URL_PATH_CONTACTS_UPDATE_MASTER_ACCOUNT = `${API_URL_PATH_CONTACTS}/createOrUpdateContactAndLinks`
// This is for when you want to update a Contact and one or more of its link objects in the same call
export const API_URL_PATH_CREATE_OR_UPDATE_CONTACT_AND_LINKS = `${API_URL_PATH_CONTACTS}/${API_URL_SPS_CREATE_OR_UPDATE_CONTACT_AND_LINKS}`

export const API_URL_PATH_ITEM_SKUS = `${API_URL_SPS_API_GATEWAY_ORDERS}/skus/allskus`
export const API_URL_PATH_CUSTOMER_MESSAGES = `${API_URL_PATH_CUSTOMERS}/customermessages`
export const API_URL_PATH_ITEM_SKUS_DIST_INVENTORY_TOKEN = `${API_URL_SPS_API_GATEWAY_PUBLIC}/public/orders/allskus`

// request new distributor
export const API_URL_PATH_DISTRIBUTOR_SEARCH = `${API_URL_PATH_CUSTOMERS}/distSearch`
export const API_URL_PATH_FETCH_COUNTRIES = `${API_URL_SPS_API_GATEWAY_ADDRESSES}/addresses/allcountries`
export const API_URL_PATH_FETCH_WAREHOUSE_EMAIL_CONTENT = `${API_URL_SPS_API_GATEWAY_EMAIL_V3}/emailTemplates/warehouse/webapp`
export const API_URL_PATH_FETCH_BOL_PDF_CONTENT = '/shipping/v3/pdfgen/shipments/webapp'
export const API_URL_PATH_DISTRIBUTOR_SEARCH_BY_CUSTOMER_PARAMS = `${API_URL_PATH_CUSTOMERS}/searchByCustomerAddressParams`
export const URL_PATH_DPS_TEXT_DISTRIBUTOR_CUSTOMER_ID = 'distCustomerId'
export const URL_PATH_DPS_DISTRIBUTOR_CUSTOMER_ID = `:${URL_PATH_DPS_TEXT_DISTRIBUTOR_CUSTOMER_ID}`
export const API_URL_PATH_REQUEST_NEW_DISTRIBUTOR = `${API_URL_PATH_RELATIONSHIPS}/relationships/${URL_PATH_DPS_DISTRIBUTOR_CUSTOMER_ID}`

// SPS stands for 'Static Path Segment': a part of a URL that never changes and
// never gets replaced with anything else by our code
export const API_URL_SPS_TEXT_HISTORY_INFO = 'historyinfo' // used in several API urls

export const API_URL_PATH_KEG_ORDERS = `${API_URL_SPS_API_GATEWAY_ORDERS}/orders`
export const API_URL_PATH_KEG_ORDERS_PICKUP_LOCATIONS = `${API_URL_PATH_KEG_ORDERS}/pickuplocations`
export const API_URL_PATH_KEG_ORDERS_HOLIDAY_DAYS = `${API_URL_PATH_KEG_ORDERS}/customerorderdates`
export const API_URL_PATH_INDIVIDUAL_KEG_ORDER = `${API_URL_PATH_KEG_ORDERS}/${API_URL_PATH_DPS_KEG_ORDER_UUID}` // for editing keg orders

export const API_URL_PATH_COLLAR_ORDERS = `${API_URL_SPS_API_GATEWAY_COLLARS}/collarorders`
export const API_URL_PATH_INDIVIDUAL_COLLAR_ORDER = `${API_URL_PATH_COLLAR_ORDERS}/${API_URL_PATH_DPS_COLLAR_ORDER_UUID}` // for editing collar orders

export const API_URL_PATH_COLLAR_PLATES = `${API_URL_SPS_API_GATEWAY_COLLARS}/collarplates`

export const API_URL_PATH_SHIPMENTS = `${API_URL_SPS_API_GATEWAY_SHIPMENTS}/shipments` // for reporting outbound shipments
export const API_URL_PATH_INDIVIDUAL_SHIPMENT = `${API_URL_PATH_SHIPMENTS}/${API_URL_PATH_DPS_SHIPMENT_UUID}` // for acknowledging inbound shipments
export const API_URL_PATH_EDIT_USER_REPORTED_SHIPMENT = `${API_URL_PATH_INDIVIDUAL_SHIPMENT}/${API_URL_SPS_TEXT_HISTORY_INFO}` // CODE_COMMENTS_136
export const API_URL_PATH_SHIPMENTS_HISTORY = `${API_URL_PATH_SHIPMENTS}/${API_URL_SPS_TEXT_HISTORY_INFO}` // for getting shipments info. See CODE_COMMENTS_87

export const API_URL_PATH_REPORT_INDIVIDUAL_NO_MOVEMENTS = `${API_URL_PATH_SHIPMENTS}/nomovement`
export const API_URL_PATH_GET_NO_MOVEMENTS = `${API_URL_PATH_REPORT_INDIVIDUAL_NO_MOVEMENTS}/search`

export const API_URL_PATH_INVENTORY_REPORTS = `${API_URL_SPS_API_GATEWAY_INVENTORY}/inventoryreports`
export const API_URL_PATH_INVENTORY_REPORTS_BULK = `${API_URL_PATH_INVENTORY_REPORTS}/bulk`
export const API_URL_PATH_INVENTORY_REPORTS_BULK_DIST_INVENTORY_NO_LOGIN_REQUIRED = `${API_URL_SPS_API_GATEWAY_PUBLIC}/tokenmethods/inventoryreports/bulk` // CODE_COMMENTS_267
export const API_URL_PATH_DIST_INVENTORY_GROUP_BY_TOKEN = `${API_URL_SPS_API_GATEWAY_PUBLIC}/tokenmethods/distinvgroupbytoken` // CODE_COMMENTS_267
export const API_URL_PATH_NO_LOGIN_CONTACTS = `${API_URL_SPS_API_GATEWAY_PUBLIC}/tokenmethods/contacts` // CODE_COMMENTS_267
export const API_URL_PATH_NO_LOGIN_CONTACTS_UNSUBSCRIBE = `${API_URL_SPS_API_GATEWAY_PUBLIC}/tokenmethods/contacts/unsubscribe`

export const API_URL_PATH_DATA_WAREHOUSE = `${API_URL_SPS_API_GATEWAY_BILLING}/workflow/datawarehouse`

const API_URL_PATH_INVOICES = `${API_URL_SPS_API_GATEWAY_BILLING}/invoices`
export const API_URL_PATH_INVOICES_METADATA = `${API_URL_PATH_INVOICES}/webapp`
export const API_URL_PATH_INVOICES_NETSUITE = `${API_URL_PATH_INVOICES}/balance`

// For creating the POST body that gets sent to CyberSource when a customer
// clicks on "Pay Balance"
export const API_URL_PATH_SIGNED_PAYMENTS = `${API_URL_PATH_INVOICES}/signedpayments`
// For getting and deleting a customer's saved bank account info
export const API_URL_PATH_STORED_BANK_ACCOUNT_INFO = `${API_URL_PATH_INVOICES}/paybalances`
// For creating saved bank account data. This endpoint is actually not called by
// the web app but rather by CyberSource after a user successfully completes
// their payment. But we include it here as a kind of documentation.
export const API_URL_PATH_SPECIAL_SET_STORED_BANK_ACCOUNT_INFO = `${API_URL_PATH_INVOICES}/paybalances/setbankinfo`

export const API_URL_PATH_DPS_TEXT_S3_KEY = 's3Key'
export const API_URL_PATH_DPS_S3_KEY = `:${API_URL_PATH_DPS_TEXT_S3_KEY}`
export const API_URL_PATH_DOWNLOAD_INVOICE = `${API_URL_SPS_API_GATEWAY_FILES}/files/${API_URL_PATH_DPS_S3_KEY}`

// CODE_COMMENTS_46
export const API_URL_PATH_DPS_TEXT_USER_ID = 'userId'
export const API_URL_PATH_DPS_USER_ID = `:${API_URL_PATH_DPS_TEXT_USER_ID}`

export const API_URL_PATH_USERS = `${API_URL_SPS_API_GATEWAY_USERS}/users`

export const API_URL_PATH_CUSTOMER_USER = `${API_URL_PATH_USERS}/${API_URL_PATH_DPS_USER_ID}`
export const API_URL_PATH_PERMISSIONS = `${API_URL_SPS_API_GATEWAY_USERS}/permissions/${API_URL_PATH_DPS_USER_ID}`
// Used to resend a confirmation code to a new user (This is a separate service
// from API_URL_PATH_FORGOT_PASSWORD, which can be called over and over again to
// send and resend a "Forgot Password" code to an _existing_ user)
export const API_URL_PATH_RESEND_NEW_USER_CONFIRMATION_CODE = `${API_URL_PATH_USERS}/resend/cus`

const API_URL_PATH_USERS_WEBAPP = `${API_URL_PATH_USERS}/webapp` // used ONLY to retrieve contact info of customer reps
export const API_URL_PATH_CUSTOMER_REPRESENTATIVE = `${API_URL_PATH_USERS_WEBAPP}/${API_URL_PATH_DPS_USER_ID}`

export const API_URL_PATH_LOGIN = `${API_URL_SPS_API_GATEWAY_JUST_V3}/login`
export const API_URL_PATH_FORGOT_PASSWORD = `${API_URL_PATH_USERS}/forgot`

export const API_URL_PATH_SYSTEM_PROPERTIES = `${API_URL_SPS_API_GATEWAY_ADMIN}/properties`
// Can be called publicly (no login required), created by the back-end team in
// order for the web app to fetch WEB_APP_JENKINS_BUILD_NUMBER even when logged
// out (CODE_COMMENTS_282)
export const API_URL_PATH_SYSTEM_PROPERTIES_WEB_APP_VERSION = `${API_URL_PATH_SYSTEM_PROPERTIES}/webappversion`

// CODE_COMMENTS_46
export const API_URL_SERVICE_CALL_STATUS = `${API_URL_SPS_API_GATEWAY_ADMIN}/servicecallstatus`
export const API_URL_PATH_DPS_AMAZON_REQUEST_ID = ':amazonRequestId'
export const API_URL_POLL_TIMED_OUT_API_FETCH = `${API_URL_SERVICE_CALL_STATUS}/${API_URL_PATH_DPS_AMAZON_REQUEST_ID}`

export const API_URL_PATH_SUBSIDIARIES = `${API_URL_PATH_CUSTOMERS}/allsubsidiaries`
export const API_URL_PATH_SUBSIDIARIES_DIST_INVENTORY_TOKEN = `${API_URL_SPS_API_GATEWAY_PUBLIC}/public/orders/allsubsidiaries`


/*
 * *****************************************************************************
 * Reducer Names
 * *****************************************************************************
*/
// reducer names need to be constants because they're used in several different
// places:
//
// * in combineReducer functions, of course
// * in REHYDRATE cases within reducer files for redux-persist
// * in selectors
export const REDUCER_NAMES_AUTH = 'authentication'
export const REDUCER_NAMES_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN = 'tasksToPerformImmediatelyAfterLogin'
export const REDUCER_NAMES_CURRENT_USER = 'currentUser'
export const REDUCER_NAMES_CURRENT_USER_EMPLOYEE = 'currentUserEmployee' // CODE_COMMENTS_221
export const REDUCER_NAMES_CATASTROPHIC_FAILURE = 'catastrophicFailure'

// Normalized part of the Redux store
export const REDUCER_NAMES_ENTITIES = 'entities'
export const REDUCER_NAMES_ENTITIES_ADDRESSES = 'addresses'
export const REDUCER_NAMES_ENTITIES_CUSTOMER_ADDRESS_LINKS = 'customerAddressLinks'
export const REDUCER_NAMES_ENTITIES_COLLAR_PLATES = 'collarPlates'
export const REDUCER_NAMES_ENTITIES_CUSTOMER_COLLAR_PLATE_LINKS = 'customerCollarPlateLinks'
export const REDUCER_NAMES_ENTITIES_CUSTOMERS = 'customers'
export const REDUCER_NAMES_ENTITIES_PARENT_CHILD_LINKS = 'parentChildLinks'
export const REDUCER_NAMES_ENTITIES_CONTRACTS = 'contracts'
export const REDUCER_NAMES_ENTITIES_RELATIONSHIPS = 'relationships'
export const REDUCER_NAMES_ENTITIES_USERS = 'users'
export const REDUCER_NAMES_ENTITIES_CONTACTS = 'contacts'
export const REDUCER_NAMES_ENTITIES_ITEM_SKUS = 'itemSkus'
export const REDUCER_NAMES_ENTITIES_CUSTOMER_MESSAGES = 'customerMessages'
export const REDUCER_NAMES_ENTITIES_CUSTOMER_CONTACT_LINKS = 'customerContactLinks'
export const REDUCER_NAMES_ENTITIES_SUBSIDIARIES = 'subsidiaries'

export const REDUCER_NAMES_INBOUND_UNACKNOWLEDGED_SHIPMENTS = 'inboundUnacknowledgedShipments'
// CODE_COMMENTS_283
export const REDUCER_NAMES_ORDERS_OF_INBOUND_UNACKNOWLEDGED_SHIPMENTS = 'ordersOfInboundUnacknowledgedShipments'

export const REDUCER_NAMES_CUSTOMER_USER_PERMISSIONS = 'customerUserPermissions'

export const REDUCER_NAMES_CUSTOMER_BALANCES = 'customerBalances'
export const REDUCER_NAMES_ORDER_KEGS = 'orderKegs'
export const REDUCER_NAMES_BANK_ACCOUNT_INFO = 'bankAccountInfo'

// CODE_COMMENTS_244
export const REDUCER_NAMES_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES = 'reportShipmentsFormsPotentialDuplicates'

export const REDUCER_NAMES_MAINTENANCE_MODE = 'maintenanceMode'
export const REDUCER_NAMES_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE = 'areWeCurrentlyInMaintenanceMode'
export const REDUCER_NAMES_MAINTENANCE_MODE_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED = 'shouldNotificationOfUpcomingMaintenanceBeDisplayed'
export const REDUCER_NAMES_MAINTENANCE_SCHEDULE = 'maintenanceSchedule'
export const REDUCER_NAMES_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503 = 'startAndEndTimesFrom503'

// CODE_COMMENTS_282
export const REDUCER_NAMES_LATEST_WEB_APP_VERSION = 'latestWebAppVersion'
export const REDUCER_NAMES_LATEST_WEB_APP_VERSION_STRING = 'latestWebAppVersionString'
export const REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH = 'numHardRefreshesByWebAppVersionAttemptingToFetch'

export const REDUCER_NAMES_TIME_OF_MOST_RECENT_USER_ACTIVITY = 'timeOfMostRecentUserActivity'


// Reducers/store slices that hold fetched history objects
export const REDUCER_NAMES_HISTORY = 'history' // PARENT
export const REDUCER_NAMES_HISTORY_KEG_ORDERS = 'historyKegOrders'
export const REDUCER_NAMES_HISTORY_COLLAR_ORDERS = 'historyCollarOrders'
export const REDUCER_NAMES_HISTORY_INVOICES = 'historyInvoices'
export const REDUCER_NAMES_HISTORY_INVENTORY = 'historyInventory'
export const REDUCER_NAMES_HISTORY_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDERS = 'historyInboundShipmentsOfSpecificKegOrders'
export const REDUCER_NAMES_HISTORY_INBOUND_EMPTY_KEG_SHIPMENTS = 'historyInboundEmptyKegShipments'
export const REDUCER_NAMES_HISTORY_OUTBOUND_FULL_KEG_SHIPMENTS = 'historyOutboundFullKegShipments'
export const REDUCER_NAMES_HISTORY_INBOUND_FULL_KEG_SHIPMENTS = 'historyInboundFullKegShipments' // CODE_COMMENTS_205
export const REDUCER_NAMES_HISTORY_OUTBOUND_EMPTY_KEG_SHIPMENTS = 'historyOutboundEmptyKegShipments' // CODE_COMMENTS_205
export const REDUCER_NAMES_HISTORY_KEG_FILLS_CONTRACTEE = 'historyKegFillsContractee' // CODE_COMMENTS_232

export const REDUCER_NAMES_HISTORY_NO_MOVEMENTS = 'historyNoMovements'

// The purpose of the following "HISTORY_FORM_NAME_**" variables is _not_ to
// hold form values state in Redux (we've moved away from holding form state in
// the global Redux store in favor of local state). Rather, the History Form
// Sagas need to save the most recent successfully submitted history form values
// to the Redux store. Why? 2 reasons:
//
// 1.  The "Download History Table to Excel" feature needs these values to
//     create the downloaded file name.
// 2.  The Order Kegs saga needs these values to know whether to insert the keg
//     order into the history table, as do other forms like Report Shipments.
//
// These variables act as Redux store slice names for those most recent
// submitted history form values.
export const HISTORY_FORM_NAME_KEG_ORDERS = 'historyFormKegOrders'
export const HISTORY_FORM_NAME_COLLAR_ORDERS = 'historyFormCollarOrders'
export const HISTORY_FORM_NAME_INVOICES = 'historyFormInvoices'
export const HISTORY_FORM_NAME_INVENTORY = 'historyFormInventory'
export const HISTORY_FORM_NAME_INBOUND_EMPTY_KEG_SHIPMENTS = 'historyFormInboundEmptyKegShipments'
export const HISTORY_FORM_NAME_OUTBOUND_FULL_KEG_SHIPMENTS = 'historyFormOutboundFullKegShipments'
export const HISTORY_FORM_NAME_INBOUND_FULL_KEG_SHIPMENTS = 'historyFormInboundFullKegShipments'
export const HISTORY_FORM_NAME_OUTBOUND_EMPTY_KEG_SHIPMENTS = 'historyFormOutboundEmptyKegShipments'
export const HISTORY_FORM_NAME_KEG_FILLS = 'historyFormKegFills'
export const HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE = 'historyFormKegFillsContractee'


export const REDUCER_NAMES_FETCH_STATUSES = 'fetchStatuses' // parent
export const REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE = 'fetchStatusesEmployee' // CODE_COMMENTS_226

export const REDUCER_NAMES_FETCH_STATUSES_LOGIN = 'fetchStatusesLogin'

export const REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO = 'fetchStatusesCustomersInfo' // the container of the reducers below
export const REDUCER_NAMES_FETCH_STATUSES_PRELIM_INFO = 'fetchStatusesPrelimInfo'
export const REDUCER_NAMES_FETCH_STATUSES_CUSTOMER = 'fetchStatusesCustomer'
export const REDUCER_NAMES_FETCH_STATUSES_CHILDREN = 'fetchStatusesChildren'
export const REDUCER_NAMES_FETCH_STATUSES_ADDRESSES = 'fetchStatusesAddresses'
export const REDUCER_NAMES_FETCH_STATUSES_CONTRACTS = 'fetchStatusesContracts'
export const REDUCER_NAMES_FETCH_STATUSES_RELATED_TO_INFO = 'fetchStatusesRelatedToInfo'
export const REDUCER_NAMES_FETCH_STATUSES_RELATED_FROM_INFO = 'fetchStatusesRelatedFromInfo'
export const REDUCER_NAMES_FETCH_STATUSES_INBOUND_UNACKNOWLEDGED_SHIPMENTS = 'fetchStatusesInboundUnacknowledgedShipments'
export const REDUCER_NAMES_FETCH_STATUSES_ORDERS_OF_INBOUND_UNACKNOWLEDGED_SHIPMENTS = 'fetchStatusesOrdersOfInboundUnacknowledgedShipments'
export const REDUCER_NAMES_FETCH_STATUSES_COLLAR_PLATES = 'fetchStatusesCollarPlates'
export const REDUCER_NAMES_FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER = 'fetchStatusesInboundShipmentsOfSpecificKegOrders'
export const REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_REPRESENTATIVES = 'fetchStatusesCustomerReps'
export const REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_USERS = 'fetchStatusesCustomerUsers' // for Manage Users page
export const REDUCER_NAMES_FETCH_STATUSES_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS = 'fetchStatusesIndividualCustomerUserAndItsPermissionsForEmployeeToOperateAs' // For when an employee logs in and operates for a customer user
export const REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_USER_PERMISSIONS = 'fetchStatusesCustomerUserPermissions' // for Manage Users page
export const REDUCER_NAMES_FETCH_STATUSES_CONTACTS = 'fetchStatusesContacts'
export const REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_MESSAGES = 'fetchStatusesCustomerMessages'
export const REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_BALANCES = 'fetchStatusesCustomerBalances'
export const REDUCER_NAMES_FETCH_STATUSES_BANK_ACCOUNT_INFO = 'fetchStatusesBankAccountInfo'
export const REDUCER_NAMES_FETCH_STATUSES_DELETE_BANK_ACCOUNT_INFO = 'fetchStatusesDeleteBankAccountInfo'
export const REDUCER_NAMES_FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY = 'fetchStatusesReportedInventoryVsCalculatedInventory'

export const REDUCER_NAMES_FETCH_STATUSES_FORMS = 'fetchStatusesForms'
export const REDUCER_NAMES_FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPS = 'fetchStatusesUniversalCustomerReps'

export const REDUCER_NAMES_FORMS_ORDER_KEGS = 'orderKegsForm'
export const REDUCER_NAMES_FORMS_EDIT_KEG_ORDER = 'editKegOrderForm'

export const REDUCER_NAMES_FORMS_ORDER_COLLARS = 'orderKegCollarsForm'
export const REDUCER_NAMES_FORMS_EDIT_COLLARS_ORDER = 'editKegCollarsOrderForm'

export const REDUCER_NAMES_FORMS_REPORT_OUTBOUND_SHIPMENTS = 'reportOutboundShipmentsForm'
export const REDUCER_NAMES_FORMS_EDIT_OUTBOUND_SHIPMENT_REPORT = 'editOutboundShipmentReportForm'

export const REDUCER_NAMES_FORMS_ACKNOWLEDGE_INBOUND_SHIPMENTS = 'acknowledgeInboundShipmentsForm'

export const REDUCER_NAMES_FORMS_REPORT_SELF_DIST_AND_PUB_SHIPMENTS = 'reportSelfDistAndPubShipmentsForm'
export const REDUCER_NAMES_FORMS_EDIT_SELF_DIST_AND_PUB_SHIPMENT_REPORT = 'editSelfDistAndPubShipmentReportForm'

export const REDUCER_NAMES_FORMS_REPORT_BUYBACK_SHIPMENTS = 'reportBuybackShipmentsForm'
export const REDUCER_NAMES_FORMS_EDIT_BUYBACK_SHIPMENT_REPORT = 'editBuybackShipmentReportForm'

export const REDUCER_NAMES_FORMS_REPORT_KEG_FILLS = 'reportKegFillsForm'
export const REDUCER_NAMES_FORMS_EDIT_KEG_FILL_REPORT = 'editKegFillReport'

export const REDUCER_NAMES_FORMS_REPORT_INVENTORY = 'reportInventoryForm'
export const REDUCER_NAMES_FORMS_EDIT_INVENTORY_REPORT = 'editInventoryReport'

// CODE_COMMENTS_196
export const REDUCER_NAMES_FORMS_DELETE_HISTORY_ITEM = 'deleteHistoryItem'

// Sometimes we need to save additional information about a form which is not
// form values or form submission statuses but is still intrinsically linked to
// a form. For example, in the "Request New Distributor" form (which is actually
// several forms displayed to the user in sequence), we need some place to store
// the list of cities returned by the API after a user chooses a U.S. state.
export const REDUCER_NAMES_FORMS_ADDITIONAL_INFO = 'formsAdditionalInfo'
export const REDUCER_NAMES_FORMS_ADDITIONAL_INFO_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES = 'mostRecentSuccessfullySubmittedHistoryFormsValues'


export const REDUCER_NAMES_PUBLIC_FORMS = 'publicForms'
export const REDUCER_NAMES_PUBLIC_FORMS_LOGIN = 'login'
export const REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD = 'forgotPassword'

// Used only for fetch statuses
export const REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL = 'forgotPasswordEnterEmail'
export const REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW = 'forgotPasswordResetPassword'
export const REDUCER_NAMES_PUBLIC_FORMS_RESEND_CONFIRMATION_CODE_TO_NEW_USER = 'resendConfirmationCodeToNewUser'

export const REDUCER_NAMES_PUBLIC_FORMS_CONFIRM_ACCOUNT = 'confirmAccount'

// We only have one FETCH_STATUSES reducer for public forms.
export const REDUCER_NAMES_FETCH_STATUSES_PUBLIC_FORMS = 'fetchStatusesPublicForms'


// CODE_COMMENTS_41
export const REDUCER_NAMES_LOGOUT_BUTTON_ENABLED_TOGGLE = 'logoutButtonEnabledToggle'


export const REDUCER_NAMES_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP =
  'ackInboundShipmentsReminderPopup'

export const REDUCER_NAMES_UNIVERSAL_CUSTOMER_REPRESENTATIVES = 'universalCustomerReps'

// This is the name of the top-level reducer
export const REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY = 'reportedInventoryVsCalculatedInventory'
// This is the name of the slice within
// REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY where the actual
// reported vs calculated data is held that we receive from the
// /billing/v3/workflow/datawarehouse call. There is (or may be in the future)
// other data held in REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY
// such individual inventoryReport objects for individual customers (say, one
// for the BRW and one for its PUB) that we also want to include in the
// chart/graph, which is why we need this distinguishing reducer name.
export const REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA = 'reportedInventoryVsCalculatedInventoryData'

/*
 * *****************************************************************************
 * Customer Types
 * *****************************************************************************
*/
// must match values returned by the API. There's a utility function to get
// human-readable names in the utils/ folder
export const CUSTOMER_TYPES_MASTER = 'MASTER'
export const CUSTOMER_TYPES_BREWER = 'BRW'
export const CUSTOMER_TYPES_CONTRACT_BREWER = 'CONBRW'
export const CUSTOMER_TYPES_DISTRIBUTOR = 'DIST'
export const CUSTOMER_TYPES_PUB = 'PUB'
export const CUSTOMER_TYPES_SELF_DISTRIBUTION = 'SELFDIST'
export const CUSTOMER_TYPES_WEB_APP_ONLY_RENTAL = 'CUSTOMER_TYPES_WEB_APP_ONLY_RENTAL_CUST' // CODE_COMMENTS_228
// Not a real enum recognized by the backend, but useful when the web app needs
// to indicate that every single customerType enum is applicable.
export const CUSTOMER_TYPES_WEB_APP_ONLY_ALL = 'CUSTOMER_TYPES_WEB_APP_ONLY_ALL'
// The following are not customer types that web app users can ship to/from (as
// of December, 2018). However, Logistics employees will often ship kegs from a
// MicroStar-owned warehouse ('WH') to a Brewer (e.g. in the regular course of
// fulfilling keg orders). Therefore, the movementType on inbound empty keg
// shipments can be e.g. 'WH2BREWER', wo we need these shipment types to be able
// to properly display the human-readable "Shipment Type" in the Inbound Empty
// Kegs history section.
export const CUSTOMER_TYPES_MAINTENANCE_FACILITY = 'MF'
export const CUSTOMER_TYPES_REGIONAL_DISTRIBUTION_CENTER = 'RDC'
export const CUSTOMER_TYPES_OTHER = 'OTH'
export const CUSTOMER_TYPES_WAREHOUSE = 'WH'
export const CUSTOMER_TYPES_VENUE = 'VENUE'
export const CUSTOMER_TYPES_SELF_COLLECTION = 'COL'

// Instead of importing this map directly, there's a
// getHumanReadableCustomerType() function in the utils/ folder.
export const CUSTOMER_TYPES_TO_HUMAN_READABLE_NAME_MAP = {
  [CUSTOMER_TYPES_MASTER]: 'Master Account',
  [CUSTOMER_TYPES_BREWER]: 'Brewer',
  [CUSTOMER_TYPES_CONTRACT_BREWER]: 'Contract Brewer',
  [CUSTOMER_TYPES_DISTRIBUTOR]: 'Distributor',
  [CUSTOMER_TYPES_PUB]: 'Pub',
  [CUSTOMER_TYPES_SELF_DISTRIBUTION]: 'Self Distribution',
  [CUSTOMER_TYPES_VENUE]: 'Venue',
  [CUSTOMER_TYPES_MAINTENANCE_FACILITY]: 'Maintenance Facility',
  [CUSTOMER_TYPES_REGIONAL_DISTRIBUTION_CENTER]: 'Regional Distribution Center',
  [CUSTOMER_TYPES_WAREHOUSE]: 'Warehouse',
  [CUSTOMER_TYPES_SELF_COLLECTION]: 'Self-Collection',
  [CUSTOMER_TYPES_OTHER]: 'Other',
}


/*
 * *****************************************************************************
 * Contract Types
 * *****************************************************************************
*/

export const CONTRACT_TYPES_PPF = 'PPF'
export const CONTRACT_TYPES_RENTAL = 'REN'

export const PPF_CONTRACT_TYPES_BRW = CUSTOMER_TYPES_BREWER
export const PPF_CONTRACT_TYPES_CONBRW = CUSTOMER_TYPES_CONTRACT_BREWER
export const PPF_CONTRACT_TYPES_CBMST = 'CBMST'

export const PPF_CONTRACT_STATUS_ACTIVE = 'ACT'
export const PPF_CONTRACT_STATUS_PENDING = 'PEN'
export const PPF_CONTRACT_STATUS_INACTIVE = 'INA'


/*
 * *****************************************************************************
 * User Types
 * *****************************************************************************
*/

export const USER_TYPE_CUSTOMER = 'CUS'
export const USER_TYPE_EMPLOYEE = 'EMP'


/*
 * *****************************************************************************
 * History tabs types
 * *****************************************************************************
*/

export const KEG_ORDER_HISTORY = 'kegOrderHistory'
export const COLLAR_ORDER_HISTORY = 'collarOrderHistory'
export const INVOICE_HISTORY = 'invoiceHistory'
export const INVENTORY_HISTORY = 'inventoryHistory'
export const INBOUND_EMPTY_KEG_SHIPMENT_HISTORY = 'inboundEmptyKegShipmentHistory'
export const OUTBOUND_FULL_KEG_SHIPMENT_HISTORY = 'outboundFullKegShipmentHistory'
export const KEG_FILL_HISTORY = 'kegFillHistory'
export const KEG_FILL_CONTRACTEE_HISTORY = 'kegFillContracteeHistory' // CODE_COMMENTS_232
// distributors
export const INBOUND_FULL_KEG_SHIPMENT_HISTORY = 'inboundFullKegShipmentHistory'
export const OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY = 'outboundEmptyKegShipmentHistory'
export const SCHEDULED_SHIPMENTS = 'scheduledShipments'


/*
 * *****************************************************************************
 * Container Types and Item SKUs
 * *****************************************************************************
*/

export const ITEM_SKU_IDS_MKM_HB_DEFAULT = 'HB-MS-D-U'
export const ITEM_SKU_IDS_MKM_SB_DEFAULT = 'SB-MS-D-U'
export const ITEM_SKU_IDS_MKM_QB_DEFAULT = 'QB-MS-D-U'
export const ITEM_SKU_IDS_BEL_HB_DEFAULT = 'HB-MS-D-U'
export const ITEM_SKU_IDS_BEL_QB_DEFAULT = 'QB-MS-D-U'
export const ITEM_SKU_IDS_KC_HB_DEFAULT = 'HB-KC-D-U'
export const ITEM_SKU_IDS_KC_SB_DEFAULT = 'SB-KC-D-U'
export const ITEM_SKU_IDS_KC_QB_DEFAULT = 'QB-KC-D-U'

export const ITEM_SKU_IDS_KSK_HB_DEFAULT = 'HB-KSK-D-U'
export const ITEM_SKU_IDS_KSK_SB_DEFAULT = 'SB-KSK-D-U'
export const ITEM_SKU_IDS_KSK_QB_DEFAULT = 'QB-KSK-D-U'

export const ITEM_SKU_IDS_CBI_PLASTIC_PALLET = 'PP-CBI'
export const ITEM_SKU_IDS_UNKNOWN_PLASTIC_PALLET = 'PP-UNK'
export const ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET = 'PP-CBI-SM'
export const ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG = 'UNK-FK'
export const ITEM_SKU_IDS_GOOD_PALLETS = 'WP-GMA'
export const ITEM_SKU_IDS_BAD_PALLETS = 'WP-NC'

export const ITEM_SKU_IDS_KS_L20 = 'L20-KS-G-U'
export const ITEM_SKU_IDS_KS_L30 = 'L30-KS-G-U'
export const ITEM_SKU_IDS_KS_L50 = 'L50-KS-G-U'
export const ITEM_SKU_IDS_KS_G9 = 'G9-KS-G-U'

export const CONTAINER_TYPES_HB = 'HB'
export const CONTAINER_TYPES_SB = 'SB'
export const CONTAINER_TYPES_QB = 'QB'
export const CONTAINER_TYPES_CPP = 'CPP'
export const CONTAINER_TYPES_CCP = 'CCP'
export const CONTAINER_TYPES_L20 = 'L20'
export const CONTAINER_TYPES_L30 = 'L30'
export const CONTAINER_TYPES_L50 = 'L50'
export const CONTAINER_TYPES_G9 = 'G9'

// The id is how the backend identifies container types. Order here is
// important: it determines which container types will be shown first in places
// like forms and tables (the Dev team made the decision that since
// half-barrels have been MicroStar's bread and butter for 20 years, they should
// always be listed first in the table; then sixth barrels, then quarter
// barrels, etc. Essentially, oldest to newest).
export const CONTAINER_TYPES = [
  { id: CONTAINER_TYPES_HB, name: 'Half Barrel', fractionBblName: '1/2 bbl' },
  { id: CONTAINER_TYPES_SB, name: 'Sixth Barrel', fractionBblName: '1/6 bbl' },
  { id: CONTAINER_TYPES_QB, name: 'Quarter Barrel', fractionBblName: '1/4 bbl' },
  { id: CONTAINER_TYPES_CPP, name: 'Constellation Plastic - KEG - Pallets' },
  { id: CONTAINER_TYPES_CCP, name: 'Constellation Plastic - CASE - Pallets' },
  { id: CONTAINER_TYPES_L20, name: 'L20', fractionBblName: 'L20' },
  { id: CONTAINER_TYPES_L30, name: 'L30', fractionBblName: 'L30' },
  { id: CONTAINER_TYPES_L50, name: 'L50', fractionBblName: 'L50' },
  { id: CONTAINER_TYPES_G9, name: 'Cask', fractionBblName: 'Cask' },
]

export const INVENTORY_REPORT_SKU_FOR_AUS_BREWERY = ['L50-KS-A-U', 'L30-KS-A-U', 'L20-KS-A-U']
export const INVENTORY_REPORT_SKU_FOR_NZ_BREWERY = ['L50-KS-D-U', 'L30-KS-D-U', 'L20-KS-D-U', 'SB-KS-D-U']
export const CONTAINER_TYPES_ENUMS = CONTAINER_TYPES.map(o => o.id)

export const ITEM_SKUS_SKU_TYPE_KEG = 'KEG'
export const ITEM_SKUS_SKU_TYPE_PALLET = 'PALLET'
export const ITEM_SKUS_SKU_TYPE_COMPOSITE = 'COMPOSITE'
export const ITEM_SKUS_SKU_TYPE_KEGPART = 'KEGPART'

export const ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL = 'UNSORTED'
export const ITEM_SKUS_SKU_SORTED_QUALITY_LEVEL = 'SORTED'
export const ITEM_SKUS_SKU_CLEAN_QUALITY_LEVEL = 'CLEAN'
export const ITEM_SKUS_SKU_DEFECTIVE_QUALITY_LEVEL = 'DEFECTIVE'
export const ITEM_SKUS_SKU_FULL_QUALITY_LEVEL = 'FULL'
export const ITEM_SKUS_SKU_EMPTY_QUALITY_LEVEL = 'EMPTY'

export const NUMBER_OF_HB_KEGS_THAT_FIT_ONTO_ONE_PALLET = 8
export const NUMBER_OF_SB_KEGS_THAT_FIT_ONTO_ONE_PALLET = 20
export const NUMBER_OF_QB_KEGS_THAT_FIT_ONTO_ONE_PALLET = 14
export const NUMBER_OF_L30_KEGS_THAT_FIT_ONTO_ONE_PALLET = 40
export const NUMBER_OF_L50_KEGS_THAT_FIT_ONTO_ONE_PALLET = 24
export const NUMBER_OF_L20_KEGS_THAT_FIT_ONTO_ONE_PALLET = 45
export const NUMBER_OF_G9_KEGS_THAT_FIT_ONTO_ONE_PALLET = 36


/*
 * *****************************************************************************
 * Shipment Types
 * *****************************************************************************
*/

// All possible shipment types from the MovementType db table
export const SHIPMENT_TYPES = [
  'BRW2BRW',
  'BRW2CONBRW',
  'BRW2DIST',
  'BRW2MF',
  'BRW2PUB',
  'BRW2RDC',
  'BRW2SELFDIST',
  'BRW2WH',
  'CONBRW2BRW',
  'CONBRW2CONBRW',
  'CONBRW2DIST',
  'CONBRW2MF',
  'CONBRW2PUB',
  'CONBRW2RDC',
  'CONBRW2WH',
  'DIST2BRW',
  'DIST2CONBRW',
  'DIST2DIST',
  'DIST2MF',
  'DIST2WH',
  'MF2BRW',
  'MF2CONBRW',
  'MF2MF',
  'MF2WH',
  'OTH2BBRW',
  'PUB2BRW',
  'PUB2CONBRW',
  'PUB2DIST',
  'RDC2DIST',
  'SELFDIST2BRW',
  'SELFDIST2CONBRW',
  'WH2BRW',
  'WH2CONBRW',
  'WH2DIST',
  'WH2MF',
  'WH2WH',
]


/*
 * *****************************************************************************
 * Business Unit ID Types (MicroStar, KegCraft, etc)
 * *****************************************************************************
*/

// There's a utils function to get the human-readable names of these
export const BUSINESS_UNIT_ID_TYPE_MICROSTAR = 'MKM'
export const BUSINESS_UNIT_ID_TYPE_BREWDOG = 'BD'
export const BUSINESS_UNIT_ID_TYPE_KEGCRAFT = 'KC'
export const BUSINESS_UNIT_ID_TYPE_KEGSTAR = 'KSK'
export const BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT = 'KS'
export const BUSINESS_UNIT_ID_TYPE_IRON_MONKEY = 'IRONM'
export const BUSINESS_UNIT_ID_TYPE_CONSTELLATION = 'CBI'
export const BUSINESS_UNIT_ID_TYPE_BELLS_BRAND = 'BEL'
// 'NON' is for recalls (a new backend feature introduced during COVID 2020 in
// which we clean Brewer's kegs that have expired beer) and will likely never be
// used in the web app, but if a NON customer does log in, we DON'T want a
// Sentry error message to be sent that says "Unknown business unit type has
// logged in".
export const BUSINESS_UNIT_ID_TYPE_NON_MICROSTAR = 'NON'

export const BUSINESS_UNIT_ID_TO_NAME_MAP = {
  [BUSINESS_UNIT_ID_TYPE_MICROSTAR]: 'MicroStar',
  [BUSINESS_UNIT_ID_TYPE_KEGCRAFT]: 'KegCraft',
  [BUSINESS_UNIT_ID_TYPE_KEGSTAR]: 'Kegstar', // The 's' is not capitalized
  [BUSINESS_UNIT_ID_TYPE_IRON_MONKEY]: 'Iron Monkey',
  [BUSINESS_UNIT_ID_TYPE_CONSTELLATION]: 'Constellation',
  [BUSINESS_UNIT_ID_TYPE_NON_MICROSTAR]: 'Non-MicroStar',
  [BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT]: 'Kegstar Int', // Added from DB name
  [BUSINESS_UNIT_ID_TYPE_BELLS_BRAND]: 'Bells Brands', // Added from DB name
}


/*
 * *****************************************************************************
 * Other Types
 * *****************************************************************************
*/


export const CUSTOMER_STATUS_ACTIVE = 'ACT'
export const CUSTOMER_STATUS_INACTIVE = 'INA'
export const CUSTOMER_STATUS_PENDING = 'PEN'
export const CUSTOMER_STATUS_HOLD = 'HOLD'
// Prepay is similar to Credit Hold in that the customer is or recently was
// behind on their payments to MicroStar. PrePay means they need to pay us
// before we'll send them kegs/collars. However, unlike Credit Hold, we don't
// restrict them from doing anything in the web app--the web app behaves exactly
// as if their status is Active.
export const CUSTOMER_STATUS_PRE_PAY = 'PRE'

export const CUSTOMER_HOLD_TYPE_CREDIT_HOLD = 'CREDIT_HOLD'
export const CUSTOMER_HOLD_TYPE_REPORTING_HOLD = 'REPORTING_HOLD'
export const CUSTOMER_HOLD_TYPE_SALES_HOLD = 'SALES_HOLD'
export const CUSTOMER_HOLD_TYPE_ACKNOWLEDGEMENT_HOLD = 'ACKNOWLEDGEMENT_HOLD'

export const ADDRESS_TYPES_BILLING = 'bil'
export const ADDRESS_TYPES_DEFAULT_SHIPPING = 'dshp'
export const ADDRESS_TYPES_SHIPPING = 'shp'

export const SHIPMENT_TYPES_FREIGHT = 'FREIGHT'
export const SHIPMENT_TYPES_REPORTED = 'REPORTED'
export const SHIPMENT_TYPES_LOCAL = 'LOCAL'
export const SHIPMENT_TYPES_BUYBACK = 'BUYBACK'

export const LOAD_TYPES_LOCAL = 'LOCAL'


/*
 * *****************************************************************************
 * Environment (see getEnvironmentBasedOnRootApiUrl() in /utils/index.js)
 * *****************************************************************************
*/

export const ENVIRONMENT_DEV = 'development'
export const ENVIRONMENT_TEST = 'test'
export const ENVIRONMENT_PROD = 'production'


/*
 * *****************************************************************************
 * Miscellaneous
 * *****************************************************************************
*/
export const FETCH_STATUSES_QUEUED = 'fetchStatusesQueued'
export const FETCH_STATUSES_IN_PROGRESS = 'fetchStatusesInProgress'
export const FETCH_STATUSES_SUCCESS = 'fetchStatusesSuccess'
export const FETCH_STATUSES_FAILURE = 'fetchStatusesFailure'

export const INTERNAL_ERROR_MESSAGES_FAILED_SAGA = 'A saga has failed'
export const INTERNAL_ERROR_MESSAGES_FAILED_FORM_SUBMISSION = 'The form submission failed. See the fetchStatuses reducers for details.'

export const DISPLAYED_ERROR_MESSAGES_PART_TECH_TEAM_ALERTED = 'Please reach out to your personalized rep for updates.'
export const DISPLAYED_ERROR_MESSAGES_FETCH_NO_RESPONSE_CODE = 'Network error - double check your Internet connection and try again. If the problem persists, contact your personalized representative.'
export const DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR = `Oops, we hit a snag. ${DISPLAYED_ERROR_MESSAGES_PART_TECH_TEAM_ALERTED}`
export const DISPLAYED_ERROR_MESSAGES_FOR_EMPLOYEES_UNKNOWN_SERVER_ERROR = "Unknown error. The error has been logged, but talk to MicroStar's web developer directly if you need assistance quickly."
export const DISPLAYED_ERROR_MESSAGES_CUSTOMER_IN_PENDING_STATUS = "This customer is still in Pending status. You won't be able to use it until it becomes Active. Contact your Customer Experience rep for details."

export const LOGOUT_BUTTON_ENABLED_TOGGLE_ENABLED = 'enabled'
export const LOGOUT_BUTTON_ENABLED_TOGGLE_DISABLED = 'disabled'

export const CUSTOMER_REPS_CUSTOMER_EXPERIENCE = 'ceRep'
export const CUSTOMER_REPS_LOGISTICS = 'logisticsRep'
export const CUSTOMER_REPS_ACCOUNT = 'accountRep'
export const CUSTOMER_REPS_QUALITY = 'qualityRep'
export const CUSTOMER_REPS_SALES = 'salesRep'
export const CUSTOMER_REPS_GENERIC = 'generic'

// CODE_COMMENTS_241
export const UNIVERSAL_CUSTOMER_REPS_KEG_COLLARS = 'COLLARS_CUSTOMER_REP'
export const UNIVERSAL_CUSTOMER_REPS_ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE_CUSTOMER_REP'
export const UNIVERSAL_CUSTOMER_REPS_TECH_SUPPORT = 'TECH_SUPPORT_CUSTOMER_REP'

export const ALL_UNIVERSAL_CUSTOMER_REPS = [
  UNIVERSAL_CUSTOMER_REPS_KEG_COLLARS,
  UNIVERSAL_CUSTOMER_REPS_ACCOUNTS_RECEIVABLE,
  UNIVERSAL_CUSTOMER_REPS_TECH_SUPPORT,
]

export const CUSTOMER_REPS_HUMAN_READABLE_NAMES = {
  [CUSTOMER_REPS_ACCOUNT]: 'Account',
  [CUSTOMER_REPS_CUSTOMER_EXPERIENCE]: 'Customer Experience',
  [CUSTOMER_REPS_LOGISTICS]: 'Logistics',
  [CUSTOMER_REPS_QUALITY]: 'Quality Assurance',
  [CUSTOMER_REPS_SALES]: 'Sales',
  [CUSTOMER_REPS_GENERIC]: GENERIC_CONTACT_INFO_NAME,
  [UNIVERSAL_CUSTOMER_REPS_KEG_COLLARS]: 'Keg Collars',
  [UNIVERSAL_CUSTOMER_REPS_ACCOUNTS_RECEIVABLE]: 'Accounts Receivable',
}

// By 'sort order', we mean the order from left to right (or top to bottom) in
// which the individual reps' contact info cards are displayed on the "Contact
// Rep" page. By 'priority order', we mean that if one part of the app wants to
// display the Quality rep's contact info, but the customer has no Quality rep
// assigned to them ('qualityRep' prop is null in the store), the app will
// display the contact info of a different rep. Which one it displays is
// determined by the order in this list: first it will try to display the CE rep
// (because that's the first item in this array); if the CE rep is also null, it
// tries to display the next rep type, et. al.
export const CUSTOMER_REPS_SORT_ORDER_AND_PRIORITY_ORDER = [
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
  CUSTOMER_REPS_LOGISTICS,
  CUSTOMER_REPS_ACCOUNT,
  CUSTOMER_REPS_QUALITY,
  CUSTOMER_REPS_SALES,
]

export const CUSTOMER_REPS_HELP_SECTION_SORT_ORDER = [
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
  CUSTOMER_REPS_LOGISTICS,
  CUSTOMER_REPS_SALES,
]

export const CONTACT_EMAIL_DELIVERY_STATUS_UNDELIVERABLE = 'UNDELIVERABLE'
export const CONTACT_EMAIL_DELIVERY_STATUS_OK = 'OK'

// CODE_COMMENTS_272
export const DEFAULT_LABEL_WIDTHS_FOR_HORIZONTAL_FORM_SECTION = {
  widescreen: 3,
  largeScreen: 3,
  computer: 4,
  tablet: 4,
  mobile: 5,
}

export const FORM_TYPES_ONE_API_SUBMIT_PER_ROW = 'one api submit per row form'
export const FORM_TYPES_REGULAR = 'regular form'
export const FORM_TYPES_INLINE = 'inline form'


// There are several instances in the app where a string needs to be the
// concatenation of multiple pieces of info. It's nice to have a unique but not
// too unwieldy string separator that we can use to join and parse strings. The
// separator has these qualities:
//
// * We should be able to use it in URLs and as HTML attribute IDs, therefore it
// needs to follow these rules https://stackoverflow.com/a/79022, which means it
// needs to be limited to alphanumerics, hyphens and underscores.
//
// * It needs to be unique enough not to likely appear in any UUIDs or other
// identifiers from the API, nor in any identifiers a dev maintainer of this app
// might type.
export const CONCATENATED_STRING_SEPARATOR = '--_--'


// used by both the login form and the forgot password form
export const PASSWORD_INPUT_TOO_LONG_ERROR_MSG = 'Password cannot be longer than 75 characters'


// used by history tables when there are no results for a query
export const HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE = 'There are no results matching these search parameters'

// used by the main fetch saga
export const RETRY_API_FETCH_SIGNAL = 'retry'
export const RUN_THROUGH_ALL_OTHER_REACTIONS_AND_THROW_ERROR_IF_NO_REACTIONS_APPLY = 'runThroughAllOtherReactionsAndThrowErrorIfNoReactionsApply'

export const DOWNLOAD_HISTORY_TABLE_FILE_EXTENSION = 'csv'

export const API_MAINTENANCE_HTTP_ERROR_CODES = [503]
export const API_TIMED_TOKEN_HTTP_HEADER_KEY = 'MStar-TimedToken' // CODE_COMMENTS_267

// The reaason this is an array of objects instead of an object is so that the
// components that use it don't have to sort it alphabetically themselves
export const US_STATES = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
]


// https://en.wikipedia.org/wiki/List_of_country_calling_codes#Tree_list
export const COUNTRIES_WHOSE_CALLING_CODE_IS_ONE = [
  'CA', 'US', 'AG', 'AI', 'AS', 'BB', 'BM', 'BS', 'DM', 'DO', 'GD', 'GU', 'JM',
  'KN', 'KY', 'LC', 'MP', 'MS', 'PR', 'SX', 'TC', 'TT', 'VC', 'VG', 'VI', 'UM',
]

export const KEG_ORDERS_MIN_DAYS = {
  [BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT]: 7,
}
export const SHIP_ENABLED_STATUS = ['ALLOC', 'BOOKED']
export const REVERT_ENABLED_STATUS = ['INTRAN']

export const ORDER_KEGS_ORDER_TYPE_LOCAL = 'LOCAL'
export const ORDER_KEGS_ORDER_TYPE_OPEN = 'STANDARD' // CODE_COMMENTS 285
export const ORDER_KEGS_ORDER_TYPE_PICKUP = 'PICKUP'
export const ORDER_KEGS_ORDER_TYPE_BLANKET = 'BLANKET'
export const ORDER_KEGS_ORDER_TYPE_STANDARD = 'STANDARD'
export const TENTATIVE_HOLD_DAYS = 2 // CODE_COMMENTS 285
export const EXCLUDE_BU_FOR_VIEW_ALL_INVOICES = [BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT]
export const SHIPMENT_TYPE_LABELS = {
  BFRT: 'Billable Freight',
  BUYBACK: 'Buy Back',
  CAPEX: 'Cap-Ex Move',
  FILLS: 'CB Fill Report',
  RECALL: 'Decant Shipments',
  FREIGHT: 'Freight Shipment',
  INV_ONLY: 'Inventory Only Move',
  LOCAL: 'Local Pickup',
  PICKUP: 'Pickup',
  RENTAL: 'Rental Shipments',
  REPORTED: 'Reported Shipments',
}
export const COLLAR_PLATES_STATUS_APP = 'APP'
export const COLLAR_PLATES_STATUS_BIL = 'BIL'
export const COLLAR_PLATES_STATUS = [COLLAR_PLATES_STATUS_BIL, COLLAR_PLATES_STATUS_APP]
