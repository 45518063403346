import React from 'react'


import { withRouter } from 'react-router-dom'

import PublicLayout from '../../PublicLayout'
import CustomerUserPrivateLayout from '../../CustomerUserPrivateLayout'
import EmployeeUserPrivateLayout from '../../EmployeeUserPrivateLayout'
import FauxPrivateLayout from '../../FauxPrivateLayout'

import {
  getIsEmployeePath,
  getIsFauxPrivatePath,
} from '../../../../utils'


// CODE_COMMENTS_19
export default withRouter(({
  isAuthenticated,
  isEmployeeUserLoggedInRatherThanCustomerUser,
  ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,
  // props provided by withRouter
  match,
  location,
  history,

  ...children
}) => {
  // CODE_COMMENTS_267
  if (getIsFauxPrivatePath(location.pathname)) {
    return <FauxPrivateLayout {...children} />
  }

  if (!isAuthenticated) {
    return <PublicLayout {...children} />
  }

  if (!isEmployeeUserLoggedInRatherThanCustomerUser) {
    return <CustomerUserPrivateLayout {...children} />
  }

  const isEmployeePath = getIsEmployeePath(location.pathname)

  if (
    ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor
    && !isEmployeePath
  ) {
    return <CustomerUserPrivateLayout {...children} />
  }

  return <EmployeeUserPrivateLayout {...children} />
})
