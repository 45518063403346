/* eslint-disable no-use-before-define */

import {
  withProp,
  withPropEquals,
} from '../../../higherOrderFunctions'

import {
  REDUCER_NAMES_FETCH_STATUSES,
  REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE,

  FETCH_STATUSES_QUEUED,
  FETCH_STATUSES_IN_PROGRESS,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_FAILURE,
} from '../../../../../constants'

import {
  pristineErrorDetails,
} from '../../../../reducers/fetchStatuses/higherOrderReducers/reducerCreators'

import {
  isTruthyAndNonEmpty,
} from '../../../../../utils'

// import { drillDownInObject } from '../../../../../utils'
import { NestedPropNotInObjectError } from '../../../../../customErrors'

// A higher order function that gives access to the store's fetch status info.
// Take this state shape:
//
// {apiInfo:
//   {fetchStatuses:
//     {customers:
//       {byCustomerId:
//         1234m: {
//           status: "fetchStatusesFailure",
//           errorDetails: { errorCode: 400, errorMessage: "Bad Request", responseBody: {…}}
//         },
//         5678y: {
//           status: "fetchStatusesFailure",
//           errorDetails: { errorCode: 400, errorMessage: "Bad Request", responseBody: {…}}
//         },
//         9999a: {
//           status: "fetchStatusesFailure",
//           errorDetails: { errorCode: 400, errorMessage: "Bad Request", responseBody: {…}}
//         }
//       }
//     }
//   }
// }
//
// Usage:
//
// const getFetchStatusSelectorsByCustomerId = thisFunction('byCustomerId')
//
// const {
//   getFetchStatuses,
//   getFetchFailureUrl,
//   getAmazonRequestId,
//   getFetchFailureMethod,
//   getFetchFailureErrorCode,
//   getFetchFailureErrorMessage,
//   getFetchFailureResponseBody,
//   getFetchFailureErrorDetails,
// } = getFetchStatusSelectorsByCustomerId('apiInfo', 'fetchStatuses', 'customers')
//
// const fetchStatusesOfParticularCustomer = getFetchStatuses(state, '1234m')
// fetchStatusesOfParticularCustomer is an object with these properties:
// {
//   hasFetchBeenAttempted,
//   isQueued,
//   isFetching,
//   didFetchSucceed,
//   didFetchFail,
// }

// What happens if the caller points to a field in the redux store which has no
// fetch status info (for instance, before a fetch has been attempted)? In such
// a case, the caller can only call the `getFetchStatuses()` function; all other
// functions will throw an unhandled error (`getFetchStatuses()` will return an
// object with `hasFetchBeenAttempted` and all other flags, e.g. `isFetching`]
// set to false). So the contract between this function and the rest of the code
// is that the caller should always call `getFetchStatuses()` first and make
// sure that `hasFetchBeenAttempted` is true (or, equivalent to this, make sure
// that `didFetchFail` or `didFetchSucceed` or `isFetching` is true) before
// calling any of the other functions.
export default (...containerNesting) => (...parentSlices) => {
  let fetchStatusesParentReducerNames
  let actualParentSlices
  // CODE_COMMENTS_226
  if (isTruthyAndNonEmpty(parentSlices)) {
    fetchStatusesParentReducerNames = parentSlices[0] === REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE
      ? [REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE]
      : [REDUCER_NAMES_FETCH_STATUSES]
    // CODE_COMMENTS_226
    actualParentSlices = parentSlices[0] === REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE
      ? parentSlices.slice(1)
      : parentSlices
  } else {
    fetchStatusesParentReducerNames = []
    actualParentSlices = []
  }

  const compare = withPropEquals(...fetchStatusesParentReducerNames, ...actualParentSlices, ...containerNesting)
  const get = withProp(...fetchStatusesParentReducerNames, ...actualParentSlices, ...containerNesting)


  // This gives you several individual pieces of fetch status info, including:
  //
  // hasFetchBeenAttempted,
  // isQueued,
  // isFetching,
  // didFetchSucceed,
  // didFetchFail,
  //
  // Usage:
  const getFetchStatuses = (state, ...entities) => (
    getFetchStatusesStandaloneF(get, compare, state, ...entities)
  )

  const getFetchFailureUrl = (state, ...entities) => (
    get('url')(state, ...entities, 'errorDetails')
  )

  const getAmazonRequestId = (state, ...entities) => (
    get('amazonRequestId')(state, ...entities, 'errorDetails')
  )

  const getFetchFailureMethod = (state, ...entities) => (
    get('method')(state, ...entities, 'errorDetails')
  )

  const getFetchFailureErrorCode = (state, ...entities) => (
    get('errorCode')(state, ...entities, 'errorDetails')
  )

  const getFetchFailureErrorMessage = (state, ...entities) => (
    get('errorMessage')(state, ...entities, 'errorDetails')
  )

  const getFetchFailureResponseBody = (state, ...entities) => (
    get('responseBody')(state, ...entities, 'errorDetails')
  )

  const getFetchFailureErrorDetails = (state, ...entities) => {
    try {
      return get('errorDetails')(state, ...entities)
    } catch (e) {
      if (e instanceof NestedPropNotInObjectError) {
        return pristineErrorDetails
      }
      throw e
    }
  }

  return {
    getFetchStatuses,
    // getAllFetchStatuses,
    getFetchFailureUrl,
    getAmazonRequestId,
    getFetchFailureMethod,
    getFetchFailureErrorCode,
    getFetchFailureErrorMessage,
    getFetchFailureResponseBody,
    getFetchFailureErrorDetails,
  }
}


// helper functions

/**
 * The reason this has to be its own function is because getFetchStatuses uses
 * this and because some files use it independently, which is why we export it.
 */
export const getFetchStatusesStandaloneF = (getFunction, compareFunction, state, ...entities) => {
  // first, figure out if a fetch has even been attempted
  let hasFetchBeenAttempted
  let isQueued = false

  try {
    // This will attempt to get the status of the fetch,
    // which will fail if no fetch has been attempted
    const fetchStatus = getFunction('status')(state, ...entities)
    if (fetchStatus) {
      if (compareFunction('status', FETCH_STATUSES_QUEUED)(state, ...entities)) {
        // If the status is queued for fetch, it means that a fetch hasn't
        // happened yet (only that it's going to be fetched soon)
        hasFetchBeenAttempted = false
        isQueued = true
      } else {
        hasFetchBeenAttempted = true
        isQueued = false
      }
    } else {
      // If getFunction('status') from above does not fail but returns
      // 'undefined', we want to indicate that no fetch has been attempted. Why?
      // Imagine this scenario: a user submits a form, the submission succeeds,
      // then the user hits the "Clear Form" button in the success dialog. When
      // he does that, a "clearFetchStatuses" action (maybe not called exactly
      // this, but something similar) will be called, which sets the form's
      // fetch status store slice to an empty object. Doing so will make it so
      // that the getFunction doesn't fail, but an empty object should be like a
      // failure. That is, an empty object should result in the same selector
      // values as if the store slice doesn't even exist.
      hasFetchBeenAttempted = false
    }
  } catch (e) {
    if (e instanceof NestedPropNotInObjectError) {
      hasFetchBeenAttempted = false
    } else {
      throw e
    }
  }

  let isFetching = false
  let didFetchSucceed = false
  let didFetchFail = false
  if (hasFetchBeenAttempted) {
    isFetching = compareFunction('status', FETCH_STATUSES_IN_PROGRESS)(state, ...entities)
    didFetchSucceed = compareFunction('status', FETCH_STATUSES_SUCCESS)(state, ...entities)
    didFetchFail = compareFunction('status', FETCH_STATUSES_FAILURE)(state, ...entities)
  }
  return {
    hasFetchBeenAttempted,
    isQueued,
    isFetching,
    didFetchSucceed,
    didFetchFail,
  }
}
