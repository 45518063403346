import React from 'react'
import { Header, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


import {
  URL_PATHS,
} from '../../../constants'

export default ({
  message,
  email, // CODE_COMMENTS_251
  password, // CODE_COMMENTS_251
}) => (
  <div className="centered-content">
    <Header as="h2" color="green">Success!</Header>
    <p>{message}</p>
    <Button
      as={Link}
      to={{
        pathname: URL_PATHS.login.path,
        state: { email, password }, // CODE_COMMENTS_251
      }}
      color="blue"
      id="loginPageLink"
    >
      Return to Login Page
    </Button>
  </div>
)
