import {
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_BANK_ACCOUNT_INFO,
} from '../../../../constants'
import getFetchStatusSelectorsByCustomerId from '../HOF/fetchStatusSelectors/byCustomerId'

export default getFetchStatusSelectorsByCustomerId(
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_BANK_ACCOUNT_INFO,
)
