import React from 'react'

import MainChoices from './MainChoices'
import SmallLinkChoices from './SmallLinkChoices'

import UiContainer from '../../common-components/UiContainer'

const ActualCustomerPortalComponent = UiContainer()(props => (
  <div>
    <MainChoices {...props} />
    <SmallLinkChoices {...props} />
  </div>
))


export default props => (
  <ActualCustomerPortalComponent
    {...props}
    uiContainerHocProps={{
      headerText: props.isEmployee ? 'Employee Portal' : 'Customer Portal',
      containerId: 'customerPortalSection',
    }}
  />
)
