/* eslint-disable no-param-reassign */

import get_ from 'lodash/get'
import intersection_ from 'lodash/intersection'
import pick_ from 'lodash/pick'
import omit_ from 'lodash/omit'
import values_ from 'lodash/values'
import uniq_ from 'lodash/uniq'

import flow_ from 'lodash/fp/flow'
import valuesFp_ from 'lodash/fp/values'
import uniqFp_ from 'lodash/fp/uniq'
import mapFp_ from 'lodash/fp/map'
import filterFp_ from 'lodash/fp/filter'

import {
  getEntireSlice as getEntireSliceCommon,
} from '../higherOrderFunctions'

import {
  getContracts,
} from '../contracts'

import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_RELATIONSHIPS as defaultSlice,

  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  PPF_CONTRACT_TYPES_BRW,

  CUSTOMER_STATUS_ACTIVE,
  CUSTOMER_STATUS_INACTIVE,

  PPF_CONTRACT_STATUS_ACTIVE,
} from '../../../../constants'

import {
  combineTwoArraysOfObjectsByKeyMatch,
  replacePropKeysWithAliases,
  combineFilterFunctionsIgnoringUndefinedArgs,
  isTruthyAndNonEmpty,
} from '../../../../utils'

import {
  CustomTypeError,
} from '../../../../customErrors'

/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/

// usage: you can use this to get the slice for all relationships or for a single
// relationship:
// const allRelationshipObjects = getEntireSlice(state)
// const singleRelationshipObject = getEntireSlice(state, relationshipId)
export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)


/*
 * *****************************************************************************
 * A little more specific
 * *****************************************************************************
*/

// Returns an array of customer objects. The filterByPpfContract arg (see
// CODE_COMMENTS_128) is an optional arg that can be an object in one of these
// configurations:
//
// { ppfContractType: 'BRW' }
// { ppfContractType: 'CONBRW', conbrwCustomerId: '1234m' }
export const getRelationshipObjectsOfAllRelatedToOrFrom = toOrFrom => ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
  filterByPpfContract, // CODE_COMMENTS_128
  relationshipObjsCustomFilterFunc,
  customerObjsCustomFilterFunc,
}) => {
  const idPropInRelObjOfThisCust = toOrFrom === 'to' ? 'sourceCustomerId' : 'destinationCustomerId'
  const idPropInRelObjOfCustRelatedToOrFrom = toOrFrom === 'to' ? 'destinationCustomerId' : 'sourceCustomerId'

  const actualRelationshipObjsCustomFilterFunc = filterByPpfContract
    ? addContractFilteringToRelationshipObjsCustomFilterFunc({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      filterByPpfContract,
      relationshipObjsCustomFilterFunc,
      onlyRelationshipsThatShipmentsCanBeReportedOn,
    })
    : relationshipObjsCustomFilterFunc

  // This is the value we'll return if the caller did not pass in
  // customerObjsCustomFilterFunc; we'll have to do more work below this
  return flow_([
    // the relationships slice is an object: turn it into an array of
    // relationship objects
    valuesFp_,
    // filter to relationship objects pertaining to this customer
    filterFp_(relObj => relObj[idPropInRelObjOfThisCust] === customerId),
    ...(onlyRelationshipsThatShipmentsCanBeReportedOn
      ? [filterFp_(relationshipObj => getCanShipmentsBeReportedOnThisRelationship({
        entireCustomersSlice,
        entireContractsSlice,
        relationshipObj,
      }))]
      : []
    ),
    // apply the passed-in relationship object filter function
    ...(actualRelationshipObjsCustomFilterFunc ? [filterFp_(actualRelationshipObjsCustomFilterFunc)] : []),
    // apply the passed-in customer object filter function
    ...(
      customerObjsCustomFilterFunc
        ? [
          filterFp_(
            relObj => {
              const targetCustomerId = relObj[idPropInRelObjOfCustRelatedToOrFrom]
              const targetCustomerObject = get_(entireCustomersSlice, [targetCustomerId])
              return customerObjsCustomFilterFunc(targetCustomerObject)
            },
          ),
        ]
        : []
    ),
  ])(entireRelationshipsSlice)
}


// returns an array of customerIds
export const getCustomerIdsOfAllRelatedToOrFrom = toOrFrom => ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_112
  filterByPpfContract, // CODE_COMMENTS_128
  relationshipObjsCustomFilterFunc,
  customerObjsCustomFilterFunc,
}) => {
  const idPropInRelObjOfCustRelatedToOrFrom = toOrFrom === 'to' ? 'destinationCustomerId' : 'sourceCustomerId'


  const relationshipObjects = getRelationshipObjectsOfAllRelatedToOrFrom(toOrFrom)({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
    filterByPpfContract, // CODE_COMMENTS_128
    relationshipObjsCustomFilterFunc,
    customerObjsCustomFilterFunc,
  })

  return flow_([
    // get the customerIds of the customers related to/from
    mapFp_(relObj => relObj[idPropInRelObjOfCustRelatedToOrFrom]),
    // Our array of customerIds might have duplicates: imagine a user passes in
    // onlyRelationshipsThatShipmentsCanBeReportedOn=false; the
    // getRelationshipObjectsOfAllRelatedToOrFrom() call we just made might
    // have multiple relationship objects with the exact same sourceCustomerId
    // and destinationCustomerId, the only difference being the effectiveDate
    // (with the relationshipObject with the most recent effectiveDate being the
    // only currently active one)
    uniqFp_,
  ])(relationshipObjects)
}


// Returns an array of customer objects. The filterByPpfContract arg (see
// CODE_COMMENTS_128) is an optional arg that can be an object in one of these
// configurations:
//
// { ppfContractType: 'BRW' }
// { ppfContractType: 'CONBRW', conbrwCustomerId: '1234m' }
export const getCustomerObjectsOfAllRelatedToOrFrom = toOrFrom => ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
  filterByPpfContract, // CODE_COMMENTS_128
  relationshipObjsCustomFilterFunc,
  customerObjsCustomFilterFunc,
}) => {
  const customerIds = getCustomerIdsOfAllRelatedToOrFrom(toOrFrom)({
    entireRelationshipsSlice,
    entireCustomersSlice,
    entireContractsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
    filterByPpfContract, // CODE_COMMENTS_128
    relationshipObjsCustomFilterFunc,
    customerObjsCustomFilterFunc,
  })

  return customerIds.map(custIdOfRelatedToOrFrom => entireCustomersSlice[custIdOfRelatedToOrFrom])
}


// returns an array of relationship IDs
export const getRelationshipIdsOfAllRelatedToOrFrom = toOrFrom => ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_112
  filterByPpfContract, // CODE_COMMENTS_128
  relationshipObjsCustomFilterFunc,
  customerObjsCustomFilterFunc,
}) => {
  const relationshipObjs = getRelationshipObjectsOfAllRelatedToOrFrom(toOrFrom)({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_112
    filterByPpfContract, // CODE_COMMENTS_128
    relationshipObjsCustomFilterFunc,
    customerObjsCustomFilterFunc,
  })

  return relationshipObjs.map(o => o.id)
}


// returns an array of objects, each with the customer props passed in
export const getMultipleRelationshipPropsOfAllRelatedToOrFrom = toOrFrom => ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  propNames,
  onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
  filterByPpfContract, // CODE_COMMENTS_128
  relationshipObjsCustomFilterFunc,
  customerObjsCustomFilterFunc,
}) => {
  const relationshipObjs = getRelationshipObjectsOfAllRelatedToOrFrom(toOrFrom)({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
    filterByPpfContract, // CODE_COMMENTS_128
    relationshipObjsCustomFilterFunc,
    customerObjsCustomFilterFunc,
  })

  return relationshipObjs.map(o => pick_(o, propNames))
}


// returns an array of objects, each with the customer props passed in
export const getMultipleCustomerPropsOfAllRelatedToOrFrom = toOrFrom => ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  propNames,
  onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
  filterByPpfContract, // CODE_COMMENTS_128
  relationshipObjsCustomFilterFunc,
  customerObjsCustomFilterFunc,
}) => {
  const customerObjs = getCustomerObjectsOfAllRelatedToOrFrom(toOrFrom)({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
    filterByPpfContract, // CODE_COMMENTS_128
    relationshipObjsCustomFilterFunc,
    customerObjsCustomFilterFunc,
  })

  return customerObjs.map(o => pick_(o, propNames))
}


// Use this if some of the info you need resides in the relationship object and
// other info resides in the customer object. The props arg should be an array
// of objects like so:
//
// propDefs = [
//   { prop: 'customerType', residesIn: 'customerObj' },
//   { prop: 'localsOnlyRelationship', residesIn: 'relationshipObj' },
// ]
//
// returns:
//
// [
//   { customerType: 'DIST', localsOnlyRelationship: true},
//   { customerType: 'DIST', localsOnlyRelationship: false},
// ]
//
//
// What if you want both the relationship id and the customer id, which both
// have the prop name 'id'? Include an 'alias' prop:
//
// propDefs = [
//   { prop: 'id', alias: 'relationshipId', residesIn: 'relationshipObj' },
//   { prop: 'id', alias: 'customerId', residesIn: 'customerObj' },
//   { prop: 'customerType', residesIn: 'customerObj' },
// ]
//
// returns:
//
// // [
//   { relationshipId: '1234', customerId: 'abcd', customerType: 'DIST'},
//   { relationshipId: '1235', customerId: 'abce', customerType: 'BRW'},
// ]
export const getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom = toOrFrom => ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  propDefs,
  onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
  filterByPpfContract, // CODE_COMMENTS_128
  relationshipObjsCustomFilterFunc,
  customerObjsCustomFilterFunc,
}) => {
  // If you pass in a malformed 'props' argument, the function on its own might
  // not throw an error but return unexpected data. This can lead to insidious
  // bugs that are hard to trace. Therefore, se nip that scenario in the bud by
  // ensuring that the 'props' object is properly shaped and throwing an error
  // if it's not.
  checkValidityOfPropsArgumentOfGetMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom(propDefs)

  const customerIdPropOfRelatedToFrom = toOrFrom === 'to' ? 'destinationCustomerId' : 'sourceCustomerId'

  const requestedRelationshipPropDefs = propDefs.filter(o => o.residesIn === 'relationshipObj')
  const requestedRelationshipPropNames = requestedRelationshipPropDefs.map(o => o.prop)
  const requestedCustomerPropDefs = propDefs.filter(o => o.residesIn === 'customerObj')
  const requestedCustomerPropNames = requestedCustomerPropDefs.map(o => o.prop)

  const propDefsWithAliases = propDefs.filter(propDef => propDef.alias)

  let relationshipProps = getMultipleRelationshipPropsOfAllRelatedToOrFrom(toOrFrom)({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    propNames: [...requestedRelationshipPropNames, customerIdPropOfRelatedToFrom],
    onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
    filterByPpfContract, // CODE_COMMENTS_128
    relationshipObjsCustomFilterFunc,
    customerObjsCustomFilterFunc,
  })

  relationshipProps = replacePropKeysWithAliases(
    relationshipProps,
    propDefsWithAliases.filter(propDef => propDef.residesIn === 'relationshipObj'),
  )

  let customerProps = getMultipleCustomerPropsOfAllRelatedToOrFrom(toOrFrom)({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    propNames: [...requestedCustomerPropNames, 'id'],
    onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
    filterByPpfContract, // CODE_COMMENTS_128
    relationshipObjsCustomFilterFunc,
    customerObjsCustomFilterFunc,
  })
  customerProps = replacePropKeysWithAliases(
    customerProps,
    propDefsWithAliases.filter(propDef => propDef.residesIn === 'customerObj'),
  )

  // combine
  let relationshipIdMatchKey
  let customerIdMatchKey
  if (requestedRelationshipPropNames.includes(customerIdPropOfRelatedToFrom)) {
    relationshipIdMatchKey = requestedRelationshipPropDefs.find(propDef => propDef.prop === 'id').alias || customerIdPropOfRelatedToFrom
  } else {
    relationshipIdMatchKey = customerIdPropOfRelatedToFrom
  }
  if (requestedCustomerPropNames.includes('id')) {
    customerIdMatchKey = requestedCustomerPropDefs.find(propDef => propDef.prop === 'id').alias || 'id'
  } else {
    customerIdMatchKey = 'id'
  }

  let toReturn = combineTwoArraysOfObjectsByKeyMatch(
    relationshipProps,
    customerProps,
    relationshipIdMatchKey,
    customerIdMatchKey,
  )

  // we've included 'id' and 'sourceCustomerId' (or 'destinationCustomerId' if
  // this is a 'from' function) in each subobject of the returned array because
  // these were necessary to combine the arrays. But the caller might not have
  // requested these props. If not, remove them.
  if (!requestedRelationshipPropNames.includes(customerIdPropOfRelatedToFrom)) {
    toReturn = toReturn.map(obj => omit_(obj, relationshipIdMatchKey))
  }
  if (!requestedCustomerPropNames.includes('id')) {
    toReturn = toReturn.map(obj => omit_(obj, customerIdMatchKey))
  }

  return toReturn
}


/*
 * *****************************************************************************
 * More Specific Selectors
 * *****************************************************************************
*/

export const getIsRelatedToOrFromAnyone = toOrFrom => ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_112
  filterByPpfContract, // CODE_COMMENTS_128
  relationshipObjsCustomFilterFunc,
  customerObjsCustomFilterFunc,
}) => (
  getCustomerObjectsOfAllRelatedToOrFrom(toOrFrom)({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_112
    filterByPpfContract, // CODE_COMMENTS_128
    relationshipObjsCustomFilterFunc,
    customerObjsCustomFilterFunc,
  }).length > 0
)


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

// for use in the function that retrieves props from both relationship objects
// and customer objects
function checkValidityOfPropsArgumentOfGetMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom(props) {
  // ensure every prop definition has a 'residesIn' key set to a truthy value
  if (!props.every(propDef => propDef.residesIn)) {
    throw new CustomTypeError("One or more of the prop definitions passed in doesn't have the required 'residesIn' prop (but see the getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom function)")
  }

  // ensure every prop definition's 'residesIn' key set to a valid string value
  if (!props.every(propDef => ['customerObj', 'relationshipObj'].includes(propDef.residesIn))) {
    throw new CustomTypeError("One or more of the 'residesIn' props included in the prop definitions passed into the function is set to something other than the strings 'relationshipObj' or 'customerObj'. The 'residesIn' prop must be set to one of these strings (but see the getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom function)")
  }

  // If the same key is requested in both the relationshipObj and the
  // customerObj, at least one prop must have an alias
  const requestedRelationshipProps = props.filter(o => o.residesIn === 'relationshipObj')
  const requestedRelationshipPropNames = requestedRelationshipProps.map(o => o.prop)
  const requestedCustomerProps = props.filter(o => o.residesIn === 'customerObj')
  const requestedCustomerPropNames = requestedCustomerProps.map(o => o.prop)

  const propNamesRequestedInBothRelationshipObjAndCustomerObj = intersection_(
    requestedRelationshipPropNames,
    requestedCustomerPropNames,
  )

  if (!propNamesRequestedInBothRelationshipObjAndCustomerObj.every(propName => {
    const doesRelationshipObjPropHaveAlias = requestedRelationshipProps.find(propDef => propDef.prop === propName).alias
    const doesCustomerObjPropHaveAlias = requestedCustomerProps.find(propDef => propDef.prop === propName).alias
    return doesRelationshipObjPropHaveAlias || doesCustomerObjPropHaveAlias
  })) {
    throw new CustomTypeError("a prop with the same name has been requested from both customer objects and relationship objects, but neither prop has an alias. Add an 'alias' prop to at least one (getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom function)")
  }
}

// See CODE_COMMENTS_128. Returns a function to be passed into filter() which
// combines the passed-in relationshipObjsCustomFilterFunc with filtering by ppf
// contract, as instructed in the passed-in filterByPpfContract arg, which
// should be an object.
function addContractFilteringToRelationshipObjsCustomFilterFunc({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  filterByPpfContract, // CODE_COMMENTS_128
  relationshipObjsCustomFilterFunc,
  onlyRelationshipsThatShipmentsCanBeReportedOn,
}) {
  const {
    conbrwCustomerId,
    ppfContractType,
  } = filterByPpfContract

  const customerType = entireCustomersSlice[customerId].customerType
  if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    // TODO rollbar throw error: (see CODE_COMMENTS_128) The caller of a
    // relationships selector should never pass in a customer ID of contract
    // brewer, because 1) it's impossible for contract brewers to be associated
    // with a brw ppfContractType, they can only be associated with a conbrw
    // ppfContractType, therefore customerId=[conbrw customerId] and
    // ppfContractType='BRW' wouldn't make any sense; 2) there's no business
    // case in which the following is needed: customerId=[conbrw customerId],
    // ppfContractType='CONBRW' and conbrwCustomerId=[a different contract
    // brewer customerId]. If the app is attempting to do this, it must be a
    // mistake on the caller's part. Return a filter function which makes the
    // selector return no information.
    return () => false
  }


  // At this point the customerType is a brewer

  if (ppfContractType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    const contractIds = getSinglePropOfAllRelationshipObjectsBySourceAndDestCustomerIds({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      sourceCustomerId: customerId,
      destinationCustomerId: conbrwCustomerId,
      propName: 'sourcePpfContract',
      onlyRelationshipsThatShipmentsCanBeReportedOn,
    })
    return combineFilterFunctionsIgnoringUndefinedArgs(
      relationshipObjsCustomFilterFunc,
      o => contractIds.includes(o.sourcePpfContract),
    )
  }

  // at this point both the customerType and ppfContractType are BRW (i.e.
  // non-contract Brewer)

  const allDefaultBrwPpfContracts = getContracts({
    entireCustomersSlice,
    entireContractsSlice,
    customerId,
    ppfContractTypes: PPF_CONTRACT_TYPES_BRW,
    notExpiredOnly: false, // CODE_COMMENTS_262
  })
  const allDefaultBrwPpfContractIds = allDefaultBrwPpfContracts.map(o => o.id)

  return combineFilterFunctionsIgnoringUndefinedArgs(
    relationshipObjsCustomFilterFunc,
    o => allDefaultBrwPpfContractIds.includes(o.sourcePpfContract),
  )
}


/*
 * *****************************************************************************
 * Not specific to either related to or related from -- used elsewhere in the
 * app besides the relatedFromInfo.js and relatedFromInfo.js selector files.
 * *****************************************************************************
*/

// Get all relationship objects between two customers (remember that there can
// be multiple relationships between two customers, with a maximum of 1 being
// currently active) when all you know are the source and destination customer
// IDs. Returns undefined if no relationship exists with the provided source
// and destination customer IDs.
export function getAllRelationshipObjectsBySourceAndDestCustomerIds({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  sourceCustomerId,
  destinationCustomerId,
  onlyRelationshipsThatShipmentsCanBeReportedOn,
  relationshipObjsCustomFilterFunc,
}) {
  const relationshipsBetweenTheTwoCustomers = values_(entireRelationshipsSlice).filter(relationshipObj => (
    relationshipObj.sourceCustomerId === sourceCustomerId &&
    relationshipObj.destinationCustomerId === destinationCustomerId &&
    (
      onlyRelationshipsThatShipmentsCanBeReportedOn
        ? getCanShipmentsBeReportedOnThisRelationship({
          entireCustomersSlice,
          entireContractsSlice,
          relationshipObj,
        })
        : true
    )
  ))

  return relationshipObjsCustomFilterFunc
    ? relationshipsBetweenTheTwoCustomers.filter(relationshipObjsCustomFilterFunc)
    : relationshipsBetweenTheTwoCustomers
}


// Use this if you want the same prop from all the relationship objects between
// two customers (remember that there can be multiple relationships between two
// customers, with a maximum of 1 being currently active)
export function getSinglePropOfAllRelationshipObjectsBySourceAndDestCustomerIds({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  sourceCustomerId,
  destinationCustomerId,
  propName,
  onlyRelationshipsThatShipmentsCanBeReportedOn,
  relationshipObjsCustomFilterFunc,
}) {
  const targetRelObjs = getAllRelationshipObjectsBySourceAndDestCustomerIds({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    sourceCustomerId,
    destinationCustomerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn,
    relationshipObjsCustomFilterFunc,
  })
  return targetRelObjs.map(o => o[propName])
}


// Use this if you want some of the same props from all the relationship objects
// between two customers (remember that there can be multiple relationships
// between two customers, with a maximum of 1 being currently active)
export function getMultiplePropsOfAllRelationshipObjectsBySourceAndDestCustomerIds({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  sourceCustomerId,
  destinationCustomerId,
  propNames, // an array
  onlyRelationshipsThatShipmentsCanBeReportedOn,
  relationshipObjsCustomFilterFunc,
}) {
  const targetRelObjs = getAllRelationshipObjectsBySourceAndDestCustomerIds({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    sourceCustomerId,
    destinationCustomerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn,
    relationshipObjsCustomFilterFunc,
  })
  return targetRelObjs.map(o => pick_(o, propNames))
}


// To be used when all you know is the source and destination customerIds
export function getCanAnyRelationshipBetweenTwoCustomersHaveShipmentsReportedOnIt({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  sourceCustomerId,
  destinationCustomerId,
  relationshipObjsCustomFilterFunc,
}) {
  const allRelationshipIdsBetweenTheseCustomers = getSinglePropOfAllRelationshipObjectsBySourceAndDestCustomerIds({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    sourceCustomerId,
    destinationCustomerId,
    propName: 'id',
    onlyRelationshipsThatShipmentsCanBeReportedOn: true,
    relationshipObjsCustomFilterFunc,
  })

  return isTruthyAndNonEmpty(allRelationshipIdsBetweenTheseCustomers)
}


export function getAllBrw2ConbrwRelationshipsOfBrewer({
  entireCustomersSlice,
  entireRelationshipsSlice,
  customerId, // must be a BRW customerId
}) {
  return flow_(
    valuesFp_,
    filterFp_(relObj => relObj.sourceCustomerId === customerId),
    filterFp_(relObj => get_(entireCustomersSlice, [relObj.destinationCustomerId, 'customerType']) === CUSTOMER_TYPES_CONTRACT_BREWER),
  )(entireRelationshipsSlice)
}


export function getAllBrw2ConbrwRelationshipsOfContractBrewer({
  entireCustomersSlice,
  entireRelationshipsSlice,
  customerId, // must be a CONBRW customerId
}) {
  return flow_(
    valuesFp_,
    filterFp_(relObj => relObj.destinationCustomerId === customerId),
    filterFp_(relObj => get_(entireCustomersSlice, [relObj.sourceCustomerId, 'customerType']) === CUSTOMER_TYPES_BREWER),
  )(entireRelationshipsSlice)
}


export function getAllContractBrewingPpfContractIdsOfBrewer({
  entireCustomersSlice,
  entireRelationshipsSlice,
  customerId,
}) {
  const targetRelationships = getAllBrw2ConbrwRelationshipsOfBrewer({
    entireRelationshipsSlice,
    entireCustomersSlice,
    customerId, // Must be a BRW customerId
  })
  return uniq_(targetRelationships.map(o => o.sourcePpfContract))
}


export function getAllContractBrewingPpfContractIdsOfContractBrewer({
  entireCustomersSlice,
  entireRelationshipsSlice,
  customerId, // Must be a CONBRW customerId
}) {
  const targetRelationships = getAllBrw2ConbrwRelationshipsOfContractBrewer({
    entireRelationshipsSlice,
    entireCustomersSlice,
    customerId,
  })
  return uniq_(targetRelationships.map(o => o.sourcePpfContract))
}


// CODE_COMMENTS_263
export function getCanShipmentsBeReportedOnThisRelationship({
  entireCustomersSlice,
  entireContractsSlice,
  relationshipObj,
}) {
  if (relationshipObj.disabled) {
    return false
  }

  const contract = entireContractsSlice[relationshipObj.sourcePpfContract]
  if (!contract) {
    // This would be very unusual; in fact, I can't think of a time when this
    // would happen, so probably best to log a third-party error here
    return false
  }

  if (contract.contractStatusId !== PPF_CONTRACT_STATUS_ACTIVE) {
    return false
  }
  // if (Number(contract?.contractEndDate || 0) < new Date().getTime()) {
  //   return false
  // }

  // If either customer's status is INA, return false
  const sourceCustomer = entireCustomersSlice[relationshipObj.sourceCustomerId]
  const destinationCustomer = entireCustomersSlice[relationshipObj.destinationCustomerId]
  const sourceCustomerStatus = sourceCustomer
    ? sourceCustomer.customerStatus || sourceCustomer.fauxCustomerStatus
    : CUSTOMER_STATUS_ACTIVE
  const destinationCustomerStatus = destinationCustomer
    ? destinationCustomer.customerStatus || destinationCustomer.fauxCustomerStatus
    : CUSTOMER_STATUS_ACTIVE
  if (
    sourceCustomerStatus === CUSTOMER_STATUS_INACTIVE
    || destinationCustomerStatus === CUSTOMER_STATUS_INACTIVE
  ) {
    return false
  }
  return true
}
