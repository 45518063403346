import React, { useEffect, useState, Fragment } from 'react'
import { Dropdown, Grid, Message, Form, Loader, Button, Header, Dimmer } from 'semantic-ui-react'
import orderBy_ from 'lodash/orderBy'
import { Trans, useTranslation } from 'react-i18next'
import InputNoAutoComplete from '../../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import createAction from '../../../../redux/actions/createAction'
import {
  FETCH_REQUEST_NEW_DISTRIBUTOR_OPTIONS,
  FETCH_REQUEST_NEW_DISTRIBUTOR_COUNTRY, FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_DISTRIBUTOR,
} from '../../../../redux/actions/actionTypes'
import GenericFetchFailureMessage from '../../../../common-components/rewrite/FetchFailure/GenericFetchFailureMessage'
import {
  capitalizeFirstLetter,
  convertCountryAlpha2CodeToAlpha3,
  getBusinessUnitNameFromBusinessUnitId, isTruthyAndNonEmpty,
} from '../../../../utils'
import RequestNewDistributorTable from './RequestNewDistributorTable'
import { getBusinessUnitIdOfRootCustomer } from '../../../../redux/selectors/rewrite/customers'
import { getCustomerIdsOfAllRelatedTo } from '../../../../redux/selectors/rewrite/relationships/relatedToInfo'
import { CUSTOMER_TYPES_DISTRIBUTOR, PPF_CONTRACT_TYPES_CONBRW } from '../../../../constants'
import {
  REQUEST_NEW_DIST_TEXT1,
  REQUEST_NEW_DIST_TEXT2,
  REQUEST_NEW_DIST_TEXT3,
} from '../../../../constants/labelCaptions'

const SuccessMessage = ({
  distributorName,
  businessName,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Fragment>
      <Header as="h2" color="green">
        <Trans ns="common" i18nKey="Success!">
          Success!
        </Trans>
      </Header>
      <Message positive>{`${translate(REQUEST_NEW_DIST_TEXT1)} ${distributorName} ${translate(REQUEST_NEW_DIST_TEXT2)} ${businessName} ${translate(REQUEST_NEW_DIST_TEXT3)}`}</Message>
    </Fragment>
  )
}
export default ({
  sources,
  dispatch,
  customerId,
  operatingContractBrewerCustomerId,
  businessUnitId,
  defaultCountry,
  ...rest
}) => {
  const businessName = getBusinessUnitNameFromBusinessUnitId(getBusinessUnitIdOfRootCustomer(rest))
  const [countries, setCountries] = useState([{ id: 'US', name: 'US' }])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [distributors, setDistributors] = useState([])
  const [pageLoader, setPageLoader]= useState(false)
  const [selectedCountry, setSelectCountry] = useState()
  const [selectedState, setSelectState] = useState('')
  const [selectedCity, setSelectCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [distributorName, setDistributorName] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [didFetchFail, setDidFetchFail] = useState(false)
  const [requestSuccessful, setRequestSuccessful] = useState(false)
  const [selectedDistributor, setSelectedDistributor] = useState({}) // submitted for request
  const { t: translate } = useTranslation('common')

  const onSelectCountry = (e, { value }) => {
    setSelectState('')
    setSelectCity('')
    setStates([])
    setCities([])
    setSelectCountry(value)
  }
  const onSelectState = (e, { value }) => {
    setSelectState(value)
  }
  const onSelectCity = (e, { value }) => {
    setSelectCity(value)
  }
  const onPostalCode = (e, { value }) => {
    setPostalCode(value)
  }
  const onDistributorChange = (e, { value }) => {
    setDistributorName(value)
  }

  const isSelectedDistAlreadyRelated = ({ distributor, selectedSource, selectedCustomerType }) => {
    if (!selectedSource) return false
    const { id } = distributor
    const distributorIdsAlreadyRelatedToWhoseRelationshipIsCurrentlyActive = getCustomerIdsOfAllRelatedTo({
      ...rest,
      customerId,
      // filterByPpfContract: operatingContractBrewerCustomerId
      //   ? { ppfContractType: PPF_CONTRACT_TYPES_CONBRW, conbrwCustomerId: operatingContractBrewerCustomerId }
      //   : { ppfContractType: PPF_CONTRACT_TYPES_BRW },
      filterByPpfContract: { ppfContractType: selectedCustomerType,
        ...(selectedCustomerType === PPF_CONTRACT_TYPES_CONBRW && { conbrwCustomerId: selectedSource }),
      },
      onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_DISTRIBUTOR,
    })
    const distributorIdsAlreadyRelatedToWhoseRelationshipIsNotCurrentlyActive = getCustomerIdsOfAllRelatedTo({
      ...rest,
      customerId,
      // filterByPpfContract: operatingContractBrewerCustomerId
      //   ? { ppfContractType: PPF_CONTRACT_TYPES_CONBRW, conbrwCustomerId: operatingContractBrewerCustomerId }
      //   : { ppfContractType: PPF_CONTRACT_TYPES_BRW },
      filterByPpfContract: { ppfContractType: selectedCustomerType,
        ...(selectedCustomerType === PPF_CONTRACT_TYPES_CONBRW && { conbrwCustomerId: selectedSource }),
      },
      onlyRelationshipsThatShipmentsCanBeReportedOn: false, // CODE_COMMENTS_112
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_DISTRIBUTOR,
    })

    const isSelectedDistAlreadyRelated_ = (
      [
        ...distributorIdsAlreadyRelatedToWhoseRelationshipIsCurrentlyActive,
        ...distributorIdsAlreadyRelatedToWhoseRelationshipIsNotCurrentlyActive,
      ].includes(id)
    )
    // const isRelationshipActiveIfSelectedDistIsAlreadyRelated_ = (
    //   isSelectedDistAlreadyRelated_ &&
    //   distributorIdsAlreadyRelatedToWhoseRelationshipIsCurrentlyActive.includes(value)
    // )
    return isSelectedDistAlreadyRelated_
  }

  useEffect(() => {
    setIsFetching(true)
    setDidFetchFail(false)
    setRequestSuccessful(false)
    dispatch(createAction(
      FETCH_REQUEST_NEW_DISTRIBUTOR_COUNTRY,
      {
        onFailure: () => {
          setDidFetchFail(true)
          setIsFetching(false)
        },
        onSuccess: responseData => {
          setIsFetching(false)
          setSelectCountry(defaultCountry)
          setCountries(responseData)
        },
      },
    ))
  }, [dispatch, defaultCountry])

  const isFormSubmittable = () => (selectedCountry && (selectedCity || postalCode))

  const handleSearch = () => {
    if (!isFormSubmittable()) return

    setPageLoader(true)
    setDidFetchFail(false)
    setRequestSuccessful(false)
    dispatch(createAction(
      FETCH_REQUEST_NEW_DISTRIBUTOR_OPTIONS,
      {
        ...rest,
        country: convertCountryAlpha2CodeToAlpha3(selectedCountry),
        state: selectedState,
        city: selectedCity,
        postalCode,
        distributorName,
        businessUnitId,
        customerId,
        operatingContractBrewerCustomerId,
        filterDistributor: isSelectedDistAlreadyRelated,
        onSuccess: responseData => {
          setDidFetchFail(false)
          setPageLoader(false)
          setDistributors(responseData?.distributorsFound || [])
        },
        onFailure: () => {
          setDidFetchFail(true)
          setPageLoader(false)
        },
      },
    ))
  }

  useEffect(() => {
    setRequestSuccessful(false)
  }, [selectedDistributor])

  const requestNewDistributor = ({ selectedSource }) => {
    if (!isTruthyAndNonEmpty(selectedDistributor) || !selectedSource) return

    setPageLoader(true)
    setDidFetchFail(false)
    setRequestSuccessful(false)
    dispatch(createAction(
      FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_DISTRIBUTOR,
      {
        ...rest,
        customerId,
        operatingContractBrewerCustomerId,
        value: selectedDistributor?.id,
        selectedSource,
        distributorOptions: distributors,
        onFailure: () => {
          setDidFetchFail(true)
          setPageLoader(false)
        },
        onSuccess: () => {
          const { id: excludeId } = selectedDistributor
          setDistributors(prevState => prevState.filter(distributor => distributor.id !== excludeId))
          setPageLoader(false)
          setDidFetchFail(false)
          setRequestSuccessful(true)
        },
      },
    ))
  }

  useEffect(() => {
    if (!selectedCountry && !selectedState) {
      return
    }
    setIsFetching(true)
    setDidFetchFail(false)
    setRequestSuccessful(false)
    dispatch(createAction(
      FETCH_REQUEST_NEW_DISTRIBUTOR_OPTIONS,
      {
        customerId,
        operatingContractBrewerCustomerId,
        country: convertCountryAlpha2CodeToAlpha3(selectedCountry),
        state: selectedState,
        businessUnitId,
        onFailure: () => {
          setDidFetchFail(true)
          setIsFetching(false)
        },
        onSuccess: responseData => {
          setIsFetching(false)
          const cityList = responseData?.cityList?.length > 0 ? responseData?.cityList : []
          const stateList = responseData?.stateList?.length > 0 ? responseData?.stateList : []
          const sortedCities = orderBy_(cityList)
          const sortedStates = orderBy_(stateList)
          setCities(sortedCities)
          setStates(sortedStates)
        },
      },
    ))
  }, [customerId, operatingContractBrewerCustomerId, dispatch, businessUnitId, selectedCountry, selectedState])

  const firstSectionDropdowns = [
    {
      rowTitle: 'Choose country',
      fieldName: 'country',
      Component: Dropdown,
      required: true,
      props: {
        value: selectedCountry,
        onChange: onSelectCountry,
        options: (
          countries.map(({
            iso2Name,
            fullName,
          }) => (
            {
              key: iso2Name,
              value: iso2Name,
              text: fullName,
            }
          ))
        ),
        search: 'true',
        selection: 'true',
      },
    },
    {
      rowTitle: 'Choose state',
      fieldName: 'state',
      Component: Dropdown,
      props: {
        value: selectedState,
        onChange: onSelectState,
        options: (
          states.map(state => (
            {
              key: state,
              value: state,
              text: state,
            }
          ))
        ),
        search: 'true',
        selection: 'true',
      },
    },
  ]

  const secondSectionDropdowns = [
    {
      fieldName: 'city',
      Component: cities.length > 0 ? Dropdown : InputNoAutoComplete,
      props: {
        value: selectedCity,
        onChange: onSelectCity,
        options: (
          cities.map(city => (
            {
              key: city,
              value: city,
              text: city,
            }
          ))
        ),
        search: 'true',
        selection: 'true',
      },
    },
    {
      fieldName: 'postal code',
      Component: InputNoAutoComplete,
      props: {
        value: postalCode,
        onChange: onPostalCode,
        style: {
          width: '160px',
        },
      },
    },
  ]

  return (
    <Fragment>
      {didFetchFail && (
        <Message negative>
          <GenericFetchFailureMessage
            customerId={operatingContractBrewerCustomerId || customerId}
          />
        </Message>
      )}
      {pageLoader && <Dimmer active><Loader size="big" active>{translate('Submitting')}</Loader></Dimmer>}
      {requestSuccessful && <SuccessMessage businessName={businessName} distributorName={selectedDistributor?.name} />}
      <Form>
        <Grid columns={12}>
          <Grid.Row className="compact-grid-row" >
            {firstSectionDropdowns.map(obj => {
              const { rowTitle, fieldName, Component, required=false } = obj
              return (
                <Grid.Column width={4} style={{ fontWeight: 'bold', paddingRight: 0 }} key={`key-${fieldName}`}>
                  <Grid.Row className="compact-grid-row">
                    <Form.Field required={required}>
                      <label
                      style={{
                        width: '120px',
                      }}
                      >
                        {translate(rowTitle)}
                      </label>
                    </Form.Field>
                  </Grid.Row>
                  <Grid.Row className="compact-grid-row">
                    <label
                    htmlFor={fieldName}
                    style={{
                      width: '120px',
                      paddingRight: '10px',
                    }}
                    >
                      {translate(capitalizeFirstLetter(fieldName))}
                    </label>
                    <div className="ui input">
                      <Component
                        {...obj.props}
                        placeholder={translate(`Select ${capitalizeFirstLetter(fieldName)}`)}
                        selectOnBlur={false}
                        name={fieldName}
                        selection
                      />
                      <Loader
                        inline
                        active={isFetching}
                        disabled={!isFetching}
                        size="small"
                        style={{ margin: '0.5rem 0 0 0.5rem' }}
                      />
                    </div>
                  </Grid.Row>
                </Grid.Column>
              )
            })}

            <Grid.Column width={8} style={{ fontWeight: 'bold' }}>
              <Grid.Row className="compact-grid-row">
                <Form.Field required>
                  <label
                    style={{
                      width: '120px',
                      verticalAlign: 'middle',
                    }}
                  >
                    <Trans ns="common" i18nKey="Choose atleast one">
                      Choose atleast one
                    </Trans>
                  </label>
                </Form.Field>
              </Grid.Row>
              <Grid.Row className="compact-grid-row border-1 border-radius5 pad20">
                {secondSectionDropdowns.map(obj => {
                  const { fieldName, Component } = obj
                  return (
                    <Fragment>
                      <label
                        htmlFor={fieldName}
                        style={{
                          width: '120px',
                          paddingRight: '10px',
                        }}
                      >
                        {translate(capitalizeFirstLetter(fieldName))}
                      </label>
                      <div className="ui input" style={{ paddingRight: '10px' }}>
                        <Component
                          {...obj.props}
                          placeholder={`${Component === Dropdown ? 'Select' : 'Enter'} ${capitalizeFirstLetter(fieldName)}`}
                          name={fieldName}
                        />
                      </div>
                    </Fragment>
                  )
                })}
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered columns={1} >
            <Grid.Column width={8} style={{ fontWeight: 'bold' }}>
              <label
                htmlFor="distributor"
                style={{
                  verticalAlign: 'middle',
                  paddingRight: '10px',
                }}
              >
                <Trans ns="common" i18nKey="Location Name:">
                  Location Name:
                </Trans>
              </label>
              <InputNoAutoComplete
                name="distributor"
                placeholder={translate('Enter part of location name')}
                value={distributorName}
                onChange={onDistributorChange}
              />
              <Button
              primary
              onClick={handleSearch}
              style={{ marginLeft: '.5rem' }}
              disabled={!isFormSubmittable()}
              >
                <i className="search icon" style={{ cursor: 'pointer' }} />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <RequestNewDistributorTable
        sources={sources}
        distributorObjs={distributors}
        selectedDistributor={selectedDistributor}
        setSelectedDistributor={setSelectedDistributor}
        requestNewDistributor={requestNewDistributor}
        isSelectedDistAlreadyRelated={isSelectedDistAlreadyRelated}
      />
    </Fragment>
  )
}
