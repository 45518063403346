/**
 * CODE_COMMENTS_12
 * CODE_COMMENTS_221
 */
import currentUserReducerCreator from './currentUserReducerCreator'

import {
  SAVE_EMPLOYEE_CURRENT_USER,
  UPDATE_BASIC_INFO_OF_EMPLOYEE_CURRENT_USER,
  UPDATE_PERMISSIONS_OF_EMPLOYEE_CURRENT_USER,
} from '../../actions/actionTypes'

import {
  REDUCER_NAMES_CURRENT_USER_EMPLOYEE,
} from '../../../constants'


export default currentUserReducerCreator({
  reducerName: REDUCER_NAMES_CURRENT_USER_EMPLOYEE,
  saveCurrentUserAction: SAVE_EMPLOYEE_CURRENT_USER,
  updateBasicInfoOfCurrentUserAction: UPDATE_BASIC_INFO_OF_EMPLOYEE_CURRENT_USER,
  updatePermissionsOfCurrentUserAction: UPDATE_PERMISSIONS_OF_EMPLOYEE_CURRENT_USER,
})
