// CODE_COMMENTS_96
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/fn/string/includes'
import 'core-js/es6/array'
import 'core-js/es7/array'
import 'raf/polyfill'
import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import localForage from 'localforage'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createBrowserHistory } from 'history' // for connected-react-router
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { enableBatching } from 'redux-batched-actions'
import { enableES5 } from 'immer'


import 'semantic-ui-css/semantic.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import { REDUX_LOGGER_TOGGLE } from './reduxLoggerToggle'
import createRootReducer from './redux/reducers'
import rootSaga from './redux/sagas'
import { handleErrorsInReducers } from './redux/customMiddleware'
import AppProvider from './AppProvider'

import {
  REDUCER_NAMES_AUTH,
  REDUCER_NAMES_CURRENT_USER,
  REDUCER_NAMES_CURRENT_USER_EMPLOYEE,
  REDUCER_NAMES_LATEST_WEB_APP_VERSION,
} from './constants'

import './styles/main.css'


// makes immer work on IE11:
// https://immerjs.github.io/immer/docs/installation#pick-your-immer-version
enableES5()

// replacement of Redux's `compose`, activates redux dev tools
const composeEnhancers = composeWithDevTools({})

const history = createBrowserHistory()

export const sagaMiddleware = createSagaMiddleware()

const allMiddleware = [
  routerMiddleware(history),
  sagaMiddleware,
  handleErrorsInReducers, // CODE_COMMENTS_131
  // logger must be the last middleware in chain, otherwise it will log
  // promises, not actual actions. Also, this is a conditional addition to the
  // array (we don't want Redux Logger enabled for deployment to production, as
  // that will noticeably slow down the app)
  ...(REDUX_LOGGER_TOGGLE ? [logger] : []),
]

const persistConfig = {
  key: 'root',
  // CODE_COMMENTS_2
  whitelist: [
    REDUCER_NAMES_AUTH,
    REDUCER_NAMES_CURRENT_USER,
    REDUCER_NAMES_CURRENT_USER_EMPLOYEE,
    REDUCER_NAMES_LATEST_WEB_APP_VERSION,
  ],
  storage: localForage,
}
let rootReducer = createRootReducer(history)
rootReducer = persistReducer(persistConfig, rootReducer)

// Why are we possibly exporting the store here? For very narrow use-cases such
// as third-party error logging. See CODE_COMMENTS_182
export const store = createStore(
  enableBatching( // CODE_COMMENTS_148
    rootReducer,
  ),
  undefined, // initial state is in reducers, not here
  composeEnhancers(
    applyMiddleware(...allMiddleware),
    // autoRehydrate(),
  ),
)
const persistor = persistStore(store)

// CODE_COMMENTS_18
if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./redux/reducers', () => {
      store.replaceReducer(rootReducer)
    })
  }
}

sagaMiddleware.run(rootSaga)

ReactDOM.render(
  <AppProvider
    store={store}
    persistor={persistor}
    history={history}
  />,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
)

// CODE_COMMENTS_18
if (module.hot) {
  module.hot.accept('./AppProvider', () => {
    ReactDOM.render(
      <AppProvider
        store={store}
        history={history}
      />,
      document.getElementById('root'),
    )
  })
}
