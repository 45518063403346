import React from 'react'
import { Card, Loader } from 'semantic-ui-react'

import {
  CUSTOMER_REPS_HUMAN_READABLE_NAMES,
} from '../../../constants'

export default ({ repType }) => (
  <Card>
    <Card.Content>
      <Card.Header>
        <span className="microstar-blue">{CUSTOMER_REPS_HUMAN_READABLE_NAMES[repType]}</span>
      </Card.Header>
      <Card.Description>
        <div style={{ minHeight: '100px' }}>
          <Loader active />
        </div>
      </Card.Description>
    </Card.Content>
  </Card>
)
