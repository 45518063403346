import React from 'react'
import { Button } from 'semantic-ui-react'

import { useTranslation } from 'react-i18next'
import {
  URL_PATH_DYNAMIC_LINKS_CUSTOMER_PORTAL,
} from '../../../../constants'

import RelativeLinkComponent from '../../../RelativeLinkComponent'

export default ({ color='blue', additionalActions }) => {
  const { t: translate } = useTranslation('common')
  return (
    <RelativeLinkComponent
      component={Button}
      to={URL_PATH_DYNAMIC_LINKS_CUSTOMER_PORTAL}
      type="button" // CODE_COMMENTS_260
      color={color}
      onClick={additionalActions}
    >
      {translate('Back to Customer Portal')}
    </RelativeLinkComponent>
  )
}
