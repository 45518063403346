import { select, call } from 'redux-saga/effects'

import {
  getProp as getCustomerProp,
} from '../../../selectors/customers'

import {
  getSourcePpfContractIdBetweenContracteeAndOperatingCBForQueryParam,
} from './contracteeBrewers'


import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_BREWER,
} from '../../../../constants'


// CODE_COMMENTS_90
export function* createQueryParametersForRelationshipApiCall(
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
) {
  const customerType = yield select(getCustomerProp, customerId, 'customerType')

  let sourceppfcontracttype
  // if Contract Brewer or contractee brewer
  if (
    customerType === CUSTOMER_TYPES_CONTRACT_BREWER ||
    (customerType === CUSTOMER_TYPES_BREWER && operatingContractBrewerCustomerId)
  ) {
    sourceppfcontracttype = CUSTOMER_TYPES_CONTRACT_BREWER
  } else {
    // If this is a non-contractee brewer or a distributor, do not include this
    // parameter
    sourceppfcontracttype = null
  }


  // Only include the following parameter if contractee brewer
  let sourceppfcontractid
  if (operatingContractBrewerCustomerId) {
    sourceppfcontractid = yield call(
      getSourcePpfContractIdBetweenContracteeAndOperatingCBForQueryParam,
      customerId,
      operatingContractBrewerCustomerId,
    )
  }


  return {
    ...(sourceppfcontracttype ? { sourceppfcontracttype } : {}),
    ...(sourceppfcontractid ? { sourceppfcontractid } : {}),
  }
}
