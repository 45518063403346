import {
  REDUCER_NAMES_HISTORY_OUTBOUND_EMPTY_KEG_SHIPMENTS,
} from '../../../constants'


import {
  createAllShipmentsHistorySelectorsHof,
} from './shipmentsHistoryHigherOrderFunctions'

export const {
  getProp,
  getPropOfAll,
  getEntireSlice,
  getAreAnyItemsInACertainStatus,
} = createAllShipmentsHistorySelectorsHof(REDUCER_NAMES_HISTORY_OUTBOUND_EMPTY_KEG_SHIPMENTS)
