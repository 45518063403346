import { connect } from 'react-redux'

import createAction from '../../redux/actions/createAction'

import {
  START_LATEST_WEB_APP_VERSION_FETCHER_THREAD,
  START_HARD_REFRESH_THREAD_TO_DOWNLOAD_LATEST_WEB_APP_VERSION,
} from '../../redux/actions/actionTypes'


export default connect()(({
  dispatch,
}) => {
  dispatch(createAction(START_LATEST_WEB_APP_VERSION_FETCHER_THREAD))
  dispatch(createAction(START_HARD_REFRESH_THREAD_TO_DOWNLOAD_LATEST_WEB_APP_VERSION))
  return null
})
