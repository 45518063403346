/**
 * There are several components which need the same single piece of information
 * from the store: "what's the customer type of the customer whose customerId
 * prop passed in?" Instead of writing multiple nearly-identical container
 * components for each of these, we write one wrapper component here.
 */
import { connect } from 'react-redux'


import {
  getBusinessUnitIdOfRootCustomer,
} from '../../../../redux/selectors/customers'


const mapStateToProps = state => {
  const businessUnitIdOfCurrentUserRootCustomer = getBusinessUnitIdOfRootCustomer({
    state,
    doThisRatherThanThrowErrorIfRootCustomerHasNotYetBeenFetched: () => null,
  })

  return {
    businessUnitIdOfCurrentUserRootCustomer,
  }
}

export default component => (
  connect(mapStateToProps)(component)
)
