import { connect } from 'react-redux'


import BannerMessageUpcomingMaintenanceWindow from './components'

import {
  getShouldNotificationOfUpcomingMaintenanceBeDisplayed,
  getMaintenanceSchedule,
} from '../../redux/selectors/maintenanceMode'


const mapStateToProps = state => ({
  shouldNotificationOfUpcomingMaintenanceBeDisplayed: getShouldNotificationOfUpcomingMaintenanceBeDisplayed(state),
  maintenanceSchedule: getMaintenanceSchedule(state),
})

export default connect(
  mapStateToProps,
)(BannerMessageUpcomingMaintenanceWindow)
