/**
 * CODE_COMMENTS_12
 */
import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_USERS as defaultSlice,
} from '../../constants'

import {
  withPropNormalized,
  withPropOfAllNormalized,
  withMultiplePropsOfAllNormalized,
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/

// usage: const firstName = getProp(state, userId, 'firstName')
export const getProp = withPropNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const arrayOfAllFirstNames = getPropOfAll(state, 'firstName')
export const getPropOfAll = withPropOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const idAndFirstNameOfAllCustomers = getMultiplePropsOfAll(state, 'id', 'firstName')
// returns:
// [
//   {id: 1234, firstName: 'David'},
//   {id: 1235, firstName: 'John'},
//   {id: 1236, firstName: 'Gonzo'},
// ]
export const getMultiplePropsOfAll = withMultiplePropsOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: you can use this to get the slice for all customers or for a single
// customer:
// const entireSliceForAllUsers = getEntireSlice(state)
// const entireSliceForSingleUser = getEntireSlice(state, userId)
export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)


/*
 * *****************************************************************************
 * More specific
 * *****************************************************************************
*/
