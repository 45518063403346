import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import startCase_ from 'lodash/startCase'


import { Trans } from 'react-i18next'
import ModalWithCatastrophicFailureErrorBoundary from '../../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'

import ModalContent from './ModalContent'


export default class DeleteItemModal extends Component {
  state = { modalOpen: false }

  // CODE_COMMENTS_69. Also, onUnmount is a Semantic UI React-specific lifecycle
  // method unique to the <Modal> component.
  onUnmount = () => {
    if (this.props.hasSubmitSucceeded) {
      this.props.onModalCloseAfterSuccessfulSubmit()
    }
  }

  handleOpen = () => this.setState({ modalOpen: true })


  handleClose = () => {
    this.setState({ modalOpen: false })
  }


  render() {
    const {
      itemType,
      humanReadableItemId,
      buttonText,
    } = this.props

    return (
      <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
        closeIcon
        closeOnDimmerClick={false} // CODE_COMMENTS_64
        open={this.state.modalOpen}
        onClose={this.handleClose}
        onUnmount={this.onUnmount}
        size="small"
        trigger={
          <Button
            compact
            size="mini"
            color="red"
            onClick={this.handleOpen}
          >
            <Trans ns="common" i18nKey={buttonText}>
              {buttonText}
            </Trans>
          </Button>
        }
      >
        <Modal.Header>
          {`${startCase_(`${buttonText} ${itemType}`)}: ${humanReadableItemId}`}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div style={{ textAlign: 'center' }}>
              <ModalContent
                closeModalFunc={this.handleClose}
                {...this.props}
              />
            </div>
          </Modal.Description>
        </Modal.Content>
      </ModalWithCatastrophicFailureErrorBoundary>
    )
  }
}
