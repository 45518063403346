import { put, call, takeEvery } from 'redux-saga/effects'

import {
  validateAndFilterArrayOfCollarPlateObjects,
} from '../util/apiResponseDataValidation/collarPlates'

import { privateFetch } from '../fetch'
import {
  FETCH_COLLAR_PLATES_INFO,
  SAVE_COLLAR_PLATES,
  SAVE_CUSTOMER_TO_COLLAR_PLATES_NORMALIZED_LINKS,
  FETCH_COLLAR_PLATES_INFO_REQUEST,
  FETCH_COLLAR_PLATES_INFO_SUCCESS,
  FETCH_COLLAR_PLATES_INFO_FAILURE,
} from '../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../utils'

import {
  API_URL_PATH_COLLAR_PLATES,
} from '../../../constants'


export function* fetchCustomerCollarPlates(action) {
  const {
    customerId,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  } = action.payload

  yield put(createAction(
    FETCH_COLLAR_PLATES_INFO_REQUEST,
    {
      target: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
    },
  ))

  const fetchConfig = {
    path: API_URL_PATH_COLLAR_PLATES,
    params: { customerId },
  }

  // CODE_COMMENTS_92
  if (operatingContractBrewerCustomerId) {
    const headers = createHeadersForContracteeBrewerApiCall(customerId)
    fetchConfig.headers = headers
  }

  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) { // customer has no collar plates on file
        yield call(doSuccess, customerId, operatingContractBrewerCustomerId, { data: [] })
        return
      }
      yield call(doFailure, customerId, operatingContractBrewerCustomerId, error)
      return
    }
    yield call(doFailure, customerId, operatingContractBrewerCustomerId, error)
    return
  }
  yield call(doSuccess, customerId, operatingContractBrewerCustomerId, response)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_COLLAR_PLATES_INFO, fetchCustomerCollarPlates],
]


function* doSuccess(customerId, operatingContractBrewerCustomerId, httpResponse) {
  const info = validateAndFilterArrayOfCollarPlateObjects(httpResponse)
  yield put(createAction(SAVE_COLLAR_PLATES, { info }))
  yield put(createAction(SAVE_CUSTOMER_TO_COLLAR_PLATES_NORMALIZED_LINKS, { leftSideObjIdValue: customerId, info }))
  yield put(createAction(
    FETCH_COLLAR_PLATES_INFO_SUCCESS,
    {
      target: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
    },
  ))
}

function* doFailure(customerId, operatingContractBrewerCustomerId, error) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_COLLAR_PLATES_INFO_FAILURE,
        target: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId)],
      }),
    },
  )
}
