import { connect } from 'react-redux'


import UnackedInboundShipmentsTable from './components/UnackedInboundShipmentsTable'

import {
  getEntireSlice as getEntireInboundUnacknowledgedShipmentsSlice,
} from '../../../../redux/selectors/rewrite/inboundUnacknowledgedShipments'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
} from '../../../../redux/selectors/rewrite/subsidiaries'
import {
  getEntireSlice as getEntireCustomersSlice,
  getProp as getCustomerProp,
} from '../../../../redux/selectors/rewrite/customers'

const mapStateToProps = (state, { customerId }) => {
  const customers = getEntireCustomersSlice(state)
  const subsidiaryId = getCustomerProp(state, customerId, 'subsidiaryId')
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)
  const inboundUnacknowledgedShipmentsSliceForThisCustomer = getEntireInboundUnacknowledgedShipmentsSlice(
    state,
    customerId,
  )
  return {
    customers,
    entireSubsidiariesSlice,
    subsidiaryId,
    inboundUnacknowledgedShipmentsSliceForThisCustomer,
  }
}

export default connect(
  mapStateToProps,
)(UnackedInboundShipmentsTable)
