/**
 * CODE_COMMENTS_62
 */
import React from 'react'


import {
  DisplayedCustomerName,
} from './displayedInventoryReportDataConnectedComponents'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  formatApiDate,
} from '../../../../../utils'


export const createDisplayedDateCounted = row => formatApiDate(row.countDate, DEFAULT_DISPLAYED_DATE_FORMAT)
export const createDisplayedCustomerName = row => <DisplayedCustomerName row={row} />
export const createDisplayedDateReported = row => formatApiDate(row.reportDate, DEFAULT_DISPLAYED_DATE_FORMAT)
export const createDisplayedReportingPerson = row => (
  // When would a reporting user's name ever be the string 'null null'? It's a
  // backend bug (https://microstartap3.atlassian.net/browse/TP3-1175) that
  // we're making less visible on the web app until the backend team gets this
  // fixed. Instead of displaying 'null null' to user, show a blank string.
  row.reportingUsersName === 'null null'
    ? ''
    : row.reportingUsersName
)
export const createDisplayedCountedBy = row => row.countedBy
export const createDisplayedOrderComments = alternateText => row => row.comments || <span style={{ color: 'grey' }}>{alternateText}</span>
