import historyReducerCreator from './historyReducerCreator'

import {
  SAVE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
  SAVE_SINGLE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM,
} from '../../actions/actionTypes'


export default historyReducerCreator({
  saveHistoryOfSingleCustomerActionType: SAVE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
  saveSingleHistoryItemActionType: SAVE_SINGLE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM,
})
