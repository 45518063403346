/* eslint-disable max-len */

import { call, select, takeEvery } from 'redux-saga/effects'

import get_ from 'lodash/get'
import historySagaCreator, { doSuccess } from './historySagaCreator'
import { sagaMiddleware } from '../../../../index'
import {
  FETCH_INVOICE_HISTORY,
  SAVE_INVOICE_HISTORY,
} from '../../../actions/actionTypes'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  URL_PARAM_CUSTOMER_ID,
  URL_PARAM_START_DATE,
  URL_PARAM_END_DATE,
  URL_PARAM_STATUS,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/invoices'

import {
  API_URL_PATH_INVOICES_METADATA, CUSTOMER_TYPES_MASTER,
} from '../../../../constants'

import {
  formatDateRangeForApiCall,
} from '../../../../utils'
import historySagaCreatorMultipleFetchesForOneFormSubmission
from './historySagaCreatorMultipleFetchesForOneFormSubmission'
import { getAllChildCustomerIdsOfCustomer } from '../../../selectors/children'

const createFetchParamsFunc = ({ customerId, formValues }) => {
  const { startDate, endDate } = formatDateRangeForApiCall({
    startDate: formValues[HISTORY_FORM_FIELD_NAME_START_DATE],
    endDate: formValues[HISTORY_FORM_FIELD_NAME_END_DATE],
  })

  return {
    [URL_PARAM_CUSTOMER_ID]: customerId,
    [URL_PARAM_START_DATE]: startDate,
    [URL_PARAM_END_DATE]: endDate,
    [URL_PARAM_STATUS]: 'FINALIZED',
  }
}

const stillSaveOnErrorFunc = error => {
  if (error.response.status === 404) { // no orders within this date range
    return []
  }
  return undefined
}

function* doAfterAllCallsHaveFinished({
  responses,
  customerId,
  operatingContractBrewerCustomerId,
  formName,
  formValues,
  setIsFetching,
  setDidFetchFail,
  setDidFetchSucceed,
}) {
  const invoiceResponse = responses?.[0] || []
  const info = invoiceResponse.reduce((acc, curr) => {
    if (curr?.didFetchSucceed && Array.isArray(curr?.response?.data)) {
      return [...acc, ...curr.response.data]
    }
    return []
  }, [])

  yield call(
    doSuccess,
    {
      saveAction: SAVE_INVOICE_HISTORY,
      customerId,
      operatingContractBrewerCustomerId,
      formName,
      formValues,
      setIsFetching,
      setDidFetchSucceed,
      setDidFetchFail,
      info,
    })
}

function* createConfigFunc(props) {
  const {
    customerId,
  } = props

  const state = yield select()
  const childCustomerIds = getAllChildCustomerIdsOfCustomer({ state, customerId })
  const fetchDefinitions = []
  const obj = []
  childCustomerIds.forEach(id => {
    const fetchDefinitionEachCustomer = {
      fetchConfig: {
        path: API_URL_PATH_INVOICES_METADATA,
        params: createFetchParamsFunc({ ...props, customerId: id }),
      },
      // no doSuccess here: instead, the data is saved in doAfterAllCallsHaveFinished
      doFailure: ({ error }) => {
        // no shipments within this date range
        if (get_(error, ['response', 'status']) === 404) {
          return []
        }
        return undefined
      },
      failureShouldNotResultInTheFailureOfTheEntireSaga: true,
    }
    // This is how to get the fetches to run in parallel; see CODE_COMMENTS_141
    obj.push(fetchDefinitionEachCustomer)
  })
  fetchDefinitions.push(obj)
  return {
    fetchDefinitions,
    doAfterAllCallsHaveFinished,
  }
}

function invoiceHistorySagaCreator(action) {
  if (action?.payload?.customerType === CUSTOMER_TYPES_MASTER) {
    const saga = historySagaCreatorMultipleFetchesForOneFormSubmission({
      createConfigFunc,
    })
    sagaMiddleware.run(saga, { ...action })
  } else {
    const saga = historySagaCreator({
      apiUrlPath: API_URL_PATH_INVOICES_METADATA,
      createFetchParamsFunc,
      stillSaveOnErrorFunc,
      saveAction: SAVE_INVOICE_HISTORY,
    })
    sagaMiddleware.run(saga, { ...action })
  }
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_INVOICE_HISTORY, invoiceHistorySagaCreator],
]
