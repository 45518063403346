import {
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY,
} from '../../../../constants'
import getFetchStatusSelectorsByCustomerId from '../HOF/fetchStatusSelectors/byCustomerId'

export default getFetchStatusSelectorsByCustomerId(
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY,
)
