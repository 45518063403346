/* eslint-disable max-len */

import React, { Fragment, useMemo, useState, useEffect, useReducer, useCallback, useLayoutEffect } from 'react'
import { Form, Grid, Message, Header, TextArea, Dropdown, Button, Container } from 'semantic-ui-react'
import moment from 'moment'
import produce from 'immer'

import get_ from 'lodash/get'
import intersection_ from 'lodash/intersection'

import flow_ from 'lodash/fp/flow'
import filterFp_ from 'lodash/fp/filter'
import pickFp_ from 'lodash/fp/pick'
import omitFp_ from 'lodash/fp/omit'
import valuesFp_ from 'lodash/fp/values'
import mapFp_ from 'lodash/fp/map'
import sumFp_ from 'lodash/fp/sum'

import cloneDeep_ from 'lodash/cloneDeep'

import { Trans, useTranslation } from 'react-i18next'
import InputNoAutoComplete from '../../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import ReactDatePicker, { validateDate } from '../../../../common-components/rewrite/ReactDatepicker'
import SemanticUiCardChoices from '../../../../common-components/rewrite/SemanticUiCardChoices'
import InformationalPopup from '../../../../common-components/InformationalPopup'
import ContentToTheRightOfAFormField from '../../../../common-components/ContentToTheRightOfAFormField'
// import FormSectionInLabeledGridLayout from '../../../../common-components/FormSectionInLabeledGridLayout'
import DisplayedAddress from '../../../../common-components/DisplayedAddress'
import RevealContactInfo from '../../../../common-components/RevealContactInfo'
import ContactCard from '../../../../common-components/ContactCard'

import FormSubmissionDimmerOverlay from '../../../../common-components/rewrite/FormSubmission/DimmerOverlay'
import SubmitButtonWithConfirmFormInput from '../../../../common-components/rewrite/SubmitButtonWithConfirmFormInput'
import ContainerTypesDifferences from '../../../ContainerTypesDifferences'

import {
  createAddressChoicesForSemanticUiCardChoices,
} from '../../../../common-components/rewrite/SemanticUiCardChoices/util'

import {
  FETCH_ORDER_KEGS,
  FETCH_WAREHOUSE_PICKUP_LOCATIONS,
} from '../../../../redux/actions/actionTypes'
import createAction from '../../../../redux/actions/createAction'
import {
  getSortedShippingAddressesOfCustomer,
} from '../../../../redux/selectors/rewrite/addresses'
import {
  getItemSkusDefaultSortOrder,
} from '../../../../redux/selectors/rewrite/itemSkus'
import {
  getIsBrwOrConBrwRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn,
} from '../../../../redux/selectors/rewrite/multipleStoreSlicesSelectors'

import {
  ORDER_KEGS_FORM_FIELD_LABEL_DATE_NEEDED,
  ORDER_KEGS_FORM_FIELD_LABEL_SHIPPING_ADDRESS,
  ORDER_KEGS_FORM_FIELD_LABEL_CUSTOMER_PO_NUM,
  ORDER_KEGS_FORM_FIELD_LABEL_ORDER_COMMENTS,

  ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED,
  ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS,
  ORDER_KEGS_FORM_FIELD_NAME_CUSTOMER_PO_NUM,
  ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS,

  ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER,
  ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER,

  MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER,

  getNonlocalQuantitiesFormFieldsNames,
  getLocalQuantitiesFormFieldsNames,
  // getQuantitiesFormFieldsNames,
  createOrderKegsFormFieldNameOfQuantity,
  ORDER_KEGS_FORM_FIELD_NAME_PALLET_TYPE,
  ORDER_KEGS_FORM_FIELD_LABEL_PALLET_TYPE,
  ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE,
  DEFAULT_ORDER_TYPES_FOR_ORDERING_KEGS,
  ORDER_TYPES_LABEL,
  ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS,
  ORDER_KEGS_FORM_FIELD_LABEL_PICKUP_ADDRESS,
  ORDER_KEGS_FORM_FIELD_LABEL_ORDER_TYPE,
} from '../../../../constants/formAndApiUrlConfig/orderKegs'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
  FORM_FIELD_PO_NUMBER_EXPLANATION,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  UNIVERSAL_CUSTOMER_REPS_ACCOUNTS_RECEIVABLE,
  CUSTOMER_STATUS_PRE_PAY,
  ITEM_SKUS_SKU_TYPE_PALLET,
  KEG_ORDERS_MIN_DAYS,
  ORDER_KEGS_ORDER_TYPE_PICKUP,
  ORDER_KEGS_ORDER_TYPE_BLANKET,
  ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
  ITEM_SKUS_SKU_TYPE_COMPOSITE,
  ORDER_KEGS_ORDER_TYPE_LOCAL,
} from '../../../../constants'


import {
  // getHumanReadableContainerType,
  sortArrayByTemplateArray,
  getBusinessUnitNameFromBusinessUnitId,
  isTruthyAndNonEmpty,
  getHumanReadableContainerTypeFromItemSku,
  convertCountryAlpha2CodeToAlpha3,
  formatApiDate,
} from '../../../../utils'

import {
  normalizerCombiner,
  nonZeroPaddedPositiveIntegerOrZero,
  maxLength,
} from '../../../../utils/formNormalization'
import {
  DELIVERY_SCHEDULE_FORM_VALUE,
  DELIVERY_SCHEDULE_ORDER_TOTAL,
  DELIVERY_SCHEDULE_FORM_ADD_DELETE_ROW, subsidiaries, ORDER_KEGS_NO_SUPPORT_FOR_ETA_MESSAGE,
} from '../../OrderKegs/constants'
import { buildFormValues, DeliveryScheduleForm } from './DeliverySchedule'
import XDaysFromNow from './XDaysFromNow'
import {
  FIELD_NAME_FOREIGN_KEGS,
  FIELD_NAME_TOTAL_NUMBER_KEGS,
  FIELD_NAME_TOTAL_NUMBER_PALLETS,
} from '../../../../constants/formAndApiUrlConfig/acknowledgeInboundShipments'
import {
  CONTACT_ACC_RECEIVABLE_FOR_PAYMENT, DATE_NEEDED, INTERNAL_NAME_PO,
  KEGS_ORDERED, KEGS_WILL_NOT_BE_PROCESSED, ORDER_COMMENTS, ORDER_KEGS_FORM_TENTATIVE_MSG, ORDER_TYPE, PALLET_TYPE,
  PAY_KEGS_BEFORE_THEY_DELIVER,
  REMINDER_PREPAY_FOR_YOUR_KEGS, SHIP_TO, SUBMIT,
} from '../../../../constants/labelCaptions'
import { extractDateValidationErrorMessageIfPresent } from '../../../../redux/sagas/forms/orderKegs/util'


const CHANGE_FORM_VALUE = 'CHANGE_FORM_VALUE'
const RESET_FORM_VALUES_TO_INITIAL_VALUES = 'RESET_FORM_VALUES_TO_INITIAL_VALUES'

function calculateTotalNumberOfKegsValue({
  rowValues,
  entireItemSkusSlice,
}) {
  return flow_(
    pickFp_([...Object.keys(entireItemSkusSlice), FIELD_NAME_FOREIGN_KEGS]),
    omitFp_([ITEM_SKU_IDS_CBI_PLASTIC_PALLET]), // CODE_COMMENTS_277
    valuesFp_,
    mapFp_(value => (Number(value) || 0)),
    sumFp_,
  )(rowValues)
}
export function calculateOrderTotal({
  formValues,
}) {
  return flow_(
    valuesFp_,
    mapFp_(obj => Number(obj[FIELD_NAME_TOTAL_NUMBER_PALLETS] || 0)),
    sumFp_,
  )(formValues)
}
export function getLastOrderDate({
  formValues,
}) {
  const momArr = formValues?.filter(obj => obj[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED])?.map(val => moment(val[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED], DEFAULT_DISPLAYED_DATE_FORMAT)) || []
  return (momArr.length && formatApiDate(moment.max(momArr), DEFAULT_DISPLAYED_DATE_FORMAT)) || ''
}

/* eslint-disable no-param-reassign, no-useless-return, consistent-return */
export const changeFormValue = produce((formValues, action) => {
  switch (action.type) {
    case CHANGE_FORM_VALUE: {
      // eslint-disable-next-line prefer-const
      let [fieldName, value] = action.payload
      const previousValue = formValues[fieldName]
      value = normalizeInputValue({
        previousValue,
        value,
        fieldName,
      })
      formValues[fieldName] = value
      return
    }
    case 'INIT_BLANKET_ORDER_TYPE': {
      const { itemSkuIds } = action.payload
      return {
        ...formValues,
        [DELIVERY_SCHEDULE_FORM_VALUE]: buildDeliveryScheduleFormValue({ itemSkuIds }),
      }
    }
    case DELIVERY_SCHEDULE_FORM_VALUE: {
      // eslint-disable-next-line prefer-const
      const {
        rowIndex,
        fieldName,
        entireItemSkusSlice,
      } = action.payload
      let {
        value,
      } = action.payload
      const previousValue = formValues[DELIVERY_SCHEDULE_FORM_VALUE][rowIndex][fieldName]
      value = normalizeInputValue({
        previousValue,
        value,
        fieldName,
      })
      formValues[DELIVERY_SCHEDULE_FORM_VALUE][rowIndex][fieldName] = value
      const formValuesForOrderTotal = cloneDeep_(formValues)
      Object.keys(formValuesForOrderTotal[DELIVERY_SCHEDULE_FORM_VALUE][rowIndex]).forEach(key => {
        const { skuType, itemsPerPallet } = entireItemSkusSlice?.[key] || {}
        const val = formValuesForOrderTotal[DELIVERY_SCHEDULE_FORM_VALUE]?.[rowIndex]?.[key]
        if (skuType === ITEM_SKUS_SKU_TYPE_COMPOSITE && itemsPerPallet) {
          const newValue = val * itemsPerPallet
          formValuesForOrderTotal[DELIVERY_SCHEDULE_FORM_VALUE][rowIndex][key] = newValue
        } else {
          formValuesForOrderTotal[DELIVERY_SCHEDULE_FORM_VALUE][rowIndex][key] = val
        }
      })
      if (formValues[DELIVERY_SCHEDULE_FORM_VALUE]?.[rowIndex]) {
        formValues[DELIVERY_SCHEDULE_FORM_VALUE][rowIndex][FIELD_NAME_TOTAL_NUMBER_KEGS] = calculateTotalNumberOfKegsValue({
          rowValues: formValuesForOrderTotal[DELIVERY_SCHEDULE_FORM_VALUE][rowIndex],
          entireItemSkusSlice,
        }) || ''

        formValues[DELIVERY_SCHEDULE_FORM_VALUE][rowIndex][FIELD_NAME_TOTAL_NUMBER_PALLETS] =
          calculateTotalNumberOfKegsValue({
            rowValues: formValues[DELIVERY_SCHEDULE_FORM_VALUE][rowIndex],
            entireItemSkusSlice,
          }) || ''
      }
      formValues[DELIVERY_SCHEDULE_ORDER_TOTAL] = calculateOrderTotal({
        formValues: formValues[DELIVERY_SCHEDULE_FORM_VALUE],
      })
      formValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED] = getLastOrderDate({
        formValues: formValues[DELIVERY_SCHEDULE_FORM_VALUE],
      })
      return
    }
    case RESET_FORM_VALUES_TO_INITIAL_VALUES: {
      const {
        sortedShippingAddresses,
        orderTypes,
        itemSkuIds,
      } = action.payload
      return getInitialValues({
        sortedShippingAddresses,
        orderTypes,
        itemSkuIds,
      })
    }
    case DELIVERY_SCHEDULE_FORM_ADD_DELETE_ROW: {
      const { type, rowIndex, itemSkuIds } = action.payload
      if (rowIndex > 0 && type === 'delete') {
        formValues[DELIVERY_SCHEDULE_FORM_VALUE] = formValues[DELIVERY_SCHEDULE_FORM_VALUE]?.filter((data, index) => index !== rowIndex)
      } else if (type === 'add') {
        // const newKey = formValues[DELIVERY_SCHEDULE_FORM_VALUE].length
        const newValue = buildDeliveryScheduleFormValue({ itemSkuIds })
        formValues[DELIVERY_SCHEDULE_FORM_VALUE] = [...formValues[DELIVERY_SCHEDULE_FORM_VALUE], ...newValue]
      }
      formValues[DELIVERY_SCHEDULE_ORDER_TOTAL] = calculateOrderTotal({
        formValues: formValues[DELIVERY_SCHEDULE_FORM_VALUE],
      })
      return
    }
    default: { return }
  }
})
/* eslint-enable no-param-reassign, no-useless-return, consistent-return */


const labelWidth = '180px' // CODE_COMMENTS_22

function addDays(date, days) {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

function getExcludedDates(param) {
  const { orderType, holidays=[] } = param || {}
  const excludedDates = []

  if (orderType === ORDER_KEGS_ORDER_TYPE_LOCAL) {
    return excludedDates
  }

  holidays.forEach(day => {
    if (day?.timeZone.includes('America') || day?.timeZone.includes('Pacific/Honolulu')) {
      const gmtDate = new Date(day.date)
      const year = gmtDate.getUTCFullYear()
      const month = String(gmtDate.getUTCMonth() + 1)
          .padStart(2, '0') // Add leading zero if needed
      const day1 = String(gmtDate.getUTCDate())
          .padStart(2, '0') // Add leading zero if needed
      const formattedDate = `${year}-${month}-${day1}`
      excludedDates.push(formattedDate)
    } else {
      const IntlFormat = new Intl.DateTimeFormat('en-US', { timeZone: 'UTC' }) // Always format in UTC
      const holidayDate = new Date(IntlFormat.format(new Date(day.date)))
      const holidayDateUTC = new Date(holidayDate.getTime() - (holidayDate.getTimezoneOffset() * 60000))
      excludedDates.push(holidayDateUTC.getTime())
    }
  })
  return excludedDates
}

export default props => {
  const {
    customerId,
    entireCustomersSlice,
    entireContractsSlice,
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    itemSkuIds: itemSkuIdsUnsorted,
    dispatch,
    defaultCountry,
    customerContracts,
    orderDetails,
    kegOrderDays,
    subsidiaryId,
  } = props
  const businessUnitId = entireCustomersSlice?.[customerId]?.businessUnitId
  const { t=a => a } = useTranslation('pagelabels') || {}
  const sortedItemSkuIds = useMemo(
    () => getItemSkuIdsForOrderKegsFormMinusPallets({
      entireItemSkusSlice,
      itemSkuIds: itemSkuIdsUnsorted,
    }),
    [entireItemSkusSlice, itemSkuIdsUnsorted],
  )
  const [itemSkuIds, setItemSkuIds] = useState(sortedItemSkuIds)
  const palletTypes = useMemo(
    () => {
      const palletTypeSkus = itemSkuIdsUnsorted.reduce((acc, curr) => {
        if (entireItemSkusSlice?.[curr]?.skuType?.toUpperCase() === ITEM_SKUS_SKU_TYPE_PALLET) {
          const key = curr
          const value = curr
          const { defaultCustomerText: text } = entireItemSkusSlice?.[curr] || ''
          return [...acc, ...(text && [{ key, value, text: t(`orderKegs.${text}`) }])] // spread only if option text available
        }
        return acc
      }, [])
      return palletTypeSkus
    },
    [itemSkuIdsUnsorted, entireItemSkusSlice, t],
  )
  const isPrepayCustomer = useMemo(
    () => get_(entireCustomersSlice, [customerId, 'customerStatus']) === CUSTOMER_STATUS_PRE_PAY,
    [entireCustomersSlice, customerId],
  )
  const hasLocalDistributors = useMemo(
    () => getHasLocalDistributors({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
    }),
    [
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
    ],
  )
  const orderTypes = useMemo(() => {
    const availableOrdersTypes = DEFAULT_ORDER_TYPES_FOR_ORDERING_KEGS
    return availableOrdersTypes?.filter(orderType => {
      const { showOnlyIfTrue } = orderType
      if (typeof showOnlyIfTrue === 'function') {
        const isValidOption = showOnlyIfTrue({
          businessUnit: businessUnitId,
          hasLocalDistributors,
          canUseDeliverySchedule: customerContracts?.canUseDeliverySchedule,
          pickupFulfillment: customerContracts?.pickupFulfillment,
          deliveredFulfillment: customerContracts?.deliveredFulfillment,
          contractEndDate: customerContracts?.contractEndDate,
        })
        if (isValidOption) {
          return true
        }
        return false
      }
      return true
    })?.map(orderType => {
      const { key, value, text } = orderType
      // eslint-disable-next-line
      return { key, value, text: t(`orderKegs.${text}`) }
    }) || []
  }, [businessUnitId, hasLocalDistributors, customerContracts, t])
  const sortedShippingAddresses = useMemo(
    () => (
      getSortedShippingAddressesOfCustomer({
        entireAddressesSlice,
        entireCustomerAddressLinksSlice,
        customerId,
      })
    ),
    [
      entireAddressesSlice,
      entireCustomerAddressLinksSlice,
      customerId,
    ],
  )
  const semanticUiCardAddressChoices = useMemo(
    () => (
      createAddressChoicesForSemanticUiCardChoices(sortedShippingAddresses)
    ),
    [sortedShippingAddresses],
  )
  const [formValues, dispatchFormValues] = useReducer(
    changeFormValue,
    getInitialValues({
      sortedShippingAddresses,
      orderTypes,
      itemSkuIds,
      orderDetails,
      entireItemSkusSlice,
    }),
  )

  const [hasAtLeastOneNonLocalKegQtyFieldBeenBlurred, setHasAtLeastOneNonLocalKegQtyFieldBeenBlurred] = useState(false)
  const [selectedPalletType, setSelectedPalletType] = useState('')
  const [selectedOrderType, setSelectedOrderType] = useState(formValues?.[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE])
  const localOrNonLocal = selectedOrderType !== ORDER_KEGS_ORDER_TYPE_LOCAL ? ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER : ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER
  const [shouldMimimumNonlocalKegsWarningBeShown, setShouldMimimumNonlocalKegsWarningBeShown] = useState(false)
  useEffect(
    () => {
      setShouldMimimumNonlocalKegsWarningBeShown(getShouldMimimumNonlocalKegsWarningBeShown({
        formValues,
        itemSkuIds,
        hasLocalDistributors,
        hasAtLeastOneNonLocalKegQtyFieldBeenBlurred,
        entireItemSkusSlice,
      }))
    },
    [
      formValues,
      itemSkuIds,
      hasLocalDistributors,
      hasAtLeastOneNonLocalKegQtyFieldBeenBlurred,
      entireItemSkusSlice,
    ],
  )

  const [isFormSubmittable, setIsFormSubmittable] = useState(false)
  useEffect(
    () => {
      setIsFormSubmittable(getIsFormSubmittable({
        formValues,
        itemSkuIds,
        hasLocalDistributors,
        entireItemSkusSlice,
        entireCustomersSlice,
        customerId,
        customerContracts,
        kegOrderDays,
      }))
    },
    [
      formValues,
      itemSkuIds,
      hasLocalDistributors,
      entireItemSkusSlice,
      entireCustomersSlice,
      customerId,
      customerContracts,
      kegOrderDays,
    ],
  )

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(false)
  const [didSubmitFail, setDidSubmitFail] = useState(false)
  const [didFetchFail, setDidFetchFail] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [kegOrderValidationMessage, setKegOrderValidationMessage] = useState([])
  const [pickupLocations, setPickUpLocations] = useState([])
  const isNonLocalQuantitiesGreaterThanZero = useMemo(() => {
    if (!formValues) {
      return true
    }

    const nonLocalQuantitiesFieldNames = getNonlocalQuantitiesFormFieldsNames(itemSkuIds)
    const sumOfNonLocalKegs = nonLocalQuantitiesFieldNames.reduce(
      (acc, fieldName) => {
        const qty = Number(formValues[fieldName]) || 0
        return acc + qty
      },
      0,
    )
    return sumOfNonLocalKegs <= 0
  }, [formValues, itemSkuIds])

  const dropDownChangeEffect = useCallback(value => {
    if (selectedPalletType) {
      const prevFieldName = createOrderKegsFormFieldNameOfQuantity(selectedPalletType, ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER)
      dispatchFormValues(createAction(CHANGE_FORM_VALUE, [prevFieldName, '']))
    }
    if (value) {
      const fieldName = createOrderKegsFormFieldNameOfQuantity(value, ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER)
      dispatchFormValues(createAction(CHANGE_FORM_VALUE, [fieldName, '1']))
    }
    setSelectedPalletType(value)
  }, [selectedPalletType])

  const semanticUiCardPickUpAddressChoices = useMemo(
    () => createAddressChoicesForSemanticUiCardChoices(pickupLocations),
    [pickupLocations],
  )

  useEffect(() => {
    if (!itemSkuIds?.length) {
      setIsFormSubmittable(false)
      setErrorMessage('No available keg types to order.')
    } else {
      setErrorMessage('')
    }
  }, [itemSkuIds])

  useEffect(
    () => {
      const { value } = palletTypes?.[0] || {}
      if (value && !isNonLocalQuantitiesGreaterThanZero) {
        dropDownChangeEffect(value)
      } else {
        dropDownChangeEffect('')
      }
    }, [palletTypes, isNonLocalQuantitiesGreaterThanZero, selectedPalletType, dropDownChangeEffect])

  useEffect(
    () => {
      if (!defaultCountry || !businessUnitId) return
      dispatch(createAction(
        FETCH_WAREHOUSE_PICKUP_LOCATIONS,
        {
          country: convertCountryAlpha2CodeToAlpha3(defaultCountry) || defaultCountry,
          // country: 'GBR',
          customerId,
          businessUnitId,
          // businessUnitId: 'KS',
          setDidFetchFail,
          setErrorMessage,
          setPickUpLocations,
        }))
    },
    [dispatch, customerId, defaultCountry, businessUnitId],
  )

  useEffect(() => {
    if (selectedOrderType !== ORDER_KEGS_ORDER_TYPE_PICKUP) {
      setItemSkuIds(sortedItemSkuIds)
    }

    if (selectedOrderType === ORDER_KEGS_ORDER_TYPE_BLANKET) {
      // dispatchFormValues(createAction('INIT_BLANKET_ORDER_TYPE', { itemSkuIds }))
    } else if (selectedOrderType === ORDER_KEGS_ORDER_TYPE_PICKUP) {
      if (!pickupLocations?.length) {
        setDidFetchFail(true)
        setErrorMessage('Sorry, there are currently no pickup locations in your country.')
        return
      } else if (pickupLocations?.length && !formValues?.[ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS]) {
        dispatchFormValues(createAction(CHANGE_FORM_VALUE, [ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS, pickupLocations?.[0]?.id]))
        return
      }
      const pickUpAddressId = formValues?.[ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS]
      const whItemSkuIds = pickupLocations?.filter(({ id }) => id === pickUpAddressId)?.[0]?.itemSkuIds
      if (selectedOrderType === ORDER_KEGS_ORDER_TYPE_PICKUP && pickUpAddressId) {
        if (!whItemSkuIds?.length) {
          setItemSkuIds([])
        } else {
          const interSectionArr = intersection_(whItemSkuIds, sortedItemSkuIds)
          const sortedInterSectionArr = sortArrayByTemplateArray(
            interSectionArr,
            getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
          )
          setItemSkuIds(sortedInterSectionArr)
        }
      } else {
        setItemSkuIds(sortedItemSkuIds)
      }
    } else {
      setDidFetchFail(false)
      setErrorMessage('')
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    selectedOrderType,
    pickupLocations,
    formValues?.[ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS],
    dispatchFormValues,
    createAction,
  ])

  // Added for work around. Since moment js can't able to disable weekends
  useLayoutEffect(() => {
    const subsidiary = subsidiaries?.[entireCustomersSlice?.[customerId]?.subsidiaryId] || {}
    if (subsidiary && Object.values(subsidiary).length > 0) {
      const form = document.querySelector('#orderKegsSection')
      if (form) {
        // form.classList.add('disable-weekend-dates')
      }
    }
  }, [entireCustomersSlice, customerId])

  const handleSubmit = () => {
    // need to send pallet type in payload for regular ordertype
    const payloadItemSkuIds = (selectedPalletType && [...itemSkuIds, selectedPalletType]) || itemSkuIds
    dispatch(createAction(
      FETCH_ORDER_KEGS,
      {
        customerId,
        itemSkuIds: payloadItemSkuIds,
        formValues,
        setIsSubmitting,
        setDidSubmitSucceed,
        setDidSubmitFail,
        entireCustomersSlice,
        setKegOrderValidationMessage,
      },
    ))
  }

  const onResetForm = callBack => {
    dispatchFormValues(createAction(
      RESET_FORM_VALUES_TO_INITIAL_VALUES,
      {
        sortedShippingAddresses,
        orderTypes,
        itemSkuIds,
      },
    ))
    setIsSubmitting(false)
    setDidSubmitSucceed(false)
    setDidSubmitFail(false)
    if (typeof callBack === 'function') {
      callBack()
    }
  }

  const quantityFields = (formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE] === ORDER_KEGS_ORDER_TYPE_BLANKET) ?
    <DeliveryScheduleForm translate={t} formValues={formValues} dispatchFormValues={dispatchFormValues} {...props} />
    : (
      itemSkuIds.map(itemSkuId => (
        <Grid.Row key={itemSkuId}>
          <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
            <Form.Field required>
              <label htmlFor={itemSkuId}>
                {getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice)}
                <ContainerTypesDifferences
                  customerId={customerId}
                />
              </label>
            </Form.Field>
          </Grid.Column>
          <Grid.Column style={{ flex: '1' }}>
            <KegQtyField
              itemSkuId={itemSkuId}
              localOrNonlocal={localOrNonLocal}
              setHasAtLeastOneNonLocalKegQtyFieldBeenBlurred={setHasAtLeastOneNonLocalKegQtyFieldBeenBlurred}
              shouldMimimumNonlocalKegsWarningBeShown={shouldMimimumNonlocalKegsWarningBeShown}
              formValues={formValues}
              dispatchFormValues={dispatchFormValues}
              entireItemSkusSlice={entireItemSkusSlice}
            />
          </Grid.Column>
        </Grid.Row>
      ))
    )

  return (
    <React.Fragment>
      {(subsidiaryId === 24 || subsidiaryId === 25) &&
      <Container>
        <Message>
          <Trans ns='pagelabels' i18nKey={`${ORDER_KEGS_NO_SUPPORT_FOR_ETA_MESSAGE}`} />
        </Message>
      </Container>
      }
      <br/>
      {errorMessage && <Message negative>{t(`orderKegs.${errorMessage}`)}</Message>}
      <FormSubmissionDimmerOverlay
        customerId={customerId}
        isSubmitting={isSubmitting}
        didSubmitSucceed={didSubmitSucceed}
        didSubmitFail={didSubmitFail}
        onResetForm={onResetForm}
        kegOrderValidationMessage={extractDateValidationErrorMessageIfPresent(kegOrderValidationMessage)?extractDateValidationErrorMessageIfPresent(kegOrderValidationMessage):''}
        successMessage={(
          <div>
            <Trans ns='pagelabels' i18nKey={`orderKegs.${KEGS_ORDERED}`}>
              <p>Your kegs have been ordered.</p>
            </Trans>
            {isPrepayCustomer &&
            <Fragment>
              <Trans ns='pagelabels' i18nKey={`orderKegs.${PAY_KEGS_BEFORE_THEY_DELIVER}`}>
                <p style={{ color: 'orange', fontSize: '1.7rem', margin: 0 }}>You MUST PAY for your kegs before they will be delivered. Contact Accounts Receivable to make a payment.</p>
              </Trans>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1em 0' }}>
                <ContactCard
                  repType={UNIVERSAL_CUSTOMER_REPS_ACCOUNTS_RECEIVABLE}
                />
              </div>
            </Fragment>
          }
          </div>
      )}
      >
        {isPrepayCustomer &&
        <Message
          warning
          style={{ marginBottom: '2rem' }}
        >
          <Trans ns='pagelabels' i18nKey={`orderKegs.${REMINDER_PREPAY_FOR_YOUR_KEGS}`}>
            <Header as="h2">Reminder: Prepay for Your Kegs</Header>
          </Trans>
          <Trans ns='pagelabels' i18nKey={`orderKegs.${REMINDER_PREPAY_FOR_YOUR_KEGS}`}>
            <span>After submitting this form, you must pay for your kegs.</span>
          </Trans>
          <Trans ns='pagelabels' i18nKey={`orderKegs.${KEGS_WILL_NOT_BE_PROCESSED}`}>
            <span style={{ fontWeight: 'bold', color: 'red' }}> Your order will not be processed until payment is made. </span>
          </Trans>
          <Trans ns='pagelabels' i18nKey={`orderKegs.${CONTACT_ACC_RECEIVABLE_FOR_PAYMENT}`}>
            <span>
              Contact Accounts Receivable to make a payment.
            </span>
          </Trans>
          <RevealContactInfo
            asPopup
            buttonColor="blue"
            repType={UNIVERSAL_CUSTOMER_REPS_ACCOUNTS_RECEIVABLE}
            buttonProps={{ style: { marginLeft: '0.5rem' } }}
            buttonTextWhenVisible="Hide A/R Contact Info"
            buttonTextWhenHidden="Show A/R Contact Info"
          />
        </Message>
      }
        <Form
        className="form-top-spacing"
        // If we show the confirmation screen, we don't want to set an onSubmit
        // prop for the form, because the SubmitButtonWithConfirmFormInput will
        // manually call the submit function.
        onSubmit={null}
        >
          <Grid columns={2}> {/* CODE_COMMENTS_22, CODE_COMMENTS_97 */}
            <Grid.Row>
              <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                <Form.Field required>
                  <label htmlFor={ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE}>
                    <Trans ns='pagelabels' i18nKey={`orderKegs.${ORDER_TYPE}`}>{ORDER_KEGS_FORM_FIELD_LABEL_ORDER_TYPE}</Trans>
                  </label>
                </Form.Field>
              </Grid.Column>
              <Grid.Column style={{ flex: '1' }}>
                <div className="ui input"> {/* CODE_COMMENTS_32 */}
                  <Dropdown
                      options={orderTypes}
                      onChange={(e, { value }) => {
                        onResetForm()
                        dispatchFormValues(createAction(CHANGE_FORM_VALUE, [ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE, value]))
                        if (pickupLocations?.length && value === ORDER_KEGS_ORDER_TYPE_PICKUP) {
                          dispatchFormValues(createAction(CHANGE_FORM_VALUE, [ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS, pickupLocations?.[0]?.id]))
                        }
                        setSelectedOrderType(value)
                      }}
                      value={formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE]}
                      fluid
                      selection
                      name={ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE}
                      style={{ width: '13rem' }}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            {selectedOrderType === ORDER_KEGS_ORDER_TYPE_PICKUP && pickupLocations?.length > 0 && <Grid.Row>
              <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                <Form.Field required> {/* Used solely to style the label */}
                  <label htmlFor={ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS}>
                    <Trans ns='pagelabels' i18nKey={`orderKegs.${ORDER_KEGS_FORM_FIELD_LABEL_PICKUP_ADDRESS}`}>{ORDER_KEGS_FORM_FIELD_LABEL_PICKUP_ADDRESS}</Trans>
                  </label>
                </Form.Field>
              </Grid.Column>
              <Grid.Column style={{ flex: '1' }}>
                <SemanticUiCardChoices
                  choices={semanticUiCardPickUpAddressChoices}
                  value={formValues[ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS]}
                  onChange={(e, { value }) => {
                    dispatchFormValues(createAction(
                      CHANGE_FORM_VALUE,
                      [ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS, value],
                    ))
                  }}
                />
              </Grid.Column>
            </Grid.Row>}
            {quantityFields}
            {palletTypes.length > 1 && <Grid.Row>
              <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                <Form.Field>
                  <Trans ns='pagelabels' i18nKey={`orderKegs.${PALLET_TYPE}`}>
                    <label htmlFor={ORDER_KEGS_FORM_FIELD_NAME_PALLET_TYPE}>Pallet Type:</label>
                  </Trans>
                </Form.Field>
              </Grid.Column>
              <Grid.Column style={{ flex: '1' }}>
                <div className="ui input"> {/* CODE_COMMENTS_32 */}
                  <Dropdown
                    options={palletTypes}
                    value={selectedPalletType}
                    onChange={(e, { value }) => dropDownChangeEffect(value)}
                    fluid
                    selection
                    name={ORDER_KEGS_FORM_FIELD_NAME_PALLET_TYPE}
                    style={{ width: '30rem' }} // Matches the DeskPhone width
                    disabled={isNonLocalQuantitiesGreaterThanZero}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          }
            {selectedOrderType !== ORDER_KEGS_ORDER_TYPE_BLANKET && (
              <Grid.Row>
                <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                  <Form.Field required>
                    <label htmlFor={ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED}>
                      <Trans ns='pagelabels' i18nKey={`orderKegs.${DATE_NEEDED}`}>
                        Date Needed:
                      </Trans>
                      <InformationalPopup
                          iconName="exclamation"
                          content={
                            <OrderKegsFormTentativeDeliveryDatesMessage
                              businessUnitIdOfCurrentUserRootCustomer={entireCustomersSlice[customerId].businessUnitId}
                            />
                          }
                      />
                    </label>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column style={{ flex: '1' }}>
                  <div className="ui input"> {/* CODE_COMMENTS_32 */}
                    <ReactDatePicker
                      {...getDateFieldFencingProps({
                        entireCustomersSlice,
                        customerId,
                        customerContracts,
                        formValues,
                        kegOrderDays,
                      })}
                      // openToDate={moment().add(1, 'month')}
                      value={formValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]} // CODE_COMMENTS_245
                      onChange={value => {
                        dispatchFormValues(createAction(CHANGE_FORM_VALUE, [ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED, value]))
                      }}
                    />
                    <ContentToTheRightOfAFormField>
                      <XDaysFromNow
                        minDays={formValues?.[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE] !== ORDER_KEGS_ORDER_TYPE_LOCAL ? customerContracts?.minOrderLeadDays : KEG_ORDERS_MIN_DAYS?.[entireCustomersSlice?.[customerId]?.businessUnitId]}
                        dateNeededInputValue={formValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]}
                        orderType={formValues?.[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE]}
                        minOrderLeadDays={customerContracts?.minOrderLeadDays}
                        minOrderExpediteDays={customerContracts?.minOrderExpediteDays}
                        maxOrderExpediteDays={customerContracts?.maxOrderExpediteDays}
                        orderTimeOfDayCutOff={customerContracts?.orderTimeOfDayCutOff}
                        entireCustomersSlice={entireCustomersSlice}
                        customerId={customerId}
                        kegOrderDays={kegOrderDays}
                      />
                    </ContentToTheRightOfAFormField>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}


            <Grid.Row>
              <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                <Form.Field required> {/* Used solely to style the label */}
                  <label htmlFor={ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS}>
                    <Trans ns='pagelabels' i18nKey={`orderKegs.${SHIP_TO}`}>Ship To:</Trans>
                  </label>
                </Form.Field>
              </Grid.Column>
              <Grid.Column style={{ flex: '1' }}>
                <SemanticUiCardChoices
                  choices={semanticUiCardAddressChoices}
                  value={formValues[ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS]}
                  onChange={(e, { value }) => {
                    dispatchFormValues(createAction(
                      CHANGE_FORM_VALUE,
                      [ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS, value],
                    ))
                  }}
                />
              </Grid.Column>
            </Grid.Row>


            <Grid.Row>
              <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                <Form.Field>
                  <label htmlFor={ORDER_KEGS_FORM_FIELD_NAME_CUSTOMER_PO_NUM}>
                    <Trans ns='pagelabels' i18nKey={`orderKegs.${INTERNAL_NAME_PO}`}>Internal Name / PO #:</Trans>
                    <InformationalPopup content={t(`orderKegs.${FORM_FIELD_PO_NUMBER_EXPLANATION}`)} />
                  </label>
                </Form.Field>
              </Grid.Column>
              <Grid.Column style={{ flex: '1' }}>
                <InputNoAutoComplete
                  maxLength={25}
                  value={formValues[ORDER_KEGS_FORM_FIELD_NAME_CUSTOMER_PO_NUM] || ''} // CODE_COMMENTS_245
                  onChange={(e, { value }) => {
                    dispatchFormValues(createAction(
                      CHANGE_FORM_VALUE,
                      [ORDER_KEGS_FORM_FIELD_NAME_CUSTOMER_PO_NUM, value],
                    ))
                  }}
                />
              </Grid.Column>
            </Grid.Row>


            <Grid.Row>
              <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                <Form.Field >
                  <Trans ns='pagelabels' i18nKey={`orderKegs.${ORDER_COMMENTS}`}>
                    <label htmlFor={ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS}>Order Comments:</label>
                  </Trans>
                </Form.Field>
              </Grid.Column>
              <Grid.Column style={{ flex: '1' }}>
                <TextArea
                  value={formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS] || ''} // CODE_COMMENTS_245
                  onChange={(e, { value }) => {
                    dispatchFormValues(createAction(
                      CHANGE_FORM_VALUE,
                      [ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS, value],
                    ))
                  }}
                  className="ui input" // textArea won't work as inline component w/o this
                  style={{ width: '300px' }}
                />
              </Grid.Column>
            </Grid.Row>

          </Grid>

          <div>
            {selectedOrderType === ORDER_KEGS_ORDER_TYPE_BLANKET && <Button
              type="button" // CODE_COMMENTS_260
              onClick={() => handleSubmit()}
              id="submit-button"
              color={isFormSubmittable ? 'green' : 'grey'}
              disabled={!isFormSubmittable || isSubmitting || didSubmitSucceed || didSubmitFail || didFetchFail}
            >
              <Trans ns='pagelabels' i18nKey={`orderKegs.${SUBMIT}`}>Submit</Trans>
            </Button>}
            {selectedOrderType !== ORDER_KEGS_ORDER_TYPE_BLANKET && <SubmitButtonWithConfirmFormInput
              dataToConfirm={createConfirmationData({
                itemSkuIds,
                hasLocalDistributors,
                entireCustomersSlice,
                entireItemSkusSlice,
                customerId,
                formValues,
                isFormSubmittable,
                sortedShippingAddresses,
                selectedPalletType,
                selectedOrderType,
                localOrNonLocal,
                pickupLocations,
                translate: t,
              })}
              handleSubmit={handleSubmit}
              color={isFormSubmittable ? 'green' : 'grey'}
              // CODE_COMMENTS_38
              disabled={!isFormSubmittable || isSubmitting || didSubmitSucceed || didSubmitFail || didFetchFail}
            />}
            {
            shouldMimimumNonlocalKegsWarningBeShown
              ? <MimimumNonlocalKegsWarning hasLocalDistributors={hasLocalDistributors} />
              : null
            }
          </div>
        </Form>
      </FormSubmissionDimmerOverlay>
    </React.Fragment>
  )
}


/*
 * *****************************************************************************
 * Other components
 * *****************************************************************************
*/

export const KegQtyField = ({
  itemSkuId,
  localOrNonlocal,
  setHasAtLeastOneNonLocalKegQtyFieldBeenBlurred,
  // shouldMimimumNonlocalKegsWarningBeShown,
  formValues,
  dispatchFormValues,
  entireItemSkusSlice,
}) => {
  const fieldName = createOrderKegsFormFieldNameOfQuantity(itemSkuId, localOrNonlocal)
  const { maxOrderQuantity, skuType, itemsPerPallet } = entireItemSkusSlice?.[itemSkuId] || {}
  const [errorMsg, setErrorMsg] = useState('')
  const checkOrderQty = e => {
    const isValid = isValidOrderQty({
      orderQty: Number(e.target.value || 0),
      maxOrderQuantity,
    })
    setErrorMsg((!isValid && `Quantity should not be greater than ${maxOrderQuantity}`) || '')
  }
  return (
    <React.Fragment>
      <InputNoAutoComplete
        value={formValues[fieldName] || ''} // CODE_COMMENTS_245
        onChange={(e, { value }) => {
          dispatchFormValues(createAction(CHANGE_FORM_VALUE, [fieldName, value]))
          checkOrderQty(e)
        }}
        onBlur={() => {
          if (localOrNonlocal === ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER) {
            setHasAtLeastOneNonLocalKegQtyFieldBeenBlurred(true)
          }
        }}
        placeholder="Quantity"
        className={
          // shouldMimimumNonlocalKegsWarningBeShown &&
          // localOrNonlocal === ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER
          errorMsg ? 'field error' : ''
        }
        error={Boolean(errorMsg)}
      />
      {skuType === ITEM_SKUS_SKU_TYPE_COMPOSITE && itemsPerPallet && Number(formValues[fieldName]) > 0 && (
        <span className="field">
          {` ${Number(formValues[fieldName]) * itemsPerPallet} kegs`}
        </span>
      )}
      {errorMsg && (
        <span className="field">
          <span className="ui form left pointing prompt label" role="alert">{errorMsg}</span>
        </span>
      )}
    </React.Fragment>
  )
}


const MimimumNonlocalKegsWarning = ({
  hasLocalDistributors,
}) => {
  const { t } = useTranslation('pagelabels')
  const warningTextIfCustomerHasNoLocalDistributors = `You must order a minimum of ${MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER} kegs`
  const warningTextIfCustomerHasLocalDistributors = `You must order a minimum of ${MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER} non-local kegs (or 0 if you're only ordering local kegs)`
  const textToDisplay = hasLocalDistributors
    ? warningTextIfCustomerHasLocalDistributors
    : warningTextIfCustomerHasNoLocalDistributors
  return (
    <span className="semantic-ui-error-text-color">{t(`orderKegs.${textToDisplay}`)}</span>
  )
}


const OrderKegsFormTentativeDeliveryDatesMessage = ({
  businessUnitIdOfCurrentUserRootCustomer,
}) => {
  const { t } = useTranslation('pagelabels')
  const businessName = getBusinessUnitNameFromBusinessUnitId(businessUnitIdOfCurrentUserRootCustomer)
  return `${businessName} ${t(`orderKegs.${ORDER_KEGS_FORM_TENTATIVE_MSG}`)} ${businessName}.`
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

function getItemSkuIdsForOrderKegsFormMinusPallets({
  entireItemSkusSlice,
  itemSkuIds,
}) {
  return flow_(
    filterFp_(itemSkuId => entireItemSkusSlice?.[itemSkuId]?.displayOnOrders),
    // Pallets are displayed on the Order Kegs form but are handled differently
    filterFp_(itemSkuId => entireItemSkusSlice?.[itemSkuId]?.skuType !== ITEM_SKUS_SKU_TYPE_PALLET),
    arr => sortArrayByTemplateArray(
      arr,
      getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
    ),
  )(itemSkuIds)
}

function buildDeliveryScheduleFormValue({
  itemSkuIds,
}) {
  let initialObj = {
    [ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]: '',
    [FIELD_NAME_TOTAL_NUMBER_KEGS]: '',
    [FIELD_NAME_TOTAL_NUMBER_PALLETS]: '',
  }
  itemSkuIds.forEach(itemSku => {
    initialObj = { ...initialObj, [itemSku]: '' }
  })
  return new Array(1).fill(initialObj)
}

function getInitialValues({
  sortedShippingAddresses,
  orderTypes,
  orderDetails={},
  entireItemSkusSlice,
  itemSkuIds,
}) {
  if (Object.keys(orderDetails).length > 0) {
    return {
      ...buildFormValues({ orderDetails, entireItemSkusSlice, isEditMode: false }),
    }
  }
  return {
    [ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS]: sortedShippingAddresses[0].id,
    [ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE]: orderTypes?.[0]?.value,
    // [DELIVERY_SCHEDULE_FORM_VALUE]: [],
    [DELIVERY_SCHEDULE_FORM_VALUE]: buildDeliveryScheduleFormValue({ itemSkuIds }),
    // [DELIVERY_SCHEDULE_FORM_VALUE]: buildDeliveryScheduleFormValue({ itemSkuIds }),
    // we specifically don't want to put any other fields in here, for these
    // reasons: 1) they're unnecessary initially (they'll be added as the user
    // fills in the form); 2) The getShouldMimimumNonlocalKegsWarningBeShown
    // function will stop working properly if keg qty fields are included as
    // keys here.
  }
}


export function getHasLocalDistributors({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
}) {
  const isRelatedToAnyCurrentlyActiveLocalDistributors = getIsBrwOrConBrwRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  })
  // In August, 2020, we removed the ability to edit keg orders (because keg
  // orders automatically get set to Accepted status, so the backend ability for
  // web app users to delete them is gone anyway [they must be in Pending status
  // for a web app user to edit them]). If the dev team ever decides to add back
  // the ability to edit keg orders back in to the web app, this
  // getHasLocalDistributors algorithm needs to change for edited orders; see
  // CODE_COMMENTS_63 for guidance.
  return isRelatedToAnyCurrentlyActiveLocalDistributors
}


// function createOrderKegsFormFieldsQuantitiesLabelToIdentifierMap({
//   entireCustomersSlice,
//   customerId,
// }) {
//   const { localQuantitiyLabel, nonLocalQuantitiyLabel } = getLabelsForLocalQuantityAndNonLocalQuantityFormFields({
//     entireCustomersSlice,
//     customerId,
//   })
//   // Order is extremely important when constructing this part of the form
//   // dynamically. Specifically, the "quantities" field names need to be in the
//   // exact same order as the field labels, otherwise form labels will refer to the
//   // wrong column. To ensure correct correct order, we link the variables using
//   // this object.
//   return {
//     [ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER]: localQuantitiyLabel,
//     [ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER]: nonLocalQuantitiyLabel,
//   }
// }


function getLabelsForLocalQuantityAndNonLocalQuantityFormFields({
  entireCustomersSlice,
  customerId,
}) {
  const businessName = getBusinessUnitNameFromBusinessUnitId(
    entireCustomersSlice[customerId].businessUnitId,
  )

  return {
    localQuantitiyLabel: 'Filled from Local Distribution',
    nonLocalQuantitiyLabel: `${businessName}-delivered`,
  }
}


function getShouldMimimumNonlocalKegsWarningBeShown({
  formValues,
  itemSkuIds,
  hasLocalDistributors,
  hasAtLeastOneNonLocalKegQtyFieldBeenBlurred,
  entireItemSkusSlice,
}) {
  if (!formValues) { return false } // form hasn't yet been rendered
  if (!hasAtLeastOneNonLocalKegQtyFieldBeenBlurred) { return false }

  // only show the error if one nonlocal quantity field has been changed
  const nonLocalQuantitiesFieldNames = getNonlocalQuantitiesFormFieldsNames(itemSkuIds)
  const nonLocalQuantitiesFieldNamesThatHaveBeenTouched = intersection_(
    nonLocalQuantitiesFieldNames,
    Object.keys(formValues),
  )
  if (!isTruthyAndNonEmpty(nonLocalQuantitiesFieldNamesThatHaveBeenTouched)) {
    return false
  }

  // as long as at least one nonlocal qty field has been filled out, always show
  // the warning unless they have the correct number of nonlocal quantities.
  return !getAreNonLocalKegQtyInputsSubmittable({
    formValues,
    itemSkuIds,
    hasLocalDistributors,
    entireItemSkusSlice,
  })
}

function isValidOrderQty({
  orderQty,
  maxOrderQuantity,
}) {
  return Number(maxOrderQuantity) > 0 ? orderQty <= maxOrderQuantity : true
}

function getAreNonLocalKegQtyInputsSubmittable({
  formValues,
  itemSkuIds,
  hasLocalDistributors,
}) {
  if (!formValues) { return false } // form hasn't yet been rendered

  const nonLocalQuantitiesFieldNames = getNonlocalQuantitiesFormFieldsNames(itemSkuIds)
  const localQuantitiesFieldNames = getLocalQuantitiesFormFieldsNames(itemSkuIds)
  const sumOfNonLocalKegs = nonLocalQuantitiesFieldNames.reduce(
    (acc, fieldName) => {
      const qty = Number(formValues[fieldName]) || 0
      return acc+qty
    },
    0,
  )
  const sumOfLocalKegs = hasLocalDistributors
    ? localQuantitiesFieldNames.reduce(
      (acc, fieldName) => {
        const qty = Number(formValues[fieldName]) || 0
        return acc+qty
      },
      0,
    )
    : null

  if (hasLocalDistributors) {
    if (sumOfLocalKegs > 0) {
      return (
        sumOfNonLocalKegs === 0 ||
        sumOfNonLocalKegs >= MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER
      )
    }
    return sumOfNonLocalKegs >= MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER
  }
  return sumOfNonLocalKegs >= MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER
}
// 100 non-local kegs minimum, or 0 non-local kegs and 1 or more local kegs (in
// other words, 0<x<100 non-local kegs are not allowed under any circumstances,
// even if the user has local kegs).
function getAreKegQtyInputsSubmittable({
  formValues,
  itemSkuIds,
  hasLocalDistributors,
  entireItemSkusSlice,
}) {
  if (!formValues) { return false } // form hasn't yet been rendered

  const nonLocalQuantitiesFieldNames = getNonlocalQuantitiesFormFieldsNames(itemSkuIds)
  const localQuantitiesFieldNames = getLocalQuantitiesFormFieldsNames(itemSkuIds)

  const nonLocalQtyGreaterThanMaxQty = itemSkuIds.filter(itemSkuId => {
    const { maxOrderQuantity } = entireItemSkusSlice[itemSkuId] || {}
    const fieldName = createOrderKegsFormFieldNameOfQuantity(
      itemSkuId,
      ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER,
    )
    return !isValidOrderQty({
      orderQty: Number(formValues[fieldName] || 0),
      maxOrderQuantity,
    })
  })

  const LocalQtyGreaterThanMaxQty = itemSkuIds.filter(itemSkuId => {
    const { maxOrderQuantity } = entireItemSkusSlice[itemSkuId] || {}
    const fieldName = createOrderKegsFormFieldNameOfQuantity(
      itemSkuId,
      ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER,
    )
    return !isValidOrderQty({
      orderQty: Number(formValues[fieldName] || 0),
      maxOrderQuantity,
    })
  })

  if (nonLocalQtyGreaterThanMaxQty.length > 0 || LocalQtyGreaterThanMaxQty.length > 0) { return false }

  const sumOfNonLocalKegs = nonLocalQuantitiesFieldNames.reduce(
    (acc, fieldName) => {
      const qty = Number(formValues[fieldName]) || 0
      return acc+qty
    },
    0,
  )
  const sumOfLocalKegs = hasLocalDistributors
    ? localQuantitiesFieldNames.reduce(
      (acc, fieldName) => {
        const qty = Number(formValues[fieldName]) || 0
        return acc+qty
      },
      0,
    )
    : null

  // if (hasLocalDistributors) {
  //   if (sumOfLocalKegs > 0) {
  //     return (
  //       sumOfNonLocalKegs === 0 ||
  //       sumOfNonLocalKegs >= MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER
  //     )
  //   }
  //   return sumOfNonLocalKegs >= MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER
  // }
  // return sumOfNonLocalKegs >= MINIMUM_NUMBER_OF_NONLOCAL_KEGS_USER_MUST_ORDER

  return (sumOfNonLocalKegs > 0) || (sumOfLocalKegs > 0)
}


/**
 * We want to create special quantity labels for the "Confirm Data" screen.
 * These will be in the exact same order as the getQuantitiesFormFieldsNames()
 * array (as long as the order of the containerTypes arg is the same between
 * the two functions)
 */
function getQuantitiesFormFieldsDataConfirmationLabels({
  itemSkuIds,
  hasLocalDistributors,
  entireCustomersSlice,
  entireItemSkusSlice,
  customerId,
}) {
  const { localQuantitiyLabel, nonLocalQuantitiyLabel } = getLabelsForLocalQuantityAndNonLocalQuantityFormFields({
    entireCustomersSlice,
    customerId,
  })

  let toReturn = []
  if (hasLocalDistributors) {
    itemSkuIds.forEach(itemSkuId => {
      const humanReadableContainerType = getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice)
      toReturn.push(
        `${humanReadableContainerType} ${nonLocalQuantitiyLabel}`,
      )
      toReturn.push(
        `${humanReadableContainerType} ${localQuantitiyLabel}`,
      )
    })
  } else {
    toReturn = itemSkuIds.map(itemSkuId => getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice))
  }
  return toReturn
}


function normalizeInputValue({
  previousValue,
  value,
  fieldName,
}) {
  let normalizer
  if (
    fieldName.endsWith(ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER)
    || fieldName.endsWith(ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER)
  ) {
    normalizer = normalizerCombiner(nonZeroPaddedPositiveIntegerOrZero, maxLength(5))
  } else if ([
    ORDER_KEGS_FORM_FIELD_NAME_CUSTOMER_PO_NUM,
    ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS,
  ].includes(fieldName)) {
    normalizer = maxLength(255)
  } else {
    normalizer = val => val
  }
  return normalizer(
    value,
    previousValue,
  )
}


// Returns an object containing the date-fencing props that should be set on the
/* function getNextDeliverableDay({
  days,
}) {
  let noOfWorkingDays = Number(days)
  const currentDate = moment().clone()
  let minDays = 0 // reset
  while (noOfWorkingDays > 0) {
    minDays += 1
    currentDate.add(1, 'days')
    if (!isWeekend(currentDate)) { // 0 sunday, 6 saturday
      noOfWorkingDays -= 1
    }
  }
  return minDays
} */

// ReactDatepicker field.
export function getDateFieldFencingProps({
  // entireCustomersSlice,
  customerContracts,
  // customerId,
  formValues,
  kegOrderDays,
}) {
  // let minDays = 2
  const minDays = 1
  const maxDays = 90
  const defaultDate = Date.now()
  const defaultTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Chicago'
  const orderType = formValues?.[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE]
  // const { maxOrderLeadDays=90, minOrderExpediteDays=1, orderTimeOfDayCutOff, minOrderLeadDays } = (orderType !== ORDER_KEGS_ORDER_TYPE_LOCAL && Object.keys(customerContracts || {})?.length && customerContracts) || {}
  // const currentDay = moment()
  const minOrderDate = kegOrderDays?.filter(o => (o.canExpediteOrder || o.canNormalOrder) && moment(o.date).isSameOrAfter(Date.now(), 'day'))?.[0]
  const maxOrderDate = kegOrderDays?.[kegOrderDays.length -1]
  // if (orderType !== ORDER_KEGS_ORDER_TYPE_LOCAL) {
    // const hours = (new Date())?.getHours() || 0
    // if (orderTimeOfDayCutOff !== 24 && (orderTimeOfDayCutOff === 0 || (orderTimeOfDayCutOff > 0 && hours >= orderTimeOfDayCutOff))) {
    //   minDays = (minOrderExpediteDays || minOrderLeadDays) + 1
    // } else {
    //   minDays = (minOrderExpediteDays || minOrderLeadDays)
    // }
    // minDays = Math.abs(currentDay.diff(moment(minOrderDate?.date).tz(minOrderDate?.timeZone), 'days'))
    // maxDays = Math.abs(currentDay.diff(moment(maxOrderDate?.date).tz(maxOrderDate?.timeZone), 'days'))
  // }

  // minDays-=1

  const holidays = kegOrderDays?.filter(o => moment(o.date).isSameOrAfter(Date.now(), 'day'))?.filter(o => (!o.canExpediteOrder && !o.canNormalOrder))
  const excludeDates = getExcludedDates({
    // minDays,
    customerContracts,
    orderType,
    holidays,
  })

  if (orderType !== ORDER_KEGS_ORDER_TYPE_LOCAL) {
    return ({
      minDate: moment.utc(minOrderDate?.date || defaultDate),
      maxDate: moment.utc(maxOrderDate?.date || addDays(defaultDate, maxDays)).tz(maxOrderDate?.timeZone || defaultTimeZone),
      excludeDates,
    })
  }
  return ({
    minDate: moment().add(minDays, 'days'),
    // maxDate: moment().add(maxOrderLeadDays, 'days'),
    maxDate: moment().add(maxDays, 'days'),
    excludeDates,
  })
}


function validateBlanketOrderForm({
  formValues,
  // itemSkuIds,
  // entireItemSkusSlice,
  entireCustomersSlice,
  customerId,
  customerContracts,
  kegOrderDays,
}) {
  if (formValues[DELIVERY_SCHEDULE_FORM_VALUE].length === 0) { return false }
  // order qty > 0
  if (Number(formValues?.[DELIVERY_SCHEDULE_ORDER_TOTAL] || 0) <= 0) { return false }
  // date validation & row qty
  const isThereAnyInvalidData = Object.values(formValues?.[DELIVERY_SCHEDULE_FORM_VALUE])?.filter(obj => {
    const isValidDate = validateDate({
      value: obj[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED],
      ...getDateFieldFencingProps({
        entireCustomersSlice,
        customerId,
        customerContracts,
        formValues,
        kegOrderDays,
      }),
    })
    if (!isValidDate?.dateMoment) { return true }
    const isValidDeliveryDate = validateDeliveryDate({
      entireCustomersSlice,
      customerId,
      date: isValidDate?.dateMoment,
    })
    if (!isValidDeliveryDate) { return true }
    if (!Number(obj[FIELD_NAME_TOTAL_NUMBER_PALLETS])) { return true }
    return Number(obj[FIELD_NAME_TOTAL_NUMBER_KEGS]) === 0
  })
  if (isThereAnyInvalidData.length > 0) { return false }
  return true
}

function isWeekend(date) {
  const isValid = date?.isValid()
  if (!isValid) { return false }
  return [0, 6].indexOf(date.day()) > -1
}

function validateDeliveryDate({
  entireCustomersSlice,
  customerId,
  date,
}) {
  const { weekendDelivery=true } = subsidiaries?.[entireCustomersSlice?.[customerId]?.subsidiaryId] || {}
  if (!weekendDelivery && isWeekend(date)) { return false }
  return true
}

export function getIsFormSubmittable({
  formValues,
  itemSkuIds,
  hasLocalDistributors,
  entireItemSkusSlice,
  entireCustomersSlice,
  customerId,
  customerContracts,
  kegOrderDays,
}) {
  if (!formValues) { return false } // form hasn't yet been rendered

  if (formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE] === ORDER_KEGS_ORDER_TYPE_BLANKET) {
    const isValid = validateBlanketOrderForm({
      formValues,
      itemSkuIds,
      entireItemSkusSlice,
      entireCustomersSlice,
      customerId,
      customerContracts,
      kegOrderDays,
    })
    if (!isValid) { return false }
  } else {
    const areKegQtyInputsSubmittable = getAreKegQtyInputsSubmittable({
      formValues,
      itemSkuIds,
      hasLocalDistributors,
      entireItemSkusSlice,
    })
    if (!areKegQtyInputsSubmittable) { return false }

    const isValidDate = validateDate({
      value: formValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED],
      ...getDateFieldFencingProps({
        entireCustomersSlice,
        customerId,
        customerContracts,
        formValues,
        kegOrderDays,
      }),
    })
    if (!isValidDate?.dateMoment) { return false }
    const isValidDeliveryDate = validateDeliveryDate({
      entireCustomersSlice,
      customerId,
      date: isValidDate?.dateMoment,
    })
    if (!isValidDeliveryDate) { return false }
  }

  // Ensure a delivery address has been chosen (the form is currently crafted
  // so that it's impossible for an address not to be selected, but that could
  // change if, for instance, we decided in the future not to set a default
  // value for the chosen address. When we unset that default value, we don't
  // want to have to remember to include this check.)
  if (!formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE]) { return false }
  if (!formValues[ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS]) { return false }
  if (formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE] === ORDER_KEGS_ORDER_TYPE_PICKUP && !formValues[ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS]) { return false }

  return true
}

const getQuantitiesFormFieldsNames = (itemSkuIds, localOrNonLocal) => {
  if (localOrNonLocal === ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER) {
    return getLocalQuantitiesFormFieldsNames(itemSkuIds)
  }
  return getNonlocalQuantitiesFormFieldsNames(itemSkuIds)
}


function createConfirmationData({
  itemSkuIds,
  // hasLocalDistributors,
  entireCustomersSlice,
  entireItemSkusSlice,
  customerId,
  formValues,
  isFormSubmittable,
  sortedShippingAddresses,
  selectedPalletType,
  selectedOrderType,
  localOrNonLocal,
  pickupLocations,
  translate,
}) {
  if (!isFormSubmittable) { return [] }
  const qtysFormFieldNames = getQuantitiesFormFieldsNames(itemSkuIds, localOrNonLocal)
  const qtysLabels = getQuantitiesFormFieldsDataConfirmationLabels({
    itemSkuIds,
    hasLocalDistributors: false, // not needed now
    entireCustomersSlice,
    entireItemSkusSlice,
    customerId,
  })
  const quantitiesFields = qtysFormFieldNames.map((fieldName, index) => ({
    label: qtysLabels[index],
    value: formValues[fieldName] || 0,
  }))
  const firstSection = [{
    label: translate(`orderKegs.${ORDER_KEGS_FORM_FIELD_LABEL_ORDER_TYPE}`),
    value: ORDER_TYPES_LABEL[selectedOrderType],
  },
  ...(selectedOrderType === ORDER_KEGS_ORDER_TYPE_PICKUP ? [{
    label: translate(`orderKegs.${ORDER_KEGS_FORM_FIELD_LABEL_PICKUP_ADDRESS}`),
    value: (
      <DisplayedAddress address={pickupLocations.find(address => (
        address.id === formValues[ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS]
      ))}
      />
    ),
  }] : []),
  ]
  const staticFields = [
    ...(selectedPalletType && [{
      label: translate(`orderKegs.${ORDER_KEGS_FORM_FIELD_LABEL_PALLET_TYPE}`),
      value: entireItemSkusSlice?.[selectedPalletType]?.defaultCustomerText,
    }]),
    {
      label: translate(`orderKegs.${ORDER_KEGS_FORM_FIELD_LABEL_DATE_NEEDED}`),
      value: formValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED],
    },
    {
      label: translate(`orderKegs.${ORDER_KEGS_FORM_FIELD_LABEL_SHIPPING_ADDRESS}`),
      value: (
        <DisplayedAddress address={sortedShippingAddresses.find(address => (
          address.id === formValues[ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS]
        ))}
        />
      ),
    },
    {
      label: translate(`orderKegs.${ORDER_KEGS_FORM_FIELD_LABEL_CUSTOMER_PO_NUM}`),
      value: formValues[ORDER_KEGS_FORM_FIELD_NAME_CUSTOMER_PO_NUM],
    },
    {
      label: translate(`orderKegs.${ORDER_KEGS_FORM_FIELD_LABEL_ORDER_COMMENTS}`),
      value: formValues[ORDER_KEGS_FORM_FIELD_NAME_ORDER_COMMENTS],
    },
  ]
  return [...firstSection, ...quantitiesFields, ...staticFields]
}
