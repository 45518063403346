/**
 * CODE_COMMENTS_12
 */
import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_USERS as defaultSlice,
} from '../../../constants'

import {
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/

export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)


/*
 * *****************************************************************************
 * More specific
 * *****************************************************************************
*/

// returns array of userObjects
export const getAllUsersOfRootCustomerId = (entireUsersSlice, rootCustomerId) => (
  Object.values(entireUsersSlice).filter(o => o.rootCustomerId === rootCustomerId)
)
