import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

import RouteNotFound from '../../../../RouteNotFound'
import PrivateRoute from '../../../../../common-components/routes/PrivateRoute'

import PrelimInfoVerification from '../../../../PrelimInfoVerification'

import Dashboard from './RentalCustomerDashboard'
import OrderKegCollars from '../../../../OrderKegCollars/OrderKegCollars'
import PayBalance from '../../../../PayBalance'
import ManageUsers from '../../../../ManageUsers'
import ManageContactsAndSubscriptions from '../../../../ManageContactsAndSubscriptions'
import ManageAddresses from '../../../../ManageAddresses'


import {
  URL_PATHS,
} from '../../../../../constants'

import { createRelativePath } from '../../../../../utils'


// CODE_COMMENTS_26
const getRoutesList = props => {
  const {
    match,
    customerId,

    shouldOrderKegCollarsFormBeRendered,
    hasPayBalancePermissions,
    hasManageUsersAndContactsPermissions,
    hasAddressesPermissions,
  } = props

  return (
    [
      <PrivateRoute
        exact
        key={URL_PATHS.root.path}
        path={createRelativePath(match, URL_PATHS.root.path)}
        render={
          routeProps => (
            <PrelimInfoVerification
              componentToRenderIfVerified={Dashboard}
              customerId={customerId}
              {...props}
              {...routeProps}
            />
          )
        }
      />,
      ...(
        shouldOrderKegCollarsFormBeRendered
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.orderKegCollars.path}
              path={createRelativePath(match, URL_PATHS.orderKegCollars.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={OrderKegCollars}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        hasPayBalancePermissions
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.payBalance.path}
              path={createRelativePath(match, URL_PATHS.payBalance.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={PayBalance}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        hasManageUsersAndContactsPermissions
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.users.path}
              path={createRelativePath(match, URL_PATHS.users.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ManageUsers}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
            <PrivateRoute
              exact
              key={URL_PATHS.contacts.path}
              path={createRelativePath(match, URL_PATHS.contacts.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ManageContactsAndSubscriptions}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
      ...(
        hasAddressesPermissions
          ? [
            <PrivateRoute
              exact
              key={URL_PATHS.addresses.path}
              path={createRelativePath(match, URL_PATHS.addresses.path)}
              render={
                routeProps => (
                  <PrelimInfoVerification
                    componentToRenderIfVerified={ManageAddresses}
                    customerId={customerId}
                    {...routeProps}
                  />
                )
              }
            />,
          ]
          : []
      ),
    ]
  )
}

export default withRouter(props => (
  <Switch>
    {getRoutesList(props)}
    <RouteNotFound />
  </Switch>
))
