import isString_ from 'lodash/isString'


import {
  getProp as getCurrentUserProp,
} from './currentUser'
import {
  getProp as getCurrentEmployeeUserProp,
} from './currentUserEmployee'

import {
  withPropSimple,
} from './higherOrderFunctions'

import {
  PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
  PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,

  MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP,
  FUNCTIONALITY_TO_PERMISSIONS_MAP,

  CONTRACTEE_BREWER_MANAGE_USERS_PERMISSIONS_CHOICES,

  DISPLAYED_PERMISSIONS_DEFS_KEY_ROOT_CUSTOMER_OF_USER,
  DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF,
} from '../../constants/permissions'

import {
  REDUCER_NAMES_CUSTOMER_USER_PERMISSIONS as defaultSlice,
} from '../../constants'

import {
  isTruthyAndNonEmpty,
  includesAll,
} from '../../utils'


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/

// usage: const perCustomerPermissions = getProp(state, userId, 'perCustomerPermissions')
const getProp = withPropSimple(defaultSlice, 'byUserId')


/*
 * *****************************************************************************
 * More specific Get Selectors
 * *****************************************************************************
*/

// The original `permissions` prop as it arrived straight from the
// backend (this does not include the `perCustomerPermissions` prop)
export function getPermissions({
  state,
  userIdIfNotCurrentUser,
}) {
  return userIdIfNotCurrentUser
    ? getProp(state, userIdIfNotCurrentUser, 'permissions')
    : getCurrentUserProp(state, 'permissions')
}


// The original `perCustomerPermissions` prop as it arrived straight from the
// backend (this does not include the `permissions` prop)
export function getPerCustomerPermissions({
  state,
  userIdIfNotCurrentUser,
}) {
  return userIdIfNotCurrentUser
    ? getProp(state, userIdIfNotCurrentUser, 'perCustomerPermissions')
    : getCurrentUserProp(state, 'perCustomerPermissions')
}


// as opposed to getEntirePerCustomerPermissionsMap()
export function getPermissionsMap({
  state,
  userIdIfNotCurrentUser,
  isThisAPermissionsCheckOfALoggedInEmployee,
}) {
  if (isThisAPermissionsCheckOfALoggedInEmployee) {
    return getCurrentEmployeeUserProp(state, PERMISSIONS_MAP_REDUX_STORE_PROP_NAME)
  }
  return userIdIfNotCurrentUser
    ? getProp(state, userIdIfNotCurrentUser, PERMISSIONS_MAP_REDUX_STORE_PROP_NAME)
    : getCurrentUserProp(state, PERMISSIONS_MAP_REDUX_STORE_PROP_NAME)
}


export function getEntirePerCustomerPermissionsMap({
  state,
  userIdIfNotCurrentUser,
}) {
  return userIdIfNotCurrentUser
    ? getProp(state, userIdIfNotCurrentUser, PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME)
    : getCurrentUserProp(state, PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME)
}


export function getPerCustomerPermissionsMapOfSingleCustomer({
  state,
  userIdIfNotCurrentUser,
  customerId,
}) {
  const entirePerCustomerPermissionsMap = getEntirePerCustomerPermissionsMap({
    state,
    userIdIfNotCurrentUser,
  })

  if (
    !isTruthyAndNonEmpty(entirePerCustomerPermissionsMap) ||
    !isTruthyAndNonEmpty(entirePerCustomerPermissionsMap[customerId])
  ) { return {} }

  return entirePerCustomerPermissionsMap[customerId]
}


// returns the result of both getPermissionsMap() and
// getEntirePerCustomerPermissionsMap()
export function getPermissionsMapAndEntirePerCustomerPermissionsMap({
  state,
  userIdIfNotCurrentUser,
}) {
  return {
    [DISPLAYED_PERMISSIONS_DEFS_KEY_ROOT_CUSTOMER_OF_USER]: getPermissionsMap({
      state,
      userIdIfNotCurrentUser,
    }),
    [DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF]: getEntirePerCustomerPermissionsMap({
      state,
      userIdIfNotCurrentUser,
    }),
  }
}

/*
 * *****************************************************************************
 * More specific query selectors
 * *****************************************************************************
*/

/**
 * The functionalityStringOrPermissionsMap arg can either be one of the keys in
 * the FUNCTIONALITY_TO_PERMISSIONS_MAP object (e.g.
 * 'FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS') or an object with the same
 * shape as a FUNCTIONALITY_TO_PERMISSIONS_MAP value:
 *
 * {
 *   ORDERS: ['READ'],
 *   SHIPMENTS: ['READ', 'CREATE', 'UPDATE'],
 * }
 *
 * Returns false if the user doesn't have ALL the needed permissions.
 */
export function getHasPermissionsToPerformFunctionality({
  state,
  userIdIfNotCurrentUser,
  customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore, // CODE_COMMENTS_166
  functionalityStringOrPermissionsMap,
  // Pass this in if you want this permissions check to be a
  // perCustomerPermissions check (i.e. what can a CB do operating on behalf of
  // a contractee?)
  customerIdIfThisIsAPerCustomerPermissionsCheck,
  isThisAPermissionsCheckOfALoggedInEmployee,
}) {
  const requestedPermissions = isString_(functionalityStringOrPermissionsMap)
    ? FUNCTIONALITY_TO_PERMISSIONS_MAP[functionalityStringOrPermissionsMap]
    : functionalityStringOrPermissionsMap

  let permissionsMapToCheck
  if (customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore) {
    permissionsMapToCheck = customerIdIfThisIsAPerCustomerPermissionsCheck
      ? customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore[
        PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME
      ][customerIdIfThisIsAPerCustomerPermissionsCheck]
      : customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore[
        PERMISSIONS_MAP_REDUX_STORE_PROP_NAME
      ]
  } else {
    permissionsMapToCheck = customerIdIfThisIsAPerCustomerPermissionsCheck
      ? getPerCustomerPermissionsMapOfSingleCustomer({
        state,
        userIdIfNotCurrentUser,
        customerId: customerIdIfThisIsAPerCustomerPermissionsCheck,
      })
      : getPermissionsMap({
        state,
        userIdIfNotCurrentUser,
        isThisAPermissionsCheckOfALoggedInEmployee,
      })
  }
  return getHasPermissionsInPermissionsMap(requestedPermissions, permissionsMapToCheck)
}


export function getHasPermissionsToPerformManageUsersPermissionsChoice({
  state,
  permissionsChoice,
  userIdIfNotCurrentUser,
  customerIdIfThisIsAPerCustomerPermissionsCheck,
  customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore, // CODE_COMMENTS_166
}) {
  return MANAGE_USERS_PERMISSIONS_CHOICES_TO_FUNCTIONALITIES_MAP[permissionsChoice].every(
    functionalityThatNeedsPermissions => (
      getHasPermissionsToPerformFunctionality({
        state,
        userIdIfNotCurrentUser,
        customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore,
        functionalityStringOrPermissionsMap: functionalityThatNeedsPermissions,
        customerIdIfThisIsAPerCustomerPermissionsCheck,
      })
    ),
  )
}


export function getDoesContractBrewerUserHavePermissionsToDoAtLeastOneThingOnBehalfOfContractee({
  state,
  userIdIfNotCurrentUser,
  contracteeCustomerId,
}) {
  return CONTRACTEE_BREWER_MANAGE_USERS_PERMISSIONS_CHOICES.some(
    permissionsChoice => (
      getHasPermissionsToPerformManageUsersPermissionsChoice({
        state,
        userIdIfNotCurrentUser,
        permissionsChoice,
        customerIdIfThisIsAPerCustomerPermissionsCheck: contracteeCustomerId,
      })
    ),
  )
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/


/**
 * requestedPermissions and permissionsMap should both be objects shaped like
 * this:
 *
 * {
 *   ORDERS: ['READ'],
 *   SHIPMENTS: ['READ', 'CREATE', 'UPDATE'],
 * }
 */
function getHasPermissionsInPermissionsMap(requestedPermissions, permissionsMap) {
  if (!permissionsMap) {
    return false
  }
  return Object.keys(requestedPermissions).every(permission => {
    const accessArrayForThisPermission = permissionsMap[permission]
    if (!accessArrayForThisPermission) { return false }
    return includesAll(accessArrayForThisPermission, requestedPermissions[permission])
  })
}
