/* eslint-disable max-len */
import React from 'react'


import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { Trans } from 'react-i18next'
import RequestNewDistributor from '../../../../../../RequestNewDistributor'
import CONBRWsResponsibleForReportingOutboundsMessage from '../../CONBRWsResponsibleForReportingOutboundsMessage'

import {
  createCustomersFieldsDropdownOptionsForShipmentFormsBrewer,
  createContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer,
} from './shared'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../../../../redux/selectors/rewrite/permissions'

import {
  REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../../../../../../constants/formAndApiUrlConfig/reportOutboundShipments'

import {
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
} from '../../../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REQUEST_NEW_DISTRIBUTOR,
} from '../../../../../../../constants/permissions'

import {
  CUSTOMER_TYPES_BREWER,
  URL_PATHS,
} from '../../../../../../../constants'

import {
  getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportOutboundShipmentsTo,
  getCustomerTypesABrewerTargetingAContractBrewingContractCanReportOutboundShipmentsTo,
} from '../../../../../util'
import { ENABLE_V2_REQUEST_NEW_DISTRIBUTOR } from '../../../../../../RequestNewDistributor/constants'
import { createRelativePath, goUpInUrlPath } from '../../../../../../../utils'


// CODE_COMMENTS_188 & CODE_COMMENTS_88: Returns an array of objects used to
// define each of the field arrays in a Report Outbound Shipments form. A Report
// outbound Shipments form will always only have one field array, therefore the
// array returned by this function should only have one item. However, the
// "Source" field can have multiple (See also CODE_COMMENTS_128)
export function createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditOutboundShipments(props) {
  const {
    operatingContractBrewerCustomerId,
  } = props
  if (operatingContractBrewerCustomerId) {
    return createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditOutboundShipmentsFormCbOperatingAsContracteeBrewer(props)
  }
  // regular old brewer.
  return createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditOutboundShipmentsFormBrewer(props)
}


/*
 * *****************************************************************************
 * Brewers who are not contractees
 * *****************************************************************************
*/

// Returns an array of objects used to define each of the field arrays in a
// non-contractee Brewer's Report Outbound Shipments form. See the docstring of
// the function that calls this one for details on the structure of the returned
// objects.
function createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditOutboundShipmentsFormBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  isEditForm,
  match,
}) {
  const allDropdownOptions = createCustomersFieldsDropdownOptionsForShipmentFormsBrewer({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsDefaultBrwPpf:
      getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportOutboundShipmentsTo(),
    customerTypesToIncludeInCustomerFieldThatMustBeChosenSecondWhenFirstCustomerIsConbrwPpf:
      getCustomerTypesABrewerTargetingAContractBrewingContractCanReportOutboundShipmentsTo(),
  })

  const {
    shouldRequestNewDistributorButtonBeRendered,
    requestNewDistributorComponent,
  } = createRequestNewDistributorComponentIfHasPermissions({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    isEditForm,
    match,
  })

  return [
    {
      fieldArrayTitle: null, // no title needed if only one field array in form
      fieldArrayName: REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
      whichCustomersFieldMustBeChosenFirstSourceOrDestination:
        WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
      customersFieldsDropdownOptions: allDropdownOptions,
      // CODE_COMMENTS_146
      arbitraryContentAfterAddRowButton: shouldRequestNewDistributorButtonBeRendered && requestNewDistributorComponent,
      // CODE_COMMENTS_231
      arbitraryContentBelowFieldArrayTitleAndAboveForm: CONBRWsResponsibleForReportingOutboundsMessage,
    },
  ]
}


/*
 * *****************************************************************************
 * CB Operating for Contractee Brewer
 * *****************************************************************************
*/

// CODE_COMMENTS_188: Returns an array of objects used to define each of the
// field arrays in a contractee Brewer's Report Outbound Shipments form. See the
// docstring of the function that calls this one for details on the structure of
// the returned objects.
function createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditOutboundShipmentsFormCbOperatingAsContracteeBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId,
  isEditForm,
  match,
}) {
  const dropdownOptionsForThisContractee =
    createContractBrewingPpfContractFieldArrayDropdownOptionsForShipmentFormsBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      conbrwCustomerId: operatingContractBrewerCustomerId,
      customerTypesToIncludeInCustomerFieldThatMustBeChosenSecond:
        getCustomerTypesABrewerTargetingAContractBrewingContractCanReportOutboundShipmentsTo(),
      setBrewerOrContractBrewerAsName: CUSTOMER_TYPES_BREWER,
    })

  const {
    shouldRequestNewDistributorButtonBeRendered,
    requestNewDistributorComponent,
  } = createRequestNewDistributorComponentIfHasPermissions({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    operatingContractBrewerCustomerId,
    isEditForm,
    match,
  })

  return [
    {
      fieldArrayTitle: null, // no title needed if only one field array in form
      fieldArrayName: REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
      whichCustomersFieldMustBeChosenFirstSourceOrDestination:
        WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
      customersFieldsDropdownOptions: dropdownOptionsForThisContractee,
      arbitraryContentAfterAddRowButton: shouldRequestNewDistributorButtonBeRendered && requestNewDistributorComponent,
    },
  ]
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

function requestNewDistributor({
  customerId,
  operatingContractBrewerCustomerId,
  match,
}) {
  if (ENABLE_V2_REQUEST_NEW_DISTRIBUTOR) {
    const path = goUpInUrlPath(match?.url || '', 1)
    const relativePath = { ...match, url: path }
    return (
      <Link to={createRelativePath(relativePath, URL_PATHS.requestNewDistributor.path)}>
        <Button
          type="button"
          id="request-new-distributor-button" // for Selenium tests
          size="mini"
          basic
          color="blue"
        >
          <Trans ns="pagelabels" i18nKey="reportShipments.Add New Location">
            Add New Location
          </Trans>
        </Button>
      </Link>
    )
  }
  return (
    <RequestNewDistributor
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
    />
  )
}

function createRequestNewDistributorComponentIfHasPermissions({
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
  operatingContractBrewerCustomerId,
  isEditForm,
  match,
}) {
  const hasPermissionsToRequestNewDistributor = getHasPermissionsToPerformFunctionality({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REQUEST_NEW_DISTRIBUTOR,
    customerIdIfThisIsAPerCustomerPermissionsCheck: operatingContractBrewerCustomerId && customerId,
  })

  const shouldRequestNewDistributorButtonBeRendered = (
    hasPermissionsToRequestNewDistributor &&
    !isEditForm
  )

  const requestNewDistributorComponent = shouldRequestNewDistributorButtonBeRendered
    ? requestNewDistributor({
      match,
      customerId,
      operatingContractBrewerCustomerId,
    })
    : null

  return {
    shouldRequestNewDistributorButtonBeRendered,
    requestNewDistributorComponent,
  }
}
