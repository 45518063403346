import { call, takeEvery } from 'redux-saga/effects'


import {
  FETCH_EDIT_OUTBOUND_SHIPMENT_REPORT,
  FETCH_STATUSES_FORMS_REQUEST,
  FETCH_STATUSES_FORMS_SUCCESS,
  FETCH_STATUSES_FORMS_FAILURE,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'
import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'

import { privateFetch } from '../../fetch'

import {
  extractFormValues,
  saveShipmentObjectToPotentialDuplicatesSliceIfMeetsCriteria,
} from './util'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm,
} from '../sharedUtil/ackInboundShipmentsAndReportOutboundShipments'

import {
  getEditIndividualUserReportedShipmentApiUrl,
} from '../../../../utils'

export function* fetchEditOutboundShipmentReport(action) {
  const {
    fieldArrayNameIfIsEditForm: fieldArrayName,
    fieldArraysAndCustomersFieldsDropdownOptions,
    customerId,
    operatingContractBrewerCustomerId,
    formValues,
    dispatchFetchStatuses,
    itemObj,
    itemSkuIds,
  } = action.payload
  dispatchFetchStatuses(createAction(FETCH_STATUSES_FORMS_REQUEST, { target: [fieldArrayName, 0] }))
  const rowValues = formValues[fieldArrayName][0]
  const fetchConfig = yield call(
    createFetchConfig,
    {
      itemObj,
      customerId,
      operatingContractBrewerCustomerId,
      fieldArrayName,
      fieldArraysAndCustomersFieldsDropdownOptions,
      rowValues,
      itemSkuIds,
    },
  )
  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    yield call(
      doFailure,
      customerId,
      fieldArrayName,
      dispatchFetchStatuses,
      error,
    )
    return
  }
  yield call(
    doSuccess,
    customerId,
    operatingContractBrewerCustomerId,
    fieldArrayName,
    dispatchFetchStatuses,
    response.data,
  )
}


function* doSuccess(
  customerId,
  operatingContractBrewerCustomerId,
  fieldArrayName,
  dispatchFetchStatuses,
  objToSave,
) {
  yield call(
    saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm,
    {
      customerId,
      operatingContractBrewerCustomerId,
      fieldArrayName,
      shipmentObj: objToSave,
    },
  )
  yield call(
    saveShipmentObjectToPotentialDuplicatesSliceIfMeetsCriteria,
    {
      customerId,
      operatingContractBrewerCustomerId,
      fieldArrayName,
      shipmentObj: objToSave,
    },
  )
  dispatchFetchStatuses(createAction(FETCH_STATUSES_FORMS_SUCCESS, { target: [fieldArrayName, 0] }))
}

function* doFailure(
  customerId,
  fieldArrayName,
  rowIndex,
  dispatchFetchStatuses,
  error,
) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_FORMS_FAILURE,
        target: [fieldArrayName, rowIndex],
      }),
      customDispatch: dispatchFetchStatuses,
    },
  )
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_EDIT_OUTBOUND_SHIPMENT_REPORT, fetchEditOutboundShipmentReport],
]


// helper functions


function* createFetchConfig({
  itemObj,
  customerId,
  operatingContractBrewerCustomerId,
  fieldArraysAndCustomersFieldsDropdownOptions,
  fieldArrayName,
  rowValues,
  itemSkuIds,
}) {
  const apiRequestBody = yield call(
    createApiRequestBody,
    {
      itemObj,
      customerId,
      fieldArraysAndCustomersFieldsDropdownOptions,
      fieldArrayName,
      rowValues,
      itemSkuIds,
    },
  )
  const fetchConfig = {
    path: getEditIndividualUserReportedShipmentApiUrl(itemObj.id),
    data: apiRequestBody,
    method: 'PUT',
  }

  // CODE_COMMENTS_92
  if (operatingContractBrewerCustomerId) {
    const headers = createHeadersForContracteeBrewerApiCall(customerId)
    fetchConfig.headers = headers
  }

  return fetchConfig
}


function* createApiRequestBody({
  itemObj,
  customerId,
  fieldArraysAndCustomersFieldsDropdownOptions,
  fieldArrayName,
  rowValues,
  itemSkuIds,
}) {
  const updatedValues = yield call(
    extractFormValues,
    {
      customerId,
      fieldArrayName,
      fieldArraysAndCustomersFieldsDropdownOptions,
      rowValues,
      itemSkuIds,
    },
  )

  return {
    // CODE_COMMENTS_66
    ...itemObj,
    ...updatedValues,
    // CODE_COMMENTS_133. Why is it necessary to null out all these props when a
    // shipment is edited? Because of the following situation: a Brewer reports
    // an outbound shipment to another Brewer she's related to but immediately
    // realizes she meant to report the shipment to a different Brewer she's
    // related to. She edits the form and now the destinationCustomerId is
    // different, which means the destinationContractId needs to be different.
    // The backend is responsible for setting all these props when it receives
    // the POST/PUT request: nulling them out signals to the backend that that
    // it needs to re-set these.
    originContractId: null,
    destinationContractId: null,
    originAddressId: null,
    destinationAddressId: null,
  }
}
