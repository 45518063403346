import {
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects'


import { privateFetch } from '../../../fetch'
import {
  FETCH_EDIT_USER_INFO_OF_ONE_USER,
  SAVE_USER,
  UPDATE_BASIC_INFO_OF_CURRENT_USER,
} from '../../../../actions/actionTypes'

import {
  getProp as getCurrentUserProp,
} from '../../../../selectors/currentUser'

import {
  generalDoFailure,
} from '../../../util/fetchFailure'
import createAction from '../../../../actions/createAction'

import {
  getCustomerUserApiUrl,
} from '../../../../../utils'


function* fetchEditInfoOfOneUser(action) {
  const {
    userObject,
    setisEditUserBasicInfoSubmitting,
    setdidEditUserBasicInfoSubmissionSucceed,
    setdidEditUserBasicInfoSubmissionFail,
  } = action.payload
  const apiRequestBody = yield call(
    createApiRequestBody,
    {
      ...action.payload,
    },
  )

  let response
  try {
    response = yield call(
      privateFetch,
      { path: getCustomerUserApiUrl(userObject.id), data: apiRequestBody, method: 'PUT' },
    )
  } catch (error) {
    yield call(
      doFailure,
      {
        error,
        setisEditUserBasicInfoSubmitting,
        setdidEditUserBasicInfoSubmissionSucceed,
        setdidEditUserBasicInfoSubmissionFail,
      },
    )
    return
  }
  yield call(
    doSuccess,
    {
      updatedUserObject: response.data,
      setisEditUserBasicInfoSubmitting,
      setdidEditUserBasicInfoSubmissionSucceed,
      setdidEditUserBasicInfoSubmissionFail,
    },
  )
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_EDIT_USER_INFO_OF_ONE_USER, fetchEditInfoOfOneUser],
]


function* doSuccess({
  updatedUserObject,
  setisEditUserBasicInfoSubmitting,
  setdidEditUserBasicInfoSubmissionSucceed,
  setdidEditUserBasicInfoSubmissionFail,
}) {
  yield put(createAction(
    SAVE_USER,
    { info: updatedUserObject },
  ))
  const currentUserId = yield select(getCurrentUserProp, 'userId')
  if (currentUserId === updatedUserObject.id) {
    yield put(createAction(
      UPDATE_BASIC_INFO_OF_CURRENT_USER,
      updatedUserObject,
    ))
  }
  setisEditUserBasicInfoSubmitting(false)
  setdidEditUserBasicInfoSubmissionSucceed(true)
  setdidEditUserBasicInfoSubmissionFail(false)
}


function* doFailure({
  error,
  setisEditUserBasicInfoSubmitting,
  setdidEditUserBasicInfoSubmissionSucceed,
  setdidEditUserBasicInfoSubmissionFail,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  setisEditUserBasicInfoSubmitting(false)
  setdidEditUserBasicInfoSubmissionSucceed(false)
  setdidEditUserBasicInfoSubmissionFail(true)
}


function createApiRequestBody({
  userObject,
  formValues,
}) {
  return {
    ...userObject,
    firstName: formValues.firstName || '',
    lastName: formValues.lastName || '',
    deskPhoneNumber: formValues.deskPhoneNumber || null,
    deskPhoneExt: formValues.deskPhoneExt || null,
    cellPhoneNumber: formValues.cellPhoneNumber || null,
    faxNumber: formValues.faxNumber || null,
  }
}
