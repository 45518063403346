/**
 * This component renders an array of span tags with breaks in between. This
 * seems to be the best way to display an address because certain Semantic UI
 * elements (such as a Card.Description) don't allow strings with newlines.
 */
import React from 'react'

import { useTranslation } from 'react-i18next'
import { processAddressObjectForDisplay } from '../../../utils'

export default ({
  addressObject,
  slightlyLargerText,
}) => {
  const { t: translate } = useTranslation('common')
  if (!addressObject) {
    // TODO: send rollbar error
    return (
      <span
        style={{
          ...(slightlyLargerText ? { fontSize: '1.1rem' } : {}),
        }}
      >
        {`[${translate('No address on file')}]`}
      </span>
    )
  }
  return processAddressObjectForDisplay(addressObject).map(line => (
    <span
      key={line}
      style={{
        ...(slightlyLargerText ? { fontSize: '1.1rem' } : {}),
      }}
    >
      {line}
      <br />
    </span>
  ))
}
