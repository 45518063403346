import {
  FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_REQUEST,
  FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_SUCCESS,
  FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_REQUEST,
  FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_SUCCESS,
  FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_FAILURE,
)
