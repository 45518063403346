import enterEmailSagas from './enterEmail'
import resendConfirmationCodeToNewUserSagas from './resendConfirmationCodeToNewUser'
import resetPassword from './resetPassword'

// CODE_COMMENTS_11
export default [
  ...enterEmailSagas,
  ...resendConfirmationCodeToNewUserSagas,
  ...resetPassword,
]
