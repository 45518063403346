import {
  SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE,
} from '../../actions/actionTypes'


const initialState = false

export default function areWeCurrentlyInMaintenanceMode(state=initialState, action) {
  switch (action.type) {
    case SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE: {
      return action.payload
    }

    default:
      return state
  }
}
