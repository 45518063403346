/**
 * CODE_COMMENTS_16
 *
 * Why is preliminary info fetch statuses its own reducer? Can't this state be
 * derived from the fetch status of all the calls that make up preliminaryInfo?
 * Technically yes, but it's difficult. Imagine creating a
 * "getIsPreliminaryInfoFetching" selector from derived state: You have to check
 * whether the "getChildren" fetch is completed or not. But a "getChildren"
 * fetch will never be attempted for customer types that don't have children. So
 * you have to make sure the customer is the right type before checking if its
 * "getChildren" fetch is finished. However, you can't know a customer's type
 * until the customer info is finished fetching (that customer info is what contains
 * the customerType info). So you have have to make sure the customer info has
 * been fetched before checking the customer's type. Instead of writing out this
 * logic in the selector (which would actually be a re-writing of the logic,
 * since this logic is already written out in the data-fetching portion of the
 * app), we can simply have our "fetch preliminary info" saga set a status flag
 * in the store (which is all this reducer is) indicating when it has finished.
 */

import {
  FETCH_PRELIM_CUSTOMER_INFO_REQUEST,
  FETCH_PRELIM_CUSTOMER_INFO_SUCCESS,
  FETCH_PRELIM_CUSTOMER_INFO_FAILURE,
  FETCH_PRELIM_CUSTOMER_INFO_CLEAR,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_PRELIM_CUSTOMER_INFO_REQUEST,
  FETCH_PRELIM_CUSTOMER_INFO_SUCCESS,
  FETCH_PRELIM_CUSTOMER_INFO_FAILURE,
  FETCH_PRELIM_CUSTOMER_INFO_CLEAR,
)
