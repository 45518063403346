import React from 'react'
import { Container, Message } from 'semantic-ui-react'

const Four04 = () => (
  <Container textAlign="center">
    <Message negative size="huge">
      <p>This page does not exist</p>
    </Message>
  </Container>
)

export default Four04
