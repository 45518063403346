import { connect } from 'react-redux'

import LoginSection from '../components/LoginSection'

import fetchStatusSelectors from '../../../redux/selectors/fetchStatuses/publicForms'

import createAction from '../../../redux/actions/createAction'

import {
  LOGIN,
} from '../../../redux/actions/actionTypes'

import {
  REDUCER_NAMES_PUBLIC_FORMS_LOGIN,
} from '../../../constants'


const {
  getFetchStatuses,
} = fetchStatusSelectors


const mapStateToProps = state => {
  const fetchStatuses = getFetchStatuses(state, REDUCER_NAMES_PUBLIC_FORMS_LOGIN)
  return {
    isAuthenticating: fetchStatuses.isFetching,
    hasSubmitFailed: fetchStatuses.didFetchFail,
  }
}

const mapDispatchToProps = dispatch => ({
  onLoginSubmit: (email, password) => dispatch(createAction(LOGIN, { email, password })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginSection)
