import { combineReducers } from 'redux'


import {
  REDUCER_NAMES_HISTORY_KEG_ORDERS,
  REDUCER_NAMES_HISTORY_COLLAR_ORDERS,
  REDUCER_NAMES_HISTORY_INVOICES,
  REDUCER_NAMES_HISTORY_INVENTORY,
  REDUCER_NAMES_HISTORY_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDERS,
  REDUCER_NAMES_HISTORY_INBOUND_EMPTY_KEG_SHIPMENTS,
  REDUCER_NAMES_HISTORY_OUTBOUND_FULL_KEG_SHIPMENTS,
  REDUCER_NAMES_HISTORY_INBOUND_FULL_KEG_SHIPMENTS,
  REDUCER_NAMES_HISTORY_OUTBOUND_EMPTY_KEG_SHIPMENTS,
  REDUCER_NAMES_HISTORY_KEG_FILLS_CONTRACTEE,

  REDUCER_NAMES_HISTORY_NO_MOVEMENTS,
} from '../../../constants'

import kegOrderHistory from './kegOrderHistory'
import inboundShipmentsOfSpecificKegOrders from './inboundShipmentsOfSpecificKegOrders'
import collarOrderHistory from './collarOrderHistory'
import invoiceHistory from './invoiceHistory'
import inventoryHistory from './inventoryHistory'
import inboundEmptyKegShipmentHistory from './inboundEmptyKegShipmentHistory'
import outboundFullKegShipmentHistory from './outboundFullKegShipmentHistory'
import inboundFullKegShipmentHistory from './inboundFullKegShipmentHistory'
import outboundEmptyKegShipmentHistory from './outboundEmptyKegShipmentHistory'
import kegFillsContracteeHistory from './kegFillsContracteeHistory'
import noMovementsHistory from './noMovements'


const historyReducers = {
  [REDUCER_NAMES_HISTORY_KEG_ORDERS]: kegOrderHistory,
  [REDUCER_NAMES_HISTORY_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDERS]: inboundShipmentsOfSpecificKegOrders,
  [REDUCER_NAMES_HISTORY_COLLAR_ORDERS]: collarOrderHistory,
  [REDUCER_NAMES_HISTORY_INVOICES]: invoiceHistory,
  [REDUCER_NAMES_HISTORY_INVENTORY]: inventoryHistory,
  [REDUCER_NAMES_HISTORY_INBOUND_EMPTY_KEG_SHIPMENTS]: inboundEmptyKegShipmentHistory,
  [REDUCER_NAMES_HISTORY_OUTBOUND_FULL_KEG_SHIPMENTS]: outboundFullKegShipmentHistory,
  // CODE_COMMENTS_205
  [REDUCER_NAMES_HISTORY_INBOUND_FULL_KEG_SHIPMENTS]: inboundFullKegShipmentHistory,
  [REDUCER_NAMES_HISTORY_OUTBOUND_EMPTY_KEG_SHIPMENTS]: outboundEmptyKegShipmentHistory,
  // CODE_COMMENTS_232
  [REDUCER_NAMES_HISTORY_KEG_FILLS_CONTRACTEE]: kegFillsContracteeHistory,

  [REDUCER_NAMES_HISTORY_NO_MOVEMENTS]: noMovementsHistory,
}

export default combineReducers(historyReducers)
