import {
  FETCH_CUSTOMER_RELATED_TO_INFO_REQUEST,
  FETCH_CUSTOMER_RELATED_TO_INFO_SUCCESS,
  FETCH_CUSTOMER_RELATED_TO_INFO_FAILURE,
  FETCH_CUSTOMER_RELATED_TO_INFO_CLEAR,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_CUSTOMER_RELATED_TO_INFO_REQUEST,
  FETCH_CUSTOMER_RELATED_TO_INFO_SUCCESS,
  FETCH_CUSTOMER_RELATED_TO_INFO_FAILURE,
  FETCH_CUSTOMER_RELATED_TO_INFO_CLEAR,
)
