/**
 * CODE_COMMENTS_62
 */
import React from 'react'

import uniq_ from 'lodash/uniq'
import memoize_ from 'lodash/memoize'
import isPlainObject_ from 'lodash/isPlainObject'
import isString_ from 'lodash/isString'

import flow_ from 'lodash/fp/flow'
import uniqFp_ from 'lodash/fp/uniq'
import filterFp_ from 'lodash/fp/filter'

import {
  createDisplayedInfoWithAlternateTextOption,
  getDisplayedHumanReadableStatusOfHistoryItem,
  getShouldHistoryItemObjBeDisplayedInTable,
  getShouldHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm,
  filterHistoryObjectsWithUnrecognizedStatus,
} from './util'
import {
  getPropOr,
  getPropOfAll as getSinglePropOfAllCollarPlateObjects,
  getMultipleCollarPlateObjectsById,
} from '../../../../redux/selectors/collarPlates'
import {
  getMultipleAddressObjectsByIdNoLinkInfoOnlyAddressInfo,
} from '../../../../redux/selectors/addresses'
import {
  getAddressIdsFromCollarOrderObjects,
  getCollarPlateIdsFromCollarOrderObjects,
} from '../../../../redux/selectors/histories/collarOrderHistory'

import {
  FIELD_LABEL_CUSTOMER_PO_NUM,
  createFedexGroundArrivalDateString,
  createFedexOvernightArrivalDateString,
} from '../../../../constants/formAndApiUrlConfig/orderKegCollars'

import {
  COLLAR_ORDER_FULL_DETAILS_LABEL_DELIVERY_DATE,
  COLLAR_ORDER_FULL_DETAILS_LABEL_COMMENTS,
  COLLAR_ORDER_FULL_DETAILS_DELIVERY_ADDRESS,
} from '../../../../constants/formAndApiUrlConfig/histories/collarOrderHistory'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,

  COLLAR_ORDER_STATUS_NEW_ORDER,
  COLLAR_ORDER_STATUS_ACCEPTED,
  COLLAR_ORDER_STATUS_PRINTING,
  COLLAR_ORDER_STATUS_SHIPPING,
  COLLAR_ORDER_STATUS_COMPLETED,
  COLLAR_ORDER_STATUS_CANCELED,
  COLLAR_ORDER_STATUS_IN_REVIEW,
  COLLAR_ORDER_STATUS_READY_TO_POST,
  COLLAR_ORDER_STATUS_PRE_POST,
  COLLAR_ORDER_STATUS_POSTED,
  COLLAR_ORDER_STATUS_INVOICED,
  COLLAR_ORDER_STATUS_SENT_TO_GP,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import DisplayedAddress from '../../../../common-components/DisplayedAddress'

import {
  formatApiDate,
  getAreAllElementsOfOneArrayIncludedInOtherArray,
} from '../../../../utils'


/*
 * *****************************************************************************
 * statuses config
 * *****************************************************************************
*/

// CODE_COMMENTS_60
export const collarOrderHistoryStatusesConfig = {
  [COLLAR_ORDER_STATUS_NEW_ORDER]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [COLLAR_ORDER_STATUS_ACCEPTED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [COLLAR_ORDER_STATUS_PRINTING]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [COLLAR_ORDER_STATUS_SHIPPING]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [COLLAR_ORDER_STATUS_COMPLETED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [COLLAR_ORDER_STATUS_CANCELED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [COLLAR_ORDER_STATUS_IN_REVIEW]: {
    includeShipmentObjsInHistoryTable: false,
  },
  [COLLAR_ORDER_STATUS_READY_TO_POST]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: COLLAR_ORDER_STATUS_COMPLETED,
  },
  [COLLAR_ORDER_STATUS_PRE_POST]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: COLLAR_ORDER_STATUS_COMPLETED,
  },
  [COLLAR_ORDER_STATUS_POSTED]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: COLLAR_ORDER_STATUS_COMPLETED,
  },
  [COLLAR_ORDER_STATUS_INVOICED]: {
    includeShipmentObjsInHistoryTable: true,
    includeStatusAsDropdownOptionInHistoryForm: true,
  },
  [COLLAR_ORDER_STATUS_SENT_TO_GP]: {
    includeShipmentObjsInHistoryTable: true,
    renameThisStatusToAnother: true,
    statusToRenameTo: COLLAR_ORDER_STATUS_COMPLETED,
  },
}


export const getShouldCollarOrderHistoryItemBeDisplayedInTable = orderObj => (
  getShouldHistoryItemObjBeDisplayedInTable(
    orderObj,
    collarOrderHistoryStatusesConfig,
    'collarOrderStatus',
  )
)

export const getDisplayedHumanReadableStatusOfCollarOrder = orderObj => (
  getDisplayedHumanReadableStatusOfHistoryItem(
    orderObj,
    collarOrderHistoryStatusesConfig,
    'collarOrderStatus',
  )
)


// CODE_COMMENTS_145
export const getShouldCollarOrderHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm = (
  currentlySelectedStatusInHistoryForm,
  orderObj,
) => (
  getShouldHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm(
    currentlySelectedStatusInHistoryForm,
    orderObj,
    collarOrderHistoryStatusesConfig,
    'collarOrderStatus',
  )
)

/*
 * *****************************************************************************
 * Displayed Data
 * *****************************************************************************
*/

export const createDisplayedOrderNumber = row => row.collarOrderIdNumber
export const createDisplayedOrderDate = row => formatApiDate(row.dateOrdered, DEFAULT_DISPLAYED_DATE_FORMAT)

// CODE_COMMENTS_237
export const createDisplayedDeliveryDate = (
  row,
  prependWithAppxIfGround=true,
) => {
  if (row.dateDelivered) { // CODE_COMMENTS_237
    return `${prependWithAppxIfGround && !row.overnightShipFlag ? 'appx. ' : ''}${formatApiDate(row.dateDelivered, DEFAULT_DISPLAYED_DATE_FORMAT)}`
  }

  return row.overnightShipFlag
    ? createFedexOvernightArrivalDateString(
      row.dateOrdered,
      DEFAULT_DISPLAYED_DATE_FORMAT,
      false, // don't include "(additional charges apply)"
    )
    : createFedexGroundArrivalDateString(
      row.dateOrdered,
      DEFAULT_DISPLAYED_DATE_FORMAT,
      prependWithAppxIfGround,
    )
}
export const createDisplayedNumberOfDifferentPlates = row => uniq_(
  row.collarPlates.map(plateInfo => plateInfo.collarPlateId),
).length
export const createDisplayedTotalQtyOrdered = row => row.collarPlates.reduce(
  (acc, plateProp) => (acc + plateProp.quantity),
  0,
)
export const createDisplayedProNumber = createDisplayedInfoWithAlternateTextOption('poNumber')
export const createDisplayedOrderComments = createDisplayedInfoWithAlternateTextOption('comments')
export const createDisplayedDeliveryAddress = (row, { customerId }) => (
  <DisplayedAddress customerId={customerId} addressId={row.shippingAddressId} />
)


/*
 * *****************************************************************************
 * field definitions
 * *****************************************************************************
*/

// Why is this a function? For extendability in case the full details modal ever
// needs to pass in different config parameters than the file download
// component.
export const getInfoDefinitionsForCollarOrderFullDetails = () => ([
  { heading: 'Order #', cellContent: createDisplayedOrderNumber },
  { heading: 'Order Status', cellContent: getDisplayedHumanReadableStatusOfCollarOrder },
  { heading: 'Order Date', cellContent: createDisplayedOrderDate },
  { heading: COLLAR_ORDER_FULL_DETAILS_LABEL_DELIVERY_DATE, cellContent: createDisplayedDeliveryDate },
  { heading: 'Shipping Type', cellContent: row => (row.overnightShipFlag ? 'Overnight' : 'Ground') },
  { heading: FIELD_LABEL_CUSTOMER_PO_NUM, cellContent: createDisplayedProNumber('none') },
  { heading: COLLAR_ORDER_FULL_DETAILS_LABEL_COMMENTS, cellContent: createDisplayedOrderComments('none') },
  { heading: COLLAR_ORDER_FULL_DETAILS_DELIVERY_ADDRESS, cellContent: createDisplayedDeliveryAddress },
])


/*
 * *****************************************************************************
 * Functions used by the DownloadTableAsFile component which access state.
 * *****************************************************************************
*/

export const getCollarPlateFlavor = (state, collarPlateId) => (
  getPropOr(state, collarPlateId, 'flavor', '[fetching...]') // CODE_COMMENTS_127
)

export const getCollarPlatePartNumber = (state, collarPlateId) => (
  getPropOr(state, collarPlateId, 'partNumber', '[fetching...]') // CODE_COMMENTS_127
)

export const getCollarPlateNumberOfColors = (state, collarPlateId) => (
  getPropOr(state, collarPlateId, 'numberOfColors', '[fetching...]') // CODE_COMMENTS_127
)


/*
 * *****************************************************************************
 * Miscellaneous
 * *****************************************************************************
*/

export function getAllCollarOrderObjectsToBeIncludedInHistoryTable(
  allCollarOrderObjects,
) {
  return filterHistoryObjectsWithUnrecognizedStatus( // CODE_COMMENTS_156
    allCollarOrderObjects,
    collarOrderHistoryStatusesConfig,
    'collarOrderStatus',
  )
}


/*
 * *****************************************************************************
 * Address objects for Download Table as File
 * *****************************************************************************
*/

// Why do we memoize this? Because We want make sure the history tables don't
// re-render every time any unrelated part of the state updates.
export const getAllAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile = memoize_(
  ({ state, arrayOrObjectOfObjects }) => (
    getUniqueAddressObjectsFromCollarOrderObjects({ state, arrayOrObjectOfObjects })
  ),
  ({ state, arrayOrObjectOfObjects }) => (
    getUniqueAddressIdsFromCollarOrderObjects({ state, arrayOrObjectOfObjects }).join()
  ),
)


// Helper functions for this section

function getUniqueAddressIdsFromCollarOrderObjects({ arrayOrObjectOfObjects }) {
  const arrayOfCollarOrderObjects = isPlainObject_(arrayOrObjectOfObjects)
    ? Object.values(arrayOrObjectOfObjects)
    : arrayOrObjectOfObjects

  return flow_(
    getAddressIdsFromCollarOrderObjects,
    uniqFp_,
    filterFp_(addressId => isString_(addressId)), // filter out nulls
  )(arrayOfCollarOrderObjects)
}

function getUniqueAddressObjectsFromCollarOrderObjects({ state, arrayOrObjectOfObjects }) {
  const addressIds = getUniqueAddressIdsFromCollarOrderObjects({ state, arrayOrObjectOfObjects })
  return getMultipleAddressObjectsByIdNoLinkInfoOnlyAddressInfo({ state, addressIds })
}


/*
 * *****************************************************************************
 * Collar Plate objects for Download Table as File
 * *****************************************************************************
*/

// Why do we memoize this? Because We want make sure the history tables don't
// re-render every time any unrelated part of the state updates.
export const getAllCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile = memoize_(
  ({ state, arrayOrObjectOfObjects }) => {
    // CODE_COMMENTS_211
    const allCollarPlateIdsThatHaveBeenFetchedSoFar = getSinglePropOfAllCollarPlateObjects(state, 'id')
    const allCollarPlateIdsFromCollarOrderObjects = getUniqueCollarPlateIdsFromCollarOrderObjects({
      state,
      arrayOrObjectOfObjects,
    })

    const haveAllCollarPlatesNecessaryForThisComponentBeenFetched = getAreAllElementsOfOneArrayIncludedInOtherArray(
      allCollarPlateIdsFromCollarOrderObjects,
      allCollarPlateIdsThatHaveBeenFetchedSoFar,
    )

    return haveAllCollarPlatesNecessaryForThisComponentBeenFetched
      ? getUniqueCollarPlateObjectsFromCollarOrderObjects({ state, arrayOrObjectOfObjects })
      : undefined // CODE_COMMENTS_211
  },
  ({ state, arrayOrObjectOfObjects }) => {
    const allCollarPlateIdsThatHaveBeenFetchedSoFar = getSinglePropOfAllCollarPlateObjects(state, 'id')
    const allCollarPlateIdsFromCollarOrderObjects = getUniqueCollarPlateIdsFromCollarOrderObjects({
      state,
      arrayOrObjectOfObjects,
    })

    // We include allCollarPlateIdsThatHaveBeenFetchedSoFar in the memoization
    // in order to force the presentational component re-renders when the web
    // app finishes fetching collar plates (but see CODE_COMMENTS_211).
    return [
      ...allCollarPlateIdsThatHaveBeenFetchedSoFar,
      ...allCollarPlateIdsFromCollarOrderObjects,
    ].join()
  },
)


// Helper functions for this section

function getUniqueCollarPlateIdsFromCollarOrderObjects({ arrayOrObjectOfObjects }) {
  const arrayOfCollarOrderObjects = isPlainObject_(arrayOrObjectOfObjects)
    ? Object.values(arrayOrObjectOfObjects)
    : arrayOrObjectOfObjects

  return getCollarPlateIdsFromCollarOrderObjects({
    arrayOfCollarOrderObjects,
    filterDuplicates: true,
    filterFalsyValues: true,
    sortResult: true,
  })
}

function getUniqueCollarPlateObjectsFromCollarOrderObjects({ state, arrayOrObjectOfObjects }) {
  const collarPlateIds = getUniqueCollarPlateIdsFromCollarOrderObjects({ state, arrayOrObjectOfObjects })
  return getMultipleCollarPlateObjectsById({ state, collarPlateIds })
}
