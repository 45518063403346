// CODE_COMMENTS_178
import {
  getPropsValidatorsOfSpecificUserType,
  WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
  WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
} from './util'

import {
  createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers,
} from '../util'

import {
  logObjectHasProblemsErrorMessage,
  CAN_STILL_BE_DISPLAYED,
  PREVENTING_DASHBOARD_FROM_LOADING,
} from '../../../../../utils/thirdPartyLogging'

import {
  isTruthyAndNonEmpty,
} from '../../../../../utils'


// `props` should be an object with at least one property: `data`
export function validateCurrentUserObject(props) {
  const {
    isEmployee, // CODE_COMMENTS_221
    checkPropsRelatedToAuthentication, // CODE_COMMENTS_222
  } = props
  const individualPropsValidatorsAndTransformers = getPropsValidatorsOfSpecificUserType({
    userType: isEmployee
      ? WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER
      : WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
    ifIsCurrentUserTypeShouldPropsRelatedToAuthenticationBeChecked: checkPropsRelatedToAuthentication,
  })

  const results = createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
    ...props,
    individualPropsValidatorsAndTransformers,
  })

  const {
    arrayOfProblemsWithData,
    canDataBeSavedToStore,
  } = results

  const {
    httpResponse,
  } = props

  if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
    if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
      logObjectHasProblemsErrorMessage({
        objectType: 'currentUserObject',
        level: canDataBeSavedToStore ? CAN_STILL_BE_DISPLAYED : PREVENTING_DASHBOARD_FROM_LOADING,
        additionalInfo: {
          arrayOfProblemsWithData,
        },
        httpResponse,
      })
    }
  }

  return results
}
