import React from 'react'
import { Button } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import PopupWithCatastrophicFailureErrorBoundary from '../../../semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'


import {
  ENVIRONMENT_PROD,
} from '../../../../constants'

import {
  getEnvironmentBasedOnRootApiUrl,
} from '../../../../utils'

export default ({
  onResetForm,
  // either a string, true or a falsy value. If true, clicking the button will
  // show a popup asking you to confirm the form clear with a default message.
  // If a string, the same as true but with the default message swapped with the
  // 'confirm' value
  confirm,
  buttonAndMessageText={
    buttonText: 'Fill Out Form Again',
    confirmationMessage: 'Are you sure? All error details will be cleared, too.',
    confirmationButtonText: 'Yes, clear it',
  },
  ...buttonProps
}) => {
  const { t: translate } = useTranslation('common')
  if (confirm && getEnvironmentBasedOnRootApiUrl() !== ENVIRONMENT_PROD) {
    if (confirm === true) {
      // eslint-disable-next-line no-param-reassign
      confirm = buttonAndMessageText.confirmationMessage
    }
    return (
      <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
        trigger={
          <Button
            type="button" // CODE_COMMENTS_260
            color="blue"
          >
            {translate(buttonAndMessageText.buttonText)}
          </Button>
        }
        content={
          <div style={{ textAlign: 'center' }}>
            <p>{translate(confirm)}</p>
            <Button
              color="green"
              content={translate(buttonAndMessageText.confirmationButtonText)}
              onClick={onResetForm}
              {...buttonProps}
            />
          </div>
        }
        on="click"
        position="top center"
      />
    )
  }
  return (
    <Button
      onClick={onResetForm}
      type="button" // CODE_COMMENTS_260
      color="blue"
      {...buttonProps}
    >
      {translate(buttonAndMessageText.buttonText)}
    </Button>
  )
}
