import React from 'react'
import { Icon, Message } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import {
  getCustomerIdsOfAllRelatedTo,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToInfo'

import InformationalPopup from '../../../../../common-components/InformationalPopup'

import {
  CUSTOMER_TYPES_PUB,
} from '../../../../../constants'


export default ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
}) => {
  const pubsRelatedTo = getCustomerIdsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263, CODE_COMMENTS_159
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_PUB,
  })

  const numberofPubsRelatedTo = pubsRelatedTo.length

  const message = `You no longer need to report shipments from your pub${numberofPubsRelatedTo > 1 ? 's' : ''} back to your brewery.`
  const { t: translate } = useTranslation('pagelabels')
  return (
    numberofPubsRelatedTo > 0 &&
      <Message
        info
        compact
        style={{
          margin: 0,
          padding: '0.5em 1em',
        }}
      >
        <Icon name="info" />
        {message}
        {' '} {/* space between the end of the message and the "Details" popup */}
        <InformationalPopup
          includeTriggerIcon={false}
          includeTriggerText
          triggerText="Details"
          content={translate('reportShipments.When you report a Brewery-to-Pub shipment, our system automatically processes the billing for both the Brewery-to-Pub shipment and the Pub-to-Brewery shipment.')}
        />
      </Message>
  )
}
