/* eslint-disable max-len */

import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

import flatten_ from 'lodash/flatten'


import PublicRoute from '../../PublicRoute'
import PrivateRoute from '../../PrivateRoute'
import RouteNotFound from '../../../../features/RouteNotFound'

import Login from '../../../../features/Login'
import ForgotPassword from '../../../../features/ForgotPasswordAndConfirmAccount/ForgotPassword'
import ConfirmAccount from '../../../../features/ForgotPasswordAndConfirmAccount/ConfirmAccount'
import ContactRep from '../../../../features/ContactRep'
import HelpPage from '../../../../features/Help'

import Dashboard from '../../../../features/Dashboard'
import EmployeeDashboard from '../../../../features/employee/EmployeeDashboard'

import ReportInventory from '../../../../features/ReportInventory/ReportInventory'


import {
  URL_PATHS,
} from '../../../../constants'

import { createRelativePath } from '../../../../utils'
import UnsubscribeContainer from '../../../../features/ManageContactsAndSubscriptions/UnsubscribeContainer'

// CODE_COMMENTS_26
const publicRoutes = () => ([
  <PublicRoute exact key={URL_PATHS.login.path} path={URL_PATHS.login.path} component={Login} />,
  <PublicRoute exact key={URL_PATHS.forgotPassword.path} path={URL_PATHS.forgotPassword.path} component={ForgotPassword} />,
  <PublicRoute exact key={URL_PATHS.confirmAccount.path} path={URL_PATHS.confirmAccount.path} component={ConfirmAccount} />,
  // faux private routes: CODE_COMMENTS_267
  <PublicRoute
    exact
    key={URL_PATHS.isDistReportInventoryNoLoginRequired.path}
    path={URL_PATHS.isDistReportInventoryNoLoginRequired.path}
    render={routeProps => <ReportInventory isDistReportInventoryNoLoginRequired {...routeProps} />}
  />,
  <PublicRoute
    exact
    key={URL_PATHS.isDistReportConstellationNoLoginRequired.path}
    path={URL_PATHS.isDistReportConstellationNoLoginRequired.path}
    render={routeProps => <ReportInventory isDistReportConstellationNoLoginRequired {...routeProps} />}
  />,
  <PublicRoute
    exact
    key={URL_PATHS.isUnsubscriptionNoLoginRequired.path}
    path={URL_PATHS.isUnsubscriptionNoLoginRequired.path}
    render={routeProps => <UnsubscribeContainer isUnsubscriptionNoLoginRequired {...routeProps} />}
  />,
])

// CODE_COMMENTS_26
const currentUserUniversalPrivateRoutes = (match, currentUserRootCustomerId) => ([
  <PrivateRoute exact key={URL_PATHS.contactRep.path} path={URL_PATHS.contactRep.path} component={ContactRep} />,
  <PrivateRoute exact key={URL_PATHS.help.path} path={URL_PATHS.help.path} component={HelpPage} />,
  <PrivateRoute
    key={URL_PATHS.root.path}
    path={createRelativePath(match, URL_PATHS.root.path)}
    render={routeProps => <Dashboard customerId={currentUserRootCustomerId} {...routeProps} />}
  />,
])

// CODE_COMMENTS_26
const currentUserEmployeeUniversalPrivateRoutes = () => ([
  <PrivateRoute
    key={URL_PATHS.employeeRoot.path}
    path={URL_PATHS.employeeRoot.path}
    render={routeProps => <EmployeeDashboard {...routeProps} />}
  />,
])


export default withRouter(({
  isAuthenticated,
  isEmployeeUserLoggedInRatherThanCustomerUser,
  ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,
  isPrelimInfoOfCurrentUserRootCustomerFetched,
  currentUserRootCustomerId,
  // customerType,
  match,
}) => {
  // CODE_COMMENTS_27
  let routes = publicRoutes()
  const toAddToRoutes = []
  if (
    isAuthenticated
    && !isEmployeeUserLoggedInRatherThanCustomerUser
    && isPrelimInfoOfCurrentUserRootCustomerFetched
  ) {
    toAddToRoutes.push(currentUserUniversalPrivateRoutes(match, currentUserRootCustomerId))
  }
  if (
    isAuthenticated
    && isEmployeeUserLoggedInRatherThanCustomerUser
  ) {
    toAddToRoutes.push(currentUserEmployeeUniversalPrivateRoutes(match))
  }
  if (
    isAuthenticated
    && isEmployeeUserLoggedInRatherThanCustomerUser
    && ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor
    && isPrelimInfoOfCurrentUserRootCustomerFetched
  ) {
    toAddToRoutes.push(currentUserUniversalPrivateRoutes(match, currentUserRootCustomerId))
  }

  routes = [...routes, ...flatten_(toAddToRoutes)]

  return (
    <Switch>
      {routes}
      <RouteNotFound />
    </Switch>
  )
})
