import Immutable from 'seamless-immutable'

import isArray_ from 'lodash/isArray'

// The seamless-immutable library has no withoutIn function, so we replicate it
// here: see CODE_COMMENTS_68. The fullPathToDelete should be an array of keys,
// with the final item in the array being the key you want to delete. If the
// path doesn't exist, returns the state unchanged.
export const withoutIn = (state, fullPathToDelete) => {
  const fullPathToDeleteWithoutFinalKey = fullPathToDelete.slice(0, -1)
  const keyToDelete = fullPathToDelete[fullPathToDelete.length-1]
  if (Immutable.getIn(state, fullPathToDeleteWithoutFinalKey)) {
    return Immutable.updateIn(state, fullPathToDeleteWithoutFinalKey, parent => parent.without(keyToDelete))
  }
  return state
}


export const withoutInMultiple = (
  state,
  // you can either pass in an array of arrays of strings or each array of
  // strings separately as multiple components
  ...fullPathsToDelete
) => {
  const pathsToDeleteArrayOfArraysOfStings =
    isArray_(fullPathsToDelete[0]) && fullPathsToDelete.every(item => isArray_(item))
      ? fullPathsToDelete[0]
      : fullPathsToDelete
  let toReturn = state
  pathsToDeleteArrayOfArraysOfStings.forEach(fullPathToDelete => {
    toReturn = withoutIn(state, fullPathToDelete)
  })
  return toReturn
}
