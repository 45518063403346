import { connect } from 'react-redux'

import fetchStatusSelectors from '../../../../redux/selectors/fetchStatuses/customers/preliminaryInfo'

import createAction from '../../../../redux/actions/createAction'
import { FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER } from '../../../../redux/actions/actionTypes'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../utils'


const { getFetchStatuses: getPrelimInfoFetchStatuses } = fetchStatusSelectors

const mapStateToProps = (state, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = ownProps
  const {
    hasFetchBeenAttempted,
    isFetching,
    didFetchSucceed,
    didFetchFail,
  } = getPrelimInfoFetchStatuses(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  return {
    hasFetchBeenAttempted,
    isFetching,
    didFetchFail,
    didFetchSucceed,
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  refetch: () => dispatch(createAction(FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER, { customerId: ownProps.customerId })),
})

export default component => (
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(component)
)
