/* eslint-disable max-len */

import React, { Fragment, useState, useEffect, useReducer } from 'react'
import { Form, Button } from 'semantic-ui-react'

import isFunction_ from 'lodash/isFunction'


import { useTranslation } from 'react-i18next'
import DateRangeFields from '../../../../../../common-components/rewrite/DateRangeFields'
import DismissableMessage from '../../../../../../common-components/DismissableMessage'
import GenericFetchFailureMessage from '../../../../../../common-components/rewrite/FetchFailure/GenericFetchFailureMessage'

import createAction from '../../../../../../redux/actions/createAction'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
} from '../../../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  isTruthyAndNonEmpty,
  parseFormNameForRedux,
} from '../../../../../../utils'


export default props => {
  const {
    formName,
    fieldsBeforeDateRange: FieldsBeforeDateRange,
    initialValues,
    mostRecentlySubmittedFormValues,
    additionalIsFormSubmittableChecksBesidesDateRanges,

    // Pass this in if the fieldsBeforeDateRange component has some other means of
    // choosing date ranges, such as month and year picker. If you pass this in,
    // the following date range props are optional.
    omitDateRangeFields,
    createDateRangeMin,
    createDateRangeMax,
    dateRangeFieldsLabel, // optional, defaults to "Date Range"
    downloadFileComponent: DownloadFileComponent,

    dispatch,
    submitAction,
  } = props

  const { t: translate } = useTranslation('common')
  const [formValues, dispatchFormValues] = useReducer(
    (formValues_, action) => ({
      ...formValues_,
      ...action,
    }),
    // Every time the user navigates away from the Dashboard, this history form
    // will unmount. When it re-mounts, we want to set the values to either 1)
    // the explicitly-set initial values if the form has not yet been submitted
    // (i.e. if this is the first render, e.g. the customer has just logged in)
    // or 2) to the most recently submitted values if this form has been
    // submitted.
    mostRecentlySubmittedFormValues || initialValues,
  )

  const [isLowDateValueValid, setIsLowDateValueValid] = useState(Object.keys(initialValues).includes(HISTORY_FORM_FIELD_NAME_START_DATE))
  const [isHighDateValueValid, setIsHighDateValueValid] = useState(Object.keys(initialValues).includes(HISTORY_FORM_FIELD_NAME_END_DATE))
  const [isFormSubmittable, setIsFormSubmittable] = useState(getIsFormSubmittable({
    formValues,
    omitDateRangeFields,
    isLowDateValueValid,
    isHighDateValueValid,
    additionalIsFormSubmittableChecksBesidesDateRanges,
  }))

  const [hasFetchBeenAttempted, setHasFetchBeenAttempted] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [didFetchFail, setDidFetchFail] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [didFetchSucceed, setDidFetchSucceed] = useState(false)


  const handleSubmit = () => {
    setHasFetchBeenAttempted(true)

    dispatch(createAction(
      submitAction,
      {
        ...props,
        formValues,
        setIsFetching,
        setDidFetchSucceed,
        setDidFetchFail,
      },
    ))
  }

  useEffect(
    () => {
      setIsFormSubmittable(getIsFormSubmittable({
        formValues,
        omitDateRangeFields,
        isLowDateValueValid,
        isHighDateValueValid,
        additionalIsFormSubmittableChecksBesidesDateRanges,
      }))
    },
    [
      formValues,
      omitDateRangeFields,
      isLowDateValueValid,
      isHighDateValueValid,
      additionalIsFormSubmittableChecksBesidesDateRanges,
    ],
  )

  // Submit the form automatically when the dashboard first loads
  useEffect(
    () => {
      if (
        // On first rendering of the form, formValues will be undefined. On
        // second rendering, formValues will be an empty object. Only after that
        // will the form values be populated with the initialValues, so we need
        // to wait until then to attempt the fetch.
        isTruthyAndNonEmpty(formValues)
        && isFormSubmittable
        && !hasFetchBeenAttempted
        && !mostRecentlySubmittedFormValues
      ) {
        handleSubmit()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      formValues,
      isFormSubmittable,
      hasFetchBeenAttempted,
      mostRecentlySubmittedFormValues,
    ],
  )

  return (
    <Fragment>
      <div style={{ float: 'right' }} key="form">
        <Form id={parseFormNameForRedux(formName).reducerName} size="tiny" onSubmit={handleSubmit}>
          <Form.Group>
            {
              DownloadFileComponent &&
              <DownloadFileComponent {...props} />
            }
            {FieldsBeforeDateRange &&
              <FieldsBeforeDateRange
                {...props}
                dispatchFormValues={dispatchFormValues}
                formValues={formValues}
              />
            }

            {
              !omitDateRangeFields &&
                <DateRangeFields
                  createDateRangeMin={createDateRangeMin}
                  createDateRangeMax={createDateRangeMax}
                  currentLowValue={formValues[HISTORY_FORM_FIELD_NAME_START_DATE]}
                  currentHighValue={formValues[HISTORY_FORM_FIELD_NAME_END_DATE]}
                  onSetIsLowValuevalid={setIsLowDateValueValid}
                  onSetIsHighValuevalid={setIsHighDateValueValid}
                  onChangeLowValue={value => { dispatchFormValues({ [HISTORY_FORM_FIELD_NAME_START_DATE]: value }) }}
                  onChangeHighValue={value => { dispatchFormValues({ [HISTORY_FORM_FIELD_NAME_END_DATE]: value }) }}
                  dateRangeLowFieldName={HISTORY_FORM_FIELD_NAME_START_DATE}
                  dateRangeHighFieldName={HISTORY_FORM_FIELD_NAME_END_DATE}
                  dateRangeFieldsLabel={dateRangeFieldsLabel}
                />
            }

            <Button
              type="submit"
              loading={isFetching}
              size="tiny"
              color={isFetching || !isFormSubmittable ? 'grey' : 'green'}
              disabled={isFetching || !isFormSubmittable}
            >
              {translate('Go')}
            </Button>
          </Form.Group>
        </Form>
      </div>
      {didFetchFail && !isFetching &&
        <DismissableMessage
          style={{ clear: 'both', marginBottom: '15px' }}
          error
        >
          <GenericFetchFailureMessage
            {...props}
          />
        </DismissableMessage>
      }
    </Fragment>
  )
}


// Helper functions

function getIsFormSubmittable({
  formValues,
  omitDateRangeFields,
  isLowDateValueValid,
  isHighDateValueValid,
  additionalIsFormSubmittableChecksBesidesDateRanges,
}) {
  if (
    !omitDateRangeFields &&
    (!isLowDateValueValid || !isHighDateValueValid)
  ) {
    return false
  }

  if (isFunction_(additionalIsFormSubmittableChecksBesidesDateRanges)) {
    return additionalIsFormSubmittableChecksBesidesDateRanges({
      formValues,
    })
  }
  return true
}
