import { connect } from 'react-redux'


import Table from './components/Table'

import {
  getEntireSlice as getAllOutboundEmptyKegShipmentHistoryObjects,
} from '../../../../../redux/selectors/histories/outboundEmptyKegShipmentHistory'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/forms'

import {
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
} from '../../../../../constants'

import {
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'
import { getEntireSlice as getEntireCustomersSlice } from '../../../../../redux/selectors/rewrite/customers'


const mapStateToProps = (state, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    formReducerName,
  } = ownProps

  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')

  const formName = createFormNameForRedux({
    reducerName: formReducerName,
    customerId,
  })

  const { getFetchStatuses } = fetchStatusSelectors
  const { isFetching, didFetchFail } = getFetchStatuses(state, formName)

  const areAnyItemsEditable = getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId)

  const allOutboundEmptyKegShipmentObjects = getAllOutboundEmptyKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  const entireCustomersSlice = getEntireCustomersSlice(state)

  return {
    entireItemSkusSlice,
    allOutboundEmptyKegShipmentObjects,
    configuredItemSkuIds,
    isFormSubmitting: isFetching,
    hasFormSubmitFailed: didFetchFail,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    ...ownProps,
    entireCustomersSlice,
  }
}

export default connect(
  mapStateToProps,
)(Table)


// CODE_COMMENTS_206
function getAreAnyItemsEditable() {
  return false
}

// CODE_COMMENTS_206
function getIsindividualItemEditable() {
  return false
}
