/**
 * CODE_COMMENTS_85
 */
import {
  getCustomerObjectsOfAllRelatedToOrFrom,
  getCustomerIdsOfAllRelatedToOrFrom,
  getRelationshipObjectsOfAllRelatedToOrFrom,
  getRelationshipIdsOfAllRelatedToOrFrom,

  getMultipleCustomerPropsOfAllRelatedToOrFrom,
  getMultipleRelationshipPropsOfAllRelatedToOrFrom,
  getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom,

  getIsRelatedToOrFromAnyone,
} from './relatedToOrFromInfo'

import {
  CUSTOMER_TYPES_BREWER,
} from '../../../../constants'


const toOrFrom = 'from'

export const getCustomerObjectsOfAllRelatedFrom = getCustomerObjectsOfAllRelatedToOrFrom(toOrFrom)
export const getCustomerIdsOfAllRelatedFrom = getCustomerIdsOfAllRelatedToOrFrom(toOrFrom)
export const getRelationshipObjectsOfAllRelatedFrom = getRelationshipObjectsOfAllRelatedToOrFrom(toOrFrom)
export const getRelationshipIdsOfAllRelatedFrom = getRelationshipIdsOfAllRelatedToOrFrom(toOrFrom)

export const getMultipleCustomerPropsOfAllRelatedFrom = getMultipleCustomerPropsOfAllRelatedToOrFrom(toOrFrom)
export const getMultipleRelationshipPropsOfAllRelatedFrom = getMultipleRelationshipPropsOfAllRelatedToOrFrom(toOrFrom)
export const getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedFrom =
  getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedToOrFrom(toOrFrom)

export const getIsRelatedFromAnyone = getIsRelatedToOrFromAnyone(toOrFrom)


// more specific helper functions


export const getCustomerIdsOfAllContracteesOfContractBrewerThatKegFillsCanBeReportedOn = ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
}) => (
  getCustomerIdsOfAllRelatedFrom({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_126, CODE_COMMENTS_263
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
  })
)

export const getCustomerIdsOfAllContracteesOfContractBrewer = ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
}) => (
  getCustomerIdsOfAllRelatedFrom({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    // CODE_COMMENTS_126, CODE_COMMENTS_263
    onlyRelationshipsThatShipmentsCanBeReportedOn: false,
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
  })
)
