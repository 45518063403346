/* eslint-disable import/prefer-default-export */
import React from 'react'
import DisplayedAddress from '../../DisplayedAddress'


/**
 * NOTE: This function is coupled to this SemanticUiCardChoices component, the
 * state shape of addresses, and the DisplayedAddress component, so use
 * carefully (it probably shouldn't be in this util file so close to our
 * reuseable SemanticUiCardChoices component, but there's not really a good
 * place to put this function anywhere else).
 */
export function createAddressChoicesForSemanticUiCardChoices(addresses) {
  return addresses.map(address => ({
    value: address.id,
    // we have to wrap our component in an array because of the way
    // SemanticUiCardChoices renders cardContent: it does it this way
    // rather than '<cardContent />' so that you can assign any content at
    // all to this cardContent property, be it a string, a number, or a
    // React component. But this flexibility means we have to be a little clever
    // with what we assign to cardContent: we can't simply assign a
    // functional component of '() => <DisplayedAddress...>'.
    cardContent: [<DisplayedAddress key="this-prevents-console-errors" address={address} />],
  }))
}
