import { put, call, takeEvery } from 'redux-saga/effects'


import { privateFetch } from '../fetch'

import createAction from '../../actions/createAction'

import {
    FETCH_CUSTOMER_MESSAGES,
    SAVE_CUSTOMER_MESSAGES,
    FETCH_CUSTOMER_MESSAGES_REQUEST,
    FETCH_CUSTOMER_MESSAGES_SUCCESS,
    FETCH_CUSTOMER_MESSAGES_FAILURE,
} from '../../actions/actionTypes'

import {
    API_URL_PATH_CUSTOMER_MESSAGES,
} from '../../../constants'

import {
    createFetchFailureAction,
    generalDoFailure,
} from '../util/fetchFailure'
import { URL_PARAM_CUSTOMER_ID } from '../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/customerMessages'

export function* fetchCustomerMessages(action) {
    const {
        customerId,
        preventsDashboardFromLoadingIfFails,
    } = action.payload
    yield put(createAction(FETCH_CUSTOMER_MESSAGES_REQUEST, { target: [customerId] }))
    const config = {
        path: API_URL_PATH_CUSTOMER_MESSAGES,
        params: {
            [URL_PARAM_CUSTOMER_ID]: customerId,
        },
    }
    let response
    try {
       response = yield call(privateFetch, config)
    } catch (error) {
        if (error.response && error.response.status === 404) { // customer has no messages on the file
            yield call(doSuccess, customerId, [])
            return
        } else {
            yield call(
                doFailure,
                {
                    customerId,
                    error,
                    preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
                },
            )
            return
        }
    }
    yield call(doSuccess, customerId, response.data)
}

// CODE_COMMENTS_11
export default [
    [takeEvery, FETCH_CUSTOMER_MESSAGES, fetchCustomerMessages],
]


function* doSuccess(customerId, customerMessages) {
    yield put(createAction(SAVE_CUSTOMER_MESSAGES, { info: customerMessages }))
    yield put(createAction(FETCH_CUSTOMER_MESSAGES_SUCCESS, { target: [customerId] })) // CODE_COMMENTS_20
}


function* doFailure({ error, preventsDashboardFromLoading }) {
    yield call(
        generalDoFailure,
        {
            error,
            preventsDashboardFromLoading,
            action: createFetchFailureAction({
                error,
                type: FETCH_CUSTOMER_MESSAGES_FAILURE,
            }),
        },
    )
}
