import React from 'react'
import { Grid, Header } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import UiContainer from '../../../../common-components/UiContainer'

import SearchForUserByEmailForm from './SearchForUserByEmailForm'
import CurrentlyOperatingAs from './CurrentlyOperatingAs'
import InstructionsOnHowToGetBackToThisPage from './InstructionsOnHowToGetBackToThisPage'


export default withTranslation()(UiContainer({
  headerText: 'Operate as Customer User',
  containerId: 'employeeOperateAsCustomerUserSection',
})(props => (
  <Grid
    columns={3}
    divided
    style={{ minHeight: '260px' }}
  >
    <Grid.Column>
      <Header as="h3" className="centered-content">{props?.t('Search')}</Header>
      <SearchForUserByEmailForm {...props} />
    </Grid.Column>
    <Grid.Column>
      <Header as="h3" className="centered-content">{props?.t('Currently Operating As')}</Header>
      <CurrentlyOperatingAs {...props} />
    </Grid.Column>
    <Grid.Column>
      <Header as="h3" className="centered-content">{props?.t('How to Get Back Here')}</Header>
      <InstructionsOnHowToGetBackToThisPage {...props} />
    </Grid.Column>
  </Grid>
)))
