import {
  withPropSimple,
  withPropOrUndefinedSimple,
  getEntireSlice as commonGetEntireSlice,
} from './higherOrderFunctions'

import { REDUCER_NAMES_CURRENT_USER as defaultSlice } from '../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../utils'


export const getProp = withPropSimple(defaultSlice)
export const getPropOrUndefined = withPropOrUndefinedSimple(defaultSlice)
export const getEntireSlice = commonGetEntireSlice(defaultSlice)


export const getCurrentUserFullName = state => (
  `${getProp(state, 'firstName')} ${getProp(state, 'lastName')}`
)

export const getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor = state => (
  isTruthyAndNonEmpty(getEntireSlice(state))
)
