import has_ from 'lodash/has'
import isArray_ from 'lodash/isArray'

import {
  individualPropsValidatorsAndTransformersOfCustomerObjectReceivedFromCustomersCall,
} from './customers'

import {
  createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers,
} from './util'


import {
  isTruthyAndNonEmpty,
} from '../../../../utils'


export function validateChildrenApiResponseData({ data, parentCustomerId }) {
  // The structure of a GET /children response should be
  // {
  //   customerToChildrenMap: {
  //     parentCustomerId1234: [...]
  //   }
  // }
  // We verify this structure here before inspecting any customer objects
  // within.
  if (!has_(data, ['customerToChildrenMap', parentCustomerId])) {
    // TODO rollbar: log error explaining that the structure of the /children
    // API response is wrong, which means we can't save this customer's children
    // and (for release 1, at least) this is causing the dashboard not to
    // render.
    return { canDataBeSavedToStore: false }
  }
  // data.customerToChildrenMap[parentCustomerId] is allowed to be
  // null/undefined, which the web app assumes to be 'no children'
  if (!data.customerToChildrenMap[parentCustomerId]) {
    // eslint-disable-next-line no-param-reassign
    data.customerToChildrenMap[parentCustomerId] = []
  }
  if (!isArray_(data.customerToChildrenMap[parentCustomerId])) {
    // TODO rollbar: log error explaining that the prop value of
    // data.customerToChildrenMap[parentCustomerId] is not an array, which means
    // we can't save this customer's children and (for release 1, at least) this
    // is causing the dashboard not to render.
    return { canDataBeSavedToStore: false }
  }

  // Now we inspect the individual customer objects of each child. If even one
  // of them is malformed in such a way that we can't put it into the Store,
  // don't put _any_ children into the store and consider the fetch to have
  // failed. This is important: we don't want to save a partial list of children
  // to the Store, because this could result in a Brewer being able to report a
  // partial inventory.
  let canAllChildCustomerObjectsBeSavedToStore = true
  const childCustomerObjects = data.customerToChildrenMap[parentCustomerId].map(childCustomerObj => {
    const {
      validatedData,
      canDataBeSavedToStore,
    } = validateCustomerObjectReceivedFromChildrenCall({ data: childCustomerObj })
    if (!canDataBeSavedToStore) { canAllChildCustomerObjectsBeSavedToStore = false }
    return validatedData
  })

  return {
    childCustomerObjects,
    canAllChildCustomerObjectsBeSavedToStore,
  }
}

// The `props` should be an object with at least one property: `data`
function validateCustomerObjectReceivedFromChildrenCall(props) {
  const individualPropsValidatorsAndTransformers =
    individualPropsValidatorsAndTransformersOfCustomerObjectReceivedFromCustomersCall.filter(
      def => (
        [
          'childrenIds',
          'itemSkuIds',
          'customerStatus',
          'customerType',
          'id',
          'name',
          'tapcustomerId',
        ].includes(def.propPath)
      ))

  const results = createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
    ...props,
    individualPropsValidatorsAndTransformers,
  })

  const {
    arrayOfProblemsWithData,
    // canDataBeSavedToStore,
  } = results

  if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
    // TODO rollbar: log error explaining that a child customer object is
    // different than expected. If canDataBeSavedToStore is false, explain that
    // this is causing the data not to be saved to the Redux store and therefore
    // is preventing the dashboard from loading.
  }

  return results
}
