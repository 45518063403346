import React from 'react'


import { useTranslation } from 'react-i18next'
import InformationalPopup from '../../../common-components/InformationalPopup'


export default () => {
  const { t: translate } = useTranslation('common')
  return (
    // CODE_COMMENTS_97
    <div style={{ marginBottom: '1em' }}>
      <InformationalPopup
        includeTriggerText
        triggerText={translate('Why am I reporting this inventory rather than my Contract Breweries?')}
        content={translate("You've elected to be responsible for reporting the inventory of full kegs of your product filled by one or more of your Contract Breweries. If you'd like to make your Contract Brewery responsible for reporting this inventory instead of you, contact your MicroStar representative and we can make this happen.")}
      />
    </div>
  )
}
