import reportShipmentsSagas from './reportShipments'
import reportOutboundShipmentSagas from './reportIndividualShipment'
import editShipmentReportSagas from './editShipmentReport'
import deleteShipmentReportSagas from './deleteShipmentReport'
import shipAndRevert from './shipAndRevert'


export default [
  ...reportShipmentsSagas,
  ...reportOutboundShipmentSagas,
  ...editShipmentReportSagas,
  ...deleteShipmentReportSagas,
  ...shipAndRevert,
]
