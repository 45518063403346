import { call, put, takeEvery } from 'redux-saga/effects'
import { batchActions } from 'redux-batched-actions'

import { privateFetch } from '../../fetch'

import {
  FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_DISTRIBUTOR,
  SAVE_RELATIONSHIPS,
  SAVE_CUSTOMER,
  SAVE_RELATIONSHIPS_AND_CUSTOMERS_AT_THE_SAME_TIME,
} from '../../../actions/actionTypes'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
} from '../../../../constants'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  createShipmentType,
  getRequestNewDistributorApiUrl,
} from '../../../../utils'

import {
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'

function* fetchChooseDistributor(action) {
  const config = yield call(createFetchConfig, action.payload)
  let response
  try {
    response = yield call(privateFetch, config)
  } catch (error) {
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { info: response.data, ...action.payload })
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_DISTRIBUTOR, fetchChooseDistributor],
]


function* doSuccess({
  // Why does the "Request New Distributor" API call return multiple
  // relationship objects? After all, we're only requesting a single
  // distributor. Yes, but there can be multiple relationships objects between
  // two customers, each with different effectiveDates. And the backend
  // back-dates the effectiveDate of this newly-created relationship by 1 year,
  // that way the web app can report shipments to this distributor from up to a
  // year ago. For reasons detailed at
  // https://microstartap3.atlassian.net/browse/TP3-795, this backdating may
  // result in multiple relationship objects between the BRW and DIST being
  // created, all of which will be returned by this API call, and all of which
  // we need to save to Redux.
  info: arrayOfRelationshipObjs,
  value: chosenDistributorId,
  distributorOptions,
  onSuccess,
}) {
  const distributorCustomerObj = distributorOptions.find(o => o.id === chosenDistributorId)
  yield put(
    batchActions( // CODE_COMMENTS_148
      [
        createAction(SAVE_RELATIONSHIPS, { info: arrayOfRelationshipObjs }),
        createAction(SAVE_CUSTOMER, { info: distributorCustomerObj }),
      ],
      SAVE_RELATIONSHIPS_AND_CUSTOMERS_AT_THE_SAME_TIME,
    ),
  )
  if (onSuccess) { onSuccess(arrayOfRelationshipObjs) }
}


function* doFailure({
  error,
  onFailure,
}) {
  if (onFailure) { onFailure({ error }) }
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
}


// Helper functions

function* createFetchConfig(props) {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    value: distributorId,
  } = props
  const params = yield call(createQueryParams, props)

  const fetchConfig = {
    path: getRequestNewDistributorApiUrl(customerId, distributorId),
    params,
    method: 'POST',
  }

  // CODE_COMMENTS_92
  if (operatingContractBrewerCustomerId) {
    const headers = createHeadersForContracteeBrewerApiCall(customerId)
    fetchConfig.headers = headers
  }

  return fetchConfig
}


function createQueryParams({
  customerId,
  selectedSource: sourceCustomerId,
}) {
  const sourceppfcontracttype = sourceCustomerId === customerId
    ? CUSTOMER_TYPES_BREWER
    : CUSTOMER_TYPES_CONTRACT_BREWER

  return {
    reltype: createShipmentType(CUSTOMER_TYPES_BREWER, CUSTOMER_TYPES_DISTRIBUTOR),
    sourceppfcontracttype,
    ...(
      sourceppfcontracttype === CUSTOMER_TYPES_CONTRACT_BREWER
        ? { destconbrwcid: sourceCustomerId }
        : {}
    ),
  }
}
