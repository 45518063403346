import React from 'react'

import ContactCardsForOneCustomer from '../containers/ContactCardsForOneCustomer'

import UiContainer from '../../../common-components/UiContainer'

import '../styles/contactRepPage.css'

export default UiContainer({
  headerText: 'Contact Representatives',
  containerId: 'contactMicroStarRepSection',
})(({
  namesAndCustomerIds,
}) => {
  let contactCards
  if (namesAndCustomerIds.length === 1) { // no children, not a Master account
    contactCards = (
      <ContactCardsForOneCustomer
        cardsOnTopOfRatherThanNextToEachother
        displayedCustomerName="Your Personalized Representitives"
        customerId={namesAndCustomerIds[0].id}
      />
    )
  } else {
    contactCards = namesAndCustomerIds.map(({ name, id }, index) => (
      <ContactCardsForOneCustomer
        key={id}
        displayedCustomerName={index === 0 ? 'Master Account' : name}
        customerId={id}
      />
    ))
  }

  return (
    <div className="contact-rep-section">
      {contactCards}
    </div>
  )
})
