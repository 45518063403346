/* eslint-disable max-len */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Routes from './components/MaintenanceFacilityRoutes'

import {
    getHasPermissionsToPerformFunctionality,
} from '../../../../redux/selectors/permissions'

import {
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR,
    FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
} from '../../../../constants/permissions'

const mapStateToProps = state => {
  const hasManageUsersAndContactsPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  })
  const hasAddressesPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
  })
  const hasAcknowledgeShipmentsPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  })
  const hasReportInventoryPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR,
  }) // CODE_COMMENTS 287

  return {
    // Why Booleans? See CODE_COMMENTS_252
    hasAcknowledgeShipmentsPermissions: Boolean(hasAcknowledgeShipmentsPermissions),
    hasManageUsersAndContactsPermissions: Boolean(hasManageUsersAndContactsPermissions),
    hasAddressesPermissions: Boolean(hasAddressesPermissions),
    hasReportInventoryPermissions: Boolean(hasReportInventoryPermissions),
  }
}

export default withRouter(connect(
    mapStateToProps,
)(Routes))
