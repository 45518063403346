import moment from 'moment'


import get_ from 'lodash/get'
import flow_ from 'lodash/fp/flow'
import entriesFp_ from 'lodash/fp/entries'
import reduceFp_ from 'lodash/fp/reduce'

import mapFp_ from 'lodash/fp/map'
import flattenFp_ from 'lodash/fp/flatten'
import filterFp_ from 'lodash/fp/filter'
import {
  validateDate,
} from '../../common-components/rewrite/ReactDatepicker'

import {
  getDoPubToBrwShipmentsGetAutoInvoiced,
} from '../../redux/selectors/rewrite/contracts'

import {
  REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../constants/formAndApiUrlConfig/reportOutboundShipments'

import {
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE,
} from '../../constants/formAndApiUrlConfig/reportBuybackShipments'

import {
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
} from '../../constants/formAndApiUrlConfig/reportSelfDistAndPubShipments'

import {
  FIELD_NAME_SOURCE_CUSTOMER,
  FIELD_NAME_DESTINATION_CUSTOMER,
  FIELD_NAME_INTERNAL_REFERENCE_NUM,
  FIELD_NAME_DATE_SHIPPED,

  // WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
  NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS,
} from '../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  CUSTOMER_TYPES_PUB,
  CUSTOMER_TYPES_VENUE,
  CUSTOMER_TYPES_SELF_COLLECTION,
  ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL,
  ITEM_SKUS_SKU_TYPE_KEG,
  ITEM_SKUS_SKU_TYPE_COMPOSITE,
} from '../../constants'

import {
  isTruthyAndNonEmpty,
  doSelectObjPropsAllExistAndContainTruthyValues,
  doesAtLeastOneObjPropExistAndContainATruthyValue,
  sortArrayByTemplateArray,
} from '../../utils'
import {
  getItemSkusDefaultSortOrder,
  getNonCompositeSkuIdsOfCompositeSku,
  mapItemSkuIdsForQualityLevel,
} from '../../redux/selectors/rewrite/itemSkus'

/*
 * *****************************************************************************
 * Customer Types
 * *****************************************************************************
*/

export function getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportOutboundShipmentsTo() {
  return [
    CUSTOMER_TYPES_DISTRIBUTOR,
    CUSTOMER_TYPES_BREWER,
    CUSTOMER_TYPES_VENUE,
    // Contract Brewers are conspicuously absent; see CODE_COMMENTS_74
    // Self-Dist and Pub customers are in a separate form altogether
  ]
}

export function getCustomerTypesABrewerTargetingAContractBrewingContractCanReportOutboundShipmentsTo() {
  return [
    CUSTOMER_TYPES_DISTRIBUTOR,
    CUSTOMER_TYPES_VENUE,
    // Brewers are conspicuously absent: see CODE_COMMENTS_74. Contract Brewers
    // are also absent; Niko informed Tad on 6/4/2018 that pooled-keg Contract
    // Brewers never physically ship their filled kegs to other pooled-keg
    // Contract Brewers, and if a customer ever wanted to perform that type of
    // move, it's better that the customer be required to call MicroStar to
    // report this type of shipment.
  ]
}


export function getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportBuybackShipmentsTo() {
  return [
    CUSTOMER_TYPES_DISTRIBUTOR,
    CUSTOMER_TYPES_BREWER,
    CUSTOMER_TYPES_SELF_DISTRIBUTION,
    CUSTOMER_TYPES_PUB,
    CUSTOMER_TYPES_VENUE,
    // Contract Brewers are conspicuously absent; see CODE_COMMENTS_74
  ]
}


export function getCustomerTypesABrewerTargetingAContractBrewingContractCanReportBuybackShipmentsTo() {
  return [
    CUSTOMER_TYPES_DISTRIBUTOR,
    CUSTOMER_TYPES_SELF_DISTRIBUTION,
    CUSTOMER_TYPES_PUB,
    // Brewers are conspicuously absent: see CODE_COMMENTS_74. Contract Brewers
    // are also absent; Niko informed Tad on 6/4/2018 that pooled-keg Contract
    // Brewers never physically ship their filled kegs to other pooled-keg
    // Contract Brewers, and if a customer ever wanted to perform that type of
    // move, it's better that the customer be required to call MicroStar to
    // report this type of shipment.
  ]
}


// eslint-disable-next-line max-len
export function getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsTo(restrictPubs) {
  if (restrictPubs) {
    return [CUSTOMER_TYPES_SELF_DISTRIBUTION]
  }
  return [
    CUSTOMER_TYPES_SELF_DISTRIBUTION,
    CUSTOMER_TYPES_PUB,
  ]
}

export function getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsFrom({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  operatingContractBrewerCustomerId,
}) {
  const doPubToBrwShipmentsGetAutoInvoiced = getDoPubToBrwShipmentsGetAutoInvoiced({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    brewerCustomerId: customerId,
    operatingContractBrewerCustomerId,
  })
  return [
    CUSTOMER_TYPES_SELF_DISTRIBUTION,
    ...(doPubToBrwShipmentsGetAutoInvoiced ? [] : [CUSTOMER_TYPES_PUB]), // CODE_COMMENTS_202
  ]
}

// eslint-disable-next-line max-len
export function getCustomerTypesABrewerTargetingAContractBrewingContractCanReportSelfDistAndPubShipmentsTo(restrictPubs) {
  return getCustomerTypesABrewerTargetingADefaultBrewingContractCanReportSelfDistAndPubShipmentsTo(restrictPubs)
}


export function getCustomerTypesABrwOrConbrwCanReportSelfCollectionShipmentsFrom() {
  return [
    CUSTOMER_TYPES_SELF_COLLECTION,
  ]
}


/*
 * *****************************************************************************
 * Date field
 * *****************************************************************************
*/

// Returns an object containing the date-fencing props that should be set on the
// react-datepicker field of all Report Shipments forms.
export function getDateFieldFencingOfReportShipmentsForm() {
  return {
    // When TAP3 first launched, users could only report up to 1 year in the
    // past, but after several months we found an edge case where 1 year was too
    // restrictive (don't remember the exact customer), so we increased this.
    minDate: moment().subtract(3, 'years'),
    maxDate: moment().add(NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS, 'days'),
  }
}

/*
 * *****************************************************************************
 * Are rows filled out
 * *****************************************************************************
*/

function getIsAtLeastOneFieldFilledOut({
  rowValues,
  fieldArrayName,
  fieldArraysAndCustomersFieldsDropdownOptions,
  entireItemSkusSlice,
}) {
  if (!isTruthyAndNonEmpty(rowValues)) { return false }

  const targetFieldArrayDefinition = fieldArraysAndCustomersFieldsDropdownOptions.find(
    def => def.fieldArrayName === fieldArrayName,
  )

  const doesFieldArrayHaveOnlyOneChoiceForCustomerFieldThatMustBeChosenFirst =
    targetFieldArrayDefinition?.customersFieldsDropdownOptions?.length === 1


  let customerFieldsToCheck
  if (doesFieldArrayHaveOnlyOneChoiceForCustomerFieldThatMustBeChosenFirst) {
    // don't check to see whether the "Source" customer field is filled out if
    // the field array has only one choice for "Source" customer (same with
    // "destination"). If this is the case, "Source" will _always_ be filled
    // out, even if the user doesn't touch the row but has simply created the
    // row. The reason is because all newly-added rows get a "Source" value
    // added as an initialValue (see the implementation there for why).
    // customerFieldsToCheck =
    //   targetFieldArrayDefinition.whichCustomersFieldMustBeChosenFirstSourceOrDestination
    //     === WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE
    //     ? [FIELD_NAME_DESTINATION_CUSTOMER]
    //     : [FIELD_NAME_SOURCE_CUSTOMER]

    // TP3-6072 - Submit button is disabled if customer choice count is 1
    customerFieldsToCheck = ' '
  } else {
    customerFieldsToCheck = [
      FIELD_NAME_SOURCE_CUSTOMER,
      FIELD_NAME_DESTINATION_CUSTOMER, // CODE_COMMENTS_93
    ]
  }

  return doesAtLeastOneObjPropExistAndContainATruthyValue(
    rowValues,
    [
      ...Object.keys(entireItemSkusSlice),
      ...customerFieldsToCheck,
      FIELD_NAME_INTERNAL_REFERENCE_NUM,
      FIELD_NAME_DATE_SHIPPED,
    ],
  )
}


export function getIsFieldRowFullyFilledOut({
  rowValues,
  fieldArrayName,
  entireItemSkusSlice,
  // Usually the only time we ignore the reference number is for SelfDist/Pub
  // shipments (see CODE_COMMENTS_95), but you can override this and ignore the
  // reference number no matter the field array name by setting the following
  // prop to true.
  ignoreReferenceNumber=false,
  returnFalseIfDateValueIsInvalid=true, // i.e. before minDate
}) {
  if (!isTruthyAndNonEmpty(rowValues)) { return false }

  // make sure at least one Item SKU field (i.e. keg field) contains a non-zero
  // integer
  if (!Object.keys(entireItemSkusSlice).some(itemSkuId => (
    // make sure the field is filled out
    rowValues[itemSkuId]
    &&
    // make sure field contains a non-zero integer
    Number(rowValues[itemSkuId]) > 0
  ))) {
    return false
  }

  // make sure all unconditionally required fields are filled out
  if (!doSelectObjPropsAllExistAndContainTruthyValues(
    rowValues,
    [
      FIELD_NAME_SOURCE_CUSTOMER,
      FIELD_NAME_DESTINATION_CUSTOMER, // CODE_COMMENTS_93
      FIELD_NAME_DATE_SHIPPED,
      // CODE_COMMENTS_95
      ...(
        (
          ignoreReferenceNumber
          || [
            REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
            REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
            REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
          ].includes(fieldArrayName)
        )
          ? []
          : [FIELD_NAME_INTERNAL_REFERENCE_NUM]),
    ],
  )) { return false }

  if (returnFalseIfDateValueIsInvalid) {
    return Boolean(validateDate({
      value: get_(rowValues, FIELD_NAME_DATE_SHIPPED),
      ...getDateFieldFencingOfReportShipmentsForm(),
    }))
  }

  return true
}


function getIsFieldRowPartiallyFilledOut({
  rowValues,
  fieldArrayName,
  fieldArraysAndCustomersFieldsDropdownOptions,
  entireItemSkusSlice,
}) {
  if (!getIsAtLeastOneFieldFilledOut({
    rowValues,
    fieldArrayName,
    fieldArraysAndCustomersFieldsDropdownOptions,
    entireItemSkusSlice,
  })) { return false }
  return !getIsFieldRowFullyFilledOut({
    rowValues,
    fieldArrayName,
    entireItemSkusSlice,
  })
}


// Returns true if at least one row is fully filled out an no rows are partially
// filled out; useful for determining whether a form is submittable.
export function getAreAllFieldRowsFullyFilledOut({
  formValues,
  fieldArraysAndCustomersFieldsDropdownOptions,
  entireItemSkusSlice,
}) {
  // [
  //   [fieldArrayName1, row1Values],
  //   [fieldArrayName1, row2Values],
  //   [fieldArrayName2, row1Values],
  //   [fieldArrayName2, row2Values],
  // ]
  const allRowValues = flow_(
    entriesFp_,

    reduceFp_(
      (acc, [fieldArrayName, arrayOfRowValues]) => ([
        ...acc,
        ...arrayOfRowValues.map(rowValues => [fieldArrayName, rowValues]),
      ]),
      [],
    ),
  )(formValues)

  let isAtLeastOneRowFullyFilledOut = false
  const areSomeRowsPartiallyFilledOut = allRowValues.some(([fieldArrayName, rowValues]) => {
    if (getIsFieldRowFullyFilledOut({
      rowValues,
      fieldArrayName,
      entireItemSkusSlice,
    })) {
      isAtLeastOneRowFullyFilledOut = true
      return false
    }
    return getIsFieldRowPartiallyFilledOut({
      rowValues,
      fieldArrayName,
      fieldArraysAndCustomersFieldsDropdownOptions,
      entireItemSkusSlice,
    })
  })
  return isAtLeastOneRowFullyFilledOut && !areSomeRowsPartiallyFilledOut
}


/*
 * *****************************************************************************
 * Potential Duplicates feature
 * *****************************************************************************
*/

export const fieldArraysToApplyPotentialDuplicatesFeatureTo = [
  REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE,
  // Notice that REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
  // REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO and
  // REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM are missing: the
  // business decision was made to not apply the duplicates feature to these
  // forms, because it's much more common to submit intentional duplicates on
  // these forms.
]


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

export function getItemSkuIdsForReportShipmentsForm({
  entireItemSkusSlice,
  itemSkuIds,
  ignoreDisplayOnShipments,
}) {
  return flow_(
    mapFp_(itemSkuId => getNonCompositeSkuIdsOfCompositeSku({
      entireItemSkusSlice,
      itemSkuId,
      filterByItemSkuTypes: [ITEM_SKUS_SKU_TYPE_KEG, ITEM_SKUS_SKU_TYPE_COMPOSITE],
    })),
    flattenFp_,
    // This will filter out pallets because all pallets have a null quality
    // level (as opposed to Unsorted)
    itemSkuIds_ => mapItemSkuIdsForQualityLevel({
      entireItemSkusSlice,
      itemSkuIds: itemSkuIds_,
      filterQualityLevel: ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL,
    }),
    filterFp_(itemSkuId => (ignoreDisplayOnShipments && true) || entireItemSkusSlice[itemSkuId].displayOnShipments),
    arr => sortArrayByTemplateArray(
      arr,
      getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
    ),
  )(itemSkuIds)
}
