/**
 * Often you want a button that shows contact info when the user presses it.
 * This component does just that.
 */

import React from 'react'

import RevealContentButton from '../RevealContentButton'
import ContactCard from '../ContactCard'
import ContactCardWithHardCodedContactInfo from '../ContactCardWithHardCodedContactInfo'


export default props => {
  const {
    customerId,
    repType,
    showGenericContactInfoIfFetchUniversalRepNotFinished,
    // If you don't pass in repType, you must pass in hardCodedContactInfo, an
    // object with these props: repTitle, name, email, phone (only repTitle is
    // required)
    hardCodedContactInfo,
    buttonProps,
    blockDisplay,
    asPopup,
    buttonTextWhenVisible='Hide Rep Contact Info',
    buttonTextWhenHidden='Show Rep Contact Info',
    fullSizeButton,
    buttonColor,
    ...rest
  } = props
  return (
    <RevealContentButton
      buttonTextWhenVisible={buttonTextWhenVisible}
      buttonTextWhenHidden={buttonTextWhenHidden}
      buttonProps={buttonProps}
      blockDisplay={blockDisplay}
      asPopup={asPopup}
      fullSizeButton={fullSizeButton}
      buttonColor={buttonColor}
    >
      {hardCodedContactInfo
        ? <ContactCardWithHardCodedContactInfo
          {...hardCodedContactInfo}
        />
        : <ContactCard
          customerId={customerId}
          repType={repType}
          showGenericContactInfoIfFetchUniversalRepNotFinished={showGenericContactInfoIfFetchUniversalRepNotFinished}
          {...rest}
        />
      }
    </RevealContentButton>
  )
}
