// CODE_COMMENTS_232

import historyReducerCreator from './historyReducerCreator'

import {
  SAVE_KEG_FILL_CONTRACTEE_HISTORY,
  SAVE_SINGLE_KEG_FILL_CONTRACTEE_HISTORY_ITEM,
} from '../../actions/actionTypes'


export default historyReducerCreator({
  saveHistoryOfSingleCustomerActionType: SAVE_KEG_FILL_CONTRACTEE_HISTORY,
  saveSingleHistoryItemActionType: SAVE_SINGLE_KEG_FILL_CONTRACTEE_HISTORY_ITEM,
})
