import React, { useEffect, useLayoutEffect, useMemo, useReducer, useState } from 'react'
import { Accordion, Grid, Icon, Form, Modal, Button, Message, Confirm } from 'semantic-ui-react'
import { useDispatch, useStore } from 'react-redux'
import flow_ from 'lodash/fp/flow'
import groupByFp_ from 'lodash/fp/groupBy'
import valuesFp_ from 'lodash/fp/values'
import mapFp_ from 'lodash/fp/map'
import { Trans } from 'react-i18next'
import FormSectionAsTable from '../../../../common-components/rewrite/formAsTable/FormSectionAsTable'
import FormSubmissionDimmerOverlay from '../../../../common-components/rewrite/FormSubmission/DimmerOverlay'
import {
  createFieldArrayDefinitions,
  DELIVERY_SCHEDULE_FORM_ADD_DELETE_ROW,
  DELIVERY_SCHEDULE_FORM_VALUE,
  DELIVERY_SCHEDULE_ORDER_TOTAL, subsidiaries,
} from '../../OrderKegs/constants'
import createAction from '../../../../redux/actions/createAction'
import {
  createOrderKegsFormFieldNameOfQuantity,
  ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED,
  ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE, ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS,
  ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS,
  ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER,
  ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER,
} from '../../../../constants/formAndApiUrlConfig/orderKegs'
import ModalWithCatastrophicFailureErrorBoundary from '../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import TextAsPseudoLink from '../../../../common-components/TextAsPseudoLink'
import { getProp as getCustomerProp } from '../../../../redux/selectors/rewrite/customers'
import {
  calculateOrderTotal,
  changeFormValue, getDateFieldFencingProps, getHasLocalDistributors,
  getIsFormSubmittable,
  getLastOrderDate, KegQtyField,
} from './index'
import {
  FIELD_NAME_TOTAL_NUMBER_KEGS,
  FIELD_NAME_TOTAL_NUMBER_PALLETS,
} from '../../../../constants/formAndApiUrlConfig/acknowledgeInboundShipments'
import {
  formatApiDate,
  formatDateForApiCall,
  getHumanReadableContainerTypeFromItemSku,
  isTruthyAndNonEmpty,
} from '../../../../utils'
import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
  KEG_ORDER_STATUS_CANCELED,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'
import {
  EDIT_OR_CANCEL_ORDER_KEGS,
  SAVE_SINGLE_KEG_ORDER_HISTORY_ITEM,
} from '../../../../redux/actions/actionTypes'
import ContainerTypesDifferences from '../../../ContainerTypesDifferences'
import {
  ITEM_SKUS_SKU_TYPE_COMPOSITE,
  KEG_ORDERS_MIN_DAYS,
  ORDER_KEGS_ORDER_TYPE_BLANKET,
  ORDER_KEGS_ORDER_TYPE_LOCAL, ORDER_KEGS_ORDER_TYPE_PICKUP,
} from '../../../../constants'
import ReactDatePicker from '../../../../common-components/rewrite/ReactDatepicker'
import ContentToTheRightOfAFormField from '../../../../common-components/ContentToTheRightOfAFormField'
import XDaysFromNow from './XDaysFromNow'
import {
  getItemSkuKegOrderId,
  getItemSkuUUId,
  parseBlanketKegs,
  parseKegs,
} from '../../../../redux/sagas/forms/orderKegs/util'
import OrderAgain from './orderAgain'
import { getKegOrderDays } from '../../../../redux/selectors/orderKegs'

function getLocalOrNonLocal(orderType) {
  return (
    orderType !== ORDER_KEGS_ORDER_TYPE_LOCAL ?
      ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_NONLOCAL_IDENTIFIER :
      ORDER_KEGS_FORM_FIELDS_NAME_QUANTITIES_LOCAL_IDENTIFIER
  )
}
function getInitialValues({ orderDetails, entireItemSkusSlice, isEditMode }) {
  const { orderType, deliveryScheduleObjects, orderLineItemObjects } = orderDetails
  const localOrNonlocal = getLocalOrNonLocal(orderType)
  /* The return objects are same, but how we read the values is different
    return Format for blanket orders
    {
      date-needed: '12/13/2022
      total-number-kegs: 10,
      HB-MS-D-U: 20
      SB-MS-D-U: 20
    }
    return format for other orders
    {
      date-needed: '12/13/2022
      HB-MS-D-U: 20
      SB-MS-D-U: 20
    }
   */
  if (orderType === ORDER_KEGS_ORDER_TYPE_BLANKET) {
    return flow_(
      groupByFp_(o => o.dateTimeNeeded),
      valuesFp_,
      mapFp_(a => {
        const value = a?.reduce((acc, curr) => {
          const { skuType, itemsPerPallet } = entireItemSkusSlice?.[curr?.itemSkuId] || {}
          let totalKegs = curr?.quantity
          if (skuType === ITEM_SKUS_SKU_TYPE_COMPOSITE && itemsPerPallet) {
            totalKegs = Number(curr?.quantity || 0) * itemsPerPallet
          }
          return {
            ...acc,
            [curr?.itemSkuId]: curr?.quantity,
            ...(isEditMode && {
              // eslint-disable-next-line max-len
              [ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]: formatApiDate(curr?.dateTimeNeeded, DEFAULT_DISPLAYED_DATE_FORMAT),
              [getItemSkuKegOrderId(curr?.itemSkuId)]: curr?.kegOrderId,
              [getItemSkuUUId(curr?.itemSkuId)]: curr?.id,
            }),
            [FIELD_NAME_TOTAL_NUMBER_KEGS]: acc[FIELD_NAME_TOTAL_NUMBER_KEGS] + totalKegs,
            [FIELD_NAME_TOTAL_NUMBER_PALLETS]: acc[FIELD_NAME_TOTAL_NUMBER_PALLETS] + curr.quantity,
          }
        }, {
          [FIELD_NAME_TOTAL_NUMBER_KEGS]: 0,
          [FIELD_NAME_TOTAL_NUMBER_PALLETS]: 0,
        })
        return value
      }),
    )(deliveryScheduleObjects)
  }
  return {
    ...(orderType === ORDER_KEGS_ORDER_TYPE_PICKUP && {
      [ORDER_KEGS_FORM_FIELD_NAME_PICKUP_ADDRESS]: orderDetails?.pickupFacilityId || '',
    }),
    ...(orderLineItemObjects?.reduce((acc, orderLineItem) => {
      const fieldName = createOrderKegsFormFieldNameOfQuantity(orderLineItem?.itemSkuId, localOrNonlocal)
      return {
        ...acc,
        [fieldName]: orderLineItem?.quantity,
        ...(isEditMode && { [getItemSkuUUId(orderLineItem?.itemSkuId)]: orderLineItem?.id }),
      }
    }, {})),
    ...(isEditMode && {
      [ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]: formatApiDate(orderDetails?.dateNeeded, DEFAULT_DISPLAYED_DATE_FORMAT),
    }),
  }
}

const getOrderLineItemIds = ({ orderLineItemObjects, isEditMode }) => (orderLineItemObjects?.reduce((acc, curr) => ({
  ...acc,
  ...(isEditMode && { [getItemSkuUUId(curr.itemSkuId)]: curr.id }) }),
{})) || {}

export const buildFormValues = ({ orderDetails, entireItemSkusSlice, isEditMode }) => {
  const { orderType, shipAddressId, orderLineItemObjects } = orderDetails
  const orderLineItemIds = getOrderLineItemIds({ orderLineItemObjects, isEditMode })
  const initialValues = getInitialValues({ orderDetails, entireItemSkusSlice, isEditMode })
  return {
    [DELIVERY_SCHEDULE_FORM_VALUE]: orderType === ORDER_KEGS_ORDER_TYPE_BLANKET ? initialValues: [],
    ...{ ...(orderType !== ORDER_KEGS_ORDER_TYPE_BLANKET && initialValues) },
    ...{ ...(orderType === ORDER_KEGS_ORDER_TYPE_BLANKET && orderLineItemIds) },
    [DELIVERY_SCHEDULE_ORDER_TOTAL]: calculateOrderTotal({ formValues: initialValues }),
    ...(isEditMode && {
      [ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]: initialValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]
      || getLastOrderDate({ formValues: initialValues }),
    }),
    [ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE]: orderType,
    [ORDER_KEGS_FORM_FIELD_NAME_SHIPPING_ADDRESS]: shipAddressId,
  }
}

export const DeliveryScheduleForm = props => {
  const {
    isEdit, formValues, customerId, itemSkuIds, entireItemSkusSlice, dispatchFormValues,
    entireCustomersSlice, customerContracts, translate,
  } = props
  const [activeIndex, setActiveIndex] = useState(0)
  // Added for work around. Since moment js can't able to disable weekends
  useLayoutEffect(() => {
    const subsidiary = subsidiaries?.[entireCustomersSlice?.[customerId]?.subsidiaryId] || {}
    if (subsidiary && Object.values(subsidiary).length > 0) {
      const form = document.querySelector('#orderKegsSection')
      if (form) {
        form.classList.add('disable-weekend-dates')
      }
    }
  }, [entireCustomersSlice, customerId])
  const fieldDefinitions = createFieldArrayDefinitions({
    customerId,
    itemSkuIds,
    entireItemSkusSlice,
    dispatchFormValues,
    entireCustomersSlice,
    customerContracts,
    translate,
  })
  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }
  return (
    <Grid.Row>
      <Grid.Column style={{ flex: '0 0 100%' }} verticalAlign="middle">
        <Accordion>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={!isEdit ? handleClick : undefined}
          >
            <Form.Field required style={{ display: 'inline-block', width: '13em' }}>
              <label style={{ display: 'inline-block' }}>
                <Trans ns='pagelabels' i18nKey='orderKegs.Order schedule'>
                  Order schedule
                </Trans>
              </label>
              <Icon name='dropdown' />
            </Form.Field>
            <strong>
              <Form.Field style={{ display: 'inline-block', width: '15em' }}>
                <label>{`${translate('orderKegs.Order Total')}: ${formValues?.[DELIVERY_SCHEDULE_ORDER_TOTAL] || ''}`}</label>
              </Form.Field>
              <Form.Field style={{ display: 'inline-block' }}>
                <label>{`${translate('orderKegs.Last Order Date')}: ${formValues?.[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED] || ''}`}</label>
              </Form.Field>
            </strong>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <i>
              {translate('orderKegs.Empty rows are ignored')}
            </i>
            <div style={{ overflowX: 'auto', padding: '0 8px' }}>
              <FormSectionAsTable
                fieldDefinitions={fieldDefinitions}
                fieldArrayName={DELIVERY_SCHEDULE_FORM_VALUE}
                formValues={formValues}
                customerContracts={customerContracts}
                entireCustomersSlice={entireCustomersSlice}
                customerId={customerId}
                abilityToAddAndDeleteRows={!isEdit}
                onAddRow={obj => {
                  dispatchFormValues(createAction(DELIVERY_SCHEDULE_FORM_ADD_DELETE_ROW, { itemSkuIds, type: 'add', ...obj }))
                }}
                onDeleteRow={obj => {
                  dispatchFormValues(createAction(DELIVERY_SCHEDULE_FORM_ADD_DELETE_ROW, { itemSkuIds, type: 'delete', ...obj }))
                }}
              />
            </div>
          </Accordion.Content>
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  )
}

const ErrorMessage = ({ msg }) => <Message negative>{msg}</Message>

export const EditOrder = props => {
  const {
    info: orderDetails, customerId, entireItemSkusSlice, entireCustomersSlice, setModalOpen, customerContracts, match,
    entireContractsSlice,
    entireRelationshipsSlice,
  } = props
  // Added for work around. Since moment js can't able to disable weekends
  useLayoutEffect(() => {
    const subsidiary = subsidiaries?.[entireCustomersSlice?.[customerId]?.subsidiaryId] || {}
    if (subsidiary && Object.values(subsidiary).length > 0) {
      const form = document.querySelector('#orderKegsSection')
      if (form) {
        form.classList.add('disable-weekend-dates')
      }
    }
  }, [entireCustomersSlice, customerId])
  const { orderId, orderType } = orderDetails
  const state = useStore()?.getState()
  const itemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')
  const kegOrderDays = getKegOrderDays(state, customerId, 'kegOrderDays')
  const dispatch = useDispatch()
  const [formValues, dispatchFormValues] = useReducer(
    changeFormValue,
    buildFormValues({ orderDetails, entireItemSkusSlice, isEditMode: true }),
  )
  const [isFormSubmittable, setIsFormSubmittable] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(false)
  const [didSubmitFail, setDidSubmitFail] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [orderObj, setOrderObj] = useState({})
  const localOrNonLocal = getLocalOrNonLocal(orderType)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const hasLocalDistributors = useMemo(
    () => getHasLocalDistributors({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
    }),
    [
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
    ],
  )

  const handleModalClose = () => {
    setModalOpen(false)
    setDidSubmitFail(false)
    setIsSubmitting(false)
    setDidSubmitSucceed(false)
    if (didSubmitSucceed) {
      if (isTruthyAndNonEmpty(orderObj)) {
        dispatch(createAction(SAVE_SINGLE_KEG_ORDER_HISTORY_ITEM, {
          savePath: [customerId],
          info: orderObj,
        }))
      }
    }
  }

  useEffect(
    () => {
      setIsFormSubmittable(getIsFormSubmittable({
        formValues,
        itemSkuIds,
        entireItemSkusSlice,
        entireCustomersSlice,
        customerId,
        hasLocalDistributors,
        kegOrderDays,
      }))
    },
    [
      formValues,
      itemSkuIds,
      entireItemSkusSlice,
      entireCustomersSlice,
      customerId,
      hasLocalDistributors,
      kegOrderDays,
    ],
  )

  const handleSubmit = () => {
    if (!isTruthyAndNonEmpty(orderDetails)) {
      return
    }
    const { deliveryScheduleObjects=[], blanketKegs=[] } = (orderType === ORDER_KEGS_ORDER_TYPE_BLANKET) ?
      parseBlanketKegs({
        orderKegForm: formValues,
        formValues: formValues[DELIVERY_SCHEDULE_FORM_VALUE],
        itemSkuIds,
        isEdit: true,
      }) : {}
    const orderLineItemObjects = (orderType === ORDER_KEGS_ORDER_TYPE_BLANKET) ?
      blanketKegs : parseKegs(itemSkuIds, formValues)
    const dateNeeded = formatDateForApiCall({
      date: formValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED],
    })

    const editOrderDetails = {
      ...orderDetails,
      deliveryScheduleObjects,
      orderLineItemObjects,
      dateNeeded,
    }
    dispatch(createAction(
      EDIT_OR_CANCEL_ORDER_KEGS,
      {
        customerId,
        itemSkuIds,
        formValues,
        setIsSubmitting,
        setDidSubmitSucceed,
        setDidSubmitFail,
        orderDetails: editOrderDetails,
        setSuccessMsg,
        setErrorMsg,
        setOrderObj,
        action: 'edit',
        id: orderDetails?.id,
      },
    ))
  }

  const handleCancel = () => {
    const cancelOrderDetails = {
      ...orderDetails,
      status: KEG_ORDER_STATUS_CANCELED,
    }
    setIsConfirmModalOpen(false)
    dispatch(createAction(
      EDIT_OR_CANCEL_ORDER_KEGS,
      {
        customerId,
        itemSkuIds,
        formValues,
        setIsSubmitting,
        setDidSubmitSucceed,
        setDidSubmitFail,
        orderDetails: cancelOrderDetails,
        setSuccessMsg,
        setErrorMsg,
        setOrderObj,
        action: 'cancel',
        id: orderDetails?.id,
      },
    ))
  }

  return (
    <ModalWithCatastrophicFailureErrorBoundary
      closeIcon
      size="large"
      dimmer="inverted"
      className="test-full-order-details faux-inverted-dimmer"
      onClose={() => handleModalClose()}
      open={!!props?.modalOpen}
    >
      <Modal.Header>{`Edit order: ${orderId}`}</Modal.Header>
      <Modal.Content>
        {/* {successMsg && <SuccessMessage msg={successMsg} />} */}
        {errorMsg && <ErrorMessage msg={errorMsg} />}
        <FormSubmissionDimmerOverlay
          customerId={customerId}
          isSubmitting={isSubmitting}
          didSubmitSucceed={didSubmitSucceed}
          didSubmitFail={didSubmitFail}
          dontShowAnyFailureButtons
          successMessage={successMsg}
          dontShowAnySuccessButtons
        >
          <Form className="form-top-spacing" onSubmit={null} id="orderKegsSection">
            <Grid columns={2}>
              {orderType === ORDER_KEGS_ORDER_TYPE_BLANKET &&
                <DeliveryScheduleForm
                  {...props}
                  isEdit
                  itemSkuIds={itemSkuIds}
                  dispatchFormValues={dispatchFormValues}
                  formValues={formValues}
                />
              }
              {orderType !== ORDER_KEGS_ORDER_TYPE_BLANKET && (
                <React.Fragment>
                  <Grid.Row>
                    <Grid.Column style={{ flex: '0 0 180px' }} verticalAlign="middle">
                      <Form.Field required>
                        <label htmlFor={ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED}>
                          Date Needed:
                        </label>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column style={{ flex: '1' }}>
                      <div className="ui input"> {/* CODE_COMMENTS_32 */}
                        <ReactDatePicker
                          {...getDateFieldFencingProps({
                            entireCustomersSlice,
                            customerId,
                            customerContracts,
                            formValues,
                            kegOrderDays,
                          })}
                          value={formValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]} // CODE_COMMENTS_245
                          onChange={value => {
                            // eslint-disable-next-line max-len
                            dispatchFormValues(createAction('CHANGE_FORM_VALUE', [ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED, value]))
                          }}
                        />
                        <ContentToTheRightOfAFormField>
                          <XDaysFromNow
                            minDays={formValues?.[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE] !== ORDER_KEGS_ORDER_TYPE_LOCAL
                              ? customerContracts?.minOrderLeadDays
                              : KEG_ORDERS_MIN_DAYS?.[entireCustomersSlice?.[customerId]?.businessUnitId]
                            }
                            dateNeededInputValue={formValues[ORDER_KEGS_FORM_FIELD_NAME_DATE_NEEDED]}
                            orderType={formValues?.[ORDER_KEGS_FORM_FIELD_NAME_ORDER_TYPE]}
                            minOrderLeadDays={customerContracts?.minOrderLeadDays}
                            minOrderExpediteDays={customerContracts?.minOrderExpediteDays}
                            maxOrderExpediteDays={customerContracts?.maxOrderExpediteDays}
                            orderTimeOfDayCutOff={customerContracts?.orderTimeOfDayCutOff}
                            entireCustomersSlice={entireCustomersSlice}
                            customerId={customerId}
                            kegOrderDays={kegOrderDays}
                          />
                        </ContentToTheRightOfAFormField>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  {itemSkuIds && itemSkuIds.map(itemSkuId => (
                    <Grid.Row key={itemSkuId}>
                      <Grid.Column style={{ flex: '0 0 180px' }} verticalAlign="middle">
                        <Form.Field required>
                          <label htmlFor={itemSkuId}>
                            {getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice)}
                            <ContainerTypesDifferences customerId={customerId} />
                          </label>
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column style={{ flex: '1' }}>
                        <KegQtyField
                          itemSkuId={itemSkuId}
                          localOrNonlocal={localOrNonLocal}
                          formValues={formValues}
                          dispatchFormValues={dispatchFormValues}
                          entireItemSkusSlice={entireItemSkusSlice}
                          setHasAtLeastOneNonLocalKegQtyFieldBeenBlurred={() => true}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  ))}
                </React.Fragment>
              )}
            </Grid>
            <Button
              type="button"
              onClick={() => setIsConfirmModalOpen(true)}
              id="cancel-button"
              color='red'
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <OrderAgain
              match={match}
              orderDetails={orderDetails}
            />
            <Button
              type="button"
              onClick={() => handleSubmit()}
              id="submit-button"
              color={isFormSubmittable ? 'green' : 'grey'}
              disabled={!isFormSubmittable}
            >
              Submit
            </Button>
            <Confirm
              content='Are you sure? Do you want to cancel the order?'
              open={isConfirmModalOpen}
              onCancel={() => setIsConfirmModalOpen(false)}
              onConfirm={() => handleCancel()}
            />
          </Form>
        </FormSubmissionDimmerOverlay>
      </Modal.Content>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}

export const EditOrderLink = props => {
  const { info: orderDetails } = props
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <React.Fragment>
      <TextAsPseudoLink onClick={() => setModalOpen(true)}>{orderDetails?.orderId}</TextAsPseudoLink>
      {modalOpen && <EditOrder {...props} modalOpen={modalOpen} setModalOpen={setModalOpen}/>}
    </React.Fragment>
  )
}
