import { isUndefined } from 'lodash'
import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_SUBSIDIARIES as defaultSlice,
} from '../../../constants'

import {
  withPropNormalized,
  withPropOrNormalized,
  withPropOfAllNormalized,
  withMultiplePropsOfAllNormalized,
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'
import { isTruthyAndNonEmpty } from '../../../utils'


/*
 * *****************************************************************************
 * Info about subsidiaries that isn't contained in the back-end database
 * *****************************************************************************
*/

// https://microstartap3.atlassian.net/browse/TP3-5371
const SUBSIDIARIES_THAT_SHOULD_DISPLAY_ORDER_ID_IN_ACK_INBOUND_SHIPMENTS_FORM = [
  'Kegstar UK',
  'Kegstar Ireland',
  'Kegstar NL',
  'Kegstar Australia',
]

const SUBSIDIARIES_THAT_SHOULD_DISPLAY_SHIPPED_QTYS_IN_ACK_INBOUND_SHIPMENTS_FORM = (
  SUBSIDIARIES_THAT_SHOULD_DISPLAY_ORDER_ID_IN_ACK_INBOUND_SHIPMENTS_FORM
)


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/


// usage: you can use this to get the slice for all customers or for a single
// customer:
// const entireSliceForAllCustomers = getEntireSlice(state)
// const entireSliceForSingleCustomer = getEntireSlice(state, customerId)
export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const customerType = getProp(state, customerId, 'customerType')
export const getProp = withPropNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const customerType = getPropOr(state, customerId, 'customerType', 'customer type not found')
export const getPropOr = withPropOrNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const arrayOfAllCustomerTypes = getPropOfAll(state, 'customerType')
export const getPropOfAll = withPropOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const idAndCustomerTypeOfAllCustomers = getMultiplePropsOfAll(state, 'id', 'customerType')
// returns:
// [
//   {id: 1234, customerType: MASTER},
//   {id: 1235, customerType: BRW},
//   {id: 1236, customerType: DIST},
// ]
export const getMultiplePropsOfAll = withMultiplePropsOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)


// The same selectors as above except instead of passing the whole Redux state
// in, you pass in the customers slice (the entire slice, all customers)

// usage: const customerType = getPropPassingInEntireCustomersSlice(entireCustomersSlice, customerId, 'customerType')
export const getPropPassingInEntireCustomersSlice = withPropNormalized()


/*
 * *****************************************************************************
 * Misc.
 * *****************************************************************************
*/

// https://microstartap3.atlassian.net/browse/TP3-5371
export function getIsSubsidiaryATypeThatShouldDisplayOrderIdInAckInboundShipmentsForm({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  const subsidiary = entireSubsidiariesSlice?.[subsidiaryId] // this works even if subsidiaryId is an integer
  if (!subsidiary) {
    return false // the default is for orderId to _not_ be displayed
  }
  return SUBSIDIARIES_THAT_SHOULD_DISPLAY_ORDER_ID_IN_ACK_INBOUND_SHIPMENTS_FORM.includes(subsidiary.name)
}


// https://microstartap3.atlassian.net/browse/TP3-5371
export function getIsSubsidiaryATypeThatShouldDisplayShippedQtysInAckInboundShipmentsForm({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  const subsidiary = entireSubsidiariesSlice?.[subsidiaryId] // this works even if subsidiaryId is an integer
  if (!subsidiary) {
    return false // the default is for shipped quantities to _not_ be displayed
  }
  return SUBSIDIARIES_THAT_SHOULD_DISPLAY_SHIPPED_QTYS_IN_ACK_INBOUND_SHIPMENTS_FORM.includes(subsidiary.name)
}


// https://microstartap3.atlassian.net/browse/TP3-5371
export function getIsSubsidiaryATypeThatShouldTrackForeignKegs({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  if (!subsidiaryId) {
    return true // the default is to display foreign kegs
  }
  const subsidiary = entireSubsidiariesSlice?.[subsidiaryId] // this works even if subsidiaryId is an integer
  if (!subsidiary) {
    return true // the default is to display foreign kegs
  }
  return subsidiary.useForeignKegs
}

export function getSubsidiaryKegCollarFeature({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  if (!subsidiaryId) {
    return true // the default is to display collar kegs
  }
  const subsidiary = entireSubsidiariesSlice?.[subsidiaryId] // this works even if subsidiaryId is an integer
  if (!subsidiary || isUndefined(subsidiary?.displayCollarFeatures)) {
    return true // the default is to display collar kegs
  }
  return subsidiary.displayCollarFeatures
}

export function getSubsidiaryTapDefectiveKegs({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  if (!subsidiaryId) {
    return true
  }
  const subsidiary = entireSubsidiariesSlice?.[subsidiaryId]
  if (!subsidiary || isUndefined(subsidiary?.useTapDefectiveKegs)) {
    return true
  }
  return subsidiary.useTapDefectiveKegs
}


// https://microstartap3.atlassian.net/browse/TP3-5371
export function getIsSubsidiaryATypeThatShouldTrackWoodenPallets({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  if (!subsidiaryId) {
    return true // the default is to display wooden pallets
  }
  const subsidiary = entireSubsidiariesSlice?.[subsidiaryId] // this works even if subsidiaryId is an integer
  if (!subsidiary) {
    return true // the default is to display wooden pallets
  }
  return subsidiary.trackWoodenPallets
}

export function getIsCustomerSubsidiaryExistsInAllSubsidiaries({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  return isTruthyAndNonEmpty(entireSubsidiariesSlice?.[subsidiaryId])
}

export function showProductOutOfCodeForSubsidiary({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  if (!subsidiaryId) {
    return true // the default is to display wooden pallets
  }
  const subsidiary = entireSubsidiariesSlice?.[subsidiaryId] // this works even if subsidiaryId is an integer
  if (!subsidiary || isUndefined(subsidiary?.displayOutOfCode)) {
    return true // the default is to display wooden pallets
  }

  return subsidiary?.displayOutOfCode
}

export function getSubsidiaryDisplayInvoiceHistory({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  if (!subsidiaryId) {
    return true
  }
  const subsidiary = entireSubsidiariesSlice?.[subsidiaryId]
  if (!subsidiary || isUndefined(subsidiary?.displayInvoicesWebApp)) {
    return true
  }
  return subsidiary?.displayInvoicesWebApp
}
