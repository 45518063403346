/* eslint-disable max-len */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import isPlainObj_ from 'lodash/isPlainObject'


import Routes from './components/BrewerRoutes'

import {
  getProp as getCustomerProp,
} from '../../../../redux/selectors/customers'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../../../redux/selectors/rewrite/parentChildLinks'
import {
  getEntireSlice as getEntireContractsSlice,
  getContracts,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../../redux/selectors/rewrite/users'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../redux/selectors/permissions'
import {
  getShouldReportOutboundShipmentsFormBeRendered,
  getShouldReportBuybackShipmentsFormBeRendered,
  getShouldReportSelfDistAndPubShipmentsFormBeRendered,
  getShouldReportKegFillsFormBeRendered,
  getShouldReportSelfCollectionShipmentsFormBeRendered,
} from '../../../../redux/selectors/rewrite/forms/reportShipmentsCommon/shouldReportShipmentsFormBeRendered'
import {
  getShouldReportedInventoryVsCalculatedInventoryPageBeRendered,
} from '../../../../redux/selectors/reportedInventoryVsCalculatedInventory'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_PROJECTIONS,
} from '../../../../constants/permissions'

import {
  PPF_CONTRACT_TYPES_BRW,
} from '../../../../constants'

import {
  getShouldReportInventoryFormBeRendered,
} from '../../../ReportInventory/util'
import {
  getShouldOrderKegCollarsFormBeRendered,
} from '../../../OrderKegCollars/util'

import {
  isTruthyAndNonEmpty,
} from '../../../../utils'
import { getEntireSlice as getEntireSubsidiariesSlice } from '../../../../redux/selectors/rewrite/subsidiaries'


const mapStateToProps = (state, { customerId }) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)

  const hasOrderKegsPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_KEGS,
  })
  const shouldOrderKegCollarsFormBeRendered = getShouldOrderKegCollarsFormBeRendered({
    entireCustomersSlice,
    entireRelationshipsSlice,
    entireContractsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    entireSubsidiariesSlice,
  })
  const hasAcknowledgeShipmentsPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  })
  const hasPayBalancePermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  })

  const currency = getCustomerProp(state, customerId, 'currency')

  const hasManageUsersAndContactsPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  })
  const hasAddressesPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
  })
  const hasManageProjectionsPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_PROJECTIONS,
  })

  const shouldReportOutboundShipmentsFormBeRendered = getShouldReportOutboundShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })
  // CODE_COMMENTS_231
  const {
    shouldOptionBeDisabled: shouldReportOutboundShipmentsFormOptionBeDisabled,
  } = isPlainObj_(shouldReportOutboundShipmentsFormBeRendered)
    ? shouldReportOutboundShipmentsFormBeRendered
    : {}
  const shouldReportBuybackShipmentsFormBeRendered = getShouldReportBuybackShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })
  const shouldReportSelfDistAndPubShipmentsFormBeRendered = getShouldReportSelfDistAndPubShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })
  const shouldReportKegFillsFormBeRendered = getShouldReportKegFillsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })
  const shouldReportSelfCollectionShipmentsFormBeRendered = getShouldReportSelfCollectionShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
  })

  const shouldReportInventoryFormBeRendered = getShouldReportInventoryFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    customerId,
  })
  const shouldReportedInventoryVsCalculatedInventoryPageBeRendered = getShouldReportedInventoryVsCalculatedInventoryPageBeRendered({
    state,
    customerId,
  })

  const hasNonExpiredNonConbrwPpfContract = isTruthyAndNonEmpty(getContracts({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: PPF_CONTRACT_TYPES_BRW,
    notExpiredOnly: true,
    activeStatusOnly: true,
    noFutureContracts: true,
  }))

  return {
    // Why Booleans? See CODE_COMMENTS_252
    hasOrderKegsPermissions: Boolean(hasOrderKegsPermissions),
    shouldOrderKegCollarsFormBeRendered: Boolean(shouldOrderKegCollarsFormBeRendered),
    hasAcknowledgeShipmentsPermissions: Boolean(hasAcknowledgeShipmentsPermissions),
    hasPayBalancePermissions: Boolean(hasPayBalancePermissions),
    hasManageUsersAndContactsPermissions: Boolean(hasManageUsersAndContactsPermissions),
    hasAddressesPermissions: Boolean(hasAddressesPermissions),
    hasManageProjectionsPermissions: Boolean(hasManageProjectionsPermissions),
    shouldReportOutboundShipmentsFormBeRendered: Boolean(shouldReportOutboundShipmentsFormBeRendered),
    shouldReportOutboundShipmentsFormOptionBeDisabled: Boolean(shouldReportOutboundShipmentsFormOptionBeDisabled),
    shouldReportBuybackShipmentsFormBeRendered: Boolean(shouldReportBuybackShipmentsFormBeRendered),
    shouldReportSelfDistAndPubShipmentsFormBeRendered: Boolean(shouldReportSelfDistAndPubShipmentsFormBeRendered),
    shouldReportKegFillsFormBeRendered: Boolean(shouldReportKegFillsFormBeRendered),
    shouldReportSelfCollectionShipmentsFormBeRendered: Boolean(shouldReportSelfCollectionShipmentsFormBeRendered),
    shouldReportInventoryFormBeRendered: Boolean(shouldReportInventoryFormBeRendered),
    shouldReportedInventoryVsCalculatedInventoryPageBeRendered,
    hasNonExpiredNonConbrwPpfContract,
    currency,
  }
}

export default withRouter( // CODE_COMMENTS_25
  connect(
    mapStateToProps,
  )(Routes),
)
