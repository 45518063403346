import React from 'react'
import { Loader, Icon, Button } from 'semantic-ui-react'

import PopupWithCatastrophicFailureErrorBoundary from '../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'

import {
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../../../constants'

import ContactCard from '../../../../../common-components/ContactCard'
import RevealFetchErrorDetails from '../../../../../common-components/fetchErrorDetails/RevealFetchErrorDetails'


export default ({
  customerId,
  onSubmit,
  isFetching,
  didFetchFail,
  errorDetails,
}) => {
  if (isFetching) {
    return (
      <Loader active inline size="tiny" />
    )
  }
  if (didFetchFail) {
    return (
      <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
        trigger={
          <Icon
            name="exclamation circle"
            color="red"
            link
          />
        }
        content={
          <div>
            <p>{DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR}</p>
            {/* Don't show the contact card if the customerId is not passed in */}
            <ContactCard customerId={customerId} repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE} />
            <Button
              color='blue'
              content='Retry deleting'
              onClick={onSubmit}
              fluid
            />
            <RevealFetchErrorDetails
              errorDetails={errorDetails}
            />
          </div>
        }
        position="top center"
        hoverable
        on="click"
        className="semantic-ui-error-border"
      />
    )
  }
  return (
    <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      trigger={
        <Icon
          name="trash alternate"
          color="blue"
          link
        />
      }
      content={
        <div style={{ textAlign: 'center' }}>
          Are you sure you want to delete this bank account?
          <Button
            color='blue'
            content='Yes, delete it'
            onClick={onSubmit}
            fluid
          />
        </div>
      }
      position="top center"
      hoverable
      on="click"
    />
  )
}
