import React from 'react'
import { Loader } from 'semantic-ui-react'
import { Trans } from 'react-i18next'

const LoadingPage = () => (
  <div>
    <Loader size="big" active>
      <Trans ns="common" i18nKey="Loading">
        Loading
      </Trans>
    </Loader>
  </div>
)

export default LoadingPage
