import { takeEvery } from 'redux-saga/effects'


import {
  shipmentHistorySagaCreator,
} from './shipmentHistorySagaCreator'

import {
  FETCH_INBOUND_FULL_KEG_SHIPMENT_HISTORY,
  SAVE_INBOUND_FULL_KEG_SHIPMENT_HISTORY,
} from '../../../actions/actionTypes'


const saga = shipmentHistorySagaCreator({
  saveAction: SAVE_INBOUND_FULL_KEG_SHIPMENT_HISTORY,
  historyName: 'inboundShipments',
})

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_INBOUND_FULL_KEG_SHIPMENT_HISTORY, saga],
]
