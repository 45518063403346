/**
 * The Manage Users form needs to wait to render until the customer's users
 * are fetched from the API. So what should be displayed while customer users
 * are being fetched? And what should be displayed if the fetch fails? This
 * component handles that (along with actually displaying user info once the
 * fetches have succeeded).
 */
import { connect } from 'react-redux'


import ManageUsersPage from './components'

import {
  FETCH_CUSTOMER_USERS_AND_THEIR_PERMISSIONS,
  FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER,
} from '../../../redux/actions/actionTypes'

import {
  getProp as getCurrentUserProp,
} from '../../../redux/selectors/currentUser'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../redux/selectors/rewrite/users'

import customerUsersFetchStatusesSelectors from '../../../redux/selectors/fetchStatuses/customers/customerUsers'
import prelimInfoFetchStatusesSelectors from '../../../redux/selectors/fetchStatuses/customers/preliminaryInfo'

import {
  createAggregateFetchStatuses,
} from '../../../utils'


const { getFetchStatuses: getCustomerUsersFetchStatuses } = customerUsersFetchStatusesSelectors
const { getFetchStatuses: getPrelimInfoFetchStatuses } = prelimInfoFetchStatusesSelectors


const mapStateToProps = (state, { customerId }) => {
  // Fetching users can fail for two general reasons: 1. the /users or
  // /permissions calls fail; 2. The preliminary info for an entire MASTER's
  // child customer can fail. We need to account for both of these
  // possibilities.
  const prelimInfoFetchStatuses = getPrelimInfoFetchStatuses(state, customerId)
  const customerUsersFetchStatuses = getCustomerUsersFetchStatuses(state, customerId)
  const combinedFetchStatuses = createAggregateFetchStatuses({
    fetchStatusObjects: [prelimInfoFetchStatuses, customerUsersFetchStatuses],
  })

  const didPrelimInfoFetchFail = prelimInfoFetchStatuses.didFetchFail
  const refetchActionIfFetchFailure = didPrelimInfoFetchFail
    ? FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER
    : FETCH_CUSTOMER_USERS_AND_THEIR_PERMISSIONS

  const entireUsersSlice = getEntireUsersSlice(state)
  const currentUserId = getCurrentUserProp(state, 'userId')

  return {
    ...combinedFetchStatuses,
    entireUsersSlice,
    currentUserId,
    refetchActionIfFetchFailure,
  }
}


export default connect(
  mapStateToProps,
)(ManageUsersPage)
