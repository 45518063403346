import React, { Fragment } from 'react'
import { Container, Menu, Image, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import microStarLogo from '../../../public/imagesAndIcons/logos/logo-blue.svg'
import kegCraftLogo from '../../../public/imagesAndIcons/logos/kegcraft-logo-smaller.png'
import kegStarIntLogo from '../../../public/imagesAndIcons/logos/kegstar-int-logo.png'
import {
  URL_PATHS,
  BUSINESS_UNIT_ID_TYPE_MICROSTAR,
  BUSINESS_UNIT_ID_TYPE_KEGCRAFT,
  BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT,
} from '../../../constants'


import './navbar.css'


export default ({
  fauxPrivateLayout, // CODE_COMMENTS_267
  isLogoutButtonEnabled, // CODE_COMMENTS_41
  logout,
  currentUserFirstName,
  currentUserRootCustomerName,
  businessUnitIdOfCurrentUserRootCustomer,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Container id="navbar">
      <Menu attached>
        <Logo
        businessUnitIdOfCurrentUserRootCustomer={businessUnitIdOfCurrentUserRootCustomer}
        fauxPrivateLayout={fauxPrivateLayout}
        />
        <Menu.Item as={Link} to={URL_PATHS.root.path} id="navbarHomeLink">
          <span className="undisplayed-in-phone-portrait-view">
            <Trans ns="common" i18nKey="Home">Home</Trans>
          </span>
          <Icon name="home" className="displayed-only-in-phone-portrait-view" />
        </Menu.Item>
        {!fauxPrivateLayout && ( // CODE_COMMENTS_267
        <Fragment>
          <Menu.Item as={Link} to={URL_PATHS.contactRep.path} id="navbarContactRepLink">
            <Trans ns="common" i18nKey="Contact Rep">Contact Rep</Trans>
          </Menu.Item>
          <Menu.Menu position="right">
            {currentUserRootCustomerName
              ? <Menu.Item className="borderless undisplayed-in-phone-portrait-view" style={{ fontStyle: 'italic' }} id="customizedGreeting">
                <span>{`${translate('Hi')},`}</span>
                {/* non-breaking space; {' '} doesn't work */}
                {'\u00A0'}
                <span style={{ color: 'green', fontWeight: 'bold' }}>{`${currentUserFirstName} @ ${currentUserRootCustomerName}`}</span>
              </Menu.Item>
              : null
            }
            <Menu.Item as={Link} to={URL_PATHS.help.path} id="navbarHelpLink">
              <Trans ns="common" i18nKey="Help">Help</Trans>
            </Menu.Item>
            <Menu.Item onClick={isLogoutButtonEnabled ? logout : null} id="navbarLogoutLink">
              <Trans ns="common" i18nKey="Logout">Logout</Trans>
            </Menu.Item>
          </Menu.Menu>
        </Fragment>
      )}
      </Menu>
    </Container>
  )
}


const Logo = ({
  businessUnitIdOfCurrentUserRootCustomer,
  fauxPrivateLayout,
}) => {
  if (fauxPrivateLayout) {
    // eslint-disable-next-line no-param-reassign
    businessUnitIdOfCurrentUserRootCustomer = BUSINESS_UNIT_ID_TYPE_MICROSTAR
  }
  // If the info of the root customer hasn't yet been fetched (i.e. if the app
  // is just starting up), don't render any logo at all
  if (!businessUnitIdOfCurrentUserRootCustomer) {
    return null
  }

  const logo = businessUnitIdOfCurrentUserRootCustomer === BUSINESS_UNIT_ID_TYPE_KEGCRAFT
    ? <Image src={kegCraftLogo} alt="logo" />
    // If the businessUnitId is either MicroStar or Iron Monkey, brand it as
    // MicroStar
    // If KS, bran it as KS
    : businessUnitIdOfCurrentUserRootCustomer === BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT ? <Image src={kegStarIntLogo} alt="logo" />
      : <Image src={microStarLogo} alt="logo" />
  return (
    <Link to={URL_PATHS.root.path} id="navbarHomeLinkLogo">
      <Menu.Item>
        {logo}
      </Menu.Item>
    </Link>
  )
}
