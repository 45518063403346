import React from 'react'

import values_ from 'lodash/values'


import DownloadTableAsFile from '../../../sharedComponents/DownloadTableAsFile'

import {
  createTableAndDownloadFileContentDefinitions,
} from '../../util/tableAndDownloadFileContentDefinitions'
import {
  createFilenameOfDownloadedTableFile,
  constructNonHeadingRowsOfDownloadedTableFile,
} from '../../../util/util'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
} from '../../../../../../constants/formAndApiUrlConfig/histories/historyShared'


export default ({
  customerId,
  customerType,
  invoicesObject,
  mostRecentlySubmittedFormValues,
}) => {
  if (!mostRecentlySubmittedFormValues) {
    return <DownloadTableAsFile data={null} filename={null} />
  }

  const filename = createFilename(mostRecentlySubmittedFormValues)

  const data = createFileData(
    invoicesObject,
    customerId,
    customerType,
  )

  return (
    <DownloadTableAsFile
      data={data}
      filename={filename}
    />
  )
}


/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

function createFilename(mostRecentlySubmittedFormValues) {
  const historyFormStartDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_START_DATE]
  const historyFormEndDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_END_DATE]

  return createFilenameOfDownloadedTableFile(
    'invoices',
    historyFormStartDate,
    historyFormEndDate,
  )
}


// array of arrays, with first array being column headings
function createFileData(
  invoicesObject,
  customerId,
  customerType,
) {
  const definitions = createTableAndDownloadFileContentDefinitions({
    tableOrDownloadFile: 'downloadFile',
    customerId,
    customerType,
  })

  let arrayOfInvoicesObjects = values_(invoicesObject)
  const sortFunction = definitions.content.find(
    o => o.heading === definitions.defaultSortColumn).customSortInfo.sortFunction
  arrayOfInvoicesObjects = sortFunction(arrayOfInvoicesObjects)

  const nonHeadingRows = constructNonHeadingRowsOfDownloadedTableFile(arrayOfInvoicesObjects, definitions.content)

  return [
    definitions.content.map(o => o.heading), // headings row
    ...nonHeadingRows,
  ]
}
