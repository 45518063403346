import React from 'react'
import { Container, Segment, Header } from 'semantic-ui-react'

import { Trans } from 'react-i18next'
import FailedToLoadCustomer from '../../common-components/FailedToLoadCustomer'

export default ({ fetchPrelimInfoOfAllCustomers }) => (
  <Container>
    <Segment>
      <Header as="h2" color="grey">
        <Trans ns="common" i18nKey="Failed to Fetch Preliminary Info">
          Failed to Fetch Preliminary Info
        </Trans>
      </Header>
      <FailedToLoadCustomer
        refetch={fetchPrelimInfoOfAllCustomers}
      />
    </Segment>
  </Container>
)
