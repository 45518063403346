import React from 'react'

import remove_ from 'lodash/remove'
import findIndex_ from 'lodash/findIndex'
import values_ from 'lodash/values'
import cloneDeep_ from 'lodash/cloneDeep'
import range_ from 'lodash/range'

import { useTranslation } from 'react-i18next'
import DownloadTableAsFile from '../../../sharedComponents/DownloadTableAsFile'

import {
  getInfoDefinitionsForCollarOrderFullDetails,
  createDisplayedDeliveryDate,
  createDisplayedProNumber,
  createDisplayedOrderComments,
  getCollarPlateFlavor,
  getCollarPlatePartNumber,
  getCollarPlateNumberOfColors,
  getAllCollarOrderObjectsToBeIncludedInHistoryTable,
} from '../../../util/collarOrderHistory'
import {
  createFilenameOfDownloadedTableFile,
  createRowDefinitionsOfAddressForDownloadedTableFile,
  constructNonHeadingRowsOfDownloadedTableFile,
} from '../../../util/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'

import {
  createFakeStateObject,
} from '../../../../../../redux/reducers/util/fakeState'

import {
  COLLAR_ORDER_FULL_DETAILS_LABEL_DELIVERY_DATE,
  COLLAR_ORDER_FULL_DETAILS_LABEL_COMMENTS,
  COLLAR_ORDER_FULL_DETAILS_DELIVERY_ADDRESS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/collarOrderHistory'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  FIELD_LABEL_CUSTOMER_PO_NUM,
} from '../../../../../../constants/formAndApiUrlConfig/orderKegCollars'

import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_COLLAR_PLATES,
} from '../../../../../../constants'

import {
  isTruthyAndNonEmpty,
  convertArrOfObjsOrSingleObjToObjOfSubObjs,
} from '../../../../../../utils'


export default ({
  allCollarOrderObjects,
  allAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
  allCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
  mostRecentlySubmittedFormValues,
}) => {
  const { t: translate } = useTranslation('common')
  if (
    !mostRecentlySubmittedFormValues
  ) {
    return <DownloadTableAsFile data={null} filename={null} />
  }

  if (
    !allCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile
  ) {
    return <DownloadTableAsFile loading loadingTooltipText={`${translate('Fetching collar plates')}...`} />
  }

  const allCollarOrderObjectsToBeIncludedInHistoryTable = getAllCollarOrderObjectsToBeIncludedInHistoryTable(
    allCollarOrderObjects,
  )

  const filename = createFilename(mostRecentlySubmittedFormValues)


  const data = createFileData(
    allCollarOrderObjectsToBeIncludedInHistoryTable,
    allAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
    allCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
  )

  return (
    <DownloadTableAsFile
      data={data}
      filename={filename}
    />
  )
}


/*
 * *****************************************************************************
 * Create filename
 * *****************************************************************************
*/

function createFilename(mostRecentlySubmittedFormValues) {
  const historyFormStartDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_START_DATE]
  const historyFormEndDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_END_DATE]
  const historyFormOrderStatus = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_STATUS]

  return createFilenameOfDownloadedTableFile(
    'keg orders',
    historyFormStartDate,
    historyFormEndDate,
    { status: historyFormOrderStatus },
  )
}


/*
 * *****************************************************************************
 * Create file data
 * *****************************************************************************
*/

// array of arrays, with first array being column headings
function createFileData(
  allCollarOrderObjectsToBeIncludedInHistoryTable,
  allAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
  allCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
) {
  // If the history form query returned no results, return an empty array. This
  // is crucially important because the addCollarPlatesDefinitions function
  // below will throw an error if the allCollarOrderObjectsToBeIncludedInHistoryTable is empty/null.
  if (!isTruthyAndNonEmpty(allCollarOrderObjectsToBeIncludedInHistoryTable)) { return [] }

  const originalDataDefinitions = getInfoDefinitionsForCollarOrderFullDetails()
  let dataDefinitions = cloneDeep_(originalDataDefinitions)

  dataDefinitions = swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions)
  dataDefinitions = swapDeliveryDateDefinition(dataDefinitions)
  dataDefinitions = swapAddressDefinition(
    allAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
    dataDefinitions,
  )
  dataDefinitions = addCollarPlatesDefinitions(
    allCollarOrderObjectsToBeIncludedInHistoryTable,
    allCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
    dataDefinitions,
  )

  let arrayOfOrderObjects = values_(allCollarOrderObjectsToBeIncludedInHistoryTable)
  arrayOfOrderObjects = createApiDateSortFunctionForHistoryTable({ datePropName: 'dateOrdered' })(arrayOfOrderObjects)
  const mainFileRows = constructNonHeadingRowsOfDownloadedTableFile(arrayOfOrderObjects, dataDefinitions)

  return [
    dataDefinitions.map(o => o.heading), // headings row
    ...mainFileRows,
  ]
}


// CODE_COMMENTS_111
function swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions) {
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === FIELD_LABEL_CUSTOMER_PO_NUM),
    1,
    { heading: FIELD_LABEL_CUSTOMER_PO_NUM, cellContent: createDisplayedProNumber() },
  )

  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === COLLAR_ORDER_FULL_DETAILS_LABEL_COMMENTS),
    1,
    { heading: COLLAR_ORDER_FULL_DETAILS_LABEL_COMMENTS, cellContent: createDisplayedOrderComments() },
  )

  // CODE_COMMENTS_109
  return dataDefinitions
}


// CODE_COMMENTS_107
function swapDeliveryDateDefinition(dataDefinitions) {
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === COLLAR_ORDER_FULL_DETAILS_LABEL_DELIVERY_DATE),
    1,
    {
      heading: COLLAR_ORDER_FULL_DETAILS_LABEL_DELIVERY_DATE,
      cellContent: row => (createDisplayedDeliveryDate(
        row,
        false, // Don't prepend with 'appx.', even if Ground shipping
      )),
    },
  )

  // CODE_COMMENTS_109
  return dataDefinitions
}

function swapAddressDefinition(
  allAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
  dataDefinitions,
) {
  // The address definition needs to be swapped out with several fields that
  // break out the address into its individual parts
  remove_(dataDefinitions, { heading: COLLAR_ORDER_FULL_DETAILS_DELIVERY_ADDRESS })

  const addressDefsForDownloadedFile = createRowDefinitionsOfAddressForDownloadedTableFile(
    allAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
    'shippingAddressId',
    'Delivery',
  )

  // NOTE: although this returns a new array, the items of the array are the
  // same as what's passed in: there's no deep copying going on here.
  return [
    ...dataDefinitions,
    ...addressDefsForDownloadedFile,
  ]
}


function addCollarPlatesDefinitions(
  allCollarOrderObjectsToBeIncludedInHistoryTable,
  allCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
  dataDefinitions,
) {
  const numCollarPlatesOfOrderWithMostCollarPlates = determineNumCollarPlatesOfOrderWithMostCollarPlates(
    allCollarOrderObjectsToBeIncludedInHistoryTable,
  )

  const state = createFakeStateObject([
    {
      path: [REDUCER_NAMES_ENTITIES, REDUCER_NAMES_ENTITIES_COLLAR_PLATES],
      value: convertArrOfObjsOrSingleObjToObjOfSubObjs(
        allCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
      ),
    },
  ])

  const collarPlateDefs = range_(numCollarPlatesOfOrderWithMostCollarPlates).reduce(
    (acc, collarPlateIndex) => ([
      ...acc,
      ...createCollarPlatesDefinitionsForOneCollarPlateIndex(state, collarPlateIndex),
    ]),
    [],
  )

  // NOTE: although this returns a new array, the items of the array are the
  // same as what's passed in: there's no deep copying going on here.
  return [
    ...dataDefinitions,
    ...collarPlateDefs,
  ]
}

function createCollarPlatesDefinitionsForOneCollarPlateIndex(state, collarPlateIndex) {
  const prefix = `Collar Plate ${collarPlateIndex+1}`
  return [
    { heading: `${prefix} Name`, cellContent: row => getCollarPlatePropIfCollarPlateIndexExistsInCollarOrderObject(row, collarPlateIndex, state, getCollarPlateFlavor) },
    { heading: `${prefix} Quantity`, cellContent: row => (row.collarPlates[collarPlateIndex] ? row.collarPlates[collarPlateIndex].quantity : null) },
    { heading: `${prefix} Part Number`, cellContent: row => getCollarPlatePropIfCollarPlateIndexExistsInCollarOrderObject(row, collarPlateIndex, state, getCollarPlatePartNumber) },
    { heading: `${prefix} Number of Colors`, cellContent: row => getCollarPlatePropIfCollarPlateIndexExistsInCollarOrderObject(row, collarPlateIndex, state, getCollarPlateNumberOfColors) },
  ]
}

// Remember that we're checking each and every row for n collarPlates, n being
// the number of collar plates that the collar order with the most colllar
// plates in the table has. If our table has 2 rows and one row is an order for
// 2 collar plates and the other is an order for just 1 collar plate, our
// downloaded file will have fields for "Collar Plate 1" and "Collar Plate 2".
// The row of the order with just 1 collar plate should have all blank "Collar
// Plate 2" cells.
function getCollarPlatePropIfCollarPlateIndexExistsInCollarOrderObject(row, collarPlateIndex, state, getFunc) {
  const collarOrderPlateLinkObj = row.collarPlates[collarPlateIndex] // CODE_COMMENTS_99

  if (!collarOrderPlateLinkObj) { return null }

  const collarPlateId = collarOrderPlateLinkObj.collarPlateId
  return getFunc(state, collarPlateId)
}


function determineNumCollarPlatesOfOrderWithMostCollarPlates(allCollarOrderObjectsToBeIncludedInHistoryTable) {
  const numberOfCollarPlatesOfEachOrder = values_(allCollarOrderObjectsToBeIncludedInHistoryTable).map(
    individualOrderObj => individualOrderObj.collarPlates.length,
  )
  return Math.max(...numberOfCollarPlatesOfEachOrder)
}
