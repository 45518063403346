import React from 'react'

import EditAndDeleteButtonsInTables from '../../../../../../EditAndDeleteButtonsInTables'


export default ({
  isindividualItemEditable,
  itemObj,
  itemType,
  customerId,
  operatingContractBrewerCustomerId,
  dontIncludeDeleteButton,
}) => (
  isindividualItemEditable
    ? <EditAndDeleteButtonsInTables
      itemType={itemType}
      itemObj={itemObj}
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      dontIncludeDeleteButton={dontIncludeDeleteButton}
    />
    : null
)
