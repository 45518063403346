import React, { useEffect } from 'react'

import Form from '../containers/Form'
import NoShipmentsMessage from './NoShipmentsMessage'

export default props => {
  const {
    unacknowledgedShipmentsQty,
    setAckInboundShipmentsReminderPopupHasHadTheChanceToRender,
  } = props

  // Imagine the user navigates directly to
  // tap.microstarlogistics.com/acknowledge-inbound-shipments rather than going
  // through the Dashboard. There are some shipments that need to be
  // acknowledged, but the user decides they want to do something else first so
  // they navigate back to the dashboard. We don't want to show them the "You
  // have unacknowledged shipments!" popup, they just came from the Ack Inbound
  // Shipments form, they know this information already. This useEffect prevents
  // that popup from rendering.
  useEffect(
    () => {
      setAckInboundShipmentsReminderPopupHasHadTheChanceToRender()
    },
    [setAckInboundShipmentsReminderPopupHasHadTheChanceToRender],
  )

  return (
    unacknowledgedShipmentsQty > 0
      ? <Form {...props} />
      : <NoShipmentsMessage
        {...props}
      />
  )
}
