import {
  createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditOutboundShipments,
} from './outboundShipments'
import {
  createFieldArraysAndCustomersFieldsDropdownOptionsForReportBuybackShipments,
} from './buybackShipments'
import {
  createFieldArraysAndCustomersFieldsDropdownOptionsForReportSelfDistAndPubShipments,
} from './selfDistAndPubShipments'
import {
  createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditSelfCollectionShipments,
} from './selfCollectionShipments'
import {
  createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditKegFills,
} from './kegFills'

export function createFieldArraysAndCustomersFieldsDropdownOptions(props) {
  const {
    formType,
    isEditForm,
    fieldArrayNameIfIsEditForm,
  } = props
  let f
  if (formType === 'outbounds') {
    f = createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditOutboundShipments
  } else if (formType === 'buybacks') {
    f = createFieldArraysAndCustomersFieldsDropdownOptionsForReportBuybackShipments
  } else if (formType === 'selfDistAndPubs') {
    f = createFieldArraysAndCustomersFieldsDropdownOptionsForReportSelfDistAndPubShipments
  } else if (formType === 'selfCollection') {
    f = createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditSelfCollectionShipments
  } else { // Must be 'kegFills'
    f = createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditKegFills
  }
  const fieldArraysAndCustomersFieldsDropdownOptions = f(props)
  if (isEditForm) {
    // The fieldArraysAndCustomersFieldsDropdownOptions array should only have
    // one item in it, representing one field array, because any edit form will
    // only be targeting one shipment and therefore will render only one field
    // array.
    return fieldArraysAndCustomersFieldsDropdownOptions.filter(o => o.fieldArrayName === fieldArrayNameIfIsEditForm)
  }
  return fieldArraysAndCustomersFieldsDropdownOptions
}
