import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import throttle_ from 'lodash/throttle'

import {
  SAVE_TIME_OF_MOST_RECENT_USER_ACTIVITY,
} from '../../redux/actions/actionTypes'
import createAction from '../../redux/actions/createAction'

export default function useRecordTimeOfMostRecentUserActivity() {
  const dispatch = useDispatch()
  useEffect(
    () => {
      function recordUserActivityTimestamp() {
        dispatch(createAction(
          SAVE_TIME_OF_MOST_RECENT_USER_ACTIVITY,
          Date.now(), // Unix Epoch milliseconds (int)
        ))
      }
      const throttledRecordUserActivityTimestamp = throttle_(
        recordUserActivityTimestamp,
        // We only need a very rough estimate of when the most recent user
        // activity was, so only run this once every 60 seconds. Why not run it
        // every second, which surely would not be any more real strain on an
        // event loop that can run hundreds of times per second on modern
        // hardware? Because we don't want to swamp Redux Dev Tools with
        // SAVE_TIME_OF_MOST_RECENT_USER_ACTIVITY events when developing the
        // app.
        60000,
      )
      document.addEventListener('mousemove', throttledRecordUserActivityTimestamp)
      document.addEventListener('keydown', throttledRecordUserActivityTimestamp)
      return function cleanup() {
        document.removeEventListener('mousemove', throttledRecordUserActivityTimestamp)
        document.removeEventListener('keydown', throttledRecordUserActivityTimestamp)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}
