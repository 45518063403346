import editContactSagas from './editContact'
import deleteContactSagas from './deleteContact'
import manageNoLoginContact from './manageNoLoginContact'

// CODE_COMMENTS_11
export default [
  ...editContactSagas,
  ...deleteContactSagas,
  ...manageNoLoginContact,
]
