/**
 * CODE_COMMENTS_131
 */
import React from 'react'


import ActualCatastrophicErrorPage from '../subfeatures/ActualCatastrophicErrorPage'


export default ({
  hasCatastrophicFailureHappened,
  children,
}) => (
  hasCatastrophicFailureHappened
    ? <ActualCatastrophicErrorPage />
    : children
)
