import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

import RouteNotFound from '../../../../RouteNotFound'
import PrivateRoute from '../../../../../common-components/routes/PrivateRoute'

import PrelimInfoVerification from '../../../../PrelimInfoVerification'

import Dashboard from './MasterAccountDashboard'
import ManageUsers from '../../../../ManageUsers'
import OperateForOtherCustomer from '../../../../OperateForOtherCustomer'
import ReportedInventoryVsCalculatedInventory from '../../../../ReportedInventoryVsCalculatedInventory'

import {
  URL_PATHS,
} from '../../../../../constants'

import { createRelativePath } from '../../../../../utils'
import InvoiceHistory from '../../../../History/individualTabs/InvoiceHistory'
import UiContainer from '../../../../../common-components/UiContainer'
import { ManageAllContactsContainer } from '../../../../ManageContactsAndSubscriptions/'

const ViewAllInvoices = UiContainer({
  headerText: 'Invoices',
  containerId: 'viewAllInvoicesSection',
})(InvoiceHistory)

// CODE_COMMENTS_26
const getRoutesList = ({
  match,
  customerId,
  hasManageUsersAndContactsPermissions,
  hasViewInvoicesPermissions,
  shouldReportedInventoryVsCalculatedInventoryPageBeRendered,
}) => ([
  <PrivateRoute
    exact
    key={URL_PATHS.root.path}
    path={createRelativePath(match, URL_PATHS.root.path)}
    render={
      routeProps => (
        <PrelimInfoVerification
          componentToRenderIfVerified={Dashboard}
          customerId={customerId}
          {...routeProps}
        />
      )
    }
  />,
  ...(
    hasManageUsersAndContactsPermissions
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.users.path}
          path={createRelativePath(match, URL_PATHS.users.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ManageUsers}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    hasManageUsersAndContactsPermissions ?
      [
        <PrivateRoute
          exact
          key={URL_PATHS.manageAllContacts.path}
          path={createRelativePath(match, URL_PATHS.manageAllContacts.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ManageAllContactsContainer}
                customerId={customerId}
                masterAccount
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    hasViewInvoicesPermissions ?
      [
        <PrivateRoute
          exact
          key={URL_PATHS.viewAllInvoices.path}
          path={createRelativePath(match, URL_PATHS.viewAllInvoices.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ViewAllInvoices}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    shouldReportedInventoryVsCalculatedInventoryPageBeRendered
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.reportedInventoryVsCalculatedInventory.path}
          path={createRelativePath(match, URL_PATHS.reportedInventoryVsCalculatedInventory.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ReportedInventoryVsCalculatedInventory}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  <PrivateRoute
    key={URL_PATHS.operateFor.path}
    path={createRelativePath(match, URL_PATHS.operateFor.path)}
    render={
      routeProps => (
        <OperateForOtherCustomer
          operatorCustomerId={customerId}
          {...routeProps}
        />
      )
    }
  />,
])

export default withRouter(props => (
  <Switch>
    {getRoutesList(props)}
    <RouteNotFound />
  </Switch>
))
