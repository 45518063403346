/* eslint-disable max-len */
import {
  gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist
  as brwWithDefaultBrwContractNotReportingOnAnyConbrwContractsINVENTORYOBJSDONTEXIST,
  gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist
  as brwWithDefaultBrwContractNotReportingOnAnyConbrwContractsINVENTORYOBJSEXIST,
} from './_brwWithDefaultBrwContractNotReportingOnAnyConbrwContracts'

import {
  gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist
  as brwWithDefaultBrwContractReportingOnOneOrMoreConbrwContractsINVENTORYOBJSDONTEXIST,
  gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist
  as brwWithDefaultBrwContractReportingOnOneOrMoreConbrwContractsINVENTORYOBJSEXIST,
} from './_brwWithDefaultBrwContractReportingOnOneOrMoreConbrwContracts'

import {
  gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist
  as brwWithNoDefaultBrwContractReportingOnOneOrMoreConbrwContractsINVENTORYOBJSDONTEXIST,
  gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist
  as brwWithNoDefaultBrwContractReportingOnOneOrMoreConbrwContractsINVENTORYOBJSEXIST,
} from './_brwWithNoDefaultBrwContractReportingOnOneOrMoreConbrwContracts'

import {
  gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist
  as brwPubOnlyINVENTORYOBJSDONTEXIST,
  gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist
  as brwPubOnlyINVENTORYOBJSEXIST,
} from './_brwPubOnly'

import {
  gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist
  as conbrwNotReportingOnAnyConbrwContractsINVENTORYOBJSDONTEXIST,
  gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist
  as conbrwNotReportingOnAnyConbrwContractsINVENTORYOBJSEXIST,
} from './_conbrwNotReportingOnAnyConbrwContracts'

import {
  gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist
  as conbrwReportingOnOneOrMoreConbrwContractsINVENTORYOBJSDONTEXIST,
  gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist
  as conbrwReportingOnOneOrMoreConbrwContractsINVENTORYOBJSEXIST,
} from './_conbrwReportingOnOneOrMoreConbrwContracts'

import {
  gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist
  as distributorINVENTORYOBJSDONTEXIST,
  gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist
  as distributorINVENTORYOBJSEXIST,
} from './_distributor'

import {
  gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist
  as distributorNoLoginRequiredINVENTORYOBJSDONTEXIST,
  gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist
  as distributorNoLoginRequiredINVENTORYOBJSEXIST,
  gatherConstellationReportObjsWhenIndividualObjsFromApiDontYetExist
  as distributorNoLoginRequiredCONSTELLATIONOBJSDONTEXIST,
} from './_distributorNoLoginRequired'

import {
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED,
  CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY_MAINTENANCE_FACILITY,
} from '../constants'


const INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST = 'INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST'
const INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST = 'INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST'


export function returnFuncToGatherInventoryReportObjsByCustomerType({
  [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
  isEditForm,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
}) {
  const doInventoryReportObjsFromApiAlreadyExistFlag = (
    isEditForm ||
    isThisForDisplayingAnAlreadyCreatedInventoryReport
  )
    ? INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST
    : INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST

  const m = {
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: brwWithDefaultBrwContractNotReportingOnAnyConbrwContractsINVENTORYOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: brwWithDefaultBrwContractNotReportingOnAnyConbrwContractsINVENTORYOBJSEXIST,
    },
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: brwWithDefaultBrwContractReportingOnOneOrMoreConbrwContractsINVENTORYOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: brwWithDefaultBrwContractReportingOnOneOrMoreConbrwContractsINVENTORYOBJSEXIST,
    },
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: brwWithNoDefaultBrwContractReportingOnOneOrMoreConbrwContractsINVENTORYOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: brwWithNoDefaultBrwContractReportingOnOneOrMoreConbrwContractsINVENTORYOBJSEXIST,
    },
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: brwPubOnlyINVENTORYOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: brwPubOnlyINVENTORYOBJSEXIST,
    },
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: conbrwNotReportingOnAnyConbrwContractsINVENTORYOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: conbrwNotReportingOnAnyConbrwContractsINVENTORYOBJSEXIST,
    },
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: conbrwReportingOnOneOrMoreConbrwContractsINVENTORYOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: conbrwReportingOnOneOrMoreConbrwContractsINVENTORYOBJSEXIST,
    },
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: distributorINVENTORYOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: distributorINVENTORYOBJSEXIST,
    },
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: distributorINVENTORYOBJSDONTEXIST, // CODE_COMMENTS 287
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: distributorINVENTORYOBJSEXIST, // CODE_COMMENTS 287
    },
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_MAINTENANCE_FACILITY]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: distributorINVENTORYOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: distributorINVENTORYOBJSEXIST,
    },
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: distributorNoLoginRequiredINVENTORYOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: distributorNoLoginRequiredINVENTORYOBJSEXIST,
    },
    [CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED]: {
      [INVENTORY_REPORT_OBJS_FROM_API_DONT_YET_EXIST]: distributorNoLoginRequiredCONSTELLATIONOBJSDONTEXIST,
      [INVENTORY_REPORT_OBJS_FROM_API_ALREADY_EXIST]: distributorNoLoginRequiredINVENTORYOBJSEXIST,
    },
  }
  return m[customerTypeForReportInventory][doInventoryReportObjsFromApiAlreadyExistFlag]
}
