import React from 'react'
// import { CSVLink } from 'react-csv'
import { Icon } from 'semantic-ui-react'


import { CSVLink } from '../../common-components/react-csv-fixed'
import StyledPopup from '../../common-components/StyledPopup'
import './styles.css'


// data is an array of arrays, with the first array being the field titles.
export default ({
  data,
  headers, // optional; pass in an array of strings to control column order
  filename,
  loading,
  loadingTooltipText,
  wrappingDivProps,
  iconProps,
}) => {
  if (loading) {
    const loadingButton = (
      <Icon.Group>
        <Icon loading size='big' name='circle notch' color="grey" />
        <Icon name='file excel outline' color="grey" style={{ paddingRight: '2px' }} />
      </Icon.Group>
    )
    if (loadingTooltipText) {
      return (
        <StyledPopup
          trigger={
            // For whatever reason, we can't have style={{ display: 'flex',
            // alignItems: 'center' }} on the wrapping div of the loading
            // button, otherwise the popup doesn't show up. And serendipitously,
            // it seems we don't need such styles on the Loading button in order
            // for it to be centered vertically.
            <div>
              {loadingButton}
            </div>
          }
          content={loadingTooltipText}
        />
      )
    }
    return (
      <WrappedDownloadButton
        {...wrappingDivProps}
      >
        {loadingButton}
      </WrappedDownloadButton>
    )
  }

  // If the form hasn't yet been successfully submitted
  if (!filename) { return null }
  // If the form query returned no results
  if (data.length <= 1) { return null }

  return (
    <WrappedDownloadButton
      {...wrappingDivProps}
    >
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
      >
        <Icon
          name="file excel outline"
          color="green"
          size="big"
          link // changes the color slightly when hovering over
          className="download-csv-icon"
          {...iconProps}
        />
      </CSVLink>
    </WrappedDownloadButton>
  )
}


const WrappedDownloadButton = ({
  children,
  ...rest
}) => (
  <div
    // for proper vertical centering
    style={{ display: 'flex', alignItems: 'center' }}
    {...rest}
  >
    {children}
  </div>
)
