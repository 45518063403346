import moment from 'moment'
import {
  REDUCER_NAMES_INBOUND_UNACKNOWLEDGED_SHIPMENTS as defaultSlice,
  TENTATIVE_HOLD_DAYS,
} from '../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../utils'

import {
  withPropSimple,
  withPropOfAllSimple,
  getEntireSlice as commonGetEntireSlice,
} from './higherOrderFunctions'
import {
  getEntireSlice as getEntireInboundUnacknowledgedShipmentsSlice,
} from './rewrite/inboundUnacknowledgedShipments'
import { getProp as getCustomerProp } from './customers'


// usage: getProp(state, customerId, shipmentId, 'goodPalletsOrigin')
export const getProp = withPropSimple(defaultSlice, 'byCustomerId')
// usage: getPropOfAll(state, customerId, 'goodPalletsOrigin')
export const getPropOfAll = withPropOfAllSimple(defaultSlice, 'byCustomerId')

// You can use this to get several levels of slices:
//
// getEntireSlice(state) // all shipment objects of all customers
// getEntireSlice(state, customerId) // all shipment objects of one customer
// getEntireSlice(state, customerId, shipmentUuid) // one shipment object
export const getEntireSlice = commonGetEntireSlice(defaultSlice, 'byCustomerId')


export const getHasAnyInboundUnacknowledgedShipments = (state, customerId) => (
  isTruthyAndNonEmpty(getEntireSlice(state, customerId))
)

export const isAcknowledgementHoldWarningRequired = (state, customerId) => {
  const hasAnyInboundUnacknowledgedShipments = isTruthyAndNonEmpty(getEntireSlice(state, customerId))
  if (!hasAnyInboundUnacknowledgedShipments) {
    return false
  }
  const inboundUnacknowledgedShipments = getEntireInboundUnacknowledgedShipmentsSlice(state, customerId)
  const unacknowledgedShipmentsMoreThan5Days = Object.values(inboundUnacknowledgedShipments || {})?.find(obj => {
    const currentDate = moment(new Date())
    const deliveryDate = moment(obj.dateReceived || obj.plannedDeliveryDate)
    const difference = moment.duration(currentDate.diff(deliveryDate)).asDays()
    if (Number(difference) > 5) {
      return true
    }
    return false
  }) || {}
  return isTruthyAndNonEmpty(unacknowledgedShipmentsMoreThan5Days)
}

export const isReportingHoldWarningRequired = (state, customerId) => {
  const reportingHoldWarningDate = getCustomerProp(state, customerId, 'reportingHoldWarningDate')
  // const { reportingHoldWarningDate } = customerSlice || {}

  if (!reportingHoldWarningDate) return false

  const currentDate = moment(new Date())
  const reportingWarningMomentDate = moment(reportingHoldWarningDate)
  const difference = moment.duration(currentDate.diff(reportingWarningMomentDate)).asDays()
  return Number(difference) >= 0 ? difference + TENTATIVE_HOLD_DAYS : false
}
