/**
 * semantic-ui-react doesn't have a built-in dismissable message.
 * Their recommendation is to write one yourself:
 * https://react.semantic-ui.com/collections/message#message-example-dismissible-block
 */

import React, { Component } from 'react'
import { Message } from 'semantic-ui-react'

class DismissableMessage extends Component {
  state = { visible: true }

  handleDismiss = () => {
    this.setState({ visible: false })
    if (this.props.onDismiss) { this.props.onDismiss() }
  }

  render() {
    if (this.state.visible) {
      return (
        <Message
          onDismiss={this.handleDismiss}
          {...this.props}
        >
          {this.props.children}
        </Message>
      )
    }
    return null
  }
}

export default DismissableMessage
