import React from 'react'
import { Loader, Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'


export default ({
  message,
  minHeight=300,
}) => {
  const { t: translate } = useTranslation('common')
  // minHeight must be at least 65, because that's the approximate minimum
  // height of the loader.

  // eslint-disable-next-line no-param-reassign
  minHeight = minHeight < 65 ? 65 : minHeight
  // Why wrap the <Loader> in a <Segment> rather than a simple <div>? Because
  // the <Loader> will escape a <div> and put itself in the middle of the page.
  // It won't escape a <Segment>.
  return (
    <Segment basic style={{ minHeight: `${minHeight}px` }}>
      <Loader size="large" active>
        {translate(message)}
      </Loader>
    </Segment>
  )
}
