import {
  SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED,
  UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED,
  SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED,
  UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED,
} from '../actions/actionTypes'

const initialState = {
  haveTasksToPerformImmediatelyAfterLoginBeenAttempted: false,
  haveTasksToPerformImmediatelyAfterLoginSucceeded: false,
}

export default (state=initialState, action) => {
  switch (action.type) {
    case SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED:
      return {
        ...state,
        haveTasksToPerformImmediatelyAfterLoginBeenAttempted: true,
      }

    case UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED:
      return {
        ...state,
        haveTasksToPerformImmediatelyAfterLoginBeenAttempted: false,
      }

    case SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED:
      return {
        ...state,
        haveTasksToPerformImmediatelyAfterLoginSucceeded: true,
      }

    case UNSET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED:
      return {
        ...state,
        haveTasksToPerformImmediatelyAfterLoginSucceeded: false,
      }

    default:
      return state
  }
}
