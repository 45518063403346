import find_ from 'lodash/find'
import get_ from 'lodash/get'

import {
  getItemSkusDefaultSortOrder,
  getContainerTypeFromItemSkuId,
} from '../../../../redux/selectors/rewrite/itemSkus'

import {
  sortArrayByTemplateArray,
} from '../../../../utils'

import {
  CONTAINER_TYPES_HB,
  CONTAINER_TYPES_SB,
  CONTAINER_TYPES_QB,

  NUMBER_OF_HB_KEGS_THAT_FIT_ONTO_ONE_PALLET,
  NUMBER_OF_QB_KEGS_THAT_FIT_ONTO_ONE_PALLET,
  NUMBER_OF_SB_KEGS_THAT_FIT_ONTO_ONE_PALLET,

  BUSINESS_UNIT_ID_TYPE_MICROSTAR,
  BUSINESS_UNIT_ID_TYPE_KEGCRAFT,
  CONTAINER_TYPES_L20,
  CONTAINER_TYPES_G9,
  CONTAINER_TYPES_L50,
  CONTAINER_TYPES_L30,
  BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT,
  NUMBER_OF_L30_KEGS_THAT_FIT_ONTO_ONE_PALLET,
  NUMBER_OF_L50_KEGS_THAT_FIT_ONTO_ONE_PALLET,
  NUMBER_OF_L20_KEGS_THAT_FIT_ONTO_ONE_PALLET,
  NUMBER_OF_G9_KEGS_THAT_FIT_ONTO_ONE_PALLET,
} from '../../../../constants'

import microStarHalfBarrelImage from '../../../../public/imagesAndIcons/kegs/hb-microstar-branding.jpg'
import microStarSixthBarrelImage from '../../../../public/imagesAndIcons/kegs/sb-microstar-branding.jpg'
import kegCraftHalfBarrelImage from '../../../../public/imagesAndIcons/kegs/hb-kegcraft-branding.jpg'
import kegCraftSixthBarrelImage from '../../../../public/imagesAndIcons/kegs/sb-kegcraft-branding.jpg'
// import quarterBarrelSubstitutionImage from '../../../../public/imagesAndIcons/kegs/qb-substitute-image.jpg'
import microStarQuaterBarrelImage from '../../../../public/imagesAndIcons/kegs/qb-microstar-branding.jpg'
import kegStar9G from '../../../../public/imagesAndIcons/kegs/ks/9G.jpg'
import kegStarL20 from '../../../../public/imagesAndIcons/kegs/ks/20L.jpg'
import kegStarL30 from '../../../../public/imagesAndIcons/kegs/ks/30L.jpg'
import kegStarL50 from '../../../../public/imagesAndIcons/kegs/ks/50L.jpg'


export const containerTypesInfo = [
  {
    containerType: CONTAINER_TYPES_HB,
    name: 'Half Barrel',
    volumeInGallons: 15.5,
    volumeInLiters: 58.67,
    heightInInches: 23.3,
    diameterInInches: [16.1, 17.1],
    weightInLbsFull: 165,
    maxQtyOnSinglePallet: NUMBER_OF_HB_KEGS_THAT_FIT_ONTO_ONE_PALLET,
    alsoKnownAs: [
      '1/2 BBL',
      'Half',
      'Barrel',
      'Keg',
      'HB',
    ],
  },
  {
    containerType: CONTAINER_TYPES_SB,
    name: 'Sixth Barrel',
    volumeInGallons: 5.16,
    volumeInLiters: 19.8,
    heightInInches: 23.3,
    diameterInInches: 9.25,
    weightInLbsFull: 58,
    maxQtyOnSinglePallet: NUMBER_OF_SB_KEGS_THAT_FIT_ONTO_ONE_PALLET,
    alsoKnownAs: [
      '1/6 BBL',
      'Slim',
      'Log',
      'Pinner',
      'Pin',
      'Pig',
      '20L',
      'Torpedo',
      'Sixtel',
      'Sixel',
      'SB',
    ],
  },
  {
    containerType: CONTAINER_TYPES_QB,
    name: 'Quarter Barrel',
    volumeInGallons: 7.75,
    volumeInLiters: 29.3,
    heightInInches: 23.3,
    diameterInInches: 11.2,
    weightInLbsFull: 87,
    maxQtyOnSinglePallet: NUMBER_OF_QB_KEGS_THAT_FIT_ONTO_ONE_PALLET,
    alsoKnownAs: [
      'Quarter',
      'Slim Quarter',
      'Pony',
      '30L',
      'QB',
    ],
  },
  {
    containerType: CONTAINER_TYPES_L30,
    name: '30 Litre Kegs',
    volumeInGallons: 7.92,
    volumeInLiters: 30,
    heightInInches: 14.37,
    diameterInInches: 15.55,
    weightInLbsFull: 21.38,
    maxQtyOnSinglePallet: NUMBER_OF_L30_KEGS_THAT_FIT_ONTO_ONE_PALLET,
    alsoKnownAs: [
      '30 Litre Keg Pallet',
      '30L Keg Pallet',
      'L30',
    ],
  },
  {
    containerType: CONTAINER_TYPES_L50,
    name: '50 Litre Kegs',
    volumeInGallons: 13.20,
    volumeInLiters: 50,
    heightInInches: 23.62,
    diameterInInches: 15,
    weightInLbsFull: 27.11,
    maxQtyOnSinglePallet: NUMBER_OF_L50_KEGS_THAT_FIT_ONTO_ONE_PALLET,
    alsoKnownAs: [
      '50 Litre Keg Pallet',
      '50L Keg Pallet',
      'L50',
    ],
  },
  {
    containerType: CONTAINER_TYPES_L20,
    name: '20 Litre Kegs',
    volumeInGallons: 5.28,
    volumeInLiters: 20,
    heightInInches: 23.15,
    diameterInInches: 9.33,
    weightInLbsFull: 11.90,
    maxQtyOnSinglePallet: NUMBER_OF_L20_KEGS_THAT_FIT_ONTO_ONE_PALLET,
    alsoKnownAs: [
      '20 Litre Keg Pallet',
      '20L Keg Pallet',
      'L20',
    ],
  },
  {
    containerType: CONTAINER_TYPES_G9,
    name: '9 Gallon Pallet',
    volumeInGallons: 9,
    volumeInLiters: 34.06,
    heightInInches: 19.05,
    diameterInInches: 16.06,
    weightInLbsFull: 22.70,
    maxQtyOnSinglePallet: NUMBER_OF_G9_KEGS_THAT_FIT_ONTO_ONE_PALLET,
    alsoKnownAs: [
      '9 Gallon Casks',
      '9G Pallet',
      '9G',
    ],
  },
]


export function getSelectContainerTypesInfo({
  entireItemSkusSlice,
  itemSkuIdsToInclude,
  businessUnitIdOfCurrentUserRootCustomer,
  displayPicsOfMKMKegsRatherThanNoPicsAtAllIfBrandOfBusinessUnitPicsNotAvailable,
}) {
  const containerTypesInfoWithImages = addImagesToContainerTypesInfoBasedOnBusinessUnitIdType({
    businessUnitIdOfCurrentUserRootCustomer,
    displayPicsOfMKMKegsRatherThanNoPicsAtAllIfBrandOfBusinessUnitPicsNotAvailable,
  })

  const sortedItemSkuIdsToInclude = sortArrayByTemplateArray(
    itemSkuIdsToInclude,
    getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
  )

  const sortedContainerTypes = Array.from(new Set(sortedItemSkuIdsToInclude.map(itemSkuId => (
    getContainerTypeFromItemSkuId({ entireItemSkusSlice, itemSkuId })
  ))))

  const selectContainerTypesInfo = sortedContainerTypes.map(containerType => (
    find_(containerTypesInfoWithImages, o => o.containerType === containerType)
  )).filter(o => o) // filter out any that don't have definitions in containerTypesInfo

  return selectContainerTypesInfo
}


// helper functions

function addImagesToContainerTypesInfoBasedOnBusinessUnitIdType({
  businessUnitIdOfCurrentUserRootCustomer, // can be null
  displayPicsOfMKMKegsRatherThanNoPicsAtAllIfBrandOfBusinessUnitPicsNotAvailable,
}) {
  const images = {
    [BUSINESS_UNIT_ID_TYPE_MICROSTAR]: {
      [CONTAINER_TYPES_HB]: microStarHalfBarrelImage,
      [CONTAINER_TYPES_SB]: microStarSixthBarrelImage,
      [CONTAINER_TYPES_QB]: microStarQuaterBarrelImage,
    },
    [BUSINESS_UNIT_ID_TYPE_KEGCRAFT]: {
      [CONTAINER_TYPES_HB]: kegCraftHalfBarrelImage,
      [CONTAINER_TYPES_SB]: kegCraftSixthBarrelImage,
      [CONTAINER_TYPES_QB]: microStarQuaterBarrelImage,
    },
    [BUSINESS_UNIT_ID_TYPE_KEGSTAR_INT]: {
      [CONTAINER_TYPES_L20]: kegStarL20,
      [CONTAINER_TYPES_L30]: kegStarL30,
      [CONTAINER_TYPES_L50]: kegStarL50,
      [CONTAINER_TYPES_G9]: kegStar9G,
    },
  }
  let businessUnitIdForImages = businessUnitIdOfCurrentUserRootCustomer
  if (!businessUnitIdForImages) {
    businessUnitIdForImages = BUSINESS_UNIT_ID_TYPE_MICROSTAR
  }
  if (!Object.keys(images).includes(businessUnitIdOfCurrentUserRootCustomer)) {
    if (displayPicsOfMKMKegsRatherThanNoPicsAtAllIfBrandOfBusinessUnitPicsNotAvailable) {
      businessUnitIdForImages = BUSINESS_UNIT_ID_TYPE_MICROSTAR
    }
  }

  return containerTypesInfo.map(o => ({
    ...o,
    image: get_(images, [businessUnitIdForImages, o.containerType], null),
  }))
}
