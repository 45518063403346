
import {
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'

import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_PARENT_CHILD_LINKS as defaultSlice,
} from '../../../constants'


// usage:
// const entireParentChildLinksSlice = getEntireSlice(state)
export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)
