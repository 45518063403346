/* eslint-disable max-len */

import { combineReducers } from 'redux'

import {
  REDUCER_NAMES_FETCH_STATUSES_PRELIM_INFO,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER,
  REDUCER_NAMES_FETCH_STATUSES_CHILDREN,
  REDUCER_NAMES_FETCH_STATUSES_ADDRESSES,
  REDUCER_NAMES_FETCH_STATUSES_CONTRACTS,
  REDUCER_NAMES_FETCH_STATUSES_RELATED_TO_INFO,
  REDUCER_NAMES_FETCH_STATUSES_RELATED_FROM_INFO,
  REDUCER_NAMES_FETCH_STATUSES_INBOUND_UNACKNOWLEDGED_SHIPMENTS,
  REDUCER_NAMES_FETCH_STATUSES_ORDERS_OF_INBOUND_UNACKNOWLEDGED_SHIPMENTS,
  REDUCER_NAMES_FETCH_STATUSES_COLLAR_PLATES,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_REPRESENTATIVES,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_USERS,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_USER_PERMISSIONS,
  REDUCER_NAMES_FETCH_STATUSES_CONTACTS,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_BALANCES,
  REDUCER_NAMES_FETCH_STATUSES_BANK_ACCOUNT_INFO,
  REDUCER_NAMES_FETCH_STATUSES_DELETE_BANK_ACCOUNT_INFO,
  REDUCER_NAMES_FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER,
  REDUCER_NAMES_FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_MESSAGES,
} from '../../../../constants'

import fetchStatusesPrelimInfoReducer from './preliminaryInfo'
import fetchStatusesCustomersReducer from './customers'
import fetchStatusesChildrenReducer from './children'
import fetchStatusesAddressesReducer from './addresses'
import fetchStatusesContractsReducer from './contracts'
import fetchStatusesRelatedToInfoReducer from './relatedToInfo'
import fetchStatusesRelatedFromInfoReducer from './relatedFromInfo'
import fetchStatusesInboundUnacknowledgedShipmentsReducer from './inboundUnacknowledgedShipments'
import fetchStatusesOrdersOfInboundUnacknowledgedShipmentsReducer from './ordersOfInboundUnacknowledgedShipments'
import fetchStatusesCollarPlatesReducer from './collarPlates'
import fetchCustomerRepresentativesReducer from './customerRepresentatives'
import fetchCustomerUsersReducer from './customerUsers' // user admin form
import fetchCustomerUserPermissionsReducer from './customerUserPermissions' // user admin form
import fetchContactsReducer from './contacts'
import fetchCustomerMessagesReducer from './customerMessages'
import fetchInboundShipmentsOfSpecificKegOrdersReducer from './inboundShipmentsOfSpecificKegOrders'
import fetchCustomerBalancesReducer from './customerBalances'
import fetchBankAccountInfoReducer from './bankAccountInfo'
import fetchDeleteBankAccountInfoReducer from './bankAccountInfoDelete'
import fetchStatusesReportedInventoryVsCalculatedInventoryReducer from './reportedInventoryVsCalculatedInventory'

export default combineReducers({
  [REDUCER_NAMES_FETCH_STATUSES_PRELIM_INFO]: fetchStatusesPrelimInfoReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CUSTOMER]: fetchStatusesCustomersReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CHILDREN]: fetchStatusesChildrenReducer,
  [REDUCER_NAMES_FETCH_STATUSES_ADDRESSES]: fetchStatusesAddressesReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CONTRACTS]: fetchStatusesContractsReducer,
  [REDUCER_NAMES_FETCH_STATUSES_RELATED_TO_INFO]: fetchStatusesRelatedToInfoReducer,
  [REDUCER_NAMES_FETCH_STATUSES_RELATED_FROM_INFO]: fetchStatusesRelatedFromInfoReducer,
  [REDUCER_NAMES_FETCH_STATUSES_INBOUND_UNACKNOWLEDGED_SHIPMENTS]:
    fetchStatusesInboundUnacknowledgedShipmentsReducer,
  [REDUCER_NAMES_FETCH_STATUSES_ORDERS_OF_INBOUND_UNACKNOWLEDGED_SHIPMENTS]:
    fetchStatusesOrdersOfInboundUnacknowledgedShipmentsReducer,
  [REDUCER_NAMES_FETCH_STATUSES_COLLAR_PLATES]: fetchStatusesCollarPlatesReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_REPRESENTATIVES]: fetchCustomerRepresentativesReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_USERS]: fetchCustomerUsersReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_USER_PERMISSIONS]: fetchCustomerUserPermissionsReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CONTACTS]: fetchContactsReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_MESSAGES]: fetchCustomerMessagesReducer,
  [REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_BALANCES]: fetchCustomerBalancesReducer,
  [REDUCER_NAMES_FETCH_STATUSES_BANK_ACCOUNT_INFO]: fetchBankAccountInfoReducer,
  [REDUCER_NAMES_FETCH_STATUSES_DELETE_BANK_ACCOUNT_INFO]: fetchDeleteBankAccountInfoReducer,
  [REDUCER_NAMES_FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER]:
    fetchInboundShipmentsOfSpecificKegOrdersReducer,
  [REDUCER_NAMES_FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY]: fetchStatusesReportedInventoryVsCalculatedInventoryReducer,
})
