import React from 'react'
import { Button, Header } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import ContactCard from '../../../../../../common-components/ContactCard'

import {
  FETCH_COLLAR_PLATES_INFO,
} from '../../../../../../redux/actions/actionTypes'
import createAction from '../../../../../../redux/actions/createAction'

import {
  UNIVERSAL_CUSTOMER_REPS_KEG_COLLARS,
} from '../../../../../../constants'


export default ({
  customerId,
  operatingContractBrewerCustomerId,
  dispatch,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <div>
      <Header as="h3" inverted color="red">
        {translate('You have no collar plates!')}
      </Header>
      <p>{translate("Looks like you have no active collar plates on file with us. Contact us to register a collar plate, then you'll be able to use this feature.")}</p>
      <ContactCard
        repType={UNIVERSAL_CUSTOMER_REPS_KEG_COLLARS}
      />
      <Button
        color="blue"
        content={translate('Refresh Collar Plates List')}
        onClick={() => dispatch(createAction(
          FETCH_COLLAR_PLATES_INFO,
          {
            customerId,
            operatingContractBrewerCustomerId,
          },
        ))}
      />
    </div>
  )
}
