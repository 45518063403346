import {
  isStringNonZeroPaddedPositiveInteger,
  isStringNonZeroPaddedPositiveOrNegativeInteger,
  getDoesStringContainDigitsOnly,
} from './index'


export const normalizerCombiner = (...funcs) => ((value, previousValue, allValues, previousAllValues) => {
  let toReturn = value
  funcs.forEach(f => {
    toReturn = f(toReturn, previousValue, allValues, previousAllValues)
  })
  return toReturn
})


export const nonZeroPaddedPositiveInteger = (value, previousValue) => {
  if (!value) { return value }
  return isStringNonZeroPaddedPositiveInteger(value)
    ? value
    : previousValue
}

export const nonZeroPaddedPositiveIntegerOrZero = (value, previousValue) => {
  if (!value) { return value }
  if (value === '0') { return value }
  return isStringNonZeroPaddedPositiveInteger(value)
    ? value
    : previousValue
}

export const nonZeroPaddedPositiveOrNegativeIntegerOrZero = (value, previousValue) => {
  if (!value) { return value }
  if (value === '0') { return value }
  if (value === '-') { return value }
  return isStringNonZeroPaddedPositiveOrNegativeInteger(value)
    ? value
    : previousValue
}


// by nonZeroPaddedNegativeInteger, we mean that -1 is ok but -01 is not
export const nonZeroPaddedNegativeInteger = (value, previousValue) => {
  if (!value) { return value }
  if (value[0] !== '-') { return previousValue }
  if (value === '-') { return value }
  return nonZeroPaddedPositiveInteger(value.substring(1))
}

// Good for things such as zip codes, not good for things such as numbers. Use
// something like nonZeroPaddedPositiveIntegerOrZero for numbers instead.
export const digitsOnly = (value, previousValue) => {
  if (!value) { return value }
  return getDoesStringContainDigitsOnly(value)
    ? value
    : previousValue
}

export const maxLength = len => (value, previousValue) => {
  if (!value) { return value }
  return value.length <= len
    ? value
    : previousValue
}

export const maxLengthConditionalLength = lengthFunc => (value, previousValue) => {
  if (!value) { return value }
  const len = lengthFunc(value, previousValue)
  return value.length <= len
    ? value
    : previousValue
}

export const maxNumericValue = maxVal => (value, previousValue) => {
  if (!value) { return value }
  return Number(value) <= maxVal
    ? value
    : previousValue
}

export const upper = value => value.toUpperCase()
