import React from 'react'

import ToCustomerPortalButton from '../AfterSubmissionButtons/ToCustomerPortalButton'
import ClearFormButton from '../AfterSubmissionButtons/ClearFormButton'

export default ({
  form: formName,
  // these will only be passed in if this is an "edit existing item" form
  isEditForm,
  confirmClearForm,

  // This will only be passed in if this modal is for deleting/canceling an item
  itemIdIfThisIsForDeletingAnItem,

  // This will only be passed in if this is an "edit existing item" form or a
  // "delete item" form
  closeModalFunc,
}) => {
  if (isEditForm) {
    return [
      <ToCustomerPortalButton
        additionalActions={closeModalFunc}
        key="tcp"
        confirm={confirmClearForm}
      />,
      <ClearFormButton
        formName={formName}
        buttonAndMessageText={{
          buttonText: 'Re-Edit Item',
        }}
        key="cf"
      />,
    ]
  }

  if (itemIdIfThisIsForDeletingAnItem) {
    return (
      <ToCustomerPortalButton
        additionalActions={closeModalFunc}
        key="tcp"
        confirm={confirmClearForm}
      />
    )
  }

  return [
    <ToCustomerPortalButton
      key="tcp"
    />,
    <ClearFormButton
      formName={formName}
      confirm={confirmClearForm}
      key="cf"
    />,
  ]
}
