import { connect } from 'react-redux'


import PayBalance from './components'

import fetchStatusSelectors from '../../redux/selectors/fetchStatuses/customers/customerBalances'

import {
  FETCH_CUSTOMER_BALANCES,
  FETCH_PAY_CUSTOMER_BALANCE,
} from '../../redux/actions/actionTypes'
import createAction from '../../redux/actions/createAction'

import {
  getBalance,
  getPreviousBalance,
  getHasAnyInvoices,
  getInvoiceDate,
} from '../../redux/selectors/customerBalances'
import { getProp as getCustomerProp } from '../../redux/selectors/customers'


const mapStateToProps = (state, { customerId }) => {
  const balance = getBalance(state, customerId)
  const previousBalance = getPreviousBalance(state, customerId)
  const hasAnyInvoices = getHasAnyInvoices(state, customerId)
  const invoiceDate = getInvoiceDate(state, customerId)

  const { getFetchStatuses, getFetchFailureErrorDetails } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(state, customerId)
  const errorDetails = getFetchFailureErrorDetails(state, customerId)
  const currency = getCustomerProp(state, customerId, 'currency')

  return {
    balance,
    previousBalance,
    invoiceDate,
    // Needed by NoInvoicesOnFileHOC
    hasAnyInvoices,

    // needed by WaitForApiFetch
    ...fetchStatuses,
    errorDetails,
    currency,
    loadingMessage: 'Fetching Balances',
    failureHeaderMessage: 'Failed to Fetch Balances',
  }
}

const mapDispatchToProps = (dispatch, { customerId }) => {
  // in case fetch fails, or in case user has no invoices, calls M*, a M*
  // employee fixes this error, and asks the user to refresh.
  const refetchCustomerBalances = () => {
    dispatch(createAction(
      FETCH_CUSTOMER_BALANCES,
      { customerId },
    ))
  }

  const onSubmit = ({
    amount,
    saveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
    useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
    updateSavedBankAccountIfUseNewBankAccount,
    remittanceDetail,
  }) => (
    // We need to make sure we return the promise here, otherwise the saga that
    // gets the secret-key signature from the backend (which is needed to make a
    // payment) won't be able to give that signature to the component.
    new Promise((resolve, reject) => {
      dispatch(createAction(
        FETCH_PAY_CUSTOMER_BALANCE,
        {
          customerId,
          amount,
          saveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
          useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
          updateSavedBankAccountIfUseNewBankAccount,
          resolve,
          reject,
          remittanceDetail,
        },
      ))
    })
  )

  return {
    onSubmit,
    // needed by WaitForApiFetch
    resubmitFunc: refetchCustomerBalances,
    // needed by mergeProps,
    dispatch,
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PayBalance)
