// Used in both the currentUser reducer and the customerUserPermissions reducer
import {
  PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
  PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
} from '../../../constants/permissions'

import {
  createPermissionsMap,
  createPerCustomerPermissionsMap,
} from '../../../utils/permissions'


// Returns a new `obj` object with two additional props added, `permissionsMap`
// and `perCustomerPermissionsMap`. If the object passed in does not contain
// `permissions` or `perCustomerPermissions` props, the new `...map` props will
// be set to empty objects.
export function addPermissionsMapsToObjectContainingPermissions(obj) {
  const permissionsMap = createPermissionsMap(obj.permissions)
  const perCustomerPermissionsMap = createPerCustomerPermissionsMap(
    obj.perCustomerPermissions,
  )

  return {
    ...obj,
    [PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]: permissionsMap,
    [PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]: perCustomerPermissionsMap,
  }
}
