import React from 'react'


import { useTranslation } from 'react-i18next'
import FeatureDisabledMessage from '../../../../common-components/FeatureDisabledMessage'

import {
  formatArrayOfStringsAsNaturalLanguagePhrase,
} from '../../../../utils'


export default ({
  customerId,
  operatingContractBrewerCustomerId,
  shippingAddresses,
}) => {
  const shipToCustomers = shippingAddresses.map(o => o.name)
  const { t: translate } = useTranslation('common')
  const customersString = formatArrayOfStringsAsNaturalLanguagePhrase(shipToCustomers)

  const operatingCustomer = operatingContractBrewerCustomerId || customerId
  const isTheOperatingCustomerTheOneCustomerThatCanBeShippedTo = (
    shipToCustomers.length === 1 &&
    shipToCustomers[0].customerId === operatingCustomer
  )

  let firstPartOfMessage
  if (isTheOperatingCustomerTheOneCustomerThatCanBeShippedTo) {
    firstPartOfMessage = translate("You don't have any shipping addresses on file")
  } else {
    firstPartOfMessage = shipToCustomers.length > 1
      ? `${translate('None of the customers you can ship to')} (${customersString}) ${translate('have any shipping addresses on file')}`
      : `${translate('The customer you can ship to')}, ${customersString}, ${translate("doesn't have any shipping addresses on file")}`
  }

  const message = `${firstPartOfMessage}. ${translate("Contact your personalized representative and provide them with shipping addresses, then you'll be able to use this feature.")}`

  return (
    <FeatureDisabledMessage
      customerId={operatingContractBrewerCustomerId || customerId}
      headerText="No Shipping Addresses"
      messageText={message}
      messageSeverity="warning"
    />
  )
}
