/* eslint-disable max-len */

import { call, put, takeEvery } from 'redux-saga/effects'

import { privateFetch } from '../fetch'

import {
  FETCH_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER,
  FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_REQUEST,
  FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_SUCCESS,
  FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_FAILURE,
  SAVE_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER,
} from '../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  API_URL_PATH_SHIPMENTS_HISTORY,
} from '../../../constants'

import {
  URL_PARAM_ORDER_ID,
} from '../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/shipments'


function* fetchInboundShipmentsOfSpecificKegOrder(action) {
  const { customerId, orderUuid } = action.payload
  yield put(createAction(FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_REQUEST, { target: [customerId, orderUuid] }))
  let response
  try {
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_SHIPMENTS_HISTORY,
        params: {
          [URL_PARAM_ORDER_ID]: orderUuid,
        },
      },
    )
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) { // customer is not related from any other customers
        yield call(doSuccess, customerId, orderUuid, [])
        return
      }
      yield call(doFailure, customerId, orderUuid, error)
      return
    }
    yield call(doFailure, customerId, orderUuid, error)
    return
  }
  yield call(doSuccess, customerId, orderUuid, response.data)
}


// just takes two lines down to one: extreme DRY!
function* doSuccess(customerId, orderUuid, shipmentsInfo) {
  yield put(createAction(SAVE_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER, { customerId, orderUuid, info: shipmentsInfo }))
  yield put(createAction(FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_SUCCESS, { target: [customerId, orderUuid] })) // CODE_COMMENTS_20
}

function* doFailure(customerId, orderUuid, error) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_FAILURE,
        target: [customerId, orderUuid],
      }),
    },
  )
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER, fetchInboundShipmentsOfSpecificKegOrder],
]
