import React, { Fragment } from 'react'

import withCustomerPrelimInfoFetchingOrFailed from '../../common-components/HOC/reduxUiCombined/withCustomerPrelimInfoFetchingOrFailed'
import DashboardDecider from './containers/DashboardDecider'

export default withCustomerPrelimInfoFetchingOrFailed(({
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  ...rest
}) => (
  <Fragment>
    <DashboardDecider
      key="DashboardDecider"
      customerId={customerId}
      operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      {...rest}
    />
  </Fragment>
))
