import React from 'react'
import { Grid } from 'semantic-ui-react'

// info is an array of objects:
// [
//   { label: 'Height', measurement: '23.3"' },
//   { label: 'Width (full)', measurement: '87 lbs"' },
// ]
export default ({ info }) => (
  <Grid
    columns={2}
    className="tight-grid-rows"
    style={{ marginTop: 0, marginBottom: '1rem' }}
  >
    {info.map(pieceOfInfo => (
      <Grid.Row key={pieceOfInfo.label}>
        {/* For alignment purposes, all displayed content within this grid */}
        {/* needs to be contained within a <Grid.Column> component; see */}
        {/* CODE_COMMENTS_97 for why. */}
        <Grid.Column width={7}>{`${pieceOfInfo.label}:`}</Grid.Column>
        <Grid.Column width={9}>{pieceOfInfo.measurement}</Grid.Column>
      </Grid.Row>
    ))}
  </Grid>
)
