import React from 'react'

import NoCollarPlatesMessage from './NoCollarPlatesMessage'

import {
  getTotalNumCollarPlatesOnFileForCustomer,
} from '../../../../../../redux/selectors/rewrite/collarPlates'

export default ({
  entireCollarPlatesSlice,
  entireCustomerCollarPlateLinksSlice,
  wrappedComponent: WrappedComponent,
  ...rest
}) => {
  const {
    customerId,
  } = rest
  const totalNumCollarPlatesOnFileForCustomer = getTotalNumCollarPlatesOnFileForCustomer({
    customerId,
    entireCollarPlatesSlice,
    entireCustomerCollarPlateLinksSlice,
    currentlyActiveCollarPlatesOnly: true,
  })

  return (
    totalNumCollarPlatesOnFileForCustomer > 0
      ? <WrappedComponent {...rest} />
      : <NoCollarPlatesMessage {...rest} />
  )
}
