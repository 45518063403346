import React from 'react'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { validateDate } from '../../../../common-components/rewrite/ReactDatepicker'

import {
  convertNumberToDaysFromNowNaturalLanguage,
} from '../../../../utils'
import { ORDER_KEGS_ORDER_TYPE_LOCAL } from '../../../../constants'

const ANZ_EXPEDITE_MESSAGING = 'If you require delivery prior to this date, please email <a href=mailto:rent@kegstar.com />rent@kegstar.com</a> with order number and requested delivery date. Orders within 2 days will incur an expedited fee.'
const EXPEDITE_MESSAGING = 'An expedite fee may be charged for this order, please extend your delivery date to avoid this fee.'

const getExpediteMsg = ({ customerId, entireCustomersSlice, translate }) => {
  // separate msging for ANZ
  const { subsidiaryId } = entireCustomersSlice?.[customerId] || {}
  if (subsidiaryId === 22 || subsidiaryId === 21) {
    return ANZ_EXPEDITE_MESSAGING
  }
  return translate(EXPEDITE_MESSAGING)
}

const XDaysFromNow = props => {
  const {
    dateNeededInputValue,
    orderType,
    // maxOrderExpediteDays = 0,
    // minOrderExpediteDays = 0,
    customerId, entireCustomersSlice,
    kegOrderDays,
  } = props
  const { t: translate } = useTranslation('pagelabels')
  const validationResult = validateDate({ value: dateNeededInputValue })
  if (!validationResult) {
    return null
  }
  const dateNeededMoment = validationResult.dateMoment
  const dateNeededDaysFromNowNumber = dateNeededMoment.diff(moment().startOf('day'), 'days')
  // let dateNeededDaysFromMinNumberOfDays = dateNeededMoment.diff(moment().add(1, 'days').startOf('day'), 'days')
  // if daysFromNow is undefined, return nothing
  if (
    !dateNeededDaysFromNowNumber &&
    dateNeededDaysFromNowNumber !== 0
  ) { return null }

  // if (orderType !== ORDER_KEGS_ORDER_TYPE_LOCAL) {
  //   const hours = (new Date())?.getHours() || 0
  //   if (orderTimeOfDayCutOff > 0 && hours > orderTimeOfDayCutOff) {
  //     modifiedMinOrderExpediteDays += 1
  //     modifiedMaxOrderExpediteDays += 1
  //   }
  // }
  const dateNeededDaysFromNowNaturalLanguage = convertNumberToDaysFromNowNaturalLanguage(
    dateNeededDaysFromNowNumber,
  )

  const isDateNeededBeforeTomorrow = dateNeededDaysFromNowNumber < 1
  // eslint-disable-next-line max-len
  // const c = (orderType !== ORDER_KEGS_ORDER_TYPE_LOCAL && minOrderExpediteDays > 0 && maxOrderExpediteDays > 0) ?
    // eslint-disable-next-line max-len
    // (dateNeededDaysFromNowNumber <= minOrderExpediteDays || dateNeededDaysFromNowNumber <= maxOrderExpediteDays) : false

  const isDateNeededHasExpediteCharge = (orderType !== ORDER_KEGS_ORDER_TYPE_LOCAL) ?
    kegOrderDays?.filter(day => {
      const orderMomnet = moment(day?.date).tz(day.timeZone)
      // console.log('orderMomnet', orderMomnet)
      return orderMomnet.isSame(validationResult.dateMoment, 'day')
    })?.[0]?.canExpediteOrder : false

  const stringToDisplay = isDateNeededHasExpediteCharge ? getExpediteMsg({
    customerId, entireCustomersSlice, translate,
  })
    : dateNeededDaysFromNowNaturalLanguage


  return (
    <span
      style={{ fontWeight: 'bold' }}
      className={(isDateNeededBeforeTomorrow || isDateNeededHasExpediteCharge) ? 'semantic-ui-error-text-color' : null}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: stringToDisplay }} />
    </span>
  )
}

export default XDaysFromNow
