/**
 * This form has as an additional feature that most forms don't have: after the
 * form is submitted, when the user clicks the 'clear form' button, the app
 * re-fetches the delivered/in-transit unacknowledged inbound shipments. This is
 * important because it would be really bad if, after the form is cleared, the
 * form presents the user with an inbound shipment they just acknowledged. In
 * order to avoid the risk of double acknowledgements, the form needs to have an
 * up-to-date list of unacknowledged shipments every time it allows the user to
 * fill out the form. So what should be displayed when the updated list of
 * shipments is being fetched? And what should be displayed if the fetch fails?
 * That's what the FormOrNoShipmentsMessageWithFetcinghWrapper is for.
 *
 * This form also displays an error message if the "fetch incoming delivered
 * unacknowledged shipments" API call fails. See CODE_COMMENTS_77.
 */
import { connect } from 'react-redux'

import WaitForApiFetch from '../../common-components/WaitForApiFetch'
import FormOrNoShipmentsMessage from './containers/FormOrNoShipmentsMessage'

import UiContainer from '../../common-components/UiContainer'

import {
  FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS,
} from '../../redux/actions/actionTypes'
import createAction from '../../redux/actions/createAction'

import fetchStatusSelectors from '../../redux/selectors/fetchStatuses/customers/inboundUnacknowledgedShipments'


const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps
  const { getFetchStatuses, getFetchFailureErrorDetails } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(state, customerId)
  const errorDetails = getFetchFailureErrorDetails(state, customerId)
  return {
    // needed by WaitForApiFetch
    ...fetchStatuses,
    errorDetails,
    loadingMessage: 'Fetching Inbound Shipments',
    failureHeaderMessage: 'Failed to Load Inbound Unacknowledged Shipments',
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  const { customerId } = ownProps
  const refetchInboundUnackedShipments = () => (
    dispatch(createAction(
      FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS,
      { customerId },
    ))
  )

  return {
    refetchInboundUnackedShipments,
  }
}


const FormOrNoShipmentsMessageWithFetcinghWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaitForApiFetch(FormOrNoShipmentsMessage))

export default UiContainer({
  headerText: 'Acknowledge Inbound Shipments',
  headerProps: { className: 'segment-header-bottom-margin' },
  containerId: 'acknowledgeInboundShipmentsSection',
})(FormOrNoShipmentsMessageWithFetcinghWrapper)
