import React from 'react'
import isString_ from 'lodash/isString'

import ErrorMessages from '../subFeatures/ErrorMessages'

import BannerMessageAccountOnHoldOrInactive from '../../../BannerMessageAccountOnHoldOrInactive'
import BannerMessageUpcomingMaintenanceWindow from '../../../BannerMessageUpcomingMaintenanceWindow'

import {
  isTruthyAndNonEmpty,
} from '../../../../utils'
import BannerMessageFromEmployee from '../../../BannerMessageFromEmployee'


export default ({
  verificationErrorMessages,
  componentToRenderIfVerified: ComponentToRenderIfVerified,
  // We don't actually use verifiers in this component, the only reason we list
  // it here is because we don't want it included in the props that we send to
  // the ComponentToRenderIfVerified, because it's a new object that gets
  // created on each render tick, so it would cause ComponentToRenderIfVerified
  // to be re-rendered on each render tick, degrading performance.
  verifiers,
  ...rest
}) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = rest
  const errorMessages = verificationErrorMessages.filter(result => isString_(result))
  if (isTruthyAndNonEmpty(errorMessages)) {
    return <ErrorMessages errorMessages={errorMessages} customerId={customerId} />
  }

  return ([
    // The "Account Inactive/On Credit Hold" banner needs to 1) show up on every
    // single valid route for the offending customer; 2) not show up on any 404
    // routes (i.e. only the "This page does not exist" message should display);
    // 3) not show up on any /operate-for/:id routes that don't concern this
    // user (i.e. if a CB is on a credit hold but not any contractees, the
    // banner message should disappear when the user navigates to an
    // /operate-for/:contracteeId route). The easiest way to make all these
    // things happen is to include the banner message with
    // <PrelimInfoVerification>. This is admittedly a bit of a kludge because it
    // tightly couples the <BannerMessageAccountOnHoldOrInactive>
    // component to the <PrelimInfoVerification> component, but it works, and
    // the reason it works is 1) <PrelimInfoVerification> wraps all valid routes
    // but does not get called on any 404 routes, which means it doesn't show up
    // on any 404 routes, and therefore the banner message won't either; 2)
    // <PrelimInfoVerification> only gets rendered after all preliminary info
    // has been successfully fetched, which means that the banner message will
    // too, and therefore when the banner message queries the Redux store, it
    // will never encounter a "prop does not exist in store" error.

    // it's impossible for CONBRWs operating for contractee brewers to be on a
    // credit hold in the web app; see CODE_COMMENTS_125.
    ...(!operatingContractBrewerCustomerId
      ? [
        <BannerMessageAccountOnHoldOrInactive
          key="Credit Hold or Inactive Banner Message If Necessary"
          customerId={customerId}
          operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
        />,
      ]
      : []
    ),
    // We put the Upcoming Maintenance banner here for the same reason as the
    // "Account Inactive/On Credit Hold" banner
    <BannerMessageUpcomingMaintenanceWindow
      key="Banner Message Upcoming Maintenance Window"
    />,
    <BannerMessageFromEmployee
        key="Message From Employee"
        customerId={customerId}
    />,
    <ComponentToRenderIfVerified
      key="Main content"
      {...rest}
    />,
  ])
}
