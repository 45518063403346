import {
  REDUCER_NAMES_BANK_ACCOUNT_INFO as defaultSlice,
} from '../../constants'

import {
  withPropOrUndefinedSimple,
  getEntireSlice as commonGetEntireSlice,
} from './higherOrderFunctions'

import {
  isTruthyAndNonEmpty,
} from '../../utils'

const defaultSlices = [defaultSlice, 'byCustomerId']

// You can use this to get several levels of slices:
//
// getEntireSlice(state) // all shipment IDs of all customers
// getEntireSlice(state, customerId) // all shipment objects of one customer
export const getEntireSlice = commonGetEntireSlice(...defaultSlices)

// usage: const customerType = getProp(state, customerId, 'previousBalance')
const getProp = withPropOrUndefinedSimple(...defaultSlices)


export function getPaymentToken(state, customerId) {
  return getProp(state, customerId, 'paymentToken')
}

export function getBankName(state, customerId) {
  return getProp(state, customerId, 'bankName')
}

export function getEndingDigitsOfBankAccount(state, customerId) {
  return getProp(state, customerId, 'endingDigits')
}

// Only call this after you're sure the fetch has succeeded, otherwise you'll
// get a false negative
export function getHasStoredBankAccountInfo(state, customerId) {
  return isTruthyAndNonEmpty(getEntireSlice(state, customerId))
}
