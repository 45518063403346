/* eslint-disable dot-notation */

import { connect } from 'react-redux'
import values_ from 'lodash/values'


import Table from './components/Table'

import {
  getEntireSlice as getAllCollarOrderHistoryObjects,
} from '../../../../../redux/selectors/histories/collarOrderHistory'
import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../../redux/selectors/permissions'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getDoesBrewerHaveCustomerLevelPermission,
  getDoesContractBrewerHaveCustomerLevelPermission,
} from '../../../../../redux/selectors/rewrite/permissionsAtTheCustomerLevel'
import {
  getCustomerIdsAddressBelongsTo,
} from '../../../../../redux/selectors/addresses'

import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/forms'

import {
  getAllCollarOrderObjectsToBeIncludedInHistoryTable,
} from '../../util/collarOrderHistory'

import {
  COLLAR_ORDER_STATUS_NEW_ORDER,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_COLLAR_ORDERS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_COLLAR_ORDERS,
  FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_DELETE_COLLAR_ORDERS,
  FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_COLLAR_ORDERS,

  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS,
} from '../../../../../constants/permissions'

import {
  HISTORY_FORM_NAME_COLLAR_ORDERS,
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
} from '../../../../../constants'

import {
  isTruthyAndNonEmpty,
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


const mapStateToProps = (state, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = ownProps
  const formName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_COLLAR_ORDERS,
    customerId,
  })

  const { getFetchStatuses } = fetchStatusSelectors
  const { isFetching, didFetchFail } = getFetchStatuses(state, formName)

  const allCollarOrderObjects = getAllCollarOrderHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  // const areAnyItemsEditable = getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId)
  const areAnyItemsEditable = false // see CODE_COMMENTS_257 for why disabled

  return {
    allCollarOrderObjects,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    isFormSubmitting: isFetching,
    hasFormSubmitFailed: didFetchFail,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
  }
}

export default connect(
  mapStateToProps,
)(Table)


// eslint-disable-next-line no-unused-vars
function getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId) {
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  if (
    // CODE_COMMENTS_153
    !getHasPermissionsToEditAndDeleteCollarOrders(
      state,
      customerId,
      operatingContractBrewerCustomerId,
    )
  ) { return false }

  // CODE_COMMENTS_168
  if (
    // If this is a CB operating for a contractee
    operatingContractBrewerCustomerId &&
    !getDoesContractBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId: customerId,
      conbrwCustomerId: operatingContractBrewerCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS,
    })
  ) { return false }

  const allCollarOrderObjects = getAllCollarOrderHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  const allCollarOrderObjectsToBeIncludedInHistoryTable = getAllCollarOrderObjectsToBeIncludedInHistoryTable(
    allCollarOrderObjects,
  )

  return values_(allCollarOrderObjectsToBeIncludedInHistoryTable).some(row => getIsindividualItemEditable(
    state,
    row,
    { customerId, operatingContractBrewerCustomerId },
  ))
}


function getIsindividualItemEditable(
  state,
  row,
  additionalPropsToPassToTableCells,
) {
  if (row.collarOrderStatus !== COLLAR_ORDER_STATUS_NEW_ORDER) { return false }

  const { customerId, operatingContractBrewerCustomerId } = additionalPropsToPassToTableCells

  // CODE_COMMENTS_170
  if (operatingContractBrewerCustomerId) { return true }


  // The only way we can tell whether a collar order concerns a Contract Brewer
  // is to determine whether the shipping address is the contract brewer's
  const brwAndOrConbrwCustomerIds = getCustomerIdsAddressBelongsTo({
    state,
    addressId: row.shippingAddressId,
  })

  // The address doesn't exist in the Redux store. This should never happen.
  if (!isTruthyAndNonEmpty(brwAndOrConbrwCustomerIds)) {
    // TODO rollbar: send message explaining that a logged-in Brewer received a
    // collar order history item from the backend that has a shipping address
    // which isn't either the Brewer's nor any of the Brewer's Contract Brewers.
    // This should never happen.
    return false
  }

  if (brwAndOrConbrwCustomerIds.includes(customerId)) { return true }

  const conbrwCustomerId = brwAndOrConbrwCustomerIds.find(id => id !== customerId)
  if (!conbrwCustomerId) { return false }

  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  return getDoesBrewerHaveCustomerLevelPermission({
    entireRelationshipsSlice,
    entireCustomersSlice,
    entireContractsSlice,
    brwCustomerId: customerId,
    conbrwCustomerId,
    permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS,
  })
}


function getHasPermissionsToEditAndDeleteCollarOrders(
  state,
  customerId,
  operatingContractBrewerCustomerId,
) {
  const editFunctionalityString = operatingContractBrewerCustomerId
    ? FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_COLLAR_ORDERS
    : FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_COLLAR_ORDERS

  const deleteFunctionalityString = operatingContractBrewerCustomerId
    ? FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_DELETE_COLLAR_ORDERS
    : FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_COLLAR_ORDERS

  return (
    // CODE_COMMENTS_153
    getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: editFunctionalityString,
      customerIdIfThisIsAPerCustomerPermissionsCheck: operatingContractBrewerCustomerId && customerId,
    }) &&
    getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: deleteFunctionalityString,
      customerIdIfThisIsAPerCustomerPermissionsCheck: operatingContractBrewerCustomerId && customerId,
    })
  )
}
