/**
 * Renders the "Accounts" section of the Master dashboard and the "Brewing For"
 * section of the Contract Brewer dashboard. This higher-order component shows a
 * loader within the card when the customer's preliminary info is still fetching
 * and shows a generic "fetch failed" card if prelim info fetching fails. Three
 * props must be passed in:
 *
 * 1. customerIds; an array of the customer IDs you want to have cards for;
 * 2. uiContainerHocProps: an object containing the headerText and headerProps
 *    of the wrapper UI container (see the docstring of the
 *    common-components/UiContainer component);
 * 3. populatedCard: the semantic-ui <Card> component you want to ultimately
 *    render once the customer's prelim info is finished loading. The
 *    populatedCard component will be passed one prop: customerId.
 */
import React, { memo } from 'react'
import { Card } from 'semantic-ui-react'

import IndividualCard from './containers/IndividualCard'
import UiContainer from '../../common-components/UiContainer'

export default memo(UiContainer()(props => {
  const {
    customerIds,
    operatingContractBrewerCustomerId,
    populatedCard,

    // A couple optional props which are good for things like "You have no
    // contractees" messages in place of the customer cards
    renderAlternateComponentIfFunc,
    alternateComponentToRender,
  } = props
  if (renderAlternateComponentIfFunc && renderAlternateComponentIfFunc(props)) {
    const AlternateComponentToRender = alternateComponentToRender
    return <AlternateComponentToRender {...props} />
  }

  return (
    <Card.Group
      itemsPerRow={4}
    >
      { customerIds.map(customerId => (
        <IndividualCard
          key={customerId}
          customerId={customerId}
          operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
          populatedCard={populatedCard}
        />
      ))}
    </Card.Group>
  )
}))
