import { put, call, takeEvery } from 'redux-saga/effects'


import { privateFetch } from '../fetch'
import {
  FETCH_CUSTOMER_BALANCES,
  SAVE_CUSTOMER_BALANCES,
  FETCH_STATUSES_CUSTOMER_BALANCES_REQUEST,
  FETCH_STATUSES_CUSTOMER_BALANCES_SUCCESS,
  FETCH_STATUSES_CUSTOMER_BALANCES_FAILURE,
} from '../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  URL_PARAM_CUSTOMER_ID,
  URL_PARAM_LATEST_INVOICE_ONLY,
} from '../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/invoices'

import {
  API_URL_PATH_INVOICES_METADATA,
  API_URL_PATH_INVOICES_NETSUITE,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'


export function* fetchCustomerBalances(action) {
  const {
    customerId,
  } = action.payload

  yield put(createAction(
    FETCH_STATUSES_CUSTOMER_BALANCES_REQUEST,
    {
      target: [customerId],
    },
  ))

  const fetchConfig = {
    // A customer's balances are contained in their invoice metadata info. We
    // simply fetch the metadata of the customer's most recent invoice, and that
    // object contains the customer's current and previous balances.
    path: API_URL_PATH_INVOICES_METADATA,
    params: {
      [URL_PARAM_CUSTOMER_ID]: customerId,
      [URL_PARAM_LATEST_INVOICE_ONLY]: true,
    },
  }

  const netSuiteFetchConfig = {
    // A customer's balances are now fetched from netsuite API. We
    // make a netsuite call and replace the balance & asofDate.
    path: `${API_URL_PATH_INVOICES_NETSUITE}/${customerId}`,
  }

  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
    const { data = {} } = yield call(
      privateFetch,
      netSuiteFetchConfig,
    )
    // Only the latest invoice is pulled. So no worries
    if (response?.data?.[0]) {
      response.data[0].invoiceDate = data?.asOfDate
      response.data[0].balance = data?.balance
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) { // customer has no invoices
        yield call(doSuccess, customerId, { data: {} })
        return
      }
      yield call(doFailure, { customerId, error })
      return
    }
    yield call(doFailure, { customerId, error })
    return
  }
  yield call(doSuccess, customerId, response)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CUSTOMER_BALANCES, fetchCustomerBalances],
]


function* doSuccess(customerId, httpResponse) {
  const responseBody = httpResponse.data
  // responseBody will be an array length-of-1 array of invoice metadata objects
  let info = responseBody[0]
  if (!isTruthyAndNonEmpty(info)) { info = {} }
  yield put(createAction(SAVE_CUSTOMER_BALANCES, { customerId, info }))
  yield put(createAction(
    FETCH_STATUSES_CUSTOMER_BALANCES_SUCCESS,
    {
      target: [customerId],
    },
  ))
}

function* doFailure({ customerId, error }) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_CUSTOMER_BALANCES_FAILURE,
        target: [customerId],
      }),
    },
  )
}
