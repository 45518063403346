import { put, call, takeEvery } from 'redux-saga/effects'


import { privateFetch } from '../fetch'
import { getContractsApiUrl } from '../../../utils'
import {
  FETCH_CUSTOMER_CONTRACTS,
  FETCH_CUSTOMER_CONTRACTS_REQUEST,
  FETCH_CUSTOMER_CONTRACTS_SUCCESS,
  FETCH_CUSTOMER_CONTRACTS_FAILURE,
  SAVE_CUSTOMER_CONTRACTS,
} from '../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  CONTRACT_TYPES_RENTAL,
} from '../../../constants'


export function* fetchContracts(action) {
  const {
    customerId,
    preventsDashboardFromLoadingIfFails,
  } = action.payload
  yield put(createAction(FETCH_CUSTOMER_CONTRACTS_REQUEST, { target: [customerId] }))
  let response
  try {
    response = yield call(privateFetch, { path: getContractsApiUrl(customerId) })
  } catch (error) {
    yield call(
      doFailure,
      {
        customerId,
        error,
        preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
      },
    )
    return
  }
  yield call(doSuccess, customerId, response, response.data)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CUSTOMER_CONTRACTS, fetchContracts],
]

function* doSuccess(customerId, response) {
  let info = response.data
  info = info.filter(o => o.contractTypeId !== CONTRACT_TYPES_RENTAL)
  yield put(createAction(SAVE_CUSTOMER_CONTRACTS, { info }))
  yield put(createAction(FETCH_CUSTOMER_CONTRACTS_SUCCESS, { target: [customerId] })) // CODE_COMMENTS_20
}

function* doFailure({ customerId, error, preventsDashboardFromLoading }) {
  yield call(
    generalDoFailure,
    {
      error,
      preventsDashboardFromLoading,
      action: createFetchFailureAction({
        error,
        type: FETCH_CUSTOMER_CONTRACTS_FAILURE,
        target: [customerId],
      }),
    },
  )
}
