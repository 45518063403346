import historyReducerCreator from './historyReducerCreator'

import {
  SAVE_INVOICE_HISTORY,
} from '../../actions/actionTypes'


export default historyReducerCreator({
  saveHistoryOfSingleCustomerActionType: SAVE_INVOICE_HISTORY,
})
