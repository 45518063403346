import { call, takeEvery } from 'redux-saga/effects'

import get_ from 'lodash/get'


import { privateFetch } from '../../fetch'

import {
  FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_STATE,
} from '../../../actions/actionTypes'

import {
  URL_PARAM_STATE,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/distSearch'

import {
  API_URL_PATH_DISTRIBUTOR_SEARCH,
} from '../../../../constants'

import {
  generalDoFailure,
} from '../../util/fetchFailure'

function* fetchChooseState(action) {
  const {
    value,
  } = action.payload
  let response
  try {
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_DISTRIBUTOR_SEARCH,
        params: { [URL_PARAM_STATE]: value },
      },
    )
  } catch (error) {
    if (get_(error, ['response', 'status']) === 404) {
      response = { data: [] }
    } else {
      yield call(doFailure, { error, ...action.payload })
    }
    return
  }
  yield call(doSuccess, { info: response.data, ...action.payload })
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_STATE, fetchChooseState],
]


function doSuccess({
  info,
  onSuccess,
}) {
  if (onSuccess) { onSuccess(info) }
}


function* doFailure({
  error,
  onFailure,
}) {
  if (onFailure) { onFailure({ error }) }
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
}
