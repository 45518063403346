import flow_ from 'lodash/fp/flow'
import map_ from 'lodash/fp/map'
import values_ from 'lodash/fp/values'
import filter_ from 'lodash/fp/filter'

import pick_ from 'lodash/pick'
import omit_ from 'lodash/omit'
import uniq_ from 'lodash/uniq'


import {
  CUSTOMER_STATUS_INACTIVE,
  CUSTOMER_TYPES_PUB,
} from '../../../constants'

/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/

// returns an array of customer Ids.
export const getAllChildCustomerObjectsOfCustomer = ({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerId,
  onlyCustomersWhoAreNotCurrentlyInactive, // CODE_COMMENTS_112, CODE_COMMENTS_122
  customerObjsCustomFilterFunc,
}) => {
  const allChildCustomerObjects = flow_(
    values_, // turn the object of objects into an array of objects
    filter_(linkObj => linkObj.parentCustomerId === customerId),
    map_(linkObj => linkObj.childCustomerId),
    map_(childCustomerId => entireCustomersSlice[childCustomerId]),
    // apply the passed-in customer object filter function
    ...(customerObjsCustomFilterFunc ? [filter_(customerObjsCustomFilterFunc)]: []),
  )(entireParentChildLinksSlice)

  return onlyCustomersWhoAreNotCurrentlyInactive
    ? allChildCustomerObjects.filter(obj => obj.customerStatus !== CUSTOMER_STATUS_INACTIVE)
    : allChildCustomerObjects
}


// returns an array of customer Ids.
export const getAllChildCustomerIdsOfCustomer = ({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerId,
  onlyCustomersWhoAreNotCurrentlyInactive, // CODE_COMMENTS_112, CODE_COMMENTS_122
  customerObjsCustomFilterFunc,
}) => (
  getAllChildCustomerObjectsOfCustomer({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId,
    onlyCustomersWhoAreNotCurrentlyInactive,
    customerObjsCustomFilterFunc,
  }).map(childCustomerObject => childCustomerObject.id)
)


/*
 * *****************************************************************************
 * More Specific Selectors
 * *****************************************************************************
*/

// Returns an array of customerObjects with each subobject being filtered to
// contain only the props within the propNames array argument.
export const getMultipleCustomerPropsOfAllChildren = ({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerId,
  propNames,
  onlyCustomersWhoAreNotCurrentlyInactive, // CODE_COMMENTS_112, CODE_COMMENTS_122
  customerObjsCustomFilterFunc,
}) => {
  const allChildCustomerObjs = getAllChildCustomerObjectsOfCustomer({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId,
    onlyCustomersWhoAreNotCurrentlyInactive,
    customerObjsCustomFilterFunc,
  })
  return allChildCustomerObjs.map(childCustomerObj => pick_(childCustomerObj, propNames))
}


// Same as getMultipleCustomerPropsOfAllChildren except filtered by customer
// type.
export const getMultipleCustomerPropsOfAllChildrenFilteredByCustomerType = ({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerId,
  propNames,
  customerTypes,
  excludeCustomerTypesRatherThanIncludeFlag=false,
  onlyCustomersWhoAreNotCurrentlyInactive, // CODE_COMMENTS_112, CODE_COMMENTS_122
  customerObjsCustomFilterFunc,
}) => {
  const notFilteredByCustomerType = getMultipleCustomerPropsOfAllChildren({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId,
    propNames: ['customerType', ...propNames],
    onlyCustomersWhoAreNotCurrentlyInactive,
    customerObjsCustomFilterFunc,
  })

  const filteredByCustomerType = notFilteredByCustomerType.filter(propsObj => (
    excludeCustomerTypesRatherThanIncludeFlag
      ? !customerTypes.includes(propsObj.customerType)
      : customerTypes.includes(propsObj.customerType)
  ))

  // we've included 'customerType' in each subobject of the returned array
  // because we needed it to filter out the customer types we don't care about.
  // But the caller might not have requested the 'customerType' prop. If not,
  // remove it.
  if (propNames.includes('customerType')) { return filteredByCustomerType }
  return filteredByCustomerType.map(propsObj => omit_(propsObj, 'customerType'))
}


export function getChildPubIds({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  customerId,
  onlyCustomersWhoAreNotCurrentlyInactive,
}) {
  return uniq_( // CODE_COMMENTS_142
    getAllChildCustomerIdsOfCustomer({
      entireCustomersSlice,
      entireParentChildLinksSlice,
      customerId,
      onlyCustomersWhoAreNotCurrentlyInactive,
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_PUB,
    }),
  )
}
