/**
 * CODE_COMMENTS_81
 */
import React from 'react'

import withFetchingOrFailed from '../../common-components/HOC/ui/withFetchingOrFailed'
import LoadingSection from '../../common-components/LoadingSection'
import FailuresContent from './FailuresContent'

export default withFetchingOrFailed(
  // loading component argument
  ({
    loadingMessage,
    loadingSectionMinHeight,
  }) => (
    <LoadingSection minHeight={loadingSectionMinHeight} message={loadingMessage} />
  ),
  // fetch failed component argument
  FailuresContent,
)
