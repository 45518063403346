import {
  FETCH_CUSTOMER_USER_PERMISSIONS_REQUEST,
  FETCH_CUSTOMER_USER_PERMISSIONS_SUCCESS,
  FETCH_CUSTOMER_USER_PERMISSIONS_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByUserIdReducerCreator from '../higherOrderReducers/reducerCreators/byUserId'

export default fetchStatusesByUserIdReducerCreator(
  FETCH_CUSTOMER_USER_PERMISSIONS_REQUEST,
  FETCH_CUSTOMER_USER_PERMISSIONS_SUCCESS,
  FETCH_CUSTOMER_USER_PERMISSIONS_FAILURE,
)
