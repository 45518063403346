import { call, takeEvery, takeLatest } from 'redux-saga/effects'


import {
  FETCH_STATUSES_FORMS_FAILURE,
  SCHEDULED_SHIPMENT,
  FETCH_UPDATE_SCHEDULED_SHIPMENT,
  FETCH_UPDATE_SCHEDULED_SHIPMENT_SHIP_DATE,
} from '../../../actions/actionTypes'
// import createAction from '../../../actions/createAction'
import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'

import { privateFetch } from '../../fetch'

import {
  saveShipmentObjectToPotentialDuplicatesSliceIfMeetsCriteria,
} from './util'

import {
  saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm,
} from '../sharedUtil/ackInboundShipmentsAndReportOutboundShipments'

import {
  formatDateForApiCall,
  getEditIndividualUserReportedShipmentApiUrl,
} from '../../../../utils'
import {
  REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../../../constants/formAndApiUrlConfig/reportOutboundShipments'
import {
  SHIPMENT_STATUS_BOOKED,
  SHIPMENT_STATUS_IN_TRANSIT,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

export function* fetchScheduledShipmentReport(action) {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    rowData,
    btnAction,
    setIsFetching,
    setDidFetchFail,
  } = action.payload
  setIsFetching(true)
  setDidFetchFail(false)
  const fieldArrayName = REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME
  // if revert remove date shipped, else set the date shipped[based on user selector or automatic]
  const dateShipped = btnAction === 'REVERT' ? null : (rowData.shipDateSelected) ? rowData.shipDateSelected : Date.now()
  const status = btnAction === 'REVERT' ? SHIPMENT_STATUS_BOOKED : SHIPMENT_STATUS_IN_TRANSIT
  const apiData = { ...rowData, dateShipped, status }
  delete apiData.shipDateSelected
  const fetchConfig = {
    data: apiData, // update only ship date
    path: getEditIndividualUserReportedShipmentApiUrl(customerId),
    method: 'PUT',
  }
  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    yield call(
      doFailure,
      customerId,
      fieldArrayName,
      setIsFetching,
      error,
      setDidFetchFail,
    )
    return
  }
  yield call(
    doSuccess,
    customerId,
    operatingContractBrewerCustomerId,
    fieldArrayName,
    setIsFetching,
    response.data,
  )
}


function* doSuccess(
  customerId,
  operatingContractBrewerCustomerId,
  fieldArrayName,
  setIsFetching,
  objToSave,
) {
  yield call(
    saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm,
    {
      customerId,
      operatingContractBrewerCustomerId,
      fieldArrayName,
      shipmentObj: objToSave,
      historyName: SCHEDULED_SHIPMENT,
    },
  )


  yield call(
    saveShipmentObjectToPotentialDuplicatesSliceIfMeetsCriteria,
    {
      customerId,
      operatingContractBrewerCustomerId,
      fieldArrayName,
      shipmentObj: objToSave,
    },
  )
  setIsFetching(false)
}

function* doFailure(
  customerId,
  fieldArrayName,
  setIsFetching,
  error,
  setDidFetchFail,
  rowIndex=0,
) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_FORMS_FAILURE,
        target: [fieldArrayName, rowIndex],
      }),
    },
  )
  setIsFetching(false)
  setDidFetchFail(true)
}

function* updateScheduledShipmentReport(action) {
  const { customerId, rowData, operatingContractBrewerCustomerId } = action.payload
  const { shipDateSelected } = rowData
  if (!shipDateSelected) {
    return
  }
  const formattedShippedDate = formatDateForApiCall({
    date: shipDateSelected,
    startOfDayRatherThanNoonLocalTime: true,
  })
  yield call(
    saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm,
    {
      customerId,
      operatingContractBrewerCustomerId,
      fieldArrayName: REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
      shipmentObj: { ...rowData, shipDateSelected: formattedShippedDate },
      historyName: SCHEDULED_SHIPMENT,
    },
  )
}
// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_UPDATE_SCHEDULED_SHIPMENT, fetchScheduledShipmentReport],
  [takeLatest, FETCH_UPDATE_SCHEDULED_SHIPMENT_SHIP_DATE, updateScheduledShipmentReport],
]
