import fetchCustomerUsersAndTheirPermissionsSagas from './customerUsersAndTheirPermissions'
import fetchCustomerUsersPermissionsSagas from './customerUserPermissions'
import individualCustomerUserAndItsPermissionsForEmployeeToOperateAs from './individualCustomerUserAndItsPermissionsForEmployeeToOperateAs'

// CODE_COMMENTS_11
export default [
  ...fetchCustomerUsersAndTheirPermissionsSagas,
  ...fetchCustomerUsersPermissionsSagas,
  ...individualCustomerUserAndItsPermissionsForEmployeeToOperateAs,
]
