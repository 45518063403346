import { connect } from 'react-redux'


import PopulatedContracteeBrewerCard from '../components/PopulatedContracteeBrewerCard'

import {
  getProp,
} from '../../../redux/selectors/customers'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../redux/selectors/rewrite/currentUser'

import {
  getWhichContracteesOfAConbrwCanHaveShipmentsReportedOnThem,
  getWhichContracteesCanConbrwOperateFor,
} from '../../../redux/selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'

const mapStateToProps = (state, props) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = props

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const isRelationshipBetweenContracteeAndCbCurrentlyActive =
    getWhichContracteesOfAConbrwCanHaveShipmentsReportedOnThem({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      conbrwCustomerId: operatingContractBrewerCustomerId,
    }).includes(customerId)

  const renderOperateForButton = getWhichContracteesCanConbrwOperateFor({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    conbrwCustomerId: operatingContractBrewerCustomerId,
  }).includes(customerId)

  return {
    name: getProp(state, customerId, 'name'),
    region: getProp(state, customerId, 'region'),
    tapcustomerId: getProp(state, customerId, 'tapcustomerId'),
    isRelationshipBetweenContracteeAndCbCurrentlyActive,
    renderOperateForButton,
  }
}

export default connect(
  mapStateToProps,
)(PopulatedContracteeBrewerCard)
