/* eslint-disable max-len */

import React from 'react'

import { Card } from 'semantic-ui-react'
import ContactCard from '../../../common-components/ContactCard'
import UiContainer from '../../../common-components/UiContainer'
import VideoPlaylist from '../../../common-components/VideoPlaylist'

import {
    CUSTOMER_REPS_HELP_SECTION_SORT_ORDER,
} from '../../../constants'


export default UiContainer({
  headerText: 'Help',
  containerId: 'helpSection',
})(({
  customerId,
}) => (
  <div>
    <p>For general questions, feel free to reach out to your personalized customer representative (business hours are Mon-Fri, 8am-4pm).</p>

    <Card.Group itemsPerRow={5}>
      {CUSTOMER_REPS_HELP_SECTION_SORT_ORDER.map(repType => (
        <ContactCard
          key={repType}
          customerId={customerId}
          repType={repType}
          fromHelpSection
        />
      ))}
    </Card.Group>

    <p>For questions about how to do something in this web app, you may find the following video tutorials helpful:</p>
    <VideoPlaylist
      videos={[
        {
          name: 'Welcome',
          youtubeId: 'LzteQnKR16A',
          length: '2:10',
        },
        {
          name: 'Ordering Empty Kegs (Including Local Pickup)',
          youtubeId: '5Ef5JE0-BjQ',
          length: '2:57',
        },
        {
          name: 'Acknowledging Inbound Shipments',
          youtubeId: 'uwCqyUAlIV0',
          length: '2:34',
        },
        {
          name: 'Reporting Outbound Shipments',
          youtubeId: 'eit73pTJGl8',
          length: '2:31',
        },
        {
          name: 'Reporting Buybacks',
          youtubeId: 'uXmDHEeoHzM',
          length: '1:51',
        },
        {
          name: 'Pub and Self Distribution Shipments',
          youtubeId: 'YVGSRSPZRRY',
          length: '2:55',
        },
        {
          name: 'Correcting Shipments',
          youtubeId: 'PWcuDFI5CXw',
          length: '2:21',
        },
        {
          name: 'Explaining Local Distributors',
          youtubeId: '9vsuLrgaHMk',
          length: '2:30',
        },
        {
          name: 'History Section',
          youtubeId: 'h2xTRD132rE',
          length: '2:54',
        },
        {
          name: 'Reporting Monthly Inventory',
          youtubeId: '3zwtX3xJrP0',
          length: '4:49',
        },
        {
          name: 'Keg Collar Ordering',
          youtubeId: 'ft7ATsct0Ag',
          length: '1:48',
        },
      ]}
    />
  </div>
    ))
