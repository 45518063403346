import { call, put, select, takeEvery } from 'redux-saga/effects'
import moment from 'moment'


import { privateFetch } from '../../fetch'

import {
  getProp as getCurrentUserProp,
} from '../../../selectors/currentUser'
import {
  getProp as getCustomerProp,
} from '../../../selectors/customers'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'
import createAction from '../../../actions/createAction'

import {
  FETCH_REPORT_INDIVIDUAL_NO_MOVEMENT,
  FETCH_STATUSES_FORMS_REQUEST,
  FETCH_STATUSES_FORMS_SUCCESS,
  FETCH_STATUSES_FORMS_FAILURE,
  SAVE_NO_MOVEMENTS_HISTORY_ITEM,
} from '../../../actions/actionTypes'

import {
  FIELD_NAME_MONTH,
} from '../../../../constants/formAndApiUrlConfig/reportNoMovements'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  API_URL_PATH_REPORT_INDIVIDUAL_NO_MOVEMENTS,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  HISTORY_FORM_NAME_OUTBOUND_FULL_KEG_SHIPMENTS,
  HISTORY_FORM_NAME_KEG_FILLS,
} from '../../../../constants'

import {
  getDoesApiErrorSayNoMovementsCantBeReportedBecauseShipmentsHaveAlreadyBeenReportedForThisPeriod,
  getDoesApiErrorSayNoMovementsCantBeReportedBecauseItIsADuplicate,
} from '../../../../features/ReportShipments/common/ReportAndEditShipments/components/ReportNoMovements/util'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  createContractMetadataObject,
} from '../../util/contractMetadataObject'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'


import {
  formatDateForApiCall,
  convertMonthAndYearStringToMoment,
  parseCustIdAndOptionalConbrwCustIdReduxIdentifier,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  createFormNameForRedux,
  convertApiDateToMoment,
} from '../../../../utils'


export function* fetchReportIndividualNoMovements(action) {
  const {
    dispatchFetchStatuses,
    custIdAndOptionalConbrwCustIdReduxIdentifier,
  } = action.payload
  dispatchFetchStatuses(createAction(
    FETCH_STATUSES_FORMS_REQUEST,
    { target: [custIdAndOptionalConbrwCustIdReduxIdentifier] },
  ))
  const fetchConfig = yield call(createFetchConfig, action.payload)

  let response
  try {
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { response, ...action.payload })
}


function* doSuccess({
  customerId,
  operatingContractBrewerCustomerId,
  custIdAndOptionalConbrwCustIdReduxIdentifier,
  dispatchFetchStatuses,
  formType,
  response,
}) {
  yield call(
    saveNoMovementsHistoryObjectToReduxStoreIfMeetsCriteriaOfShipmentsHistoryForm,
    {
      customerId,
      operatingContractBrewerCustomerId,
      formType,
      response,
    },
  )
  dispatchFetchStatuses(createAction(
    FETCH_STATUSES_FORMS_SUCCESS,
    { target: [custIdAndOptionalConbrwCustIdReduxIdentifier] },
  ))
}


function* doFailure({
  error,
  dispatchFetchStatuses,
  custIdAndOptionalConbrwCustIdReduxIdentifier,
}) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_FORMS_FAILURE,
        target: [custIdAndOptionalConbrwCustIdReduxIdentifier],
      }),
      customDispatch: dispatchFetchStatuses,
      onlyLogErrorIf: () => (
        !getDoesApiErrorSayNoMovementsCantBeReportedBecauseShipmentsHaveAlreadyBeenReportedForThisPeriod({ error })
        && !getDoesApiErrorSayNoMovementsCantBeReportedBecauseItIsADuplicate({ error })
      ),
    },
  )
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REPORT_INDIVIDUAL_NO_MOVEMENT, fetchReportIndividualNoMovements],
]


// helper functions

function* createFetchConfig({
  customerId,
  operatingContractBrewerCustomerId,
  custIdAndOptionalConbrwCustIdReduxIdentifier,
  formValues,
}) {
  const apiRequestBody = yield call(
    createApiRequestBody,
    {
      customerId,
      operatingContractBrewerCustomerId,
      custIdAndOptionalConbrwCustIdReduxIdentifier,
      formValues,
    },
  )
  const fetchConfig = {
    path: API_URL_PATH_REPORT_INDIVIDUAL_NO_MOVEMENTS,
    data: apiRequestBody,
    method: 'POST',
    // Don't retry the call several times if the NoMovements report is for a
    // month that already has shipments or if it's a duplicate
    customErrorReactions: [
      {
        check: error => (
          getDoesApiErrorSayNoMovementsCantBeReportedBecauseShipmentsHaveAlreadyBeenReportedForThisPeriod({ error })
          || getDoesApiErrorSayNoMovementsCantBeReportedBecauseItIsADuplicate({ error })
        ),
        reaction: function mySaga(error) {
          // Throwing the error here prevents the call from being re-tried
          // multiple times, which is what we want. This error will be caught by
          // the try/catch block in this file that surrounds the privateFetch()
          // call; that catch block handles the rest of the error, calling this
          // file's doFailure() function.
          throw error
        },
        shouldThisErrorReactionBeCalledBeforeAnyUniversalErrorReactionsSoAsToAvoidUnnecessaryRefetches: true,
      },
    ],
  }

  // CODE_COMMENTS_92
  if (operatingContractBrewerCustomerId) {
    const headers = createHeadersForContracteeBrewerApiCall(customerId)
    fetchConfig.headers = headers
  }

  return fetchConfig
}


function* createApiRequestBody({
  customerId,
  operatingContractBrewerCustomerId,
  custIdAndOptionalConbrwCustIdReduxIdentifier,
  formValues,
}) {
  const reportingPeriodStartDate = createReportingPeriodForNoMovementsReport({ formValues })
  const dateReported = formatDateForApiCall({ date: moment() })
  // eslint-disable-next-line no-underscore-dangle
  const _contractMetadataObject = yield call(
    createContractMetadataObjectForNoMovementsReport,
    {
      customerId,
      operatingContractBrewerCustomerId,
      custIdAndOptionalConbrwCustIdReduxIdentifier,
    },
  )
  const userId = yield select(getCurrentUserProp, 'userId')
  const { customerId: sourceCustomerId } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(
    custIdAndOptionalConbrwCustIdReduxIdentifier,
  )


  const requestBody = assembleApiRequestBodyObj({
    sourceCustomerId,
    reportingPeriodStartDate,
    dateReported,
    userId,
    _contractMetadataObject,
  })

  return requestBody
}


function assembleApiRequestBodyObj({
  sourceCustomerId,
  reportingPeriodStartDate,
  dateReported,
  userId,
  _contractMetadataObject,
}) {
  return {
    customerId: sourceCustomerId,
    reportingPeriodStartDate,
    dateReported,
    reportingUserId: userId,
    _contractMetadataObject,
  }
}

// Returns Noon UTC the first day of the month selected
function createReportingPeriodForNoMovementsReport({ formValues }) {
  const date = convertMonthAndYearStringToMoment(formValues[FIELD_NAME_MONTH])
  return formatDateForApiCall({
    date,
  })
}

// CODE_COMMENTS_133
function* createContractMetadataObjectForNoMovementsReport({
  // Here, customerId is the customer currently being operated for (whether it's
  // a child currently being operated for by a Master or a contractee currently
  // being operated for by a Contract Brewer). If no customer is currently being
  // operated for, this is just the logged-in customer.
  customerId,
  operatingContractBrewerCustomerId,
  custIdAndOptionalConbrwCustIdReduxIdentifier,
}) {
  const customerType = yield select(getCustomerProp, customerId, 'customerType')
  const {
    contractBrewerCustomerId: conbrwCustIdFromReduxIdentifier,
  } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(
    custIdAndOptionalConbrwCustIdReduxIdentifier,
  )

  if (
    // When a logged-in Contract Brewer operates on behalf of one of its
    // contractees
    operatingContractBrewerCustomerId
    // when a logged-in Brewer reports an outbound shipment from one of its
    // Contract Brewer locations.
    || (customerType === CUSTOMER_TYPES_BREWER && conbrwCustIdFromReduxIdentifier)
  ) {
    return createContractMetadataObject(
      operatingContractBrewerCustomerId || conbrwCustIdFromReduxIdentifier,
      null,
    )
  }

  // Keg Fills
  if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    return createContractMetadataObject(customerId, customerId)
  }

  // For any type of shipment not covered above
  return createContractMetadataObject('BRW', null)
}


/*
 * *****************************************************************************
 * Save History Object returned from successful POST/PUT to Redux Store
 * *****************************************************************************
 */

function* saveNoMovementsHistoryObjectToReduxStoreIfMeetsCriteriaOfShipmentsHistoryForm({
  customerId,
  operatingContractBrewerCustomerId,
  formType,
  response,
}) {
  const noMovementsObj = response.data
  const historyFormReducerName = formType === 'kegFills'
    ? HISTORY_FORM_NAME_KEG_FILLS
    : HISTORY_FORM_NAME_OUTBOUND_FULL_KEG_SHIPMENTS

  const historyFormName = createFormNameForRedux({
    reducerName: historyFormReducerName,
    customerId,
    operatingContractBrewerCustomerId,
  })

  const doesNoMovementsReportObjectMeetCriteriaOfShipmentsHistoryForm = yield call(
    getDoesNoMovementsReportObjectMeetCriteriaOfShipmentsHistoryForm,
    {
      historyFormName,
      noMovementsObj,
    },
  )

  if (doesNoMovementsReportObjectMeetCriteriaOfShipmentsHistoryForm) {
    yield put(createAction(
      SAVE_NO_MOVEMENTS_HISTORY_ITEM,
      {
        savePath: [createCustIdAndOptionalConbrwCustIdReduxIdentifier(
          customerId,
          operatingContractBrewerCustomerId,
        )],
        info: noMovementsObj,
      },
    ))
  }
}


function* getDoesNoMovementsReportObjectMeetCriteriaOfShipmentsHistoryForm({
  historyFormName,
  noMovementsObj,
}) {
  const mostRecentlySubmittedFormValues = yield select(getMostRecentlySubmittedFormValues, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return false }

  const historyFormStartDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_START_DATE]
  const historyFormEndDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_END_DATE]

  const startOfMonthOfHistoryFormStartDate = moment.utc(historyFormStartDate, DEFAULT_DISPLAYED_DATE_FORMAT).startOf('month')
  const enOfMonthOfHistoryFormEndDate = moment.utc(historyFormEndDate, DEFAULT_DISPLAYED_DATE_FORMAT).endOf('month')

  const dateOfNoMovementsObj = convertApiDateToMoment(
    noMovementsObj.reportingPeriodStartDate,
    true, // use UTC
  )
  return dateOfNoMovementsObj.isBetween(startOfMonthOfHistoryFormStartDate, enOfMonthOfHistoryFormEndDate)
}
