import React from 'react'
import { Table } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import {
  FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS,

  FIELD_NAME_SHIPMENT_ID,
  FIELD_NAME_CARRIER,
  FIELD_NAME_DATE_SHIPPED,
  FIELD_NAME_SOURCE,

  FIELD_LABEL_SHIPMENT_ID,
  FIELD_LABEL_CARRIER,
  FIELD_LABEL_DATE_SHIPPED,
  FIELD_LABEL_SOURCE,
} from '../../../../../constants/formAndApiUrlConfig/acknowledgeInboundShipments'

import {
  getInitialValues,
} from '../../../../AcknowledgeInboundShipments/util'


export default ({
  entireSubsidiariesSlice,
  subsidiaryId,
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
}) => {
  const initialValues = getInitialValues({
    entireSubsidiariesSlice,
    subsidiaryId,
    inboundUnacknowledgedShipmentsSliceForThisCustomer,
  })
  const { t: translate } = useTranslation('common')
  const displayedColumns = [
    { fieldLabel: FIELD_LABEL_SHIPMENT_ID, cellContent: o => o[FIELD_NAME_SHIPMENT_ID] },
    { fieldLabel: FIELD_LABEL_CARRIER, cellContent: o => o[FIELD_NAME_CARRIER] }, // CODE_COMMENTS_86
    { fieldLabel: FIELD_LABEL_DATE_SHIPPED, cellContent: o => o[FIELD_NAME_DATE_SHIPPED] },
    { fieldLabel: FIELD_LABEL_SOURCE, cellContent: o => o[FIELD_NAME_SOURCE] },
  ]

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          {displayedColumns.map(displayedColumn => (
            <Table.HeaderCell key={displayedColumn.fieldLabel}>
              {translate(displayedColumn.fieldLabel)}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {initialValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS].map(shipmentInfo => (
          <Table.Row key={shipmentInfo.id}>
            {displayedColumns.map(displayedColumn => (
              <Table.Cell key={displayedColumn.fieldLabel}>
                {displayedColumn.cellContent(shipmentInfo)}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
