import React, { Fragment } from 'react'
import { Icon, Button, List } from 'semantic-ui-react'

import get_ from 'lodash/get'

import PopupWithCatastrophicFailureErrorBoundary from '../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'

import {
  getDoesFormRowHaveUnresolvedPotentialDuplicates,
  POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS,
  POTENTIAL_DUPLICATES_OTHER_FORM_ROWS,
} from '../util/potentialDuplicates'

import {
  isTruthyAndNonEmpty,
  formatArrayOfStringsAsNaturalLanguagePhrase,
} from '../../../../../../utils'


export default props => {
  const {
    potentialDuplicates,
    onManuallyMarkPotentialDuplicateAsNotDuplicate,
    fieldArrayName,
    rowIndex,
  } = props
  const potentialDuplicatesOfRow = get_(potentialDuplicates, [fieldArrayName, rowIndex])
  if (!getDoesFormRowHaveUnresolvedPotentialDuplicates(potentialDuplicatesOfRow)) {
    return null
  }
  return (
    <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      wide
      trigger={
        <Icon
          link
          name="exclamation triangle"
          size="large"
          color="orange"
        />
      }
      on="click"
    >
      <Fragment>
        <DuplicatesMessage
          potentialDuplicatesOfRow={potentialDuplicatesOfRow}
        />
        <div
          style={{ textAlign: 'center' }}
        >
          <Button
            compact
            color='green'
            onClick={() => onManuallyMarkPotentialDuplicateAsNotDuplicate(props)}
            style={{ marginTop: '0.5rem' }}
          >
            This is intentional
          </Button>
        </div>

      </Fragment>
    </PopupWithCatastrophicFailureErrorBoundary>
  )
}


const DuplicatesMessage = ({
  potentialDuplicatesOfRow,
}) => {
  const previouslySubmittedDuplicates = potentialDuplicatesOfRow[POTENTIAL_DUPLICATES_PREVIOUSLY_SUBMITTED_SHIPMENTS]
  const otherRowsDuplicates = potentialDuplicatesOfRow[POTENTIAL_DUPLICATES_OTHER_FORM_ROWS]

  const hasPreviouslySubmittedDupes = isTruthyAndNonEmpty(previouslySubmittedDuplicates)
  const hasOtherRowsDupes = isTruthyAndNonEmpty(otherRowsDuplicates)

  return (
    <Fragment>
      {hasPreviouslySubmittedDupes &&
        <PreviouslySubmittedDuplicatesMessage previouslySubmittedDuplicates={previouslySubmittedDuplicates} />
      }
      {hasPreviouslySubmittedDupes && hasOtherRowsDupes ? 'Also, ' : ''}
      {hasOtherRowsDupes &&
        <OtherRowsDuplicatesMessage
          otherRowsDuplicates={otherRowsDuplicates}
          capitalizeFirstCharOfMessage={!hasPreviouslySubmittedDupes}
        />
      }
    </Fragment>
  )
}


const PreviouslySubmittedDuplicatesMessage = ({
  previouslySubmittedDuplicates, // array
}) => {
  const moreThanOne = previouslySubmittedDuplicates.length > 1
  const message = `${moreThanOne ? `${previouslySubmittedDuplicates.length} shipments` : 'A shipment'} with this same origin, destination, ship date and quantities ${moreThanOne ? 'have' : 'has'} already been submitted:`
  const duplicatesStrings = previouslySubmittedDuplicates.map(o => (
    <span>
      {o.createdBy
        ? (
          <Fragment>
            {'by '}
            <span style={{ fontWeight: 'bold' }}>{o.createdBy}</span>
          </Fragment>
        )
        : null
      }
      {o.createdBy ? ' on ' : 'Reported on '}
      <span style={{ fontWeight: 'bold' }}>{o.dateReported}</span>
      {', Ref# '}
      <span style={{ fontWeight: 'bold' }}>{o.proNumber}</span></span>
  ))
  return (
    <Fragment>
      <p>{message}</p>
      <List bulleted>
        {duplicatesStrings.map((li, index) => (
          <List.Item key={previouslySubmittedDuplicates[index].proNumber}>{li}</List.Item>
        ))}
      </List>
    </Fragment>
  )
}

const OtherRowsDuplicatesMessage = ({
  otherRowsDuplicates, // array
  capitalizeFirstCharOfMessage=true,
}) => {
  const otherRowsStrings = otherRowsDuplicates.sort().map(index => {
    const rowNumber = index+1
    return rowNumber.toString()
  })
  return `${capitalizeFirstCharOfMessage ? 'T' : 't'}his is a potential duplicate of row${otherRowsDuplicates.length > 1 ? 's' : ''} ${formatArrayOfStringsAsNaturalLanguagePhrase(otherRowsStrings)}.`
}
