import { call, takeEvery } from 'redux-saga/effects'


import { fetchEditContact } from './editContact'

import {
  FETCH_DELETE_CONTACT,
} from '../../../actions/actionTypes'


function* fetchDeleteContact(action) {
  const newAction = {
    ...action,
    payload: {
      ...action.payload,
      isDeleteContact: true,
    },
  }
  const toReturn = yield call(fetchEditContact, newAction)
  return toReturn
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_DELETE_CONTACT, fetchDeleteContact],
]
