/* eslint-disable max-len */

import React, { Fragment, useState, useEffect, useCallback } from 'react'
import {
  Form,
  Button,
  Header,
  Grid,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react'

import get_ from 'lodash/get'


import { connect } from 'react-redux'
import MyModal from '../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/MyModal'
import PhoneNumberInput from '../../../../common-components/PhoneNumberInput'
import InputNoAutoComplete from '../../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import InformationalPopup from '../../../../common-components/InformationalPopup'
// import ContentToTheRightOfAFormField from '../../../../common-components/ContentToTheRightOfAFormField'
import FetchFailureUnknownErrorContent from '../../../../common-components/FormSubmission/DismissableMessageFailuresContent/FetchFailureUnknownErrorContent'
// import RevealContactInfo from '../../../../common-components/RevealContactInfo'

import ChooseReplacementDefaultShippingContactOrInvoiceRecipient from '../../ChooseReplacementDefaultShippingContactOrInvoiceRecipient'

import {
  FETCH_UNSUBSCRIPTION,
} from '../../../../redux/actions/actionTypes'

import createAction from '../../../../redux/actions/createAction'

import {
  createCustomerAndEmailSubscriptionIdentifier,
  DISPLAYED_CONTACT_TYPE_BILLING, DISPLAYED_CONTACT_TYPE_SHIPPING,
  DISPLAYED_CONTACT_TYPE_SHIPPING_AND_BILLING,
  DISPLAYED_CONTACT_TYPES,
  // DISPLAYED_INFORMATIONAL_MESSAGE_CONTACT_TYPE,
  // DISPLAYED_INFORMATIONAL_MESSAGE_DEFAULT_SHIPPING_CONTACT,
} from '../../constants'

import {
  getIsContactTheOnlyInvoiceRecipient,
  getHumanReadableContactTypeFromContactMashupObj,
} from '../../utils'

// import {
//   CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
// } from '../../../../constants'

import {
  getIsStringAValidEmailAddress,
  getIsPossiblePhoneNumber,
  isTruthyAndNonEmpty,
} from '../../../../utils'

import './styles.css'
import HorizontalFormSectionAsLabeledGrid from '../../../../common-components/HorizontalFormSectionAsLabeledGrid'
// import { getAllContactObjectsOfCustomer } from '../../../../redux/selectors/contacts'
// import { getAllContactObjectsOfCustomer } from '../../../../redux/selectors/contacts'

const labelWidth = '160px' // CODE_COMMENTS_22

const contactTypeDropdownOptions = (disableType = {}) => DISPLAYED_CONTACT_TYPES.map(s => ({
  key: s,
  text: s,
  value: s,
  disabled: disableType[s] || false,
}))

// const dummyPhoneNum = '+18002442450' // USA
// const dummyPhoneNum = '+14167765495' // Canada
// const dummyPhoneNum = '+445544334454' // UK
// const dummydeskPhoneExt = '123'

/* function getInitialCustomerEmailProps({
  customersWithSameContact,
  emailSubscriptionProps,
  entireContactsSlice,
  entireCustomerContactLinksSlice,
  customerType,
}) {
  const arr = {}
  emailSubscriptionProps?.forEach(prop => (
    customersWithSameContact?.forEach(customer => {
      const customerProps = getAllContactObjectsOfCustomer({
        customerType,
        customerId: customer.customerId,
        entireContactsSlice,
        entireCustomerContactLinksSlice,
      })?.find(obj => obj.contactId === customer.contactId)
      arr[createCustomerAndEmailSubscriptionIdentifier(customer?.customerId, prop?.name)] = get_(customerProps, prop.name, false)
    })
  ))
  return arr
} */

function getInitialFormValues({
  contact,
  customersWithSameContact,
}) {
  return {
    id: get_(contact, 'id', null),
    lastBounceDate: get_(contact, 'lastBounceDate', null),
    lastDeliveryDate: get_(contact, 'lastDeliveryDate', null),
    active: get_(contact, 'active', null),
    firstName: get_(contact, 'firstName', ''),
    lastName: get_(contact, 'lastName', ''),
    emailAddress: get_(contact, 'emailAddress', ''),
    emailDeliveryStatus: get_(contact, 'emailDeliveryStatus', null),
    emailDeliveryFailureCounter: get_(contact, 'emailDeliveryFailureCounter', null),
    contactType: getHumanReadableContactTypeFromContactMashupObj(contact),
    defaultShippingContact: get_(contact, 'defaultShippingContact', false),
    replacementDefaultShippingContactId: null,
    replacementInvoiceRecipientContactId: null,
    deskPhone: get_(contact, 'deskPhone', ''),
    deskPhoneExt: get_(contact, 'deskPhoneExt', ''),
    cellPhone: get_(contact, 'cellPhone', ''),
    faxNumber: get_(contact, 'faxNumber', ''),
    customerContactObjects: [...customersWithSameContact],
  }
}

function leftLabels({ customersWithSameContact }) {
  return customersWithSameContact?.map(row => ({
    label: row?.customerName,
  }))
}

function topLabels(topLabelFields) {
  return topLabelFields?.map(row => ({
    label: row?.displayedName,
    // arbitraryContentAfterLabel: <InformationalPopup content={row?.explanation} />,
  }))
}

function rowsForHorizontalFormSectionAsLabeledGrid({
  customersWithSameContact,
  topLabelFields,
  setCheckBoxFormValue,
  formValues,
}) {
  const rowCheckBoxes = customersWithSameContact?.map(customer => {
    const { customerContactObjects } = formValues
    const customerObj = customerContactObjects.find(obj => obj?.customerId === customer?.customerId)
    return topLabelFields?.map(prop => {
      // refer value by index. Since array maintains order.
      // Update value by customer.
      // name prop - not used. Simpley for reference
      const { customerId } = customer
      const { name, Component } = prop
      let value = customerObj?.[name] // refer index
      if (Component === Dropdown) {
        value = getHumanReadableContactTypeFromContactMashupObj(customerObj)
        return (
          <Component
            options={contactTypeDropdownOptions()}
            value={value}
            onChange={(e, { value: val }) => setCheckBoxFormValue(customerId, name)(val)}
            search
            selection
            name="contactType"
            style={{ minWidth: '8em' }} // Matches the DeskPhone width
          />
        )
      }
      return (
        <Checkbox
          onChange={() => setCheckBoxFormValue(customerId, name)(!value)} // update by value
          checked={value}
          name={createCustomerAndEmailSubscriptionIdentifier(customerId, name)}
        />
      )
    })
  })
  return rowCheckBoxes
}

const NoLoginAddOrEditContacts = ({
  token,
  modalTrigger,
  isEditForm,
  // all contacts currently being displayed to the user. Pass this in whether
  // isEditForm or not.
  contacts,
  contact, // The object from the Redux store; only pass in if isEditForm
  emailSubscriptionProps, // getApplicableEmailSubscriptionPropsBasedOnCustomerType()
  dispatch,
  customersWithSameContact,
  entireContactsSlice,
  entireCustomerContactLinksSlice,
}) => {
  const [formValues, setFormValues] = useState(getInitialFormValues({
    contact,
    emailSubscriptionProps,
    customersWithSameContact,
    entireContactsSlice,
    entireCustomerContactLinksSlice,
  }))
  // usage: setFormValue('firstName')('Mark')
  const setFormValue = name => value => setFormValues({ ...formValues, [name]: value })
  const setCheckBoxFormValue = (name, ref) => value => {
    const newFormValues = { ...formValues }
    const { customerContactObjects } = newFormValues
    const newCustomerContactObjects = customerContactObjects?.filter(customerContactObject => customerContactObject?.customerId !== name)
    const newCustomerContactObject = Object.assign({}, customerContactObjects?.find(customerContactObject => customerContactObject?.customerId === name))

    if (ref === 'contactType') {
      if (value === DISPLAYED_CONTACT_TYPE_SHIPPING_AND_BILLING) {
        newCustomerContactObject.shippingContact = true
        newCustomerContactObject.billingContact = true
      } else if (value === DISPLAYED_CONTACT_TYPE_BILLING) {
        newCustomerContactObject.shippingContact = false
        newCustomerContactObject.billingContact = true
      } else if (value === DISPLAYED_CONTACT_TYPE_SHIPPING) {
        newCustomerContactObject.shippingContact = true
        newCustomerContactObject.billingContact = false
      }
    } else {
      newCustomerContactObject[ref] = value
    }
    newCustomerContactObjects.push(newCustomerContactObject)
    setFormValues({ ...formValues, customerContactObjects: [...newCustomerContactObjects], dataChanged: true })
  }

  const [hasUserBlurredFirstNameField, setHasUserBlurredFirstNameField] = useState(false)
  const [hasUserBlurredLastNameField, setHasUserBlurredLastNameField] = useState(false)
  const [hasUserBlurredEmailField, setHasUserBlurredEmailField] = useState(false)

  const [isFetching, setIsFetching] = useState(false)
  const [didFetchFail, setDidFetchFail] = useState(false)
  const [fetchErrorDetails, setFetchErrorDetails] = useState({})
  // const [disableType, setDisableType] = useState({})
  const topLabelFields = [{ name: 'contactType', displayedName: 'Type', Component: Dropdown }, ...emailSubscriptionProps, { name: 'defaultShippingContact', displayedName: 'Default Shipping Contact?' }]

  const resetFormValuesToInitialValues = useCallback(
    () => {
      setFormValues(getInitialFormValues({
        contact,
        emailSubscriptionProps,
        customersWithSameContact,
        entireContactsSlice,
        entireCustomerContactLinksSlice,
      }))
      setHasUserBlurredFirstNameField(false)
      setHasUserBlurredLastNameField(false)
      setHasUserBlurredEmailField(false)
    },
    [
      contact,
      emailSubscriptionProps,
      customersWithSameContact,
      entireContactsSlice,
      entireCustomerContactLinksSlice,
    ],
  )


  useEffect(() => {
    if (formValues.defaultShippingContact && formValues?.contactType === DISPLAYED_CONTACT_TYPE_BILLING) {
      setFormValue('contactType')(DISPLAYED_CONTACT_TYPE_SHIPPING_AND_BILLING)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.defaultShippingContact])

  useEffect(
    // When the user updates a contact, we need to update the default form info
    () => { resetFormValuesToInitialValues() },
    [contact, resetFormValuesToInitialValues],
  )

  useEffect(
    () => {
      const currentDefShipContact = contacts.find(o => o.defaultShippingContact)
      // setCurrentDefaultShippingContact(currentDefShipContact || {})
      // Let's say a backend bug happens in which a customer currently has no
      // default shipping contact. The UI needs to prevent the user from adding
      // or editing a contact without checking its "Default Shipping Contact"
      // checkbox, because otherwise the backend will return an error saying
      // "Customer must have exactly one default shipping contact". Therefore,
      // if there's currently no default shipping contact, whichever contact the
      // end user edits (or if they add a new one), they MUST check the
      // contact's default shipping contact. So we set it for them, and we'll
      // disable this checkbox, preventing the user from unchecking it (we also
      // provide a "why can't I uncheck this?" informational popup making it
      // clear to the user what's going on).
      if (!isTruthyAndNonEmpty(currentDefShipContact)) {
        setFormValue('defaultShippingContact')(true)
      }
      // Why this check? When a user edits the default shipping contact, when
      // the saga unsets the old default shipping contact then sets the new
      // default shipping contact, that render tick in between these two events
      // needs to be considered.
      if (
        isTruthyAndNonEmpty(currentDefShipContact)
        && isTruthyAndNonEmpty(contact)
        && currentDefShipContact.id !== contact.id
      ) {
        setFormValue('defaultShippingContact')(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contacts],
  )

  const propsForValidationAndFormSubmission = {
    // customerId,
    contacts,
    contact,
    emailSubscriptionProps,
    isEditForm,
    formValues,
    entireCustomerContactLinksSlice,
  }

  const isContactTheOnlyInvoiceRecipient = getIsContactTheOnlyInvoiceRecipient({ contact, contacts })
  const isFormSubmittable = getIsFormSubmittable(propsForValidationAndFormSubmission)
  const areCurrentValuesSameAsInitialValues = getAreCurrentValuesSameAsInitialValues(propsForValidationAndFormSubmission)

  const unsubscribe = closeModal => {
    const { customerContactObjects = [] } = formValues || {}
    const newCustomerContactObjects = customerContactObjects?.map(customerContactObject => {
      const obj = { ...customerContactObject }
      delete obj.customerName
      return {
        ...obj,
        receiveDistInvReportForm: false,
        receiveInvoice: false,
        receiveOrderUpdates: false,
        receiveShipments: false,
      }
    })
    const newFormValues = { ...formValues, customerContactObjects: newCustomerContactObjects }
    setIsFetching(true)
    dispatch(createAction(
      FETCH_UNSUBSCRIPTION,
      {
        ...propsForValidationAndFormSubmission,
        formValues: newFormValues,
        entireCustomerContactLinksSlice,
        token,
        onFetchSuccess: () => {
          setIsFetching(false)
          // For when a new contact is added
          // setFormValues(getInitialFormValues({
          //   contact,
          //   emailSubscriptionProps,
          //   customersWithSameContact,
          //   entireContactsSlice,
          //   entireCustomerContactLinksSlice,
          // }))
          closeModal()
        },
        onFetchFailure: errorDetails => {
          setFetchErrorDetails(errorDetails)
          setIsFetching(false)
          setDidFetchFail(true)
        },
      },
    ))
  }
  const submitForm = closeModal => {
    const { customerContactObjects = [] } = formValues || {}
    const newCustomerContactObjects = customerContactObjects?.map(customerContactObject => {
      const obj = { ...customerContactObject }
      delete obj.customerName
      return {
        ...obj,
      }
    })
    const newFormValues = { ...formValues, customerContactObjects: newCustomerContactObjects }
    setIsFetching(true)
    dispatch(createAction(
      FETCH_UNSUBSCRIPTION,
      {
        ...propsForValidationAndFormSubmission,
        formValues: newFormValues,
        entireCustomerContactLinksSlice,
        token,
        onFetchSuccess: () => {
          setIsFetching(false)
          // For when a new contact is added
          setFormValues(getInitialFormValues({
            contact,
            emailSubscriptionProps,
            customersWithSameContact,
            entireContactsSlice,
            entireCustomerContactLinksSlice,
          }))
          setHasUserBlurredFirstNameField(false)
          setHasUserBlurredLastNameField(false)
          setHasUserBlurredEmailField(false)
          // closeModal() must come after all other values are set. I'm not sure
          // why, but if you closeModal() before resetting values to their
          // initial values, if the user immediately opens the edit modal again,
          // the values will be what they were BEFORE the user edited them this
          // most recent time.
          closeModal()
        },
        onFetchFailure: errorDetails => {
          setFetchErrorDetails(errorDetails)
          setIsFetching(false)
          setDidFetchFail(true)
        },
      },
    ))
  }

  return (
    <MyModal
      // When the user closes the modal, we want the form values to return to
      // their initial values.
      size="large"
      onClose={resetFormValuesToInitialValues}
      modalTitle={() => (
        <Header as="h4">
          {isEditForm
            ? <span>Edit Subscriptions: <span style={{ fontStyle: 'italic' }}>{`${contact.firstName} ${contact.lastName}`}</span></span>
            : <span>Add New Contact</span>
          }
        </Header>
      )}
      modalContent={({ closeModal }) => (
        <Fragment>
          <Form>
            <Grid columns={4}> {/* CODE_COMMENTS_22, CODE_COMMENTS_97 */}
              <Grid.Row>
                <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                  <Form.Field required>
                    <label htmlFor="firstName">
                      First Name
                    </label>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column style={{ flex: '1' }}>
                  <div className="ui input"> {/* CODE_COMMENTS_32 */}
                    <InputNoAutoComplete
                      disabled
                      name="firstName"
                      value={formValues.firstName}
                      onChange={e => {
                        const input = e.target.value
                        if (input.length <= 30) { // CODE_COMMENTS_192
                          setFormValue('firstName')(input)
                        }
                      }}
                      style={{ width: '15rem' }} // Matches the CellPhone width
                      onBlur={() => { setHasUserBlurredFirstNameField(true) }}
                      className={
                        (
                          isEditForm && !formValues.firstName
                        )
                        || (
                          !isEditForm && hasUserBlurredFirstNameField && !formValues.firstName
                        )
                          ? 'input-error-semantic-ui-container'
                          : null
                      }
                    />
                  </div>
                </Grid.Column>
                <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                  <Form.Field required>
                    <label htmlFor="lastName">
                      Last Name
                    </label>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column style={{ flex: '1' }}>
                  <div className="ui input"> {/* CODE_COMMENTS_32 */}
                    <InputNoAutoComplete
                      disabled
                      name="lastName"
                      value={formValues.lastName}
                      onChange={e => {
                        const input = e.target.value
                        if (input.length <= 50) { // CODE_COMMENTS_192
                          setFormValue('lastName')(input)
                        }
                      }}
                      style={{ width: '15rem' }} // Matches the CellPhone width
                      onBlur={() => { setHasUserBlurredLastNameField(true) }}
                      className={
                        (
                          isEditForm && !formValues.lastName
                        )
                        || (
                          !isEditForm && hasUserBlurredLastNameField && !formValues.lastName
                        )
                          ? 'input-error-semantic-ui-container'
                          : null
                      }
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                  <Form.Field>
                    <label htmlFor="deskPhone">
                      Desk Phone
                    </label>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column style={{ flex: '1' }}>
                  <div className="ui input"> {/* CODE_COMMENTS_32 */}
                    <PhoneNumberInput
                      disabled
                      name="deskPhone"
                      initialValue={isEditForm && contact.deskPhone}
                      value={formValues.deskPhone}
                      onChange={setFormValue('deskPhone')}
                      includeExtension
                      extensionValue={formValues.deskPhoneExt}
                      onExtensionChange={setFormValue('deskPhoneExt')}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                  <Form.Field>
                    <label htmlFor="cellPhone">
                      Cell Phone
                    </label>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column style={{ flex: '1' }}>
                  <div className="ui input"> {/* CODE_COMMENTS_32 */}
                    <PhoneNumberInput
                      disabled
                      name="cellPhone"
                      initialValue={isEditForm && contact.cellPhone}
                      value={formValues.cellPhone}
                      onChange={setFormValue('cellPhone')}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                  <Form.Field>
                    <label htmlFor="faxNumber">
                      Fax Number
                    </label>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column style={{ flex: '1' }}>
                  <div className="ui input"> {/* CODE_COMMENTS_32 */}
                    <PhoneNumberInput
                      disabled
                      name="faxNumber"
                      initialValue={isEditForm && contact.faxNumber}
                      value={formValues.faxNumber}
                      onChange={setFormValue('faxNumber')}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                  <Form.Field required>
                    <label htmlFor="emailAddress">
                      Email
                    </label>
                  </Form.Field>
                </Grid.Column>
                <Grid.Column style={{ flex: '1' }}>
                  <div className="ui input"> {/* CODE_COMMENTS_32 */}
                    <InputNoAutoComplete
                      name="emailAddress"
                      value={formValues.emailAddress}
                      onChange={e => {
                        const input = e.target.value
                        if (input.length <= 100) { // CODE_COMMENTS_192
                          const inputNoWhitespace = input && input.replace(/ /g, '')
                          setFormValue('emailAddress')(inputNoWhitespace)
                        }
                      }}
                      onBlur={() => { setHasUserBlurredEmailField(true) }}
                      className={(!formValues.emailAddress || !getIsStringAValidEmailAddress(formValues.emailAddress)) && hasUserBlurredEmailField
                        ? 'input-error-semantic-ui-container'
                        : null
                      }
                      style={{ width: '28rem' }} // Matches the DeskPhone width
                      disabled // CODE_COMMENTS_278
                    />
                  </div>
                  {/* <InformationalPopup */}
                  {/*   includeTriggerIcon={false} */}
                  {/*   includeTriggerText */}
                  {/*   triggerText="Why can't I edit the email address?" */}
                  {/*   content={( */}
                  {/*     <Fragment> */}
                  {/*       <p> */}
                  {/*         {"To prevent email address duplication, users aren't allowed to edit email addresses themselves. Reach out to your Customer Experience rep if you need to edit this contact's email address."} */}
                  {/*       </p> */}
                  {/*     </Fragment> */}
                  {/*   )} */}
                  {/*   triggerTextProps={{ */}
                  {/*     // With display set to 'block', the trigger text appears */}
                  {/*     // underneath the email input field rather than beside it, */}
                  {/*     // which is what we want. But it has the side effect that */}
                  {/*     // the trigger text field now stretches the entire width */}
                  {/*     // of the Edit Contact form rather than just the length of */}
                  {/*     // the text content; This doesn't affect the look of the */}
                  {/*     // trigger text itself, but it makes the popup offset. */}
                  {/*     // Setting to 'inline-block' fixes this. */}
                  {/*     style: { display: 'inline-block' }, */}
                  {/*     className: 'small-text', // same as <small> tag. */}
                  {/*   }} */}
                  {/* /> */}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                  <Form.Field>
                    <label htmlFor="getOrderUpdateEmails">
                      Email Subscriptions
                    </label>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{ flex: '1' }}>
                  <HorizontalFormSectionAsLabeledGrid
                    topLabels={topLabels(topLabelFields)}
                    leftLabels={leftLabels({ customersWithSameContact })}
                    rows={rowsForHorizontalFormSectionAsLabeledGrid({ setCheckBoxFormValue, formValues, topLabelFields, customersWithSameContact })}
                  />
                </Grid.Column>
              </Grid.Row>


              {isContactTheOnlyInvoiceRecipient && !formValues.receiveInvoice &&
                <Grid.Row>
                  <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                    <Form.Field
                      required
                      style={{ margin: '0' }}
                    >
                      <label
                        htmlFor="replacementInvoiceRecipientContactId"
                      >
                        New Invoices Recipient:
                        <InformationalPopup
                          content="At least one contact must receive Invoice emails."
                        />
                      </label>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column style={{ flex: '1' }}>
                    <div className="ui input"> {/* CODE_COMMENTS_32 */}
                      <ChooseReplacementDefaultShippingContactOrInvoiceRecipient
                        contact={contact}
                        contacts={contacts}
                        value={formValues.replacementInvoiceRecipientContactId}
                        setValue={setFormValue('replacementInvoiceRecipientContactId')}
                        style={{ marginLeft: '0.5rem' }}
                        placeholder='Choose New'
                        name="replacementInvoiceRecipientContactId"
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              }


            </Grid>

            <Button
              type="submit"
              disabled={!isFormSubmittable}
              onClick={() => { submitForm(closeModal) }}
              color={isFormSubmittable && !isFetching ? 'green' : null}
              loading={isFetching}
            >
              {isEditForm && areCurrentValuesSameAsInitialValues
                ? 'No Values Changed'
                : 'Submit'
              }
            </Button>
            <Button
              loading={isFetching}
              disabled={!formValues?.active}
              onClick={() => unsubscribe(closeModal)}
              color='red'
            >
              Unsubscribe All
            </Button>
            <Button
              floated='right'
              onClick={() => {
                resetFormValuesToInitialValues()
                // closeModal() must come after all other values are reset. See
                // the note above that uses the same pattern for why.
                closeModal()
              }}
              color='red'
            >
              Cancel
            </Button>
          </Form>
          {didFetchFail && !isFetching &&
            <FetchFailureUnknownErrorContent
              // customerId={customerId}
              errorDetails={fetchErrorDetails}
              onDismiss={() => { setDidFetchFail(false) }}
            />
          }
        </Fragment>
      )}
      trigger={modalTrigger}
    />
  )
}


function getIsFormSubmittable(props) {
  const { isEditForm } = props
  if (isEditForm) {
    return getIsFormSubmittableEditContact(props)
  }
  return getIsFormSubmittableAddNewContact(props)
}


function getIsFormSubmittableAddNewContact({
  formValues,
}) {
  const {
    firstName,
    lastName,
    contactType,
    deskPhone,
    cellPhone,
    faxNumber,
  } = formValues
  const strPropsThatMustNotBeBlank = [
    firstName,
    lastName,
    // emailAddress, // why commented out? CODE_COMMENTS_278
    contactType,
  ]

  const propsThatMustBeValidEmailAddresses = [
    // emailAddress, // why commented out? CODE_COMMENTS_278
  ]

  const propsThatMustBeEitherBlankOrPossiblePhoneNumbers = [
    deskPhone,
    cellPhone,
    faxNumber,
  ]

  return (
    strPropsThatMustNotBeBlank.every(s => s && s.trim() !== '')
    && propsThatMustBeValidEmailAddresses.every(s => s && getIsStringAValidEmailAddress(s))
    && propsThatMustBeEitherBlankOrPossiblePhoneNumbers.every(s => getIsPossiblePhoneNumber(s))
  )
}


function getIsFormSubmittableEditContact(props) {
  const {
    // contact,
    // contacts,
    formValues,
  } = props

  const {
    // firstName,
    // lastName,
    // // emailAddress, // why commented out? CODE_COMMENTS_278
    // contactType,
    // defaultShippingContact,
    // replacementDefaultShippingContactId,
    // replacementInvoiceRecipientContactId,
    // receiveInvoice,
    // deskPhone,
    // cellPhone,
    // faxNumber,
    dataChanged,
  } = formValues

  // const strPropsThatMustNotBeBlank = [
  //   firstName,
  //   lastName,
  //   // emailAddress, // why commented out? CODE_COMMENTS_278
  //   contactType,
  // ]
  //
  // const propsThatMustBeValidEmailAddresses = [
  //   // emailAddress, // why commented out? CODE_COMMENTS_278
  // ]
  //
  // const propsThatMustBeEitherBlankOrPossiblePhoneNumbers = [
  //   deskPhone,
  //   cellPhone,
  //   faxNumber,
  // ]
  //
  // const isSomeoneSetToDefaultShippingContact = (
  //   !contact.defaultShippingContact
  //   || (defaultShippingContact || replacementDefaultShippingContactId)
  // )
  //
  // const isContactTheOnlyInvoiceRecipient = getIsContactTheOnlyInvoiceRecipient({ contact, contacts })
  // const isSomeoneSetToReceiveInvoices = (
  //   !isContactTheOnlyInvoiceRecipient
  //   || receiveInvoice
  //   || replacementInvoiceRecipientContactId
  // )

  return (
    // strPropsThatMustNotBeBlank.every(s => s && s.trim() !== '')
    // && propsThatMustBeValidEmailAddresses.every(s => s && getIsStringAValidEmailAddress(s))
    // && propsThatMustBeEitherBlankOrPossiblePhoneNumbers.every(s => getIsPossiblePhoneNumber(s))
    // && isSomeoneSetToDefaultShippingContact
    // && isSomeoneSetToReceiveInvoices
    // && !getAreCurrentValuesSameAsInitialValues(props)
    dataChanged
  )
}


function getAreCurrentValuesSameAsInitialValues({
  contact,
  // emailSubscriptionProps,
  formValues,
  isEditForm,
}) {
  if (!isEditForm) { return false }

  const {
    firstName,
    lastName,
    // emailAddress, // why commented out? CODE_COMMENTS_278
    contactType,
    defaultShippingContact,
    deskPhone,
    deskPhoneExt,
    cellPhone,
    faxNumber,
  } = formValues
  const areNonEmailSubscriptionPropValuesTheSame = (
    getAreStringValuesTheSame(contact.firstName, firstName)
    && getAreStringValuesTheSame(contact.lastName, lastName)
    // && getAreStringValuesTheSame(contact.emailAddress, emailAddress) // why commented out? CODE_COMMENTS_278
    && getAreStringValuesTheSame(getHumanReadableContactTypeFromContactMashupObj(contact), contactType)
    && getAreBooleanValuesTheSame(contact.defaultShippingContact, defaultShippingContact)
    && getAreStringValuesTheSame(contact.deskPhone, deskPhone)
    && getAreStringValuesTheSame(contact.deskPhoneExt, deskPhoneExt)
    && getAreStringValuesTheSame(contact.cellPhone, cellPhone)
    && getAreStringValuesTheSame(contact.faxNumber, faxNumber)
  )

  // const areEmailSubscriptionPropValuesTheSame = emailSubscriptionProps.every(prop => (
  //   getAreBooleanValuesTheSame(get_(contact, prop.name), get_(formValues, prop.name))
  // ))

  const { dataChanged } = formValues

  return Boolean(
    areNonEmailSubscriptionPropValuesTheSame
    && !dataChanged,
  )
}


// Takes null, undefined, etc. into account
function getAreBooleanValuesTheSame(first, second) {
  return (
    (first && second)
    || (!first && !second)
  )
}

// Considers a blank string to be the same as null/undefined
function getAreStringValuesTheSame(first, second) {
  if (
    (!first || first.trim() === '')
    && (!second || second.trim() === '')
  ) {
    return true
  }
  return first === second
}

export default connect()(
  NoLoginAddOrEditContacts,
)
