import React from 'react'
import { Icon, Message } from 'semantic-ui-react'

export default ({
  banner = {},
}) => {
  const { message } = banner
  return (
    message &&
    <Message
      info
      compact
      style={{
        margin: 0,
        padding: '0.5em 1em',
      }}
    >
      <Icon name="info" />
      {message}
    </Message>
  )
}
