import React from 'react'
import { Button, Header } from 'semantic-ui-react'
import lowerCase_ from 'lodash/lowerCase'

import { useTranslation } from 'react-i18next'
import FormSubmissionDimmerOverlay from '../../../../../../../common-components/FormSubmission/DimmerOverlay'

export default FormSubmissionDimmerOverlay(({
  buttonText,
  onSubmit,

  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <div
      // CODE_COMMENTS_70
      style={isSubmitting || hasSubmitSucceeded || hasSubmitFailed ? { minHeight: '300px' } : null}
    >
      <Header key="header">{translate('Are you sure?')}</Header>
      <Button
        key="delete-button"
        color="red"
        onClick={onSubmit}
        // When the delete is submitted, the dimmer overlay prevents the user from
        // clicking "Delete" again, but without this disabled flag, the user can
        // still press the Enter button on their keyboard and re-submit the call.
        disabled={isSubmitting || hasSubmitSucceeded || hasSubmitFailed}
      >
        {`Yes, ${lowerCase_(buttonText)} it`}
      </Button>
    </div>
  )
})
