/**
 * The Order Keg Collars form has two additional features that most other forms
 * don't have:
 *
 * 1)  It needs to wait to render until the customer's keg collar plates are
 *     fetched from the API.
 * 2)  If the customer has no keg collars plates on file, it needs to show a
 *     message instructing the user to contact their MicroStar rep to add keg
 *     collar plates to their account.
 *
 * So what should be displayed while collar plates are being fetched? And what
 * should be displayed if the fetch fails? That's what the
 * FormOrNoCollarPlatesMessageWithFetcinghWrapper is for.
 */
import React from 'react'
import { connect } from 'react-redux'

import WaitForApiFetch from '../../../../../common-components/WaitForApiFetch'
import FormOrNoCollarPlatesMessage from './containers/FormOrNoCollarPlatesMessage'

import {
  FETCH_COLLAR_PLATES_INFO,
} from '../../../../../redux/actions/actionTypes'
import createAction from '../../../../../redux/actions/createAction'

import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/customers/collarPlates'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


const mapStateToProps = (state, ownProps) => {
  const { customerId, operatingContractBrewerCustomerId } = ownProps
  const { getFetchStatuses, getFetchFailureErrorDetails } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  const errorDetails = getFetchFailureErrorDetails(
    state,
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  return {
    // needed by WaitForApiFetch
    ...fetchStatuses,
    errorDetails,
    loadingMessage: 'Fetching Keg Collar Plates',
    failureHeaderMessage: 'Failed to Load Keg Collar Plates',
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = ownProps
  const resubmitFunc = () => (
    dispatch(createAction(
      FETCH_COLLAR_PLATES_INFO,
      {
        customerId,
        operatingContractBrewerCustomerId,
      },
    ))
  )

  return {
    resubmitFunc,
  }
}


export default wrappedComponent => connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaitForApiFetch(props => (
  <FormOrNoCollarPlatesMessage
    {...props}
    wrappedComponent={wrappedComponent}
  />
)))
