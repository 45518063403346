import React from 'react'


import { useTranslation } from 'react-i18next'
import PopupWithCatastrophicFailureErrorBoundary from '../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'

import ContactCard from '../../../../../common-components/ContactCard'

import {
  CUSTOMER_REPS_LOGISTICS,
} from '../../../../../constants'

export default ({
  customerId,
  trigger,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      trigger={trigger}
      on="click"
      position="top center"
    >
      <p>{translate('Contact your personalized representative to request a local Release Authorization.')}</p>
      <ContactCard
        repType={CUSTOMER_REPS_LOGISTICS}
        customerId={customerId}
      />
    </PopupWithCatastrophicFailureErrorBoundary>
  )
}
