/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/browser'
import hash from 'object-hash'

import mapValues_ from 'lodash/mapValues'
import isPlainObject_ from 'lodash/isPlainObject'
import isArray_ from 'lodash/isArray'
import get_ from 'lodash/get'
import has_ from 'lodash/has'
import set_ from 'lodash/set'
import cloneDeep_ from 'lodash/cloneDeep'
import isString_ from 'lodash/isString'
import keys_ from 'lodash/keys'
import values_ from 'lodash/values'

import {
  getPropOrUndefined as getCurrentUserPropOrUndefined,
} from '../../redux/selectors/currentUser'

import {
  getPropOr as getCustomerPropOrUndefined,
} from '../../redux/selectors/customers'


import {
  API_URL_SERVICE_CALL_STATUS,
  ENVIRONMENT_PROD,
  CUSTOMER_TYPES_MASTER,
} from '../../constants'

import {
  API_FETCH_TIMEOUT_RETRY_DELAY,
} from '../../config'

import {
  getCustomerIdOfCustomerCurrentlyBeingOperatedForFromUrlPath,
  extractAmazonRequestIdFromHttpResponseOrErrorObject,
  prependStringToAllObjectKeys,
  extractMostImportantDetailsFromApiErrorObject,
  getApiUrlPathFromHttpResponseOrErrorObject,
  getMethodFromHttpResponseOrErrorObject,
  getEnvironmentBasedOnRootApiUrl,
  getPathsOfAllSubObjectsThatHaveSpecificCharacteristics,
  includesSome,
  isAxiosErrorOrResponseObject,
  replaceAllUuidsInString,
} from '../'

import { WEBAPP_VERSION } from '../../version'

// This is a horrible hack that should only be used sparingly. See
// CODE_COMMENTS_182
import { store } from '../../index'


/*
 * *****************************************************************************
 * initializing Sentry; this must be set before defining the logging functions.
 * *****************************************************************************
*/

Sentry.init({
  dsn: 'https://9ca7c2b2694b47559ad5d7aece45f37b@sentry.io/1329915',
  environment: getEnvironmentBasedOnRootApiUrl(),
  release: WEBAPP_VERSION,
  // Personally-identifiable information such as email address. We can do this
  // because 1) error messages sent to Sentry are encrypted via HTTPS; 2) we
  // don't have to be HIIPA-compliant.
  sendDefaultPii: true,
  // As of @sentry/browser version 5.15.4, we need to set the normalizeDepth
  // setting so that nested objects actually get sent to the Sentry backend:
  // https://github.com/getsentry/sentry-javascript/pull/2404. Without this
  // setting, nested objects in the e.g. `extra` field show up as the string
  // '[Object]', which is totally unhelpful. For example:
  // {
  //   mostImportantDetailsFromError: {
  //     responseBody: {
  //       validationErrors: '[Object]',
  //       ...
  //     }
  //     ...
  //   }
  // }
  normalizeDepth: 10,
  // 'debug' mode simply prints Sentry errors (e.g. sending the error to
  // Sentry's servers failed [returned a 4xx/5xx code]) and irregularities to
  // the console, helpful when you're tinkering with code involving Sentry.
  debug: getEnvironmentBasedOnRootApiUrl() !== ENVIRONMENT_PROD,
  // This is how to delete one or more Sentry Default Integrations:
  // https://github.com/getsentry/sentry-javascript/issues/1652#issuecomment-429551650
  integrations(integrations) {
    return integrations
      // Sentry's `Dedupe` Default Integration is silly: it checks to see
      // whether two errors (i.e. "events") in a row have the same fingerprint
      // (which, in our setup, simply means the same message) and if so, doesn't
      // send the second error to the Sentry server. This is not what we want;
      // there are many times we want to send the same message multiple times in
      // a row. History items are a good example: if several history items in
      // one search result have the same problem, we want to send same message
      // multiple times in a row, one for each offending history item. Our
      // algorithm for reducing true duplicate error messages being sent to
      // Sentry is more complex.
      .filter(i => i.name !== 'Dedupe')
  },
})


/*
 * *****************************************************************************
 * Constants. Why not just use the string literals? See CODE_COMMENTS_33
 * *****************************************************************************
*/

export const LOG_SEVERITY_FATAL = 'fatal'
export const LOG_SEVERITY_ERROR = 'error'
export const LOG_SEVERITY_WARNING = 'warning'
export const LOG_SEVERITY_INFO = 'info'
export const LOG_SEVERITY_DEBUG = 'debug'


/*
 * *****************************************************************************
 * The main functions
 * *****************************************************************************
*/

// Use this when you have a custom message to send to the 3rd-party error
// logging service rather than an actual error object. `additionalInfo` and the
// other, longer `additionalInfoThat...` prop are optional: they're objects
// whose that will be passed to the 3rd-party error-logging service.
export function logErrorMessage({
  message,
  severity, // 'error', 'warning', 'info', etc
  additionalInfo,
  // Put info in here that should NOT affect the decision of whether this event
  // has already been sent to our 3rd-party logging service. This is usually
  // information pertaining to the exact offending API call, such as Amazon
  // Request IDs. See CODE_COMMENTS_183 for details.
  additionalInfoThatDoesntAffectWhetherSameLogOccurrenceHasAlreadyBeenSent,
  // Don't add the entire httpResponse object to
  // additionalInfoThatDoesntAffectWhetherSameLogOccurrenceHasAlreadyBeenSent
  // yourself; instead, add it here. This function will do two things: 1) add
  // the entire http response to `extra`; 2) extract the Amazon request ID and
  // include it as its own key/value pair in `extra`, that way it's easier to
  // find in Sentry.
  httpResponse,
}) {
  const indexedData = getIndexedData()

  const shouldErrorBeSent = !hasSameLogOccurrenceAlreadyBeenSent({
    message,
    ...(additionalInfo || {}),
    ...indexedData,
  })

  if (shouldErrorBeSent) {
    const extra = {
      ...(
        httpResponse
          ? {
            amazonRequestId: extractAmazonRequestIdFromHttpResponseOrErrorObject(httpResponse),
            httpResponse,
          }
          : {}
      ),
      ...(additionalInfo || {}),
      ...(additionalInfoThatDoesntAffectWhetherSameLogOccurrenceHasAlreadyBeenSent || {}),
    }

    sentryCaptureMessage({ message, severity, extra })
  }
}


export const CAN_STILL_BE_DISPLAYED = 'CAN_STILL_BE_DISPLAYED'
export const CANT_BE_DISPLAYED = 'CANT_BE_DISPLAYED'
export const PREVENTING_DASHBOARD_FROM_LOADING = 'PREVENTING_DASHBOARD_FROM_LOADING'
// level can be either:
// CAN_STILL_BE_DISPLAYED
// CANT_BE_DISPLAYED
// PREVENTING_DASHBOARD_FROM_LOADING
export function logObjectHasProblemsErrorMessage({
  level,
  objectType,
  ...rest // e.g. `additionalInfo` and `httpResponse`
}) {
  const messages = {
    [CAN_STILL_BE_DISPLAYED]: `${objectType} has problems but can still be saved`,
    [CANT_BE_DISPLAYED]: `${objectType} has problems, can't be saved (but dashboard still loads)`,
    [PREVENTING_DASHBOARD_FROM_LOADING]: `${objectType} has problems, can't be saved, preventing dashboard from loading`,
  }
  const message = messages[level]

  const severities = {
    [CAN_STILL_BE_DISPLAYED]: LOG_SEVERITY_WARNING,
    [CANT_BE_DISPLAYED]: LOG_SEVERITY_ERROR,
    [PREVENTING_DASHBOARD_FROM_LOADING]: LOG_SEVERITY_FATAL,
  }
  const severity = severities[level]
  logErrorMessage({
    message,
    severity,
    ...rest,
  })
}


// app crashed
export function logCatastrophicFailureError({ error }) {
  // Notice there's no hasSameLogOccurrenceAlreadyBeenSent() check: catastrophic
  // failure errors should always be sent.
  sentryCaptureException({
    error,
    severity: LOG_SEVERITY_FATAL,
  })
}


export function logApiCallError({
  error,
  // if so, severity will be `fatal`, otherwise `error` (if 504 that will be
  // retried/polled, 'info')
  preventsDashboardFromLoading,
  // CODE_COMMENTS_184: valid values: 'retry', 'poll' or falsy
  ifTimeoutWillCallBeRetriedOrPolled,
}) {
  const extra = {
    error,
    mostImportantDetailsFromError: extractMostImportantDetailsFromApiErrorObject({ error }),
    preventsDashboardFromLoading: (!ifTimeoutWillCallBeRetriedOrPolled && preventsDashboardFromLoading) || false,
  }
  if (!error.response) { // No internet error or CORS error
    extra.details = "The 'error' object of the API call does not have a 'response' prop, which means one of two things: \n1) the end user lost internet connection (a Network Error); \n2) a CORS error. \nIt's likely a CORS error since the web app had the internet connection to send this log error to our 3rd-party error logging service, something it does immediately after receiving the API error. A couple points: \n1. The CORS error might have happened on the preflight OPTIONS call rather than in the method in this event's title, there's no way for the web app code to know; \n2) Unfortunately, it's no use checking the telemetry/breadcrumbs feature of this 3rd-party logging service to confirm a CORS error rather than a network eroror, because telemetry/breadcrumbs will not detect and include the CORS error messages printed to the console by the browser (the browser is very sneaky this way--it rightly does a good job making CORS errors opaque to code)."
  }
  if (ifTimeoutWillCallBeRetriedOrPolled) {
    const webAppActionIfPoll = `The web app will poll for the result of this call by making a call to ${API_URL_SERVICE_CALL_STATUS}`
    const webAppActionIfRetry = `The web app will wait ${API_FETCH_TIMEOUT_RETRY_DELAY} seconds then retry the call.`
    extra.details = `This call has timed out with a 504. ${ifTimeoutWillCallBeRetriedOrPolled === 'poll' ? webAppActionIfPoll : webAppActionIfRetry}`
  }

  let severity
  if (ifTimeoutWillCallBeRetriedOrPolled) {
    severity = LOG_SEVERITY_INFO
  } else {
    severity = preventsDashboardFromLoading ? LOG_SEVERITY_FATAL : LOG_SEVERITY_ERROR
  }

  // Notice there's no hasSameLogOccurrenceAlreadyBeenSent() check: API call
  // errors should always be sent.
  sentryCaptureMessage({
    message: createApiCallErrorMessage({
      error,
      preventsDashboardFromLoading,
      ifTimeoutWillCallBeRetriedOrPolled,
    }),
    severity,
    extra,
  })
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

function sentryCaptureMessage({ message, severity, extra }) {
  sentryCaptureMessageOrException({ messageOrException: 'message', message, severity, extra })
}

function sentryCaptureException({ error, severity, extra }) {
  sentryCaptureMessageOrException({ messageOrException: 'exception', error, severity, extra })
}

function sentryCaptureMessageOrException({
  messageOrException,
  message,
  error,
  severity,
  extra,
}) {
  const newExtra = makeAllNecessaryTransofmationsToExtraObj(extra)
  Sentry.withScope(scope => {
    configureSentryForThisMessage({
      severity,
      tags: getIndexedData(),
      extra: newExtra,
      scope,
    })

    if (messageOrException === 'message') {
      // https://microstartap3.atlassian.net/browse/TP3-1448
      const messageWithUuidsGeneralized = replaceAllUuidsInString(message, ':uuid')
      Sentry.captureMessage(messageWithUuidsGeneralized)
    } else {
      Sentry.captureException(error)
    }
  })
}


// severity should be a string 'error', 'warning', etc. tags and extra should be
// objects. scope should be the scope object provided by Sentry in the
// Sentry.withScope() or Sentry.configureScope() closures.
function configureSentryForThisMessage({
  severity,
  tags={},
  extra={},
  scope,
}) {
  scope.setLevel(severity)

  // object must have the props as instructed at
  // https://docs.rollbar.com/docs/person-tracking#section-javascript
  const userInfo = {
    id: tags._userId,
    username: tags._username,
    email: tags._userEmail,
  }
  scope.setUser(userInfo)

  // Why are we including the same user info in `tags` that we put into
  // `userInfo`? Won't userInfo automatically be indexed by Sentry, making our
  // "manual indexing" of it within `tags` redundant? Apparently not, because,
  // by an incredible oversight on the part of Sentry's UI team, I can't filter
  // by user email on the main "Issues" page of the Sentry app without also
  // saving email as a tag.
  Object.entries(tags).forEach(([key, value]) => {
    scope.setTag(key, value)
  })

  Object.entries(extra).forEach(([key, value]) => {
    scope.setExtra(key, value)
  })
}


function getIndexedData() {
  const state = store.getState()

  // Tad discussed with Nash the best names to use for tags and Nash decided
  // that 'userFirstName' is better than 'firstName', that way you can clearly
  // tell which tags are for users and which are for customers.
  const userId = getCurrentUserPropOrUndefined(state, 'userId')
  const username = getCurrentUserPropOrUndefined(state, 'username')
  const userEmail = getCurrentUserPropOrUndefined(state, 'emailAddress')
  const userFirstName = getCurrentUserPropOrUndefined(state, 'firstName')
  const userLastName = getCurrentUserPropOrUndefined(state, 'lastName')

  const customerId = getCurrentUserPropOrUndefined(state, 'rootCustomerId')
  const customerType = getCustomerPropOrUndefined(state, customerId, 'customerType')
  const customerTapId = getCustomerTapIdForSentry(state, customerId, customerType)

  const customerName = getCustomerPropOrUndefined(state, customerId, 'name')


  const operatingForCustomerId = getCustomerIdOfCustomerCurrentlyBeingOperatedForFromUrlPath(
    // we have to use `window` here rather than any of React Router's techniques
    // of getting the pathname because React Router's techniques require a
    // component to retrieve:
    // (https://stackoverflow.com/questions/42253277/react-router-v4-how-to-get-current-route)
    window.location.pathname,
  )


  // Why don't we call these e.g. operatingForCustomerName instead of
  // operatingForName? Because Sentry has a bug where if the key name is too
  // long (not sure exactly how long, but operatingForCustomerName is too long),
  // it will display an error saying "Discarded value due to exceeding maximum
  // length" and omit the too-long tags.
  let operatingForName
  let operatingForType
  let operatingForTapId

  if (!operatingForCustomerId) {
    operatingForName = customerName
    operatingForType = customerType
    operatingForTapId = customerTapId
  } else {
    operatingForName = getCustomerPropOrUndefined(
      state,
      operatingForCustomerId,
      'name',
    )

    operatingForType = getCustomerPropOrUndefined(
      state,
      operatingForCustomerId,
      'customerType',
    )

    operatingForTapId = getCustomerTapIdForSentry(
      state,
      operatingForCustomerId,
      operatingForType,
    )
  }


  let indexedData = {
    userId,
    username,
    userEmail,
    userFirstName,
    userLastName,

    customerName,
    customerTapId,
    customerType,

    operatingForName,
    operatingForTapId,
    operatingForType,
  }
  // We prepend all keys with an underscore so that, in the Sentry app, all our
  // custom tags are grouped one after another, rather than interspersed with
  // default tags like "browser" and "os"
  indexedData = prependStringToAllObjectKeys({ obj: indexedData, str: '_' })

  // If any values are falsy, set them to 'not available'
  return mapValues_(indexedData, value => value || 'not available')
}


function createApiCallErrorMessage({
  error,
  preventsDashboardFromLoading,
  ifTimeoutWillCallBeRetriedOrPolled,
}) {
  const path = getApiUrlPathFromHttpResponseOrErrorObject(error)
  const method = getMethodFromHttpResponseOrErrorObject(error)

  const errorCode = error.response
    ? error.response.status
    : 'Likely CORS error'

  const preventingDashobardFromLoadingText = 'Preventing Dashboard from loading'

  if (
    errorCode === 504 ||
    ifTimeoutWillCallBeRetriedOrPolled
  ) {
    let message = `API Timeout: ${errorCode} on ${method} ${path}`
    if (ifTimeoutWillCallBeRetriedOrPolled) {
      if (ifTimeoutWillCallBeRetriedOrPolled === 'poll') {
        return `${message} (web app will poll for response with ${API_URL_SERVICE_CALL_STATUS})`
      } // must equal 'retry' rather than 'poll'
      return `${message} (web app will retry call)`
    }
    message = `${message} (web app has polled/retried several times, not gotten result, will no longer try)`
    if (preventsDashboardFromLoading) {
      message = `${message} ${preventingDashobardFromLoadingText}`
    }
    return message
  }

  let message = `API Error: ${errorCode} on ${method} ${path}`
  if (preventsDashboardFromLoading) {
    message = `${message} (${preventingDashobardFromLoadingText})`
  }
  return message
}


const errorMessageHashes = new Set()
// See CODE_COMMENTS_183. By 'same log occurrence' we mean that all the info in
// each nested prop of objectOfMessageInfo is the same. objectOfMessageInfo
// should include:
//
// * the error message string;
// * all data specific to this particular error (for instance, if this error is
//   about an inventory report object having missing inventory types, it should
//   include the inventory object's ID and the missing inventory types array:
//   ['FUL', 'EMP']);
// * all logged-in user and customer data (user email, tapcustomerId, etc).
export function hasSameLogOccurrenceAlreadyBeenSent(objectOfMessageInfo) {
  // isn't hashing slow? CODE_COMMENTS_194
  const messageHash = hash(objectOfMessageInfo)
  if (errorMessageHashes.has(messageHash)) {
    return true
  }
  errorMessageHashes.add(messageHash)
  return false
}

function getCustomerTapIdForSentry(state, customerId, customerType) {
  let customerTapId
  if (customerType === CUSTOMER_TYPES_MASTER) {
    // Masters almost never have TAP IDs, so for the sake of explicitness, if
    // this is a master customer, set the value to a string explaining why this
    // value isn't in Redux.
    customerTapId = getCustomerPropOrUndefined(state, customerId, 'tapcustomerId') || 'none (MASTER)'
  } else {
    customerTapId = getCustomerPropOrUndefined(state, customerId, 'tapcustomerId')
  }
  return customerTapId
}


/*
 * *****************************************************************************
 * scrubbing all sensitive values from the `extra` object
 * *****************************************************************************
*/

// Put all sensitive prop key names in here. Our scrubbers will do two things:
//
// 1. all props with these keys will be recursively searched for and have their
//    values set to 'SCRUBBED'
// 2. all strings will be recursively searched for and replaced with 'SCRUBBED'
//    if the contain one or more of the strings in this list
//
// This list is not case-sensitive: upper-case, lower-case doesn't matter, our function
// will find them.
const propsThatNeedToBeScrubbed = [
  // If an error happens in the /login pages, don't save user-entered PWs
  'password',
  'pw', // not strictrly necessary as of Dec. '18 but just in case
  // We don't want to save JSON userTokens or refreshTokens
  'userToken',
  'refreshToken',
  'Authentication',
  // as of December, 2018, the web app uses the 'Authentication' http header
  // for its tokens, _not_ 'Authorization'. But if this changes in the future,
  // we don't want to have to remember to change this function along with it.
  'Authorization',
]

function makeAllNecessaryTransofmationsToExtraObj(extraObj) {
  const funcsToRunOnExtraObjectInOrder = [
    parseRequestBodyOfHttpErrorAndResponseObjectsToJson,
    scrubValuesOfSensitivePropNamesInHttpResponseAndErrorObjects,
    scrubStringsWithSensitiveSubstringsInHttpResponseAndErrorObjects,
  ]

  return funcsToRunOnExtraObjectInOrder.reduce(
    (acc, f) => f(acc),
    extraObj,
  )
}


// Axios has a bug that turns request bodies of its Error and Response objects
// into strings rather than objects (I couldn't find this exact problem when I
// googled for it, but here's some related issues concerning the bodies of
// _response_ objects being strings rather than objects:
// https://github.com/axios/axios/issues/1723,
// https://github.com/axios/axios/issues/960). This function changes the request
// bodies back to objects (when possible--I've also seen the case where Axios
// cuts the request body string seemingly in half, which leaves the JSON string
// in an invalid JSON format. In such a case, this function simply leaves the
// string as-is).
function parseRequestBodyOfHttpErrorAndResponseObjectsToJson(extraObj) {
  // we'll be manipulating obj directly (see CODE_COMMENTS_186 for why), and we
  // don't want to directly mutate function arguments, so we're forced to do a
  // deep clone here.
  const obj = cloneDeep_(extraObj)

  const pathToRequestBodyWithinHttpErrorAndResponseObjects = [
    ['config', 'data'],
  ]

  let pathsToHttpErrorAndResponseObjects = getPathsOfAllSubObjectsThatHaveSpecificCharacteristics({
    obj,
    subObjectsFilterFunc: o => isAxiosErrorOrResponseObject(o),
    iterateOverTheseKindsOfObjectsFunc: isPlainObjectOrPlainArrayOrAxiosErrorOrResponseObject,
    // This is required because of the admittedly stupid way
    // getPathsOfAllSubObjectsThatHaveSpecificCharacteristics() works; see below
    currentNestedPath: ['blah blah blah'],
  })

  // CODE_COMMENTS_187
  pathsToHttpErrorAndResponseObjects = pathsToHttpErrorAndResponseObjects.map(
    arr => (arr.length > 0 ? arr.slice(1) : arr),
  )

  const allPathsThatNeedToBeConvertedFromAStringToAJSONObject = pathsToHttpErrorAndResponseObjects.reduce(
    (acc, pathToHttpErrOrResObj) => {
      const pathsToAdd = pathToRequestBodyWithinHttpErrorAndResponseObjects.map(pathToRequestBodyWithinHttpObj => (
        [...pathToHttpErrOrResObj, ...pathToRequestBodyWithinHttpObj]
      ))
      return [...acc, ...pathsToAdd]
    },
    [],
  )

  // Why are we using forEach() here instead of reduce()? And why are we
  // manipulating the `obj` directly? See CODE_COMMENTS_186.
  allPathsThatNeedToBeConvertedFromAStringToAJSONObject.forEach(pathThatNeedsToBeConvertedFromAStringToAJSONObject => {
    const requestBody = get_(obj, pathThatNeedsToBeConvertedFromAStringToAJSONObject)
    let requestBodyAsJsonObj
    if (isString_(requestBody)) {
      try {
        requestBodyAsJsonObj = JSON.parse(requestBody)
      } catch (e) { // The string is somehow malformed JSON
        requestBodyAsJsonObj = requestBody
      }
    } else {
      requestBodyAsJsonObj = requestBody
    }
    set_(obj, pathThatNeedsToBeConvertedFromAStringToAJSONObject, requestBodyAsJsonObj)
  })

  return obj
}


// Recursively checks all subobjects of obj for any props with the keys in
// 'propsThatNeedToBeScrubbed' changes their values to 'SCRUBBED' (does this
// functionally: doesn't change the passed-in obj).
function scrubValuesOfSensitivePropNamesInHttpResponseAndErrorObjects(extraObj) {
  // we'll be manipulating obj directly (see comments below for why), and we
  // don't want to directly mutate function arguments, so we're forced to do a
  // deep clone here.
  const obj = cloneDeep_(extraObj)

  if (!isPlainObject_(obj)) { return obj }
  let pathsToSubObjectsThatHaveSensitiveProps = getPathsOfAllSubObjectsThatHaveSpecificCharacteristics({
    obj,
    subObjectsFilterFunc: o => {
      const keysOfObjectIteratingOver = Object.keys(o)
      const lowercasekeysOfObjectIteratingOver = keysOfObjectIteratingOver.map(
        key => (isString_(key) ? key.toLowerCase() : key),
      )

      const propsThatNeedToBeScrubbedLowercase = propsThatNeedToBeScrubbed.map(
        prop => (isString_(prop) ? prop.toLowerCase() : prop),
      )
      return isPlainObjectOrPlainArrayOrAxiosErrorOrResponseObject(o) && includesSome(
        lowercasekeysOfObjectIteratingOver,
        propsThatNeedToBeScrubbedLowercase,
      )
    },
    iterateOverTheseKindsOfObjectsFunc: isPlainObjectOrPlainArrayOrAxiosErrorOrResponseObject,
    // This is required because of the admittedly stupid way
    // getPathsOfAllSubObjectsThatHaveSpecificCharacteristics() works; see
    // CODE_COMMENTS_187
    currentNestedPath: ['blah blah blah'],
  })


  // CODE_COMMENTS_187
  pathsToSubObjectsThatHaveSensitiveProps = pathsToSubObjectsThatHaveSensitiveProps.map(
    arr => (arr.length > 0 ? arr.slice(1) : arr),
  )

  // Why are we using forEach() here instead of reduce()? And why are we
  // manipulating the `obj` directly? See CODE_COMMENTS_186.
  pathsToSubObjectsThatHaveSensitiveProps.forEach(
    pathToSubObjectThatHasAnAuthenticationProp => (
      propsThatNeedToBeScrubbed.forEach(
        propThatNeedsToBeScrubbed => {
          const subObjectThatHasAnAuthenticationProp = get_(obj, pathToSubObjectThatHasAnAuthenticationProp)
          if (has_(subObjectThatHasAnAuthenticationProp, propThatNeedsToBeScrubbed)) {
            set_(obj, [...pathToSubObjectThatHasAnAuthenticationProp, propThatNeedsToBeScrubbed], 'SCRUBBED')
          }
        },
      )
    ),
  )
  return obj
}


// Recursively checks all strings in obj to see whether they contain one or more
// of the strings in  'propsThatNeedToBeScrubbed'; changes the string to
// 'SCRUBBED' if so (does this functionally: doesn't change the passed-in obj).
function scrubStringsWithSensitiveSubstringsInHttpResponseAndErrorObjects(extraObj) {
  // we'll be manipulating obj directly (see comments below for why), and we
  // don't want to directly mutate function arguments, so we're forced to do a
  // deep clone here.
  const obj = cloneDeep_(extraObj)

  let pathsToObjsSomeOfWhoseValuesAreStringsThatHasSensitiveProps =
    getPathsOfAllSubObjectsThatHaveSpecificCharacteristics({
      obj,
      subObjectsFilterFunc: o => (
        // If o is truly an object, values_() will get its values. If o is an
        // array, values_ will simply return the array, which is what we want.
        values_(o).some(value => (
          isString_(value) &&
          propsThatNeedToBeScrubbed.some(propThatNeedToBeScrubbed => value.includes(propThatNeedToBeScrubbed))
        ))
      ),
      iterateOverTheseKindsOfObjectsFunc: isPlainObjectOrPlainArrayOrAxiosErrorOrResponseObject,
      // This is required because of the admittedly stupid way
      // getPathsOfAllSubObjectsThatHaveSpecificCharacteristics() works; see
      // CODE_COMMENTS_187
      currentNestedPath: ['blah blah blah'],
    })


  // CODE_COMMENTS_187
  pathsToObjsSomeOfWhoseValuesAreStringsThatHasSensitiveProps =
    pathsToObjsSomeOfWhoseValuesAreStringsThatHasSensitiveProps.map(
      arr => (arr.length > 0 ? arr.slice(1) : arr),
    )

  // Why are we using forEach() here instead of reduce()? And why are we
  // manipulating the `obj` directly? See CODE_COMMENTS_186.
  pathsToObjsSomeOfWhoseValuesAreStringsThatHasSensitiveProps.forEach(
    pathToObjSomeOfWhoseValuesAreStringsThatHasSensitiveProps => {
      const targetObj = get_(obj, pathToObjSomeOfWhoseValuesAreStringsThatHasSensitiveProps)
      const propsWhoseValuesAreStringsThatHasSensitiveProps = keys_(targetObj).filter(key => (
        isString_(targetObj[key]) &&
        propsThatNeedToBeScrubbed.some(propThatNeedToBeScrubbed => targetObj[key].includes(propThatNeedToBeScrubbed))
      ))
      propsWhoseValuesAreStringsThatHasSensitiveProps.forEach(propKeyToScrub => {
        set_(obj, [...pathToObjSomeOfWhoseValuesAreStringsThatHasSensitiveProps, propKeyToScrub], 'SCRUBBED')
      })
    },
  )

  return obj
}


// helper functions for this section

function isPlainObjectOrPlainArrayOrAxiosErrorOrResponseObject(o) {
  return (
    isPlainObject_(o) ||
    isArray_(o) ||
    // The Axios library returns a plain object if an `axios()` call succeeds
    // (i.e. it returns true when calling lodash's isPlainObject(obj) on it),
    // but if the call fails, the object returned by the `axios()` call is not
    // a plain object (returns false on an isPlainObject(obj) call), but a
    // special type of object (but one in which you can still run most
    // built-in object methods on).
    isAxiosErrorOrResponseObject(o)
  )
}
