import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'
import CustomerPortal from '../../../../CustomerPortal'
import History from '../../../../History'

import {
  CUSTOMER_TYPES_BREWER,
} from '../../../../../constants'

export default memo(({ // Why memo? See CODE_COMMENTS_253
  customerId,
  operatingContractBrewerCustomerId, // CODE_COMMENTS_88
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <div>
      <CustomerPortal
        customerId={customerId}
        customerType={CUSTOMER_TYPES_BREWER}
        operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
      />
      <History
        customerId={customerId}
        operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
        translate={translate}
      />
    </div>
  )
})
