import React, { Fragment, useState } from 'react'
import {
  Form,
  Button,
  Header,
  Grid,
} from 'semantic-ui-react'


import MyModal from '../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/MyModal'
import FetchFailureUnknownErrorContent from '../../../../common-components/FormSubmission/DismissableMessageFailuresContent/FetchFailureUnknownErrorContent'
import InformationalPopup from '../../../../common-components/InformationalPopup'

import ChooseReplacementDefaultShippingContactOrInvoiceRecipient from '../../ChooseReplacementDefaultShippingContactOrInvoiceRecipient'

import {
  FETCH_DELETE_CONTACT,
} from '../../../../redux/actions/actionTypes'

import createAction from '../../../../redux/actions/createAction'

import {
  DISPLAYED_INFORMATIONAL_MESSAGE_DEFAULT_SHIPPING_CONTACT,
} from '../../constants'

import {
  getIsContactTheOnlyInvoiceRecipient,
} from '../../utils'

import {
  isTruthyAndNonEmpty,
} from '../../../../utils'


const labelWidth = '180px' // CODE_COMMENTS_22

export default ({
  customerId,
  modalTrigger,
  contact, // The object from the Redux store; only pass in if isEditForm
  contacts, // all contacts currently being displayed to the user
  dispatch,
}) => {
  if (!isTruthyAndNonEmpty(contacts) || !isTruthyAndNonEmpty(contact)) { return null } // first render

  const [replacementDefaultShippingContactId, setReplacementDefaultShippingContactId] = useState(null)
  const [replacementInvoiceRecipientContactId, setReplacementInvoiceRecipientContactId] = useState(null)

  const [isFetching, setIsFetching] = useState(false)
  const [didFetchFail, setDidFetchFail] = useState(false)
  const [fetchErrorDetails, setFetchErrorDetails] = useState({})

  const propsForValidationAndFormSubmission = {
    contact,
    contacts,
    // We put this into a formValues prop because that's the shape the saga
    // expects (it's mostly the same code as Edit Contact saga)
    formValues: {
      replacementDefaultShippingContactId,
      replacementInvoiceRecipientContactId,
    },
  }

  const submitForm = () => {
    setIsFetching(true)
    dispatch(createAction(
      FETCH_DELETE_CONTACT,
      {
        ...propsForValidationAndFormSubmission,
        // Why no onFetchSuccess function? Don't we want to set isFetching to
        // false and close the modal? No, because this modal component gets
        // unmounted and destroyed as soon as the saga updates the Redux store
        // with this contact's 'deleted' prop set to true. If we were to try to
        // close the modal, React would log this warning message to the console:
        // "Warning: Can't perform a React state update on an unmounted
        // component. This is a no-op, but it indicates a memory leak in your
        // application. To fix, cancel all subscriptions and asynchronous tasks
        // in a useEffect cleanup function.""
        onFetchFailure: errorDetails => {
          setFetchErrorDetails(errorDetails)
          setIsFetching(false)
          setDidFetchFail(true)
        },
      },
    ))
  }

  const isContactTheOnlyInvoiceRecipient = getIsContactTheOnlyInvoiceRecipient({ contact, contacts })
  const isFormSubmittable = getIsFormSubmittable(propsForValidationAndFormSubmission)
  let submitButtonText
  if (contact.defaultShippingContact) {
    if (isFormSubmittable) {
      submitButtonText = `Delete ${contact.firstName} ${contact.lastName}`
    } else {
      submitButtonText = 'Choose New Defaults First'
    }
  } else {
    submitButtonText = `Yes, Delete ${contact.firstName} ${contact.lastName}`
  }

  return (
    <MyModal
      // When the user closes the modal, we want the form values to return to
      // their initial values.
      onClose={() => {
        setReplacementDefaultShippingContactId(null)
        setReplacementInvoiceRecipientContactId(null)
      }}
      modalTitle={() => (
        <Header as="h4">
          Delete Contact: <span style={{ fontStyle: 'italic' }}>{`${contact.firstName} ${contact.lastName}`}</span>
        </Header>
      )}
      modalContent={({ closeModal }) => (
        <Fragment>
          <Header as="h4" style={{ textAlign: 'center' }}>
            {contact.defaultShippingContact
              ? 'Choose New Defaults'
              : 'Are you sure?'
            }
          </Header>
          <Form>
            {(contact.defaultShippingContact || isContactTheOnlyInvoiceRecipient) &&
              <Grid
                columns={2} // CODE_COMMENTS_22, CODE_COMMENTS_97
                // This provides a little space for the choose new default
                // shipping contact form.
                style={{ padding: '1rem 0rem' }}
              >

                {contact.defaultShippingContact &&
                  <Grid.Row>
                    <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                      <Form.Field
                        required
                        style={{ margin: '0' }}
                      >
                        <label
                          htmlFor="replacementDefaultShippingContactId"
                        >
                          New Default Shipping Contact
                          <InformationalPopup
                            content={DISPLAYED_INFORMATIONAL_MESSAGE_DEFAULT_SHIPPING_CONTACT}
                          />
                        </label>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column style={{ flex: '1' }}>
                      <div className="ui input"> {/* CODE_COMMENTS_32 */}
                        <ChooseReplacementDefaultShippingContactOrInvoiceRecipient
                          contact={contact}
                          contacts={contacts}
                          value={replacementDefaultShippingContactId}
                          setValue={setReplacementDefaultShippingContactId}
                          name="replacementDefaultShippingContactId"
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                }

                {isContactTheOnlyInvoiceRecipient &&
                  <Grid.Row>
                    <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                      <Form.Field
                        required
                        style={{ margin: '0' }}
                      >
                        <label
                          htmlFor="replacementInvoiceRecipientContactId"
                        >
                          New Invoice Recipient
                          <InformationalPopup
                            content="At least one contact must receive Invoice emails."
                          />
                        </label>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column style={{ flex: '1' }}>
                      <div className="ui input"> {/* CODE_COMMENTS_32 */}
                        <ChooseReplacementDefaultShippingContactOrInvoiceRecipient
                          contact={contact}
                          contacts={contacts}
                          value={replacementInvoiceRecipientContactId}
                          setValue={setReplacementInvoiceRecipientContactId}
                          name="replacementInvoiceRecipientContactId"
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                }

              </Grid>
            }
            <Button
              type="submit"
              disabled={!isFormSubmittable}
              onClick={() => { submitForm(closeModal) }}
              color={isFormSubmittable && !isFetching ? 'green' : null}
              loading={isFetching}
            >
              {submitButtonText}
            </Button>
            <Button
              floated='right'
              onClick={closeModal}
              color='red'
            >
              Cancel
            </Button>
          </Form>
          {didFetchFail && !isFetching &&
            <FetchFailureUnknownErrorContent
              customerId={customerId}
              errorDetails={fetchErrorDetails}
              onDismiss={() => { setDidFetchFail(false) }}
            />
          }
        </Fragment>
      )}
      trigger={modalTrigger}
      size='tiny'
    />
  )
}


function getIsFormSubmittable({
  contact,
  contacts,
  formValues,
}) {
  const {
    replacementDefaultShippingContactId,
    replacementInvoiceRecipientContactId,
  } = formValues
  const isContactTheOnlyInvoiceRecipient = getIsContactTheOnlyInvoiceRecipient({ contact, contacts })

  const isReplacementDefaultShippingContactIdFormInputGood = (
    !contact.defaultShippingContact
    || replacementDefaultShippingContactId
  )
  const isReplacementInvoiceRecipientGood = (
    !isContactTheOnlyInvoiceRecipient
    || replacementInvoiceRecipientContactId
  )

  return (
    isReplacementDefaultShippingContactIdFormInputGood
    && isReplacementInvoiceRecipientGood
  )
}
