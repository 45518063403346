/**
 * CODE_COMMENTS_86
 */

/* eslint-disable max-len */

import uniq_ from 'lodash/uniq'
import difference_ from 'lodash/difference'

import {
  logErrorMessage,
  LOG_SEVERITY_ERROR,
} from '../../../../../utils/thirdPartyLogging'

import {
  isTruthyAndNonEmpty,
  getApiUrlPathFromHttpResponseOrErrorObject,
  getMethodFromHttpResponseOrErrorObject,
} from '../../../../../utils'


// Return the array of customer IDs in the relatedTo/From call not in the map
// call and the customerIDs in the map call not in the relatedTo/From call.
// Return undefined if no discrepancies.
export function getDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall(
  toOrFrom, // either 'to' or 'from'
  arrayOfCustomerObjects,
  arrayOfRelationshipObjects,
) {
  const relationshipCustomerIdProp = toOrFrom === 'to' ? 'destinationCustomerId' : 'sourceCustomerId'

  const customerIdsReturnedByRelatedToOrFromCall =
    uniq_(arrayOfCustomerObjects.map(obj => obj.id))
  const customerIdsReturnedByRelatedToOrFromMapCall =
    uniq_(arrayOfRelationshipObjects.map(obj => obj[relationshipCustomerIdProp]))

  const inRelatedCallNotInMapCall = difference_(
    customerIdsReturnedByRelatedToOrFromCall,
    customerIdsReturnedByRelatedToOrFromMapCall,
  )
  const inMapCallNotInRelatedCall = difference_(
    customerIdsReturnedByRelatedToOrFromMapCall,
    customerIdsReturnedByRelatedToOrFromCall,
  )

  const areThereDiscrepancies = (
    isTruthyAndNonEmpty(inRelatedCallNotInMapCall) ||
    isTruthyAndNonEmpty(inMapCallNotInRelatedCall)
  )

  if (areThereDiscrepancies) {
    return {
      inRelatedCallNotInMapCall,
      inMapCallNotInRelatedCall,
    }
  }
  return undefined
}


export function filterCustomerObjectsAndRelationshipObjectsThatHaveDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall(
  toOrFrom, // either 'to' or 'from'
  arrayOfCustomerObjects,
  arrayOfRelationshipObjects,
  discrepanciesBetweenTheTwoCalls,
) {
  const relationshipCustomerIdProp = toOrFrom === 'to' ? 'destinationCustomerId' : 'sourceCustomerId'

  const {
    inRelatedCallNotInMapCall,
    inMapCallNotInRelatedCall,
  } = discrepanciesBetweenTheTwoCalls

  const customerIdsToDeleteFromBothArrays = uniq_([
    ...inRelatedCallNotInMapCall,
    ...inMapCallNotInRelatedCall,
  ])

  const filteredArrayOfCustomerObjects = arrayOfCustomerObjects.filter(
    o => !customerIdsToDeleteFromBothArrays.includes(o.id),
  )
  const filteredArrayOfRelationshipObjects = arrayOfRelationshipObjects.filter(
    o => !customerIdsToDeleteFromBothArrays.includes(o[relationshipCustomerIdProp]),
  )

  return {
    arrayOfCustomerObjects: filteredArrayOfCustomerObjects,
    arrayOfRelationshipObjects: filteredArrayOfRelationshipObjects,
  }
}

// TODO rollbar: log error explaining the discrepancy. Amazon request IDs are
// in the customerInfoResponse and relationshipInfoResponse objects
export function logErrorExplainingDiscrepancyBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall(
  // created by the
  // getDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall() call
  discrepanciesBetweenTheTwoCalls,
  customerCallResponse,
  relationshipsCallResponse,
) {
  const {
    inRelatedCallNotInMapCall,
    inMapCallNotInRelatedCall,
  } = discrepanciesBetweenTheTwoCalls

  const customersApiUrlPath = getApiUrlPathFromHttpResponseOrErrorObject(customerCallResponse)
  const customersMethod = getMethodFromHttpResponseOrErrorObject(customerCallResponse)
  const relationshipsApiUrlPath = getApiUrlPathFromHttpResponseOrErrorObject(relationshipsCallResponse)
  const relationshipsMethod = getMethodFromHttpResponseOrErrorObject(relationshipsCallResponse)

  const inRelatedCallNotInMapCallForErrorMessage = isTruthyAndNonEmpty(inRelatedCallNotInMapCall)
    ? inRelatedCallNotInMapCall.join('\n')
    : '[none]'
  const inMapCallNotInRelatedCallForErrorMessage = isTruthyAndNonEmpty(inMapCallNotInRelatedCall)
    ? inMapCallNotInRelatedCall.join('\n')
    : '[none]'

  const details = `There's a discrepancy between the ${customersMethod} ${customersApiUrlPath} response and the ${relationshipsMethod} ${relationshipsApiUrlPath} response:
    \n\nCustomers in ${customersApiUrlPath} that are not in ${relationshipsApiUrlPath}: \n${inRelatedCallNotInMapCallForErrorMessage}
    \n\nCustomers in ${relationshipsApiUrlPath} that are not in ${customersApiUrlPath}: \n${inMapCallNotInRelatedCallForErrorMessage}
  `

  logErrorMessage({
    message: `Discrepancy between ${customersApiUrlPath} and ${relationshipsApiUrlPath}`,
    severity: LOG_SEVERITY_ERROR,
    additionalInfo: {
      details,
    },
    additionalInfoThatDoesntAffectWhetherSameLogOccurrenceHasAlreadyBeenSent: {
      customerCallHttpResponse: customerCallResponse,
      relationshipsCallHttpResponse: relationshipsCallResponse,
    },
  })
}
