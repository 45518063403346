import {
  REDUCER_NAMES_FETCH_STATUSES,
  REDUCER_NAMES_FETCH_STATUSES_FORMS,
} from '../../../../constants'
import getFetchStatusSelectors from '../HOF/fetchStatusSelectors'


export default getFetchStatusSelectors()(
  REDUCER_NAMES_FETCH_STATUSES_FORMS,
)

// Get the entire fetchStatusesForms slice (i.e. all forms)
export function getAllFormFetchStatuses(state) {
  return state[REDUCER_NAMES_FETCH_STATUSES][REDUCER_NAMES_FETCH_STATUSES_FORMS]
}
