/**
 * A very generic component which simply takes in a list of functions, each tied
 * to a component, and renders the component based on the first function that
 * returns true. Each function is passed three arguments: errorCode,
 * errorMessage, responseBody, amazonRequestId and HTTP method. When a function
 * returns true, its tied component is rendered. If multiple functions return
 * true, only the first component in the array passed in is rendered. This is a
 * great way to render different content depending on, say, the error code
 * received from the API fetch.
 */
import React from 'react'

/**
 * The 'instructions' arg should be a an object formatted like this:
 *
 * {
 *   renderWhenTrue: [
 *     { when: (errorDetails) => {...}, render: () => (<p>...</p>) },
 *     { when: (errorDetails) => {...}, render: () => (<p>...</p>) },
 *     { when: (errorDetails) => {...}, render: () => (<p>...</p>) },
 *   ]
 *   renderWhenNoneTrue: () => (<p>...</p>),
 * }
 *
 * --  renderWhenTrue: an array of objects, each of which contains two props:
 *     'when' and 'render':
 *     - when: a function that takes three arguments: an errorDetails object,
 *       which contains properties such as errorCode, errorMessage,
 *       amazonRequestId, etc. If the function returns true, the sibling prop to
 *       this one, 'render', will be rendered.
 *     - render: the component to render if 'when' returns true.
 *
 * --  renderWhenNoneTrue: the component to render when no 'when' functions from
 *     the 'renderWhenTrue' prop return true.
 *
 * --  wrapperComponent: An optional wrapper that wraps the component to render
 *     (no matter what the component to render is); make use of the children
 *     prop in the wrapper component in order to render properly. The wrapper
 *     component is passed all the props that the component to render is.
 *
 * The errorDetails arg should be an object with these properties:
 * url,
 * amazonRequestId,
 * method,
 * errorCode,
 * errorMessage,
 * responseBody
 */
export default ({ instructions, errorDetails, ...rest }) => {
  // eslint-disable-next-line no-use-before-define
  const ComponentToRender = selectComponent(instructions, errorDetails)
  const toReturn = (
    <ComponentToRender
      errorDetails={errorDetails}
      {...rest}
    />
  )
  if (instructions.wrapperComponent) {
    const WrapperComponent = instructions.wrapperComponent
    return (
      <WrapperComponent
        errorDetails={errorDetails}
        {...rest}
      >
        {toReturn}
      </WrapperComponent>
    )
  }
  return toReturn
}


const selectComponent = (instructions, errorDetails) => {
  const found = instructions.renderWhenTrue.find(({ when }) => (
    when(errorDetails)
  ))
  return found
    ? found.render
    : instructions.renderWhenNoneTrue
}
