import {
  REDUCER_NAMES_ORDERS_OF_INBOUND_UNACKNOWLEDGED_SHIPMENTS as defaultSlice,
} from '../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../utils'

import {
  withPropSimple,
  withPropOfAllSimple,
  getEntireSlice as commonGetEntireSlice,
} from './higherOrderFunctions'


// usage: getProp(state, customerId, shipmentId, 'goodPalletsOrigin')
export const getProp = withPropSimple(defaultSlice, 'byCustomerId')
// usage: getPropOfAll(state, customerId, 'goodPalletsOrigin')
export const getPropOfAll = withPropOfAllSimple(defaultSlice, 'byCustomerId')

// You can use this to get several levels of slices:
//
// getEntireSlice(state) // all shipment objects of all customers
// getEntireSlice(state, customerId) // all shipment objects of one customer
// getEntireSlice(state, customerId, orderUuid) // one shipment object
export const getEntireSlice = commonGetEntireSlice(defaultSlice, 'byCustomerId')


export const getHasAnyInboundUnacknowledgedShipments = (state, customerId) => (
  isTruthyAndNonEmpty(getEntireSlice(state, customerId))
)
