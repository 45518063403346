import React from 'react'
import { useTranslation } from 'react-i18next'

export default () => {
  const { t: translate } = useTranslation('pagelabels')
  return (
    <span
    style={{
      color: '#f2711c',
      fontWeight: 'bold',
    }}
    >
      {translate('reportShipments.You must resolve potential duplicates before submitting (click on the orange warning signs to resolve)')}
    </span>
  )
}
