// CODE_COMMENTS_277

import React from 'react'
import { Card, Image } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import GridOfContainerTypeInfo from '../ContainerTypesDifferences/ContainerTypeCard/GridOfContainerTypeInfo'

import InformationalPopup from '../../../../common-components/InformationalPopup'

import {
  CONTAINER_TYPES,
  CONTAINER_TYPES_CPP,
} from '../../../../constants'

import cbiPlasticPallet from '../../../../public/imagesAndIcons/cbi-plastic-pallet.jpg'


export default () => {
  const { t: translate } = useTranslation('common')
  return (
    <InformationalPopup
      content={
        <Card>
          <Image src={cbiPlasticPallet} />
          <Card.Content>
            <Card.Header>{CONTAINER_TYPES.find(o => o.id === CONTAINER_TYPES_CPP).name}</Card.Header>
            <Card.Description>
              <GridOfContainerTypeInfo
                info={[
                  { label: translate('Width'), measurement: '43"' },
                  { label: translate('Length'), measurement: '47"' },
                  { label: translate('Height'), measurement: '6"' },
                ]}
              />
            </Card.Description>
          </Card.Content>
        </Card>
      }
      popupProps={{
        flowing: true,
      }}
    />
  )
}
