// CODE_COMMENTS_283

import Immutable from 'seamless-immutable'

import {
  SAVE_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER,
} from '../actions/actionTypes'

import {
  convertArrayOfObjectsToObjectOfSubObjects,
} from '../../utils'

const initialState = Immutable({ byCustomerId: {} })

export default function ordersOfInboundUnacknowledgedKegShipmentsReducer(state=initialState, action) {
  switch (action.type) {
    case SAVE_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER: {
      const { customerId, info } = action.payload
      return (
        Immutable.setIn(
          state,
          ['byCustomerId', customerId],
          convertArrayOfObjectsToObjectOfSubObjects(info, 'id'),
        )
      )
    }

    default:
      return state
  }
}
