
import React from 'react'
import moment from 'moment'


import get_ from 'lodash/get'

import flow_ from 'lodash/fp/flow'
import valuesFp_ from 'lodash/fp/values'
import sortByFp_ from 'lodash/fp/sortBy'
import mapFp_ from 'lodash/fp/map'


import OrderIdLink from './containers/OrderIdLink'

import {
  validateDate,
} from '../../common-components/rewrite/ReactDatepicker'

import {
  getIsSubsidiaryATypeThatShouldDisplayOrderIdInAckInboundShipmentsForm,
  getIsSubsidiaryATypeThatShouldTrackWoodenPallets,
  getIsSubsidiaryATypeThatShouldTrackForeignKegs,
} from '../../redux/selectors/rewrite/subsidiaries'

import {
  FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS,

  FIELD_NAME_ID,
  FIELD_NAME_ORDER_ID,
  FIELD_NAME_SHIPMENT_ID,
  FIELD_NAME_CARRIER,
  FIELD_NAME_DATE_SHIPPED,
  FIELD_NAME_SOURCE,
  FIELD_NAME_TOTAL_NUMBER_KEGS,
  FIELD_NAME_DATE_RECEIVED,
  FIELD_NAME_GOOD_PALLETS,
  FIELD_NAME_BAD_PALLETS,
  FIELD_NAME_FOREIGN_KEGS,
  FIELD_NAME_SHIPMENT_TYPE,
  FIELD_NAME_SHIPMENT_TYPE_LABELS,
  FIELD_TOUCHED,
} from '../../constants/formAndApiUrlConfig/acknowledgeInboundShipments'

import {
  SHIPMENT_STATUS_ACKNOWLEDGED,
  SHIPMENT_STATUS_COMPLETED,
  SHIPMENT_STATUS_PRE_POST,
  SHIPMENT_STATUS_READY_TO_POST,
  SHIPMENT_STATUS_POSTED,
  SHIPMENT_STATUS_SENT_TO_STAGING,
  SHIPMENT_STATUS_SENT_TO_GP,
  SHIPMENT_STATUS_INVOICED,

  DEFAULT_DISPLAYED_DATE_FORMAT,
  SHIPMENT_STATUS_PARTIAL_POST,
} from '../../constants/formAndApiUrlConfig/commonConfig'

import {
  SHIPMENT_TYPES_LOCAL,
  ITEM_SKUS_SKU_TYPE_PALLET, CUSTOMER_TYPES_WAREHOUSE,
} from '../../constants'

import {
  formatApiDate,
  convertApiDateToMoment,
  formatDateForApiCall,
  doSelectObjPropsAllExistAndContainTruthyValues,
  isTruthyAndNonEmpty,
} from '../../utils'
import { getNonPalletSkusForUKE, getNonPalletSkusOfAllQuality } from '../../redux/selectors/rewrite/itemSkus'


export function getDoesAckInboundShipmentsApiErrorSayShipmentHasAlreadyBeenAcked({
  errorCode,
  responseBody,
}) {
  if (errorCode !== 400) { return false }

  const potentialAlreadyAckedErrorMessageWithinResponseBody =
    get_(responseBody, 'validationErrors.errors.shipmentStatus[0]')
  if (!potentialAlreadyAckedErrorMessageWithinResponseBody) { return false }

  const ackedOrBeyondStatuses = [
    SHIPMENT_STATUS_ACKNOWLEDGED,
    SHIPMENT_STATUS_COMPLETED,
    SHIPMENT_STATUS_PRE_POST,
    SHIPMENT_STATUS_READY_TO_POST,
    SHIPMENT_STATUS_POSTED,
    SHIPMENT_STATUS_PARTIAL_POST,
    SHIPMENT_STATUS_SENT_TO_STAGING,
    SHIPMENT_STATUS_SENT_TO_GP,
    SHIPMENT_STATUS_INVOICED,
  ]
  // https://microstartap3.atlassian.net/browse/TP3-1721 -- If the shipment has
  // already been acknowledged, the error message will be e.g. 'The current
  // status is ACK. A customer is trying to move to ACK. But the customer can
  // only move to []'. We just check for the first part of the error message:
  // 'The current status is ACK|COM|etc'
  const regex = new RegExp(
    `The current status is (${ackedOrBeyondStatuses.join('|')})`,
    'i', // i means case-insensitive
  )
  const doesErrorMessageIndicateThatTheShipmentHasAlreadyBeenAcknowledged =
    regex.test(potentialAlreadyAckedErrorMessageWithinResponseBody)
  return doesErrorMessageIndicateThatTheShipmentHasAlreadyBeenAcknowledged
}


export function getUnacknowledgedShipmentsQty({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
}) {
  return valuesFp_(inboundUnacknowledgedShipmentsSliceForThisCustomer).length
}


function getAllDateShippedValues({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
}) {
  return flow_(
    valuesFp_,
    // order must be the same as what's returned by the the
    // getInitialValues() function
    sortByFp_([
      o => getDateShippedOfShipmentToUseAsMinSelectableDateReceived(o),
      // Why do we need this second argument? Because what if two shipments have
      // the same dateShipped? We need to perform a strict sort which always
      // returns the exact same output given the same input, no matter how the
      // input might be orderd; that way this output matches that of the
      // getInitialValues() function.
      o => o.shipmentIdNumber,
    ]),
    mapFp_(o => getDateShippedOfShipmentToUseAsMinSelectableDateReceived(o)),
  )(inboundUnacknowledgedShipmentsSliceForThisCustomer)
}


function getAllShipmentTypes({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
}) {
  return flow_(
    valuesFp_,
    // order must be the same as what's returned by the the
    // getInitialValues() function
    sortByFp_([
      o => getDateShippedOfShipmentToUseAsMinSelectableDateReceived(o),
      // Why do we need this second argument? Because what if two shipments have
      // the same dateShipped? We need to perform a strict sort which always
      // returns the exact same output given the same input, no matter how the
      // input might be orderd; that way this output matches that of the
      // getInitialValues() function.
      o => o.shipmentIdNumber,
    ]),
    mapFp_(o => (o.shipmentType)),
  )(inboundUnacknowledgedShipmentsSliceForThisCustomer)
}


/**
 * This is different from hiding pallets fields in a row if the shipment is
 * local. In that case, we still display the "Good Pallets" and "Bad Pallets"
 * columns, but the individual cells are not displayed. This determines whether
 * the entire columns should even be rendered.
 */
export function getShouldPalletsColumnsBeRendered({
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  return getIsSubsidiaryATypeThatShouldTrackWoodenPallets({
    entireSubsidiariesSlice,
    subsidiaryId,
  })
}


// Omit good pallets and bad pallets fields if it's a local shipment.
export function determineFieldsToOmit({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
}) {
  const shipmentTypes = getAllShipmentTypes({ inboundUnacknowledgedShipmentsSliceForThisCustomer })
  const fieldsToOmit = shipmentTypes.map((shipmentType, index) => (
    shipmentType === SHIPMENT_TYPES_LOCAL ? index : null
  )).filter(item => item !== null)
  return {
    [FIELD_NAME_GOOD_PALLETS]: fieldsToOmit,
    [FIELD_NAME_BAD_PALLETS]: fieldsToOmit,
  }
}


export function getArePalletsFieldsRenderedForThisRow({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
  entireSubsidiariesSlice,
  subsidiaryId,
  rowIndex,
}) {
  if (!getShouldPalletsColumnsBeRendered({
    entireSubsidiariesSlice,
    subsidiaryId,
  })) { return false }
  const fieldsToOmit = determineFieldsToOmit({
    inboundUnacknowledgedShipmentsSliceForThisCustomer,
  })
  const arePalletsFieldsOmittedForThisRow = fieldsToOmit[FIELD_NAME_GOOD_PALLETS].includes(rowIndex)
  return !arePalletsFieldsOmittedForThisRow
}

// Get all the text-only-not-fillable-field values for the form
export function getInitialValues({
  entireSubsidiariesSlice,
  customerId,
  subsidiaryId,
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
}) {
  const info = flow_(
    valuesFp_,
    // order is important, not just for displaying items in a sensible order but
    // also because the order must be the same as what's returned by the the
    // getAckInboundShipmentsFormMinimumDateReceivedValues() function
    sortByFp_([
      o => getDateShippedOfShipmentToUseAsMinSelectableDateReceived(o),
      // Why do we need this second argument? Because what if two shipments have
      // the same dateShipped? We need to perform a strict sort which always
      // returns the exact same output given the same input, no matter how the
      // input might be orderd; that way this output matches that of the
      // getInitialValues() function.
      o => o.shipmentIdNumber,
    ]),
    // All delivered shipments should have a non-null dateReceived value, but
    // this isn't an absolute guarantee. If a delivered shipment's
    // dateReceived is null, use the dateShipped (which should always be
    // non-null).
    mapFp_(o => ({
      [FIELD_NAME_ID]: o.id,
      ...(
        getIsSubsidiaryATypeThatShouldDisplayOrderIdInAckInboundShipmentsForm({
          entireSubsidiariesSlice,
          subsidiaryId,
        })
          ? {
            [FIELD_NAME_ORDER_ID]: (
              <OrderIdLink
                customerId={customerId}
                shipment={o}
              />
            ),
          }
          : {}
      ),
      [FIELD_NAME_SHIPMENT_ID]: o.shipmentId,
      [FIELD_NAME_CARRIER]: o.carrierName, // CODE_COMMENTS_86
      [FIELD_NAME_DATE_SHIPPED]: (
        // Technically, it's possible for local shipments to have an undefined
        // dateShipped prop. In such a case, we want to display nothing/a blank
        // value.
        o.dateShipped
          ? formatApiDate(o.dateShipped, DEFAULT_DISPLAYED_DATE_FORMAT)
          : o.plannedPickupDate ? formatApiDate(o.plannedPickupDate, DEFAULT_DISPLAYED_DATE_FORMAT)
            : null
      ),
      [FIELD_NAME_SOURCE]: o.originCustomerName,
      // No "Good Pallets" or "Bad Pallets" initial values; see CODE_COMMENTS_177
      [FIELD_NAME_TOTAL_NUMBER_KEGS]: 0,
      [FIELD_NAME_DATE_RECEIVED]: (
        o.dateReceived
          ? formatApiDate(o.dateReceived, DEFAULT_DISPLAYED_DATE_FORMAT)
          : null
      ),
      [FIELD_NAME_SHIPMENT_TYPE]: FIELD_NAME_SHIPMENT_TYPE_LABELS[o.shipmentType],
      [FIELD_TOUCHED]: false,
    })),
  )(inboundUnacknowledgedShipmentsSliceForThisCustomer)
  return { [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS]: info }
}


/*
 * *****************************************************************************
 * Determine whether field rows are filled out.
 * *****************************************************************************
*/

export function getIsFieldRowFullyFilledOut({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
  entireSubsidiariesSlice,
  subsidiaryId,
  itemSkuIds,
  rowValues,
  rowIndex,
  returnFalseIfDateValueIsInvalid=true, // i.e. before minDate
}) {
  if (!isTruthyAndNonEmpty(rowValues)) { return false }
  // make sure all the required fields are filled out
  let requiredFields = [
    FIELD_NAME_DATE_RECEIVED,
    // keg qtys (HB, SB, etc.) must all be filled in, even if it's with a 0.
    ...itemSkuIds,
  ]
  // If the pallets fields are rendered, the user must enter a value for both
  // good pallets and bad pallets (that value can be 0).
  if (
    getShouldPalletsColumnsBeRendered({
      entireSubsidiariesSlice,
      subsidiaryId,
    })
    &&
    !isShipmentInRowXALocal({
      inboundUnacknowledgedShipmentsSliceForThisCustomer,
      rowIndex,
    })
  ) {
    requiredFields = [
      ...requiredFields,
      FIELD_NAME_GOOD_PALLETS,
      FIELD_NAME_BAD_PALLETS,
    ]
  }
  if (getIsSubsidiaryATypeThatShouldTrackForeignKegs({
    entireSubsidiariesSlice,
    subsidiaryId,
  })) {
    requiredFields = [
      ...requiredFields,
      FIELD_NAME_FOREIGN_KEGS,
      // keg qtys (HB, SB, etc.) must all be filled in, even if it's with a 0.
    ]
  }
  if (!doSelectObjPropsAllExistAndContainTruthyValues(rowValues, requiredFields, true)) {
    return false
  }

  // keg qtys (HB, SB, etc) cannot all be 0; at least one of them must be a
  // positive integer
  if ([
    ...itemSkuIds,
  ].every(fieldName => (
    !rowValues[fieldName]
    ||
    Number(rowValues[fieldName]) === 0
  ))) {
    return false
  }

  if (returnFalseIfDateValueIsInvalid) {
    return Boolean(validateDate({
      value: get_(rowValues, FIELD_NAME_DATE_RECEIVED),
      ...getDateFieldFencingOfAcknowledgeInboundShipmentsRow({
        inboundUnacknowledgedShipmentsSliceForThisCustomer,
        rowIndex,
      }),
    }))
  }
  return true
}


export function getIsFieldRowPartiallyFilledOut({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
  entireSubsidiariesSlice,
  subsidiaryId,
  itemSkuIds,
  rowValues,
  rowIndex,
  returnTrueIfFieldRowFullyFilledOutButDateValueIsInvalid=true, // i.e. before minDate
}) {
  return (
    isAtLeastOneFieldFilledOut({
      inboundUnacknowledgedShipmentsSliceForThisCustomer,
      itemSkuIds,
      rowValues,
      rowIndex,
    })
    &&
    !getIsFieldRowFullyFilledOut({
      inboundUnacknowledgedShipmentsSliceForThisCustomer,
      entireSubsidiariesSlice,
      subsidiaryId,
      itemSkuIds,
      rowValues,
      rowIndex,
      returnFalseIfDateValueIsInvalid: returnTrueIfFieldRowFullyFilledOutButDateValueIsInvalid,
    })
  )
}

function isShipmentInRowXALocal({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
  rowIndex,
}) {
  const shipmentTypes = getAllShipmentTypes({ inboundUnacknowledgedShipmentsSliceForThisCustomer })
  const shipmentTypeForThisRow = shipmentTypes[rowIndex]
  return shipmentTypeForThisRow === SHIPMENT_TYPES_LOCAL
}


function isAtLeastOneFieldFilledOut({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
  itemSkuIds,
  rowValues,
  rowIndex,
}) {
  if (!isTruthyAndNonEmpty(rowValues)) { return false }
  const isShipmentALocal = isShipmentInRowXALocal({
    inboundUnacknowledgedShipmentsSliceForThisCustomer,
    rowIndex,
  })
  const filledOutFields = Object.keys(rowValues).filter(fieldName => rowValues[fieldName] || rowValues[fieldName] === 0)
  return [
    FIELD_NAME_DATE_RECEIVED,
    FIELD_NAME_FOREIGN_KEGS,
    ...(
      isShipmentALocal
        ? []
        : [
          FIELD_NAME_GOOD_PALLETS,
          FIELD_NAME_BAD_PALLETS,
        ]
    ),
    ...itemSkuIds,
  ].some(fieldName => filledOutFields.includes(fieldName))
}


/*
 * *****************************************************************************
 * Misc.
 * *****************************************************************************
*/

// Returns an object containing the date-fencing props that should be set on the
// react-datepicker field of an Acknowledge Inbound Shipments row
export function getDateFieldFencingOfAcknowledgeInboundShipmentsRow({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
  rowIndex,
  contractEndDate,
}) {
  const dateShipped = getAllDateShippedValues({ inboundUnacknowledgedShipmentsSliceForThisCustomer })[rowIndex]
  return ({
    minDate: convertApiDateToMoment(dateShipped),
    // TP3-7683: Enable customer to acknowledge orders once their contract expires
    // max date to acknowledge is contract end date if contract expired.
    // eslint-disable-next-line max-len
    maxDate: (contractEndDate && contractEndDate < moment())? convertApiDateToMoment(contractEndDate) : moment(), // today
  })
}


// CODE_COMMENTS_255: Returns a unix timestamp
function getDateShippedOfShipmentToUseAsMinSelectableDateReceived(shipmentObj) {
  if (shipmentObj.dateShipped) {
    return shipmentObj.dateShipped
  } else if (shipmentObj.plannedPickupDate) {
    return shipmentObj.plannedPickupDate
  }
  return formatDateForApiCall({
    date: moment().subtract(6, 'weeks'),
  })
}

export const getUnacknowledgedShipmentSkus = (
  inboundUnacknowledgedShipmentsSliceForThisCustomer, entireItemSkusSlice, itemSkuIdList, subsidiaryId, customerType,
) => {
  const shippedSkuSet = new Set()
  Object.values(inboundUnacknowledgedShipmentsSliceForThisCustomer)?.forEach(({ lineItems }) => lineItems?.forEach(
    line => line?.itemSkuId && line?.linkType?.toUpperCase() === 'SHIPPED'
      && entireItemSkusSlice[line?.itemSkuId]?.skuType !== ITEM_SKUS_SKU_TYPE_PALLET
      && shippedSkuSet.add(line?.itemSkuId)),
  )
  if (subsidiaryId === 24 || subsidiaryId === 25) {
    if (customerType === CUSTOMER_TYPES_WAREHOUSE) {
      const filteredSkuList = getNonPalletSkusForUKE({ entireItemSkusSlice, itemSkuIdList, shippedSkuSet })
      shippedSkuSet.add(filteredSkuList)
    } else {
      const filteredSkuList = getNonPalletSkusOfAllQuality({ entireItemSkusSlice, itemSkuIdList, shippedSkuSet })
      shippedSkuSet.add(filteredSkuList)
    }
  }
  return [...shippedSkuSet]
}
