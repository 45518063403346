/**
 * CODE_COMMENTS_220
 */

/* eslint-disable max-len */

import sortBy_ from 'lodash/sortBy'


import {
  getPropPassingInEntireCustomersSlice as getCustomerProp,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getContracts,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedTo,
} from '../../../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getWhichContractBrewersDoesBrwHaveCustomerLvlPermissionsToReportInventoryFor,
} from '../../../../redux/selectors/rewrite/permissionsAtTheCustomerLevel'


import {
  INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ,
  INVENTORY_REPORT_OBJS_PUBS,
  INVENTORY_REPORT_OBJS_CONTRACT_BREWERS,
  INFO_JUST_FOR_THE_WEB_APP,
  INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME,
  INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID,
  INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID,
} from '../constants'

import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CBMST,
} from '../../../../constants'

import {
  getNamesAndIdsOfCustomersFromInventoryReportObjects,
  getAllPubsABrwCanReportInventoryFor,
} from '../'

import {
  isTruthyAndNonEmpty,
  parseCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../utils'


// For Report Inventory forms (as opposed to Edit Inventory forms)
export function gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist({
  customerId,
  entireCustomersSlice,
  entireContractsSlice,
  entireParentChildLinksSlice,
  entireRelationshipsSlice,
}) {
  const hasActiveBrwPpfContract = isTruthyAndNonEmpty(getContracts({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CBMST],
    notExpiredOnly: false, // CODE_COMMENTS_268
    activeStatusOnly: true,
    noFutureContracts: true,
  }))
  const brwName = getCustomerProp(entireCustomersSlice, customerId, 'name')
  const pubs = getAllPubsABrwCanReportInventoryFor({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    customerId,
  })
  const conbrwCustomerIds = getWhichContractBrewersDoesBrwHaveCustomerLvlPermissionsToReportInventoryFor({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  })

  return {
    ...(
      hasActiveBrwPpfContract
        ? {
          [INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ]: {
            [INFO_JUST_FOR_THE_WEB_APP]: {
              [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: brwName,
            },
            customerId,
          },
        }
        : {}
    ),
    ...(
      isTruthyAndNonEmpty(pubs)
        ? {
          [INVENTORY_REPORT_OBJS_PUBS]: pubs.map(({ name, id }) => ({
            [INFO_JUST_FOR_THE_WEB_APP]: {
              [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: name,
            },
            customerId: id,
          })),
        }
        : {}
    ),
    ...(
      isTruthyAndNonEmpty(conbrwCustomerIds)
        ? {
          [INVENTORY_REPORT_OBJS_CONTRACT_BREWERS]: conbrwCustomerIds.map(id => ({
            [INFO_JUST_FOR_THE_WEB_APP]: {
              [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: getCustomerProp(entireCustomersSlice, id, 'name'),
              [INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID]: customerId,
              [INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID]: id,
            },
            // This should be the Brewer's customerId, not the Contract
            // Brewer's, because that's what the API will expect it to be
            // (remember, contract brewing contracts are technically a BRW's
            // contract, not a CONBRW's contract).
            customerId,
          })),
        }
        : {}
    ),
  }
}


// For Edit form and full details modals
export function gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist({
  customerId,
  // An object representing one row in the history table and containing all
  // the individual inventory objects for this single as-displayed-to-the-user
  // Inventory Report object (see CODE_COMMENTS_229 for details):
  // {
  //   countDate: 1567277112000,
  //   inventoryReportObjsAndReduxIdentifiers: [
  //     { reduxIdentifier: brwCustId, inventoryReportObj: <inventoryReportObj> },
  //     { reduxIdentifier: childPubCustId, inventoryReportObj: <inventoryReportObj> },
  //     { reduxIdentifier: brwCustId__--__conbrwCustId, inventoryReportObj: <inventoryReportObj> },
  //   ]
  // }
  countDateAndInventoryReportObjsWithReduxIdentifiers,
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
}) {
  const { inventoryReportObjsAndReduxIdentifiers } = countDateAndInventoryReportObjsWithReduxIdentifiers

  // Default Contract, if there is one
  const brwName = getCustomerProp(entireCustomersSlice, customerId, 'name')
  const inventoryReportObjAndReduxIdOfDefaultContract = inventoryReportObjsAndReduxIdentifiers.find(o => {
    const {
      customerId: customerIdOfReduxId,
      contractBrewerCustomerId,
    } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(o.reduxIdentifier)
    return (
      customerIdOfReduxId === customerId
      && !contractBrewerCustomerId
    )
  })

  const inventoryReportObjOfDefaultContract = inventoryReportObjAndReduxIdOfDefaultContract &&
    inventoryReportObjAndReduxIdOfDefaultContract.inventoryReportObj

  // Pubs
  const inventoryReportObjsAndReduxIdentifiersOfPubs = inventoryReportObjsAndReduxIdentifiers.filter(o => {
    const {
      customerId: customerIdOfReduxId,
      contractBrewerCustomerId,
    } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(o.reduxIdentifier)
    return (
      // The only way this could happen is if it's a pub
      customerIdOfReduxId !== customerId
      && !contractBrewerCustomerId
    )
  })
  const inventoryReportObjsOfPubs = inventoryReportObjsAndReduxIdentifiersOfPubs.map(o => o.inventoryReportObj)

  let pubsNamesAndIds = getNamesAndIdsOfCustomersFromInventoryReportObjects({
    arrayOfInventoryReportObjs: inventoryReportObjsOfPubs,
    entireCustomersSlice,
  })
  pubsNamesAndIds = sortBy_(pubsNamesAndIds, 'name')

  // Inventory Report Objects of Contract Brewing contracts
  const inventoryReportObjsAndReduxIdsOfContractBrewingContracts = inventoryReportObjsAndReduxIdentifiers.filter(o => {
    const {
      contractBrewerCustomerId,
    } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(o.reduxIdentifier)
    return contractBrewerCustomerId
  })

  const inventoryReportObjsOfContractBrewingContracts = inventoryReportObjsAndReduxIdsOfContractBrewingContracts.map(o => o.inventoryReportObj)
  const conbrwNamesAndCustomerIdsAndContractIds = getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    propDefs: [
      { prop: 'id', residesIn: 'customerObj' },
      { prop: 'name', residesIn: 'customerObj' },
      { prop: 'sourcePpfContract', residesIn: 'relationshipObj' },
    ],
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
  })


  return {
    ...(
      inventoryReportObjOfDefaultContract
        ? {
          [INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ]: {
            [INFO_JUST_FOR_THE_WEB_APP]: {
              [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: brwName,
            },
            ...inventoryReportObjOfDefaultContract,
          },
        }
        : {}
    ),
    ...(
      isTruthyAndNonEmpty(inventoryReportObjsOfPubs)
        ? {
          [INVENTORY_REPORT_OBJS_PUBS]: inventoryReportObjsOfPubs.map(inventoryReportObj => ({
            [INFO_JUST_FOR_THE_WEB_APP]: {
              [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: pubsNamesAndIds.find(({ id }) => id === inventoryReportObj.customerId).name,
            },
            ...inventoryReportObj,
          })),
        }
        : {}
    ),
    ...(
      isTruthyAndNonEmpty(inventoryReportObjsOfContractBrewingContracts)
        ? {
          [INVENTORY_REPORT_OBJS_CONTRACT_BREWERS]: inventoryReportObjsOfContractBrewingContracts.map(inventoryReportObj => ({
            [INFO_JUST_FOR_THE_WEB_APP]: {
              [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: conbrwNamesAndCustomerIdsAndContractIds.find(({ sourcePpfContract }) => sourcePpfContract === inventoryReportObj.contractId).name,
              [INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID]: customerId,
              [INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID]: conbrwNamesAndCustomerIdsAndContractIds.find(({ sourcePpfContract }) => sourcePpfContract === inventoryReportObj.contractId).id,
            },
            ...inventoryReportObj,
          })),
        }
        : {}
    ),
  }
}
