import { compose } from 'recompose'


import FeatureDisabledDueToInactiveAccountOrHold from '../../../common-components/FeatureDisabledDueToInactiveAccountOrHold'
import WaitForCollarPlatesToLoad from './subFeatures/WaitForCollarPlatesToLoad'
import OrerAndEditShared from './containers'


export default compose(
  FeatureDisabledDueToInactiveAccountOrHold,
  WaitForCollarPlatesToLoad,
)(OrerAndEditShared)
