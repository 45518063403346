/**
 * CODE_COMMENTS_220
 */

/* eslint-disable max-len */

import {
  getPropPassingInEntireCustomersSlice as getCustomerProp,
} from '../../../../redux/selectors/rewrite/customers'

import {
  getWhichContracteesCanConbrwReportInventoryFor,
} from '../../../../redux/selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'

import {
  INVENTORY_REPORT_OBJS_CBMST_REPORT_OBJ,
  INVENTORY_REPORT_OBJS_CONTRACTEES,
  INFO_JUST_FOR_THE_WEB_APP,
  INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME,
  INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID,
  INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID,
} from '../constants'

import {
  getNamesAndIdsOfCustomersFromInventoryReportObjects,
} from '../'

import {
  isTruthyAndNonEmpty,
  parseCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../utils'


// For Report Inventory forms (as opposed to Edit Inventory forms)
export function gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
}) {
  const conbrwName = getCustomerProp(entireCustomersSlice, customerId, 'name')
  const contracteeCustomerIds = getWhichContracteesCanConbrwReportInventoryFor({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    conbrwCustomerId: customerId,
  })

  return {
    [INVENTORY_REPORT_OBJS_CBMST_REPORT_OBJ]: {
      [INFO_JUST_FOR_THE_WEB_APP]: {
        [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: conbrwName,
      },
      customerId,
    },
    ...(
      isTruthyAndNonEmpty(contracteeCustomerIds)
        ? {
          [INVENTORY_REPORT_OBJS_CONTRACTEES]: contracteeCustomerIds.map(id => ({
            [INFO_JUST_FOR_THE_WEB_APP]: {
              [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: getCustomerProp(entireCustomersSlice, id, 'name'),
              [INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID]: id,
              [INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID]: customerId,
            },
            // This should be the Brewer's customerId, not the Contract
            // Brewer's, because that's what the API will expect it to be
            // (remember, contract brewing contracts are technically a BRW's
            // contract, not a CONBRW's contract).
            customerId: id,
          })),
        }
        : {}
    ),
  }
}


// For Edit form and full details modals
export function gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist({
  entireCustomersSlice,
  customerId,
  // An object representing one row in the history table and containing all
  // the individual inventory objects for this single as-displayed-to-the-user
  // Inventory Report object (see CODE_COMMENTS_229 for details):
  // {
  //   countDate: 1567277112000,
  //   inventoryReportObjsAndReduxIdentifiers: [
  //     { reduxIdentifier: brwCustId, inventoryReportObj: <inventoryReportObj> },
  //     { reduxIdentifier: childPubCustId, inventoryReportObj: <inventoryReportObj> },
  //     { reduxIdentifier: brwCustId__--__conbrwCustId, inventoryReportObj: <inventoryReportObj> },
  //   ]
  // }
  countDateAndInventoryReportObjsWithReduxIdentifiers,
}) {
  const { inventoryReportObjsAndReduxIdentifiers } = countDateAndInventoryReportObjsWithReduxIdentifiers

  // CBMST contract
  const conbrwName = getCustomerProp(entireCustomersSlice, customerId, 'name')
  const inventoryReportObjAndReduxIdsOfCbmstContract = inventoryReportObjsAndReduxIdentifiers.find(o => {
    const {
      customerId: customerIdOfReduxId,
    } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(o.reduxIdentifier)
    return (
      customerIdOfReduxId === customerId
    )
  })
  const inventoryReportObjOfCbmstContract = inventoryReportObjAndReduxIdsOfCbmstContract &&
    inventoryReportObjAndReduxIdsOfCbmstContract.inventoryReportObj

  // Contractees (i.e. conbrw contracts)
  const inventoryReportObjsAndReduxIdsOfContractBrewingContracts = inventoryReportObjsAndReduxIdentifiers.filter(o => {
    const {
      contractBrewerCustomerId,
    } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(o.reduxIdentifier)
    return (
      contractBrewerCustomerId === customerId
    )
  })
  const inventoryReportObjsOfContractBrewingContracts =
    inventoryReportObjsAndReduxIdsOfContractBrewingContracts.map(o => o.inventoryReportObj)

  const contracteeNamesAndIds = getNamesAndIdsOfCustomersFromInventoryReportObjects({
    arrayOfInventoryReportObjs: inventoryReportObjsOfContractBrewingContracts,
    entireCustomersSlice,
  })

  return {
    [INVENTORY_REPORT_OBJS_CBMST_REPORT_OBJ]: {
      [INFO_JUST_FOR_THE_WEB_APP]: {
        [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: conbrwName,
      },
      ...inventoryReportObjOfCbmstContract,
    },
    ...(
      isTruthyAndNonEmpty(inventoryReportObjsOfContractBrewingContracts)
        ? {
          [INVENTORY_REPORT_OBJS_CONTRACTEES]: inventoryReportObjsOfContractBrewingContracts.map(inventoryReportObj => ({
            [INFO_JUST_FOR_THE_WEB_APP]: {
              [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: contracteeNamesAndIds.find(({ id }) => id === inventoryReportObj.customerId).name,
              [INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID]: inventoryReportObj.customerId,
              [INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID]: customerId,
            },
            ...inventoryReportObj,
          })),
        }
        : {}
    ),
  }
}
