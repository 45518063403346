// CODE_COMMENTS_267

import { all, call, takeEvery } from 'redux-saga/effects'


import get_ from 'lodash/get'
import minBy_ from 'lodash/minBy'

import flow_ from 'lodash/fp/flow'
import groupByFp_ from 'lodash/fp/groupBy'
import valuesFp_ from 'lodash/fp/values'
import mapFp_ from 'lodash/fp/map'
import sortByFp_ from 'lodash/fp/sortBy'

import {
  fetchItemSkus,
} from '../../customers/itemSkus'
import {
  fetchSubsidiaries,
} from '../../customers/subsidiaries'

import { publicFetch } from '../../fetch'
import {
  FETCH_INFO_NEEDED_TO_CONSTRUCT_DIST_REPORT_INVENTORY_NO_LOGIN_REQUIRED_FORM,
} from '../../../actions/actionTypes'

import {
  API_URL_PATH_DIST_INVENTORY_GROUP_BY_TOKEN,
  BUSINESS_UNIT_ID_TYPE_MICROSTAR,
  BUSINESS_UNIT_ID_TYPE_KEGCRAFT,
  BUSINESS_UNIT_ID_TYPE_KEGSTAR,
  BUSINESS_UNIT_ID_TYPE_CONSTELLATION,
} from '../../../../constants'

import {
  generalDoFailure,
} from '../../util/fetchFailure'
import {
  createApiTimedTokenHttpHeader,
  extractMostImportantDetailsFromApiErrorObject,
  sortArrayOfObjectsByTemplateArray,
} from '../../../../utils'


function* fetchInfoNeededToConstructDistributorReportInventoryNoLoginRequiredForm(action) {
  const {
    token,
    setConfigObjReturnedFromDistInvNoLoginConfigCall,
    setDoesUserNotHavePermissionsToMakeConfigCall,
    setDidConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray,
    setIsDistInvNoLoginConfigCallFetching,
    setDidDistInvNoLoginConfigCallSucceed,
    setdidDistInvNoLoginConfigCallFail,
    setIsTokenExpired,
    setHasDistInvAlreadyBeenReportedForThisToken,
    isDistReportInventoryNoLoginRequired,
    isDistReportConstellationNoLoginRequired,
  } = action.payload
  setIsDistInvNoLoginConfigCallFetching(true)
  let distInventoryGroupResponse
  try {
    // yield all to perform the calls at the same time
    [
      distInventoryGroupResponse,
    ] = yield all([
      call(
        publicFetch,
        {
          path: API_URL_PATH_DIST_INVENTORY_GROUP_BY_TOKEN,
          headers: createApiTimedTokenHttpHeader(token),
          // Don't retry the call several times if the token is expired or doesn't
          // exist.
          customErrorReactions: [
            {
              check: error => ([401, 500, 403].includes(get_(error, 'response.status'))),
              reaction: function mySaga(error) {
                // Throwing the error here prevents the call from being re-tried
                // multiple times, which is what we want. This error will be
                // caught by the try/catch block below.
                throw error
              },
              shouldThisErrorReactionBeCalledBeforeAnyUniversalErrorReactionsSoAsToAvoidUnnecessaryRefetches: true,
            },
          ],
        },
      ),
      call(
        fetchItemSkus,
        {
          payload: {
            isDistReportInventoryNoLoginRequired,
            isDistReportConstellationNoLoginRequired,
            token,
          },
        },
      ),
      call(
        fetchSubsidiaries,
        {
          payload: {
            isDistReportInventoryNoLoginRequired,
            isDistReportConstellationNoLoginRequired,
            token,
          },
        },
      ),
    ])
  } catch (error) {
    // See CODE_COMMENTS_267 for what all the error responses mean
    if ([401, 500, 403].includes(get_(error, 'response.status'))) {
      if (error.response.status === 401) {
        setIsTokenExpired(true)
        setHasDistInvAlreadyBeenReportedForThisToken(false)
        setDoesUserNotHavePermissionsToMakeConfigCall(false)
      } else if (error.response.status === 500) {
        setIsTokenExpired(false)
        setHasDistInvAlreadyBeenReportedForThisToken(true)
        setDoesUserNotHavePermissionsToMakeConfigCall(false)
      } else if (error.response.status === 403) {
        const permissionsMessagePart = 'does not have appropriate permissions to execute this function'
        if (get_(error, 'response.data.message').includes(permissionsMessagePart)) {
          setIsTokenExpired(false)
          setHasDistInvAlreadyBeenReportedForThisToken(false)
          setDoesUserNotHavePermissionsToMakeConfigCall(true)
        } else {
          setIsTokenExpired(false)
          setHasDistInvAlreadyBeenReportedForThisToken(false)
          setDoesUserNotHavePermissionsToMakeConfigCall(false)
          yield call(doFailure, { error, ...action.payload })
        }
      }
      setConfigObjReturnedFromDistInvNoLoginConfigCall(null)
      setDidConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray(false)
      setIsDistInvNoLoginConfigCallFetching(false)
      setDidDistInvNoLoginConfigCallSucceed(true)
      setdidDistInvNoLoginConfigCallFail(false)
    } else {
      yield call(doFailure, { error, ...action.payload })
    }
    return
  }
  yield call(doSuccess, { response: distInventoryGroupResponse, ...action.payload })
}


function doSuccess({
  response,
  setIsDistInvNoLoginConfigCallFetching,
  setDidDistInvNoLoginConfigCallSucceed,
  setdidDistInvNoLoginConfigCallFail,
  setConfigObjReturnedFromDistInvNoLoginConfigCall,
  setSubsidiaryId, // CODE_COMMENTS_283
  setDidConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray,
  setIsTokenExpired,
  setHasDistInvAlreadyBeenReportedForThisToken,
  setDoesUserNotHavePermissionsToMakeConfigCall,
  setDistInvNoLoginConfigCallFailureErrorDetails,
  isDistReportConstellationNoLoginRequired,
}) {
  // eslint-disable-next-line max-len
  const essentialDetails = convertDistInventoryGroupResponseBodyToEssentialDetails(response.data, isDistReportConstellationNoLoginRequired)
  setConfigObjReturnedFromDistInvNoLoginConfigCall(essentialDetails)
  setSubsidiaryId(null)
  setDidConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray(essentialDetails.customers.length === 0)
  setIsDistInvNoLoginConfigCallFetching(false)
  setDidDistInvNoLoginConfigCallSucceed(true)
  setdidDistInvNoLoginConfigCallFail(false)
  setIsTokenExpired(false)
  setHasDistInvAlreadyBeenReportedForThisToken(false)
  setDoesUserNotHavePermissionsToMakeConfigCall(false)
  setDistInvNoLoginConfigCallFailureErrorDetails(null)
}


function* doFailure({
  error,
  setIsDistInvNoLoginConfigCallFetching,
  setDidDistInvNoLoginConfigCallSucceed,
  setdidDistInvNoLoginConfigCallFail,
  setDistInvNoLoginConfigCallFailureErrorDetails,
  setIsTokenExpired,
  setHasDistInvAlreadyBeenReportedForThisToken,
  setDidConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  setDistInvNoLoginConfigCallFailureErrorDetails(extractMostImportantDetailsFromApiErrorObject({ error }))
  setIsDistInvNoLoginConfigCallFetching(false)
  setDidDistInvNoLoginConfigCallSucceed(false)
  setdidDistInvNoLoginConfigCallFail(true)
  setIsTokenExpired(false)
  setHasDistInvAlreadyBeenReportedForThisToken(false)
  setDidConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray(false)
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    FETCH_INFO_NEEDED_TO_CONSTRUCT_DIST_REPORT_INVENTORY_NO_LOGIN_REQUIRED_FORM,
    fetchInfoNeededToConstructDistributorReportInventoryNoLoginRequiredForm,
  ],
]


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

function convertDistInventoryGroupResponseBodyToEssentialDetails(
  responseBody, // see CODE_COMMENTS_267 for the shape of responseBody
  isDistReportConstellationNoLoginRequired,
) {
  return {
    firstName: responseBody.tokenDataObject.contactObject.firstName,
    lastName: responseBody.tokenDataObject.contactObject.lastName,
    emailAddress: responseBody.tokenDataObject.contactObject.emailAddress,
    // no need to group for nologin constellation
    // eslint-disable-next-line max-len
    customers: !isDistReportConstellationNoLoginRequired ? groupCustomersByPhysicalAddress(responseBody.childDists) : [responseBody.childDists],
    tokenExpireDate: responseBody.tokenDataObject.tokenExpireDate,
    tokenCounter: responseBody.tokenDataObject.tokenCounter,
    tokenMaxCounter: responseBody.tokenDataObject.tokenMaxCounter,
  }
}


function groupCustomersByPhysicalAddress(customerObjs) {
  return flow_(
    groupByFp_(o => o.customerAddresses[0].id),
    valuesFp_,
    // sort each group by MKM, KC, KSK, CBI.
    mapFp_(a => sortArrayOfObjectsByTemplateArray(
      a,
      'businessUnitId',
      [
        BUSINESS_UNIT_ID_TYPE_MICROSTAR,
        BUSINESS_UNIT_ID_TYPE_KEGCRAFT,
        BUSINESS_UNIT_ID_TYPE_KEGSTAR,
        BUSINESS_UNIT_ID_TYPE_CONSTELLATION,
      ],
    )),
    // sort by lowest TAPId in each group
    sortByFp_([arrayOfCustomerObjs => (minBy_(arrayOfCustomerObjs, 'tapcustomerId').tapcustomerId)]),
  )(customerObjs)
}
