import { call, select } from 'redux-saga/effects'


// import { createSignedDataTESTINGONLY } from './_createSignedDataTESTINGONLY'

import { privateFetch } from '../../fetch'

import {
  getHasStoredBankAccountInfo,
} from '../../../selectors/bankAccountInfo'

import {
  generalDoFailure,
} from '../../util/fetchFailure'

import {
  API_URL_PATH_SIGNED_PAYMENTS,
  INTERNAL_ERROR_MESSAGES_FAILED_FORM_SUBMISSION,
} from '../../../../constants'

import {
  TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE,
} from '../../../../config'


// Returns an object of key-value pairs that the web app should POST as Form
// Data to the payments integrator's website.
export default function* fetchSignedData(action) {
  const {
    customerId,
    amount,
    saveNewBankAccountInfoIfNoBankAccountInfoYetSaved,
    useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved,
    updateSavedBankAccountIfUseNewBankAccount,
    remittanceDetail,
  } = action.payload

  const hasStoredBankAccountInfo = yield select(getHasStoredBankAccountInfo, customerId)

  let useSavedPaymentInfo
  if (
    TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE
    || !hasStoredBankAccountInfo
    || (
      useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved === 'new'
      && !updateSavedBankAccountIfUseNewBankAccount
    )
  ) {
    useSavedPaymentInfo = false
  } else {
    useSavedPaymentInfo = true
  }

  let transactionType
  if (TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE) {
    transactionType = 'sale'
  } else if (saveNewBankAccountInfoIfNoBankAccountInfoYetSaved) {
    transactionType = 'sale,create_payment_token'
  } else if (useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved === 'saved') {
    transactionType = 'sale'
  } else if (useSavedOrNewBankAccountIfBankAccountInfoAlreadySaved === 'new') {
    transactionType = updateSavedBankAccountIfUseNewBankAccount
      ? 'sale,update_payment_token'
      : 'sale'
  } else {
    transactionType = 'sale'
  }

  let response
  try {
    // const signedData = yield call(
    //   createSignedDataTESTINGONLY,
    //   {
    //     customerId,
    //     amount,
    //     useSavedPaymentInfo,
    //     transactionType,
    //   })
    // response = { data: signedData }
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_SIGNED_PAYMENTS,
        method: 'POST',
        data: {
          customerId,
          // Why are we converting the amount to a string? Although it seems
          // wrong, it's actually the right thing to do; 350.1 will be converted
          // to '350.1', not '350.10', and we want to sent '350.1'. We want to
          // send an amount string exactly as the number will be sent to
          // CyberSource. And since we're sending CyberSource a number (e.g.
          // 310.1), we want the string we send to the API to match 310.1
          // exactly--sending a string makes it easier for the backend to create
          // the comma-separated string it will sign.
          amount: amount.toString(),
          useSavedPaymentInfo,
          transactionType,
          remittanceDetail,
        },
      },
    )
  } catch (error) {
    yield call(doFailure, { error, customerId })
    return INTERNAL_ERROR_MESSAGES_FAILED_FORM_SUBMISSION
  }
  return response.data
}


function* doFailure({ error }) {
  // logs the error to our 3rd-party logging service
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
}
