import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Accordion,
  // Label,
} from 'semantic-ui-react'

import InformationalPopup from '../../../../../../../common-components/InformationalPopup'
import './styles.css'


export default ({ formType, children }) => {
  const { t: translate } = useTranslation('pagelabels')
  const heading = formType === 'kegFills'
    ? 'Report No Keg Fills for Entire Month'
    : 'Report No Shipments for Entire Month'
  const content = formType === 'kegFills'
    ? "If you haven't filled any kegs for any brewers for the entire month, let us know using this form."
    : "If you haven't shipped any kegs at all for the entire month, let us know using this form."
  return (
    <div style={{ marginTop: '25px', marginBottom: '10px' }}>
      <Accordion
      panels={[
        {
          key: 'noMovements',
          title: {
            content: (
              <Fragment>
                <span
                  style={{
                    // We can't actually use a <Header> component as the title
                    // because of their block layout; accordion titles must be
                    // inline elements so they'll be on the same line as the
                    // sideways triangle to their left. Therefore, we mimic the
                    // styling of an <h4> tag here.
                    fontSize: '1.07142857rem',
                  }}
                  className="ui header"
                >
                  {
                    translate(`reportShipments.${heading}`)
                  }
                </span>
                <InformationalPopup
                  content={
                    translate(`reportShipments.${content}`)
                  }
                  // By default, you need to click on all Popups in order to
                  // render them (see
                  // https://microstartap3.atlassian.net/browse/TP3-3504 for
                  // why), but in this case, we only want to render by hovering,
                  // because a click opens the accordion content.
                  popupProps={{ on: 'hover' }}
                />
                {/* The "Report No Movements" feature will never be "new" again,
                so why don't we just delete this code? Because the next time we
                need to implement the "New" label for a future feature, it's
                helpful to know what kind of styling we've used in the past for
                "New" labels */}
                {/* <Label
                  circular
                  color='teal'
                  size='tiny'
                  style={{
                    verticalAlign: 'middle',
                    // For some reason, vertical-align: middle has no effect on
                    // its own (even though vertical-align: top has an effect),
                    // so this margin gives us proper vertical alignment.
                    marginTop: '-4px',
                  }}
                >
                  New
                </Label> */}
              </Fragment>
            ),
          },
          content: {
            key: 'noMovements',
            content: children,
          },
        },
      ]}
      exclusive
      defaultActiveIndex={-1}
      fluid
      className="no-movements-accordion"
      />
    </div>
  )
}
