import Immutable from 'seamless-immutable'

import {
  SAVE_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA_OF_ONE_CUSTOMER,
} from '../actions/actionTypes'

import {
  REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA,
} from '../../constants'


const initialState = Immutable({ byCustomerId: {} })

export default function reportedInventoryVsCalculatedInventoryReducer(state=initialState, action) {
  switch (action.type) {
    case SAVE_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA_OF_ONE_CUSTOMER: {
      const { customerId, info } = action.payload
      return (
        Immutable.setIn(
          state,
          ['byCustomerId', customerId, REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA],
          info,
        )
      )
    }

    default:
      return state
  }
}
