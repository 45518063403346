import { useState, useEffect } from 'react'

const EVENT_KEY_DOWN = 'keydown'
const EVENT_KEY_PRESS = 'keypress'

export default function useIsCapsLockOn() {
  const [isCapsLockOn, setIsCapsLockOn] = useState(null)

  useEffect(
    () => {
      function onKeyDown(e) {
        if (e.keyCode === 20 && isCapsLockOn !== null) {
          setIsCapsLockOn(!isCapsLockOn)
        }
      }

      function onKeyPress(e) {
        const chr = getChar(e)
        if (!chr) {
          return // special key
        }

        if (chr.toLowerCase() === chr.toUpperCase()) {
          // caseless symbol, like whitespace
          // can't use it to detect Caps Lock
          return
        }

        setIsCapsLockOn(
          (
            (chr.toLowerCase() === chr && e.shiftKey)
            || (chr.toUpperCase() === chr && !e.shiftKey)
          ),
        )
      }

      document.addEventListener(EVENT_KEY_DOWN, onKeyDown)
      document.addEventListener(EVENT_KEY_PRESS, onKeyPress)
      return () => {
        document.removeEventListener(EVENT_KEY_DOWN, onKeyDown)
        document.removeEventListener(EVENT_KEY_PRESS, onKeyPress)
      }
    },
    // [],
  )

  return isCapsLockOn
}


function getChar(e) {
  if (e.which == null) {
    return String.fromCharCode(e.keyCode) // IE
  }
  if (e.which !== 0 && e.charCode !== 0) {
    return String.fromCharCode(e.which) // rest
  }

  return null
}
