import { connect } from 'react-redux'

import FormWithFailureMessage from './components/FormWithFailureMessage'

import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/publicForms'

import {
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL,
} from '../../../../../constants'


const {
  getFetchStatuses,
} = fetchStatusSelectors


const mapStateToProps = state => {
  const fetchStatuses = getFetchStatuses(state, REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL)

  return {
    ...fetchStatuses,
  }
}

export default connect(
  mapStateToProps,
)(FormWithFailureMessage)
