import {
  REDUCER_NAMES_HISTORY,
  REDUCER_NAMES_HISTORY_KEG_ORDERS as defaultSlice,
} from '../../../constants'


import {
  withPropSimple,
  withPropOfAllSimple,
  getEntireSlice as commonGetEntireSlice,
} from '../higherOrderFunctions'


import {
  getAreAnyItemsInACertainStatus as commonGetAreAnyItemsInACertainStatus,
} from './shared'


const defaultSlices = [REDUCER_NAMES_HISTORY, defaultSlice, 'byCustomerId']

// usage: getProp(state, customerId, orderId, 'dateOrdered')
export const getProp = withPropSimple(...defaultSlices)
// usage: getPropOfAfll(state, customerId, 'dateOrdered')
export const getPropOfAll = withPropOfAllSimple(...defaultSlices)

export const getEntireSlice = commonGetEntireSlice(...defaultSlices)

export const getAreAnyItemsInACertainStatus = commonGetAreAnyItemsInACertainStatus(getPropOfAll, 'status')


export const getAddressIdsFromKegOrderObjects = kegOrderObjects => (
  kegOrderObjects.map(o => o.shipAddressId)
)
