/* eslint-disable max-len */

import React, { Fragment } from 'react'
import { Form, Input, Button, Icon, Header } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import ResendConfirmationCode from './subFeatures/ResendConfirmationCode'
import NeverReceivedConfirmationCode from './subFeatures/NeverReceivedConfirmationCode'

import InputNoAutoComplete from '../../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import CapsLockIsOnWarning from '../../../../common-components/CapsLockIsOnWarning'

import {
  getInvalidPasswordErrorText,
  getIsStringAValidEmailAddress,
  getDoesStringContainDigitsOnly,
} from '../../../../utils'

import {
  FORGOT_PASSWORD_CONFIRMATION_CODE_MAX_LENGTH,
  LOGIN_EMAIL_MAX_LENGTH,
} from '../../../../config'

const emailAddressRequiredText = 'Email address required'
const confirmationCodeRequiredText = 'Confirmation code required'
const confirmationCodeNot6DigitNumberText = 'Confirmation code must be a 6-digit number'
const passwordRequiredText = 'Password required'

class ForgotPasswordResetPasswordForm extends React.Component {
  state = {
    emailInput: '', // for confirm account only
    confirmationCodeInput: '',
    enterPwInput: '',
    reEnterPwInput: '',
    // gets set to true only when the user first enters a value into the input
    // fields
    emailInputTouched: false,
    confirmationCodeInputTouched: false,
    enterPwInputTouched: false,
    reEnterPwInputTouched: false,

    emailInputErrorText: emailAddressRequiredText,
    confirmationCodeInputErrorText: confirmationCodeRequiredText,
    enterPwInputErrorText: passwordRequiredText,
    doPasswordsMatch: true, // "Enter" and "Re-Enter" will both be blank at first
  }

  // for confirm account only
  onEmailInputChange = e => {
    let emailInput = e.target.value
    let emailInputErrorText

    // remove all whitespace
    emailInput = emailInput && emailInput.replace(/ /g, '')

    if (!getIsStringAValidEmailAddress(emailInput)) {
      emailInputErrorText = 'Invalid email address'
    }

    if (['', undefined].includes(emailInput)) {
      emailInputErrorText = emailAddressRequiredText
    }

    // max length rule
    if (emailInput.length > LOGIN_EMAIL_MAX_LENGTH) {
      emailInput = this.state.emailInput
    }

    this.setState({
      emailInput,
      emailInputErrorText,
    })
  }

  onConfirmationCodeInputChange = e => {
    let confirmationCodeInput = e.target.value
    let confirmationCodeInputErrorText

    if (['', undefined].includes(confirmationCodeInput)) {
      confirmationCodeInputErrorText = confirmationCodeRequiredText
    }

    if (
      !getDoesStringContainDigitsOnly(confirmationCodeInput) ||
      confirmationCodeInput.length !== 6
    ) {
      confirmationCodeInputErrorText = confirmationCodeNot6DigitNumberText
    }

    // max length rule. Notice that max length is the only limiting rule for
    // this input. Confirmation codes sent by AWS are always 6 integers, but we
    // don't want to convey that to the end-user because that would guide
    // malicious users to what a valid confirmation code looks like.
    if (confirmationCodeInput.length > FORGOT_PASSWORD_CONFIRMATION_CODE_MAX_LENGTH) {
      confirmationCodeInput = this.state.confirmationCodeInput
    }

    this.setState({
      confirmationCodeInput,
      confirmationCodeInputErrorText,
    })
  }


  onPasswordInputChange = e => {
    const enterPwInput = e.target.value

    let enterPwInputErrorText = getInvalidPasswordErrorText(enterPwInput) // null if valid

    if (['', undefined].includes(enterPwInput)) {
      enterPwInputErrorText = passwordRequiredText
    }

    this.setState({
      enterPwInput,
      enterPwInputTouched: true,
      enterPwInputErrorText,
      doPasswordsMatch: enterPwInput === this.state.reEnterPwInput,
    })
  }


  onReEnterPasswordInputChange = e => {
    const reEnterPwInput = e.target.value

    this.setState({
      reEnterPwInput,
      reEnterPwInputTouched: true,
      doPasswordsMatch: reEnterPwInput === this.state.enterPwInput,
    })
  }


    onEmailInputBlur = () => {
      this.setState({ emailInputTouched: true })
    }

    onConfirmationCodeInputBlur = () => {
      this.setState({ confirmationCodeInputTouched: true })
    }

    onEnterPasswordInputsBlur = () => {
      this.setState({ enterPwInputTouched: true })
    }

    onReEnterPasswordInputBlur = () => {
      this.setState({ reEnterPwInputTouched: true })
    }


  onSubmit = e => {
    const {
      handleSubmit,
      isConfirmAccountForm,
      setEmail: parentSetEmail,
      setPassword: parentSetPassword,
    } = this.props
    e.preventDefault()
    const {
      emailInput: email,
      confirmationCodeInput: confirmationCode,
      enterPwInput: password,
    } = this.state
    if (isConfirmAccountForm) {
      parentSetEmail(email) // CODE_COMMENTS_251
    }
    parentSetPassword(password) // CODE_COMMENTS_251
    handleSubmit({ email, confirmationCode, password })
  }


  render() {
    const {
      emailInput,
      confirmationCodeInput,
      enterPwInput,
      reEnterPwInput,

      emailInputErrorText,
      confirmationCodeInputErrorText,
      enterPwInputErrorText,
      doPasswordsMatch,

      emailInputTouched,
      confirmationCodeInputTouched,
      enterPwInputTouched,
      reEnterPwInputTouched,
    } = this.state

    // Was the confirmation code sent via text or email?
    const {
      email, // the email address the user entered in step 1 of the Forgot Password form.
      isConfirmAccountForm,
      isFetching,
      t: translate,
    } = this.props


    const doFormInputsHaveErrors = isConfirmAccountForm
      ? (
        Boolean(emailInputErrorText) ||
        Boolean(confirmationCodeInputErrorText) ||
        Boolean(enterPwInputErrorText) ||
        !doPasswordsMatch
      )
      : (
        Boolean(confirmationCodeInputErrorText) ||
        Boolean(enterPwInputErrorText) ||
        !doPasswordsMatch
      )

    return (
      <div>
        {!isConfirmAccountForm &&
          <div>
            <Header as="h4" color="grey">{translate('Step 2 Reset Your Password')}</Header>
            <p
              style={{ fontWeight: 'bold' }}
            >
              {`${translate('Reset code sent to')} ${email}`}
            </p>
            <p
              // for whatever reason, the popup below invalidates the default
              // bottom padding of <p> tags. We reinstate it here.
              style={{ paddingBottom: '1rem' }}
            >
              {translate('Enter the confirmation code below along with your new desired password.')}
              {translate('Enter the confirmation code below along with your new desired password.')}
              <br />
              <NeverReceivedConfirmationCode />
            </p>
          </div>
        }
        {isConfirmAccountForm &&
        <Fragment>
          <span>{translate('You received an email from us containing a confirmation code. Enter it here along with your email address and desired password to complete the account confirmation process.')}</span>
          <ResendConfirmationCode
            email={isConfirmAccountForm
              ? emailInput
              : email
            }
            isConfirmAccountForm={isConfirmAccountForm}
          />
        </Fragment>
        }
        <Form onSubmit={this.onSubmit}>
          {isConfirmAccountForm &&
            <div className="field-with-error-message">
              <Form.Input
                label={translate('Email')}
                type="text"
                name="email"
                value={emailInput}
                onChange={this.onEmailInputChange}
                onBlur={this.onEmailInputBlur}
              />
              {(emailInputTouched && emailInputErrorText) ? <small>{translate(emailInputErrorText)}</small> : null}
            </div>
          }
          <div className="field-with-error-message">
            <Form.Field>
              <label htmlFor="confirmationCode">{translate('Confirmation Code')}</label>
              <InputNoAutoComplete
                name="confirmationCode"
                value={confirmationCodeInput}
                onChange={this.onConfirmationCodeInputChange}
                onBlur={this.onConfirmationCodeInputBlur}
              />
            </Form.Field>
            {(confirmationCodeInputTouched && confirmationCodeInputErrorText) ? <small>{translate(confirmationCodeInputErrorText)}</small> : null}
          </div>
          <div className="field-with-error-message">
            <Form.Field>
              <label htmlFor="enterPassword">{translate('New Password')}</label>
              <CapsLockIsOnWarning
                translate={translate}
                trigger={
                  <Input
                    name="enterPassword"
                    type="password"
                    value={enterPwInput}
                    onChange={this.onPasswordInputChange}
                    onBlur={this.onEnterPasswordInputsBlur}
                  />
                }
              />
            </Form.Field>
            {(enterPwInputTouched && enterPwInputErrorText) ? <small>{translate(enterPwInputErrorText)}</small> : null}
            <Form.Field style={{ paddingTop: '0.5em' }}>
              <label htmlFor="reEnterPassword">{translate('Re-enter Password')}</label>
              <CapsLockIsOnWarning
                translate={translate}
                trigger={
                  <Input
                    name="reEnterPassword"
                    type="password"
                    value={reEnterPwInput}
                    onChange={this.onReEnterPasswordInputChange}
                    onBlur={this.onReEnterPasswordInputBlur}
                  />
                }
              />
            </Form.Field>
            {(reEnterPwInputTouched && !doPasswordsMatch) ? <small>{translate('Passwords do not match')}</small> : null}
          </div>
          {isFetching
            ? <Button type="submit" fluid animated loading disabled>{translate('Loading')}</Button>
            : (
              <Button
                type="submit"
                fluid
                animated
                color={doFormInputsHaveErrors ? null : 'green'}
                disabled={doFormInputsHaveErrors}
              >
                <Button.Content visible>{translate('Submit')}</Button.Content>
                <Button.Content hidden>
                  <Icon name="right arrow" />
                </Button.Content>
              </Button>
            )
          }
        </Form>
      </div>
    )
  }
}

export default withTranslation()(ForgotPasswordResetPasswordForm)
