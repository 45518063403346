import {
  getHasPermissionsToPerformFunctionality,
} from '../permissions'

import {
  getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToReportInventory,
} from '../contractBrewersAndContracteesSpecialSelectors'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_BREWER,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_CONTRACT_BREWER,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR,
} from '../../../../constants/permissions'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR, CUSTOMER_TYPES_MAINTENANCE_FACILITY,
  CUSTOMER_TYPES_WAREHOUSE,
} from '../../../../constants'


export function getHasReportInventoryPermissions({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  customerId,
}) {
  const customerType = entireCustomersSlice[customerId].customerType
  const functionalityStringOrPermissionsMap = {
    [CUSTOMER_TYPES_BREWER]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_BREWER,
    [CUSTOMER_TYPES_CONTRACT_BREWER]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_CONTRACT_BREWER,
    [CUSTOMER_TYPES_DISTRIBUTOR]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR,
    [CUSTOMER_TYPES_WAREHOUSE]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR, // CODE_COMMENTS 287
    [CUSTOMER_TYPES_MAINTENANCE_FACILITY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORT_INVENTORY_DISTRIBUTOR,
  }[customerType]

  let hasReportInventoryPermissions = getHasPermissionsToPerformFunctionality({
    entirePermissionsSlice,
    entireCurrentUserSlice,
    functionalityStringOrPermissionsMap,
  })

  if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    hasReportInventoryPermissions =
      getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToReportInventory({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        entirePermissionsSlice,
        entireCurrentUserSlice,
        entireUsersSlice,
        conbrwCustomerId: customerId,
      })
  }

  return hasReportInventoryPermissions
}
