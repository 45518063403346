import React from 'react'
import { Container, Segment, Header, Message, List, Button } from 'semantic-ui-react'

import ContactCard from '../../../../../../common-components/ContactCard'

import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../../../../constants'

export default ({
  errorMessages,
  customerId,
  fetchPrelimInfoOfAllCustomers,
}) => (
  <Container>
    <Segment>
      <Header as="h2" color="grey">Problems with Customer Data on File</Header>
      <Message compact negative style={{ maxWidth: '850px' }}>
        <p>{'There are some problems with the customer information we have on file for you which are preventing this app from loading:'}</p>
        <List bulleted>
          {errorMessages.map(errorMessage => (
            <List.Item
              key={errorMessage}
              style={{ whiteSpace: 'pre-wrap' }} // retains line breaks
            >
              {errorMessage}
            </List.Item>
          ))}
        </List>
        {'Contact your personalized representative to resolve these issues. Once your representative confirms that the issues have been resolved, click the Reload App button below.'}
        <ContactCard repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE} customerId={customerId} />
        <Button
          color="blue"
          onClick={fetchPrelimInfoOfAllCustomers}
        >
          Reload App
        </Button>
      </Message>
    </Segment>
  </Container>
)
