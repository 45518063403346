/**
 * CODE_COMMENTS_84
 */
import {
  SAVE_CUSTOMER_TO_COLLAR_PLATES_NORMALIZED_LINKS,
} from '../../actions/actionTypes'

import normalizedLinkReducer from './higherOrderEntityReducers/normalizedLinkReducerCreator'

export default normalizedLinkReducer({
  saveActionType: SAVE_CUSTOMER_TO_COLLAR_PLATES_NORMALIZED_LINKS,
  leftSideObjIdKey: 'customerId',
  rightSideObjIdKey: 'collarPlateId',
})
