import get_ from 'lodash/get'
import isString_ from 'lodash/isString'


// Used by both the ReportNoMovements component and the reportNoMovements saga
export function getDoesApiErrorSayNoMovementsCantBeReportedBecauseShipmentsHaveAlreadyBeenReportedForThisPeriod({
  error,
}) {
  const errorCode = get_(error, ['response', 'status'])
  const errorMessage = get_(error, ['response', 'data', 'validationErrors', 'errors', 'existingShipment', 0])
  return (
    error
    && errorCode === 400
    && isString_(errorMessage)
    && errorMessage.toLowerCase().includes('shipments already exist')
  )
}


// Used by both the ReportNoMovements component and the reportNoMovements saga
export function getDoesApiErrorSayNoMovementsCantBeReportedBecauseItIsADuplicate({
  error,
}) {
  const errorCode = get_(error, ['response', 'status'])
  const errorMessage = get_(error, ['response', 'data', 'messageDetail'])
  return (
    error
    && errorCode === 500
    && isString_(errorMessage)
    && errorMessage.toLowerCase().includes('duplicate entry')
  )
}
