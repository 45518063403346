/**
 * CODE_COMMENTS_31
 */

// These fields are not fillable; they're fields that only display content,
// letting the user know which shipment a field array row corresponds to.
export const FIELD_NAME_ORDER_ID = 'order-id'
export const FIELD_NAME_SHIPMENT_ID = 'shipment-id'
export const FIELD_NAME_SHIPMENT_TYPE = 'shipmentType'
export const FIELD_NAME_CARRIER = 'carrier'
export const FIELD_NAME_DATE_SHIPPED = 'date-shipped'
export const FIELD_NAME_SOURCE = 'source'
export const FIELD_NAME_TOTAL_NUMBER_KEGS = 'total-number-kegs'
export const FIELD_NAME_TOTAL_NUMBER_PALLETS = 'total-number-pallets'
export const FIELD_TOUCHED = 'touched'
export const FIELD_ROW_INDEX = 'rowIndex'

// fillable fields
export const FIELD_NAME_DATE_RECEIVED = 'date-received'
export const FIELD_NAME_GOOD_PALLETS = 'good-pallets'
export const FIELD_NAME_BAD_PALLETS = 'bad-pallets'
export const FIELD_NAME_UNKNOWN_PALLETS = 'unknown-pallets'
export const FIELD_NAME_FOREIGN_KEGS = 'foreign-kegs'

// ID is not a fillable field nor is it a displayed field. It's an undisplayed
// field with a pre-filled initial value whose purpose is to convey to the
// submission saga which row corresponds to which database-identifiable ID of
// the shipment (the ID must be included in the body of the API call).
export const FIELD_NAME_ID = 'id'


// We save these labels to constants because they're used by both the Ack
// Inbound Shipment form and by the popup modal prompting the user to
// acknowledge their delivered shipments.
export const FIELD_LABEL_ORDER_ID = 'Order ID'
export const FIELD_LABEL_SHIPMENT_ID = 'Shipment ID'
export const FIELD_LABEL_SHIPMENT_TYPE = 'Shipment Type'
export const FIELD_LABEL_DATE_SHIPPED = 'Date Shipped'
export const FIELD_LABEL_CARRIER = 'Carrier'
export const FIELD_LABEL_SOURCE = 'Origin'
export const FIELD_LABEL_DATE_RECEIVED = 'Date Received'
export const FIELD_LABEL_GOOD_PALLETS = 'Good Pallets'
export const FIELD_LABEL_BAD_PALLETS = 'Bad Pallets'

// CODE_COMMENTS_277. We can't use the phrase "Good Wood Pallets" because that's
// too long, it makes the label take up 3 lines (it's a very narrow form
// column), overflowing into the input field below.
export const FIELD_LABEL_GOOD_WOOD_PALLETS = 'OK Wood Pallets'
// CODE_COMMENTS_277
export const FIELD_LABEL_BAD_WOOD_PALLETS = 'Bad Wood Pallets'

export const FIELD_LABEL_UNKNOWN_PLASTIC_PALLETS = 'Unknown Plastic Pallets'

export const FIELD_LABEL_FOREIGN_KEGS = 'Foreign Kegs'
export const FIELD_LABEL_TOTAL_NUMBER_KEGS = 'Total # Kegs'
export const FIELD_LABEL_TOTAL_NUMBER_PALLETS = 'Total # Pallets'


export const FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS = 'ackInboundShipments'
export const SHIPMENT_TYPE_LOCAL = 'LOCAL'
export const SHIPMENT_TYPE_FREIGHT = 'FREIGHT'
export const FIELD_NAME_SHIPMENT_TYPE_LABELS = {
  [SHIPMENT_TYPE_LOCAL]: 'Local Pickup',
  [SHIPMENT_TYPE_FREIGHT]: 'Delivered',
}
