import React from 'react'

import EditButton from './subFeatures/EditButton'
import DeleteButton from './subFeatures/DeleteButton'

export default ({
  dontIncludeDeleteButton,
  ...rest
}) => ([
  <EditButton key="editButton" {...rest} />,
  ...(dontIncludeDeleteButton ? [] : [<DeleteButton key="deleteButton" {...rest} />]),
])
