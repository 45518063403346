import React, { Fragment, useState, useEffect, useMemo } from 'react'
import {
  Table,
  Icon, Message, Loader,
} from 'semantic-ui-react'
import { compose } from 'recompose'

import sortBy_ from 'lodash/sortBy'
import get_ from 'lodash/get'


import moment from 'moment'
import { useDispatch } from 'react-redux'
import AddOrEditIndividualContact from '../AddOrEditIndividualContact/components/NoLoginAddOrEditContacts'

import WaitForApiFetch from '../../../common-components/WaitForApiFetch'
import UiContainer from '../../../common-components/UiContainer'
import InformationalPopup from '../../../common-components/InformationalPopup'
import StyledPopup from '../../../common-components/StyledPopup'
import ContactCard from '../../../common-components/ContactCard'
import withEither from '../../../common-components/HOC/general/withEither'

import {
  DISPLAYED_INFORMATIONAL_MESSAGE_CONTACT_TYPE,
} from '../constants'


import {
  getApplicableEmailSubscriptionPropsBasedOnCustomerType,
  getHumanReadableContactTypeFromContactMashupObj,
} from '../utils'

import {
  CONTACT_EMAIL_DELIVERY_STATUS_UNDELIVERABLE,
  CUSTOMER_REPS_GENERIC,
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
  getQueryParametersFromUrl,
} from '../../../utils'

import {
  TEMPORARILY_DISABLE_CONTACTS_FEATURE,
} from '../../../config'

import './styles.css'
import createAction from '../../../redux/actions/createAction'
import {
  FETCH_INFO_NEEDED_TO_CONSTRUCT_UNSUBSCRIPTION_NO_LOGIN_REQUIRED_FORM,
} from '../../../redux/actions/actionTypes'
import { getAllContactObjectsOfContact } from '../../../redux/selectors/contacts'

const UlContent = ({ content }) => (
  <ul style={{ margin: 0, padding: 0, marginBlock: 0, listStyle: 'none' }}>
    <li style={{ height: '38px', padding: '8px', borderTop: '1px solid #e8e8e9' }}>{content}</li>
  </ul>
)

function ErrorMessageUnsubscriptionNoLogin({
  type,
  // ...allDistInvNoLoginStateVars
}) {
  // const {
  //   dispatch,
  // } = allDistInvNoLoginStateVars
  let header
  let content
  if (type === 'expired') {
    header = 'Link Expired'
    content = "This email link has expired (links are only good for a couple weeks). Check for a more recent email from MicroStar Kegs. If you feel you've received this message in error, contact us."
  } else {
    header = 'Unknown Error'
    content = DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR
  }
  return (
    <Fragment>
      <Message
        error
        header={header}
        content={
          <Fragment>
            <p>{content}</p>
            {/* <Button */}
            {/*   primary */}
            {/*   onClick={() => { */}
            {/*     dispatch(createAction( */}
            {/*       FETCH_INFO_NEEDED_TO_CONSTRUCT_DIST_REPORT_INVENTORY_NO_LOGIN_REQUIRED_FORM, */}
            {/*       { */}
            {/*         ...allDistInvNoLoginStateVars, */}
            {/*       }, */}
            {/*     )) */}
            {/*   }} */}
            {/* > */}
            {/*   Retry */}
            {/* </Button> */}
          </Fragment>
        }
        style={{ maxWidth: '450px' }}
      />
      <ContactCard repType={CUSTOMER_REPS_GENERIC} />
    </Fragment>
  )
}

const ManageContactsAndSubscriptions = ({
  location,
  contactId,
  entireContactsSlice,
  entireCustomerContactLinksSlice,
  entireCustomersSlice,
}) => {
  const dispatch = useDispatch()
  const [column, setColumn] = useState('lastName')
  const [data, setData] = useState([])
  const [direction, setDirection] = useState('ascending')
  const [token, setToken] = useState(null)
  const [tokenExpiration, setTokenExpiration] = useState(null)
  const [isTokenExpired, setIsTokenExpired] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const handleSort = (clickedColumn, optDirection) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn)
      setData(sortBy_(data, [clickedColumn]))
      setDirection(optDirection || 'ascending')
      return
    }
    setData(data.reverse())
    setDirection(optDirection || (direction === 'ascending' ? 'descending' : 'ascending'))
  }

  useEffect(
    () => {
      const {
        mstartoken,
        expiration,
      } = getQueryParametersFromUrl(location)
      setToken(mstartoken)
      setTokenExpiration(expiration)
      const isTokenExpired_ = moment(expiration)?.isBefore(moment.now())
      if (isTokenExpired_ || !contactId || !mstartoken) {
        setIsTokenExpired(true)
      } else {
        dispatch(createAction(
          FETCH_INFO_NEEDED_TO_CONSTRUCT_UNSUBSCRIPTION_NO_LOGIN_REQUIRED_FORM,
          {
            contactId,
            token: mstartoken,
            tokenExpiration,
            setIsFetching,
            setIsTokenExpired,
            setErrorMessage,
          },
        ))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // empty array so it only renders on mount
  )

  const emailSubscriptionProps = useMemo(
    () => getApplicableEmailSubscriptionPropsBasedOnCustomerType({
      contactId,
      customerType: 'CONTACT',
      entireContactsSlice,
      entireCustomersSlice,
      entireCustomerContactLinksSlice,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      contactId,
      // Why aren't we including customerType, entireCustomersSlice and
      // entireParentChildLinksSlice? i.e. why are we disabling
      // react-hooks/exhaustive-deps? Because it messes with the edit form.
      // Details: emailSubscriptionProps gets passed in to the
      // AddOrEditIndividualContact component. Whenever it changes, the
      // resetFormValuesToInitialValues() function gets called. This is a
      // problem: a user can be filling out the Edit Contact form and the form
      // values will automatically change back to initial values right before
      // their eyes. This is especially a problem for CONBRW users, because
      // entireCustomersSlice will be changing for 30 seconds, up to over a
      // minute, after logging in depending on the number of contractees the
      // CONBRW has. Note that all the information needed to calculate
      // emailSubscriptionProps exists in the Redux store on this component's
      // first render, so omiting customerType, etc from the deps here isn't an
      // issue.
    ],
  )
  useEffect(() => {
    const contact = entireContactsSlice?.[contactId]
    if (contact) {
      setData([contact])
      setDirection('ascending')
    }
  }, [entireContactsSlice, contactId])

  if (isTokenExpired) {
    return <ErrorMessageUnsubscriptionNoLogin type="expired" />
  } else if (errorMessage) {
    return <ErrorMessageUnsubscriptionNoLogin />
  }
  return (
    <div style={{ overflowX: 'auto' }}>
      <Loader
        active={isFetching}
        disabled={!isFetching}
        size="small"
        style={{ margin: '0.5rem 0 0 0.5rem' }}
      />
      {isTruthyAndNonEmpty(data) &&
        <Table celled sortable compact structured className="contacts-table" style={{ minWidth: '100%', width: 'max-content' }}>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell
                style={{ width: '80px' }}
                rowSpan='2'
              /> {/* Edit/Delete buttons */}
              <Table.HeaderCell
                sorted={column === 'lastName' ? direction : null}
                onClick={handleSort('lastName')}
                rowSpan='2'
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'emailAddress' ? direction : null}
                onClick={handleSort('emailAddress')}
                rowSpan='2'
              >
                Email
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'contactType' ? direction : null}
                onClick={handleSort('contactType')}
                rowSpan='2'
              >
                Type
                <InformationalPopup
                  content={DISPLAYED_INFORMATIONAL_MESSAGE_CONTACT_TYPE}
                />
              </Table.HeaderCell>
              <Table.HeaderCell rowSpan='2'>Location</Table.HeaderCell>
              <Table.HeaderCell
                colSpan='3'
              >
                Email Subscriptions
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row textAlign='center'>
              {emailSubscriptionProps.map(prop => (
                <Table.HeaderCell key={prop.name}>
                  {prop.displayedName}
                  <InformationalPopup
                    content={prop.explanation}
                  />
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.map(o => {
              const locations = []
              const type = []
              const customersWithSameContact = Object.values(entireCustomerContactLinksSlice)?.filter(
                obj => obj.contactId === o?.id,
              )
              // Vertically aligned content
              customersWithSameContact?.forEach(customer => {
                locations.push(<UlContent content={customer?.customerName} />)
                type.push(<UlContent content={getHumanReadableContactTypeFromContactMashupObj(customer)} />)
              })
              const subscriptions = emailSubscriptionProps?.map(prop => {
                const arr = []
                customersWithSameContact?.forEach(customer => {
                  const customerProps = getAllContactObjectsOfContact({
                    contactId,
                    entireContactsSlice,
                    entireCustomerContactLinksSlice,
                  })?.find(obj => obj.customerId === customer.customerId)
                  arr.push(<UlContent content={get_(customerProps, prop.name) &&
                  <Icon color='green' name='checkmark' size='large'/>}
                  />)
                })
                return <Table.Cell style={{ padding: 0 }}>{arr}</Table.Cell>
              })
              return (
                <Table.Row key={o.id} textAlign='center' verticalAlign='top'>
                  <Table.Cell>
                    <AddOrEditIndividualContact
                      entireContactsSlice={entireContactsSlice}
                      entireCustomerContactLinksSlice={entireCustomerContactLinksSlice}
                      token={token}
                      isEditForm
                      contact={o}
                      contacts={data}
                      // customerType={customerType}
                      customersWithSameContact={customersWithSameContact}
                      // entireCustomersSlice={entireCustomersSlice}
                      emailSubscriptionProps={emailSubscriptionProps}
                      modalTrigger={({ openModal }) => (
                        <StyledPopup
                          trigger={<Icon link name='edit' color='blue' />}
                          content="Edit"
                          onClick={openModal}
                        />
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell>{`${o.firstName} ${o.lastName}`}</Table.Cell>
                  <Table.Cell>
                    <span
                    style={
                      o.emailDeliveryStatus === CONTACT_EMAIL_DELIVERY_STATUS_UNDELIVERABLE
                        ? {
                          color: 'red',
                        }
                        : null
                    }
                    >
                      {o.emailAddress}
                    </span>
                    {o.emailDeliveryStatus === CONTACT_EMAIL_DELIVERY_STATUS_UNDELIVERABLE &&
                      <InformationalPopup
                        iconProps={{ color: 'red' }}
                        popupProps={{ className: 'semantic-ui-error-border', wide: true }}
                        content={
                          <Fragment>
                            {/* eslint-disable-next-line max-len */}
                            <span>Our system is unable to deliver to this email address, please update it. Contact your CE rep if you believe this email address is correct.</span>
                            {/* <RevealContactInfo */}
                            {/*   customerId={customerId} */}
                            {/*   repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE} */}
                            {/*   blockDisplay */}
                            {/* /> */}
                          </Fragment>
                        }
                      />
                    }
                  </Table.Cell>
                  <Table.Cell style={{ padding: 0 }}>
                    {type}
                  </Table.Cell>
                  <Table.Cell style={{ padding: 0 }}>
                    {locations}
                  </Table.Cell>
                  {subscriptions}
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      }
      {!isTruthyAndNonEmpty(data) &&
        <p>{"You don't yet have any contacts."}</p>
      }
      <InformationalPopup
        includeTriggerIcon={false}
        includeTriggerText
        triggerText="Add New Contact"
        open
        content={(
          <Fragment>
            <p>
              {"Email us with your new Contact's information and we'll get it added right away:"}
            </p>
          </Fragment>
        )}
      />
    </div>
  )
}

// const MasterAccountContacts = props => {
//   const [activeCustomer, setActiveCustomer] = useState({})
//   const { customerId, entireParentChildLinksSlice = [], entireCustomersSlice } = props || {}
//   const childCustomers = Object.values(entireParentChildLinksSlice)?.filter(
//     slice => slice.parentCustomerId === customerId,
//   )
//   const handleClick = (e, titleProps) => {
//     const { accCustomerId } = titleProps
//     setActiveCustomer({ ...activeCustomer, [accCustomerId]: !activeCustomer?.[accCustomerId] })
//   }
//   return (
//     <Fragment>
//       {childCustomers && childCustomers?.map(({ childCustomerId }) => (
//         <Accordion>
//           <Accordion.Title
//             active={activeCustomer[childCustomerId]}
//             accCustomerId={childCustomerId}
//             onClick={handleClick}
//           >
//             <label style={{ display: 'inline-block', width: 'fit-content' }}>
//               <Header as='h2' size='small' style={{ display: 'inline-block' }}>
//                {entireCustomersSlice?.[childCustomerId]?.name}
//               </Header>
//               <Icon name='dropdown' />
//             </label>
//           </Accordion.Title>
//           <Accordion.Content active={activeCustomer[childCustomerId]}>
//             <ManageContactsAndSubscriptions {...props} customerId={childCustomerId} />
//           </Accordion.Content>
//         </Accordion>
//       ))}
//     </Fragment>
//   )
// }

// Manage contacts & subscription screen. Route- /contacts
export default compose(
  UiContainer({
    headerText: 'Manage Contacts and Email Subscriptions',
    containerId: 'manageSubscriptionsSection',
  }),
  withEither(
    () => TEMPORARILY_DISABLE_CONTACTS_FEATURE,
    () => <p>This feature is temporarily disabled</p>,
  ),
  WaitForApiFetch,
)(
  ManageContactsAndSubscriptions,
)

// Manage contacts for master account. Route- /manage-all-contacts
export const ManageAllContacts = compose(
  UiContainer({
    headerText: 'Manage Subscriptions',
    containerId: 'manageSubscriptionsSection',
  }),
  withEither(
    () => TEMPORARILY_DISABLE_CONTACTS_FEATURE,
    () => <p>This feature is temporarily disabled</p>,
  ),
)(ManageContactsAndSubscriptions)


// function getNewContactBodyText(data) {
//   const template = `Hi ${data.ceRepFirstName} ${data.ceRepLastName},
//
// Please add a new Contact for me.
//
// Customer: ${data.customerName} (${data.tapCustomerId})
// First Name *:
// Last Name *:
// Email *:
// Type (Billing, Shipping, Both) *:
// Default Shipping Contact (Y/N):
// Desk Phone:
// Cell Phone:
// Fax Number:
// Subscribe to Order Updates (Y/N):
// Subscribe to Invoices:
// Subscribe to Shipments:
//
// Thanks!
//
// ${data.userFirstName || ''} ${data.userLastName || ''}`
//
//   return template
// }
