// Returns one of two things:
//
// 1) the label text passed in just as it is
// 2) the label text followed by an informational popup detailing the
//    differences between container types.
//
// #2 will be returned if the label text is or contains a container type
// string--"Half Barrel", "HB", "Sixth Barrel", "SB", etc.
//
// This component is used by both the history tables and by the "Full Details"
// modals within the history tables.

import React from 'react'

import { useTranslation } from 'react-i18next'
import ContainerTypesDifferences from '../../../ContainerTypesDifferences'

import {
  CONTAINER_TYPES,
  CONTAINER_TYPES_CPP,
  ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
} from '../../../../constants'


export default ({
  customerId,
  labelText,
  colonAfterText,
}) => {
  const { t: translate } = useTranslation('common')
  const containerType = matchLabelTextToContainerType(labelText)
  const itemSkuId = containerType === CONTAINER_TYPES_CPP
    ? ITEM_SKU_IDS_CBI_PLASTIC_PALLET
    // The 'itemSkuId' prop is ignored in the <ContainerTypesDifferences>
    // component unless it equals ITEM_SKU_IDS_CBI_PLASTIC_PALLET; that's how we
    // signal to the component to render CPP info rather than kegs info.
    : null
  if (containerType) {
    return (
      <span>
        {translate(labelText)}
        {colonAfterText && ':'}
        <ContainerTypesDifferences
          customerId={customerId}
          itemSkuId={itemSkuId}
        />
      </span>
    )
  }

  return translate(labelText)
}


// Pass in "half barrel" and this returns 'HB'. Returns null if no match.
function matchLabelTextToContainerType(labelText) {
  const allPossibleContainerTypeTextThatCanBeInHeading = CONTAINER_TYPES.reduce(
    (acc, o) => ({
      ...acc,
      [o.id.toLowerCase()]: o.id,
      [o.name.toLowerCase()]: o.id,
    }),
    {},
  )
  return allPossibleContainerTypeTextThatCanBeInHeading[labelText.toLowerCase()]
}
