import {
  createDisplayedShipmentNumber,
  createDisplayedProNumber,
  createDisplayedShipmentType,
  createDisplayedShipmentDate,
  createDisplayedDestinationName,
  createDisplayedDestinationState,
  createItemSkuIdFieldsDefinitions,
  createDisplayedMovementType,
} from '../common/displayedShipmentDataEtc'

import {
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION,
} from '../../../../../../constants/formAndApiUrlConfig/histories/OutboundFullKegShipmentHistory'

import {
  OUTBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
} from '../../../../../../constants/formAndApiUrlConfig/histories/OutboundEmptyKegShipmentHistory'

import {
  getDisplayedHumanReadableStatusOfInboundEmptyKegShipment,
} from '../inboundEmptyKegShipmentHistory'
import { CUSTOMER_TYPES_WAREHOUSE } from '../../../../../../constants'


// Used by both the Full Details modal and the "Download File As CSV" feature
export function getInfoDefinitionsOutboundEmptyKegShipmentFullDetails({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  entireCustomersSlice,
  customerId,
}) {
  return [
    {
      heading: 'Shipment #',
      cellContent: createDisplayedShipmentNumber,
    },
    (entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ? {
      heading: 'Shipment Type',
      cellContent: createDisplayedShipmentType,
    }: {
      heading: 'Movement Type',
      cellContent: createDisplayedMovementType,
    }),
    {
      heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION,
      cellContent: createDisplayedDestinationName,
    },
    {
      heading: 'Destination State',
      cellContent: createDisplayedDestinationState,
    },
    {
      heading: 'Ship Date',
      cellContent: createDisplayedShipmentDate,
    },
    {
      heading: 'Status',
      cellContent: row => getDisplayedHumanReadableStatusOfInboundEmptyKegShipment(row),
    },
    {
      heading: OUTBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
      cellContent: createDisplayedProNumber('none'),
    },

    ...createItemSkuIdFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
      useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged: true,
    }),
  ]
}
