import React from 'react'
import { Tab } from 'semantic-ui-react'

import UiContainer from '../../../common-components/UiContainer'


export default UiContainer({
  headerText: 'History',
  containerId: 'historySection',
})(({ tabPanes }) => (
  <Tab
    renderActiveOnly={false} // CODE_COMMENTS_42
    panes={tabPanes}
  />
))
