/**
 * Renders a button which reveals additional content when clicked and hides the
 * content when clicked again. Content is revealed in one of two ways: as a
 * semantic-ui-react popup or revealed directly below the button, pushing other
 * content below it further down (choose which way by including the asPopup
 * prop).
 */
import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import PopupWithCatastrophicFailureErrorBoundary from '../semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'


class RevealContent extends Component {
  state = { visible: false }

  handleToggle = () => {
    this.setState({ visible: !this.state.visible })
  }

  render() {
    const {
      buttonTextWhenVisible,
      buttonTextWhenHidden,
      buttonProps,
      blockDisplay,
      asPopup,
      popupProps,
      fullSizeButton,
      buttonColor,
      t: translate,
    } = this.props
    if (asPopup) {
      return (
        <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
          trigger={
            <Button
              type="button" // CODE_COMMENTS_260
              compact={!fullSizeButton}
              size={fullSizeButton ? undefined : 'mini'}
              color={buttonColor && buttonColor}
              content={this.state.visible ? translate(buttonTextWhenVisible) : translate(buttonTextWhenHidden)}
              style={{ marginTop: '1em', marginBottom: '1em', display: blockDisplay ? 'block' : null }}
              {...buttonProps}
            />
          }
          content={this.props.children}
          on="click"
          position="top center"
          {...popupProps}
        />
      )
    }
    return (
      <div style={blockDisplay ? null : { display: 'inline' }}>
        <Button
          type="button" // CODE_COMMENTS_260
          compact={!fullSizeButton}
          size={!fullSizeButton && 'mini'}
          color={buttonColor && buttonColor}
          content={this.state.visible ? buttonTextWhenVisible : buttonTextWhenHidden}
          onClick={this.handleToggle}
          style={{ marginTop: '1em', marginBottom: '1em' }}
          {...buttonProps}
        />
        {this.state.visible ? this.props.children : null}
      </div>
    )
  }
}

export default withTranslation()(RevealContent)
