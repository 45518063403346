import React from 'react'
import { Card, Image, Label } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import GridOfContainerTypeInfo from './GridOfContainerTypeInfo'

export default ({ containerTypeInfo }) => {
  const { t: translate } = useTranslation('common')
  return (
    <Card>
      {containerTypeInfo.image && <Image src={containerTypeInfo.image} />}
      <Card.Content>
        <Card.Header>{containerTypeInfo.name}</Card.Header>
        <Card.Meta>
          {`${containerTypeInfo.volumeInGallons} Gal | ${containerTypeInfo.volumeInLiters} Liters`}
        </Card.Meta>

        <Card.Description>
          <GridOfContainerTypeInfo
            info={[
              { label: translate('Height'), measurement: `${containerTypeInfo.heightInInches}"` },
              { label: translate('Weight (full)'), measurement: `${containerTypeInfo.weightInLbsFull} lbs` },
              { label: translate('Diameter'), measurement: `${createDisplayedDiameterText(containerTypeInfo.diameterInInches)}` },
              { label: translate('Fit on 1 Pallet'), measurement: `${containerTypeInfo.maxQtyOnSinglePallet}` },
            ]}
          />
        </Card.Description>

        <Card.Description>Also Known As:</Card.Description>
        <Card.Description>
          {containerTypeInfo.alsoKnownAs.map(synonym => <Label key={synonym}>{synonym}</Label>)}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}


function createDisplayedDiameterText(diameter) {
  // The diameter value can either be a number or an array of two numbers to
  // indicate a range
  return Array.isArray(diameter)
    ? `${diameter[0]} - ${diameter[1]}"`
    : `${diameter}"`
}
