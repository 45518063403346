import React from 'react'

import ContainerTypesDifferences from './ContainerTypesDifferences'
import CBIPlasticPalletsDescription from './CBIPlasticPalletsDescription'
import CBICasePlasticPalletsDescription from './CBICasePlasticPalletsDescription'

import {
  ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET,
  ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
  ITEM_SKU_IDS_GOOD_PALLETS,
} from '../../../constants'


export default props => {
  const {
    itemSkuId,
  } = props

  if (itemSkuId === ITEM_SKU_IDS_CBI_PLASTIC_PALLET) {
    return <CBIPlasticPalletsDescription {...props} />
  }
  if (itemSkuId === ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET) {
    return <CBICasePlasticPalletsDescription {...props} />
  }
  if (itemSkuId === ITEM_SKU_IDS_GOOD_PALLETS) {
    return <React.Fragment />
  }
  return <ContainerTypesDifferences {...props} />
}
