import React from 'react'

import { Link } from 'react-router-dom'

import {
  URL_PATHS,
} from '../../../constants'


export default ({ translate }) => (
  <div
    style={{ display: 'flex', justifyContent: 'space-between' }}
  >
    <Link
      to={URL_PATHS.forgotPassword.path}
      className="small-link"
      id="forgotPasswordLink"
    >
      {translate('Forgot Password')}
    </Link>

    <Link
      to={URL_PATHS.confirmAccount.path}
      className="small-link"
      id="confirmAccountLink"
    >
      {`${translate('First Time Logging In')}?`}
    </Link>
  </div>
)
