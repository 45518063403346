/**
 * This is simply a <Popup> triggered by an <Icon>, text, or both (with the icon
 * coming just before the text). This is so generic that it almost doesn't need
 * to be its own component, but it's super convenient for informational popup
 * notes of form fields, which are used throughout the app often enough to
 * justify its existence.
 */

import React from 'react'
import { Icon } from 'semantic-ui-react'


// import { useTranslation } from 'react-i18next'
import PopupWithCatastrophicFailureErrorBoundary from '../semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'
import TextAsPseudoLink from '../TextAsPseudoLink'

export default ({
  includeTriggerIcon=true,
  iconName='info', // other popular ones are "exclamation" and "question"
  size='small',
  includeTriggerText=false,
  triggerText,
  content,
  // optional props
  popupProps,
  iconProps,
  triggerTextProps,
}) => {
  // const { t: translate } = useTranslation('common')
  const triggerIcon = (
    <Icon
      name={iconName}
      size={size}
      color="blue"
      style={{ margin: '0px' }}
      {...iconProps}
    />
  )

  const trigger = (
    <TextAsPseudoLink
      {...triggerTextProps}
    >
      {includeTriggerIcon && triggerIcon}{includeTriggerText && triggerText}
    </TextAsPseudoLink>
  )

  return (
    <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      trigger={trigger}
      content={content}
      position="top center"
      hoverable
      // Why not both click and hover? Because of
      // https://microstartap3.atlassian.net/browse/TP3-3504
      on='click'
      {...popupProps}
    />
  )
}
