import { connect } from 'react-redux'

import AckInboundShipmentsModal from './components'

import {
  getHasAnyInboundUnacknowledgedShipments,
} from '../../redux/selectors/inboundUnacknowledgedShipments'
import {
  getHasAckInboundShipmentsReminderPopupHadChanceToRender,
} from '../../redux/selectors/ackInboundShipmentsReminderPopup'

import createAction from '../../redux/actions/createAction'
import {
  SET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER,
} from '../../redux/actions/actionTypes'


const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps
  const hasAnyInboundUnacknowledgedShipments = getHasAnyInboundUnacknowledgedShipments(state, customerId)
  const hasAckInboundShipmentsReminderPopupHadChanceToRender =
    getHasAckInboundShipmentsReminderPopupHadChanceToRender(state, customerId)

  return {
    hasAnyInboundUnacknowledgedShipments,
    hasAckInboundShipmentsReminderPopupHadChanceToRender,
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { customerId } = ownProps
  const setAckInboundShipmentsReminderPopupHasHadTheChanceToRender = () => (
    dispatch(createAction(
      SET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER,
      { customerId },
    ))
  )

  return {
    setAckInboundShipmentsReminderPopupHasHadTheChanceToRender,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AckInboundShipmentsModal)
