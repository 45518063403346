/**
 * The purpose of this component is to do one of two things:
 *
 * 1)  if the route is exactly "[...]/operate-for", with nothing after
 *     "operate-for", redirect to [...];
 * 2)  render the components that create the needed "/operate-for/..." routes
 *     (e.g. "/operate-for/order-kegs", etc.) for the customer being operated
 *     for (notice that this component does not create the needed routes itself;
 *     it simply renders the components that create these routes)
 */

import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import RouteNotFoundIfInvalidCustomerId from './containers/RouteNotFoundIfInvalidCustomerId'
import PrivateRoute from '../../common-components/routes/PrivateRoute'

import {
  goUpInUrlPath,
} from '../../utils'

import {
  URL_PATH_DPS_CUSTOMER_ID,
} from '../../constants'


export default ({
  operatorCustomerId,
  match, // match.url will be '[...]/operate-for'
}) => (
  //  Why don't we render <RouteNotFound> within this <Switch> component?
  //  Because it would be impossible to get to <RouteNotFound>: the only two
  //  routes within the <Switch> are:
  //
  //  exactly [current path]/operate-for
  //
  //  [current path]/operate-for/[literally any possible path, even paths with additional slashes]
  //
  //  Therefore every possible path will match. This is what we want. More
  //  deeply nested components will check whether the text after the
  //  '/operate-for' is a valid customer ID and render a 404 if it isn't.
  <Switch>
    <PrivateRoute
      // We can't use our nifty createRelativePath() function here because we
      // have no variable in our constants.js file set to "/:customerId". We
      // have "/operate-for", "/operate-for/:customerId", and ":customerId", but
      // those aren't exactly what we need. The problem is that this component
      // gets called when we're in the middle of creating a bunch of routes:
      // we've created "[...]/operate-for", and that's our current path (it's
      // what match.url is set to), but we haven't yet created
      // "[...]/operate-for/:customerId", which is what we're trying to do now.
      // Being in this limbo state is one of the few times that our
      // createRelativePath() function won't work for us, so we simply have to
      // do some manual string concatenation here.
      path={`${match.url}/${URL_PATH_DPS_CUSTOMER_ID}`}
      render={routeProps => (
        <RouteNotFoundIfInvalidCustomerId
          operatorCustomerId={operatorCustomerId}
          {...routeProps}
        />
      )}
    />
    {/* if user manually navigates to '/operate-for', with nothing after the */}
    {/* 'operate-for' send them back to '/' */}
    <Redirect
      exact
      from={match.url}
      to={goUpInUrlPath(match.url, 1)}
    />
  </Switch>
)
