import React from 'react'
import { Header, Message } from 'semantic-ui-react'


import ContactCard from '../../ContactCard'

import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
  CUSTOMER_REPS_SALES,
} from '../../../constants'

export default ({
  isCustomerOnCreditHold,
  isCustomerOnReportingHold,
  isCustomerOnSalesHold,
  isCustomerStatusInactive,
  isCustomerOnAcknowledgmentHold,
  customerId,
}) => {
  let headerText
  let messageText
  if (isCustomerOnCreditHold) {
    headerText = 'Credit Hold'
    messageText = "Your account has been placed on a credit hold. Please contact your personalized representative to resolve. Until then, you won't be able to use this feature."
  } else if (isCustomerOnReportingHold) {
    headerText = 'Reporting Hold'
    messageText = 'Our records indicate you have not reported outbound shipments within the last 90 days. Please report outbound shipments to order kegs.'
  } else if (isCustomerOnSalesHold) {
    headerText = 'Sales Hold'
    messageText = 'Your account has been placed on hold. Please contact your account manager to resolve. Until this is resolved, you cannot order kegs (but you can perform all other activities).'
  } else if (isCustomerStatusInactive) {
    headerText = 'Inactive Account'
    messageText = "Your account is currently inactive, so you won't be able to use this feature. If you feel your account has been de-activated by mistake, contact your personalized representative to resolve."
  } else if (isCustomerOnAcknowledgmentHold) {
    headerText = 'Acknowledgment Hold'
    messageText = 'You account is currently on acknowledgment hold. This will prevent you from ordering more kegs. Please acknowledge your shipments to remove the hold on your account.'
  }

  return (
    <Message
      compact
      negative
      style={{ width: '500px' }}
    >
      <Header as="h2">{headerText}</Header>
      <p>{messageText}</p>
      <ContactCard
        customerId={customerId}
        repType={isCustomerOnSalesHold ? CUSTOMER_REPS_SALES : CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
      />
    </Message>
  )
}
