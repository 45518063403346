import { connect } from 'react-redux'


import NoMatch from './components'

import {
  getIsEmployeeUserLoggedInRatherThanCustomerUser,
} from '../../../../redux/selectors/currentUserEmployee'
import {
  getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,
} from '../../../../redux/selectors/currentUser'
import {
  getIsAuthenticated,
} from '../../../../redux/selectors/authentication'


const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state),
  isEmployeeUserLoggedInRatherThanCustomerUser: getIsEmployeeUserLoggedInRatherThanCustomerUser(state),
  ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor:
    getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor(state),
})

export default connect(
  mapStateToProps,
)(NoMatch)
