import { call, put, take } from 'redux-saga/effects'

import {
  validateCustomerRepObject,
} from '../../util/apiResponseDataValidation/usersAndPermissions/customerRepresentative'

import { privateFetch } from '../../fetch'
import { getCustomerRepresentativeApiUrl } from '../../../../utils'
import {
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_REQUEST,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_SUCCESS,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_FAILURE,
  SAVE_USER,
} from '../../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'

import {
  XhrReplicationError,
} from '../../../../customErrors'


// The saga that does the actual fetching/saving of data
function* fetchCustomerRepresentativeWorkhorse(action) {
  const { repId } = action.payload
  yield put(createAction(FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_REQUEST, { target: [repId] }))
  let response
  try {
    response = yield call(privateFetch, { path: getCustomerRepresentativeApiUrl(repId) })
  } catch (error) {
    yield call(
      doFailure,
      {
        repId,
        error,
      },
    )
    return
  }
  yield call(doSuccess, repId, response)
}


function* doSuccess(repId, httpResponse) {
  const data = httpResponse.data
  const {
    validatedData: info,
    canDataBeSavedToStore,
  } = yield call(validateCustomerRepObject, { httpResponse, data })
  // CODE_COMMENTS_178
  if (!canDataBeSavedToStore) {
    // CODE_COMMENTS_149
    const error = new XhrReplicationError({
      response: {
        status: 'not a real xhr error',
        statusText: `${httpResponse.config.url} has returned a malformed userObject`,
        data: {},
        headers: {},
      },
      config: httpResponse.config,
    })
    yield call(
      doFailure,
      {
        error,
        overridingOnlyLogErrorIfFunc: () => false, // CODE_COMMENTS_193
      },
    )
    return
  }

  yield put(createAction(SAVE_USER, { info }))
  yield put(createAction(FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_SUCCESS, { target: [repId] })) // CODE_COMMENTS_20
}

function* doFailure({ repId, error, overridingOnlyLogErrorIfFunc }) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_FAILURE,
        target: [repId],
      }),
      onlyLogErrorIf: overridingOnlyLogErrorIfFunc,
    },
  )
}


/**
 * This saga looks a little different from most of our other sagas: what's this
 * 'chan' variable and why does the function have a while loop? These are needed
 * because this function gets called by the redux-saga Channel queue, which
 * lives in a separate file in this folder.
 */
export function* fetchCustomerRepresentative(chan) {
  while (true) {
    const action = yield take(chan)
    yield call(fetchCustomerRepresentativeWorkhorse, action)
  }
}
