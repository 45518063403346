import React from 'react'

import VerifiedOrErrorMessages from '../../VerifiedOrErrorMessages'
import {
  getIsEachContracteeBrewerTheCbCanReportInventoryForConfiguredWithAtLeastOneContainerType,
  getIsConfiguredWithAtLeastOneContainerType,
  getIsNotInPendingStatus,
} from '../../Verifiers'


export default props => (
  <VerifiedOrErrorMessages
    verifiers={[
      {
        verifierFunction: getIsConfiguredWithAtLeastOneContainerType,
      },
      // This is important because it's how we determine which container types
      // to render in the Contract Brewer's Report Inventory form.
      {
        verifierFunction: getIsEachContracteeBrewerTheCbCanReportInventoryForConfiguredWithAtLeastOneContainerType,
      },
      {
        verifierFunction: getIsNotInPendingStatus,
      },
    ]}
    {...props}
  />
)
