import fetchLatestWebAppVersionNumberSagas from './fetchLatestWebAppVersionNumber'
import hardRefreshThreadToDownloadLatestWebAppVersionSagas from './hardRefreshThreadToDownloadLatestWebAppVersion'
import hardRefreshToDownloadLatestWebAppVersionSagas from './hardRefreshToDownloadLatestWebAppVersion'


// CODE_COMMENTS_11
export default [
  ...fetchLatestWebAppVersionNumberSagas,
  ...hardRefreshThreadToDownloadLatestWebAppVersionSagas,
  ...hardRefreshToDownloadLatestWebAppVersionSagas,
]
