/**
 * CODE_COMMENTS_117, CODE_COMMENTS_135
 */

/* eslint-disable no-continue */

import { call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import axios from 'axios'
import moment from 'moment'


import {
  addAuthHeaderToFetchHeaders,
} from '../util'

import {
  API_FETCH_TIMEOUT_POLLING_POLL_RETRY_DELAY,
  API_FETCH_TIMEOUT_POLLING_STOP_POLLING_AFTER_X_MINUTES,
} from '../../../../config'

import {
  logApiCallError,
} from '../../../../utils/thirdPartyLogging'

import {
  getPollTimedOutApiFetchApiUrl,
} from '../../../../utils'

import {
  XhrReplicationError,
} from '../../../../customErrors'


// CODE_COMMENTS_117
export function* fetchWithTimeoutPolling(config) {
  let response
  try {
    response = yield call(axios, config)
    return response
  } catch (error) {
    if (
      error.response &&
      error.response.status === 504
    ) {
      const amazonRequestId = error.response.headers['x-amzn-requestid']
      logApiCallError({
        error,
        config,
        ifTimeoutWillCallBeRetriedOrPolled: 'poll',
      })
      response = yield call(pollTimedOutApiFetch, amazonRequestId, error)
      return response
    }
    throw error
  }
}


function* pollTimedOutApiFetch(amazonRequestId, original504Error) {
  const config = yield call(createConfigForTimeoutPolling, amazonRequestId)

  const stopPollingAt = moment().add(API_FETCH_TIMEOUT_POLLING_STOP_POLLING_AFTER_X_MINUTES, 'minutes')
  while (moment() < stopPollingAt) {
    let response
    try {
      response = yield call(axios, config)
    } catch (error) {
      if (error.response && error.response.status === 504) { // timeout
        // If the timeout polling call itself receives a 504, this has nothing
        // to do with the original request we're polling for, it's just that the
        // actual timeout polling lambda has timed out. This just means that no
        // timeout polling lambdas are warm, so simply send another timeout
        // polling call immediately. (We're not in any danger of getting caught
        // in an infinite "always getting 504s" loop here because this loop is
        // timed and will stop running after
        // API_FETCH_TIMEOUT_POLLING_STOP_POLLING_AFTER_X_MINUTES minutes.)
        logApiCallError({
          error,
          config,
          ifTimeoutWillCallBeRetriedOrPolled: 'retry',
        })
        continue
      } else {
        // if the polling call returns an error that isn't a timeout, just throw
        // that error, there's nothing we can do to handle this.

        // TODO rollbar: report the fact that /servicecallstatus has itself
        // returned an error (not the original request) which is not a 504, as
        // this should never happen.
        throw error
      }
    }

    // Ensure the target amazon request id of the /servicecallstatus response is
    // the same as the request ID passed to it (this is simply a preventative
    // check to ensure that /servicecallstatus is working properly)
    const targetAmazonRequestIdOfServiceCallStatusResponse = response.data.requestId
    if (targetAmazonRequestIdOfServiceCallStatusResponse !== amazonRequestId) {
      // TODO rollbar: report a serious backend error: we called
      // `/servicecallstatus/${amazonRequestId}` but the response we got back
      // targets a different Request ID:
      // targetAmazonRequestIdOfServiceCallStatusResponse

      // We throw an XhrReplicationError because if we don't, the entire app
      // will crash (because the original saga that initiated the API fetch is
      // expecting either an XHR response object an XHR error object; if it
      // doesn't get one of those two things, an unexpected error will probably
      // occur when inspecting the object). We don't want the entire app to
      // crash, we simply want to show the user that an unhandled error
      // occurred on this particular API call.
      const servicecallstatusBugErrorMessage = `${config.url} is targeting a different amazonRequestId (${targetAmazonRequestIdOfServiceCallStatusResponse}). This has been reported to our logging service.`
      throw new XhrReplicationError({
        response: {
          status: 500,
          statusText: servicecallstatusBugErrorMessage,
          data: { message: servicecallstatusBugErrorMessage },
          headers: {},
        },
        config: original504Error.config,
      })
    }

    // CODE_COMMENTS_135
    if (
      response.data.status === 0 ||
      // response.data.status is supposed to equal the integer 0 if the original
      // call has not yet finished, but code refactors of the backend in the
      // future might change this to the string 0, in which case we're
      // proactively coding for that scenario.
      response.data.status === '0'
    ) {
      yield call(delay, API_FETCH_TIMEOUT_POLLING_POLL_RETRY_DELAY*1000)
      continue
    }
    const responseOfOriginalRequest = craftResponseOfOriginalRequestFromServiceCallStatusResponse(
      response,
      original504Error,
    )
    return responseOfOriginalRequest
  }
  // if the polling service has polled for several minutes and the original call
  // has still has not returned a response, stop polling and throw the original
  // 504 error.
  throw original504Error
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

function* createConfigForTimeoutPolling(amazonRequestId) {
  const path = getPollTimedOutApiFetchApiUrl(amazonRequestId)
  const headers = yield call(addAuthHeaderToFetchHeaders)

  return {
    url: path,
    baseURL: process.env.REACT_APP_API_ROOT,
    headers,
  }
}


// CODE_COMMENTS_135
function craftResponseOfOriginalRequestFromServiceCallStatusResponse(
  servicecallstatusResponse,
  original504Error,
) {
  const statusOfOriginalReponse = servicecallstatusResponse.data.status
  const bodyOfOriginalResponse = JSON.parse(servicecallstatusResponse.data.response.body)
  const amazonRequestIdOfOriginalResponse = servicecallstatusResponse.data.requestId

  // original request is not an error
  if (statusOfOriginalReponse < 399) {
    return {
      status: statusOfOriginalReponse,
      data: bodyOfOriginalResponse,
    }
  }

  // If the original request threw an error, we want to replicate that error and
  // throw it. The error will bubble up to the saga performing the actual fetch,
  // and will be handled in a try block there.
  throw new XhrReplicationError({
    response: {
      status: statusOfOriginalReponse,
      statusText: '',
      data: bodyOfOriginalResponse,
      headers: {
        'x-amzn-requestid': amazonRequestIdOfOriginalResponse,
      },
    },
    config: original504Error.config,
  })
}
