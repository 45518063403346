// See CODE_COMMENTS_238. This thread has only one purpose: to fetch the
// maintenance mode schedule every hour or so. It doesn't put the app into
// maintenance mode or take the app out of maintenance mode.

/* eslint-disable no-continue */

import {
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'
// import moment from 'moment-timezone'


import {
  getAreWeCurrentlyInMaintenanceMode,
} from '../../selectors/maintenanceMode'

import { privateFetch } from '../fetch'

import {
  START_MAINTENANCE_SCHEDULE_FETCHER_THREAD,
  SAVE_MAINTENANCE_SCHEDULE,
} from '../../actions/actionTypes'
import {
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  URL_PARAM_PROPERTY,
  URL_PARAM_PROPERTY_VALUE_MAINTENANCE_SCHEDULE,
} from '../../../constants/formAndApiUrlConfig/systemProperties'

import {
  API_URL_PATH_SYSTEM_PROPERTIES,
} from '../../../constants'

import {
  putAppIntoMaintenanceModeIfTheCurrentTimeIsWithinXMinutesAfterTheStartOfAMaintenanceWindow,
  checkIfFlagToDisplayNotificationOfUpcomingMaintenanceShouldBeSetAndSetItIfSo,
} from './util'

import {
  extractSystemPropertyFromHttpResponse,
} from '../util/systemProperties'

import {
  POLL_BACKEND_EVERY_X_MINUTES_WHILE_IN_MAINTENANCE_MODE,
  POLL_BACKEND_EVERY_X_MINUTES_FOR_MAINTENANCE_SCHEDULE,
} from '../../../config'


// Wait, shouldn't this be a cancelable saga which gets canceled on logout or
// authentication failure? Yes, but instead of using the cancelableForkedSaga()
// function here, we let our main rootSaga() wrap this with a
// transformSagasIntoCancellableSagas() function.
function* fetchMaintenanceScheduleThread() {
  while (true) {
    const areWeCurrentlyInMaintenanceMode = yield select(getAreWeCurrentlyInMaintenanceMode)
    if (areWeCurrentlyInMaintenanceMode) {
      yield call(delay, POLL_BACKEND_EVERY_X_MINUTES_WHILE_IN_MAINTENANCE_MODE*1000*60)
      continue
    }
    yield call(fetchMaintenanceSchedule)
    yield call(delay, POLL_BACKEND_EVERY_X_MINUTES_FOR_MAINTENANCE_SCHEDULE*1000*60)
  }
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    START_MAINTENANCE_SCHEDULE_FETCHER_THREAD,
    fetchMaintenanceScheduleThread,
  ],
]


// Helper functions

function* fetchMaintenanceSchedule() {
  let response
  try {
    // // For testing of Maintenance Mode features
    // response = { data: [{ value: JSON.stringify({
    //   maintainanceModeActive: false,
    //   maintenanceSchedule: [
    //     {
    //       startTimeUTC: moment.tz('2019-12-16 13:50', 'America/Denver').valueOf(),
    //       endTimeUTC: moment.tz('2019-12-16 13:52', 'America/Denver').valueOf(),
    //       internalReason: null,
    //       customerFacingReason: null,
    //     },
    //     {
    //       startTimeUTC: moment.tz('2025-12-06 22:00', 'America/Denver').valueOf(),
    //       endTimeUTC: moment.tz('2025-12-06 23:45', 'America/Denver').valueOf(),
    //       internalReason: null,
    //       customerFacingReason: null,
    //     },
    //   ],
    // }) }] }
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_SYSTEM_PROPERTIES,
        params: {
          [URL_PARAM_PROPERTY]: URL_PARAM_PROPERTY_VALUE_MAINTENANCE_SCHEDULE,
        },
        setOperateAsCustomerUserHeaderIfEmployeeIsLoggedIn: false, // CODE_COMMENTS_221
      },
    )
  } catch (error) {
    yield call(
      generalDoFailure,
      {
        error,
        preventsDashboardFromLoading: false,
      },
    )
    return
  }

  const maintenanceSchedule = extractSystemPropertyFromHttpResponse(response, 'maintenanceSchedule')
  if (maintenanceSchedule) {
    yield put(createAction(SAVE_MAINTENANCE_SCHEDULE, maintenanceSchedule))
    // Why do we do this stuff here, aren't there other saga threads that
    // specifically handle this? Yes, but if the user loads the app during a
    // maintenance window (or during the 24hrs before a maintenance window, when
    // the "Upcoming Maintenance" warning should be shown), we want the right
    // thing to happen immediately; for example, we don't want to wait the ~10
    // minutes or so between loop iterations for the "Upcoming Maintenance"
    // warning to be shown. See
    // https://microstartap3.atlassian.net/browse/TP3-3357 for motivation.
    yield call(putAppIntoMaintenanceModeIfTheCurrentTimeIsWithinXMinutesAfterTheStartOfAMaintenanceWindow)
    yield call(checkIfFlagToDisplayNotificationOfUpcomingMaintenanceShouldBeSetAndSetItIfSo)
  }
}
