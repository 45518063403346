/* eslint-disable max-len */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


import Routes from './components/DistributorRoutes'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../../../redux/selectors/rewrite/parentChildLinks'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../../redux/selectors/rewrite/users'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../redux/selectors/permissions'

import {
  getShouldReportInventoryFormBeRendered,
} from '../../../ReportInventory/util'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
} from '../../../../constants/permissions'

const mapStateToProps = (state, { customerId }) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)

  const hasManageUsersAndContactsPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  })
  const hasAddressesPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
  })

  const shouldReportInventoryFormBeRendered = getShouldReportInventoryFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    customerId,
  })

  return {
    // Why Booleans? See CODE_COMMENTS_252
    shouldReportInventoryFormBeRendered: Boolean(shouldReportInventoryFormBeRendered),
    hasManageUsersAndContactsPermissions: Boolean(hasManageUsersAndContactsPermissions),
    hasAddressesPermissions: Boolean(hasAddressesPermissions),
  }
}

export default withRouter(connect(
  mapStateToProps,
)(Routes))
