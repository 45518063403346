import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

import ModalWithCatastrophicFailureErrorBoundary from '../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'

import EditInventoryReport from '../../../../../ReportInventory/EditInventoryReport'
import EditShipmentReport from '../../../../../ReportShipments/EditShipment'

import withCustomerType from '../../../../../../common-components/HOC/redux/withCustomerType'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  CUSTOMER_TYPES_DISTRIBUTOR,
} from '../../../../../../constants'

import {
  formatApiDate,
} from '../../../../../../utils'


class EditItemModal extends Component {
  state = { modalOpen: false }

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => this.setState({ modalOpen: false })

  render() {
    const {
      itemType,
      itemObj,
      customerId,
      customerType,
      operatingContractBrewerCustomerId, // CODE_COMMENTS_88
      t: translate,
    } = this.props

    let EditComponent
    let modalHeaderText

    switch (itemType) {
      // Where's keg orders? It was decided shortly after TAP3 launched that keg
      // orders will automatically be placed into Accepted status rather than
      // Pending status immediately after the user places the order.
      // Consequently, it's now impossible for customer users to edit keg
      // orders.

      // Where's collar orders? We've temporarily disabled edits to collar
      // orders; see CODE_COMMENTS_257 for why.

      case 'inbound empty keg shipment':
      case 'outbound full keg shipment': {
        EditComponent = EditShipmentReport
        modalHeaderText = `Edit Shipment: ${itemObj.shipmentId}`
        break
      }
      case 'keg fill report': {
        EditComponent = EditShipmentReport
        modalHeaderText = `Edit Keg Fill Report: ${itemObj.shipmentId}`
        break
      }
      case 'inventory report': {
        EditComponent = EditInventoryReport
        const editWhat = customerType === CUSTOMER_TYPES_DISTRIBUTOR
          ? 'Edit Empty Keg Report'
          : 'Edit Inventory Report'
        modalHeaderText = `${editWhat}: Reporting for ${formatApiDate(itemObj.countDate, DEFAULT_DISPLAYED_DATE_FORMAT)}`
        break
      }
      default: throw new Error(`The History table should always render one of the cases above, but the itemType is ${itemType} which is not one of the cases.`)
    }

    return (
      <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
        closeIcon
        closeOnDimmerClick={false} // CODE_COMMENTS_64
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="large"
        dimmer="inverted" // CODE_COMMENTS_72
        trigger={
          <Button
            compact
            size="mini"
            color="green"
            onClick={this.handleOpen}
          >
            {translate('Edit')}
          </Button>
        }
      >

        <Modal.Header>{modalHeaderText}</Modal.Header>
        <Modal.Content>
          <EditComponent
            itemObj={itemObj}
            customerId={customerId}
            operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
            closeModalFunc={this.handleClose}
          />
        </Modal.Content>
      </ModalWithCatastrophicFailureErrorBoundary>
    )
  }
}


export default withTranslation()(withCustomerType(EditItemModal))
