// For the Manage Users form
import { put, call } from 'redux-saga/effects'


import {
  validateAndFilterArrayOfCustomerUserObjects,
} from '../../util/apiResponseDataValidation/usersAndPermissions/customerUsers'

import { privateFetch } from '../../fetch'

import {
  FETCH_CUSTOMER_USERS_REQUEST,
  FETCH_CUSTOMER_USERS_SUCCESS,
  FETCH_CUSTOMER_USERS_FAILURE,
  SAVE_USERS,
} from '../../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'

import {
  URL_PARAM_ROOT_CUSTOMER_ID,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/users'

import {
  API_URL_PATH_USERS,
} from '../../../../constants'


export function* fetchCustomerUsers(action) {
  const { customerId } = action.payload
  yield put(createAction(FETCH_CUSTOMER_USERS_REQUEST, { target: [customerId] }))
  let response
  try {
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_USERS,
        params: {
          [URL_PARAM_ROOT_CUSTOMER_ID]: customerId,
        },
      },
    )
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) {
        // no users--not sure when this would ever happen
        yield call(doSuccess, customerId, { data: [] })
        return
      }
      yield call(doFailure, customerId, error)
      return
    }
    yield call(doFailure, customerId, error)
    return
  }
  yield call(doSuccess, customerId, response)
}


function* doSuccess(customerId, httpResponse) {
  const info = validateAndFilterArrayOfCustomerUserObjects(httpResponse)
  yield put(createAction(SAVE_USERS, { info }))
  // CODE_COMMENTS_20
  yield put(createAction(
    FETCH_CUSTOMER_USERS_SUCCESS, { target: [customerId] },
  ))
}

function* doFailure(customerId, error) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_CUSTOMER_USERS_FAILURE,
        target: [customerId],
      }),
    },
  )
}
