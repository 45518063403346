import React from 'react'

import { Icon } from 'semantic-ui-react'

export default ({ t: translate }) => (
  <div className="centered-content">
    <p style={{ fontWeight: 'bold' }}>{`${translate('Hover over the bottom right of the screen')}.`}</p>
    <span>{translate('A link looking like this')}</span>
    <Icon
      name="user circle outline"
      className="microstar-blue"
      size="big"
    />
    <span>{`${translate('will appear')}.`}</span>
  </div>
)
