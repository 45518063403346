import { connect } from 'react-redux'

import ResendConfirmationCode from './components'

import fetchStatusSelectors from '../../../../../../redux/selectors/fetchStatuses/publicForms'

import {
  REDUCER_NAMES_PUBLIC_FORMS_RESEND_CONFIRMATION_CODE_TO_NEW_USER,
} from '../../../../../../constants'


const {
  getFetchStatuses,
} = fetchStatusSelectors


const mapStateToProps = state => {
  const fetchStatuses = getFetchStatuses(
    state,
    REDUCER_NAMES_PUBLIC_FORMS_RESEND_CONFIRMATION_CODE_TO_NEW_USER,
  )

  return {
    ...fetchStatuses,
  }
}

export default connect(
  mapStateToProps,
)(ResendConfirmationCode)
