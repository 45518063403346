import { connect } from 'react-redux'


import MaybeGenericContactCard from './components/MaybeGenericContactCard'

import {
  getAllNonNullRepTypesOfCustomer,
  getAllRepsOfCustomer,
} from '../../redux/selectors/customers'
import {
  getUniversalCustomerRep,
} from '../../redux/selectors/universalCustomerRepresentatives'

import {
  getIsUniversalCustomerRepType,
} from '../../utils'

import {
  CUSTOMER_REPS_GENERIC,
  CUSTOMER_REPS_SORT_ORDER_AND_PRIORITY_ORDER,
} from '../../constants'


// If, say, a logistics rep contact card is requested by the caller of this
// component but the customer's 'logisticsRep' is null in the store (which means
// the customer has no logistics rep assigned to them), this component renders a
// different customer rep. Which one depends on the order of this array: the
// first alternative is the first item in this array. If the customer doesn't
// have the first item rep type assigned to them either, move to the second item
// in the array, etc. If a customer has no rep types assigned to them, return a
// contact card with MicroStar's general contact info.
const getAlternativeRepInfo = allReps => {
  // eslint-disable-next-line no-restricted-syntax
  for (const prioritizedAltRepType of CUSTOMER_REPS_SORT_ORDER_AND_PRIORITY_ORDER) {
    // if the alternative rep type is not also null
    if (allReps[prioritizedAltRepType]) {
      return {
        repType: prioritizedAltRepType,
        repId: allReps[prioritizedAltRepType],
      }
    }
  }
  return {
    repType: CUSTOMER_REPS_GENERIC,
  }
}

// If 'customerId' is not passed in, it meas either it's a universal customer
// rep (see CODE_COMMENTS_241) or we must be logged out. If we're logged out,
// return a contact card with MicroStar's general contact info. If the
// customerId is passed in but its desired repType is null in the store, render
// a different repType (which one to return is explained in other docstrings in
// this file).
const mapStateToProps = (
  state,
  {
    customerId,
    repType,
    // Helpful when, for instance, you want to show the tech support universal
    // rep on the Catastrophic Failure page but the catastrophic failure happens
    // before the tech support universal rep has been fetched (in which case the
    // fetching of the tech support universal rep, along with all other fetches,
    // will be canceled).
    showGenericContactInfoIfFetchUniversalRepNotFinished,
    fromHelpSection,
  }) => {
  let repId
  let actualRepType
  const isUniversalCustomerRepType = getIsUniversalCustomerRepType(repType)
  if (fromHelpSection) {
   const availableRep = getAllNonNullRepTypesOfCustomer(state, customerId)
   if (availableRep.includes(repType)) {
     const allReps = getAllRepsOfCustomer(state, customerId)
     repId = allReps[repType]
     actualRepType = repType
   } else {
     repId = ''
     actualRepType =''
   }
  } else if (!isUniversalCustomerRepType && customerId) {
    const allReps = getAllRepsOfCustomer(state, customerId)
    repId = allReps[repType]
    if (repId) {
      actualRepType = repType
    } else {
      const newInfo = getAlternativeRepInfo(allReps)
      actualRepType = newInfo.repType
      repId = newInfo.repId
    }
  } else if (isUniversalCustomerRepType) {
    if (showGenericContactInfoIfFetchUniversalRepNotFinished) {
      const universalCustomerRep = getUniversalCustomerRep(state, repType)
      if (!universalCustomerRep) {
        actualRepType = CUSTOMER_REPS_GENERIC
      } else {
        actualRepType = repType
      }
    } else {
      actualRepType = repType
    }
  // not isUniversalCustomerRepType and no customerId passed in
  } else {
    actualRepType = CUSTOMER_REPS_GENERIC
  }

  return {
    repId,
    repType: actualRepType,
  }
}

export default connect(
  mapStateToProps,
)(MaybeGenericContactCard)
