import React from 'react'

import isNumber_ from 'lodash/isNumber'
import orderBy_ from 'lodash/orderBy'


/**
 * CODE_COMMENTS_62
 */
import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  formatApiDate,
  formatStringOrNumberToMoney,
} from '../../../../utils'

export const createDisplayedCustomerName = row => row?.customerName || ''
export const createDisplayedCustomerNumber = row => row?.tapCustomerId || ''
export const createDisplayedInvoiceNumber = row => row?.invoiceNumber || ''
export const createDisplayedInvoiceDate = () => row => formatApiDate(row.invoiceDate, DEFAULT_DISPLAYED_DATE_FORMAT)
export const createDisplayedDueDate = () => row => formatApiDate(row.dueDate, DEFAULT_DISPLAYED_DATE_FORMAT)
export const createDisplayedInvoicePreviousBalance = (tableOrDownloadFile, currency) => hofCreateDisplayedInvoiceDollarAmount('previousBalance', tableOrDownloadFile, currency)
export const createDisplayedInvoiceCharges = (tableOrDownloadFile, currency) => hofCreateDisplayedInvoiceDollarAmount('currentCharges', tableOrDownloadFile, currency)
export const createDisplayedInvoicePayments = (tableOrDownloadFile, currency) => hofCreateDisplayedInvoiceDollarAmount('payments', tableOrDownloadFile, currency)
export const createDisplayedInvoiceBalance = (tableOrDownloadFile, currency) => hofCreateDisplayedInvoiceDollarAmount('balance', tableOrDownloadFile, currency)
export const createDisplayedInvoiceDebitsAndCredits = (tableOrDownloadFile, currency) => row => {
  if (isNumber_(row.debits) || isNumber_(row.credits)) {
    const value = (row.debits || 0) + (row.credits || 0)
    return tableOrDownloadFile === 'table'
    // eslint-disable-next-line
      ? (<> { formatStringOrNumberToMoney({
        value,
        includeSign: true,
        currency,
      }) }</>)
      : value
  }
  return tableOrDownloadFile === 'table'
    ? <span style={{ color: 'grey' }}>not available</span> // CODE_COMMENTS_250
    : null
}


export function createInvoiceHistoryTableDollarAmountFieldSortFunction(objectPropName) {
  return (rowsArray, ascOrDesc='desc') => (
    orderBy_(
      rowsArray,
      row => (
        isNumber_(row[objectPropName])
          ? row[objectPropName]
          : -999999999999 // CODE_COMMENTS_250
      ),
      [ascOrDesc],
    )
  )
}


// Helper functions

function hofCreateDisplayedInvoiceDollarAmount(objectPropName, tableOrDownloadFile, currency = 'USD') {
  return row => {
    if (isNumber_(row[objectPropName])) {
      const value = row[objectPropName]
      return tableOrDownloadFile === 'table'
      // eslint-disable-next-line
        ? (<> { formatStringOrNumberToMoney({ value, includeSign: true, currency }) }</>) : value
    }
    return tableOrDownloadFile === 'table'
      ? <span style={{ color: 'grey' }}>not available</span> // CODE_COMMENTS_250
      : null
  }
}
