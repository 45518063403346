import React from 'react'


import LayoutDecider from './containers'
import UniversalLayout from '../UniversalLayout'


export default props => (
  <UniversalLayout>
    <LayoutDecider {...props} />
  </UniversalLayout>
)
