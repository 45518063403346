import React from 'react'
import { connect } from 'react-redux'

import DismissableMessage from '../../../common-components/DismissableMessage'

import {
  FETCH_STATUSES_FORMS_CLEAR,
} from '../../../redux/actions/actionTypes'

import createAction from '../../../redux/actions/createAction'

export default connect()(
  ({
    dispatch, // we get access to this because the component is wrapped in connect()
    formName,
    onDismiss: customOnDismiss,
    ...rest
  }) => (
    <DismissableMessage
      style={{ clear: 'both', marginBottom: '15px' }}
      error
      onDismiss={() => {
        if (customOnDismiss) { customOnDismiss({ formName, ...rest }) }
        if (dispatch && formName) {
          dispatch(createAction(FETCH_STATUSES_FORMS_CLEAR, { target: [formName] }))
        }
      }}
      {...rest}
    />
  ),
)
