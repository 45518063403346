import {
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_REPRESENTATIVES,
} from '../../../../constants'
import getFetchStatusSelectors from '../HOF/fetchStatusSelectors'

export default getFetchStatusSelectors()(
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMER_REPRESENTATIVES,
)
