import {
  FETCH_CUSTOMER_ADDRESSES_REQUEST,
  FETCH_CUSTOMER_ADDRESSES_SUCCESS,
  FETCH_CUSTOMER_ADDRESSES_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_CUSTOMER_ADDRESSES_REQUEST,
  FETCH_CUSTOMER_ADDRESSES_SUCCESS,
  FETCH_CUSTOMER_ADDRESSES_FAILURE,
)
