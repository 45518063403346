import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import isPlainObj_ from 'lodash/isPlainObject'


import Routes from './components/ContracteeBrewerRoutes'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../redux/selectors/rewrite/currentUser'

import {
  getShouldReportOutboundShipmentsFormBeRendered,
  getShouldReportBuybackShipmentsFormBeRendered,
  getShouldReportSelfDistAndPubShipmentsFormBeRendered,
} from '../../../../redux/selectors/rewrite/forms/reportShipmentsCommon/shouldReportShipmentsFormBeRendered'

import {
  getShouldOrderKegCollarsFormBeRendered,
} from '../../../OrderKegCollars/util'
import { getEntireSlice as getEntireSubsidiariesSlice } from '../../../../redux/selectors/rewrite/subsidiaries'


const mapStateToProps = (state, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = ownProps

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)

  const shouldOrderKegCollarsFormBeRendered = getShouldOrderKegCollarsFormBeRendered({
    entireCustomersSlice,
    entireRelationshipsSlice,
    entireContractsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    operatingContractBrewerCustomerId,
    entireSubsidiariesSlice,
  })

  const shouldReportOutboundShipmentsFormBeRendered = getShouldReportOutboundShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    operatingContractBrewerCustomerId,
  })

  // CODE_COMMENTS_231
  const {
    shouldOptionBeDisabled: shouldReportOutboundShipmentsFormOptionBeDisabled,
  } = isPlainObj_(shouldReportOutboundShipmentsFormBeRendered)
    ? shouldReportOutboundShipmentsFormBeRendered
    : {}

  const shouldReportBuybackShipmentsFormBeRendered = getShouldReportBuybackShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    operatingContractBrewerCustomerId,
  })
  const shouldReportSelfDistAndPubShipmentsFormBeRendered = getShouldReportSelfDistAndPubShipmentsFormBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    operatingContractBrewerCustomerId,
  })

  return {
    // Why Booleans? See CODE_COMMENTS_252
    shouldOrderKegCollarsFormBeRendered: Boolean(shouldOrderKegCollarsFormBeRendered),
    shouldReportOutboundShipmentsFormBeRendered: Boolean(shouldReportOutboundShipmentsFormBeRendered),
    shouldReportOutboundShipmentsFormOptionBeDisabled: Boolean(shouldReportOutboundShipmentsFormOptionBeDisabled),
    shouldReportBuybackShipmentsFormBeRendered: Boolean(shouldReportBuybackShipmentsFormBeRendered),
    shouldReportSelfDistAndPubShipmentsFormBeRendered: Boolean(shouldReportSelfDistAndPubShipmentsFormBeRendered),
  }
}

export default withRouter( // CODE_COMMENTS_25
  connect(
    mapStateToProps,
  )(Routes),
)
