// This is a replacement for the default 'react-datepicker' component, the
// difference being:
// 1.  This component doesn't manage its own value prop. That prop must be
//     managed by the parent component and passed down as the 'value' prop (this
//     is usually what users of this component will want anyway--form components
//     tend to want to manage their own form values state, usually using
//     useReducer with a single reducer for all form values).
// 2.  This component allows the user to type 4-4-19 rather than the exact way
//     react-datepicker wants it, 04/04/2019. "But wait,
//     react-datepicker's demo app https://reactdatepicker.com/ illustrates that
//     this feature is already built-in." It is on the latest version (2.14.1 as
//     of April, 2020), but we're using version 1.0.4. So why not just upgrade?
//     Because upgrading would require changing minDate, maxDate, etc. passed to
//     <DatePicker> from moment() objects to native date objects (use the
//     date-fns helper package to add/subtract days/weeks/months/etc from date
//     objects--details at
//     https://github.com/Hacker0x01/react-datepicker#momentjs), which is a
//     pretty big refactor touching dozens of files throughout the app.
// 3.  The input box turns red if a user-typed value isn't a valid date or
//     doesn't conform to the minDate/maxDate/etc restrictions.

import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import isString_ from 'lodash/isString'
import isFunction_ from 'lodash/isFunction'


import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../constants/formAndApiUrlConfig/commonConfig'

import {
  validateAndNormalizeDate,
  doesStringContainNumbersAndOrForwardSlashesAndOrHyphensOnly,
} from '../../../utils'


export default props => {
  const {
    value,
    minDate,
    maxDate,
    includeDates,
    excludeDates,
    onChange: onChangeParent,
    onSetIsValueValid: onSetIsValueValidParent,
    compact,
    className,
    ...otherReactDatePickerProps
  } = props
  const [applyErrorStyling, setApplyErrorStyling] = useState(false)
  const [currentlyFocused, setCurrentlyFocused] = useState(false)

  /**
   * react-datepicker's onChange() fires when two conditions are both met: 1)
   * the format of the value in the input box matches the datepicker component's
   * 'dateFormat' prop (which, if not provided, defaults to 'MM/DD/YYYY'); 2)
   * the date does not violate any of react-datepicker's filter props such as
   * 'minDate' and 'excludeDates.'
   *
   * But onChange() also fires when the input becomes an empty string. Firing on
   * an empty string is a problem in our case because we parse the input
   * argument from a date object to a string, and we'll get an error if we use
   * date.format() on an empty string. This function accounts for this.
   */
  const handleChange = date => {
    const dateString = date
      ? date.format(DEFAULT_DISPLAYED_DATE_FORMAT)
      // input is blank string
      : null
    onChangeParent(dateString)
    if (isFunction_(onSetIsValueValidParent)) {
      const result = validateDate({
        ...props,
        value: date,
      })
      onSetIsValueValidParent(Boolean(result))
    }
  }


  // Limit the characters the user can enter into the field
  const handleChangeRaw = e => {
    const { value: value_ } = e.target
    // input is blank string
    if (!value_) {
      onChangeParent('')
      if (isFunction_(onSetIsValueValidParent)) {
        onSetIsValueValidParent(false)
      }
    }
    if (isString_(value_) && value_.length > 10) { return }
    if (doesStringContainNumbersAndOrForwardSlashesAndOrHyphensOnly(value_)) {
      onChangeParent(value_)
      if (isFunction_(onSetIsValueValidParent)) {
        const result = validateDate({
          ...props,
          value: value_,
        })
        onSetIsValueValidParent(Boolean(result))
      }
    }
  }


  // Apply error styling if the entered date is invalid
  const handleBlur = e => {
    setCurrentlyFocused(false)
    const value_ = e.target.value
    if (!value) {
      setApplyErrorStyling(false)
      return
    }
    const result = validateDate({
      ...props,
      value: value_,
    })
    if (result) {
      const {
        properlyFormattedDateString,
        dateMoment,
      } = result
      handleChange(dateMoment)
      onChangeParent(properlyFormattedDateString)
      setApplyErrorStyling(false)
    } else {
      setApplyErrorStyling(true)
    }
  }

  const handleFocus = () => {
    setCurrentlyFocused(true)
    setApplyErrorStyling(false)
  }

  // When the value changes from outside this component, recompute error
  // styling. The value changes from outside the component, for instance, when a
  // user adds or deletes a row in a <FormSectionAsTable> component. To
  // illustrate, imagine this scenario: the date field at row 7 is blank and row
  // 8 is an invalid date, then the user deletes row 3: the date field at row 7
  // doesn't re-render from scratch; instead, its value is simply set what row 8
  // used to be, namely the invalid date (and row 8 is set to what row 9 was,
  // etc). Something needs to make it so that row 7 becomes error-styled and row
  // 8 has its error styling removed. This function does that.
  useEffect(
    () => {
      if (
        value
        // We only want this function to run if the value changes due to user
        // deleting a row or changing the minDate/maxDate (which happens in
        // dateRange fields), not due to the user changing this date field.
        && !currentlyFocused
      ) {
        const result = validateDate({
          value,
          minDate,
          maxDate,
          includeDates,
          excludeDates,
        })
        if (result) {
          setApplyErrorStyling(false)
          if (isFunction_(onSetIsValueValidParent)) { onSetIsValueValidParent(true) }
        } else {
          setApplyErrorStyling(true)
          if (isFunction_(onSetIsValueValidParent)) { onSetIsValueValidParent(false) }
        }
      } else {
        setApplyErrorStyling(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      value,
      minDate,
      maxDate,
      includeDates,
      excludeDates,
      currentlyFocused,
    ],
  )

  return (
    <DatePicker
      autoComplete="off"
      selected={(validateDate(props) || {}).dateMoment}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      includeDates={includeDates}
      excludeDates={excludeDates}
      placeholderText="Select Date"
      {...otherReactDatePickerProps}

      onChangeRaw={handleChangeRaw}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}

      // when the user clicks the left and right keys on the keyboard, under
      // normal circumstances, we want that to move the cursor in the input
      // field, not the selected date value in the popup. However, React has a
      // known bug that makes cursor shipment in controlled input fields
      // virtually useless (the problem is that the cursor jumps to the end of
      // the input whenever it's changed; illustrated here
      // https://github.com/mui-org/material-ui/issues/4430#issuecomment-223838757,
      // good discussion here https://github.com/facebook/react/issues/955).
      // Therefore, it's more useful to have the left and right keys move the
      // selected date value in the popup.
      disabledKeyboardNavigation={false}
      // The react-datepicker popup tends to jump around (gets higher and
      // lower depending on how many weeks there are in the month) when the
      // user scrolls through the months--see
      // github.com/Hacker0x01/react-datepicker/issues/361. The fixedHeight
      // flag solves the problem.
      fixedHeight

      className={createClassName({
        className,
        compact,
        applyErrorStyling,
      })}
    />
  )
}


function createClassName({
  className: classNamePassedIn,
  compact,
  applyErrorStyling,
}) {
  const classes = []
  if (compact) { classes.push('compact-date-field') }
  if (applyErrorStyling) { classes.push('input-error-semantic-ui') }
  if (classes.length === 0) { return classNamePassedIn }
  const newClasses = classes.join(' ')
  if (classNamePassedIn) { return `${classNamePassedIn} ${newClasses}` }
  return newClasses
}


// Returns undefined if the date string is not valid, otherwise returns a
// two-item object:
// {
//   properlyFormattedDateString, dateMoment,
// }
//
// Unfortunately, we have to re-create react-datepicker's validation logic in
// order to get correctly-working validation. There's just no other way around
// it. We considered setting a flag in our override of react-datepicker's
// onChange() handler, because it only fires when the input date passes all
// react-datepicker validation settings such as minDate and maxDate; the problem
// is that the user is allowed to input a valid date that might not be in the
// exact right format (e.g. '1/1/18' instead of '01/01/2018') (this is a feature
// we built into this component), but react-datepicker's onChange() won't be
// fired when the date isn't in the exact right format.
//
// PS: This validation function doesn't have any facilities for times, only
// dates (no checking for minTime, maxTime, excludeTimes, etc.
export function validateDate({
  value,
  minDate,
  maxDate,
  includeDates,
  excludeDates,
}) {
  // If the field contains an empty string (i.e. is not filled out), throw no
  // error.
  if (
    value === undefined
    || value === ''
  ) { return undefined }
  // If the object passed in is a moment object, turn it into a string
  const valueStr = moment.isMoment(value)
    ? value.format(DEFAULT_DISPLAYED_DATE_FORMAT)
    : value
  const properlyFormattedDateString = validateAndNormalizeDate(valueStr)
  if (!properlyFormattedDateString) { return undefined }
  const dateMoment = moment(properlyFormattedDateString, DEFAULT_DISPLAYED_DATE_FORMAT)
  // CODE_COMMENTS_80
  if (minDate) { if (dateMoment.isBefore(minDate, 'day')) { return undefined } }
  if (maxDate) { if (dateMoment.isAfter(maxDate, 'day')) { return undefined } }
  if (includeDates) { if (!includeDates.includes(dateMoment)) { return undefined } }
  if (excludeDates) { if (excludeDates.includes(dateMoment)) { return undefined } }
  return {
    properlyFormattedDateString,
    dateMoment,
  }
}
