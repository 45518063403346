import { connect } from 'react-redux'


import sortBy_ from 'lodash/sortBy'
import OrderAndEditShared from '../components'

import {
  getEntireSlice as getEntireCustomersSlice,
  getProp as getCustomerProp,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getContracts,
  getEntireSlice as getEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireAddressesSlice,
} from '../../../../redux/selectors/rewrite/addresses'
import {
  getEntireSlice as getEntireCustomerAddressLinksSlice,
} from '../../../../redux/selectors/rewrite/customerAddressLinks'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../redux/selectors/rewrite/itemSkus'
import { getAddressOfCustomer } from '../../../../redux/selectors/addresses'
import {
  ADDRESS_TYPES_DEFAULT_SHIPPING,
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CBMST,
  PPF_CONTRACT_TYPES_CONBRW,
} from '../../../../constants'
import { getKegOrderDays } from '../../../../redux/selectors/orderKegs'


const mapStateToProps = (state, ownProps) => {
  const { customerId, operatingContractBrewerCustomerId, location } = ownProps
  const { info } = location || {}
  const { orderDetails } = info || {}
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const subsidiaryId = getCustomerProp(state, customerId, 'subsidiaryId')
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireAddressesSlice = getEntireAddressesSlice(state)
  const entireCustomerAddressLinksSlice = getEntireCustomerAddressLinksSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const itemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')
  const kegOrderDays = getKegOrderDays(state, customerId)
  const defaultShippingAddress = getAddressOfCustomer({
    state,
    customerId,
    currentlyActiveAddressesOnly: true, // CODE_COMMENTS_112 CODE_COMMENTS_116
    addressType: ADDRESS_TYPES_DEFAULT_SHIPPING,
  })
  const { country: defaultCountry } = defaultShippingAddress
  const customerContracts = getContracts({
    entireContractsSlice,
    entireCustomersSlice,
    entireRelationshipsSlice,
    customerId: operatingContractBrewerCustomerId || customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CONBRW, PPF_CONTRACT_TYPES_CBMST],
    notExpiredOnly: false,
    activeStatusOnly: true,
    noFutureContracts: true,
    mostRecentOnly: true,
  })

  return {
    entireCustomersSlice,
    entireContractsSlice,
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    itemSkuIds,
    defaultCountry,
    customerContracts,
    orderDetails,
    kegOrderDays: sortBy_(kegOrderDays, ['date']),
    subsidiaryId,
  }
}


export default connect(
  mapStateToProps,
)(OrderAndEditShared)
