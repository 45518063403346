import { connect } from 'react-redux'


import OrderAndEditShared from '../components'

import {
  getEntireSlice as getEntireInventoryHistorySlice,
} from '../../../redux/selectors/histories/inventoryHistory'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../../redux/selectors/rewrite/parentChildLinks'
import {
  getEntireSlice as getEntireAddressesSlice,
} from '../../../redux/selectors/rewrite/addresses'
import {
  getEntireSlice as getEntireCustomerAddressLinksSlice,
} from '../../../redux/selectors/rewrite/customerAddressLinks'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../redux/selectors/rewrite/users'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
} from '../../../redux/selectors/rewrite/subsidiaries'


const mapStateToProps = state => {
  const entireInventoryHistorySlice = getEntireInventoryHistorySlice(state)
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireAddressesSlice = getEntireAddressesSlice(state)
  const entireCustomerAddressLinksSlice = getEntireCustomerAddressLinksSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)

  return {
    entireInventoryHistorySlice,
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entireAddressesSlice,
    entireCustomerAddressLinksSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
  }
}


export default connect(
  mapStateToProps,
)(OrderAndEditShared)
