import { put, call, takeEvery } from 'redux-saga/effects'


import { privateFetch } from '../fetch'
import {
  FETCH_CONTACTS,
  SAVE_CONTACTS,
  SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
} from '../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  URL_PARAM_CUSTOMER_ID,
} from '../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/contacts'

import {
  API_URL_PATH_CONTACTS,
} from '../../../constants'


export function* fetchContacts(action) {
  const {
    customerId,
    preventsDashboardFromLoadingIfFails,
  } = action.payload
  yield put(createAction(FETCH_CONTACTS_REQUEST, { target: [customerId] }))
  const config = {
    path: API_URL_PATH_CONTACTS,
    params: {
      [URL_PARAM_CUSTOMER_ID]: customerId,
    },
  }
  let response
  try {
    response = yield call(privateFetch, config)
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) { // customer has no addresses on file
        yield call(doSuccess, customerId, [])
        return
      }
      yield call(
        doFailure,
        {
          customerId,
          error,
          preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
        },
      )
      return
    }
    yield call(
      doFailure,
      {
        customerId,
        error,
        preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
      },
    )
    return
  }
  yield call(doSuccess, customerId, response.data)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CONTACTS, fetchContacts],
]

function* doSuccess(customerId, info) {
  const normalizedContacts = []
  const customerContactLinks = []
  info.forEach(contactObjWithLinks => {
    const { customerContactObjects, ...contact } = contactObjWithLinks
    normalizedContacts.push(contact)
    customerContactObjects.forEach(linkObj => {
      customerContactLinks.push({ ...linkObj, contactId: contact.id })
    })
  })

  yield put(createAction(SAVE_CONTACTS, { info: normalizedContacts }))
  yield put(createAction(SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS, { info: customerContactLinks }))
  yield put(createAction(FETCH_CONTACTS_SUCCESS, { target: [customerId] })) // CODE_COMMENTS_20
}

function* doFailure({ customerId, error, preventsDashboardFromLoading }) {
  yield call(
    generalDoFailure,
    {
      error,
      preventsDashboardFromLoading,
      action: createFetchFailureAction({
        error,
        type: FETCH_CONTACTS_FAILURE,
        target: [customerId],
      }),
    },
  )
}
