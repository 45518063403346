import { connect } from 'react-redux'

import ReportedVsCalculatedInventory from './components'

import WaitForApiFetch from '../../common-components/WaitForApiFetch'
import UiContainer from '../../common-components/UiContainer'

import {
  FETCH_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY,
} from '../../redux/actions/actionTypes'
import createAction from '../../redux/actions/createAction'

import fetchStatusSelectors from '../../redux/selectors/fetchStatuses/customers/reportedVsCalculatedInventory'
import {
  getReportedVsCalculatedInventoryDataForCustomer,
} from '../../redux/selectors/reportedInventoryVsCalculatedInventory'


function mapStateToProps(state, { customerId }) {
  const { getFetchStatuses, getFetchFailureErrorDetails } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(state, customerId)
  const errorDetails = getFetchFailureErrorDetails(state, customerId)

  const { didFetchSucceed } = fetchStatuses
  const reportedVsCalculatedInventoryDataForCustomer = didFetchSucceed &&
    getReportedVsCalculatedInventoryDataForCustomer(state, customerId)

  return {
    reportedVsCalculatedInventoryDataForCustomer,

    // needed by WaitForApiFetch
    ...fetchStatuses,
    errorDetails,
    loadingMessage: 'Fetching Data',
    failureHeaderMessage: 'Failed to Fetch Reported Vs Calculated Inventory Data',
  }
}


function mapDispatchToProps(dispatch, { customerId }) {
  const refetchReportedVsCalculatedInventory = () => {
    dispatch(createAction(
      FETCH_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY,
      { customerId },
    ))
  }


  return {
    // needed by WaitForApiFetch
    resubmitFunc: refetchReportedVsCalculatedInventory,
    // needed by mergeProps,
    dispatch,
  }
}


export default UiContainer({
  headerText: 'Reported Vs Calculated Inventory',
  containerId: 'reported-vs-calculated-inv',
})(connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaitForApiFetch(ReportedVsCalculatedInventory)))
