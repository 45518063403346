import isString_ from 'lodash/isString'
import isNumber_ from 'lodash/isNumber'

import { WEBAPP_VERSION } from '../../version'

import {
  REDUCER_NAMES_LATEST_WEB_APP_VERSION as defaultSlice,
  REDUCER_NAMES_LATEST_WEB_APP_VERSION_STRING,
  REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH,
} from '../../constants'


// Returns a number "jenkins-PROD-TAP3-WEB-UI-165"
export function getLatestWebAppVersionString(state) {
  return state[defaultSlice][REDUCER_NAMES_LATEST_WEB_APP_VERSION_STRING]
}


// Returns a number "jenkins-PROD-TAP3-WEB-UI-165"
export function getCurrentWebAppVersionString() {
  return WEBAPP_VERSION
}

export function getEntireNumberOfHardRefreshesByWebAppVersionAttemptingToFetchSlice(state) {
  return state[defaultSlice][REDUCER_NAMES_NUMBER_OF_HARD_REFRESHES_BY_WEB_APP_VERSION_ATTEMPTING_TO_FETCH]
}


export function getNumberOfHardRefreshesByWebAppVersionAttemptingToFetch(state, webAppVersionAttemptingToFetch) {
  const slice = getEntireNumberOfHardRefreshesByWebAppVersionAttemptingToFetchSlice(state)
  if (Object.keys(slice).includes(webAppVersionAttemptingToFetch)) {
    return slice[webAppVersionAttemptingToFetch]
  }
  return 0
}


export function getIsLatestWebAppVersionNumberGreaterThanCurrentWebAppVersionNumber(state) {
  const latestWebAppVersionString = getLatestWebAppVersionString(state)
  if (!latestWebAppVersionString) { // saga fetch hasn't had chance to run yet
    return false
  }
  const latestWebAppVersionNumber = convertWebAppVersionStringToNumber(latestWebAppVersionString)
  const currentWebAppVersionNumber = convertWebAppVersionStringToNumber(WEBAPP_VERSION)
  if (!isNumber_(latestWebAppVersionNumber) || !isNumber_(currentWebAppVersionNumber)) {
    return false
  }
  return currentWebAppVersionNumber < latestWebAppVersionNumber
}


// "jenkins-PROD-TAP3-WEB-UI-165" -> 165
function convertWebAppVersionStringToNumber(webAppVersionString) {
  // if somthing other than a string is accidentally passed in
  if (!isString_(webAppVersionString)) {
    return null
  }
  // Match the numbers at the end of a string: https://stackoverflow.com/a/6340194
  const matches = webAppVersionString.trim().match(/\d+$/)
  if (!matches) {
    return null
  }
  const versionNumberAsString = matches[0]
  return parseInt(versionNumberAsString, 10)
}
