/**
 * CODE_COMMENTS_49
 * CODE_COMMENTS_51
*/

/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file, see CODE COMMENTS 50

import { put, call, takeEvery } from 'redux-saga/effects'

import {
  validateChildrenApiResponseData,
} from '../util/apiResponseDataValidation/children'

import { privateFetch } from '../fetch'
import { getCustomerChildrenApiUrl } from '../../../utils'
import {
  FETCH_CUSTOMER_CHILDREN,
  SAVE_PARENT_TO_CHILDREN_NORMALIZED_LINKS,
  FETCH_CUSTOMER_CHILDREN_REQUEST,
  FETCH_CUSTOMER_CHILDREN_SUCCESS,
  FETCH_CUSTOMER_CHILDREN_FAILURE,
} from '../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  doSuccess as doFetchCustomerInfoSuccess,
} from './customers'

import {
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
} from '../../../constants'

import {
  ApiReturnedUnexpectedDataError,
} from '../../../customErrors'


export function* fetchCustomerChildren(action) {
  const {
    customerId,
    preventsDashboardFromLoadingIfFails,
  } = action.payload
  yield put(createAction(FETCH_CUSTOMER_CHILDREN_REQUEST, { target: [customerId] }))
  const config = { path: getCustomerChildrenApiUrl(customerId) }
  let response
  try {
    response = yield call(privateFetch, config)
  } catch (error) {
    yield call(
      doFailure,
      {
        customerId,
        error,
        preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
      },
    )
    return
  }
  yield call(doSuccess, customerId, response)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CUSTOMER_CHILDREN, fetchCustomerChildren],
]

function* doSuccess(customerId, response) {
  const data = response.data
  const {
    childCustomerObjects,
    canAllChildCustomerObjectsBeSavedToStore,
  } = yield call(
    validateChildrenApiResponseData, {
      data,
      parentCustomerId: customerId,
    })

  // CODE_COMMENTS_49: filter out self-dist customers
  const childCustomerObjectsWithoutSelfDist = childCustomerObjects.filter(o => (
    o.customerType !== CUSTOMER_TYPES_SELF_DISTRIBUTION
  ))

  if (!canAllChildCustomerObjectsBeSavedToStore) {
    yield put(createAction(FETCH_CUSTOMER_CHILDREN_FAILURE, { target: [customerId] }))
    throw new ApiReturnedUnexpectedDataError() // CODE_COMMENTS_180
  }
  yield put(createAction(
    SAVE_PARENT_TO_CHILDREN_NORMALIZED_LINKS,
    {
      leftSideObjIdValue: customerId,
      info: childCustomerObjectsWithoutSelfDist,
    },
  ))
  for (const childObj of childCustomerObjectsWithoutSelfDist) { // CODE_COMMENTS_50
    yield call(doFetchCustomerInfoSuccess, childObj.id, response, childObj)
  }
  yield put(createAction(FETCH_CUSTOMER_CHILDREN_SUCCESS, { target: [customerId] })) // CODE_COMMENTS_20
}


function* doFailure({ customerId, error, preventsDashboardFromLoading }) {
  yield call(
    generalDoFailure,
    {
      error,
      preventsDashboardFromLoading,
      action: createFetchFailureAction({
        error,
        type: FETCH_CUSTOMER_CHILDREN_FAILURE,
        target: [customerId],
      }),
    },
  )
}
