import { connect } from 'react-redux'

import DownloadInboundFullKegHistoryTableAsFile from './components'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getAllInboundFullKegShipmentHistoryObjects,
} from '../../../../../redux/selectors/histories/inboundFullKegShipmentHistory'
import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'

import {
  // Why are we using functions from the outboundFullKegShipmentHistory
  // component? Because that component and this component refer to the same
  // shipments: outbound full keg shipments for Brewers are inbound full keg
  // shipments for Distributors. See CODE_COMMENTS_205.
  getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
} from '../../util/shipmentHistory/outboundFullKegShipmentHistory'
import {
  getAllOriginAndDestinationCustomerObjectsFromShipmentObjects,
} from '../../util/shipmentHistory/common/util'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


const mapStateToProps = (
  state,
  {
    customerId,
    operatingContractBrewerCustomerId,
    formName: historyFormName, // CODE_COMMENTS_110
  },
) => {
  const mostRecentlySubmittedFormValues = getMostRecentlySubmittedFormValues(state, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return {} }

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')


  const allInboundFullKegShipmentHistoryObjects = getAllInboundFullKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  const allInboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable(
      allInboundFullKegShipmentHistoryObjects,
    )
  const allOriginAndDestinationCustomerObjectsToBeIncludedInDownloadShipmentsHistoryFile =
    getAllOriginAndDestinationCustomerObjectsFromShipmentObjects({
      state,
      arrayOrObjectOfObjects: allInboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
    })


  return {
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    allInboundFullKegShipmentHistoryObjects,
    configuredItemSkuIds,
    allOriginAndDestinationCustomerObjectsToBeIncludedInDownloadShipmentsHistoryFile,
    mostRecentlySubmittedFormValues,
  }
}


export default connect(
  mapStateToProps,
)(DownloadInboundFullKegHistoryTableAsFile)
