/* eslint-disable max-len */

import { takeEvery } from 'redux-saga/effects'

import get_ from 'lodash/get'


import historySagaCreator from './historySagaCreator'

import {
  FETCH_KEG_ORDER_HISTORY,
  SAVE_KEG_ORDER_HISTORY,
} from '../../../actions/actionTypes'


import {
  createStatusApiParameterValueForHistoryFetch,
} from './util'

import {
  kegOrderHistoryStatusesConfig,
} from '../../../../features/History/individualTabs/util/kegOrderHistory'

import {
  API_URL_PATH_KEG_ORDERS,
} from '../../../../constants'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  URL_PARAM_CUSTOMER_ID,
  URL_PARAM_ORDER_STATUS,
  URL_PARAM_START_DATE,
  URL_PARAM_END_DATE,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/orders'

import {
  COMMON_STATUS_ALL,
} from '../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  formatDateRangeForApiCall,
} from '../../../../utils'


const createFetchParamsFunc = ({
  customerId,
  formValues,
}) => {
  const { startDate, endDate } = formatDateRangeForApiCall({
    startDate: formValues[HISTORY_FORM_FIELD_NAME_START_DATE],
    endDate: formValues[HISTORY_FORM_FIELD_NAME_END_DATE],
  })

  return {
    [URL_PARAM_CUSTOMER_ID]: customerId,
    [URL_PARAM_START_DATE]: startDate,
    [URL_PARAM_END_DATE]: endDate,
    ...(
      // only add a 'status' param if the status chosen is something other than
      // "All". But see CODE_COMMENTS_61
      formValues[HISTORY_FORM_FIELD_NAME_STATUS] === COMMON_STATUS_ALL
        ? {}
        : {
          [URL_PARAM_ORDER_STATUS]: createStatusApiParameterValueForKegOrderHistoryFetch(
            formValues[HISTORY_FORM_FIELD_NAME_STATUS],
          ),
        }
    ),
  }
}

const stillSaveOnErrorFunc = error => {
  if (get_(error, 'response.status') === 404) { // no orders within this date range
    return []
  }
  return undefined
}

const saga = historySagaCreator({
  apiUrlPath: API_URL_PATH_KEG_ORDERS,
  createFetchParamsFunc,
  stillSaveOnErrorFunc,
  saveAction: SAVE_KEG_ORDER_HISTORY,
})

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_KEG_ORDER_HISTORY, saga],
]


// helper functions

function createStatusApiParameterValueForKegOrderHistoryFetch(
  statusChosenByUserInHistoryForm,
) {
  return createStatusApiParameterValueForHistoryFetch(
    statusChosenByUserInHistoryForm,
    kegOrderHistoryStatusesConfig,
  )
}
