import React, { memo } from 'react'

import ChildrenOverview from '../../../../ChildrenOverview'
import CustomerPortal from '../../../../CustomerPortal'
// import History from '../../../../History'

import {
  CUSTOMER_TYPES_MASTER,
} from '../../../../../constants'


export default memo(({ // Why memo? See CODE_COMMENTS_253
  customerId,
}) => (
  <div>
    <ChildrenOverview />

    <CustomerPortal
      customerId={customerId}
      customerType={CUSTOMER_TYPES_MASTER}
    />


    {/*
    On October 22, 2018, it was decided by Harley and Mac that Master customers
    will not have an "Invoices" history tab. Up until this point, the web app
    devs were told that Masters have an invoices history tab, so we coded for
    it. "Invoices" was the only history tab for Master customers, so commenting
    out the entire <History> section for Masters serves to disable the
    "Invoices" history tab. We've decided to comment the code out rather than
    delete it because perhaps in the future we'll change our mind and decide
    that Masters do indeed have invoices, in which case it's a simple matter of
    uncommenting the <History> component here.
    */}

    {/* <History customerId={customerId} /> */}
  </div>
))
