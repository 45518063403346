import {
  FETCH_COLLAR_PLATES_INFO_REQUEST,
  FETCH_COLLAR_PLATES_INFO_SUCCESS,
  FETCH_COLLAR_PLATES_INFO_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_COLLAR_PLATES_INFO_REQUEST,
  FETCH_COLLAR_PLATES_INFO_SUCCESS,
  FETCH_COLLAR_PLATES_INFO_FAILURE,
)
