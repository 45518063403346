export function getCustomerMessageInfo({
  customerMessages,
  currentDate,
}) {
    return Object.values(customerMessages)
      .filter(({
         startDate,
         endDate,
       }) => currentDate >= startDate && currentDate <= endDate)
      .map(({ message }) => message)
}
