import { connect } from 'react-redux'

import FeatureDisabledMessageOrOriginalFeature from '../components'

import {
  getIsCustomerOnAcknowledgmentHold,
  getIsCustomerOnCreditHold,
  getIsCustomerOnReportingHold,
  getIsCustomerOnSalesHold,
  getIsCustomerStatusInactive,
} from '../../../redux/selectors/customers'

const mapStateToProps = (state, props) => {
  const { customerId } = props
  const isCustomerOnCreditHold = getIsCustomerOnCreditHold(state, customerId)
  const isCustomerOnReportingHold = getIsCustomerOnReportingHold(state, customerId)
  const isCustomerStatusInactive = getIsCustomerStatusInactive(state, customerId)
  const isCustomerOnSalesHold = getIsCustomerOnSalesHold(state, customerId)
  const isCustomerOnAcknowledgmentHold = getIsCustomerOnAcknowledgmentHold(state, customerId)
  return {
    isCustomerOnCreditHold,
    isCustomerOnReportingHold,
    isCustomerStatusInactive,
    isCustomerOnSalesHold,
    isCustomerOnAcknowledgmentHold,
    ...props,
  }
}

export default connect(
  mapStateToProps,
)(FeatureDisabledMessageOrOriginalFeature)
