import React from 'react'
import {
  Switch,
  withRouter,
} from 'react-router-dom'

import PrivateRoute from '../../../common-components/routes/PrivateRoute'

import {
  URL_PATHS,
} from '../../../constants'

import { createRelativePath } from '../../../utils'

import Dashboard from '../../Dashboard'


import RouteNotFound from '../../RouteNotFound'

export default withRouter(({
  customerId: operateeCustomerId,
  operatingContractBrewerCustomerId,
  match,
}) => (
  <Switch>
    <PrivateRoute
      key={URL_PATHS.root.path}
      path={createRelativePath(match, URL_PATHS.root.path)}
      render={
        routeProps => (
          <Dashboard
            customerId={operateeCustomerId}
            operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
            {...routeProps}
          />
        )
      }
    />,
    <RouteNotFound />
  </Switch>
))
