import { connect } from 'react-redux'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../redux/selectors/permissions'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER,
} from '../../../../constants/permissions'


const mapStateToProps = state => ({
  shouldOperateAsCustomerUserFormBeDisabled: !getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_OPERATE_AS_CUSTOMER_USER,
    isThisAPermissionsCheckOfALoggedInEmployee: true,
  }),
})

export default Component => (
  connect(mapStateToProps)(Component)
)
