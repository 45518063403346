/* eslint-disable max-len */

import React from 'react'

import findIndex_ from 'lodash/findIndex'
import values_ from 'lodash/values'
import cloneDeep_ from 'lodash/cloneDeep'


import DownloadTableAsFile from '../../../sharedComponents/DownloadTableAsFile'


import {
  getIsSubsidiaryATypeThatShouldTrackForeignKegs,
} from '../../../../../../redux/selectors/rewrite/subsidiaries'


import {
  INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ACTUAL_DELIVERY_DATE,
  INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ESTIMATED_DELIVERY_DATE,
  INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_SOURCE,
  INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_FOREIGN_KEGS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/InboundEmptyKegShipmentHistory'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/historyShared'


import {
  getInfoDefinitionsInboundEmptyKegShipmentFullDetails,
  getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable,
  getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
} from '../../../util/shipmentHistory/inboundEmptyKegShipmentHistory'
import {
  createDisplayedProNumber,
  createDisplayedForeignKegs,
  createDisplayedActualDeliveryDateForCsv,
  createDisplayedEstimatedDeliveryDateForCsv,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'
import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'
import {
  createFilenameOfDownloadedTableFile,
  constructNonHeadingRowsOfDownloadedTableFile,
} from '../../../util/util'

import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'


export default ({
  entireItemSkusSlice,
  entireSubsidiariesSlice,
  allInboundEmptyKegShipmentHistoryObjects,
  subsidiaryId,
  configuredItemSkuIds,
  mostRecentlySubmittedFormValues,
  customerId,
  entireCustomersSlice,
}) => {
  if (!mostRecentlySubmittedFormValues) {
    return <DownloadTableAsFile data={null} filename={null} />
  }

  const allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable(
      allInboundEmptyKegShipmentHistoryObjects,
    )

  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
  })

  const filename = createFilename(mostRecentlySubmittedFormValues)

  const data = createFileData({
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    subsidiaryId,
    allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
    itemSkuIdsToIncludeAsFieldsInTable,
    customerId,
    entireCustomersSlice,
  })

  return (
    <DownloadTableAsFile
      data={data}
      filename={filename}
    />
  )
}


/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

function createFilename(mostRecentlySubmittedFormValues) {
  const historyFormStatus = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_STATUS
  ]
  const historyFormStartDate = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_START_DATE
  ]
  const historyFormEndDate = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_END_DATE
  ]

  return createFilenameOfDownloadedTableFile(
    'inbound shipments',
    historyFormStartDate,
    historyFormEndDate,
    { status: historyFormStatus },
  )
}


// array of arrays, with first array being column headings
function createFileData({
  entireItemSkusSlice,
  entireSubsidiariesSlice,
  subsidiaryId,
  allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
  itemSkuIdsToIncludeAsFieldsInTable,
  customerId,
  entireCustomersSlice,
}) {
  const originalDataDefinitions = getInfoDefinitionsInboundEmptyKegShipmentFullDetails({
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    subsidiaryId,
    itemSkuIdsToIncludeAsFieldsInTable,
    downloadAttachment: true,
    customerId,
    entireCustomersSlice,
  })

  let dataDefinitions = cloneDeep_(originalDataDefinitions)
  dataDefinitions = swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions)
  dataDefinitions = swapCarrierDefinition(dataDefinitions)
  dataDefinitions = swapForeignKegsDefinition({
    entireSubsidiariesSlice,
    subsidiaryId,
    dataDefinitions,
  })

  let arrayOfShipmentObjects = values_(allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable)
  arrayOfShipmentObjects = createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' })(arrayOfShipmentObjects)
  arrayOfShipmentObjects = arrayOfShipmentObjects.filter(getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable)

  const nonHeadingRows = constructNonHeadingRowsOfDownloadedTableFile(arrayOfShipmentObjects, dataDefinitions)

  return [
    dataDefinitions.map(o => o.heading), // headings row
    ...nonHeadingRows,
  ]
}

// CODE_COMMENTS_111
function swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions) {
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM),
    1,
    {
      heading: INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
      cellContent: createDisplayedProNumber(),
    },
  )

  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ACTUAL_DELIVERY_DATE),
    1,
    {
      heading: INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ACTUAL_DELIVERY_DATE,
      cellContent: createDisplayedActualDeliveryDateForCsv,
    },
  )

  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ESTIMATED_DELIVERY_DATE),
    1,
    {
      heading: INBOUND_EMPTY_KEG_SHIPMENT_FULL_DETAILS_LABEL_ESTIMATED_DELIVERY_DATE,
      cellContent: createDisplayedEstimatedDeliveryDateForCsv,
    },
  )

  // CODE_COMMENTS_109
  return dataDefinitions
}


// CODE_COMMENTS_132
function swapCarrierDefinition(dataDefinitions) {
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_SOURCE),
    1,
    {
      heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_SOURCE,
      cellContent: row => row.originCustomerName,
    },
  )

  // CODE_COMMENTS_109
  return dataDefinitions
}


// If a shipment's purpose is _not_ to fulfill a keg order, the table and full
// details modal show "n/a" in grey. We want the csv file to show an empty cell
// instead.
function swapForeignKegsDefinition({
  entireSubsidiariesSlice,
  subsidiaryId,
  dataDefinitions,
}) {
  if (getIsSubsidiaryATypeThatShouldTrackForeignKegs({
    entireSubsidiariesSlice,
    subsidiaryId,
  })) {
    dataDefinitions.splice(
      findIndex_(dataDefinitions, o => o.heading === INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_FOREIGN_KEGS),
      1,
      {
        heading: INBOUND_EMPTY_KEG_SHIPMENT_TABLE_LABEL_FOREIGN_KEGS,
        cellContent: createDisplayedForeignKegs(true),
      },
    )
  }
  // CODE_COMMENTS_109
  return dataDefinitions
}
