import {
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_CONTRACTS,
} from '../../../../constants'
import getFetchStatusSelectorsByCustomerId from '../HOF/fetchStatusSelectors/byCustomerId'

// eslint-disable-next-line import/prefer-default-export
export default getFetchStatusSelectorsByCustomerId(
  REDUCER_NAMES_FETCH_STATUSES_CUSTOMERS_INFO,
  REDUCER_NAMES_FETCH_STATUSES_CONTRACTS,
)
