import { connect } from 'react-redux'

import MaintenanceModeSplashScreen from './components'

import {
  getAreWeCurrentlyInMaintenanceMode,
  getStartAndEndTimesFrom503,
  getMaintenanceSchedule,
} from '../../redux/selectors/maintenanceMode'

const mapStateToProps = state => ({
  areWeCurrentlyInMaintenanceMode: getAreWeCurrentlyInMaintenanceMode(state),
  startAndEndTimesFrom503: getStartAndEndTimesFrom503(state),
  maintenanceSchedule: getMaintenanceSchedule(state),
})

export default connect(
  mapStateToProps,
)(MaintenanceModeSplashScreen)
