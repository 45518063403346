import React from 'react'


import { useTranslation } from 'react-i18next'
import InformationalPopup from '../../../common-components/InformationalPopup'

import {
  INVENTORY_REPORT_OBJS,
  INVENTORY_REPORT_OBJS_PUBS,
} from '../util/constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'


export default ({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
}) => {
  const { t: translate } = useTranslation('common')
  const universalText = translate('Monthly Shipping Totals')
  if (isTruthyAndNonEmpty(inventoryReportObjs[INVENTORY_REPORT_OBJS_PUBS])) {
    const textInHeading = isThisForDisplayingAnAlreadyCreatedInventoryReport
      ? translate('(Self-Distribution Shipments Included but Not Pub Shipments)')
      : translate('(Include Self-Distribution Shipments but NOT Pub Shipments!)')
    const popupText = isThisForDisplayingAnAlreadyCreatedInventoryReport
      ? translate("These numbers include self-distribution shipments but not kegs you've moved in and out of your pubs/tasting rooms")
      : translate("Don't include any kegs that you've moved in and out of your pubs/tasting rooms, but DO include self-distribution shipments.")
    return (
      <span>
        {universalText}
        {' '}
        <InformationalPopup
          content={popupText}
          includeTriggerIcon={false}
          includeTriggerText
          triggerText={textInHeading}
        />
      </span>
    )
  }
  // if customer has no pubs
  return universalText
}
