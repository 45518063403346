import memoize_ from 'lodash/memoize'

import values_ from 'lodash/values'

import {
  getIsCustomerOnAcknowledgmentHold,
  getIsCustomerOnCreditHold,
  getIsCustomerOnReportingHold,
  getIsCustomerOnSalesHold,
  getIsCustomerStatusInactive,
} from '../../../../redux/selectors/customers'

import {
  getCustomerIdsOfAllContracteesOfContractBrewerThatKegFillsCanBeReportedOn,
} from '../../../../redux/selectors/rewrite/relationships/relatedFromInfo'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as geEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'

import {
  isTruthyAndNonEmpty,
} from '../../../../utils'
import { EXCLUDE_BU_FOR_VIEW_ALL_INVOICES } from '../../../../constants'


// we memoize here so that the potentially disabled Customer Portal cards don't
// re-render every single time any portion of the state changes.
export const createDisabledIfAnyOfTheseConditionsAreTrueFunc = memoize_(
  ({
    customerId,
    customerIsInactive,
    customerIsOnCreditHold,
    customerIsOnSalesHold,
    customerIsOnReportingHold,
    customerIsOnAcknowledgementHold,
    contractBrewerHasNoActiveContractees,
  }) => state => {
    const entireCustomersSlice = getEntireCustomersSlice(state)
    const entireContractsSlice = geEntireContractsSlice(state)
    const entireRelationshipsSlice = getEntireRelationshipsSlice(state)

    const isCustomerStatusInactive = getIsCustomerStatusInactive(state, customerId)
    const isCustomerOnCreditHold = getIsCustomerOnCreditHold(state, customerId)
    const isCustomerOnSalesHold = getIsCustomerOnSalesHold(state, customerId)
    const isCustomerOnReportingHold = getIsCustomerOnReportingHold(state, customerId)
    const isCustomerOnAcknowledgementHold = getIsCustomerOnAcknowledgmentHold(state, customerId)
    const activeContractees = getCustomerIdsOfAllContracteesOfContractBrewerThatKegFillsCanBeReportedOn({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
    })
    const doesContractBrewerHaveActiveContractees = isTruthyAndNonEmpty(activeContractees)

    return (
      (customerIsInactive && isCustomerStatusInactive) ||
      (customerIsOnCreditHold && isCustomerOnCreditHold) ||
      (customerIsOnSalesHold && isCustomerOnSalesHold) ||
      (customerIsOnReportingHold && isCustomerOnReportingHold) ||
      (customerIsOnAcknowledgementHold && isCustomerOnAcknowledgementHold) ||
      (contractBrewerHasNoActiveContractees && !doesContractBrewerHaveActiveContractees)
    )
  },
  argObj => values_(argObj).join(),
)

export const getShouldViewAllInvoicesBeRendered = ({
  entireCustomersSlice,
  customerId,
}) => (!EXCLUDE_BU_FOR_VIEW_ALL_INVOICES.includes(entireCustomersSlice?.[customerId]?.businessUnitId))
