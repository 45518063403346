import { takeEvery, call, put, select } from 'redux-saga/effects'

import get_ from 'lodash/get'
import flatten_ from 'lodash/flatten'


import {
  getChildPubIds,
} from '../../../selectors/children'
import {
  getProp as getCustomerProp,
} from '../../../selectors/customers'

import {
  getEntireSlice as getEntireRelationshipsSlice,
  getAllBrw2ConbrwRelationshipsOfBrewer,
  getAllContractBrewingPpfContractIdsOfContractBrewer,
} from '../../../selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
  getContracts,
} from '../../../selectors/rewrite/contracts'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../selectors/rewrite/currentUser'

import {
  getWhichContracteesCanConbrwFetchInventoryReportHistoryFor,
} from '../../../selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'

import historySagaCreatorMultipleFetchesForOneFormSubmission from './historySagaCreatorMultipleFetchesForOneFormSubmission'

import {
  createHeadersForContracteeBrewerApiCall,
} from '../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  FETCH_INVENTORY_HISTORY,
  SAVE_INVENTORY_HISTORY_OF_MULTIPLE_CUSTOMERS,
  SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES,
} from '../../../actions/actionTypes'

import createAction from '../../../actions/createAction'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  URL_PARAM_CUSTOMER_ID,
  URL_PARAM_COUNT_DATE_START,
  URL_PARAM_COUNT_DATE_END,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/inventoryReports'

import {
  API_URL_PATH_INVENTORY_REPORTS,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_WAREHOUSE,
  PPF_CONTRACT_TYPES_CBMST,
  CUSTOMER_TYPES_MAINTENANCE_FACILITY,
} from '../../../../constants'

import {
  formatDateRangeForApiCall,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../utils'


// CODE_COMMENTS_141
function* createConfigFunc({
  customerId,
  formValues,
}) {
  const allCustomerIdsToFetchInventoryFor = yield call(
    getAllCustomerIdsToFetchInventoryFor,
    customerId,
  )

  const individualFetchDefinitions = []

  // CODE_COMMENTS_50
  // eslint-disable-next-line no-restricted-syntax
  for (const customerIdToFetchInventoryFor of allCustomerIdsToFetchInventoryFor) {
    const path = API_URL_PATH_INVENTORY_REPORTS
    const params = yield call(
      createFetchParams,
      {
        customerIdToFetchInventoryFor,
        formValues,
      },
    )
    const headers = yield call(
      createFetchHeaders,
      {
        currentlyOperatingCustomerId: customerId,
        customerIdToFetchInventoryFor,
      },
    )
    const fetchConfig = { path, params, headers }

    individualFetchDefinitions.push({
      fetchConfig,
      doFailure,
    })
  }

  // each API call must run sequentially, so we need to return an array of
  // arrays with each subarray having a single fetch definition; see
  // CODE_COMMENTS_141
  const fetchDefinitions = individualFetchDefinitions.reduce(
    (acc, fetchDefinition) => ([...acc, [fetchDefinition]]),
    [],
  )

  return {
    fetchDefinitions,
    doAfterAllCallsHaveFinished,
  }
}


function createFetchParams({
  customerIdToFetchInventoryFor,
  formValues,
}) {
  const { startDate, endDate } = formatDateRangeForApiCall({
    startDate: formValues[HISTORY_FORM_FIELD_NAME_START_DATE],
    endDate: formValues[HISTORY_FORM_FIELD_NAME_END_DATE],
  })
  return {
    [URL_PARAM_CUSTOMER_ID]: customerIdToFetchInventoryFor,
    [URL_PARAM_COUNT_DATE_START]: startDate,
    [URL_PARAM_COUNT_DATE_END]: endDate,
  }
}

function* createFetchHeaders({
  currentlyOperatingCustomerId,
  customerIdToFetchInventoryFor,
}) {
  const customerType = yield select(getCustomerProp, currentlyOperatingCustomerId, 'customerType')
  if (
    customerType === CUSTOMER_TYPES_CONTRACT_BREWER &&
     currentlyOperatingCustomerId !== customerIdToFetchInventoryFor
  ) {
    return createHeadersForContracteeBrewerApiCall(customerIdToFetchInventoryFor)
  }
  return {}
}


// eslint-disable-next-line consistent-return
function doFailure({ error }) {
  // no inventory reports within this date range
  if (get_(error, ['response', 'status']) === 404) {
    return []
  }
}


function* doAfterAllCallsHaveFinished({
  // an array of arrays of objects:
  // [
  //   [{ didFetchSucceed: true, response: <response> }],
  //   [{ didFetchSucceed: true, response: <response> }],
  // ]
  responses: responsesThatNeedFlattening,
  customerId,
  formName,
  formValues,
}) {
  const responseObjects = flatten_(responsesThatNeedFlattening)?.map(o => o.response)
  const infoFromFetches = responseObjects?.map(o => o.data)
  const info = yield call(
    organizeInventoryHistoryItemsToSaveInReduxStore,
    customerId,
    infoFromFetches,
  )
  yield put(createAction(SAVE_INVENTORY_HISTORY_OF_MULTIPLE_CUSTOMERS, { info }))

  // CODE_COMMENTS_79
  yield put(createAction(
    SAVE_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES,
    {
      formName,
      formValues,
    },
  ))
}

const saga = historySagaCreatorMultipleFetchesForOneFormSubmission({
  createConfigFunc,
})

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_INVENTORY_HISTORY, saga],
]


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/
function* getAllCustomerIdsToFetchInventoryFor(customerId) {
  const customerType = yield select(getCustomerProp, customerId, 'customerType')

  if (customerType === CUSTOMER_TYPES_BREWER) {
    const childPubIds = yield select(getChildPubIds, customerId)
    return [customerId, ...childPubIds]
  }

  if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    const state = yield select()
    const entireCustomersSlice = getEntireCustomersSlice(state)
    const entireContractsSlice = getEntireContractsSlice(state)
    const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
    const entirePermissionsSlice = getEntirePermissionsSlice(state)
    const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
    const contracteeBrewerCustomerIds = getWhichContracteesCanConbrwFetchInventoryReportHistoryFor({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      conbrwCustomerId: customerId,
    })
    return [customerId, ...contracteeBrewerCustomerIds]
  }
  // must be a distributor
  return [customerId]
}


// Takes an array of arrays of inventory objects (one top-level array per API
// call, e.g. one array for the BRW and one array for each child pub) and
// returns an object that will _completely override_ the entries in the Redux
// store for every customerId in contained within infoFromFetches (customerIds
// in the Inventory History Redux store slice that aren't represented in
// infoFromFetches won't be touched.)
//
// Keep in mind that what gets returned isn't
// exactly what gets stored in Redux: this function returns an object whose keys
// are Redux Identifiers (i.e. customerIds, concated with Conbrw IDs if
// necessary) and whose values are arrays of inventoryReportObjs, but what gets
// stored in Redux is an object whose keys are Redux Identifiers (same as what
// this function returns), but whose keys are objects of inventoryReportObjs
// keyed by their ids (this final transormation is done in the reducer, not in
// this function).
//
// This function is very similar to
// src/features/History/individualTabs/InventoryHistory/util/convertingToReduxStoreStructure.js
// -> formatArrayOfInventoryReportsToReduxStoreStructure(), but it's different
// enough that the two functions shouldn't be combined.
function* organizeInventoryHistoryItemsToSaveInReduxStore(customerId, infoFromFetches) {
  const map = {
    [CUSTOMER_TYPES_BREWER]: organizeInventoryHistoryItemsToSaveInReduxStoreBRW,
    [CUSTOMER_TYPES_CONTRACT_BREWER]: organizeInventoryHistoryItemsToSaveInReduxStoreCONBRW,
    [CUSTOMER_TYPES_DISTRIBUTOR]: organizeInventoryHistoryItemsToSaveInReduxStoreDIST,
    [CUSTOMER_TYPES_WAREHOUSE]: organizeInventoryHistoryItemsToSaveInReduxStoreWAREHOUSE,
    [CUSTOMER_TYPES_MAINTENANCE_FACILITY]: organizeInventoryHistoryItemsToSaveInReduxStoreMAINTENANCEFACILITY,
  }
  const customerType = yield select(getCustomerProp, customerId, 'customerType')
  const func = map[customerType]
  const toReturn = yield call(func, customerId, infoFromFetches)
  return toReturn
}

function* organizeInventoryHistoryItemsToSaveInReduxStoreBRW(customerId, infoFromFetches) {
  const allCustomerIdsToFetchInventoryFor = yield call(
    getAllCustomerIdsToFetchInventoryFor,
    customerId,
  )

  // BRW. inventoryReportObjsOfBrw will contain inventory report objects of the
  // Brewer's default contract (if it has one) and of the Brewer's Contract
  // Brewing contracts (if it has them). We need to separate these into a
  // 'default contract' pile and individual 'contract brewing contract' piles.
  const inventoryReportObjsOfBrw = infoFromFetches[0]
  const state = yield select()
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const conbrwRelationships = getAllBrw2ConbrwRelationshipsOfBrewer({
    entireCustomersSlice,
    entireRelationshipsSlice,
    customerId,

  })
  const conbrwContractIds = conbrwRelationships.map(o => o.sourcePpfContract)
  const infoForBrwAllContractsToSaveToReduxStore = Object.values(inventoryReportObjsOfBrw).reduce(
    (acc, inventoryReportObj) => {
      let key
      if (conbrwContractIds.includes(inventoryReportObj.contractId)) {
        const targetConbrwCustId = conbrwRelationships.find(
          o => o.sourcePpfContract === inventoryReportObj.contractId,
        ).destinationCustomerId
        const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier( // CODE_COMMENTS_212
          customerId,
          targetConbrwCustId,
        )
        key = reduxIdentifier
      } else {
        // This must be for a default brewing contract.
        key = createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId)
      }
      return {
        ...acc,
        [key]: [
          ...(acc[key] || []),
          inventoryReportObj,
        ],
      }
    },
    {},
  )

  // Pubs
  const pubCustomerIds = allCustomerIdsToFetchInventoryFor.slice(1) // all but 1st item
  const inventoryReportObjsOfPubs = infoFromFetches.slice(1)
  const infoForPubsToSaveToReduxStore = pubCustomerIds.reduce(
    (acc, pubCustomerId, index) => ({
      ...acc,
      [createCustIdAndOptionalConbrwCustIdReduxIdentifier(pubCustomerId)]: inventoryReportObjsOfPubs[index],
    }),
    {},
  )

  const inventoryReportObjsProperlyFormattedForReduxStore = {
    ...infoForBrwAllContractsToSaveToReduxStore,
    ...infoForPubsToSaveToReduxStore,
  }

  // We're not quite finished: the brw customerId, all
  // brw-conbrw-customerId-combos, and all child pub IDs must have an entry in
  // the object we return, even if the value is just an empty array. Why?
  // Imagine, for example, that a previous search contained inventory reports of
  // child pub IDs but this search doesn't (let's say the Brewer only recently
  // got pubs assigned to them and this most recent search is for years ago). We
  // want to override what's in the redux store for those pubs, indicating that
  // there were no inventory report objects for those pubs in this most recent
  // search.
  const allRelatedToConbrwCustomerIdsWhetherTheyHaveInventoryReportsFromThisFetchOrNot =
    conbrwRelationships.map(o => o.destinationCustomerId)
  const allChildPubCustomerIdsWhetherTheyHaveInventoryReportsFromThisFetchOrNot =
    yield select(getChildPubIds, customerId)
  const allBrwConbrwCustomerIdComboReduxIds =
    allRelatedToConbrwCustomerIdsWhetherTheyHaveInventoryReportsFromThisFetchOrNot.map(conbrwId => (
      createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        customerId,
        conbrwId,
      )
    ))
  const allPubReduxIds =
    allChildPubCustomerIdsWhetherTheyHaveInventoryReportsFromThisFetchOrNot.map(pubCustomerId => (
      createCustIdAndOptionalConbrwCustIdReduxIdentifier(pubCustomerId)
    ))

  return [
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId),
    ...allBrwConbrwCustomerIdComboReduxIds,
    ...allPubReduxIds,
  ].reduce(
    (acc, reduxId) => ({
      ...acc,
      [reduxId]: inventoryReportObjsProperlyFormattedForReduxStore[reduxId] || [],
    }),
    {},
  )
}


function* organizeInventoryHistoryItemsToSaveInReduxStoreCONBRW(
  customerId,
  // an array of arrays of inventory objects, one element per customer (i.e.
  // inventory reports of CONBRW master contract first, then each contractee
  // after that).
  infoFromFetches,
) {
  const allCustomerIdsToFetchInventoryFor = yield call(
    getAllCustomerIdsToFetchInventoryFor,
    customerId,
  )

  const filteredInfoFromFetches = yield call(
    filterOutDefaultBrwInventoryReportObjs,
    {
      customerId,
      infoFromFetches,
    },
  )

  return allCustomerIdsToFetchInventoryFor.reduce(
    (acc, customerIdToSave, index) => {
      const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier( // CODE_COMMENTS_212
        customerIdToSave,
        // if customerId and customerIdToSave are the same, it means this is the
        // inventory report for the Master Contract Brewing contract
        customerIdToSave !== customerId && customerId,
      )

      return {
        ...acc,
        [reduxIdentifier]: filteredInfoFromFetches[index],
      }
    },
    {},
  )
}

// eslint-disable-next-line require-yield
function* organizeInventoryHistoryItemsToSaveInReduxStoreDIST(customerId, infoFromFetches) {
  return {
    [customerId]: infoFromFetches[0],
  }
}

// eslint-disable-next-line require-yield
function* organizeInventoryHistoryItemsToSaveInReduxStoreWAREHOUSE(customerId, infoFromFetches) {
  return {
    [customerId]: infoFromFetches[0],
  }
}

// eslint-disable-next-line require-yield
function* organizeInventoryHistoryItemsToSaveInReduxStoreMAINTENANCEFACILITY(customerId, infoFromFetches) {
  return {
    [customerId]: infoFromFetches[0],
  }
}

// https://microstartap3.atlassian.net/browse/TP3-3832: Imagine a MASTER user
// logs in who has a child BRW and a child CONBRW, and one of the child CONBRW's
// contractees is the child BRW (this situation is actually relatively common:
// it happens when a CONBRW moves from unpooled to pooled). When the Master user
// operates for this CONBRW and then this inventory history call gets made, the
// call will return inventory reports not just on the contractee's CONBRW
// contracts with the operating CONBRW (both current and expired), but also on
// the contractee's BRW contracts, both current and expired. Why? Think of it
// from the standpoint of the backend: the call is
// /inventoryreports?cid={contracteeId}  with an
// operateOnBehalfOf={contracteeId} header. Nowhere in this call is the Contract
// Brewer's ID given; when a CONBRW user is logged in, the backend knows to
// limit the inventory reports it sends back to the CONBRW contracts between the
// contractee and the logged-in CONBRW (it's a row-level security feature). But
// when a MASTER user is logged in, and the contractee is one of the Master's
// children, the backend has no way to know that the web app only wants
// inventory reports on CONBRW contracts of this contractee. Therefore, here we
// filter out any inventory reports that aren't on the Conbrw contracts between
// the CONBRW and contractee.
function* filterOutDefaultBrwInventoryReportObjs({
  customerId,
  infoFromFetches,
}) {
  const state = yield select()
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)

  const cbmstContracts = getContracts({
    entireContractsSlice,
    customerId,
    // This is a little redundant, because when you pass in a conbrw customerId
    // to getContracts(), you can only get CBMST contracts, not conbrw
    // contracts; we define it here to be explicit
    ppfContractTypes: PPF_CONTRACT_TYPES_CBMST,
    // technically this isn't necessary, because we can only fetch inventory
    // report history on active contracts, but for this particular funciton, it
    // doesn't matter if we include inactive contract IDs
    activeStatusOnly: false,
  })
  const cbmstContractIds = cbmstContracts.map(o => o.id)

  const conbrwContractIds = getAllContractBrewingPpfContractIdsOfContractBrewer({
    entireCustomersSlice,
    entireRelationshipsSlice,
    customerId,
  })

  const allContractIdsToKeep = [...cbmstContractIds, ...conbrwContractIds]

  return infoFromFetches.map(arrayOfInventoryReportObjs => (
    arrayOfInventoryReportObjs.filter(o => allContractIdsToKeep.includes(o.contractId))
  ))
}
