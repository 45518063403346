import React from 'react'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'


import PopupWithCatastrophicFailureErrorBoundary from '../../semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'

import {
  FETCH_STATUSES_FORMS_CLEAR,
} from '../../../redux/actions/actionTypes'

import createAction from '../../../redux/actions/createAction'

import {
  ENVIRONMENT_PROD,
} from '../../../constants'

import {
  getEnvironmentBasedOnRootApiUrl,
} from '../../../utils'

export default connect()(({
  // we get access to dispatch because this component is wrapped in connect()
  dispatch,
  // props provided by caller
  formName,
  // either a string, true or a falsy value. If true, clicking the button will
  // show a popup asking you to confirm the form clear with a default message.
  // If a string, the same as true but with the default message swapped with the
  // 'confirm' value
  confirm,
  // You can make the "clear form" button can do more than just clear the form;
  // simply pass in a function as this argument and it will be executed
  // immediately after the form is cleared.
  doAfterFormIsCleared,
  buttonAndMessageText={
    buttonText: 'Fill Out Form Again',
    confirmationMessage: 'Are you sure? All error details will be cleared, too.',
    confirmationButtonText: 'Yes, clear it',
  },
  ...buttonProps
}) => {
  const clearForm = () => {
    // clear the formStatuses slice
    dispatch(createAction(FETCH_STATUSES_FORMS_CLEAR, { target: [formName] }))
    if (doAfterFormIsCleared) {
      doAfterFormIsCleared(dispatch, formName, confirm)
    }
  }
  if (confirm && getEnvironmentBasedOnRootApiUrl() !== ENVIRONMENT_PROD) {
    if (confirm === true) {
      // eslint-disable-next-line no-param-reassign
      confirm = buttonAndMessageText.confirmationMessage
    }
    return (
      <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
        trigger={
          <Button
            type="button" // CODE_COMMENTS_260
            color="blue"
          >
            {buttonAndMessageText.buttonText}
          </Button>
        }
        content={
          <div style={{ textAlign: 'center' }}>
            <p>{confirm}</p>
            <Button
              color="green"
              content={buttonAndMessageText.confirmationButtonText}
              onClick={clearForm}
              {...buttonProps}
            />
          </div>
        }
        on="click"
        position="top center"
      />
    )
  }
  return (
    <Button
      onClick={clearForm}
      type="button" // CODE_COMMENTS_260
      color="blue"
      {...buttonProps}
    >
      {buttonAndMessageText.buttonText}
    </Button>
  )
})
