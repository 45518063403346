/**
 * CODE_COMMENTS_115
 */

import { connect } from 'react-redux'

import FormDecider from '../components/FormDecider'

import fetchStatusSelectors from '../../../../redux/selectors/fetchStatuses/publicForms'

import {
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL,
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW,
} from '../../../../constants'


const {
  getFetchStatuses,
} = fetchStatusSelectors


const mapStateToProps = state => {
  const fetchStatusesEnterEmailForm = getFetchStatuses(state, REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL)
  const fetchStatusesResetPwForm = getFetchStatuses(state, REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW)

  return {
    hasEnterEmailFormFetchSucceeded: fetchStatusesEnterEmailForm.didFetchSucceed,
    hasResetPwFormFetchSucceeded: fetchStatusesResetPwForm.didFetchSucceed,
  }
}

export default connect(
  mapStateToProps,
)(FormDecider)
