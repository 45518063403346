// CODE_COMMENTS_244

import omit_ from 'lodash/omit'

import {
  SAVE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
  SAVE_MULTIPLE_SHIPMENTS_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
  DELETE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
  DELETE_SINGLE_SHIPMENT_BY_ID_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
} from '../actions/actionTypes'

import {
  convertShipmentObjsIntoProperShapeForReduxStore,
  createPotentialDuplicateShipmentStringKeyForReduxStore,
} from '../../utils/reportShipmentsFormsPotentialDuplicates'


const initialState = {}

export default function reportShipmentsFormsPotentialDuplicates(state=initialState, action) {
  switch (action.type) {
    case SAVE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES:
    case SAVE_MULTIPLE_SHIPMENTS_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES: {
      const toAdd = convertShipmentObjsIntoProperShapeForReduxStore(action.payload)
      const toAddIncludingShipmentsCurrentlyInState = Object.keys(toAdd).reduce(
        (acc, keyToAdd) => {
          const sipmentsInToAdd = toAdd[keyToAdd]
          const existingShipmentsWithIdenticalDetails = state[keyToAdd] || []
          return {
            ...acc,
            [keyToAdd]: [...sipmentsInToAdd, ...existingShipmentsWithIdenticalDetails],
          }
        },
        {},
      )
      return {
        ...state,
        ...toAddIncludingShipmentsCurrentlyInState,
      }
    }

    // See CODE_COMMENTS_254 for the difference between this and
    // DELETE_SINGLE_SHIPMENT_BY_ID_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES
    case DELETE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES: {
      const key = createPotentialDuplicateShipmentStringKeyForReduxStore(action.payload)
      if (state[key]) {
        return omit_(state, key)
      }
      return state
    }

    // See CODE_COMMENTS_254 for the difference between this and
    // DELETE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES
    case DELETE_SINGLE_SHIPMENT_BY_ID_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES: {
      const objs = convertShipmentObjsIntoProperShapeForReduxStore({
        ...action.payload,
        shipmentObj: action.payload.itemObj,
      })
      const key = Object.keys(objs)[0]
      if (state[key]) {
        if (state[key].length > 1) {
          return {
            ...state,
            [key]: state[key].filter(o => o.id !== action.payload.itemObj.id),
          }
        }
        return omit_(state, key)
      }
      return state
    }

    default:
      return state
  }
}
