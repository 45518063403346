import { connect } from 'react-redux'

import CustomerPortal from './components'


import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../../redux/selectors/rewrite/currentUser'

import {
  getShouldOrderKegCollarsFormBeRendered,
} from '../../../../OrderKegCollars/util'
import { getEntireSlice as getEntireSubsidiariesSlice } from '../../../../../redux/selectors/rewrite/subsidiaries'


const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)

  const shouldOrderKegCollarsFormBeRendered = getShouldOrderKegCollarsFormBeRendered({
    entireCustomersSlice,
    entireRelationshipsSlice,
    entireContractsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    entireSubsidiariesSlice,
  })

  return {
    // Why Booleans? See CODE_COMMENTS_252
    shouldOrderKegCollarsFormBeRendered: Boolean(shouldOrderKegCollarsFormBeRendered),
  }
}

export default connect(
  mapStateToProps,
)(CustomerPortal)
