/**
 * CODE_COMMENTS_131
 */
import { connect } from 'react-redux'


import CatastrophicFailurePage from './components/CatastrophicFailurePage'

import {
  getHasCatastrophicFailureHappened,
} from '../../redux/selectors/catastrophicFailure'


const mapStateToProps = state => ({
  hasCatastrophicFailureHappened: getHasCatastrophicFailureHappened(state),
})

export default connect(
  mapStateToProps,
)(CatastrophicFailurePage)
