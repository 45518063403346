import { connect } from 'react-redux'

import DownloadOutboundFullKegHistoryTableAsFile from './components'

import {
  getEntireSlice as getAllOutboundFullKegShipmentHistoryObjects,
} from '../../../../../redux/selectors/histories/outboundFullKegShipmentHistory'
import {
  getEntireSlice as getAllKegFillsContracteeHistoryHistoryObjects,
} from '../../../../../redux/selectors/histories/kegFillsContracteeHistory'
import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'

import {
  getShouldOriginColumnBeRendered,
} from '../../util/shipmentHistory/outboundFullKegShipmentHistory'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


const mapStateToProps = (
  state,
  {
    customerId,
    operatingContractBrewerCustomerId,
    formName: historyFormName, // CODE_COMMENTS_110
    kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm,
  },
) => {
  const mostRecentlySubmittedFormValues = getMostRecentlySubmittedFormValues(state, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return {} }

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const customerType = getCustomerProp(state, customerId, 'customerType')

  const getAllHistoryObjsFunc = kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm
    ? getAllKegFillsContracteeHistoryHistoryObjects
    : getAllOutboundFullKegShipmentHistoryObjects

  const allHistoryObjs = getAllHistoryObjsFunc(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')


  const shouldOriginColumnBeRendered = getShouldOriginColumnBeRendered({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    customerType,
    kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm, // CODE_COMMENTS_232
  })


  return {
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    allHistoryObjs,
    configuredItemSkuIds,
    shouldOriginColumnBeRendered,
    mostRecentlySubmittedFormValues,
  }
}


export default connect(
  mapStateToProps,
)(DownloadOutboundFullKegHistoryTableAsFile)
