import get_ from 'lodash/get'


import {
  getAllBrw2ConbrwRelationshipsOfBrewer,
} from '../../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'

import {
  FIELD_NAME_SOURCE_CUSTOMER,
  FIELD_NAME_DESTINATION_CUSTOMER,
  FIELD_NAME_INTERNAL_REFERENCE_NUM,
  FIELD_NAME_DATE_SHIPPED,
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
} from '../../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
} from '../../../../../../constants/formAndApiUrlConfig/reportBuybackShipments'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  logErrorMessage,
  LOG_SEVERITY_ERROR,
} from '../../../../../../utils/thirdPartyLogging'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  convertApiDateToMoment,
} from '../../../../../../utils'
import { PPF_CONTRACT_TYPES_CONBRW } from '../../../../../../constants'


export function getInitialValues(props) {
  const { isEditForm } = props
  return isEditForm
    ? getInitialValuesEditForm(props)
    : getInitialValuesReportForm(props)
}


/*
 * *****************************************************************************
 * Report Shipments forms
 * *****************************************************************************
*/

// Creating initial values does these things:
// 1. It ensures the first row of each field array is shown by default.
// 2. It ensures that if the customers field that must be chosen first
//    (sometimes this is Source, sometimes Destination depending on which form
//    this is--remember that this function creates the initial values for all
//    shipments forms: Outbounds, Buybacks, Fills, etc) has only one option, the
//    first row in the field array that this function creates has its
//    Source/Destination value (whichever must be chosen first) set to the one
//    and only option. This is important because the form itself will not
//    actually render a UI form field, but rather just plain text of the
//    customer's name, so there won't be any way to set the value in the UI. The
//    "Add Row" button will automatically set this value for all new rows, but
//    it can't do it for the first row that this function creates, so we set the
//    value here in addition to creating the row.
// 3. If the customers field that must be chosen first has only one option, it
//    ensures that if the customers field that must be chosen _second_ also has
//    only one option, the first row in the field array that this function
//    creates has its Source/Destination value (whichever must be chosen second)
//    set to the one and only option. The form will still render a UI form field
//    (a dropdown), but the one and only option will be chosen for the user as a
//    UX convenience. This is especially important for the Self-Collection
//    Shipments form, where there will always be only one source and one
//    destination.
function getInitialValuesReportForm({
  fieldArraysAndCustomersFieldsDropdownOptions,
}) {
  return fieldArraysAndCustomersFieldsDropdownOptions.reduce(
    (acc, fieldArrayDef) => {
      let initialValuesOfFirstRow
      if (fieldArrayDef.customersFieldsDropdownOptions.length === 1) {
        const fieldNameToSet = fieldArrayDef.whichCustomersFieldMustBeChosenFirstSourceOrDestination ===
          WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE
          ? FIELD_NAME_SOURCE_CUSTOMER
          : FIELD_NAME_DESTINATION_CUSTOMER

        const valueToSet = fieldArrayDef.customersFieldsDropdownOptions[0].id
        initialValuesOfFirstRow = { [fieldNameToSet]: valueToSet }

        const otherCustomersFieldChoices = fieldArrayDef.customersFieldsDropdownOptions[
          0
        ].choicesForOtherCustomersFieldWhenThisCustomerIsSelected
        if (otherCustomersFieldChoices.length === 1) {
          const secondFieldNameToSet = fieldArrayDef.whichCustomersFieldMustBeChosenFirstSourceOrDestination ===
            WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE
            ? FIELD_NAME_DESTINATION_CUSTOMER
            : FIELD_NAME_SOURCE_CUSTOMER
          initialValuesOfFirstRow[[secondFieldNameToSet]] = otherCustomersFieldChoices[0].id
        }
      } else {
        initialValuesOfFirstRow = {}
      }
      // If there's more than one option for the customer field that must be
      // chosen first, all we have to do is make sure the first row is shown
      // by default.
      return {
        ...acc,
        // this is the magic that ensures the first row is shown by default
        [fieldArrayDef.fieldArrayName]: [initialValuesOfFirstRow],
      }
    },
    {},
  )
}


/*
 * *****************************************************************************
 * Edit Shipment forms
 * *****************************************************************************
*/

// We specifically don't want to memoize this because if we do, then when the
// user successfully edits the item and, on the 'Success!' overlay, clicks the
// "Re-Edit" button, the pre-filled values (initialValues) of the form row will
// still be the old information, not the newly-edited info.
function getInitialValuesEditForm(props) {
  const { fieldArrayNameIfIsEditForm } = props
  return {
    [fieldArrayNameIfIsEditForm]: [convertShipmentObjToFormValues(props)],
  }
}


// helper functions


function convertShipmentObjToFormValues(props) {
  const {
    itemObj,
  } = props
  const toReturn = {}

  // source & destination customer fields
  const {
    sourceCustomerFieldValue,
    destinationCustomerFieldValue,
  } = createSourceCustomerAndDestinationCustomerFieldValuesForEditForm(props)
  toReturn[FIELD_NAME_SOURCE_CUSTOMER] = sourceCustomerFieldValue
  toReturn[FIELD_NAME_DESTINATION_CUSTOMER] = destinationCustomerFieldValue


  // Reference # Field
  toReturn[FIELD_NAME_INTERNAL_REFERENCE_NUM] = itemObj.proNumber


  // Date Shipped Field
  toReturn[FIELD_NAME_DATE_SHIPPED] = convertApiDateToMoment(
    itemObj.dateShipped,
  ).format(DEFAULT_DISPLAYED_DATE_FORMAT)


  // Keg Qtys fields
  itemObj.lineItems.forEach(o => {
    if (o.linkType.toUpperCase() === 'SHIPPED') {
      toReturn[o.itemSkuId] = o.quantity
    }
  })

  return toReturn
}


// Source and Destination customers are the trickiest part of initialValues of
// an edit shipments form. If we didn't need to worry about contract brewing
// contracts (i.e. CBs operating for a contractee and reporting shipments, or
// Brewers reporting shipments from one of their Contract Brewer locations), it
// would be simple: the source form value is the shipment object's
// originCustomerId and the destination form value is the shipment object's
// destinationCustomerId (and swap the source and destination if the shipment
// object's `reverse` flag is true).

// However, because of shipments on contract brewing contracts, it's more
// complicated. Fortunately, the complexity is limited: as of December, 2018,
// the web app can only create shipments where the _source_ is a contract
// brewing contract, never the destination (this is because we don't want the
// web app creating shipments of empty kegs _to_ a conbrw contract location,
// because those locations are never supposed to hold on to empty kegs--the kegs
// need to quickly move to the CBMST location, and we haven't yet thought of a
// good way for the system to automatically make those moves).

// In order to determine whether the shipment's source is a conbrw contract
// rather than a brewer's default contract, we check the `originContractId` of
// the shipmentObject, which the backend sets when the shipmentObject first gets
// created. If the `originContractId` is one of the currently-operated-for
// customer's conbrw contract, all we have to do is check our relationships and
// find the relationship that matches up with this `originContractId` and grab
// the Contract Brewer customerId from it. Then we can create the true source
// form value with the createCustIdAndOptionalConbrwCustIdReduxIdentifier()
// function (see the docstring of that function for details).

// NOTE: Why do we rely on `originContractId` rather than
// _contractMetadataObject to determine whether the source of the shipment is a
// conbrw contract? We can't rely on the _contractMetadataObject in the
// shipmentObject (see CODE_COMMENTS_133 for details on
// _contractMetadataObject). The web app sets _contractMetadataObject when it
// creates a shipment, and _contractMetadataObject contains useful info
// concerning exactly what kind of shipment this is (For instance, if this is an
// outbound shipment reported by a logged-in Brewer, is the shipment origin the
// Brewer's own "Brewer Contract" inventory location or one of the Brewer's
// Contract Brewer inventory locations?) However, the backend does not save
// _contractMetadataObject to the database. When we get shipmentObjects back
// from the backend, _contractMetadataObject might be completely nulled out or
// it might be changed to something completely different than what the web app
// set it to when it created the shipment.
function createSourceCustomerAndDestinationCustomerFieldValuesForEditForm({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  itemObj,
  fieldArrayNameIfIsEditForm,
}) {
  let sourceCustomerFieldValue = itemObj.originCustomerId
  let destinationCustomerFieldValue = itemObj.destinationCustomerId

  const contractType = get_(entireContractsSlice, [itemObj.originContractId, 'ppfContractType'])

  if (
    contractType === PPF_CONTRACT_TYPES_CONBRW
  ) {
    const allBrwConbrwRelationships = getAllBrw2ConbrwRelationshipsOfBrewer({
      entireCustomersSlice,
      entireRelationshipsSlice,
      customerId,
    })
    let brwConbrwRelationship
    if (allBrwConbrwRelationships) {
      brwConbrwRelationship = allBrwConbrwRelationships.find(o => o.sourcePpfContract === itemObj.originContractId)
    }
    if (!brwConbrwRelationship) {
      // This should never happen: it would mean that this brewer (i.e.
      // customerId) has a contract brewing location that the web app doesn't
      // know about (web app has no information on the Contract Brewer in that
      // brw-conbrw contract relationship). We don't want to crash the web app,
      // but there's really no good thing to do at this point (we can't show an
      // "Oops, this shipment is not editable" message without doing some major
      // refactoring), so do something silly: set the value as if this were a
      // shipment on the Brewer's default Brewing contract. This might result in
      // the user successfully editing the shipment on the wrong contract
      // thinking that they edited it on the right contract, but the only way
      // this can happen is if its default contract is related to the same
      // customer as its contract brewing contract, which is unusual.
      sourceCustomerFieldValue =
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(
          customerId,
        )

      logErrorMessage({
        message: 'Editable shipment has unrecognized originContractId',
        severity: LOG_SEVERITY_ERROR,
        additionalInfo: {
          details: "A shipment the user is trying to edit has an unrecognized originContractId which is neither the customer's default PPF contract nor the sourcePpfContract of any brw2conbrw relationship between the currently-operated-for Brewer (if indeed it's a brewer who is currently being operated for) and any of its contractees",
          customerId,
        },
      })
    } else {
      const conbrwCustomerId = brwConbrwRelationship.destinationCustomerId
      sourceCustomerFieldValue =
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(
          customerId,
          conbrwCustomerId,
        )
    }
  }

  if ([
    REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
    REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
  ].includes(fieldArrayNameIfIsEditForm)) {
    // es6 swapping fun!
    [sourceCustomerFieldValue, destinationCustomerFieldValue] =
      [destinationCustomerFieldValue, sourceCustomerFieldValue]
  }

  return {
    sourceCustomerFieldValue,
    destinationCustomerFieldValue,
  }
}
