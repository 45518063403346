import React from 'react'


import ShipmentHistoryFormCreator from '../../HOC/ShipmentHistoryFormCreator'

import DownloadOutboundFullKegShipmentHistoryTableAsFile from '../DownloadTableAsFile'

import {
  outboundFullKegShipmentHistoryStatusesConfig,
} from '../../util/shipmentHistory/outboundFullKegShipmentHistory'

import {
  FETCH_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
  FETCH_KEG_FILL_CONTRACTEE_HISTORY,
} from '../../../../../redux/actions/actionTypes'


export default props => {
  const {
    kegFillsHistoryFormRatherThanOutboundShipmentsHistoryForm,
    kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm,
  } = props
  return (
    <ShipmentHistoryFormCreator
      {...props}
      downloadFileComponent={DownloadOutboundFullKegShipmentHistoryTableAsFile}
      statusesConfig={outboundFullKegShipmentHistoryStatusesConfig}
      submitAction={kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm
        ? FETCH_KEG_FILL_CONTRACTEE_HISTORY
        : FETCH_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY
      }
      dateRangeFieldsLabel={
        kegFillsHistoryFormRatherThanOutboundShipmentsHistoryForm
        || kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm
          ? 'Fill Date'
          : 'Ship Date'
      }
      inboundOrOutbound={
        kegFillsContracteeHistoryFormRatherThanOutboundShipmentsHistoryForm
          ? 'inbound'
          : 'outbound'
      }
    />
  )
}
