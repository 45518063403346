/* eslint-disable dot-notation, max-len */

import { connect } from 'react-redux'
import moment from 'moment'


import Table from './components/Table'


import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
} from '../../../../../redux/selectors/rewrite/subsidiaries'
import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../../redux/selectors/permissions'
import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/forms'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_BREWER,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_CONTRACT_BREWER,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_DISTRIBUTOR,
} from '../../../../../constants/permissions'

import {
  HISTORY_FORM_NAME_INVENTORY,
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,

  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_WAREHOUSE,
  CUSTOMER_TYPES_MAINTENANCE_FACILITY,
} from '../../../../../constants'


import {
  INVENTORY_REPORT_OBJS,
} from '../../../../ReportInventory/util/constants'

import {
  createGroupingsOfInventoryReportObjsForHistoryTable,
  optionsObjForConnectFunction,
} from '../util/groupingInventoryReportObjs'

import {
  getMostRepresentativeInventoryReportObj,
} from '../../../../ReportInventory/util'

import {
  createFormNameForRedux,
  convertApiDateToMoment,
} from '../../../../../utils'


const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps
  const customerType = getCustomerProp(state, customerId, 'customerType')
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)
  const subsidiaryId = getCustomerProp(state, customerId, 'subsidiaryId')

  const formName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_INVENTORY,
    customerId,
  })

  const { getFetchStatuses } = fetchStatusSelectors
  const { isFetching, didFetchFail } = getFetchStatuses(state, formName)

  // CODE_COMMENTS_229
  const groupingsOfInventoryReportObjsForHistoryTable = createGroupingsOfInventoryReportObjsForHistoryTable({
    state,
    customerId,
    isThisForDisplayingAnAlreadyCreatedInventoryReport: true,
  })

  const areAnyItemsEditable = getAreAnyItemsEditable(
    state,
    customerId,
    customerType,
    groupingsOfInventoryReportObjsForHistoryTable,
  )
  // const areAnyItemsEditable = false

  return {
    customerType,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    subsidiaryId,
    groupingsOfInventoryReportObjsForHistoryTable,
    isFormSubmitting: isFetching,
    hasFormSubmitFailed: didFetchFail,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    ...ownProps,
  }
}


export default connect(
  mapStateToProps,
  undefined,
  undefined,
  optionsObjForConnectFunction,
)(Table)


function getAreAnyItemsEditable(
  state,
  customerId,
  customerType,
  groupingsOfInventoryReportObjsForHistoryTable,
) {
  const mapOfCustomerTypesToInventoryPermissionsMaps = {
    [CUSTOMER_TYPES_BREWER]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_BREWER,
    [CUSTOMER_TYPES_CONTRACT_BREWER]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_CONTRACT_BREWER,
    [CUSTOMER_TYPES_DISTRIBUTOR]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_DISTRIBUTOR,
    [CUSTOMER_TYPES_WAREHOUSE]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_DISTRIBUTOR,
    [CUSTOMER_TYPES_MAINTENANCE_FACILITY]: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_INVENTORY_DISTRIBUTOR,
  }
  const inventoryPermissionsMapForThisCustomerType = mapOfCustomerTypesToInventoryPermissionsMaps[customerType]

  if (
    !getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: inventoryPermissionsMapForThisCustomerType,
    })
  ) { return false }
  return groupingsOfInventoryReportObjsForHistoryTable.some(row => getIsindividualItemEditable(state, row))
}


function getIsindividualItemEditable(state, row) {
  const inventoryReportObj = getMostRepresentativeInventoryReportObj({
    [INVENTORY_REPORT_OBJS]: row[INVENTORY_REPORT_OBJS],
  })
  const reportDate = convertApiDateToMoment(inventoryReportObj.reportDate)
  // allow inventory reports to be edited that were created within the last year
  // CODE_COMMENTS_80
  return reportDate.isSameOrAfter(moment().startOf('month').subtract(1, 'year'))
}
