import { call, select, takeEvery } from 'redux-saga/effects'

import get_ from 'lodash/get'


import cloneDeep_ from 'lodash/cloneDeep'
import isFunction_ from 'lodash/isFunction'
import { privateFetch } from '../../../fetch'

import {
  FETCH_REQUEST_NEW_DISTRIBUTOR_OPTIONS,
  FETCH_REQUEST_NEW_DISTRIBUTOR_COUNTRY,
} from '../../../../actions/actionTypes'

import {
  URL_PARAM_ADDRESS_SEARCH_PARAMS,
  URL_PARAM_BUSINESS_UNIT_ID, URL_PARAM_CITY,
  URL_PARAM_COUNTRY,
  URL_PARAM_CUSTOMER_NAME,
  URL_PARAM_ID,
  URL_PARAM_POSTAL_CODE,
  URL_PARAM_STATE,
} from '../../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/distSearch'

import {
  API_URL_PATH_DISTRIBUTOR_SEARCH_BY_CUSTOMER_PARAMS,
  API_URL_PATH_FETCH_COUNTRIES,
  CUSTOMER_STATUS_ACTIVE,
} from '../../../../../constants'

import {
  generalDoFailure,
} from '../../../util/fetchFailure'
import {
  createHeadersForContracteeBrewerApiCall,
} from '../../../util/headersAndQueryParamsOfApiCalls/contracteeBrewers'
import { getProp as getCustomerProp } from '../../../../selectors/customers'

function* createFetchConfig(props) {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = props
  const data = yield call(createQueryParams, props)

  const fetchConfig = {
    data,
    path: API_URL_PATH_DISTRIBUTOR_SEARCH_BY_CUSTOMER_PARAMS,
    method: 'post',
  }

  if (operatingContractBrewerCustomerId) {
    const headers = createHeadersForContracteeBrewerApiCall(customerId)
    fetchConfig.headers = headers
  }

  return fetchConfig
}

function createQueryParams({
  country,
  state,
  city,
  postalCode,
  distributorName,
  businessUnitId,
}) {
  return {
    [URL_PARAM_CUSTOMER_NAME]: distributorName || null,
    [URL_PARAM_BUSINESS_UNIT_ID]: businessUnitId,
    [URL_PARAM_ADDRESS_SEARCH_PARAMS]: {
      [URL_PARAM_ID]: null,
      [URL_PARAM_COUNTRY]: country || null,
      [URL_PARAM_STATE]: state || null,
      [URL_PARAM_CITY]: city || null,
      [URL_PARAM_POSTAL_CODE]: postalCode || null,
    },
  }
}

function* fetchOptions(action) {
  let response
  const config = yield call(createFetchConfig, action.payload)
  try {
    response = yield call(privateFetch, config)
  } catch (error) {
    if (get_(error, ['response', 'status']) === 404) {
      response = { data: [] }
    } else {
      yield call(doFailure, { error, ...action.payload })
    }
    return
  }
  yield call(doSuccess, { info: response.data, ...action.payload })
}

function* fetchCountries(action) {
  let response
  try {
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_FETCH_COUNTRIES,
      },
    )
  } catch (error) {
    if (get_(error, ['response', 'status']) === 404) {
      response = { data: [] }
    } else {
      yield call(doFailure, { error, ...action.payload })
    }
    return
  }
  yield call(doSuccess, { info: response.data, ...action.payload })
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REQUEST_NEW_DISTRIBUTOR_OPTIONS, fetchOptions],
  [takeEvery, FETCH_REQUEST_NEW_DISTRIBUTOR_COUNTRY, fetchCountries],
]

function* filterDistributorsByBusienssUnit(
  customerId,
  operatingContractBrewerCustomerId,
  arrayOfDistributorCustomerObjs,
) {
  const businessUnitIdOfCustomer = yield select(
    getCustomerProp,
    operatingContractBrewerCustomerId || customerId,
    'businessUnitId',
  )
  return arrayOfDistributorCustomerObjs.filter(o => o.businessUnitId === businessUnitIdOfCustomer)
}

function filterDistributorsByRSDists(arrayOfDistributorCustomerObjs) {
  /* eslint-disable no-useless-escape, no-multi-spaces */
  const myRe = new RegExp(
    [
      '^',             // start of string
      '(KC\\:)*',      // 0 or more instances of literally 'KC:'
      '\\s*',          // 0 or more spaces
      'RS',            // literally 'RS'
      '\\s*',          // 0 or more spaces
      '[–—\\-\\:]',       // A single En Dash, Em Dash, hyphen or colon
    ].join(''),
    'i', // i means case-insensitive
  )
  /* eslint-enable no-useless-escape, no-multi-spaces */
  return arrayOfDistributorCustomerObjs.filter(o => !myRe.test(o.name))
}

const filterOnlyActiveDistributors = filteredDistObjs => (
  filteredDistObjs?.filter(distributor => distributor?.customerStatus === CUSTOMER_STATUS_ACTIVE)
)

function* doSuccess({
  info,
  onSuccess,
  customerId,
  operatingContractBrewerCustomerId,
  filterDistributor,
}) {
  const response = cloneDeep_(info)
  if (response?.distributorsFound?.length > 0) {
    let filteredDistObjs = yield call(
      filterDistributorsByBusienssUnit,
      customerId,
      operatingContractBrewerCustomerId,
      response.distributorsFound,
    )
    filteredDistObjs = filterDistributorsByRSDists(filteredDistObjs)
    filteredDistObjs = filterOnlyActiveDistributors(filteredDistObjs)
    if (isFunction_(filterDistributor)) {
      // commented for now
      // filteredDistObjs = filteredDistObjs.filter(obj => filterDistributor({ distributor: obj }) === false)
    }
    response.distributorsFound = [...filteredDistObjs]
  }
  if (onSuccess) { onSuccess(response) }
}


function* doFailure({
  error,
  onFailure,
}) {
  if (onFailure) { onFailure({ error }) }
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
}
