/* eslint-disable max-len */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import RootRoutes from './components/RootRoutes'
import { getIsAuthenticated } from '../../../redux/selectors/authentication'
import fetchStatusSelectors from '../../../redux/selectors/fetchStatuses/customers/preliminaryInfo'
import {
  getProp as getCurrentUserProp,
  getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,
} from '../../../redux/selectors/currentUser'
import {
  getIsEmployeeUserLoggedInRatherThanCustomerUser,
} from '../../../redux/selectors/currentUserEmployee'


const { getFetchStatuses: getPrelimInfoFetchStatuses } = fetchStatusSelectors

const mapStateToProps = state => {
  const isAuthenticated = getIsAuthenticated(state)
  const isEmployeeUserLoggedInRatherThanCustomerUser = getIsEmployeeUserLoggedInRatherThanCustomerUser(state)
  const ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor = getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor(state)

  let isPrelimInfoOfCurrentUserRootCustomerFetched
  let currentUserRootCustomerId
  if (
    (
      isAuthenticated
      && !isEmployeeUserLoggedInRatherThanCustomerUser
    )
    || (
      isAuthenticated
      && isEmployeeUserLoggedInRatherThanCustomerUser
      && ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor
    )
  ) {
    currentUserRootCustomerId = getCurrentUserProp(state, 'rootCustomerId')
    isPrelimInfoOfCurrentUserRootCustomerFetched = getPrelimInfoFetchStatuses(
      state,
      currentUserRootCustomerId,
    ).didFetchSucceed
  } else {
    isPrelimInfoOfCurrentUserRootCustomerFetched = false
  }

  return {
    isAuthenticated,
    isEmployeeUserLoggedInRatherThanCustomerUser,
    ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,
    isPrelimInfoOfCurrentUserRootCustomerFetched,
    currentUserRootCustomerId,
  }
}

export default withRouter( // CODE_COMMENTS_25
  connect(
    mapStateToProps,
  )(RootRoutes),
)
