import get_ from 'lodash/get'

import {
  REDUCER_NAMES_FORMS_ADDITIONAL_INFO as parentSlice,
  REDUCER_NAMES_FORMS_ADDITIONAL_INFO_MOST_RECENT_SUCCESSFULLY_SUBMITTED_HISTORY_FORMS_VALUES as defaultSlice,
} from '../../../../constants'

// Returns the form values or undefined if the form hasn't yet been successfully submitted
export function getMostRecentlySubmittedFormValues(state, formName) {
  return get_(state, [parentSlice, defaultSlice, formName])
}
