import { connect } from 'react-redux'

import BannerMessageAccountOnHoldOrInactive from './components'


import {
  getProp as getCustomerProp,
  getIsCustomerOnCreditHold,
  getIsCustomerOnReportingHold,
  getIsCustomerOnSalesHold,
  getIsCustomerStatusInactive,
  getIsCustomerABrwType,
  getIsCustomerAConBrwType,
  getIsCustomerOnAcknowledgmentHold,
} from '../../redux/selectors/customers'
import {
  isAcknowledgementHoldWarningRequired,
  isReportingHoldWarningRequired,
} from '../../redux/selectors/inboundUnacknowledgedShipments'


const mapStateToProps = (state, props) => {
  const {
    customerId,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
  } = props
  const isCustomerOnCreditHold = getIsCustomerOnCreditHold(state, customerId)
  const isCustomerOnReportingHold = getIsCustomerOnReportingHold(state, customerId)
  const isCustomerOnSalesHold = getIsCustomerOnSalesHold(state, customerId)
  const isCustomerStatusInactive = getIsCustomerStatusInactive(state, customerId)
  const isCustomerOnAcknowledgmentHold = getIsCustomerOnAcknowledgmentHold(state, customerId)
  const isCustomerOnAcknowledgmentHoldWarning = isAcknowledgementHoldWarningRequired(state, customerId)
  const isCustomerOnReportingHoldWarning = isReportingHoldWarningRequired(state, customerId)
  const customerName = getCustomerProp(state, customerId, 'name')

  // in other words, is their account configured to these things (e.g. some
  // customers aren't even configured to order keg collars) and does this user
  // have permission to do them.
  const canCustomerNormallyOrderKegs = (
    (
      getIsCustomerABrwType(state, customerId) ||
      getIsCustomerAConBrwType(state, customerId)
    ) &&
    // Make sure the customer is not a contractee brewer
    !operatingContractBrewerCustomerId
  )
  const canCustomerNormallyOrderKegCollars = getIsCustomerABrwType(state, customerId)


  return {
    isCustomerOnCreditHold,
    isCustomerOnReportingHold,
    isCustomerOnSalesHold,
    isCustomerStatusInactive,
    customerName,
    customerId,
    canCustomerNormallyOrderKegs,
    canCustomerNormallyOrderKegCollars,
    isCustomerOnAcknowledgmentHold,
    isCustomerOnAcknowledgmentHoldWarning,
    isCustomerOnReportingHoldWarning,
  }
}

export default connect(
  mapStateToProps,
)(BannerMessageAccountOnHoldOrInactive)
