/**
 * Each User section needs to wait to render until its user's permissions are
 * fetched from the API. So what should be displayed while this info is being
 * fetched? And what should be displayed if the fetch fails? That's what this
 * component is for.
 */
import React from 'react'
import { connect } from 'react-redux'

import WaitForApiFetch from '../../../common-components/WaitForApiFetch'

import ActualIndividualUser from './ActualIndividualUser'

import {
  FETCH_CUSTOMER_USER_PERMISSIONS,
} from '../../../redux/actions/actionTypes'
import createAction from '../../../redux/actions/createAction'

import fetchStatusSelectors from '../../../redux/selectors/fetchStatuses/customers/customerUserPermissions'


const mapStateToProps = (state, ownProps) => {
  const { userObject } = ownProps
  const userId = userObject.id
  const { getFetchStatuses, getFetchFailureErrorDetails } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(state, userId)
  const errorDetails = getFetchFailureErrorDetails(state, userId)
  return {
    // needed by WaitForApiFetch
    ...fetchStatuses,
    errorDetails,
    loadingMessage: `Fetching ${userObject.firstName} ${userObject.lastName}'s Permissions...`,
    failureHeaderMessage: `Failed to Load ${userObject.firstName} ${userObject.lastName}'s Permissions`,
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    userObject,
  } = ownProps
  const userId = userObject.id
  const resubmitFunc = () => (
    dispatch(createAction(
      FETCH_CUSTOMER_USER_PERMISSIONS,
      {
        userId,
      },
    ))
  )

  return {
    resubmitFunc,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaitForApiFetch(props => <ActualIndividualUser {...props} />))
