import { channel } from 'redux-saga'
import { take, takeEvery, call, fork, put } from 'redux-saga/effects'

import {
  START_FETCH_CUSTOMER_REPRESENTATIVE_QUEUE_THREAD,
  FETCH_CUSTOMER_REPRESENTATIVE_INFO,
  FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_QUEUE,
} from '../../../actions/actionTypes'

import { fetchCustomerRepresentative } from './fetchCustomerRepresentative'

import createAction from '../../../actions/createAction'

// https://redux-saga.js.org/docs/advanced/Channels.html#using-channels-to-communicate-between-sagas
function* fetchCustomerRepresentativeQueue() {
  // create a channel to queue incoming requests
  const chan = yield call(channel)

  // create 1 worker 'thread'
  yield fork(fetchCustomerRepresentative, chan)

  const repIdsFetchingWhenAppFirstStarts = []

  while (true) {
    const action = yield take(FETCH_CUSTOMER_REPRESENTATIVE_INFO)

    const { repId, isPrelimInfoFetchRequest } = action.payload

    const shouldWePutThisRepInTheQueue = (
      (!isPrelimInfoFetchRequest)
      ||
      (isPrelimInfoFetchRequest && !repIdsFetchingWhenAppFirstStarts.includes(repId))
    )

    // We need this queue to do two things: 1) avoid fetching duplicate reps
    // when the app is first starting and gathering all preliminary info; 2)
    // always fetch the rep info when the request comes after the app has
    // gathered all preliminary info (this will happen if, for example, a
    // request for a rep fails on the first try during prelim info, in which
    // case a "retry" button will be displayed to the user in the error message
    // within the rep's contact info card). The only way to make both things
    // happen correctly is for each fetch request to send a flag stating whether
    // or not the request is happening during the prelim info gathering phase.
    // You may ask, "Why do we need the flag? Can't we just check the fetch
    // status of this rep to see whether it's in Queued or Fetching or Succeeded
    // state?" No, if we did it that way, the following scenario would happen:
    // The Master customer has a single rep which this queue fetches, and after
    // 0.25 seconds the fetch fails. Then, 6 seconds later when prelim info is
    // fetching for a child (remember that many seconds can go by betwen the
    // prelim info fetching of the master and that of its children), the child's
    // reps get put into the queue. If the child has a rep which is the same as
    // the Master rep, it will be put into this queue and be fetched again,
    // because that rep's fetch status is "failed", not "queued" or "fetching"
    // or "succeeded". Oops, duplicate fetching.
    if (isPrelimInfoFetchRequest && !repIdsFetchingWhenAppFirstStarts.includes(repId)) {
      repIdsFetchingWhenAppFirstStarts.push(repId)
    }

    if (shouldWePutThisRepInTheQueue) {
      // When a rep fetch gets put into the queue, we want to mark that rep as
      // queued to be fetched in the Redux store, that way the UI can render a
      // loading spinner in rep's Contact Info section even before the fetch
      // starts, while the fetch action is sitting in the queue.
      yield put(createAction(FETCH_STATUSES_CUSTOMER_REPRESENTATIVE_QUEUE, { target: [repId] }))
      yield put(chan, action)
    }
  }
}

// CODE_COMMENTS_11
export default [
  // The function is 'take' rather than 'takeEvery' because this saga should be
  // called once and only once: at the beginning of the Fetch Preliminary Info
  // saga. If this accidentally gets called multiple times, multiple API fetches
  // will happen for one "fetch single rep" action, wasting backend resources.
  // We ensure a single possible call by using 'take'.
  [takeEvery, START_FETCH_CUSTOMER_REPRESENTATIVE_QUEUE_THREAD, fetchCustomerRepresentativeQueue],
]
