import flow_ from 'lodash/fp/flow'
import valuesFp_ from 'lodash/fp/values'
import filterFp_ from 'lodash/fp/filter'
import mapFp_ from 'lodash/fp/map'
import sortByFp_ from 'lodash/fp/sortBy'

import {
  CUSTOMER_TYPES_MASTER,
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_CONTACTS as defaultSlice,
  REDUCER_NAMES_ENTITIES_CUSTOMER_CONTACT_LINKS,
} from '../../constants'

import {
  withPropNormalized,
  withPropOrNormalized,
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/

// usage: const firstName = getProp(state, contactId, 'firstName')
export const getProp = withPropNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const firstName = getPropOr(state, contactId, 'firstName', 'first name not found')
// CODE_COMMENTS_127
export const getPropOr = withPropOrNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: you can use this to get the slice for all contacts or for a single
// contact:
// const entireSliceForAllContacts = getEntireSlice(state)
// const entireSliceForSingleCollarPlate = getEntireSlice(state, contactId)
export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)

// const entireCustomerContactLinksSlice = getEntireLinksSlice(state)
// You could technically get a single link if you wanted to, but that's
// impractical since you'd have to construct the link id string:
// const singleCustomerContactLinksSlice = getEntireLinksSlice(state, '<customerId>--__--<contactId>')
export const getEntireLinksSlice = getEntireSliceCommon(
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_CUSTOMER_CONTACT_LINKS,
)

/*
 * *****************************************************************************
 * More specific selectors which don't use state but which rather take the
 * entire contacts slice and customerContactLinks slice as arguments. The idea
 * is that, in your mapStateToProps functions, you only get these entire slices,
 * not filtered by customer. Then, in the connected component, you call these
 * more specific selectors to filter on the current customer. That way, the
 * connected component only re-renders when contacts are added or removed from
 * the Redux store, not every single time some unrelated piece of the Redux
 * store changes.
 * *****************************************************************************
*/

// Returns an array of contact objects ordered alphabetically by lastName

// Returns an array of mashup objects of contactObject and
// customerContactLinkObject from the backend; i.e. they're contactObjects with
// their customerContactLinkObject props added ('billingContact',
// 'shippingContact', 'receiveOrderUpdates', etc).
export function getAllContactObjectsOfCustomer({
  customerId,
  entireContactsSlice,
  entireCustomerContactLinksSlice,
  currentlyActiveContactsOnly=true, // CODE_COMMENTS_112
}) {
  const allMashupObjsOfCustomer = flow_(
    valuesFp_, // turn the object of objects into an array of objects
    filterFp_(linkObj => linkObj.customerId === customerId),
    mapFp_(linkObj => createMashupObjFromCustomerContactLinkObj({
      entireContactsSlice,
      linkObj,
    })),
    sortByFp_(obj => obj.lastName),
  )(entireCustomerContactLinksSlice)

  return currentlyActiveContactsOnly
    ? allMashupObjsOfCustomer.filter(o => o.active)
    : allMashupObjsOfCustomer
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

function createMashupObjFromCustomerContactLinkObj({
  entireContactsSlice,
  linkObj,
}) {
  const contactObj = entireContactsSlice[linkObj.contactId]
  return {
    // order is important because both objects contain an 'id' prop, and we want
    // 'id' to map to the address's ID
    ...linkObj,
    ...contactObj,
    customerContactLinkId: linkObj.id,
    // what about contactId? That's a prop of the linkObj
  }
}

export function getAllContactObjectsOfMaster({
  // customerId,
  entireContactsSlice,
  entireCustomerContactLinksSlice,
  currentlyActiveContactsOnly=true, // CODE_COMMENTS_112
}) {
  const allMashupObjsOfCustomer = flow_(
    valuesFp_, // turn the object of objects into an array of objects
    // filterFp_(linkObj => linkObj.customerId === customerId),
    mapFp_(linkObj => createMashupObjFromCustomerContactLinkObj({
      entireContactsSlice,
      linkObj,
    })),
    sortByFp_(obj => obj.lastName),
  )(entireCustomerContactLinksSlice)

  return currentlyActiveContactsOnly
    ? allMashupObjsOfCustomer.filter(o => o.active)
    : allMashupObjsOfCustomer
}

export function getAllContactsOfCustomer(obj) {
  if (obj.customerType === CUSTOMER_TYPES_MASTER) {
    // Unique contacts
    return [...new Map(getAllContactObjectsOfMaster(obj)?.map(i => [i.contactId, i]))?.values() || []]
  }
  return getAllContactObjectsOfCustomer(obj)
}

export function getAllContactObjectsOfContact({
  contactId,
  entireContactsSlice,
  entireCustomerContactLinksSlice,
  currentlyActiveContactsOnly=true, // CODE_COMMENTS_112
}) {
  const allMashupObjsOfCustomer = flow_(
    valuesFp_, // turn the object of objects into an array of objects
    filterFp_(linkObj => linkObj.contactId === contactId),
    mapFp_(linkObj => createMashupObjFromCustomerContactLinkObj({
      entireContactsSlice,
      linkObj,
    })),
    sortByFp_(obj => obj.lastName),
  )(entireCustomerContactLinksSlice)

  return currentlyActiveContactsOnly
    ? allMashupObjsOfCustomer.filter(o => o.active)
    : allMashupObjsOfCustomer
}
