import historyReducerCreator from './historyReducerCreator'

import {
  SAVE_NO_MOVEMENTS_HISTORY,
  SAVE_NO_MOVEMENTS_HISTORY_ITEM,
  DELETE_NO_MOVEMENTS_HISTORY_ITEM,
} from '../../actions/actionTypes'


export default historyReducerCreator({
  saveHistoryOfSingleCustomerActionType: SAVE_NO_MOVEMENTS_HISTORY,
  saveSingleHistoryItemActionType: SAVE_NO_MOVEMENTS_HISTORY_ITEM,
  deleteSingleHistoryItemActionType: DELETE_NO_MOVEMENTS_HISTORY_ITEM,
})
