/* eslint-disable no-continue */

import { call, put, select, takeEvery } from 'redux-saga/effects'
import { delay } from 'redux-saga'

import {
  getProp as getAuthenticationProp,
} from '../../selectors/authentication'
import {
  getAreWeCurrentlyInMaintenanceMode,
} from '../../selectors/maintenanceMode'

import {
  LOGOUT,
  START_KEEP_USER_LOGGED_IN_THREAD_ONCE_WE_KNOW_WE_HAVE_AN_UNEXPIRED_REFRESH_TOKEN,
} from '../../actions/actionTypes'
import createAction from '../../actions/createAction'

import {
  fetchNewUserToken,
  FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED,
  FETCH_NEW_USER_TOKEN_RESULT_SITE_UNDER_MAINTENANCE,
} from './util/fetchNewUserToken'

import {
  getIsUserTokenExpired,
  getIsUserTokenWithinXMinutesOfExpiringButNotExpired,
} from '../../../utils'


// Wait, shouldn't this be a cancelable saga which gets canceled on logout or
// authentication failure? Yes, but instead of using the cancelableForkedSaga()
// function here, we let our main rootSaga() wrap this with a
// transformSagasIntoCancellableSagas() function.
function* keepUserLoggedInOnceWeKnowWeHaveAnUnexpiredRefreshToken() {
  while (true) {
    // Pause this thread while we're in maintenance mode (CODE_COMMENTS_238)
    const areWeCurrentlyInMaintenanceMode = yield select(getAreWeCurrentlyInMaintenanceMode)
    if (areWeCurrentlyInMaintenanceMode) {
      yield call(delay, 20*1000) // wait several seconds
      continue
    }

    const decodedUserToken = yield select(getAuthenticationProp, 'decodedUserToken')
    const isUserTokenExpired = getIsUserTokenExpired(decodedUserToken.exp)

    if (isUserTokenExpired) {
      // if the user token is expired, try to refresh it (we can do this? Yep,
      // see CODE_COMMENTS_209)
      const result = yield call(fetchNewUserToken)
      if (result === FETCH_NEW_USER_TOKEN_RESULT_SITE_UNDER_MAINTENANCE) {
        continue
      } else if (result !== FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED) {
        yield put(createAction(LOGOUT))
        return
      }
    }

    yield call(delay, 20*1000) // wait several seconds
    if (getIsUserTokenWithinXMinutesOfExpiringButNotExpired(decodedUserToken.exp, 5)) {
      const result = yield call(fetchNewUserToken)
      if (result === FETCH_NEW_USER_TOKEN_RESULT_SITE_UNDER_MAINTENANCE) {
        continue
      } else if (result !== FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED) {
        yield put(createAction(LOGOUT))
        return
      }
      // The userToken has been refreshed, so we can simply continue this loop
    }
  // The userToken is not close to expiry, so we can simply continue this loop
  }
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    START_KEEP_USER_LOGGED_IN_THREAD_ONCE_WE_KNOW_WE_HAVE_AN_UNEXPIRED_REFRESH_TOKEN,
    keepUserLoggedInOnceWeKnowWeHaveAnUnexpiredRefreshToken,
  ],
]
