/* eslint-disable dot-notation */

import { connect } from 'react-redux'


import Table from './components/Table'

import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getEntireSlice as getAllInvoiceHistoryObjects,
} from '../../../../../redux/selectors/histories/invoiceHistory'
import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/forms'

import {
  HISTORY_FORM_NAME_INVOICES,
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
} from '../../../../../constants'

import {
  createFormNameForRedux,
} from '../../../../../utils'


const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps
  const customerType = getCustomerProp(state, customerId, 'customerType')
  const currency = getCustomerProp(state, customerId, 'currency')
  const formName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_INVOICES,
    customerId,
  })
  const { getFetchStatuses } = fetchStatusSelectors
  const { isFetching, didFetchFail } = getFetchStatuses(state, formName)
  const invoicesObject = getAllInvoiceHistoryObjects(state, customerId)

  return {
    customerType,
    currency,
    invoicesObject,
    areAnyItemsEditable: false,
    getIsindividualItemEditable,
    isFormSubmitting: isFetching,
    hasFormSubmitFailed: didFetchFail,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
    ...ownProps,
  }
}

export default connect(
  mapStateToProps,
)(Table)


function getIsindividualItemEditable() { return false }
