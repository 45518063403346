import React from 'react'
import { Message } from 'semantic-ui-react'


import {
  getAllConbrwCustomerObjsBrewerHasCustomerLevelPermissionsToReportShipmentsFor,
} from '../../../../../redux/selectors/rewrite/permissionsAtTheCustomerLevel'


export default ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
}) => {
  const conbrwCustomerObjsToList =
    getAllConbrwCustomerObjsBrewerHasCustomerLevelPermissionsToReportShipmentsFor({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      doesNotHavePermissionsRatherThanDoes: true,
    })

  const namesOfConbrwsResponsibleForReportingOutboundShipments = conbrwCustomerObjsToList.map(o => o.name)

  return (
    namesOfConbrwsResponsibleForReportingOutboundShipments.map(conbrwName => (
      <Message
        info
        compact
        style={{
          margin: 0,
          padding: '0.5em 1em',
        }}
        key={conbrwName}
      >
        {`${conbrwName} is responsible for reporting outbound shipments of kegs it fills for you.`}
      </Message>
    ))
  )
}
