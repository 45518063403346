/**
 * CODE_COMMENTS_12
 */
import orderBy_ from 'lodash/orderBy'
import isPlainObject_ from 'lodash/isPlainObject'
import values_ from 'lodash/values'
import cloneDeep_ from 'lodash/cloneDeep'
import flatten_ from 'lodash/flatten'
import uniq_ from 'lodash/uniq'
import isArray_ from 'lodash/isArray'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import valuesFp_ from 'lodash/fp/values'
import flattenFp_ from 'lodash/fp/flatten'
import uniqFp_ from 'lodash/fp/uniq'
import keysFp_ from 'lodash/fp/keys'
import filterFp_ from 'lodash/fp/filter'

import {
  withPropNormalized,
  withPropOrNormalized,
  withPropOfAllNormalized,
  withMultiplePropsOfAllNormalized,
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'

import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_ITEM_SKUS as defaultSlice,
  ITEM_SKUS_SKU_TYPE_COMPOSITE,
  ITEM_SKUS_SKU_TYPE_PALLET,
  ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL,
} from '../../../constants'

import {
  sortArrayByTemplateArray,
  isNumberOrStringRepresentationOfNumber,
  isTruthyAndNonEmpty,
} from '../../../utils'


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/


// usage: you can use this to get the slice for all customers or for a single
// customer:
// const entireSliceForAllCustomers = getEntireSlice(state)
// const entireSliceForSingleCustomer = getEntireSlice(state, customerId)
export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const customerType = getProp(state, customerId, 'customerType')
export const getProp = withPropNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const customerType = getPropOr(state, customerId, 'customerType', 'customer type not found')
export const getPropOr = withPropOrNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const arrayOfAllCustomerTypes = getPropOfAll(state, 'customerType')
export const getPropOfAll = withPropOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const idAndCustomerTypeOfAllCustomers = getMultiplePropsOfAll(state, 'id', 'customerType')
// returns:
// [
//   {id: 1234, customerType: MASTER},
//   {id: 1235, customerType: BRW},
//   {id: 1236, customerType: DIST},
// ]
export const getMultiplePropsOfAll = withMultiplePropsOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)


// The same selectors as above except instead of passing the whole Redux state
// in, you pass in the customers slice (the entire slice, all customers)

// usage: const customerType = getPropPassingInEntireCustomersSlice(entireCustomersSlice, customerId, 'customerType')
export const getPropPassingInEntireCustomersSlice = withPropNormalized()


/*
 * *****************************************************************************
 * Misc.
 * *****************************************************************************
*/

export function getItemSkusDefaultSortOrder({
  entireItemSkusSlice,
  returnItemSkuIdsOnly=true, // return just the item SKU IDs or the entire Item SKU ID objects
}) {
  const ordered = orderBy_(
    entireItemSkusSlice,
    [
      // createOrderByFunc('branding', ['MS', 'KC', 'KS', 'KSK', 'IM']),
      createOrderByFunc('containerType', ['L50', 'L30', 'L20', 'HB', 'SB', 'QB', 'G9', 'CCP', 'CPP']),
      // createOrderByFunc('valueType', ['D', 'A', 'G', 'S']),
      // createOrderByFunc('qualityLevel', ['CLEAN', 'SORTED', 'UNSORTED']),
    ],
    [
      // 'asc',
      'asc',
      // 'asc',
      // 'asc',
    ],
  )
  if (returnItemSkuIdsOnly) {
    return ordered.map(o => o.id)
  }
  return ordered
}


function createOrderByFunc(propName, orderArray) {
  return itemSku => {
    const orderNumber = orderArray.indexOf(itemSku[propName])
    if (orderNumber === -1) {
      // if the prop isn't in the passed-in array, put it at the end and sort by
      // alphabetical order with all the others that aren't in the array.
      return itemSku[propName]
    }
    return orderNumber
  }
}


export function getContainerTypeFromItemSkuId({
  entireItemSkusSlice,
  itemSkuId,
}) {
  return entireItemSkusSlice?.[itemSkuId]?.containerType
}

export function getItemSkuFromItemSkuSlice({
  entireItemSkusSlice,
  itemSkuId,
}) {
  return entireItemSkusSlice?.[itemSkuId] || {}
}


// Pass in e.g. an array of kegOrder objects:
// [
//   {
//     orderLineItemObjects: [
//       { itemSkuId: 'HB-MS-D-U', orderType: 'REGULAR', quantity: 15 },
//       { itemSkuId: 'SB-MS-D-U', orderType: 'REGULAR', quantity: 15 },
//       { itemSkuId: 'QB-MS-D-U', orderType: 'REGULAR', quantity: 15 },
//     ],
//     ...
//   },
//   {
//     orderLineItemObjects: [
//       { itemSkuId: 'HB-MS-D-U', orderType: 'REGULAR', quantity: 15 },
//       { itemSkuId: 'CBI-PP', orderType: 'REGULAR', quantity: 15 },
//     ],
//     ...
//   },
//   ...
// ]
//
// and this function returns an array of unique itemSkuIds:
//
// [
//   'HB-MS-D-U',
//   'SB-MS-D-U',
//   'QB-MS-D-U',
//   'CBI-PP'
// ]
export function getAllUniqueItemSkuIdsInLineItemsPropOfObjects({
  entireItemSkusSlice,
  // an array (or id-keyed object) of multiple inventoryReportObjs or multiple
  // kegOrderObjs
  objs,
  propName, // e.g. 'orderLineItemObjects' or 'lineItems'
}) {
  const arrOfObjs = isPlainObject_(objs)
    ? values_(objs)
    : objs
  return flow_(
    mapFp_(o => o[propName]),
    flattenFp_,
    mapFp_(o => o.itemSkuId),
    uniqFp_,
    a => (
      sortArrayByTemplateArray(
        a,
        getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
      )
    ),
  )(arrOfObjs)
}


// const oldArr = [
//   { itemSkuId: 'HB-MS-D-U', inventoryType: 'TTL_IN', quantity: 1 },
//   { itemSkuId: 'HB-MS-D-U', inventoryType: 'OSW', quantity: 1 },
// ]
// const newArr = [
//   { itemSkuId: 'HB-MS-D-U', inventoryType: 'TTL_IN', quantity: 999 },
//   { itemSkuId: 'QB-MS-D-U', inventoryType: 'OSW', quantity: 999 },
// ]
// ->
// [
//   { itemSkuId: 'HB-MS-D-U', inventoryType: 'TTL_IN', quantity: 999 },
//   { itemSkuId: 'HB-MS-D-U', inventoryType: 'OSW', quantity: 1 },
//   { itemSkuId: 'QB-MS-D-U', inventoryType: 'OSW', quantity: 999 }
// ]
//
// This works on all lineItem objects, including inventory reports (whose type
// is "inventoryType" as shown above), keg orders (whose type is "orderType"),
// and shipments (whose type is "linkType"). The one restriction is that the
// types in oldArray and newArrayOrIndividualObj must be the same (which, why
// wouldn't they be? You wouldn't want to merge an inventory report's lineItems
// with a keg order's lineItems).
export function mergeLineItemObjectsArrays(oldArray, newArrayOrIndividualObj) {
  const newArray = isPlainObject_(newArrayOrIndividualObj)
    ? [newArrayOrIndividualObj]
    : newArrayOrIndividualObj
  if (oldArray.length === 0) {
    return newArray
  }
  if (newArray.length === 0) {
    return oldArray
  }
  const typeOldArray = determineTypeKeynameOfLineItems({ lineItems: oldArray })
  const typeNewArray = determineTypeKeynameOfLineItems({ lineItems: newArray })
  if (typeOldArray !== typeNewArray) {
    throw new Error(`oldArray's type ${typeOldArray} does not match newArray's type ${typeNewArray}`)
  }
  const toReturn = cloneDeep_(oldArray)
  newArray.forEach(newObj => {
    const oldObj = toReturn.find(o => (
      o.itemSkuId === newObj.itemSkuId
      && o[typeNewArray] === newObj[typeNewArray]
    ))
    if (oldObj) {
      oldObj.quantity = newObj.quantity
    } else {
      toReturn.push(newObj)
    }
  })
  return toReturn
}


// returns null if no such itemSkuId exists
export function getItemSkuIdQtyFromLineItem({
  // the actual lineItems array, e.g.
  // inventoryReportObj.inventoryLineItemObjects or
  // kegOrderObj.orderLineItemObjects
  lineItems,
  itemSkuId,
  // Each object type that has a ...lineItem... prop (as of April, 2022 this
  // includes keg orders, shipments and inventory reports) has a different key
  // name for its type prop. For example, kegOrders='orderType',
  // shipments='linkType' and inventory reports='inventoryType':

  // kegOrderObj.orderLineItemObjects=[
  //   {
  //     "itemSkuId": "HB-KSK-D-U",
  //     "orderType": "LOCAL",
  //     "quantity": 0
  // },
  //   ...
  // ]
  // shipmentObj.lineItems=[
  //   {
  //     "itemSkuId": "HB-MS-D-U",
  //     "quantity": 0,
  //     "linkType": "ACKED"
  // },
  //   ...
  // ]
  // inventoryReportObj.inventoryLineItemObjects=[
  //   {
  //     "itemSkuId": "HB-MS-D-U",
  //     "inventoryType": "TTL_IN",
  //     "quantity": 0
  //   },
  //   ...
  // ]

  // There's no need to tell this function the type keyname of the lineItems
  // passed in, it will discover that on its own. All you need to do is pass in
  // the type value you want to filter on, e.g. type='LOCAL' or type='TTL_IN'.

  // The type argument is optional because sometimes you want to match on both
  // the itemSkuId and the type, but sometimes you only want to match on the
  // itemSkuId. For example, Inventory Report Objects will contain at most one
  // itemSkuId=ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG ('UNK-FK') item. When displaying
  // the full details of inventory reports, all we care about is getting the
  // quantity of this item, we don't care what its inventoryType is set to.
  type,
}) {
  if (!isTruthyAndNonEmpty(lineItems)) { return null }
  let itemSkuObj
  if (type) {
    const typeKeyname = determineTypeKeynameOfLineItems({ lineItems })
    if (lineItems.length === 0) {
      throw new Error('The lineItems array passed in is empty, so no type keyname can be determined')
    }
    itemSkuObj = lineItems.find(o => (
      o[typeKeyname] === type
      && o.itemSkuId === itemSkuId
    ))
  } else {
    itemSkuObj = lineItems.find(o => o.itemSkuId === itemSkuId)
  }
  if (!itemSkuObj) { return null }
  if (isNumberOrStringRepresentationOfNumber(itemSkuObj.quantity)) {
    return Number(itemSkuObj.quantity)
  }
  return itemSkuObj.quantity
}


function determineTypeKeynameOfLineItems({
  lineItems,
}) {
  return determineTypeKeynameOfSingleLineItem({
    // The assumption here is that every object in the lineItems array has the
    // exact same keys.
    lineItem: lineItems[0],
  })
}


function determineTypeKeynameOfSingleLineItem({
  lineItem,
}) {
  const possibilities = flow_(
    keysFp_,
    filterFp_(s => s !== 'itemSkuId' && s !== 'quantity'),
    filterFp_(s => s.toLowerCase().includes('type')),
  )(lineItem)
  if (possibilities.length === 1) {
    return possibilities[0]
  }
  if (possibilities.length === 0) {
    throw new Error(`This lineItem object has no 'type' key candidate (i.e. no 'orderType' key, 'linkType' key or 'inventoryType' key): ${lineItem}`)
  }
  // What if a new prop gets added to lineItem objects in the future called
  // typefaceOfKegLabel or some other string that contains 'type' but has
  // nothing to do with what this function is targetting? In such a case,
  // possibilities will have multiple items, so filter to those that _end_ with
  // the substring 'type', not just those that include 'type'.
  const possibilitiesEndingWithType = possibilities.filter(s => s.endsWith('type'))
  if (possibilitiesEndingWithType.length === 1) {
    return possibilitiesEndingWithType[0]
  }
  if (possibilitiesEndingWithType.length > 1) {
    const morePrecisePossibilities = possibilities.filter(s => (
      [
        'orderType',
        'linkType',
        'inventoryType',
      ].map(s_ => s_.toLowerCase()).includes(s.toLowerCase())
    ))
    if (morePrecisePossibilities.length === 0) {
      // we've done all we can to whittle the possibilities down to 1, just return
      // a random value from possibilitiesEndingWithType
      return possibilitiesEndingWithType[0]
    }
  }
  // possibilitiesEndingWithType.length must be 0, so we've done all we can to
  // whittle the possibilities down to 1, just return a random value from
  // possibilities
  return possibilities[0]
}

export function filterItemSkuIdsForQualityLevel({
  entireItemSkusSlice,
  itemSkuIds,
  filterQualityLevel,
}) {
  return itemSkuIds.filter(itemSkuId => {
    const { qualityLevel } = getItemSkuFromItemSkuSlice({
      entireItemSkusSlice,
      itemSkuId,
    })
    return qualityLevel?.toUpperCase() === filterQualityLevel
  })
}

export function getContainerTypeQtyFromLineItem({
  lineItems,
  containerType,
  entireItemSkusSlice,
  // type, not required
}) {
  if (!isTruthyAndNonEmpty(lineItems)) { return null }
  const sumOfQuantity = lineItems.filter(o => entireItemSkusSlice?.[o?.itemSkuId]?.containerType === containerType && !o.inventoryType?.startsWith('TTL'))?.reduce(
    (previousValue, { quantity = 0 }) => previousValue + quantity,
    0,
  )
  if (!sumOfQuantity) { return null }
  if (isNumberOrStringRepresentationOfNumber(sumOfQuantity)) {
    return Number(sumOfQuantity)
  }
  return sumOfQuantity
}

// Returns an array of Item SKU IDs with IDs from itemSkuIds replaced with
// versions that match filterQualityLevel if possible. This function does not
// replace/omit any IDs that don't have a version that matches
// filterQualityLevel; it does, however, deduplicate the array before returning,
// so the returned array may not be the same length as the passed-in itemSkuIds
// array.
export function mapItemSkuIdsForQualityLevel({
  entireItemSkusSlice,
  itemSkuIds,
  filterQualityLevel,
}) {
  const replacedWithVersionsThatMatchQualityLevel = itemSkuIds.map(itemSkuId => {
    const versionsThatMatchQualityLevel = flow_(
      valuesFp_,
      filterFp_(o => (
        o.qualityLevel === filterQualityLevel
        && o.businessUnit === entireItemSkusSlice[itemSkuId].businessUnit
        && o.containerType === entireItemSkusSlice[itemSkuId].containerType
        && o.branding === entireItemSkusSlice[itemSkuId].branding
        && o.valueType === entireItemSkusSlice[itemSkuId].valueType
        && o.skuType === entireItemSkusSlice[itemSkuId].skuType
      )),
      mapFp_(o => o.id),
      uniqFp_,
    )(entireItemSkusSlice)
    if (versionsThatMatchQualityLevel.length === 1) {
      // Choose the first one (but there should never be more than one)
      return versionsThatMatchQualityLevel[0]
    }
    if (versionsThatMatchQualityLevel.length > 1) {
      const sameProductShape = versionsThatMatchQualityLevel.filter(
        obj => entireItemSkusSlice[obj].productShape === entireItemSkusSlice[itemSkuId].productShape,
      )
      if (sameProductShape.length === 1) {
        return sameProductShape[0]
      }
      return versionsThatMatchQualityLevel[1]
    }
    return itemSkuId
  })
  return uniq_(replacedWithVersionsThatMatchQualityLevel)
}


// A recursive function that returns a deduped array of all non-composite
// children itemSkuIds of a composite itemSku. The recursion allows composite
// ItemSkus to have children which are also composite (and those children can
// have composite children, etc). Composite children do not exist as of the
// writing of this function in Sep '22, so this recursion is simply
// future-proofing.
export function getNonCompositeSkuIdsOfCompositeSku({
  entireItemSkusSlice,
  // doesn't need to be a composite type (will simply return [itemSkuId] if not)
  itemSkuId,
  // Optional single string itemSkuType or an array of such strings, e.g.
  // [ITEM_SKUS_SKU_TYPE_KEG]
  filterByItemSkuTypes,
}) {
  const itemSku = entireItemSkusSlice[itemSkuId]
  if (itemSku.skuType !== ITEM_SKUS_SKU_TYPE_COMPOSITE) {
    if (filterByItemSkuTypes) {
      const filterByItemSkuTypesArray = isArray_(filterByItemSkuTypes)
        ? filterByItemSkuTypes
        : [filterByItemSkuTypes]
      return [itemSku].filter(o => filterByItemSkuTypesArray.includes(o.skuType)).map(o => o.id)
    }
    return [itemSkuId]
  }
  return uniq_(flatten_(
    itemSku.childItemSkuObjects.map(childObj => getNonCompositeSkuIdsOfCompositeSku({
      entireItemSkusSlice,
      itemSkuId: childObj.id,
      filterByItemSkuTypes,
    })),
  ))
}

export function getNonPalletSkus({
  entireItemSkusSlice,
  itemSkuIds,
}) {
  return flow_(
    valuesFp_,
    flattenFp_,
    filterFp_(itemSkuId => entireItemSkusSlice?.[itemSkuId]?.skuType !== ITEM_SKUS_SKU_TYPE_PALLET),
  )(itemSkuIds)
}

export function getNonPalletSkusForUKE({
   entireItemSkusSlice,
   itemSkuIdList,
   shippedSkuSet,
 }) {
  return flow_(
      valuesFp_,
      flattenFp_,
      filterFp_(itemSkuId => entireItemSkusSlice?.[itemSkuId]?.skuType !== ITEM_SKUS_SKU_TYPE_PALLET
      && entireItemSkusSlice?.[itemSkuId]?.qualityLevel === ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL
      && entireItemSkusSlice?.[itemSkuId]?.skuType !== ITEM_SKUS_SKU_TYPE_COMPOSITE
      && !shippedSkuSet.has(itemSkuId)),
  )(itemSkuIdList)
}

export function getNonPalletSkusOfAllQuality({
 entireItemSkusSlice,
 itemSkuIdList,
 shippedSkuSet,
}) {
  return flow_(
      valuesFp_,
      flattenFp_,
      filterFp_(itemSkuId => entireItemSkusSlice?.[itemSkuId]?.skuType !== ITEM_SKUS_SKU_TYPE_PALLET
          && entireItemSkusSlice?.[itemSkuId]?.skuType !== ITEM_SKUS_SKU_TYPE_COMPOSITE
          && !shippedSkuSet.has(itemSkuId)),
  )(itemSkuIdList)
}

export function getItemSkusDefaultSortOrderBasedOnContainerType({
  entireItemSkusSlice,
  returnItemSkuIdsOnly=true, // return just the item SKU IDs or the entire Item SKU ID objects
}) {
  const ordered = orderBy_(
    entireItemSkusSlice,
    [
      createOrderByFunc('containerType', ['L50', 'L30', 'L20', 'HB', 'SB', 'QB', 'CPP']),
      createOrderByFunc('qualityLevel', ['CLEAN', 'SORTED', 'UNSORTED']),
    ],
    [
      'asc',
      'asc',
    ],
  )
  if (returnItemSkuIdsOnly) {
    return ordered.map(o => o.id)
  }
  return ordered
}

export function getItemSkuIdsOfAllQualityLevel({
  entireItemSkusSlice,
  itemSkuIds,
  ignoreQualityLevel,
}) {
  const replacedWithVersionsThatMatchQualityLevel = itemSkuIds.reduce((acc, itemSkuId) => {
    const versionsThatMatchQualityLevel = flow_(
      valuesFp_,
      filterFp_(o => (
        (ignoreQualityLevel ? !ignoreQualityLevel.includes(o.qualityLevel) : o.qualityLevel)
        && o.businessUnit === entireItemSkusSlice[itemSkuId].businessUnit
        && o.containerType === entireItemSkusSlice[itemSkuId].containerType
        && o.branding === entireItemSkusSlice[itemSkuId].branding
        && o.valueType === entireItemSkusSlice[itemSkuId].valueType
        && o.skuType === entireItemSkusSlice[itemSkuId].skuType
      )),
      mapFp_(o => o.id),
      uniqFp_,
    )(entireItemSkusSlice)
    return [...acc, ...versionsThatMatchQualityLevel]
  }, [])
  return uniq_(replacedWithVersionsThatMatchQualityLevel)
}
