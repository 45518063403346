import { call, takeEvery } from 'redux-saga/effects'


import {
  privateFetch,
  publicFetch,
} from '../../fetch'
import {
  FETCH_REPORT_INVENTORY,
  FETCH_EDIT_INVENTORY_REPORT,
} from '../../../actions/actionTypes'

import {
  createApiRequestBody,
  createHttpHeadersForReportInventoryCall,
  saveInventoryReportObjectsToReduxStoreIfTheyMeetCriteriaOfHistoryForm,
} from './util'

import {
  API_URL_PATH_INVENTORY_REPORTS_BULK,
  API_URL_PATH_INVENTORY_REPORTS_BULK_DIST_INVENTORY_NO_LOGIN_REQUIRED,
} from '../../../../constants'

import {
  generalDoFailure,
} from '../../util/fetchFailure'


function* fetchReportOrEditInventory(action) {
  const {
    customerId,
    isEditForm,
    setIsSubmitting,
    isDistReportInventoryNoLoginRequired,
    isDistReportConstellationNoLoginRequired,
    token: tokenForDistReportInventoryNoLoginRequired,
  } = action.payload
  setIsSubmitting(true)
  const apiRequestBody = yield call(createApiRequestBody, action.payload)
  const headers = yield call(
    createHttpHeadersForReportInventoryCall,
    {
      customerId,
      apiRequestBody,
      isDistReportInventoryNoLoginRequired,
      isDistReportConstellationNoLoginRequired,
      tokenForDistReportInventoryNoLoginRequired,
    },
  )

  let response
  try {
    response = yield call(
      (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) ? publicFetch : privateFetch,
      {
        path: (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
          ? API_URL_PATH_INVENTORY_REPORTS_BULK_DIST_INVENTORY_NO_LOGIN_REQUIRED
          : API_URL_PATH_INVENTORY_REPORTS_BULK,
        data: apiRequestBody,
        method: isEditForm ? 'PUT' : 'POST',
        headers,
      },
    )
  } catch (error) {
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { response, ...action.payload })
}


function* doSuccess({
  response,
  customerId,
  setIsSubmitting,
  setDidSubmitSucceed,
  setDidSubmitFail,
}) {
  yield call(saveInventoryReportObjectsToReduxStoreIfTheyMeetCriteriaOfHistoryForm, customerId, response.data)
  setIsSubmitting(false)
  setDidSubmitSucceed(true)
  setDidSubmitFail(false)
}


function* doFailure({
  error,
  setIsSubmitting,
  setDidSubmitSucceed,
  setDidSubmitFail,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  setIsSubmitting(false)
  setDidSubmitSucceed(false)
  setDidSubmitFail(true)
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REPORT_INVENTORY, fetchReportOrEditInventory],
  [takeEvery, FETCH_EDIT_INVENTORY_REPORT, fetchReportOrEditInventory],
]
