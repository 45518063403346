import flow_ from 'lodash/fp/flow'
import map_ from 'lodash/fp/map'
import values_ from 'lodash/fp/values'
import filter_ from 'lodash/fp/filter'
import sortBy_ from 'lodash/fp/sortBy'

import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_COLLAR_PLATES as defaultSlice,
  REDUCER_NAMES_ENTITIES_CUSTOMER_COLLAR_PLATE_LINKS,
} from '../../constants'

import {
  withPropNormalized,
  withPropOrNormalized,
  withPropOfAllNormalized,
  withMultiplePropsOfAllNormalized,
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/


// usage: const flavor = getProp(state, collarPlateId, 'flavor')
export const getProp = withPropNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const flavor = getPropOr(state, collarPlateId, 'flavor', 'flavor not found')
// CODE_COMMENTS_127
export const getPropOr = withPropOrNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const allCollarPlateIds = getProp(state, 'id')
export const getPropOfAll = withPropOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const idAndFlavorOfAllCollarPlates = getMultiplePropsOfAll(state, 'id', 'flavor')
// returns:
// [
//   {id: 1234, flavor: 'white rascal'},
//   {id: 1235, flavor: 'rumpkin'},
//   {id: 1236, flavor: 'pale alesya'},
// ]
export const getMultiplePropsOfAll = withMultiplePropsOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: you can use this to get the slice for all collar plates or for a single
// collar plate:
// const entireSliceForAllCollarPlates = getEntireSlice(state)
// const entireSliceForSingleCollarPlate = getEntireSlice(state, collarPlateId)
export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)


/*
 * *****************************************************************************
 * More specific selectors
 * *****************************************************************************
*/

export const getTotalNumCollarPlatesOnFileForCustomer = ({
  state,
  customerId,
  currentlyActiveCollarPlatesOnly, // CODE_COMMENTS_112
}) => (
  getAllCollarPlateIdsOfCustomer({ state, customerId, currentlyActiveCollarPlatesOnly }).length
)


// returns an array of collar plate objects, ordered alphabetically by 'flavor'.
export const getAllCollarPlateObjectsOfCustomer = ({
  state,
  customerId,
  currentlyActiveCollarPlatesOnly, // CODE_COMMENTS_112
}) => {
  const allCollarPlateIdsOfCustomer = getAllCollarPlateIdsOfCustomer({
    state,
    customerId,
    currentlyActiveCollarPlatesOnly,
  })
  return flow_(
    map_(id => getEntireSlice(state, id)),
    sortBy_(obj => obj.flavor),
  )(allCollarPlateIdsOfCustomer)
}

export const getMultipleCollarPlateObjectsById = ({
  state,
  collarPlateIds, // array
}) => (
  collarPlateIds.map(collarPlateId => getEntireSlice(
    state,
    collarPlateId,
  ))
)

/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

function getEntireCustomerCollarPlateLinksSlice(state) {
  return getEntireSliceCommon(
    REDUCER_NAMES_ENTITIES,
    REDUCER_NAMES_ENTITIES_CUSTOMER_COLLAR_PLATE_LINKS,
  )(state)
}


function getAllCollarPlateIdsOfCustomer({
  state,
  customerId,
  currentlyActiveCollarPlatesOnly, // CODE_COMMENTS_112
}) {
  const links = getEntireCustomerCollarPlateLinksSlice(state)
  const collarPlateIdsOfCustomer = flow_(
    values_, // turn the object of objects into an array of objects
    filter_(linkObj => linkObj.customerId === customerId),
    map_(linkObj => linkObj.collarPlateId),
  )(links)

  if (!currentlyActiveCollarPlatesOnly) { return collarPlateIdsOfCustomer }

  const propsPertinantToCurrentlyActive = getMultiplePropsOfAll(state, customerId, 'id', 'hidden', 'dateApproved')
  return propsPertinantToCurrentlyActive.filter(props => (
    collarPlateIdsOfCustomer.includes(props.id) &&
    // a collar plate is currently active if its 'hidden' prop is false and its
    // dateApproved prop is not null (the dateApproved prop will never be a date
    // in the future, so we don't have to worry about checking what the date
    // actually is)
    !props.hidden &&
    props.dateApproved
  )).map(props => props.id)
}
