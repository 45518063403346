import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import ForgotPasswordEnterEmailForm from '../subFeatures/ForgotPasswordEnterEmailForm'
import ForgotPasswordResetPasswordForm from '../subFeatures/ForgotPasswordResetPasswordForm'
import SuccessPage from '../../shared/SuccessPage'

export default ({
  hasEnterEmailFormFetchSucceeded,
  hasResetPwFormFetchSucceeded,
}) => {
  const [email, setEmail] = useState(null) // CODE_COMMENTS_251
  const [password, setPassword] = useState(null) // CODE_COMMENTS_251
  const { t: translate } = useTranslation('common')
  if (!hasEnterEmailFormFetchSucceeded) {
    return (
      <ForgotPasswordEnterEmailForm
        setEmail={setEmail}
      />
    )
  }
  if (!hasResetPwFormFetchSucceeded) {
    return (
      <ForgotPasswordResetPasswordForm
        email={email}
        setPassword={setPassword}
      />
    )
  }
  return (
    <SuccessPage
      message={translate('Your password has been reset.')}
      email={email}
      password={password}
    />
  )
}
