import { connect } from 'react-redux'


import OrderIdLink from '../components/OrderIdLink'

import {
  getEntireSlice as getEntireOrdersOfInboundUnacknowledgedShipmentsSlice,
} from '../../../redux/selectors/ordersOfInboundUnacknowledgedShipments'
import fetchStatusSelectors from '../../../redux/selectors/fetchStatuses/customers/ordersOfInboundUnacknowledgedShipments'

import {
  getEntireSlice as getEntireCustomersSlice,
  getProp as getCustomerProp,
} from '../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../redux/selectors/rewrite/itemSkus'


/*
 * *****************************************************************************
 * mapStateToProps
 * *****************************************************************************
*/

const mapStateToProps = (state, props) => {
  const {
    customerId,
    shipment,
  } = props
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const { getFetchStatuses } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(state, customerId)
  // This will be undefined if the order hasn't yet been fetched. Remember that
  // the order could exist in Redux even if fetchStatuses.didFetchFail is true.
  // This would would happen if, for example, the order gets fetched when the
  // form first renders, then the user acknowledges a shipment but not _this_
  // shipment (remember that users can acknowledge some but not all shipments),
  // then they click "Fill out the form again", which re-fetches all orders of
  // inbound unacknowledged shipments, and this second fetch fails.
  const order = getEntireOrdersOfInboundUnacknowledgedShipmentsSlice(
    state,
    customerId,
    shipment.orderId,
  )

  return {
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    configuredItemSkuIds,
    ...fetchStatuses,
    order,
  }
}


export default connect(
  mapStateToProps,
)(OrderIdLink)
