import React from 'react'
import { useTranslation } from 'react-i18next'

const REPORT_DEFECTIVE_KEG_LINK = 'https://docs.google.com/forms/d/1S0Qm07KoVCLlLjeCNrBheE2Pn-YWMPbMZHWW8a4lIOg/viewform?edit_requested=true#responses'
export default () => {
  const { t: translate }= useTranslation('pagelabels')
 return (
   <a
    href={REPORT_DEFECTIVE_KEG_LINK}
    target="_blank"
    rel="noopener noreferrer"
    style={{ paddingLeft: '20px' }}
   >
     {translate('reportShipments.Report Defective Keg')}
   </a>
  )
}
