/* eslint-disable import/prefer-default-export */
import isEqual_ from 'lodash/isEqual'
import isPlainObject_ from 'lodash/isPlainObject'
import get_ from 'lodash/get'


/**
 * Adds special css classes to the <Field> depending on the type of the Field's
 * `component` prop. For instnace, if the field is of a dropdown type, we need
 * to add a specific CSS class that slightly decreases its height in order to
 * make it the exact same height as other components in the row.
 */
export const createFieldClassName = fieldDefinition => {
  // eslint-disable-next-line no-param-reassign
  let toAddToClassName

  // if the component is a dropdown/select type
  if (isDropdownComponent(fieldDefinition)) {
    toAddToClassName = 'dropdown-no-overflow-text'
  }

  return [
    // we use lodash's 'get' here so that if the component doesn't have either a
    // fieldProps property or a className property, a "Cannot read property of
    // undefined" error is not thrown.
    get_(fieldDefinition, 'fieldProps.className'),
    toAddToClassName,
  ].join(' ').trim()
}


/**
 * Does so by checking to see if the field has an "options" prop (which all
 * semantic-ui-react dropdown components do), that the options prop is an array
 * of plain objects, and that each object has three keys: "key", "value", and
 * "text".
 */
export function isDropdownComponent(fieldDefinition) {
  // if there's no fieldProps.options nested path, it's not a dropdown
  if (!get_(fieldDefinition, 'fieldProps.options')) { return false }
  if (
    Array.isArray(fieldDefinition.fieldProps.options) &&
    fieldDefinition.fieldProps.options.every(item => isPlainObject_(item)) &&
    fieldDefinition.fieldProps.options.every(item => (isEqual_(
      Object.keys(item).sort(),
      ['key', 'value', 'text'].sort(),
    )))
  ) {
    return true
  }
  return false
}
