import React from 'react'

import FetchingContactCard from './FetchingContactCard'
import PopulatedContactCard from '../containers/PopulatedContactCard'
import FetchFailedContactCard from '../containers/FetchFailedContactCard'

export default ({
  repType,
  repId,
  hasFetchBeenAttempted,
  isQueued,
  isFetching,
  didFetchSucceed,
  ...rest
}) => {
  if (!hasFetchBeenAttempted || isQueued || isFetching) {
    return (
      <FetchingContactCard
        repType={repType}
      />
    )
  }
  if (didFetchSucceed) {
    return (
      <PopulatedContactCard
        repType={repType}
        repId={repId}
        {...rest}
      />
    )
  }
  return (
    <FetchFailedContactCard
      repType={repType}
      repId={repId}
      {...rest}
    />
  )
}
