import {
  isTruthyAndNonEmpty,
} from '../../../../utils'

/*
 * *****************************************************************************
 * statuses config
 * *****************************************************************************
*/

export const createStatusApiParameterValueForHistoryFetch = (
  statusChosenByUserInHistoryForm,
  statusConfig,
) => {
  const otherStatusesThatHaveBeenRenamedToTheStatusChosenByUserInHistoryForm =
    Object.keys(statusConfig).filter(
      status => statusConfig[status].statusToRenameTo === statusChosenByUserInHistoryForm,
    )

  return isTruthyAndNonEmpty(otherStatusesThatHaveBeenRenamedToTheStatusChosenByUserInHistoryForm)
    ? [
      statusChosenByUserInHistoryForm,
      ...otherStatusesThatHaveBeenRenamedToTheStatusChosenByUserInHistoryForm,
    ].join('_')
    : statusChosenByUserInHistoryForm
}
