import {
  SAVE_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER,
} from '../../actions/actionTypes'

import {
  convertArrayOfObjectsToObjectOfSubObjects,
} from '../../../utils'


const initialState = {
  byCustomerId: {},
}

export default (state=initialState, action) => {
  switch (action.type) {
    case SAVE_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER:
      return {
        ...state,
        byCustomerId: {
          ...state.byCustomerId,
          [action.payload.customerId]: {
            ...state.byCustomerId[action.payload.customerId],
            // CODE_COMMENTS_138
            [action.payload.orderUuid]: convertArrayOfObjectsToObjectOfSubObjects(action.payload.info, 'id'),
          },
        },
      }

    default:
      return state
  }
}
