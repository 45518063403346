import React from 'react'
import { connect } from 'react-redux'

import WaitForApiFetch from '../../../../../../../../common-components/WaitForApiFetch'
import ShipmentsTable from './ShipmentsTable'
import fetchStatusSelectors from '../../../../../../../../redux/selectors/fetchStatuses/customers/inboundShipmentsOfSpecificKegOrders'


import {
  FETCH_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER,
} from '../../../../../../../../redux/actions/actionTypes'

import createAction from '../../../../../../../../redux/actions/createAction'

const mapStateToProps = (state, { customerId, orderDetails }) => {
  const { getFetchStatuses, getFetchFailureErrorDetails } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(state, customerId, orderDetails.id)
  const errorDetails = getFetchFailureErrorDetails(state, customerId, orderDetails.id)

  return {
    // props needed by the WaitForApiFetch component
    ...fetchStatuses,
    errorDetails,
    loadingSectionMinHeight: 0,
    loadingMessage: 'Getting Shipments',
    failureHeaderMessage: 'Failed to Load Shipments for This Order',
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { customerId, orderDetails } = ownProps
  const fetchShipmentsOfThisOrder = () => {
    dispatch(createAction(FETCH_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER, { customerId, orderUuid: orderDetails.id }))
  }
  return {
    fetchShipmentsOfThisOrder,
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { customerId, orderDetails } = ownProps
  const { hasFetchBeenAttempted } = stateProps
  const { fetchShipmentsOfThisOrder } = dispatchProps

  if (!hasFetchBeenAttempted) {
    fetchShipmentsOfThisOrder(customerId, orderDetails)
  }

  return {
    ...stateProps,
    ...ownProps,

    // props needed by the WaitForApiFetch component
    resubmitFunc: fetchShipmentsOfThisOrder,
    isShipmentSection: true,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(WaitForApiFetch(props => <ShipmentsTable {...props} />))
