import { connect } from 'react-redux'


import DownloadInventoryHistoryTableAsFile from './components'

import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
} from '../../../../../redux/selectors/rewrite/subsidiaries'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'


import {
  HISTORY_FORM_NAME_INVENTORY,
} from '../../../../../constants'

import {
  createGroupingsOfInventoryReportObjsForHistoryTable,
  optionsObjForConnectFunction,
} from '../util/groupingInventoryReportObjs'

import {
  createFormNameForRedux,
} from '../../../../../utils'


const mapStateToProps = (state, { customerId }) => {
  const customerType = getCustomerProp(state, customerId, 'customerType')
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)
  const subsidiaryId = getCustomerProp(state, customerId, 'subsidiaryId')
  const historyFormName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_INVENTORY,
    customerId,
  })
  const mostRecentlySubmittedFormValues = getMostRecentlySubmittedFormValues(state, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return {} }

  // CODE_COMMENTS_229
  const groupingsOfInventoryReportObjsForHistoryTable = createGroupingsOfInventoryReportObjsForHistoryTable({
    state,
    customerId,
    isThisForDisplayingAnAlreadyCreatedInventoryReport: true,
  })

  return {
    mostRecentlySubmittedFormValues,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    subsidiaryId,
    groupingsOfInventoryReportObjsForHistoryTable,
    customerType,
  }
}


export default connect(
  mapStateToProps,
  undefined,
  undefined,
  optionsObjForConnectFunction,
)(DownloadInventoryHistoryTableAsFile)
