/**
 * CODE_COMMENTS_14
 */
import { put, call, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import unsetFp_ from 'lodash/fp/unset'


import {
  createFetchFailureAction,
  generalDoFailure,
} from './util/fetchFailure'
import createAction from '../actions/createAction'

import {
  validateCurrentUserObject,
} from './util/apiResponseDataValidation/usersAndPermissions/currentUser'

import { publicFetch } from './fetch'
import {
  LOGIN,
  FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
  FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
  FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
  LOGOUT,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE,
  SAVE_CURRENT_USER,
  SAVE_EMPLOYEE_CURRENT_USER,
} from '../actions/actionTypes'
import {
  URL_PATHS,
  API_URL_PATH_LOGIN,
  REDUCER_NAMES_PUBLIC_FORMS_LOGIN,
} from '../../constants'

import {
  getIsUserAnEmployee,
} from '../../utils'

import {
  XhrReplicationError,
} from '../../customErrors'


function* login(action) {
  const { email, password } = action.payload
  yield put(createAction(
    FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
    { target: [REDUCER_NAMES_PUBLIC_FORMS_LOGIN] },
  ))
  const config = {
    path: API_URL_PATH_LOGIN,
    method: 'POST',
    data: { username: email, password },
  }
  let response
  try {
    response = yield call(
      publicFetch,
      config,
    )
  } catch (error) {
    yield call(
      doFailure,
      {
        error,
        wasThisALoginAttempt: true,
      },
    )
    return
  }
  yield call(doSuccess, response)
}


function* logout() {
  // Wait, doesn't logout need to a lot more than just redirect to the login
  // page? Doesn't it at least need to set the isAuthenticated flag to false?
  // No, because elsewhere in the code there's a higher-order function that
  // ensures that __almost every part__ of the store slice gets returned to an
  // initial state.
  yield put(push(URL_PATHS.login.path))
}


function* authenticationFailure(action) {
  yield call(doFailure, { error: action.payload.error })
}


// CODE_COMMENTS_11
const authSagas = [
  [takeEvery, LOGIN, login],
  [takeEvery, LOGOUT, logout],
  [takeEvery, AUTHENTICATION_FAILURE, authenticationFailure],
]
export default authSagas


function* doSuccess(httpResponse) {
  const data = httpResponse.data
  const isEmployee = getIsUserAnEmployee(data)
  const {
    validatedData: currentUserInfo,
    canDataBeSavedToStore,
  } = yield call(
    validateCurrentUserObject,
    {
      httpResponse,
      data,
      isEmployee,
      checkPropsRelatedToAuthentication: true, // CODE_COMMENTS_222
    },
  )
  // CODE_COMMENTS_178
  if (!canDataBeSavedToStore) {
    // CODE_COMMENTS_149
    const error = new XhrReplicationError({
      response: {
        status: 'not a real xhr error',
        statusText: `${httpResponse.config.url} has returned a malformed userObject`,
        data: {},
        headers: {},
      },
      config: httpResponse.config,
    })
    yield call(
      doFailure,
      {
        error,
        wasThisALoginAttempt: true,
        overridingOnlyLogErrorIfFunc: () => false, // CODE_COMMENTS_193
      },
    )
    return
  }

  yield put(createAction(
    isEmployee ? SAVE_EMPLOYEE_CURRENT_USER : SAVE_CURRENT_USER,
    currentUserInfo,
  ))
  yield put(createAction(
    AUTHENTICATION_SUCCESS,
    currentUserInfo,
  ))
  yield put(createAction(
    FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
    { target: [REDUCER_NAMES_PUBLIC_FORMS_LOGIN] },
  ))
}


function* doFailure({
  error,
  wasThisALoginAttempt,
  overridingOnlyLogErrorIfFunc,
}) {
  // Don't include the user's password when logging an error object to a
  // 3rd-party logging service. We don't actually need to do this because our
  // current error logging service, Sentry, filters such sensitive data
  // automatically, but what happens if we decide to change to a service that
  // doesn't automatically filter such data?
  const errorWithoutPassword = scrubPasswordFromLoginErrorObject(error)

  yield call(
    generalDoFailure,
    {
      error: errorWithoutPassword,
      preventsDashboardFromLoading: true,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
        target: [REDUCER_NAMES_PUBLIC_FORMS_LOGIN],
      }),
      onlyLogErrorIf: overridingOnlyLogErrorIfFunc || (
        () => {
          if (!wasThisALoginAttempt) { return false }
          if (!error.response) { return true }
          return error.response.status !== 404
        }
      ),
    },
  )
  yield put(push(URL_PATHS.login.path))
}


function scrubPasswordFromLoginErrorObject(error) {
  const passwordLocationsInErrorObject = [
    'config.data.password',
    'response.config.data.password',
  ]
  return passwordLocationsInErrorObject.reduce(
    // unsetFp_ won't throw an error if the error doesn't happen to exist
    (acc, location) => unsetFp_(location)(acc),
    error,
  )
}
