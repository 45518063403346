import React from 'react'


import HistoryTableCreator from '../../../HOC/HistoryTableCreator'

import {
  createTableAndDownloadFileContentDefinitions,
} from '../../util/tableAndDownloadFileContentDefinitions'


export default props => {
  const {
    groupingsOfInventoryReportObjsForHistoryTable,
  } = props
  const definitions = createTableAndDownloadFileContentDefinitions({
    ...props,
    tableOrDownloadFile: 'table',
  })
  return (
    <HistoryTableCreator
      {...props}
      tableInfoObj={
        // tableInfoObj needs to be an object, not an array
        groupingsOfInventoryReportObjsForHistoryTable.reduce(
          (acc, o) => ({ ...acc, [o.countDate.toString()]: o }),
          {},
        )
      }
      definitions={definitions}
      additionalPropsToPassToTableCells={props}
      // for Edit and Delete buttons column
      itemType="inventory report"
      dontIncludeDeleteButton
    />
  )
}
