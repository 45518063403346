import FetchStatusReducerCreator from '../higherOrderReducers/reducerCreators'

import {
  FETCH_STATUSES_FORMS_REQUEST,
  FETCH_STATUSES_FORMS_SUCCESS,
  FETCH_STATUSES_FORMS_FAILURE,
  FETCH_STATUSES_FORMS_CLEAR,
  FETCH_STATUSES_FORMS_QUEUE,
} from '../../../actions/actionTypes'


export default FetchStatusReducerCreator({})(
  FETCH_STATUSES_FORMS_REQUEST,
  FETCH_STATUSES_FORMS_SUCCESS,
  FETCH_STATUSES_FORMS_FAILURE,
  FETCH_STATUSES_FORMS_CLEAR,
  FETCH_STATUSES_FORMS_QUEUE,
)
