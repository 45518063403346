import { put, call, takeEvery } from 'redux-saga/effects'

import { publicFetch } from '../../fetch'
import {
  FETCH_RESEND_CONFIRMATION_CODE_TO_NEW_USER,
  FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
  FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
  FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
} from '../../../actions/actionTypes'

import {
  API_URL_PATH_RESEND_NEW_USER_CONFIRMATION_CODE,
  REDUCER_NAMES_PUBLIC_FORMS_RESEND_CONFIRMATION_CODE_TO_NEW_USER,
} from '../../../../constants'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'


export function* fetchEnterEmail(action) {
  const { email } = action.payload
  yield put(createAction(
    FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
    { target: [REDUCER_NAMES_PUBLIC_FORMS_RESEND_CONFIRMATION_CODE_TO_NEW_USER] },
  ))
  try {
    yield call(publicFetch, { path: API_URL_PATH_RESEND_NEW_USER_CONFIRMATION_CODE, params: { email } })
  } catch (error) {
    yield call(doFailure, error)
    return
  }
  yield call(doSuccess)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_RESEND_CONFIRMATION_CODE_TO_NEW_USER, fetchEnterEmail],
]

function* doSuccess() {
  yield put(createAction(
    FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
    { target: [REDUCER_NAMES_PUBLIC_FORMS_RESEND_CONFIRMATION_CODE_TO_NEW_USER] },
  )) // CODE_COMMENTS_20
}

function* doFailure(error) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
        target: [REDUCER_NAMES_PUBLIC_FORMS_RESEND_CONFIRMATION_CODE_TO_NEW_USER],
      }),
    },
  )
}
