/**
 * CODE_COMMENTS_131: middleware that sets the "Catastrophic Failure Happened"
 * flag in the Redux Store if an unhandled error occurs in any reducer.
 */
import createAction from './actions/createAction'

import {
  SET_CATASTROPHIC_FAILURE_HAPPENED,
} from './actions/actionTypes'

export const handleErrorsInReducers = store => next => action => {
  let reducerResult
  try {
    reducerResult = next(action)
  } catch (error) {
    store.dispatch(createAction(SET_CATASTROPHIC_FAILURE_HAPPENED, { error }))
  }
  return reducerResult
}
