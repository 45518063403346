/**
 * CODE_COMMENTS_161
 */
import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'


import {
  replaceMultipleForwardSlashesWithOne,
} from '../../../utils'


export default withRouter(({ children, location }) => {
  const pathNameWithoutMultipleForwardSlashes = replaceMultipleForwardSlashesWithOne(location.pathname)
  if (pathNameWithoutMultipleForwardSlashes === location.pathname) { // no multiple slashes
    return children
  }
  return (
    <Redirect
      to={{
        pathname: pathNameWithoutMultipleForwardSlashes,
        state: { from: location },
      }}
    />
  )
})
