import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'

import Four04 from '../../../../../features/Four04'
import {
  URL_PATHS,
} from '../../../../../constants'


export default withRouter(({
  isAuthenticated,
  isEmployeeUserLoggedInRatherThanCustomerUser,
  ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,

  // props provided by withRouter
  location,
}) => {
  if (isAuthenticated) {
    if (!isEmployeeUserLoggedInRatherThanCustomerUser) {
      return <Four04 />
    }

    if (
      isEmployeeUserLoggedInRatherThanCustomerUser
      && ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor
    ) {
      return <Four04 />
    }

    // This is the final possibility in the 'isAuthenticated' flow, so
    // technically this if statement isn't necessary; we write it out here in
    // order to be explicit and super clear about what's happening.
    if (
      isEmployeeUserLoggedInRatherThanCustomerUser
      && !ifEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor
    ) {
      // If an employee user is logged in but hasn't chosen a customer user to
      // operate as, any route they manually navigate to that isn't an existing
      // route (i.e. that isn't `/employee/operate-as-customer-user` or
      // '/employee/flat-files', etc) should redirect them to
      // /employee/operate-as-customer-user rather than showing a 404. Why?
      // Because the user is most likely trying to access a customer-user page
      // (i.e. a page in which they're operating as a customer user, such as
      // /order-kegs or /report-outbound-shipments). But since the employee user
      // has forgotten to choose a customer user to operate as, the
      // customer-user route won't exist (the rootRoutes component is smart this
      // way, only rendering customer-user routes once the employee user has
      // actually chosen a customer user to operate as).
      // return <Four04 />
      return <RedirectToOperateAsCustomerUserPage from={{ ...location, state: { notFoundError: true } }} />
    }
  }
  // This app has no need for a public 404. If an unauthenticated user
  // navigates to a private route or a route that doesn't exist, redirect
  // them to the login page. Also, we need to do something a little odd
  // here: set our custom notFoundError flag to false if the user isn't
  // authenticated. Why? After all, the whole reason this component renders
  // is because the user tried to navigate to a route that doesn't exist, so
  // why are we setting a "route does not exist" flag to false here? Because
  // private routes are created dynamically after the user logs in. So if
  // the user logs out then navigates to a valid private route (say, a few
  // hours later, using a bookmark), that route may not exist yet, but it
  // will once the user logs in. If we simply redirect the user to the login
  // page here without setting notFoundError to false, the notFoundError
  // setting (which lives within the `location` param and has been set to
  // true by the RouteNotFound component, which is what led us to this
  // component) will carry over to the login page. Then something unexpected
  // will happen: when the user logs in, the app will correctly redirect
  // them to their desired route, but the 404 component renders rather than
  // the route's normal component. Why? Because we wrap the component that
  // creates all the routes (RootRoutes) in the CaptureRouteNotFound
  // component, and after login, CaptureRouteNotFound checks the
  // notFoundError to check whether to render its children or the 404
  // component. If notFoundError is true, it renders 404 and doesn't even
  // call the RootRoutes component, so the private routes never even get
  // created. In order to avoid this nasty bug, we set notFoundError to
  // false here. When we do this, we're basically assuming that any
  // non-public route an unauthenticated user visits is a valid private
  // route. We'll only find out whether it's truly valid when RootRoutes
  // gets rendered. This is the desired behavior, because if the route is
  // not valid, React Router's dynamic routing facilities combined with our
  // careful 404 handling will properly render a 404.
  return <RedirectToLoginPage from={{ ...location, state: { notFoundError: false } }} />
})

const RedirectToLoginPage = ({ from }) => (
  <Redirect to={{
    pathname: URL_PATHS.login.path,
    state: { from },
  }}
  />
)

const RedirectToOperateAsCustomerUserPage = ({ from }) => (
  <Redirect to={{
    // pathname: URL_PATHS.employeeOperateAsCustomerUser.path,
    pathname: URL_PATHS.employeeOperateAsCustomerUser.path,
    state: { from },
  }}
  />
)
