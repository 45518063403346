import jwtDecode from 'jwt-decode'

import isString_ from 'lodash/isString'
import isNumber_ from 'lodash/isNumber'
import isArray_ from 'lodash/isArray'
import isPlainObject_ from 'lodash/isPlainObject'
import keys_ from 'lodash/keys'
import values_ from 'lodash/values'


import {
  isStringValidator,
  isStringOrNumberValidator,
  isValidEmailAddressValidator,
  isBooleanValidator,
  isFalsyValidator,
} from '../simpleValidators'

import {
  validatorCombinerWithOrLogic,
} from '../util'


/*
 * *****************************************************************************
 * Props Validators
 * *****************************************************************************
*/

export const WEBAPP_DEFINED_USER_TYPE_CURRENT_USER = 'WEBAPP_DEFINED_USER_TYPE_CURRENT_USER'
export const WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER = 'WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER'
export const WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE = 'WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE'
export const WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER = 'WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER'

// We export this because the currentUser.js file needs it
const propsValidatorsOfAllTypesOfUsers = [
  // Props shared between multiple user types
  {
    propPath: 'active',
    validatorFn: validatorCombinerWithOrLogic(isFalsyValidator, isBooleanValidator),
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER,
    ],
  },
  // What about deskPhoneNumber, cellPhoneNumber and faxNumber? Users are not
  // required to have these. See CODE_COMMENTS_208.
  {
    propPath: 'emailAddress',
    validatorFn: isValidEmailAddressValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER,
    ],
  },
  {
    propPath: 'firstName',
    validatorFn: isStringValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER,
    ],
  },
  {
    propPath: 'id',
    validatorFn: isStringOrNumberValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER,
    ],
  },
  {
    propPath: 'lastName',
    validatorFn: isStringValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER,
    ],
  },
  {
    propPath: 'rootCustomerId',
    validatorFn: isStringOrNumberValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER,
    ],
  },
  {
    propPath: 'username',
    validatorFn: isStringValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER,
    ],
  },

  // currentUser only

  {
    propPath: 'permissions',
    validatorFn: getIsPermissionsObjectValid,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
    ],
  },
  {
    propPath: 'perCustomerPermissions',
    validatorFn: getIsPerCustomerPermissionsObjectValid,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
    ],
  },
  {
    propPath: 'refreshToken',
    validatorFn: isStringValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
    ],
  },
  {
    propPath: 'userId',
    validatorFn: isStringOrNumberValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
    ],
  },
  {
    propPath: 'userToken',
    validatorFn: userTokenValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: true,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CURRENT_USER,
      WEBAPP_DEFINED_USER_TYPE_EMPLOYEE_CURRENT_USER,
    ],
  },

  // Customer Representatives only

  {
    propPath: 'userRoleId', // e.g. CE, LOG, SALES
    validatorFn: isStringValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE,
    ],
  },
  {
    propPath: 'userTypeId',
    validatorFn: isStringValidator,
    doNotSaveApiResponseDataToStoreIfValidationFails: false,
    appliesToUserTypes: [
      WEBAPP_DEFINED_USER_TYPE_CUSTOMER_REPRESENTATIVE,
    ],
  },
]


export function getPropsValidatorsOfSpecificUserType({
  userType,
  ifIsCurrentUserTypeShouldPropsRelatedToAuthenticationBeChecked, // CODE_COMMENTS_222
}) {
  const propsValidators = propsValidatorsOfAllTypesOfUsers.filter(prop => prop.appliesToUserTypes.includes(userType))
  if (ifIsCurrentUserTypeShouldPropsRelatedToAuthenticationBeChecked) {
    return propsValidators
  }
  const propsRelatedToAuthentication = ['userToken', 'refreshToken']
  return propsValidators.filter(o => !propsRelatedToAuthentication.includes(o.propPath))
}


/*
 * *****************************************************************************
 * Permissions
 * *****************************************************************************
*/

// Returns true if a permissions object has no issues with either its shape or
// data, otherwise returns a an array of strings explaining what's wrong. This
// is for permissions objects only, not perCustomerPermissions objects (there's
// a separate method for perCustomerPermissions objects).
export function getIsPermissionsObjectValid({
  data: permissionsObj,
}) {
  const problems = []
  // permissions objects are allowed to be null
  if (!permissionsObj) { return true }
  if (!isArray_(permissionsObj)) {
    problems.push('The permissions prop is not an array')
    return problems
  }

  // eslint-disable-next-line consistent-return
  permissionsObj.forEach((item, index) => {
    const printableItem = JSON.stringify(item)
    if (!isPlainObject_(item)) {
      problems.push(`${printableItem} (index ${index}) is not a plain object.`)
      return problems
    }
    const keys = keys_(item)
    if (!keys.includes('permission')) {
      problems.push(`${printableItem} (index ${index}) doesn't include a 'permission' prop`)
    }
    if (!keys.includes('access')) {
      problems.push(`${printableItem} (index ${index}) doesn't include an 'access' prop`)
    }

    if (keys.includes('permission') && !isString_(item.permission)) {
      problems.push(`The 'permission' prop of ${printableItem} (index ${index}) isn't a string`)
    }
    if (keys.includes('access') && !isString_(item.access)) {
      problems.push(`The 'access' prop of ${printableItem} (index ${index}) isn't a string`)
    }
  })

  if (problems.length === 0) { return true }
  return problems
}


// CODE_COMMENTS_179. This is for perCustomerPermissions objects only, not
// permissions objects (there's a separate method for permissions objects).
export function getIsPerCustomerPermissionsObjectValid({
  data: perCustomerPermissionsObj,
}) {
  // permissions objects are allowed to be null
  if (!perCustomerPermissionsObj) { return true }
  if (!isPlainObject_(perCustomerPermissionsObj)) {
    return 'The perCustomerPermissions prop is not an object'
  }

  const problemsByCustomer = keys_(perCustomerPermissionsObj).reduce(
    (acc, operateForCustomerId) => ({
      ...acc,
      [operateForCustomerId]: getIsPermissionsObjectValid({
        data: perCustomerPermissionsObj[operateForCustomerId],
      }),
    }),
    {},
  )

  if (values_(problemsByCustomer).every(isIndividualCustomerPermissionsObjValid => (
    isIndividualCustomerPermissionsObjValid === true
  ))) {
    return true
  }

  const customersWithProblems = keys_(problemsByCustomer).filter(custId => problemsByCustomer[custId] !== true)

  const problems = customersWithProblems.reduce(
    (acc, custId) => {
      const problemsOfThisCustomer = problemsByCustomer[custId]
      const problemsOfThisCustomerWithCustomerIdPrependedToStrings = problemsOfThisCustomer.map(
        problemDescription => `${custId}: ${problemDescription}`,
      )
      return [
        ...acc,
        ...problemsOfThisCustomerWithCustomerIdPrependedToStrings,
      ]
    },
    [],
  )

  return problems
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

// CODE_COMMENTS_179
function userTokenValidator({ data: userToken }) {
  if (!isString_(userToken)) { return 'user token is not a string' }
  let decodedUserToken
  try {
    decodedUserToken = jwtDecode(userToken)
  } catch (e) {
    return 'userToken is not able to be decoded'
  }
  if (!isPlainObject_(decodedUserToken)) { return 'The Decoded user token is not an object' }
  if (!isNumber_(decodedUserToken.exp)) { return 'decodedUserToken.exp is not a number' }
  return true
}
