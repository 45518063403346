import { connect } from 'react-redux'

import failureMessageIfNecessaryHocOnResetPwOrConfirmAccount from '../../../shared/failureMessageIfNecessaryHocOnResetPwOrConfirmAccount'
import Form from '../../../shared/FormSharedByResetPwAndConfirmAccount'

import {
  getProp,
} from '../../../../../redux/selectors/publicForms/forgotPassword'

import createAction from '../../../../../redux/actions/createAction'

import {
  FETCH_FORGOT_PASSWORD_RESET_PW,
} from '../../../../../redux/actions/actionTypes'

import {
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW,
} from '../../../../../constants'

import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/publicForms'


const {
  getFetchStatuses,
} = fetchStatusSelectors


const mapStateToProps = state => {
  const fetchStatuses = getFetchStatuses(state, REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW)

  const apiResponseIndicatingWhereResetCodeSent = getProp(state, 'apiResponseIndicatingWhereResetCodeSent')

  return {
    ...fetchStatuses,
    apiResponseIndicatingWhereResetCodeSent,
    formName: REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_RESET_PW,
  }
}

const mapDispatchToProps = dispatch => ({
  handleSubmit: ({ confirmationCode, password }) => {
    dispatch(createAction(FETCH_FORGOT_PASSWORD_RESET_PW, { confirmationCode, password }))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(failureMessageIfNecessaryHocOnResetPwOrConfirmAccount(Form))
