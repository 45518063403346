// CODE_COMMENTS_267

import { all, call, put, takeEvery } from 'redux-saga/effects'


import get_ from 'lodash/get'
import isFunction_ from 'lodash/isFunction'
import { publicFetch } from '../../fetch'
import {
  FETCH_INFO_NEEDED_TO_CONSTRUCT_UNSUBSCRIPTION_NO_LOGIN_REQUIRED_FORM, FETCH_UNSUBSCRIPTION,
  SAVE_CONTACT,
  SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK,
} from '../../../actions/actionTypes'

import {
  API_URL_PATH_NO_LOGIN_CONTACTS_UNSUBSCRIBE,
  API_URL_PATH_NO_LOGIN_CONTACTS,
} from '../../../../constants'

import {
  generalDoFailure,
} from '../../util/fetchFailure'
import {
  createApiTimedTokenHttpHeader,
} from '../../../../utils'
import createAction from '../../../actions/createAction'

function* unsubscribeContact(action) {
  const props = {
    ...action.payload,
  }
  const {
    token,
    onFetchStart,
    formValues,
    onFetchSuccess,
    entireCustomerContactLinksSlice,
  } = props

  if (isFunction_(onFetchStart)) { onFetchStart() }

  const data = { ...formValues }
  delete data.contactType
  delete data.defaultShippingContact
  delete data.replacementDefaultShippingContactId
  delete data.replacementInvoiceRecipientContactId
  delete data.dataChanged


  let response
  try {
    const config = {
      path: `${API_URL_PATH_NO_LOGIN_CONTACTS_UNSUBSCRIBE}`,
      data,
      method: 'PUT',
      headers: createApiTimedTokenHttpHeader(token),
    }
    response = yield call(publicFetch, config)
  } catch (error) {
    yield call(doFailure, error, props)
    return
  }
  const responseData = response?.data
  const contactObj = { ...responseData }
  delete contactObj.customerContactObjects
  // yield put(createAction(SAVE_CONTACT, { info: contactObj }))
  const { customerContactObjects } = responseData
  // eslint-disable-next-line no-restricted-syntax
  for (const linkObj of customerContactObjects) {
    linkObj.contactId = contactObj.id
    linkObj.customerName = entireCustomerContactLinksSlice?.[linkObj?.id]?.customerName
    yield put(createAction(SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK, { info: linkObj }))
  }
  if (isFunction_(onFetchSuccess)) { onFetchSuccess() }
}

function* fetchInfoNeededToConstructUnSubscribeNoLoginRequiredForm(action) {
  const {
    token,
    contactId,
    setIsFetching,
    setIsTokenExpired,
    setErrorMessage,
  } = action.payload
  setIsFetching(true)
  setErrorMessage(false)
  let noLoginContactsApiResponse
  try {
    // yield all to perform the calls at the same time
    [
      noLoginContactsApiResponse,
    ] = yield all([
      call(
        publicFetch,
        {
          path: `${API_URL_PATH_NO_LOGIN_CONTACTS}/${contactId}`,
          headers: createApiTimedTokenHttpHeader(token),
          // Don't retry the call several times if the token is expired or doesn't
          // exist.
          customErrorReactions: [
            {
              check: error => ([401, 500, 403].includes(get_(error, 'response.status'))),
              reaction: function mySaga(error) {
                // Throwing the error here prevents the call from being re-tried
                // multiple times, which is what we want. This error will be
                // caught by the try/catch block below.
                throw error
              },
              shouldThisErrorReactionBeCalledBeforeAnyUniversalErrorReactionsSoAsToAvoidUnnecessaryRefetches: true,
            },
          ],
        },
      ),
    ])
  } catch (error) {
    // See CODE_COMMENTS_267 for what all the error responses mean
    if ([401, 500, 403].includes(get_(error, 'response.status'))) {
      if (error.response.status === 401) {
        setIsTokenExpired(true)
      } else if (error.response.status === 500) {
        setIsTokenExpired(false)
      } else if (error.response.status === 403) {
        const permissionsMessagePart = 'does not have appropriate permissions to execute this function'
        if (get_(error, 'response.data.message').includes(permissionsMessagePart)) {
          setIsTokenExpired(false)
        } else {
          setIsTokenExpired(false)
          yield call(doFailure, { error, ...action.payload })
        }
      }
    } else {
      yield call(doFailure, { error, ...action.payload })
    }
    return
  }
  yield call(doSuccess, { response: noLoginContactsApiResponse, ...action.payload })
}


function* doSuccess({
  response,
  setIsTokenExpired,
  setIsFetching,
}) {
  const { contactObject, unsubscribeInfoByCustomerId } = response?.data || {}
  const contactObj = { ...contactObject }
  delete contactObj.customerContactObjects
  // delete contactObj?.unsubscribeInfoByCustomerId
  const { customerContactObjects } = contactObject
  // eslint-disable-next-line no-restricted-syntax
  for (const linkObj of customerContactObjects) {
    linkObj.contactId = contactObj.id
    linkObj.customerName = unsubscribeInfoByCustomerId?.[linkObj?.customerId]?.customerName
    yield put(createAction(SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK, { info: linkObj }))
  }
  yield put(createAction(SAVE_CONTACT, { info: contactObj }))
  setIsTokenExpired(false)
  setIsFetching(false)
}


function* doFailure({
  error,
  setIsTokenExpired,
  setErrorMessage,
}) {
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  setIsTokenExpired(false)
  setErrorMessage(true)
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    FETCH_INFO_NEEDED_TO_CONSTRUCT_UNSUBSCRIPTION_NO_LOGIN_REQUIRED_FORM,
    fetchInfoNeededToConstructUnSubscribeNoLoginRequiredForm,
  ],
  [takeEvery, FETCH_UNSUBSCRIPTION, unsubscribeContact],
]
