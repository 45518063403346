/**
 * CODE_COMMENTS_31
 */
import moment from 'moment'

import {
  convertApiDateToMoment,
} from '../../utils'


export const FIELD_ARRAY_NAME_KEG_COLLARS = 'kegCollars'

export const FIELD_NAME_SHIPPING_ADDRESS = 'shipping-address'
export const FIELD_NAME_CUSTOMER_PO_NUM = 'customer-po-number'
export const FIELD_NAME_ORDER_COMMENTS = 'order-comments'
export const FIELD_NAME_SHIPPING_TYPE = 'shipping-type'

export const FIELD_NAME_KEG_COLLAR_CHOICE = 'keg-collar-choice'
export const FIELD_NAME_KEG_COLLAR_AMOUNT = 'keg-collar-amount'

export const FIELD_VALUE_SHIPPING_TYPE_FEDEX_GROUND = 'fedexGround'
export const FIELD_VALUE_SHIPPING_TYPE_FEDEX_OVERNIGHT = 'fedexOvernight'

export const FIELD_LABEL_SHIPPING_ADDRESS = 'Shipping Address'
export const FIELD_LABEL_CUSTOMER_PO_NUM = 'Internal Name / PO#'
export const FIELD_LABEL_ORDER_COMMENTS = 'Order Comments'
export const FIELD_LABEL_KEG_COLLAR_CHOICE = 'Keg Collar'
export const FIELD_LABEL_KEG_COLLAR_AMOUNT = 'Amount'
export const FIELD_LABEL_SHIPPING_TYPE = 'Shipping'

export const KEG_COLLAR_AMOUNTS_IN_INCREMENTS_OF = 250
export const KEG_COLLAR_AMOUNTS_MAX_OF_SINGLE_ROW = 10000

export const SHIPPING_TYPE_OPTIONS = [
  {
    name: 'Fedex Ground',
    value: FIELD_VALUE_SHIPPING_TYPE_FEDEX_GROUND,
    arrivalDateString: createFedexGroundArrivalDateString,
  },
  {
    name: 'Fedex Overnight',
    value: FIELD_VALUE_SHIPPING_TYPE_FEDEX_OVERNIGHT,
    arrivalDateString: createFedexOvernightArrivalDateString,
  },
]

export const LEFT_LABELS_COLUMN_WIDTH = '180px'

// helper functions

// Two Fridays from the order date. If the order date is a Friday, one Friday
// from the order date.
// --  dateOrdered: a date string as formatted by the API or a moment.js object
function getDateWhenKegCollarsWillBePrinted(dateOrdered) {
  const orderDateMomentObj = moment.isMoment(dateOrdered)
    ? dateOrdered
    : convertApiDateToMoment(dateOrdered)
  const theFridayOfOrderDateWeek = orderDateMomentObj.clone().endOf('week').subtract(1, 'day')
  const isOrderDateASaturday = orderDateMomentObj.day() === 6
  return isOrderDateASaturday
    ? theFridayOfOrderDateWeek.clone().add(2, 'weeks')
    : theFridayOfOrderDateWeek.clone().add(1, 'weeks')
}

// The next Wednesday after the collars are printed (recommended by Joe Hutton,
// the MicroStar employee in charge of collar orders). Args:
//
// --  dateStringFormat: should follow the conventions of the moment.js library
export function createFedexGroundArrivalDateString(
  dateOrdered,
  dateStringFormat,
  prependWithAppx=true,
) {
  const printed = getDateWhenKegCollarsWillBePrinted(dateOrdered)
  const dateString = printed.clone().add(5, 'days').format(dateStringFormat)
  return prependWithAppx
    ? `appx. ${dateString}`
    : dateString
}

// The day the collars are printed. How can this be? The collar printing company
// often prints on Thursday or Wednesday rather than on Friday, and when they
// receive an Overnight order, they almost always print on Thursday or Wednesday
// so that the collars arrive by Friday. Joe Hutton, the MicroStar employee in
// charge of collar orders, has only seen collars arrive after Friday on a few
// occassions (only about 1/500 times), therefore he advises setting the
// displayed arrival date to Friday.
export function createFedexOvernightArrivalDateString(
  dateOrdered,
  dateStringFormat,
  includeAdditionalChargesApplyNote=true,
) {
  const printed = getDateWhenKegCollarsWillBePrinted(dateOrdered)
  const dateString = printed.format(dateStringFormat)
  return includeAdditionalChargesApplyNote
    ? `${dateString} (additional charges apply)`
    : dateString
}
