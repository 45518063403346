import { useTranslation } from 'react-i18next'
import withBusinessUnitIdOfCurrentUserRootCustomer from '../HOC/redux/withBusinessUnitIdOfCurrentUserRootCustomer'

import {
  getBusinessUnitNameFromBusinessUnitId,
} from '../../utils'


export default withBusinessUnitIdOfCurrentUserRootCustomer(({
  businessUnitIdOfCurrentUserRootCustomer,
}) => {
  const { t: translate } = useTranslation('common')
  const businessUnitName = getBusinessUnitNameFromBusinessUnitId(businessUnitIdOfCurrentUserRootCustomer)
  return `${translate('A foreign keg is a keg delivered to you, on a')} ${businessUnitName} ${translate('shipment, that has stickers/decals of a company other than')} ${businessUnitName}, ${translate('such as Anheuser-Busch or Boston Beer. Please note, foreign kegs received from distributors classified as Local should be returned to them on the next shipment between the two companies.')}`
})
