/* eslint-disable max-len */

import flow_ from 'lodash/fp/flow'
import map_ from 'lodash/fp/map'
import values_ from 'lodash/fp/values'
import flatten_ from 'lodash/fp/flatten'


import { getFetchStatusesStandaloneF } from '../../../fetchStatuses/HOF/fetchStatusSelectors'

import {
  withProp,
  withPropEquals,
} from '../../higherOrderFunctions'

import {
  isTruthyAndNonEmpty,
} from '../../../../../utils'


/**
 * Pass in the fetchStatuses slice of a form that has multiple rows, each of
 * which gets individually submitted, and this function returns an array of
 * booleans representing:
 *
 * -- has the entire form been submitted?
 * -- are some rows submitting?
 * -- have some row submissions failed?
 * -- have all row submissions succeeded?
 *
 */
export const getAggregateRowFetchStatuses = (
  formFetchStatusesSlice,
  // Helpful when your fetch status slice isn't actually a form with field
  // arrays, but some other shape (for example, the Report No Movements form)
  customGetFetchStatusesOfAllRowsFunc,
) => {
  if (!isTruthyAndNonEmpty(formFetchStatusesSlice)) {
    return [false, false, false, false]
  }


  const getFetchStatusesOfAllRowsFunc = (
    customGetFetchStatusesOfAllRowsFunc
    || flow_(
      // convert form slice object to array of fieldArray objects
      values_,
      // convert each fieldArray object into an array of individual row fetch
      // status info objects
      map_(values_),
      // combine all fieldArray arrays, leaving a single array of individual row
      // fetch status info objects
      flatten_,
    )
  )

  const fetchStatusesOfAllRows = getFetchStatusesOfAllRowsFunc(formFetchStatusesSlice)

  // In order to get the actual statuses of each form row (isFetching,
  // didFetchSucceed, etc.), we're going to use the same code used by
  // /selectors/fetchStatuses/HOF/fetchStatusSelectors/index.js. To do
  // this, we're going to pass in each status object, which looks like
  //
  // { status: "fetchStatusesSuccess", errorDetails: {...} }
  //
  // and pretend it's the entire state.
  const customGetF = withProp()
  const customCompareF = withPropEquals()

  return [
    (
      fetchStatusesOfAllRows.some(status => getFetchStatusesStandaloneF(customGetF, customCompareF, status).isQueued)
      || fetchStatusesOfAllRows.some(status => getFetchStatusesStandaloneF(customGetF, customCompareF, status).hasFetchBeenAttempted)
    ),
    fetchStatusesOfAllRows.some(status => getFetchStatusesStandaloneF(customGetF, customCompareF, status).isFetching),
    fetchStatusesOfAllRows.some(status => getFetchStatusesStandaloneF(customGetF, customCompareF, status).didFetchFail),
    fetchStatusesOfAllRows.every(status => getFetchStatusesStandaloneF(customGetF, customCompareF, status).didFetchSucceed),
  ]
}
