/**
 * CODE_COMMENTS_84
 */
import {
  SAVE_PARENT_TO_CHILDREN_NORMALIZED_LINKS,
} from '../../actions/actionTypes'

import normalizedLinkReducer from './higherOrderEntityReducers/normalizedLinkReducerCreator'

export default normalizedLinkReducer({
  saveActionType: SAVE_PARENT_TO_CHILDREN_NORMALIZED_LINKS,
  leftSideObjIdKey: 'parentCustomerId',
  rightSideObjIdKey: 'childCustomerId',
})
