import { connect } from 'react-redux'

import ManageAddressesPage from './components/Page'

import {
  getDefaultShippingAddressOfCustomer,
  getBillingAddressOfCustomer,
  getShippingAddressesOfCustomerExcludingDefaultShippingAddress,
} from '../../redux/selectors/addresses'


const mapStateToProps = (state, { customerId }) => {
  const defaultShippingAddress = getDefaultShippingAddressOfCustomer({ state, customerId })
  const billingAddress = getBillingAddressOfCustomer({ state, customerId })
  const nonDefaultShippingAddresses = getShippingAddressesOfCustomerExcludingDefaultShippingAddress({
    state,
    customerId,
    currentlyActiveAddressesOnly: true,
  })

  return {
    defaultShippingAddress,
    billingAddress,
    nonDefaultShippingAddresses,
  }
}

export default connect(
  mapStateToProps,
)(ManageAddressesPage)
