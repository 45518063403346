import { put, call, takeEvery } from 'redux-saga/effects'

import { publicFetch } from '../../fetch'
import {
  FETCH_FORGOT_PASSWORD_ENTER_EMAIL,
  FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
  FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
  FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
  SAVE_FORGOT_PASSWORD_INPUT_EMAIL_AND_RESET_CODE_SENT_TO,
} from '../../../actions/actionTypes'

import {
  API_URL_PATH_FORGOT_PASSWORD,
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL,
} from '../../../../constants'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'


export function* fetchEnterEmail(action) {
  const { email } = action.payload
  yield put(createAction(
    FETCH_STATUSES_PUBLIC_FORMS_REQUEST,
    { target: [REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL] },
  ))
  let response
  try {
    response = yield call(publicFetch, { path: API_URL_PATH_FORGOT_PASSWORD, params: { email } })
  } catch (error) {
    yield call(doFailure, error)
    return
  }
  yield call(
    doSuccess,
    {
      email,
      // The response will be a string indicating where the reset code was sent
      apiResponseIndicatingWhereResetCodeSent: response.data,
    })
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_FORGOT_PASSWORD_ENTER_EMAIL, fetchEnterEmail],
]

function* doSuccess(payload) {
  // CODE_COMMENTS_54
  yield put(createAction(SAVE_FORGOT_PASSWORD_INPUT_EMAIL_AND_RESET_CODE_SENT_TO, payload))
  yield put(createAction(
    FETCH_STATUSES_PUBLIC_FORMS_SUCCESS,
    { target: [REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL] },
  )) // CODE_COMMENTS_20
}

function* doFailure(error) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_PUBLIC_FORMS_FAILURE,
        target: [REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD_ENTER_EMAIL],
      }),
    },
  )
}
