import React from 'react'
import { Modal, Grid } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import ModalWithCatastrophicFailureErrorBoundary from '../../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import TextAsPseudoLink from '../../../../../../../common-components/TextAsPseudoLink'

import HistoryLabelWithContainerTypesDifferencesPopupIfNecessary from '../../../../util/HistoryLabelWithContainerTypesDifferencesPopupIfNecessary'
import {
  createContainerTypesFieldsDefinitions,
  createDisplayedDestinationName,
  createDisplayedDestinationState, createDisplayedMovementType,
  createDisplayedProNumber,
  createDisplayedShipmentDate,
  createDisplayedShipmentNumber,
  createDisplayedShipmentType,
  createItemSkuIdFieldsDefinitions,
} from '../../../../util/shipmentHistory/common/displayedShipmentDataEtc'
import {
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION,
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
} from '../../../../../../../constants/formAndApiUrlConfig/histories/OutboundFullKegShipmentHistory'
import {
  createDisplayedOriginNameForOutboundShipmentsHistoryTable,
} from '../../../../util/shipmentHistory/outboundFullKegShipmentHistory/displayedDataEtc/displayedOutboundFullKegShipmentDataEtc'
import { getDisplayedHumanReadableStatusOfHistoryItem } from '../../../../util/util'
import { scheduledShipmentsHistoryStatusesConfig } from '../../../Form'
import { CUSTOMER_TYPES_WAREHOUSE } from '../../../../../../../constants'
import WHEmailSection from './whEmail'
import PrintBOL from './printBOL'


export default ({
  entireItemSkusSlice,
  customerId,
  info: shipmentDetails,
  itemSkuIdsToIncludeAsFieldsInTable,
  shouldOriginColumnBeRendered, // CODE_COMMENTS_130
  entireCustomersSlice,
  dispatch,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      closeIcon
      size="large"
      trigger={<TextAsPseudoLink>{shipmentDetails.shipmentId}</TextAsPseudoLink>}
      className="test-full-shipment-details"
    >
      <Modal.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{translate('Shipment Details')}</span>
          <div>
            <WHEmailSection dispatch={dispatch} shipmentId={shipmentDetails.id} />
            <PrintBOL dispatch={dispatch} shipmentId={shipmentDetails.id} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <AdditionalShipmentDetails
          entireCustomersSlice={entireCustomersSlice}
          entireItemSkusSlice={entireItemSkusSlice}
          customerId={customerId}
          shipmentDetails={shipmentDetails}
          itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
          shouldOriginColumnBeRendered={shouldOriginColumnBeRendered} // CODE_COMMENTS_130
        />
      </Modal.Content>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}

export const getInfoDefinitionsOutboundFullKegShipmentFullDetails = ({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  shouldOriginColumnBeRendered,
  downloadAttachment,
  entireCustomersSlice,
  customerId,
}) => ([
  { heading: 'Shipment #', cellContent: createDisplayedShipmentNumber },
  { heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM, cellContent: createDisplayedProNumber('none') },
  ...(entireCustomersSlice?.[customerId]?.customerType === CUSTOMER_TYPES_WAREHOUSE ?
    [{ heading: 'Shipment Type', cellContent: createDisplayedShipmentType }]
    : [{ heading: 'Movement Type', cellContent: createDisplayedMovementType }]
  ),
  ...(shouldOriginColumnBeRendered
    ? [{
      heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
      cellContent: createDisplayedOriginNameForOutboundShipmentsHistoryTable,
    }]
    : []
  ),
  { heading: OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_DESTINATION, cellContent: createDisplayedDestinationName },
  { heading: 'Destination State', cellContent: createDisplayedDestinationState },
  { heading: 'Ship Date', cellContent: createDisplayedShipmentDate },
  { heading: 'Status',
    cellContent: shipmentObj => (
      getDisplayedHumanReadableStatusOfHistoryItem(
        shipmentObj,
        scheduledShipmentsHistoryStatusesConfig,
        'status',
      )
    ) },
  ...(downloadAttachment ?
    createContainerTypesFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
    })
    :
    createItemSkuIdFieldsDefinitions({
      entireItemSkusSlice,
      itemSkuIdsToIncludeAsFieldsInTable,
    })),
  { heading: 'Delivery Notes', cellContent: row => row?.destinationDeliveryNotes || '' },
  { heading: 'Order Comments', cellContent: row => row?.orderComments || '' },
])

const AdditionalShipmentDetails = ({
  entireItemSkusSlice,
  customerId,
  shipmentDetails,
  itemSkuIdsToIncludeAsFieldsInTable,
  shouldOriginColumnBeRendered, // CODE_COMMENTS_130
  entireCustomersSlice,
}) => {
  const toDisplay = getInfoDefinitionsOutboundFullKegShipmentFullDetails({
    entireItemSkusSlice,
    itemSkuIdsToIncludeAsFieldsInTable,
    shouldOriginColumnBeRendered,
    entireCustomersSlice,
    customerId,
  })
  return (
    // For alignment purposes, all displayed content within this grid needs to
    // be contained within a <Grid.Column> component; see CODE_COMMENTS_97 for
    // why.
    <Grid columns={2}>
      {toDisplay.map(definition => (
        <Grid.Row key={definition.heading} className="compact-grid-row">
          <Grid.Column width={3} style={{ fontWeight: 'bold' }}>
            <HistoryLabelWithContainerTypesDifferencesPopupIfNecessary
              customerId={customerId}
              labelText={definition.heading}
              colonAfterText
            />
          </Grid.Column>
          <Grid.Column width={13}>{definition.cellContent(shipmentDetails, { customerId })}</Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  )
}
