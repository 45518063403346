import {
  getPropsValidatorsOfSpecificUserType,
  WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER,
} from './util'

import {
  isArrayOfObjectsValidator,
} from '../simpleValidators'

import {
  createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers,
} from '../util'

import {
  logObjectHasProblemsErrorMessage,
  CAN_STILL_BE_DISPLAYED,
  CANT_BE_DISPLAYED,
} from '../../../../../utils/thirdPartyLogging'

import {
  isTruthyAndNonEmpty,
} from '../../../../../utils'

export function validateAndFilterArrayOfCustomerUserObjects(httpResponse) {
  const { data } = httpResponse
  if (isArrayOfObjectsValidator({ data }) !== true) {
    logObjectHasProblemsErrorMessage({
      objectType: 'array of customerUserObjects',
      level: CANT_BE_DISPLAYED,
      additionalInfo: {
        details: "The response body of the call is not an array of objects, which it should always be. Therefore, this user won't be able to manage users",
        arrayOfProblemsWithData: ['response body is not an array of objects'],
      },
      httpResponse,
    })
    return []
  }

  return data.reduce(
    (acc, customerUserObj) => {
      const {
        validatedData,
        canDataBeSavedToStore,
      } = validateCustomerUserObject({
        data: customerUserObj,
        httpResponse,
      })
      return canDataBeSavedToStore
        ? [...acc, validatedData]
        : acc
    },
    [],
  )
}


// `props` should be an object with at least one property: `data`
function validateCustomerUserObject(props) {
  const individualPropsValidatorsAndTransformers = getPropsValidatorsOfSpecificUserType({
    userType: WEBAPP_DEFINED_USER_TYPE_CUSTOMER_USER,
  })

  const results = createNewApiResponseDataBasedOnindividualPropsValidatorsAndTransformers({
    ...props,
    individualPropsValidatorsAndTransformers,
  })

  const {
    arrayOfProblemsWithData,
    canDataBeSavedToStore,
  } = results

  const {
    data,
    httpResponse,
  } = props

  if (isTruthyAndNonEmpty(arrayOfProblemsWithData)) {
    logObjectHasProblemsErrorMessage({
      objectType: 'customerUserObject',
      level: canDataBeSavedToStore ? CAN_STILL_BE_DISPLAYED : CANT_BE_DISPLAYED,
      additionalInfo: {
        arrayOfProblemsWithData,
        targetObject: data,
      },
      httpResponse,
    })
  }

  return results
}
