/* eslint-disable no-restricted-syntax, no-loop-func */ // "for of" loops OK in this file, see CODE COMMENTS 50
import { put, select } from 'redux-saga/effects'

import flow_ from 'lodash/fp/flow'
import getOrFp_ from 'lodash/fp/getOr'
import mapFp_ from 'lodash/fp/map'
import valuesFp_ from 'lodash/fp/values'
import flattenFp_ from 'lodash/fp/flatten'


import {
  SAVE_ADDRESSES,
  SAVE_CUSTOMER_TO_ADDRESSES_NORMALIZED_LINKS,
} from '../../actions/actionTypes'
import createAction from '../../actions/createAction'

import {
  getEntireSlice as getEntireAddressesSlice,
} from '../../selectors/rewrite/addresses'
import {
  getEntireSlice as getEntireCustomerAddressLinksSlice,
} from '../../selectors/rewrite/customerAddressLinks'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_MASTER,
  CONCATENATED_STRING_SEPARATOR,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'


// CODE_COMMENTS_48
export function convertObjContainingCustomerToRelationsMapIntoArrayOfRelationshipObjects(obj) {
  // If obj is an empty object, an empty array is returned (because lodash is
  // awesome)
  return flow_(
    // default value comes first in getOr:
    // https://github.com/lodash/lodash/issues/1980#issuecomment-182594539
    getOrFp_({}, ['selectedRelations', 'customerToRelationsMap']),
    // starts as { cust1: {BRW2DIST: arrOfObjs, BRW2BRW: arrOfObjs}, cust2: {BRW2BRW: arrOfObjs}}
    valuesFp_, // [ {BRW2DIST: arrOfObjs, BRW2BRW: arrOfObjs}, {BRW2BRW: arrOfObjs} ]
    mapFp_(valuesFp_), // [ [arrOfObjs, arrOfObjs], [arrOfObjs] ]
    flattenFp_, // [arrOfObjs, arrOfObjs, arrOfObjs]
    flattenFp_, // [combinedArrOfObjs]
  )(obj)
}


// Every time the web app calls /relatedTo or /relatedFrom, each customer object
// returned contains a `customerAddresses` prop containing an address object.
// Save the addresses of all non-Distributor customers to the Redux store. Why?
// Because with the right permissions, Brewers are allowed to ship keg collars
// to their Contract Brewers and vice versa. We avoid saving Dist addresses
// because the web app doesn't use them, they would just be a waste of memory.
export function* saveAddressesEmbeddedInCustomerObjs(arrayOfCustomerObjects) {
  const customerObjs = arrayOfCustomerObjects.filter(
    o => (
      [
        CUSTOMER_TYPES_BREWER,
        CUSTOMER_TYPES_CONTRACT_BREWER,
        CUSTOMER_TYPES_MASTER,
      ].includes(o.customerType)
    ),
  )

  const state = yield select()
  const entireCustomerAddressLinksSlice = getEntireCustomerAddressLinksSlice(state)
  const entireAddressesSlice = getEntireAddressesSlice(state)

  for (const customerObj of customerObjs) {
    let arrayOfAddressObjs = customerObj.customerAddresses
    if (!isTruthyAndNonEmpty(arrayOfAddressObjs)) {
      return
    }
    let arrayOfCalObjs = arrayOfAddressObjs.map(addressObj => (
      createFauxCustomerAddressLinkObjOfRelatedToConbrwAddress({
        addressId: addressObj.id,
        customerId: customerObj.id,
      })
    ))
    // https://microstartap3.atlassian.net/browse/TP3-3856: If a MASTER logs in
    // who has a child BRW and a child CONBRW, and the BRW and CONBRW are
    // related, then the CONBRW addresses will already be in the Redux store.
    // Filter these out.
    arrayOfAddressObjs = arrayOfAddressObjs.filter(o => !entireAddressesSlice[o.id])
    arrayOfCalObjs = arrayOfCalObjs.filter(o => (
      !Object.values(entireCustomerAddressLinksSlice).some(existingO => (
        existingO.customerId === o.customerId
        && existingO.addressId === o.addressId
      ))
    ))

    yield put(createAction(SAVE_ADDRESSES, { info: arrayOfAddressObjs }))
    yield put(createAction(SAVE_CUSTOMER_TO_ADDRESSES_NORMALIZED_LINKS, { info: arrayOfCalObjs }))
  }
}


function createFauxCustomerAddressLinkObjOfRelatedToConbrwAddress({
  addressId,
  customerId,
}) {
  return {
    id: `${customerId}${CONCATENATED_STRING_SEPARATOR}${addressId}`,
    customerId,
    addressId,
    dshp: false,
    shp: true,
    bil: false,
    active: true,
    comments: null,
    deliveryNotes: null,
  }
}
