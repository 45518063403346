import { connect } from 'react-redux'


import NavBar from './components/NavBar'

import createAction from '../../../redux/actions/createAction'
import {
  LOGOUT,
} from '../../../redux/actions/actionTypes'


import {
  getProp as getCurrentUserEmployeeProp,
} from '../../../redux/selectors/currentUserEmployee'
import { getIsLogoutButtonEnabled } from '../../../redux/selectors/logoutButtonEnabledToggle'

const mapStateToProps = state => {
  const currentUserFirstName = getCurrentUserEmployeeProp(state, 'firstName')

  return {
    isLogoutButtonEnabled: getIsLogoutButtonEnabled(state), // CODE_COMMENTS_41
    currentUserFirstName,
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(createAction(LOGOUT)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar)
