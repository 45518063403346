// CODE_COMMENTS_168, CODE_COMMENTS_169

import maxBy_ from 'lodash/maxBy'
import uniq_ from 'lodash/uniq'
import uniqBy_ from 'lodash/uniqBy'
import sortBy_ from 'lodash/sortBy'

import {
  getCanAnyRelationshipBetweenTwoCustomersHaveShipmentsReportedOnIt,
  getSinglePropOfAllRelationshipObjectsBySourceAndDestCustomerIds,
  getAllRelationshipObjectsBySourceAndDestCustomerIds,
} from './relationships/relatedToOrFromInfo'

import {
  getCustomerIdsOfAllRelatedTo,
  getMultipleCustomerPropsOfAllRelatedTo,
} from './relationships/relatedToInfo'

import {
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BREWER,
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_CONTRACT_BREWER,
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_NEITHER,
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BOTH,

  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS,
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS,
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_KEG_FILLS,
  CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_INVENTORY,
} from '../../../constants/permissions'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'


// See getWhoHasCustomerLevelPermission() docstring for param details.
export function getDoesBrewerHaveCustomerLevelPermission({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  brwCustomerId,
  conbrwCustomerId,
  permission,
}) {
  return [
    CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BREWER,
    CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BOTH,
  ].includes(
    getWhoHasCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId,
      conbrwCustomerId,
      permission,
    }),
  )
}


// See getWhoHasCustomerLevelPermission() docstring for param details.
export function getDoesContractBrewerHaveCustomerLevelPermission({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  brwCustomerId,
  conbrwCustomerId,
  permission,
}) {
  return [
    CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_CONTRACT_BREWER,
    CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BOTH,
  ].includes(
    getWhoHasCustomerLevelPermission({
      entireRelationshipsSlice,
      entireCustomersSlice,
      entireContractsSlice,
      brwCustomerId,
      conbrwCustomerId,
      permission,
    }),
  )
}


// Returns an array of CONBRW customerIds answering this question: which
// Contract Brewing contracts does the BRW have relationship-level permissions
// to report inventory on? (Don't confuse this with the Contract Brewer's Master
// contract, which only the Contract Brewer can report inventory on.)
export function getWhichContractBrewersDoesBrwHaveCustomerLvlPermissionsToReportInventoryFor({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId, // Why explicitly pass this in? CODE_COMMENTS_174
}) {
  const activeConbrwsRelatedTo = uniq_( // CODE_COMMENTS_216
    getCustomerIdsOfAllRelatedTo({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263, CODE_COMMENTS_159, CODE_COMMENTS_268
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
    }),
  )

  return activeConbrwsRelatedTo.filter(conbrwCustomerId => (
    getDoesBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId: customerId,
      conbrwCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_INVENTORY,
    })
  ))
}

// Returns an array of contractee customerIds a logged-in CONBRW has
// relationship-level permissions to report inventory on. (Don't confuse this
// with the Contract Brewer's Master contract, which only the Contract Brewer
// can report inventory on.) This function only checks customer-level /
// relationship-level permissions. For a more complete check of which
// contractees a conbrw can report inventory for (one which checks user-level
// permissions), see the getWhichContracteesCanConbrwReportInventoryFor()
// function in the contractBrewersAndContracteesSpecialSelectors.js file
export function getWhichContracteesDoesConbrwHaveCustomerLvlPermissionsToReportInventoryFor({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  conbrwCustomerId, // Why explicitly pass this in? CODE_COMMENTS_174
}) {
  const activeBrwsRelatedFrom = uniq_( // CODE_COMMENTS_216
    getCustomerIdsOfAllRelatedTo({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId: conbrwCustomerId,
      onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112, CODE_COMMENTS_159
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
    }),
  )

  return activeBrwsRelatedFrom.filter(brwCustomerId => (
    getDoesBrewerHaveCustomerLevelPermission({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId,
      conbrwCustomerId,
      permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_INVENTORY,
    })
  ))
}


// Returns an array of Conbrw Customer Objects. This function only checks
// customer(i.e. relationship)-level permissions. For a more complete check of
// which contractees a conbrw can report keg fills for (functions which check
// user-level permissions), see the functions in the
// shouldReportShipmentsFormBeRendered.js file.
export function getAllConbrwCustomerObjsBrewerHasCustomerLevelPermissionsToReportShipmentsFor({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  doesNotHavePermissionsRatherThanDoes=false,
}) {
  const cbNamesAndIds = getMultipleCustomerPropsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    propNames: ['id', 'name'],
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_112, CODE_COMMENTS_159
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
  })

  if (!isTruthyAndNonEmpty(cbNamesAndIds)) { return [] }
  const cbNamesAndIdsNoDupes = uniqBy_(cbNamesAndIds, 'id') // CODE_COMMENTS_230
  // If this Brewer has multiple contract brewers he reports shipments for, we
  // want the displayed field arrays of these contract brewers to be sorted in
  // alphabetical order descending
  const cbNamesAndIdsSortedByNameDescNoDupes = sortBy_(cbNamesAndIdsNoDupes, 'name')

  const contractBrewerIds = cbNamesAndIdsSortedByNameDescNoDupes.map(o => o.id)

  // CODE_COMMENTS_168
  const cbIdsBrewerHasCustomerLevelPermissionsToReportShipmentsFor =
    contractBrewerIds.filter(conbrwCustomerId => (
      getDoesBrewerHaveCustomerLevelPermission({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        brwCustomerId: customerId,
        conbrwCustomerId,
        permission: CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS,
      })
    ))

  const cbIdsToReturn = doesNotHavePermissionsRatherThanDoes
    ? contractBrewerIds.filter(id => !cbIdsBrewerHasCustomerLevelPermissionsToReportShipmentsFor.includes(id))
    : cbIdsBrewerHasCustomerLevelPermissionsToReportShipmentsFor

  return cbIdsToReturn.map(conbrwCustomerId => (
    entireCustomersSlice[conbrwCustomerId]
  ))
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

// Returns one of the following constants (defined in
// /constants/permissions.js):

// - CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BREWER
// - CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_CONTRACT_BREWER
// - CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_NEITHER
// - CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BOTH

// If one of the relationships between the Brewer and Contract Brewer is
// currently active, inspect that relationship. If none are currently active,
// inspect the relationship with the latest `effectiveDate` (see
// CODE_COMMENTS_169 for why we do this).

// If there are no relationships at all between the Brewer and Contract Brewer,
// return NEITHER.
function getWhoHasCustomerLevelPermission({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  brwCustomerId,
  conbrwCustomerId,
  // Should be set to one of the following constants (defined in
  // /constants/permissions.js):
  // - CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS
  // - CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS
  // - CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_KEG_FILLS
  permission,
}) {
  const canRelationshipHaveShipmentsReportedOnIt = getCanAnyRelationshipBetweenTwoCustomersHaveShipmentsReportedOnIt({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    sourceCustomerId: brwCustomerId,
    destinationCustomerId: conbrwCustomerId,
  })

  let contractBrewerPermissionsObject
  if (canRelationshipHaveShipmentsReportedOnIt) {
    contractBrewerPermissionsObject = getSinglePropOfAllRelationshipObjectsBySourceAndDestCustomerIds({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      sourceCustomerId: brwCustomerId,
      destinationCustomerId: conbrwCustomerId,
      propName: 'contractBrewerPermissions',
      onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263, CODE_COMMENTS_159
    })[0]
  } else {
    // If no relationships are active, target the relationship with the latest
    // effective date
    const allRelationshipsBetweenBrwAndConbrwThatCanHaveShipmentsReportedOnThem =
      getAllRelationshipObjectsBySourceAndDestCustomerIds({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        sourceCustomerId: brwCustomerId,
        destinationCustomerId: conbrwCustomerId,
        onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263, CODE_COMMENTS_159
      })

    // If there are no relationships at all between the brewer and contract
    // brewer
    if (!isTruthyAndNonEmpty(allRelationshipsBetweenBrwAndConbrwThatCanHaveShipmentsReportedOnThem)) {
      return CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_NEITHER
    }

    contractBrewerPermissionsObject = maxBy_(
      allRelationshipsBetweenBrwAndConbrwThatCanHaveShipmentsReportedOnThem,
      o => (
        // We use the or expression here in case every single one of the
        // relationship objects' effectiveDate is null (this should never
        // happen, but just in case)
        o.effectiveDate || -1000
      ),
    ).contractBrewerPermissions
  }

  return getWhoHasCustomerLevelPermissionFromContractBrewerPermissionsObject(
    contractBrewerPermissionsObject,
    permission,
  )
}

// CODE_COMMENTS_218
const defaultsWhenTheCustomerLevelPermissionDoesntExist = {
  [CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_ORDER_COLLARS]: (
    CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BREWER),
  [CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_OUTBOUND_SHIPMENTS]: (
    CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_BREWER),
  [CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_KEG_FILLS]: (
    CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_CONTRACT_BREWER),
  [CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_REPORT_INVENTORY]: (
    CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_CONTRACT_BREWER),
}


function getWhoHasCustomerLevelPermissionFromContractBrewerPermissionsObject(
  contractBrewerPermissionsObject,
  permission,
) {
  if (
    !contractBrewerPermissionsObject ||
    !isTruthyAndNonEmpty(contractBrewerPermissionsObject) ||
    !contractBrewerPermissionsObject[permission]
  ) {
    // CODE_COMMENTS_218
    return (
      defaultsWhenTheCustomerLevelPermissionDoesntExist[permission] ||
      // if for whatever reason the permission doesn't exist in the defaults
      // map, return 'neither' (This would happen if, for instance, the dev team
      // introduces a new customer-level permission but the web app developers
      // forget to update the defaults map).
      CUSTOMER_LVL_PERMISSIONS_BRW_CONBRW_VALUE_NEITHER
    )
  }

  return contractBrewerPermissionsObject[permission]
}
