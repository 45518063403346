import {
  REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE,
  REDUCER_NAMES_FETCH_STATUSES_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS,
} from '../../../constants'
import getFetchStatusSelectors from '../fetchStatuses/HOF/fetchStatusSelectors'

export default getFetchStatusSelectors()(
  REDUCER_NAMES_FETCH_STATUSES_EMPLOYEE, // CODE_COMMENTS_226
  REDUCER_NAMES_FETCH_STATUSES_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS,
)
