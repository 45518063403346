import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

import {
  URL_PATHS,
} from '../../../../constants'

import {
  getIsEmployeePath,
} from '../../../../utils'


import './styles.css'


export default withRouter(({
  isEmployeeUserLoggedInRatherThanCustomerUser,
  children,
  // provided by withRouter
  location,
}) => {
  if (
    !isEmployeeUserLoggedInRatherThanCustomerUser
    || getIsEmployeePath(location.pathname)
  ) {
    return children
  }
  return (
    <div>
      {children}
      <div className="hidden-content">
        <Link
          to={URL_PATHS.employeeOperateAsCustomerUser.path}
        >
          <Icon
            name="user circle outline"
            className="microstar-blue"
            size="huge"
          />
        </Link>
      </div>
    </div>
  )
})
