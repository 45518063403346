/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file

import { call, takeEvery } from 'redux-saga/effects'

import {
  fetchReportIndividualNoMovements,
} from './reportIndividualNoMovements'

import {
  FETCH_REPORT_NO_MOVEMENTS,
  FETCH_STATUSES_FORMS_QUEUE,
} from '../../../actions/actionTypes'

import createAction from '../../../actions/createAction'


import {
  FIELD_NAME_SELECTED_CUSTOMERS,
} from '../../../../constants/formAndApiUrlConfig/reportNoMovements'


function* fetchReportNoMovements(action) {
  const {
    formValues,
    dispatchFetchStatuses,
  } = action.payload

  /* eslint-disable no-unused-vars */
  const reduxIdsToSubmit = Object.entries(formValues[FIELD_NAME_SELECTED_CUSTOMERS]).filter(
    ([_, shouldSubmit]) => shouldSubmit,
  ).map(([id, _]) => id)
  /* eslint-enable no-unused-vars */

  // Set all rows that are going to be fetched to queued
  // CODE_COMMENTS_50
  for (const custIdAndOptionalConbrwCustIdReduxIdentifier of reduxIdsToSubmit) {
    dispatchFetchStatuses(createAction(
      FETCH_STATUSES_FORMS_QUEUE,
      { target: [custIdAndOptionalConbrwCustIdReduxIdentifier] }),
    )
  }

  // Now submit each selected customer in sequential order (no parallel
  // requests)
  for (const custIdAndOptionalConbrwCustIdReduxIdentifier of reduxIdsToSubmit) {
    yield call(
      fetchReportIndividualNoMovements,
      {
        payload: {
          ...action.payload,
          custIdAndOptionalConbrwCustIdReduxIdentifier,
        },
      },
    )
  }
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REPORT_NO_MOVEMENTS, fetchReportNoMovements],
]
