import {
  createSuccessiveMonthAndYearStringsFromRange,
} from '../../../utils'


export function createMonthAndYearPickerDropdownOptions({
  rangeMin,
  rangeMax,
  sortOptionsInDropdownFromLatestToEarliestRatherThanEarliestToLatest=false,
}) {
  const monthsToIncludeInDropdown = createSuccessiveMonthAndYearStringsFromRange(
    rangeMin,
    rangeMax,
  )
  if (sortOptionsInDropdownFromLatestToEarliestRatherThanEarliestToLatest) {
    monthsToIncludeInDropdown.reverse()
  }
  return monthsToIncludeInDropdown.map(month => (
    { key: month, value: month, text: month }
  ))
}
