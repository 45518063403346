/**
 * CODE_COMMENTS_62
 */
import React from 'react'

import get_ from 'lodash/get'
import uniq_ from 'lodash/uniq'
import capitalize_ from 'lodash/capitalize'

import moment from 'moment'

import NotApplicable from '../../../sharedComponents/NotApplicable'

import {
  getContainerTypeFromItemSkuId,
  getItemSkuIdQtyFromLineItem,
} from '../../../../../../redux/selectors/rewrite/itemSkus'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../../../constants/formAndApiUrlConfig/commonConfig'
import {
  CUSTOMER_TYPES_WAREHOUSE,
  ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG, ITEM_SKUS_SKU_TYPE_COMPOSITE, ITEM_SKUS_SKU_TYPE_KEG,
  SHIPMENT_TYPE_LABELS,
} from '../../../../../../constants'

import {
  isPurposeOfShipmentToFulfillKegOrder,
} from '../../util'

import {
  inboundEmptyKegShipmentHistoryStatusesConfig,
} from '../inboundEmptyKegShipmentHistory'

import {
  formatApiDate, getHumanReadableContainerTypeFromItemSku,
  getHumanReadableShipmentType,
  processAddressObjectForDisplay, processAddressObjectForScheduleShipmentAddress,
  sortArrayByTemplateArray,
} from '../../../../../../utils'
import ReactDatepicker from '../../../../../../common-components/rewrite/ReactDatepicker'
import {
  NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS,
} from '../../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

function createDateRangeMin() {
  return moment('01/01/1985', 'MM-DD-YYYY')
}

function createDateRangeMax() {
  return moment().clone().add(NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS, 'days')
}

export const createDisplayedShipmentNumber = row => row.shipmentId
export const createDisplayedOrderNumber = row => row.humanReadableOrderId
export const createDisplayedMovementType = row => (
  `${getHumanReadableShipmentType(row.movementType)}${row.reverseMovement ? ' (Reverse)' : ''}`
)
export const createDisplayedShipmentType = row => (row?.shipmentType ? SHIPMENT_TYPE_LABELS[row?.shipmentType] : '')
export const createDateForTable = ({ date='', editable = false, onChange }) => {
  if (!editable && !date) {
    return <NotApplicable />
  }
  if (!editable) {
    return formatApiDate(date, DEFAULT_DISPLAYED_DATE_FORMAT)
  }
  return (
    <div className="ui tiny form fields field ui input width70">
      <ReactDatepicker
        value={(date && formatApiDate(date, DEFAULT_DISPLAYED_DATE_FORMAT)) || ''}
        minDate={createDateRangeMin()}
        maxDate={createDateRangeMax()}
        onChange={onChange}
        name='shipDate'
        compact
        showMonthDropdown
        showYearDropdown
      />
    </div>
  )
}
export const createDisplayedDestinationName = row => row.destinationCustomerName
export const createDisplayedCarrierName = row => row.carrierName
export const createDisplayedSourceName = row => row.originCustomerName
export const createDisplayedDestinationState = row => get_(row, 'destinationAddressObject.stateOrProvince')
export const createDisplayedPlannedShipDate = row => formatApiDate(row.plannedPickupDate, DEFAULT_DISPLAYED_DATE_FORMAT)
export const createDisplayedDeliveryDate = row => (row.dateReceived && formatApiDate(row.dateReceived, DEFAULT_DISPLAYED_DATE_FORMAT)) || ''
export const createDisplayedShipmentDate = row => (row?.dateShipped ? formatApiDate(row.dateShipped, DEFAULT_DISPLAYED_DATE_FORMAT) : '')
export const createOrEditDisplayedShipmentDate = ({ onFormChangeValues }) => row => createDateForTable({
  date: row.shipDateSelected || row.dateShipped,
  editable: true,
  onChange: dateString => onFormChangeValues({ ...row, shipDateSelected: dateString }),
})
export const createDisplayedReportDate = row => formatApiDate(row.dateReported, DEFAULT_DISPLAYED_DATE_FORMAT)
// export const createDisplayedProNumber = createDisplayedInfoWithAlternateTextOption('proNumber')
export const createDisplayedProNumber = () => row => (row?.proNumber || row?.orderCustomerPO || '')
export const createDisplayedPlannedDeliveryDate = row => formatApiDate(row.plannedDeliveryDate,
  DEFAULT_DISPLAYED_DATE_FORMAT)

export const createDisplayedDeliveryDateForTable = row => {
  // if the purpose of this shipment is not to fulfill an order
  if (!row.orderId) {
    return <NotApplicable />
  }
  if (row.dateReceived) {
    return formatApiDate(row.dateReceived, DEFAULT_DISPLAYED_DATE_FORMAT)
  }
  const estimatedDeliveryDate = formatApiDate(
    row.estimatedDeliveryDate || row.plannedDeliveryDate,
    DEFAULT_DISPLAYED_DATE_FORMAT,
  )
  return `${estimatedDeliveryDate} (est)`
}

export const createDisplayedActualDeliveryDateForFullDetailsModal = row => {
  if (!row.orderId) {
    return <NotApplicable />
  }
  if (!row.dateReceived) {
    return <span>not yet delivered</span>
  }
  return formatApiDate(row.dateReceived, DEFAULT_DISPLAYED_DATE_FORMAT)
}

export const createDisplayedActualDeliveryDateForCsv = row => {
  if (!row.orderId || !row.dateReceived) {
    return null
  }
  return formatApiDate(row.dateReceived, DEFAULT_DISPLAYED_DATE_FORMAT)
}

export const createDisplayedEstimatedDeliveryDateForFullDetailsModal = row => {
  if (
    !row.orderId
  ) {
    return <NotApplicable />
  }
  if (
    !row.estimatedDeliveryDate && !row.plannedDeliveryDate
  ) {
    return <span>no estimate yet</span>
  }
  const estimatedDeliveryDate = row.estimatedDeliveryDate || row.plannedDeliveryDate
  return formatApiDate(estimatedDeliveryDate, DEFAULT_DISPLAYED_DATE_FORMAT)
}

export const createDisplayedEstimatedDeliveryDateForCsv = row => {
  if (
    !row.orderId ||
    (!row.estimatedDeliveryDate && row.plannedDeliveryDate)
  ) {
    return null
  }
  const estimatedDeliveryDate = row.estimatedDeliveryDate || row.plannedDeliveryDate
  return formatApiDate(estimatedDeliveryDate, DEFAULT_DISPLAYED_DATE_FORMAT)
}

export function createItemSkuIdFieldsDefinitions({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  customerType,
  // For most types of shipments (e.g. outbound, keg fills), get the quantities
  // from the lineItems prop whose linkType is 'SHIPPED'. The only exception is
  // inbound empty keg shipments, in which case use items from the lineItems
  // prop whose linkType is 'ACKED' (if appropriate, otherwise, use 'SHIPPED').
  // This is necessary for inbound shipments arranged by MicroStar to fulfill a
  // keg order (in contrast to inbound shipments initiated by the web app user,
  // such as empty kegs from self-dist/pub) because if a user acknowledges an
  // inbound shipment claiming that a different number of kegs arrived than what
  // the distributor claimed were shipped, the web app should display the user's
  // acknowledged numbers in the table, not the shipped numbers.
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged=false,
}) {
  return (
    itemSkuIdsToIncludeAsFieldsInTable.map(itemSkuId => {
      // Warehouse customer types need to be shown the Quality level (Sorted,
      // Unsorted, etc) in the Full Details modal; see
      // https://microstartap3.atlassian.net/browse/TP3-5364
      const heading = customerType === CUSTOMER_TYPES_WAREHOUSE
        ? `${getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice)} ${capitalize_(entireItemSkusSlice[itemSkuId].qualityLevel)}`
        : getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice)
      return {
        heading,
        cellContent: row => {
          const ackedLineItemObj = row.lineItems.find(lineItem => (
            lineItem.itemSkuId === itemSkuId && lineItem.linkType === 'ACKED'
          ))
          let linkType
          if (
            useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged
            && isPurposeOfShipmentToFulfillKegOrder(row)
            && ackedLineItemObj
            && !inboundEmptyKegShipmentHistoryStatusesConfig[
              row.status].displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder
          ) {
            linkType = 'ACKED'
          } else {
            linkType = 'SHIPPED'
          }
          const lineItemObj = row.lineItems.find(lineItem => (
            lineItem.itemSkuId === itemSkuId && lineItem.linkType === linkType
          ))
          return lineItemObj
            ? lineItemObj.quantity
            : 0
        },
        className: 'max-width-100px',
        includeInTotalsRow: true,
      }
    })
  )
}

export const createContainerFieldsDefinitionsForKegFills = ({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  // includeLocalKegsColumns, // CODE_COMMENTS_101
}) => {
  const uniqueContainerTypes = Array.from(itemSkuIdsToIncludeAsFieldsInTable.reduce((containerTypes, itemSkuId) => {
    const containerType = getContainerTypeFromItemSkuId({
      entireItemSkusSlice,
      itemSkuId,
    })
    if (!containerType) return containerTypes
    return containerTypes.add(containerType)
  }, new Set()))
  return uniqueContainerTypes.reduce((defs, containerType, index) => {
    // const containerType = getContainerTypeFromItemSkuId({
    //   entireItemSkusSlice,
    //   itemSkuId,
    // })
    const newDefs = defs.slice() // keep it functional
    // const orderedLabel = includeLocalKegsColumns
    //   ? `Ordered ${containerType}`
    //   : containerType
    // const localLabel = `Local ${containerType}`
    newDefs[index] = {
      heading: containerType,
      cellContent: row => {
        const sumOfQuantity = row.lineItems.map(
          lineItem => {
            if (entireItemSkusSlice?.[lineItem?.itemSkuId]?.containerType === containerType &&
              entireItemSkusSlice?.[lineItem?.itemSkuId]?.skuType === ITEM_SKUS_SKU_TYPE_COMPOSITE) {
              const { childItemSkuObjects=[] } = entireItemSkusSlice?.[lineItem?.itemSkuId] || {}
              // eslint-disable-next-line max-len
              const childSkuQuantity = childItemSkuObjects?.filter(({ id }) => entireItemSkusSlice?.[id]?.skuType === ITEM_SKUS_SKU_TYPE_KEG).reduce(
                (previousValue, { quantity = 0 }) => previousValue + quantity,
                0,
              )
              return {
                quantity: childSkuQuantity * (lineItem?.quantity || 0),
              }
            } else if (entireItemSkusSlice?.[lineItem?.itemSkuId]?.containerType === containerType) {
              return { quantity: lineItem?.quantity || 0 }
            } else {
              return { quantity: 0 }
            }
          },
        )?.reduce(
          (previousValue, { quantity = 0 }) => previousValue + quantity,
          0,
        ) // sum all the same container types
        return sumOfQuantity || 0
      },
      includeInTotalsRow: true,
    }
    // if (includeLocalKegsColumns) {
    //   newDefs[index+uniqueContainerTypes.length] = {
    //     heading: localLabel,
    //     cellContent: row => {
    //       const sumOfQuantity = row.orderLineItemObjects.filter(
    // eslint-disable-next-line max-len
    //          const lineItemObj = row.orderLineItemObjects.find(lineItem => lineItem.itemSkuId === itemSkuId && lineItem.orderType === 'LOCAL')
    //       )
    //         ?.reduce(
    //           (previousValue, { quantity = 0 }) => previousValue + quantity,
    //           0,
    //         ) // sum all the same container types
    //       return sumOfQuantity || 0
    //     },
    //     includeInTotalsRow: true,
    //   }
    // }
    return newDefs
  }, [])
}

export function createContainerTypesFieldsDefinitions({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  // For most types of shipments (e.g. outbound, keg fills), get the quantities
  // from the lineItems prop whose linkType is 'SHIPPED'. The only exception is
  // inbound empty keg shipments, in which case use items from the lineItems
  // prop whose linkType is 'ACKED' (if appropriate, otherwise, use 'SHIPPED').
  // This is necessary for inbound shipments arranged by MicroStar to fulfill a
  // keg order (in contrast to inbound shipments initiated by the web app user,
  // such as empty kegs from self-dist/pub) because if a user acknowledges an
  // inbound shipment claiming that a different number of kegs arrived than what
  // the distributor claimed were shipped, the web app should display the user's
  // acknowledged numbers in the table, not the shipped numbers.
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged=false,
}) {
  const uniqueContainerTypes = Array.from(itemSkuIdsToIncludeAsFieldsInTable.reduce((containerTypes, itemSkuId) => {
    const containerType = getContainerTypeFromItemSkuId({
      entireItemSkusSlice,
      itemSkuId,
    })
    if (!containerType) return containerTypes
    return containerTypes.add(containerType)
  }, new Set()))
  return (
    uniqueContainerTypes.map(containerType => ({
      heading: containerType,
      cellContent: row => {
        const ackedLineItemObj = row.lineItems.filter(lineItem => (
          lineItem.itemSkuId.indexOf(containerType) > -1 && lineItem.linkType === 'ACKED'
        ))
        let linkType
        if (
          useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged
          && isPurposeOfShipmentToFulfillKegOrder(row)
          && ackedLineItemObj.length > 0
          && !inboundEmptyKegShipmentHistoryStatusesConfig[
            row.status].displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder
        ) {
          linkType = 'ACKED'
        } else {
          linkType = 'SHIPPED'
        }
        const sumOfQuantity = row.lineItems.filter(lineItem => (
          entireItemSkusSlice?.[lineItem?.itemSkuId]?.containerType === containerType
            && lineItem.linkType === linkType
        ))?.reduce(
          (previousValue, { quantity = 0 }) => previousValue + quantity,
          0,
        )
        return sumOfQuantity || 0
      },
      includeInTotalsRow: true,
    }))
  )
}


// Technically this is only being used for inbound empty keg shipments. If the
// purpose of the shipment is to fulfill a keg order, then this component
// displays the the Foreign Kegs field of the shipment object, which will be a
// number. Otherwise, displays 'n/a' in grey.
export const createDisplayedForeignKegs = (
  renderNullRatherThanAlternateTextIfShipmentPurposeIsNotToFulfillKegOrder=false,
) => row => {
  if (isPurposeOfShipmentToFulfillKegOrder(row)) {
    return getItemSkuIdQtyFromLineItem({
      lineItems: row.lineItems,
      itemSkuId: ITEM_SKU_IDS_UNKNOWN_FOREIGN_KEG,
    }) || 0
  }
  return renderNullRatherThanAlternateTextIfShipmentPurposeIsNotToFulfillKegOrder
    ? null
    : <NotApplicable />
}

export const createDisplayedAddress = row => (
  processAddressObjectForDisplay(row?.destinationAddressObject || {}).map(line => (
    <span
      key={line}
    >
      {line}
      <br />
    </span>
  ))
)

export const createScheduledShipmentAddress = row => (
    processAddressObjectForScheduleShipmentAddress(row?.destinationAddressObject || {}))

export function createValveTypeDefinition({
  entireItemSkusSlice,
  itemSkuIdsToIncludeAsFieldsInTable,
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
  // As of Dec 2022, only Warehouses get this field, and for our warehouse
  // customers, we refer to "Valve Type" as "Spear Type", shortened to "Spear"
  // for the history table.
  heading='Spear',
}) {
  return {
    heading,
    cellContent: row => {
      const ackedLineItems = row.lineItems.filter(lineItem => lineItem.linkType === 'ACKED')
      let linkType
      if (
        useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged
        && isPurposeOfShipmentToFulfillKegOrder(row)
        && ackedLineItems.length > 0
        && !inboundEmptyKegShipmentHistoryStatusesConfig[
          row.status].displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder
      ) {
        linkType = 'ACKED'
      } else {
        linkType = 'SHIPPED'
      }
      const lineItemsWhoseValveTypeWeWantToDisplay = row.lineItems.filter(lineItem => {
        if (lineItem.linkType !== linkType) { return false }
        // Exclude line items with a null valveType (i.e. pallets)
        const valveType = entireItemSkusSlice?.[lineItem.itemSkuId]?.valueType
        return Boolean(valveType)
      })
      // Most shipments will have line items with all the same valveType. If
      // not, return a comma-separated list of them
      const valveTypes = uniq_(lineItemsWhoseValveTypeWeWantToDisplay.map(o => (
        entireItemSkusSlice[o.itemSkuId].valueType
      )))
      if (valveTypes.length === 0) {
        return null
      } else if (valveTypes.length === 1) {
        return valveTypes[0]
      }
      const valveTypesSortedByItemSkuIdsToIncludeAsFieldsInTable = sortArrayByTemplateArray(
        valveTypes,
        uniq_(itemSkuIdsToIncludeAsFieldsInTable.map(itemSkuId => entireItemSkusSlice[itemSkuId].valueType)),
      )
      return valveTypesSortedByItemSkuIdsToIncludeAsFieldsInTable.join(', ')
    },
    className: 'testValveType', // CODE_COMMENTS_57
  }
}


export function createQualityLevelDefinition({
  entireItemSkusSlice,
  useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged,
  heading='Quality', // shortened from 'Quality Level' for the history table
}) {
  return {
    heading,
    cellContent: row => {
      const ackedLineItems = row.lineItems.filter(lineItem => lineItem.linkType === 'ACKED')
      let linkType
      if (
        useAckedKegsInsteadOfShippedKegsIfThisShipmentFulfillsAnOrderAndHasBeenAcknowledged
        && isPurposeOfShipmentToFulfillKegOrder(row)
        && ackedLineItems.length > 0
        && !inboundEmptyKegShipmentHistoryStatusesConfig[
          row.status].displayShippedKegsRatherThanAckedKegsIfShipmentInThisStatusFulfillsAnOrder
      ) {
        linkType = 'ACKED'
      } else {
        linkType = 'SHIPPED'
      }
      const lineItemsWhoseQualityLevelWeWantToDisplay = row.lineItems.filter(lineItem => {
        if (lineItem.linkType !== linkType) { return false }
        // Exclude line items with a null qualityLevel (i.e. pallets)
        const qualityLevel = entireItemSkusSlice?.[lineItem.itemSkuId]?.qualityLevel
        return Boolean(qualityLevel)
      })
      // Most shipments will have line items with all the same qualityLevel. If
      // not, return the string "Multiple"
      const qualityLevels = uniq_(lineItemsWhoseQualityLevelWeWantToDisplay.map(o => (
        entireItemSkusSlice[o.itemSkuId].qualityLevel
      )))
      if (qualityLevels.length === 0) {
        return null
      } else if (qualityLevels.length === 1) {
        return capitalize_(qualityLevels[0])
      }
      return 'Multiple'
    },
    className: 'testQualityLevel', // CODE_COMMENTS_57
  }
}
