import React from 'react'
import { Header } from 'semantic-ui-react'

import { useTranslation } from 'react-i18next'
import ContactCard from '../../../../../common-components/ContactCard'

import {
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
} from '../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../../../constants'


export default ({
  fieldArraysAndCustomersFieldsDropdownOptions,
  customerId,
  children,
}) => {
  const customersFieldsDropdownOptionsOfFieldThatMustBeChosenFirstOfEachFormSection =
    fieldArraysAndCustomersFieldsDropdownOptions.map(o => o.customersFieldsDropdownOptions)
  const areThereAnyCustomerOptionssThatMustBeChosenFirst =
    customersFieldsDropdownOptionsOfFieldThatMustBeChosenFirstOfEachFormSection.some(
      arr => arr.length > 0,
    )

  if (areThereAnyCustomerOptionssThatMustBeChosenFirst) {
    return children
  }

  // just choose the first one
  const whichCustomersFieldMustBeChosenFirstSourceOrDestination =
    fieldArraysAndCustomersFieldsDropdownOptions?.[0]?.whichCustomersFieldMustBeChosenFirstSourceOrDestination

  const fieldThatMustBeChosenFirst =
    whichCustomersFieldMustBeChosenFirstSourceOrDestination ===
      WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE
      ? 'source'
      : 'destination'
  const { t: translate } = useTranslation('pagelabels')

  return (
    <div>
      <Header as="h3" inverted color="red">
        {translate(`reportShipments.You have no ${fieldThatMustBeChosenFirst} customers!`)}
      </Header>
      <p>{translate(`reportShipments.Looks like you're not configured with any valid ${fieldThatMustBeChosenFirst} customers to ship ${fieldThatMustBeChosenFirst === 'source' ? 'from' : 'to'}. This usually happens because one or more of your contracts with us has expired or because you don't have customer-level permissions to report these shipments. Contact your personalized representative, they'll be able to help you report these shipments.`)}</p>
      <ContactCard customerId={customerId} repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE} />
    </div>
  )
}
