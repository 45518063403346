// CODE_COMMENTS_233
import React from 'react'


import InputNoAutoComplete from '../../../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'


export default ({
  input,
  shouldHaveWarningClassApplied,
  ...rest
}) => {
  const passedInClassName = rest.className
  const warningClassName = shouldHaveWarningClassApplied
    // Why are we using 'input-orange-semantic-ui-container' here rather
    // than 'input-orange-semantic-ui'? Because Semantic UI's <Input>
    // component doesn't render just <input> in the resulting HTML, but
    // rather <div><input /></div>. We want to target the <input> but we
    // can only set the class name on the wrapping <div>, so this
    // 'input-orange-semantic-ui-container' class uses a little css magic
    // to target this div's children.
    ? 'input-orange-semantic-ui-container'
    : null

  let className
  if (!passedInClassName && !warningClassName) {
    className = null
  } else {
    className = [
      ...(passedInClassName ? [passedInClassName] : []),
      ...(warningClassName ? [warningClassName] : []),
    ].join(' ')
  }

  return (
    <InputNoAutoComplete
      {...input}
      {...rest}
      className={className}
    />
  )
}
