import React from 'react'


import ShipmentHistoryFormCreator from '../../HOC/ShipmentHistoryFormCreator'

import DownloadInboundEmptyKegShipmentHistoryTableAsFile from '../DownloadTableAsFile'

import {
  inboundEmptyKegShipmentHistoryStatusesConfig,
} from '../../util/shipmentHistory/inboundEmptyKegShipmentHistory'

import { FETCH_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY } from '../../../../../redux/actions/actionTypes'


export default props => (
  <ShipmentHistoryFormCreator
    {...props}
    downloadFileComponent={DownloadInboundEmptyKegShipmentHistoryTableAsFile}
    statusesConfig={inboundEmptyKegShipmentHistoryStatusesConfig}
    submitAction={FETCH_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY}
    inboundOrOutbound="inbound"
  />
)
