import { call, put, select } from 'redux-saga/effects'


import {
  SAVE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
} from '../../../../actions/actionTypes'
import createAction from '../../../../actions/createAction'


import {
  getProp as getCustomerProp,
} from '../../../../selectors/customers'

import {
  REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../../../../constants/formAndApiUrlConfig/reportOutboundShipments'
import {
  REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
} from '../../../../../constants/formAndApiUrlConfig/reportKegFills'

import {
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
} from '../../../../../constants/formAndApiUrlConfig/reportBuybackShipments'

import {
  FIELD_NAME_SOURCE_CUSTOMER,
  FIELD_NAME_DESTINATION_CUSTOMER,
  FIELD_NAME_INTERNAL_REFERENCE_NUM,
  FIELD_NAME_DATE_SHIPPED,
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
} from '../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  CUSTOMER_TYPES_BREWER,
  SHIPMENT_TYPES_BUYBACK,
  SHIPMENT_TYPES_REPORTED,
} from '../../../../../constants'

import {
  createContractMetadataObject,
} from '../../../util/contractMetadataObject'

import {
  fieldArraysToApplyPotentialDuplicatesFeatureTo,
} from '../../../../../features/ReportShipments/util'

import {
  getDoesDateFallWithinDateRangeOfPotentialDuplicates,
} from '../../../../../utils/reportShipmentsFormsPotentialDuplicates'

import {
  createShipmentType,
  formatDateForApiCall,
  parseCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'

// This works because all Report Shipments/Shipments forms are comprised of
// field arrays. See CODE_COMMENTS_58.
export const getAllFieldArrayNamesInReportShipmentsForm = values => (
  Object.keys(values)
)


/*
 * *****************************************************************************
 * Extracting Form Values
 * *****************************************************************************
 */

/**
 * The values calculated in this function are those needed by both the
 * reportIndividualOutboundShipment saga and the editOutboundShipmentReport
 * saga.
 */
export function* extractFormValues({
  customerId,
  fieldArrayName,
  fieldArraysAndCustomersFieldsDropdownOptions,
  rowValues,
  itemSkuIds,
}) {
  const fieldArrayDef = fieldArraysAndCustomersFieldsDropdownOptions.find(
    o => o.fieldArrayName === fieldArrayName,
  )
  const { itemSkuIdsForFieldArray } = fieldArrayDef || {}

  let originCustomerId
  let destinationCustomerId
  let contractBrewerCustomerId
  if (
    fieldArrayDef.whichCustomersFieldMustBeChosenFirstSourceOrDestination ===
      WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE
  ) {
    (
      {
        customerId: originCustomerId,
        contractBrewerCustomerId,
      } =
        parseCustIdAndOptionalConbrwCustIdReduxIdentifier(
          rowValues[FIELD_NAME_SOURCE_CUSTOMER],
        )
    )
    destinationCustomerId = rowValues[FIELD_NAME_DESTINATION_CUSTOMER]
  } else {
    (
      {
        customerId: destinationCustomerId,
        contractBrewerCustomerId,
      } =
        parseCustIdAndOptionalConbrwCustIdReduxIdentifier(
          rowValues[FIELD_NAME_DESTINATION_CUSTOMER],
        )
    )
    originCustomerId = rowValues[FIELD_NAME_SOURCE_CUSTOMER]
  }

  /* After adding shipment type buyback for resale & defective, reverse movement is not required.
   So setting it as false always.
  const reverseMovement = [
    REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
    REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
  ].includes(fieldArrayName) */
  const reverseMovement = false
  const itemSkuIdsForRequestPayload = (itemSkuIdsForFieldArray?.length && itemSkuIdsForFieldArray) || itemSkuIds

  let shipmentType
  if ([
    REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
    REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_DEFECTIVE_KEG,
  ].includes(fieldArrayName)) {
    shipmentType = SHIPMENT_TYPES_BUYBACK
  } else {
    shipmentType = SHIPMENT_TYPES_REPORTED
  }

  const loadType = null

  // Origin and Destination need to be swapped if this is a reverse shipment
  if (reverseMovement) {
    [originCustomerId, destinationCustomerId] = [destinationCustomerId, originCustomerId]
  }

  const movementType = yield call(
    craftShipmentType,
    originCustomerId,
    destinationCustomerId,
  )

  const proNumber = rowValues[FIELD_NAME_INTERNAL_REFERENCE_NUM] || null

  const dateShipped = formatDateForApiCall({ date: rowValues[FIELD_NAME_DATE_SHIPPED] })

  // const configuredItemSkuIds = yield select(getCustomerProp, customerId, 'itemSkuIds')
  const lineItems = itemSkuIdsForRequestPayload?.filter(itemSkuId => rowValues[itemSkuId])?.map(itemSkuId => ({
    itemSkuId,
    quantity: Number(rowValues[itemSkuId]),
    linkType: 'SHIPPED',
  }))

  const customerType = yield select(getCustomerProp, customerId, 'customerType')
  const conbrwCustomerIdIfThisIsAKegFillCreatedByABrw = (
    fieldArrayName === REPORT_KEG_FILLS_FIELD_ARRAY_NAME
    && customerType === CUSTOMER_TYPES_BREWER
    && originCustomerId
  )

  // eslint-disable-next-line no-underscore-dangle
  const _contractMetadataObject = yield call(
    createContractMetadataObjectForShipmentReport,
    {
      customerId,
      contractBrewerCustomerId,
      fieldArrayName,
      destinationCustomerId,
      conbrwCustomerIdIfThisIsAKegFillCreatedByABrw,
      shipmentType,
    },
  )

  return {
    movementType,
    originCustomerId,
    destinationCustomerId,
    reverseMovement,
    proNumber,
    dateShipped,
    lineItems,
    _contractMetadataObject,
    shipmentType,
    loadType,
  }
}


/*
 * *****************************************************************************
 * Shipment type
 * *****************************************************************************
 */

function* craftShipmentType(
  originCustomerId,
  destinationCustomerId,
) {
  const customerTypeofOriginCustomerId = yield select(getCustomerProp, originCustomerId, 'customerType')
  const customerTypeofDestinationCustomerId = yield select(getCustomerProp, destinationCustomerId, 'customerType')

  return createShipmentType(
    customerTypeofOriginCustomerId,
    customerTypeofDestinationCustomerId,
  )
}


/*
 * *****************************************************************************
 * _contractMetadataObject (CODE_COMMENTS_133)
 * *****************************************************************************
 */

// CODE_COMMENTS_133
function* createContractMetadataObjectForShipmentReport({
  // Here, customerId is the customer currently being operated for (whether it's
  // a child currently being operated for by a Master or a contractee currently
  // being operated for by a Contract Brewer). If no customer is currently being
  // operated for, this is just the logged-in customer.
  customerId,
  contractBrewerCustomerId,
  fieldArrayName,
  destinationCustomerId,
  conbrwCustomerIdIfThisIsAKegFillCreatedByABrw,
  shipmentType,
}) {
  const customerType = yield select(getCustomerProp, customerId, 'customerType')

  const destinationCustomerType = yield select(getCustomerProp, destinationCustomerId, 'customerType')


  // When a logged-in Contract Brewer operates on behalf of one of its
  // contractees or when a logged-in Brewer reports an outbound shipment from
  // one of its Contract Brewer locations.
  if (contractBrewerCustomerId) {
    return createContractMetadataObject(
      shipmentType === SHIPMENT_TYPES_BUYBACK ? 'CONBRW' : contractBrewerCustomerId,
      shipmentType === SHIPMENT_TYPES_BUYBACK
        ? contractBrewerCustomerId
        : destinationCustomerType === CUSTOMER_TYPES_BREWER
        // As of December, 2018, the web app does not allow BRW2BRW shipments
        // from a contract brewing location to another Brewer, but the web app
        // could be extended in the future.
          ? 'BRW'
          : null,
    )
  }


  // If a logged-in Contract Brewer reports a keg fill
  if (
    fieldArrayName === REPORT_KEG_FILLS_FIELD_ARRAY_NAME
  ) {
    if (customerType === CUSTOMER_TYPES_BREWER) {
      return createContractMetadataObject(
        conbrwCustomerIdIfThisIsAKegFillCreatedByABrw,
        conbrwCustomerIdIfThisIsAKegFillCreatedByABrw,
      )
    }
    return createContractMetadataObject(customerId, customerId)
  }


  // If a logged-in brewer is reporting an outbound shipment to a Brewer it's
  // related to
  if (
    fieldArrayName === REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME &&
    customerType === CUSTOMER_TYPES_BREWER &&
    destinationCustomerType === CUSTOMER_TYPES_BREWER
  ) {
    return createContractMetadataObject('BRW', 'BRW')
  }


  // For any type of shipment not covered above
  return createContractMetadataObject('BRW', null)
}


/*
 * *****************************************************************************
 * Saving to Potential Duplicates
 * *****************************************************************************
 */

export function* saveShipmentObjectToPotentialDuplicatesSliceIfMeetsCriteria({
  customerId,
  operatingContractBrewerCustomerId,
  fieldArrayName,
  shipmentObj,
}) {
  if (
    fieldArraysToApplyPotentialDuplicatesFeatureTo.includes(fieldArrayName)
    && getDoesDateFallWithinDateRangeOfPotentialDuplicates({ date: shipmentObj.dateShipped })
  ) {
    yield put(createAction(
      SAVE_SINGLE_SHIPMENT_OF_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
      {
        customerId,
        operatingContractBrewerCustomerId,
        shipmentObj,
      },
    ))
  }
}
