import get_ from 'lodash/get'
import without_ from 'lodash/without'

import flow_ from 'lodash/fp/flow'
import uniqFp_ from 'lodash/fp/uniq'
import mapFp_ from 'lodash/fp/map'


// import {
//   getAllChildCustomerObjectsOfCustomer,
// } from '../../redux/selectors/rewrite/children'

import {
  DISPLAYED_CONTACT_TYPE_SHIPPING,
  DISPLAYED_CONTACT_TYPE_BILLING,
  DISPLAYED_CONTACT_TYPE_SHIPPING_AND_BILLING,
  EMAIL_SUBSCRIPTION_PROPS,
} from './constants'

import {
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_MASTER,
} from '../../constants'
import { getAllContactObjectsOfContact, getAllContactsOfCustomer } from '../../redux/selectors/contacts'


// Functions that are needed by, for instance, both the
// AddOrEditIndividualContact and DeleteIndividualContact components

// https://microstartap3.atlassian.net/browse/TP3-3042
export function getIsContactTheOnlyInvoiceRecipient({ contact, contacts }) {
  if (!contact || !contacts) { return false }
  const contactsWithReceiveInvoice = contacts.filter(o => o.receiveInvoice)
  return contactsWithReceiveInvoice.length === 1 && contactsWithReceiveInvoice[0].id === contact.id
}


export function getHumanReadableContactTypeFromContactMashupObj(o) {
  const isShippingContact = get_(o, 'shippingContact')
  const isBillingContact = get_(o, 'billingContact')
  if (isShippingContact && !isBillingContact) { return DISPLAYED_CONTACT_TYPE_SHIPPING }
  if (!isShippingContact && isBillingContact) { return DISPLAYED_CONTACT_TYPE_BILLING }
  if (isShippingContact && isBillingContact) { return DISPLAYED_CONTACT_TYPE_SHIPPING_AND_BILLING }
  return null // if neither
}


// Returns an object:
// {
//   billingContact: true,
//   shippingContact: false,
// }
export function convertHumanReadableContactTypeToCustomerContactLinkObjProps(s) {
  const toReturn = {
    billingContact: false,
    shippingContact: false,
  }
  if (s === DISPLAYED_CONTACT_TYPE_SHIPPING) {
    toReturn.shippingContact = true
  } else if (s === DISPLAYED_CONTACT_TYPE_BILLING) {
    toReturn.billingContact = true
  } else if (s === DISPLAYED_CONTACT_TYPE_SHIPPING_AND_BILLING) {
    toReturn.shippingContact = true
    toReturn.billingContact = true
  }
  return toReturn
}


// returns a subset of EMAIL_SUBSCRIPTION_PROPS
export function getApplicableEmailSubscriptionPropsBasedOnCustomerType({
  contactId,
  customerId,
  customerType,
  entireCustomersSlice,
  entireParentChildLinksSlice,
  entireCustomerContactLinksSlice,
  entireContactsSlice,
}) {
  const nonDistProps = EMAIL_SUBSCRIPTION_PROPS.filter(o => o.name !== 'receiveDistInvReportForm')
  const distProps = EMAIL_SUBSCRIPTION_PROPS.filter(o => ['receiveDistInvReportForm', 'receiveInvoice'].includes(o.name))

  if (customerType === 'CONTACT') {
    const childCustomerTypes = flow_(
      () => getAllContactObjectsOfContact({
        entireContactsSlice,
        entireCustomerContactLinksSlice,
        contactId,
        currentlyActiveContactsOnly: true,
      }),
      mapFp_(o => entireCustomersSlice?.[o.customerId]?.customerType),
      uniqFp_,
    )()
    if (childCustomerTypes.length === 0) { // no active children
      return nonDistProps
    }
    const nonDistChildTypes = without_(childCustomerTypes, CUSTOMER_TYPES_DISTRIBUTOR)
    const hasDistChildren = childCustomerTypes.includes(CUSTOMER_TYPES_DISTRIBUTOR)
    const hasNonDistChildren = nonDistChildTypes.length > 0
    if (hasDistChildren && hasNonDistChildren) {
      return EMAIL_SUBSCRIPTION_PROPS
    } else if (hasDistChildren && !hasNonDistChildren) {
      return distProps
    }
    return nonDistProps
  } else if (customerType === CUSTOMER_TYPES_DISTRIBUTOR) {
    return distProps
  } else if (customerType === CUSTOMER_TYPES_MASTER) {
    const childCustomerTypes = flow_(
      () => getAllContactsOfCustomer({
        customerType,
        entireCustomersSlice,
        entireParentChildLinksSlice,
        customerId,
        onlyCustomersWhoAreNotCurrentlyInactive: true,
      }),
      mapFp_(o => o.customerType),
      uniqFp_,
    )()
    if (childCustomerTypes.length === 0) { // no active children
      return nonDistProps
    }
    const nonDistChildTypes = without_(childCustomerTypes, CUSTOMER_TYPES_DISTRIBUTOR)
    const hasDistChildren = childCustomerTypes.includes(CUSTOMER_TYPES_DISTRIBUTOR)
    const hasNonDistChildren = nonDistChildTypes.length > 0
    if (hasDistChildren && hasNonDistChildren) {
      return EMAIL_SUBSCRIPTION_PROPS
    } else if (hasDistChildren && !hasNonDistChildren) {
      return distProps
    }
    return nonDistProps
  }
  // Brw or Conbrw customer passed in
  return nonDistProps
}
