/**
 * CODE_COMMENTS_220
 */

/* eslint-disable max-len */

import {
  getPropPassingInEntireCustomersSlice as getCustomerProp,
} from '../../../../redux/selectors/rewrite/customers'

import {
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ,
  INFO_JUST_FOR_THE_WEB_APP,
  INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME,
} from '../constants'

import {
  parseCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../utils'


// For Report Inventory forms (as opposed to Edit Inventory forms)
export function gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist({
  entireCustomersSlice,
  customerId,
}) {
  const distName = getCustomerProp(entireCustomersSlice, customerId, 'name')

  return {
    [INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ]: {
      [INFO_JUST_FOR_THE_WEB_APP]: {
        [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: distName,
      },
      customerId,
    },
  }
}


// For Edit form and full details modals
export function gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist({
  entireCustomersSlice,
  customerId,
  // An object representing one row in the history table and containing all
  // the individual inventory objects for this single as-displayed-to-the-user
  // Inventory Report object (see CODE_COMMENTS_229 for details):
  // {
  //   countDate: 1567277112000,
  //   inventoryReportObjsAndReduxIdentifiers: [
  //     { reduxIdentifier: brwCustId, inventoryReportObj: <inventoryReportObj> },
  //     { reduxIdentifier: childPubCustId, inventoryReportObj: <inventoryReportObj> },
  //     { reduxIdentifier: brwCustId__--__conbrwCustId, inventoryReportObj: <inventoryReportObj> },
  //   ]
  // }
  countDateAndInventoryReportObjsWithReduxIdentifiers,
}) {
  const { inventoryReportObjsAndReduxIdentifiers } = countDateAndInventoryReportObjsWithReduxIdentifiers
  const distName = getCustomerProp(entireCustomersSlice, customerId, 'name')

  const inventoryReportObjAndReduxId = inventoryReportObjsAndReduxIdentifiers.find(o => {
    const {
      customerId: customerIdOfReduxId,
    } = parseCustIdAndOptionalConbrwCustIdReduxIdentifier(o.reduxIdentifier)
    return (
      customerIdOfReduxId === customerId
    )
  })

  const inventoryReportObjOfDistributor = inventoryReportObjAndReduxId &&
    inventoryReportObjAndReduxId.inventoryReportObj

  return {
    [INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ]: {
      [INFO_JUST_FOR_THE_WEB_APP]: {
        [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: distName,
      },
      ...inventoryReportObjOfDistributor,
    },
  }
}
