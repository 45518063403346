import { connect } from 'react-redux'


import DownloadCollarOrderHistoryAsFile from './components'

import {
  getEntireSlice as getAllCollarOrderHistoryObjects,
} from '../../../../../redux/selectors/histories/collarOrderHistory'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'


import {
  getAllCollarOrderObjectsToBeIncludedInHistoryTable,
  getAllAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
  getAllCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
} from '../../util/collarOrderHistory'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


const mapStateToProps = (
  state,
  {
    customerId,
    operatingContractBrewerCustomerId,
    formName: historyFormName,
  },
) => {
  const mostRecentlySubmittedFormValues = getMostRecentlySubmittedFormValues(state, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return {} }

  const allCollarOrderObjects = getAllCollarOrderHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  const allCollarOrderObjectsToBeIncludedInHistoryTable = getAllCollarOrderObjectsToBeIncludedInHistoryTable(
    allCollarOrderObjects,
  )

  const allAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile =
    getAllAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile({
      state,
      arrayOrObjectOfObjects: allCollarOrderObjectsToBeIncludedInHistoryTable,
    })

  const allCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile =
    getAllCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile({
      state,
      arrayOrObjectOfObjects: allCollarOrderObjectsToBeIncludedInHistoryTable,
    })


  return {
    allCollarOrderObjects,
    allAddressObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
    allCollarPlateObjectsToBeIncludedInDownloadCollarOrderHistoryFile,
    mostRecentlySubmittedFormValues,
  }
}


export default connect(
  mapStateToProps,
)(DownloadCollarOrderHistoryAsFile)
