import { connect } from 'react-redux'

import ErrorMessages from './components'


import createAction from '../../../../../redux/actions/createAction'
import { FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS } from '../../../../../redux/actions/actionTypes'

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
})

const mapDispatchToProps = dispatch => ({
  fetchPrelimInfoOfAllCustomers: () => dispatch(createAction(FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorMessages)
