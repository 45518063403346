import keys_ from 'lodash/keys'
import get_ from 'lodash/get'

import {
  drillDownInObject,
} from '../../utils'
import { NestedPropNotInObjectError } from '../../customErrors'


export const getEntireSlice = (...slices) => (state, ...entities) => {
  try {
    return drillDownInObject(state, ...slices, ...entities)
  } catch (e) {
    if (e instanceof NestedPropNotInObjectError) { return undefined }
    throw e
  }
}

/**
 * A higher-order function that acts as a surrogate for a getter function
 * that might look like:
 *
 * const getCustomerType = (state, customerId) => (
 *   state[defaultSlice].byCustomerId[customerId].customerType
 * )
 *
 * With this higher-order function, all you have to do is create the getter
 * once in the file:
 *
 * const withCustomer = withProp(defaultSlice, 'byCustomerId')
 *
 * Then, all your getters can be shorter and DRYer:
 *
 * getCustomerType = withCustomer('customerType')
 */
export const withProp = (...slices) => property => (state, ...entities) => (
  drillDownInObject(state, ...slices, ...entities, property)
)


// Like withProp but you pass the actual prop you're searching for as the final
// arg in the ultimate selector function rather than into an intermediary
// function.
export const withPropSimple = (...slices) => (state, ...entitiesAndProp) => (
  drillDownInObject(state, ...slices, ...entitiesAndProp)
)


// Like withPropSimple except it won't throw an error if the nested prop isn't
// in the object; rather it will return undefined
export const withPropOrUndefinedSimple = (...slices) => (state, ...entitiesAndProp) => {
  try {
    return drillDownInObject(state, ...slices, ...entitiesAndProp)
  } catch (e) {
    if (e instanceof NestedPropNotInObjectError) {
      return undefined
    }
    throw e
  }
}


/**
 * Like withProp except that if your property is nested further than 'entities'
 * takes you, you can add additional nesting.
 */
export const withPropNested = (...slices) => (...nesting) => property => (state, ...entities) => (
  drillDownInObject(state, ...slices, ...entities, ...nesting, property)
)

/**
 * Similar to the withProp() HOF above except that instead of returning a value from the
 * store, it compares a store value with another value
 */
export const withPropEquals = (...slices) => (property, compareValue) => (state, ...entities) => (
  drillDownInObject(state, ...slices, ...entities, property) === compareValue
)


/**
 * Similar to the withProp() HOF above except instead of returning a single
 * property of a single entity, it returns an array of a specific property of
 * each entity in the state slice. For example:
 *
 * state = {
 *   customersKegOrderHistory: {
 *     byCustomerId: {
 *       1234m: {
 *         e45: {id: 'e45', requestedKegs: {HB: 624, SB: 559}, ...},
 *         f99: {id: 'f99', requestedKegs: {HB: 650}, ...},
 *       }
 *     }
 *   }
 * }
 *
 * If you make these calls:
 *
 * const getAll =  withPropOfAll('customersKegOrderHistory', 'byCustomerId')
 * const getAllRequestedKegs = getAll('requestedKegs')
 * const AllRequestedKegsOfAllOrders = getAllRequestedKegs(state, '1234m')
 *
 * then AllRequestedKegsOfAllOrders will equal [{HB: 624, SB: 559}, {HB: 650}]
 *
 */
export const withPropOfAll = (...slices) => property => (state, ...entities) => {
  const targetObj = drillDownInObject(state, ...slices, ...entities)
  return keys_(targetObj).map(key => targetObj[key][property])
}

// see withPropSimple documentation
export const withPropOfAllSimple = (...slices) => (state, ...entitiesAndProp) => {
  const prop = entitiesAndProp[entitiesAndProp.length-1]
  const entities = entitiesAndProp.slice(0, -1)
  const targetObj = drillDownInObject(state, ...slices, ...entities)
  return keys_(targetObj).map(key => targetObj[key][prop])
}


/**
 * Like withPropOfAll except that if your property is nested further than
 * 'entities' takes you, you can add additional nesting.
 */
export const withPropOfAllNested = (...slices) => (...nesting) => property => (state, ...entities) => {
  const targetObj = drillDownInObject(state, ...slices, ...entities)
  return keys_(targetObj).map(key => drillDownInObject(targetObj, key, ...nesting)[property],
  )
}

/**
 * Similar to the withPropOfAll() HOF above except instead of returning a list of
 * one specific property of each entity in the state slice, it returns a list of
 * objects which contain multiple properties of each entity in the state slice.
 * For example:
 *
 * state = {
 *   customersKegOrderHistory: {
 *     byCustomerId: {
 *       1234m: {
 *         e45: {id: 'e45', requestedKegs: {HB: 624, SB: 559}, ...},
 *         f99: {id: 'f99', requestedKegs: {HB: 650}, ...},
 *       }
 *     }
 *   }
 * }
 *
 * If you make these calls:
 *
 * const getMultiplePropsOfAll =  withMultiplePropsOfAll('customersKegOrderHistory', 'byCustomerId')
 * const getAllIdsAndRequestedKegs = getMultiplePropsOfAll('id', 'requestedKegs')
 * const AllIdsAndRequestedKegsOfAllOrders = getAllRequestedKegs(state, '1234m')
 *
 * then AllRequestedKegsOfAllOrders will equal:
 *
 * [
 *   {id: 'e45', requestedKegs: {HB: 624, SB: 559}},
 *   {id: 'f99', requestedKegs: {HB: 650} },
 * ]
 *
 */
export const withMultiplePropsOfAll = (...slices) => (...properties) => (state, ...entities) => {
  const targetObj = drillDownInObject(state, ...slices, ...entities)
  return keys_(targetObj).map(key => (
    properties.reduce((acc, property) => ({
      [property]: targetObj[key][property],
      ...acc,
    }), {})
  ))
}


/**
 * Like withMultiplePropsOfAll except that if your property is nested further than
 * 'entities' takes you, you can add additional nesting.
 */
export const withMultiplePropsOfAllNested = (...slices) => (...nesting) => (...properties) => (state, ...entities) => {
  const targetObj = drillDownInObject(state, ...slices, ...entities, ...nesting)
  return keys_(targetObj).map(key => (
    properties.reduce((acc, property) => ({
      [property]: targetObj[key][property],
      ...acc,
    }), {})
  ))
}


/*
 * *****************************************************************************
 * HOFs for normalized state
 * *****************************************************************************
*/

/**
 * like withProp except for normalized Redux slices, therefore we don't need as
 * much nesting.
 */
export const withPropNormalized = (...slices) => (state, id, prop) => (
  drillDownInObject(state, ...slices, id, prop)
)

// Like withPropNormalized except if the nested prop doesn't exist, return a
// default value rather than throwing an error.
export const withPropOrNormalized = (...slices) => (state, id, prop, defaultValueIfPathNotFound=undefined) => (
  get_(state, [...slices, id, prop], defaultValueIfPathNotFound)
)

// withPropOfAll for normalized Redux slices
export const withPropOfAllNormalized = (...slices) => (state, prop) => {
  const targetSlice = drillDownInObject(state, ...slices)
  return keys_(targetSlice).map(key => targetSlice[key][prop])
}

// withMultiplePropsOfAll for normalized Redux slices
export const withMultiplePropsOfAllNormalized = (...slices) => (state, ...props) => {
  const targetSlice = drillDownInObject(state, ...slices)
  return keys_(targetSlice).map(key => (
    props.reduce((acc, prop) => ({
      [prop]: targetSlice[key][prop],
      ...acc,
    }), {})
  ))
}
