/* eslint-disable max-len */

/**
 * CODE_COMMENTS_15
*/
import { put, all, call, select, takeEvery } from 'redux-saga/effects'
import { batchActions } from 'redux-batched-actions'


import { privateFetch } from '../fetch'
import {
  getRelatedFromCustomerInfoApiUrl,
  getRelatedFromRelationshipInfoApiUrl,
} from '../../../utils'
import {
  FETCH_CUSTOMER_RELATED_FROM_INFO,
  SAVE_CUSTOMERS,
  SAVE_RELATIONSHIPS,
  SAVE_RELATIONSHIPS_AND_CUSTOMERS_AT_THE_SAME_TIME,
  FETCH_CUSTOMER_RELATED_FROM_INFO_REQUEST,
  FETCH_CUSTOMER_RELATED_FROM_INFO_SUCCESS,
  FETCH_CUSTOMER_RELATED_FROM_INFO_FAILURE,
} from '../../actions/actionTypes'

import {
  getProp as getCustomerProp,
} from '../../selectors/customers'
import {
  getProp as getCurrentUserProp,
} from '../../selectors/currentUser'
import {
  getAllChildCustomerIdsOfCustomer,
} from '../../selectors/children'

import {
  getDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall,
  filterCustomerObjectsAndRelationshipObjectsThatHaveDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall,
  logErrorExplainingDiscrepancyBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall,
} from '../util/apiResponseDataValidation/relatedToOrFrom/relatedToOrFromMapVsNonMapDiscrepancies'
import {
  validateAndFilterRelationshipObjectsBasedOnBasicProps,
} from '../util/apiResponseDataValidation/relatedToOrFrom'
import {
  createQueryParametersForRelationshipApiCall,
} from '../util/headersAndQueryParamsOfApiCalls/relationships'
import {
  saveAddressesEmbeddedInCustomerObjs,
} from '../util/relatedToOrFrom'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  CUSTOMER_TYPES_MASTER,
} from '../../../constants'

import {
  ApiReturnedUnexpectedDataError,
} from '../../../customErrors'


export function* fetchCustomerRelatedFromInfo(action) {
  const {
    customerId,
    preventsDashboardFromLoadingIfFails,
  } = action.payload
  yield put(createAction(FETCH_CUSTOMER_RELATED_FROM_INFO_REQUEST, { target: [customerId] }))

  // CODE_COMMENTS_90
  const params = yield call(
    createQueryParametersForRelationshipApiCall,
    customerId,
  )

  const fetchConfigCustomersInfoCall = { path: getRelatedFromCustomerInfoApiUrl(customerId), params }
  const fetchConfigRelationshipsInfoCall = { path: getRelatedFromRelationshipInfoApiUrl(customerId), params }


  let customerInfoResponse
  let relationshipInfoResponse
  try {
    // CODE_COMMENTS_48
    [customerInfoResponse, relationshipInfoResponse] = yield all([
      call(privateFetch, fetchConfigCustomersInfoCall),
      call(privateFetch, fetchConfigRelationshipsInfoCall),
    ])
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) { // customer is not related from any other customers
        yield call(
          doSuccess,
          customerId,
          { ...error, data: [] },
          { ...error, data: {} },
        )
        return
      }
      yield call(
        doFailure,
        {
          customerId,
          error,
          preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
        },
      )
      return
    }
    yield call(
      doFailure,
      {
        customerId,
        error,
        preventsDashboardFromLoading: preventsDashboardFromLoadingIfFails,
      },
    )
    return
  }
  yield call(doSuccess, customerId, customerInfoResponse, relationshipInfoResponse)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CUSTOMER_RELATED_FROM_INFO, fetchCustomerRelatedFromInfo],
]


function* doSuccess(customerId, customerInfoResponse, relationshipInfoResponse) {
  const fResult = validateAndFilterRelationshipObjectsBasedOnBasicProps({
    customerInfoResponse,
    relationshipInfoResponse,
  })
  let {
    arrayOfCustomerObjects,
    arrayOfRelationshipObjects,
  } = fResult
  const {
    canDataBeSavedToStore,
    haveAnyCustomerOrRelationshipObjectsBeenFiltered: haveAnyCustomerOrRelationshipObjectsBeenFilteredBasedOnBasicProps,
  } = fResult

  if (!canDataBeSavedToStore) {
    throw new ApiReturnedUnexpectedDataError() // CODE_COMMENTS_180
  }

  const discrepanciesBetweenTheTwoCalls =
    getDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall(
      'from',
      arrayOfCustomerObjects,
      arrayOfRelationshipObjects,
    )

  if (discrepanciesBetweenTheTwoCalls) {
    ({
      arrayOfCustomerObjects,
      arrayOfRelationshipObjects,
    } = filterCustomerObjectsAndRelationshipObjectsThatHaveDiscrepanciesBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall(
      'from',
      arrayOfCustomerObjects,
      arrayOfRelationshipObjects,
      discrepanciesBetweenTheTwoCalls,
    ))

    // If any customer or relationship objects have been filtered because their
    // basic props have problems, then the discrepancies between customers and
    // relationships are probably due to that filtering rather than to the
    // original data sent by the backend.
    if (!haveAnyCustomerOrRelationshipObjectsBeenFilteredBasedOnBasicProps) {
      logErrorExplainingDiscrepancyBetweenRelatedToOrFromCallAndRelatedToOrFromMapCall(
        discrepanciesBetweenTheTwoCalls,
        customerInfoResponse,
        relationshipInfoResponse,
      )
    }
  }

  yield call(saveAddressesEmbeddedInCustomerObjs, arrayOfCustomerObjects)

  // A Master logs in who has a child BRW and a child CONBRW. The Child CONBRW
  // is related from the child BRW, so the BRW's customerObj will show up in
  // these results. The BRW's customerObj is already saved in the Redux store,
  // so we don't want to save it again (and in fact, it's harmful if we
  // save it again because the customer object of the BRW that gets
  // returned from this call contains a restricted set of information. For
  // example, the customer representative props [ceRep, logisticsRep, etc] are
  // all null).
  const state = yield select()
  const rootCustomerId = getCurrentUserProp(state, 'rootCustomerId')
  const rootCustomerType = getCustomerProp(state, rootCustomerId, 'customerType')
  if (rootCustomerType === CUSTOMER_TYPES_MASTER) {
    const childCustomerIds = getAllChildCustomerIdsOfCustomer({
      state,
      customerId: rootCustomerId,
    })
    arrayOfCustomerObjects = arrayOfCustomerObjects.filter(
      o => !childCustomerIds.includes(o.id),
    )
    // Keep in mind that although we filter out the superfluous customerObj, we
    // DON'T want to filter out the relationshipObj--it's not superfluous.
  }

  yield put(
    batchActions( // CODE_COMMENTS_148
      [
        createAction(SAVE_CUSTOMERS, { info: arrayOfCustomerObjects }),
        createAction(SAVE_RELATIONSHIPS, { info: arrayOfRelationshipObjects }),
      ],
      SAVE_RELATIONSHIPS_AND_CUSTOMERS_AT_THE_SAME_TIME,
    ),
  )
  yield put(createAction(FETCH_CUSTOMER_RELATED_FROM_INFO_SUCCESS, { target: [customerId] })) // CODE_COMMENTS_20
}

function* doFailure({ customerId, error, preventsDashboardFromLoading }) {
  yield call(
    generalDoFailure,
    {
      error,
      preventsDashboardFromLoading,
      action: createFetchFailureAction({
        error,
        type: FETCH_CUSTOMER_RELATED_FROM_INFO_FAILURE,
        target: [customerId],
      }),
    },
  )
}
