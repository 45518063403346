import {
  FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_REQUEST,
  FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_SUCCESS,
  FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_REQUEST,
  FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_SUCCESS,
  FETCH_STATUSES_INBOUND_SHIPMENTS_OF_SPECIFIC_KEG_ORDER_FAILURE,
)
