import values_ from 'lodash/values'

import {
  withPropSimple,
  getEntireSlice as commonGetEntireSlice,
} from './higherOrderFunctions'

import {
  REDUCER_NAMES_INBOUND_UNACKNOWLEDGED_SHIPMENTS as defaultSlice,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'


// You can use this to get several levels of slices:
//
// getEntireSlice(state) // all shipment objects of all customers
// getEntireSlice(state, customerId) // all shipment objects of one customer
// getEntireSlice(state, customerId, shipmentUuid) // one shipment object
export const getEntireSlice = commonGetEntireSlice(defaultSlice, 'byCustomerId')


// usage: getProp(state, customerId, shipmentId, 'goodPalletsOrigin')
export const getProp = withPropSimple(defaultSlice, 'byCustomerId')


export const getHasAnyInboundUnacknowledgedShipments = (state, customerId) => (
  isTruthyAndNonEmpty(getEntireSlice(state, customerId))
)

export const getUnacknowledgedShipmentsQty = (state, customerId) => {
  const slice = getEntireSlice(state, customerId)
  return values_(slice).length
}
