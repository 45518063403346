import { connect } from 'react-redux'
import values_ from 'lodash/values'


import Table from './components/Table'

import {
  getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable,
  getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
} from '../../util/shipmentHistory/inboundEmptyKegShipmentHistory'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getCustomerIdsOfAllRelatedTo,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
} from '../../../../../redux/selectors/rewrite/subsidiaries'
import {
  getEntireSlice as getAllInboundEmptyKegShipmentHistoryObjects,
} from '../../../../../redux/selectors/histories/inboundEmptyKegShipmentHistory'
import {
  getProp as getCustomerProp,
  getPropOr as getCustomerPropOr,
} from '../../../../../redux/selectors/customers'
import {
  getAllChildCustomerIdsOfCustomer,
} from '../../../../../redux/selectors/children'
import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../../redux/selectors/permissions'
import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/forms'

import {
  SHIPMENT_STATUS_PENDING,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_SHIPMENTS,
} from '../../../../../constants/permissions'

import {
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_PUB,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../../../constants'

import {
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


const mapStateToProps = (state, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    formReducerName,
  } = ownProps
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')
  const formName = createFormNameForRedux({
    reducerName: formReducerName,
    customerId,
  })

  const { getFetchStatuses } = fetchStatusSelectors
  const { isFetching, didFetchFail } = getFetchStatuses(state, formName)

  const areAnyItemsEditable = getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId)

  const allInboundEmptyKegShipmentObjects = getAllInboundEmptyKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  return {
    entireCustomersSlice,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    customerId,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    configuredItemSkuIds,
    allInboundEmptyKegShipmentObjects,
    isFormSubmitting: isFetching,
    hasFormSubmitFailed: didFetchFail,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    ...ownProps,
  }
}

export default connect(
  mapStateToProps,
)(Table)


function getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId) {
  if (
    // CODE_COMMENTS_153
    !getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_SHIPMENTS,
    }) ||
    !getHasPermissionsToPerformFunctionality({
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_SHIPMENTS,
    })
  ) { return false }

  const allInboundEmptyKegShipmentObjects = getAllInboundEmptyKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  const allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable = values_(
    getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable(
      allInboundEmptyKegShipmentObjects,
    ),
  )
  const shipmentObjsInTable = allInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable.filter(
    getShouldInboundEmptyKegShipmentHistoryItemBeDisplayedInTable,
  )
  return shipmentObjsInTable.some(row => getIsindividualItemEditable(state, row))
}


function getIsindividualItemEditable(state, row) {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)

  // only pending shipments are allowed to be edited
  if (row.status !== SHIPMENT_STATUS_PENDING) { return false }

  const originCustomerId = row.originCustomerId
  const originCustomerType = getCustomerPropOr(state, originCustomerId, 'customerType')
  const destinationCustomerId = row.destinationCustomerId
  const destinationCustomerType = getCustomerPropOr(state, destinationCustomerId, 'customerType')

  // This is a rare case in which we have a shipment from a customer we've never
  // been related to. See CODE_COMMENTS_108.
  if (!originCustomerType) { return false }

  if (originCustomerType === CUSTOMER_TYPES_PUB) {
    // CODE_COMMENTS_123: If the origin is a Pub customer, the shipment can be
    // edited in either of these situations:
    //   1) the currently operating customer is related to the pub AND the
    //   relationship is currently active;
    //   2) the pub is a child of the currently operating customer AND the pub
    //   customer's status is active
    const activeRelatedToPubIds = getCustomerIdsOfAllRelatedTo({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId: row.destinationCustomerId,
      onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_PUB,
    })
    const activeChildPubs = getAllChildCustomerIdsOfCustomer({
      state,
      customerId: row.destinationCustomerId,
      // CODE_COMMENTS_112, CODE_COMMENTS_122
      onlyCustomersWhoAreNotCurrentlyInactive: true,
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_PUB,
    })
    return (
      activeRelatedToPubIds.includes(originCustomerId) ||
      activeChildPubs.includes(originCustomerId)
    )
  }

  if (originCustomerType === CUSTOMER_TYPES_DISTRIBUTOR) {
    // If this is a "buyback" shipment of a defective or beer-out-of-code keg,
    // it's editable. We can distinguish between these shipments and shipments
    // arranged by MicroStar to fulfill keg orders by checking the 'orderId'
    // prop of the shipmentObject: if the 'orderId' is null (meaning this
    // shipment isn't associated with any keg order), it means this shipment
    // must be a buyback of defective/beer-out-of-code kegs.
    return !row.orderId
  }

  if (
    originCustomerType === CUSTOMER_TYPES_SELF_DISTRIBUTION
    && destinationCustomerType === CUSTOMER_TYPES_CONTRACT_BREWER
  ) {
    // CODE_COMMENTS_279: It's gonna take some work to allow customers to _edit_
    // SELFDIST2CONBRW shipments, because, for CONBRWs, we have to code for the
    // special case where a shipment in the CONBRW's CBMST Shipments History
    // table (i.e. in their home dashboard, not in their “Operating for XX
    // Brewer” dashboard) has its "Origin" dropdown options be rendered as if
    // this CONBRW were operating for its contractee.
    return false
  }

  // if it's any other type, like SELFDIST, and it's in pending status, it's
  // editable
  return true
}
