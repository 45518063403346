import { call, takeEvery } from 'redux-saga/effects'


import cloneDeep_ from 'lodash/cloneDeep'
import {
  shipmentHistorySagaCreator,
} from './shipmentHistorySagaCreator'

import {
  FETCH_BOL_PDF_DOWNLOAD,
  FETCH_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
  FETCH_SCHEDULED_SHIPMENT_HISTORY, FETCH_WH_EMAIL_CONTENT,
  SAVE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
} from '../../../actions/actionTypes'
import { generalDoFailure } from '../../util/fetchFailure'
import { privateFetch } from '../../fetch'
import { API_URL_PATH_FETCH_BOL_PDF_CONTENT, API_URL_PATH_FETCH_WAREHOUSE_EMAIL_CONTENT } from '../../../../constants'


const saga = shipmentHistorySagaCreator({
  saveAction: SAVE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
  alsoFetchNoMovements: true,
})

const scheduledShipmentSaga = shipmentHistorySagaCreator({
  saveAction: SAVE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY,
  alsoFetchNoMovements: true,
  historyName: 'scheduledShipments',
})

function* fetchWarehouseEmailContent(action) {
  let response
  const { shipmentId } = action.payload
  try {
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_FETCH_WAREHOUSE_EMAIL_CONTENT,
        params: {
          sid: shipmentId,
        },
      },
    )
  } catch (error) {
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { info: response.data, ...action.payload })
}
function* fetchBolPDFContent(action) {
  let response
  const { shipmentId } = action.payload
  try {
    response = yield call(
      privateFetch,
      {
        path: `${API_URL_PATH_FETCH_BOL_PDF_CONTENT}/${shipmentId}`,
      },
    )
  } catch (error) {
    yield call(doFailure, { error, ...action.payload })
    return
  }
  yield call(doSuccess, { info: response.data, ...action.payload })
}
// eslint-disable-next-line require-yield
function* doSuccess({
  info,
  onSuccess,
}) {
  const response = cloneDeep_(info)
  if (onSuccess) { onSuccess(response) }
}

function* doFailure({
  error,
  onFailure,
}) {
  if (onFailure) { onFailure({ error }) }
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
}
// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY, saga],
  [takeEvery, FETCH_SCHEDULED_SHIPMENT_HISTORY, scheduledShipmentSaga],
  [takeEvery, FETCH_WH_EMAIL_CONTENT, fetchWarehouseEmailContent],
  [takeEvery, FETCH_BOL_PDF_DOWNLOAD, fetchBolPDFContent],
]
