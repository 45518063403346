/* eslint-disable max-len */

import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

import RouteNotFound from '../../../../RouteNotFound'
import PrivateRoute from '../../../../../common-components/routes/PrivateRoute'

import PrelimInfoVerification from '../../../../PrelimInfoVerification'

import Dashboard from './ContractBrewerDashboard'
import OrderKegs from '../../../../OrderKegs/OrderKegs'
import AcknowledgeInboundShipments from '../../../../AcknowledgeInboundShipments'
import PayBalance from '../../../../PayBalance'
import ReportInventory from '../../../../ReportInventory/ReportInventory'
import ManageUsers from '../../../../ManageUsers'
import ManageContactsAndSubscriptions from '../../../../ManageContactsAndSubscriptions'
import ManageAddresses from '../../../../ManageAddresses'
import ReportKegFills from '../../../../ReportShipments/ReportKegFills'
import ReportSelfCollectionShipments from '../../../../ReportShipments/ReportSelfCollectionShipments'
import OperateForOtherCustomer from '../../../../OperateForOtherCustomer'

import {
  URL_PATHS,
} from '../../../../../constants'

import { createRelativePath } from '../../../../../utils'


// CODE_COMMENTS_26
const getListOfContractBrewerRoutes = ({
  match,
  customerId,
  currency,
  hasOrderKegsPermissions,
  hasAcknowledgeShipmentsPermissions,
  shouldReportKegFillsFormBeRendered,
  shouldReportSelfCollectionShipmentsFormBeRendered,
  hasPayBalancePermissions,
  shouldReportInventoryFormBeRendered,
  hasManageUsersAndContactsPermissions,
  hasAddressesPermissions,
}) => ([
  <PrivateRoute
    exact
    key={URL_PATHS.root.path}
    path={createRelativePath(match, URL_PATHS.root.path)}
    render={
      routeProps => (
        <PrelimInfoVerification
          componentToRenderIfVerified={Dashboard}
          customerId={customerId}
          {...routeProps}
        />
      )
    }
  />,
  ...(
    hasOrderKegsPermissions
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.orderKegs.path}
          path={createRelativePath(match, URL_PATHS.orderKegs.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={OrderKegs}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    hasAcknowledgeShipmentsPermissions
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.acknowledgeInboundShipments.path}
          path={createRelativePath(match, URL_PATHS.acknowledgeInboundShipments.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={AcknowledgeInboundShipments}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    shouldReportKegFillsFormBeRendered
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.reportKegFills.path}
          path={createRelativePath(match, URL_PATHS.reportKegFills.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ReportKegFills}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    shouldReportSelfCollectionShipmentsFormBeRendered
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.reportSelfCollectionShipments.path}
          path={createRelativePath(match, URL_PATHS.reportSelfCollectionShipments.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ReportSelfCollectionShipments}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    hasPayBalancePermissions && currency === 'USD'
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.payBalance.path}
          path={createRelativePath(match, URL_PATHS.payBalance.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={PayBalance}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    shouldReportInventoryFormBeRendered
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.reportInventory.path}
          path={createRelativePath(match, URL_PATHS.reportInventory.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ReportInventory}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    hasManageUsersAndContactsPermissions
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.users.path}
          path={createRelativePath(match, URL_PATHS.users.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ManageUsers}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
        <PrivateRoute
          exact
          key={URL_PATHS.contacts.path}
          path={createRelativePath(match, URL_PATHS.contacts.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ManageContactsAndSubscriptions}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    hasAddressesPermissions
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.addresses.path}
          path={createRelativePath(match, URL_PATHS.addresses.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ManageAddresses}
                customerId={customerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  <PrivateRoute
    key={URL_PATHS.operateFor.path}
    path={createRelativePath(match, URL_PATHS.operateFor.path)}
    render={
      routeProps => (
        <OperateForOtherCustomer
          operatorCustomerId={customerId}
          {...routeProps}
        />
      )
    }
  />,
])

export default withRouter(props => (
  <Switch>
    {getListOfContractBrewerRoutes(props)}
    <RouteNotFound />
  </Switch>
))
