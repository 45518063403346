/**
 * CODE_COMMENTS_133
*/
import { put, call, select, takeEvery } from 'redux-saga/effects'

import isEqual_ from 'lodash/isEqual'


import {
  validateCustomerUserPermissionsObject,
} from '../../util/apiResponseDataValidation/usersAndPermissions/customerUserPermissions'

import { privateFetch } from '../../fetch'

import {
  FETCH_CUSTOMER_USER_PERMISSIONS,
  FETCH_CUSTOMER_USER_PERMISSIONS_REQUEST,
  FETCH_CUSTOMER_USER_PERMISSIONS_SUCCESS,
  FETCH_CUSTOMER_USER_PERMISSIONS_FAILURE,
  SAVE_CUSTOMER_USER_PERMISSIONS,
  UPDATE_PERMISSIONS_OF_CURRENT_USER,
  UPDATE_PERMISSIONS_OF_EMPLOYEE_CURRENT_USER,
  FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS,
} from '../../../actions/actionTypes'

import {
  getProp as getCurrentCustomerUserProp,
} from '../../../selectors/currentUser'

import {
  getProp as getCurrentEmployeeUserProp,
  getIsEmployeeUserLoggedInRatherThanCustomerUser,
} from '../../../selectors/currentUserEmployee'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'


import {
  PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
  PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
} from '../../../../constants/permissions'

import {
  createPermissionsMap,
  createPerCustomerPermissionsMap,
} from '../../../../utils/permissions'

import {
  getUserPermissionsApiUrl,
} from '../../../../utils'

import {
  XhrReplicationError,
  NestedPropNotInObjectError,
} from '../../../../customErrors'


export function* fetchCustomerUserPermissions(action) {
  const {
    userId,
    setOperateAsCustomerUserHeaderIfEmployeeIsLoggedIn = true, // CODE_COMMENTS_221
  } = action.payload

  yield put(createAction(FETCH_CUSTOMER_USER_PERMISSIONS_REQUEST, { target: [userId] }))

  let response
  try {
    response = yield call(privateFetch, {
      path: getUserPermissionsApiUrl(userId),
      setOperateAsCustomerUserHeaderIfEmployeeIsLoggedIn,
    })
  } catch (error) {
    yield call(doFailure, { userId, error })
    return
  }
  yield call(doSuccess, { userId, httpResponse: response })
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CUSTOMER_USER_PERMISSIONS, fetchCustomerUserPermissions],
]

// info should be an object with two props, `permissions` and
// `perCustomerPermissions`
function* doSuccess({
  userId,
  httpResponse,
}) {
  const { data } = httpResponse
  const {
    validatedData: info,
    canDataBeSavedToStore,
  } = yield call(
    validateCustomerUserPermissionsObject,
    {
      httpResponse,
      data,
    },
  )
  if (!canDataBeSavedToStore) {
    // CODE_COMMENTS_149
    const error = new XhrReplicationError({
      response: {
        status: 'not a real xhr error',
        statusText: `${httpResponse.config.url} has returned a malformed customerUserPermissionsObject`,
        data: {},
        headers: {},
      },
      config: httpResponse.config,
    })
    yield call(
      doFailure,
      {
        userId,
        error,
        overridingOnlyLogErrorIfFunc: () => false, // CODE_COMMENTS_193
      },
    )
    return
  }

  yield put(createAction(SAVE_CUSTOMER_USER_PERMISSIONS, { userId, info }))
  yield call(updateCurrentUserPermissionsIfTheTargetUserIsTheCurrentUser, { userId, info })
  yield put(createAction(FETCH_CUSTOMER_USER_PERMISSIONS_SUCCESS, { target: [userId] })) // CODE_COMMENTS_20
}

function* doFailure({
  userId,
  error,
  overridingOnlyLogErrorIfFunc,
}) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_CUSTOMER_USER_PERMISSIONS_FAILURE,
        target: [userId],
      }),
      onlyLogErrorIf: overridingOnlyLogErrorIfFunc,
    },
  )
}

// CODE_COMMENTS_240
function* updateCurrentUserPermissionsIfTheTargetUserIsTheCurrentUser({
  userId,
  info,
}) {
  let isTargetUserTheCurrentlyLoggedInCustomerUser = false
  let isTargetUserTheCurrentlyLoggedInEmployeeUser = false
  const isEmployeeUserLoggedIn = yield select(getIsEmployeeUserLoggedInRatherThanCustomerUser)
  if (isEmployeeUserLoggedIn) {
    const currentEmployeeUserId = yield select(getCurrentEmployeeUserProp, 'userId')
    isTargetUserTheCurrentlyLoggedInEmployeeUser = currentEmployeeUserId === userId
    try {
      const currentCustomerUserId = yield select(getCurrentCustomerUserProp, 'userId')
      isTargetUserTheCurrentlyLoggedInCustomerUser = currentCustomerUserId === userId
    } catch (e) {
      // When would getCurrentCustomerUserProp() ever fail? When an employee user logs in,
      // currentUser is set to an empty object. The first time the employee user
      // searches for a customer user, if the customer user is found, this code
      // will be called, and it will fail here.
      if (e instanceof NestedPropNotInObjectError) {
        // isTargetUserTheCurrentlyLoggedInCustomerUser is already set to false by
        // default, so do nothing.
      } else {
        throw e
      }
    }
  } else { // employee user is not logged in
    const currentCustomerUserId = yield select(getCurrentCustomerUserProp, 'userId')
    isTargetUserTheCurrentlyLoggedInCustomerUser = currentCustomerUserId === userId
  }


  if (
    isTargetUserTheCurrentlyLoggedInCustomerUser
    || isTargetUserTheCurrentlyLoggedInEmployeeUser
  ) {
    const currentPermissionsMap = yield select(
      isTargetUserTheCurrentlyLoggedInCustomerUser ? getCurrentCustomerUserProp : getCurrentEmployeeUserProp,
      PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
    )
    const currentPerCustomerPermissionsMap = yield select(
      isTargetUserTheCurrentlyLoggedInCustomerUser ? getCurrentCustomerUserProp : getCurrentEmployeeUserProp,
      PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
    )
    const newPermissionsMap = createPermissionsMap(info.permissions)
    const newPerCustomerPermissionsMap = createPerCustomerPermissionsMap(info.perCustomerPermissions)
    if (
      // Why are we comparing permissionsMaps rather than permissions? Doesn't
      // this require us to potentially needlessly create newPermissionsMap? No,
      // because permissions is an array whereas permissionsMap is an object, so
      // lodash's isEqual will fail if the current permissions and the new
      // permissions have the same items but in a different order (and as a
      // general rule, we shouldn't rely on the ordering of arrays coming from
      // the backend). Whereas with objects, order doesn't matter to isEqual.
      !isEqual_(currentPermissionsMap, newPermissionsMap)
      || !isEqual_(currentPerCustomerPermissionsMap, newPerCustomerPermissionsMap)
    ) {
      yield put(createAction(
        isTargetUserTheCurrentlyLoggedInCustomerUser
          ? UPDATE_PERMISSIONS_OF_CURRENT_USER
          : UPDATE_PERMISSIONS_OF_EMPLOYEE_CURRENT_USER,
        info,
      ))
      if (isTargetUserTheCurrentlyLoggedInCustomerUser) {
        // essentially, reload the app
        yield put(createAction(FETCH_PRELIM_INFO_OF_ALL_CUSTOMERS))
      }
    }
  }
}
