import {
  REDUCER_NAMES_PUBLIC_FORMS,
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD as defaultSlice,
} from '../../../constants'

import {
  withPropSimple,
} from '../higherOrderFunctions'

export const getProp = withPropSimple(REDUCER_NAMES_PUBLIC_FORMS, defaultSlice)
