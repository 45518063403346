import kegOrderHistorySagas from './kegOrderHistory'
import collarOrderHistorySagas from './collarOrderHistory'
import inboundEmptyKegShipmentHistorySagas from './inboundEmptyKegShipmentHistory'
import outboundFullKegShipmentHistorySagas from './outboundFullKegShipmentHistory'
import inboundFullKegShipmentHistorySagas from './inboundFullKegShipmentHistory'
import outboundEmptyKegShipmentHistorySagas from './outboundEmptyKegShipmentHistory'
import kegFillContracteeHistorySagas from './kegFillContracteeHistory'
import inventoryHistorySagas from './inventoryHistory'
import invoiceHistorySagas from './invoiceHistory'

// CODE_COMMENTS_11
export default [
  ...kegOrderHistorySagas,
  ...collarOrderHistorySagas,
  ...inboundEmptyKegShipmentHistorySagas,
  ...outboundFullKegShipmentHistorySagas,

  // CODE_COMMENTS_205
  ...inboundFullKegShipmentHistorySagas,
  ...outboundEmptyKegShipmentHistorySagas,

  // CODE_COMMENTS_232
  ...kegFillContracteeHistorySagas,

  ...inventoryHistorySagas,
  ...invoiceHistorySagas,
]
