import React from 'react'

import { Button } from 'semantic-ui-react'

import createAction from '../../../redux/actions/createAction'
import {
  FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS,
} from '../../../redux/actions/actionTypes'

import {
  getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn,
} from '../../../redux/selectors/rewrite/relationships/relatedToInfo'

import {
  getIncludeLocalKegsColumnsInHistoryTable,
  determineItemSkuIdsToIncludeAsFieldsInKegOrderHistoryTable,
} from '../../History/individualTabs/util/kegOrderHistory'

import OrderAndShipmentsDetails from '../../History/individualTabs/KegOrderHistory/Table/subFeatures/OrderAndShipmentsDetails'


export default props => {
  const {
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    configuredItemSkuIds,
    customerId,
    didFetchFail,
    shipment,
    order,
    dispatch,
  } = props

  let itemSkuIdsToIncludeAsFieldsInTable = null
  let includeLocalKegsColumns = null

  if (order) {
    const isRelatedToLocalDistributors = getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
    })
    const allKegOrderObjectsToBeIncludedInHistoryTable = { [order.id]: order }
    itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInKegOrderHistoryTable({
      entireItemSkusSlice,
      allKegOrderObjectsToBeIncludedInHistoryTable,
      configuredItemSkuIds,
    })
    includeLocalKegsColumns = getIncludeLocalKegsColumnsInHistoryTable(
      allKegOrderObjectsToBeIncludedInHistoryTable,
      isRelatedToLocalDistributors,
    )
  }

  const refetchCustomerUsers = () => (
    dispatch(createAction(
      FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS,
      props,
    ))
  )

  return (
    <OrderAndShipmentsDetails
      entireItemSkusSlice={entireItemSkusSlice}
      info={order}
      customerId={customerId}
      itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
      includeLocalKegsColumns={includeLocalKegsColumns}
      humanReadableOrderIdIfOrderNotFetchedYet={shipment.humanReadableOrderId}
      didFetchFail={didFetchFail}
      componentToRenderIfFetchFailed={() => (
        <>
          <span style={{ color: '#9f3a38' }}>Failed to fetch order</span>
          <Button size="tiny" style={{ marginLeft: '1rem' }} color="blue" onClick={refetchCustomerUsers}>Retry</Button>
        </>
      )}
    />
  )
}
