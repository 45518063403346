import React from 'react'


import CustomerPortal from '../../../CustomerPortal'


export default () => (
  <div>
    <CustomerPortal
      isEmployee
    />
  </div>
)
