import { connect } from 'react-redux'


import GetBackToEmployeeSectionWhenOperatingAsCustomerUser from './components'

import {
  getIsEmployeeUserLoggedInRatherThanCustomerUser,
} from '../../../redux/selectors/currentUserEmployee'

const mapStateToProps = state => ({
  isEmployeeUserLoggedInRatherThanCustomerUser: getIsEmployeeUserLoggedInRatherThanCustomerUser(state),
})

export default connect(
  mapStateToProps,
)(GetBackToEmployeeSectionWhenOperatingAsCustomerUser)
