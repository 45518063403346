import { connect } from 'react-redux'


import RequestnewDistributor from './components'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../redux/selectors/rewrite/currentUser'

const mapStateToProps = state => ({
  entireCustomersSlice: getEntireCustomersSlice(state),
  entireContractsSlice: getEntireContractsSlice(state),
  entireRelationshipsSlice: getEntireRelationshipsSlice(state),
  entirePermissionsSlice: getEntirePermissionsSlice(state),
  entireCurrentUserSlice: getEntireCurrentUserSlice(state),
})

export default connect(
  mapStateToProps,
)(RequestnewDistributor)
