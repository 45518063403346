/**
 * CODE_COMMENTS_133
 */

import {
  SAVE_CUSTOMER_USER_PERMISSIONS,
} from '../actions/actionTypes'

import {
  addPermissionsMapsToObjectContainingPermissions,
} from './util/permissions'


const initialState = {
  byUserId: {},
}

export default (state=initialState, action) => {
  switch (action.type) {
    case SAVE_CUSTOMER_USER_PERMISSIONS:
      return {
        ...state,
        byUserId: {
          ...state.byUserId,
          [action.payload.userId]: addPermissionsMapsToObjectContainingPermissions(action.payload.info),
        },
      }

    default:
      return state
  }
}
