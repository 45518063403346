// For when an employee logs in and operates for a customer user

import { call } from 'redux-saga/effects'


import { privateFetch } from '../../fetch'

import {
  URL_PARAM_EMAIL,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/users'

import {
  API_URL_PATH_USERS,
} from '../../../../constants'

import {
  getIsUserAnEmployee,
} from '../../../../utils'

import {
  XhrReplicationError,
} from '../../../../customErrors'


export function* fetchIndividualCustomerUser(action) {
  const { email } = action.payload
  let response
  try {
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_USERS,
        params: {
          [URL_PARAM_EMAIL]: email,
        },
        setOperateAsCustomerUserHeaderIfEmployeeIsLoggedIn: false, // CODE_COMMENTS_221
      },
    )
  } catch (error) {
    // if (error.response && error.response.status === 404) {
    //   const fakeXhrError = new XhrReplicationError({
    //     response: {
    //       ...(error.response ? error.response : {}),
    //       status: 'not a real xhr error',
    //       statusText: `${email} not found`,
    //     },
    //     config: error.config,
    //   })
    //   return { success: false, error: fakeXhrError }
    // }
    return { success: false, error }
  }
  const userObject = response.data[0]
  if (getIsUserAnEmployee(userObject)) {
    const fakeXhrError = new XhrReplicationError({
      response: {
        status: 'not a real xhr error',
        statusText: `${email} is an employee user, not a customer user`,
        data: {},
        headers: {},
      },
      config: response.config,
    })
    return { success: false, error: fakeXhrError }
  }
  return { success: true, userObject }
}
