/* eslint-disable max-len */

import React, { Fragment, useState, useEffect, useReducer, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Form, Segment, Header, Button, Message } from 'semantic-ui-react'
import produce from 'immer'
import moment from 'moment'

import get_ from 'lodash/get'
import set_ from 'lodash/set'
import isArray_ from 'lodash/isArray'
import isFunction_ from 'lodash/isFunction'


// Higher-order components and hooks
import { useTranslation } from 'react-i18next'
import UiContainer from '../../../common-components/UiContainer/UiContainerAsParent'
import FormSubmissionDimmerOverlay from '../../../common-components/rewrite/FormSubmission/DimmerOverlay'
import FullDetailsModal from './FullDetailsModal'
import HorizontalFormSection from '../../../common-components/HorizontalFormSection'
import InformationalPopup from '../../../common-components/InformationalPopup'
import LoadingSection from '../../../common-components/LoadingSection'
import ContactCard from '../../../common-components/ContactCard'
import useScrollProgrammatically from '../../../common-components/hooks/useScrollProgrammatically'

import LabelToApiInventoryTypeMapFields from './_LabelToApiInventoryTypeMapFields'

import {
  FETCH_REPORT_INVENTORY,
  FETCH_EDIT_INVENTORY_REPORT,
  FETCH_INFO_NEEDED_TO_CONSTRUCT_DIST_REPORT_INVENTORY_NO_LOGIN_REQUIRED_FORM, // CODE_COMMENTS_267
} from '../../../redux/actions/actionTypes'
import createAction from '../../../redux/actions/createAction'

import {
  getBusinessUnitIdOfRootCustomer,
} from '../../../redux/selectors/rewrite/customers'

import {
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY,
  REPORT_INVENTORY_CONFIG_OBJECT,
  INVENTORY_REPORT_OBJS, REPORT_CONSTELLATION_ARBITRARY_CONTENT,
} from '../util/constants'

import {
  FORM_NOT_SUBMITTABLE_EXPLANATION_NON_DISTRIBUTORS,
  FORM_NOT_SUBMITTABLE_EXPLANATION_DISTRIBUTORS,
} from '../../../constants/formAndApiUrlConfig/reportInventory'

import {
  CUSTOMER_REPS_GENERIC,
  CUSTOMER_TYPES_DISTRIBUTOR,
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
  URL_PATHS,
} from '../../../constants'

import {
  getItemSkuIds,
  getInitialValues,
  getAreCurrentValuesSameAsInitialValues,
  getIsFormSubmittable,
  getUiContainerProps,
} from './util'

import {
  returnFuncToGatherInventoryReportObjsByCustomerType,
} from '../util/inventoryReportObjs/interface'

import {
  ARBITRARY_CONTENT_BEFORE_FORM,
  SECTION_DEFINITIONS,
  SECTION_KEY,
  SECTION_TITLE,
  ARBITRARY_CONTENT_AFTER_SECTION_TITLE_AND_BEFORE_SECTION_FORM_FIELDS,
  SECTION_FIELDS,
  FIELD_LABEL,
  ARBITRARY_CONTENT_AFTER_LABEL_TEXT,
  FORM_FIELD_KIND,
  FORM_FIELD_KIND_INDIVIDUAL_FIELD,

  REPORT_FORM_FIELD_COMPONENT,
  EDIT_FORM_FIELD_COMPONENT,
  FULL_DETAILS_COMPONENT,
  SAME_AS_REPORT_FORM_FIELD_COMPONENT,
  SAME_AS_EDIT_FORM_FIELD_COMPONENT,
  SAME_AS_FULL_DETAILS_COMPONENT,

  ONLY_RENDER_SECTION_IF,
  ONLY_RENDER_FIELD_IF,
  IS_FORM_FIELD_REQUIRED,

  HEADINGS_AND_BRANDING_LANGUAGE,
  TITLE_OF_FULL_DETAILS_VIEW,

  getConfigObjectBasedOnCustomerTypeForReportInventory,
} from '../util/configObjects'

import {
  determineCustomerTypeForReportInventory,
  createMonthReportingForRangeMin,
  createMonthReportingForRangeMax,
  getReportingForMinAndMaxDates,
  getCustomerName,
  getNameOfReportingPerson,
} from '../util'

import {
  getBusinessUnitNameFromBusinessUnitId,
  getQueryParametersFromUrl,
} from '../../../utils'
import { getSubsidiaryTapDefectiveKegs } from '../../../redux/selectors/rewrite/subsidiaries'
import ReportDefectiveKegLink
from '../../ReportShipments/common/ReportAndEditShipments/components/ReportDefectiveKegLink'


const CHANGE_FORM_VALUE = 'CHANGE_FORM_VALUE'
const RESET_FORM_VALUES_TO_INITIAL_VALUES = 'RESET_FORM_VALUES_TO_INITIAL_VALUES'

/* eslint-disable no-param-reassign, no-useless-return, prefer-const, consistent-return */
const formValuesReducer = produce((formValues, action) => {
  switch (action.type) {
    case CHANGE_FORM_VALUE: {
      let { fieldName, value, normalizer } = action.payload
      const pathToField = isArray_(fieldName) ? fieldName : [fieldName]
      const previousValue = get_(formValues, pathToField)
      value = normalizer
        ? normalizer(value, previousValue)
        : value
      set_(formValues, pathToField, value)
      return
    }
    case RESET_FORM_VALUES_TO_INITIAL_VALUES: {
      const {
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
        itemSkuIds,
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
        isDistReportInventoryNoLoginRequired,
        isDistReportConstellationNoLoginRequired,
        configObjReturnedFromDistInvNoLoginConfigCall,
        entireItemSkusSlice,
        entireSubsidiariesSlice,
        subsidiaryId,
      } = action.payload
      return getInitialValues({
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
        itemSkuIds,
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
        isDistReportInventoryNoLoginRequired,
        isDistReportConstellationNoLoginRequired,
        configObjReturnedFromDistInvNoLoginConfigCall,
        entireItemSkusSlice,
        entireSubsidiariesSlice,
        subsidiaryId,
      })
    }
    default: { return }
  }
})
/* eslint-enable no-param-reassign, no-useless-return, prefer-const, consistent-return */

function showDefectiveKegLink({
  entireSubsidiariesSlice,
  entireCustomersSlice,
  customerId,
}) {
  const subsidiaryId = entireCustomersSlice?.[customerId]?.subsidiaryId
  if (subsidiaryId === 21 || subsidiaryId === 22) {
    return false
  }
  if (!getSubsidiaryTapDefectiveKegs({ entireSubsidiariesSlice, subsidiaryId })) {
    return true
  }
  return false
}

export default props => {
  const {
    entireCustomersSlice,
    entireCurrentUserSlice,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    customerId,
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventoryIfFullDetailsOrEditForm,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjsIfFullDetailsOrEditForm,
    isThisForDisplayingAnAlreadyCreatedInventoryReport,
    isEditForm,
    dispatch,
    isDistReportInventoryNoLoginRequired, // CODE_COMMENTS_276
    isDistReportConstellationNoLoginRequired,
    // react-router props
    location,
  } = props
  const { t: translate } = useTranslation('common')
  const customerType = (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
    ? CUSTOMER_TYPES_DISTRIBUTOR
    : entireCustomersSlice[customerId].customerType
  const customerTypeForReportInventory = (
    customerTypeForReportInventoryIfFullDetailsOrEditForm
    || determineCustomerTypeForReportInventory({
      ...props,
      customerType,
    })
  )

  // CODE_COMMENTS_267
  const [token, setToken] = useState(null)
  const [tokenExpiration, setTokenExpiration] = useState(null)
  const [isDistInvNoLoginConfigCallFetching, setIsDistInvNoLoginConfigCallFetching] = useState(false)
  /* eslint-disable no-unused-vars */
  const [didDistInvNoLoginConfigCallSucceed, setDidDistInvNoLoginConfigCallSucceed] = useState(false)
  const [isTokenExpired, setIsTokenExpired] = useState(false)
  const [hasDistInvAlreadyBeenReportedForThisToken, setHasDistInvAlreadyBeenReportedForThisToken] = useState(false)
  const [doesUserNotHavePermissionsToMakeConfigCall, setDoesUserNotHavePermissionsToMakeConfigCall] = useState(false)
  const [didConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray, setDidConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray] = useState(false)
  // Failure means something unexpected happened. If we get a 500 because
  // inventory has already been reported, the call succeeds and the
  // hasDistInvAlreadyBeenReportedForThisToken flag is set. Failure is only for
  // unexpected errors not covered by the state props above.
  const [didDistInvNoLoginConfigCallFail, setdidDistInvNoLoginConfigCallFail] = useState(false)
  const [distInvNoLoginConfigCallFailureErrorDetails, setDistInvNoLoginConfigCallFailureErrorDetails] = useState(null)
  const [configObjReturnedFromDistInvNoLoginConfigCall, setConfigObjReturnedFromDistInvNoLoginConfigCall] = useState(null)
  const [subsidiaryId, setSubsidiaryId] = useState(null)

  // We gather all these props into a single object because we need to reference
  // them multiple times in this file, specifically when we dispatch
  // FETCH_INFO_NEEDED_TO_CONSTRUCT_DIST_REPORT_INVENTORY_NO_LOGIN_REQUIRED_FORM
  const allDistInvNoLoginStateVars = {
    ...props,
    token,
    isDistReportInventoryNoLoginRequired,
    isDistReportConstellationNoLoginRequired,
    setToken,
    tokenExpiration,
    setTokenExpiration,
    isDistInvNoLoginConfigCallFetching,
    setIsDistInvNoLoginConfigCallFetching,
    didDistInvNoLoginConfigCallSucceed,
    setDidDistInvNoLoginConfigCallSucceed,
    isTokenExpired,
    setIsTokenExpired,
    hasDistInvAlreadyBeenReportedForThisToken,
    setHasDistInvAlreadyBeenReportedForThisToken,
    doesUserNotHavePermissionsToMakeConfigCall,
    setDoesUserNotHavePermissionsToMakeConfigCall,
    didConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray,
    setDidConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray,
    didDistInvNoLoginConfigCallFail,
    setdidDistInvNoLoginConfigCallFail,
    distInvNoLoginConfigCallFailureErrorDetails,
    setDistInvNoLoginConfigCallFailureErrorDetails,
    configObjReturnedFromDistInvNoLoginConfigCall,
    setConfigObjReturnedFromDistInvNoLoginConfigCall,
    subsidiaryId,
    setSubsidiaryId,
  }

  useEffect(
    () => {
      if (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) {
        const {
          mstartoken,
          expiration,
        } = getQueryParametersFromUrl(location)
        setToken(mstartoken)
        setTokenExpiration(expiration)
        const isTokenExpired_ = moment(expiration).isBefore(moment.now())
        if (isTokenExpired_) {
          setIsTokenExpired(true)
        } else {
          dispatch(createAction(
            FETCH_INFO_NEEDED_TO_CONSTRUCT_DIST_REPORT_INVENTORY_NO_LOGIN_REQUIRED_FORM,
            {
              ...allDistInvNoLoginStateVars,
              token: mstartoken,
              expiration,
            },
          ))
        }
      } else {
        setSubsidiaryId(entireCustomersSlice[customerId].subsidiaryId)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // empty array so it only renders on mount
  )


  const businessUnitId = (!isDistReportInventoryNoLoginRequired && !isDistReportConstellationNoLoginRequired) && getBusinessUnitIdOfRootCustomer({
    entireCustomersSlice,
    entireCurrentUserSlice,
  })
  const businessUnitName = (!isDistReportInventoryNoLoginRequired && !isDistReportConstellationNoLoginRequired) && getBusinessUnitNameFromBusinessUnitId(businessUnitId)


  const configObj = useMemo(
    () => (
      getConfigObjectBasedOnCustomerTypeForReportInventory({
        [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
      })
    ),
    [customerTypeForReportInventory],
  )

  const inventoryReportObjs = useMemo(
    () => (
      inventoryReportObjsIfFullDetailsOrEditForm ||
      returnFuncToGatherInventoryReportObjsByCustomerType({
        [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
      })({
        ...props,
        configObjReturnedFromDistInvNoLoginConfigCall,
      })
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      inventoryReportObjsIfFullDetailsOrEditForm,
      customerTypeForReportInventory,
      isEditForm,
      isThisForDisplayingAnAlreadyCreatedInventoryReport,
      configObjReturnedFromDistInvNoLoginConfigCall,
    ],
  )

  const itemSkuIds = useMemo(
    () => (
      getItemSkuIds({
        entireCustomersSlice,
        entireItemSkusSlice,
        customerId,
        [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
        subsidiaryId,
      })
    ),
    [
      entireCustomersSlice,
      entireItemSkusSlice,
      customerId,
      customerTypeForReportInventory,
      inventoryReportObjs,
      isEditForm,
      isThisForDisplayingAnAlreadyCreatedInventoryReport,
      subsidiaryId,
    ],
  )

  const customerName = (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
    ? null // Top section not rendered for this special inventory report
    : getCustomerName({
      ...props,
      [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
      [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
    })

  const nameOfReportingPerson = (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
    ? null // Top section not rendered for this special inventory report
    : getNameOfReportingPerson({
      ...props,
      [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
      [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
    })

  const [initialValues, setInitialValues] = useState(getInitialValues({
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
    itemSkuIds,
    isEditForm,
    isThisForDisplayingAnAlreadyCreatedInventoryReport,
    isDistReportInventoryNoLoginRequired,
    isDistReportConstellationNoLoginRequired,
    configObjReturnedFromDistInvNoLoginConfigCall,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    subsidiaryId,
  }))
  useEffect(
    () => {
      setInitialValues(getInitialValues({
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
        itemSkuIds,
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
        isDistReportInventoryNoLoginRequired,
        isDistReportConstellationNoLoginRequired,
        configObjReturnedFromDistInvNoLoginConfigCall,
        entireItemSkusSlice,
        entireSubsidiariesSlice,
        subsidiaryId,
      }))
    },
    [
      inventoryReportObjs,
      configObj,
      itemSkuIds,
      isEditForm,
      isThisForDisplayingAnAlreadyCreatedInventoryReport,
      isDistReportInventoryNoLoginRequired,
      isDistReportConstellationNoLoginRequired,
      configObjReturnedFromDistInvNoLoginConfigCall,
      entireItemSkusSlice,
      entireSubsidiariesSlice,
      subsidiaryId,
    ],
  )

  const [formValues, dispatchFormValues] = useReducer(
    formValuesReducer,
    getInitialValues({
      [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
      [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
      itemSkuIds,
      isEditForm,
      isThisForDisplayingAnAlreadyCreatedInventoryReport,
      isDistReportInventoryNoLoginRequired,
      isDistReportConstellationNoLoginRequired,
      configObjReturnedFromDistInvNoLoginConfigCall,
      entireItemSkusSlice,
      entireSubsidiariesSlice,
      subsidiaryId,
    }),
  )

  // We create this function so that the individual form field definition
  // functions within configObj don't have to call
  // `dispatchFormValues(createAction(CHANGE_FORM_VALUE, { fieldName, value,
  // normalizer }))`, they can just call `changeFormValue({ fieldName, value,
  // normalizer })`
  const changeFormValue = ({ fieldName, value, normalizer }) => {
    dispatchFormValues(createAction(
      CHANGE_FORM_VALUE,
      { fieldName, value, normalizer },
    ))
  }

  const [areCurrentValuesSameAsInitialValues, setAreCurrentValuesSameAsInitialValues] = useState(false)
  useEffect(
    () => {
      setAreCurrentValuesSameAsInitialValues(getAreCurrentValuesSameAsInitialValues({
        formValues,
        initialValues,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
      }))
    },
    [
      formValues,
      initialValues,
      isThisForDisplayingAnAlreadyCreatedInventoryReport,
    ],
  )

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [didSubmitSucceed, setDidSubmitSucceed] = useState(false)
  const [didSubmitFail, setDidSubmitFail] = useState(false)

  const handleSubmit = () => {
    dispatch(createAction(
      isEditForm ? FETCH_EDIT_INVENTORY_REPORT : FETCH_REPORT_INVENTORY,
      {
        ...props,
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
        customerType,
        configObjReturnedFromDistInvNoLoginConfigCall,
        formValues,
        itemSkuIds,
        token,
        tokenExpiration,
        setIsSubmitting,
        setDidSubmitSucceed,
        setDidSubmitFail,
      },
    ))
  }

  const onResetForm = () => {
    dispatchFormValues(createAction(
      RESET_FORM_VALUES_TO_INITIAL_VALUES,
      {
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
        itemSkuIds,
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
        isDistReportInventoryNoLoginRequired,
        isDistReportConstellationNoLoginRequired,
        configObjReturnedFromDistInvNoLoginConfigCall,
        entireItemSkusSlice,
        entireSubsidiariesSlice,
        subsidiaryId,
      },
    ))
    setIsSubmitting(false)
    setDidSubmitSucceed(false)
    setDidSubmitFail(false)
  }

  const [
    dateCountedMinDate,
    dateCountedMaxDate,
    dateCountedOpenToDate,
  ] = isThisForDisplayingAnAlreadyCreatedInventoryReport
    ? [null, null, null] // not needed by fullDetails--see CODE_COMMENTS_214
    : getReportingForMinAndMaxDates({ formValues })


  // CODE_COMMENTS_274
  const [formSubmissionScrollRef, scrollToFormSubmissionContent] = useScrollProgrammatically()

  const propsForConfigObjDefinitions = {
    ...props,
    [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
    [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    arbitraryContent: (isDistReportConstellationNoLoginRequired && REPORT_CONSTELLATION_ARBITRARY_CONTENT) || null,
    customerType,
    businessUnitId,
    businessUnitName,
    entireSubsidiariesSlice,
    subsidiaryId,
    itemSkuIds,
    customerName,
    nameOfReportingPerson,
    initialValues,
    formValues,
    changeFormValue,
    areCurrentValuesSameAsInitialValues,
    monthReportingForRangeMin: createMonthReportingForRangeMin(),
    monthReportingForRangeMax: createMonthReportingForRangeMax(),
    dateCountedMinDate,
    dateCountedMaxDate,
    dateCountedOpenToDate,
    configObjReturnedFromDistInvNoLoginConfigCall, // CODE_COMMENTS_267
  }

  const [isFormSubmittable, setIsFormSubmittable] = useState(false)
  useEffect(
    () => {
      setIsFormSubmittable(getIsFormSubmittable({
        [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
        [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
        [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
        customerType,
        businessUnitId,
        businessUnitName,
        entireSubsidiariesSlice,
        subsidiaryId,
        formValues,
        itemSkuIds,
        // these fields are required only if this is an edit form
        initialValues,
        isEditForm,
        isThisForDisplayingAnAlreadyCreatedInventoryReport,
        isDistReportInventoryNoLoginRequired,
        isDistReportConstellationNoLoginRequired,
        configObjReturnedFromDistInvNoLoginConfigCall,
        entireItemSkusSlice,
      }))
    },
    [
      customerTypeForReportInventory,
      configObj,
      inventoryReportObjs,
      customerType,
      businessUnitId,
      businessUnitName,
      entireSubsidiariesSlice,
      subsidiaryId,
      formValues,
      itemSkuIds,
      // these fields are required only if this is an edit form
      initialValues,
      isEditForm,
      isThisForDisplayingAnAlreadyCreatedInventoryReport,
      isDistReportInventoryNoLoginRequired,
      isDistReportConstellationNoLoginRequired,
      configObjReturnedFromDistInvNoLoginConfigCall,
      entireItemSkusSlice,
    ],
  )

  return (
    <UiContainer
      {...getUiContainerProps({
        [REPORT_INVENTORY_CONFIG_OBJECT]: configObj,
        ...props,
      })}
    >
      {(isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) && isDistInvNoLoginConfigCallFetching && <LoadingSection />}
      {isDistReportInventoryNoLoginRequired && !isDistInvNoLoginConfigCallFetching && isTokenExpired && <ErrorMessageDistInvNoLogin type="expired" {...allDistInvNoLoginStateVars} />}
      {isDistReportConstellationNoLoginRequired && !isDistInvNoLoginConfigCallFetching && isTokenExpired && <ErrorMessageDistInvNoLogin type="expired-constellation" {...allDistInvNoLoginStateVars} />}
      {(isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) && !isDistInvNoLoginConfigCallFetching && hasDistInvAlreadyBeenReportedForThisToken && <ErrorMessageDistInvNoLogin type="alreadyReported" {...allDistInvNoLoginStateVars} />}
      {(isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) && !isDistInvNoLoginConfigCallFetching && doesUserNotHavePermissionsToMakeConfigCall && <ErrorMessageDistInvNoLogin type="noPermissions" {...allDistInvNoLoginStateVars} />}
      {(isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) && !isDistInvNoLoginConfigCallFetching && didConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray && <ErrorMessageDistInvNoLogin type="emptyCustomersArray" {...allDistInvNoLoginStateVars} />}
      {(isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) && !isDistInvNoLoginConfigCallFetching && didDistInvNoLoginConfigCallFail && <ErrorMessageDistInvNoLogin type="configCallFailed" {...allDistInvNoLoginStateVars} />}
      {(
        (!isDistReportInventoryNoLoginRequired && !isDistReportConstellationNoLoginRequired)
        || (
          (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired)
          && configObjReturnedFromDistInvNoLoginConfigCall
          && !didConfigObjReturnedFromDistInvNoLoginConfigCallHaveEmptyCustomersArray
          // The subtleties of React render ticks require that we check that
          // inventoryReportObjs isn't null.
          && inventoryReportObjs
        )
      ) &&
      <FormSubmissionDimmerOverlay
        customerId={customerId}
        dontActuallyApplyTheFormSubmissionDimmerOverlayHocHoc={isThisForDisplayingAnAlreadyCreatedInventoryReport}
        isSubmitting={isSubmitting}
        didSubmitSucceed={didSubmitSucceed}
        didSubmitFail={didSubmitFail}
        onResetForm={onResetForm}
        successMessage={(
          <div>
            {isEditForm
              ? <p>{`Your ${customerType === CUSTOMER_TYPES_DISTRIBUTOR ? 'empty kegs' : 'inventory'} report has been updated.`}</p>
              : (
                <Fragment>
                  <p>{`Your ${isDistReportConstellationNoLoginRequired ? 'case pallet inventory has' : customerType === CUSTOMER_TYPES_DISTRIBUTOR ? 'empty kegs have' : 'inventory has'} been reported.`}</p>
                  {(!isDistReportInventoryNoLoginRequired && !isDistReportConstellationNoLoginRequired) &&
                    <p>To edit your report, search for it in the Inventory History section of the Customer Portal.</p>
                  }
                </Fragment>
              )
            }
          </div>
        )}
        dontShowAnyFailureButtons={isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired}
        dontShowAnySuccessButtons={isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired}
        formSubmissionScrollRef={formSubmissionScrollRef}
      >
        <FullDetailsModal
          dontActuallyApplyTheFullDetailsModalHoc={!isThisForDisplayingAnAlreadyCreatedInventoryReport}
          fullDetailsModalHeaderText={configObj[HEADINGS_AND_BRANDING_LANGUAGE][TITLE_OF_FULL_DETAILS_VIEW]}
          {...{ [INVENTORY_REPORT_OBJS]: inventoryReportObjs }}
        >
          {configObj[ARBITRARY_CONTENT_BEFORE_FORM] && configObj[ARBITRARY_CONTENT_BEFORE_FORM](propsForConfigObjDefinitions)}
          <Form onSubmit={handleSubmit}>
            {
              (
                isFunction_(configObj[SECTION_DEFINITIONS])
                  ? configObj[SECTION_DEFINITIONS](propsForConfigObjDefinitions)
                  : configObj[SECTION_DEFINITIONS]
              ).map((section, sectionIndex) => (
                <Section
                  sectionOfConfigObj={section}
                  key={section[SECTION_KEY]}
                  {...propsForConfigObjDefinitions}
                  customBusinessUnitIdForContainerTypeDiffsPics={
                    isDistReportInventoryNoLoginRequired
                      ? configObjReturnedFromDistInvNoLoginConfigCall.customers[sectionIndex][0].businessUnitId
                      : isDistReportConstellationNoLoginRequired ? configObjReturnedFromDistInvNoLoginConfigCall.customers[0][sectionIndex].businessUnitId
                        : null
                  }
                />
              ))
            }


            {/* Submit Button */}
            {
              !isThisForDisplayingAnAlreadyCreatedInventoryReport && (
                <div
                  style={{ marginTop: '15px' }} // not too close to the last form field
                >
                  <Button
                    type="submit"
                    color={isFormSubmittable ? 'green' : 'grey'}
                    disabled={!isFormSubmittable || isSubmitting || didSubmitSucceed || didSubmitFail}
                    onClick={scrollToFormSubmissionContent}
                  >
                    {
                      isEditForm && areCurrentValuesSameAsInitialValues
                        ? translate('No Values Changed')
                        : translate('Submit')
                    }
                  </Button>
                  {!isFormSubmittable &&
                    <InformationalPopup
                      content={
                        translate(customerType === CUSTOMER_TYPES_DISTRIBUTOR
                          ? FORM_NOT_SUBMITTABLE_EXPLANATION_DISTRIBUTORS
                          : FORM_NOT_SUBMITTABLE_EXPLANATION_NON_DISTRIBUTORS)
                      }
                      includeTriggerIcon={false}
                      includeTriggerText
                      triggerText={translate("Why can't I submit?")}
                    />
                  }
                  {(!isDistReportInventoryNoLoginRequired && !isDistReportConstellationNoLoginRequired) &&
                    showDefectiveKegLink({ entireSubsidiariesSlice, entireCustomersSlice, customerId }) && <ReportDefectiveKegLink />
                  }
                </div>
              )
            }
          </Form>
        </FullDetailsModal>
      </FormSubmissionDimmerOverlay>
      }
    </UiContainer>
  )
}


function Section({
  sectionOfConfigObj: section,
  ...props
}) {
  const { t: translate }= useTranslation('common')
  if (
    section[ONLY_RENDER_SECTION_IF] &&
    !(section[ONLY_RENDER_SECTION_IF](props))
  ) {
    return null
  }

  let sectionTitle = section[SECTION_TITLE]
  if (isFunction_(sectionTitle)) { sectionTitle = sectionTitle(props) }

  const ContentAfterSectionTitle = section[ARBITRARY_CONTENT_AFTER_SECTION_TITLE_AND_BEFORE_SECTION_FORM_FIELDS]

  // Filter out fields whose ONLY_RENDER_FIELD_IF function returns false
  const sectionFieldsToRender = section[SECTION_FIELDS].filter(field => (
    !field[ONLY_RENDER_FIELD_IF]
    || field[ONLY_RENDER_FIELD_IF](props)
  ))

  // Group adjacent FORM_FIELD_KIND_INDIVIDUAL_FIELD fields with each other so
  // they can be put into the same HorizontalFormSection. Make
  // FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP fields each their own
  // group.
  const groupedSectionFields = sectionFieldsToRender.reduce(
    (acc, field) => {
      if (acc.length === 0) {
        return [[field]]
      }
      if (field[FORM_FIELD_KIND] === FORM_FIELD_KIND_INDIVIDUAL_FIELD) {
        const lastGroup = acc[acc.length - 1]
        if (lastGroup[lastGroup.length-1][FORM_FIELD_KIND] === FORM_FIELD_KIND_INDIVIDUAL_FIELD) {
          lastGroup.push(field)
          return acc
        }
        return [...acc, [field]]
      }
      return [...acc, [field]]
    },
    [],
  )

  return (
    <Segment vertical>
      {sectionTitle && <Header as="h3">{typeof sectionTitle === 'string' ? translate(sectionTitle) : sectionTitle}</Header>}
      {ContentAfterSectionTitle && <ContentAfterSectionTitle {...props} />}
      {groupedSectionFields.map((fields, index) => (
        <GroupOfReportInventoryFields
          fields={fields}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          {...props}
        />
      ))}
    </Segment>
  )
}


function GroupOfReportInventoryFields({
  fields,
  ...props
}) {
  if (fields[0][FORM_FIELD_KIND] === FORM_FIELD_KIND_INDIVIDUAL_FIELD) {
    return (
      <HorizontalFormSectionForReportInventory
        fields={fields}
        {...props}
      />
    )
  }

  // If it's not an Individual Form Field, it must be a labeled grid field
  return (
    <LabelToApiInventoryTypeMapFields
      fieldOfConfigObj={fields[0]}
      {...props}
    />
  )
}


function HorizontalFormSectionForReportInventory({
  fields,
  ...props
}) {
  const { t: translate } = useTranslation('common')
  const fieldsToRender = fields.filter(field => {
    const formFieldComponent = determineIndividualFormFieldComponentToRender({
      field,
      ...props,
    })
    return Boolean(formFieldComponent)
  })
  if (fieldsToRender.length === 0) { return null }

  return (
    <HorizontalFormSection>
      {fieldsToRender.map(field => {
        const FormFieldComponent = determineIndividualFormFieldComponentToRender({
          field,
          ...props,
        })
        return {
          label: translate(field[FIELD_LABEL]),
          arbitraryContentAfterLabelText: (
            field[ARBITRARY_CONTENT_AFTER_LABEL_TEXT]
              ? (isFunction_(field[ARBITRARY_CONTENT_AFTER_LABEL_TEXT])
                ? field[ARBITRARY_CONTENT_AFTER_LABEL_TEXT](props)
                : field[ARBITRARY_CONTENT_AFTER_LABEL_TEXT]
              )
              : null
          ),
          required: field[IS_FORM_FIELD_REQUIRED],
          field: <FormFieldComponent {...props} />,
        }
      })}
    </HorizontalFormSection>
  )
}


// Helper functions

function determineIndividualFormFieldComponentToRender({
  field,
  isEditForm,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
}) {
  if (isEditForm) {
    if (field[EDIT_FORM_FIELD_COMPONENT] === SAME_AS_REPORT_FORM_FIELD_COMPONENT) {
      return field[REPORT_FORM_FIELD_COMPONENT]
    } else if (field[EDIT_FORM_FIELD_COMPONENT] === SAME_AS_FULL_DETAILS_COMPONENT) {
      return field[FULL_DETAILS_COMPONENT]
    }
    return field[EDIT_FORM_FIELD_COMPONENT]
  }

  if (isThisForDisplayingAnAlreadyCreatedInventoryReport) {
    if (field[FULL_DETAILS_COMPONENT] === SAME_AS_REPORT_FORM_FIELD_COMPONENT) {
      return field[REPORT_FORM_FIELD_COMPONENT]
    } else if (field[FULL_DETAILS_COMPONENT] === SAME_AS_EDIT_FORM_FIELD_COMPONENT) {
      return field[EDIT_FORM_FIELD_COMPONENT]
    }
    return field[FULL_DETAILS_COMPONENT]
  }

  if (field[REPORT_FORM_FIELD_COMPONENT] === SAME_AS_EDIT_FORM_FIELD_COMPONENT) {
    return field[EDIT_FORM_FIELD_COMPONENT]
  } else if (field[REPORT_FORM_FIELD_COMPONENT] === SAME_AS_FULL_DETAILS_COMPONENT) {
    return field[FULL_DETAILS_COMPONENT]
  }
  return field[REPORT_FORM_FIELD_COMPONENT]
}


function ErrorMessageDistInvNoLogin({
  type,
  ...allDistInvNoLoginStateVars
}) {
  const {
    dispatch,
    configObjReturnedFromDistInvNoLoginConfigCall,
  } = allDistInvNoLoginStateVars
  let header
  let content
  const { t: translate } = useTranslation('common')
  if (type === 'expired') {
    header = 'Link Expired'
    content = "This email link has expired (links are only good for a couple weeks). Check for a more recent email from MicroStar Kegs. If you feel you've received this message in error, contact us."
  } else if (type === 'alreadyReported') {
    header = 'Already Reported'
    content = "You've already reported empty kegs using this email link. If you feel you've received this message in error, contact us."
  } else if (type === 'emptyCustomersArray') {
    header = 'No One to Report For'
    content = `${translate('Your user')}, ${configObjReturnedFromDistInvNoLoginConfigCall.emailAddress}, ${translate("has no distributors selected for it to report empty kegs for; contact MicroStar and ask a rep to change this. Click the Retry button once they've made the change.")}}`
  } else if (type === 'noPermissions') {
    header = 'Permissions Problem'
    content = "You do not currently have permissions to report empty kegs; contact MicroStar and ask a CE rep to grant you Create and Read Inventory permissions. Click the Retry button once they've made the change."
  } else if (type === 'expired-constellation') {
    header = 'Link Expired'
    content = 'The period to submit your Constellation Pallet Reconcilliation Inventory has ended.  Please contact Microstar if concerned.'
  } else {
    header = 'Unknown Error'
    content = DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR
  }
  return (
    <Fragment>
      <Message
        error
        header={translate(header)}
        content={
          <Fragment>
            <p>{translate(content)}</p>
            <Button
              primary
              onClick={() => {
                dispatch(createAction(
                  FETCH_INFO_NEEDED_TO_CONSTRUCT_DIST_REPORT_INVENTORY_NO_LOGIN_REQUIRED_FORM,
                  {
                    ...allDistInvNoLoginStateVars,
                  },
                ))
              }}
            >
              Retry
            </Button>
          </Fragment>
        }
        style={{ maxWidth: '450px' }}
      />
      <ContactCard repType={CUSTOMER_REPS_GENERIC} />
    </Fragment>
  )
}

function SuccessButtonForDistInvNoLogin() {
  return (
    <Button
      as={Link}
      to={URL_PATHS.login.path}
      type="button" // CODE_COMMENTS_260
      color="blue"
    >
      Log in
    </Button>
  )
}
