import React from 'react'

import findIndex_ from 'lodash/findIndex'
import values_ from 'lodash/values'
import cloneDeep_ from 'lodash/cloneDeep'


import DownloadTableAsFile from '../../../sharedComponents/DownloadTableAsFile'

import {
  getOriginNameForOutboundShipmentsHistoryTable,
} from '../../../util/shipmentHistory/outboundFullKegShipmentHistory/displayedDataEtc/util'
import {
  // Why are we using a bunch of functions from the
  // outboundFullKegShipmentHistory component? Because that component and this
  // component refer to the same shipments: outbound full keg shipments for
  // Brewers are inbound full keg shipments for Distributors. See
  // CODE_COMMENTS_205.
  getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
  getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable,
} from '../../../util/shipmentHistory/outboundFullKegShipmentHistory'
import {
  getInfoDefinitionsInboundFullKegShipmentFullDetails,
} from '../../../util/shipmentHistory/inboundFullKegShipmentHistory'
import {
  createDisplayedProNumber,
} from '../../../util/shipmentHistory/common/displayedShipmentDataEtc'
import {
  determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
} from '../../../util/shipmentHistory/common/util'
import {
  createFilenameOfDownloadedTableFile,
  constructNonHeadingRowsOfDownloadedTableFile,
} from '../../../util/util'
import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../../util'

import {
  INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
  INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM,
} from '../../../../../../constants/formAndApiUrlConfig/histories/InboundFullKegShipmentHistory'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../../../constants/formAndApiUrlConfig/histories/historyShared'


export default ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entireItemSkusSlice,
  customerId,
  operatingContractBrewerCustomerId,
  configuredItemSkuIds,
  allInboundFullKegShipmentHistoryObjects,
  mostRecentlySubmittedFormValues,
}) => {
  if (!mostRecentlySubmittedFormValues) {
    return <DownloadTableAsFile data={null} filename={null} />
  }

  const allInboundFullKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllOutboundFullKegShipmentObjectsToBeIncludedInHistoryTable(
      allInboundFullKegShipmentHistoryObjects,
    )

  const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
    entireItemSkusSlice,
    configuredItemSkuIds,
    shipments: allInboundFullKegShipmentHistoryObjects,
  })

  const filename = createFilename(mostRecentlySubmittedFormValues)

  const data = createFileData({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entireItemSkusSlice,
    customerId,
    operatingContractBrewerCustomerId,
    allInboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
    itemSkuIdsToIncludeAsFieldsInTable,
  })

  return (
    <DownloadTableAsFile
      data={data}
      filename={filename}
    />
  )
}

/*
 * *****************************************************************************
 * helper functions
 * *****************************************************************************
*/

function createFilename(mostRecentlySubmittedFormValues) {
  const historyFormStatus = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_STATUS
  ]
  const historyFormStartDate = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_START_DATE
  ]
  const historyFormEndDate = mostRecentlySubmittedFormValues[
    HISTORY_FORM_FIELD_NAME_END_DATE
  ]

  return createFilenameOfDownloadedTableFile(
    'inbound shipments',
    historyFormStartDate,
    historyFormEndDate,
    { status: historyFormStatus },
  )
}


// array of arrays, with first array being column headings
function createFileData({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entireItemSkusSlice,
  customerId,
  operatingContractBrewerCustomerId,
  allInboundFullKegShipmentObjectsToBeIncludedInHistoryTable,
  itemSkuIdsToIncludeAsFieldsInTable,
}) {
  const originalDataDefinitions = getInfoDefinitionsInboundFullKegShipmentFullDetails({
    entireItemSkusSlice,
    itemSkuIdsToIncludeAsFieldsInTable,
    entireCustomersSlice,
    customerId,
  })

  let dataDefinitions = cloneDeep_(originalDataDefinitions)
  dataDefinitions = swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions)
  dataDefinitions = swapCustomerNamesDefinition({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    operatingContractBrewerCustomerId,
    dataDefinitions,
  })

  let arrayOfShipmentObjects = values_(allInboundFullKegShipmentObjectsToBeIncludedInHistoryTable)
  arrayOfShipmentObjects = createApiDateSortFunctionForHistoryTable({ datePropName: 'dateShipped' })(arrayOfShipmentObjects)
  arrayOfShipmentObjects = arrayOfShipmentObjects.filter(getShouldOutboundFullKegShipmentHistoryItemBeDisplayedInTable)

  const nonHeadingRows = constructNonHeadingRowsOfDownloadedTableFile(arrayOfShipmentObjects, dataDefinitions)

  return [
    dataDefinitions.map(o => o.heading), // headings row
    ...nonHeadingRows,
  ]
}


// CODE_COMMENTS_111
function swapDefinitionsToShowNothingRatherThanAlternateTextOnUndefinedProp(dataDefinitions) {
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM),
    1,
    { heading: INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM, cellContent: createDisplayedProNumber() },
  )

  // CODE_COMMENTS_109
  return dataDefinitions
}


// CODE_COMMENTS_132
function swapCustomerNamesDefinition({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
  operatingContractBrewerCustomerId,
  dataDefinitions,
}) {
  // Origin field, see CODE_COMMENTS_130
  dataDefinitions.splice(
    findIndex_(dataDefinitions, o => o.heading === INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN),
    1,
    {
      heading: INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
      cellContent: row => getOriginNameForOutboundShipmentsHistoryTable({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        customerId,
        operatingContractBrewerCustomerId,
        row,
      }),
    },
  )

  // CODE_COMMENTS_109
  return dataDefinitions
}
