import React, { Fragment, useState, useEffect, useImperativeHandle } from 'react'
import { Header, Form, Grid } from 'semantic-ui-react'

import get_ from 'lodash/get'


import { useTranslation } from 'react-i18next'
import InputNoAutoComplete from '../../../../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import PhoneNumberInput from '../../../../../../common-components/PhoneNumberInput'

import {
  FETCH_EDIT_USER_INFO_OF_ONE_USER,
} from '../../../../../../redux/actions/actionTypes'
import createAction from '../../../../../../redux/actions/createAction'

import {
  formatPhoneNumberForDisplay,
  formatPhoneNumberAndPotentialExtensionForDisplay,
  getAreFormValuesSameAsInitialValues,
  getIsPossiblePhoneNumber,
} from '../../../../../../utils'


const formLabelColumnProps = {
  style: { flex: '0 0 100px' }, // CODE_COMMENTS_22
  verticalAlign: 'middle',
}
const formValueColumnProps = {
  style: {
    // field should stretch the length of the page; see CODE_COMMENTS_22
    flex: '1',
    // We're dealing with user-provided data here, which means one word
    // could get long--someone has a long email address, for example. We
    // don't want long email addresses, which HTML considers a single
    // word, to overflow into the grid beside it. This CSS makes sure it
    // doesn't.
    wordBreak: 'break-all',
  },
  verticalAlign: 'middle',
}


export default ({
  customerId,
  userObject,
  inEditMode,
  refEditUserBasicInfoFormHandleSubmit,
  refEditUserBasicInfoFormOnCancelEditUser,
  setareCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues,
  setisEditUserBasicInfoFormSubmittable,
  setisEditUserBasicInfoSubmitting,
  setdidEditUserBasicInfoSubmissionSucceed,
  setdidEditUserBasicInfoSubmissionFail,
  dispatch,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [initialValues, setInitialValues] = useState(getInitialFormValues(userObject))
  const { t: translate } = useTranslation('common')

  const [firstName, setFirstName] = useState(initialValues.firstName)
  const [lastName, setLastName] = useState(initialValues.lastName)
  const [deskPhoneNumber, setDeskPhoneNumber] = useState(initialValues.deskPhoneNumber)
  const [deskPhoneExt, setDeskPhoneExt] = useState(initialValues.deskPhoneExt)
  const [cellPhoneNumber, setCellPhoneNumber] = useState(initialValues.cellPhoneNumber)
  const [faxNumber, setFaxNumber] = useState(initialValues.faxNumber)

  // Every time a user changes the form values, determine whether current values
  // are the same as initial values and whether the form is submittable.
  useEffect(
    () => {
      const formValues = {
        firstName,
        lastName,
        deskPhoneNumber,
        deskPhoneExt,
        cellPhoneNumber,
        faxNumber,
      }
      setareCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues(
        getAreFormValuesSameAsInitialValues(initialValues, formValues),
      )
      setisEditUserBasicInfoFormSubmittable(getIsFormSubmittable({ initialValues, formValues }))
    },
    [
      initialValues,
      firstName,
      lastName,
      deskPhoneNumber,
      deskPhoneExt,
      cellPhoneNumber,
      faxNumber,
      setareCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues,
      setisEditUserBasicInfoFormSubmittable,
    ],
  )

  // CODE_COMMENTS_258
  useImperativeHandle(
    refEditUserBasicInfoFormHandleSubmit,
    () => ({
      handleSubmit: () => {
        const formValues = {
          firstName,
          lastName,
          deskPhoneNumber,
          deskPhoneExt,
          cellPhoneNumber,
          faxNumber,
        }
        if (getAreFormValuesSameAsInitialValues(initialValues, formValues)) {
          return
        }
        dispatch(createAction(
          FETCH_EDIT_USER_INFO_OF_ONE_USER,
          {
            customerId,
            userObject,
            formValues: {
              firstName,
              lastName,
              deskPhoneNumber,
              deskPhoneExt,
              cellPhoneNumber,
              faxNumber,
            },
            setisEditUserBasicInfoSubmitting,
            setdidEditUserBasicInfoSubmissionSucceed,
            setdidEditUserBasicInfoSubmissionFail,
          },
        ))
      },
    }),
    [
      dispatch,
      customerId,
      userObject,
      initialValues,
      firstName,
      lastName,
      deskPhoneNumber,
      deskPhoneExt,
      cellPhoneNumber,
      faxNumber,
      setisEditUserBasicInfoSubmitting,
      setdidEditUserBasicInfoSubmissionSucceed,
      setdidEditUserBasicInfoSubmissionFail,
    ],
  )

  // CODE_COMMENTS_258
  useImperativeHandle(
    refEditUserBasicInfoFormOnCancelEditUser,
    () => ({
      onCancelEditUser: () => {
        setFirstName(initialValues.firstName)
        setLastName(initialValues.lastName)
        setDeskPhoneNumber(initialValues.deskPhoneNumber)
        setDeskPhoneExt(initialValues.deskPhoneExt)
        setCellPhoneNumber(initialValues.cellPhoneNumber)
        setFaxNumber(initialValues.faxNumber)
      },
    }),
    [initialValues],
  )

  // When the user object changes (e.g. after edit), reset form values
  useEffect(
    () => {
      const initialValues_ = getInitialFormValues(userObject)
      setInitialValues(initialValues_)
      setFirstName(initialValues_.firstName)
      setLastName(initialValues_.lastName)
      setDeskPhoneNumber(initialValues_.deskPhoneNumber)
      setDeskPhoneExt(initialValues_.deskPhoneExt)
      setCellPhoneNumber(initialValues_.cellPhoneNumber)
      setFaxNumber(initialValues_.faxNumber)
    },
    [userObject],
  )

  return (
    <Fragment>
      <Header as="h4">Info</Header>
      <Form className="tight-grid-rows-form">
        <Grid
          key="userBasicInfo"
          columns={2}
        >


          <Grid.Row key={'firstName'}>
            <Grid.Column {...formLabelColumnProps}>
              {`${translate('First Name')}:`}
            </Grid.Column>
            <Grid.Column {...formValueColumnProps} >
              {
                inEditMode
                  ? (
                    <InputNoAutoComplete
                      name="firstName"
                      value={firstName}
                      onChange={e => {
                        const input = e.target.value
                        if (input.length <= 100) { // CODE_COMMENTS_192
                          setFirstName(input)
                        }
                      }}
                      fluid
                    />
                  )
                  : (
                    userObject.firstName
                      ? userObject.firstName
                      : <span style={{ color: 'grey' }}>none</span>
                  )
              }
            </Grid.Column>
          </Grid.Row>


          <Grid.Row key={'lastName'}>
            <Grid.Column {...formLabelColumnProps}>
              {`${translate('Last Name')}:`}
            </Grid.Column>
            <Grid.Column {...formValueColumnProps} >
              {
                inEditMode
                  ? (
                    <InputNoAutoComplete
                      name="lastName"
                      value={lastName}
                      onChange={e => {
                        const input = e.target.value
                        if (input.length <= 100) { // CODE_COMMENTS_192
                          setLastName(input)
                        }
                      }}
                      fluid
                    />
                  )
                  : (
                    userObject.lastName
                      ? userObject.lastName
                      : <span style={{ color: 'grey' }}>none</span>
                  )
              }
            </Grid.Column>
          </Grid.Row>


          <Grid.Row key={'email'}>
            <Grid.Column {...formLabelColumnProps}>
              {`${translate('Email')}:`}
            </Grid.Column>
            <Grid.Column {...formValueColumnProps} >
              {
                userObject.emailAddress
                  ? userObject.emailAddress
                  : <span style={{ color: 'grey' }}>none</span>
              }
            </Grid.Column>
          </Grid.Row>


          <Grid.Row key={'deskPhoneNumber'}>
            <Grid.Column {...formLabelColumnProps}>
              {`${translate('Desk')}:`}
            </Grid.Column>
            <Grid.Column {...formValueColumnProps} >
              {
                inEditMode
                  ? (
                    <PhoneNumberInput
                      name="deskPhoneNumber"
                      initialValue={initialValues.deskPhoneNumber}
                      value={deskPhoneNumber}
                      onChange={setDeskPhoneNumber}
                      includeExtension
                      extensionValue={deskPhoneExt}
                      onExtensionChange={setDeskPhoneExt}
                    />
                  )
                  : (
                    userObject.deskPhoneNumber
                      ? formatPhoneNumberAndPotentialExtensionForDisplay(
                        userObject.deskPhoneNumber,
                        userObject.deskPhoneExt,
                      )
                      : <span style={{ color: 'grey' }}>none</span>
                  )
              }
            </Grid.Column>
          </Grid.Row>


          <Grid.Row key={'cellPhoneNumber'}>
            <Grid.Column {...formLabelColumnProps}>
              {`${translate('Cell')}:`}
            </Grid.Column>
            <Grid.Column {...formValueColumnProps} >
              {
                inEditMode
                  ? (
                    <div className="ui input"> {/* CODE_COMMENTS_32 */}
                      <PhoneNumberInput
                        name="cellPhoneNumber"
                        initialValue={initialValues.cellPhoneNumber}
                        value={cellPhoneNumber}
                        onChange={setCellPhoneNumber}
                      />
                    </div>
                  )
                  : (
                    userObject.cellPhoneNumber
                      ? formatPhoneNumberForDisplay(userObject.cellPhoneNumber)
                      : <span style={{ color: 'grey' }}>none</span>
                  )
              }
            </Grid.Column>
          </Grid.Row>


          <Grid.Row key={'faxNumber'}>
            <Grid.Column {...formLabelColumnProps}>
              {`${translate('Fax')}:`}
            </Grid.Column>
            <Grid.Column {...formValueColumnProps} >
              {
                inEditMode
                  ? (
                    <PhoneNumberInput
                      name="faxNumber"
                      initialValue={initialValues.faxNumber}
                      value={faxNumber}
                      onChange={setFaxNumber}
                    />
                  )
                  : (
                    userObject.faxNumber
                      ? formatPhoneNumberForDisplay(userObject.faxNumber)
                      : <span style={{ color: 'grey' }}>none</span>
                  )
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Fragment>
  )
}


function getInitialFormValues(userObject) {
  return {
    firstName: get_(userObject, 'firstName', ''),
    lastName: get_(userObject, 'lastName', ''),
    deskPhoneNumber: get_(userObject, 'deskPhoneNumber', ''),
    deskPhoneExt: get_(userObject, 'deskPhoneExt', ''),
    cellPhoneNumber: get_(userObject, 'cellPhoneNumber', ''),
    faxNumber: get_(userObject, 'faxNumber', ''),
  }
}


function getIsFormSubmittable(props) {
  const {
    initialValues,
    formValues,
  } = props

  const {
    deskPhoneNumber,
    cellPhoneNumber,
    faxNumber,
  } = formValues

  const propsThatMustBeEitherBlankOrPossiblePhoneNumbers = [
    deskPhoneNumber,
    cellPhoneNumber,
    faxNumber,
  ]

  return (
    propsThatMustBeEitherBlankOrPossiblePhoneNumbers.every(s => getIsPossiblePhoneNumber(s))
    && !getAreFormValuesSameAsInitialValues(initialValues, formValues)
  )
}
