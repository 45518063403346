import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


import RouteNotFoundIfInvalidCustomerId from '../components/RouteNotFoundIfInvalidCustomerId'

import {
  getProp as getCustomerProp,
  getDoesCustomerHaveChild,
} from '../../../redux/selectors/customers'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../redux/selectors/rewrite/contracts'
import {
  getCustomerIdsOfAllContracteesOfContractBrewer,
} from '../../../redux/selectors/rewrite/relationships/relatedFromInfo'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'

import {
  URL_PATH_DPS_TEXT_CUSTOMER_ID,
} from '../../../constants'


const mapStateToProps = (state, { match, operatorCustomerId }) => {
  const operateeCustomerId = match.params[URL_PATH_DPS_TEXT_CUSTOMER_ID]
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const isOperatorAtAllowedToOperateForOperatee = (
    // Master-Child relationship
    getDoesCustomerHaveChild(state, operatorCustomerId, operateeCustomerId) ||
    // Contract Brewer to Contractee Brewer relationship
    getCustomerIdsOfAllContracteesOfContractBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId: operatorCustomerId,
    }).includes(operateeCustomerId)
  )

  const operatorCustomerType = getCustomerProp(state, operatorCustomerId, 'customerType')

  return ({
    isOperatorAtAllowedToOperateForOperatee,
    operateeCustomerId,
    operatorCustomerType,
    operatorCustomerId,
  })
}

export default withRouter( // CODE_COMMENTS_25
  connect(
    mapStateToProps,
  )(RouteNotFoundIfInvalidCustomerId),
)
