import { connect } from 'react-redux'


import FetchFailureContent from '../../FetchFailureContent'

import customerRepsFetchStatusSelectors from '../../../redux/selectors/fetchStatuses/customers/customerRepresentatives'
import universalCustomerRepsFetchStatusSelectors from '../../../redux/selectors/fetchStatuses/universalCustomerRepresentatives'

import createFetchFailedContactCard from '../components/FetchFailedContactCard'

import createAction from '../../../redux/actions/createAction'
import {
  FETCH_CUSTOMER_REPRESENTATIVE_INFO,
  FETCH_UNIVERSAL_CUSTOMER_REPRESENTATIVE,
} from '../../../redux/actions/actionTypes'

import {
  getIsUniversalCustomerRepType,
} from '../../../utils'

import {
  DISPLAYED_ERROR_MESSAGES_FETCH_NO_RESPONSE_CODE,
  DISPLAYED_ERROR_MESSAGES_PART_TECH_TEAM_ALERTED,
} from '../../../constants'


const createInstructions = (repType, retryFetchCustomerRepresentative) => ({
  renderWhenTrue: [
    {
      when: ({ errorCode }) => !errorCode,
      render: createFetchFailedContactCard(
        repType,
        DISPLAYED_ERROR_MESSAGES_FETCH_NO_RESPONSE_CODE,
        retryFetchCustomerRepresentative,
      ),
    },
  ],
  renderWhenNoneTrue: createFetchFailedContactCard(
    repType,
    `Failed to fetch contact info! ${DISPLAYED_ERROR_MESSAGES_PART_TECH_TEAM_ALERTED}`,
    retryFetchCustomerRepresentative,
  ),
})


const mapStateToProps = (state, { repId, repType }) => {
  const isUniversalCustomerRepType = getIsUniversalCustomerRepType(repType) // CODE_COMMENTS_241
  const {
    getFetchFailureErrorDetails: getCustomerRepsFetchFailureErrorDetails,
  } = customerRepsFetchStatusSelectors
  const {
    getFetchFailureErrorDetails: getUniversalCustomerRepsFetchFailureErrorDetails,
  } = universalCustomerRepsFetchStatusSelectors
  const errorDetails = isUniversalCustomerRepType
    ? getUniversalCustomerRepsFetchFailureErrorDetails(state, repType)
    : getCustomerRepsFetchFailureErrorDetails(state, repId)

  return {
    // the renderWhenNoneTrue component needs these
    errorDetails,
  }
}

const mapDispatchToProps = (dispatch, { repType, repId }) => {
  const isUniversalCustomerRepType = getIsUniversalCustomerRepType(repType) // CODE_COMMENTS_241
  const retryFetchCustomerRepresentative = () => {
    if (isUniversalCustomerRepType) {
      dispatch(createAction(FETCH_UNIVERSAL_CUSTOMER_REPRESENTATIVE, { repType }))
    } else {
      dispatch(createAction(FETCH_CUSTOMER_REPRESENTATIVE_INFO, { repId }))
    }
  }

  const instructions = createInstructions(repType, retryFetchCustomerRepresentative)

  return {
    instructions,
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FetchFailureContent)
