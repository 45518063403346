/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file, see CODE COMMENTS 50

import { call, put, select } from 'redux-saga/effects'

import flow_ from 'lodash/fp/flow'
import valuesFp_ from 'lodash/fp/values'
import filterFp_ from 'lodash/fp/filter'
import mapFp_ from 'lodash/fp/map'


import {
  SAVE_SINGLE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM,
  SAVE_SINGLE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM,
  SAVE_SINGLE_KEG_FILL_CONTRACTEE_HISTORY_ITEM,
  DELETE_NO_MOVEMENTS_HISTORY_ITEM, SCHEDULED_SHIPMENT,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'

import {
  getProp as getCustomerProp,
} from '../../../selectors/customers'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'
import {
  getEntireSlice as getAllNoMovementsHistoryObjects,
} from '../../../selectors/histories/noMovementsHistory'

import {
  getShouldOutboundShipmentHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm,
} from '../../../../features/History/individualTabs/util/shipmentHistory/outboundFullKegShipmentHistory'

import {
  getShouldInboundShipmentHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm,
} from '../../../../features/History/individualTabs/util/shipmentHistory/inboundEmptyKegShipmentHistory'

import {
  REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../../../constants/formAndApiUrlConfig/reportOutboundShipments'
import {
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
} from '../../../../constants/formAndApiUrlConfig/reportSelfDistAndPubShipments'
import {
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE,
} from '../../../../constants/formAndApiUrlConfig/reportBuybackShipments'
import {
  REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
} from '../../../../constants/formAndApiUrlConfig/reportKegFills'
import {
  REPORT_SELF_COLLECTION_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../../../constants/formAndApiUrlConfig/reportSelfCollectionShipments'
import {
  FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS,
} from '../../../../constants/formAndApiUrlConfig/acknowledgeInboundShipments'


import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
  HISTORY_FORM_FIELD_NAME_STATUS,
} from '../../../../constants/formAndApiUrlConfig/histories/historyShared'


import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,

  HISTORY_FORM_NAME_INBOUND_EMPTY_KEG_SHIPMENTS,
  HISTORY_FORM_NAME_OUTBOUND_FULL_KEG_SHIPMENTS,
  HISTORY_FORM_NAME_KEG_FILLS,
  HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE, CUSTOMER_TYPES_WAREHOUSE,
} from '../../../../constants'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  createFormNameForRedux,
  doesHistoryItemDateFallWithinHistoryFormDateRange,
  isTruthyAndNonEmpty,
  convertApiDateToMoment,
  formatDateForApiCall,
} from '../../../../utils'

/*
 * *****************************************************************************
 * Save History Object returned from successful POST/PUT to Redux Store
 * *****************************************************************************
 */

// Used by the reportOutboundShipments sagas and the acknowledgeInboundShipments
// saga
export function* saveShipmentHistoryObjectToReduxStoreIfMeetsCriteriaOfHistoryForm({
  customerId,
  operatingContractBrewerCustomerId,
  fieldArrayName,
  shipmentObj,
  historyName,
}) {
  const historyFormReducerName = yield call(
    getReducerNameOfHistoryFormThisShipmentHistoryItemWillBeDisplayedIn,
    customerId,
    fieldArrayName,
  )

  const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
    customerId,
    operatingContractBrewerCustomerId,
  )

  const historyFormName = createFormNameForRedux({
    reducerName: historyFormReducerName,
    customerId,
    operatingContractBrewerCustomerId,
  })

  // CODE_COMMENTS_279: Customers can report a special type of inbound shipment:
  // SELFDIST2CONBRW, the destination being the CBMST contract. Either logged-in
  // Brewers (who have a conbrw contract) or logged-in Conbrws can report this
  // shipment (depending on which of them has the relationship-level permission
  // to report shipments on a conbrw contract). If a logged-in BRW reports this
  // shipment, it shouldn't appear anywhere in their history, because logged-in
  // BRWs aren't able to query shipments to/from their related-to CONBRW's CBMST
  // contract. (If the CONBRW reports this shipment, the shipment should show up
  // in the CONBRW's CBMST shipment history, so we don't filter out such
  // shipments here.)
  const customerType = yield select(getCustomerProp, customerId, 'customerType')
  let destinationCustomerType
  if (customerType !== CUSTOMER_TYPES_WAREHOUSE) {
    destinationCustomerType = yield select(getCustomerProp, shipmentObj.destinationCustomerId, 'customerType')
  }
  if (
    fieldArrayName === REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM
    && customerType === CUSTOMER_TYPES_BREWER
    && destinationCustomerType === CUSTOMER_TYPES_CONTRACT_BREWER
  ) {
    return
  }

  const doesShipmentReportObjectMeetCriteriaOfHistoryForm = yield call(
    getDoesShipmentReportObjectMeetCriteriaOfHistoryForm,
    historyFormName,
    shipmentObj,
    historyFormReducerName,
    historyName,
  )

  if (doesShipmentReportObjectMeetCriteriaOfHistoryForm) {
    yield call(
      saveShipmentHistoryObjectToReduxStore,
      {
        reduxIdentifier,
        shipmentObj,
        historyFormReducerName,
        fieldArrayName,
      },
    )
  }

  yield call(
    specialSaveKegFillObjectToContracteeKegFillsReduxStoreIfMeetsCriteriaOfHistoryForm,
    {
      customerId,
      fieldArrayName,
      shipmentObj,
    },
  )
}

// CODE_COMMENTS_235: If a logged-in CONBRW reports a keg fill, the created
// shipment object will have been saved to the CONBRW's "Keg Fills" history tab,
// but it _also_ needs to be saved to the "Keg Fills" history tab of the
// contractee (imagine, for example, that this the CONBRW can operate for and
// view keg fills history objects for this contractee).
function* specialSaveKegFillObjectToContracteeKegFillsReduxStoreIfMeetsCriteriaOfHistoryForm({
  customerId,
  fieldArrayName,
  shipmentObj,
}) {
  const historyFormReducerName = yield call(
    getReducerNameOfHistoryFormThisShipmentHistoryItemWillBeDisplayedIn,
    customerId,
    fieldArrayName,
  )
  if (historyFormReducerName !== HISTORY_FORM_NAME_KEG_FILLS) {
    return
  }
  const conbrwCustomerId = customerId
  const contracteeCustomerId = shipmentObj.destinationCustomerId

  const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
    contracteeCustomerId,
    conbrwCustomerId,
  )

  const historyFormName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE,
    customerId: contracteeCustomerId,
    operatingContractBrewerCustomerId: conbrwCustomerId,
  })

  const doesShipmentReportObjectMeetCriteriaOfHistoryForm = yield call(
    getDoesShipmentReportObjectMeetCriteriaOfHistoryForm,
    historyFormName,
    shipmentObj,
    HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE,
  )

  if (doesShipmentReportObjectMeetCriteriaOfHistoryForm) {
    yield call(
      saveShipmentHistoryObjectToReduxStore,
      {
        reduxIdentifier,
        shipmentObj,
        historyFormReducerName: HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE,
        fieldArrayName,
      },
    )
  }
}


// Helper functions for this section


function* getReducerNameOfHistoryFormThisShipmentHistoryItemWillBeDisplayedIn(
  customerId,
  fieldArrayName,
) {
  if ([
    FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS,
    REPORT_SELF_COLLECTION_SHIPMENTS_FIELD_ARRAY_NAME,
  ].includes(fieldArrayName)) {
    return HISTORY_FORM_NAME_INBOUND_EMPTY_KEG_SHIPMENTS
  }

  const customerType = yield select(getCustomerProp, customerId, 'customerType')
  if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    return HISTORY_FORM_NAME_KEG_FILLS
  }

  // Remember that some Brewers are responsible for reporting the kegs that
  // their CONBRWs fill for them.
  if (
    customerType === CUSTOMER_TYPES_BREWER
    && fieldArrayName === REPORT_KEG_FILLS_FIELD_ARRAY_NAME
  ) {
    return HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE
  }

  if (
    [
      REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
      REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE,
    ].includes(fieldArrayName)
  ) {
    return HISTORY_FORM_NAME_INBOUND_EMPTY_KEG_SHIPMENTS
  }

  return HISTORY_FORM_NAME_OUTBOUND_FULL_KEG_SHIPMENTS
}


function* getDoesShipmentReportObjectMeetCriteriaOfHistoryForm(
  historyFormName,
  shipmentObj,
  historyFormReducerName,
  historyName,
) {
  const mostRecentlySubmittedFormValues = yield select(getMostRecentlySubmittedFormValues, historyFormName)
  // if history form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return false }

  const historyFormStartDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_START_DATE]
  const historyFormEndDate = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_END_DATE]
  const historyFormStatus = mostRecentlySubmittedFormValues[HISTORY_FORM_FIELD_NAME_STATUS]

  const dateOk = historyName !== SCHEDULED_SHIPMENT ? doesHistoryItemDateFallWithinHistoryFormDateRange(
    shipmentObj.dateShipped,
    historyFormStartDate,
    historyFormEndDate,
  ) : true

  const currentlySelectedStatusInHistoryFormCheckingFunction = getCurrentlySelectedStatusInHistoryFormCheckingFunction(
    historyFormReducerName,
  )
  const statusOk = historyName !== SCHEDULED_SHIPMENT ? currentlySelectedStatusInHistoryFormCheckingFunction(
    historyFormStatus,
    shipmentObj,
  ) : true
  return (dateOk && statusOk)
}


function* saveShipmentHistoryObjectToReduxStore({
  reduxIdentifier,
  shipmentObj,
  historyFormReducerName,
  fieldArrayName,
}) {
  const historyFormReducerNameToSaveActionMap = {
    [HISTORY_FORM_NAME_OUTBOUND_FULL_KEG_SHIPMENTS]: SAVE_SINGLE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM,
    [HISTORY_FORM_NAME_INBOUND_EMPTY_KEG_SHIPMENTS]: SAVE_SINGLE_INBOUND_EMPTY_KEG_SHIPMENT_HISTORY_ITEM,
    [HISTORY_FORM_NAME_KEG_FILLS]: SAVE_SINGLE_OUTBOUND_FULL_KEG_SHIPMENT_HISTORY_ITEM,
    [HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE]: SAVE_SINGLE_KEG_FILL_CONTRACTEE_HISTORY_ITEM,
  }

  yield put(createAction(
    historyFormReducerNameToSaveActionMap[historyFormReducerName],
    {
      savePath: [reduxIdentifier],
      info: shipmentObj,
    },
  ))

  yield call(
    deleteNoMovementsReportIfOneExistsForThePeriodThisShipmentFallsIn,
    {
      reduxIdentifier,
      shipmentObj,
      fieldArrayName,
    },
  )
}


function getCurrentlySelectedStatusInHistoryFormCheckingFunction(
  historyFormReducerName,
) {
  if (
    [
      HISTORY_FORM_NAME_OUTBOUND_FULL_KEG_SHIPMENTS,
      HISTORY_FORM_NAME_KEG_FILLS,
      HISTORY_FORM_NAME_KEG_FILLS_CONTRACTEE,
    ].includes(historyFormReducerName)
  ) {
    return getShouldOutboundShipmentHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm
  }
  return getShouldInboundShipmentHistoryItemObjBeDisplayedInTableBasedOnCurrentlySelectedStatusInHistoryForm
}


// If a user creates a NoMovements report but then reports an outbound shipment
// for that period, delete the NoMovements report
function* deleteNoMovementsReportIfOneExistsForThePeriodThisShipmentFallsIn({
  fieldArrayName,
  reduxIdentifier,
  shipmentObj,
}) {
  if ([
    REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
    REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
    REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
  ].includes(fieldArrayName)) {
    const allNoMovementsObjects = yield select(
      getAllNoMovementsHistoryObjects,
      // CODE_COMMENTS_196
      reduxIdentifier,
    )
    const dateShippedMoment = convertApiDateToMoment(shipmentObj.dateShipped, true)
    const reportingPeriodStartDateOfNoMovementsObjectsToDelete = formatDateForApiCall({
      date: dateShippedMoment.startOf('month'),
      startOfDayRatherThanNoonLocalTime: true,
      useUtcInsteadOfLocalTime: true,
    })
    const noMovementsIdsToDelete = flow_(
      valuesFp_,
      filterFp_(o => o.reportingPeriodStartDate === reportingPeriodStartDateOfNoMovementsObjectsToDelete),
      mapFp_(o => o.id),
    )(allNoMovementsObjects)

    if (isTruthyAndNonEmpty(noMovementsIdsToDelete)) {
      for (const itemId of noMovementsIdsToDelete) { // CODE_COMMENTS_50
        yield put(createAction(
          DELETE_NO_MOVEMENTS_HISTORY_ITEM,
          {
            savePath: [reduxIdentifier],
            itemId,
          },
        ))
      }
    }
  }
}
