// CODE_COMMENTS_232

import { takeEvery } from 'redux-saga/effects'

import uniq_ from 'lodash/uniq'

import {
  shipmentHistorySagaCreator,
} from './shipmentHistorySagaCreator'

import {
  FETCH_KEG_FILL_CONTRACTEE_HISTORY,
  SAVE_KEG_FILL_CONTRACTEE_HISTORY,
} from '../../../actions/actionTypes'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getRelationshipObjectsOfAllRelatedTo,
} from '../../../selectors/rewrite/relationships/relatedToInfo'

import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../../constants'


const saga = shipmentHistorySagaCreator({
  saveAction: SAVE_KEG_FILL_CONTRACTEE_HISTORY,
  transformInfoBeforeSaveFunc: ({ state, customerId, info: arrayOfShipmentObjs }) => {
    const entireCustomersSlice = getEntireCustomersSlice(state)
    const entireContractsSlice = getEntireContractsSlice(state)
    const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
    const conbrwContractIds = uniq_(
      getRelationshipObjectsOfAllRelatedTo({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        customerId,
        customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
      }).map(o => o.sourcePpfContract),
    )
    return arrayOfShipmentObjs.filter(shipment => conbrwContractIds.includes((shipment.destinationContractId)))
  },
})

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_KEG_FILL_CONTRACTEE_HISTORY, saga],
]
