/* eslint-disable no-restricted-syntax, max-len */ // CODE_COMMENTS_50

import { call, put, select, takeEvery } from 'redux-saga/effects'

import isFunction_ from 'lodash/isFunction'
import isNumber_ from 'lodash/isNumber'
import toNumber_ from 'lodash/toNumber'


import { privateFetch } from '../../fetch'
import {
  FETCH_EDIT_CONTACT,
  SAVE_CONTACT,
  SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK,
  DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK,
  FETCH_EDIT_MASTER_CONTACT,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'

import {
  getEntireSlice as getEntireContactsSlice,
  getEntireLinksSlice as getEntireCustomerContactLinksSlice,
} from '../../../selectors/contacts'

import {
  generalDoFailure,
} from '../../util/fetchFailure'

import {
  EMAIL_SUBSCRIPTION_PROPS,
} from '../../../../features/ManageContactsAndSubscriptions/constants'

import {
  getIsContactTheOnlyInvoiceRecipient,
  convertHumanReadableContactTypeToCustomerContactLinkObjProps,
} from '../../../../features/ManageContactsAndSubscriptions/utils'

import {
  API_URL_PATH_CREATE_OR_UPDATE_CONTACT_AND_LINKS,
  API_URL_SPS_CUSTOMER_CONTACT_LINKS,
  API_URL_SPS_CREATE_OR_UPDATE_CONTACT_AND_LINKS,
  API_URL_PATH_CONTACTS_UPDATE_MASTER_ACCOUNT,
} from '../../../../constants'

import {
  getIndividualContactApiUrl,
  getIndividualCustomerContactLinkApiUrl,
  extractMostImportantDetailsFromApiErrorObject,
} from '../../../../utils'


const formFieldNamesThatApplyToContactObjNotLinkObj = [
  'firstName',
  'lastName',
  'emailAddress',
  'deskPhone',
  'deskPhoneExt',
  'cellPhone',
  'faxNumber',
]


// Why are we exporting? Because deleteContact.js needs this
export function* fetchEditContact(action) {
  const entireContactsSlice = yield select(getEntireContactsSlice)
  const entireCustomerContactLinksSlice = yield select(getEntireCustomerContactLinksSlice)
  const props = {
    ...action.payload,
    entireContactsSlice,
    entireCustomerContactLinksSlice,
  }
  const {
    onFetchStart,
  } = props

  if (isFunction_(onFetchStart)) { onFetchStart() }

  const {
    fetchConfigs,
    didTheUserSetTheContactsDefaultShippingStatus, // CODE_COMMENTS_236
    didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus, // CODE_COMMENTS_236
  } = yield call(createFetchConfigObjsForEachContactAndOrLinkToUpdate, props)

  let response
  const responseBodies = []
  try {
    // CODE_COMMENTS_50
    for (const config of fetchConfigs) {
      response = yield call(privateFetch, config)
      responseBodies.push(response.data)
    }
  } catch (error) {
    yield call(doFailure, error, props)
    return
  }
  yield call(
    doSuccess,
    {
      fetchConfigs,
      didTheUserSetTheContactsDefaultShippingStatus,
      didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus,
      responseBodies,
      props,
    },
  )
}
export function* fetchEditMasterContact(action) {
  const props = {
    ...action.payload,
  }
  const {
    onFetchStart,
    formValues,
    onFetchSuccess,
    // entireCustomerContactLinksSlice,
    // contact,
  } = props

  if (isFunction_(onFetchStart)) { onFetchStart() }

  const data = { ...formValues }
  delete data.contactType
  delete data.defaultShippingContact
  delete data.replacementDefaultShippingContactId
  delete data.replacementInvoiceRecipientContactId
  delete data.dataChanged

  let response
  try {
    const config = {
      path: `${API_URL_PATH_CONTACTS_UPDATE_MASTER_ACCOUNT}`,
      data,
      method: 'POST',
    }
    response = yield call(privateFetch, config)
  } catch (error) {
    yield call(doFailure, error, props)
    return
  }
  const responseData = response?.data
  const contactObj = { ...responseData }
  delete contactObj.customerContactObjects
  yield put(createAction(SAVE_CONTACT, { info: contactObj }))
  const { customerContactObjects } = responseData
  for (const linkObj of customerContactObjects) {
    linkObj.contactId = contactObj.id
    // const linkObjToUpdate = Object.values(entireCustomerContactLinksSlice)?.find(obj => obj?.contactId === contactObj.id && obj.customerId === linkObj.customerId)
    // const updatedLinkObj = {
    //   ...linkObjToUpdate,
    //   defaultShippingContact: linkObj?.defaultShippingContact,
    // }

    yield put(createAction(SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK, { info: linkObj }))
    // yield put(createAction(SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK, { info: updatedLinkObj }))
  }

  if (isFunction_(onFetchSuccess)) { onFetchSuccess() }
}


function* doSuccess({
  fetchConfigs,
  didTheUserSetTheContactsDefaultShippingStatus, // CODE_COMMENTS_236
  didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus, // CODE_COMMENTS_236
  responseBodies,
  props,
}) {
  const {
    contact: contactLinkMashupObjBeingEdited,
    contacts: allContactLinkMashupObjsOfCustomer,
    onFetchSuccess,
    entireCustomerContactLinksSlice,
  } = props
  for (const [index, responseBody] of Object.entries(responseBodies)) {
    const fetchConfig = fetchConfigs?.[index]
    if (fetchConfig.method === 'DELETE') {
      yield put(createAction(
        DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK,
        // The response body of a DELETE customer contact link call returns a
        // contact object (not a customerContact object) with all values set to
        // null (not sure why):
        //
        // {
        //   id: null,
        //   actinve: null,
        //   firstName: null,
        //   lastName: null,
        //   customerContactObject: [],
        //   ...
        // }
        //
        // Therefore, we need to get the id of the deleted customer contact link
        // from the object being edited passed in by the form component.
        { info: contactLinkMashupObjBeingEdited.customerContactLinkId },
      ))
    } else {
      const objType = getCallTypeFromApiUrlPath(fetchConfig.path)
      if (objType === 'mashupObj') {
        const { customerContactObjects, ...contactObj } = responseBody
        const linkObj = customerContactObjects[0]
        linkObj.contactId = contactObj.id
        yield put(createAction(SAVE_CONTACT, { info: contactObj }))
        yield put(createAction(SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK, { info: linkObj }))
      } else if (objType === 'contactObj') {
        const contactObj = responseBody
        yield put(createAction(SAVE_CONTACT, { info: contactObj }))
      } else {
        const linkObj = responseBody
        yield put(createAction(SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK, { info: linkObj }))
      }
    }
  }

  // CODE_COMMENTS_236
  if (didTheUserSetTheContactsDefaultShippingStatus) {
    // unset the defaultShippingContact flag on the old Default Shipping Contact
    const mashupObjContainingLinkObjToUpdate = allContactLinkMashupObjsOfCustomer.find(o => o.defaultShippingContact)
    const idOfLinkObjToUpdate = mashupObjContainingLinkObjToUpdate.customerContactLinkId
    const linkObjToUpdate = entireCustomerContactLinksSlice[idOfLinkObjToUpdate]
    const updatedLinkObj = {
      ...linkObjToUpdate,
      defaultShippingContact: false,
    }
    yield put(createAction(SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK, { info: updatedLinkObj }))
  }
  if (didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus) {
    // unset the defaultShippingContact flag on the linkObj of the contact being edited
    const linkObjToUpdate = entireCustomerContactLinksSlice[contactLinkMashupObjBeingEdited.customerContactLinkId]
    const updatedLinkObj = {
      ...linkObjToUpdate,
      defaultShippingContact: false,
    }
    yield put(createAction(SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK, { info: updatedLinkObj }))
  }
  if (isFunction_(onFetchSuccess)) { onFetchSuccess() }
}


function* doFailure(error, props) {
  const {
    onFetchFailure,
  } = props
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
  const errorDetails = extractMostImportantDetailsFromApiErrorObject({ error })
  if (isFunction_(onFetchFailure)) { onFetchFailure(errorDetails) }
}


// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_EDIT_CONTACT, fetchEditContact],
  [takeEvery, FETCH_EDIT_MASTER_CONTACT, fetchEditMasterContact],
]


// helper functions


// Returns a 3-item object:
//
// {
//    fetchConfigs: [{ path, data, method }, ...] // array of fetch config objects
//    didTheUserSetTheContactsDefaultShippingStatus: false,
//    didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus: false,
// }
//
// There will be one object per contact that needs to be updated. However, there
// are 3 different URLs that might be set as the path:
// 1. If only the Contact information needs to be edited (i.e. personal info
//    such as phone number), the PUT API_URL_PATH_INDIVIDUAL_CONTACT endpoint
//    will be set.
// 2. If only the Customer-Contact Link for a Contact needs to be edited (i.e.
//    email subscriptions such as receiveInvoice), the PUT
//    API_URL_PATH_INDIVIDUAL_CUSTOMER_CONTACT_LINK endpoint will be set.
// 3. If both the Contact info AND its Customer-Contact Link needs to be edited,
//    the POST API_URL_PATH_CREATE_OR_UPDATE_CONTACT_AND_LINKS endpoint will be
//    set.
function* createFetchConfigObjsForEachContactAndOrLinkToUpdate(props) {
  const fetchConfigsForReplacementDefaultShippingAndInvoiceContacts = createFetchConfigObjsForReplacementDefaultShippingContactAndOrReplacementInvoiceContact(props)
  const {
    fetchConfig: fetchConfigForEditedContact,
    didTheUserSetTheContactsDefaultShippingStatus,
    didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus,
  } = yield createFetchConfigObjForEditedContact(props)
  return {
    fetchConfigs: [
      ...fetchConfigsForReplacementDefaultShippingAndInvoiceContacts,
      ...(fetchConfigForEditedContact ? [fetchConfigForEditedContact] : []),
    ],
    didTheUserSetTheContactsDefaultShippingStatus,
    didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus,
  }
}


// Returns an array of fetch config objects:
//
// { path, data, method }
//
// Returns an empty array if no replacements are needed. See the docstring of
// createFetchConfigObjsForEachContactAndOrLinkToUpdate for details about the
// structure of the objects returned. The motivation for this function is that
// each customer must have one and only one Default Shipping Contact and at
// least one Contact that receives invoices. So, for example, if a user edits
// the Default Shipping Contact to not be the Default Shipping Contact anymore
// (or deletes the Default Shipping Contact), the form prompts them to choose a
// new Contact to be the Default Shipping Contact.
function createFetchConfigObjsForReplacementDefaultShippingContactAndOrReplacementInvoiceContact({
  contact: contactLinkMashupObjBeingEdited,
  contacts: allContactLinkMashupObjsOfCustomer,
  formValues,
  entireCustomerContactLinksSlice,
  isDeleteContact,
}) {
  const {
    defaultShippingContact,
    replacementDefaultShippingContactId,
    replacementInvoiceRecipientContactId,
    receiveInvoice,
  } = formValues

  const doWeNeedToSetAReplacementDefaultShippingContact = isDeleteContact
    ? replacementDefaultShippingContactId
    : (
      // Why not just set doWeNeedToSetAReplacementDefaultShippingContact to
      // Boolean(replacementDefaultShippingContactId)? Because what if the user
      // set a replacement default shipping contact but then changed their mind
      // and reversed the form actions, keeping the default shipping contact the
      // same? In this case replacementDefaultShippingContactId could be set
      // even though we don't actually need to set a new default shipping
      // contact.
      // Is the Contact being edited the current Default Shipping Contact?
      contactLinkMashupObjBeingEdited.defaultShippingContact
      // Did the user uncheck the "Default Shipping Contact" checkbox?
      && !defaultShippingContact
    )
  const doWeNeedToSetAReplacementInvoiceRecipient = isDeleteContact
    ? replacementInvoiceRecipientContactId
    : (
      getIsContactTheOnlyInvoiceRecipient({
        contact: contactLinkMashupObjBeingEdited,
        contacts: allContactLinkMashupObjsOfCustomer,
      })
      && !receiveInvoice
    )

  if (doWeNeedToSetAReplacementDefaultShippingContact || doWeNeedToSetAReplacementInvoiceRecipient) {
    if (replacementDefaultShippingContactId === replacementInvoiceRecipientContactId) {
      const replacementDefaultShippingAndInvoiceContactMashupObj = allContactLinkMashupObjsOfCustomer.find(o => o.id === replacementDefaultShippingContactId)
      const customerContactLinkObj = entireCustomerContactLinksSlice[replacementDefaultShippingAndInvoiceContactMashupObj.customerContactLinkId]
      const updatedLinkObj = {
        ...customerContactLinkObj,
        defaultShippingContact: true,
        receiveInvoice: true,
      }
      return [{
        path: getIndividualCustomerContactLinkApiUrl(updatedLinkObj.id),
        data: updatedLinkObj,
        method: 'PUT',
      }]
    }

    const toReturn = []
    if (doWeNeedToSetAReplacementDefaultShippingContact) {
      const replacementDefaultShippingContactMashupObj = allContactLinkMashupObjsOfCustomer.find(o => o.id === replacementDefaultShippingContactId)
      const customerContactLinkObj = entireCustomerContactLinksSlice[replacementDefaultShippingContactMashupObj.customerContactLinkId]
      const updatedLinkObj = {
        ...customerContactLinkObj,
        defaultShippingContact: true,
        shippingContact: true,
      }
      toReturn.push({
        path: getIndividualCustomerContactLinkApiUrl(updatedLinkObj.id),
        data: updatedLinkObj,
        method: 'PUT',
      })
    }

    if (doWeNeedToSetAReplacementInvoiceRecipient) {
      const replacementInvoiceContactMashupObj = allContactLinkMashupObjsOfCustomer.find(o => o.id === replacementInvoiceRecipientContactId)
      const customerContactLinkObj = entireCustomerContactLinksSlice[replacementInvoiceContactMashupObj.customerContactLinkId]
      const updatedLinkObj = {
        ...customerContactLinkObj,
        receiveInvoice: true,
      }
      toReturn.push({
        path: getIndividualCustomerContactLinkApiUrl(updatedLinkObj.id),
        data: updatedLinkObj,
        method: 'PUT',
      })
    }

    return toReturn
  }
  return []
}


// Returns a 3-item object:
//
// {
//   fetchConfig,
//   didTheUserSetTheContactsDefaultShippingStatus,
//   didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus,
// }
//
// fetchConfig will be null if
// didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus is true (see
// CODE_COMMENTS_236)
function createFetchConfigObjForEditedContact(props) {
  const {
    contact: contactLinkMashupObjBeingEdited,
    isDeleteContact,
  } = props

  let fetchConfig
  let didTheUserSetTheContactsDefaultShippingStatus // CODE_COMMENTS_236
  let didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus // CODE_COMMENTS_236

  if (isDeleteContact) {
    fetchConfig = {
      path: getIndividualCustomerContactLinkApiUrl(contactLinkMashupObjBeingEdited.customerContactLinkId),
      data: null,
      method: 'DELETE',
    }
    // if we're deleting a contact, the
    // createFetchConfigObjsForReplacementDefaultShippingContactAndOrReplacementInvoiceContact()
    // function will create fetch configs for replacement contacts, and the
    // objects returned by those calls will be saved to the Redux store, so we
    // don't need to manually edit the props of any replacement contacts (the
    // reason for these variables)
    didTheUserSetTheContactsDefaultShippingStatus = false
    didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus = false
  } else {
    // Determine whether the the link object or the contact object needs to be
    // edited, or both, or neither. (When would neither ever happen? See
    // CODE_COMMENTS_236: when the only thing the user changed was unsetting
    // this contact being the Default Shipping Contact; the new Default Shipping
    // Contact selected in the form will be set on the backend by the call
    // defined by the
    // createFetchConfigObjsForReplacementDefaultShippingContactAndOrReplacementInvoiceContact
    // function; when the new Default Shipping Contact gets set, the backend
    // automatically unsets the that flag on this Contact, so there's no need
    // for another fetch to unset. In other words, when
    // didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus is true,
    // fetchConfig gets returned as null.)
    const doesContactObjNeedToBeEdited = getAreAnyFormValuesDifferentThatApplyToContactObj(props)
    const {
      areAnyLinkValuesBesidesDefaultShippingDifferent,
      wasDefaultShippingContactUnset,
      wasDefaultShippingContactSet,
    } = getAreAnyFormValuesDifferentThatApplyToLinkObj(props)
    const areAnyLinkValuesIncludingDefaultShippingDifferent = (
      areAnyLinkValuesBesidesDefaultShippingDifferent
      || wasDefaultShippingContactUnset
      || wasDefaultShippingContactSet
    )
    const doesLinkObjNeedToBeEdited = wasDefaultShippingContactUnset
      ? (
        // If the contact object needs to be edited, then we need to call the
        // method that changes both the Contact and the Link
        // (API_URL_PATH_CREATE_OR_UPDATE_CONTACT_AND_LINKS)
        doesContactObjNeedToBeEdited
        || areAnyLinkValuesBesidesDefaultShippingDifferent
      )
      : areAnyLinkValuesIncludingDefaultShippingDifferent

    didTheUserSetTheContactsDefaultShippingStatus = (
      wasDefaultShippingContactSet
    )
    didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus = (
      wasDefaultShippingContactUnset
      && !doesContactObjNeedToBeEdited
      && !doesLinkObjNeedToBeEdited
    )

    if (!doesContactObjNeedToBeEdited && !doesLinkObjNeedToBeEdited) {
      // The only way this can happen is if the user did nothing but unset the
      // contact's Default Shipping status.
      fetchConfig = null
    } else if (doesContactObjNeedToBeEdited && doesLinkObjNeedToBeEdited) {
      const contactObj = createUpdatedContactObjectForApiCallBody(props)
      const linkObj = createUpdatedLinkObjectForApiCallBody(props)
      fetchConfig = {
        path: API_URL_PATH_CREATE_OR_UPDATE_CONTACT_AND_LINKS,
        // mimic one of the values in the array returned by
        // GET customers/v3/contacts/:customerId
        data: {
          ...contactObj,
          customerContactObjects: [linkObj],
        },
        method: 'POST',
      }
    } else if (doesContactObjNeedToBeEdited && !doesLinkObjNeedToBeEdited) {
      fetchConfig = {
        path: getIndividualContactApiUrl(contactLinkMashupObjBeingEdited.id),
        data: createUpdatedContactObjectForApiCallBody(props),
        method: 'PUT',
      }
    } else {
      // !doesContactObjNeedToBeEdited && doesLinkObjNeedToBeEdited
      fetchConfig = {
        path: getIndividualCustomerContactLinkApiUrl(contactLinkMashupObjBeingEdited.customerContactLinkId),
        data: createUpdatedLinkObjectForApiCallBody(props),
        method: 'PUT',
      }
    }
  }

  return {
    fetchConfig,
    didTheUserSetTheContactsDefaultShippingStatus,
    didTheUserDoNothingButUnsetTheContactsDefaultShippingStatus,
  }
}


// Returns a boolean
function getAreAnyFormValuesDifferentThatApplyToContactObj({
  contact: contactLinkMashupObjBeingEdited,
  formValues,
}) {
  return formFieldNamesThatApplyToContactObjNotLinkObj.some(propName => (
    !getIsFormValueIdenticalToContactMashupObjPropValue({
      propName,
      formValue: formValues[propName],
      contactPropValue: contactLinkMashupObjBeingEdited[propName],
    })
  ))
}


// Returns a 3-item object:
// {
//   areAnyLinkValuesBesidesDefaultShippingDifferent: true
//   wasDefaultShippingContactUnset: false,
//   wasDefaultShippingContactSet: false,
// }
function getAreAnyFormValuesDifferentThatApplyToLinkObj({
  contact: contactLinkMashupObjBeingEdited,
  formValues,
  emailSubscriptionProps,
}) {
  const customerContactLinkObjPropNames = emailSubscriptionProps.map(o => o.name)
  const areAnyEmailSubscriptionValuesDifferent = customerContactLinkObjPropNames.some(propName => (
    !getIsFormValueIdenticalToContactMashupObjPropValue({
      propName,
      formValue: formValues[propName],
      contactPropValue: contactLinkMashupObjBeingEdited[propName],
    })
  ))

  const contactTypeFormValuesInLinkObjFormat = convertHumanReadableContactTypeToCustomerContactLinkObjProps(formValues.contactType)
  const isContactTypeSelectionDifferent = Object.entries(contactTypeFormValuesInLinkObjFormat).some(([propName, formValue]) => (
    !getIsFormValueIdenticalToContactMashupObjPropValue({
      propName,
      formValue,
      contactPropValue: contactLinkMashupObjBeingEdited[propName],
    })
  ))

  const wasDefaultShippingContactUnset = (
    contactLinkMashupObjBeingEdited.defaultShippingContact
    && !formValues.defaultShippingContact
  )
  const wasDefaultShippingContactSet = (
    !contactLinkMashupObjBeingEdited.defaultShippingContact
    && formValues.defaultShippingContact
  )

  return {
    areAnyLinkValuesBesidesDefaultShippingDifferent: (
      areAnyEmailSubscriptionValuesDifferent
      || isContactTypeSelectionDifferent
    ),
    wasDefaultShippingContactUnset,
    wasDefaultShippingContactSet,
  }
}


function getIsFormValueIdenticalToContactMashupObjPropValue({
  propName,
  formValue,
  contactPropValue,
}) {
  if (EMAIL_SUBSCRIPTION_PROPS.map(o => o.name).includes(propName)) {
    return Boolean(formValue) === Boolean(contactPropValue)
  }
  if (!formValue && !contactPropValue) {
    return true
  }
  if (isNumber_(contactPropValue) && !isNumber_(formValue)) {
    const formValueAsNumber = toNumber_(formValue)
    if (Number.isNaN(formValueAsNumber)) { return false }
    return formValueAsNumber === contactPropValue
  }
  return formValue === contactPropValue
}


function createUpdatedContactObjectForApiCallBody({
  contact: contactLinkMashupObjBeingEdited,
  formValues,
  entireContactsSlice,
}) {
  const contactObj = entireContactsSlice[contactLinkMashupObjBeingEdited.id]
  const formValuesForContactObj = formFieldNamesThatApplyToContactObjNotLinkObj.reduce(
    (acc, fieldName) => ({
      ...acc,
      [fieldName]: formValues[fieldName],
    }),
    {},
  )
  return {
    ...contactObj,
    ...formValuesForContactObj,
  }
}

function createUpdatedLinkObjectForApiCallBody({
  contact: contactLinkMashupObjBeingEdited,
  emailSubscriptionProps,
  formValues,
  entireCustomerContactLinksSlice,
}) {
  const linkObj = entireCustomerContactLinksSlice[contactLinkMashupObjBeingEdited.customerContactLinkId]
  const emailSubscriptionValues = emailSubscriptionProps.reduce(
    (acc, prop) => ({
      ...acc,
      [prop.name]: formValues[prop.name],
    }),
    {},
  )
  const contactTypeValues = convertHumanReadableContactTypeToCustomerContactLinkObjProps(formValues.contactType)
  const defaultShippingContactProps = {
    defaultShippingContact: formValues.defaultShippingContact,
  }
  return {
    ...linkObj,
    ...emailSubscriptionValues,
    ...contactTypeValues,
    ...defaultShippingContactProps,
  }
}


function getCallTypeFromApiUrlPath(urlPath) {
  if (urlPath.includes(API_URL_SPS_CREATE_OR_UPDATE_CONTACT_AND_LINKS)) {
    return 'mashupObj'
  }
  if (urlPath.includes(API_URL_SPS_CUSTOMER_CONTACT_LINKS)) {
    return 'linkObj'
  }
  return 'contactObj'
}
