import { connect } from 'react-redux'

import FetchFailedCard from '../components/FetchFailedCard'

import createAction from '../../../redux/actions/createAction'
import { FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER } from '../../../redux/actions/actionTypes'

import {
  getProp as getCustomerProp,
} from '../../../redux/selectors/customers'
import {
  NestedPropNotInObjectError,
} from '../../../customErrors'

const mapStateToProps = (state, { customerId }) => {
  const customerName = getCustomerPropOrUndefined(state, customerId, 'name')

  return {
    customerName,
  }
}

const mapDispatchToProps = (dispatch, { customerId, operatingContractBrewerCustomerId }) => ({
  retryFetchCustomerPrelimInfo: () => {
    dispatch(createAction(
      FETCH_PRELIM_INFO_OF_SINGLE_CUSTOMER,
      { customerId, operatingContractBrewerCustomerId },
    ))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FetchFailedCard)


// helper functions

function getCustomerPropOrUndefined(state, customerId, propName) {
  let propValue
  try {
    propValue = getCustomerProp(state, customerId, propName)
  } catch (e) {
    // The web app received an error from the /customers fetch for this customer
    if (e instanceof NestedPropNotInObjectError) {
      propValue = undefined
    } else {
      throw e
    }
  }
  return propValue
}
