import set_ from 'lodash/set'

// definitions should be an array of objects, with each object
// having these props:
// * path: the second parameter to Lodash's _.set() function
//   (https://lodash.com/docs/4.17.11#set)
// * value: the third parameter to Lodash's _.set() function

// This function doesn't do a whole lot. In most cases it would actually be
// easier to just create an object on the fly to use as fake state rather than
// using this function. But we want to use this function so that if we ever make
// major changes to state shape, we can look up all the uses of it and refactor
// the invocations.
export function createFakeStateObject(definitions) {
  return definitions.reduce(
    (acc, def) => set_(acc, def.path, def.value),
    {},
  )
}
