import { connect } from 'react-redux'


import DownloadInboundEmptyKegHistoryTableAsFile from './components'

import {
  getEntireSlice as getAllOutboundEmptyKegShipmentHistoryObjects,
} from '../../../../../redux/selectors/histories/outboundEmptyKegShipmentHistory'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import {
  getMostRecentlySubmittedFormValues,
} from '../../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'

import {
  // Why are we using functions from the inboundEmptyKegShipmentHistory
  // component? Because that component and this component refer to the same
  // shipments: outbound full keg shipments for Brewers are inbound full keg
  // shipments for Distributors. See CODE_COMMENTS_205.
  getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
} from '../../util/shipmentHistory/inboundEmptyKegShipmentHistory'
import {
  getAllOriginAndDestinationCustomerObjectsFromShipmentObjects,
} from '../../util/shipmentHistory/common/util'

import {
  HISTORY_FORM_NAME_OUTBOUND_EMPTY_KEG_SHIPMENTS,
} from '../../../../../constants'

import {
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'
import { getEntireSlice as getEntireCustomersSlice } from '../../../../../redux/selectors/rewrite/customers'


const mapStateToProps = (state, { customerId, operatingContractBrewerCustomerId }) => {
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')

  const historyFormName = createFormNameForRedux({
    reducerName: HISTORY_FORM_NAME_OUTBOUND_EMPTY_KEG_SHIPMENTS,
    customerId,
  })
  const mostRecentlySubmittedFormValues = getMostRecentlySubmittedFormValues(state, historyFormName)
  // if form hasn't yet been successfully submitted
  if (!mostRecentlySubmittedFormValues) { return {} }


  const allOutboundEmptyKegShipmentHistoryObjects = getAllOutboundEmptyKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  const allOutboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable =
    getAllInboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable(
      allOutboundEmptyKegShipmentHistoryObjects,
    )
  // We don't actually need destination customers for the inbound shipments
  // file: The destination is always the currently-operated-for customer, so the
  // file doesn't even have a "Destination" field. The reason we gather this
  // data is because this is what the outbound shipments file needs, and its
  // easier to simply re-use this function than to write a special function for
  // inbounds that returns a little less data but only the data we need.
  const allOriginAndDestinationCustomerObjectsToBeIncludedInDownloadShipmentsHistoryFile =
    getAllOriginAndDestinationCustomerObjectsFromShipmentObjects({
      state,
      arrayOrObjectOfObjects: allOutboundEmptyKegShipmentObjectsToBeIncludedInHistoryTable,
    })
  const entireCustomersSlice = getEntireCustomersSlice(state)

  return {
    entireItemSkusSlice,
    allOutboundEmptyKegShipmentHistoryObjects,
    configuredItemSkuIds,
    allOriginAndDestinationCustomerObjectsToBeIncludedInDownloadShipmentsHistoryFile,
    mostRecentlySubmittedFormValues,
    entireCustomersSlice,
    customerId,
  }
}


export default connect(
  mapStateToProps,
)(DownloadInboundEmptyKegHistoryTableAsFile)
