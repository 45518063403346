// CODE_COMMENTS_241

/* eslint-disable max-len */

import { call, put, takeEvery } from 'redux-saga/effects'


import { privateFetch } from '../fetch'
import {
  FETCH_UNIVERSAL_CUSTOMER_REPRESENTATIVE,
  SAVE_UNIVERSAL_CUSTOMER_REPRESENTATIVE,
  FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_REQUEST,
  FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_SUCCESS,
  FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_FAILURE,
} from '../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  URL_PARAM_PROPERTY,
} from '../../../constants/formAndApiUrlConfig/systemProperties'

import {
  API_URL_PATH_SYSTEM_PROPERTIES,
} from '../../../constants'

import {
  extractSystemPropertyFromHttpResponse,
} from '../util/systemProperties'


export function* fetchUniversalCustomerRepresentative(action) {
  const { repType } = action.payload
  yield put(createAction(FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_REQUEST, { target: [repType] }))
  let httpResponse
  try {
    httpResponse = yield call(
      privateFetch,
      {
        path: API_URL_PATH_SYSTEM_PROPERTIES,
        params: {
          [URL_PARAM_PROPERTY]: repType,
        },
        setOperateAsCustomerUserHeaderIfEmployeeIsLoggedIn: false, // CODE_COMMENTS_221
      },
    )
  } catch (error) {
    yield call(doFailure, { repType, error })
    return
  }
  yield call(doSuccess, { repType, httpResponse })
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    FETCH_UNIVERSAL_CUSTOMER_REPRESENTATIVE,
    fetchUniversalCustomerRepresentative,
  ],
]


function* doSuccess({ repType, httpResponse }) {
  const repInfo = extractSystemPropertyFromHttpResponse(httpResponse)
  yield put(createAction(SAVE_UNIVERSAL_CUSTOMER_REPRESENTATIVE, { [repType]: repInfo }))
  yield put(createAction(FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_SUCCESS, { target: [repType] })) // CODE_COMMENTS_20
}


function* doFailure({ repType, error }) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_UNIVERSAL_CUSTOMER_REPRESENTATIVE_FAILURE,
        target: [repType],
      }),
    },
  )
}
