import { connect } from 'react-redux'


import OperateAsCustomerUser from './components'

import withNoPermissions from './withNoPermissions'

import {
  getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor,
  getEntireSlice as getCustomerUserCurrentlyOperatingAs,
} from '../../../redux/selectors/currentUser'
import fetchStatusSelectors from '../../../redux/selectors/fetchStatusesEmployee/individualCustomerUserAndItsPermissions'


import {
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS,
  FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_CLEAR,
} from '../../../redux/actions/actionTypes'
import createAction from '../../../redux/actions/createAction'


const { getFetchStatuses, getFetchFailureErrorDetails } = fetchStatusSelectors


const mapStateToProps = state => {
  const fetchStatuses = getFetchStatuses(state)
  const { didFetchFail } = fetchStatuses
  let errorDetails
  if (didFetchFail) {
    errorDetails = getFetchFailureErrorDetails(state)
  }

  const hasCustomerUserBeenChosen =
    getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor(state)

  const customerUserCurrentlyOperatingAs = getCustomerUserCurrentlyOperatingAs(state)

  return {
    ...fetchStatuses,
    errorDetails,
    hasCustomerUserBeenChosen,
    customerUserCurrentlyOperatingAs,
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: email => {
    dispatch(createAction(
      FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS,
      { email },
    ))
  },
  onClearFetchStatuses: () => {
    dispatch(createAction(FETCH_INDIVIDUAL_CUSTOMER_USER_AND_ITS_PERMISSIONS_FOR_EMPLOYEE_TO_OPERATE_AS_CLEAR))
  },
})

export default withNoPermissions(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OperateAsCustomerUser),
)
