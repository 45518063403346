import React from 'react'
import { Loader, Icon } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import fetchStatusSelectors from '../../../../../../redux/selectors/rewrite/fetchStatuses/forms'

import PopupWithCatastrophicFailureErrorBoundary from '../../../../../semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'

import GenericFetchFailureMessage from '../../../../FetchFailure/GenericFetchFailureMessage'

const { getFetchStatuses } = fetchStatusSelectors


const wrapper = Component => props => (
  // This wrapping div is very important for visual alignment of the table. See
  // the CSS file the className is defined in for details.
  <div className="vertically-centered-field">
    <Component {...props} />
  </div>
)

export default wrapper(props => {
  const {
    fetchStatuses,
    fieldArrayName,
    rowIndex,
  } = props
  const fetchStatusesOfRow = getFetchStatuses(fetchStatuses, fieldArrayName, rowIndex)
  const { t: translate } = useTranslation('common')
  if (!fetchStatusesOfRow.isQueued && !fetchStatusesOfRow.hasFetchBeenAttempted) {
    return <span />
  }

  if (fetchStatusesOfRow.isQueued) {
    return translate('Queued')
  }

  if (fetchStatusesOfRow.isFetching) {
    return (
      <Loader
        key={`submitting${rowIndex}`}
        active
        inline
        size="tiny"
      />
    )
  }

  if (fetchStatusesOfRow.didFetchSucceed) {
    return (
      <Icon
        key={`submitSucceeded${rowIndex}`}
        name="check circle"
        size="large"
        color="green"
      />
    )
  }

  // if not queued, fetching, or succeeded, it must have failed
  return (
    <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      trigger={
        <Icon
          key={`submitFailed${rowIndex}`}
          link
          name="exclamation circle"
          size="large"
          color="red"
        />
      }
      on="click"
      wide="very"
      position="bottom center"
      className="semantic-ui-error-border"
    >
      <GenericFetchFailureMessage
        {...props}
      />
    </PopupWithCatastrophicFailureErrorBoundary>
  )
})
