// Like ContactCard except you pass in the contact info explicitly--this
// component doesn't look anything up in Redux
import React from 'react'

import PopulatedContactCard from '../ContactCard/components/PopulatedContactCard'

// props should include   repTitle, name, phone, and email
export default props => (
  <PopulatedContactCard {...props} />
)
