/* eslint-disable max-len */

import React, { Fragment, memo, useState, useReducer, useEffect } from 'react'
import { Form, Grid, Checkbox, Button, Header, Loader, Icon } from 'semantic-ui-react'
import produce from 'immer'
import moment from 'moment'

import values_ from 'lodash/values'
import get_ from 'lodash/get'

import flow_ from 'lodash/fp/flow'
import valuesFp_ from 'lodash/fp/values'


import { useTranslation } from 'react-i18next'
import AccordionWrapper from './AccordionWrapper'
import MonthAndYearPicker from '../../../../../../common-components/rewrite/MonthAndYearPicker'
import DismissableMessage from '../../../../../../common-components/DismissableMessage'
import RevealContactInfo from '../../../../../../common-components/RevealContactInfo'
import ToCustomerPortalButton from '../../../../../../common-components/rewrite/FormSubmission/AfterSubmissionButtons/ToCustomerPortalButton'
import PopupWithCatastrophicFailureErrorBoundary from '../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/PopupWithCatastrophicFailureErrorBoundary'


import fetchStatusesReducer from '../../../../../../redux/reducers/fetchStatuses/forms'
import {
  getAggregateRowFetchStatuses,
} from '../../../../../../redux/selectors/rewrite/fetchStatuses/forms/formWithFieldArrays'
import {
  FETCH_REPORT_NO_MOVEMENTS,
  FETCH_STATUSES_FORMS_CLEAR,
} from '../../../../../../redux/actions/actionTypes'
import createAction from '../../../../../../redux/actions/createAction'

import {
  FIELD_NAME_MONTH,
  FIELD_NAME_SELECTED_CUSTOMERS,
} from '../../../../../../constants/formAndApiUrlConfig/reportNoMovements'


import {
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
  FETCH_STATUSES_QUEUED,
  FETCH_STATUSES_IN_PROGRESS,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_FAILURE,
} from '../../../../../../constants'

import {
  createMonthAndYearPickerDropdownOptions,
} from '../../../../../../common-components/rewrite/MonthAndYearPicker/util'

import {
  getDoesApiErrorSayNoMovementsCantBeReportedBecauseShipmentsHaveAlreadyBeenReportedForThisPeriod,
  getDoesApiErrorSayNoMovementsCantBeReportedBecauseItIsADuplicate,
} from './util'


const labelWidth = '130px'

const CHANGE_FORM_VALUE = 'CHANGE_FORM_VALUE'
const RESET_FORM_VALUES_TO_INITIAL_VALUES = 'RESET_FORM_VALUES_TO_INITIAL_VALUES'


/* eslint-disable no-param-reassign, no-useless-return, consistent-return */
const changeFormValue = produce((formValues, action) => {
  switch (action.type) {
    case CHANGE_FORM_VALUE: {
      // eslint-disable-next-line prefer-const
      const { fieldName } = action.payload
      if (fieldName === FIELD_NAME_MONTH) {
        const { value } = action.payload
        formValues[fieldName] = value
      } else {
        const { id } = action.payload
        formValues[FIELD_NAME_SELECTED_CUSTOMERS][id] = !formValues[FIELD_NAME_SELECTED_CUSTOMERS][id]
      }
      return
    }
    case RESET_FORM_VALUES_TO_INITIAL_VALUES: {
      return getInitialValues(action.payload)
    }
    default: { return }
  }
})
/* eslint-enable no-param-reassign, no-useless-return, consistent-return */

const customGetFetchStatusesOfAllRowsFunc = flow_(
  // convert form slice object to an array of individual row fetch
  // status info objects, i.e. convert this:
  // {
  //   customerId1: { status: 'fetchStatusesInProgress', errorDetails: { errorUrl: null, ... } },
  //   customerId2: { status: 'fetchStatusesInProgress', errorDetails: { errorUrl: null, ... } },
  //   ...
  // }
  // to this:
  // [
  //   { status: 'fetchStatusesInProgress', errorDetails: { errorUrl: null, ... } },
  //   { status: 'fetchStatusesInProgress', errorDetails: { errorUrl: null, ... } },
  //   ...
  // ]
  valuesFp_,
)


export default memo(props => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    formType,
    fieldArraysAndCustomersFieldsDropdownOptions,
    dispatch,
  } = props
  const { t: translate } = useTranslation('pagelabels')
  const customersFieldsDropdownOptions = fieldArraysAndCustomersFieldsDropdownOptions[0].customersFieldsDropdownOptions
  const multipleSources = customersFieldsDropdownOptions.length > 1

  const [formValues, dispatchFormValues] = useReducer(
    changeFormValue,
    getInitialValues({
      fieldArraysAndCustomersFieldsDropdownOptions,
    }),
  )

  const [isFormSubmittable, setIsFormSubmittable] = useState(false)
  useEffect(
    () => {
      setIsFormSubmittable(getIsFormSubmittable({
        formValues,
      }))
    },
    [
      formValues,
    ],
  )

  const [hasEntireFormBeenSubmitted, setHasEntireFormBeenSubmitted] = useState(false)
  const [areSomeRowsSubmitting, setAreSomeRowsSubmitting] = useState(false)
  const [didSomeRowSubmissionsFail, setDidSomeRowSubmissionsFail] = useState(false)
  const [didAllRowSubmissionsSucceed, setDidAllRowSubmissionsSucceed] = useState(false)
  const [fetchStatuses, dispatchFetchStatuses] = useReducer(fetchStatusesReducer, {})

  useEffect(
    () => {
      const [
        hasEntireFormBeenSubmitted_,
        areSomeRowsSubmitting_,
        didSomeRowSubmissionsFail_,
        didAllRowSubmissionsSucceed_,
      ] = getAggregateRowFetchStatuses(fetchStatuses, customGetFetchStatusesOfAllRowsFunc)
      setHasEntireFormBeenSubmitted(hasEntireFormBeenSubmitted_)
      setAreSomeRowsSubmitting(areSomeRowsSubmitting_)
      setDidSomeRowSubmissionsFail(didSomeRowSubmissionsFail_)
      setDidAllRowSubmissionsSucceed(didAllRowSubmissionsSucceed_)
    },
    [fetchStatuses],
  )

  const handleSubmit = () => {
    setHasEntireFormBeenSubmitted(true)
    dispatch(createAction(
      FETCH_REPORT_NO_MOVEMENTS,
      {
        customerId,
        operatingContractBrewerCustomerId,
        formValues,
        dispatchFetchStatuses,
        formType,
      },
    ))
  }

  const onResetForm = () => {
    dispatchFormValues(createAction(
      RESET_FORM_VALUES_TO_INITIAL_VALUES,
      {
        fieldArraysAndCustomersFieldsDropdownOptions,
      },
    ))
    dispatchFetchStatuses(createAction(FETCH_STATUSES_FORMS_CLEAR))
  }

  return (
    <AccordionWrapper
      {...props}
    >
      <Form>
        <Grid columns={2}> {/* CODE_COMMENTS_22, CODE_COMMENTS_97 */}

          <Grid.Row>
            <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
              <Form.Field required>
                <label htmlFor="contactType">
                  {translate('reportShipments.Which Month')}
                </label>
              </Form.Field>
            </Grid.Column>
            <Grid.Column style={{ flex: '1' }}>
              <div className="ui input"> {/* CODE_COMMENTS_32 */}
                <MonthAndYearPicker
                  disabled={hasEntireFormBeenSubmitted}
                  rangeMin={createMonthReportingForRangeMin()}
                  rangeMax={createMonthReportingForRangeMax()}
                  sortOptionsInDropdownFromLatestToEarliestRatherThanEarliestToLatest
                  name={FIELD_NAME_MONTH}
                  value={formValues[FIELD_NAME_MONTH]}
                  onChange={(e, { value }) => {
                    dispatchFormValues(createAction(
                      CHANGE_FORM_VALUE,
                      {
                        fieldName: FIELD_NAME_MONTH,
                        value,
                      },
                    ))
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>


          {customersFieldsDropdownOptions.length > 1 &&
            <Grid.Row>
              <Grid.Column style={{ flex: `0 0 ${labelWidth}` }} verticalAlign="middle">
                <Form.Field required>
                  <label htmlFor="selectedCustomers">
                    Which Sources
                  </label>
                </Form.Field>
              </Grid.Column>
              <Grid.Column style={{ flex: '1' }}>
                <div className="ui input"> {/* CODE_COMMENTS_32 */}
                  <div>
                    <Grid
                      columns={hasEntireFormBeenSubmitted ? 3 : 2}
                      id="tight-grid-rows-stupid-hack-of-css-specificity"
                    >
                      {customersFieldsDropdownOptions.map(({ id, name }) => (
                        <Grid.Row key={id}>


                          {hasEntireFormBeenSubmitted &&
                            <Grid.Column style={{ flex: '0 0 6rem' }}>
                              <SubmissionFieldMultipleSources
                                customerId={customerId}
                                fetchStatuses={fetchStatuses}
                                id={id}
                              />
                            </Grid.Column>
                          }


                          <Grid.Column style={{ flex: '0 0 2rem' }}>
                            <Checkbox
                              style={{ verticalAlign: 'middle' }}
                              disabled={hasEntireFormBeenSubmitted}
                              onChange={() => {
                                dispatchFormValues(createAction(
                                  CHANGE_FORM_VALUE,
                                  {
                                    fieldName: FIELD_NAME_SELECTED_CUSTOMERS,
                                    id,
                                  },
                                ))
                              }}
                              checked={formValues[FIELD_NAME_SELECTED_CUSTOMERS][id]}
                              name={id}
                            />
                          </Grid.Column>


                          <Grid.Column style={{ flex: '1' }}>
                            {name}
                          </Grid.Column>


                        </Grid.Row>
                      ))}
                    </Grid>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          }


        </Grid>
        <SubmitButton
          fetchStatuses={fetchStatuses}
          handleSubmit={handleSubmit}
          hasEntireFormBeenSubmitted={hasEntireFormBeenSubmitted}
          isFormSubmittable={isFormSubmittable}
          areSomeRowsSubmitting={areSomeRowsSubmitting}
          didSomeRowSubmissionsFail={didSomeRowSubmissionsFail}
          didAllRowSubmissionsSucceed={didAllRowSubmissionsSucceed}
          {...props}
        />
        {hasEntireFormBeenSubmitted && !areSomeRowsSubmitting &&
          <PostFetchButtonsNextToSubmitButton
            onResetForm={onResetForm}
          />
        }
      </Form>
      {didSomeRowSubmissionsFail && !multipleSources &&
        <FailureMessage
          fetchStatuses={fetchStatuses}
          id={customersFieldsDropdownOptions[0].id}
          multipleSources={false}
          {...props}
        />
      }
    </AccordionWrapper>
  )
})


/*
 * *****************************************************************************
 * Helper Components
 * *****************************************************************************
*/

const SubmitButton = props => {
  const {
    areSomeRowsSubmitting,
    handleSubmit,
  } = props
  return (
    <Button
      type="submit"
      disabled={getIsSubmitButtonDisabled(props)}
      onClick={handleSubmit}
      color={getSubmitButtonColor(props)}
      loading={areSomeRowsSubmitting}
    >
      {getSubmitButtonText(props)}
    </Button>
  )
}


// helper functions for Helper Components

function getSubmitButtonColor({
  fieldArraysAndCustomersFieldsDropdownOptions,
  hasEntireFormBeenSubmitted,
  isFormSubmittable,
  areSomeRowsSubmitting,
  didSomeRowSubmissionsFail,
  didAllRowSubmissionsSucceed,
}) {
  const multipleSources = fieldArraysAndCustomersFieldsDropdownOptions[0].customersFieldsDropdownOptions.length > 1
  let buttonColor
  if (
    (!hasEntireFormBeenSubmitted && isFormSubmittable && !areSomeRowsSubmitting)
    || didAllRowSubmissionsSucceed
  ) {
    buttonColor = 'green'
  } else if (!areSomeRowsSubmitting && didSomeRowSubmissionsFail && !multipleSources) {
    buttonColor = 'red'
  } else if (!areSomeRowsSubmitting && didSomeRowSubmissionsFail && multipleSources) {
    buttonColor = 'orange'
  } else {
    buttonColor = null // grey
  }
  return buttonColor
}

function getSubmitButtonText({
  fieldArraysAndCustomersFieldsDropdownOptions,
  hasEntireFormBeenSubmitted,
  areSomeRowsSubmitting,
  didSomeRowSubmissionsFail,
}) {
  const multipleSources = fieldArraysAndCustomersFieldsDropdownOptions[0].customersFieldsDropdownOptions.length > 1
  if (
    !hasEntireFormBeenSubmitted
    // The areSomeRowsSubmitting case doesn't actually matter as it concerns
    // text because the <Button> component will have a spinner (loading) when
    // rows are submitting
    || areSomeRowsSubmitting
  ) {
    return 'Submit'
  }
  if (didSomeRowSubmissionsFail) {
    return multipleSources
      ? 'Done, some errors'
      : 'Failure!'
  }
  return 'Success!'
}

function getIsSubmitButtonDisabled({
  isFormSubmittable,
  areSomeRowsSubmitting,
  didSomeRowSubmissionsFail,
  didAllRowSubmissionsSucceed,
}) {
  return (
    !isFormSubmittable
    || areSomeRowsSubmitting
    || didSomeRowSubmissionsFail
    || didAllRowSubmissionsSucceed
  )
}


const PostFetchButtonsNextToSubmitButton = ({
  onResetForm,
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Fragment>
      <ToCustomerPortalButton />
      <Button
        onClick={onResetForm}
        type="button" // CODE_COMMENTS_260
        color="blue"
      >
        {translate('Fill Out Form Again')}
      </Button>
    </Fragment>
  )
}


const FailureMessage = ({
  customerId,
  fetchStatuses,
  multipleSources,
  id,
}) => {
  const errors = extractErrorObjectsFromFetchStatuses({ fetchStatuses })
  const error = errors[id]
  const doesApiErrorSayNoMovementsCantBeReportedBecauseShipmentsHaveAlreadyBeenReportedForThisPeriod =
    getDoesApiErrorSayNoMovementsCantBeReportedBecauseShipmentsHaveAlreadyBeenReportedForThisPeriod({ error })
  const doesApiErrorSayNoMovementsCantBeReportedBecauseItIsADuplicate =
    getDoesApiErrorSayNoMovementsCantBeReportedBecauseItIsADuplicate({ error })
  let errorTitle
  let errorMessage
  if (doesApiErrorSayNoMovementsCantBeReportedBecauseShipmentsHaveAlreadyBeenReportedForThisPeriod) {
    errorTitle = 'Shipments Reported in Selected Month'
    errorMessage = "At least one shipment has already been reported for the selected month, so you can't report No Shipments for it."
  } else if (doesApiErrorSayNoMovementsCantBeReportedBecauseItIsADuplicate) {
    errorTitle = 'Duplicate'
    errorMessage = "You've already reported No Shipments for the selected month."
  } else {
    errorTitle = 'Unknown Error'
    errorMessage = (
      <Fragment>
        {DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR}
        <RevealContactInfo
          customerId={customerId}
          repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
          asPopup
          style={{ marginLeft: '1rem' }}
        />
      </Fragment>
    )
  }
  if (multipleSources) {
    return (
      <Fragment>
        <Header as='h3'>
          {errorTitle}
        </Header>
        {errorMessage}
      </Fragment>
    )
  }
  return (
    <DismissableMessage
      error
      header={errorTitle}
      content={errorMessage}
      style={{ maxWidth: '450px' }}
    />
  )
}

const SubmissionFieldMultipleSources = props => {
  const {
    fetchStatuses,
    id,
  } = props
  const fetchStatus = get_(fetchStatuses, [id, 'status'])
  if (fetchStatus === FETCH_STATUSES_QUEUED) {
    return 'Queued'
  }

  if (fetchStatus === FETCH_STATUSES_IN_PROGRESS) {
    return (
      <Loader
        key={`submitting${id}`}
        active
        inline
        size="tiny"
      />
    )
  }

  if (fetchStatus === FETCH_STATUSES_SUCCESS) {
    return (
      <Icon
        key={`submitSucceeded${id}`}
        name="check circle"
        size="large"
        color="green"
      />
    )
  }

  if (fetchStatus === FETCH_STATUSES_FAILURE) {
    return (
      <PopupWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
        trigger={
          <Icon
            key={`submitFailed${id}`}
            link
            name="exclamation circle"
            size="large"
            color="red"
          />
        }
        on="click"
        wide="very"
        position="bottom center"
        className="semantic-ui-error-border"
      >
        <FailureMessage
          multipleSources
          {...props}
        />
      </PopupWithCatastrophicFailureErrorBoundary>
    )
  }

  // if not queued, fetching, succeeded, or failed, it must be an unsubmitted
  // field
  return <span />
}

/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

function createMonthReportingForRangeMin() {
  return moment().subtract(1, 'month')
}

function createMonthReportingForRangeMax() {
  return moment()
}

function getMonthDropdownOptions() {
  return createMonthAndYearPickerDropdownOptions({
    rangeMin: createMonthReportingForRangeMin(),
    rangeMax: createMonthReportingForRangeMax(),
    sortOptionsInDropdownFromLatestToEarliestRatherThanEarliestToLatest: true,
  })
}

function getInitialValues({
  fieldArraysAndCustomersFieldsDropdownOptions,
}) {
  // If we're in the first week of the month, the default selected value should
  // be last month, otherwise this month
  const monthDropdownOptions = getMonthDropdownOptions()
  const formFieldMonthInitialValue = moment().date() <= 7
    ? monthDropdownOptions[1].value // last month
    : monthDropdownOptions[0].value // this month

  const customersFieldsDropdownOptions = fieldArraysAndCustomersFieldsDropdownOptions[0].customersFieldsDropdownOptions
  // { customerId1: false, customerId2: false, customerId3: false }
  // or if there's only one source:
  // { customerId1: true }
  const formFieldSelectedCustomersInitialValue = customersFieldsDropdownOptions.reduce(
    (acc, o) => ({
      ...acc,
      [o.id]: Boolean(customersFieldsDropdownOptions.length === 1),
    }),
    {},
  )

  return {
    [FIELD_NAME_MONTH]: formFieldMonthInitialValue,
    [FIELD_NAME_SELECTED_CUSTOMERS]: formFieldSelectedCustomersInitialValue,
  }
}


function getIsFormSubmittable({
  formValues,
}) {
  return (
    formValues
    && getMonthDropdownOptions().map(o => o.value).includes(formValues[FIELD_NAME_MONTH])
    && values_(formValues[FIELD_NAME_SELECTED_CUSTOMERS]).some(bool => bool)
  )
}

// {
//   custIdAndOptionalConbrwCustIdReduxIdentifier1: <errorObject>,
//   custIdAndOptionalConbrwCustIdReduxIdentifier2: undefined, // this fetch succeeded or is still submitting
//   custIdAndOptionalConbrwCustIdReduxIdentifier3: <errorObject>,
// }
function extractErrorObjectsFromFetchStatuses({
  fetchStatuses,
  // {
  //   custIdAndOptionalConbrwCustIdReduxIdentifier1: { errorCode: 400, errorMessage: 'Blah', ...},
  //   custIdAndOptionalConbrwCustIdReduxIdentifier2: undefined, // this fetch succeeded or is still submitting
  //   custIdAndOptionalConbrwCustIdReduxIdentifier3: { errorCode: 400, errorMessage: 'Blah', ...},
  // }
  returnErrorDetailsRatherThanErrorObjects=false,
}) {
  return Object.entries(fetchStatuses).reduce(
    (acc, [custIdAndOptionalConbrwCustIdReduxIdentifier, errorInfo]) => ({
      ...acc,
      [custIdAndOptionalConbrwCustIdReduxIdentifier]: get_(errorInfo, ['errorDetails', returnErrorDetailsRatherThanErrorObjects ? undefined : 'errorObject']),
    }),
    {},
  )
}
