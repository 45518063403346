import React from 'react'
import { Button, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


import {
  URL_PATHS,
} from '../../../../constants'


import './currentlyOperatingAsStyles.css'


export default ({
  hasCustomerUserBeenChosen,
  customerUserCurrentlyOperatingAs,
  t: translate,
}) => (
  <div className="centered-content">
    <Header
      as="h3"
      className="line-up-with-search-button"
    >
      <span
        style={{ color: hasCustomerUserBeenChosen ? 'green' : 'grey', wordWrap: 'break-word' }}
      >
        {hasCustomerUserBeenChosen
          ? customerUserCurrentlyOperatingAs.emailAddress
          : translate('No one, yet')
        }
      </span>
    </Header>
    {hasCustomerUserBeenChosen &&
      <Button
        fluid
        as={Link}
        to={URL_PATHS.root.path}
        color="blue"
        id="goToRootCustomerDashboardLink"
      >
        {translate('Go to Customer Dashboard')}
      </Button>
    }
  </div>
)
