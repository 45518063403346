import {
  FETCH_STATUSES_CUSTOMER_BALANCES_REQUEST,
  FETCH_STATUSES_CUSTOMER_BALANCES_SUCCESS,
  FETCH_STATUSES_CUSTOMER_BALANCES_FAILURE,
} from '../../../actions/actionTypes'

import fetchStatusesByCustomerIdReducerCreator from '../higherOrderReducers/reducerCreators/byCustomerId'

export default fetchStatusesByCustomerIdReducerCreator(
  FETCH_STATUSES_CUSTOMER_BALANCES_REQUEST,
  FETCH_STATUSES_CUSTOMER_BALANCES_SUCCESS,
  FETCH_STATUSES_CUSTOMER_BALANCES_FAILURE,
)
