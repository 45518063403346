/**
 * CODE_COMMENTS_93
 */
import { connect } from 'react-redux'


import Table from './components/Table'

import {
  getEntireSlice as getAllKegFillsContracteeHistoryObjects,
} from '../../../../../redux/selectors/histories/kegFillsContracteeHistory'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/forms'


import {
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
} from '../../../../../constants'

import {
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'


const mapStateToProps = (state, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
    formReducerName,
  } = ownProps

  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')


  const formName = createFormNameForRedux({
    reducerName: formReducerName,
    customerId,
  })

  const { getFetchStatuses } = fetchStatusSelectors
  const { isFetching, didFetchFail } = getFetchStatuses(state, formName)

  const areAnyItemsEditable = false

  const allKegFillsContracteeHistoryObjects = getAllKegFillsContracteeHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )

  return {
    entireItemSkusSlice,
    allKegFillsContracteeHistoryObjects,
    configuredItemSkuIds,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    isFormSubmitting: isFetching,
    hasFormSubmitFailed: didFetchFail,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
    ...ownProps,
  }
}

export default connect(
  mapStateToProps,
)(Table)


function getIsindividualItemEditable() {
  return false
}
