/**
 * A HorizontalFormSectionAsLabeledGrid specifically designed for the Report
 * Inventory form
 */

/* eslint-disable max-len */

import React from 'react'

import isFunction_ from 'lodash/isFunction'
import isArray_ from 'lodash/isArray'


import HorizontalFormSectionAsLabeledGrid from '../../../common-components/HorizontalFormSectionAsLabeledGrid'
import ContainerTypesDifferences from '../../ContainerTypesDifferences'

import KegQtyField from './KegQtyField'

import {
  getContainerTypeFromItemSkuId,
} from '../../../redux/selectors/rewrite/itemSkus'

import {
  ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET,
  ITEM_SKU_IDS_CBI_PLASTIC_PALLET, ITEM_SKU_IDS_GOOD_PALLETS,
} from '../../../constants'

import {
  LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR,
  CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP,
  CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP,
  INCLUDE_TOTALS,
} from '../util/configObjects'

import {
  getBaseContainerTypeFromItemSku,
  getHumanReadableContainerTypeFromItemSku, getHumanReadableContainerTypeFromItemSkuWithQualityLevel,
  getHumanReadableContainerTypeInFractionBblForm,
} from '../../../utils'


export default ({
  fieldOfConfigObj,
  ...props
}) => {
  const {
    customerId,
    itemSkuIds,
    entireItemSkusSlice,
    isThisForDisplayingAnAlreadyCreatedInventoryReport,
    isDistReportInventoryNoLoginRequired,
    isEditForm,
    subsidiaryId,
    isDistReportConstellationNoLoginRequired,
    formValues,
    customerType,
    customBusinessUnitIdForContainerTypeDiffsPics, // pass in if isDistReportInventoryNoLoginRequired
  } = props

  const labelToApiInventoryTypeMap = fieldOfConfigObj[LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR](props)
  let customItemSkuIds
  if (isFunction_(fieldOfConfigObj[CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP])) {
    customItemSkuIds = fieldOfConfigObj[CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP](props)
  } else if (isArray_(fieldOfConfigObj[CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP])) {
    customItemSkuIds = fieldOfConfigObj[CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]
  } else {
    customItemSkuIds = itemSkuIds
  }


  const topLabels = labelToApiInventoryTypeMap.map(row => ({
    label: row.label,
    required: !isThisForDisplayingAnAlreadyCreatedInventoryReport,
    arbitraryContentAfterLabel: row.arbitraryContentAfterLabel,
  }))
  if (fieldOfConfigObj[INCLUDE_TOTALS] && !(isThisForDisplayingAnAlreadyCreatedInventoryReport || isEditForm) && subsidiaryId === 24) {
    topLabels.push({ label: 'Totals' }) // aggregate bottom row
  }

  // rowsForHorizontalFormSectionAsLabeledGrid must be an array of arrays of React
  // components
  const rowsForHorizontalFormSectionAsLabeledGrid = isThisForDisplayingAnAlreadyCreatedInventoryReport
    ? customItemSkuIds.map(itemSkuId => (
      labelToApiInventoryTypeMap.map(row => <span>{row.initialValueFunc(itemSkuId)}</span>)
    ))
    : customItemSkuIds.map((itemSkuId, rowIndex) => (
      labelToApiInventoryTypeMap.map((row, columnIndex) => {
        if (fieldOfConfigObj[CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]) {
          const rulesArray = isFunction_(fieldOfConfigObj[CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP])
            ? fieldOfConfigObj[CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP](props)
            : fieldOfConfigObj[CUSTOM_RULES_FOR_WHICH_FIELDS_TO_RENDER_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]
          if ((!rulesArray?.[columnIndex]?.[rowIndex] && !isDistReportConstellationNoLoginRequired) || (isDistReportConstellationNoLoginRequired && !rulesArray?.[rowIndex]?.[columnIndex])) {
            return null
          }
        }
        return (
          <KegQtyField
            {...props}
            name={row.fieldNameFunc(itemSkuId)}
            negativeValuesAllowed={row.negativeValuesAllowed}
          />
        )
      })
    ))


  if (fieldOfConfigObj[INCLUDE_TOTALS] && !(isThisForDisplayingAnAlreadyCreatedInventoryReport || isEditForm) && subsidiaryId === 24) {
    const arrayOfTotalNumbersByItemSkuId = customItemSkuIds.map(itemSkuId => (
      labelToApiInventoryTypeMap.reduce(
        (acc, row) => {
          if (isThisForDisplayingAnAlreadyCreatedInventoryReport) {
            const fieldValue = row.initialValueFunc(itemSkuId)
            const fieldValueAsNumber = fieldValue ? Number(fieldValue) : 0
            return fieldValueAsNumber + acc
          }
          if (!formValues) { // If this is the first time the form is being rendered
            return acc
          }
          const fieldName = row.fieldNameFunc(itemSkuId)
          const fieldValue = formValues[fieldName]
          const fieldValueAsNumber = fieldValue ? Number(fieldValue) : 0
          return fieldValueAsNumber + acc
        },
        0,
      )
    ))
    if (rowsForHorizontalFormSectionAsLabeledGrid?.length === arrayOfTotalNumbersByItemSkuId?.length) {
      rowsForHorizontalFormSectionAsLabeledGrid.forEach((ele, idx) => ele.push(arrayOfTotalNumbersByItemSkuId[idx]))
    }
  }

  const leftLabels = () => customItemSkuIds.map(itemSkuId => {
    let label

    if (itemSkuId === ITEM_SKU_IDS_CBI_PLASTIC_PALLET) {
      label = 'Constellation Plastic - KEG - Pallets'
    } else if (itemSkuId === ITEM_SKU_IDS_CBI_CASE_PLASTIC_PALLET) {
      label = 'Constellation Plastic - CASE - Pallets'
    } else if (itemSkuId === ITEM_SKU_IDS_GOOD_PALLETS) {
      label = 'Constellation Wood Pallets'
    } else {
      const containerType = getContainerTypeFromItemSkuId({
        entireItemSkusSlice,
        itemSkuId,
      })
      if (isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) {
        label = getHumanReadableContainerTypeInFractionBblForm(containerType)
      } else if (subsidiaryId === 24 && !isEditForm && !isThisForDisplayingAnAlreadyCreatedInventoryReport && customerType === 'WH') {
          label = getBaseContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice)
        } else if (subsidiaryId === 24 && isEditForm && !isThisForDisplayingAnAlreadyCreatedInventoryReport && customerType === 'WH') {
          label = getHumanReadableContainerTypeFromItemSkuWithQualityLevel(itemSkuId, entireItemSkusSlice)
        } else if (subsidiaryId === 24 && !isEditForm && isThisForDisplayingAnAlreadyCreatedInventoryReport && customerType === 'WH') {
          label = getHumanReadableContainerTypeFromItemSkuWithQualityLevel(itemSkuId, entireItemSkusSlice)
        } else if (isEditForm && customerType === 'WH') {
          label = getHumanReadableContainerTypeFromItemSkuWithQualityLevel(itemSkuId, entireItemSkusSlice)
        } else {
          label = getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice)
        }
    }
    return {
      label,
      arbitraryContentAfterLabel: () => (
        <ContainerTypesDifferences
          customerId={customerId}
          itemSkuId={itemSkuId} // For CPP
          itemSkuIdsToInclude={(isDistReportInventoryNoLoginRequired || isDistReportConstellationNoLoginRequired) && customItemSkuIds}
          businessUnitIdOfCurrentUserRootCustomer={customBusinessUnitIdForContainerTypeDiffsPics}
          displayPicsOfMKMKegsRatherThanNoPicsAtAllIfBrandOfBusinessUnitPicsNotAvailable={!isDistReportInventoryNoLoginRequired}
        />
      ),
    }
  })

  return (
    <HorizontalFormSectionAsLabeledGrid
      topLabels={topLabels}
      leftLabels={leftLabels()}
      rows={rowsForHorizontalFormSectionAsLabeledGrid}
    />
  )
}
