import { connect } from 'react-redux'

import PopulatedContactCard from '../components/PopulatedContactCard'

import { getProp } from '../../../redux/selectors/users'
import { getUniversalCustomerRep } from '../../../redux/selectors/universalCustomerRepresentatives'

import {
  getIsUniversalCustomerRepType,
} from '../../../utils'

const mapStateToProps = (state, { repId, repType }) => {
  const { name, phone, email } = getRepDetails(state, repId, repType)

  return ({
    name,
    phone,
    email,
  })
}

export default connect(
  mapStateToProps,
)(PopulatedContactCard)


// Helper functions

function getRepDetails(state, repId, repType) {
  const isUniversalCustomerRepType = getIsUniversalCustomerRepType(repType) // CODE_COMMENTS_241
  if (isUniversalCustomerRepType) {
    return getUniversalCustomerRep(state, repType)
  }
  const name = `${getProp(state, repId, 'firstName')} ${getProp(state, repId, 'lastName')}`
  const phone = getProp(state, repId, 'deskPhoneNumber')
  const email = getProp(state, repId, 'emailAddress')
  return { name, phone, email }
}
