import { combineReducers } from 'redux'

import {
  REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD,
} from '../../../constants'

import forgotPasswordReducer from './forgotPassword'


const publicFormReducers = {
  [REDUCER_NAMES_PUBLIC_FORMS_FORGOT_PASSWORD]: forgotPasswordReducer,
}

export default combineReducers(publicFormReducers)
