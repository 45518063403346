import { connect } from 'react-redux'

import failureMessageIfNecessaryHocOnResetPwOrConfirmAccount from '../../shared/failureMessageIfNecessaryHocOnResetPwOrConfirmAccount'
import Form from '../../shared/FormSharedByResetPwAndConfirmAccount'


import createAction from '../../../../redux/actions/createAction'

import {
  FETCH_FETCH_CONFIRM_ACCOUNT,
} from '../../../../redux/actions/actionTypes'

import {
  REDUCER_NAMES_PUBLIC_FORMS_CONFIRM_ACCOUNT,
} from '../../../../constants'

import fetchStatusSelectors from '../../../../redux/selectors/fetchStatuses/publicForms'


const {
  getFetchStatuses,
} = fetchStatusSelectors


const mapStateToProps = state => {
  const fetchStatuses = getFetchStatuses(state, REDUCER_NAMES_PUBLIC_FORMS_CONFIRM_ACCOUNT)
  return {
    ...fetchStatuses,
    isConfirmAccountForm: true,
    formName: REDUCER_NAMES_PUBLIC_FORMS_CONFIRM_ACCOUNT,
  }
}

const mapDispatchToProps = dispatch => ({
  handleSubmit: ({ email, confirmationCode, password }) => {
    dispatch(createAction(FETCH_FETCH_CONFIRM_ACCOUNT, { email, confirmationCode, password }))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(failureMessageIfNecessaryHocOnResetPwOrConfirmAccount(Form))
