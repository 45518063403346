/**
 * CODE_COMMENTS_12
 */
import sortBy_ from 'lodash/sortBy'
import orderBy_ from 'lodash/orderBy'
import isArray_ from 'lodash/isArray'

import {
  getProp as getCurrentUserProp,
} from './currentUser'

import {
  withPropNormalized,
  withPropOrNormalized,
  withPropOfAllNormalized,
  withMultiplePropsOfAllNormalized,
  getEntireSlice as getEntireSliceCommon,
} from './higherOrderFunctions'

import {
  REDUCER_NAMES_ENTITIES,
  REDUCER_NAMES_ENTITIES_CUSTOMERS as defaultSlice,

  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
  CUSTOMER_TYPES_PUB,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,

  CUSTOMER_STATUS_INACTIVE,
  CUSTOMER_STATUS_HOLD,

  CUSTOMER_HOLD_TYPE_CREDIT_HOLD,
  CUSTOMER_HOLD_TYPE_REPORTING_HOLD,
  CUSTOMER_HOLD_TYPE_SALES_HOLD,

  CUSTOMER_REPS_ACCOUNT,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
  CUSTOMER_REPS_LOGISTICS,
  CUSTOMER_REPS_QUALITY,
  CUSTOMER_REPS_SALES,

  BUSINESS_UNIT_ID_TYPE_MICROSTAR,
  CUSTOMER_TYPES_WAREHOUSE,
  CUSTOMER_HOLD_TYPE_ACKNOWLEDGEMENT_HOLD, CUSTOMER_TYPES_MAINTENANCE_FACILITY,
} from '../../constants'

import {
  deDupeArray,
} from '../../utils'

import {
  NestedPropNotInObjectError,
} from '../../customErrors'


/*
 * *****************************************************************************
 * The basics
 * *****************************************************************************
*/

// usage: const customerType = getProp(state, customerId, 'customerType')
export const getProp = withPropNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const customerType = getProp(state, customerId, 'customerType', 'customer type not found')
export const getPropOr = withPropOrNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const arrayOfAllCustomerTypes = getPropOfAll(state, 'customerType')
export const getPropOfAll = withPropOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: const idAndCustomerTypeOfAllCustomers = getMultiplePropsOfAll(state, 'id', 'customerType')
// returns:
// [
//   {id: 1234, customerType: MASTER},
//   {id: 1235, customerType: BRW},
//   {id: 1236, customerType: DIST},
// ]
export const getMultiplePropsOfAll = withMultiplePropsOfAllNormalized(REDUCER_NAMES_ENTITIES, defaultSlice)

// usage: you can use this to get the slice for all customers or for a single
// customer:
// const entireSliceForAllCustomers = getEntireSlice(state)
// const entireSliceForSingleCustomer = getEntireSlice(state, customerId)
export const getEntireSlice = getEntireSliceCommon(REDUCER_NAMES_ENTITIES, defaultSlice)


/*
 * *****************************************************************************
 * Customer Types
 * *****************************************************************************
*/

export const getIsCustomerAMasterType = isOneOfTheseHof(CUSTOMER_TYPES_MASTER)
export const getIsCustomerABrwType = isOneOfTheseHof(CUSTOMER_TYPES_BREWER)
export const getIsCustomerAConBrwType = isOneOfTheseHof(CUSTOMER_TYPES_CONTRACT_BREWER)
export const getIsCustomerADistType = isOneOfTheseHof(CUSTOMER_TYPES_DISTRIBUTOR)


export const getIsCustomerAMasterOrBrwOrConbrwOrDistType = isOneOfTheseHof(
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
)
export const getIsCustomerAMasterOrBrwOrConbrwType = isOneOfTheseHof(
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
)
export const getIsCustomerABrwOrConbrwType = isOneOfTheseHof(
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
)
export const getIsCustomerABrwOrConbrwOrDistType = isOneOfTheseHof(
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
)
export const getIsCustomerAMasterOrBrwType = isOneOfTheseHof(
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_TYPES_BREWER,
)
export const getIsCustomerABrwOrDistType = isOneOfTheseHof(
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_DISTRIBUTOR,
)
export const getIsCustomerAWhType = isOneOfTheseHof(
  CUSTOMER_TYPES_WAREHOUSE,
)
export const getIsCustomerAMfType = isOneOfTheseHof(
  CUSTOMER_TYPES_MAINTENANCE_FACILITY,
)

// Brewers can have PUB children, see CODE_COMMENTS_49. Contract Brewers can
// have Self-Collection (COL) children.
export const getIsCustomerOfATypeThatHasChildren = (state, customerId) => {
  if (getIsRentalCustomer(state, customerId)) {
    return false
  }
  return getIsCustomerAMasterOrBrwOrConbrwType(state, customerId)
}


export const getIsCustomerOfATypeThatCanHavePrelimInfoFetchedOnIt = (state, customerId) => (
  !getIsCustomerStatusInactive(state, customerId)
  && getIsCustomerAMasterOrBrwOrConbrwOrDistType(state, customerId)
)


export const getIsCustomerOfATypeThatHasAddresses = (state, customerId) => (
  getIsRentalCustomer(state, customerId)
  || getIsCustomerABrwOrConbrwOrDistType(state, customerId)
)


// related *to*, not related from. Shouldn't we also call /relatedTo on Contract
// Brewers? No, see CODE_COMMENTS_82. Distributors can be related to other
// distributors.
export const getIsCustomerOfATypeThatIsRelatedToOtherCustomers = (state, customerId) => {
  if (getIsRentalCustomer(state, customerId)) {
    return false
  }
  return getIsCustomerABrwOrDistType(state, customerId)
}


export const getIsCustomerOfATypeThatIsRelatedFromOtherCustomers = (state, customerId) => {
  if (getIsRentalCustomer(state, customerId)) {
    return false
  }
  return getIsCustomerABrwOrConbrwOrDistType(state, customerId)
}


// This includes CONBRWs and non-contractee BRWs, no rental customers
export const getIsCustomerOfATypeThatHasContractsCalledOnIt = (
  state,
  customerId,
  operatingContractBrewerCustomerId,
) => {
  if (
    operatingContractBrewerCustomerId
    || getIsRentalCustomer(state, customerId)
  ) {
    return false
  }
  return getIsCustomerABrwOrConbrwType(state, customerId)
}


// Even though Pubs and self-distribution customers are children of Master
// customedrs, they will not have their own portals (no one will ever log in
// with their root customer as a pub or self-distribution customer) and
// therefore will not have child cards in the "Children Overview" section of the
// Master dashboard.
export const getIsCustomerOfATypeThatHasItsOwnPortal = getIsCustomerABrwOrConbrwOrDistType


export const getIsCustomerOfATypeThatHasInboundUnacknowledgedShipments = (
  state,
  customerId,
  operatingContractBrewerCustomerId,
) => {
  if (
    operatingContractBrewerCustomerId
    || getIsRentalCustomer(state, customerId)
  ) {
    return false
  }
  return getIsCustomerABrwOrConbrwType(state, customerId)
}


// This includes rental customers (because all rentals are BRWs.)
export const getIsCustomerOfATypeThatHasContacts = getIsCustomerABrwOrConbrwOrDistType


export const getIsCustomerOfATypeThatOrdersCollars = (state, customerId) => (
  getIsRentalCustomer(state, customerId)
  || getIsCustomerABrwOrConbrwType(state, customerId)
)


export const getIsCustomerOfATypeThatPaysBalances = (state, customerId) => (
  getIsRentalCustomer(state, customerId)
  || getIsCustomerABrwOrConbrwType(state, customerId)
)


// helper functions for this section

// You can either pass in a single array of customer types or list each customer
// type as its own argument
function isOneOfTheseHof(...custTypes) {
  const arrayOfCustTypes = custTypes.reduce(
    (acc, argument) => (isArray_(argument) ? [...acc, ...argument] : [...acc, argument]),
    [],
  )
  return (state, customerId) => arrayOfCustTypes.includes(getProp(state, customerId, 'customerType'))
}


/*
 * *****************************************************************************
 * Misc
 * *****************************************************************************
*/

export function getDoesCustomerHaveAnyChildren(state, customerId) {
  return getProp(state, customerId, 'childrenIds').length > 0
}


export function getDoesCustomerHaveChild(state, customerId, childCustomerId) {
  return getProp(state, customerId, 'childrenIds').includes(childCustomerId)
}


/**
 * A helper function for other selectors in this file
 */
function getIdsOfAllCustomersWithCustomerInfoSavedForThem(state) {
  return getPropOfAll(state, 'id')
}


export function getIsCustomerInfoSavedForACustomer(state, customerId) {
  getIdsOfAllCustomersWithCustomerInfoSavedForThem(state).includes(customerId)
}


// If this is called on a MASTER customer, it should only be called after
// /customers/:id/children/objects has been successfully called on the MASTER
// customer. Returns an empty list if customer has no non-self-dist and pub
// children.
export function getIdsOfNonInactiveAndNonSelfDistAndPubChildren(state, masterCustomerId) {
  if (!getIsCustomerOfATypeThatHasChildren(state, masterCustomerId)) { return [] }
  const childrenIdsOfMaster = getProp(state, masterCustomerId, 'childrenIds')
  const idAndCustomerTypeOfAllCustomers = getMultiplePropsOfAll(state, 'id', 'customerType')
  const idAndCustomerTypeOfAllChildrenCustomers = idAndCustomerTypeOfAllCustomers.filter(
    idAndCustomerType => childrenIdsOfMaster.includes(idAndCustomerType.id),
  )
  const idAndCustomerTypeOfAllNonSelfDistAndPubChildren = idAndCustomerTypeOfAllChildrenCustomers.filter(
    idAndCustomerType => ![
      CUSTOMER_TYPES_PUB,
      CUSTOMER_TYPES_SELF_DISTRIBUTION,
    ].includes(idAndCustomerType.customerType),
  )

  const idsOfAllNonSelfDistAndPubChildren =
    idAndCustomerTypeOfAllNonSelfDistAndPubChildren.map(idAndCustomerType => idAndCustomerType.id)

  return idsOfAllNonSelfDistAndPubChildren.filter(id => !getIsCustomerStatusInactive(state, id))
}


// Be careful with this function: you can only use it on rootCustomers or their
// children to get accurate results (you could get a false negative for
// relatedTo/relatedFrom customers); see CODE_COMMENTS_125.
export function getIsCustomerOnCreditHold(state, customerId) {
  return (
    getProp(state, customerId, 'customerStatus') === CUSTOMER_STATUS_HOLD
    && getProp(state, customerId, 'holdType') === CUSTOMER_HOLD_TYPE_CREDIT_HOLD
  )
}


// Be careful with this function: you can only use it on rootCustomers or their
// children to get accurate results (you could get a false negative for
// relatedTo/relatedFrom customers); see CODE_COMMENTS_125.
export function getIsCustomerOnReportingHold(state, customerId) {
  return (
    getProp(state, customerId, 'customerStatus') === CUSTOMER_STATUS_HOLD
    && getProp(state, customerId, 'holdType') === CUSTOMER_HOLD_TYPE_REPORTING_HOLD
  )
}
// CODE_COMMENTS_125
export function getIsCustomerOnSalesHold(state, customerId) {
  return (
    getProp(state, customerId, 'customerStatus') === CUSTOMER_STATUS_HOLD
    && getProp(state, customerId, 'holdType') === CUSTOMER_HOLD_TYPE_SALES_HOLD
  )
}


// CODE_COMMENTS_125
export function getIsCustomerStatusInactive(state, customerId) {
  return getProp(state, customerId, 'customerStatus') === CUSTOMER_STATUS_INACTIVE
}

export function getIsCustomerOnAcknowledgmentHold(state, customerId) {
  return (
    getProp(state, customerId, 'customerStatus') === CUSTOMER_STATUS_HOLD
    && getProp(state, customerId, 'holdType') === CUSTOMER_HOLD_TYPE_ACKNOWLEDGEMENT_HOLD
  )
}


export function sortArrayOfCustomerIdsByOtherCustomerProps({
  state,
  customerIds,
  arrayOfPropNames,
  // optional: an array, the last argument of lodash's orderBy(), for example
  // ['asc', 'desc']
  ascendingAndDescendingRules,
}) {
  const props = getMultiplePropsOfAll(state, 'id', ...arrayOfPropNames).filter(o => customerIds.includes(o.id))
  const sorted = ascendingAndDescendingRules
    ? orderBy_(props, arrayOfPropNames, ascendingAndDescendingRules)
    : sortBy_(props, arrayOfPropNames)
  return sorted.map(o => o.id)
}


// pass in an array of customerIds and this sends back an array of customer
// objects. If the customer doesn't exist in the store, it is skipped.
export function getMultipleCustomerObjectsById(state, customerIds) {
  return customerIds.map(customerId => {
    let customerObj
    try {
      customerObj = getEntireSlice(state, customerId)
    } catch (e) {
      if (e instanceof NestedPropNotInObjectError) {
        customerObj = undefined
      } else {
        throw e
      }
    }
    return customerObj
  }).filter(customerObj => customerObj) // filter falsy (non-existent) objects
}


export function getBusinessUnitIdOfRootCustomer({
  state,
  // set this to a function. If the caller doesn't set this and the root
  // customer hasn't been fetched yet, a NestedPropNotInObjectError error will
  // be thrown.
  doThisRatherThanThrowErrorIfRootCustomerHasNotYetBeenFetched=null,
}) {
  const getCustomerProp = getProp

  const currentUserRootCustomerId = getCurrentUserProp(state, 'rootCustomerId')
  let businessUnitIdOfCurrentUserRootCustomer
  try {
    businessUnitIdOfCurrentUserRootCustomer = getCustomerProp(state, currentUserRootCustomerId, 'businessUnitId')
  } catch (e) {
    if (e instanceof NestedPropNotInObjectError) {
      if (doThisRatherThanThrowErrorIfRootCustomerHasNotYetBeenFetched) {
        return doThisRatherThanThrowErrorIfRootCustomerHasNotYetBeenFetched({
          state,
          currentUserRootCustomerId,
        })
      }
      throw e
    }
    throw e
  }
  // If for whatever reason the `businessUnitId` prop of the root customer is
  // set to null within the API object, set it to MicroStar
  if (!businessUnitIdOfCurrentUserRootCustomer) {
    businessUnitIdOfCurrentUserRootCustomer = BUSINESS_UNIT_ID_TYPE_MICROSTAR
  }

  return businessUnitIdOfCurrentUserRootCustomer
}


// CODE_COMMENTS_228
export function getIsRentalCustomer(state, customerId) {
  const customerType = getProp(state, customerId, 'customerType')
  // It's possible a Master customer could have a name that starts with 'KCR'
  // (see CODE_COMMENTS_228), in which case we want to treat that customer as a
  // Master, not as a Rental Customer.
  if (customerType === CUSTOMER_TYPES_MASTER) {
    return false
  }
  return getProp(state, customerId, 'name')?.toLowerCase()?.startsWith('kcr')
}

/*
 * *****************************************************************************
 * Customer Reps
 * *****************************************************************************
*/

// returns an array (IDs are not mapped to rep type), nulls values filtered out
export function getIdsOfAllReps(state, customerId) {
  return deDupeArray([
    getProp(state, customerId, 'ceRep'),
    getProp(state, customerId, 'logisticsRep'),
    getProp(state, customerId, 'accountRep'),
    getProp(state, customerId, 'qualityRep'),
    getProp(state, customerId, 'salesRep'),
  ].filter(
    // If a customer doesn't have, say, a sales rep assigned to them, the
    // 'salesRep' prop will be null.
    id => id,
  ))
}


export function getAllRepsOfCustomer(state, customerId, filterOutNulls=true) {
  const toReturn = {
    [CUSTOMER_REPS_CUSTOMER_EXPERIENCE]: getProp(state, customerId, 'ceRep'),
    [CUSTOMER_REPS_LOGISTICS]: getProp(state, customerId, 'logisticsRep'),
    [CUSTOMER_REPS_ACCOUNT]: getProp(state, customerId, 'accountRep'),
    [CUSTOMER_REPS_QUALITY]: getProp(state, customerId, 'qualityRep'),
    [CUSTOMER_REPS_SALES]: getProp(state, customerId, 'salesRep'),
  }
  if (filterOutNulls) {
    return Object.keys(toReturn).reduce((acc, key) => (
      toReturn[key]
        ? { ...acc, [key]: toReturn[key] }
        : acc
    ), {})
  }
  return toReturn
}


export function getAllNonNullRepTypesOfCustomer(state, customerId) {
  const allReps = getAllRepsOfCustomer(state, customerId)
  return Object.keys(allReps)
}
