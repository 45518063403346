/**
 * CODE_COMMENTS_131
 */
import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import ReactSVG from 'react-svg'

import logo from '../../public/imagesAndIcons/logos/logo.svg'


import './styles.css'


export default ({
  title,
  titleColor,
  content,
}) => (
  <Container className="two-column-splash-screen-container">
    <Grid verticalAlign="middle">
      <Grid.Row columns={2}>
        <Grid.Column>
          <ReactSVG path={logo} className="grey-logo-fill" />
        </Grid.Column>
        <Grid.Column>
          <h1 style={{ color: titleColor }}>{title}</h1>
          {content()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
)
