import React from 'react'

import RevealContactInfo from '../../RevealContactInfo'
import RevealFetchErrorDetails from '../../fetchErrorDetails/RevealFetchErrorDetails'

import DismissableMessageClearingFetchStatus from './DismissableMessageClearingFetchStatus'

import {
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
} from '../../../constants'


export default ({
  formName,
  errorDetails,
}) => (
  <DismissableMessageClearingFetchStatus
    error
    formName={formName}
    content={
      <div>
        <p>{DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR}</p>
        <div>
          <RevealContactInfo
            asPopup
          />
          <RevealFetchErrorDetails
            errorDetails={errorDetails}
            asPopup
          />
        </div>
      </div>
    }
  />
)
