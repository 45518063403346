// CODE_COMMENTS_205

import React from 'react'


import ShipmentHistoryFormCreator from '../../HOC/ShipmentHistoryFormCreator'

import DownloadInboundFullKegShipmentHistoryTableAsFile from '../DownloadTableAsFile'

import {
  outboundFullKegShipmentHistoryStatusesConfig,
} from '../../util/shipmentHistory/outboundFullKegShipmentHistory'

import { FETCH_INBOUND_FULL_KEG_SHIPMENT_HISTORY } from '../../../../../redux/actions/actionTypes'


export default props => (
  <ShipmentHistoryFormCreator
    {...props}
    downloadFileComponent={DownloadInboundFullKegShipmentHistoryTableAsFile}
    // Yes, we really mean _outbound_ empty keg shipment history here: inbound
    // full doesn't have its own because it's identical to outbound full
    statusesConfig={outboundFullKegShipmentHistoryStatusesConfig}
    submitAction={FETCH_INBOUND_FULL_KEG_SHIPMENT_HISTORY}
    inboundOrOutbound="inbound"
  />
)
