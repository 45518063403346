// Basic tracking: sends a tracking message anytime someone visits the site or
// navigates to a different page. Implementation from
// https://github.com/react-ga/react-ga/issues/122#issuecomment-299692833 which
// seems to have become a de-facto standard (it's been added to the `react-ga`
// documentation:
// https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker)

import React from 'react'
import GoogleAnalytics from 'react-ga'


import {
  ENVIRONMENT_PROD,
} from './constants'

import {
  getEnvironmentBasedOnRootApiUrl,
} from './utils'

GoogleAnalytics.initialize(
  // our personalized code provided by Google Analytics
  'UA-131022580-1',
)

export default (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    })
    GoogleAnalytics.pageview(page)
  }

  const HOC = class extends React.Component {
    componentDidMount() {
      const page = this.props.location.pathname
      trackPage(page)
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (
        // This function gets called every time the page re-renders, so without
        // the following condition, a tracking message could be sent multiple
        // times while the user is still on the same page (due to some unrelated
        // part of the app changing the Redux store such as an AJAX call and
        // causing a site-wide re-render)
        currentPage !== nextPage &&
        // don't track dev or test
        getEnvironmentBasedOnRootApiUrl() === ENVIRONMENT_PROD
      ) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return HOC
}
