import {
  withPropSimple,
} from './higherOrderFunctions'

import { REDUCER_NAMES_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN as defaultSlice } from '../../constants'


const getProp = withPropSimple(defaultSlice)


export function getHaveTasksToPerformImmediatelyAfterLoginBeenAttempted(state) {
  return getProp(state, 'haveTasksToPerformImmediatelyAfterLoginBeenAttempted')
}

export function getHaveTasksToPerformImmediatelyAfterLoginSucceeded(state) {
  return getProp(state, 'haveTasksToPerformImmediatelyAfterLoginSucceeded')
}
