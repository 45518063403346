import chooseStateSagas from './chooseState'
import chooseCitySagas from './chooseCity'
import chooseDistributorSagas from './chooseDistributor'
import v2Sagas from './v2/index'

export default [
  ...v2Sagas,
  ...chooseStateSagas,
  ...chooseCitySagas,
  ...chooseDistributorSagas,
]
