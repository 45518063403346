// A component that allows you to format part of a form as a table, Use this
// form-as-table when:
//
// 1) every field in one column has the same field type (all <Input>, all
//    <Select>, etc);
// 2) you want the user to be able to add or delete rows;
// 3) you want each row to make its own API call and have its own fetch status.
//
// (If you don't need these features but simply want a table-like layout to your
// form fields, consider using HorizontalFormSectionAsLabeledGrid instead, which
// has a simpler state shape.)
//
// The most basic configuration is a simple table with top labels:
//
// +-----------+-----------+
// | topLabel1 | topLabel2 |
// +-----------+-----------+
// | [field]   | [field]   |
// | [field]   | [field]   |
// +-----------+-----------+
//
// Again, if this is all you need, consider using
// HorizontalFormSectionAsLabeledGrid.
//
// There are four major pieces of configuration that enhance this component.
// The first is the ability for individual row submission. To enable, set the
// "areRowsIndividuallySubmitted" flag. When the
// hasEntireFormBeenSubmitted prop is set to true, an additional column gets
// created on the right end of the table when the form gets submitted.
// Each cell has real-time submission status info for its row:
//
// +-----------+-----------+---------------+
// | topLabel1 | topLabel2 |  Submitted?   |
// +-----------+-----------+---------------+
// | [field]   | [field]   | Succeeded     |
// | [field]   | [field]   | Failed!       |
// | [field]   | [field]   | Submitting... |
// | [field]   | [field]   | Queued        |
// | [field]   | [field]   | Queued        |
// +-----------+-----------+---------------+
//
// All fields are automatically disabled when the form is submitting.
//
//
// The second major configuration is the ability to add and delete rows: when
// abilityToAddAndDeleteRows is set, An "Add row" button is included at the
// bottom of the table, and an additional column is added to the beginning of
// the table with minus buttons to delete each row:
//
// +---+-----------+-----------+
// |   | topLabel1 | topLabel2 |
// +---+-----------+-----------+
// | - | [field]   | [field]   |
// | - | [field]   | [field]   |
// | - | [field]   | [field]   |
// +---+-----------+-----------+
// Add Row [Button]
//
// When abilityToAddAndDeleteRows is set, include the `onAddRow` and
// `onDeleteRow` props, which should be functions that change set formValues by
// adding/deleting an element to the fieldArray you're targeting (the
// fieldArrayName and rowIndex will be passed to the function, as well as all
// other props that this FormSectionAsTable receives.)
//
//
// The third major enhancement is the ability to add left labels to each row:
//
// +------------+-----------+-----------+
// |            | topLabel1 | topLabel2 |
// +------------+-----------+-----------+
// | leftLabel1 | [field]   | [field]   |
// | leftLabel2 | [field]   | [field]   |
// +------------+-----------+-----------+
//
//
// The fourth major enhancement is the ability to make the individual
// row submission column pull double duty by serving a different purpose before
// the form has been submitted. This is useful when, for example, you want to
// alert the user that a row might be a duplicate. To enable, pass in the
// boolean shouldRenderSubmissionColumnAlternatePurpose prop. If true, also pass
// in these props:
// * submissionColumnAlternatePurposeRowRenderer: (props, rowNumber) => (
//   <Popup
//     trigger={<Icon name="warning sign" color="orange" circular}
//     content="The Popup Content"
//   />
// )
// * submissionColumnAlternatePurposeColumnTitle: "Some Title"
//
//
// Other minor features include:
//
// * Set the "compact" prop for very little padding around fields (making it
// look like a traditional table).
//
// * Set the "noFieldJustText" prop on a particular column if you want the cells
// in that column to simply display text rather than be a fillable form field.
// The noFieldJustText prop can be set to several different things:
//
//   1)  true. Set it to true if the displayed text resides in the formValues
//       prop, which this component will read
//   2)  A function which takes the form values, field array name, and row index
//       as arguments. Use this if you want to calculate the content based on
//       the form's values.
//   3)  an array of strings. Setting this to an array of strings allows you to
//       set the displayed text within fieldDefinitions itself. Each array item
//       corresponds to a single form row. Use this if you want to set different
//       static text for each individual row.
//   4)  a string. Setting this to a string allows you to set the displayed text
//       within fieldDefinitions itself. Only do this if you want every row to
//       have the exact same text.
//
// * Set the "createPerCellFieldProps" prop if you want each individual field in
// a column to have its own props on the fieldComponent component. This can
// be helpful when, for instance, you have a fixed-number-of-rows table (no
// ability to add/delete rows) and each row represents a separate entity (as a
// concrete example, each row represents one shipment that the user is
// acknowledging they've received, and each "date received" field needs to have
// a different minimum selectable date depending on the ship date of the
// shipment). The "createPerCellFieldProps" prop should be an object with each
// item having a field name as its key and an array of objects containing actual
// props as its value (the array should be in the order of the rendered field
// array rows), e.g.:
//
// {
//   'date-received': [
//     { minDate: ..., maxDate: ..., validation: ... },
//     { minDate: ..., maxDate: ..., validation: ... },
//     { minDate: ..., maxDate: ..., validation: ... },
//   ],
//   ...
// }
//
// * Set the "fieldsToOmit" prop if you want to completely omit certain form
// fields in specific rows. This prop should be an object with each item having
// a field name as its key and an array of row indexes as its value, e.g.:
// {
//   'good-pallets': [1, 3, 4], // omit the 'good-pallets' field from rows 1, 3, and 4
//   ...
// }
//
//
// The `fieldDefinitions` prop should be an array of objects, with each object
// representing a table column. It should have these properties:
// {
//   topLabel: str,
//   fieldName: str,
//   formFieldProps: either an object or a function that returns an object. The
//     object props will be passed as props to the Semantic UI <Form.Field>
//     component. The function gets passed in an object with these props:
//     { formValues, rowIndex }. The function is helpful when, for instance, you
//     want to disable the field depending on the value of a different part of
//     the form;
//   fieldComponent: the actual input component, such as a semantic-ui-react
//     <Input> component;
//   fieldProps: same as formFieldProps, except the object props will be passed
//     as props to the fieldComponent component. The function is helpful
//     when, for instance, you want to show different placeholder text depending
//     on the value of a different part of the form;
// }

/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Form, Button, Icon, Dropdown } from 'semantic-ui-react'


import get_ from 'lodash/get'
import isFunction_ from 'lodash/isFunction'
import { useTranslation } from 'react-i18next'

import SubmissionField from './subfeatures/SubmissionField'
import IndividualField from './IndividualField'

import './styles/formSectionAsTable.css'


export default props => {
  const {
    fieldDefinitions,
    fetchStatuses,
    areRowsIndividuallySubmitted=true,
    customRenderFailurePopupInstructions,

    abilityToAddAndDeleteRows=true,
    addRowButtonText='New Row',

    perCellFieldProps,
    fieldsToOmit,
    // if abilityToAddAndDeleteRows is true, you can decide whether the first row
    // is allowed to be deleted or not
    firstRowCannotBeDeleted=false,
    // leftLabels=false,

    arbitraryContentAfterAddRowButton,

    fieldArrayName,
    hasEntireFormBeenSubmitted,
    formValues,

    shouldRenderSubmissionColumnAlternatePurpose,
    submissionColumnAlternatePurposeRowRenderer: SubmissionColumnAlternatePurposeRowRenderer,
    submissionColumnAlternatePurposeColumnTitle,
    showSubmitField,
  } = props
  const groupByFieldsArr = []
  const { t: translate } = useTranslation('common')

  return (
    <div
      className="form-as-table"
      id={fieldArrayName}
      style={{
        marginTop: '25px',
        marginBottom: '25px',
      }}
    >

      {formValues[fieldArrayName].map((o, index) => {
        const indexOfFirstNonOmittedNonTextOnlyColumnInThisRow = fieldDefinitions.findIndex(fieldDefinition => {
          const shouldThisParticularFieldBeOmitted = (
            fieldsToOmit
            && fieldsToOmit[fieldDefinition.fieldName]
            && fieldsToOmit[fieldDefinition.fieldName].includes(index)
          )
          return (!shouldThisParticularFieldBeOmitted && !fieldDefinition.noFieldJustText)
        })
        return (
          <div key={`${fieldArrayName}[${index}]`}>
            <Form.Group>
              {/* Remove Row button */}
              {abilityToAddAndDeleteRows &&
                <Form.Field
                  disabled={hasEntireFormBeenSubmitted}
                >
                  {/* We add a label to the "Remove Row" column for alignment
                  purposes only (specifically, so that we can apply the same
                  alignment CSS to a "remove row" button on the first row that
                  we do to a checkbox on the first row). */}
                  {index === 0 &&
                    <label
                      htmlFor="this-label-prevents-a-console-warning"
                      style={{ visibility: 'hidden' }}
                    >
                      .
                    </label>
                  }
                  <DeleteRowButton
                    rowIndex={index}
                    firstRowCannotBeDeleted={firstRowCannotBeDeleted}
                    {...props}
                  />
                </Form.Field>
              }


              {/* Main fields */}
              {fieldDefinitions.map((fieldDefinition, columnIndex) => {
                // will return the predefined field props or, if no predefined, undefined
                const { groupByFields, showLabel, renderOnlyIf } = fieldDefinition

                // eslint-disable-next-line max-len
                const hasNext = groupByFields && (fieldDefinition?.groupByComponent === fieldDefinitions?.[columnIndex+1]?.groupByComponent)
                const fieldPropsForThisParticularField = get_(
                  perCellFieldProps,
                  `[${fieldDefinition.fieldName}][${index}]`,
                )

                const shouldThisParticularFieldBeOmitted = (
                  fieldsToOmit
                  && fieldsToOmit[fieldDefinition.fieldName]
                  && fieldsToOmit[fieldDefinition.fieldName].includes(index)
                )

                const fieldHtmlName = `${fieldArrayName}[${index}].${fieldDefinition.fieldName}`

                const shouldFieldBeAutoFocused = (
                  // On forms such as the Acknowledge Inbound Shipments form,
                  // where the user can't add/edit additional rows, we don't want
                  // to have this automatic cursor focus functionality; Without
                  // this condition, if the Acknowledge Inbound Shipments form has
                  // more than one row in it, the cursor would focus on the first
                  // field of the last row, which doesn't make any sense.
                  abilityToAddAndDeleteRows
                  && index === 0
                  && indexOfFirstNonOmittedNonTextOnlyColumnInThisRow === columnIndex
                  && Object.keys(formValues)?.indexOf(fieldArrayName) === 0 // first row
                )

                // CODE_COMMENT 281
                const ele = (isFunction_(renderOnlyIf) && !renderOnlyIf({
                  ...props, values: o, fields: fieldDefinition,
                })) ?
                  <React.Fragment />
                  :
                  (
                    <IndividualField
                    {...props}
                    formValues={formValues}
                    fieldArrayName={fieldArrayName}
                    rowIndex={index}
                    fieldDefinition={fieldDefinition}
                    fieldPropsForThisParticularField={{
                      ...fieldPropsForThisParticularField,
                      ...(fieldDefinition.fieldComponent === Dropdown
                        ? {
                          // If the field is a Semantic Ui React dropdown,
                          // autoFocus: true won't work; this will:
                          // https://stackoverflow.com/a/48109134/6995996
                          searchInput: { autoFocus: shouldFieldBeAutoFocused },
                          // without the openOnFocus prop set to false (it's
                          // true by default), if the autofocused column is a
                          // Semantic UI React <Dropdown>, then when the user
                          // clicks "New Row", the dropdown choices of this
                          // newly-rendered dropdown would would automatically
                          // open as if the user had clicked on the field. This
                          // is annoying. What we want is for the cursor to be
                          // focused on the dropdown field but not automatically
                          // show the options; the user can then click the
                          // spacebar to show the options (or, if this is a
                          // searchable dropdown, start typing a search term,
                          // which will automatically open the filtered list of
                          // choices). This prop does that.
                          openOnFocus: false,
                        }
                        : {
                          // When the user clicks the the "New Row" button,
                          // focus the cursor on the first non-text, non-omitted
                          // column in the new row. This is helpful for users
                          // who are using the keyboard only to add new rows.
                          autoFocus: shouldFieldBeAutoFocused,
                        }
                      ),

                    }}
                    shouldThisParticularFieldBeOmitted={shouldThisParticularFieldBeOmitted}
                    disabled={hasEntireFormBeenSubmitted}
                    showLabel={index === 0 || showLabel}
                    // We use Lodash's get here because it won't throw a 'TypeError:
                    // Cannot read property of undefined' if there is no
                    // fieldArrayName prop in the initialValues object; instead, it
                    // just returns undefined
                    initialValue={get_(formValues, [fieldArrayName, index, fieldDefinition.fieldName])}
                    fieldHtmlName={fieldHtmlName}
                    key={fieldHtmlName}
                    />
                  )

                if (groupByFields) {
                  groupByFieldsArr.push(ele)
                  // eslint-disable-next-line max-len

                  if (!hasNext) {
                    const groupByFieldsArrProps = [...groupByFieldsArr]
                    groupByFieldsArr.length = 0
                    const { groupByComponent: GroupByComponent, groupByLabel } = fieldDefinition
                    return (
                      // eslint-disable-next-line max-len
                      <GroupByComponent
                        groupByLabel={groupByLabel}
                        index={index}
                        {...props}
                      >
                        {groupByFieldsArrProps}
                      </GroupByComponent>
                    )
                  }
                  return <></>
                }

                return ele
              })}


              {/* Row submission status column */}
              {areRowsIndividuallySubmitted && hasEntireFormBeenSubmitted &&
                <Form.Field
                  width={1}
                >
                  {(index === 0 || showSubmitField) && <label htmlFor="avoids-console-error">{`${translate('Submitted')}?`}</label>}
                  <SubmissionField
                    fetchStatuses={fetchStatuses}
                    fieldArrayName={fieldArrayName}
                    rowIndex={index}
                    customRenderFailurePopupInstructions={customRenderFailurePopupInstructions}
                  />
                </Form.Field>
              }


              {/* Alternate Purpose column in place of Row submission status column */}
              {shouldRenderSubmissionColumnAlternatePurpose && !hasEntireFormBeenSubmitted &&
                <Form.Field
                  width={1}
                >
                  {index === 0 && <label htmlFor="avoids-console-error">{submissionColumnAlternatePurposeColumnTitle}</label>}
                  {/* This wrapping div is very important for visual */}
                  {/* alignment of the table. See the CSS file the */}
                  {/* className is defined in for details. */}
                  <div className="vertically-centered-field">
                    <SubmissionColumnAlternatePurposeRowRenderer
                      rowIndex={index}
                      {...props}
                    />
                  </div>
                </Form.Field>
              }
            </Form.Group>
            {/* <div>This is where keg collars choices for individual customers will go</div> */}
          </div>
        )
      })}


      {/* Add row button */}
      {abilityToAddAndDeleteRows &&
        <AddRowButton
          fieldArrayName={fieldArrayName}
          text={addRowButtonText}
          disabled={hasEntireFormBeenSubmitted}
          {...props}
        />
      }

      {arbitraryContentAfterAddRowButton || null}
    </div>
  )
}


const AddRowButton = props => {
  const {
    onAddRow,
    disabled,
    text,
  } = props
  const { t: translate } = useTranslation('common')
  return (
    <Button
      type="button" // CODE_COMMENTS_260
      icon
      labelPosition='left'
      color="blue"
      onClick={() => { onAddRow(props) }
      }
      size="mini"
      style={{
        marginTop: '5px', // not too close to the last row
        marginRight: '10px', // not too close to the arbitrary content to the right of the add rows button
      }}
      disabled={disabled}
    >
      <Icon name='plus' />
      {translate(text)}
    </Button>
  )
}


const DeleteRowButton = props => {
  const {
    rowIndex,
    firstRowCannotBeDeleted,
    onDeleteRow,
  } = props
  const actualButton = (
    <Button
      // compact
      type="button" // CODE_COMMENTS_260
      title="Remove row"
      // set onClick to do nothing on the first line just in case
      // our hidden visibility style doesn't render in some odd browser
      onClick={
        firstRowCannotBeDeleted && rowIndex === 0
          ? () => {}
          : () => { onDeleteRow(props) }
      }
      style={firstRowCannotBeDeleted && rowIndex === 0 ? { visibility: 'hidden' } : { verticalAlign: 'middle' }}
      size="mini"
      className="reduced-padding-button remove-row-button"
    >
      -
    </Button>
  )
  return (
    <div className="vertically-centered-field">
      {actualButton}
    </div>
  )
}
