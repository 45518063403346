/* eslint-disable max-len */

import React, { Fragment, useReducer, useImperativeHandle, useEffect } from 'react'
import { Header, Grid, Loader, Form, Checkbox, Icon, Accordion, Button } from 'semantic-ui-react'
import produce from 'immer'
import {
  Link,
} from 'react-router-dom'

import chunk_ from 'lodash/chunk'
import pickBy_ from 'lodash/pickBy'
import intersection_ from 'lodash/intersection'
import { useTranslation } from 'react-i18next'

import flow_ from 'lodash/fp/flow'
import keysFp_ from 'lodash/fp/keys'
import reduceFp_ from 'lodash/fp/reduce'


import InformationalPopup from '../../../../../../../common-components/InformationalPopup'
import RevealContactInfo from '../../../../../../../common-components/RevealContactInfo'

import {
  FETCH_EDIT_PERMISSIONS_OF_ONE_USER,
} from '../../../../../../../redux/actions/actionTypes'
import createAction from '../../../../../../../redux/actions/createAction'

import {
  DISPLAYED_PERMISSIONS_DEFS_KEY_ROOT_CUSTOMER_OF_USER,
  DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF,
  MANAGE_USERS_PERMISSIONS_CHOICE_TO_HUMAN_READABLE_LABEL_MAP,
} from '../../../../../../../constants/permissions'

import {
  CUSTOMER_TYPES_MASTER,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../../../../../constants'

import {
  createDisplayedManageUsersPermissionsDefinitionsOfOneUser,
  createEditUserPermissionsFormFieldName,
  parseEditUserPermissionsFormFieldName,
} from '../../../../../util'

import {
  getWhichManageUserPermissionsChoicesAreASubsetOfThisOne,
  getWhichManageUserPermissionsChoicesAreASupersetOfThisOne,
} from '../../../../../../../utils/permissions'

import {
  isTruthyAndNonEmpty,
  getAreFormValuesSameAsInitialValues,
  formatArrayOfStringsAsNaturalLanguagePhrase,
} from '../../../../../../../utils'


import './styles.css'

// out of 16. If a user has per-customer permissions, the own-user permissions
// will only have 1 column, the other being taken up by the per-customer
// permissions, and we want the per-customer permissions to be a little wider
// than the own-user permissions
const PER_CUSTOMER_PERMISSIONS_GRID_COLUMN_WIDTH = 9

const CHANGE_FORM_VALUE = 'CHANGE_FORM_VALUE'
const RESET_FORM_VALUES_TO_INITIAL_VALUES = 'RESET_FORM_VALUES_TO_INITIAL_VALUES'

const FORM_VALUES = 'FORM_VALUES'

const FIELD_NAMES_TO_PROGRAMMATICALLY_CHECK_OR_UNCHECK = 'FIELD_NAMES_TO_PROGRAMMATICALLY_CHECK_OR_UNCHECK'
const MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM = 'MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM'

/* eslint-disable no-param-reassign, no-useless-return, prefer-const, consistent-return */
const formValuesReducer = produce((formValuesReducerSlice, action) => {
  switch (action.type) {
    case CHANGE_FORM_VALUE: {
      let { fieldName } = action.payload
      // check or uncheck the value
      const previousValue = formValuesReducerSlice[FORM_VALUES][fieldName]
      formValuesReducerSlice[FORM_VALUES][fieldName] = !previousValue
      formValuesReducerSlice[MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM] = 'Hello!'
      // programmatically check or uncheck any dependent values
      const {
        [FIELD_NAMES_TO_PROGRAMMATICALLY_CHECK_OR_UNCHECK]: fieldNamesToProgrammaticallyCheckOrUncheck,
        [MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM]: messageForProgrammaticChangingOfForm,
      } = determineFieldNamesToProgrammaticallyCheckOrUncheck({
        ...action.payload,
        isChecked: !previousValue,
        formValues: formValuesReducerSlice[FORM_VALUES],
      })
      fieldNamesToProgrammaticallyCheckOrUncheck.forEach(fieldName_ => {
        formValuesReducerSlice[FORM_VALUES][fieldName_] = !previousValue
      })
      formValuesReducerSlice[MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM] = messageForProgrammaticChangingOfForm
      return
    }
    case RESET_FORM_VALUES_TO_INITIAL_VALUES: {
      const {
        userObject,
        permissionsDefinitions,
      } = action.payload
      return getInitialValues({
        userObject,
        permissionsDefinitions,
      })
    }
    default: { return }
  }
})
/* eslint-enable no-param-reassign, no-useless-return, prefer-const, consistent-return */


const TOGGLE_INDIVIDUAL_ACCORDION_PANEL = 'TOGGLE_INDIVIDUAL_ACCORDION_PANEL'
const EXPAND_ALL_ACCORDION_PANELS = 'EXPAND_ALL_ACCORDION_PANELS'
const COLLAPSE_ALL_ACCORDION_PANELS = 'COLLAPSE_ALL_ACCORDION_PANELS'

/* eslint-disable no-param-reassign, no-useless-return, prefer-const, consistent-return */
const perCustomerPermissionsAccordionPanelsActiveReducer = produce((values, action) => {
  switch (action.type) {
    case TOGGLE_INDIVIDUAL_ACCORDION_PANEL: {
      const { index } = action.payload
      values[index] = !values[index]
      return
    }
    case EXPAND_ALL_ACCORDION_PANELS: {
      return values.map(() => true)
    }
    case COLLAPSE_ALL_ACCORDION_PANELS: {
      return values.map(() => false)
    }
    default: { return }
  }
})
/* eslint-enable no-param-reassign, no-useless-return, prefer-const, consistent-return */

export default props => {
  const {
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entirePermissionsSlice,
    customerId,
    userObject,
    rootCustomerType,
    hasConbrwChildIds,
    hasFetchBeenAttemptedAggregateConbrwChildren,
    isFetchingAggregateConbrwChildren,
    didFetchSucceedAggregateConbrwChildren,
    didFetchFailAggregateConbrwChildren,
    dispatch,
    refEditUserPermissionsFormHandleSubmit,
    refEditUserPermissionsFormOnCancelEditUser,
    setAreCurrentValuesOfEditUserPermissionsFormSameAsInitialValues,
    setEditUserButtonHidden,
    setIsEditUserPermissionsSubmitting,
    setDidEditUserPermissionsSubmissionSucceed,
    setDidEditUserPermissionsSubmissionFail,
  } = props
  const { t: translate } = useTranslation('common')

  // CODE_COMMENTS_259
  if (rootCustomerType === CUSTOMER_TYPES_MASTER && hasConbrwChildIds) {
    if (didFetchFailAggregateConbrwChildren) {
      setEditUserButtonHidden(true)
      return (
        <Fragment>
          <Header as="h4">{translate('Permissions')}</Header>
          <Icon name="exclamation" color="red" />
          <span className="semantic-ui-error-text-color">{translate('Failed to fetch permissions for this user.')} </span>
          <Link to="/">{translate('Return to the Dashboard')}</Link>
          <span className="semantic-ui-error-text-color">{` ${translate('and ensure that all Contract Brewer child accounts have loaded. If the problem persists, contact your rep.')} `}</span>
          <RevealContactInfo
            customerId={customerId}
            repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
            asPopup
          />
        </Fragment>
      )
    }
    if (!hasFetchBeenAttemptedAggregateConbrwChildren || isFetchingAggregateConbrwChildren) {
      setEditUserButtonHidden(true)
      return (
        <Fragment>
          <Header as="h4">{translate('Permissions')}</Header>
          <Loader active inline size="tiny" style={{ marginRight: '0.5rem' }} />
          <span>{translate('Fetching Permissions of child Contract Brewers')}...</span>
        </Fragment>
      )
    }
    if (didFetchSucceedAggregateConbrwChildren) {
      setEditUserButtonHidden(false)
    }
  } else {
    setEditUserButtonHidden(false)
  }

  const permissionsDefinitions = createDisplayedManageUsersPermissionsDefinitionsOfOneUser({
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entirePermissionsSlice,
    userIdIfNotCurrentUser: userObject.id,
  })

  const hasPerCustomerPermissions = isTruthyAndNonEmpty(permissionsDefinitions[DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF])

  const [formValuesReducerSlice, dispatchFormValues] = useReducer(
    formValuesReducer,
    getInitialValues({
      userObject,
      permissionsDefinitions,
    }),
  )
  // We create this function so that the individual form field definition
  // functions within configObj don't have to call
  // `dispatchFormValues(createAction(CHANGE_FORM_VALUE, { fieldName }))`,
  // they can just call `changeFormValue({ fieldName })`
  const changeFormValue = props_ => {
    dispatchFormValues(createAction(
      CHANGE_FORM_VALUE,
      props_,
    ))
  }

  const [
    perCustomerPermissionsAccordionPanelsActiveReducerSlice,
    dispatchPerCustomerPermissionsAccordionPanelsActive,
  ] = useReducer(
    perCustomerPermissionsAccordionPanelsActiveReducer,
    hasPerCustomerPermissions
      ? Object.keys(permissionsDefinitions[DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF]).map(() => false)
      : [],
  )

  // Every time a user changes the form values, do these things:
  // 1) determine whether current values are the same as initial values;
  // 2) determine whether to show a "The permission you checked depends on these
  //    other permissions, so they were also checked" message.
  useEffect(
    () => {
      const initialValues = getInitialValues({
        userObject,
        permissionsDefinitions,
      })
      setAreCurrentValuesOfEditUserPermissionsFormSameAsInitialValues(
        getAreFormValuesSameAsInitialValues(formValuesReducerSlice[FORM_VALUES], initialValues[FORM_VALUES]),
      )
    },
    [
      userObject,
      permissionsDefinitions,
      formValuesReducerSlice,
      setAreCurrentValuesOfEditUserPermissionsFormSameAsInitialValues,
    ],
  )

  const allProps = {
    ...props,
    permissionsDefinitions,
    hasPerCustomerPermissions,
    formValues: formValuesReducerSlice[FORM_VALUES],
    changeFormValue,
    perCustomerPermissionsAccordionPanelsActiveReducerSlice,
    dispatchPerCustomerPermissionsAccordionPanelsActive,
  }

  // CODE_COMMENTS_258
  useImperativeHandle(
    refEditUserPermissionsFormHandleSubmit,
    () => ({
      handleSubmit: () => {
        dispatch(createAction(
          FETCH_EDIT_PERMISSIONS_OF_ONE_USER,
          {
            customerId,
            rootCustomerType,
            userObject,
            formValues: formValuesReducerSlice[FORM_VALUES],
            entireCustomersSlice,
            entireContractsSlice,
            entireRelationshipsSlice,
            entireParentChildLinksSlice,
            entireCurrentUserSlice,
            entireUsersSlice,
            entirePermissionsSlice,
            setIsEditUserPermissionsSubmitting,
            setDidEditUserPermissionsSubmissionSucceed,
            setDidEditUserPermissionsSubmissionFail,
          },
        ))
      },
    }),
    [
      dispatch,
      customerId,
      rootCustomerType,
      userObject,
      formValuesReducerSlice,
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entireParentChildLinksSlice,
      entireCurrentUserSlice,
      entireUsersSlice,
      entirePermissionsSlice,
      setIsEditUserPermissionsSubmitting,
      setDidEditUserPermissionsSubmissionSucceed,
      setDidEditUserPermissionsSubmissionFail,
    ],
  )

  // CODE_COMMENTS_258
  useImperativeHandle(
    refEditUserPermissionsFormOnCancelEditUser,
    () => ({
      onCancelEditUser: () => {
        dispatchFormValues(createAction(
          RESET_FORM_VALUES_TO_INITIAL_VALUES,
          { userObject, permissionsDefinitions },
        ))
      },
    }),
    [userObject, permissionsDefinitions],
  )

  return (
    <Fragment>
      <Header as="h4">{translate('Permissions')}</Header>
      {/* Even when info is just being displayed, it's wrapped in a form, which
          doesn't hurt anything. */}
      <Form>
        <Grid columns={2}>
          <Grid.Row>
            <OwnUserPermissions {...allProps} />
            {hasPerCustomerPermissions && <PerCustomerUserPermissions {...allProps} />}
          </Grid.Row>
        </Grid>
      </Form>
      {
        formValuesReducerSlice[MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM] &&
        <span
          style={{
            color: '#276f86',
            fontStyle: 'italic',
          }}
        >
          {formValuesReducerSlice[MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM]}
        </span>
      }
    </Fragment>
  )
}


/*
 * *****************************************************************************
 * Additional Components
 * *****************************************************************************
*/

const OwnUserPermissions = props => {
  const {
    permissionsDefinitions,
    hasPerCustomerPermissions,
  } = props
  const numColumns = hasPerCustomerPermissions ? 1 : 2
  const columnWidth = hasPerCustomerPermissions ? 16 - PER_CUSTOMER_PERMISSIONS_GRID_COLUMN_WIDTH : 8
  const permissionsDefsOfRootCustomer = permissionsDefinitions[DISPLAYED_PERMISSIONS_DEFS_KEY_ROOT_CUSTOMER_OF_USER]
  const chunkedPermissionsDefsOfRootCustomer = chunk_(
    permissionsDefsOfRootCustomer,
    Math.ceil(permissionsDefsOfRootCustomer.length / numColumns),
  )
  return (
    <Fragment>
      {[...Array(numColumns).keys()].map(gridColumnIndex => (
        <Grid.Column key={gridColumnIndex} width={columnWidth}>
          {chunkedPermissionsDefsOfRootCustomer[gridColumnIndex].map(permissionDefinition => (
            <EditUserPermissionsCheckboxFormField
              {...props}
              key={permissionDefinition.label}
              permissionDefinition={permissionDefinition}
            />
          ))}
        </Grid.Column>
      ))}
    </Fragment>
  )
}


const PerCustomerUserPermissions = props => {
  const {
    permissionsDefinitions,
    perCustomerPermissionsAccordionPanelsActiveReducerSlice,
    dispatchPerCustomerPermissionsAccordionPanelsActive,
  } = props
  const { t: translate } = useTranslation('common')
  const perCustomerCheckboxDefinitions = permissionsDefinitions[DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF]
  return (
    <Grid.Column key="perCustomerUsers" width={PER_CUSTOMER_PERMISSIONS_GRID_COLUMN_WIDTH}>
      <Button.Group fluid size="tiny">
        <Button className="reduced-padding-button" onClick={() => { dispatchPerCustomerPermissionsAccordionPanelsActive(createAction(EXPAND_ALL_ACCORDION_PANELS)) }}>
          {translate('Expand All')}
        </Button>
        <Button.Or />
        <Button className="reduced-padding-button" onClick={() => { dispatchPerCustomerPermissionsAccordionPanelsActive(createAction(COLLAPSE_ALL_ACCORDION_PANELS)) }}>
          {translate('Collapse All')}
        </Button>
      </Button.Group>
      <Accordion
        exclusive={false}
        fluid
        styled
      >
        {Object.keys(perCustomerCheckboxDefinitions).map((contracteeCustomerId, index) => (
          <Fragment key={contracteeCustomerId}>
            <Accordion.Title
              active={perCustomerPermissionsAccordionPanelsActiveReducerSlice[index]}
              onClick={() => { dispatchPerCustomerPermissionsAccordionPanelsActive(createAction(TOGGLE_INDIVIDUAL_ACCORDION_PANEL, { index })) }}
            >
              <Icon name='dropdown' />
              {perCustomerCheckboxDefinitions[contracteeCustomerId].name}
            </Accordion.Title>
            <Accordion.Content
              key={contracteeCustomerId}
              active={perCustomerPermissionsAccordionPanelsActiveReducerSlice[index]}
            >
              {perCustomerCheckboxDefinitions[contracteeCustomerId].manageUsersPermissionsChoices.map(permissionDefinition => (
                <EditUserPermissionsCheckboxFormField
                  {...props}
                  key={permissionDefinition.label}
                  permissionDefinition={permissionDefinition}
                  customerId={contracteeCustomerId}
                />
              ))}
            </Accordion.Content>
          </Fragment>
        ))}
      </Accordion>
    </Grid.Column>
  )
}


const EditUserPermissionsCheckboxFormField = props => {
  const {
    permissionDefinition,
    customerId,
    inEditMode,
    formValues,
    changeFormValue,
    isEditSubmitting,
  } = props
  const fieldName = createEditUserPermissionsFormFieldName(
    customerId,
    permissionDefinition.manageUsersPermissionsChoice,
  )
  return (
    <Form.Field
      key={permissionDefinition.label}
      // All Form.Field components have a marginBottom: 1rem by default, but
      // this provides too much space, we want the checkboxes to be super close
      // to one another.
      style={{ marginBottom: 0 }}
    >
      <div key={permissionDefinition.label}>
        {inEditMode
          ? (
            <Checkbox
              onChange={() => { changeFormValue({ ...props, fieldName }) }}
              checked={formValues[fieldName]}
              disabled={isEditSubmitting}
              name={fieldName}
              label={permissionDefinition.label}
              className="user-permission-checkbox-field"
            />
          )
          : (
            <Fragment>
              <Icon
                size="large"
                name={permissionDefinition.hasPermission ? 'check circle' : 'close'}
                color={permissionDefinition.hasPermission ? 'green' : 'grey'}
              />
              <span>{permissionDefinition.label}</span>
            </Fragment>
          )
        }
        {permissionDefinition.informationalPopupContent &&
          <InformationalPopup
            content={permissionDefinition.informationalPopupContent}
          />
        }
      </div>
    </Form.Field>
  )
}

/*
 * *****************************************************************************
 * Initial Values Helper Functions
 * *****************************************************************************
*/

function getInitialValues(props) {
  return {
    [FORM_VALUES]: getInitialValuesOfForm(props),
    [MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM]: null,
  }
}

function getInitialValuesOfForm({
  userObject,
  permissionsDefinitions,
}) {
  const rootCustomerInitialValues = getInitialValuesForSingleCustomer({
    permissionsDefinitionsOfSingleCustomer: permissionsDefinitions[DISPLAYED_PERMISSIONS_DEFS_KEY_ROOT_CUSTOMER_OF_USER],
    customerId: userObject.rootCustomerId,
  })

  const operateForPermissionsDefs = permissionsDefinitions[DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF]

  const initialValuesOfCustomersUserCanOperateOnBehalfOf = flow_(
    keysFp_,
    reduceFp_(
      (acc, customerIdUserCanOperateFor) => {
        const initialValuesForThisCustomerUserCanOperateFor =
          getInitialValuesForSingleCustomer({
            permissionsDefinitionsOfSingleCustomer:
              operateForPermissionsDefs[customerIdUserCanOperateFor].manageUsersPermissionsChoices,
            customerId: customerIdUserCanOperateFor,
          })
        return { ...acc, ...initialValuesForThisCustomerUserCanOperateFor }
      },
      {},
    ),
  )(operateForPermissionsDefs)
  return {
    ...rootCustomerInitialValues,
    ...initialValuesOfCustomersUserCanOperateOnBehalfOf,
  }
}

function getInitialValuesForSingleCustomer({
  permissionsDefinitionsOfSingleCustomer,
  customerId,
}) {
  return permissionsDefinitionsOfSingleCustomer.reduce(
    (acc, def) => ({
      ...acc,
      [createEditUserPermissionsFormFieldName(customerId, def.manageUsersPermissionsChoice)]:
        def.hasPermission,
    }),
    {},
  )
}


/*
 * *****************************************************************************
 * Helper Functions for Message for Programmatic changing of form
 * *****************************************************************************
*/

// Returns A 2-item object:
// {
//    [FIELD_NAMES_TO_PROGRAMMATICALLY_CHECK_OR_UNCHECK]: [
//      'View Shipment History',
//    ],
//    [MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM]: 'Acknowledge Inbound Shipments depends on View Shipment History, so it was checked too.',
// }
function determineFieldNamesToProgrammaticallyCheckOrUncheck({
  permissionDefinition,
  isChecked, // true if the user checked the box, false if they unchecked it
  customerId,
  formValues,
}) {
  const manageUsersPermissionsChoice = permissionDefinition.manageUsersPermissionsChoice
  // either the root customer or the operate-for customer in the "Per-Customer
  // Permissions" section of the form
  const formValuesConcerningOnlyThisCustomer = pickBy_(
    formValues,
    (value, key) => {
      const { rootCustomerIdOrOperateForCustomerId } = parseEditUserPermissionsFormFieldName(key)
      return rootCustomerIdOrOperateForCustomerId === customerId
    },
  )
  const fieldNamesConcerningOnlyThisCustomer = Object.keys(formValuesConcerningOnlyThisCustomer)
  const manageUserPermissionsChoicesConcerningOnlyThisCustomer = fieldNamesConcerningOnlyThisCustomer.map(
    fieldName => parseEditUserPermissionsFormFieldName(fieldName).manageUsersPermissionsChoice,
  )

  if (isChecked) {
    const permissionsChoicesSubsets = getWhichManageUserPermissionsChoicesAreASubsetOfThisOne(
      manageUsersPermissionsChoice,
    )
    const permissionsChoicesSubsetsThatArePartOfThisCustomersCheckboxFields = intersection_(
      permissionsChoicesSubsets,
      manageUserPermissionsChoicesConcerningOnlyThisCustomer,
    )

    const fieldNamesThatMustBeCheckedIfThisOneIsChecked = fieldNamesConcerningOnlyThisCustomer.filter(fieldName => {
      const {
        manageUsersPermissionsChoice: adminUsersPermissionsChoice,
      } = parseEditUserPermissionsFormFieldName(fieldName)
      return permissionsChoicesSubsetsThatArePartOfThisCustomersCheckboxFields.includes(
        adminUsersPermissionsChoice,
      )
    })
    const fieldNamesToProgrammaticallyChangeFromUncheckedToChecked =
      fieldNamesThatMustBeCheckedIfThisOneIsChecked.filter(
        fieldName => !formValues[fieldName],
      )

    let message
    if (isTruthyAndNonEmpty(fieldNamesToProgrammaticallyChangeFromUncheckedToChecked)) {
      const manageUserPermissionsChoicesToProgrammaticallyChangeFromUncheckedToChecked =
        fieldNamesToProgrammaticallyChangeFromUncheckedToChecked.map(
          n => parseEditUserPermissionsFormFieldName(n).manageUsersPermissionsChoice,
        )
      message = createMessageForProgramaticChangingOfForm({
        thesePermissions: [manageUsersPermissionsChoice],
        dependOnThesePermissions: manageUserPermissionsChoicesToProgrammaticallyChangeFromUncheckedToChecked,
      })
    } else {
      message = null
    }

    return {
      [FIELD_NAMES_TO_PROGRAMMATICALLY_CHECK_OR_UNCHECK]: fieldNamesToProgrammaticallyChangeFromUncheckedToChecked,
      [MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM]: message,
    }
  }

  // the user unchecked a box
  const permissionsChoicesSupersets = getWhichManageUserPermissionsChoicesAreASupersetOfThisOne(
    manageUsersPermissionsChoice,
  )
  const permissionsChoicesSupersetsThatArePartOfThisCustomersCheckboxFields = intersection_(
    permissionsChoicesSupersets,
    manageUserPermissionsChoicesConcerningOnlyThisCustomer,
  )

  const fieldNamesThatMustBeUncheckedIfThisOneIsUnchecked =
    fieldNamesConcerningOnlyThisCustomer.filter(fieldName => {
      const {
        manageUsersPermissionsChoice: adminUsersPermissionsChoice,
      } = parseEditUserPermissionsFormFieldName(fieldName)
      return permissionsChoicesSupersetsThatArePartOfThisCustomersCheckboxFields.includes(
        adminUsersPermissionsChoice,
      )
    })
  const fieldNamesToProgrammaticallyChangeFromCheckedToUnchecked =
    fieldNamesThatMustBeUncheckedIfThisOneIsUnchecked.filter(
      fieldName => Boolean(formValues[fieldName]),
    )

  let message
  if (isTruthyAndNonEmpty(fieldNamesToProgrammaticallyChangeFromCheckedToUnchecked)) {
    const manageUserPermissionsChoicesToProgrammaticallyChangeFromCheckedToUnchecked =
      fieldNamesToProgrammaticallyChangeFromCheckedToUnchecked.map(
        n => parseEditUserPermissionsFormFieldName(n).manageUsersPermissionsChoice,
      )
    message = createMessageForProgramaticChangingOfForm({
      thesePermissions: manageUserPermissionsChoicesToProgrammaticallyChangeFromCheckedToUnchecked,
      dependOnThesePermissions: [manageUsersPermissionsChoice],
      uncheckThePermissionsRatherThanCheckThem: true,
    })
  } else {
    message = null
  }

  return {
    [FIELD_NAMES_TO_PROGRAMMATICALLY_CHECK_OR_UNCHECK]: fieldNamesToProgrammaticallyChangeFromCheckedToUnchecked,
    [MESSAGE_FOR_PROGRAMMATIC_CHANGING_OF_FORM]: message,
  }
}


function createMessageForProgramaticChangingOfForm({
  thesePermissions,
  dependOnThesePermissions,
  uncheckThePermissionsRatherThanCheckThem,
}) {
  const thesePermissionsStrings = thesePermissions.map(
    p => MANAGE_USERS_PERMISSIONS_CHOICE_TO_HUMAN_READABLE_LABEL_MAP[p],
  )
  const dependOnThesePermissionsStrings = dependOnThesePermissions.map(
    p => MANAGE_USERS_PERMISSIONS_CHOICE_TO_HUMAN_READABLE_LABEL_MAP[p],
  )

  const thesePermissionsStringsNaturalLanguage =
    formatArrayOfStringsAsNaturalLanguagePhrase(thesePermissionsStrings)
  const dependOnThesePermissionsStringsNaturalLanguage =
    formatArrayOfStringsAsNaturalLanguagePhrase(dependOnThesePermissionsStrings)

  return `${thesePermissionsStringsNaturalLanguage} depend${thesePermissions.length > 1 ? '' : 's'} on ${dependOnThesePermissionsStringsNaturalLanguage}, so ${thesePermissions.length > 1 ? 'they were' : 'it was'} ${uncheckThePermissionsRatherThanCheckThem ? 'un' : ''}checked too.`
}
