/* eslint-disable max-len */

import reduce_ from 'lodash/reduce'


import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../../redux/selectors/rewrite/contracts'
import {
  getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedTo,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'

import {
    CUSTOMER_TYPES_BREWER,
    CUSTOMER_TYPES_CONTRACT_BREWER,
    CUSTOMER_TYPES_DISTRIBUTOR, CUSTOMER_TYPES_MAINTENANCE_FACILITY,
    CUSTOMER_TYPES_WAREHOUSE,
} from '../../../../../constants'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  isTruthyAndNonEmpty,
} from '../../../../../utils'


/*
 * *****************************************************************************
 * Exported functions
 * *****************************************************************************
*/

// This function takes an array of inventoryReportObjects containing any reports
// that a customer of a specific type could possibly receive and converts them
// into an object suitable for storage in the Redux store. The returned object
// has reduxIdentifiers as keys (see the docstring of the
// createCustIdAndOptionalConbrwCustIdReduxIdentifier utils function for
// details) and an array of inventoryReportObjects as values, By "reports that a
// customer of a specific type could possibly receive", we mean the following:

// * CustomerType of BRW: all default-contract reports, conbrw-contract reports,
//   and pub reports;
// * customerType of CONBRW: all CBMST reports and conbrw-contract reports of
//   contractees
// * customerType of DIST: all DIST reports (no contract associated with these)

// Because this function is so flexible, it can be used by both the saga that
// fetches inventory reports for the history table and the saga that submits
// "Report Inventory" forms (the return value of the API call is an array of
// inventoryReportObjects that need to be saved to the Redux store).
//
// This function is very similar to
// src/redux/sagas/forms/histories/inventoryHistory.js ->
// organizeInventoryHistoryItemsToSaveInReduxStore(), but it's different enough
// that the two functions shouldn't be combined.
export function formatArrayOfInventoryReportsToReduxStoreStructure(args) {
  const {
    state,
    customerIdOfCustomerCurrentlyBeingOperatedFor,
    // An array of inventoryReportObjects as formatted by the backend
    // eslint-disable-next-line no-unused-vars
    inventoryReportObjects,
  } = args
  const customerTypeOfCustomerCurrentlyBeingOperatedFor = getCustomerProp(
    state,
    customerIdOfCustomerCurrentlyBeingOperatedFor,
    'customerType',
  )

  const funcsMap = {
    [CUSTOMER_TYPES_BREWER]: formatArrayOfInventoryReportsToBeSavedToReduxStoreBRW,
    [CUSTOMER_TYPES_CONTRACT_BREWER]: formatArrayOfInventoryReportsToBeSavedToReduxStoreCONBRW,
    [CUSTOMER_TYPES_DISTRIBUTOR]: formatArrayOfInventoryReportsToBeSavedToReduxStoreDIST,
    [CUSTOMER_TYPES_WAREHOUSE]: formatArrayOfInventoryReportsToBeSavedToReduxStoreDIST,
    [CUSTOMER_TYPES_MAINTENANCE_FACILITY]: formatArrayOfInventoryReportsToBeSavedToReduxStoreDIST,
  }
  const toReturn = funcsMap[customerTypeOfCustomerCurrentlyBeingOperatedFor](args)

  return toReturn
}


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

function formatArrayOfInventoryReportsToBeSavedToReduxStoreBRW({
  state,
  customerIdOfCustomerCurrentlyBeingOperatedFor,
  inventoryReportObjects,
}) {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)

  // First, separate BRW inventory reports from PUB inventory reports
  const brwInventoryReports = []
  const pubInventoryReports = []
  inventoryReportObjects.forEach(o => {
    if (o.customerId === customerIdOfCustomerCurrentlyBeingOperatedFor) {
      brwInventoryReports.push(o)
    } else {
      pubInventoryReports.push(o)
    }
  })

  // Next, separate default-brewing-contract-location inventory reports from
  // contract-brewing-contract-location inventory reports.
  const defaultBrewingContractLocationInventoryReportObjs = []
  const contractBrewingContractLocationInventoryReportObjs = []
  // The web app doesn't have access to all the Brewer's default contract IDs
  // past and present (remember, contracts renew once every ~1-5 years, and each
  // time they do, a different contractId is issued; the web app only has access
  // to the Brewer's _current_ default contract ID), but it does have access to
  // all its contract-brewing contract IDs past and present.
  const contractIdsAndConbrwCustomerIdsOfAllConbrwsRelatedTo =
    getMultipleRelationshipPropsAndCustomerPropsOfAllRelatedTo({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId: customerIdOfCustomerCurrentlyBeingOperatedFor,
      propDefs: [
        { prop: 'id', residesIn: 'customerObj' },
        { prop: 'sourcePpfContract', residesIn: 'relationshipObj' },
      ],
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
    })
  const conbrwContractIds = contractIdsAndConbrwCustomerIdsOfAllConbrwsRelatedTo.map(o => o.sourcePpfContract)
  brwInventoryReports.forEach(o => {
    if (conbrwContractIds.includes(o.contractId)) {
      contractBrewingContractLocationInventoryReportObjs.push(o)
    } else {
      defaultBrewingContractLocationInventoryReportObjs.push(o)
    }
  })

  // Next, group contract-brewing-contract inventory reports by contract ID
  const conbrwInventoryReportObjsByContractId =
    contractBrewingContractLocationInventoryReportObjs.reduce(
      (acc, o) => {
        const runningArrayForThisContractId = acc[o.contractId] || []
        const newRunningArray = [...runningArrayForThisContractId, o]
        return {
          ...acc,
          [o.contractId]: newRunningArray,
        }
      },
      {},
    )
  // Next, format contract-brewing-contract inventory reports into their final
  // structure as they'll be put into the Redux store
  const finalConbrwContractInventoryReportObjs = reduce_(
    conbrwInventoryReportObjsByContractId,
    (acc, arrayOfInventoryReportObjs, contractId) => {
      const conbrwCustIdAssociatedWithThisContract =
        contractIdsAndConbrwCustomerIdsOfAllConbrwsRelatedTo.find(o => o.sourcePpfContract === contractId).id
      const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        customerIdOfCustomerCurrentlyBeingOperatedFor,
        conbrwCustIdAssociatedWithThisContract,
      )
      const runningArrayOfInventoryReportsForThisReduxIdentifier = acc[reduxIdentifier] || []
      const newRunningArray = [
        ...runningArrayOfInventoryReportsForThisReduxIdentifier,
        ...arrayOfInventoryReportObjs,
      ]
      return {
        ...acc,
        [reduxIdentifier]: newRunningArray,
      }
    },
    {},
  )

  // Next, format pub inventory reports into their final structure as they'll be
  // put into the Redux store
  const finalPubInventoryReportObjs = pubInventoryReports.reduce(
    (acc, o) => {
      const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        o.customerId,
      )
      const runningArrayOfInventoryReportsForThisReduxIdentifier = acc[reduxIdentifier] || []
      const newRunningArray = [...runningArrayOfInventoryReportsForThisReduxIdentifier, o]
      return {
        ...acc,
        [reduxIdentifier]: newRunningArray,
      }
    },
    {},
  )

  const toReturn = {
    ...(
      isTruthyAndNonEmpty(defaultBrewingContractLocationInventoryReportObjs)
        ? {
          [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerIdOfCustomerCurrentlyBeingOperatedFor)]:
            defaultBrewingContractLocationInventoryReportObjs,
        }
        : {}
    ),
    ...finalConbrwContractInventoryReportObjs,
    ...finalPubInventoryReportObjs,
  }
  return toReturn
}


function formatArrayOfInventoryReportsToBeSavedToReduxStoreCONBRW({
  customerIdOfCustomerCurrentlyBeingOperatedFor,
  inventoryReportObjects,
}) {
  // First, separate CBMST inventory reports from
  // contract-brewing-contract-location inventory reports.
  const cbmstInventoryReportObjs = []
  const contractBrewingContractLocationInventoryReportObjs = []

  inventoryReportObjects.forEach(o => {
    if (o.customerId === customerIdOfCustomerCurrentlyBeingOperatedFor) {
      cbmstInventoryReportObjs.push(o)
    } else {
      contractBrewingContractLocationInventoryReportObjs.push(o)
    }
  })

  // Next, group contract-brewing-contract inventory reports by contractee customerId
  const contracteeInventoryReportObjs = contractBrewingContractLocationInventoryReportObjs.reduce(
    (acc, o) => {
      const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        o.customerId,
        customerIdOfCustomerCurrentlyBeingOperatedFor,
      )

      const runningArrayOfInventoryReportsForThisReduxIdentifier = acc[reduxIdentifier]
      const newRunningArray = [
        ...(runningArrayOfInventoryReportsForThisReduxIdentifier || []),
        o,
      ]
      return {
        ...acc,
        [reduxIdentifier]: newRunningArray,
      }
    },
    {},
  )

  return {
    ...(
      isTruthyAndNonEmpty(cbmstInventoryReportObjs)
        ? {
          [createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerIdOfCustomerCurrentlyBeingOperatedFor)]:
            cbmstInventoryReportObjs,
        }
        : {}
    ),
    ...contracteeInventoryReportObjs,
  }
}


function formatArrayOfInventoryReportsToBeSavedToReduxStoreDIST({
  customerIdOfCustomerCurrentlyBeingOperatedFor,
  inventoryReportObjects,
}) {
  // DISTs only have one type of inventory report, and their Redux Identifier is
  // simply the DIST's customerId.
  const reduxIdentifier = createCustIdAndOptionalConbrwCustIdReduxIdentifier(
    customerIdOfCustomerCurrentlyBeingOperatedFor,
  )
  return {
    [reduxIdentifier]: inventoryReportObjects,
  }
}
