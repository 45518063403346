import { connect } from 'react-redux'

import FetchingOrFailed from '../components/FetchingOrFailed'

import customerRepsFetchStatusSelectors from '../../../redux/selectors/fetchStatuses/customers/customerRepresentatives'
import universalCustomerRepsFetchStatusSelectors from '../../../redux/selectors/fetchStatuses/universalCustomerRepresentatives'

import {
  getIsUniversalCustomerRepType,
} from '../../../utils'


const mapStateToProps = (state, { repId, repType }) => {
  const isUniversalCustomerRepType = getIsUniversalCustomerRepType(repType) // CODE_COMMENTS_241
  const { getFetchStatuses: getCustomerRepsFetchStatuses } = customerRepsFetchStatusSelectors
  const { getFetchStatuses: getUniversalCustomerRepsFetchStatuses } = universalCustomerRepsFetchStatusSelectors
  const fetchStatuses = isUniversalCustomerRepType
    ? getUniversalCustomerRepsFetchStatuses(state, repType)
    : getCustomerRepsFetchStatuses(state, repId)

  return ({
    ...fetchStatuses,
  })
}

export default connect(
  mapStateToProps,
)(FetchingOrFailed)
