import React, { Fragment } from 'react'
import { Card, Icon, Label } from 'semantic-ui-react'

import { useTranslation } from 'react-i18next'
import {
  CUSTOMER_REPS_HUMAN_READABLE_NAMES,
} from '../../../constants'

import {
  formatPhoneNumber,
} from '../../../utils'

export default ({
  repType,
  // repTitle is for other components that import just this file: if you don't
  // include repType, this component will replace the human-readable rep type
  // with the text of repTitle.
  repTitle,
  name,
  phone,
  email,
  // The only reason we define repId and dispatch here is so that they don't end
  // up as props on the <Card> component, which, because they become actual DOM
  // props, throws a React warning in the console during development.
  repId,
  dispatch,
  customized,
  customSubjectText,
  customBodyText,
  ...rest
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <Card
      // Without this 'width' css prop, the "Click for starter text" label would
      // be pushed onto a new line instead of appearing to the left of the email
      // address. Setting width to an absolute value is a bit hacky, but it's
      // necessary because the Semantic UI React <Card> component has no ability
      // to dynamically widen to fit its contents.
      style={{ width: (customSubjectText || customBodyText) ? '330px' : null }}
      {...rest}
    >
      <Card.Content>
        <Card.Header>
          <span className="microstar-blue">
            {translate(repTitle || CUSTOMER_REPS_HUMAN_READABLE_NAMES[repType])}
          </span>
        </Card.Header>
        <Card.Description>
          {/* There will be no name if we want to show the general MicroStar info */}
          {name && <p><Icon name="user outline"/><span style={{ fontWeight: 'bold' }}>{name}</span></p>}
          {email && (
            <Fragment>
              <p style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: `${(customSubjectText || customBodyText) ? 'wrap' : 'unset'}`,
              }}
              >
                <Icon name="mail outline"/>
                <a
                  href={`mailto:${email}?subject=${encodeURI(customSubjectText) || ''}&body=${encodeURI(customBodyText) || ''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    width: 'calc(100% - 20px)',
                  }}
                >
                  {email}
                </a>
                {(customSubjectText || customBodyText) && (
                  <Label
                    basic
                    color='teal'
                    pointing='above'
                  >
                    {translate('Click for template email text')}
                  </Label>
                )}
              </p>
            </Fragment>
          )}
          {phone && <p><Icon name="phone"/>{formatPhoneNumber(phone)}</p>}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}
