import React from 'react'
import { Message } from 'semantic-ui-react'

import ContactCard from '../../../common-components/ContactCard'


export default () => (
  <Message compact style={{ width: '400px' }}>
    <p>{"We're sorry, you haven't been assigned any personalized representatives. Please contact our general customer support line and we'll get you set up with some personalized reps right away."}</p>
    <ContactCard />
  </Message>
)
