import {
  NestedPropNotInObjectError,
} from '../../../customErrors'


export const getAreAnyItemsInACertainStatus = (
  getPropOfAllFunction,
  statusPropName,
) => (state, customerId, targetStatus) => {
  let allStatuses
  try {
    allStatuses = getPropOfAllFunction(state, customerId, statusPropName)
  } catch (e) {
    if (e instanceof NestedPropNotInObjectError) {
      return false
    }
  }
  return allStatuses.some(status => status === targetStatus)
}
