/* eslint-disable no-restricted-syntax */
import { delay } from 'redux-saga'
import { put, select, takeEvery, call } from 'redux-saga/effects'

import {
  FETCH_INFO_OF_ALL_REPRESENTATIVES_OF_ONE_CUSTOMER,
  FETCH_CUSTOMER_REPRESENTATIVE_INFO,
} from '../../../actions/actionTypes'

import {
  getIdsOfAllReps,
} from '../../../selectors/customers'

import createAction from '../../../actions/createAction'

function* fetchAllRepresentativesOfOneCustomer(action) {
  const { customerId } = action.payload
  const repIds = yield select(getIdsOfAllReps, customerId)
  // CODE_COMMENTS_50
  for (const repId of repIds) {
    yield put(createAction(
      FETCH_CUSTOMER_REPRESENTATIVE_INFO,
      {
        repId,
        // This saga will only be called when prelim info is fetching
        isPrelimInfoFetchRequest: true,
      },
    ))
    // The queue that handles the FETCH_CUSTOMER_REPRESENTATIVE_INFO action
    // needs time to check to see whether a fetch for this rep ID has already
    // been fetched or not. Without some delay, this loop will run too quickly
    // for the queue to check what it needs to check and then keep watching for
    // more FETCH_CUSTOMER_REPRESENTATIVE_INFO action dispatches, which would
    // result in the queue not fetching rep info for some reps.
    //
    // Bear in mind that this loop and the queue loop run each iteration in
    // hundreds of millionths of a second, so delaying here for 1/10th of a
    // second is an ungodly long time. If we only delayed for 1/1000th of a
    // second here, that would almost always be no problem, so we wait 1/10th
    // of a second just to make absolutely sure that this loop isn't racing
    // faster than the queue loop.
    yield call(delay, 100)
  }
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_INFO_OF_ALL_REPRESENTATIVES_OF_ONE_CUSTOMER, fetchAllRepresentativesOfOneCustomer],
]
