import React from 'react'

import DownloadCsv from '../../../../../common-components/DownloadCsv'

export default ({
  data,
  filename,
  loading,
  loadingTooltipText,
}) => (
  <DownloadCsv
    data={data}
    filename={filename}
    loading={loading}
    loadingTooltipText={loadingTooltipText}
  />
)
