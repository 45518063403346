/**
 * CODE_COMMENTS_12
 * CODE_COMMENTS_221
 */
import { REHYDRATE } from 'redux-persist/lib/constants'

import omit_ from 'lodash/omit'


import {
  addPermissionsMapsToObjectContainingPermissions,
} from '../util/permissions'

import {
  validatePersistedState,
} from '../util/persistedState'

import {
  REDUCER_NAMES_AUTH,
} from '../../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'


export default function currentUserReducerCreator({
  initialState = {},
  reducerName,
  saveCurrentUserAction,
  updateBasicInfoOfCurrentUserAction, // name and phone numbers
  updatePermissionsOfCurrentUserAction,
}) {
  return (state = initialState, action) => {
    switch (action.type) {
      case saveCurrentUserAction: {
        const currentUserInfo = action.payload
        // CODE_COMMENTS_223
        const withAuthPropsRemoved = removePropsRelatedToAuthentication(currentUserInfo)
        const withPermissionsMaps = addPermissionsMapsToObjectContainingPermissions(withAuthPropsRemoved)
        return withPermissionsMaps
      }

      // CODE_COMMENTS_3
      case REHYDRATE: {
        // CODE_COMMENTS_6
        const persistedState = action.payload
        const isPersistedStateProperlyStructured = validatePersistedState(persistedState)
        if (!isPersistedStateProperlyStructured) {
          return { ...state } // CODE_COMMENTS_8
        }

        if (!persistedState) { return { ...state } } // CODE_COMMENTS_8
        let persistedCurrentUserSlice = persistedState[reducerName]
        // if this slice of store has never been persisted before (e.g. first
        // time user has ever visited the site), don't run any rehydration
        if (!persistedCurrentUserSlice) { return { ...state } } // CODE_COMMENTS_8

        // CODE_COMMENTS_13
        if (!isTruthyAndNonEmpty(persistedCurrentUserSlice)) {
          return { ...state } // CODE_COMMENTS_8
        }

        const persistedAuthenticationSlice = persistedState[REDUCER_NAMES_AUTH]
        if (!persistedAuthenticationSlice) { return { ...state } } // CODE_COMMENTS_8
        if (!persistedAuthenticationSlice.isAuthenticated) { return { ...state } } // CODE_COMMENTS_8

        // CODE_COMMENTS_223
        persistedCurrentUserSlice = removePropsRelatedToAuthentication(persistedCurrentUserSlice)

        // The persistedCurrentUserSlice will already have the permissionsMap
        // and perCustomerPermissionsMap props, so why do we re-calculate and
        // re-save them here to what will assuredly be exactly their same
        // values? Because validatePersistedState() doesn't check the
        // permissionsMap or perCustomerPermissionsMap props, and it's possible
        // these props could have become corrupted in the user's local storage
        // (see CODE_COMMENTS_181), so to be extra safe, we re-create them here.
        persistedCurrentUserSlice = addPermissionsMapsToObjectContainingPermissions(persistedCurrentUserSlice)

        return persistedCurrentUserSlice
      }

      case updateBasicInfoOfCurrentUserAction: {
        return {
          ...state,
          ...action.payload,
        }
      }

      // For when a user with "Manage Users" privileges updates their own
      // permissions
      case updatePermissionsOfCurrentUserAction: {
        const { permissions, perCustomerPermissions } = action.payload
        const withUpdatedPermissions = {
          ...state,
          permissions,
          perCustomerPermissions,
        }
        const withUpdatedPermissionsMaps = addPermissionsMapsToObjectContainingPermissions(withUpdatedPermissions)
        return withUpdatedPermissionsMaps
      }

      default:
        return state
    }
  }
}


// Helper functions

// CODE_COMMENTS_223
function removePropsRelatedToAuthentication(persistedCurrentUserSlice) {
  return omit_(
    persistedCurrentUserSlice,
    ['userToken', 'decodedUserToken', 'refreshToken'],
  )
}


// function addPermissionsToCurrentUserDEVONLY_TESTING_PURPOSES(currentUserObj) {
//   const permissions = [
//     ...currentUserObj.permissions,
//     { permission: 'RELATIONSHIP_REQUEST', access: 'CREATE' },
//   ]
//   return {
//     ...currentUserObj,
//     permissions,
//   }
// }
