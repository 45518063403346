import React from 'react'
import { Modal, Grid } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import ModalWithCatastrophicFailureErrorBoundary from '../../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import TextAsPseudoLink from '../../../../../../../common-components/TextAsPseudoLink'
import CollarsTable from '../CollarsTable'

import {
  getInfoDefinitionsForCollarOrderFullDetails,
} from '../../../../util/collarOrderHistory'

// import CollarOrderLineItemCards from '../../../../../../../common-components/CollarOrderLineItemCards'


export default props => {
  const {
    info: orderDetails,
    customerId,
  } = props
  const { t: translate } = useTranslation('common')
  return (
    <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      closeIcon
      size="large"
      trigger={<TextAsPseudoLink>{orderDetails.collarOrderIdNumber}</TextAsPseudoLink>}
      className="test-full-shipment-details"
    >
      <Modal.Header>{translate('Order Details')}</Modal.Header>
      <Modal.Content>
        <KegCollarOrderDetails
          orderDetails={orderDetails}
          customerId={customerId}
          translate={translate}
        />
      </Modal.Content>
      <Modal.Header>{translate('Keg Collars Ordered')}</Modal.Header>
      <Modal.Content>
        <CollarsTable
          {...props}
        />
      </Modal.Content>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}


const KegCollarOrderDetails = ({
  customerId,
  orderDetails,
  translate,
}) => {
  const toDisplay = getInfoDefinitionsForCollarOrderFullDetails()
  return (
    // For alignment purposes, all displayed content within this grid needs to
    // be contained within a <Grid.Column> component; see CODE_COMMENTS_97 for
    // why.
    <Grid columns={2}>
      {toDisplay.map(definition => (
        <Grid.Row key={definition.heading} className="compact-grid-row">
          <Grid.Column width={3} style={{ fontWeight: 'bold' }}>{`${translate(definition.heading)}:`}</Grid.Column>
          <Grid.Column width={13}>{definition.cellContent(orderDetails, { customerId })}</Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  )
}

// At first we used this component to display collars as individual cards
// because, when TAP3 launched, we thought we were going to expose collar plate
// images to the web app within a few months after launch. Now, in September,
// 2019, the backend still hasn't exposed collar plate images to the web app, so
// the grey placeholder collar plate image with the "Preview Coming Soon" text
// above it (see <CollarOrderLineItemCards>) is more of a joke than an upcoming
// feature, so we've axed it in favor of a simple table. If the backend ever
// does end up exposing collar plate images to the web app, You can replace the
// <CollarsTable> component with this one.

// const KegCollarsOrderedAsCards = ({
//   orderDetails,
//   customerId,
// }) => (
//   <CollarOrderLineItemCards
//     collarOrderPlateLinkObjects={orderDetails.collarPlates} // CODE_COMMENTS_99
//     customerId={customerId}
//   />
// )
