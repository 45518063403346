import React, { useState } from 'react'
import { connect } from 'react-redux'
import cloneDeep_ from 'lodash/cloneDeep'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import {
  getEntireSlice as getEntireCustomersSlice,
  getProp as getCustomerProp,
} from '../../../../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../../../redux/selectors/rewrite/itemSkus'
import { EditOrderLink } from '../../../../../../OrderKegs/OrderAndEditShared/components/DeliverySchedule'
import {
  getEntireSlice as getAllKegOrderHistoryObjects,
} from '../../../../../../../redux/selectors/histories/kegOrderHistory'
import { KEG_ORDER_STATUS_PENDING } from '../../../../../../../constants/formAndApiUrlConfig/commonConfig'
import OrderAndShipmentsDetails from '../../../../KegOrderHistory/Table/subFeatures/OrderAndShipmentsDetails'
import TextAsPseudoLink from '../../../../../../../common-components/TextAsPseudoLink'
import createAction from '../../../../../../../redux/actions/createAction'
import { FETCH_KEG_INDIVIDUAL_ORDER } from '../../../../../../../redux/actions/actionTypes'


const mapStateToProps = (state, { itemSkuIdsToIncludeAsFieldsInTable, includeLocalKegsColumns, row, customerId }) => {
  const allKegOrderObjects = getAllKegOrderHistoryObjects(state, customerId)
  return {
    entireItemSkusSlice: getEntireItemSkusSlice(state),
    entireCustomersSlice: getEntireCustomersSlice(state),
    configuredItemSkuIds: getCustomerProp(state, customerId, 'itemSkuIds'),
    humanReadableOrderId: row?.humanReadableOrderId,
    customerId,
    includeLocalKegsColumns,
    itemSkuIdsToIncludeAsFieldsInTable,
    row,
    allKegOrderObjects,
  }
}

const Container = props => {
  const { t: translate } = useTranslation('common')
  const { humanReadableOrderId, row, dispatch, customerId, allKegOrderObjects } = props
  const info = cloneDeep_(allKegOrderObjects?.[row?.orderId] || {})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { orderId, shipmentId } = row

  if (Object?.keys(info)?.length) {
    return <KegOrder {...props} info={info} />
  }

  const handleClick = () => dispatch(
    createAction(
      FETCH_KEG_INDIVIDUAL_ORDER, {
        customerId,
        orderId,
        setIsSubmitting,
        forceSave: true,
        callBack: () => {
          const eleId = `#${shipmentId} span`
          const ele = document.querySelector(eleId) || null
          if (ele) {
            setTimeout(() => ele?.click(), 1000)
          }
        },
      },
    ))

  return (
    <React.Fragment>
      {isSubmitting && <Dimmer active><Loader size="big" active>{translate('Loading')}</Loader></Dimmer>}
      <TextAsPseudoLink onClick={() => handleClick()}>{humanReadableOrderId}</TextAsPseudoLink>
    </React.Fragment>
  )
}

const KegOrder = props => {
  const { info, row } = props
  if (!info?.orderId) {
    return <React.Fragment />
  }
  return (
    <div id={`${row?.shipmentId}`}>
      {info?.status === KEG_ORDER_STATUS_PENDING && <EditOrderLink {...props} /> }
      {info?.status !== KEG_ORDER_STATUS_PENDING && <OrderAndShipmentsDetails {...props} /> }
    </div>
  )
}

export default connect(
  mapStateToProps,
)(Container)
