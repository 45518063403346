/**
 * CODE_COMMENTS_267
 */

import has_ from 'lodash/has'


import {
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS,
  INFO_JUST_FOR_THE_WEB_APP,
  INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME,
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS,
  INFO_JUST_FOR_THE_WEB_APP_CUSTOM_FORM_ROW_LABEL,
  INFO_JUST_FOR_THE_WEB_APP_CUSTOMER_BUSINESS_UNIT_ID,
  CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS,
  CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS,
} from '../constants'

import {
  getDisplayedDistCustomerNameFromArrayOfCustomerObjectsForOnePhysicalLocation,
} from '../'

import {
  getBusinessUnitNameFromBusinessUnitId,
} from '../../../../utils'
import { BUSINESS_UNIT_ID_TYPE_MICROSTAR } from '../../../../constants'


export function gatherInventoryReportObjsWhenIndividualObjsFromApiDontYetExist({
  configObjReturnedFromDistInvNoLoginConfigCall,
}) {
  // It takes time to make the call that fetches this config object
  if (!configObjReturnedFromDistInvNoLoginConfigCall) {
    return null
  }
  return {
    [INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS]: (
      configObjReturnedFromDistInvNoLoginConfigCall.customers.map(arrayOfCustomerObjs => ({
        [INFO_JUST_FOR_THE_WEB_APP]: {
          // eslint-disable-next-line max-len
          [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: getDisplayedDistCustomerNameFromArrayOfCustomerObjectsForOnePhysicalLocation(arrayOfCustomerObjs),
        },
        [INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]: arrayOfCustomerObjs.map(customerObj => {
          // { MKM: 2, KC: 1, CBI: 1}
          const quantitiesOfBusinessUnitIds = arrayOfCustomerObjs.reduce(
            (acc, cust) => ({
              ...acc,
              [cust.businessUnitId]: has_(acc, cust.businessUnitId)
                ? acc[cust.businessUnitId] + 1
                : 1,
            }),
            {},
          )
          let label = getDisplayedLabelOfBusinessUnitId(customerObj.businessUnitId)
          if (quantitiesOfBusinessUnitIds[customerObj.businessUnitId] > 1) {
            label = `${customerObj.businessUnitId} - ${customerObj.name}`
          }
          return {
            [INFO_JUST_FOR_THE_WEB_APP]: {
              [INFO_JUST_FOR_THE_WEB_APP_CUSTOM_FORM_ROW_LABEL]: label,
              [INFO_JUST_FOR_THE_WEB_APP_CUSTOMER_BUSINESS_UNIT_ID]: customerObj.businessUnitId,
            },
            customerId: customerObj.id,
          }
        }),
      }))
    ),
  }
}

export function gatherConstellationReportObjsWhenIndividualObjsFromApiDontYetExist({
  configObjReturnedFromDistInvNoLoginConfigCall,
}) {
  // It takes time to make the call that fetches this config object
  if (!configObjReturnedFromDistInvNoLoginConfigCall) {
    return null
  }

  const obj = []
  configObjReturnedFromDistInvNoLoginConfigCall.customers.forEach(arrayOfCustomerObjs => {
    const mkmArrayOfCustomerObjs = arrayOfCustomerObjs.filter(o => o.businessUnitId === BUSINESS_UNIT_ID_TYPE_MICROSTAR)
    // eslint-disable-next-line max-len
    const individualObj = mkmArrayOfCustomerObjs.map(customerObj => {
      // { MKM: 2, KC: 1, CBI: 1}
      // const individualObjForPhysicalLocation =
      // getDisplayedDistCustomerNameFromArrayOfCustomerObjectsForOnePhysicalLocation(arrayOfCustomerObjs)
      const quantitiesOfBusinessUnitIds = arrayOfCustomerObjs.reduce(
        (acc, cust) => ({
          ...acc,
          [cust.businessUnitId]: has_(acc, cust.businessUnitId)
            ? acc[cust.businessUnitId] + 1
            : 1,
        }),
        {},
      )
      let label = getDisplayedLabelOfBusinessUnitId(customerObj.businessUnitId)
      if (quantitiesOfBusinessUnitIds[customerObj.businessUnitId] > 1) {
        label = `${customerObj.businessUnitId} - ${customerObj.name}`
      }
      return {
        [INFO_JUST_FOR_THE_WEB_APP]: {
          [INFO_JUST_FOR_THE_WEB_APP_CUSTOM_FORM_ROW_LABEL]: label,
          [INFO_JUST_FOR_THE_WEB_APP_CUSTOMER_BUSINESS_UNIT_ID]: customerObj.businessUnitId,
        },
        customerId: customerObj.id,
      }
    })
    mkmArrayOfCustomerObjs.forEach((arr, index) => {
      obj.push({ [INFO_JUST_FOR_THE_WEB_APP]:
      {
        [INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]: arr.name,
      },
      [CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]: individualObj[index],
      customerId: arr.id,
      })
    })
  })
  return {
    [CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS]: obj,
  }
}

// This returns an empty object (it might as well return null) because no-login
// distributors will never edit their inventory reports, only create them, so
// this function will never actually be called. (Note that the inventory reports
// created by no-login dists will be editable, but distributors will have to log
// in to edit them, and at that point the inventory reports will be classified
// as "distributor" InventoryReportObjs, not "distributorNoLoginRequired"
// InventoryReportObjs.)

export function gatherInventoryReportObjsWhenIndividualObjsFromApiAlreadyExist() {
  return {}
}

function getDisplayedLabelOfBusinessUnitId(businessUnitId) {
  return `${getBusinessUnitNameFromBusinessUnitId(businessUnitId)} Branded Kegs`
}
