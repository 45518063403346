/**
 * CODE_COMMENTS_1
 */
import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { Route } from 'react-router-dom'

import App from './App'
import LoadingPage from './common-components/LoadingPage'
import BrowserUpdate from './common-components/BrowserUpdate'
import withAnalyticsTracker from './withAnalyticsTracker'

BrowserUpdate()

const AppProvider = ({ store, persistor, history }) => (
  <React.Fragment>
    <div id='site-alert' />
    <Provider store={store}>
      <PersistGate loading={<LoadingPage />} persistor={persistor}>
        {/* `ConnectedRouter` replaces react-router's built-in `Router` */}
        <ConnectedRouter history={history}>
          {/* We write the following line instead of just <App /> in order */}
          {/* to make our Analytics tracking work. */}
          <Route component={withAnalyticsTracker(App)} />
          {/* <App /> */}
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </React.Fragment>
)

export default AppProvider
