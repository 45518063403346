/* eslint-disable consistent-return, no-use-before-define, max-len */

import { call } from 'redux-saga/effects'
import axios from 'axios'
// import qs from 'qs'
// import moment from 'moment-timezone'

import cloneDeep_ from 'lodash/cloneDeep'
import keys_ from 'lodash/keys'

import {
  convertPublicFetchIntoPrivateFetch,
  withOperateAsCustomerUserHeader,
  defaultFetchMiddleware,
} from './util/higherOrderSagas'

import {
  fetchWithTimeoutPolling,
} from './util/pollTimedOutApiFetch'

import {
  getDoesThisCallUseTimeoutPolling,
} from './util'

import {
  RETRY_API_FETCH_SIGNAL,
  RUN_THROUGH_ALL_OTHER_REACTIONS_AND_THROW_ERROR_IF_NO_REACTIONS_APPLY,
} from '../../../constants'

// import { XhrReplicationError } from '../../../customErrors'

// Uncomment the line below to encode spaces in query parameters as '%20' rather
// than '+'

// axios.defaults.paramsSerializer = params => qs.stringify(params, { format: 'RFC3986' })

/*
 * *****************************************************************************
 * Exported Functions: The only way the app should ever make fetch calls is by
 * calling one of these exported functions.
 * *****************************************************************************
*/

export function* publicFetch({
  path,
  method='get',
  params=null,
  data=null,
  headers={},
  customErrorReactions,
  // used by the convertPublicFetchIntoPrivateFetch higher-order function
  isPrivateFetch=false,
}) {
  let config = {
    url: path,
    method,
    params,
    data,
    headers,
  }
  config = addJsonContentTypeToHeadersIfNecessary(config) // CODE_COMMENTS_201
  const response = yield call(
    fetchWithCustomErrorReactions,
    {
      config,
      errorReactions: customErrorReactions,
      isPrivateFetch,
    },
  )
  return response
}


export const privateFetch = withOperateAsCustomerUserHeader(
  convertPublicFetchIntoPrivateFetch(publicFetch),
)


/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/


/**
 * This function has custom retry logic built-in. See CODE_COMMENTS_117 and
 * the "Retrying XHR calls" section of
 * https://redux-saga.js.org/docs/recipes/index.html
 */
const fetchWithCustomErrorReactions = defaultFetchMiddleware(
  function* fetchWithCustomErrorReactionsWrappedSaga({ config, errorReactions }) {
    const doesThisCallUseTimeoutPolling = yield call(getDoesThisCallUseTimeoutPolling, config)

    const numTimesEachErrorHasHappened = new Array(errorReactions.length).fill(0)
    // we use a for loop here rather than while(true) because what if the user
    // passes in an errorReactions array in which, in one of the reactions, they
    // forget to throw the error and instead retry endlessly?
    const retriesHardLimit = 20
    for (let i=0; i < retriesHardLimit; i+=1) {
      try {
        // // For testing of Maintenance Mode features
        // const startString = '2019-12-07 00:00'
        // const endString = '2019-12-07 22:33'
        // const start = moment.tz(startString, 'America/Denver')
        // const end = moment.tz(endString, 'America/Denver')
        // const startFormattedForMessageDetail = start.format('MM/DD/YYYY HH:mm')
        // const endFormattedForMessageDetail = end.format('MM/DD/YYYY HH:mm')
        // const messageDetail = `Expected maintenance ${startFormattedForMessageDetail}-${endFormattedForMessageDetail}`
        // if (moment().isBetween(start, end)) {
        //   throw new XhrReplicationError({
        //     response: {
        //       status: 503,
        //       statusText: 'Internal server error',
        //       data: {
        //         status: 'SERVICE_UNAVAILABLE',
        //         errorCode: null,
        //         uuid: 'c0f72b26-36ad-4172-9d37-d3fd5be25bc4',
        //         date: 1571850028766,
        //         message: 'The TAP3 System is under maintainance.',
        //         messageDetail,
        //         validationErrors: null,
        //       },
        //       headers: {},
        //     },
        //     config: {
        //       url: '/collarOrders',
        //       params: {},
        //       method: 'GET',
        //     },
        //   })
        // }
        const response = doesThisCallUseTimeoutPolling
          ? yield call(fetchWithTimeoutPolling, config)
          : yield call(axios, config)
        return response
      } catch (error) {
        let shouldCallBeRetried = false
        if (i === retriesHardLimit) { throw error } // see comment above
        // Why 'for of' here? Because of the 'yield' below. See CODE_COMMENTS_50
        // eslint-disable-next-line no-restricted-syntax
        for (const index of errorReactions.keys()) {
          const actionDefinition = errorReactions[index]
          if (actionDefinition.check(error, config)) {
            numTimesEachErrorHasHappened[index]+=1
            const reaction = yield call(
              actionDefinition.reaction,
              error,
              numTimesEachErrorHasHappened[index],
            )
            // The response will be either RETRY_API_FETCH_SIGNAL or whatever the
            // fetch() funciton should return
            if (reaction === RETRY_API_FETCH_SIGNAL) {
              shouldCallBeRetried = true
              break
            }
            if (reaction === RUN_THROUGH_ALL_OTHER_REACTIONS_AND_THROW_ERROR_IF_NO_REACTIONS_APPLY) {
              // eslint-disable-next-line no-continue
              continue
            }
            return reaction
          }
        }
        // if the loop above went through all the error reactions and the error
        // didn't match any of them
        if (!shouldCallBeRetried) { throw error }
        // otherwise continue, i.e. make another call
      }
    }
  },
)


// CODE_COMMENTS_201
function addJsonContentTypeToHeadersIfNecessary(config) {
  let doesHeadersHaveAContentTypeAlreadySet
  if (!config.headers) {
    doesHeadersHaveAContentTypeAlreadySet = false
  } else {
    const headerNames = keys_(config.headers)
    const lowercaseHeaderNames = headerNames.map(name => name.toLowerCase())
    doesHeadersHaveAContentTypeAlreadySet = lowercaseHeaderNames.includes('content-type')
  }

  if (
    config.method.toLowerCase() !== 'get' &&
    !config.data &&
    !doesHeadersHaveAContentTypeAlreadySet
  ) {
    const newConfig = cloneDeep_(config)
    newConfig.headers = newConfig.headers || {}
    newConfig.headers['Content-Type'] = 'application/json'
    newConfig.data = null // CODE_COMMENTS_201
    return newConfig
  }

  return config
}
