import { call, select, takeEvery } from 'redux-saga/effects'

import get_ from 'lodash/get'


import {
  getProp as getCustomerProp,
} from '../../../selectors/customers'

import { privateFetch } from '../../fetch'
import {
  FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_CITY,
} from '../../../actions/actionTypes'

import {
  URL_PARAM_STATE,
  URL_PARAM_CITY,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/distSearch'

import {
  API_URL_PATH_DISTRIBUTOR_SEARCH,
} from '../../../../constants'

import {
  generalDoFailure,
} from '../../util/fetchFailure'


function* fetchChooseCity(action) {
  const {
    value,
    selectedUsState,
  } = action.payload
  let response
  try {
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_DISTRIBUTOR_SEARCH,
        params: {
          [URL_PARAM_STATE]: selectedUsState,
          [URL_PARAM_CITY]: value,
        },
      },
    )
  } catch (error) {
    if (get_(error, ['response', 'status']) === 404) {
      response = { data: [] }
    } else {
      yield call(doFailure, { error, ...action.payload })
    }
    return
  }
  yield call(doSuccess, { info: response.data, ...action.payload })
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REQUEST_NEW_DISTRIBUTOR_CHOOSE_CITY, fetchChooseCity],
]


function* doSuccess({
  customerId,
  operatingContractBrewerCustomerId,
  info,
  onSuccess,
}) {
  let filteredDistObjs = yield call(
    filterDistributorsByBusienssUnitId,
    customerId,
    operatingContractBrewerCustomerId,
    info,
  )
  filteredDistObjs = filterDistributorsByRSDists(filteredDistObjs)
  if (onSuccess) { onSuccess(filteredDistObjs) }
}


function* doFailure({
  error,
  onFailure,
}) {
  if (onFailure) { onFailure({ error }) }
  yield call(
    generalDoFailure,
    {
      error,
    },
  )
}

// MKM customers aren't allowed to be related to KC customers and vice versa, so
// we need to filter the list of distributors we get by their businessUnitId:
// each distributor in the form dropdown needs to have the same businessUnitId
// as the currently-logged-in (or, more specifically, currently-operated-on)
// customer. PS: Ideally, it would be better to to add a query parameter to our
// fetch so that the backend filters the results rather than having the web app
// do the filtering. But that would require a change to backend code, and in
// January, 2019 when we realized we need this filter, the frontend team had
// more time available than the backend team (backend team was mired in
// invoicing), so this frontend filtering solution works fine.
function* filterDistributorsByBusienssUnitId(
  customerId,
  operatingContractBrewerCustomerId,
  arrayOfDistributorCustomerObjs,
) {
  const businessUnitIdOfCustomer = yield select(
    getCustomerProp,
    operatingContractBrewerCustomerId || customerId,
    'businessUnitId',
  )
  return arrayOfDistributorCustomerObjs.filter(o => o.businessUnitId === businessUnitIdOfCustomer)
}


// https://microstartap3.atlassian.net/browse/TP3-3782 - RS distributors are
// recall distributors and cannot be shipped to by customers, so filter them
// out. Their names start with 'RS -' or 'KC: RS -'
function filterDistributorsByRSDists(arrayOfDistributorCustomerObjs) {
  /* eslint-disable no-useless-escape, no-multi-spaces */
  const myRe = new RegExp(
    [
      '^',             // start of string
      '(KC\\:)*',      // 0 or more instances of literally 'KC:'
      '\\s*',          // 0 or more spaces
      'RS',            // literally 'RS'
      '\\s*',          // 0 or more spaces
      '[–—\\-\\:]',       // A single En Dash, Em Dash, hyphen or colon
    ].join(''),
    'i', // i means case-insensitive
  )
  /* eslint-enable no-useless-escape, no-multi-spaces */
  return arrayOfDistributorCustomerObjs.filter(o => !myRe.test(o.name))
}
