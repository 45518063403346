import { call, put, takeEvery } from 'redux-saga/effects'


import fetchNewUserTokenIfCurrentUserTokenExpired from './keepUserLoggedIn/util/fetchNewUserTokenIfCurrentUserTokenExpired'

import {
  PERFORM_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN,
  START_MAINTENANCE_SCHEDULE_FETCHER_THREAD,
  START_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE_THREAD,
  START_SET_MAINTENANCE_MODE_NOTIFICATION_FLAG_THREAD,
  START_POLL_TO_GET_OUT_OF_MAINTENANCE_MODE_THREAD,
  START_KEEP_USER_LOGGED_IN_THREAD_ONCE_WE_KNOW_WE_HAVE_AN_UNEXPIRED_REFRESH_TOKEN,
  FETCH_ALL_UNIVERSAL_CUSTOMER_REPRESENTATIVES,
  SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED,
  SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED,
} from '../actions/actionTypes'

import createAction from '../actions/createAction'


// This is the function that should be called immediately after the user
// successfully logs in, no matter whether the logged-in user is an employee
// user or a customer user.
function* tasksToPerformImmediatelyAfterLogin() {
  yield put(createAction(SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_BEEN_ATTEMPTED))
  // We want to call this _before_ calling any other tasks here, because what if
  // the user starts the web app while we're currently in maintenance mode? All
  // calls to the backend will throw a 503, which will put the web app into
  // Maintenance Mode. The WHEN_DO_WE_GET_OUT_OF_MAINTENANCE_MODE_THREAD is the
  // only thing that can take the web app _out_ of maintenance mode (see
  // CODE_COMMENTS_238), so we want to make absolute sure this thread is running
  // before we make any calls to the backend.
  yield put(createAction(START_POLL_TO_GET_OUT_OF_MAINTENANCE_MODE_THREAD)) // CODE_COMMENTS_238
  // This call is a blocking call that fetches a new userToken if necessary. If
  // the new user token fails because our refreshToken is also expired
  // (something we can't know until we try the fetch), the saga will dispatch a
  // logout action and this fetchPrelimInfo saga will automatically be canceled.
  yield call(fetchNewUserTokenIfCurrentUserTokenExpired)
  // These calls, on the other hand, are non-blocking calls; they're async.
  yield put(createAction(START_MAINTENANCE_SCHEDULE_FETCHER_THREAD)) // CODE_COMMENTS_238
  yield put(createAction(START_PUT_APP_IN_MAINTENANCE_MODE_BASED_ON_MAINTENANCE_SCHEDULE_THREAD)) // CODE_COMMENTS_238
  yield put(createAction(START_SET_MAINTENANCE_MODE_NOTIFICATION_FLAG_THREAD)) // CODE_COMMENTS_238
  yield put(createAction(START_KEEP_USER_LOGGED_IN_THREAD_ONCE_WE_KNOW_WE_HAVE_AN_UNEXPIRED_REFRESH_TOKEN))
  yield put(createAction(FETCH_ALL_UNIVERSAL_CUSTOMER_REPRESENTATIVES))
  yield put(createAction(SET_HAVE_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN_SUCCEEDED))
}


// CODE_COMMENTS_11
export default [
  [
    takeEvery,
    PERFORM_TASKS_TO_PERFORM_IMMEDIATELY_AFTER_LOGIN,
    tasksToPerformImmediatelyAfterLogin,
  ],
]
