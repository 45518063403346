import Immutable from 'seamless-immutable'

import keys_ from 'lodash/keys'


import { withoutIn } from '../util/seamlessImmutable'

import {
  convertArrayOfObjectsToObjectOfSubObjects,
} from '../../../utils'


export default ({
  saveHistoryOfSingleCustomerActionType,
  saveHistoryOfMultipleCustomersActionType,
  saveHistoryItemsOfMultipleCustomersWithoutDeletingAnyExistingHistoryItemsActionType,
  saveSingleHistoryItemActionType,
  deleteSingleHistoryItemActionType,
  initialState=Immutable({ byCustomerId: {} }),
  itemPrimaryKey='id',
}) => (
  (state=initialState, action) => {
    switch (action.type) {
      case saveHistoryOfSingleCustomerActionType:
        return (
          Immutable.setIn(
            state,
            ['byCustomerId', ...action.payload.savePath],
            // CODE_COMMENTS_138
            convertArrayOfObjectsToObjectOfSubObjects(action.payload.info, itemPrimaryKey),
          )
        )

      // action.payload.info should be an object shaped like this:
      //
      // {
      //   customerId1: [arrayOfInventoryObjectsForThisCustomer],
      //   customerId2: [arrayOfInventoryObjectsForThisCustomer],
      // }
      //
      // This reducer will convert it into:
      //
      // {
      //   customerId1: {
      //     idOfInventoryObject1: { inventoryObject1 },
      //     idOfInventoryObject2: { inventoryObject2 },
      //   },
      //   customerId2: {
      //     idOfInventoryObject3: { inventoryObject3 },
      //     idOfInventoryObject4: { inventoryObject4 },
      //   },
      // }
      //
      // and merge it into the info of the rest of the customers. Any history
      // objects currently in the store for customerId1 and customerId2 will be
      // deleted/overwritten.
      case saveHistoryOfMultipleCustomersActionType: {
        let runningNewState = state
        const objectOfArrays = action.payload.info
        keys_(objectOfArrays).forEach(customerId => {
          runningNewState = Immutable.setIn(
            runningNewState,
            ['byCustomerId', customerId],
            convertArrayOfObjectsToObjectOfSubObjects(
              objectOfArrays[customerId],
              itemPrimaryKey,
            ),
          )
        })
        return runningNewState
      }

      // Just like saveHistoryOfMultipleCustomersActionType except that the
      // history objects in the payload get added to the customerId1 object,
      // they don't override it. The only time a history item will get
      // overridden is if a history object in the payload has the same ID as a
      // history item in the current store (for the same customer).
      case saveHistoryItemsOfMultipleCustomersWithoutDeletingAnyExistingHistoryItemsActionType: {
        let runningNewState = state
        const objectOfArrays = action.payload.info

        keys_(objectOfArrays).forEach(customerId => {
          const objectOfSubObjects = convertArrayOfObjectsToObjectOfSubObjects(
            objectOfArrays[customerId],
            itemPrimaryKey,
          )
          runningNewState = Immutable.setIn(
            runningNewState,
            ['byCustomerId', customerId],
            {
              ...(state.byCustomerId[customerId] || {}),
              ...objectOfSubObjects,
            },
          )
        })
        return runningNewState
      }

      case saveSingleHistoryItemActionType:
        return (
          Immutable.setIn(
            state,
            ['byCustomerId', ...action.payload.savePath, action.payload.info[itemPrimaryKey]],
            action.payload.info,
          )
        )

      case deleteSingleHistoryItemActionType: {
        return withoutIn(
          state,
          ['byCustomerId', ...action.payload.savePath, action.payload.itemId],
        )
      }

      default:
        return state
    }
  }
)
