import {
  SAVE_SUBSIDIARIES,
} from '../../actions/actionTypes'

import reducerCreator from './higherOrderEntityReducers/basicReducerCreator'

export default reducerCreator(
  [
    SAVE_SUBSIDIARIES,
  ],
)
