/**
 * See CODE_COMMENTS_81
 */
import { compose } from 'recompose'

import withEither from '../../general/withEither'


const isLoadingFn = ({
  isFetching,
  hasFetchBeenAttempted,
  statusesOfMultipleFetches,
}) => {
  if (statusesOfMultipleFetches) { // only passed in if we're waiting for multiple API fetches
    return statusesOfMultipleFetches.fetchStatuses.some(fetchStatusesOfOneFetch => (
      fetchStatusesOfOneFetch.isFetching || !fetchStatusesOfOneFetch.hasFetchBeenAttempted
    ))
  }
  // if we're only waiting for a single api fetch
  return isFetching || !hasFetchBeenAttempted
}

const didFetchFailFn = ({
  didFetchFail,
  statusesOfMultipleFetches,
}) => {
  if (statusesOfMultipleFetches) { // only passed in if we're waiting for multiple API fetches
    return statusesOfMultipleFetches.fetchStatuses.some(fetchStatusesOfOneFetch => (
      fetchStatusesOfOneFetch.didFetchFail
    ))
  }
  // if we're only waiting for a single api fetch
  return didFetchFail
}

export default (
  LoadingComponent,
  FetchFailedComponent,
) => (
  compose(
    withEither(isLoadingFn, LoadingComponent),
    withEither(didFetchFailFn, FetchFailedComponent),
  )
)
