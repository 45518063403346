import React, { memo, useState } from 'react'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import SmartInput from 'react-phone-number-input/smart-input'

import isString_ from 'lodash/isString'

import 'react-phone-number-input/style.css'


import InputNoAutoComplete from '../semanticUiCustomComponents/InputNoAutoComplete'

import {
  COUNTRIES_WHOSE_CALLING_CODE_IS_ONE,
} from '../../constants'

import {
  getDoesStringContainDigitsOnly,
  getIsPossiblePhoneNumber,
} from '../../utils'

import './styles.css'


export default memo(({ // Why memo? CODE_COMMENTS_266
  initialValue,
  value,
  onChange: onChangeParent,
  includeExtension,
  extensionValue,
  onExtensionChange: onExtensionChangeParent,
  setIsPhoneInputValid: parentSetIsPhoneInputValid,
  // By default, this component has a width of 24rem with the extension and
  // 15rem without.
  ...rest
}) => {
  const [isPhoneInputValid, setIsPhoneInputValid] = useState(getIsPossiblePhoneNumber(initialValue))
  // We need to set displayInitialValueAsLocalNumber to its correct value
  // immediately, because if we change the displayInitialValueAsLocalNumber prop
  // after the <PhoneInput> component first renders, it has no effect. That's
  // why we can't put the code that calculates this prop into a useEffect()
  // function as we normally would, because useEffect() always runs after
  // initial render. Secondly, we want to ensure that
  // determineWhetherToDisplayInitialValueAsLocalNumber() only runs once, not
  // every time this component updates, so we take advantage of useState() which
  // has this ability.
  const [displayInitialValueAsLocalNumber] = useState(
    determineWhetherToDisplayInitialValueAsLocalNumber(initialValue),
  )
  const [hasUserBlurredField, setHasUserBlurredField] = useState(false)
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <PhoneInput
        value={value}
        onChange={value_ => {
          onChangeParent(value_)
          const isValid = getIsPossiblePhoneNumber(value_)
          setIsPhoneInputValid(isValid)
          if (parentSetIsPhoneInputValid) {
            parentSetIsPhoneInputValid(isValid)
          }
        }}
        flagsPath="https://flagicons.lipis.dev/flags/4x3/"
        displayInitialValueAsLocalNumber={displayInitialValueAsLocalNumber}
        inputComponent={SmartInput}
        style={{
          width: '15rem',
        }}
        onBlur={() => { setHasUserBlurredField(true) }}
        inputClassName={
          // What's with the hasUserBlurredField? It's here because, when the
          // user is entering a new phone number for the first time, we don't
          // want the input to go red as soon as they enter the first character,
          // only going back to normal after they finally enter enough
          // characters for the phone number to be valid. We want to give the
          // user a chance to enter the entire phone number before visually
          // bugging them letting them know the phone number is invalid.
          !isPhoneInputValid && hasUserBlurredField
            ? 'input-error-semantic-ui'
            : null
        }
        // The default country if no 'value' prop is passed in on first render
        country="US"
        // Put US, Canada and Great Britain at the top
        countryOptions={['US', 'CA', 'GB', '|', '...']}
        {...rest}
      />
      {includeExtension &&
        <div style={{ display: 'flex', alignItems: 'center', width: '9rem' }}>
          <InputNoAutoComplete
            type="text"
            name="deskPhoneExtension"
            value={
              extensionValue
              // This 'or empty string' line is important, because if the value
              // of this component is null when it mounts, the component be an
              // uncontrolled component. Then, when the user enters a value, it
              // will become a controlled component. This leads to unexpected
              // behavior; for example, when the component is uncontrolled, our
              // custom onChange handler doesn't fire, which results in the
              // input value accepting any characters, not just numbers. See
              // https://microstartap3.atlassian.net/browse/TP3-3978 for an
              // example of the unexpected behavior in action.
              || ''
            }
            placeholder="ext."
            onChange={e => {
              const input = e.target.value
              if (
                input === ''
                || (
                  getDoesStringContainDigitsOnly(input)
                  && input.length <= 10 // CODE_COMMENTS_192
                )
              ) {
                onExtensionChangeParent(input)
              }
            }}
            style={{ marginLeft: '0.5em' }}
          />
        </div>
      }
    </div>
  )
})


function determineWhetherToDisplayInitialValueAsLocalNumber(initialValue) {
  if (
    !initialValue
    || (isString_(initialValue) && initialValue.trim() === '')
  ) {
    return false
  }
  const phoneNumber = parsePhoneNumber(initialValue)
  // If a phone number is a US/Canada number (i.e. starts with +1), display
  // it as e.g. (303) 123-4567, otherwise show the country code e.g. for a
  // Great Britain phone number +44 55 4433 4454
  if (phoneNumber && COUNTRIES_WHOSE_CALLING_CODE_IS_ONE.includes(phoneNumber.country)) {
    return true
  }
  return false
}
