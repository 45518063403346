import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Icon, Popup } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { REVERT_ENABLED_STATUS, SHIP_ENABLED_STATUS } from '../../../../../../../constants'
import createAction from '../../../../../../../redux/actions/createAction'
import {
  FETCH_UPDATE_SCHEDULED_SHIPMENT,
} from '../../../../../../../redux/actions/actionTypes'

const UiButton = props => {
  const buttonColor = props?.loading ? 'grey' : props?.action === 'SHIP' ? 'green' : 'yellow'
  const buttonText = props.action === 'SHIP' ? 'Ship' : 'Revert'
  const { t: translate } = useTranslation('common')
  return (
    <Button
    compact
    size="mini"
    color={buttonColor}
    onClick={() => props.handleClick({
      action: props.action,
    })}
    loading={props?.loading}
    >
      {translate(buttonText)}
    </Button>
  )
}

const ShipAndRevertButtons = ({
  showShipButton,
  showRevertButton,
  customerId,
  entireItemSkusSlice,
  dispatch,
  itemSkuIds,
  operatingContractBrewerCustomerId,
  info,
}) => {
  // const { getFetchStatuses } = fetchStatusSelectors
  const [isFetching, setIsFetching] = useState(false)
  const [didFetchFail, setDidFetchFail] = useState(false)

  const handleSubmit = ({ action }) => (
    dispatch(createAction(
      FETCH_UPDATE_SCHEDULED_SHIPMENT,
      {
        customerId,
        operatingContractBrewerCustomerId,
        entireItemSkusSlice,
        itemSkuIds,
        setIsFetching,
        setDidFetchFail,
        rowData: info,
        btnAction: action,
      },
    ))
  )
  return (
    <div className="ui input">
      {showShipButton && <UiButton loading={isFetching} handleClick={handleSubmit} action='SHIP' />}
      {showRevertButton && <UiButton loading={isFetching} handleClick={handleSubmit} action='REVERT' />}
      {didFetchFail && (
        <Popup trigger={<Icon color="red" name="info circle" style={{ position: 'relative', margin: 'auto' }} size="small" />}>
          <Popup.Content>
            Oops, we hit a snag. The form submission failed.
          </Popup.Content>
        </Popup>
      )}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const showShipButton = SHIP_ENABLED_STATUS.includes(props?.info?.status?.toUpperCase())
  const showRevertButton = REVERT_ENABLED_STATUS.includes(props?.info?.status?.toUpperCase())

  return {
    showRevertButton,
    showShipButton,
    ...props,
  }
}

export default connect(
  mapStateToProps,
)(ShipAndRevertButtons)
