import React from 'react'
import { Table } from 'semantic-ui-react'


import {
  formatDateForTruncatedDisplay,
} from '../util'


export default function DataTable({
  dataForChart,
  containerType,
  doesDayComeBeforeMonthInLocaleDateStringFormat,
}) {
  const arr = dataForChart[containerType]
  return (
    <Table
      compact
      className="very-compact-table undisplayed-in-tablet-portrait-and-all-phone-views"
      definition
      stackable={false}
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />{/* Top left cell (blank) */}
          {arr.map(o => (
            <Table.HeaderCell key={o.date} textAlign='center'>
              {formatDateForTruncatedDisplay({
                unixTime: o.date,
                doesDayComeBeforeMonthInLocaleDateStringFormat,
                includeYear: false,
              })}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          <Table.Cell collapsing>Total In</Table.Cell>
          {arr.map(o => <Table.Cell key={o.date} textAlign='center'>{o.TotalIn}</Table.Cell>)}
        </Table.Row>

        <Table.Row>
          <Table.Cell collapsing>Total Out</Table.Cell>
          {arr.map(o => <Table.Cell key={o.date} textAlign='center'>{o.TotalOut}</Table.Cell>)}
        </Table.Row>

        <Table.Row>
          <Table.Cell collapsing>Calculated</Table.Cell>
          {arr.map(o => <Table.Cell key={o.date} textAlign='center'>{o.calculatedQuantity}</Table.Cell>)}
        </Table.Row>

        <Table.Row>
          <Table.Cell collapsing>Reported</Table.Cell>
          {arr.map(o => <Table.Cell key={o.date} textAlign='center'>{o.reportedQuantity}</Table.Cell>)}
        </Table.Row>

        <Table.Row>
          <Table.Cell collapsing>Difference</Table.Cell>
          {arr.map(o => <Table.Cell key={o.date} textAlign='center'>{o.differenceBetweenReportedAndCalculated}</Table.Cell>)}
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
