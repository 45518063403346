import { connect } from 'react-redux'


import DeleteBankAccount from './components'

import fetchStatusSelectors from '../../../../redux/selectors/fetchStatuses/customers/bankAccountInfoDelete'

import {
  FETCH_DELETE_STORED_BANK_ACCOUNT_INFO,
} from '../../../../redux/actions/actionTypes'
import createAction from '../../../../redux/actions/createAction'


const mapStateToProps = (state, { customerId }) => {
  const { getFetchStatuses, getFetchFailureErrorDetails } = fetchStatusSelectors
  const fetchStatuses = getFetchStatuses(state, customerId)
  const errorDetails = getFetchFailureErrorDetails(state, customerId)

  return {
    ...fetchStatuses,
    errorDetails,
  }
}

const mapDispatchToProps = (dispatch, { customerId }) => ({
  onSubmit: () => {
    dispatch(createAction(
      FETCH_DELETE_STORED_BANK_ACCOUNT_INFO,
      { customerId },
    ))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteBankAccount)
