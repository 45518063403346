import { connect } from 'react-redux'


import ContainerTypesDifferences from './components'

import {
  getProp as getCustomerProp,
  getBusinessUnitIdOfRootCustomer,
} from '../../redux/selectors/customers'
import {
  getIsAuthenticated,
} from '../../redux/selectors/authentication'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../redux/selectors/rewrite/itemSkus'

import {
  ITEM_SKU_IDS_MKM_HB_DEFAULT,
  ITEM_SKU_IDS_MKM_SB_DEFAULT,
  ITEM_SKU_IDS_MKM_QB_DEFAULT,
  ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
} from '../../constants'


const mapStateToProps = (
  state,
  {
    customerId,
    // Pass this in if you want to render a CPP description popup instead of the
    // regular Container Type Differences popup (CODE_COMMENTS_277).
    itemSkuId,
    // itemSkuIdsToInclude and businessUnitIdOfCurrentUserRootCustomer are
    // dynamically determined in this file, but you can hard-code them
    // instead by passing them in directly
    itemSkuIdsToInclude: itemSkuIdsToIncludeHardCoded,
    businessUnitIdOfCurrentUserRootCustomer: businessUnitIdOfCurrentUserRootCustomerHardCoded,
    // If the web app has no branded pics to match the business unit (which is
    // the case for all but MKM and KC), it can do one of two things: show
    // MKM-branded kegs or show no pictures at all, just the text differences.
    displayPicsOfMKMKegsRatherThanNoPicsAtAllIfBrandOfBusinessUnitPicsNotAvailable=true,
  },
) => {
  if (itemSkuId === ITEM_SKU_IDS_CBI_PLASTIC_PALLET) {
    return {}
  }

  const entireItemSkusSlice = getEntireItemSkusSlice(state)

  let itemSkuIdsToInclude
  if (itemSkuIdsToIncludeHardCoded) {
    itemSkuIdsToInclude = itemSkuIdsToIncludeHardCoded
    // If the user accidentally included ITEM_SKU_IDS_CBI_PLASTIC_PALLET in
    // itemSkuIdsToIncludeHardCoded, filter it here
    itemSkuIdsToInclude = itemSkuIdsToInclude.filter(s => s !== ITEM_SKU_IDS_CBI_PLASTIC_PALLET)
  } else {
    // We need to use this component when no user is logged in, such as in the
    // special Distributor Report Inventory form with no login required
    // (CODE_COMMENTS_267). In such a case, use sane defaults.
    if (!getIsAuthenticated(state) || !customerId) {
      itemSkuIdsToInclude = [
        ITEM_SKU_IDS_MKM_HB_DEFAULT,
        ITEM_SKU_IDS_MKM_SB_DEFAULT,
        ITEM_SKU_IDS_MKM_QB_DEFAULT,
      ]
    } else {
      itemSkuIdsToInclude = getCustomerProp(state, customerId, 'itemSkuIds')
    }
    itemSkuIdsToInclude = itemSkuIdsToInclude.filter(s => s !== ITEM_SKU_IDS_CBI_PLASTIC_PALLET)
  }


  let businessUnitIdOfCurrentUserRootCustomer
  if (businessUnitIdOfCurrentUserRootCustomerHardCoded) {
    businessUnitIdOfCurrentUserRootCustomer = businessUnitIdOfCurrentUserRootCustomerHardCoded
  } else if (
    // We need to use this component when no user is logged in, such as in the
    // special Distributor Report Inventory form with no login required
    // (CODE_COMMENTS_267). In such a case, use sane defaults.
    !getIsAuthenticated(state) || !customerId
  ) {
    businessUnitIdOfCurrentUserRootCustomer = null
  } else {
    businessUnitIdOfCurrentUserRootCustomer = getBusinessUnitIdOfRootCustomer({
      state,
      doThisRatherThanThrowErrorIfRootCustomerHasNotYetBeenFetched: () => null,
    })
  }

  return {
    entireItemSkusSlice,
    itemSkuIdsToInclude,
    businessUnitIdOfCurrentUserRootCustomer,
    displayPicsOfMKMKegsRatherThanNoPicsAtAllIfBrandOfBusinessUnitPicsNotAvailable,
  }
}

export default connect(
  mapStateToProps,
)(ContainerTypesDifferences)
