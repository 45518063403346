import React from 'react'
import { Header, Card } from 'semantic-ui-react'

import NoRepsOnFile from './NoRepsOnFile'

import ContactCard from '../../../common-components/ContactCard'

import {
  CUSTOMER_REPS_SORT_ORDER_AND_PRIORITY_ORDER,
} from '../../../constants'

import {
  sortArrayByTemplateArray,
} from '../../../utils'

export default ({
  customerId,
  displayedCustomerName,
  cardsOnTopOfRatherThanNextToEachother,
  repTypesToCreateACardFor,
}) => {
  if (!repTypesToCreateACardFor.length) { // if customer has no reps on file
    return (
      <NoRepsOnFile />
    )
  }

  const orderedRepTypesToCreateACardFor = sortArrayByTemplateArray(
    repTypesToCreateACardFor,
    CUSTOMER_REPS_SORT_ORDER_AND_PRIORITY_ORDER,
  )

  const contactCards = orderedRepTypesToCreateACardFor.map(repType => (
    <ContactCard
      key={repType}
      customerId={customerId}
      repType={repType}
    />
  ))

  if (cardsOnTopOfRatherThanNextToEachother) {
    return (
      <div>
        <Header as="h3" style={{ marginTop: '100px' }}>{displayedCustomerName}</Header>
        {contactCards}
      </div>
    )
  }

  return (
    <div>
      <Header as="h3">{displayedCustomerName}</Header>
      <Card.Group itemsPerRow={4}>
        {contactCards}
      </Card.Group>
    </div>
  )
}
