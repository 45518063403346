import React from 'react'

// import isNumber_ from 'lodash/isNumber'
// import orderBy_ from 'lodash/orderBy'


import DownloadInvoiceButton from './components/DownloadInvoiceButton'

import {
  createDisplayedInvoiceDate,
  // createDisplayedInvoicePreviousBalance,
  createDisplayedInvoiceCharges,
  // createDisplayedInvoiceDebitsAndCredits,
  // createDisplayedInvoicePayments,
  // createDisplayedInvoiceBalance,

  createInvoiceHistoryTableDollarAmountFieldSortFunction,
  createDisplayedCustomerNumber,
  createDisplayedCustomerName,
  createDisplayedInvoiceNumber,
  createDisplayedDueDate,
} from '../../util/invoiceHistory'

import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../util'
import { CUSTOMER_TYPES_MASTER } from '../../../../../constants'


export function createTableAndDownloadFileContentDefinitions({
  tableOrDownloadFile,
  customerId,
  customerType,
  currency,
}) {
  return {
    content: [
      ...(customerType === CUSTOMER_TYPES_MASTER ? [
        {
          heading: 'Customer Name',
          cellContent: createDisplayedCustomerName,
          customSortInfo: {
            columnNotSortable: true,
          },
          includeInTotalsRow: false,
          className: 'customerName', // CODE_COMMENTS_57
        },
        {
          heading: 'Customer Number',
          cellContent: createDisplayedCustomerNumber,
          customSortInfo: {
            columnNotSortable: true,
          },
          includeInTotalsRow: false,
          className: 'customerNumber', // CODE_COMMENTS_57
        },
      ] : []),
      {
        heading: 'Invoice Number',
        cellContent: createDisplayedInvoiceNumber,
        customSortInfo: {
          columnNotSortable: true,
        },
        includeInTotalsRow: false,
        className: 'testInvoiceNumber',
      },
      {
        heading: 'Invoice Date',
        cellContent: createDisplayedInvoiceDate(tableOrDownloadFile),
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'invoiceDate' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        includeInTotalsRow: false,
        className: 'testInvoiceDate', // CODE_COMMENTS_57
      },
      {
        heading: 'Due Date',
        cellContent: createDisplayedDueDate(tableOrDownloadFile),
        customSortInfo: {
          sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'dueDate' }),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        includeInTotalsRow: false,
        className: 'testDueDate',
      },
      // ...(customerType !== CUSTOMER_TYPES_DISTRIBUTOR
      //   ? [{
      //     heading: 'Previous Balance',
      //     cellContent: createDisplayedInvoicePreviousBalance(tableOrDownloadFile, currency),
      //     customSortInfo: {
      //       sortFunction: createInvoiceHistoryTableDollarAmountFieldSortFunction('previousBalance'),
      //       sortApiObjectValuesRatherThanCellContents: true,
      //     },
      //     includeInTotalsRow: false, // CODE_COMMENTS_249
      //     className: 'testInvoicePreviousBalance', // CODE_COMMENTS_57
      //   }]
      //   : []
      // ),
      {
        heading: 'Charges',
        cellContent: createDisplayedInvoiceCharges(tableOrDownloadFile, currency),
        customSortInfo: {
          sortFunction: createInvoiceHistoryTableDollarAmountFieldSortFunction('currentCharges'),
          sortApiObjectValuesRatherThanCellContents: true,
        },
        includeInTotalsRow: false, // CODE_COMMENTS_249
        className: 'testInvoiceCharges', // CODE_COMMENTS_57
      },
      // {
      //   heading: 'Debits/Credits',
      //   cellContent: createDisplayedInvoiceDebitsAndCredits(tableOrDownloadFile, currency),
      //   customSortInfo: {
      //     sortFunction: (rowsArray, ascOrDesc='desc') => (
      //       orderBy_(
      //         rowsArray,
      //         row => {
      //           if (isNumber_(row.debits) || isNumber_(row.credits)) {
      //             return (row.debits || 0) + (row.credits || 0)
      //           }
      //           return -999999999999 // CODE_COMMENTS_250
      //         },
      //         [ascOrDesc],
      //       )
      //     ),
      //     sortApiObjectValuesRatherThanCellContents: true,
      //   },
      //   includeInTotalsRow: false, // CODE_COMMENTS_249
      //   className: 'testInvoiceDebitsCredits', // CODE_COMMENTS_57
      // },
      // {
      //   heading: 'Payments',
      //   cellContent: createDisplayedInvoicePayments(tableOrDownloadFile, currency),
      //   customSortInfo: {
      //     sortFunction: createInvoiceHistoryTableDollarAmountFieldSortFunction('payments'),
      //     sortApiObjectValuesRatherThanCellContents: true,
      //   },
      //   includeInTotalsRow: false, // CODE_COMMENTS_249
      //   className: 'testInvoicePayments', // CODE_COMMENTS_57
      // },
      // ...(customerType !== CUSTOMER_TYPES_DISTRIBUTOR
      //   ? [{
      //     heading: 'Balance',
      //     cellContent: createDisplayedInvoiceBalance(tableOrDownloadFile, currency),
      //     customSortInfo: {
      //       sortFunction: createInvoiceHistoryTableDollarAmountFieldSortFunction('balance'),
      //       sortApiObjectValuesRatherThanCellContents: true,
      //     },
      //     includeInTotalsRow: false, // CODE_COMMENTS_249
      //     className: 'testInvoiceBalance', // CODE_COMMENTS_57
      //   }]
      //   : []
      // ),
      ...(
        tableOrDownloadFile === 'table'
          ? [{
            heading: 'Download PDF',
            cellContent: row => (
              <DownloadInvoiceButton
                customerId={customerId}
                info={row}
              />
            ),
            customSortInfo: { columnNotSortable: true },
            className: 'testKegCollarOrderNumber', // CODE_COMMENTS_57
          }]
          : []
      ),
    ],
    defaultSortColumn: 'Invoice Date',
    rowKey: row => row.id,
  }
}
