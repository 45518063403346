import { connect } from 'react-redux'

import FormDecider from '../components/FormDecider'

import fetchStatusSelectors from '../../../../redux/selectors/fetchStatuses/publicForms'

import {
  REDUCER_NAMES_PUBLIC_FORMS_CONFIRM_ACCOUNT,
} from '../../../../constants'


const {
  getFetchStatuses,
} = fetchStatusSelectors


const mapStateToProps = state => {
  const fetchStatusesConfirmAccountForm = getFetchStatuses(state, REDUCER_NAMES_PUBLIC_FORMS_CONFIRM_ACCOUNT)

  return {
    hasConfirmAccountFormFetchSucceeded: fetchStatusesConfirmAccountForm.didFetchSucceed,
  }
}

export default connect(
  mapStateToProps,
)(FormDecider)
