import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

import RouteNotFound from '../../../RouteNotFound'
import PrivateRoute from '../../../../common-components/routes/PrivateRoute'


import EmployeeDashboard from './EmployeeDashboard'
import OperateAsCustomerUser from '../../../employee/OperateAsCustomerUser'


import {
  URL_PATHS,
} from '../../../../constants'


// CODE_COMMENTS_26
const getRoutesList = props => (
  [
    <PrivateRoute
      exact
      key={URL_PATHS.employeeRoot.path}
      path={URL_PATHS.employeeRoot.path}
      render={
        routeProps => (
          <EmployeeDashboard
            {...props}
            {...routeProps}
          />
        )
      }
    />,
    <PrivateRoute
      exact
      key={URL_PATHS.employeeOperateAsCustomerUser.path}
      path={URL_PATHS.employeeOperateAsCustomerUser.path}
      render={
        routeProps => (
          <OperateAsCustomerUser
            {...props}
            {...routeProps}
          />
        )
      }
    />,
  ]
)

export default withRouter(props => (
  <Switch>
    {getRoutesList(props)}
    <RouteNotFound />
  </Switch>
))
