import React from 'react'

import ToCustomerPortalButton from '../AfterSubmissionButtons/ToCustomerPortalButton'
import ResetFormButton from '../AfterSubmissionButtons/ResetFormButton'

export default ({
  onResetForm,
  // these will only be passed in if this is an "edit existing item" form
  isEditForm,
  confirmClearForm,

  // This will only be passed in if this modal is for deleting/canceling an item
  itemIdIfThisIsForDeletingAnItem,

  // This will only be passed in if this is an "edit existing item" form or a
  // "delete item" form
  closeModalFunc,
}) => {
  if (isEditForm) {
    return [
      <ToCustomerPortalButton
        additionalActions={closeModalFunc}
        key="tcp"
        confirm={confirmClearForm}
      />,
      <ResetFormButton
        onResetForm={onResetForm}
        buttonAndMessageText={{
          buttonText: 'Re-Edit Item',
        }}
        key="cf"
      />,
    ]
  }

  if (itemIdIfThisIsForDeletingAnItem) {
    return (
      <ToCustomerPortalButton
        additionalActions={closeModalFunc}
        key="tcp"
        confirm={confirmClearForm}
      />
    )
  }

  return [
    <ToCustomerPortalButton
      key="tcp"
    />,
    <ResetFormButton
      onResetForm={onResetForm}
      confirm={confirmClearForm}
      key="cf"
    />,
  ]
}
