import { put, call, takeEvery, select } from 'redux-saga/effects'
import moment from 'moment'


import { privateFetch } from '../../fetch'
import {
  FETCH_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY,
  SAVE_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA_OF_ONE_CUSTOMER,
  FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_REQUEST,
  FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_SUCCESS,
  FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_FAILURE,
} from '../../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'

import {
  getProp as getCustomerProp,
} from '../../../selectors/customers'

import {
  API_URL_PATH_DATA_WAREHOUSE,
} from '../../../../constants'
import {
  REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_MAX_NUM_PAST_MONTHS_TO_DISPLAY,
} from '../../../../config'
import {
  convertApiDateToMoment,
  formatDateForApiCall,
} from '../../../../utils'
import {
  XhrReplicationError,
} from '../../../../customErrors'


export function* fetchReportedInventoryVsCalculatedInventory(action) {
  const {
    customerId,
  } = action.payload

  yield put(createAction(
    FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_REQUEST,
    {
      target: [customerId],
    },
  ))

  const fetchConfig = yield call(createFetchConfig, customerId)

  let response
  try {
    response = yield call(privateFetch, fetchConfig)
  } catch (error) {
    if (error.response) {
      yield call(doFailure, { customerId, error })
      return
    }
    yield call(doFailure, { customerId, error })
    return
  }
  yield call(doSuccess, customerId, response)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY, fetchReportedInventoryVsCalculatedInventory],
]


function* doSuccess(customerId, httpResponse) {
  const responseBody = httpResponse.data
  // This is a weird instance where the API call can technically succeed with a
  // 200 response but the response body indicates failure. The response body of
  // this call will always contain a 'statusCode' prop, and if the value is
  // anything but 200, the call has failed.
  if (responseBody.statusCode !== 200) {
    // CODE_COMMENTS_149
    const error = new XhrReplicationError({
      response: {
        status: 'xhr error',
        statusText: `${httpResponse.config.url} has returned a ${responseBody.statusCode} error: ${responseBody.message}`,
        data: responseBody,
        headers: {},
      },
      config: httpResponse.config,
    })
    yield call(doFailure, { customerId, error })
    return
  }
  const info = responseBody.body
  yield put(createAction(SAVE_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA_OF_ONE_CUSTOMER, { customerId, info }))
  yield put(createAction(
    FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_SUCCESS,
    {
      target: [customerId],
    },
  ))
}

function* doFailure({ customerId, error }) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_FAILURE,
        target: [customerId],
      }),
    },
  )
}


// Helper functions

function* createFetchConfig(customerId) {
  const startDate = yield call(getStartDateOfDataToFetch, customerId)
  return {
    path: API_URL_PATH_DATA_WAREHOUSE,
    data: {
      event_type: 'BREWER_INVENTORY',
      customerId,
      startDate: formatDateForApiCall({
        date: startDate,
        startOfDayRatherThanNoonLocalTime: true,
      }),
    },
    // There's no good reason for this to be a POST--we're not creating a
    // resource, we're just fetching info, so it should really be a GET; this is
    // just a rare instance where the backend doesn't follow best practice.
    method: 'POST',
  }
}


// Returns a moment object of the max of the customer's 'invReconciliationDate'
// prop or
// REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_MAX_NUM_PAST_MONTHS_TO_DISPLAY
function* getStartDateOfDataToFetch(customerId) {
  let invoiceReconciliationDate = yield select(getCustomerProp, customerId, 'invReconciliationDate')
  invoiceReconciliationDate = convertApiDateToMoment(invoiceReconciliationDate)
  const minimumDateToDisplay = moment().subtract(REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_MAX_NUM_PAST_MONTHS_TO_DISPLAY, 'months')
  return invoiceReconciliationDate.isAfter(minimumDateToDisplay)
    ? invoiceReconciliationDate
    : minimumDateToDisplay
}
