/* eslint-disable max-len */

import { call, put, all, select } from 'redux-saga/effects'


import { fetchCustomerInfo } from '../../customers'
import {
  fetchChildrenOfCustomer,
  fetchAddressesOfCustomer,
  fetchContractsOfCustomer,
  fetchRelatedToInfoOfCustomer,
  fetchRelatedFromInfoOfCustomer,
  fetchInboundUnacknowledgedShipmentsOfCustomer,
  fetchCollarPlatesOfCustomer,
  fetchCustomerUsersAndTheirPermissions,
  fetchContacts,
  fetchCustomerBalances,
  fetchBankAccountInfo,
  fetchCustomerMessages,
  fetchPotentialDuplicatesForReportShipmentsForms,
  fetchReportedInventoryVsCalculatedInventoryOfCustomer, fetchOrderKegsUserPermission,
} from '../fetchIndividualEntityTypes'

import {
  withPrelimCustomerInfoFetchStartedAndFinishedFlags,
  callMultipleFetchesAtTheSameTime,
} from '../util'

import customerFetchStatusesSelectors from '../../../../selectors/fetchStatuses/customers/customers'

import {
  FETCH_INFO_OF_ALL_REPRESENTATIVES_OF_ONE_CUSTOMER,
} from '../../../../actions/actionTypes'
import createAction from '../../../../actions/createAction'


import { PrelimInfoFetchNeededForRenderingDashboardFailedError } from '../../../../../customErrors'


const { getFetchStatuses: getCustomerFetchStatuses } = customerFetchStatusesSelectors

/**
* Fetch all preliminary info in the correct order (e.g. customer info first,
* then addresses and other info that relies on customer info). If any one of
* these fetchings fails, cancel all the others and throw the
* PrelimInfoFetchNeededForRenderingDashboardFailedError that bubbles up.
 */
export const fetchPrelimInfoOfAnyCustomerTypeBesidesContracteeBrewer = withPrelimCustomerInfoFetchStartedAndFinishedFlags(
  function* fetchPrelimInfoOfAnyCustomerTypeBesidesContracteeBrewerWrappedSaga(customerId) {
    yield all([
      // Put all sagas here that can happen at the same time as fetching
      // customer info
      call(fetchCustomerThenInfoWhichDependsOnCustomer, customerId),
    ])
    // Why are we using call here? Shouldn't this be a non-blocking call, so
    // shouldn't we use PUT? No, because
    // makeFetchesThatMustHappenAfterAllPrelimInfoHasBeenFetched() is itself a
    // non-blocking call, so it will start whatever fetches it needs to and then
    // return immediately.
    yield call(makeFetchesThatMustHappenAfterAllPrelimInfoHasBeenFetched, customerId)
  },
)


function* fetchCustomerThenInfoWhichDependsOnCustomer(customerId) {
  // Fetching the customer info should be a blocking call here, because we need
  // that info before fetching the children, because the customer info tells us
  // the customer's type (master account, brewer, distributor, etc), and the
  // customerType determines whether we should attempt to fetch children at all
  // (only certain customer types will have children). Note that the reason this
  // is a blocking call is not just because we're using redux-saga's call()
  // here. Using call() here is necessary, but not sufficient. We also need to
  // make sure that the fetchCustomerInfo() function (an import from another
  // file) is a blocking fetch function. That is, fetchCustomerInfo can't simply
  // call `yield put(THE_FETCH_ACTION)`, because that would be non-blocking; it
  // itself must wait for the API fetch to finish before returning.
  yield call(fetchCustomerInfo, { payload: { customerId, preventsDashboardFromLoadingIfFails: true } })
  const customerFetchStatuses = yield select(getCustomerFetchStatuses, customerId)
  if (customerFetchStatuses.didFetchFail) {
    throw new PrelimInfoFetchNeededForRenderingDashboardFailedError()
  }
  yield call(makePostCustomerFetches, customerId)
}


function* makePostCustomerFetches(customerId) {
  yield callMultipleFetchesAtTheSameTime({
    generatorsOfNonBlockingSagasWhoseFailureWontResultInTheFailureOfTheWrappingSaga: [
      put(createAction(FETCH_INFO_OF_ALL_REPRESENTATIVES_OF_ONE_CUSTOMER, { customerId })),
      // Why are we using call() here? Isn't call() a blocking function? We want
      // these to be run at the same time as the functions within the `yield
      // all(...)` below, so shouldn't we be using put() here instead of call()?
      // No, because the functions called by these `call()`s are themselves
      // non-blocking (i.e. they use put()).
      call(fetchContractsOfCustomer, { customerId, preventsDashboardFromLoadingIfFails: true }),
      call(fetchCollarPlatesOfCustomer, customerId),
      call(fetchCustomerUsersAndTheirPermissions, customerId),
      call(fetchContacts, customerId),
      call(fetchCustomerBalances, customerId),
      call(fetchOrderKegsUserPermission, customerId),
      call(fetchBankAccountInfo, customerId),
      call(fetchCustomerMessages, customerId),
      call(fetchReportedInventoryVsCalculatedInventoryOfCustomer, customerId),
    ],
    generatorsOfBlockingSagasWhoseFailureWillResultInTheFailureOfTheWrappingSaga: [
      call(fetchChildrenOfCustomer, { customerId, preventsDashboardFromLoadingIfFails: true }),
      call(fetchAddressesOfCustomer, { customerId, preventsDashboardFromLoadingIfFails: true }),
      call(fetchRelatedToInfoOfCustomer, { customerId, preventsDashboardFromLoadingIfFails: true }),
      call(fetchRelatedFromInfoOfCustomer, { customerId, preventsDashboardFromLoadingIfFails: true }),
    ],
  })

  // We don't want fetchInboundUnacknowledgedShipmentsOfCustomer to fetch
  // anything if the customer doesn't have a default PPF contract, so we need to
  // fetch the customer's contract info before running
  // fetchInboundUnacknowledgedShipmentsOfCustomer.
  yield callMultipleFetchesAtTheSameTime({
    generatorsOfBlockingSagasWhoseFailureWillResultInTheFailureOfTheWrappingSaga: [
      call(fetchInboundUnacknowledgedShipmentsOfCustomer, customerId),
    ],
  })
}

// Put all fetches here that require that prelim info be fetched (e.g. children,
// addresses, relationships) before they can run.
function* makeFetchesThatMustHappenAfterAllPrelimInfoHasBeenFetched(customerId) {
  yield callMultipleFetchesAtTheSameTime({
    // Put all fetches in here that can run _after_ the dashboard has loaded, as
    // the user starts using the app.
    generatorsOfNonBlockingSagasWhoseFailureWontResultInTheFailureOfTheWrappingSaga: [
      // Why are we using call() here? Isn't call() a blocking function? We want
      // these to be run at the same time as the functions within the `yield
      // all(...)` below, so shouldn't we be using put() here instead of call()?
      // No, because the functions called by these `call()`s are themselves
      // non-blocking (i.e. they use put()).
      call(fetchPotentialDuplicatesForReportShipmentsForms, customerId), // CODE_COMMENTS_244
    ],
    // Intentionally an empty array, which ensures that the wrapping saga is
    // non-blocking.
    generatorsOfBlockingSagasWhoseFailureWillResultInTheFailureOfTheWrappingSaga: [],
  })
}
