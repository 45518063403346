import { call, put } from 'redux-saga/effects'

import { privateFetch } from './fetch'

import {
  FETCH_STATUSES_FORMS_REQUEST,
  FETCH_STATUSES_FORMS_SUCCESS,
  FETCH_STATUSES_FORMS_FAILURE,
} from '../actions/actionTypes'

import {
  createHeadersForContracteeBrewerApiCall,
} from './util/headersAndQueryParamsOfApiCalls/contracteeBrewers'

import {
  createFetchFailureAction,
  generalDoFailure,
} from './util/fetchFailure'
import createAction from '../actions/createAction'

import {
  REDUCER_NAMES_FORMS_DELETE_HISTORY_ITEM,
} from '../../constants'

import {
  createFormNameForRedux,
} from '../../utils'


export const delteHistoryItemSagaCreator = (
  getItemId,
  urlCreator,
  doOnSuccess,
) => (
  function* saga(action) {
    const { customerId, operatingContractBrewerCustomerId } = action.payload
    const itemId = getItemId(action)

    // CODE_COMMENTS_196
    const formName = createFormNameForRedux({
      reducerName: REDUCER_NAMES_FORMS_DELETE_HISTORY_ITEM,
      customerId,
      operatingContractBrewerCustomerId,
      additionalIdentifier: itemId,
    })

    yield put(createAction(
      FETCH_STATUSES_FORMS_REQUEST,
      {
        target: [formName], // CODE_COMMENTS_196
      },
    ))


    const fetchConfig = {
      path: urlCreator(action),
      method: 'DELETE',
    }

    // CODE_COMMENTS_92
    if (operatingContractBrewerCustomerId) {
      const headers = createHeadersForContracteeBrewerApiCall(customerId)
      fetchConfig.headers = headers
    }

    try {
      yield call(
        privateFetch,
        fetchConfig,
      )
    } catch (error) {
      yield call(doFailure, error, formName)
      return
    }
    yield call(doSuccess, formName, doOnSuccess, action)
  }
)

function* doSuccess(
  formName,
  doOnSuccess,
  action,
) {
  if (doOnSuccess) {
    yield call(doOnSuccess, action, formName)
  }
  // We specifically do not want to set the item to 'Canceled' status in the
  // store here; see CODE_COMMENTS_69
  yield put(createAction(
    FETCH_STATUSES_FORMS_SUCCESS,
    {
      target: [formName], // CODE_COMMENTS_196
    },
  ))
}

function* doFailure(error, formName) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_FORMS_FAILURE,
        target: [formName],
      }),
    },
  )
}
