/* eslint-disable max-len */

import clone_ from 'lodash/clone'
import pull_ from 'lodash/pull'
import difference_ from 'lodash/difference'


import {
  getAllChildCustomerIdsOfCustomer,
} from '../../redux/selectors/rewrite/children'
import {
  getHasPermissionsToPerformManageUsersPermissionsChoice,
  getPermissionsMap,
  getEntirePerCustomerPermissionsMap,
} from '../../redux/selectors/rewrite/permissions'
import {
  getCustomerIdsOfAllContracteesOfContractBrewerThatKegFillsCanBeReportedOn,
} from '../../redux/selectors/rewrite/relationships/relatedFromInfo'
import {
  getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToViewInventoryReportHistory,
  getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToReportInventory,
} from '../../redux/selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'

import {
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER,
  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER,

  MANAGE_USERS_PERMISSIONS_CHOICE_TO_HUMAN_READABLE_LABEL_MAP,
  MANAGE_USERS_PERMISSIONS_CHOICE_TO_CATEGORY_MAP,

  MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS,
  MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_FILLS,

  PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,
  PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME,

  DISPLAYED_PERMISSIONS_DEFS_KEY_ROOT_CUSTOMER_OF_USER,
  DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF,
} from '../../constants/permissions'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_MASTER,
  CONCATENATED_STRING_SEPARATOR,
} from '../../constants'

import {
  getAllManageUsersPermissionsChoicesForAUserBasedOnRootCustomerType,
  getAllManageUsersPermissionsChoicesUserOrCustomerHasPermissionsToDo,
  combinePermissionsOfMultipleManageUsersPermissionsChoices,

  getAllPermissionsNeededForManageUsersPermissionsChoice,
  mergePermissionsObjects,
  removeAllPermissionsOfOnePermissionsObjectFromAnotherPermissionsObject,

  determineRootCustomerTypeOfUser,
  determineRootCustomerIdOfUser,
} from '../../utils/permissions'

import {
  isTruthyAndNonEmpty,
} from '../../utils'


/*
 * *****************************************************************************
 * Granting Permissions
 * *****************************************************************************
*/

export function createUpdatedPermissionsMapByGrantingPermissionsForAnManageUsersPermissionsChoice({
  entirePermissionsSlice,
  entireCurrentUserSlice,
  userIdIfNotCurrentUser,
  // pass in a `permissionsMap` only, no `perCustomerPermissionsMap`. But see
  // CODE_COMMENTS_166
  customPermissionsMapToUseInsteadOfMapInReduxStore,
  manageUsersPermissionsChoice,
}) {
  let permissionsMapToUse
  if (customPermissionsMapToUseInsteadOfMapInReduxStore) {
    permissionsMapToUse = customPermissionsMapToUseInsteadOfMapInReduxStore
  } else {
    permissionsMapToUse = userIdIfNotCurrentUser
      ? getPermissionsMap({
        entirePermissionsSlice,
        entireCurrentUserSlice,
        userIdIfNotCurrentUser,
      })
      : entireCurrentUserSlice[PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]
  }

  const permissionsToAdd = getAllPermissionsNeededForManageUsersPermissionsChoice(
    manageUsersPermissionsChoice,
  )
  return mergePermissionsObjects([
    permissionsMapToUse,
    permissionsToAdd,
  ])
}


export function createUpdatedPerCustomerPermissionsMapByGrantingPermissionsForAnManageUsersPermissionsChoiceToASingleCustomer({
  entirePermissionsSlice,
  entireCurrentUserSlice,
  userIdIfNotCurrentUser,
  // pass in a `perCustomerPermissionsMap` only, no `permissionsMap`. But
  // see CODE_COMMENTS_166
  customPerCustomerPermissionsMapToUseInsteadOfMapInReduxStore,
  manageUsersPermissionsChoice,
  customerIdOfCustToOperateOnBehalfOf,
}) {
  let perCustomerPermissionsMapToUse
  if (customPerCustomerPermissionsMapToUseInsteadOfMapInReduxStore) {
    perCustomerPermissionsMapToUse = customPerCustomerPermissionsMapToUseInsteadOfMapInReduxStore
  } else {
    perCustomerPermissionsMapToUse = userIdIfNotCurrentUser
      ? getEntirePerCustomerPermissionsMap({
        entirePermissionsSlice,
        entireCurrentUserSlice,
        userIdIfNotCurrentUser,
      })
      : entireCurrentUserSlice[PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]
  }

  const perCustomerPermissionsMapOfThisCustomer =
    perCustomerPermissionsMapToUse[customerIdOfCustToOperateOnBehalfOf] || {}

  const permissionsToAdd = getAllPermissionsNeededForManageUsersPermissionsChoice(
    manageUsersPermissionsChoice,
  )
  const newPerCustomerPermissionsMapOfThisCustomer = mergePermissionsObjects([
    perCustomerPermissionsMapOfThisCustomer,
    permissionsToAdd,
  ])

  return {
    ...perCustomerPermissionsMapToUse,
    [customerIdOfCustToOperateOnBehalfOf]: newPerCustomerPermissionsMapOfThisCustomer,
  }
}


/*
 * *****************************************************************************
 * Revoking Permissions
 * *****************************************************************************
*/


// Does so without inadvertently revoking permissions for another choice the
// user is currently allowed to do. This returns a `permissionsMap` only, not a
// `perCustomerPermissionsMap`. For `perCustomerPermissionsMap`, use
// createUpdatedPerCustomerPermissionsMapByRevokingPermissionsForAnManageUsersPermissionsChoiceFromASingleCustomer()
export function createUpdatedPermissionsMapByRevokingPermissionsForAnManageUsersPermissionsChoice({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  userIdIfNotCurrentUser,
  // pass in a `permissionsMap` only, no `perCustomerPermissionsMap`. But see
  // CODE_COMMENTS_166
  customPermissionsMapToUseInsteadOfMapInReduxStore,
  manageUsersPermissionsChoice,
}) {
  let permissionsMapToUse
  if (customPermissionsMapToUseInsteadOfMapInReduxStore) {
    permissionsMapToUse = customPermissionsMapToUseInsteadOfMapInReduxStore
  } else {
    permissionsMapToUse = userIdIfNotCurrentUser
      ? getPermissionsMap({
        entirePermissionsSlice,
        entireCurrentUserSlice,
        userIdIfNotCurrentUser,
      })
      : entireCurrentUserSlice[PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]
  }

  const permissionsToRemove =
    getAllPermissionsToRevokeWhenRevokingSingleManageUsersPermissionsChoiceInSuchAWayThatOtherChoicesArentInadvertentlyRevoked({
      entireCustomersSlice,
      entireParentChildLinksSlice,
      entireCurrentUserSlice,
      entireUsersSlice,
      entirePermissionsSlice,
      userIdIfNotCurrentUser,
      // CODE_COMMENTS_166
      customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore: {
        [PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]: permissionsMapToUse,
        [PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]: {},
      },
      manageUsersPermissionsChoice,
    })

  return removeAllPermissionsOfOnePermissionsObjectFromAnotherPermissionsObject(
    permissionsMapToUse,
    permissionsToRemove,
  )
}


// Does so without inadvertently revoking permissions for another choice the
// user is currently allowed to do. This returns a `perCustomerPermissionsMap`
// only, not a `permissionsMap`. For `permissionsMap`, use
// createUpdatedPermissionsMapByRevokingPermissionsForAnManageUsersPermissionsChoice()
export function createUpdatedPerCustomerPermissionsMapByRevokingPermissionsForAnManageUsersPermissionsChoiceFromASingleCustomer({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  userIdIfNotCurrentUser,
  // pass in a `perCustomerPermissionsMap` only, no `permissionsMap`. But
  // see CODE_COMMENTS_166
  customPerCustomerPermissionsMapToUseInsteadOfMapInReduxStore,
  manageUsersPermissionsChoice,
  customerIdOfCustToOperateOnBehalfOf,
}) {
  let perCustomerPermissionsMapToUse
  if (customPerCustomerPermissionsMapToUseInsteadOfMapInReduxStore) {
    perCustomerPermissionsMapToUse = customPerCustomerPermissionsMapToUseInsteadOfMapInReduxStore
  } else {
    perCustomerPermissionsMapToUse = userIdIfNotCurrentUser
      ? getEntirePerCustomerPermissionsMap({
        entirePermissionsSlice,
        entireCurrentUserSlice,
        userIdIfNotCurrentUser,
      })
      : entireCurrentUserSlice[PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]
  }

  const perCustomerPermissionsMapOfThisCustomer =
    perCustomerPermissionsMapToUse[customerIdOfCustToOperateOnBehalfOf] || {}

  const permissionsToRemove =
    getAllPermissionsToRevokeWhenRevokingSingleManageUsersPermissionsChoiceInSuchAWayThatOtherChoicesArentInadvertentlyRevoked({
      entireCustomersSlice,
      entireParentChildLinksSlice,
      entireCurrentUserSlice,
      entireUsersSlice,
      entirePermissionsSlice,
      userIdIfNotCurrentUser,
      // CODE_COMMENTS_166
      customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore: {
        [PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]: {},
        [PER_CUSTOMER_PERMISSIONS_MAP_REDUX_STORE_PROP_NAME]: perCustomerPermissionsMapToUse,
      },
      manageUsersPermissionsChoice,
      customerIdIfThisIsAPerCustomerPermissionsCheck: customerIdOfCustToOperateOnBehalfOf,
    })

  const newPerCustomerPermissionsMapOfThisCustomer = removeAllPermissionsOfOnePermissionsObjectFromAnotherPermissionsObject(
    perCustomerPermissionsMapOfThisCustomer,
    permissionsToRemove,
  )

  return {
    ...perCustomerPermissionsMapToUse,
    [customerIdOfCustToOperateOnBehalfOf]: newPerCustomerPermissionsMapOfThisCustomer,
  }
}


// Helper functions for this section


// Returns a single object of all permissions that can be safely revoked so that
// only this single Manage Users permissions choice is revoked, none others.
// For instance, let's say a user has these permissions (among others), which
// are necessary for creating, editing and deleting shipments:

// { SHIPMENTS: ['CREATE', 'UPDATE', 'DELETE'], RELATIONSHIPS: ['READ'] }

// You want to revoke their ability to create/edit/delete shipments, so you pass
// in MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_SHIPMENTS as the
// manageUsersPermissionsChoice argument. This function will not blindly return:

// { SHIPMENTS: ['CREATE', 'UPDATE', 'DELETE'], RELATIONSHIPS: ['READ'] }

// Rather, it will

// { SHIPMENTS: ['CREATE', 'UPDATE', 'DELETE'] }
function getAllPermissionsToRevokeWhenRevokingSingleManageUsersPermissionsChoiceInSuchAWayThatOtherChoicesArentInadvertentlyRevoked({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  userIdIfNotCurrentUser,
  customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore, // CODE_COMMENTS_166
  manageUsersPermissionsChoice,
  customerIdIfThisIsAPerCustomerPermissionsCheck,
}) {
  const allChoicesUserOrCustomerHasPermissionsToDo = getAllManageUsersPermissionsChoicesUserOrCustomerHasPermissionsToDo({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entirePermissionsSlice,
    userIdIfNotCurrentUser,
    customPermissionsMapAndPerCustomerPermissionsMapToUseInsteadOfMapsInReduxStore, // CODE_COMMENTS_166
    customerIdIfThisIsAPerCustomerPermissionsCheck,
  })

  const allWithoutTargetChoice = pull_(
    clone_(allChoicesUserOrCustomerHasPermissionsToDo), // avoid manipulating arrays directly when possible
    manageUsersPermissionsChoice,
  )

  const permissionsThatCannotBeRevoked = combinePermissionsOfMultipleManageUsersPermissionsChoices(
    allWithoutTargetChoice,
  )

  const permissionsWeWantToRevoke = combinePermissionsOfMultipleManageUsersPermissionsChoices(
    [manageUsersPermissionsChoice],
  )

  const whichPermissionsShouldBeRevoked = Object.keys(permissionsWeWantToRevoke).reduce(
    (acc, permissionString) => ({
      ...acc,
      [permissionString]: difference_(
        permissionsWeWantToRevoke[permissionString],
        permissionsThatCannotBeRevoked[permissionString] || [],
      ),
    }),
    {},
  )

  return whichPermissionsShouldBeRevoked
}


/*
 * *****************************************************************************
 * Displayed Permissions Definitions
 * *****************************************************************************
*/


// Used by the ManageUsers feature. Returns an object with this shape:
//
// {
//   rootCustomerOfUser: [
//     {
//       manageUsersPermissionsChoice: 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_SHIPMENTS',
//       label: 'View Shipments',
//       category: 'Shipments',
//       hasPermission: true,
//     },
//     ...
//   ],
//   customersToOperateOnBehalfOf: {
//     contracteeId1: {
//       name: 'Good Beer Brewing'
//       manageUsersPermissionsChoices: [
//         {
//           manageUsersPermissionsChoice: 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS',
//           label: 'View Keg Collar Orders',
//           category: 'Collars',
//           hasPermission: true,
//         },
//         ...
//       ],
//     },
//     contracteeId1: {
//       name: 'Awesome Time Brewing'
//       manageUsersPermissionsChoices: [
//         {
//           manageUsersPermissionsChoice: 'MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_COLLAR_ORDERS',
//           label: 'View Keg Collar Orders',
//           category: 'Collars',
//           hasPermission: true,
//         },
//         ...
//       ],
//     },
//   }
// }
export function createDisplayedManageUsersPermissionsDefinitionsOfOneUser({
  entireCustomersSlice,
  entireContractsSlice,
  entireParentChildLinksSlice,
  entireRelationshipsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  userIdIfNotCurrentUser,
}) {
  const rootCustomerType = determineRootCustomerTypeOfUser({
    entireCustomersSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
  })
  const rootCustomerCheckboxDefinitions = getRootCustomerCheckboxDefinitions({
    entireCustomersSlice,
    entireParentChildLinksSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entirePermissionsSlice,
    userIdIfNotCurrentUser,
  })
  const checkboxDefinitionsOfCustomersToOperateOnBehalfOf =
    getCheckboxDefinitionsOfCustomersToOperateOnBehalfOf({
      entireCustomersSlice,
      entireContractsSlice,
      entireParentChildLinksSlice,
      entireRelationshipsSlice,
      entireCurrentUserSlice,
      entireUsersSlice,
      entirePermissionsSlice,
      rootCustomerType,
      userIdIfNotCurrentUser,
    })

  // CODE_COMMENTS_173
  const rootCustomerCheckboxDefinitionsWithSpecialInventoryChecks = alterRootCustomerPermissionsDefsConcerningInventoryIfNecessary({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
    originalRootCustomerCheckboxDefinitions: rootCustomerCheckboxDefinitions,
  })

  return {
    [DISPLAYED_PERMISSIONS_DEFS_KEY_ROOT_CUSTOMER_OF_USER]: rootCustomerCheckboxDefinitionsWithSpecialInventoryChecks,
    [DISPLAYED_PERMISSIONS_DEFS_KEY_CUSTOMERS_TO_OPERATE_ON_BEHALF_OF]: checkboxDefinitionsOfCustomersToOperateOnBehalfOf,
  }
}


// Helper functions for this section

// see docstring of calling function for details
function getRootCustomerCheckboxDefinitions({
  entireCustomersSlice,
  entireParentChildLinksSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  userIdIfNotCurrentUser,
}) {
  const allPotentialManageUsersPermissionsChoicesForThisCustomer =
    getAllManageUsersPermissionsChoicesForAUserBasedOnRootCustomerType({
      entireCustomersSlice,
      entireParentChildLinksSlice,
      entireCurrentUserSlice,
      entireUsersSlice,
      userIdIfNotCurrentUser,
    })

  return createManageUsersPermissionsChoicesDefinitionsForOneCustomer({
    entireCustomersSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entirePermissionsSlice,
    allPotentialManageUsersPermissionsChoicesForThisCustomer,
    userIdIfNotCurrentUser,
  })
}


// see docstring of calling function for details
function getCheckboxDefinitionsOfCustomersToOperateOnBehalfOf({
  entireCustomersSlice,
  entireContractsSlice,
  entireParentChildLinksSlice,
  entireRelationshipsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  rootCustomerType,
  userIdIfNotCurrentUser,
}) {
  const rootCustomerId = determineRootCustomerIdOfUser({
    entireCurrentUserSlice,
    entireUsersSlice,
    userIdIfNotCurrentUser,
  })
  if (rootCustomerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    const toReturn = getCheckboxDefinitionsOfCustomersToOperateOnBehalfOfContractBrewer({
      entireCustomersSlice,
      entireContractsSlice,
      entireParentChildLinksSlice,
      entireRelationshipsSlice,
      entireCurrentUserSlice,
      entireUsersSlice,
      entirePermissionsSlice,
      userIdIfNotCurrentUser,
      conbrwCustomerId: rootCustomerId,
    })
    return toReturn
  } else if (rootCustomerType === CUSTOMER_TYPES_MASTER) {
    const conbrwChildIds = getAllChildCustomerIdsOfCustomer({
      entireCustomersSlice,
      entireParentChildLinksSlice,
      customerId: rootCustomerId,
      onlyCustomersWhoAreNotCurrentlyInactive: true,
      customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_CONTRACT_BREWER,
    })
    if (!isTruthyAndNonEmpty(conbrwChildIds)) {
      return {}
    }
    const defsPerConbrwChild = conbrwChildIds.map(conbrwCustomerId => (
      getCheckboxDefinitionsOfCustomersToOperateOnBehalfOfContractBrewer({
        entireCustomersSlice,
        entireContractsSlice,
        entireParentChildLinksSlice,
        entireRelationshipsSlice,
        entireCurrentUserSlice,
        entireUsersSlice,
        entirePermissionsSlice,
        userIdIfNotCurrentUser,
        conbrwCustomerId,
      })
    ))
    // we now have an array of objects, where each object's keys are contractee
    // customerIds. We want to merge this into a single object. Multiple objects
    // might contain the same customerId (meaning that the Master has multiple
    // ConBrw children who brew for one or more of the same contractee). If so,
    // we need to make sure not to have duplicate values in the object we
    // return. Fortunately, the values of those objects will be identical, so we
    // don't need to perform any special merging, we just need to make sure
    // there are no duplicates in the returned object.
    return defsPerConbrwChild.reduce(
      (acc, def) => ({
        ...acc,
        ...def,
      }),
      {},
    )
  }
  return {}
}


// see docstring of calling function for details
function getCheckboxDefinitionsOfCustomersToOperateOnBehalfOfContractBrewer({
  entireCustomersSlice,
  entireContractsSlice,
  entireParentChildLinksSlice,
  entireRelationshipsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  userIdIfNotCurrentUser,
  conbrwCustomerId,
}) {
  const contracteeCustomerIds = getCustomerIdsOfAllContracteesOfContractBrewerThatKegFillsCanBeReportedOn({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId: conbrwCustomerId,
  })
  return contracteeCustomerIds.reduce(
    (acc, customerId) => {
      const name = entireCustomersSlice[customerId].name
      const allPotentialManageUsersPermissionsChoicesForThisCustomer =
        getAllManageUsersPermissionsChoicesForAUserBasedOnRootCustomerType({
          entireCustomersSlice,
          entireParentChildLinksSlice,
          entireCurrentUserSlice,
          entireUsersSlice,
          userIdIfNotCurrentUser,
          getContracteePermissionsInsteadOfRootCustomerPermissions: true,
        })
      const manageUsersPermissionsChoices = createManageUsersPermissionsChoicesDefinitionsForOneCustomer({
        entireCustomersSlice,
        entireCurrentUserSlice,
        entireUsersSlice,
        entirePermissionsSlice,
        allPotentialManageUsersPermissionsChoicesForThisCustomer,
        userIdIfNotCurrentUser,
        customerIdIfThisIsAPerCustomerPermissionsCheck: customerId,
      })
      return {
        ...acc,
        [customerId]: {
          name,
          manageUsersPermissionsChoices,
        },
      }
    },
    {},
  )
}


function createManageUsersPermissionsChoicesDefinitionsForOneCustomer({
  entireCustomersSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  entirePermissionsSlice,
  allPotentialManageUsersPermissionsChoicesForThisCustomer,
  userIdIfNotCurrentUser,
  customerIdIfThisIsAPerCustomerPermissionsCheck,
}) {
  // Set custom informational popups for unusual/confusing permissions
  let informationalPopups
  let rootCustomerId
  if (userIdIfNotCurrentUser) {
    rootCustomerId = entireUsersSlice[userIdIfNotCurrentUser].rootCustomerId
  } else if (customerIdIfThisIsAPerCustomerPermissionsCheck) {
    rootCustomerId = customerIdIfThisIsAPerCustomerPermissionsCheck
  } else {
    rootCustomerId = entireCurrentUserSlice.rootCustomerId
  }
  if (entireCustomersSlice[rootCustomerId].customerType === CUSTOMER_TYPES_BREWER) {
    informationalPopups = {
      [MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_CANCEL_KEG_FILLS]: 'This permission only affects Brewers who have Contract Brewers. Check this if you want to be responsible for reporting the kegs your Contract Brewer fills for you (Contract Brewers are usually responsible for this, but if they are unable or unwilling to, then you can do it).',
      [MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_KEG_FILLS]: 'This permission only affects Brewers who have Contract Brewers. It allows you to see the history of kegs your Contract Brewer has filled for you.',
    }
  } else {
    informationalPopups = {}
  }

  return allPotentialManageUsersPermissionsChoicesForThisCustomer.map(manageUsersPermissionsChoice => ({
    manageUsersPermissionsChoice,
    label: MANAGE_USERS_PERMISSIONS_CHOICE_TO_HUMAN_READABLE_LABEL_MAP[manageUsersPermissionsChoice],
    category: MANAGE_USERS_PERMISSIONS_CHOICE_TO_CATEGORY_MAP[manageUsersPermissionsChoice],
    hasPermission: getHasPermissionsToPerformManageUsersPermissionsChoice({
      entirePermissionsSlice,
      entireCurrentUserSlice,
      permissionsChoice: manageUsersPermissionsChoice,
      userIdIfNotCurrentUser,
      customerIdIfThisIsAPerCustomerPermissionsCheck,
    }),
    informationalPopupContent: informationalPopups[manageUsersPermissionsChoice],
  }))
}


// CODE_COMMENTS_173: If a CB user has "Create & Edit Inventory" or "View
// Inventory Reports" permissions, all applicable customers it can operate for
// must also have this permission in order for them to actually have the
// permission. This function performs these checks and sets the permissions to
// false if necessary.
function alterRootCustomerPermissionsDefsConcerningInventoryIfNecessary({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  entireUsersSlice,
  userIdIfNotCurrentUser,
  originalRootCustomerCheckboxDefinitions,
}) {
  const userObject = userIdIfNotCurrentUser
    ? entireUsersSlice[userIdIfNotCurrentUser]
    : entireCurrentUserSlice
  const rootCustomerType = entireCustomersSlice[userObject.rootCustomerId].customerType
  if (rootCustomerType !== CUSTOMER_TYPES_CONTRACT_BREWER) { return originalRootCustomerCheckboxDefinitions }

  const hasOriginalRootCustomerPermissionToViewInventoryReportHistory = originalRootCustomerCheckboxDefinitions.find(o => (
    o.manageUsersPermissionsChoice === MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER
  )).hasPermission
  const hasOriginalRootCustomerPermissionToReportInventory = originalRootCustomerCheckboxDefinitions.find(o => (
    o.manageUsersPermissionsChoice === MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER
  )).hasPermission

  // set to true just at first
  let hasPerCustomerPermissionsToViewInventoryReportHistoryForAllApplicableContractees = true
  if (hasOriginalRootCustomerPermissionToViewInventoryReportHistory) {
    hasPerCustomerPermissionsToViewInventoryReportHistoryForAllApplicableContractees =
      getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToViewInventoryReportHistory({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        entirePermissionsSlice,
        entireCurrentUserSlice,
        entireUsersSlice,
        userIdIfNotCurrentUser: userObject.id,
        conbrwCustomerId: userObject.rootCustomerId,
      })
  }

  // set to true just at first
  let hasPerCustomerPermissionsToReportInventoryForAllApplicableContractees = true
  if (hasOriginalRootCustomerPermissionToReportInventory) {
    hasPerCustomerPermissionsToReportInventoryForAllApplicableContractees =
      getDoesConbrwUserHaveAllNecessaryPermissionsAndPerCustomerPermissionsToReportInventory({
        entireCustomersSlice,
        entireContractsSlice,
        entireRelationshipsSlice,
        entirePermissionsSlice,
        entireCurrentUserSlice,
        entireUsersSlice,
        userIdIfNotCurrentUser: userObject.id,
        conbrwCustomerId: userObject.rootCustomerId,
      })
  }

  return originalRootCustomerCheckboxDefinitions.map(item => {
    if (item.manageUsersPermissionsChoice === MANAGE_USERS_PERMISSIONS_CHOICE_VIEW_INVENTORY_REPORTS_CONTRACT_BREWER) {
      return hasPerCustomerPermissionsToViewInventoryReportHistoryForAllApplicableContractees
        ? item
        : { ...item, hasPermission: false }
    } else if (item.manageUsersPermissionsChoice === MANAGE_USERS_PERMISSIONS_CHOICE_CREATE_EDIT_INVENTORY_REPORTS_CONTRACT_BREWER) {
      return hasPerCustomerPermissionsToReportInventoryForAllApplicableContractees
        ? item
        : { ...item, hasPermission: false }
    }
    return item
  })
}


export function createEditUserPermissionsFormFieldName(
  rootCustomerIdOrOperateForCustomerId,
  manageUsersPermissionsChoice,
) {
  return `${rootCustomerIdOrOperateForCustomerId}${CONCATENATED_STRING_SEPARATOR}${manageUsersPermissionsChoice}`
}

export function parseEditUserPermissionsFormFieldName(fieldName) {
  const [
    rootCustomerIdOrOperateForCustomerId,
    manageUsersPermissionsChoice,
  ] = fieldName.split(CONCATENATED_STRING_SEPARATOR)
  return {
    rootCustomerIdOrOperateForCustomerId,
    manageUsersPermissionsChoice,
  }
}
