import React from 'react'


import { connect } from 'react-redux'
import ReportAndEditShipments from './common/ReportAndEditShipments'
import { getContracts, getEntireSlice as getEntireContractsSlice } from '../../redux/selectors/rewrite/contracts'
import {
  CUSTOMER_TYPES_PUB,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CBMST,
  PPF_CONTRACT_TYPES_CONBRW,
} from '../../constants'
import { getCustomerObjectsOfAllRelatedTo } from '../../redux/selectors/rewrite/relationships/relatedToInfo'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'


const ReportSelfDistAndPubShipmentsComponent = props => {
  const { banner }= props
  return (
    <ReportAndEditShipments
      formType="selfDistAndPubs"
      isEditForm={false}
      banner={banner}
      {...props}
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const { taproomFixedFee = null } = getContracts({
    entireContractsSlice,
    entireCustomersSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_CBMST, PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CONBRW],
    notExpiredOnly: false,
    activeStatusOnly: true,
    noFutureContracts: true,
    mostRecentOnly: true,
  }) || {}

  const activePubs = getCustomerObjectsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_PUB,
  })
  const activeSelfDist = getCustomerObjectsOfAllRelatedTo({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn: true, // CODE_COMMENTS_263
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_SELF_DISTRIBUTION,
  })
  const banner = {}
  const addOnProps = {}
  if (taproomFixedFee && activeSelfDist?.length && !activePubs?.length) {
    banner.message = 'Pub moves aren\'t required with fixed fee.'
  } else if (taproomFixedFee && activeSelfDist?.length && activePubs?.length) {
    banner.message = 'Pub moves aren\'t required with fixed fee.'
    addOnProps.restrictPubs = true
  }


  return {
    ...ownProps,
    banner,
    ...addOnProps,
  }
}

export default connect(
  mapStateToProps,
)(ReportSelfDistAndPubShipmentsComponent)
