import React from 'react'


import HistoryTableCreator from '../../../HOC/HistoryTableCreator'

import {
  createTableAndDownloadFileContentDefinitions,
} from '../../util/tableAndDownloadFileContentDefinitions'

export default ({
  customerId,
  customerType,
  invoicesObject,
  isFormSubmitting,
  hasFormSubmitFailed,
  noResultsMessage,
  areAnyItemsEditable,
  getIsindividualItemEditable,
  currency,
}) => {
  const definitions = createTableAndDownloadFileContentDefinitions({
    tableOrDownloadFile: 'table',
    customerId,
    customerType,
    currency,
  })

  return (
    <HistoryTableCreator
      tableInfoObj={invoicesObject}
      definitions={definitions}
      isFormSubmitting={isFormSubmitting}
      hasFormSubmitFailed={hasFormSubmitFailed}
      additionalPropsToPassToTableCells={{ customerId }}
      noResultsMessage={noResultsMessage}

      // for Edit and Delete buttons column
      areAnyItemsEditable={areAnyItemsEditable}
      itemType="invoice"
      customerId={customerId}
      getIsindividualItemEditable={getIsindividualItemEditable}
    />
  )
}
