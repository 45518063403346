import {
  SAVE_STORED_BANK_ACCOUNT_INFO,
  DELETE_STORED_BANK_ACCOUNT_INFO_FROM_REDUX_STORE,
} from '../actions/actionTypes'

const initialState = {
  byCustomerId: {},
}

export default (state=initialState, action) => {
  switch (action.type) {
    case SAVE_STORED_BANK_ACCOUNT_INFO:
      return {
        ...state,
        byCustomerId: {
          ...state.byCustomerId,
          [action.payload.customerId]: action.payload.info,
        },
      }
    case DELETE_STORED_BANK_ACCOUNT_INFO_FROM_REDUX_STORE:
      return {
        ...state,
        byCustomerId: {
          ...state.byCustomerId,
          [action.payload.customerId]: null,
        },
      }

    default:
      return state
  }
}
