import React from 'react'


import ReportAndEditShipments from './common/ReportAndEditShipments'


export default props => (
  <ReportAndEditShipments
    formType="selfCollection"
    isEditForm={false}
    {...props}
  />
)
