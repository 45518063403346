import React from 'react'
import moment from 'moment'


import DownloadInventoryHistoryTableAsFile from '../DownloadTableAsFile'
import HistoryFormCreator from '../../HOC/HistoryFormCreator'

import { FETCH_INVENTORY_HISTORY } from '../../../../../redux/actions/actionTypes'

import {
  HISTORY_FORM_FIELD_NAME_START_DATE,
  HISTORY_FORM_FIELD_NAME_END_DATE,
} from '../../../../../constants/formAndApiUrlConfig/histories/historyShared'

import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../../../constants/formAndApiUrlConfig/commonConfig'

import {
  HISTORY_FORM_NAME_INVENTORY,
} from '../../../../../constants'


import {
  createFormNameForRedux,
} from '../../../../../utils'


const initialValues = {
  [HISTORY_FORM_FIELD_NAME_START_DATE]:
    moment().subtract(1, 'year').startOf('month').format(DEFAULT_DISPLAYED_DATE_FORMAT),
  [HISTORY_FORM_FIELD_NAME_END_DATE]:
    moment().add(1, 'month').format(DEFAULT_DISPLAYED_DATE_FORMAT),
}


function createDateRangeMin() {
  return moment('01/01/1985', 'MM-DD-YYYY')
}

function createDateRangeMax() {
  return moment().add(1, 'month')
}

export default props => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = props
  return (
    <HistoryFormCreator
      {...props}
      createDateRangeMin={createDateRangeMin}
      createDateRangeMax={createDateRangeMax}
      dateRangeFieldsLabel="Date Counted"
      initialValues={initialValues}
      additionalIsFormSubmittableChecksBesidesDateRanges={null}
      submitAction={FETCH_INVENTORY_HISTORY}
      fieldsBeforeDateRange={null}
      downloadFileComponent={DownloadInventoryHistoryTableAsFile}
      formName={createFormNameForRedux({
        reducerName: HISTORY_FORM_NAME_INVENTORY,
        customerId,
        operatingContractBrewerCustomerId,
      })}
    />
  )
}
