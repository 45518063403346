/* eslint-disable max-len */

import React from 'react'

import isFunction_ from 'lodash/isFunction'

import flow_ from 'lodash/fp/flow'
import mapFp_ from 'lodash/fp/map'
import filterFp_ from 'lodash/fp/filter'
import flattenFp_ from 'lodash/fp/flatten'
import uniqByFp_ from 'lodash/fp/uniqBy'

import NotApplicable from '../../sharedComponents/NotApplicable'

import {
  getContainerTypeFromItemSkuId,
  getContainerTypeQtyFromLineItem,
} from '../../../../../redux/selectors/rewrite/itemSkus'

import {
  INVENTORY_REPORT_OBJS,
  CUSTOMER_TYPE_FOR_REPORT_INVENTORY,
} from '../../../../ReportInventory/util/constants'

import {
  FIELD_LABEL_DATE_COUNTED,
  FIELD_LABEL_TO_API_INVENTORY_TYPE_MAP,
} from '../../../../../constants/formAndApiUrlConfig/reportInventory'

import {
  createApiDateSortFunctionForHistoryTable,
} from '../../../util'


import {
  ONLY_RENDER_SECTION_IF,
  SECTION_DEFINITIONS,
  SECTION_FIELDS,
  ONLY_RENDER_FIELD_IF,
  FORM_FIELD_KIND,
  FORM_FIELD_KIND_INDIVIDUAL_FIELD,
  FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP,
  LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR,
  CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP,
  FIELD_LABEL,
  TRUNCATED_FIELD_LABEL_FOR_HISTORY_TABLE,
  FULL_DETAILS_COMPONENT,
  HISTORY_TABLE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT,
  DOWNLOAD_FILE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT,
  LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE,
  HEADING_IF_LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE,
  SAME_AS_REPORT_FORM_FIELD_COMPONENT,
  SAME_AS_EDIT_FORM_FIELD_COMPONENT,
  REPORT_FORM_FIELD_COMPONENT,
  EDIT_FORM_FIELD_COMPONENT,
  getConfigObjectBasedOnCustomerTypeForReportInventory,
} from '../../../../ReportInventory/util/configObjects'

import {
  getMostRepresentativeInventoryReportObj,
  determineItemSkuIdsToIncludeInInventoryReportFullDetailsOrEditForm,
} from '../../../../ReportInventory/util'

import {
  isTruthyAndNonEmpty,
} from '../../../../../utils'

/*
 * *****************************************************************************
 * Exported functions
 * *****************************************************************************
*/

export function createTableAndDownloadFileContentDefinitions(props) {
  const {
    tableOrDownloadFile,
    groupingsOfInventoryReportObjsForHistoryTable,
  } = props
  const contentDefinitionsOfIndividualRows = groupingsOfInventoryReportObjsForHistoryTable.map(grouping => {
    const {
      [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
      [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    } = grouping
    const configObj = getConfigObjectBasedOnCustomerTypeForReportInventory({
      [CUSTOMER_TYPE_FOR_REPORT_INVENTORY]: customerTypeForReportInventory,
    })
    return convertConfigObjToContentDefinitionsForTableOrDownloadFile({
      ...props,
      configObj,
      [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    })
  })

  const consolidatedContentDefinitions = consolidateContentDefinitionsOfIndividualRows(
    contentDefinitionsOfIndividualRows,
    tableOrDownloadFile,
  )

  return {
    content: consolidatedContentDefinitions,
    defaultSortColumn: FIELD_LABEL_DATE_COUNTED,
    rowKey: row => getMostRepresentativeInventoryReportObj({
      [INVENTORY_REPORT_OBJS]: row[INVENTORY_REPORT_OBJS],
    }).id,
  }
}

/*
 * *****************************************************************************
 * Helper functions
 * *****************************************************************************
*/

// Returns an array of table definition objects:
// [
//   {
//     heading: 'Full HB',
//     cellContent: row => { ... },
//   },
//   ...
// ]
function convertConfigObjToContentDefinitionsForTableOrDownloadFile(props) {
  const {
    configObj,
    tableOrDownloadFile,
  } = props
  return flow_(
    // Target the SECTION_DEFINITIONS of the config object
    configObj_ => configObj_[SECTION_DEFINITIONS],
    // Filter out any sections that shouldn't be rendered
    filterFp_(section => {
      if (!section[ONLY_RENDER_SECTION_IF]) {
        return section
      }
      return section[ONLY_RENDER_SECTION_IF]({
        ...props,
        isThisForDisplayingAsARowInTheHistoryTable: tableOrDownloadFile === 'table',
        isThisForDisplayingAsARowInTheDownloadFile: tableOrDownloadFile === 'downloadFile',
      })
    }),
    // Target the SECTION_FIELDS of each section definition
    mapFp_(section => section[SECTION_FIELDS]),
    mapFp_(sectionFields => (
      convertSectionFieldsDefinitionsOfSingleSectionToContentDefinitionsForTableOrDownloadFile({
        ...props,
        [SECTION_FIELDS]: sectionFields,
      })
    )),
    // convert the array of arrays (one array for each section) into a flat
    // array.
    flattenFp_,
  )(configObj)
}


// Returns an array of table definition objects FOR A SINGLE SECION_FIELDS
// OBJECT:
// [
//   {
//     heading: 'Full HB',
//     cellContent: row => { ... },
//     customSortInfo: { ... }, // For the history table
//   },
//   ...
// ]
function convertSectionFieldsDefinitionsOfSingleSectionToContentDefinitionsForTableOrDownloadFile(props) {
  const {
    entireItemSkusSlice,
    [SECTION_FIELDS]: sectionFields,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    tableOrDownloadFile,
  } = props
  const itemSkuIds = determineItemSkuIdsToIncludeInInventoryReportFullDetailsOrEditForm({
    entireItemSkusSlice,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  })
  const toReturn = flow_(
    // sectionFields is an array of individual field object definitions. Filter
    // out all fields that shouldn't be rendered.
    filterFp_(fieldDef => {
      if (!fieldDef[ONLY_RENDER_FIELD_IF]) {
        return true
      }
      return fieldDef[ONLY_RENDER_FIELD_IF]({
        ...props,
        isThisForDisplayingAnAlreadyCreatedInventoryReport: true,
        isThisForDisplayingAsARowInTheHistoryTable: tableOrDownloadFile === 'table',
        itemSkuIds,
      })
    }),
    // Sometimes a field is an individual field and sometimes is a "label to API
    // inventory type map" which acutally consists of multiple fields.
    // Therefore, always return an array of content definition objects, even if
    // the field is a single field rather than a "multiple-fields" field. When all our returned values are arrays,
    mapFp_(fieldDef => {
      if (fieldDef[FORM_FIELD_KIND] === FORM_FIELD_KIND_INDIVIDUAL_FIELD) {
        const heading = (tableOrDownloadFile === 'table' && fieldDef[TRUNCATED_FIELD_LABEL_FOR_HISTORY_TABLE])
          ? fieldDef[TRUNCATED_FIELD_LABEL_FOR_HISTORY_TABLE]
          : fieldDef[FIELD_LABEL]
        let cellContent
        if (tableOrDownloadFile === 'table') {
          cellContent = (
            fieldDef[HISTORY_TABLE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT]
            || fieldDef[FULL_DETAILS_COMPONENT]
          )
        } else {
          cellContent = (
            fieldDef[DOWNLOAD_FILE_COMPONENT_IF_DIFFERENT_FROM_FULL_DETAILS_COMPONENT]
            || fieldDef[FULL_DETAILS_COMPONENT]
          )
        }
        if (cellContent === SAME_AS_EDIT_FORM_FIELD_COMPONENT) { cellContent = fieldDef[EDIT_FORM_FIELD_COMPONENT] }
        if (cellContent === SAME_AS_REPORT_FORM_FIELD_COMPONENT) { cellContent = fieldDef[REPORT_FORM_FIELD_COMPONENT] }
        return [{
          heading,
          cellContent,
          ...(heading === FIELD_LABEL_DATE_COUNTED
            ? {
              customSortInfo: {
                sortFunction: createApiDateSortFunctionForHistoryTable({ datePropName: 'countDate' }),
                sortApiObjectValuesRatherThanCellContents: true,
              },
            }
            : {}
          ),
        }]
      } else if (fieldDef[FORM_FIELD_KIND] === FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP) {
        if (
          fieldDef[LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE]
          && tableOrDownloadFile === 'table'
        ) {
          return createInventoryHistoryTableDefinitionsAGGREGATESBasedOnApiToLabelInventoryMap({
            ...fieldDef,
            entireItemSkusSlice,
            [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
            heading: fieldDef[
              HEADING_IF_LABEL_TO_API_INVENTORY_TYPE_MAP_INCLUDE_ONLY_TOTALS_AS_COLUMNS_IN_HISTORY_TABLE
            ],
            tableOrDownloadFile,
          })
        }
        return createInventoryHistoryTableDefinitionsBasedOnApiToLabelInventoryMap({
          ...props,
          fieldDef,
          [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
          tableOrDownloadFile,
        })
      }
      throw Error(`The FORM_FIELD_KIND of a field definition is ${fieldDef[FORM_FIELD_KIND]} when the only valid options are FORM_FIELD_KIND_INDIVIDUAL_FIELD and FORM_FIELD_KIND_LABEL_TO_API_INVENTORY_TYPE_MAP. Troubleshoot.`)
    }),
    // flatten the array of arrays to a single array
    flattenFp_,
  )(sectionFields)
  return toReturn
}


function createInventoryHistoryTableDefinitionsBasedOnApiToLabelInventoryMap(props) {
  const {
    entireItemSkusSlice,
    // fieldDef,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    tableOrDownloadFile,
  } = props
  const itemSkuIds = determineItemSkuIdsToIncludeInInventoryReportFullDetailsOrEditForm({
    entireItemSkusSlice,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  })
  const containerTypesForThisRow = Array.from(itemSkuIds.reduce((containerTypes, itemSkuId) => {
    const containerType = getContainerTypeFromItemSkuId({
      entireItemSkusSlice,
      itemSkuId,
    })
    if (!containerType) return containerTypes
    return containerTypes.add(containerType)
  }, new Set()))

  return containerTypesForThisRow.map(containerType => ({
    heading: createHeadingForInventoryHistoryTableColumn({
      fieldLabel: '',
      itemSkuId: containerType,
    }),
    cellContent: row => {
      const { inventoryLineItemObjects } = getMostRepresentativeInventoryReportObj({ [INVENTORY_REPORT_OBJS]: row[INVENTORY_REPORT_OBJS] })
      try {
        return getContainerTypeQtyFromLineItem({
          lineItems: inventoryLineItemObjects,
          containerType,
          entireItemSkusSlice,
        })
      } catch (e) {
        // If this inventoryReportObj doesn't have this API inventory type
        // and/or itemSkuId in its inventoryLineItemObjects prop
        if (e instanceof TypeError) {
          return tableOrDownloadFile === 'table'
            ? <NotApplicable />
            : null
        }
        throw e
      }
    },
    includeInTotalsRow: true,
  }))
}


// Similar to
// createInventoryHistoryTableDefinitionsBasedOnApiToLabelInventoryMap except it
// aggregates each api to label inventory report object into a single value by
// container type.
function createInventoryHistoryTableDefinitionsAGGREGATESBasedOnApiToLabelInventoryMap(props) {
  const {
    entireItemSkusSlice,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
    [LABEL_TO_API_INVENTORY_TYPE_MAP_CREATOR]: createLabelToApiInventoryMapFunc,
    [CUSTOM_LIST_OF_ITEM_SKU_IDS_TO_INCLUDE_IN_LABEL_TO_API_INVENTORY_TYPE_MAP]: customItemSkuIdsArrayOrFunc,
    heading,
    tableOrDownloadFile,
  } = props
  let itemSkuIds = determineItemSkuIdsToIncludeInInventoryReportFullDetailsOrEditForm({
    entireItemSkusSlice,
    [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  })
  if (customItemSkuIdsArrayOrFunc) {
    const customItemSkuIds = isFunction_(customItemSkuIdsArrayOrFunc)
      ? customItemSkuIdsArrayOrFunc({
        ...props,
        itemSkuIds,
      })
      : customItemSkuIdsArrayOrFunc
    itemSkuIds = customItemSkuIds || itemSkuIds
  }
  return itemSkuIds.map(itemSkuId => ({
    heading: createHeadingForInventoryHistoryTableColumn({
      entireItemSkusSlice,
      fieldLabel: heading,
      itemSkuId,
    }),
    cellContent: row => {
      const labelToApiInventoryMaps = createLabelToApiInventoryMapFunc(row)
      if (isTruthyAndNonEmpty(labelToApiInventoryMaps)) {
        return labelToApiInventoryMaps.reduce(
          (acc, map) => {
            try {
              return acc + Number(map.initialValueFunc(itemSkuId))
            } catch (e) {
              // If this inventoryReportObj doesn't have this itemSkuId in
              // its inventoryLineItemObjects prop
              if (e instanceof TypeError) {
                return acc
              }
              throw e
            }
          },
          0,
        )
      }
      return tableOrDownloadFile === 'table'
        ? <NotApplicable />
        : null
    },
    includeInTotalsRow: true,
  }))
}


function createHeadingForInventoryHistoryTableColumn({
  entireItemSkusSlice, // optional
  fieldLabel,
  itemSkuId,
}) {
  return `${fieldLabel} ${(entireItemSkusSlice && getContainerTypeFromItemSkuId({ entireItemSkusSlice, itemSkuId })) || itemSkuId}`
}


function consolidateContentDefinitionsOfIndividualRows(
  contentDefinitionsOfIndividualRows,
  tableOrDownloadFile,
) {
  return flow_(
    // contentDefinitionsOfIndividualRows is an array of arrays of objects.
    // Flatten down to an array of objects,
    flattenFp_,
    // filter to only one definition per heading
    uniqByFp_('heading'),
    // Remove the '(no foreign)' text from any headings
    mapFp_(def => {
      let newHeading = def.heading
      if (newHeading.includes('(no foreign)')) {
        newHeading = def.heading.replace('(no foreign)', '').trim()
      }
      if (tableOrDownloadFile === 'table') {
        newHeading = truncateByReplacingFieldLabelTextWithApiInventoryType(newHeading)
      }
      return {
        ...def,
        heading: newHeading,
      }
    }),
  )(contentDefinitionsOfIndividualRows)
}


function truncateByReplacingFieldLabelTextWithApiInventoryType(
  label,
) {
  const matchingApiInventoryType = Object.entries(FIELD_LABEL_TO_API_INVENTORY_TYPE_MAP).find(([label_]) => label.includes(label_))
  if (matchingApiInventoryType) {
    const [label_, apiInventoryType] = matchingApiInventoryType
    return label.replace(label_, apiInventoryType)
  }
  return label
}
