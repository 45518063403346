import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import NestedOperateForRoutes from '../components/NestedOperateForRoutes'
import { getProp } from '../../../redux/selectors/customers'


const mapStateToProps = (state, ownProps) => ({
  customerType: getProp(state, ownProps.customerId, 'customerType'),
  ...ownProps,
})

export default withRouter( // CODE_COMMENTS_25
  connect(
    mapStateToProps,
  )(NestedOperateForRoutes),
)
