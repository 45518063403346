import React from 'react'

import VerifiedOrErrorMessages from '../../VerifiedOrErrorMessages'
import {
  getIsConfiguredWithAtLeastOneContainerType,
  getIsNotInPendingStatus,
} from '../../Verifiers'


export default props => (
  <VerifiedOrErrorMessages
    verifiers={[
      {
        verifierFunction: getIsConfiguredWithAtLeastOneContainerType,
      },
      {
        verifierFunction: getIsNotInPendingStatus,
      },
    ]}
    {...props}
  />
)
