// CODE_COMMENTS_267

import React from 'react'

import NavBar from '../../NavBar'

export default ({ children }) => (
  <main>
    <NavBar
      fauxPrivateLayout
    />
    {children}
  </main>
)
