import React from 'react'


import OrderAndEditShared from '../OrderAndEditShared'
import UiContainer from '../../../common-components/UiContainer'


export default UiContainer({
  headerText: 'Order Kegs',
  containerId: 'orderKegsSection',
})(
  props => (
    <OrderAndEditShared
      {...props}
      isEditForm={false}
    />
  ),
)
