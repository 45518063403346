import {
  REDUCER_NAMES_HISTORY,
  REDUCER_NAMES_HISTORY_INVENTORY as defaultSlice,
} from '../../../constants'

import {
  withPropSimple,
  withPropOfAllSimple,
  getEntireSlice as commonGetEntireSlice,
} from '../higherOrderFunctions'


const defaultSlices = [REDUCER_NAMES_HISTORY, defaultSlice, 'byCustomerId']

// usage: getProp(state, customerId, inventoryReportId, 'countedBy')
export const getProp = withPropSimple(...defaultSlices)
// usage: getPropOfAll(state, customerId, 'countedBy')
export const getPropOfAll = withPropOfAllSimple(...defaultSlices)

export const getEntireSlice = commonGetEntireSlice(...defaultSlices)
