import { connect } from 'react-redux'

import MaybeEditAndDeleteButtonsInTables from './components'

const mapStateToProps = (state, props) => {
  const {
    getIsindividualItemEditable,
    itemObj: row,
    additionalPropsToPassToTableCells,
  } = props

  const isindividualItemEditable = getIsindividualItemEditable(
    state,
    row,
    additionalPropsToPassToTableCells,
  )

  return {
    isindividualItemEditable,
  }
}

export default connect(
  mapStateToProps,
)(MaybeEditAndDeleteButtonsInTables)
