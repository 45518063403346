import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import DashboardDecider from '../components/DashboardDecider'

import {
  getProp,
  getIsRentalCustomer,
} from '../../../redux/selectors/customers'


const mapStateToProps = (state, { customerId }) => {
  const customerType = getProp(state, customerId, 'customerType')
  const isRentalCustomer = getIsRentalCustomer(state, customerId)
  return {
    customerType,
    isRentalCustomer,
  }
}

export default withRouter(connect(
  mapStateToProps,
)(DashboardDecider))
