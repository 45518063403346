import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Routes from './components/RentalCustomerRoutes'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../redux/selectors/permissions'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../redux/selectors/rewrite/currentUser'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
} from '../../../../constants/permissions'

import {
  getShouldOrderKegCollarsFormBeRendered,
} from '../../../OrderKegCollars/util'
import { getEntireSlice as getEntireSubsidiariesSlice } from '../../../../redux/selectors/rewrite/subsidiaries'


const mapStateToProps = (state, props) => {
  const {
    customerId,
  } = props

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)

  const shouldOrderKegCollarsFormBeRendered = getShouldOrderKegCollarsFormBeRendered({
    entireCustomersSlice,
    entireRelationshipsSlice,
    entireContractsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    customerId,
    entireSubsidiariesSlice,
  })
  const hasPayBalancePermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
  })
  const hasManageUsersAndContactsPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
  })
  const hasAddressesPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_ADDRESSES,
  })

  return {
    // Why Booleans? See CODE_COMMENTS_252
    shouldOrderKegCollarsFormBeRendered: Boolean(shouldOrderKegCollarsFormBeRendered),
    hasPayBalancePermissions: Boolean(hasPayBalancePermissions),
    hasManageUsersAndContactsPermissions: Boolean(hasManageUsersAndContactsPermissions),
    hasAddressesPermissions: Boolean(hasAddressesPermissions),
  }
}

export default withRouter( // CODE_COMMENTS_25
  connect(
    mapStateToProps,
  )(Routes),
)
