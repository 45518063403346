/**
 * CODE_COMMENTS_131
 */
import React from 'react'
import { Popup } from 'semantic-ui-react'

import createComponentWithCatastrophicFailureErrorBoundary from './createComponentWithCatastrophicFailureErrorBoundary'

// By default, Semantic UI React's <Popup> component ignores some DOM event
// handlers you pass to it such as onClick, onFocus and onBlur. But sometimes
// you want a popup to do something special when it gets clicked, such as open a
// modal. So to get around this, we simply wrap the Popup in an arbitrary
// element like a span and put the event handlers on it. It's not a perfect
// solution, but good enough for this app's purposes.
const PopupThatAllowsCustomDomEventHandlers = props => {
  const {
    onClick,
    onFocus,
    onBlur,
    ...rest
  } = props

  if (onClick || onFocus || onBlur) {
    return (
      <span
        role="button"
        // Follows the the jsx-a11y rule that "Elements with the 'Button'
        // interactive role must be focusable":
        // https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/interactive-supports-focus.md#case-i-got-the-error-elements-with-the-role-interactive-role-must-be-focusable-how-can-i-fix-this
        // However, we don't want the focusability of this text to disrupt the
        // normal progression of the tab key when a user is using keyboard
        // navigation rather than the mouse (for instance, when filling out a
        // form), so we set the tabIndex to -1 rather than 0.
        tabIndex={-1}
        onClick={onClick}
        // Follows this jsx-a11y rule:
        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/click-events-have-key-events.md
        onKeyDown={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <Popup {...rest} />
      </span>
    )
  }
  return <Popup {...rest} />
}

export default createComponentWithCatastrophicFailureErrorBoundary(PopupThatAllowsCustomDomEventHandlers)
