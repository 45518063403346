import {
  REDUCER_NAMES_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES as defaultSlice,
} from '../../../constants'


export function getEntireSlice(state) {
  return state[defaultSlice]
}


/*
 * *****************************************************************************
 * Pseudo-selectors that don't get state passed in but rather the entire store
 * slices.
 * *****************************************************************************
*/

export function isShipmentsFormRowPotentialDuplicate(
  reportedShipmentsStoreSlice,
  rowString, // created by createPotentialDuplicateShipmentStringKeyForReduxStore()
) {
  return reportedShipmentsStoreSlice[rowString]
}
