import React from 'react'


import {
  OrderKegCollarsOption,
  ReportOutboundShipmentsOption,
  ReportBuybackShipmentsOption,
  ReportSelfDistAndPubShipmentsOption,
} from '../../shared/IndividualOptions'


// CODE_COMMENTS_39
export default ({
  shouldOrderKegCollarsFormBeRendered,
  shouldReportOutboundShipmentsFormBeRendered,
  shouldReportOutboundShipmentsFormOptionBeDisabled,
  whyReportOutboundShipmentsFormDisabledMessage,
  shouldReportBuybackShipmentsFormBeRendered,
  shouldReportSelfDistAndPubShipmentsFormBeRendered,
}) => ([
  ...(
    shouldOrderKegCollarsFormBeRendered
      ? [<OrderKegCollarsOption key="OrderKegCollarsOption" />]
      : []
  ),
  ...(
    shouldReportOutboundShipmentsFormBeRendered
      ? [
        <ReportOutboundShipmentsOption
          key="ReportOutboundShipmentsOption"
          // CODE_COMMENTS_231
          disabledIf={shouldReportOutboundShipmentsFormOptionBeDisabled}
          whyIsThisOptionDisabledMessage={whyReportOutboundShipmentsFormDisabledMessage}
        />,
      ]
      : []
  ),
  ...(
    shouldReportBuybackShipmentsFormBeRendered
      ? [<ReportBuybackShipmentsOption key="ReportBuybackShipmentsOption" />]
      : []
  ),
  ...(
    shouldReportSelfDistAndPubShipmentsFormBeRendered
      ? [<ReportSelfDistAndPubShipmentsOption key="ReportSelfDistAndPubShipmentsOption" />]
      : []
  ),
])
