import { takeEvery } from 'redux-saga/effects'


import {
  shipmentHistorySagaCreator,
} from './shipmentHistorySagaCreator'

import {
  FETCH_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
  SAVE_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
} from '../../../actions/actionTypes'


const saga = shipmentHistorySagaCreator({
  saveAction: SAVE_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY,
})

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY, saga],
]
