// For the Manage Users form

/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file, see CODE COMMENTS 50


import { select, call, takeEvery } from 'redux-saga/effects'


import {
  FETCH_CUSTOMER_USERS_AND_THEIR_PERMISSIONS,
} from '../../../actions/actionTypes'

import { fetchCustomerUsers } from './customerUsers'
import { fetchCustomerUserPermissions } from './customerUserPermissions'

import customerUsersFetchStatusSelectors from '../../../selectors/fetchStatuses/customers/customerUsers'

import {
  getEntireSlice as getEntireUsersSlice,
  getAllUsersOfRootCustomerId,
} from '../../../selectors/rewrite/users'

export function* fetchCustomerUsersAndTheirPermissions(action) {
  const { customerId } = action.payload
  yield call(fetchCustomerUsers, { payload: { customerId } })

  const { getFetchStatuses } = customerUsersFetchStatusSelectors
  const { didFetchFail } = yield select(getFetchStatuses, customerId)
  if (didFetchFail) { return }

  const entireUsersSlice = yield select(getEntireUsersSlice)
  const customerUserObjs = getAllUsersOfRootCustomerId(entireUsersSlice, customerId)
  const customerUserIds = customerUserObjs.map(o => o.id)
  // CODE_COMMENTS_49, CODE_COMMENTS_50
  for (const userId of customerUserIds) {
    yield call(fetchCustomerUserPermissions, { payload: { userId } })
  }
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_CUSTOMER_USERS_AND_THEIR_PERMISSIONS, fetchCustomerUsersAndTheirPermissions],
]
