import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import {
  goUpToCustomerPortal,
} from '../../utils'

import {
  URL_PATH_DYNAMIC_LINKS_CUSTOMER_PORTAL,
} from '../../constants'


const linkToMap = {
  [URL_PATH_DYNAMIC_LINKS_CUSTOMER_PORTAL]: goUpToCustomerPortal,
}

export default withRouter(({
  component: Component,
  to,
  children,

  // withRouter props
  match,
  // explicitly defined so they don't get included in ...rest
  location,
  history,
  staticContext,

  ...rest
}) => (
  <Component
    as={Link}
    to={linkToMap[to](match.url)}
    {...rest}
  >
    {children}
  </Component>
))
