/**
 * CODE_COMMENTS_31
 */

/* eslint-disable max-len */

// This is how to export imports in ES6: https://stackoverflow.com/a/42700900
export {
  OUTBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN as
  INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_ORIGIN,
} from './OutboundFullKegShipmentHistory'

export const INBOUND_FULL_KEG_SHIPMENT_FULL_DETAILS_LABEL_REFERENCE_NUM = 'Reference #'
