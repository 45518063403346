// CODE_COMMENTS_149

import React from 'react'
import { Header, Message } from 'semantic-ui-react'

import {
  logErrorMessage,
  LOG_SEVERITY_WARNING,
} from '../../../utils/thirdPartyLogging'

import ContactCard from '../../../common-components/ContactCard'

import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../constants'

export default ({ customerId }) => {
  logErrorMessage({
    message: 'Contract Brewer has no contractees',
    severity: LOG_SEVERITY_WARNING,
    additionalInfo: {
      details: 'This Contract Brewer is not related from any contractee brewers, either active or inactive. Their dashboard still loads but functionality is limited to ordering kegs, acknowledging inbound shipments, reporting inventory and managing users. They cannot report keg fills or operate for any contractees.',
      customerId,
    },
  })

  return (
    <Message
      warning
    >
      <Header as="h2">No Contractee Brewers</Header>
      <p>{"Uh oh, our system shows you're not configured to brew for any brewers. Contact your personalized representative and we'll get this changed in our system right away."}</p>
      <ContactCard customerId={customerId} repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE} />
    </Message>
  )
}
