import pick_ from 'lodash/pick'
import sortBy_ from 'lodash/sortBy'
import uniqBy_ from 'lodash/uniqBy'
import get_ from 'lodash/get'


import {
  getWhichContracteesOfALoggedInConbrwIsTheConbrwResponsibleForReportingKegFillsFor,
  getWhichConbrwsOfALoggedInBrwIsTheBrwResponsibleForReportingKegFillsFor,
} from '../../../../../../../redux/selectors/rewrite/contractBrewersAndContracteesSpecialSelectors'

import {
  REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
} from '../../../../../../../constants/formAndApiUrlConfig/reportKegFills'

import {
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE,
  WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION,
} from '../../../../../../../constants/formAndApiUrlConfig/reportShipmentsCommon'

import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
} from '../../../../../../../constants'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../../../utils'


// CODE_COMMENTS_188
export function createFieldArraysAndCustomersFieldsDropdownOptionsForReportAndEditKegFills({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  entirePermissionsSlice,
  entireCurrentUserSlice,
  customerId,
}) {
  const customerType = get_(entireCustomersSlice, [customerId, 'customerType'])

  let customerDropdownChoices
  if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    customerDropdownChoices = getWhichContracteesOfALoggedInConbrwIsTheConbrwResponsibleForReportingKegFillsFor({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      conbrwCustomerId: customerId,
      getCustomerObjectsRatherThanCustomerIds: true,
    })
  } else {
    customerDropdownChoices = getWhichConbrwsOfALoggedInBrwIsTheBrwResponsibleForReportingKegFillsFor({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      brwCustomerId: customerId,
      getCustomerObjectsRatherThanCustomerIds: true,
    })
  }

  customerDropdownChoices = customerDropdownChoices.map(o => pick_(o, ['id', 'name', 'customerType']))
  customerDropdownChoices = uniqBy_(customerDropdownChoices, 'id') // CODE_COMMENTS_230
  customerDropdownChoices = sortBy_(customerDropdownChoices, 'name')

  const dropdownOptions = [
    {
      id: createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId),
      name: get_(entireCustomersSlice, [customerId, 'name']),
      customerType,
      choicesForOtherCustomersFieldWhenThisCustomerIsSelected: customerDropdownChoices,
    },
  ]


  return [
    {
      fieldArrayTitle: null, // no title needed if only one field array in form
      fieldArrayName: REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
      whichCustomersFieldMustBeChosenFirstSourceOrDestination: (
        customerType === CUSTOMER_TYPES_CONTRACT_BREWER
          ? WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE
          : WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION
      ),
      customersFieldsDropdownOptions: dropdownOptions,
    },
  ]
}
