import React from 'react'
import { compose } from 'recompose'
import moment from 'moment'

import { Segment, Grid, List, Header } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import PayBalanceTemporarilyDisabledHOC from './PayBalanceTemporarilyDisabledHOC'
import WaitForApiFetch from '../../../common-components/WaitForApiFetch'
import UiContainer from '../../../common-components/UiContainer'
import NoInvoicesOnFileHOC from './NoInvoicesOnFileHOC'

import PayBalanceForm from './PayBalanceForm'

import {
  formatStringOrNumberToMoney,
} from '../../../utils'

import './styles.css'
import {
  DEFAULT_DISPLAYED_DATE_FORMAT,
} from '../../../constants/formAndApiUrlConfig/commonConfig'
import { PAY_BALANCE_TEXT1, PAY_BALANCE_TEXT2, PAY_BALANCE_TEXT3 } from '../../../constants/labelCaptions'


const PayBalance = props => {
  const {
    balance,
    // previousBalance,
    invoiceDate,
    currency,
  } = props
  const { t: translate } = useTranslation('pagelabels')

  return (
    <div>
      <Segment.Group horizontal>
        <Segment style={{ flexGrow: 4, width: 0 }}>
          <Header as="h3" textAlign="center">{translate('payBalance.Balances')}</Header>
          <div style={{ paddingLeft: 25, paddingRight: 25 }}>
            <Grid className="semitight-grid-rows">
              <Grid.Row columns={2} style={{ marginTop: '1rem' }}>
                <Grid.Column style={{ textAlign: 'right' }}>{`${translate(`payBalance.${PAY_BALANCE_TEXT1}`)} ${invoiceDate ? `(${translate(`payBalance.${PAY_BALANCE_TEXT2}`)} ${moment(invoiceDate).utc().format(DEFAULT_DISPLAYED_DATE_FORMAT)})` : ''}:`}</Grid.Column>
                <Grid.Column style={{ textAlign: 'left' }}>
                  <span className="bold-text-slightly-larger">
                    {/* eslint-disable-next-line */}
                    {/* <span>{getSymbolFromCurrency(currency)}</span> */}
                    {formatStringOrNumberToMoney({ value: balance, includeSign: true, currency })}
                  </span>
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row columns={2}> */}
              {/*   <Grid.Column style={{ textAlign: 'right' }}>Previous Balance: </Grid.Column> */}
              {/*   <Grid.Column style={{ textAlign: 'left' }}> */}
              {/*     /!* eslint-disable-next-line *!/ */}
              {/*     /!* <span>{getSymbolFromCurrency(currency)}</span> *!/ */}
              {/*     {formatStringOrNumberToMoney({ value: previousBalance, includeSign: true, currency })} */}
              {/*   </Grid.Column> */}
              {/* </Grid.Row> */}
            </Grid>
            <PayBalanceForm {...props} />
          </div>
        </Segment>
        <Segment style={{ flexGrow: 6, width: 0 }}>
          <Header as="h3">{translate('payBalance.Instructions')}</Header>
          <List as="ol">
            <List.Item as="li">{translate('payBalance.Enter your desired amount and click Pay.')}</List.Item>
            <List.Item as="li">{translate("payBalance.You'll be re-directed to our secure CyberSource payments portal.")}</List.Item>
            <List.Item as="li"><span style={{ fontWeight: 'bold' }}>{translate('payBalance.We currently only accept bank account payments, not credit card payments.')} &nbsp;&nbsp;</span>{translate("payBalance.Enter your bank account information and click Pay. (Note: Billing Address is your address, not your bank's address.)")}</List.Item>
            <List.Item as="li">{translate("payBalance.You'll receive an email receipt upon successful payment.")}</List.Item>
            <List.Item as="li">{translate('payBalance.Click Return to Website to return back here.')}</List.Item>
          </List>
          <div style={{ maxWidth: 700, paddingLeft: 25, paddingRight: 25 }}><p>{translate(`payBalance.${PAY_BALANCE_TEXT3}`)}</p></div>
        </Segment>
      </Segment.Group>
    </div>
  )
}

export default compose(
  UiContainer({
    headerText: 'Pay Balance',
    headerProps: { className: 'segment-header-bottom-margin' },
    containerId: 'payBalancePage',
  }),
  PayBalanceTemporarilyDisabledHOC,
  WaitForApiFetch,
  NoInvoicesOnFileHOC,
)(PayBalance)
