// CODE_COMMENTS_205

import React from 'react'


import ShipmentHistoryFormCreator from '../../HOC/ShipmentHistoryFormCreator'

import DownloadOutboundEmptyKegShipmentHistoryTableAsFile from '../DownloadTableAsFile'

import {
  inboundEmptyKegShipmentHistoryStatusesConfig,
} from '../../util/shipmentHistory/inboundEmptyKegShipmentHistory'

import { FETCH_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY } from '../../../../../redux/actions/actionTypes'


export default props => (
  <ShipmentHistoryFormCreator
    {...props}
    downloadFileComponent={DownloadOutboundEmptyKegShipmentHistoryTableAsFile}
    // Yes, we really mean _inbound_ empty keg shipment history here: outbound
    // empty doesn't have its own because it's identical to inbound empty
    statusesConfig={inboundEmptyKegShipmentHistoryStatusesConfig}
    submitAction={FETCH_OUTBOUND_EMPTY_KEG_SHIPMENT_HISTORY}
    inboundOrOutbound="outbound"
  />
)
