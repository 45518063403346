/**
 * CODE_COMMENTS_84
 */
import Immutable from 'seamless-immutable'

import {
  createReduxNormalizedLinkId,
} from '../../../../utils'

// leftSideObjIdKey and rightSideObjIdKey are arbitrary strings this reducer
// uses as keys in the saved store slice, which can then be used by selectors.
export default ({ saveActionType, leftSideObjIdKey, rightSideObjIdKey }) => {
  const initialState = Immutable({})
  return (state=initialState, action) => {
    if (saveActionType === action.type) {
      // `info` should be an array of objects you want to associate with a
      // customer ID. This reducer doesn't save these objects themselves (you
      // should be saving them in a different reducer), it simply extracts the
      // `id` prop from these objects and creates links between those ids and
      // the customerId passed in.
      const {
        info: arrayOfObjects,
        leftSideObjIdValue,
      } = action.payload
      const objToSave = arrayOfObjects.reduce((acc, obj) => {
        const linkId = createReduxNormalizedLinkId(leftSideObjIdValue, obj.id)
        return {
          [linkId]: {
            id: linkId,
            [leftSideObjIdKey]: leftSideObjIdValue,
            [rightSideObjIdKey]: obj.id,
          },
          ...acc,
        }
      }, {})
      return (
        Immutable.merge(
          state,
          objToSave,
        )
      )
    }
    return state
  }
}
