/**
 * CODE_COMMENTS_41
 */

import {
  REDUCER_NAMES_LOGOUT_BUTTON_ENABLED_TOGGLE as defaultSlice,
  LOGOUT_BUTTON_ENABLED_TOGGLE_ENABLED,
} from '../../constants'


export function getIsLogoutButtonEnabled(state) {
  return state[defaultSlice] === LOGOUT_BUTTON_ENABLED_TOGGLE_ENABLED
}
