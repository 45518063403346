import React from 'react'
import { Container, Header, Message } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import RevealContactInfo from '../../../common-components/RevealContactInfo'
import ContactCard from '../../../common-components/ContactCard'

import {
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
  CUSTOMER_REPS_SALES,
} from '../../../constants'
import {
  WARNING_TEXT1,
  WARNING_TEXT10,
  WARNING_TEXT11,
  WARNING_TEXT12,
  WARNING_TEXT13,
  WARNING_TEXT2,
  WARNING_TEXT3,
  WARNING_TEXT4,
  WARNING_TEXT5,
  WARNING_TEXT6,
  WARNING_TEXT7,
  WARNING_TEXT8,
  WARNING_TEXT9,
} from '../../../constants/labelCaptions'

export default ({
  isCustomerOnCreditHold,
  isCustomerOnReportingHold,
  isCustomerOnSalesHold,
  isCustomerStatusInactive,
  customerName,
  customerId,
  canCustomerNormallyOrderKegs,
  canCustomerNormallyOrderKegCollars,
  isCustomerOnAcknowledgmentHold,
  isCustomerOnAcknowledgmentHoldWarning,
  isCustomerOnReportingHoldWarning,
}) => {
  if (!isCustomerOnAcknowledgmentHold && !isCustomerOnCreditHold &&
    !isCustomerOnReportingHold && !isCustomerStatusInactive && !isCustomerOnSalesHold &&
    !isCustomerOnAcknowledgmentHoldWarning && !isCustomerOnReportingHoldWarning) {
    return null
  }

  const { t: translate } = useTranslation('common')
  let prohibitedFeaturesText
  if (canCustomerNormallyOrderKegs && canCustomerNormallyOrderKegCollars) {
    prohibitedFeaturesText = 'order kegs or keg collars'
  } else if (canCustomerNormallyOrderKegs) {
    prohibitedFeaturesText = 'order kegs'
  } else {
    prohibitedFeaturesText = 'order keg collars'
  }

  const prohibitedFeaturesTextForCreditHold = (
    canCustomerNormallyOrderKegs ||
    canCustomerNormallyOrderKegCollars
  )
    ? `${translate(WARNING_TEXT1)} ${translate(prohibitedFeaturesText)} (${translate(WARNING_TEXT2)}).`
    : '' // Master custonmers can neither order kegs nor order keg collars

  const prohibitedFeaturesTextForInactiveAccount = (
    canCustomerNormallyOrderKegs ||
    canCustomerNormallyOrderKegCollars
  )
    ? `, ${translate(WARNING_TEXT3)} ${translate(prohibitedFeaturesText)} (${translate(WARNING_TEXT2)})`
    : '' // Master custonmers can neither order kegs nor order keg collars


  let headerText
  let messageText
  const messageType = {}
  if (isCustomerOnCreditHold) {
    headerText = 'Credit Hold'
    messageText = `${customerName} ${translate(WARNING_TEXT4)} ${prohibitedFeaturesTextForCreditHold}`
  } else if (isCustomerOnReportingHold) {
    headerText = 'Reporting Hold'
    messageText = translate(WARNING_TEXT5)
  } else if (isCustomerOnSalesHold) {
    headerText = 'Sales Hold'
    messageText = `${customerName} ${translate(WARNING_TEXT6)} ${prohibitedFeaturesTextForCreditHold}`
  } else if (isCustomerStatusInactive) {
    headerText = 'Inactive Account'
    messageText = `${customerName}'s ${translate(WARNING_TEXT8)}${prohibitedFeaturesTextForInactiveAccount}. ${translate(WARNING_TEXT7)}`
  } else if (isCustomerOnAcknowledgmentHold) {
    headerText = 'Acknowledgment Hold'
    messageText = translate(WARNING_TEXT9)
  } else if (isCustomerOnAcknowledgmentHoldWarning) {
    messageType.warning = true
    headerText = 'Acknowledgment Hold Warning'
    messageText = translate(WARNING_TEXT10)
  } else if (isCustomerOnReportingHoldWarning) {
    messageType.warning = true
    headerText = 'Reporting Hold Warning'
    messageText = `${translate(WARNING_TEXT11)} ${customerName} ${WARNING_TEXT12} ${isCustomerOnReportingHoldWarning} ${translate(WARNING_TEXT13)}`
  }


  return (
    <Container>
      <Message negative {...messageType}>
        <Header as="h2">{`${translate(headerText)}: ${customerName}`}</Header>
        <p>{messageText}</p>
        {(isCustomerOnCreditHold || isCustomerOnReportingHold || isCustomerOnSalesHold) &&
          <ContactCard
            customerId={customerId}
            repType={isCustomerOnSalesHold ? CUSTOMER_REPS_SALES : CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
          />
        }
        {isCustomerStatusInactive &&
          <RevealContactInfo customerId={customerId} repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE} />
        }
      </Message>
    </Container>
  )
}
