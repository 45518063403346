/**
 * CODE_COMMENTS_31
 */

// Users can report shipments in the future?? Yep: CODE_COMMENTS_176
export const NUMBER_OF_DAYS_INTO_THE_FUTURE_USER_CAN_REPORT_SHIPMENTS = 60

export const FIELD_NAME_SOURCE_CUSTOMER = 'sourceCustomer'
export const FIELD_NAME_DESTINATION_CUSTOMER = 'destinationCustomer'
export const FIELD_NAME_INTERNAL_REFERENCE_NUM = 'internalReferenceNum'
export const FIELD_NAME_DATE_SHIPPED = 'dateShipped'

export const REFERENCE_NUMBER_REQUIRED_EXPLANATION = "This will show up on the distributor's invoice, so it's a required field; enter either your sales order number, invoice number or BOL number."


// These are strictly for labeling within the UI
export const SOURCE_OR_DEST_CUSTOMER_FIELD_LABEL_CHOICE_SOURCE = 'source'
export const SOURCE_OR_DEST_CUSTOMER_FIELD_LABEL_CHOICE_DESTINATION = 'destination'
export const SOURCE_OR_DEST_CUSTOMER_FIELD_LABEL_CHOICE_BREWER = 'brewer' // CODE_COMMENTS_93

// These are strictly for conveying to the form which field must be chosen
// first, the source or the destination
export const WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_SOURCE = 'source'
export const WHICH_CUSTOMERS_FIELD_MUST_BE_CHOSEN_FIRST_SOURCE_OR_DESTINATION_DESTINATION = 'destination'
