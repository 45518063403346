export const URL_PARAM_DESTINATION_CUSTOMER_ID = 'destcid'
export const URL_PARAM_ORIGIN_CUSTOMER_ID = 'origincid'
export const URL_PARAM_SHIPPED_START_DATE = 'sDateShip'
export const URL_PARAM_SHIPPED_END_DATE = 'eDateShip'
export const URL_PARAM_SHIPMENT_STATUS = 'status'
export const URL_PARAM_SHIPMENT_MOVEMENT_TYPE = 'movementType'
export const URL_PARAM_ORDER_ID = 'orderid'
export const URL_PARAM_PLANNED_PICKUP_START_DATE = 'startDatePlannedDelivery'
export const URL_PARAM_PLANNED_PICKUP_END_DATE = 'endDatePlannedDelivery'
export const URL_PARAM_START_DATE_PLANNED_OR_ACTUAL = 'startDatePlannedOrActual'
export const URL_PARAM_END_DATE_PLANNED_OR_ACTUAL = 'endDatePlannedOrActual'
