import { connect } from 'react-redux'

import Table from './components/Table'

import {
  getEntireSlice as getAllInboundFullKegShipmentHistoryObjects,
} from '../../../../../redux/selectors/histories/inboundFullKegShipmentHistory'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../../redux/selectors/rewrite/itemSkus'
import {
  getProp as getCustomerProp,
} from '../../../../../redux/selectors/customers'
import fetchStatusSelectors from '../../../../../redux/selectors/fetchStatuses/forms'

import {
  HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
} from '../../../../../constants'

import {
  createFormNameForRedux,
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../../../utils'
import { getEntireSlice as getEntireCustomersSlice } from '../../../../../redux/selectors/rewrite/customers'


const mapStateToProps = (state, ownProps) => {
  const {
    customerId,
    operatingContractBrewerCustomerId, // CODE_COMMENTS_88
    formReducerName,
  } = ownProps
  const customerType = getCustomerProp(state, customerId, 'customerType')
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const configuredItemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')

  const formName = createFormNameForRedux({
    reducerName: formReducerName,
    customerId,
  })

  const { getFetchStatuses } = fetchStatusSelectors
  const { isFetching, didFetchFail } = getFetchStatuses(state, formName)

  const areAnyItemsEditable = getAreAnyItemsEditable(state, customerId, operatingContractBrewerCustomerId)

  const allInboundFullKegShipmentObjects = getAllInboundFullKegShipmentHistoryObjects(
    state,
    // CODE_COMMENTS_196
    createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
  )
  const entireCustomersSlice = getEntireCustomersSlice(state)

  return {
    // We need to be extra careful that none of these render new value every
    // time some irrelevant part of the state changes, otherwise our table,
    // which can comprise hundreds or even thousands of components, will
    // re-render on each props update.
    entireItemSkusSlice,
    customerType,
    allInboundFullKegShipmentObjects,
    configuredItemSkuIds,
    isFormSubmitting: isFetching,
    hasFormSubmitFailed: didFetchFail,
    noResultsMessage: HISTORY_TABLE_NO_RESULTS_DEFAULT_MESSAGE,
    areAnyItemsEditable,
    getIsindividualItemEditable,
    entireCustomersSlice,
    ...ownProps,
  }
}

export default connect(
  mapStateToProps,
)(Table)


// CODE_COMMENTS_205
function getAreAnyItemsEditable() {
  return false
}


// CODE_COMMENTS_205
function getIsindividualItemEditable() {
  return false
}
