import React from 'react'
import { Icon, Message } from 'semantic-ui-react'


// CODE_COMMENTS_271
export default function DifferenceBetweenZeroAndBlankKegQtyFieldMessage({ arbitraryContent }) {
  const message = arbitraryContent || "If you have zero kegs of a certain type, enter 0, don't leave the field blank (blank means you don't have a count)."
  return (
    <Message
      info
      compact
      style={{
        margin: 0,
        padding: '0.5em 1em',
      }}
    >
      <Icon name="info" />
      {message}
    </Message>
  )
}
