import { connect } from 'react-redux'


import HistoryFormCreator from './components'

import {
  getMostRecentlySubmittedFormValues,
} from '../../../../../redux/selectors/rewrite/forms/mostRecentSuccessfullySubmittedHistoryFormValues'


function mapStateToProps(state, { formName }) {
  return {
    mostRecentlySubmittedFormValues: getMostRecentlySubmittedFormValues(state, formName),
  }
}

export default connect(
  mapStateToProps,
)(HistoryFormCreator)
