import { put, call, takeEvery } from 'redux-saga/effects'


import { privateFetch } from '../../fetch'
import {
  FETCH_STORED_BANK_ACCOUNT_INFO,
  SAVE_STORED_BANK_ACCOUNT_INFO,
  FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_REQUEST,
  FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_SUCCESS,
  FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_FAILURE,
} from '../../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../../util/fetchFailure'
import createAction from '../../../actions/createAction'

import {
  URL_PARAM_CUSTOMER_ID,
} from '../../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/bankAccountInfo'

import {
  API_URL_PATH_STORED_BANK_ACCOUNT_INFO,
} from '../../../../constants'

import {
  TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE,
} from '../../../../config'


export function* fetchStoredBankAccountInfo(action) {
  if (TEMPORARILY_DISABLE_SAVE_BANK_ACCOUNT_FEATURE) {
    return
  }
  const {
    customerId,
  } = action.payload

  yield put(createAction(
    FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_REQUEST,
    {
      target: [customerId],
    },
  ))

  const fetchConfig = {
    path: API_URL_PATH_STORED_BANK_ACCOUNT_INFO,
    params: {
      [URL_PARAM_CUSTOMER_ID]: customerId,
    },
  }

  let response
  try {
    // response = {
    //   data: [{
    //     paymentToken: 'ABCDEFGHIJKLMNOPQRSTUV',
    //     bankName: 'CAPITAL ONE',
    //     endingDigits: 9999,
    //   }],
    // }
    response = yield call(
      privateFetch,
      fetchConfig,
    )
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) { // customer has no invoices
        yield call(doSuccess, customerId, { data: [{}] })
        return
      }
      yield call(doFailure, { customerId, error })
      return
    }
    yield call(doFailure, { customerId, error })
    return
  }
  yield call(doSuccess, customerId, response)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_STORED_BANK_ACCOUNT_INFO, fetchStoredBankAccountInfo],
]


function* doSuccess(customerId, httpResponse) {
  const info = httpResponse.data[0]
  yield put(createAction(SAVE_STORED_BANK_ACCOUNT_INFO, { customerId, info }))
  yield put(createAction(
    FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_SUCCESS,
    {
      target: [customerId],
    },
  ))
}

function* doFailure({ customerId, error }) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_STATUSES_STORED_BANK_ACCOUNT_INFO_FAILURE,
        target: [customerId],
      }),
    },
  )
}
