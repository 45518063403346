/* eslint-disable max-len */

import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

import RouteNotFound from '../../../../RouteNotFound'
import PrivateRoute from '../../../../../common-components/routes/PrivateRoute'

import PrelimInfoVerification from '../../../../PrelimInfoVerification'

import Dashboard from './ContracteeBrewerDashboard'
import OrderKegCollars from '../../../../OrderKegCollars/OrderKegCollars'
import ReportOutboundShipments from '../../../../ReportShipments/ReportOutboundShipments'
import ReportBuybackShipments from '../../../../ReportShipments/ReportBuybackShipments'
import ReportSelfDistAndPubShipments from '../../../../ReportShipments/ReportSelfDistAndPubShipments'
import RequestNewDistributor from '../../../../RequestNewDistributor/v2.0/'

import {
  URL_PATHS,
} from '../../../../../constants'

import { createRelativePath } from '../../../../../utils'


// CODE_COMMENTS_26
const getRoutesList = ({
  match,
  customerId,
  operatingContractBrewerCustomerId,

  shouldOrderKegCollarsFormBeRendered,
  shouldReportOutboundShipmentsFormBeRendered,
  shouldReportOutboundShipmentsFormOptionBeDisabled,
  shouldReportBuybackShipmentsFormBeRendered,
  shouldReportSelfDistAndPubShipmentsFormBeRendered,
}) => ([
  <PrivateRoute
    exact
    key={URL_PATHS.root.path}
    path={createRelativePath(match, URL_PATHS.root.path)}
    render={
      routeProps => (
        <PrelimInfoVerification
          componentToRenderIfVerified={Dashboard}
          customerId={customerId}
          operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
          {...routeProps}
        />
      )
    }
  />,
  ...(
    shouldOrderKegCollarsFormBeRendered
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.orderKegCollars.path}
          path={createRelativePath(match, URL_PATHS.orderKegCollars.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={OrderKegCollars}
                customerId={customerId}
                operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    shouldReportOutboundShipmentsFormBeRendered
    && !shouldReportOutboundShipmentsFormOptionBeDisabled
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.reportOutboundShipments.path}
          path={createRelativePath(match, URL_PATHS.reportOutboundShipments.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ReportOutboundShipments}
                customerId={customerId}
                operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
                {...routeProps}
              />
            )
          }
        />,
        <PrivateRoute
          exact
          key={URL_PATHS.requestNewDistributor.path}
          path={createRelativePath(match, URL_PATHS.requestNewDistributor.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={RequestNewDistributor}
                customerId={customerId}
                operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    shouldReportBuybackShipmentsFormBeRendered
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.reportBuybackShipments.path}
          path={createRelativePath(match, URL_PATHS.reportBuybackShipments.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ReportBuybackShipments}
                customerId={customerId}
                operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
  ...(
    shouldReportSelfDistAndPubShipmentsFormBeRendered
      ? [
        <PrivateRoute
          exact
          key={URL_PATHS.reportSelfDistAndPubShipments.path}
          path={createRelativePath(match, URL_PATHS.reportSelfDistAndPubShipments.path)}
          render={
            routeProps => (
              <PrelimInfoVerification
                componentToRenderIfVerified={ReportSelfDistAndPubShipments}
                customerId={customerId}
                operatingContractBrewerCustomerId={operatingContractBrewerCustomerId}
                {...routeProps}
              />
            )
          }
        />,
      ]
      : []
  ),
])

export default withRouter(props => (
  <Switch>
    {getRoutesList(props)}
    <RouteNotFound />
  </Switch>
))
