/**
 * CODE_COMMENTS_131. Only use this on Semantic UI components whose actual
 * displayed content can be placed within a 'content' prop or in children, for
 * example, <Modal> and <Popup>.
 */
import React from 'react'


import CatastrophicFailureComponentBoundary from '../../catastrophicFailure/CatastrophicFailureComponentBoundary'


export default component => ({
  children,
  ...componentProps
}) => {
  const Component = component

  const content = componentProps.content
    ? <CatastrophicFailureComponentBoundary>{componentProps.content}</CatastrophicFailureComponentBoundary>
    : null

  const childrenWrappedInBoundary = children
    ? <CatastrophicFailureComponentBoundary>{children}</CatastrophicFailureComponentBoundary>
    : null

  return (
    <Component
      {...componentProps}
      // {...content}
      content={content}

    >
      {childrenWrappedInBoundary}
    </Component>
  )
}
