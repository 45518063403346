/* eslint-disable max-len */

import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Header, Grid, Button } from 'semantic-ui-react'

import get_ from 'lodash/get'


import { useTranslation } from 'react-i18next'
import UserBasicInfo from './UserBasicInfo'
import UserPermissions from './UserPermissions'

import RevealContentButton from '../../../../../common-components/RevealContentButton'


export default props => {
  const {
    userObject,
    currentUserId,
  } = props

  const [inEditMode, setInEditMode] = useState(false)
  const [areCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues, setareCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues] = useState(true)
  const [areCurrentValuesOfEditUserPermissionsFormSameAsInitialValues, setAreCurrentValuesOfEditUserPermissionsFormSameAsInitialValues] = useState(true)
  const [isEditUserBasicInfoFormSubmittable, setisEditUserBasicInfoFormSubmittable] = useState(true)
  // We don't need an "isEditUserPermissionsFormSubmittable" state variable
  // because the user permissions form is all checkboxes, so user input can't
  // put it into an unsubmittable state.


  const [isEditUserBasicInfoSubmitting, setisEditUserBasicInfoSubmitting] = useState(false)
  const [isEditUserPermissionsSubmitting, setIsEditUserPermissionsSubmitting] = useState(false)

  const [didEditUserBasicInfoSubmissionSucceed, setdidEditUserBasicInfoSubmissionSucceed] = useState(false)
  const [didEditUserPermissionsSubmissionSucceed, setDidEditUserPermissionsSubmissionSucceed] = useState(false)

  const [didEditUserBasicInfoSubmissionFail, setdidEditUserBasicInfoSubmissionFail] = useState(false)
  const [didEditUserPermissionsSubmissionFail, setDidEditUserPermissionsSubmissionFail] = useState(false)


  // Used by the UserPermissions component in case any of a MASTER user's CONBRW
  // children's contractees fail to fetch, in which case we can't populate the
  // user's perCustomerPermissions (see CODE_COMMENTS_259).
  const [editUserButtonHidden, setEditUserButtonHidden] = useState(false)

  const onEditUser = () => {
    setInEditMode(true)

    setisEditUserBasicInfoSubmitting(false)
    setIsEditUserPermissionsSubmitting(false)

    setdidEditUserBasicInfoSubmissionSucceed(false)
    setDidEditUserPermissionsSubmissionSucceed(false)

    setdidEditUserBasicInfoSubmissionFail(false)
    setDidEditUserPermissionsSubmissionFail(false)
  }

  const refEditUserBasicInfoFormOnCancelEditUser = useRef(null)
  const refEditUserPermissionsFormOnCancelEditUser = useRef(null)
  const onCancelEditUser = () => {
    setInEditMode(false)

    setisEditUserBasicInfoSubmitting(false)
    setIsEditUserPermissionsSubmitting(false)

    setdidEditUserBasicInfoSubmissionSucceed(false)
    setDidEditUserPermissionsSubmissionSucceed(false)

    setdidEditUserBasicInfoSubmissionFail(false)
    setDidEditUserPermissionsSubmissionFail(false)

    refEditUserBasicInfoFormOnCancelEditUser.current.onCancelEditUser()
    // This conditional is required because the user permissions section of a
    // MASTER user might not be rendered if one or more of the MASTER's conbrw
    // children's childConbrwCustomerId/relatedFrom call failed (i.e. if the
    // MASTER's child conbrw's fetch of its contractees failed)
    if (get_(refEditUserPermissionsFormOnCancelEditUser, ['current', 'onCancelEditUser'])) {
      refEditUserPermissionsFormOnCancelEditUser.current.onCancelEditUser()
    }
  }

  // Once both the User and Permissions calls finish, set to no longer in edit
  // mode.
  useEffect(
    () => {
      if (!isEditUserBasicInfoSubmitting && !isEditUserPermissionsSubmitting) {
        setInEditMode(false)
      }
    },
    [
      isEditUserBasicInfoSubmitting,
      isEditUserPermissionsSubmitting,
    ],
  )

  const refEditUserBasicInfoFormHandleSubmit = useRef(null)
  const refEditUserPermissionsFormHandleSubmit = useRef(null)
  const handleSubmit = () => {
    if (areCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues) {
      setisEditUserBasicInfoSubmitting(false)
      setdidEditUserBasicInfoSubmissionSucceed(true)
      setdidEditUserBasicInfoSubmissionFail(false)
    } else {
      setisEditUserBasicInfoSubmitting(true)
      setdidEditUserBasicInfoSubmissionSucceed(false)
      setdidEditUserBasicInfoSubmissionFail(false)
      refEditUserBasicInfoFormHandleSubmit.current.handleSubmit()
    }

    if (
      areCurrentValuesOfEditUserPermissionsFormSameAsInitialValues
      // The user permissions section of a MASTER user might not be rendered if
      // one or more of the MASTER's conbrw children's
      // {childConbrwCustomerId}/relatedFrom call failed (i.e. if the MASTER's
      // child conbrw's fetch of its contractees failed)
      || !get_(refEditUserPermissionsFormHandleSubmit, ['current', 'handleSubmit'])
    ) {
      setIsEditUserPermissionsSubmitting(false)
      setDidEditUserPermissionsSubmissionSucceed(true)
      setDidEditUserPermissionsSubmissionFail(false)
    } else {
      setIsEditUserPermissionsSubmitting(true)
      setDidEditUserPermissionsSubmissionSucceed(false)
      setDidEditUserPermissionsSubmissionFail(false)
      refEditUserPermissionsFormHandleSubmit.current.handleSubmit()
    }
  }

  const allProps = {
    ...props,
    inEditMode,
    setEditUserButtonHidden,
    onEditUser,
    onCancelEditUser,
    refEditUserBasicInfoFormOnCancelEditUser,
    refEditUserPermissionsFormOnCancelEditUser,
    handleSubmit,
    refEditUserBasicInfoFormHandleSubmit,
    refEditUserPermissionsFormHandleSubmit,
    areCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues,
    setareCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues,
    areCurrentValuesOfEditUserPermissionsFormSameAsInitialValues,
    setAreCurrentValuesOfEditUserPermissionsFormSameAsInitialValues,
    isEditUserBasicInfoFormSubmittable,
    setisEditUserBasicInfoFormSubmittable,
    isEditUserBasicInfoSubmitting,
    setisEditUserBasicInfoSubmitting,
    isEditUserPermissionsSubmitting,
    setIsEditUserPermissionsSubmitting,
    didEditUserBasicInfoSubmissionSucceed,
    setdidEditUserBasicInfoSubmissionSucceed,
    didEditUserPermissionsSubmissionSucceed,
    setDidEditUserPermissionsSubmissionSucceed,
    didEditUserBasicInfoSubmissionFail,
    setdidEditUserBasicInfoSubmissionFail,
    didEditUserPermissionsSubmissionFail,
    setDidEditUserPermissionsSubmissionFail,
  }

  return (
    <Fragment>
      <Header key="fullName" as="h3">{`${userObject.firstName} ${userObject.lastName} ${currentUserId === userObject.id ? '(you)' : ''}`}</Header>
      <Grid
        // This negates the default grid margin of -1rem, putting some space
        // between the displayed user info and the Edit user button; but see
        // CODE_COMMENTS_97
        style={{ marginBottom: 0 }}
      >
        <Grid.Row>
          <Grid.Column width={6}>
            <UserBasicInfo {...allProps} />
          </Grid.Column>
          <Grid.Column width={10}>
            <UserPermissions {...allProps} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {!editUserButtonHidden &&
        <Buttons {...allProps} />
      }
    </Fragment>
  )
}


const Buttons = props => {
  const {
    inEditMode,
    onEditUser,
    isEditUserBasicInfoSubmitting,
    isEditUserPermissionsSubmitting,
    didEditUserBasicInfoSubmissionSucceed,
    didEditUserPermissionsSubmissionSucceed,
    didEditUserBasicInfoSubmissionFail,
    didEditUserPermissionsSubmissionFail,
  } = props
  const { t: translate } = useTranslation('common')
  const isEditSubmitting = isEditUserBasicInfoSubmitting || isEditUserPermissionsSubmitting
  const didEditSubmissionSucceed = didEditUserBasicInfoSubmissionSucceed && didEditUserPermissionsSubmissionSucceed
  const didEditSubmissionFail = didEditUserBasicInfoSubmissionFail || didEditUserPermissionsSubmissionFail
  return (
    <Fragment>
      {!inEditMode &&
        <Button
          color="green"
          onClick={onEditUser}
          style={{
            // a little space between this button and the success/failure text after
            // the user gets edited
            marginRight: '1rem',
          }}
        >
          {translate('Edit User')}
        </Button>
      }
      {inEditMode &&
        <Fragment>
          <SubmitButton {...props} />
          <CancelEditButton {...props} />
        </Fragment>
      }
      {
        (didEditSubmissionSucceed || didEditSubmissionFail) &&
        <span
          style={{
            color: didEditSubmissionSucceed ? 'green' : 'red',
            fontWeight: 'bold',
          }}
        >
          {
            didEditSubmissionSucceed
              ? translate('User Successfully Edited')
              : translate('Edit Failed!')
          }
        </span>
      }
      {!isEditSubmitting && didEditSubmissionFail &&
        <RevealContentButton
          buttonTextWhenVisible="Details"
          buttonTextWhenHidden="Details"
          buttonProps={{
            style: { marginLeft: '0.5rem' }, // a little space between the text and this button
          }}
          blockDisplay={false}
          asPopup
          buttonColor="red"
        >
          {translate("We're sorry, some or all of the edits to this user failed. Please reach out to your personalized rep for updates. In the meantime, you can try your edit again or contact your personalized representative.")}
        </RevealContentButton>
      }
    </Fragment>
  )
}


const SubmitButton = ({
  handleSubmit,
  areCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues,
  areCurrentValuesOfEditUserPermissionsFormSameAsInitialValues,
  isEditUserBasicInfoFormSubmittable,
  isEditUserBasicInfoSubmitting,
  isEditUserPermissionsSubmitting,
}) => {
  const { t: translate } = useTranslation('common')
  const isEditSubmitting = isEditUserBasicInfoSubmitting || isEditUserPermissionsSubmitting
  const isFormSubmittable = (
    !isEditSubmitting
    && (
      (
        // The user only changed permissions
        areCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues
        && !areCurrentValuesOfEditUserPermissionsFormSameAsInitialValues
      )
      || (
        // The user changed basic info (and might or might not have changed
        // permissions)
        !areCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues
        && isEditUserBasicInfoFormSubmittable
      )
    )
  )
  const areCurrentValuesSameAsInitialValues = (
    areCurrentValuesOfEditUserBasicInfoFormSameAsInitialValues
    && areCurrentValuesOfEditUserPermissionsFormSameAsInitialValues
  )
  const buttonColor = isFormSubmittable
    ? 'green'
    : 'grey'

  const buttonText = areCurrentValuesSameAsInitialValues
    ? 'No Values Changed'
    : 'Submit'

  const shouldButtonDisplayALoadingSpinnerInPlaceOfText = isEditSubmitting

  const isButtonDisabled = !isFormSubmittable

  return (
    <Button
      color={buttonColor}
      disabled={isButtonDisabled}
      loading={shouldButtonDisplayALoadingSpinnerInPlaceOfText}
      onClick={handleSubmit}
    >
      {translate(buttonText)}
    </Button>
  )
}


const CancelEditButton = ({
  onCancelEditUser,
  isEditUserBasicInfoSubmitting,
  isEditUserPermissionsSubmitting,
}) => {
  const { t: translate } = useTranslation('common')
  if (isEditUserBasicInfoSubmitting || isEditUserPermissionsSubmitting) {
    return null
  }
  return (
    <Button
      style={{ paddingLeft: '2rem' }}
      color="red"
      onClick={onCancelEditUser}
    >
      {translate('Cancel Edit')}
    </Button>
  )
}
