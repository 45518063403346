import Immutable from 'seamless-immutable'

import {
  SAVE_RELATIONSHIPS,
  SAVE_RELATIONSHIP,
} from '../../actions/actionTypes'


import {
  isTruthyAndNonEmpty,
  convertArrOfObjsOrSingleObjToObjOfSubObjs,
} from '../../../utils'


const initialState = Immutable({})

export default (state=initialState, action) => {
  switch (action.type) {
    case SAVE_RELATIONSHIPS:
    case SAVE_RELATIONSHIP: {
      const { info } = action.payload
      // if we're not related to/from anyone, info will be an empty array
      if (!isTruthyAndNonEmpty(info)) { return state }

      return (
        Immutable.merge(
          state,
          convertArrOfObjsOrSingleObjToObjOfSubObjs(info),
        )
      )
    }
    default:
      return state
  }
}
