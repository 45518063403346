/* eslint-disable max-len */

import {
  getHasPermissionsToPerformFunctionality,
} from './permissions'
import {
  getProp as getCustomerProp,
  getIsCustomerAMasterOrBrwType,
} from './customers'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORTED_VS_CALCULATED_INVENTORY,
} from '../../constants/permissions'

import {
  REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY,
  REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA,
} from '../../constants'


export function getShouldReportedInventoryVsCalculatedInventoryPageBeRendered({
  state,
  customerId,
  operatingContractBrewerCustomerId,
}) {
  // Only logged in BRWs (or logged-in masters operating for BRW children) are
  // allowed to see the page, not CONBRWs operating for contractees
  if (operatingContractBrewerCustomerId) {
    return false
  }
  const isMasterOrBrw = getIsCustomerAMasterOrBrwType(state, customerId)
  // If customer.invReconciliationDate is null, don't show the page
  const inventoryReconciliationDate = getCustomerProp(state, customerId, 'invReconciliationDate')
  const hasViewInventoryHistoryPermissions = getHasPermissionsToPerformFunctionality({
    state,
    functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_REPORTED_VS_CALCULATED_INVENTORY,
  })
  return isMasterOrBrw && inventoryReconciliationDate && hasViewInventoryHistoryPermissions
}


export function getReportedVsCalculatedInventoryDataForCustomer(state, customerId) {
  return state[REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY].byCustomerId[customerId][REDUCER_NAMES_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY_DATA]
}
