/**
 * This file is for selectors that take info from several different store slices
 * and therefore aren't associated with one particular slice/reducer.
 */

import {
  getIsCustomerABrwType,
  getIsCustomerAConBrwType,
} from './customers'

import {
  getCustomerIdOfCustomerCurrentlyBeingOperatedFor,
  getOperatorCustomerIdOfCustomerCurrentlyBeingOperatedFor,
} from './currentlyOperatingCustomer'

import {
  getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn,
} from './relationships/relatedToInfo'

import {
  getCustomerIdsOfAllRelatedFrom,
} from './relationships/relatedFromInfo'

import {
  CUSTOMER_TYPES_BREWER,
} from '../../../constants'


// Use this selector sparingly: it relies on the current URL path, which is
// precarious (see CODE_COMMENTS_121).
export const getIsCustomerAContracteeBrewerBeingOperatedOnByAContractBrewer = ({
  entireCurrentUserSlice,
  entireCustomersSlice,
  customerId,
  currentUrlPath,
}) => {
  const currentUserCustomerId = entireCurrentUserSlice.rootCustomerId
  if (
    // if the current user is a Brewer, this session will not have any
    // contractee brewers
    getIsCustomerABrwType(entireCustomersSlice, currentUserCustomerId)
    ||
    // if the caller accidentally passed in a non-brewer contractee customerId
    !getIsCustomerABrwType(entireCustomersSlice, customerId)
  ) { return false }


  const currentlyOperatedForCustomerId = getCustomerIdOfCustomerCurrentlyBeingOperatedFor({
    entireCurrentUserSlice,
    currentUrlPath,
  })
  const operatorCustomerId = getOperatorCustomerIdOfCustomerCurrentlyBeingOperatedFor({
    entireCurrentUserSlice,
    currentUrlPath,
  })

  if (!currentlyOperatedForCustomerId || !operatorCustomerId) { return false }

  return (
    getIsCustomerABrwType(entireCustomersSlice, currentlyOperatedForCustomerId) &&
    getIsCustomerAConBrwType(entireCustomersSlice, operatorCustomerId)
  )
}


// Call this on Contract Brewers.
export const getAreAnyContracteeBrewersRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn = ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId, // the Contract Brewer's customerId
  onlyRelationshipsThatShipmentsCanBeReportedOn, // CODE_COMMENTS_263
}) => {
  const contracteeBrwIds = getCustomerIdsOfAllRelatedFrom({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    onlyRelationshipsThatShipmentsCanBeReportedOn,
    customerObjsCustomFilterFunc: o => o.customerType === CUSTOMER_TYPES_BREWER,
  })
  return contracteeBrwIds.some(id => getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId: id,
  }))
}


// Call this on either brewers or contract brewers
export const getIsBrwOrConBrwRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn = ({
  entireCustomersSlice,
  entireContractsSlice,
  entireRelationshipsSlice,
  customerId,
}) => {
  const isContractBrewer = getIsCustomerAConBrwType(entireCustomersSlice, customerId)
  return isContractBrewer
    ? getAreAnyContracteeBrewersRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
      // CODE_COMMENTS_112
      onlyRelationshipsThatShipmentsCanBeReportedOn: true,
    })
    : getIsRelatedToAnyLocalDistributorsThatShipmentsCanBeReportedOn({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      customerId,
    })
}
