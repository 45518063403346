// A replacement for 'SemanticUiCardChoices', this component alllows you to
// group card choices into separate tabs.

import React, { memo } from 'react'
import { Tab, Container } from 'semantic-ui-react'

import SemanticUiCardChoices from './index'

export default memo(({
  tabDefinitions,
  parentTabComponentProps,
  // These will be passed into the <SemanticUiCardChoices> component
  ...rest
}) => (
  <Tab
    panes={createTabPanesOfSemanticUiCardChoices({ tabDefinitions, ...rest })}
    // I don't know why, but renderActiveOnly is necessary to make the tab
    // content show up
    renderActiveOnly={false}
    {...parentTabComponentProps}
  />
))


function createTabPanesOfSemanticUiCardChoices({
  tabDefinitions,
  ...rest
}) {
  return tabDefinitions.map((
    {
      tabTitle,
      propsForSemanticUiCardChoices,
    },
    index,
  ) => {
    const tabHtmlId = `${tabTitle}-tab-button`
    return {
      menuItem: {
        // adding a key here avoids a react console warning: 'Each child in an
        // array or iterator should have a unique "key" prop.'
        key: tabHtmlId,
        id: tabHtmlId,
        content: tabTitle,
      },
      // CODE_COMMENTS_42
      pane: {
        id: `${tabTitle}-tab-content`,
        key: `Tab ${index}`,
        // The following `as` prop prevents the Tab Pane content from being
        // wrapped in a <Segment>, which is what happens by default. We
        // essentially don't want the content to be wrapped in any tag, but
        // Semantic UI React has the constraint that tab panes need to be
        // wrapped in a tag. So to emulate not being wrapped in anything, we
        // swap a <Segment> with a <Container> and get rid of the Container's
        // default border with a `border: 0` style prop.
        as: Container,
        style: { border: 0 },
        content: (
          <SemanticUiCardChoices
            {...rest}
            {...propsForSemanticUiCardChoices}
          />
        ),
      },
    }
  })
}
