/**
 * CODE_COMMENTS_160
 */
import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { Trans } from 'react-i18next'
import {
  OPERATING_FOR_PREPEND_TEXT,
} from '../util'

export default ({
  breadcrumbDefinitions,
}) => {
  const breadcrumbs = breadcrumbDefinitions.reduce(
    (acc, { link, label }, index) => {
      if (index < breadcrumbDefinitions.length-1) { // if not last item
        return [
          ...acc,
          (
            <Breadcrumb.Section key={link} as={Link} to={link}>
              <Trans ns="common" i18nKey={label}>
                <Label labelText={label} />
              </Trans>
            </Breadcrumb.Section>
          ),
          (
            <Breadcrumb.Divider key={`${link}-divider`} icon="right angle" />
          ),
        ]
      }
      return [
        ...acc,
        (
          <Breadcrumb.Section key={link} active>
            <Trans ns="common" i18nKey={label}>
              {label}
            </Trans>
          </Breadcrumb.Section>
        ),
      ]
    },
    [],
  )
  return (
    <Breadcrumb>
      {breadcrumbs}
    </Breadcrumb>
  )
}


const Label = ({
  labelText,
}) => {
  if (labelText.includes(OPERATING_FOR_PREPEND_TEXT)) {
    const textAfterOperatingForText = labelText.split(OPERATING_FOR_PREPEND_TEXT)[1]
    return (
      <span>
        {OPERATING_FOR_PREPEND_TEXT}
        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>{textAfterOperatingForText}</span>
      </span>
    )
  }
  return labelText
}
