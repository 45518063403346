import { connect } from 'react-redux'


import ReportAndEditShipments from './components'

import {
  getEntireSlice as getEntireCustomersSlice,
  getProp as getCustomerProp,
} from '../../../../redux/selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
} from '../../../../redux/selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../../../redux/selectors/rewrite/parentChildLinks'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../redux/selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntireUsersSlice,
} from '../../../../redux/selectors/rewrite/users'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../redux/selectors/rewrite/currentUser'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../redux/selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireItemSkusSlice,
} from '../../../../redux/selectors/rewrite/itemSkus'
import {
  getEntireSlice as getEntireReportShipmentsFormsPotentialDuplicatesSlice,
} from '../../../../redux/selectors/rewrite/reportShipmentsFormsPotentialDuplicates'
import { getEntireSlice as getEntireSubsidiariesSlice } from '../../../../redux/selectors/rewrite/subsidiaries'


const mapStateToProps = (state, { customerId, ignoreDisplayOnShipments }) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  const entireUsersSlice = getEntireUsersSlice(state)
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  const entireSubsidiariesSlice = getEntireSubsidiariesSlice(state)
  // For duplicates checks--see CODE_COMMENTS_244
  const entireReportShipmentsFormsPotentialDuplicatesSlice = getEntireReportShipmentsFormsPotentialDuplicatesSlice(
    state,
  )
  const itemSkuIds = getCustomerProp(state, customerId, 'itemSkuIds')
  const customerType = entireCustomersSlice?.[customerId]?.customerType


  return {
    entireSubsidiariesSlice,
    entireCustomersSlice,
    entireContractsSlice,
    entireParentChildLinksSlice,
    entireRelationshipsSlice,
    entirePermissionsSlice,
    entireCurrentUserSlice,
    entireUsersSlice,
    entireItemSkusSlice,
    entireReportShipmentsFormsPotentialDuplicatesSlice,
    itemSkuIds,
    customerType,
    customerId,
    ignoreDisplayOnShipments,
  }
}


export default connect(
  mapStateToProps,
)(ReportAndEditShipments)
