import { compose } from 'recompose'

import withPermissions from './reduxHoc'
import withNoPermissions from './uiHoc'

export default compose(
  // Order is very important here. We want to make sure that the Redux HOC comes
  // before the UI HOC because the UI HOC will be expecting props that the Redux
  // HOC provides.
  withPermissions,
  withNoPermissions,
)
