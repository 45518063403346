import {
  getEntireSlice as commonGetEntireSlice,
} from './higherOrderFunctions'

import { REDUCER_NAMES_CURRENT_USER as defaultSlice } from '../../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../../utils'

export const getEntireSlice = commonGetEntireSlice(defaultSlice)


export const getCurrentUserFullName = entireCurrentUserSlice => (
  `${entireCurrentUserSlice.firstName} ${entireCurrentUserSlice.lastName}`
)

export const getIfEmployeeUserIsLoggedInHaveTheyChosenCustomerUserToOperateFor = entireCurrentUserSlice => (
  isTruthyAndNonEmpty(entireCurrentUserSlice)
)
