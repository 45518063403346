import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react'

import FetchFailureContent from '../../FetchFailureContent'
import RevealContactInfo from '../../RevealContactInfo'

import DefaultFailureAndSuccessButtons from '../DimmerOverlay/DefaultFailureAndSuccessButtons'

import FetchFailureUnknownErrorContent from './FetchFailureUnknownErrorContent'


import formFetchStatusesSelectors from '../../../redux/selectors/fetchStatuses/forms'

import {
  isFetchStatusesSliceSeparatedIntoIndividualFieldArrays,
  getNameOfFirstFieldArrayOfForm,
} from '../../../redux/selectors/fetchStatuses/forms/formWithFieldArrays'

import {
  DISPLAYED_ERROR_MESSAGES_FETCH_NO_RESPONSE_CODE,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
} from '../../../constants'

const { getFetchFailureErrorDetails: getFormFetchFailureErrorDetails } = formFetchStatusesSelectors

// Every form has its own slice in the fetchStatuses portion of the Redux Store.
// However, within the form slice, the actual fetch status information (which is
// composed of a "status" prop and an "error details" prop) can be in a
// different place depending on the nature of the form. Specifically, there are
// two types of forms that will use a dimmer overlay:

// 1. Regular forms such as the Order Kegs form. These forms have the "status"
// and "errorDetails" props as direct children of the form's fetchStatus slice.
// The shape is like this:

// { status: ..., errorDetails: ...}

// 2. Forms like the "Edit Outbound Shipment Report" form, which is very similar
// to the "Report Outbound Shipment" form in that they both have a field array
// (the same field array, in fact). The difference is that the "Edit outbound
// shipment report" form only ever has a single row in its field array and only
// has one submit, whereas the "Report Outbound Shipments" form can have
// multiple rows and has a submit for each row. Because the "Edit Outbound
// Shipments Report" form is culled from the "Report Outbound Shipments" form,
// the shape of its fetchStatus slice is the same as the shape of the "Report
// Outbound Shipments" form's fetchStatus slice. The shape is like this:

// { fieldArrayName: [{ status: ..., errorDetails: ...}] }


// We want to render the default exported component in this file on both
// "regular" forms, which have no field arrays, and on forms which have a single
// row in a single field array. This function fetches the correct error details
// no no matter what type of form we have.
const getFetchFailureErrorDetailsDependingOnFormType = (state, formName) => {
  if (isFetchStatusesSliceSeparatedIntoIndividualFieldArrays(state, formName)) {
    const fieldArrayName = getNameOfFirstFieldArrayOfForm(state, formName)
    // get the fetch error details of the first row of the first field array
    return getFormFetchFailureErrorDetails(state, formName, fieldArrayName, 0)
  }
  // This also targets fetch statuses when the user deletes a history item, even
  // though there's not actually any form rendered when deleting a history
  // item--see CODE_COMMENTS_197
  return getFormFetchFailureErrorDetails(state, formName)
}

const mapStateToProps = (state, ownProps) => {
  const { form: formName } = ownProps


  const errorDetails = getFetchFailureErrorDetailsDependingOnFormType(
    state,
    formName,
  )

  return ({
    ...ownProps,
    // It's super important that the stateProps' errorDetails prop come *after*
    // ownProps, becuase ownProps might contain an outdated errorDetails prop
    // (this is the case in the Order Keg Collars form)
    errorDetails,
  })
}


const mapDispatchToProps = dispatch => ({
  dispatch, // for mergeProps
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    errorDetails,
  } = stateProps
  const {
    // these will only be passed in if this is for an "edit existing item" form
    isEditForm,

    // these will only be passed in if this is a "delete item" form
    itemIdIfThisIsForDeletingAnItem,
    onSubmit,

    //
    closeModalFunc,
  } = ownProps
  const {
    customerId,
  } = ownProps
  let { customFailureRenderInstructions } = ownProps
  // eslint-disable-next-line no-param-reassign
  if (!customFailureRenderInstructions) { customFailureRenderInstructions = [] }
  const instructions = {
    renderWhenTrue: [
      ...customFailureRenderInstructions,
      {
        when: ({ errorCode }) => !errorCode,
        render: () => (
          <div>
            <p>{DISPLAYED_ERROR_MESSAGES_FETCH_NO_RESPONSE_CODE}</p>
            <Button
              color="blue"
              content={itemIdIfThisIsForDeletingAnItem ? 'Retry' : 'Resubmit'}
              onClick={itemIdIfThisIsForDeletingAnItem
                ? onSubmit
                : () => {}
              }
            />
            <DefaultFailureAndSuccessButtons
              {...ownProps} // passing in the form name as the 'form' prop
              isEditForm={isEditForm}
              closeModalFunc={closeModalFunc}
              confirmClearForm
            />
            {/* If we set the blockDisplay prop of RevealContactInfo rather
              than wrapping it in a div, it won't be centered within the
              dimmer. */}
            <div>
              <RevealContactInfo
                customerId={customerId}
                repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
                asPopup
              />
            </div>
          </div>
        ),
      },
    ],
    renderWhenNoneTrue: FetchFailureUnknownErrorContent,
  }
  return {
    // the FetchFailureUnknownErrorContent component needs these
    ...ownProps,
    instructions,
    // It's super important that the stateProps' errorDetails prop come *after*
    // ownProps, becuase ownProps might contain an outdated errorDetails prop
    // (this is the case in the Order Keg Collars form)
    errorDetails,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(FetchFailureContent)
