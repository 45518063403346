/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file

import { call, select, takeEvery } from 'redux-saga/effects'

import valuesFp_ from 'lodash/fp/values'
import flow_ from 'lodash/fp/flow'
import filterFp_ from 'lodash/fp/filter'
import mapFp_ from 'lodash/fp/map'
import {
  fetchAcknowledgeIndividualInboundShipment,
} from './acknowledgeIndividualInboundShipment'

import {
  FETCH_ACKNOWLEDGE_INBOUND_SHIPMENTS,
  FETCH_STATUSES_FORMS_QUEUE,
} from '../../../actions/actionTypes'

import createAction from '../../../actions/createAction'

import {
  getIsFieldRowFullyFilledOut,
  getUnacknowledgedShipmentSkus,
} from '../../../../features/AcknowledgeInboundShipments/util'


import {
  FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS,
  FIELD_NAME_SHIPMENT_ID,
} from '../../../../constants/formAndApiUrlConfig/acknowledgeInboundShipments'
import { getEntireSlice as getEntireItemSkusSlice } from '../../../selectors/rewrite/itemSkus'


function* fetchAcknowledgeInboundShipments(action) {
  const {
    formValues,
    dispatchFetchStatuses,
    inboundUnacknowledgedShipmentsSliceForThisCustomer,
  } = action.payload
  const state = yield select()
  const entireItemSkusSlice = getEntireItemSkusSlice(state)
  // Set all rows that are going to be fetched to queued

  // CODE_COMMENTS_50
  for (const [rowIndex, rowValues] of formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS].entries()) {
    // the user doesn't have to fill out every single row, just one
    const shippedSkus = flow_(
      valuesFp_,
      filterFp_(o => o?.shipmentId === rowValues[FIELD_NAME_SHIPMENT_ID]),
      mapFp_(o => o),
      obj => getUnacknowledgedShipmentSkus(obj, entireItemSkusSlice),
    )(inboundUnacknowledgedShipmentsSliceForThisCustomer)
    if (getIsFieldRowFullyFilledOut({
      ...action.payload,
      rowValues,
      rowIndex,
      itemSkuIds: shippedSkus,
    })) {
      dispatchFetchStatuses(createAction(
        FETCH_STATUSES_FORMS_QUEUE,
        { target: [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS, rowIndex] }),
      )
    }
  }

  // Now submit each row in sequential order (no parallel requests)
  for (const [rowIndex, rowValues] of formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS].entries()) {
    // the user doesn't have to fill out every single row, just one
    const shippedSkus = flow_(
      valuesFp_,
      filterFp_(o => o?.shipmentId === rowValues[FIELD_NAME_SHIPMENT_ID]),
      mapFp_(o => o),
      obj => getUnacknowledgedShipmentSkus(obj, entireItemSkusSlice),
    )(inboundUnacknowledgedShipmentsSliceForThisCustomer)
    if (getIsFieldRowFullyFilledOut({
      ...action.payload,
      rowValues,
      rowIndex,
      itemSkuIds: shippedSkus,
    })) {
      yield call(
        fetchAcknowledgeIndividualInboundShipment,
        {
          payload: {
            ...action.payload,
            rowIndex,
            rowValues,
          },
        },
      )
    }
  }
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_ACKNOWLEDGE_INBOUND_SHIPMENTS, fetchAcknowledgeInboundShipments],
]
