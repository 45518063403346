import React, { Fragment } from 'react'
import { Card, Button } from 'semantic-ui-react'
import {
  Link,
  withRouter,
} from 'react-router-dom'


import { useTranslation } from 'react-i18next'
import InformationalPopup from '../../../common-components/InformationalPopup'
import RevealContactInfo from '../../../common-components/RevealContactInfo'

import {
  CUSTOMER_STATUS_PENDING,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
  DISPLAYED_ERROR_MESSAGES_CUSTOMER_IN_PENDING_STATUS,
} from '../../../constants'

import {
  getOperateForCustomerUrl,
  createRelativePath,
} from '../../../utils'

import DisplayedAddress from '../../../common-components/DisplayedAddress'

export default withRouter(({
  customerId,
  masterCustomerId,
  name,
  customerType,
  customerStatus,
  region,
  address,
  tapcustomerId,
  match,
}) => (
  <Card centered>
    <Card.Content>
      <Card.Header>{ name }</Card.Header>
      <Card.Meta>{ customerType }</Card.Meta>
      <Card.Meta>Region: { region }</Card.Meta>
      <Card.Meta>Tap ID: { tapcustomerId }</Card.Meta>
      {customerStatus === CUSTOMER_STATUS_PENDING &&
        <Fragment>
          <Card.Meta style={{ color: 'orange' }}>
            Pending
            <InformationalPopup
              iconName="question"
              content={
                <Fragment>
                  <span>{DISPLAYED_ERROR_MESSAGES_CUSTOMER_IN_PENDING_STATUS}</span>
                  <RevealContactInfo
                    blockDisplay
                    customerId={masterCustomerId}
                    repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
                  />
                </Fragment>
              }
            />
          </Card.Meta>
        </Fragment>
      }
      <Card.Description>
        <DisplayedAddress
          address={address}
        />
      </Card.Description>
    </Card.Content>
    <Card.Content extra className="centered-content">
      <OperateForButton
        customerId={customerId}
        customerStatus={customerStatus}
        match={match}
      />
    </Card.Content>
  </Card>
))


const OperateForButton = ({
  customerId,
  customerStatus,
  match,
}) => {
  // What about Inactive customers? We've already filtered them out in a parent
  // component, no populated card will be rendered for them.
  const { t: translate } = useTranslation('common')
  if (customerStatus !== CUSTOMER_STATUS_PENDING) {
    return (
      <Link to={createRelativePath(match, getOperateForCustomerUrl(customerId))}>
        <Button className="testOperateForButton" fluid color="blue">{translate('Operate For')}</Button>
      </Link>
    )
  }
  // Pending customer
  return (
    <Button className="testOperateForButton" fluid disabled>
      {translate('Operate For')}
    </Button>
  )
}
