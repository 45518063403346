import { connect } from 'react-redux'

import VerifiedOrErrorMessages from './components'

// `verifiers` is an array of two-prop objects:

// {
//   verifierFunction: () => {...},
//   onlyRunIfThisFuncReturnsTrue: [
//     () => {...},
//     ...
//   ],
// }

// These two-prop objects are important because sometimes you don't want to run
// a verifier unless certain conditions are met, say until other verifiers have
// passed first or not unless the user has certain permissions. If you don't
// want to put any conditions on whether the verifier function runs, simply
// omit the onlyRunIfThisFuncReturnsTrue prop.

// Each verifier is passed these props:
// * state
// * customerId
// * operatingContractBrewerCustomerId
const mapStateToProps = (state, { verifiers, ...rest }) => {
  const {
    customerId,
    operatingContractBrewerCustomerId,
  } = rest
  const verificationErrorMessages = verifiers.map(
    ({
      verifierFunction,
      onlyRunIfThisFuncReturnsTrue,
    }) => {
      if (onlyRunIfThisFuncReturnsTrue) {
        if (onlyRunIfThisFuncReturnsTrue(state, customerId, operatingContractBrewerCustomerId)) {
          return verifierFunction(state, customerId, operatingContractBrewerCustomerId)
        }
        return true
      }
      return verifierFunction(state, customerId, operatingContractBrewerCustomerId)
    },
  )

  return {
    verificationErrorMessages,
    ...rest,
  }
}

export default connect(
  mapStateToProps,
)(VerifiedOrErrorMessages)
