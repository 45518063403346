import { put, call, select, takeEvery } from 'redux-saga/effects'
import moment from 'moment'

import { privateFetch } from '../fetch'

import {
  SHIPMENT_STATUS_IN_TRANSIT,
  SHIPMENT_STATUS_DELIVERED,
} from '../../../constants/formAndApiUrlConfig/commonConfig'

import {
  FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS,
  FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_REQUEST,
  FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_SUCCESS,
  FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_FAILURE,
  SAVE_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER,
  UNSET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER,
  FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS,
} from '../../actions/actionTypes'

import {
  createFetchFailureAction,
  generalDoFailure,
} from '../util/fetchFailure'
import createAction from '../../actions/createAction'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../selectors/rewrite/customers'
import {
  getEntireSlice as getEntireSubsidiariesSlice,
  getIsSubsidiaryATypeThatShouldDisplayOrderIdInAckInboundShipmentsForm,
} from '../../selectors/rewrite/subsidiaries'

import {
  URL_PARAM_DESTINATION_CUSTOMER_ID,
  URL_PARAM_SHIPMENT_STATUS,
} from '../../../constants/formAndApiUrlConfig/urlParamsAndRequestProps/shipments'

import {
  API_URL_PATH_SHIPMENTS_HISTORY,
  CUSTOMER_TYPES_PUB,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  CUSTOMER_TYPES_WAREHOUSE,
} from '../../../constants'

import {
  parseShipmentType,
  createMultipleStatusesQueryParamValueForShipmentsApiCall,
} from '../../../utils'

export function* fetchCustomerInboundUnacknowledgedShipments(action) {
  const { customerId } = action.payload
  yield put(createAction(FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_REQUEST, { target: [customerId] }))
  let response
  try {
    response = yield call(
      privateFetch,
      {
        path: API_URL_PATH_SHIPMENTS_HISTORY,
        params: {
          [URL_PARAM_DESTINATION_CUSTOMER_ID]: customerId,
          [URL_PARAM_SHIPMENT_STATUS]: createMultipleStatusesQueryParamValueForShipmentsApiCall(
            SHIPMENT_STATUS_IN_TRANSIT,
            SHIPMENT_STATUS_DELIVERED,
          ),
        },
      },
    )
  } catch (error) {
    if (error.response) {
      if (error.response.status === 404) { // no unacknowledged shipments
        yield call(doSuccess, customerId, [])
        return
      }
      yield call(doFailure, customerId, error)
      return
    }
    yield call(doFailure, customerId, error)
    return
  }
  yield call(doSuccess, customerId, response.data)
}

// CODE_COMMENTS_11
export default [
  [takeEvery, FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS, fetchCustomerInboundUnacknowledgedShipments],
]

function* doSuccess(customerId, info) {
  const filteredShipments = filterOutSelfDistAndPubShipments(info)
  const furtherFilteredShipments = filterOutFutureShipments(filteredShipments)
  // DO NOT use ifTruthyAndNotEmpty(filteredShipments) here, because we want to
  // save empty arrays as well (imagine a user has an unacknowledged shipment,
  // then acknowledges it, then refreshes the unacknowledged shipments list and
  // it returns a 404, in which case info will be set to an empty array: we want
  // to overwrite the now-acknowledged shipment in the Redux store with an empty
  // array of shipments).
  if (furtherFilteredShipments) {
    yield put(
      createAction(
        SAVE_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_OF_ONE_CUSTOMER,
        { customerId, info: furtherFilteredShipments },
      ),
    )
    // Unsetting here is necessary because although fetching inbound
    // unacknowledged shipments gets started before the dashboard loads, it can
    // still be waiting for a response from the backend by the time the
    // dashboard loads (that is, its completion does not block the dashboard
    // from loading like other fetches do).
    yield put(createAction(UNSET_ACK_INBOUND_SHIPMENTS_REMINDER_POPUP_HAS_HAD_CHANCE_TO_RENDER, { customerId }))
  }
  // CODE_COMMENTS_20
  yield put(createAction(
    FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_SUCCESS, { target: [customerId] },
  ))
  // CODE_COMMENTS_283
  const entireSubsidiariesSlice = yield select(getEntireSubsidiariesSlice)
  const subsidiaryId = (yield select(getEntireCustomersSlice, customerId)).subsidiaryId
  if (getIsSubsidiaryATypeThatShouldDisplayOrderIdInAckInboundShipmentsForm({
    entireSubsidiariesSlice,
    subsidiaryId,
  })) {
    yield put(createAction(
      FETCH_ORDERS_OF_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS, { customerId },
    ))
  }
}

function* doFailure(customerId, error) {
  yield call(
    generalDoFailure,
    {
      error,
      action: createFetchFailureAction({
        error,
        type: FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS_FAILURE,
        target: [customerId],
      }),
    },
  )
}

// This function filters out any SELFDIST2BRW or PUB2BRW shipments that might
// have been accidentally marked as "Delivered" status by the backend.
// SELFDIST2BRW and PUB2BRW shipments are never supposed to have a status of
// "Delivered" and brewers aren't supposed to have to acknowledge inbound empty
// shipments of SELFDIST or PUB to the brewer. This function is a "Just in case"
// safety net: the only reason it would return a different array from the
// original is if the backend messes up.
function filterOutSelfDistAndPubShipments(shipments) {
  return shipments.filter(shipment => {
    const [from, to] = parseShipmentType(shipment.movementType)
    if (from === CUSTOMER_TYPES_SELF_DISTRIBUTION && to === CUSTOMER_TYPES_WAREHOUSE) {
      return true
    }
    return ![CUSTOMER_TYPES_SELF_DISTRIBUTION, CUSTOMER_TYPES_PUB].includes(from)
  })
}

function filterOutFutureShipments(shipments) {
  return shipments.filter(shipment => {
    // const mome = moment()
    if (shipment?.dateShipped) {
      return moment(shipment?.dateShipped).isSameOrBefore(Date.now(), 'day')
    } else if (shipment?.plannedPickupDate) {
      return moment(shipment?.plannedPickupDate).isSameOrBefore(Date.now(), 'day')
    } else {
      return true
    }
  })
}
