import {
  REDUCER_NAMES_HISTORY,
} from '../../../constants'


import {
  withPropSimple,
  withPropOfAllSimple,
  getEntireSlice as commonGetEntireSlice,
} from '../higherOrderFunctions'


import {
  getAreAnyItemsInACertainStatus as commonGetAreAnyItemsInACertainStatus,
} from './shared'


export function createAllShipmentsHistorySelectorsHof(specificHistoryReducerSlice) {
  const defaultSlices = [REDUCER_NAMES_HISTORY, specificHistoryReducerSlice, 'byCustomerId']

  // usage: getProp(state, customerId, orderId, 'dateOrdered')
  const getProp = withPropSimple(...defaultSlices)
  // usage: getPropOfAfll(state, customerId, 'dateOrdered')
  const getPropOfAll = withPropOfAllSimple(...defaultSlices)
  const getEntireSlice = commonGetEntireSlice(...defaultSlices)
  const getAreAnyItemsInACertainStatus = commonGetAreAnyItemsInACertainStatus(getPropOfAll, 'status')

  return {
    getProp,
    getPropOfAll,
    getEntireSlice,
    getAreAnyItemsInACertainStatus,
  }
}
