import { delay } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'

import get_ from 'lodash/get'
import isString_ from 'lodash/isString'

import {
  getIsAuthenticated,
  getProp as getAuthenticationProp,
} from '../../../../redux/selectors/authentication'
import {
  getProp as getCurrentCustomerUserProp,
} from '../../../../redux/selectors/currentUser'

import {
  AUTHENTICATION_FAILURE,
  SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE,
  SET_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503,
} from '../../../actions/actionTypes'

import createAction from '../../../actions/createAction'

import {
  RETRY_API_FETCH_SIGNAL,
  API_URL_PATH_LOGIN,
} from '../../../../constants'

import {
  API_FETCH_TIMEOUT_NUM_OF_RETRY_ATTEMPTS,
  API_FETCH_TIMEOUT_RETRY_DELAY,
  API_FETCH_FALSE_4XX_AND_5XX_ERRORS_NUM_OF_RETRY_ATTEMPTS,
  API_FETCH_NOT_IMPLEMENTED_RETRY_DELAY,
  API_FETCH_NETWORK_CONNECTION_ISSUE_NUM_OF_RETRY_ATTEMPTS,
  API_FETCH_NETWORK_CONNECTION_ISSUE_RETRY_DELAY,
} from '../../../../config'

import {
  logApiCallError,
} from '../../../../utils/thirdPartyLogging'

import {
  getDoesHttpErrorIndicateThatBackendIsUnderMaintentance,
  extractDateRangeFromString,
  extractMostImportantDetailsFromApiErrorObject,
} from '../../../../utils'

/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

// Safe methods are those that don't change the backend, they just fetch data
const safeMethods = ['GET', 'OPTIONS', 'HEAD']

export const getIsThisASafeHttpCall = config => (
  // fetch calls don't have to include a method. If they don't, the axios
  // library uses 'GET' by default, which is safe and idempotent
  !config.method ||
  safeMethods.includes(config.method.toUpperCase())
)

const getIsThisARefreshUserTokenCall = config => (
  config.path === API_URL_PATH_LOGIN &&
  config.method === 'PUT'
)

// CODE_COMMENTS_117
export function* getDoesThisCallUseTimeoutPolling(config) {
  const isAuthenticated = yield select(getIsAuthenticated)
  const isThisASafeHttpCall = getIsThisASafeHttpCall(config)
  // refresh user token calls are the one call we make when we're authenticated
  // and we don't include a JWT token. They should not use timeout polling
  // because timeout polling requires a JWT.
  const isThisARefreshUserTokenCall = getIsThisARefreshUserTokenCall(config)
  return (
    // you have to be logged in to be able to use timeoutPolling, because the
    // timeout polling URL requires a JWT token
    isAuthenticated &&
    !isThisASafeHttpCall &&
    !isThisARefreshUserTokenCall
  )
}


// returns a new headers object
export function* addAuthHeaderToFetchHeaders(headers) {
  const authToken = yield select(getAuthenticationProp, 'userToken')
  const authHeader = { Authentication: authToken }

  return headers
    ? { ...headers, ...authHeader }
    : authHeader
}


// Returns a new headers object. Make sure that a customer user has been
// selected to operate for before calling this function, otherwise it will
// throw an error.
export function* addOperateAsCustomerUserHeaderToFetchHeaders(headers) {
  const idOfCustomerUserCurrentlyOperatingFor = yield select(getCurrentCustomerUserProp, 'id')
  const operateAsCustomerUserHeader = {
    OperateAsUser: idOfCustomerUserCurrentlyOperatingFor,
    // OperateAsCustomerUser: idOfCustomerUserCurrentlyOperatingFor,
  }
  return headers
    ? { ...headers, ...operateAsCustomerUserHeader }
    : operateAsCustomerUserHeader
}


/*
 * *****************************************************************************
 * universal error reactions
 * *****************************************************************************
*/


// CODE_COMMENTS_118
const errorReactionsOfCallThatDoesNotUseTimeoutPolling = [
  {
    check: error => !get_(error, ['response']), // CODE_COMMENTS_76
    reaction: function* mySaga(error, numTimesThisHasHappened) {
      if (numTimesThisHasHappened > API_FETCH_NETWORK_CONNECTION_ISSUE_NUM_OF_RETRY_ATTEMPTS) {
        throw error
      }
      yield call(delay, 1000*API_FETCH_NETWORK_CONNECTION_ISSUE_RETRY_DELAY)
      return RETRY_API_FETCH_SIGNAL
    },
    doesThisReactionNeedToComeBeforeAnyCustomErrorReactions: true, // CODE_COMMENTS_256
  },
  {
    check: error => get_(error, 'response.status') === 504, // CODE_COMMENTS_117
    reaction: function* mySaga(error, numTimesThisHasHappened) {
      if (numTimesThisHasHappened > API_FETCH_TIMEOUT_NUM_OF_RETRY_ATTEMPTS) { throw error }
      logApiCallError({
        error,
        ifTimeoutWillCallBeRetriedOrPolled: 'retry',
      })
      yield call(delay, 1000*API_FETCH_TIMEOUT_RETRY_DELAY)
      return RETRY_API_FETCH_SIGNAL
    },
  },
]


const errorReactionsOfCallThatUsesTimeoutPolling = [
  {
    // Calls that use timeout polling cannot be retried, because most of the
    // time the calls are non-idempotent, so if we don't get a response, we
    // unfortunately have no choice but to throw an error.
    check: error => !get_(error, ['response']),
    reaction: error => { throw error },
    doesThisReactionNeedToComeBeforeAnyCustomErrorReactions: true,
  },
  // When the timeout polling fetch throws a 504, it's because the web app has
  // been polling the original request for
  // API_FETCH_TIMEOUT_POLLING_STOP_POLLING_AFTER_X_MINUTES minutes and the
  // original request still hasn't returned a response. In such a case, the
  // timeout polling feature will throw the *original* 504 received by the
  // *original* request. there's nothing we can do to get the original response
  // anymore, so the polling feature simply returns the original 504. We throw
  // it here and let it bubble up to the saga that made the original request. We
  // DO NOT want to return the RETRY_API_FETCH_SIGNAL here, because the fetch
  // may be idempotent (see CODE_COMMENTS_117). Technically we don't need to
  // define this 504 check here; the fetchWithCustomErrorReactions will throw
  // the 504 even if this definition weren't here here. But we define it here to
  // be as explicit as possible about our intentions, so as to help future
  // developers grok this rather complex fetching algorithm.
  {
    check: error => get_(error, 'response.status') === 504, // CODE_COMMENTS_117
    reaction: error => { throw error },
  },
]

export const getUniversalErrorReactions = ({
  doesThisCallUseTimeoutPolling,
  isPrivateFetch,
}) => {
  const variableErrorReactions = doesThisCallUseTimeoutPolling
    ? errorReactionsOfCallThatUsesTimeoutPolling
    : errorReactionsOfCallThatDoesNotUseTimeoutPolling
  return [
    ...variableErrorReactions,
    {
      // False 501s, False 417s and all other False 4xx and 5xx codes--see
      // CODE_COMMENTS_76. This check is intentionally above the checks for 401
      // unauthorized and 503 maintenance mode in case the backend throws false
      // 401/503 errors; we want to retry those calls up to ~4 times before
      // deciding that, yes, we're definitely unauthorized/in maintenance mode.
      check: (error, config) => {
        if (get_(error, 'response.status') < 400 || get_(error, 'response.status') > 599) {
          return false
        }
        // 404 errors on GET calls are one of the few universal exceptions to
        // the "always try to re-fetch idempotent calls" rule.
        if (
          get_(error, 'response.status') === 404
          && (
            !get_(config, ['method'])
            || (
              isString_(get_(config, ['method']))
              && get_(config, ['method']).toLowerCase() === 'get'
            )
          )
        ) {
          return false
        }
        return true
      },
      reaction: function* mySaga(error, numTimesThisHasHappened) {
        if (numTimesThisHasHappened > API_FETCH_FALSE_4XX_AND_5XX_ERRORS_NUM_OF_RETRY_ATTEMPTS) {
          throw error
        }
        yield call(delay, 1000*API_FETCH_NOT_IMPLEMENTED_RETRY_DELAY)
        return RETRY_API_FETCH_SIGNAL
      },
    },
    // Abstracts away "unauthorized" errors, which occur when a token has
    // expired (but see CODE_COMMENTS_9). We will rarely encounter 401 errors
    // because we have a service worker that keeps the user logged in, but we
    // need to handle these errors nonetheless because what if our service
    // worker dies unexpectedly? In such a rare case, we don't implement any
    // complex logic (no saving of form data, no caching the fetch to run after
    // the user re-logs in); we just kick the user out. Why don't we try to get
    // a new userToken here, behind-the-scenes, when receiving a 401? See
    // CODE_COMMENTS_210.

    // Technically this 401 handler isn't a universal error reaction because
    // it's impossible to get a 401 "unauthorized" on public fetches, but it
    // doesn't hurt anything for this error reaction to be included in public
    // fetches.
    {
      // 401 is unauthorized, which means the user's JWT has expired
      check: error => get_(error, 'response.status') === 401,
      reaction: function* mySaga(error) {
        yield put(createAction(AUTHENTICATION_FAILURE, { error }))
      },
    },
    {
      // 503, maintenance mode
      check: error => {
        const underMaintenance = getDoesHttpErrorIndicateThatBackendIsUnderMaintentance({ error })
        // CODE_COMMENTS_238: We're not going to display the "Under Maintenance"
        // splash screen if the user isn't logged in. Instead, each individual
        // public call call (login, forgot password, etc.) will handle the 503
        // by displaying a "We're in Maintenance Mode" error message.
        return (underMaintenance && isPrivateFetch)
      },
      reaction: function* mySaga(error) {
        const mostImportantInfo = extractMostImportantDetailsFromApiErrorObject({ error })
        const maintenanceModeDurationString = get_(mostImportantInfo, 'responseBody.messageDetail', '')
        const startAndEnd = extractDateRangeFromString({ str: maintenanceModeDurationString })
        yield put(createAction(
          SET_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503,
          startAndEnd,
        ))
        yield put(createAction(SET_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE, true))
        throw error
      },
    },
  ]
}
