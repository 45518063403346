/**
 * CODE_COMMENTS_134
 */

import { connect } from 'react-redux'

import {
  getOriginNameForKegFillsContracteeHistoryTable,
} from './util'

export const DisplayedOriginNameForKegFillsContracteeHistoryTable = connect(
  (state, { row }) => ({
    displayedText: getOriginNameForKegFillsContracteeHistoryTable({ state, row }),
  }),
)(({ displayedText }) => displayedText)
