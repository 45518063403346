/* eslint-disable max-len */

import { combineReducers } from 'redux'


import {
  REDUCER_NAMES_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE,
  REDUCER_NAMES_MAINTENANCE_MODE_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED,
  REDUCER_NAMES_MAINTENANCE_SCHEDULE,
  REDUCER_NAMES_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503,
} from '../../../constants'

import areWeCurrentlyInMaintenanceMode from './areWeCurrentlyInMaintenanceMode'
import shouldNotificationOfUpcomingMaintenanceBeDisplayed from './shouldNotificationOfUpcomingMaintenanceBeDisplayed'
import maintenanceSchedule from './maintenanceSchedule'
import startAndEndTimesFrom503 from './startAndEndTimesFrom503'

const formsAdditionalInfoReducers = {
  [REDUCER_NAMES_ARE_WE_CURRENTLY_IN_MAINTENANCE_MODE]: areWeCurrentlyInMaintenanceMode,
  [REDUCER_NAMES_MAINTENANCE_MODE_SHOULD_NOTIFICATION_OF_UPCOMING_MAINTENANCE_BE_DISPLAYED]: shouldNotificationOfUpcomingMaintenanceBeDisplayed,
  [REDUCER_NAMES_MAINTENANCE_SCHEDULE]: maintenanceSchedule,
  [REDUCER_NAMES_MAINTENANCE_MODE_START_AND_END_TIMES_FROM_503]: startAndEndTimesFrom503,
}

export default combineReducers(formsAdditionalInfoReducers)
