import React from 'react'
import { withRouter } from 'react-router-dom'

import withCustomerPrelimInfoFetchingOrFailed from '../../../common-components/HOC/reduxUiCombined/withCustomerPrelimInfoFetchingOrFailed'
import NestedOperateForRoutes from '../containers/NestedOperateForRoutes'

export default withRouter( // CODE_COMMENTS_25
  withCustomerPrelimInfoFetchingOrFailed(props => (
    <NestedOperateForRoutes
      {...props}
    />
  )),
)
