/**
 * CODE_COMMENTS_84
 */
import {
  SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS,
  SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK,
  DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS,
  DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK,
} from '../../actions/actionTypes'

import reducerCreator from './higherOrderEntityReducers/basicReducerCreator'

export default reducerCreator([
  SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS,
  SAVE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK,
  DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINKS,
  DELETE_CUSTOMER_TO_CONTACTS_NORMALIZED_LINK,
])
