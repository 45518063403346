import Immutable from 'seamless-immutable'

import get_ from 'lodash/get'


import {
  FETCH_STATUSES_QUEUED,
  FETCH_STATUSES_IN_PROGRESS,
  FETCH_STATUSES_SUCCESS,
  FETCH_STATUSES_FAILURE,
} from '../../../../../constants'

import { CustomTypeError } from '../../../../../customErrors'

import { withoutIn } from '../../../util/seamlessImmutable'

import { isNonArrayObject } from '../../../../../utils'


export const pristineErrorDetails = {
  errorurl: null,
  method: null,
  errorCode: null,
  errorMessage: null,
  responseBody: null,
}


export default ({
  containerNesting,
  initialStateValue,
}) => (
  requestActionType,
  successActionType,
  failureActionType,
  resetActionType,
  queueActionType,
) => {
  let initialState = Immutable({})
  if (containerNesting) {
    initialState = Immutable.setIn(initialState, containerNesting, initialStateValue)
  } else if (initialStateValue) {
    // if the user passes in an initialStateValue but not a containerNesting,
    // the initialValue must be an object
    if (isNonArrayObject(initialStateValue)) {
      initialState = Immutable(initialStateValue)
    } else {
      throw new CustomTypeError('If you pass in an initialStateValue arg without a containterNesting arg, it must be an object')
    }
  }

  return (state=initialState, action) => {
    switch (action.type) {
      case requestActionType: {
        const updatedInfo = {
          status: FETCH_STATUSES_IN_PROGRESS,
          errorDetails: pristineErrorDetails,
        }
        return setImmutably(containerNesting, state, action, updatedInfo)
      }

      case successActionType: {
        const updatedInfo = {
          status: FETCH_STATUSES_SUCCESS,
          errorDetails: pristineErrorDetails,
        }
        return setImmutably(containerNesting, state, action, updatedInfo)
      }

      case failureActionType: {
        const updatedInfo = {
          status: FETCH_STATUSES_FAILURE,
          errorDetails: action.payload.errorDetails,
        }
        return setImmutably(containerNesting, state, action, updatedInfo)
      }


      case queueActionType: {
        const updatedInfo = {
          status: FETCH_STATUSES_QUEUED,
          errorDetails: pristineErrorDetails,
        }
        return setImmutably(containerNesting, state, action, updatedInfo)
      }

      case resetActionType: {
        return deletePortionOfState(containerNesting, state, action)
      }


      default:
        return state
    }
  }
}


/*
 * *****************************************************************************
 * Helper Functions
 * *****************************************************************************
*/

function setImmutably(containerNesting, state, action, value) {
  let objPathToSetValueIn = get_(action, ['payload', 'target']) || []
  if (containerNesting) { objPathToSetValueIn = [...containerNesting, ...objPathToSetValueIn] }

  // The user doesn't need to pass in either a `target` value or a
  // `containerNesting` value. If they don't pass in either of them, it means
  // that this fetch status will only ever get called once at a time. It's a
  // kind of singleton in this way.
  if (objPathToSetValueIn.length === 0) {
    return (
      Immutable.merge(
        state,
        value,
      )
    )
  }
  return (
    Immutable.setIn(
      state,
      objPathToSetValueIn,
      value,
    )
  )
}

/**
 * Removes a portion of the state. The action object passed in should have a
 * payload which is a list of the nested keys, the last of which is the one you
 * want to delete. You can also pass in no payload at all, in which case this
 * entire fetchStatus slice is deleted (which is something you'd want to do if,
 * say, this fetch status only ever gets called one at a time and so is a kind
 * of singleton).
 */
function deletePortionOfState(containerNesting, state, action) {
  const nestedPathToDelete = get_(action, ['payload', 'target']) || []
  const keyToDelete = nestedPathToDelete[nestedPathToDelete.length-1]
  const nestedPathToDeleteWithoutFinalKey = nestedPathToDelete.slice(0, -1)
  const fullPathToDeleteWithoutFinalKey = containerNesting
    ? [...containerNesting, ...nestedPathToDeleteWithoutFinalKey]
    : nestedPathToDeleteWithoutFinalKey
  // if we're trying to delete a singleton-like key (see this function's
  // docstring)
  if (fullPathToDeleteWithoutFinalKey.length === 0 && !keyToDelete) {
    return Immutable({})
  }
  // if we're trying to delete a top-level key within the state slice
  if (fullPathToDeleteWithoutFinalKey.length === 0) {
    return Immutable.without(state, keyToDelete)
  }
  return withoutIn(
    state,
    [...fullPathToDeleteWithoutFinalKey, keyToDelete],
  )
}
