import React from 'react'
import { Card } from 'semantic-ui-react'

import ContainerTypeCard from './ContainerTypeCard'
import { getSelectContainerTypesInfo } from './util'

import InformationalPopup from '../../../../common-components/InformationalPopup'


export default ({
  entireItemSkusSlice,
  itemSkuIdsToInclude,
  businessUnitIdOfCurrentUserRootCustomer,
  displayPicsOfMKMKegsRatherThanNoPicsAtAllIfBrandOfBusinessUnitPicsNotAvailable,
}) => {
  const selectContainerTypesInfo = getSelectContainerTypesInfo({
    entireItemSkusSlice,
    itemSkuIdsToInclude,
    businessUnitIdOfCurrentUserRootCustomer,
    displayPicsOfMKMKegsRatherThanNoPicsAtAllIfBrandOfBusinessUnitPicsNotAvailable,
  })

  return (
    <InformationalPopup
      content={
        <Card.Group>
          {selectContainerTypesInfo.map(containerTypeInfo => (
            <ContainerTypeCard
              key={containerTypeInfo.containerType}
              containerTypeInfo={containerTypeInfo}
            />
          ))}
        </Card.Group>
      }
      popupProps={{
        flowing: true,
        position: 'right center',
      }}
    />
  )
}
