import React from 'react'
import { Message, Button } from 'semantic-ui-react'

import FetchFailureContent from '../../common-components/FetchFailureContent'
import RevealContactInfo from '../../common-components/RevealContactInfo'
import RevealFetchErrorDetails from '../../common-components/fetchErrorDetails/RevealFetchErrorDetails'


import {
  DISPLAYED_ERROR_MESSAGES_FETCH_NO_RESPONSE_CODE,
  CUSTOMER_REPS_CUSTOMER_EXPERIENCE,
  DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR,
} from '../../constants'

import {
  isTruthyAndNonEmpty,
} from '../../utils'


export default props => {
  const {
    customerId,
    statusesOfMultipleFetches, // passed in if we're waiting for multiple API fetches, see CODE_COMMENTS_81
    resubmitFunc,
    resubmitButtonText='Retry',
    failureHeaderMessage,
  } = props
  let {
    errorDetails, // passed in if we're waiting for a single API fetch
    customFailureRenderInstructions,
  } = props
  /* eslint-disable no-param-reassign */

  if (statusesOfMultipleFetches) {
    errorDetails = statusesOfMultipleFetches.errorDetailsObjects.find(
      errorDetailsObj => isTruthyAndNonEmpty(errorDetailsObj),
    )
  }
  if (!customFailureRenderInstructions) { customFailureRenderInstructions = [] }
  /* eslint-enable no-param-reassign */

  const instructions = {
    renderWhenTrue: [
      ...customFailureRenderInstructions,
      {
        when: ({ errorCode }) => !errorCode,
        render: () => (
          <ErrorContent
            message={DISPLAYED_ERROR_MESSAGES_FETCH_NO_RESPONSE_CODE}
            showErrorDetailsButton={false}
            customerId={customerId}
            resubmitFunc={resubmitFunc}
            resubmitButtonText={resubmitButtonText}
          />
        ),
      },
    ],


    renderWhenNoneTrue: () => (
      <ErrorContent
        message={DISPLAYED_ERROR_MESSAGES_UNKNOWN_SERVER_ERROR}
        customerId={customerId}
        resubmitFunc={resubmitFunc}
        resubmitButtonText={resubmitButtonText}
        errorDetails={errorDetails}
      />
    ),


    wrapperComponent: ({ children }) => (
      <Message compact negative>
        <Message.Header>{failureHeaderMessage}</Message.Header>
        {children}
      </Message>
    ),
  }
  return (
    <FetchFailureContent
      {...props}
      instructions={instructions}
      errorDetails={errorDetails}
    />
  )
}


const ErrorContent = ({
  message,
  showResubmitButton=true,
  showContactRepButton=true,
  showErrorDetailsButton=true,

  // optional props depending on which buttons are shown. For instance, no need
  // to pass in the errorDetails if showErrorDetailsButton is false
  customerId,
  resubmitFunc,
  resubmitButtonText,
  errorDetails,
}) => (
  <div>
    <p>{message}</p>
    <div>
      {showResubmitButton &&
        <ResubmitButton
          resubmitButtonText={resubmitButtonText}
          onClick={resubmitFunc}
        />
      }
      {/* RevealContactInfo has a blockDisplay prop you can set to true, which
        allows you to avoid having to wrap it in a div, but if we used that prop
        here, the button won't be centered within the containing div. */}
      <div>
        {showContactRepButton &&
          <RevealContactInfo
            customerId={customerId}
            repType={CUSTOMER_REPS_CUSTOMER_EXPERIENCE}
          />
        }
        {showErrorDetailsButton &&
          <RevealFetchErrorDetails
            errorDetails={errorDetails}
          />
        }
      </div>
    </div>
  </div>
)

const ResubmitButton = ({
  resubmitButtonText,
  onClick,
}) => (
  <Button
    color="blue"
    content={resubmitButtonText}
    onClick={onClick}
  />
)
