import React from 'react'

import { Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export default ({
  info: collarOrderObj,
  collarPlates,
}) => {
  const tableData = createTableData(collarOrderObj, collarPlates)
  const { t: translate } = useTranslation('common')
  return (
    <Table
      celled
      headerRow={[translate('Name'), translate('Quantity'), translate('# Colors'), translate('Part Number')]}
      renderBodyRow={({ id, flavor, quantity, numberOfColors, partNumber }) => ({
        key: id,
        cells: [flavor, quantity, numberOfColors, partNumber],
      })}
      tableData={tableData}
    />
  )
}


// Helper functions

function createTableData(collarOrderObj, collarPlates) {
  return collarOrderObj.collarPlates.map(collarOrderPlateLinkObject => ({
    ...collarPlates[collarOrderPlateLinkObject.collarPlateId],
    // Order is important here: both
    // collarPlates[collarOrderPlateLinkObject.collarPlateId] and
    // collarOrderPlateLinkObject have an id, and in the object we return, we
    // want the id to be the collarOrderPlateLinkObject id, because we can be
    // sure that that id will be unique and will therefore be appropriate to use
    // as a React list key
    // (collarPlates[collarOrderPlateLinkObject.collarPlateId] is not guaranteed
    // to be unique because remember that a customer can make a collar order in
    // which they select the same collar plate for multiple rows).
    ...collarOrderPlateLinkObject,
  }))
}
