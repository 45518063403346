import React from 'react'
import {
  Button,
} from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import createAction from '../../../../../../../redux/actions/createAction'
import {
  FETCH_RESEND_CONFIRMATION_CODE_TO_NEW_USER,
  FETCH_FORGOT_PASSWORD_ENTER_EMAIL,
} from '../../../../../../../redux/actions/actionTypes'

import {
  getIsStringAValidEmailAddress,
} from '../../../../../../../utils'


export default ({
  email,
  isConfirmAccountForm,
  dispatch,
  isFetching,
  didFetchFail,
  didFetchSucceed,
}) => {
  const isValidEmailAddress = getIsStringAValidEmailAddress(email)
  const { t: translate } = useTranslation('common')

  let buttonText = `${translate('Resend Code to')} ${email}`
  let buttonColor = 'blue'
  if (!isValidEmailAddress) {
    // This will happen when a user first navigates to the "Confirm Account"
    // page--we don't yet know what their email address is.
    buttonText = translate('Resend Code: enter email address below to enable')
  }
  if (isFetching) {
    buttonColor = 'grey'
  }
  if (didFetchFail) {
    buttonText = translate('Failed! Retry?')
    buttonColor = 'red'
  }
  if (didFetchSucceed) {
    buttonText = `${translate('Sent! Resend to')} ${email}`
    buttonColor = 'green'
  }

  return (
    <div>
      <Button
        color={buttonColor}
        onClick={() => {
          dispatch(createAction(
            isConfirmAccountForm ? FETCH_RESEND_CONFIRMATION_CODE_TO_NEW_USER : FETCH_FORGOT_PASSWORD_ENTER_EMAIL,
            { email },
          ))
        }}
        loading={isFetching}
        disabled={isFetching || !isValidEmailAddress}
        size="tiny"
        className="reduced-padding-button"
        style={{ marginTop: '0.5em', marginBottom: '0.5em' }}
      >
        {buttonText}
      </Button>
    </div>
  )
}
