import React from 'react'


import ReportAndEditShipments from './common/ReportAndEditShipments'

import {
  REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../constants/formAndApiUrlConfig/reportOutboundShipments'
import {
  REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
} from '../../constants/formAndApiUrlConfig/reportKegFills'
import {
  REPORT_SELF_COLLECTION_SHIPMENTS_FIELD_ARRAY_NAME,
} from '../../constants/formAndApiUrlConfig/reportSelfCollectionShipments'

import {
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
  REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE,
} from '../../constants/formAndApiUrlConfig/reportBuybackShipments'

import {
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
  REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
} from '../../constants/formAndApiUrlConfig/reportSelfDistAndPubShipments'

import {
  CUSTOMER_TYPES_BREWER,
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_PUB,
  CUSTOMER_TYPES_SELF_DISTRIBUTION,
  CUSTOMER_TYPES_SELF_COLLECTION,
} from '../../constants'

import {
  parseShipmentType,
} from '../../utils'


export default props => {
  const {
    formType,
    fieldArrayNameIfIsEditForm,
  } = determineFormTypeBasedOnShipmentObj(props)
  return (
    <ReportAndEditShipments
      isEditForm
      formType={formType}
      fieldArrayNameIfIsEditForm={
        fieldArrayNameIfIsEditForm
      }
      {...props}
    />
  )
}


// Helper functions

function determineFormTypeBasedOnShipmentObj({
  customerId,
  itemObj: shipmentObj,
}) {
  const [originCustomerType, destinationCustomerType] = parseShipmentType(shipmentObj.movementType)
  const currentlyOperatingCustomerIsDestination = customerId === shipmentObj.destinationCustomerId

  // if the currently operating customer is the destination, this can only be
  // one of these shipment types:
  // 1. an inbound shipment arranged by the MicroStar Logistics team which
  //    fulfills part of a (or an entire) keg order; in such a case, the code
  //    flow will never get to this component because such a shipment can never
  //    be in 'pending' state, which is one of the requirements for a shipment
  //    form to be edited. So we don't need to worry about this case;
  // 2. empty kegs the Brewer has moved from self-dist/pub;
  // 3. Keg fills from the CONBRW to the logged-in BRW (remember that BRWs who
  //    have CONBRWs brewing for them can not only see the keg fills their
  //    CONBRWs report, but also report Keg Fills for their CONBRWs and edit
  //    those keg fills that they report.
  // 4. a Self-Collection shipment
  // 5. Buyback shipments of kegs with out-of-code beer in them received from a
  //    distributor the Brewer recently shipped the kegs out to. In this case
  //    they're not technically "Buyback/Reverse" shipments in that they don't
  //    have their "reverse" flag set to true; rather, they're inbound shipments
  //    with the distributor as the origin and the Brewer as the destination.
  if (currentlyOperatingCustomerIsDestination) {
    // Case #2. The web app can't actually distinguish between out-of-code
    // buybacks moved from a Self-Dist/Pub and empty keg shipments created by
    // the "Report Self-Dist/Pub Shipments" form--the shipment objects are
    // exactly the same. However, out-of-code buybacks moved from a
    // Self-Dist/Pub are more rare than empty keg shipments created by the
    // "Report Self-Dist/Pub Shipments" form, so we simply assume it's an empty
    // keg shipment created by the "Report Self-Dist/Pub Shipments" form.
    if ([CUSTOMER_TYPES_SELF_DISTRIBUTION, CUSTOMER_TYPES_PUB].includes(originCustomerType)) {
      return ({
        formType: 'selfDistAndPubs',
        fieldArrayNameIfIsEditForm: REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_EMPTY_KEGS_FROM,
      })
    }

    // Case #3
    if (
      originCustomerType === CUSTOMER_TYPES_CONTRACT_BREWER
      && destinationCustomerType === CUSTOMER_TYPES_BREWER
    ) {
      return ({
        formType: 'kegFills',
        fieldArrayNameIfIsEditForm: REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
      })
    }

    // Case #4
    if (originCustomerType === CUSTOMER_TYPES_SELF_COLLECTION) {
      return ({
        formType: 'selfCollection',
        fieldArrayNameIfIsEditForm: REPORT_SELF_COLLECTION_SHIPMENTS_FIELD_ARRAY_NAME,
      })
    }

    // CASE #5: If we get to this part of the code flow, we know that this must
    // be a buyback shipment of out-of-code-beer kegs--although we can't
    // distinguish between Case #2 and Case #3 when the origin is a
    // Self-Dist/Pub, we _can_ distinguish between them when the origin customer
    // is _not_ a Self-Dist/Pub.
    return ({
      formType: 'buybacks',
      fieldArrayNameIfIsEditForm: REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_BEER_OUT_OF_CODE,
    })
  }


  // curently operating customer is the origin

  // if it's a reverse shipment, it has to be a buyback, no matter the
  // destination customer type
  if (shipmentObj.reverseMovement) {
    return ({
      formType: 'buybacks',
      // The web app can't actually distinguish between ready-for-resale
      // buyback shipments and defective buyback shipments--the shipment
      // objects look exactly the same (i.e. there's no `defective` flag on
      // a shipmentObject). Therefore, we simply assume this to be a
      // ready-for-resale, nothing-wrong-with-kegs-or-product buyback. We
      // can get away with this for two reasons: 1) when editing a shipment,
      // the field array name isn't displayed to the user, the heading just
      // says "Edit Shipment: [shipmentId]"; 2) defective keg buybacks are
      // refunded to the customer just like ready-for-resale kegs are
      // (that's why they're both outbound full keg shipments), so--and this
      // is a dirty little secret--the ready-for-resale field array and
      // defective-kegs field array in the Report Buyback Shipments form
      // render the _exact same content_ (same origin customer choices, same
      // destination customer choices). So it doesn't matter which of the
      // two field arrays we choose here--the fact that we choose
      // ready-for-resale is arbitrary.
      fieldArrayNameIfIsEditForm: REPORT_BUYBACK_SHIPMENTS_FIELD_ARRAY_NAME_RESALE,
    })
  }

  if ([CUSTOMER_TYPES_SELF_DISTRIBUTION, CUSTOMER_TYPES_PUB].includes(destinationCustomerType)) {
    return ({
      formType: 'selfDistAndPubs',
      fieldArrayNameIfIsEditForm: REPORT_SELF_DIST_PUB_FIELD_ARRAY_NAME_FULL_KEGS_TO,
    })
  }

  // Case #3
  if (
    originCustomerType === CUSTOMER_TYPES_CONTRACT_BREWER
    && destinationCustomerType === CUSTOMER_TYPES_BREWER
  ) {
    return ({
      formType: 'kegFills',
      fieldArrayNameIfIsEditForm: REPORT_KEG_FILLS_FIELD_ARRAY_NAME,
    })
  }

  // If it's not a reverse or keg fill, and if self-dist/pub isn't destination,
  // render a regular ol' outbound shipments form
  return ({
    formType: 'outbounds',
    fieldArrayNameIfIsEditForm: REPORT_OUTBOUND_SHIPMENTS_FIELD_ARRAY_NAME,
  })
}
