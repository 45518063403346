import { connect } from 'react-redux'

import { ManageAllContacts } from './components/ManageContactsNoLogin'

import {
  getEntireSlice as getEntireContactsSlice,
  getEntireLinksSlice as getEntireCustomerContactLinksSlice,
} from '../../redux/selectors/contacts'

import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../redux/selectors/rewrite/customers'
//
// import {
//   getEntireSlice as getEntireUsersSlice,
// } from '../../redux/selectors/rewrite/users'
//
// import {
//   getEntireSlice as getEntireCurrentUserSlice,
// } from '../../redux/selectors/currentUser'

import {
  getEntireSlice as getEntireParentChildLinksSlice,
} from '../../redux/selectors/rewrite/parentChildLinks'

// import {
//   getAllRepsOfCustomer,
// } from '../../redux/selectors/customers'

import {
  FETCH_CONTACTS,
} from '../../redux/actions/actionTypes'
import createAction from '../../redux/actions/createAction'

// import fetchStatusSelectors from '../../redux/selectors/fetchStatuses/customers/contacts'

// import { CUSTOMER_REPS_CUSTOMER_EXPERIENCE } from '../../constants'

const mapStateToProps = (state, { isUnsubscriptionNoLoginRequired, location }) => {
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const contactId = location?.pathname?.split('/')?.[2]
  // const customerType = entireCustomersSlice[customerId].customerType
  // const customerName = entireCustomersSlice[customerId].name
  // const tapCustomerId = entireCustomersSlice[customerId].tapcustomerId

  // const { getFetchStatuses, getFetchFailureErrorDetails } = fetchStatusSelectors
  // const fetchStatuses = getFetchStatuses(state, customerId)
  // const errorDetails = getFetchFailureErrorDetails(state, customerId)

  const entireContactsSlice = getEntireContactsSlice(state)
  const entireCustomerContactLinksSlice = getEntireCustomerContactLinksSlice(state)
  const entireParentChildLinksSlice = getEntireParentChildLinksSlice(state)
  // const entireUsersSlice = getEntireUsersSlice(state)
  // const entireCurrentUserSlice = getEntireCurrentUserSlice(state)
  // const userFirstName = entireCurrentUserSlice.firstName
  // const userLastName = entireCurrentUserSlice.lastName
  // const userRepIds = getAllRepsOfCustomer(state, customerId)
  // const ceRepId = userRepIds[CUSTOMER_REPS_CUSTOMER_EXPERIENCE]
  // const ceRep = entireUsersSlice[ceRepId]
  // const ceRepFirstName = ceRep?.firstName
  // const ceRepLastName = ceRep?.lastName

  return {
    // customerType,
    contactId,
    isUnsubscriptionNoLoginRequired,
    entireContactsSlice,
    entireCustomerContactLinksSlice,
    entireCustomersSlice,
    // entireUsersSlice,
    entireParentChildLinksSlice,
    // needed by the 'Add New Contact' email template body
    // customerName,
    // tapCustomerId,
    location,
    // userFirstName,
    // userLastName,
    // ceRepFirstName,
    // ceRepLastName,
    // needed by WaitForApiFetch
    // ...fetchStatuses,
    // errorDetails,
    loadingMessage: 'Fetching Subscribers...',
    failureHeaderMessage: 'Failed to Fetch Subscribers',
  }
}

const mapDispatchToProps = (dispatch, { contactId }) => ({
  // needed by WaitForApiFetch
  resubmitFunc: () => (
    dispatch(createAction(
      FETCH_CONTACTS,
      {
        contactId,
      },
    ))
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageAllContacts)
