import React from 'react'
import { Dimmer, Loader, Header } from 'semantic-ui-react'

import get_ from 'lodash/get'

import { useTranslation } from 'react-i18next'
import FormWithDimmerOverlayFailuresContent from '../FormWithDimmerOverlayFailuresContent'

import DefaultFailureAndSuccessButtons from './DefaultFailureAndSuccessButtons'

export default WrappedComponent => props => {
  const {
    isSubmitting,
    hasSubmitSucceeded,
    hasSubmitFailed,
    successMessage,
    formSubmissionDimmerOverlayHocProps,
  } = props
  const { t: translate } = useTranslation('common')
  if (get_(formSubmissionDimmerOverlayHocProps, 'dontActuallyApplyTheFormSubmissionDimmerOverlayHocHoc')) {
    return <WrappedComponent {...props} />
  }

  const active = isSubmitting || hasSubmitSucceeded || hasSubmitFailed
  return (
    <Dimmer.Dimmable dimmed={active}>
      <Dimmer
        active={active}
        onClickOutside={null}
        // Increase the darkness of the dimmer from Semantic UI's default (white
        // text on top of dimmed text is too hard to read without darkening this
        // a little): https://github.com/Semantic-Org/Semantic-UI/issues/1897
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.925)' }}
      >
        {isSubmitting &&
          <Loader size="big">{translate('Submitting')}</Loader>
        }
        {hasSubmitSucceeded && !isSubmitting &&
          <div>
            <Header as="h2" inverted color="green">
              {translate('Success!')}
            </Header>
            {successMessage}
            <div style={{ marginTop: '20px' }}>
              <DefaultFailureAndSuccessButtons
                {...props}
              />
            </div>
          </div>
        }
        {hasSubmitFailed && !isSubmitting &&
          <div>
            <Header as="h2" inverted color="red">
              {translate('Submission Failed')}
            </Header>
            <FormWithDimmerOverlayFailuresContent
              {...props}
            />
          </div>
        }
      </Dimmer>
      <WrappedComponent {...props} />
    </Dimmer.Dimmable>
  )
}
