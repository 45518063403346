import React from 'react'
import { Modal, Grid } from 'semantic-ui-react'


import { useTranslation } from 'react-i18next'
import ModalWithCatastrophicFailureErrorBoundary from '../../../../../../../common-components/semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'
import TextAsPseudoLink from '../../../../../../../common-components/TextAsPseudoLink'

import {
  getInfoDefinitionsInboundFullKegShipmentFullDetails,
} from '../../../../util/shipmentHistory/inboundFullKegShipmentHistory'
// import {
//   determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable,
// } from '../../../../util/shipmentHistory/common/util'

import HistoryLabelWithContainerTypesDifferencesPopupIfNecessary from '../../../../util/HistoryLabelWithContainerTypesDifferencesPopupIfNecessary'


export default ({
  itemSkuIdsToIncludeAsFieldsInTable,
  entireItemSkusSlice,
  customerId,
  customerType,
  info: shipmentDetails,
  allInboundFullKegShipmentObjects,
  configuredItemSkuIds,
  entireCustomersSlice,
  ...rest
}) => {
  const { t: translate } = useTranslation('common')
  return (
    <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
      closeIcon
      size="large"
      trigger={<TextAsPseudoLink>{shipmentDetails.shipmentId}</TextAsPseudoLink>}
      className="test-full-shipment-details"
    >
      <Modal.Header>{translate('Shipment Details')}</Modal.Header>
      <Modal.Content>
        <AdditionalShipmentDetails
          entireCustomersSlice={entireCustomersSlice}
          entireItemSkusSlice={entireItemSkusSlice}
          customerId={customerId}
          customerType={customerType}
          shipmentDetails={shipmentDetails}
          allInboundFullKegShipmentObjects={allInboundFullKegShipmentObjects}
          configuredItemSkuIds={configuredItemSkuIds}
          itemSkuIdsToIncludeAsFieldsInTable={itemSkuIdsToIncludeAsFieldsInTable}
          {...rest}
        />
      </Modal.Content>
    </ModalWithCatastrophicFailureErrorBoundary>
  )
}

const AdditionalShipmentDetails = ({
  entireItemSkusSlice,
  customerId,
  customerType,
  shipmentDetails,
  entireCustomersSlice,
  // allInboundFullKegShipmentObjects,
  // configuredItemSkuIds,
  itemSkuIdsToIncludeAsFieldsInTable,
  ...rest
}) => {
  // const itemSkuIdsToIncludeAsFieldsInTable = determineItemSkuIdsToIncludeAsFieldsInShipmentsHistoryTable({
  //   entireItemSkusSlice,
  //   configuredItemSkuIds,
  //   shipments: allInboundFullKegShipmentObjects,
  // })

  const toDisplay = getInfoDefinitionsInboundFullKegShipmentFullDetails({
    entireItemSkusSlice,
    itemSkuIdsToIncludeAsFieldsInTable,
    customerType,
    entireCustomersSlice,
    customerId,
    ...rest,
  })
  return (
    // For alignment purposes, all displayed content within this grid needs to
    // be contained within a <Grid.Column> component; see CODE_COMMENTS_97 for
    // why.
    <Grid columns={2}>
      {toDisplay.map(definition => (
        <Grid.Row key={definition.heading} className="compact-grid-row">
          <Grid.Column width={3} style={{ fontWeight: 'bold' }}>
            <HistoryLabelWithContainerTypesDifferencesPopupIfNecessary
              customerId={customerId}
              labelText={definition.heading}
              colonAfterText
            />
          </Grid.Column>
          <Grid.Column width={13}>{definition.cellContent(shipmentDetails, { customerId })}</Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  )
}
