/* CODE_COMMENTS_41 */

import {
  LOGOUT_BUTTON_ENABLED_TOGGLE_ENABLED,
  LOGOUT_BUTTON_ENABLED_TOGGLE_DISABLED,
} from '../../constants'

import {
  ENABLE_LOGOUT_BUTTON,
  DISABLE_LOGOUT_BUTTON,
} from '../actions/actionTypes'

const initialState = LOGOUT_BUTTON_ENABLED_TOGGLE_ENABLED

export default (state=initialState, action) => {
  switch (action.type) {
    case ENABLE_LOGOUT_BUTTON: {
      return LOGOUT_BUTTON_ENABLED_TOGGLE_ENABLED
    }

    case DISABLE_LOGOUT_BUTTON: {
      return LOGOUT_BUTTON_ENABLED_TOGGLE_DISABLED
    }

    default:
      return state
  }
}
