import React from 'react'
import { Card, Icon, Image, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import InformationalPopup from '../../../../../../../common-components/InformationalPopup'


import '../styles/customerportaloption.css'

// One constraint of semantic-ui-react's icons is that they're square, and the
// particular icons we use here are exactly 112px by 112px ("massive"). But
// several of our custom icon images are wider than they are tall, so
// constraining their size to 112px wide makes them appear much smaller than
// Semantic's icons, which were designed to be square. If a custom icon image is
// much wider than tall, pass it in as an image rather than an icon: set the
// 'asImage' prop to true and pass in the image's source with the 'imgSrc' prop.
const Option = props => {
  const {
    text,
    linkTo,
    asImage,
    imgSrc,
    customContentInPlaceOfIcon: CustomContentInPlaceOfIcon,
    alternateImgSrcIfDisabled,
    whyIsThisOptionDisabledMessage,
    id, // for selenium testing
    disabled,
    labelAsNew,

    name, // pased in only if the Icon is built in to Semantic UI React
    className, // pased in only if the Icon is custom built

    // passed in automatically by the <PopupComponent> if this <Option> is being
    // used as its 'trigger' prop.
    onBlur,
    onClick,
    onFocus,
    onMouseEnter,
    onMouseLeave,

  } = props
  const { t: translate } = useTranslation('common')
  return (
    <Card
      // Sometimes we want a Customer Portal card not to link to another page but
      // to render a popup when clicked. In such a case, the caller won't pass in
      // a `linkTo` prop, but we still want the card to have the same visual style
      // as a link, with the mouse still changing from a pointer to a hand when
      // moused over, so when linkTo isn't passed in, we set `link` to true.
      link={!linkTo && !disabled}
      as={linkTo && !disabled ? Link : null}
      to={linkTo && !disabled ? linkTo : null}
      className={`centered-content customer-portal${disabled ? ' disabled-card' : ''}`}
      id={id}

      onBlur={onBlur && onBlur}
      onClick={onClick && onClick}
      onFocus={onFocus && onFocus}
      onMouseEnter={onMouseEnter && onMouseEnter}
      onMouseLeave={onMouseLeave && onMouseLeave}
    >
      {labelAsNew &&
        <Label
          corner
          color="teal"
          style={{ paddingTop: '7px', paddingLeft: '15px' }}
        >
          New
        </Label>
      }
      <Card.Content>
        {whyIsThisOptionDisabledMessage &&
          <InformationalPopup
            includeTriggerIcon={false}
            includeTriggerText
            triggerText={translate('Why is this disabled?')}
            content={whyIsThisOptionDisabledMessage}
          />
        }
        {
          CustomContentInPlaceOfIcon
            ? <div
              style={{
                // Setting this to the font-size of a 'massive' icon makes the
                // Card.Header below line up vertically with the other cards'
                // headers in this row of <Card>s.
                height: '8em',
                // Center vertically center the content within the div
                display: 'flex',
                justifyContent: 'center', // align horizontal
                alignItems: 'center', // align vertical
              }}
            >
              <CustomContentInPlaceOfIcon {...props} />
            </div>
            : asImage
              ? <ImageInsteadOfIcon imgSrc={
                disabled && alternateImgSrcIfDisabled
                  ? alternateImgSrcIfDisabled
                  : imgSrc
              }
              />
              : <Icon size="massive" name={name && name} className={className && className} />
        }
        <Card.Header className={disabled ? 'disabled-card-label-text' : null}>
          {translate(text)}
        </Card.Header>
      </Card.Content>
    </Card>
  )
}


export default ({
  popupInsteadOfLink,
  popupComponent: PopupComponent,
  propsForPopupComponent,
  dispatch,
  ...rest
}) => {
  if (popupInsteadOfLink) {
    return (
      <PopupComponent
        trigger={<Option {...rest} />}
        {...propsForPopupComponent}
        {...rest}
      />
    )
  }
  return <Option {...rest} />
}

const ImageInsteadOfIcon = ({ imgSrc }) => (
  <div className="image-instead-of-icon-wrapper">
    <Image
      src={imgSrc}
    />
  </div>
)
