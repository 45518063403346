// A component which renders the `renderIfHasPermissions` prop if the
// `requiredPermissions` prop is true and renders null if false. Props:

// 1. requiredPermissions: can be one of three things: a string, an object (see
// the docstring of the getHasPermissionsToPerformFunctionality selector for
// details onthese two) or a function which will be passed an object which
// contains `state` and all the props received by this component and which
// should return a boolean representing whether the user has permissions to
// render the component.

// 2. renderIfHasPermissions: the component to render if (this uses the 'render props' pattern)

// 3. contracteeBrewerCustomerId, which must be passed in if this is a
// perCustomerPermission check (i.e. if this is a contractee brewer component).

import { connect } from 'react-redux'

import isFunction_ from 'lodash/isFunction'

import HasPermissions from './components'

import {
  getHasPermissionsToPerformFunctionality,
} from '../../redux/selectors/permissions'


const mapStateToProps = (state, props) => {
  const {
    requiredPermissions,
    // Will be undefined unless this is a contractee Brewer component
    customerIdIfThisIsAPerCustomerPermissionsCheck,
  } = props
  if (!requiredPermissions) {
    // There are some routes/components that render no matter what permissions a user has
    return {
      hasPermissions: true,
    }
  }

  return {
    hasPermissions: isFunction_(requiredPermissions)
      ? requiredPermissions({ state, ...props })
      : getHasPermissionsToPerformFunctionality({
        state,
        functionalityStringOrPermissionsMap: requiredPermissions,
        customerIdIfThisIsAPerCustomerPermissionsCheck,
      }),
  }
}

export default connect(
  mapStateToProps,
)(HasPermissions)
