import { call, put, select } from 'redux-saga/effects'

import isString_ from 'lodash/isString'


import { publicFetch } from '../../fetch'

import {
  UPDATE_USER_TOKEN,
} from '../../../actions/actionTypes'
import createAction from '../../../actions/createAction'

import {
  getProp as getAuthenticationProp,
} from '../../../selectors/authentication'

import {
  API_URL_PATH_LOGIN,
} from '../../../../constants'

import {
  logErrorMessage,
  LOG_SEVERITY_ERROR,
} from '../../../../utils/thirdPartyLogging'

import {
  extractMostImportantDetailsFromApiErrorObject,
  getDoesHttpErrorIndicateThatBackendIsUnderMaintentance,
} from '../../../../utils'


// These constnats allow our generators within this file to communicate to one
// other. Why not just use the string literals? See CODE_COMMENTS_33
export const FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED = 'FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED'
export const FETCH_NEW_USER_TOKEN_RESULT_REFRESH_TOKEN_EXPIRED = 'FETCH_NEW_USER_TOKEN_RESULT_REFRESH_TOKEN_EXPIRED'
export const FETCH_NEW_USER_TOKEN_RESULT_UNKNOWN_ERROR = 'FETCH_NEW_USER_TOKEN_RESULT_UNKNOWN_ERROR'
export const FETCH_NEW_USER_TOKEN_RESULT_SITE_UNDER_MAINTENANCE = 'FETCH_NEW_USER_TOKEN_RESULT_SITE_UNDER_MAINTENANCE' // CODE_COMMENTS_238

export function* fetchNewUserToken() {
  const refreshToken = yield select(getAuthenticationProp, 'refreshToken')
  let response
  try {
    response = yield call(
      // This is a publicFetch rather than a privateFetch because the userToken
      // is not actually necessary in order to re-login without a username and
      // password, only an unexpired refreshToken is necessary. See
      // CODE_COMMENTS_209.
      publicFetch,
      {
        path: API_URL_PATH_LOGIN,
        method: 'PUT',
        data: { refreshToken },
      },
    )
  } catch (error) {
    return doFailure(error)
  }
  return yield call(doSuccess, response)
}


function* doSuccess(response) {
  yield put(createAction(UPDATE_USER_TOKEN, response.data))
  return FETCH_NEW_USER_TOKEN_RESULT_NEW_USER_TOKEN_SUCCESSFULLY_FETCHED
}


function doFailure(error) {
  const hasRefreshTokenExpired = getDidFetchNewUserTokenFailBecauseRefreshTokenIsExpired(error)

  if (hasRefreshTokenExpired) {
    return FETCH_NEW_USER_TOKEN_RESULT_REFRESH_TOKEN_EXPIRED
  }
  // CODE_COMMENTS_238
  if (getDoesHttpErrorIndicateThatBackendIsUnderMaintentance({ error })) {
    return FETCH_NEW_USER_TOKEN_RESULT_SITE_UNDER_MAINTENANCE
  }
  // If the refresh token hasn't expired but some other error occurred, log the
  // error to our 3rd-party logging service
  logErrorMessage({
    message: 'Automatically re-logging in the user has failed for a reason other than refreshToken being expired',
    severity: LOG_SEVERITY_ERROR,
    httpResponse: error,
  })
  return FETCH_NEW_USER_TOKEN_RESULT_UNKNOWN_ERROR
}


// Helper functions

function getDidFetchNewUserTokenFailBecauseRefreshTokenIsExpired(error) {
  const errorDetails = extractMostImportantDetailsFromApiErrorObject({ error })
  return (
    errorDetails.responseBody && // will be null if network error
    isString_(errorDetails.responseBody.message) &&
    errorDetails.responseBody.message.toLowerCase().includes('refresh token has expired')
  )
}
