/**
 * CODE_COMMENTS_135
 */
import {
  SET_CATASTROPHIC_FAILURE_HAPPENED,
  UNSET_CATASTROPHIC_FAILURE_HAPPENED,
} from '../actions/actionTypes'

const initialState = {
  hasCatastrophicFailureHappened: false,
  error: null,
  info: null,
}

export default (state=initialState, action) => {
  switch (action.type) {
    case SET_CATASTROPHIC_FAILURE_HAPPENED: {
      return {
        hasCatastrophicFailureHappened: true,
        error: action.payload.error,
        info: action.payload.info,
      }
    }

    case UNSET_CATASTROPHIC_FAILURE_HAPPENED:
      return initialState

    default:
      return state
  }
}
