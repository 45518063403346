import React from 'react'

import ReportInventoryComponent from './containers'

export default props => (
  <ReportInventoryComponent
    isThisForDisplayingAnAlreadyCreatedInventoryReport
    {...props}
  />
)
