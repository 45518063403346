import React from 'react'


import { useTranslation } from 'react-i18next'
import LoginForm from './LoginForm'
import ForgotPasswordAndOtherLinks from './ForgotPasswordAndOtherLinks'

import PublicFormFailuresContent from '../../../common-components/FormSubmission/PublicFormFailuresContent'
import DismissableMessageClearingFetchStatus from '../../../common-components/FormSubmission/PublicFormFailuresContent/DismissableMessageClearingFetchStatus'

import PublicPageWrapper from '../../../common-components/publicPages/PublicPageWrapper'

import {
  REDUCER_NAMES_PUBLIC_FORMS_LOGIN,
} from '../../../constants'


export default PublicPageWrapper('Login')(props => {
  const {
    isAuthenticating,
    hasSubmitFailed,
  } = props
  const { t: translate } = useTranslation('common')
  return (
    <div>
      {hasSubmitFailed && !isAuthenticating
        ? <PublicFormFailuresContent
          formName={REDUCER_NAMES_PUBLIC_FORMS_LOGIN}

          customFailureRenderInstructions={[
            {
              when: ({ errorCode }) => errorCode === 404,
              render: () => (
                <DismissableMessageClearingFetchStatus
                  formName={REDUCER_NAMES_PUBLIC_FORMS_LOGIN}
                  content={
                    <p>{translate('Incorrect email address or password')}</p>
                  }
                />
              ),
            },
            {
              when: ({ errorCode }) => errorCode === 401,
              render: () => (
                <DismissableMessageClearingFetchStatus
                  formName={REDUCER_NAMES_PUBLIC_FORMS_LOGIN}
                  content={
                    <p>{translate('Your session has expired, please log in again')}</p>
                  }
                />
              ),
            },
          ]}
        />
        : null
      }
      <LoginForm
        {...props}
      />
      <ForgotPasswordAndOtherLinks translate={translate} />
    </div>
  )
})
